import { convertIntif } from "@project/sharedcomponents/utilityFunctions"
import {
  CLOSINGDATA_SERVICE_CLEAR,
  CLOSINGDATA_SERVICE_FAIL,
  CLOSINGDATA_SERVICE_START,
  CLOSINGDATA_SERVICE_SUCCESS,
  CLOSINGDATA_SERVICE_UPDATED,
  CLOSINGSTOCK_SERVICE_FAIL,
  CLOSINGSTOCK_SERVICE_START,
  CLOSINGSTOCK_SERVICE_SUCCESS,
} from "../Actions/types"

const INITIAL_STATE = {
  error: "",
  loading: false,

  data: [],
  totalcount: 0,
  rowsPerPage: 0,
  page: 0,
  order: "asc",
  orderBy: "",
  search_text: "",
  ddclosingstock: [],
  columnData: [
    { id: "month", numeric: false, visible: true, label: "Month" },
    { id: "code", numeric: false, visible: false, label: "Code", toggle: true },
    {
      id: "amt",
      numeric: true,
      visible: true,
      label: `Amount`,
      toggle: true,
      width: 200,
      format: "indian_rupee",
      currencySign: true,
      popover: { key: "updateBalance", placeholder: "Enter Amount", label: "Update Amount", name: "amt", title: "Update Closing Stock", number: true },
    },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLOSINGSTOCK_SERVICE_START:
      return { ...state, loading: true, error: "" }

    case CLOSINGSTOCK_SERVICE_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        data: action.payload.data.result,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
        islogin: true,
      }
    case CLOSINGSTOCK_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        data: [],
        loading: false,
        totalcount: 0,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
      }
    case CLOSINGDATA_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case CLOSINGDATA_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case CLOSINGDATA_SERVICE_SUCCESS:
      return {
        ...state,
        ddclosingstock: action.payload.data.result,
        ...INITIAL_STATE,
        data: action.payload.data.result,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
        code: action.code,
        islogin: true,
      }
    case CLOSINGDATA_SERVICE_FAIL:
      return {
        ...state,
        ddclosingstock: [],
        error: action.payload,
        data: [],
        loading: false,
        totalcount: 0,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
        code: action.code,
      }
    case CLOSINGDATA_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.month_no) !== convertIntif(action.payload.DetailGrid[0].month_no)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.amt = action.payload.DetailGrid[0].amt

        return updateditem
      })

      return { ...state, data: updateddata }

    default:
      return state
  }
}
