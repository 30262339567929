import { columnGSTSummary, getTaxRate } from "./reportFunction"
import {
  DatetoDMY,
  arraySum,
  changeSign,
  checkCondition,
  cloneObj,
  convertIntif,
  getNumberonly,
  getdropdownfieldvalue,
  isblank,
  makeExcelRequireCol,
  makeExcelRequiredData,
  replaceBRtoNewLine,
  replacefunction,
  toFixedFn,
} from "./utilityFunctions"
import { generateExcelData } from "./xlsxFunctions"

import lodashMap from "lodash/map"
import lodashSum from "lodash/sum"

export const outward_supplies = [
  { id: "Type", numeric: false, visible: true, label: "Nature of Supplies", width: 200 },
  { id: "invoices", numeric: false, visible: true, label: "No of Invoices", width: 110 },
  { id: "Taxable_Amt", numeric: true, currencySign: true, visible: true, label: "Total Taxable Value", format: "indian_rupee" },
  { id: "IGST_Amt", numeric: true, currencySign: true, visible: true, label: "Integrated Tax", format: "indian_rupee" },
  { id: "CGST_Amt", numeric: true, currencySign: true, currencySign: true, visible: true, label: "Central Tax", format: "indian_rupee" },
  { id: "SGST_Amt", numeric: true, visible: true, label: "State/UT Tax", format: "indian_rupee" },
  { id: "CESS_Amt", numeric: true, currencySign: true, visible: true, label: "Cess", format: "indian_rupee" },
]
export const interstate_supplies = [
  { id: "Type", numeric: false, visible: true, label: "Type", width: 200 },
  { id: "Placeof_Supply", numeric: false, visible: true, label: "Place of Supply (State/UT)" },
  { id: "Taxable_Amt", numeric: true, visible: true, currencySign: true, label: "Total Taxable Value", format: "indian_rupee" },
  { id: "IGST_Amt", numeric: true, visible: true, currencySign: true, label: "Amount of Integrated Tax", format: "indian_rupee" },
]
export const itc = [
  { id: "Type", numeric: false, visible: true, label: "Eligible ITC", width: 200 },
  { id: "IGST_Amt", numeric: true, currencySign: true, visible: true, label: "Integrated Tax", format: "indian_rupee" },
  { id: "CGST_Amt", numeric: true, currencySign: true, visible: true, label: "Central Tax", format: "indian_rupee" },
  { id: "SGST_Amt", numeric: true, currencySign: true, visible: true, label: "State/UT Tax", format: "indian_rupee" },
  { id: "CESS_Amt", numeric: true, currencySign: true, visible: true, label: "Cess", format: "indian_rupee" },
]
export const exempt_supplies = [
  { id: "Type", numeric: false, visible: true, label: "Nature of Supplies", width: 200 },
  { id: "Interstate_Amt", numeric: true, currencySign: true, visible: true, label: "Inter-State supplies", format: "indian_rupee" },
  { id: "Intrastate_Amt", numeric: true, currencySign: true, visible: true, label: "Intra-State supplies", format: "indian_rupee" },
]

export const downloadGSTR3BJSON = ({ data, to_date, from_date, functions }) => {
  const { getAccountSitesetting, getquarter, user_data, getAccountSessionUserData, downloadJSONFile } = functions

  const GSTIN = getAccountSessionUserData("gstin", user_data)
  var ret_period = to_date.split("-")
  ret_period = `${ret_period[1]}${ret_period[0]}`

  const unreg_details = []
  const comp_details = []
  data.interstate_supplies.forEach((e) => {
    if (e.btype === "unreg_details") {
      unreg_details.push({ pos: e.Stateid, txval: getFileValue(e.Taxable_Amt), iamt: getFileValue(e.IGST_Amt) })
    } else if (e.btype === "comp_details") {
      comp_details.push({ pos: e.Stateid, txval: getFileValue(e.Taxable_Amt), iamt: getFileValue(e.IGST_Amt) })
    }
  })

  const jsonData = {
    gstin: GSTIN,
    ret_period: ret_period,
    sup_details: {
      osup_det: {
        txval: getValueByType(data.outward_supplies, "taxable_outward_supplies", "Taxable_Amt"),
        iamt: getValueByType(data.outward_supplies, "taxable_outward_supplies", "IGST_Amt"),
        camt: getValueByType(data.outward_supplies, "taxable_outward_supplies", "CGST_Amt"),
        samt: getValueByType(data.outward_supplies, "taxable_outward_supplies", "SGST_Amt"),
        csamt: getValueByType(data.outward_supplies, "taxable_outward_supplies", "CESS_Amt"),
      },
      osup_zero: {
        txval: getValueByType(data.outward_supplies, "zero_rated_outward_supplies", "Taxable_Amt"),
        iamt: getValueByType(data.outward_supplies, "zero_rated_outward_supplies", "IGST_Amt"),
        camt: getValueByType(data.outward_supplies, "zero_rated_outward_supplies", "CGST_Amt"),
        samt: getValueByType(data.outward_supplies, "zero_rated_outward_supplies", "SGST_Amt"),
        csamt: getValueByType(data.outward_supplies, "zero_rated_outward_supplies", "CESS_Amt"),
      },
      osup_nil_exmp: {
        txval: getValueByType(data.outward_supplies, "other_outward_supplies", "Taxable_Amt"),
        iamt: getValueByType(data.outward_supplies, "other_outward_supplies", "IGST_Amt"),
        camt: getValueByType(data.outward_supplies, "other_outward_supplies", "CGST_Amt"),
        samt: getValueByType(data.outward_supplies, "other_outward_supplies", "SGST_Amt"),
        csamt: getValueByType(data.outward_supplies, "other_outward_supplies", "CESS_Amt"),
      },
      isup_rev: {
        txval: getValueByType(data.outward_supplies, "inward_supplies", "Taxable_Amt"),
        iamt: getValueByType(data.outward_supplies, "inward_supplies", "IGST_Amt"),
        camt: getValueByType(data.outward_supplies, "inward_supplies", "CGST_Amt"),
        samt: getValueByType(data.outward_supplies, "inward_supplies", "SGST_Amt"),
        csamt: getValueByType(data.outward_supplies, "inward_supplies", "CESS_Amt"),
      },
      osup_nongst: {
        txval: 0,
        iamt: 0,
        camt: 0,
        samt: 0,
        csamt: 0,
      },
    },
    itc_elg: {
      itc_avl: [
        {
          ty: "IMPG",
          iamt: getValueByType(data.itc, "itc_eligible_import_goods", "IGST_Amt"),
          camt: getValueByType(data.itc, "itc_eligible_import_goods", "CGST_Amt"),
          samt: getValueByType(data.itc, "itc_eligible_import_goods", "SGST_Amt"),
          csamt: getValueByType(data.itc, "itc_eligible_import_goods", "CESS_Amt"),
        },
        { ty: "IMPS", iamt: 0, camt: 0, samt: 0, csamt: 0 },
        {
          ty: "ISRC",
          iamt: getValueByType(data.itc, "inward_supplies", "IGST_Amt"),
          camt: getValueByType(data.itc, "inward_supplies", "CGST_Amt"),
          samt: getValueByType(data.itc, "inward_supplies", "SGST_Amt"),
          csamt: getValueByType(data.itc, "inward_supplies", "CESS_Amt"),
        },
        {
          ty: "ISD",
          iamt: 0,
          camt: 0,
          samt: 0,
          csamt: 0,
        },
        {
          ty: "OTH",
          iamt: getValueByType(data.itc, "itc_eligible_inward_supplies", "IGST_Amt"),
          camt: getValueByType(data.itc, "itc_eligible_inward_supplies", "CGST_Amt"),
          samt: getValueByType(data.itc, "itc_eligible_inward_supplies", "SGST_Amt"),
          csamt: getValueByType(data.itc, "itc_eligible_inward_supplies", "CESS_Amt"),
        },
      ],
      itc_rev: [
        { ty: "RUL", iamt: 0, camt: 0, samt: 0, csamt: 0 },
        { ty: "OTH", iamt: 0, camt: 0, samt: 0, csamt: 0 },
      ],
      itc_net: {
        iamt: getValueByType(data.itc, "itc_total", "IGST_Amt"),
        camt: getValueByType(data.itc, "itc_total", "CGST_Amt"),
        samt: getValueByType(data.itc, "itc_total", "SGST_Amt"),
        csamt: getValueByType(data.itc, "itc_total", "CESS_Amt"),
      },
      itc_inelg: [
        { ty: "RUL", iamt: 0, camt: 0, samt: 0, csamt: 0 },
        { ty: "OTH", iamt: 0, camt: 0, samt: 0, csamt: 0 },
      ],
    },
    inward_sup: {
      isup_details: [
        { ty: "GST", inter: 0, intra: 0 },
        { ty: "NONGST", inter: 0, intra: 0 },
      ],
    },
    intr_ltfee: {
      intr_details: { iamt: 0, camt: 0, samt: 0, csamt: 0 },
      ltfee_details: {},
    },
    inter_sup: {
      unreg_details: unreg_details,
      comp_details: comp_details,
      uin_details: [],
    },
  }

  const currentDate = new Date(to_date)
  var currentMonth = currentDate.getMonth()
  currentMonth = currentMonth + 1
  if (currentMonth < 10) {
    currentMonth = `0${currentMonth}`
  }
  const fileName = `${currentMonth}_${currentDate.getFullYear()}_GSTR3B${GSTIN}-Details`

  // Sep_2021-GSTR3B24BZFPS4775M1ZN-Details.json

  downloadJSONFile(jsonData, fileName)
}

const getValueByType = (dataArray, btype, key) => {
  var returnValue = 0
  const valueFind = dataArray.find((e) => e.btype === btype)
  if (!isblank(valueFind)) {
    returnValue = getFileValue(valueFind[key])
  }
  return returnValue
}

export const getFileValue = (value, decimalPoint) => {
  var decimal = decimalPoint ? convertIntif(decimalPoint) : 2
  var returnValue = 0
  returnValue = !isblank(value) ? value : 0
  if (!isNaN(returnValue)) {
    returnValue = parseFloat(toFixedFn(parseFloat(returnValue), decimal))
  } else {
    returnValue = 0
  }
  return returnValue
}

export const getPositiveValue = (value) => {
  var returnValue = getFileValue(value)
  return Math.abs(returnValue)
}

const generateB2BData = (data, dd_taxcode) => {
  var uniqueGSTData = []
  data.forEach((e) => {
    if (e.btype === "B2B" && !isblank(e.detailData) && !isblank(e.detailData.records) && !isblank(e.detailData.records.list)) {
      e.detailData.records.list.forEach((i) => {
        if (uniqueGSTData.filter((j) => j.ctin === i.gstin).length > 0) {
          uniqueGSTData = uniqueGSTData.map((k) => {
            const updated = { ...k }
            if (updated.ctin === i.gstin) {
              updated.inv = [...updated.inv, getB2BInvData(i, dd_taxcode)]
            }
            return updated
          })
        } else {
          uniqueGSTData.push({ ctin: i.gstin, inv: [getB2BInvData(i, dd_taxcode)] })
        }
      })
    }
  })
  return uniqueGSTData
}

const generateB2CSData = (data, functions) => {
  const { getAccountSessionUserData, user_data, dd_taxcode } = functions
  const companyStateId = getAccountSessionUserData("statecode", user_data)
  var igstCodes = !isblank(dd_taxcode) && !isblank(dd_taxcode.igst) ? dd_taxcode.igst.split(",") : []
  var sgstCodes = !isblank(dd_taxcode) && !isblank(dd_taxcode.sgst) ? dd_taxcode.sgst.split(",") : []
  var cgstCodes = !isblank(dd_taxcode) && !isblank(dd_taxcode.cgst) ? dd_taxcode.cgst.split(",") : []
  var uniqueStateAndRateData = []
  data.forEach((e) => {
    if (e.btype === "B2CS" && !isblank(e.detailData) && !isblank(e.detailData.records) && !isblank(e.detailData.records.list)) {
      e.detailData.records.list.forEach((i) => {
        i.invoiceDetail.forEach((j) => {
          if (!isblank(j.taxdetail) && j.taxdetail.length > 0) {
            if (!isblank(j.taxdetail[0].taxper) && !isblank(j.taxdetail[0].taxcode)) {
              var uniqueKey = ""
              var rt = ""
              var sply_ty = convertIntif(companyStateId) === convertIntif(i.placeof_supplyId) ? "INTRA" : "INTER"
              if (igstCodes.includes(j.taxdetail[0].taxcode)) {
                rt = getFileValue(j.taxdetail[0].taxper)
                uniqueKey = `${i.placeof_supplyId}_${getFileValue(j.taxdetail[0].taxper)}`
              } else if (sgstCodes.includes(j.taxdetail[0].taxcode) || cgstCodes.includes(j.taxdetail[0].taxcode)) {
                rt = getFileValue(j.taxdetail[0].taxper) * 2
                uniqueKey = `${i.placeof_supplyId}_${getFileValue(j.taxdetail[0].taxper) * 2}`
              }
              if (uniqueStateAndRateData.filter((k) => k.uniqueKey === uniqueKey).length > 0) {
                uniqueStateAndRateData = uniqueStateAndRateData.map((l) => {
                  const updated = { ...l }

                  let updatedTaxAmt = getFileValue(j.taxdetail[0].taxamount)
                  if (updated.uniqueKey === uniqueKey) {
                    if (i.amount < 0) {
                      updated.txval = getFileValue(getFileValue(updated.txval) + changeSign(getFileValue(j.taxable_amt)))
                      updatedTaxAmt = changeSign(updatedTaxAmt)
                    } else {
                      updated.txval = getFileValue(getFileValue(updated.txval) + getFileValue(j.taxable_amt))
                    }

                    if (sply_ty === "INTRA") {
                      updated.camt = getFileValue(getFileValue(updated.camt) + updatedTaxAmt)
                      updated.samt = getFileValue(getFileValue(updated.samt) + updatedTaxAmt)
                    } else {
                      updated.iamt = getFileValue(getFileValue(updated.iamt) + updatedTaxAmt)
                    }
                  }
                  return updated
                })
              } else {
                let updatedTaxableAmt = getFileValue(j.taxable_amt)
                let updatedTaxAmt = getFileValue(j.taxdetail[0].taxamount)
                if (i.amount < 0) {
                  updatedTaxableAmt = changeSign(updatedTaxableAmt)
                  updatedTaxAmt = changeSign(updatedTaxAmt)
                }
                if (sply_ty === "INTRA") {
                  uniqueStateAndRateData.push({
                    uniqueKey: uniqueKey,
                    pos: i.placeof_supplyId,
                    csamt: 0.0,
                    rt: rt,
                    sply_ty: sply_ty,
                    typ: "OE",
                    txval: updatedTaxableAmt,
                    camt: updatedTaxAmt,
                    samt: updatedTaxAmt,
                  })
                } else {
                  uniqueStateAndRateData.push({
                    uniqueKey: uniqueKey,
                    pos: i.placeof_supplyId,
                    csamt: 0.0,
                    rt: rt,
                    sply_ty: sply_ty,
                    typ: "OE",
                    txval: updatedTaxableAmt,
                    iamt: updatedTaxAmt,
                  })
                }
              }
            }
          }
        })
      })
    }
  })
  uniqueStateAndRateData = uniqueStateAndRateData.map((e) => {
    const updated = { ...e }
    delete updated.uniqueKey
    return updated
  })
  return uniqueStateAndRateData
}

const generateB2CLData = (data, functions) => {
  var uniqueStateData = []
  data.forEach((e) => {
    if (e.btype === "B2CL" && !isblank(e.detailData) && !isblank(e.detailData.records) && !isblank(e.detailData.records.list)) {
      e.detailData.records.list.forEach((i) => {
        if (uniqueStateData.filter((j) => j.pos === i.placeof_supplyId).length > 0) {
          uniqueStateData = uniqueStateData.map((k) => {
            const updated = { ...k }
            if (updated.pos === i.placeof_supplyId) {
              updated.inv = [...updated.inv, getb2ClInv(i, functions)]
            }
            return updated
          })
        } else {
          uniqueStateData.push({
            pos: i.placeof_supplyId,
            inv: [getb2ClInv(i, functions)],
          })
        }
      })
    }
  })
  return uniqueStateData
}

const getb2ClInv = (invDetail, functions) => {
  const { dd_taxcode } = functions
  var igstCodes = !isblank(dd_taxcode) && !isblank(dd_taxcode.igst) ? dd_taxcode.igst.split(",") : []
  var sgstCodes = !isblank(dd_taxcode) && !isblank(dd_taxcode.sgst) ? dd_taxcode.sgst.split(",") : []
  var cgstCodes = !isblank(dd_taxcode) && !isblank(dd_taxcode.cgst) ? dd_taxcode.cgst.split(",") : []
  var itms = []
  invDetail.invoiceDetail.forEach((e) => {
    if (!isblank(e.taxdetail) && e.taxdetail.length > 0) {
      if (igstCodes.includes(e.taxdetail[0].taxcode)) {
        itms.push({
          num: itms.length + 1,
          itm_det: {
            txval: getFileValue(e.taxable_amt),
            rt: getFileValue(e.taxdetail[0].taxper),
            iamt: getFileValue(e.taxdetail[0].taxamount),
            csamt: 0,
          },
        })
      }
    }
  })
  const returnData = {
    inum: invDetail.billno,
    idt: DatetoDMY(invDetail.date),
    val: getFileValue(invDetail.netamount),
    itms: itms,
  }
  return returnData
}

const generateCDNRData = (data, dd_taxcode) => {
  var uniqueGSTData = []
  data.forEach((e) => {
    if (e.btype === "CDNR" && !isblank(e.detailData) && !isblank(e.detailData.records) && !isblank(e.detailData.records.list)) {
      e.detailData.records.list.forEach((i) => {
        if (uniqueGSTData.filter((j) => j.ctin === i.gstin).length > 0) {
          uniqueGSTData = uniqueGSTData.map((k) => {
            const updated = { ...k }
            if (updated.ctin === i.gstin) {
              updated.nt = [...updated.nt, getCDNRInvData(i, dd_taxcode)]
            }
            return updated
          })
        } else {
          uniqueGSTData.push({ ctin: i.gstin, nt: [getCDNRInvData(i, dd_taxcode)] })
        }
      })
    }
  })
  return uniqueGSTData
}

const generateCDNURData = (data, dd_taxcode) => {
  var uniqueData = []
  data.forEach((e) => {
    if (e.btype === "CDNUR" && !isblank(e.detailData) && !isblank(e.detailData.records) && !isblank(e.detailData.records.list)) {
      e.detailData.records.list.forEach((i) => {
        uniqueData.push(getCDNRInvData(i, dd_taxcode))
      })
    }
  })
  return uniqueData
}

const getExpInvData = (invDetail, exp_typ) => {
  const items = []
  invDetail.invoiceDetail.forEach((e) => {
    if (!isblank(e.taxdetail) && e.taxdetail.length > 0) {
      if (!isblank(e.taxdetail[0].taxcode)) {
        if (exp_typ === "WOPAY") {
          items.push({
            txval: getFileValue(e.taxable_amt),
            rt: getFileValue(e.taxdetail[0].taxper),
            iamt: 0,
            csamt: 0,
          })
        } else {
          items.push({
            txval: getFileValue(e.taxable_amt),
            rt: getFileValue(e.taxdetail[0].taxper),
            iamt: getFileValue(e.taxdetail[0].taxamount),
            csamt: 0,
          })
        }
      }
    }
  })
  const returnValue = {
    inum: invDetail.billno,
    idt: DatetoDMY(invDetail.date),
    val: getFileValue(invDetail.netamount),
    itms: items,
  }
  const sbpcode = getExpShippDetail(invDetail.expShippDetail, "shippingport")
  const sbnum = getExpShippDetail(invDetail.expShippDetail, "shippingNo")
  const sbdt = getExpShippDetail(invDetail.expShippDetail, "shippingDate")
  if (!isblank(sbpcode)) {
    returnValue.sbpcode = sbpcode
  }
  if (!isblank(sbnum)) {
    returnValue.sbnum = sbnum
  }
  if (!isblank(sbdt)) {
    returnValue.sbdt = DatetoDMY(sbdt)
  }
  return returnValue
}

const generateExpData = (data) => {
  var uniqueData = []
  data.forEach((e) => {
    if (e.btype === "Export_Invoices" && !isblank(e.detailData) && !isblank(e.detailData.records) && !isblank(e.detailData.records.list)) {
      e.detailData.records.list.forEach((i) => {
        if (uniqueData.filter((j) => j.exp_typ === i.exp_typ).length > 0) {
          uniqueData = uniqueData.map((j) => {
            const updated = { ...j }
            if (updated.exp_typ === i.exp_typ) {
              updated.inv = [...updated.inv, getExpInvData(i, i.exp_typ)]
            }
            return updated
          })
        } else {
          uniqueData.push({
            exp_typ: i.exp_typ,
            inv: [getExpInvData(i, i.exp_typ)],
          })
        }
      })
    }
  })
  return uniqueData
}

export const generateNilData = (data, functions) => {
  const { getAccountSessionUserData, user_data } = functions
  const company_stateCode = getAccountSessionUserData("statecode", user_data)

  var nilInvoices = [
    {
      sply_ty: "INTRB2B",
      expt_amt: 0,
      nil_amt: 0,
      ngsup_amt: 0,
    },
    {
      sply_ty: "INTRAB2B",
      expt_amt: 0,
      nil_amt: 0,
      ngsup_amt: 0,
    },
    {
      sply_ty: "INTRB2C",
      expt_amt: 0,
      nil_amt: 0,
      ngsup_amt: 0,
    },
    {
      sply_ty: "INTRAB2C",
      expt_amt: 0,
      nil_amt: 0,
      ngsup_amt: 0,
    },
  ]
  data.forEach((e) => {
    if (e.btype === "NIL" && !isblank(e.detailData) && !isblank(e.detailData.records) && !isblank(e.detailData.records.list)) {
      e.detailData.records.list.forEach((i) => {
        if (checkCondition(i.gst_treatment, [1, 2])) {
          if (convertIntif(i.placeof_supplyId) === convertIntif(company_stateCode)) {
            nilInvoices = setNilRatedData(nilInvoices, "INTRAB2B", i)
          } else {
            nilInvoices = setNilRatedData(nilInvoices, "INTRB2B", i)
          }
        } else {
          if (convertIntif(i.placeof_supplyId) === convertIntif(company_stateCode)) {
            nilInvoices = setNilRatedData(nilInvoices, "INTRAB2C", i)
          } else {
            nilInvoices = setNilRatedData(nilInvoices, "INTRB2C", i)
          }
        }
      })
    }
  })

  var uniqueData = []

  if (
    nilInvoices.filter((item, index) => {
      return item.expt_amt > 0 || item.nil_amt > 0 || item.ngsup_amt > 0
    }).length > 0
  )
    uniqueData = {
      inv: nilInvoices,
    }
  return uniqueData
}

const setNilRatedData = (nilInvoices, type, i) => {
  return nilInvoices.map((e) => {
    const updated = { ...e }
    if (updated.sply_ty === type) {
      if (convertIntif(i.zeroRatedType) === 2) {
        updated.expt_amt = getFileValue(getFileValue(updated.expt_amt) + getFileValue(i.netamount))
      } else if (convertIntif(i.zeroRatedType) === 1) {
        updated.nil_amt = getFileValue(getFileValue(updated.nil_amt) + getFileValue(i.netamount))
      } else if (convertIntif(i.zeroRatedType) === 3) {
        updated.ngsup_amt = getFileValue(getFileValue(updated.ngsup_amt) + getFileValue(i.netamount))
      }
    }
    return updated
  })
}

const generateHSNData = (data, functions) => {
  const { getAccountSitesetting, user_data } = functions
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint", user_data)
  var returnData = []
  data.forEach((e) => {
    if (e.btype === "HSN" && !isblank(e.detailData) && !isblank(e.detailData.records) && !isblank(e.detailData.records.list)) {
      e.detailData.records.list.forEach((i) => {
        returnData.push({
          num: returnData.length + 1,
          hsn_sc: i.hsn_key,
          uqc: i.uqc_short,
          qty: getFileValue(i.tot_qty, inventory_decimalpoint),
          // "val": getFileValue(i.amount),
          rt: getFileValue(i.rate),
          txval: getFileValue(i.netamount),
          iamt: getFileValue(i.igst_amount),
          samt: getFileValue(i.sgst_amount),
          camt: getFileValue(i.cgst_amount),
          csamt: 0,
        })
      })
    }
  })
  return returnData
}

const getB2BInvData = (invDetail, dd_taxcode) => {
  var igstCodes = !isblank(dd_taxcode) && !isblank(dd_taxcode.igst) ? dd_taxcode.igst.split(",") : []
  var sgstCodes = !isblank(dd_taxcode) && !isblank(dd_taxcode.sgst) ? dd_taxcode.sgst.split(",") : []
  var cgstCodes = !isblank(dd_taxcode) && !isblank(dd_taxcode.cgst) ? dd_taxcode.cgst.split(",") : []
  var items = []
  invDetail.invoiceDetail.forEach((e) => {
    if (!isblank(e.taxdetail) && e.taxdetail.length > 0) {
      if (!isblank(e.taxdetail[0].taxcode)) {
        if (igstCodes.includes(e.taxdetail[0].taxcode)) {
          if (items.filter((i) => !isblank(i.itm_det) && i.itm_det.rt === getFileValue(e.taxdetail[0].taxper)).length > 0) {
            items = items.map((j) => {
              const updated = { ...j }
              if (updated.itm_det.rt === getFileValue(e.taxdetail[0].taxper)) {
                updated.itm_det.txval = getFileValue(getFileValue(updated.itm_det.txval) + getFileValue(e.taxable_amt))
                updated.itm_det.iamt = getFileValue(getFileValue(updated.itm_det.iamt) + getFileValue(e.taxdetail[0].taxamount))
              }
              return updated
            })
          } else {
            items.push({
              num: items.length + 1,
              itm_det: {
                rt: getFileValue(e.taxdetail[0].taxper),
                txval: getFileValue(e.taxable_amt),
                iamt: getFileValue(e.taxdetail[0].taxamount),
                csamt: 0,
              },
            })
          }
        } else if (cgstCodes.includes(e.taxdetail[0].taxcode) || sgstCodes.includes(e.taxdetail[0].taxcode)) {
          if (items.filter((i) => !isblank(i.itm_det) && i.itm_det.rt === getFileValue(e.taxdetail[0].taxper) * 2).length > 0) {
            items = items.map((j) => {
              const updated = { ...j }
              if (updated.itm_det.rt === getFileValue(e.taxdetail[0].taxper) * 2) {
                updated.itm_det.txval = getFileValue(getFileValue(updated.itm_det.txval) + getFileValue(e.taxable_amt))
                updated.itm_det.samt = getFileValue(getFileValue(updated.itm_det.samt) + getFileValue(e.taxdetail[0].taxamount))
                updated.itm_det.camt = getFileValue(getFileValue(updated.itm_det.camt) + getFileValue(e.taxdetail[0].taxamount))
              }
              return updated
            })
          } else {
            items.push({
              num: items.length + 1,
              itm_det: {
                rt: getFileValue(e.taxdetail[0].taxper) * 2,
                txval: getFileValue(e.taxable_amt),
                samt: getFileValue(e.taxdetail[0].taxamount),
                camt: getFileValue(e.taxdetail[0].taxamount),
                csamt: 0,
              },
            })
          }
        }
      }
    }
  })
  const returnValue = {
    inum: invDetail.billno,
    idt: DatetoDMY(invDetail.date),
    val: getFileValue(invDetail.netamount),
    pos: invDetail.placeof_supplyId,
    rchrg: invDetail.reversable_charge,
    // "inv_typ": invDetail.invoiceType === 'Regular' ? 'R' : '',
    inv_typ: "R",
    itms: items,
  }
  return returnValue
}

const getCDNRInvData = (invDetail, dd_taxcode) => {
  var igstCodes = !isblank(dd_taxcode) && !isblank(dd_taxcode.igst) ? dd_taxcode.igst.split(",") : []
  var sgstCodes = !isblank(dd_taxcode) && !isblank(dd_taxcode.sgst) ? dd_taxcode.sgst.split(",") : []
  var cgstCodes = !isblank(dd_taxcode) && !isblank(dd_taxcode.cgst) ? dd_taxcode.cgst.split(",") : []
  var items = []
  invDetail.invoiceDetail.forEach((e) => {
    if (!isblank(e.taxdetail) && e.taxdetail.length > 0) {
      if (!isblank(e.taxdetail[0].taxcode)) {
        if (igstCodes.includes(e.taxdetail[0].taxcode)) {
          if (items.filter((j) => getPositiveValue(j.itm_det.rt) === getPositiveValue(e.taxdetail[0].taxper)).length > 0) {
            items = items.map((k) => {
              const updated = { ...k }
              if (getPositiveValue(updated.itm_det.rt) === getPositiveValue(e.taxdetail[0].taxper)) {
                updated.itm_det.txval = getPositiveValue(getPositiveValue(updated.itm_det.txval) + getPositiveValue(e.taxable_amt))
                updated.itm_det.iamt = getPositiveValue(getPositiveValue(updated.itm_det.iamt) + getPositiveValue(e.taxdetail[0].taxamount))
              }
              return updated
            })
          } else {
            items.push({
              num: items.length + 1,
              itm_det: {
                rt: getPositiveValue(e.taxdetail[0].taxper),
                txval: getPositiveValue(e.taxable_amt),
                iamt: getPositiveValue(e.taxdetail[0].taxamount),
                csamt: 0,
              },
            })
          }
        } else if (cgstCodes.includes(e.taxdetail[0].taxcode) || sgstCodes.includes(e.taxdetail[0].taxcode)) {
          if (items.filter((j) => getPositiveValue(j.itm_det.rt) === getPositiveValue(e.taxdetail[0].taxper) * 2).length > 0) {
            items = items.map((k) => {
              const updated = { ...k }
              if (getPositiveValue(updated.itm_det.rt) === getPositiveValue(e.taxdetail[0].taxper) * 2) {
                updated.itm_det.txval = getPositiveValue(updated.itm_det.txval) + getPositiveValue(e.taxable_amt)
                updated.itm_det.samt = getPositiveValue(updated.itm_det.samt) + getPositiveValue(e.taxdetail[0].taxamount) * 2
                updated.itm_det.camt = getPositiveValue(updated.itm_det.camt) + getPositiveValue(e.taxdetail[0].taxamount) * 2
              }
              return updated
            })
          } else {
            items.push({
              num: items.length + 1,
              itm_det: {
                rt: getPositiveValue(e.taxdetail[0].taxper) * 2,
                txval: getPositiveValue(e.taxable_amt),
                samt: getPositiveValue(e.taxdetail[0].taxamount) * 2,
                camt: getPositiveValue(e.taxdetail[0].taxamount) * 2,
                csamt: 0,
              },
            })
          }
        }
      }
    }
  })

  const returnValue = {
    nt_num: invDetail.billno,
    nt_dt: DatetoDMY(invDetail.date),
    ntty: invDetail.invoicetype === "S" ? "C" : "D",
    val: getPositiveValue(invDetail.netamount),
    pos: invDetail.placeof_supplyId,
    rchrg: invDetail.reversable_charge,
    // "inv_typ": invDetail.invoiceType === 'Regular' ? 'R' : '',
    inv_typ: "R",
    itms: items,
  }
  return returnValue
}

export const downloadGSTR1JSON = ({ data, to_date, from_date, functions, gstr1_version, formValues, quarter_months }) => {
  const { getAccountSessionUserData, user_data, dd_taxcode, downloadJSONFile } = functions

  const GSTIN = getAccountSessionUserData("gstin", user_data)
  let isIFF = false
  if (formValues && formValues.quarter_period && quarter_months) {
    let period = getdropdownfieldvalue({ dropDownData: quarter_months, field: "id", value: formValues.quarter_period, displayvalue: "code" })
    isIFF = checkCondition(period, [1, 2])
  }
  var ret_period = to_date.split("-")
  ret_period = `${ret_period[1]}${ret_period[0]}`
  var error = ""
  var b2bData = generateB2BData(data, dd_taxcode)
  var hsnData = generateHSNData(data, functions)
  var cdnrData = generateCDNRData(data, dd_taxcode)
  var cdnurData = generateCDNURData(data, dd_taxcode)
  var expData = generateExpData(data)
  var nilData = generateNilData(data, functions)
  var b2csData = generateB2CSData(data, functions)
  var b2clData = generateB2CLData(data, functions)
  const jsonData = {
    gstin: GSTIN,
    fp: ret_period,
    version: gstr1_version,
    hash: "hash",
  }
  if (b2bData.length > 0) {
    jsonData.b2b = b2bData
  }
  if (hsnData.length > 0) {
    if (!isIFF) {
      jsonData.hsn = {
        data: hsnData,
      }
    }
  }
  if (b2csData.length > 0) {
    if (!isIFF) {
      jsonData.b2cs = b2csData
    }
  }
  if (b2clData.length > 0) {
    if (!isIFF) {
      jsonData.b2cl = b2clData
    }
  }

  if (cdnrData.length > 0) {
    jsonData.cdnr = cdnrData
  }

  if (cdnurData.length > 0) {
    if (!isIFF) {
      jsonData.cdnur = cdnurData
    }
  }
  if (expData.length > 0) {
    if (!isIFF) {
      jsonData.exp = expData
    }
  }
  if (nilData.length > 0) {
    if (!isIFF) {
      jsonData.nil = nilData
    }
  }

  const currentDate = new Date()
  var currentMonth = currentDate.getMonth()
  currentMonth = currentMonth + 1
  if (currentMonth < 10) {
    currentMonth = `0${currentMonth}`
  }
  const fileName = `returns_${currentDate.getDate()}${currentMonth}${currentDate.getFullYear()}_R1_${GSTIN}_offline`

  downloadJSONFile(jsonData, fileName)
}

export const generateGSTR1Excel = ({ data, quarter_months, formValues, functions }) => {
  const { getquarter, getAccountSitesetting, user_data } = functions
  const gst_report_range = getAccountSitesetting("gst_report_range", user_data)

  const from_date = getdropdownfieldvalue({ dropDownData: getquarter(gst_report_range === 1 ? "Q" : "M"), field: "id", value: formValues.quarterSelectedValue, displayvalue: "startdate" })
  const to_date = getdropdownfieldvalue({ dropDownData: getquarter(gst_report_range === 1 ? "Q" : "M"), field: "id", value: formValues.quarterSelectedValue, displayvalue: "enddate" })

  let isIFF = false
  if (formValues && formValues.quarter_period && quarter_months) {
    let period = getdropdownfieldvalue({ dropDownData: quarter_months, field: "id", value: formValues.quarter_period, displayvalue: "code" })
    isIFF = checkCondition(period, [1, 2])
  }

  const { exportAsExcelSheets } = functions
  let SheetNames = ["b2b", "b2cl", "b2cs", "cdnr", "cdnur", "exp", "at", "atadj", "exemp", "hsn"]
  if (isIFF) {
    SheetNames = ["b2b", "cdnr"]
  }

  var Sheets = {
    b2b: {},
    cdnr: {},
  }

  if (!isIFF) {
    Sheets.b2cl = {}
    Sheets.b2cs = {}
    Sheets.cdnur = {}
    Sheets.exp = {}
    Sheets.at = {}
    Sheets.atadj = {}
    Sheets.exemp = {}
    Sheets.hsn = {}
  }

  const B2B = data.find((e) => e.btype === "B2B")
  const B2CL = data.find((e) => e.btype === "B2CL")
  const CDNR = data.find((e) => e.btype === "CDNR")
  const B2CS = data.find((e) => e.btype === "B2CS")
  const CDNUR = data.find((e) => e.btype === "CDNUR")
  const Export_Invoices = data.find((e) => e.btype === "Export_Invoices")
  const NIL = data.find((e) => e.btype === "NIL")
  const HSN = data.find((e) => e.btype === "HSN")

  if (!isblank(B2B)) {
    /* CONVERT Regular to 
    Regular B2B
    SEZ supplies with payment
    SEZ supplies without payment
    Deemed Exp
    Intra-State supplies attracting IGST
    */
    B2B.detailData.records.list = B2B.detailData.records.list.map((e, index) => {
      const row = { ...e }
      if (row.invoiceType === "Regular") {
        row.invoiceType = "Regular B2B"
      }
      return row
    })

    Sheets = generateDataForGSTR1Excel({ newdata: B2B.detailData.records.list, type: "b2b", Sheets: cloneObj(Sheets), functions })
  } else {
    Sheets = generateDataForGSTR1Excel({ newdata: [], type: "b2b", Sheets: cloneObj(Sheets), functions })
  }

  if (!isIFF) {
    if (!isblank(B2CL)) {
      Sheets = generateDataForGSTR1Excel({ newdata: B2CL.detailData.records.list, type: "b2cl", Sheets: cloneObj(Sheets), functions })
    } else {
      Sheets = generateDataForGSTR1Excel({ newdata: [], type: "b2cl", Sheets: cloneObj(Sheets), functions })
    }
  }

  if (!isIFF) {
    if (!isblank(B2CS)) {
      Sheets = generateDataForGSTR1Excel({ newdata: B2CS.detailData.records.list, type: "b2cs", Sheets: cloneObj(Sheets), functions })
    } else {
      Sheets = generateDataForGSTR1Excel({ newdata: [], type: "b2cs", Sheets: cloneObj(Sheets), functions })
    }
  }

  if (!isblank(CDNR)) {
    Sheets = generateDataForGSTR1Excel({ newdata: CDNR.detailData.records.list, type: "cdnr", Sheets: cloneObj(Sheets), functions })
  } else {
    Sheets = generateDataForGSTR1Excel({ newdata: [], type: "cdnr", Sheets: cloneObj(Sheets), functions })
  }

  if (!isIFF) {
    if (!isblank(CDNUR)) {
      Sheets = generateDataForGSTR1Excel({ newdata: CDNUR.detailData.records.list, type: "cdnur", Sheets: cloneObj(Sheets), functions })
    } else {
      Sheets = generateDataForGSTR1Excel({ newdata: [], type: "cdnur", Sheets: cloneObj(Sheets), functions })
    }
  }

  if (!isIFF) {
    if (!isblank(NIL)) {
      Sheets = generateDataForGSTR1Excel({ newdata: NIL.detailData.records.list, type: "exemp", Sheets: cloneObj(Sheets), functions })
    } else {
      Sheets = generateDataForGSTR1Excel({ newdata: [], type: "exemp", Sheets: cloneObj(Sheets), functions })
    }
    if (!isblank(HSN)) {
      Sheets = generateDataForGSTR1Excel({ newdata: HSN.detailData.records.list, type: "hsn", Sheets: cloneObj(Sheets), functions })
    } else {
      Sheets = generateDataForGSTR1Excel({ newdata: [], type: "hsn", Sheets: cloneObj(Sheets), functions })
    }
    if (!isblank(Export_Invoices)) {
      Sheets = generateDataForGSTR1Excel({ newdata: Export_Invoices.detailData.records.list, type: "exp", Sheets: cloneObj(Sheets), functions })
    } else {
      Sheets = generateDataForGSTR1Excel({ newdata: [], type: "exp", Sheets: cloneObj(Sheets), functions })
    }
    Sheets = generateDataForGSTR1Excel({ newdata: [], type: "at", Sheets: cloneObj(Sheets), functions })
    Sheets = generateDataForGSTR1Excel({ newdata: [], type: "atadj", Sheets: cloneObj(Sheets), functions })
  }

  /* RENAME SHEETS NAME AND CHANGE INVOICE TYPE */
  Object.defineProperty(Sheets, "b2b,sez,de", Object.getOwnPropertyDescriptor(Sheets, "b2b"))
  delete Sheets["b2b"]
  var index = SheetNames.indexOf("b2b")
  if (index !== -1) {
    SheetNames[index] = "b2b,sez,de"
  }

  const fileName = `gstr1-${from_date}-to-${to_date}`
  exportAsExcelSheets({ fileName, SheetNames, Sheets })
}

const getExpShippDetail = (data, key) => {
  if (!isblank(data) && typeof data === "string") {
    const expShippDetail = JSON.parse(data)
    return !isblank(expShippDetail[key]) ? expShippDetail[key] : ""
  }
  return ""
}

const generateDataForGSTR1Excel = ({ newdata, type, Sheets, functions }) => {
  const title = {
    title1: " ",
    title2: " ",
  }
  const { getAccountSitesetting, user_data } = functions
  var data = newdata
  if (type === "cdnr" || type === "cdnur") {
    data = data.map((e) => {
      const updated = { ...e }
      updated.netamount = getPositiveValue(updated.netamount)
      updated.taxable_amt = getPositiveValue(updated.taxable_amt)
      updated.cess_amount = getPositiveValue(updated.cess_amount)
      return updated
    })
  }

  var columnDataB2B = [
    { id: "gstin", validateGST: true, numeric: false, total: "label", visible: true, label: "GSTIN/UIN of Recipient", width: 200 },
    { id: "party_name", validateGST: true, numeric: false, total: "label", visible: true, label: "Receiver Name", width: 200 },
    { id: "billno", numeric: false, visible: true, label: "Invoice Number", width: 100 },
    { id: "date", numeric: false, visible: true, format: "dmmyy", label: "Invoice date", width: 150 },
    { id: "netamount", numeric: true, total: true, visible: true, currencySign: true, label: `Invoice Value`, width: 150, format: "indian_rupee" },
    { id: "placeof_supply", numeric: false, visible: true, label: "Place Of Supply" },
    { id: "reversable_charge", numeric: false, visible: true, label: "Reverse Charge" },
    { id: "applicable_rate", numeric: true, visible: true, label: "Applicable % of Tax Rate", width: 100 },
    { id: "invoiceType", numeric: false, visible: true, label: "Invoice Type" },
    { id: "e_commerce_gstin", numeric: false, visible: true, label: "E-Commerce GSTIN" },
    { id: "tax_rate", numeric: true, visible: true, label: "Rate", format: "indian_rupee" },
    { id: "taxable_amt", numeric: true, visible: true, label: "Taxable Value", format: "indian_rupee" },
    { id: "cess_amount", numeric: true, visible: true, label: "Cess Amount", format: "indian_rupee" },
  ]

  var columnDataB2CL = [
    { id: "billno", numeric: false, visible: true, label: "Invoice Number", width: 100 },
    { id: "date", numeric: false, visible: true, format: "dmmyy", label: "Invoice date", width: 150 },
    { id: "netamount", numeric: true, total: true, visible: true, currencySign: true, label: `Invoice Value`, width: 150, format: "indian_rupee" },
    { id: "placeof_supply", numeric: false, visible: true, label: "Place Of Supply" },
    { id: "applicable_rate", numeric: true, visible: true, label: "Applicable % of Tax Rate", width: 100 },
    { id: "tax_rate", numeric: true, visible: true, label: "Rate", format: "indian_rupee" },
    { id: "taxable_amt", numeric: true, visible: true, label: "Taxable Value", format: "indian_rupee" },
    { id: "cess_amount", numeric: true, visible: true, label: "Cess Amount", format: "indian_rupee" },
    { id: "e_commerce_gstin", numeric: false, visible: true, label: "E-Commerce GSTIN" },
  ]

  var columnDataB2CS = [
    { id: "type", numeric: false, visible: true, label: "Type", width: 100 },
    { id: "placeof_supply", numeric: false, visible: true, label: "Place Of Supply" },
    { id: "applicable_rate", numeric: true, visible: true, label: "Applicable % of Tax Rate", width: 100 },
    { id: "tax_rate", numeric: true, visible: true, label: "Rate", format: "indian_rupee" },
    { id: "taxable_amt", numeric: true, visible: true, label: "Taxable Value", format: "indian_rupee" },
    { id: "cess_amount", numeric: true, visible: true, label: "Cess Amount", format: "indian_rupee" },
    { id: "e_commerce_gstin", numeric: false, visible: true, label: "E-Commerce GSTIN" },
  ]

  var columnDataCDNR = [
    { id: "gstin", validateGST: true, numeric: false, total: "label", visible: true, label: "GSTIN/UIN of Recipient", width: 200 },
    { id: "party_name", validateGST: true, numeric: false, total: "label", visible: true, label: "Receiver Name", width: 200 },
    { id: "billno", numeric: false, visible: true, label: "Note Number", width: 100 },
    { id: "date", numeric: false, visible: true, format: "dmmyy", label: "Note Date", width: 150 },
    { id: "note_type", numeric: false, visible: true, label: "Note Type", width: 100 },
    { id: "placeof_supply", numeric: false, visible: true, label: "Place Of Supply", width: 100 },
    { id: "reversable_charge", numeric: false, visible: true, label: "Reverse Charge" },
    { id: "note_supply_type", numeric: false, visible: true, label: "Note Supply Type", width: 100 },
    { id: "netamount", numeric: true, total: true, visible: true, currencySign: true, label: `Note Value`, width: 150, format: "indian_rupee" },
    { id: "applicable_rate", numeric: true, visible: true, label: "Applicable % of Tax Rate", width: 100 },
    { id: "tax_rate", numeric: true, visible: true, label: "Rate", width: 100 },
    { id: "taxable_amt", numeric: true, visible: true, label: "Taxable Value", width: 100 },
    { id: "cess_amount", numeric: true, visible: true, label: "Cess Amount", width: 100 },
  ]

  var columnDataCDNUR = [
    { id: "ur_type", numeric: false, visible: true, label: "UR Type", width: 100 },
    { id: "billno", numeric: false, visible: true, label: "Note Number", width: 100 },
    { id: "date", numeric: false, visible: true, format: "dmmyy", label: "Note Date", width: 150 },
    { id: "note_type", numeric: false, visible: true, label: "Note Type", width: 100 },
    { id: "placeof_supply", numeric: false, visible: true, label: "Place Of Supply", width: 100 },
    { id: "netamount", numeric: true, total: true, visible: true, currencySign: true, label: `Note Value`, width: 150, format: "indian_rupee" },
    { id: "applicable_rate", numeric: true, visible: true, label: "Applicable % of Tax Rate", width: 100 },
    { id: "tax_rate", numeric: true, visible: true, label: "Rate", width: 100 },
    { id: "taxable_amt", numeric: true, visible: true, label: "Taxable Value", width: 100 },
    { id: "cess_amount", numeric: true, visible: true, label: "Cess Amount", width: 100 },
  ]

  var columnDataExempt = [
    { id: "description", numeric: false, visible: true, label: "Description", width: 100 },
    { id: "nil_amt", numeric: true, visible: true, label: "Nil Rated Supplies", width: 100 },
    { id: "expt_amt", numeric: true, visible: true, label: "Exempted(other than nil rated/non GST supply)", width: 100 },
    { id: "ngsup_amt", numeric: true, visible: true, label: "Non-GST Supplies", width: 100 },
  ]

  var columnDataHSN = [
    { id: "hsn_key", numeric: false, visible: true, label: "HSN" },
    { id: "description", numeric: false, visible: true, label: "Description" },
    { id: "uqc", numeric: false, visible: true, label: "UQC" },
    { id: "tot_qty", numeric: true, visible: true, label: "Total Quantity", format: "qty_decimal" },
    { id: "amount", numeric: true, visible: true, label: "Total Value", format: "indian_rupee" },
    { id: "rate", numeric: true, visible: true, label: "Rate", width: 100, format: "indian_rupee" },
    { id: "netamount", numeric: true, visible: true, label: "Taxable Value", width: 100, format: "indian_rupee" },
    { id: "igst_amount", numeric: true, visible: true, label: "Integrated Tax Amount", width: 100, format: "indian_rupee" },
    { id: "cgst_amount", numeric: true, visible: true, label: "Central Tax Amount", width: 100, format: "indian_rupee" },
    { id: "sgst_amount", numeric: true, visible: true, label: "State/UT Tax Amount", width: 100, format: "indian_rupee" },
    { id: "cess_amount", numeric: true, visible: true, label: "Cess Amount", width: 100, format: "indian_rupee" },
  ]

  var columnDataExportInv = [
    { id: "exp_typ", numeric: false, visible: true, label: "Export Type", width: 100 },
    { id: "billno", numeric: false, visible: true, label: "Invoice Number", width: 100 },
    { id: "date", numeric: false, visible: true, label: "Invoice date", format: "dmmyy", width: 100 },
    { id: "netamount", numeric: false, visible: true, label: "Invoice Value", width: 100 },
    { id: "shipping_portcode", numeric: false, visible: true, label: "Port Code", width: 100 },
    { id: "shipp_bill_no", numeric: false, visible: true, label: "Shipping Bill Number", width: 100 },
    { id: "shipp_bill_date", numeric: false, visible: true, label: "Shipping Bill Date", format: "dmmyy", width: 100 },
    { id: "tax_rate", numeric: true, visible: true, label: "Rate", width: 100 },
    { id: "taxable_amt", numeric: true, visible: true, label: "Taxable Value", width: 100 },
    { id: "cess_amount", numeric: true, visible: true, label: "Cess Amount", width: 100 },
  ]
  var columnDataAT = [
    { id: "placeof_supply", numeric: false, visible: true, label: "Place Of Supply", width: 100 },
    { id: "applicable_rate", numeric: true, visible: true, label: "Applicable % of Tax Rate", width: 100 },
    { id: "tax_rate", numeric: true, visible: true, label: "Rate", width: 100 },
    { id: "Gross_advance_received", numeric: true, visible: true, label: "Gross Advance Received", width: 100 },
    { id: "cess_amount", numeric: true, visible: true, label: "Cess Amount", width: 100 },
  ]

  var columnDataATADJ = [
    { id: "placeof_supply", numeric: false, visible: true, label: "Place Of Supply", width: 100 },
    { id: "applicable_rate", numeric: true, visible: true, label: "Applicable % of Tax Rate", width: 100 },
    { id: "tax_rate", numeric: true, visible: true, label: "Rate", width: 100 },
    { id: "Gross_advance_received", numeric: true, visible: true, label: "Gross Advance Adjusted", width: 100 },
    { id: "cess_amount", numeric: true, visible: true, label: "Cess Amount", width: 100 },
  ]

  var returnData = []
  const totalInvoiceValue = arraySum(data, "netamount")

  if (type === "exemp") {
    const dataforExempt = [
      {
        btype: "NIL",
        detailData: {
          records: {
            list: data,
          },
        },
      },
    ]
    const exemptData = [generateNilData(dataforExempt, functions)]

    if (!isblank(exemptData[0].inv)) {
      returnData = exemptData[0].inv.map((e) => {
        const exemp = {
          nil_amt: e.nil_amt,
          expt_amt: e.expt_amt,
          ngsup_amt: e.ngsup_amt,
        }
        if (e.sply_ty === "INTRB2B") {
          exemp.description = "Inter-State supplies to registered persons"
        } else if (e.sply_ty === "INTRAB2B") {
          exemp.description = "Intra-State supplies to registered persons"
        } else if (e.sply_ty === "INTRB2C") {
          exemp.description = "Inter-State supplies to unregistered persons"
        } else if (e.sply_ty === "INTRAB2C") {
          exemp.description = "Intra-State supplies to unregistered persons"
        }
        return exemp
      })
    } else {
      returnData = []
    }
  } else if (type === "hsn") {
    returnData = data.map((e) => {
      const updated = { ...e }
      updated.description = ""
      return updated
    })
  } else {
    data.forEach((e) => {
      var taxArray = []
      e.invoiceDetail.forEach((i) => {
        const taxRate = getTaxRate(i.taxdetail)
        if (taxArray.filter((j) => j.tax === taxRate).length > 0) {
          taxArray = taxArray.map((j) => {
            const updated = { ...j }
            if (updated.tax === taxRate) {
              updated.taxable_amt = parseFloat(getNumberonly(updated.taxable_amt)) + parseFloat(getNumberonly(i.taxable_amt))
            }
            return updated
          })
        } else {
          taxArray.push({ tax: parseFloat(getNumberonly(taxRate)), taxable_amt: parseFloat(getNumberonly(i.taxable_amt)) })
        }
      })
      taxArray.forEach((i) => {
        const NewItem = cloneObj(e)
        NewItem.netamount = parseFloat(getNumberonly(NewItem.netamount))
        NewItem.tax_rate = i.tax
        NewItem.taxable_amt = i.taxable_amt
        NewItem.cess_amount = 0.0
        if (type === "b2cs") {
          NewItem.type = "OE"
        } else if (type === "cdnr" || type === "cdnur") {
          NewItem.note_type = NewItem.invType === "S" || NewItem.invoicetype === "S" ? "C" : "D"
        }
        if (type === "cdnur") {
          NewItem.ur_type = "B2CL"
        }
        if (type === "cdnr" || type === "b2b") {
          NewItem.party_name = NewItem.party_detail.name
          if (type === "cdnr") {
            NewItem.note_supply_type = "Regular"
          }
        }
        returnData.push(NewItem)
      })
    })
  }

  if (type === "b2cs") {
    var tempArray = []
    returnData.forEach((e) => {
      if (tempArray.filter((i) => i.placeof_supply === e.placeof_supply && i.tax_rate === e.tax_rate).length > 0) {
        tempArray = tempArray.map((i) => {
          const updated = { ...i }
          if (updated.placeof_supply === e.placeof_supply && updated.tax_rate === e.tax_rate) {
            //NOTE: NEGATIVE TAX taxable_amt

            if (e.amount < 0) {
              updated.taxable_amt = getFileValue(getFileValue(updated.taxable_amt) + changeSign(getFileValue(e.taxable_amt)))
              updated.cess_amount = getFileValue(getFileValue(updated.cess_amount) + changeSign(getFileValue(e.cess_amount)))
            } else {
              updated.taxable_amt = getFileValue(getFileValue(updated.taxable_amt) + getFileValue(e.taxable_amt))
              updated.cess_amount = getFileValue(getFileValue(updated.cess_amount) + getFileValue(e.cess_amount))
            }
          }
          return updated
        })
      } else {
        const updated = { ...e }

        if (e.amount < 0) {
          updated.taxable_amt = changeSign(getFileValue(e.taxable_amt))
          updated.cess_amount = changeSign(getFileValue(e.cess_amount))
        } else {
          updated.taxable_amt = getFileValue(e.taxable_amt)
          updated.cess_amount = getFileValue(e.cess_amount)
        }
        tempArray.push(updated)
      }
    })
    returnData = tempArray
  }
  if (type === "exp") {
    returnData = returnData.map((e) => {
      const updated = { ...e }
      updated.shipping_portcode = getExpShippDetail(e.expShippDetail, "shippingport")
      updated.shipp_bill_no = getExpShippDetail(e.expShippDetail, "shippingNo")
      updated.shipp_bill_date = getExpShippDetail(e.expShippDetail, "shippingDate")
      return updated
    })
  }

  const noOfReciepents = getUniqueCount(returnData, "gstin")
  const noOfInvoices = getUniqueCount(returnData, "billno")
  const noOfShippBill = getUniqueCount(returnData, "shipp_bill_no")

  var totalTaxable = arraySum(returnData, "taxable_amt")
  if (type === "hsn") {
    totalTaxable = arraySum(returnData, "netamount")
  }
  const totalCess = arraySum(returnData, "cess_amount")

  const totalSgst = arraySum(returnData, "sgst_amount")
  const totalCgst = arraySum(returnData, "cgst_amount")
  const totalIgst = arraySum(returnData, "igst_amount")

  const noOfHSN = getUniqueCount(returnData, "hsn_key")
  const totalValue = arraySum(returnData, "amount")

  const total_NillRated = arraySum(returnData, "nil_amt")
  const total_Exempt = arraySum(returnData, "expt_amt")
  const total_nonGST = arraySum(returnData, "ngsup_amt")

  var columnData = []
  if (type === "b2b") {
    columnData = columnDataB2B
  } else if (type === "b2cl") {
    columnData = columnDataB2CL
  } else if (type === "b2cs") {
    columnData = columnDataB2CS
  } else if (type === "cdnr") {
    columnData = columnDataCDNR
  } else if (type === "cdnur") {
    columnData = columnDataCDNUR
  } else if (type === "exemp") {
    columnData = columnDataExempt
  } else if (type === "hsn") {
    columnData = columnDataHSN
  } else if (type === "exp") {
    columnData = columnDataExportInv
  } else if (type === "at") {
    columnData = columnDataAT
  } else if (type === "atadj") {
    columnData = columnDataATADJ
  }

  returnData = makeExcelRequiredData({ data: returnData, column: columnData, functions: { getAccountSitesetting, user_data } })
  columnData = makeExcelRequireCol(columnData, getAccountSitesetting, user_data)
  Sheets[type] = generateExcelData({ csvData: returnData, title, mycol: columnData, skipIndex: [0] })

  if (type === "b2b") {
    Sheets[type].A1 = { v: "Summary For B2B(4)" }
    Sheets[type].A2 = { v: "No. of Recipients" }
    Sheets[type].A3 = { v: noOfReciepents }
    Sheets[type].C2 = { v: "No. of Invoices" }
    Sheets[type].C3 = { v: noOfInvoices }
    Sheets[type].E2 = { v: "Total Invoice Value" }
    Sheets[type].E3 = { v: totalInvoiceValue }
    Sheets[type].L2 = { v: "Total Taxable Value" }
    Sheets[type].L3 = { v: getFileValue(totalTaxable) }
    Sheets[type].M2 = { v: "Total Cess" }
    Sheets[type].M3 = { v: getFileValue(totalCess) }
  } else if (type === "b2cl") {
    Sheets[type].A1 = { v: "Summary For B2CL(5)" }
    Sheets[type].A2 = { v: "No of Invoices" }
    Sheets[type].A3 = { v: noOfInvoices }
    Sheets[type].C2 = { v: "Total Invoice Value" }
    Sheets[type].C3 = { v: getFileValue(totalInvoiceValue) }
    Sheets[type].G2 = { v: "Total Taxable Value" }
    Sheets[type].G3 = { v: getFileValue(totalTaxable) }
  } else if (type === "b2cs") {
    Sheets[type].A1 = { v: "Summary For B2CS(7)" }
    Sheets[type].E2 = { v: "Total Taxable Value" }
    Sheets[type].E3 = { v: getFileValue(totalTaxable) }
    Sheets[type].F2 = { v: "Total Cess" }
    Sheets[type].F3 = { v: getFileValue(totalCess) }
  } else if (type === "cdnr") {
    Sheets[type].A1 = { v: "Summary For CDNR(9B)" }
    Sheets[type].A2 = { v: "No. of Recipients" }
    Sheets[type].A3 = { v: noOfReciepents }
    Sheets[type].C2 = { v: "No. of Notes" }
    Sheets[type].C3 = { v: noOfInvoices }
    Sheets[type].I2 = { v: "Total Note Value" }
    Sheets[type].I3 = { v: getFileValue(totalInvoiceValue) }
    Sheets[type].L2 = { v: "Total Taxable Value" }
    Sheets[type].L3 = { v: getFileValue(totalTaxable) }
    Sheets[type].M2 = { v: "Total Cess" }
    Sheets[type].M3 = { v: getFileValue(totalCess) }
  } else if (type === "cdnur") {
    Sheets[type].A1 = { v: "Summary For CDNUR(9B)" }
    Sheets[type].B2 = { v: "No. of Notes/Vouchers" }
    Sheets[type].B3 = { v: noOfInvoices }
    Sheets[type].F2 = { v: "Total Note Value" }
    Sheets[type].F3 = { v: getFileValue(totalInvoiceValue) }
    Sheets[type].I2 = { v: "Total Taxable Value" }
    Sheets[type].I3 = { v: getFileValue(totalTaxable) }
    Sheets[type].J2 = { v: "Total Cess" }
    Sheets[type].J3 = { v: getFileValue(totalCess) }
  } else if (type === "exemp") {
    Sheets[type].A1 = { v: "Summary For Nil rated, exempted and non GST outward supplies (8)" }
    Sheets[type].B2 = { v: "Total Nil Rated Supplies" }
    Sheets[type].B3 = { v: getFileValue(total_NillRated) }
    Sheets[type].C2 = { v: "Total Exempted Supplies" }
    Sheets[type].C3 = { v: getFileValue(total_Exempt) }
    Sheets[type].D2 = { v: "Total Non-GST Supplies" }
    Sheets[type].D3 = { v: getFileValue(total_nonGST) }
  } else if (type === "hsn") {
    Sheets[type].A1 = { v: "Summary For HSN(12)" }

    Sheets[type].A2 = { v: "No. of HSN" }
    Sheets[type].A3 = { v: noOfHSN }

    Sheets[type].E2 = { v: "Total Value" }
    Sheets[type].E3 = { v: getFileValue(totalValue) }

    Sheets[type].G2 = { v: "Total Taxable Value" }
    Sheets[type].G3 = { v: getFileValue(totalTaxable) }

    Sheets[type].H2 = { v: "Total Integrated Tax" }
    Sheets[type].H3 = { v: getFileValue(totalIgst) }

    Sheets[type].I2 = { v: "Total Central Tax" }
    Sheets[type].I3 = { v: getFileValue(totalCgst) }

    Sheets[type].J2 = { v: "Total State/UT Tax" }
    Sheets[type].J3 = { v: getFileValue(totalSgst) }

    Sheets[type].K2 = { v: "Total Cess" }
    Sheets[type].K3 = { v: getFileValue(totalCess) }
  } else if (type === "exp") {
    Sheets[type].A1 = { v: "Summary For EXP(6)" }
    Sheets[type].B2 = { v: "No. of Invoices" }
    Sheets[type].B3 = { v: noOfInvoices }
    Sheets[type].D2 = { v: "Total Invoice Value" }
    Sheets[type].D3 = { v: getFileValue(totalValue) }
    Sheets[type].F2 = { v: "No. of Shipping Bill" }
    Sheets[type].F3 = { v: noOfShippBill }
    Sheets[type].J2 = { v: "Total Taxable Value" }
    Sheets[type].J3 = { v: getFileValue(totalTaxable) }
  } else if (type === "at") {
    Sheets[type].A1 = { v: "Summary For Advance Received (11B)" }
    Sheets[type].D2 = { v: "Total Advance Received" }
    Sheets[type].D3 = { v: getFileValue(total_NillRated) }
    Sheets[type].E2 = { v: "Total Cess" }
    Sheets[type].E3 = { v: getFileValue(total_Exempt) }
  } else if (type === "atadj") {
    Sheets[type].A1 = { v: "Summary For Advance Adjusted (11B)" }
    Sheets[type].D2 = { v: "Total Advance Adjusted" }
    Sheets[type].D3 = { v: getFileValue(total_NillRated) }
    Sheets[type].E2 = { v: "Total Cess" }
    Sheets[type].E3 = { v: getFileValue(total_Exempt) }
  }

  return Sheets
}

const getUniqueCount = (itemArray, key) => {
  const countArray = []
  itemArray.forEach((e) => {
    if (countArray.filter((i) => i === e[key]).length === 0) {
      countArray.push(e[key])
    }
  })
  return countArray.length
}

export const generateGSTR3BExcel = ({ data, to_date, from_date, functions }) => {
  const { exportAsExcelSheets } = functions
  const SheetNames = ["outward_supplies", "interstate_supplies", "itc", "exempt_supplies"]
  var Sheets = {
    outward_supplies: {},
    interstate_supplies: {},
    itc: {},
    exempt_supplies: {},
  }
  Sheets = generateDataForGSTR3BExcel({ data: data.outward_supplies, type: "outward_supplies", Sheets: cloneObj(Sheets), functions })
  Sheets = generateDataForGSTR3BExcel({ data: data.interstate_supplies, type: "interstate_supplies", Sheets: cloneObj(Sheets), functions })
  Sheets = generateDataForGSTR3BExcel({ data: data.itc, type: "itc", Sheets: cloneObj(Sheets), functions })
  Sheets = generateDataForGSTR3BExcel({ data: data.exempt_supplies, type: "exempt_supplies", Sheets: cloneObj(Sheets), functions })
  const fileName = `gstr3b-${from_date}-to-${to_date}`
  exportAsExcelSheets({ fileName, SheetNames, Sheets })
}

{
  /*     <TableCell style={{ minWidth: 250 }}>Nature of Supplies</TableCell>
            <TableCell align="right" className={classes.w175} >Inter-State supplies</TableCell>
            <TableCell align="right" className={classes.w175}>Intra-State supplies</TableCell> */
}

const generateDataForGSTR3BExcel = ({ data, type, Sheets, functions }) => {
  const { getAccountSitesetting, user_data } = functions
  const columnData = {
    outward_supplies,
    interstate_supplies,
    itc,
    exempt_supplies,
  }
  const returnData = makeExcelRequiredData({ data, column: columnData[type], functions: { getAccountSitesetting, user_data } })
  const typeWiseColumnData = makeExcelRequireCol(columnData[type], getAccountSitesetting, user_data)
  Sheets[type] = generateExcelData({ csvData: returnData, mycol: typeWiseColumnData, skipIndex: [0] })
  return Sheets
}

export const generateGSTRSummaryExcel = ({ data, formValues, functions, reportdata }) => {
  const { getAccountSitesetting, exportAsExcelSheets, user_data, getquarter, getAccountSessionUserData } = functions

  var companyName = getAccountSessionUserData("company_name")
  var company_address1 = replacefunction(replaceBRtoNewLine(getAccountSessionUserData("company_address1")))
  var company_address2 = replacefunction(replaceBRtoNewLine(getAccountSessionUserData("company_address2")))

  const columnDataHSN = [
    { id: "description", numeric: false, visible: true, label: "Description" },
    { id: "tot_qty", numeric: true, visible: true, label: "Quantity", width: 100, format: "qty_decimal" },
    { id: "uqc", numeric: false, visible: true, label: "Unit Of Measure", width: 200 },
    { id: "hsn_key", numeric: false, total: "label", visible: true, label: "HSN or SAC", width: 150 },
    { id: "rate", numeric: true, visible: true, label: "Rate", width: 150, format: "indian_rupee" },
    {
      id: "netamount",
      numeric: true,
      total: true,
      currencySign: true,
      visible: true,
      label: `TAXABLE AMT `,
      aggregate: "sum",
      Aggregated: ({ value }) => `(${amount_format(value)})`,
      width: 150,
      format: "indian_rupee",
    },
    {
      id: "tax_amount",
      numeric: true,
      total: true,
      currencySign: true,
      visible: true,
      label: `TOTAL TAX `,
      aggregate: "sum",
      Aggregated: ({ value }) => `(${amount_format(value)})`,
      width: 150,
      format: "indian_rupee",
    },
    {
      id: "igst_amount",
      numeric: true,
      total: true,
      currencySign: true,
      visible: true,
      label: `IGST`,
      aggregate: "sum",
      Aggregated: ({ value }) => `(${amount_format(value)})`,
      width: 100,
      format: "indian_rupee",
    },
    {
      id: "cgst_amount",
      numeric: true,
      total: true,
      currencySign: true,
      visible: true,
      label: `CGST`,
      aggregate: "sum",
      Aggregated: ({ value }) => `(${amount_format(value)})`,
      width: 100,
      format: "indian_rupee",
    },
    {
      id: "sgst_amount",
      numeric: true,
      total: true,
      currencySign: true,
      visible: true,
      label: `SGST`,
      aggregate: "sum",
      Aggregated: ({ value }) => `(${amount_format(value)})`,
      width: 100,
      format: "indian_rupee",
    },
    {
      id: "error",
      numeric: false,
      visible: true,
      label: "Error",
      width: 150,
      badge: {
        Error: { color: "red" },
      },
      onClick: true,
    },
    {
      id: "cess",
      numeric: true,
      total: true,
      currencySign: true,
      visible: true,
      label: `Cess`,
      aggregate: "sum",
      Aggregated: ({ value }) => `(${amount_format(value)})`,
      width: 100,
      format: "indian_rupee",
    },
    {
      id: "amount",
      numeric: true,
      total: true,
      currencySign: true,
      visible: true,
      label: `Total Amount `,
      aggregate: "sum",
      Aggregated: ({ value }) => `(${amount_format(value)})`,
      width: 150,
      format: "indian_rupee",
    },
  ]

  formValues.FromDate = getdropdownfieldvalue({ dropDownData: getquarter("M"), field: "id", value: formValues.quarterSelectedValue, displayvalue: "startdate" })
  formValues.ToDate = getdropdownfieldvalue({ dropDownData: getquarter("M"), field: "id", value: formValues.quarterSelectedValue, displayvalue: "enddate" })

  const SheetNames = data.map((e) => e.label)
  var Sheets = {}

  var title = {}
  title.title1 = companyName
  title.title2 = company_address1
  title.title3 = company_address2
  title.title4 = reportdata.name
  title.title5 = `From: ${DatetoDMY(formValues.FromDate)} To: ${DatetoDMY(formValues.ToDate)}`

  data.forEach((e) => {
    var columnData = columnGSTSummary
    if (e.id === "salesReturn" || e.id === "purchaseReturn") {
      columnData = [
        ...columnData,
        { id: "ref_billno", numeric: false, visible: true, label: "Ref Billno	", width: 125 },
        { id: "ref_b_date", numeric: false, visible: true, label: "Ref Bill Date", width: 125, date: true, format: "dmy" },
      ]
    }
    if (e.id === "hsn") {
      columnData = columnDataHSN
    }
    const returnData = makeExcelRequiredData({ data: e.detailGrid, column: columnData, functions: { getAccountSitesetting, user_data } })
    columnData = makeExcelRequireCol(columnData, getAccountSitesetting, user_data)

    /*total caluculated */
    var obj = {}
    columnData.forEach((colItem) => {
      obj[colItem.id] = ""
      if (colItem.total === "label") {
        obj[colItem.id] = "Total"
      } else if (colItem.total) {
        obj[colItem.id] = parseFloat(lodashSum(lodashMap(returnData, (d) => parseFloat(getNumberonly(d[colItem.id])))))
      }
    })

    returnData.push(obj)

    Sheets[e.label] = generateExcelData({ csvData: returnData, title, mycol: columnData, skipIndex: [0], headerIndex: 0, total_Index: [returnData.length - 1] })
  })
  const fileName = `gst_summary-${DatetoDMY(formValues.FromDate)}-to-${DatetoDMY(formValues.ToDate)}`
  exportAsExcelSheets({ fileName, SheetNames, Sheets })
}
