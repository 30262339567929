import { amount_format, DatetoDMY, isblank, replaceBRtoNewLine, replacefunction } from "./utilityFunctions"

export default ({ printdata, user_data, functions }) => {
  const { getAccountSessionUserData, getAccountSitesetting } = functions

  let journalentry_heading = getAccountSitesetting("journalentry_heading")
  let journalentry_terms = getAccountSitesetting("journalentry_terms")

  var companyName = getAccountSessionUserData("company_name", user_data)
  var aliasName = getAccountSessionUserData("company_alias", user_data)
  var company_address1 = replacefunction(replaceBRtoNewLine(getAccountSessionUserData("company_address1", user_data)))
  var company_street = replacefunction(replaceBRtoNewLine(getAccountSessionUserData("company_street", user_data)))
  var company_address2 = replacefunction(replaceBRtoNewLine(getAccountSessionUserData("company_address2", user_data)))

  var invoice_template = {}
  invoice_template.topheaderheight = 25
  invoice_template.bottomheaderheight = 20
  invoice_template.headerheight = 95
  invoice_template.topheader = {}
  invoice_template.header = {}
  invoice_template.body = {}
  invoice_template.footer = {}

  var HeaderObject = generateDetailHeaders(getAccountSitesetting, user_data)
  var Headers = HeaderObject.Headers
  var widthArray = HeaderObject.widthArray
  var Values = generateDetailValues(printdata)

  if (isblank(journalentry_heading)) {
    journalentry_heading = "Journal"
  }

  if (!isblank(journalentry_terms)) {
    journalentry_terms = {
      stack: [
        {
          text: "Terms & Conditions",
          bold: true,
        },
        {
          text: journalentry_terms,
          fontSize: 7,
        },
      ],
      margin: [0, 5, 0, 0],
      unbreakable: true,
    }
  } else {
    journalentry_terms = {}
  }

  if (isblank(company_street)) {
    company_street = ""
  }
  if (isblank(company_address2)) {
    company_address2 = ""
  }
  company_address2 = `${company_street} ${company_address2}`

  var dd = {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [15, invoice_template.headerheight, 15, 20],

    header: [
      { text: companyName, style: "companyName", bold: true },
      { text: aliasName, style: "companyAddress", bold: true },
      { text: company_address1, style: "companyAddress", bold: true },
      { text: company_address2, style: "companyAddress", bold: true },
      { text: journalentry_heading, style: "companyAddress", bold: true },
    ],
    footer: [],
    content: [
      { text: `Journal: #${printdata.docno}`, bold: true },
      { text: `Date: ${DatetoDMY(printdata.date)}`, bold: true, margin: [0, 2, 0, 5] },
      {
        table: {
          headerRows: 1,
          widths: widthArray,
          body: [Headers, ...Values],
        },
        layout: {
          hLineWidth: function (i, node) {
            return 1
          },
          vLineWidth: function (i, node) {
            return 1
          },
        },
      },
      journalentry_terms,
    ],

    defaultStyle: {
      fontSize: 8,
    },
    styles: {
      center: {
        alignment: "center",
      },
      right: {
        alignment: "right",
      },
      left: {
        alignment: "left",
      },
      companyAddress: {
        alignment: "center",
        fontSize: 10,
      },
      companyName: {
        bold: true,
        fontSize: 12,
        alignment: "center",
        margin: [0, 15, 0, 0],
      },
    },
  }
  return dd
}

// Narrationitem

const generateDetailHeaders = (getAccountSitesetting, user_data) => {
  const currency = getAccountSitesetting("currency", user_data)
  var HeaderObject = {}
  HeaderObject.widthArray = [20, "*", "*", 70, 70]
  HeaderObject.Headers = [
    {
      text: "#",
      style: ["itemsHeader", ""],
      fillColor: "#ffffff",
      bold: true,
    },
    {
      text: "Account Name",
      style: ["itemsHeader", ""],
      bold: true,
      fillColor: "#ffffff",
    },
    {
      text: "Narration",
      style: ["itemsHeader", ""],
      bold: true,
      fillColor: "#ffffff",
    },
    {
      text: `Debit(${currency})`,
      alignment: "right",
      bold: true,
      fillColor: "#ffffff",
    },
    {
      text: `Credit(${currency})`,
      alignment: "right",
      bold: true,
      fillColor: "#ffffff",
    },
  ]
  return HeaderObject
}

const generateDetailValues = (data) => {
  var Values = []
  if (!isblank(data.DetailGrid) && data.DetailGrid.length > 0) {
    data.DetailGrid.forEach((item, index) => {
      var singleValue = [
        {
          text: index + 1,
          alignment: "center",
        },
        {
          text: item.accountName,
          alignment: "left",
        },
        {
          text: item.Narrationitem,
        },
        {
          text: amount_format(item.debit),
          alignment: "right",
        },
        {
          text: amount_format(item.credit),
          alignment: "right",
        },
      ]
      Values.push(singleValue)
    })
    var totalValue = [
      {
        text: "",
      },
      {
        text: "",
      },
      {
        text: "Total Amount",
        bold: true,
        alignment: "right",
      },
      {
        text: amount_format(data.debitamt),
        bold: true,
        alignment: "right",
      },
      {
        text: amount_format(data.creditamt),
        bold: true,
        alignment: "right",
      },
    ]
    Values.push(totalValue)
  }
  return Values
}
