import { Link } from "@material-ui/core"
import React, { useState } from "react"

function DebounceLink({ children, style, onClick, time, ...rest }) {
  const [isDebouncing, setIsDebouncing] = useState(false)
  const timing = time ? time : 1000

  const handleClick = (e) => {
    if (isDebouncing) {
      e.preventDefault()
      return true
    } else {
      setIsDebouncing(true)
      setTimeout(() => {
        setIsDebouncing(false)
      }, timing) // Adjust the time (in milliseconds) as needed
      if (onClick) {
        onClick(e)
      }
    }
  }

  return (
    <Link {...rest} onClick={handleClick} style={{ pointerEvents: isDebouncing ? "none" : "auto", ...style }}>
      {children}
    </Link>
  )
}

export default DebounceLink
