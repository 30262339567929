import { Button, CircularProgress, Drawer, makeStyles } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import FallBackComponent from "@project/components/common/FallBackComponent"
import PDFViewer from "@project/components/common/PDFViewer"
import { isblank, replaceAllFn } from "@project/sharedcomponents/utilityFunctions"
import React, { lazy, Suspense } from "react"
import { isMobileOnly } from "react-device-detect"
import "react-perfect-scrollbar/dist/css/styles.css"
import { useDispatch, useSelector } from "react-redux"
import { closeAllDrawer, rightdrawerstate } from "../../Actions"
import { getAccountSitesetting } from "../../Lib/commonfunctions"

//Invoice forms
import ModalTransactionMultiple from "../ModalPages/ModalTransactionMultiple"
import ModalTransactionSingle from "../ModalPages/modalTransactionSingle"

const AddWebhookform = lazy(() => import("../ModalPages/addWebhookform"))

const gstOldPenInvcModal = lazy(() => import("../gstOldPendingInvoice/addUpdate"))

//Master forms
const CustomBarcodePrint = lazy(() => import("../../BarCodePrinting/Components/ModalPages/customBarcodePrint"))
const CustomerSupplierModal = lazy(() => import("../ModalPages/customerSupplierForm"))
const CustomerView = lazy(() => import("../ModalPages/customerView"))
const goldSaleReturn = lazy(() => import("../ModalPages/goldSaleReturn"))
const LedgerReport = lazy(() => import("../ModalPages/ledgerReport"))
const modalAddItemInBulk = lazy(() => import("../ModalPages/modalAddItemInBulk"))
const modalAddItemInBulkBarcode = lazy(() => import("../ModalPages/modalAddItemInBulkBarcode"))
const modalEwayBill = lazy(() => import("../ModalPages/modalEwayBill"))
const UpdateInvoiceSettings = lazy(() => import("../ModalPages/UpdateInvoiceSettings"))
const InwardForm = lazy(() => import("../ModalPages/modalInwardForm"))
const modalJournalEntryForm = lazy(() => import("../ModalPages/modalJournalEntryForm"))
const modalManageItemForm = lazy(() => import("../product/addUpdate"))
const OutwardForm = lazy(() => import("../ModalPages/modalOutwardForm"))
// const ModalPaymentForm = lazy(() => import("../ModalPages/modalPaymentMultiple"))

const modalPromoOption = lazy(() => import("../ModalPages/modalPromoOption"))
const promoOption = lazy(() => import("../ModalPages/promoOption"))
const modalPurchaseInvoiceForm = lazy(() => import("../ModalPages/modalPurchaseInvoiceForm"))
const modalPurchaseReturnForm = lazy(() => import("../ModalPages/modalPurchaseReturnForm"))
// const modalReceiptForm = lazy(() => import("../ModalPages/modalReceiptMultiple"))

const modalReceivePayment = lazy(() => import("../ModalPages/modalReceivePayment"))
//Transaction forms
const SalesReturnForm = lazy(() => import("../ModalPages/modalSalesReturnForm"))
const modalShippingAddress = lazy(() => import("../ModalPages/modalShippingAddress"))
const ProductDetail = lazy(() => import("../product/detail"))
//other invoice forms
const purchaseOldGold = lazy(() => import("../ModalPages/purchaseOldGold"))
const quickLinkDrawer = lazy(() => import("../ModalPages/quickLinkDrawer"))
const recentActivitiesDrawer = lazy(() => import("../ModalPages/recentActivitiesDrawer"))

const salesInvoiceForm = lazy(() => import("../ModalPages/salesInvoiceForm"))
const settingsDrawer = lazy(() => import("../ModalPages/settingsDrawer"))

const modalManageState = lazy(() => import("../ModalPages/modalManageState"))

const modalCountryForm = lazy(() => import("../ModalPages/modalCountryForm"))
const modalManageCityForm = lazy(() => import("../ModalPages/modalManageCityForm"))
const modalDiscountCouponsForm = lazy(() => import("../ModalPages/modalDiscountCoupons"))
const costCenter = lazy(() => import("../ModalPages/costCenter"))
const selectCostCenterForm = lazy(() => import("../ModalPages/selectCostCenterForm"))
const modalWireTransferForm = lazy(() => import("../ModalPages/modalWireTransferForm"))
const modalSenderForm = lazy(() => import("../ModalPages/modalSenderForm"))
const modalBeneficiaryForm = lazy(() => import("../ModalPages/modalBeneficiaryForm"))
const modalEditTaxSchemeForm = lazy(() => import("../ModalPages/modalEditTaxSchemeForm"))
const modalTaxSchemeForm = lazy(() => import("../ModalPages/modalTaxSchemeForm"))
const modalManageAccountForm = lazy(() => import("../ModalPages/modalManageAccountForm"))
const modalManageUserForm = lazy(() => import("../ModalPages/modalManageUserForm"))

const modalImportUnitForm = lazy(() => import("../ModalPages/modalImportUnitForm"))
const modalImportAccount = lazy(() => import("../ModalPages/modalImportAccount"))
const modalTransportForm = lazy(() => import("../ModalPages/modalTransportForm"))
const modalGoDownForm = lazy(() => import("../ModalPages/modalGoDownForm"))
const editCompanyForm = lazy(() => import("../ModalPages/editCompanyForm"))
const agentForm = lazy(() => import("../ModalPages/agentForm"))
const salesmanForm = lazy(() => import("../ModalPages/salesmanForm"))
const modalBotFactory = lazy(() => import("../botFactory/addUpdate"))
const modalAddRecord = lazy(() => import("../ModalPages/addRecord"))
const SelectAddressFromGSTIN = lazy(() => import("../ModalPages/SelectAddressFromGSTIN"))

const mergePackingSlip = lazy(() => import("../ModalPages/mergePackingSlip"))
const ModalCustomPage = lazy(() => import("../ModalPages/modalPage"))

const IPOStockPartyForm = lazy(() => import("../ModalPages/IPOStockPartyForm"))
const IPOStockMasterForm = lazy(() => import("../ModalPages/IPOStockMasterForm"))

const modalManagePartyGroup = lazy(() => import("../ModalPages/modalManagePartyGroup"))
const modalProductGroupForm = lazy(() => import("../ModalPages/modalProductGroupForm"))
const ModalManageProductSubGroup = lazy(() => import("../ModalPages/ModalManageProductSubGroup"))
const modalManageSubGroup = lazy(() => import("../ModalPages/modalManageSubGroup"))
const modalManageProductSubGroupLevel2 = lazy(() => import("../ModalPages/modalManageProductSubGroupLevel2"))
const IPOStockForm = lazy(() => import("../ModalPages/IPOStockForm"))
const groupSMSAddContactForm = lazy(() => import("../ModalPages/groupSMSAddContactForm"))
const groupSMSAddGroupForm = lazy(() => import("../ModalPages/groupSMSAddGroupForm"))
const groupSMSSendForm = lazy(() => import("../ModalPages/groupSMSSendForm"))
const GSTDetail = lazy(() => import("../GSTPages/GSTDetail"))

const ProcessDetail = lazy(() => import("../ModalPages/processDetail"))

const addBatchForm = lazy(() => import("../ModalPages/addBatchForm"))
const selectBatchForm = lazy(() => import("../ModalPages/selectBatchForm"))
const selectSerialForm = lazy(() => import("../ModalPages/selectSerialForm"))
const addSerialNumberForm = lazy(() => import("../ModalPages/addSerialNumberForm"))

const createLotInPurchase = lazy(() => import("../ModalPages/createLotInPurchase"))

const modalConfigProductForm = lazy(() => import("../ModalPages/modalConfigProductForm"))
const modalBundleProductForm = lazy(() => import("../ModalPages/modalBundleProductForm"))

const modalChangePasswordForm = lazy(() => import("../ModalPages/modalChangePasswordForm"))

const customFieldForm = lazy(() => import("../ModalPages/customFieldForm"))
const customPageForm = lazy(() => import("../ModalPages/customPageForm"))
const ConfigProductDetail = lazy(() => import("../ModalPages/configProductDetail"))

const gstTaxDetail = lazy(() => import("../ModalPages/gstTaxDetail"))
const modalSMSTemplate = lazy(() => import("../ModalPages/modalSMSTemplate"))
const modalEmailTemplate = lazy(() => import("../ModalPages/modalEmailTemplate"))
const adjustBill = lazy(() => import("../ModalPages/adjustBill"))
const adjustBillByBill = lazy(() => import("../ModalPages/adjustBillByBill"))
const adjustTDS = lazy(() => import("../ModalPages/adjustTDS"))

const selectPenOrder = lazy(() => import("../ModalPages/selectPenOrder"))
const selectPenOrderV1 = lazy(() => import("../ModalPages/selectPenOrderV1"))
// const botFactory = lazy(() => import("../ModalPages/botFactory"))
const customerDetail = lazy(() => import("../ModalPages/customerDetail"))

const invoiceDetail = lazy(() => import("../invoice/detail"))
const stockTransferDetail = lazy(() => import("../stockTransfer/detail"))
const transactionDetail = lazy(() => import("../ModalPages/transactionDetail"))
const modalStockTransfer = lazy(() => import("../ModalPages/modalStockTransfer"))
const modalGoDownStockTransfer = lazy(() => import("../ModalPages/modalGoDownStockTransfer"))

const modalManageProcessTransfer = lazy(() => import("../ModalPages/modalManageProcessTransfer"))
const lotParameterizedModal = lazy(() => import("../ModalPages/lotParameterizedModal"))
const SelectFromProcessOrLot = lazy(() => import("../ModalPages/SelectFromProcessOrLot"))
const modalManageProcess = lazy(() => import("../ModalPages/modalManageProcess"))
const modalLotHistory = lazy(() => import("../ModalPages/modalLotHistory"))
const NewUpdates = lazy(() => import("../ModalPages/newUpdates"))
const ImportDetailModal = lazy(() => import("../export/detailModal"))
const modalEnvelopePrinting = lazy(() => import("../envelopePrinting/addUpdate"))
const modalChequePrinting = lazy(() => import("../chequePrinting/addUpdate"))

const modalLabelTemplate = lazy(() => import("../../BarCodePrinting/Components/ModalPages/modalLabelTemplate"))
const modalLabelDesign = lazy(() => import("../../BarCodePrinting/Components/ModalPages/modalLabelDesign"))
const importTemplate = lazy(() => import("../../BarCodePrinting/Components/ModalPages/importTemplate"))

const TransportView = lazy(() => import("../ModalPages/transportView"))
const OtherAccountView = lazy(() => import("../ModalPages/otherAccountView"))
const catalogueUserDetail = lazy(() => import("../getMyStorecontacts/detail"))

// const productAttribute = lazy(() => import("../productAttribute/addUpdate"))
const sendNotification = lazy(() => import("../getMyStoreUser/sendNotification"))

const ImportCountry = lazy(() => import("../manageCountry/importCountry"))
const DraftList = lazy(() => import("../Draft"))
const ChangeYearMapping = lazy(() => import("../changeYearUtility/mappingForm"))
const AddNewOrganization = lazy(() => import("../organizations/addCompany"))
const AddDimensionsRange = lazy(() => import("../demoWoodFn/rangeModel"))
const AddBillOfEntry = lazy(() => import("../billOfEntry"))
const SelectItemForBarcode = lazy(() => import("../ModalPages/SelectItemForBarcode"))
const AddAdjustBill = lazy(() => import("../ModalPages/addAdjustBill"))

const useStyles = makeStyles((theme) => ({
  secondaryActionFrame: {
    overflow: "visible",
    position: "fixed",
    right: 8,
    top: 4,
    zIndex: 10001,
  },
  drawerBackgroundColor: {
    backgroundColor: theme.palette.background.default,
  },
}))

export default function DrawerComponent({ t, formValues, navigate }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { islogin } = useSelector((state) => state.AuthReducer)
  const { drawerStack, windowWidth, sidebarloading } = useSelector((state) => state.CommonReducer)

  var sales_invoices_types = getAccountSitesetting("invoice_type_detail")
  var purchase_invoices_types = getAccountSitesetting("purchase_type_detail")
  var inward_invoices_types = getAccountSitesetting("inward_type_detail")
  var outward_invoices_types = getAccountSitesetting("outward_type_detail")

  const closeDrawer = (e) => {
    dispatch(rightdrawerstate({ open: false, title: e.drawer }))
  }

  const closeDrawerAll = () => {
    dispatch(closeAllDrawer())
  }

  const closeClick = (type, e) => {
    if (type === "Close") {
      closeDrawer(e)
    } else {
      closeDrawerAll()
    }
  }

  const secondaryAction = (e) => (
    <React.Fragment>
      <Button style={{ padding: 8, color: "#ffffff" }} size="small" startIcon={<Close fontSize="small" />} onClick={() => closeClick("Close", e)}>
        Close
      </Button>
    </React.Fragment>
  )

  const rendersidebarloading = (index) => {
    if (!islogin) {
      return null
    }
    if (sidebarloading && index === drawerStack.length - 1) {
      return (
        <React.Fragment>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 100000001,
            }}
          />
          <CircularProgress
            color="primary"
            disableShrink
            size={50}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 100000002,
              marginLeft: "-25px",
              marginTop: "-25px",
            }}
          />
        </React.Fragment>
      )
    }
    return null
  }

  return (
    <React.Fragment>
      {drawerStack.map((e, index) => {
        let MyComponent = null
        if (e.drawer === "PDFViewer") {
          MyComponent = PDFViewer
        } else if (e.drawer === "Add New CustomerSupplier") {
          MyComponent = CustomerSupplierModal
        } else if (e.drawer === "Add New Custom Page") {
          MyComponent = customPageForm
        } else if (e.drawer === "Preview OtherAccount") {
          MyComponent = OtherAccountView
        } else if (e.drawer === "Catalogue User Detail") {
          MyComponent = catalogueUserDetail
        } else if (e.drawer === "Preview Customer") {
          MyComponent = CustomerView
        } else if (e.drawer === "Add New Adjust") {
          MyComponent = AddAdjustBill
        } else if (e.drawer === "Draft List") {
          MyComponent = DraftList
        } else if (e.drawer === "Preview Transport") {
          MyComponent = TransportView
        } else if (e.drawer === "Ledger Report") {
          MyComponent = LedgerReport
        } else if (e.drawer === "GST Detail") {
          MyComponent = GSTDetail
        } else if (e.drawer === "ItemList") {
          MyComponent = ProcessDetail
        } else if (e.drawer === "Add New Item") {
          MyComponent = modalManageItemForm
        } else if (e.drawer === "Add New ImportUnit") {
          MyComponent = modalImportUnitForm
        } else if (e.drawer === "Add New ImportAccount") {
          MyComponent = modalImportAccount
        } else if (e.drawer === "Add New State") {
          MyComponent = modalManageState
        } else if (e.drawer === "Add New Country") {
          MyComponent = modalCountryForm
        } else if (e.drawer === "Add New City") {
          MyComponent = modalManageCityForm
        } else if (e.drawer === "Add New Discount Coupon") {
          MyComponent = modalDiscountCouponsForm
        } else if (e.drawer === "Add New Cost Center") {
          MyComponent = costCenter
        } else if (e.drawer === "Select Cost Center") {
          MyComponent = selectCostCenterForm
        } else if (e.drawer === "Add New Wire Transfer") {
          MyComponent = modalWireTransferForm
        } else if (e.drawer === "Add New Sender") {
          MyComponent = modalSenderForm
        } else if (e.drawer === "Add New Beneficiary") {
          MyComponent = modalBeneficiaryForm
        } else if (e.drawer === "Edit Tax Group" || e.drawer === "Add New Tax Group") {
          MyComponent = modalEditTaxSchemeForm
        } else if (e.drawer === "Add New TaxScheme") {
          MyComponent = modalTaxSchemeForm
        } else if (e.drawer === "Add New Account") {
          MyComponent = modalManageAccountForm
        } else if (e.drawer === "Add New User") {
          MyComponent = modalManageUserForm
        } else if (e.drawer === "Add New Product Group") {
          MyComponent = modalProductGroupForm
        } else if (e.drawer === "Receive Payment Form") {
          MyComponent = modalReceivePayment
        } else if (e.drawer === "Purchase Old Gold") {
          MyComponent = purchaseOldGold
        } else if (e.drawer === "Gold Sale Return") {
          MyComponent = goldSaleReturn
        } else if (e.drawer === "Add New Transport") {
          MyComponent = modalTransportForm
        } else if (e.drawer === "Add New Godown") {
          MyComponent = modalGoDownForm
        } else if (e.drawer === "Add New Company") {
          MyComponent = editCompanyForm
        } else if (e.drawer === "Add New Agent") {
          MyComponent = agentForm
        } else if (e.drawer === "Add New Salesman") {
          MyComponent = salesmanForm
        } else if (e.drawer === "Add New Customer Group") {
          MyComponent = modalManagePartyGroup
        } else if (e.drawer === "Add New Product Sub Group") {
          MyComponent = ModalManageProductSubGroup
        } else if (e.drawer === "Add New Sub Group") {
          MyComponent = modalManageSubGroup
        } else if (e.drawer === "Add New Product Sub Group Level2") {
          MyComponent = modalManageProductSubGroupLevel2
        } else if (e.drawer === "Add New RecentActivitiesDrawer") {
          MyComponent = recentActivitiesDrawer
        } else if (e.drawer === "Add New QuickLink") {
          MyComponent = quickLinkDrawer
        } else if (e.drawer === "Add New Promo Option") {
          MyComponent = modalPromoOption
        } else if (e.drawer === "Promo Option") {
          MyComponent = promoOption
        } else if (e.drawer === "Add New SettingsDrawer") {
          MyComponent = settingsDrawer
        } else if (e.drawer === "Add New Contact") {
          MyComponent = groupSMSAddContactForm
        } else if (e.drawer === "Add New Group") {
          MyComponent = groupSMSAddGroupForm
        } else if (e.drawer === "Add New Group SMS") {
          MyComponent = groupSMSSendForm
        } else if (e.drawer === "Add New IPO Stock Account") {
          MyComponent = IPOStockPartyForm
        } else if (e.drawer === "Add New IPO Stock Master") {
          MyComponent = IPOStockMasterForm
        } else if (e.drawer === "Product Detail") {
          MyComponent = ProductDetail
        } else if (e.drawer === "Update Shipping Address") {
          MyComponent = modalShippingAddress
        } else if (e.drawer === "Update Eway Bill") {
          MyComponent = modalEwayBill
        } else if (e.drawer === "Update Invoice Settings") {
          MyComponent = UpdateInvoiceSettings
        } else if (e.drawer === "Add New Batch") {
          MyComponent = addBatchForm
        } else if (e.drawer === "Create Lot in Purchase") {
          MyComponent = createLotInPurchase
        } else if (e.drawer === "Add Lot Parameterized Qty") {
          MyComponent = lotParameterizedModal
        } else if (e.drawer === "New Updates") {
          MyComponent = NewUpdates
        } else if (e.drawer === "Import Detail Modal") {
          MyComponent = ImportDetailModal
        } else if (e.drawer === "Select Process or Lot") {
          MyComponent = SelectFromProcessOrLot
        } else if (e.drawer === "Select Batch") {
          MyComponent = selectBatchForm
        } else if (e.drawer === "Select Serial") {
          MyComponent = selectSerialForm
        } else if (e.drawer === "Add New Serial") {
          MyComponent = addSerialNumberForm
        } else if (e.drawer === "Change Password") {
          MyComponent = modalChangePasswordForm
        } else if (e.drawer === "Add Item in Bulk") {
          MyComponent = modalAddItemInBulk
        } else if (e.drawer === "Add Item in Bulk Barcode") {
          MyComponent = modalAddItemInBulkBarcode
        } else if (e.drawer === "Config Product Detail") {
          MyComponent = ConfigProductDetail
        } else if (e.drawer === "GST Tax Detail") {
          MyComponent = gstTaxDetail
        } else if (e.drawer === "Edit SMS Template") {
          MyComponent = modalSMSTemplate
        } else if (e.drawer === "Edit Email Template") {
          MyComponent = modalEmailTemplate
        } else if (e.drawer === "Adjust Bill") {
          MyComponent = adjustBill
        } else if (e.drawer === "Adjust Bill By Bill") {
          MyComponent = adjustBillByBill
        } else if (e.drawer === "Adjust TDS") {
          MyComponent = adjustTDS
        } else if (e.drawer === "Select Pen Order") {
          MyComponent = selectPenOrder
        } else if (e.drawer === "Select Pen Order V1") {
          MyComponent = selectPenOrderV1
        } else if (e.drawer === "Customer Detail") {
          MyComponent = customerDetail
        } else if (e.drawer === "Add Page Template") {
          MyComponent = modalLabelTemplate
        } else if (e.drawer === "Import Template") {
          MyComponent = importTemplate
        } else if (e.drawer === "Invoice Detail") {
          MyComponent = invoiceDetail
        } else if (e.drawer === "Stock Transfer Detail") {
          MyComponent = stockTransferDetail
        } else if (e.drawer === "Transaction Detail") {
          MyComponent = transactionDetail
        } else if (e.drawer === "Add New Process") {
          MyComponent = modalManageProcess
        } else if (e.drawer === "Send Notification") {
          MyComponent = sendNotification
        } else if (e.drawer === "customBarcodePrint") {
          MyComponent = CustomBarcodePrint
        } else if (e.drawer === "Import Country") {
          MyComponent = ImportCountry
        } else if (e.drawer === "Add New Organization") {
          MyComponent = AddNewOrganization
        } else if (e.drawer === "Add Record") {
          MyComponent = modalAddRecord
        } else if (e.drawer === "Select Address") {
          MyComponent = SelectAddressFromGSTIN
        } else if (!isblank(sales_invoices_types) && sales_invoices_types.filter((i) => i.id === e.drawer).length > 0) {
          MyComponent = salesInvoiceForm
        } else if (!isblank(purchase_invoices_types) && purchase_invoices_types.filter((i) => i.id === e.drawer).length > 0) {
          if (formValues.newHookForm) {
            MyComponent = modalPurchaseInvoiceForm //modalPurchaseInvoiceFormForHook
          } else {
            MyComponent = modalPurchaseInvoiceForm
          }
        } else if (!isblank(outward_invoices_types) && outward_invoices_types.length > 0 && outward_invoices_types.filter((i) => i.id === e.drawer).length > 0) {
          if (formValues.newHookForm) {
            MyComponent = OutwardForm //OutwardHookForm
          } else {
            MyComponent = OutwardForm
          }
        } else if (!isblank(inward_invoices_types) && inward_invoices_types.length > 0 && inward_invoices_types.filter((i) => i.id === e.drawer).length > 0) {
          if (formValues.newHookForm) {
            MyComponent = InwardForm //InwardHookForm
          } else {
            MyComponent = InwardForm
          }
        } else if (e.drawer === "configurableitem") {
          MyComponent = modalConfigProductForm
        } else if (e.drawer === "bundleitem") {
          MyComponent = modalBundleProductForm
        } else if (e.drawer === "sales_return") {
          MyComponent = SalesReturnForm
        } else if (e.drawer === "Add New GST Old Pen Inv") {
          MyComponent = gstOldPenInvcModal
        } else if (e.drawer === "purchase_return") {
          MyComponent = modalPurchaseReturnForm
        } else if (e.drawer === "receipt") {
          MyComponent = ModalTransactionMultiple
        } else if (e.drawer === "receiptdetail") {
          MyComponent = ModalTransactionSingle
        } else if (e.drawer === "payment") {
          MyComponent = ModalTransactionMultiple
        } else if (e.drawer === "paymentdetail") {
          MyComponent = ModalTransactionSingle
        } else if (e.drawer === "journal") {
          MyComponent = modalJournalEntryForm
        } else if (e.drawer === "IPOStock") {
          MyComponent = IPOStockForm
        } else if (e.drawer === "IPOStock") {
          MyComponent = IPOStockForm
        }
        // else if (e.drawer === "Bot Factory") {
        //   MyComponent = botFactory
        // }
        else if (e.drawer === "Add Label Design") {
          MyComponent = modalLabelDesign
        } else if (e.drawer === "Godown Stock Transfer") {
          MyComponent = modalGoDownStockTransfer
        } else if (e.drawer === "Stock Transfer") {
          MyComponent = modalStockTransfer
        } else if (e.drawer === "Add New Process Transfer") {
          MyComponent = modalManageProcessTransfer
        } else if (e.drawer === "Add New Custom Field") {
          MyComponent = customFieldForm
        } else if (e.drawer === "Add New Webhook") {
          MyComponent = AddWebhookform
        } else if (e.drawer === "Add New Page") {
          MyComponent = ModalCustomPage
        } else if (e.drawer === "Add New Bot") {
          MyComponent = modalBotFactory
        } else if (e.drawer === "Merge Packing Slip") {
          MyComponent = mergePackingSlip
        } else if (e.drawer === "Lot History") {
          MyComponent = modalLotHistory
        } else if (e.drawer === "Add New Envelope") {
          MyComponent = modalEnvelopePrinting
        } else if (e.drawer === "Add New Cheque") {
          MyComponent = modalChequePrinting
        } else if (e.drawer === "Change Year") {
          MyComponent = ChangeYearMapping
        } else if (e.drawer === "Add Dimensions Range") {
          MyComponent = AddDimensionsRange
        } else if (e.drawer === "Select Item For Barcode") {
          MyComponent = SelectItemForBarcode
        } else if (e.drawer === "Add Bill of Entry") {
          MyComponent = AddBillOfEntry
        }

        return (
          <Drawer
            key={index}
            elevation={0}
            anchor={e.position}
            open={e.open}
            onClose={(event, reason) => {
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                closeDrawer(e)
              }
            }}
            style={{ zIndex: 1200 + index }}
          >
            <div
              id="rightDrawer"
              style={{
                position: "relative",
                width: e.expandDrawer || isMobileOnly ? windowWidth : e.width,
                height: "100vh",
              }}
              className={classes.drawerBackgroundColor}
            >
              <div className={classes.secondaryActionFrame}>{secondaryAction(e)}</div>
              <Suspense fallback={<FallBackComponent />}>
                {e.open && e.drawer === "PDFViewer" ? (
                  <MyComponent
                    {...(e.position === "bottom" && { drawerType: "full" })}
                    base64data={e.data}
                    data={e.fieldname}
                    componentId={e.drawer ? replaceAllFn(e.drawer, " ", "_") : ""}
                    drawerData={e}
                    drawerIndex={index}
                  />
                ) : e.open && e.drawer === "Draft List" ? (
                  <MyComponent
                    {...(e.position === "bottom" && { drawerType: "full" })}
                    drawerData={e}
                    drawerIndex={index}
                    dispatch={dispatch}
                    componentId={e.drawer ? replaceAllFn(e.drawer, " ", "_") : ""}
                  />
                ) : e.open ? (
                  <MyComponent
                    {...(e.position === "bottom" && { drawerType: "full" })}
                    drawerData={e}
                    drawerIndex={index}
                    dispatch={dispatch}
                    other_type={e.drawer ? e.drawer : ""}
                    t={t}
                    componentId={e.drawer ? replaceAllFn(e.drawer, " ", "_") : ""}
                  />
                ) : (
                  <div></div>
                )}
              </Suspense>
              {rendersidebarloading(index)}
            </div>
          </Drawer>
        )
      })}
    </React.Fragment>
  )
}

// const PaymentFormDetail = lazy(() => import("../ModalPages/modalPaymentSingle"))
// const ModalCatalogue = lazy(() => import("../catalogue/addUpdate"))
// const ModalReceiptForm = lazy(() => import("../ModalPages/modalReceiptSingle"))
// const modalManageUnitForm = lazy(() => import("../ModalPages/modalManageUnitForm"))
// const LinkProductInCatalogue = lazy(() => import("../ModalPages/LinkProductInCatalogue"))
// const multiSelector = lazy(() => import("../shippingUtility/stateSelector"))
// const ModalPaymentSetting = lazy(() => import("../paymentUtility/addUpdate"))
// const GodownWiseOpeningStock = lazy(() => import("../ModalPages/GodownWiseOpeningStock"))

/* else if (e.drawer === "Link Product In Catalogue") {
        MyComponent = LinkProductInCatalogue
      } 
      else if (e.drawer === "Add New Catalogue") {
        MyComponent = ModalCatalogue
      } 
        else if (e.drawer === "Add New Unit") {
        MyComponent = modalManageUnitForm
      } 
       else if (e.drawer === "Multi Select") {
       MyComponent = multiSelector
     }
    else if (e.drawer === "Add New PaymentSetting") {
       MyComponent = ModalPaymentSetting
     }else if (e.drawer === "recordexpense") {
        MyComponent = modalRecordExpenseForm
      } else if (e.drawer === "Godown Wise Opening Stock") {
          MyComponent = GodownWiseOpeningStock
        } */
