import axios from "axios"
import { list_url, post_form_url } from "./apiURL"
import { getCatchErrorMessage } from "./utilityFunctions"
import { isblank } from "./utilityFunctions"
export const getBase64ImageFromURL = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { responseType: "arraybuffer" })
      .then((response) => {
        const image = response
        const raw = Buffer.from(image.data).toString("base64")
        const base64Image = "data:" + image.headers["content-type"] + ";base64," + raw
        resolve(base64Image)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// "https://images.unsplash.com/photo-1617854818583-09e7f077a156"

export const getBase64ImageFromURLV1 = (url) => {
  return new Promise((resolve, reject) => {
    var img = new Image()
    img.setAttribute("crossOrigin", "anonymous")
    img.onload = () => {
      var canvas = document.createElement("canvas")
      canvas.width = img.width
      canvas.height = img.height
      var ctx = canvas.getContext("2d")
      ctx.drawImage(img, 0, 0)
      var dataURL = canvas.toDataURL("image/png")
      resolve(dataURL)
    }
    img.onerror = (error) => {
      reject(error)
    }
    img.src = url
    img.source = {
      header: {
        "Access-Control-Allow-Origin": "*",
      },
    }
    //"https://images.pexels.com/photos/209640/pexels-photo-209640.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=300"
  })
}

export const getPostData = (error) => {
  const postData = error && error.config && error.config.data ? error.config.data : ""
  var post = {}
  if (postData) {
    if (!isblank(postData.entries) && typeof postData.entries === "function") {
      for (var [key, value] of postData.entries()) {
        try {
          post[key] = JSON.parse(value)
        } catch (err) {
          post[key] = value
        }
      }
    } else if (!isblank(postData._parts) && postData._parts.length > 0) {
      postData._parts.forEach((e) => {
        try {
          post[e[0]] = JSON.parse(e[1])
        } catch (err) {
          post[e[0]] = e[1]
        }
      })
    } else if (postData) {
      post = postData
    }
  }
  return post
}

export const getPostUrl = (error) => {
  return error && error.config && error.config.url ? error.config.url : ""
}

export const getPostToken = (error) => {
  return error && error.config && error.config.headers
    ? error.config.headers.Authorization
      ? error.config.headers.Authorization
      : error.config.headers.authorization
        ? error.config.headers.authorization
        : ""
    : ""
}

export const postErrorOnException = (error, BASE_URL, title, company_url, user_name) => {
  if (error && (error.message === "Request aborted" || error.message === "Network Error")) {
    console.log(error)
  } else {
    var currentDateTime = new Date().toString()
    var path = window && window.location && window.location.href ? window.location.href : ""
    var post = getPostData(error)
    const errorInfo = {
      url: getPostUrl(error),
      post: post,
      response: error && error.response && error.response.data ? error.response.data : "",
      token: getPostToken(error),
    }

    var error_data = {
      error: { name: title ? title : "Axios Exception", version: process.env.REACT_APP_VERSION, message: error.message ? error.message : "", stack: error.stack ? error.stack : "" },
      errorInfo: errorInfo,
      company_url: company_url ? company_url : "",
      path: path,
      currentDateTime,
      user_name: user_name,
    }
    error_data = Array.isArray(error_data) ? error_data.map(JSON.stringify) : JSON.stringify(error_data)
    let data = new FormData()
    data.append("error_data", error_data)
    var url = ""
    if (BASE_URL) {
      url = list_url({ type: "error_post", props: { config: { BASE_URL } } })
    } else {
      url = list_url({ type: "error_post" })
    }

    axios({
      method: "post",
      url: url,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => {})
      .catch((error) => {})
  }
}

export const postProductPhotoURLOnServer = ({ srNo, file, is_default, onError, onSuccess, itemCode, functions }) => {
  const { dispatch, getAccountSessionUserData, getheaders, handleResponse, loadingstate, isMobileOnly, config } = functions
  const formdata = {
    product_id: itemCode,
    srNo: [srNo],
    imageNames: [file.postName],
    is_default: is_default,
    type: "multi_image",
    catalogue_id: "",
  }
  const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
  let data = new FormData()
  data.append("type", "itemImageInsert")
  data.append("action", "insert")
  data.append("form_data", formdata2)
  axios.defaults.headers.common = getheaders()
  var typeForUrl = "itemImageInsert"
  if (!isMobileOnly && process.env.REACT_APP_MODULE_TYPE === "getmycatalogue") {
    typeForUrl = `catalogue_itemImageInsert`
  }
  var url = ""
  if (isMobileOnly) {
    url = post_form_url({ formtype: typeForUrl, props: { config, isMobileOnly } })
  } else {
    url = post_form_url({ formtype: typeForUrl })
  }
  axios({
    method: "post",
    url: url,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  })
    .then((response) => handleResponse(response, dispatch))
    .then((response) => {
      if (response.data.meta.code === 401) {
        loadingstate(false)
        onError(response.data.meta.message)
      } else {
        response.data.result = {
          photo: response.data.insert_codes[0].photo_url,
        }
        onSuccess(response.data.result)
      }
    })
    .catch((error) => {
      postErrorOnException(error, "", "", getAccountSessionUserData("company_url"), getAccountSessionUserData("name"))
      loadingstate(false)
      onError(getCatchErrorMessage(error))
    })
}

export const postSinglePhotoURL = ({ dispatch, update_code, imageNames, other_type, onError, onSuccess, functions, srNo }) => {
  const { handleResponse, getheaders, config, postErrorOnException, isMobileOnly } = functions
  const formdata = {
    imageNames,
    update_code,
  }
  if (srNo) {
    formdata.srNo = srNo
  }
  const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
  let data = new FormData()
  data.append("type", "update_photo")
  data.append("action", "update")
  data.append("other_type", other_type)
  data.append("form_data", formdata2)
  axios.defaults.headers.common = getheaders()

  var url = ""
  if (isMobileOnly) {
    url = list_url({ type: "postSinglePhotoURL", props: { config } })
  } else {
    url = list_url({ type: "postSinglePhotoURL" })
  }

  axios({
    method: "post",
    url: url,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  })
    .then((response) => handleResponse(response, dispatch))
    .then((response) => {
      if (response.data.meta.code === 401) {
        onError(response.data.meta.message)
      } else {
        onSuccess(response.data)
      }
    })
    .catch((error) => {
      postErrorOnException(error)
      onError(getCatchErrorMessage(error))
    })
}
