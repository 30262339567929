import { list_url, post_form_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, isblank, replaceAllFn, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getAccountSitesetting, getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import { pushDrawer, removeDrawer } from "./CommonActionsV2"
import {
  BILL_SERVICE_SUCCESS,
  COMMON_SERVICE_INSERTED,
  COMMON_SERVICE_UPDATED,
  DROPDOWN_ITEM_DELETED,
  DROPDOWN_ITEM_INSERTED,
  DROPDOWN_ITEM_UPDATED,
  FORM_DEP_SERVICE_SUCCESS,
  FORM_POST_SERVICE_SUCCESS,
  LAST_PAGE_UPDATE,
  LOADING_STATE,
  PAGE_FORM_POST_SERVICE_SUCCESS,
  PUR_BILL_SERVICE_SUCCESS,
  SNACKBAR_STATE,
  UPDATE_OTHER_DETAIL,
} from "./types"

const condition = (draftType, type) => {
  const invoice_type_detail = getAccountSitesetting("invoice_type_detail")
  const purchase_type_detail = getAccountSitesetting("purchase_type_detail")
  const inward_type_detail = getAccountSitesetting("inward_type_detail")
  const outward_type_detail = getAccountSitesetting("outward_type_detail")
  const url = draftType.split("/")
  if (!isblank(url[3])) {
    url[3] = replaceAllFn(url[3], "-", "_")
    if (type === "S") {
      return url[2] === "invoice" && (url[3] === "orders" || invoice_type_detail.filter((e) => e.id === url[3]).length > 0)
    } else if (type === "PU") {
      return url[2] === "purchaseinvoices" && purchase_type_detail.filter((e) => e.id === url[3]).length > 0
    } else if (type === "I") {
      return (url[2] === "inwards" || url[2] === "process") && inward_type_detail.filter((e) => e.id === url[3]).length > 0
    } else if (type === "O") {
      if (url[2] === "outwards" && outward_type_detail.filter((e) => e.id === url[3]).length > 0) {
        return true
      }
      if (url[2] === "process" && url[3] === "lotmaster") {
        return true
      }
    }
  } else if (!isblank(url[2])) {
    return url[2] === type
  }
  return false
}

export const fetch_draftData_pageWise = ({ other_type, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("offset", 0)
    data.append("type", "draft")
    data.append("limit", "All")
    data.append("sort_order", "desc")
    data.append("sort_by", "code")
    data.append("search_text", "")
    data.append("other_type", other_type)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "draft" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        dispatch({ type: LOADING_STATE, state: false })
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          const user = {
            data: { dd_draft: response.data.result },
          }
          dispatch({
            type: FORM_DEP_SERVICE_SUCCESS,
            payload: user,
            dropdown_string: "dd_draft",
          })

          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "dependency",
            lastaction: "success",
            lasttime: new Date().getTime(),
          })
          // response.data.result
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_STATE, state: false })
        if (axios.isCancel(error)) {
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        }
      })
  }
}

export const draft_actions = ({ draftType, formaction, formdata, otherDetail, common_formtype, functions }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_STATE, state: true })
    if (formaction !== "") {
      const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
      var url = post_form_url({ formtype: "draft", props: { userdata: "", getAccountSitesetting } })
      let data = new FormData()
      data.append("type", "draft")
      data.append("action", formaction)
      data.append("form_data", formdata2)
      data.append("offset", 0)
      data.append("limit", "All")
      axios.defaults.headers.common = getheaders()
      axios({
        method: "post",
        url: url,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          dispatch({ type: LOADING_STATE, state: false })
          if (response.data.meta.code === 401) {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: response.data.meta.message,
              snackbarType: "error",
            })
          } else {
            /*
            Clear Sales Invoice Backup 
            */

            if (condition(draftType, "S")) {
              localStorage.setItem("formValues", "")
            }

            dispatch({
              type: UPDATE_OTHER_DETAIL,
              otherDetail: "",
            })

            if (formaction !== "delete") {
              dispatch({
                type: PAGE_FORM_POST_SERVICE_SUCCESS,
                payload: response,
                pageformtype: "draft",
                pageformaction: formaction,
              })
              dispatch({
                type: FORM_POST_SERVICE_SUCCESS,
                payload: response,
                formtype: "draft",
                formaction: formaction,
                purchase_type_detail: [],
              })
            }
            if (formaction === "insert") {
              dispatch({
                type: DROPDOWN_ITEM_INSERTED,
                formtype: "draft",
                code: response.data.insert_code,
                payload: formdata,
              })
              if (
                condition(draftType, "S") ||
                condition(draftType, "PU") ||
                condition(draftType, "I") ||
                condition(draftType, "O") ||
                condition(draftType, "debitnotes") ||
                condition(draftType, "creditnotes") ||
                condition(draftType, "receipts") ||
                condition(draftType, "journalentries") ||
                condition(draftType, "payments")
              ) {
                const payload = JSON.parse(formdata.search_data)
                payload.code = response.data.insert_code
                payload.detail = formdata.detail
                payload.type = draftType
                payload.draftReason = formdata.title
                if (condition(draftType, "S") || condition(draftType, "PU") || condition(draftType, "I") || condition(draftType, "O")) {
                  const Inserteditem = {}
                  Object.keys(payload).forEach((e) => {
                    Inserteditem[e] = payload[e]
                  })
                  if (common_formtype === "draft") {
                    dispatch({
                      type: COMMON_SERVICE_INSERTED,
                      row: Inserteditem,
                    })
                  }
                } else if (condition(draftType, "creditnotes") || condition(draftType, "debitnotes")) {
                  const Inserteditem = {}
                  Object.keys(payload).forEach((e) => {
                    Inserteditem[e] = payload[e]
                  })
                  if (common_formtype === "draft") {
                    dispatch({
                      type: COMMON_SERVICE_INSERTED,
                      row: Inserteditem,
                    })
                  }
                } else if (condition(draftType, "receipts") || condition(draftType, "journalentries") || condition(draftType, "payments")) {
                  const Inserteditem = {}
                  Object.keys(payload).forEach((e) => {
                    Inserteditem[e] = payload[e]
                  })
                  if (common_formtype === "draft") {
                    dispatch({
                      type: COMMON_SERVICE_INSERTED,
                      row: Inserteditem,
                    })
                  }
                }
              }
              removeDrawer(dispatch)
            } else if (formaction === "update") {
              dispatch({
                type: DROPDOWN_ITEM_UPDATED,
                formtype: "draft",
                payload: formdata,
                code: formdata.update_code,
              })
              if (
                condition(draftType, "S") ||
                condition(draftType, "PU") ||
                condition(draftType, "I") ||
                condition(draftType, "O") ||
                condition(draftType, "debitnotes") ||
                condition(draftType, "creditnotes") ||
                condition(draftType, "receipts") ||
                condition(draftType, "journalentries") ||
                condition(draftType, "payments")
              ) {
                const payload = JSON.parse(formdata.search_data)
                payload.code = formdata.update_code
                payload.detail = formdata.detail
                payload.type = draftType
                payload.draftReason = formdata.title
                if (condition(draftType, "S") || condition(draftType, "PU") || condition(draftType, "I") || condition(draftType, "O")) {
                  const updateditem = {}
                  Object.keys(payload).forEach((e) => {
                    updateditem[e] = payload[e]
                  })
                  dispatch({
                    type: COMMON_SERVICE_UPDATED,
                    row: updateditem,
                    payload: { update_code: payload.code },
                  })
                } else if (condition(draftType, "creditnotes") || condition(draftType, "debitnotes")) {
                  const updateditem = {}
                  Object.keys(payload).forEach((e) => {
                    updateditem[e] = payload[e]
                  })
                  dispatch({
                    type: COMMON_SERVICE_UPDATED,
                    row: updateditem,
                    payload: { update_code: payload.code },
                  })
                } else if (condition(draftType, "receipts") || condition(draftType, "journalentries") || condition(draftType, "payments")) {
                  const updateditem = {}
                  Object.keys(payload).forEach((e) => {
                    updateditem[e] = payload[e]
                  })
                  dispatch({
                    type: COMMON_SERVICE_UPDATED,
                    row: updateditem,
                    payload: { update_code: payload.code },
                  })
                }
              }
              removeDrawer(dispatch)
            } else if (formaction === "delete") {
              dispatch({
                type: DROPDOWN_ITEM_DELETED,
                formtype: "draft",
                payload: formdata,
                response: response,
                otherDetail: otherDetail,
              })

              if (otherDetail === "multi_delete") {
                dispatch({
                  type: LAST_PAGE_UPDATE,
                  lasttype: "draft",
                  lastaction: "multi_delete",
                  lasttime: new Date().getTime(),
                  lastdata: formdata,
                })
              }
            }
          }
        })
        .catch((error) => {
          dispatch({ type: LOADING_STATE, state: false })
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        })
    }
  }
}

export const draft_click = (draftData) => {
  return (dispatch) => {
    const type = draftData.type
    const form_data = {
      data: {
        form_data: { ...JSON.parse(draftData.detail), draftCode: draftData.code },
      },
    }
    const url = type.split("/")
    if (!isblank(url[3])) {
      url[3] = replaceAllFn(url[3], "-", "_")
      if (url[3] === "lotmaster") {
        url[3] = "manage_lot_master"
      }
      if (condition(type, "S")) {
        dispatch({
          type: PAGE_FORM_POST_SERVICE_SUCCESS,
          payload: form_data,
          pageformtype: form_data.data.form_data.invoice_type,
          pageformaction: "draft_edit",
        })

        pushDrawer({ title: form_data.data.form_data.invoice_type, position: "bottom", dispatch })
      } else if (condition(type, "PU") || condition(type, "O") || condition(type, "I")) {
        dispatch({
          type: PAGE_FORM_POST_SERVICE_SUCCESS,
          payload: form_data,
          pageformtype: url[3],
          pageformaction: "draft_edit",
        })

        pushDrawer({ title: url[3], position: "bottom", dispatch })
      }
    } else if (!isblank(url[2])) {
      if (condition(type, "debitnotes") || condition(type, "creditnotes") || condition(type, "receipts") || condition(type, "journalentries") || condition(type, "payments")) {
        var formtype = ""
        var modal = ""
        if (condition(type, "debitnotes")) {
          formtype = "purchasereturn"
          modal = "purchase_return"
        } else if (condition(type, "creditnotes")) {
          formtype = "salesreturn"
          modal = "sales_return"
        } else if (condition(type, "receipts")) {
          if (form_data.data.form_data.receipt_type === "M") {
            modal = "receipt"
          } else {
            modal = "receiptdetail"
          }
          formtype = "receipt"
        } else if (condition(type, "journalentries")) {
          formtype = "journal_entry"
          modal = "journal"
        } else if (condition(type, "payments")) {
          if (form_data.data.form_data.receipt_type === "M") {
            modal = "payment"
          } else {
            modal = "paymentdetail"
          }
          formtype = "payment"
        }

        if (condition(type, "debitnotes") || condition(type, "creditnotes")) {
          dispatch({
            type: BILL_SERVICE_SUCCESS,
            payload: { data: { result: form_data.data.form_data.ddsalesbill } },
          })

          dispatch({
            type: PUR_BILL_SERVICE_SUCCESS,
            payload: { data: { result: form_data.data.form_data.ddpurbill } },
          })
        }

        dispatch({
          type: PAGE_FORM_POST_SERVICE_SUCCESS,
          payload: form_data,
          pageformtype: formtype,
          pageformaction: "draft_edit",
        })

        pushDrawer({ title: modal, position: "bottom", dispatch })
      }
    }
  }
}
