import { isblank } from "./utilityFunctions"

export const changeDetail = (result) => {
  var newResult = result.map((item) => {
    const updatedItem = { ...item }
    updatedItem.DocNo = item.bill_no
    if (!isblank(item.billno_str)) {
      updatedItem.DocNo = item.billno_str
    }
    if (!isblank(updatedItem.custom_fields)) {
      updatedItem.custom_fields = typeof updatedItem.custom_fields === "string" ? JSON.parse(updatedItem.custom_fields) : updatedItem.custom_fields
    }

    updatedItem.qty = updatedItem.tot_qty
    updatedItem.penqty = updatedItem.tot_penqty
    return updatedItem
  })
  return newResult
}
export const changeDetailV1 = (item) => {
  const updatedItem = { ...item }
  updatedItem.DocNo = item.bill_no
  if (!isblank(item.billno_str)) {
    updatedItem.DocNo = item.billno_str
  }
  if (!isblank(updatedItem.custom_fields)) {
    updatedItem.custom_fields = typeof updatedItem.custom_fields === "string" ? JSON.parse(updatedItem.custom_fields) : updatedItem.custom_fields
  }

  updatedItem.qty = updatedItem.tot_qty
  updatedItem.penqty = updatedItem.tot_penqty
  return updatedItem
}
