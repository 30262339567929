import { Box, Typography } from "@material-ui/core"
import React from "react"
import Slider from "react-slick"
import Hero from "./Hero"

export default function LoginSlider() {
  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    autoplay: true,
    centerMode: true,
    centerPadding: "140px",
  }
  return (
    <Slider {...settings}>
      <div>
        <Hero
          title={
            <Box>
              <Typography variant="h5" style={{ fontWeight: "bold" }} gutterBottom>
                GetMyCatalogue: Showcase. Share. Sell.
              </Typography>
              <Typography color="primary" variant="h6" gutterBottom>
                Your Products, One Click to Success!
              </Typography>
              <Typography>
                Craft beautiful product catalogues in minutes! Share instantly on social media, WhatsApp, or email. Sync with FinalBooks for hassle-free stock and order management, boost sales, and
                wow customers with automated WhatsApp support!
              </Typography>
            </Box>
          }
          btnUrl="https://demogmc.getmycatalogue.com"
          btnText="View Demo Catalogue"
        />
      </div>
      <div>
        <Hero
          title={
            <Box>
              <Typography variant="h5" style={{ fontWeight: "bold" }} gutterBottom>
                WhatsApp Business Integration
              </Typography>
              <Typography color="primary" variant="h6" gutterBottom>
                Grow your business faster with WhatsApp
              </Typography>
              <Typography>One click documents sharing, Automate marketing, drive more sales and deliver first class support</Typography>
            </Box>
          }
          btnText="Learn More"
          btnUrl="https://wa.me/message/GP6ZXQCRKZF7I1"
          status="Official WhatsApp Business APIs Partner"
        />
      </div>
    </Slider>
  )
}
