import { convertIntif, getNumberonly } from "@project/sharedcomponents/utilityFunctions"
import {
  WIRETRANSFER_SENDER_SEARCH_TEXT_UPDATE,
  WIRETRANSFER_SENDER_SERVICE_DELETED,
  WIRETRANSFER_SENDER_SERVICE_FAIL,
  WIRETRANSFER_SENDER_SERVICE_INSERTED,
  WIRETRANSFER_SENDER_SERVICE_START,
  WIRETRANSFER_SENDER_SERVICE_SUCCESS,
  WIRETRANSFER_SENDER_SERVICE_UPDATED,
} from "../Actions/types"

const INITIAL_STATE = {
  current_tab_index: 0,
  error: "",
  loading: false,
  data: [],
  totalcount: 0,
  rowsPerPage: "",
  page: 0,
  order: "asc",
  orderBy: "code",
  norecord: false,
  deleted: false,
  search_text: "",
  columnData: [
    { id: "code", numeric: false, visible: false, label: "Code", width: 100 },
    { id: "bankname", numeric: false, visible: true, label: "Bank Name", width: 150 },
    { id: "branchname", numeric: false, visible: true, label: "Branch Name", width: 150 },
    { id: "sender_ac_no", numeric: false, visible: true, label: "Sender A/C Number", width: 200 },
    { id: "sender_ac_holder", numeric: false, visible: true, label: "Sender A/C Holder" },
    { id: "mob_no", numeric: false, visible: true, label: "Mobile Number", width: 175 },
    { id: "pan", numeric: false, visible: true, label: "Pan", width: 125 },
  ],
  metadata: "",
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WIRETRANSFER_SENDER_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case WIRETRANSFER_SENDER_SEARCH_TEXT_UPDATE:
      return { ...state, search_text: action.search_text, norecord: false }

    case WIRETRANSFER_SENDER_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.result,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        islogin: true,
        norecord: action.payload.data.result.length === 0,
      }
    case WIRETRANSFER_SENDER_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        data: [],
        loading: false,
        totalcount: 0,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
        norecord: true,
      }

    case WIRETRANSFER_SENDER_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_code
      inserteditem.bankname = action.payload.bankName
      inserteditem.branchname = action.payload.branchName
      inserteditem.sender_ac_no = action.payload.senderAccountNumber
      inserteditem.sender_ac_holder = action.payload.senderAccountHolder
      inserteditem.mob_no = action.payload.mobileNumber
      inserteditem.pan = action.payload.panNumber
      return { ...state, data: [inserteditem, ...state.data] }
    case WIRETRANSFER_SENDER_SERVICE_DELETED:
      return { ...state, data: state.data.filter((item) => convertIntif(item.code) !== convertIntif(action.payload)), deleted: !state.deleted, totalcount: parseInt(getNumberonly(state.totalcount)) - 1 }
    case WIRETRANSFER_SENDER_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.update_code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.bankname = action.payload.bankName
        updateditem.branchname = action.payload.branchName
        updateditem.sender_ac_no = action.payload.senderAccountNumber
        updateditem.sender_ac_holder = action.payload.senderAccountHolder
        updateditem.mob_no = action.payload.mobileNumber
        updateditem.pan = action.payload.panNumber
        return updateditem
      })

      return { ...state, data: updateddata }
    default:
      return state
  }
}
