import { DatetoDMY, amount_format, getTaxTypeName, isblank, numForCalc, number2text, replaceBRtoNewLine, replacefunction } from "./utilityFunctions"

export default ({ printdata, user_data, functions }) => {
  const { getAccountSessionUserData, getAccountSitesetting, other_type } = functions
  var companyName = printdata.cmpy_data[0].name
  var company_address1 = replacefunction(replaceBRtoNewLine(getAccountSessionUserData("company_address1", user_data)))
  var company_address2 = `${replacefunction(replaceBRtoNewLine(getAccountSessionUserData("company_street", user_data)))} ${replacefunction(
    replaceBRtoNewLine(getAccountSessionUserData("company_address2", user_data))
  )}`
  var tax = getAccountSitesetting("tax", user_data)
  var tax_name = getAccountSitesetting("tax_name", user_data)
  var gstin = !isblank(printdata.cmpy_data) && printdata.cmpy_data.length > 0 ? printdata.cmpy_data[0].gstin : ""
  var pan = !isblank(printdata.cmpy_data) && printdata.cmpy_data.length > 0 ? printdata.cmpy_data[0].pan : ""
  var companyMobile = !isblank(printdata.cmpy_data) && printdata.cmpy_data.length > 0 ? printdata.cmpy_data[0].mobile : ""
  var companyPhone = !isblank(printdata.cmpy_data) && printdata.cmpy_data.length > 0 ? printdata.cmpy_data[0].phoneO : ""
  var base64_logo = printdata.base64_logo
  var base64_signature = printdata.base64_signature
  var receipt_heading = ""
  var receipt_terms = ""
  var receipt_other = ""
  var receipt_print_signature = ""
  var print_narration_receipt = ""
  var print_adjust_details = ""

  // Checking Tax Type
  if (numForCalc(tax) === 2) {
    tax = tax_name
  } else {
    tax = getTaxTypeName(tax)
  }

  if (other_type === "receipt") {
    receipt_heading = getAccountSitesetting("receipt_heading")
    receipt_terms = getAccountSitesetting("receipt_terms")
    receipt_other = getAccountSitesetting("receipt_other")
    receipt_print_signature = parseInt(getAccountSitesetting("receipt_print_signature"))
    print_narration_receipt = parseInt(getAccountSitesetting("print_narration_receipt"))
    print_adjust_details = parseInt(getAccountSitesetting("adjust_details_receipt"))
  } else if (other_type === "payment") {
    receipt_heading = getAccountSitesetting("payment_heading")
    receipt_terms = getAccountSitesetting("payment_terms")
    receipt_other = getAccountSitesetting("payment_other")
    receipt_print_signature = parseInt(getAccountSitesetting("payment_print_signature"))
    print_narration_receipt = parseInt(getAccountSitesetting("print_narration_payment"))
    print_adjust_details = parseInt(getAccountSitesetting("adjust_details_payment"))
  } else {
    receipt_heading = getAccountSitesetting("journalentry_heading")
    receipt_terms = getAccountSitesetting("journalentry_terms")
    receipt_other = getAccountSitesetting("journalentry_other")
    receipt_print_signature = parseInt(getAccountSitesetting("journalentry_print_signature"))
  }

  var receiptNo = printdata.docno
  var narrHeading = "NARRATION"
  var adjustDetailHeading = "ADJUSTED AGAINST"

  var receiptDate = DatetoDMY(printdata.date)
  var companyContact = ""
  if (!isblank(companyPhone)) {
    companyContact = `Contact: ${companyPhone}`
  }
  if (!isblank(companyMobile)) {
    if (!isblank(companyContact)) {
      companyContact = `${companyContact}, ${companyMobile}`
    } else {
      companyContact = `Contact: ${companyMobile}`
    }
  }

  var companyTaxInfo = ""
  if (!isblank(gstin)) {
    companyTaxInfo = `${tax}: ${gstin}`
  }
  if (!isblank(pan)) {
    companyTaxInfo = `${companyTaxInfo} PAN:${pan}`
  }
  var Logo = {
    text: "",
    style: "headerLogo",
    width: 64,
    border: [false, false, false, false],
    margin: [0, 0, 0, 0],
  }
  if (!isblank(base64_logo)) {
    var Logo_url = `data:image/png;base64,${base64_logo}`
    Logo = {
      image: Logo_url,
      style: "headerLogo",
      width: 64,
      fit: [64, 64],
      border: [false, false, false, false],
      margin: [0, 0, 0, 0],
    }
  }
  var headerObj = [
    {
      columns: [{ text: receipt_heading, alignment: "center", fontSize: 8, marginBottom: 5 }],
      margin: [15, 8, 15, 0],
    },
    {
      style: "tableExample",
      table: {
        widths: [80, "*", 80],
        body: [
          [
            Logo,
            {
              stack: [
                { text: companyName, style: "companyName", bold: true, marginBottom: 2 },
                { text: company_address1, style: "companyAddress" },
                { text: company_address2, style: "companyAddress" },
                { text: companyContact, style: "companyAddress" },
                { text: companyTaxInfo, style: "companyAddress" },
              ],
              border: [false, false, false, false],
              margin: [0, 0, 0, 0],
            },
            {
              stack: [],
              border: [false, false, false, false],
              margin: [0, 0, 0, 0],
            },
          ],
        ],
      },
      margin: [15, 0, 15, 0],
    },
  ]

  var finalContent = []
  var title = other_type === "payment" ? "PAYMENT" : "RECEIPT"

  let signature = { text: "Signature", alignment: "right", margin: [0, 50, 0, 5] }

  if (receipt_print_signature === 1 && !isblank(base64_signature)) {
    var Signature_url = `data:image/png;base64,${base64_signature}` //`data:image/png;base64,${base64_signature}`
    signature = {
      columns: [
        { text: "Signature", alignment: "right", margin: [0, 50, 0, 5] },
        {
          image: Signature_url,
          style: "headerLogo",
          width: 80,
          fit: [80, 80],
          border: [false, false, false, false],
          margin: [0, 20, 0, 0],
        },
      ],
    }
  }

  if (printdata.hasOwnProperty("DetailGrid") && printdata.DetailGrid.length > 0) {
    const currency = getAccountSitesetting("currency")
    printdata.DetailGrid.forEach((item, index) => {
      let narrationObj = []
      let adjustDetailObj = []
      let transObj = []

      if (!isblank(item.doc_narration) && print_narration_receipt === 1) {
        narrationObj = [
          {
            colSpan: 2,
            field_id: "pnarration",
            table: {
              widths: ["auto", "*"],
              body: [
                [
                  { text: narrHeading, border: [false, false, false, false] },
                  { text: !isblank(item.doc_narration) ? replaceBRtoNewLine(item.doc_narration) : "", bold: true, border: [false, false, false, true] },
                ],
              ],
            },
            layout: {
              defaultBorder: false,
            },
          },
        ]
      }
      if (!isblank(item.doc_adjustment)) {
        adjustDetailObj = [
          {
            colSpan: 2,
            field_id: "padjust",
            table: {
              widths: ["auto", "*"],
              body: [
                [
                  { text: adjustDetailHeading, border: [false, false, false, false] },
                  { text: !isblank(item.doc_adjustment) ? replaceBRtoNewLine(item.doc_adjustment) : "", bold: true, border: [false, false, false, true] },
                ],
              ],
            },
            layout: {
              defaultBorder: false,
            },
          },
        ]
      }
      if (!isblank(receipt_other)) {
        transObj = [
          {
            colSpan: 2,
            table: {
              widths: ["*"],
              body: [[{ text: receipt_other, bold: true, border: [false, false, false, true] }]],
            },
            margin: [5, 0, 0, 5],
            layout: {
              defaultBorder: false,
            },
          },
        ]
      }

      var detailContent = [
        {
          table: {
            heights: [1, 1, 1, 1, 1, 1, 1, 1, 65],
            widths: ["*", 110],
            body: [
              [
                {
                  text: title,
                  fontSize: 11,
                  bold: true,
                  colSpan: 2,
                  alignment: "center",
                },
                {
                  text: "",
                },
              ],
              [
                {
                  table: {
                    widths: ["auto", 100],
                    body: [
                      [
                        { text: `${title} NO`, border: [false, false, false, false] },
                        { text: `#${receiptNo}`, bold: true, border: [false, false, false, true] },
                      ],
                    ],
                  },
                  layout: {
                    defaultBorder: false,
                  },
                },
                {
                  table: {
                    widths: ["auto", "*"],
                    body: [
                      [
                        { text: "DATE", border: [false, false, false, false] },
                        { text: receiptDate, bold: true, border: [false, false, false, true] },
                      ],
                    ],
                  },
                  layout: {
                    defaultBorder: false,
                  },
                },
              ],
              [
                {
                  colSpan: 2,
                  table: {
                    widths: ["auto", "*"],
                    body: [
                      [
                        { text: other_type === "payment" ? "PAID TO" : "RECEIVED WITH THANKS FROM", border: [false, false, false, false] },
                        { text: item.accountName, bold: true, border: [false, false, false, true] },
                      ],
                    ],
                  },
                  layout: {
                    defaultBorder: false,
                  },
                },
              ],
              [
                {
                  colSpan: 2,
                  table: {
                    widths: ["auto", "*"],
                    body: [
                      [
                        { text: "AMOUNT", border: [false, false, false, false] },
                        { text: `${currency} ${amount_format(item.amount)} (${number2text(item.amount)})`, bold: true, border: [false, false, false, true] },
                      ],
                    ],
                  },
                  layout: {
                    defaultBorder: false,
                  },
                },
              ],
              // Conditionally insert transObj here if it is not empty
              ...(transObj.length > 0 ? transObj : []),
              [
                {
                  table: {
                    widths: ["auto", 100],
                    body: [
                      [
                        { text: "BY CASH/CHEQUE NO.", border: [false, false, false, false] },
                        { text: printdata.bankCashGroupCode !== "A0005" ? item.cheque : "Cash", bold: true, border: [false, false, false, true] },
                      ],
                    ],
                  },
                  layout: {
                    defaultBorder: false,
                  },
                },
                {
                  alignment: "right",
                  table: {
                    widths: ["auto", "*"],
                    body: [
                      [
                        { text: "DATE", border: [false, false, false, false] },
                        { text: DatetoDMY(item.chequedate), bold: true, border: [false, false, false, true] },
                      ],
                    ],
                  },
                  layout: {
                    defaultBorder: false,
                  },
                },
              ],
              [
                {
                  colSpan: 2,
                  table: {
                    widths: ["auto", "*"],
                    body: [
                      [
                        { text: "DRAWN ON & BRANCH", border: [false, false, false, false] },
                        { text: !isblank(printdata.bankCashName) ? printdata.bankCashName : "", bold: true, border: [false, false, false, true] },
                      ],
                    ],
                  },
                  layout: {
                    defaultBorder: false,
                  },
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 0
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 0
            },
            paddingLeft: function (i, node) {
              return 10
            },
            paddingRight: function (i, node) {
              return 10
            },
            paddingTop: function (i, node) {
              return 6
            },
            paddingBottom: function (i, node) {
              return 6
            },
          },
        },
      ]

      // narrationObj, and adjustDetailObj if they are not blank

      if (narrationObj.length > 0) {
        detailContent[0].table.body.push(narrationObj)
      }
      if (adjustDetailObj.length > 0) {
        detailContent[0].table.body.push(adjustDetailObj)
      }

      detailContent[0].table.body.push([{ text: receipt_terms, margin: [5, 0, 0, 0] }, signature])

      if (index !== 0) {
        finalContent.push({ text: "", pageBreak: "after" })
      }
      finalContent = [...finalContent, ...detailContent]
    })
  }

  var dd = {
    // a string or { width: number, height: number }
    pageSize: "A5",
    // by default we use portrait, you can change it to landscape if you wish
    pageOrientation: "landscape",
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [15, 90, 15, 15],

    header: headerObj,
    footer: [],
    content: finalContent,
    defaultStyle: {
      fontSize: 9,
    },
    styles: {
      companyAddress: {
        alignment: "center",
        fontSize: 10,
      },
      companyName: {
        bold: true,
        fontSize: 12,
        alignment: "center",
      },
    },
  }
  return dd
}
