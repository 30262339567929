import { default as shared_dynamicTemplate } from "@project/sharedcomponents/dynamicTemplate"
import { default as shared_generateChallanPdf } from "@project/sharedcomponents/generateChallanPdf"
import { default as shared_generateGodownStockTransferPdf } from "@project/sharedcomponents/generateGodownStockTransferPdf"
import { default as shared_generateInwardPdf } from "@project/sharedcomponents/generateInwardPdf"
import { default as shared_generateOutwardPdf } from "@project/sharedcomponents/generateOutwardPdf"
import { default as shared_generateProcessTransferPdf } from "@project/sharedcomponents/generateProcessTransferPdf"
import { default as shared_generateStockTransferPdf } from "@project/sharedcomponents/generateStockTransferPdf"

import { generatePdfDoc } from "@project/components/pdfmakeFunctions"
import { default as shared_generateEwayBillPDF } from "@project/sharedcomponents/generateEwayBillPdf"
import { default as shared_generateGSTR3PDF } from "@project/sharedcomponents/generateGSTR3PDF"
import { default as shared_generateJournalPdf } from "@project/sharedcomponents/generateJournalPdf"
import { default as shared_generateTransactionPdf } from "@project/sharedcomponents/generateTransactionPdf"
import { default as shared_invoiceTemplate2 } from "@project/sharedcomponents/invoiceTemplate2"
import { default as shared_invoiceTemplate3 } from "@project/sharedcomponents/invoiceTemplate3"

import { amount_formatDecimal, numForCalc } from "@project/sharedcomponents/utilityFunctions"

import { generatePackingSlipPdf as shared_generatePackingSlipPdf, multiPackingSlipPrint as shared_multiPackingSlipPrint } from "@project/sharedcomponents/packingSlipPrint"
import { getAccountSessionUserData, getAccountSitesetting, getDdCountryState, getconfigsettingdata, getquarter } from "./commonfunctions"

export const generateProcessTransferPdf = ({ printdata, meta, pageSize, user_data }) => {
  const functions = { getAccountSitesetting, getAccountSessionUserData, getconfigsettingdata }
  return shared_generateProcessTransferPdf({ printdata, meta, pageSize, user_data, functions })
}

export const generateStockTransferPdf = ({ printdata, meta, pageSize, user_data }) => {
  const functions = { getAccountSitesetting, getAccountSessionUserData, getconfigsettingdata }
  return shared_generateStockTransferPdf({ printdata, meta, pageSize, user_data, functions })
}

export const generateGodownStockTransferPdf = ({ printdata, meta, pageSize, user_data }) => {
  const functions = { getAccountSitesetting, getAccountSessionUserData, getconfigsettingdata }
  return shared_generateGodownStockTransferPdf({ printdata, meta, pageSize, user_data, functions })
}

export const generateOutwardPdf = ({ printdata, meta, pageSize, user_data }) => {
  const functions = { getAccountSitesetting, getAccountSessionUserData, amount_formatDecimal, numForCalc, getconfigsettingdata }
  return shared_generateOutwardPdf({ printdata, meta, pageSize, user_data, functions })
}

export const generateChallanPdf = ({ printdata, pageSize }) => {
  const functions = { getAccountSitesetting, getAccountSessionUserData, getconfigsettingdata }
  return shared_generateChallanPdf({ printdata, pageSize, user_data: "", functions })
}

//ah Template
export const invoiceTemplate2 = ({ printdata, meta, pageSize, user_data }) => {
  const functions = { getAccountSitesetting, getAccountSessionUserData, getconfigsettingdata }
  return shared_invoiceTemplate2({ printdata, meta, pageSize, user_data, functions })
}
//wang Template
export const invoiceTemplate3 = ({ printdata, meta, pageSize, user_data }) => {
  const functions = { getAccountSitesetting, getAccountSessionUserData, getconfigsettingdata }
  return shared_invoiceTemplate3({ printdata, meta, pageSize, user_data, functions })
}

export const generateInwardPdf = ({ printdata, meta, pageSize, user_data }) => {
  const functions = { getAccountSitesetting, getAccountSessionUserData, amount_formatDecimal, numForCalc, getconfigsettingdata }
  return shared_generateInwardPdf({ printdata, meta, pageSize, user_data, functions })
}

export const multiPackingSlipPrint = ({ printdata, meta, pageSize, user_data }) => {
  const functions = { getAccountSitesetting, getAccountSessionUserData, getconfigsettingdata }
  return shared_multiPackingSlipPrint({ printdata, meta, pageSize, user_data, functions })
}

export const generatePackingSlipPdf = ({ printdata, meta, pageSize, user_data }) => {
  const functions = { getAccountSitesetting, getAccountSessionUserData, getconfigsettingdata }
  return shared_generatePackingSlipPdf({ printdata, meta, pageSize, user_data, functions })
}
export const dynamicTemplate = ({ printdata, meta, user_data }) => {
  const functions = { getAccountSitesetting, getAccountSessionUserData, dd_country_state: getDdCountryState(), generatePdfDoc, getconfigsettingdata }
  return shared_dynamicTemplate({ print_data: printdata, meta, user_data, functions })
}

export const generateTransactionPdf = ({ printdata, other_type }) => {
  const functions = { getAccountSitesetting, getAccountSessionUserData, other_type, getconfigsettingdata }
  return shared_generateTransactionPdf({ printdata, user_data: "", functions })
}

export const generateJournalPdf = ({ printdata }) => {
  const functions = { getAccountSitesetting, getAccountSessionUserData, getconfigsettingdata }
  return shared_generateJournalPdf({ printdata, user_data: "", functions })
}

export const generateGSTR3PDF = ({ data, formValues }) => {
  const functions = { getAccountSitesetting, getAccountSessionUserData, getquarter }
  return shared_generateGSTR3PDF({ data, meta: formValues, user_data: "", functions })
}

export const generateEwayBillPdf = ({ printdata, meta, pageSize }) => {
  const functions = { getAccountSitesetting, getAccountSessionUserData }
  return shared_generateEwayBillPDF({ printdata, meta, pageSize, functions })
}
