import { getdropdownvalue } from "@project/components/utilityFunctions"
import { convertIntif, getNumberonly } from "@project/sharedcomponents/utilityFunctions"
import {
  WIRETRANSFER_SEARCH_TEXT_UPDATE,
  WIRETRANSFER_SERVICE_DELETED,
  WIRETRANSFER_SERVICE_FAIL,
  WIRETRANSFER_SERVICE_INSERTED,
  WIRETRANSFER_SERVICE_START,
  WIRETRANSFER_SERVICE_SUCCESS,
  WIRETRANSFER_SERVICE_UPDATED,
} from "../Actions/types"
const INITIAL_STATE = {
  current_tab_index: 0,
  error: "",
  loading: false,
  data: [],
  totalcount: 0,
  rowsPerPage: 20,
  page: 0,
  order: "asc",
  norecord: false,
  deleted: false,
  orderBy: "id",
  search_text: "",
  columnData: [
    { id: "code", numeric: false, visible: false, label: "Code", width: 100 },
    { id: "wire_date", numeric: false, visible: true, label: "Wire Date", width: 125 },
    { id: "wire_type", numeric: false, visible: true, label: "Wire Type", width: 125 },
    { id: "sender_ac_holder", numeric: false, visible: true, label: "Sender" },
    { id: "beneficiary_ac_holder", numeric: false, visible: true, label: "Beneficiary" },
    { id: "wire_totalamount", numeric: true, visible: true, label: "Total Amount", width: 150, format: "indian_rupee" },
    { id: "cheque_no", numeric: false, visible: true, label: "Cheque Number", width: 175 },
  ],
  metadata: "",
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WIRETRANSFER_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case WIRETRANSFER_SEARCH_TEXT_UPDATE:
      return { ...state, search_text: action.search_text, norecord: false }

    case WIRETRANSFER_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.result,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        islogin: true,
        norecord: action.payload.data.result.length === 0,
      }
    case WIRETRANSFER_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        data: [],
        loading: false,
        totalcount: 0,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
        norecord: true,
      }

    case WIRETRANSFER_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_code
      inserteditem.wire_date = action.payload.paymentDate
      inserteditem.wire_type = action.payload.type
      inserteditem.sender_ac_holder = getdropdownvalue({ formValues: action.payload, field: "senderSelectedValue", prop: "name" })
      inserteditem.beneficiary_ac_holder = getdropdownvalue({ formValues: action.payload, field: "beneficiarySelectedValue", prop: "name" })
      inserteditem.wire_totalamount = action.payload.amount
      inserteditem.cheque_no = action.payload.chequeNumber
      return { ...state, data: [inserteditem, ...state.data] }

    case WIRETRANSFER_SERVICE_DELETED:
      if (action.otherDetail === "multi_delete") {
        var finalData = []
        var applyToDelete = action.payload.code.codes.map((a) => a.toString())
        if (action.response.data.meta.code === 403) {
          //Error
          var NotDelete = []
          action.response.data.meta.message.forEach((element) => {
            var codes = element.code.toString().split(",")
            NotDelete = [...NotDelete, ...codes]
          })
          NotDelete = NotDelete.map((e) => e.toString())
          finalData = state.data.filter((item) => {
            item.code = item.code.toString()
            if (!(applyToDelete.includes(item.code) && !NotDelete.includes(item.code))) {
              return item
            }
            return false
          })
        } //All delete
        else {
          finalData = state.data.filter((item) => {
            item.code = item.code.toString()
            if (!applyToDelete.includes(item.code)) {
              return item
            }
            return false
          })
        }
        return { ...state, data: finalData, deleted: !state.deleted }
      } else {
        return { ...state, data: state.data.filter((item) => item.code.toString() !== action.payload.code.toString()), deleted: !state.deleted, totalcount: parseInt(getNumberonly(state.totalcount)) - 1 }
      }
    case WIRETRANSFER_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.update_code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.wire_date = action.payload.paymentDate
        updateditem.wire_type = action.payload.type
        updateditem.sender_ac_holder = getdropdownvalue({ formValues: action.payload, field: "senderSelectedValue", prop: "name" })
        updateditem.beneficiary_ac_holder = getdropdownvalue({ formValues: action.payload, field: "beneficiarySelectedValue", prop: "name" })
        updateditem.wire_totalamount = action.payload.amount
        updateditem.cheque_no = action.payload.chequeNumber
        return updateditem
      })
      return { ...state, data: updateddata }
    default:
      return state
  }
}
