import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItemSecondaryAction } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import Avatar from "@material-ui/core/Avatar"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import { makeStyles } from "@material-ui/core/styles"
import { Check } from "@material-ui/icons"
import AppsIcon from "@material-ui/icons/Apps"
import BusinessIcon from "@material-ui/icons/Business"
import EditIcon from "@material-ui/icons/Edit"
import OpenInNew from "@material-ui/icons/OpenInNew"
import Settings from "@material-ui/icons/Settings"
import TodayIcon from "@material-ui/icons/Today"
import accounting_logo from "@project/components/Images/account.svg"
import payroll_logo from "@project/components/Images/payroll.svg"
import { encryptionV2 } from "@project/components/cryptoJSFunctions"
import { TabContainer } from "@project/components/materialUiFunctions"
import { getStorage } from "@project/components/sessionStoreFunctions"
import { getAllFBModules } from "@project/components/utilityFunctions"
import { isblank } from "@project/sharedcomponents/utilityFunctions"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { changeURL, getAccountSessionUserData, getfinancialyear, getheaders, getmyorganisation, goToNavigate } from "../../Lib/commonfunctions"

import { gotoPage } from "../../Actions"

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 640,
    minHeight: 350,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  tabContainer: {
    maxHeight: 300,
    overflow: "auto",
  },
  wrapper: {
    flexDirection: "row",
  },
}))

export default function FBApps({ change_module, year, active_module, close_popover, handleyearChange }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  var activeModule = active_module ? active_module : "accounting"
  var userModuleRights = []
  if (!isblank(year)) {
    userModuleRights = getStorage(`userModuleRights_${year}`, "")
  } else {
    userModuleRights = getStorage(`userModuleRights`, "")
  }
  userModuleRights = userModuleRights && userModuleRights.length > 0 ? userModuleRights.map((a) => a.name) : []
  const [openyear, setopenyear] = useState(false)
  const [opencompany, setopencompany] = useState(false)
  const [value, set_Value] = useState(0)
  const [company, setcompany] = useState("")
  const [changeyear, setchangeyear] = useState("")

  const handleTabChange = (event, newValue) => {
    set_Value(newValue)
  }

  const onOrganizationPress = (row) => {
    if (getAccountSessionUserData("company_url") !== row.company_url) {
      setopencompany(true)
      setcompany(row)
    }
  }

  const manageorganizations = () => {
    close_popover()
    dispatch(gotoPage({ key: "manageorganizations" }))
  }

  const handleNewYearDialogue = (value) => {
    if (value !== getAccountSessionUserData("financial_year")) {
      setchangeyear(value)
      setopenyear(true)
    }
  }

  const handleyearClose = () => {
    setopenyear(false)
  }

  const handlecompanyClose = () => {
    setopencompany(false)
  }

  const handlecompanyChangeButtonClick = () => {
    close_popover()
    setopencompany(false)
    goToNavigate("/")
    var data = {
      name: company.name,
      id: company.company_url,
      token: getheaders(),
      user: getAccountSessionUserData("name"),
      year: parseInt(getAccountSessionUserData("financial_year"), 10),
      old: getAccountSessionUserData("company_url"),
    }

    var encrypted = encryptionV2(data)
    window.location.href = changeURL(company.company_url, `/switchcompany/${encrypted}`)
  }

  const handleyearChangeButtonClick = () => {
    close_popover()
    setopenyear(false)
    handleyearChange(changeyear)
  }

  return (
    <>
      <Box className={classes.paper}>
        <AppBar color="transparent" position="static">
          <Tabs value={value} onChange={handleTabChange} aria-label="menu-tabs" variant="scrollable">
            <Tab
              data-testid="fmenu_switchYear"
              label={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <TodayIcon style={{ marginRight: 4 }} /> Switch Year
                  </div>
                </>
              }
            />
            <Tab
              data-testid="fmenu_organizations"
              label={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <BusinessIcon style={{ marginRight: 4 }} /> Organizations
                  </div>
                </>
              }
            />
            <Tab
              data-testid="fmenu_otherApps"
              label={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <AppsIcon style={{ marginRight: 4 }} /> Other Apps
                  </div>
                </>
              }
            />
          </Tabs>
        </AppBar>
        <TabContainer value={value} index={0}>
          <Box className={classes.tabContainer}>
            <List disablePadding>
              {getfinancialyear().map((row, index) => (
                <ListItem
                  key={`year-${index}`}
                  data-testid={`year_${row.id}_button`}
                  button
                  {...(getAccountSessionUserData("financial_year") !== row.id && {
                    onClick: () => handleNewYearDialogue(row.id),
                  })}
                  selected={getAccountSessionUserData("financial_year") === row.id}
                >
                  <ListItemIcon>
                    <OpenInNew />
                  </ListItemIcon>
                  <ListItemText primary={row.name} data-testid={`year_${row.id}_text`} />
                  {getAccountSessionUserData("financial_year") === row.id && (
                    <ListItemSecondaryAction>
                      <Check fontSize="small" />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
        </TabContainer>
        <TabContainer value={value} index={1}>
          <Box className={classes.tabContainer}>
            <List disablePadding dense>
              <ListItem button onClick={() => manageorganizations()} key="organization">
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary="MANAGE ORGANIZATIONS" secondary="Add/Connect/Remove New Organizations" />
                <ListItemSecondaryAction>
                  <EditIcon fontSize="small" />
                </ListItemSecondaryAction>
              </ListItem>
              {getmyorganisation().map((row, index) => (
                <ListItem
                  key={`org-${index}`}
                  data-testid={`org_${row.id}_button`}
                  button
                  {...(getAccountSessionUserData("company_url") !== row.company_url && {
                    onClick: () => onOrganizationPress(row),
                  })}
                  selected={getAccountSessionUserData("company_url") === row.company_url}
                >
                  <ListItemIcon>
                    <OpenInNew />
                  </ListItemIcon>
                  <ListItemText primary={row.name} secondary={row.company_url} data-testid={`org_${row.id}_button`} />
                  {getAccountSessionUserData("company_url") === row.company_url && (
                    <ListItemSecondaryAction>
                      <Check fontSize="small" />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
        </TabContainer>
        <TabContainer value={value} index={2}>
          <Box className={classes.tabContainer}>
            <List disablePadding className={classes.root}>
              {getAllFBModules().map((item, i) => {
                if (item.name === "Accounting") {
                  return (
                    <ListItem
                      data-testid="fb_module_accounting"
                      key="accounting"
                      button
                      selected={activeModule === "accounting" || !userModuleRights.includes("accounting")}
                      {...(!(activeModule === "accounting" || !userModuleRights.includes("accounting")) && { onClick: () => change_module(item) })}
                    >
                      <ListItemAvatar>
                        <Avatar variant="rounded" src={accounting_logo} />
                      </ListItemAvatar>
                      <ListItemText primary="Accounting" />
                      {(activeModule === "accounting" || !userModuleRights.includes("accounting")) && (
                        <ListItemSecondaryAction>
                          <Check fontSize="small" />
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  )
                } else if (item.name === "Payroll") {
                  return (
                    <ListItem data-testid="fb_module_payroll" key="payroll" button disabled={activeModule === "payroll" || !userModuleRights.includes("payroll")} onClick={() => change_module(item)}>
                      <ListItemAvatar>
                        <Avatar variant="rounded" src={payroll_logo} />
                      </ListItemAvatar>
                      <ListItemText primary="Payroll" />
                    </ListItem>
                  )
                }
                return null
              })}
            </List>
          </Box>
        </TabContainer>
      </Box>

      <Dialog open={openyear} onClose={handleyearClose} aria-labelledby="switch-title" aria-describedby="switch-description">
        <DialogTitle id="switch-title" style={{ paddingBottom: 0 }}>
          Switch Financial Year
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="switch-description">Are you sure want to switch financial year?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="switch_financial_year_cancel" onClick={handleyearClose} variant="text">
            Cancel
          </Button>
          <Button data-testid="switch_financial_year_submit" onClick={handleyearChangeButtonClick} color="primary" variant="text">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={opencompany} onClose={handlecompanyClose} aria-labelledby="switch-title" aria-describedby="switch-description">
        <DialogTitle id="switch-title" style={{ paddingBottom: 0 }}>
          Switch Company
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="switch-description">Are you sure want to switch company?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlecompanyClose} variant="text">
            Cancel
          </Button>
          <Button onClick={handlecompanyChangeButtonClick} color="primary" variant="text">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
