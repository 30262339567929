import lodashMap from "lodash/map"
import lodashSum from "lodash/sum"
import moment from "moment"
import { generateTableData, getTotalOfData } from "./common"
import colors from "./constantData/colors"
import re from "./constantData/reportsEnum"
import { fromDateToDate, getReportHeaders, showDetailInMasterRow } from "./reportFunction"
import {
  DatetoDMY,
  amount_format,
  amount_formatDecimal,
  breakString,
  capitalizeString,
  checkCondition,
  cloneObj,
  format_report_fieldV1,
  getAllOrderStatus,
  getCountryDataById,
  getCustomFieldColumn,
  getNumberonly,
  getNumberonlyDecimal,
  getdropdownfieldvalue,
  isblank,
  replaceBRtoNewLine,
  replacefunction,
  uppercaseString,
} from "./utilityFunctions"

const capitalizeIfRequire = (val) => {
  if (textTransformation === "u") {
    return uppercaseString(val)
  } else if (textTransformation === "c") {
    return capitalizeString(val)
  }
  return val
}

const addCommonContent = (props) => {
  const { reportdata, formValues, user_data, functions, dropDowns, extraTitle } = props
  const { getAccountSitesetting, getAccountSessionUserData, getquarter } = functions
  const gst_report_range = getAccountSitesetting("gst_report_range", user_data)

  var companyName = getAccountSessionUserData("company_name", user_data)
  // var company_address1 = replacefunction(replaceBRtoNewLine(getAccountSessionUserData("company_address1", user_data)))
  var company_address1 = getAccountSessionUserData("company_address1", user_data)
  const company_street = getAccountSessionUserData("company_street", user_data)
  const company_city = getAccountSessionUserData("company_city", user_data)
  const company_state = getAccountSessionUserData("company_state", user_data)
  const company_zip = getAccountSessionUserData("company_zip", user_data)
  const company_country_code = getAccountSessionUserData("company_country_code", user_data)
  let company_address2 = ""
  if (!isblank(company_street)) {
    company_address2 = company_street
  }
  if (!isblank(company_city)) {
    if (!isblank(company_address2)) {
      company_address2 = company_address2 + ", " + company_city
    } else {
      company_address2 = company_city
    }
  }
  if (!isblank(company_state)) {
    if (!isblank(company_address2)) {
      company_address2 = company_address2 + ", " + capitalizeIfRequire(company_state)
    } else {
      company_address2 = capitalizeIfRequire(company_state)
    }
  }
  if (!isblank(company_country_code)) {
    const CountryData = getCountryDataById(company_country_code, dd_country_state)
    if (!isblank(CountryData.name)) {
      if (!isblank(company_address2)) {
        company_address2 = company_address2 + ", " + CountryData.name
      } else {
        company_address2 = CountryData.name
      }
    }
  }
  if (!isblank(company_zip)) {
    if (!isblank(company_address2)) {
      company_address2 = company_address2 + " - " + company_zip
    } else {
      company_address2 = company_zip
    }
  }

  // company_address2 = replacefunction(replaceBRtoNewLine(company_address2))

  var finalcontain = []
  var titlecontain = []
  var LotName = ""
  var reportTitle = reportdata.name
  if (parseInt(reportdata.reportid) === re.SalesOrder && formValues.status !== "All" && !isblank(formValues.status)) {
    reportTitle = `${reportTitle} - ${getdropdownfieldvalue({ dropDownData: getAllOrderStatus(), field: "id", value: formValues.status, displayvalue: "name" })}`
  }

  finalcontain.push({
    columns: [
      { width: "auto", text: "" },
      { width: "*", text: `${companyName}`, style: "header", alignment: "center" },
      { width: "auto", text: "" },
    ],
    margin: [0, 0, 0, 0],
  })

  finalcontain.push({ text: `${company_address1}`, style: "subheader", alignment: "center", bold: false, margin: [0, 0, 0, 0] })
  finalcontain.push({ text: `${company_address2}`, style: "subheader", alignment: "center", bold: false, margin: [0, 0, 0, 0] })
  finalcontain.push({ text: `${reportTitle}`, style: "header", alignment: "center", margin: [0, 4, 0, 0] })

  var headers = getReportHeaders({ reportdata, formValues, dropDowns, getAccountSitesetting })
  const checkFromTo = fromDateToDate(reportdata.reportid)
  if (checkFromTo) {
    headers = headers.filter((e, i) => !(e.label === "From" || e.label === "To"))
    headers.push({
      label: "From",
      value: `${DatetoDMY(formValues.FromDate)} To: ${DatetoDMY(formValues.ToDate)}`,
    })
  }

  if (headers.length > 0) {
    headers.forEach((e) => {
      titlecontain.push({ text: `${e.label}: ${e.value}`, style: "subheader", alignment: "center" })
    })
  }

  if (extraTitle && extraTitle.length > 0) {
    extraTitle.forEach((e) => {
      titlecontain.push({ text: `${e}`, style: "subheader", alignment: "center" })
    })
  }

  if (parseInt(reportdata.reportid) === re.GSTR_1Summary) {
    formValues.FromDate = getdropdownfieldvalue({
      dropDownData: getquarter(gst_report_range === 1 ? "Q" : "M", user_data),
      field: "id",
      value: formValues.quarterSelectedValue,
      displayvalue: "startdate",
    })
    formValues.ToDate = getdropdownfieldvalue({ dropDownData: getquarter(gst_report_range === 1 ? "Q" : "M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "enddate" })
  }

  if (titlecontain.length > 0) {
    finalcontain.push({ stack: titlecontain, margin: [0, 4, 0, 8] })
  }

  return finalcontain
}

const getdocDefinition = (props) => {
  const { reportdata, getAccountSessionUserData, user_data, getAccountSitesetting, finalcontain, Print_page_type } = props
  var datePrint = moment().format("DD-MM-YYYY hh:mm A")
  const tax = getAccountSitesetting("tax", user_data)
  const report_font_size = parseFloat(getAccountSitesetting("report_font_size", user_data))
  const account_type = getAccountSessionUserData("account_type", user_data)

  const reportBodyFontSize = report_font_size
  const reportHeaderFontSize = report_font_size + 2
  const reportSubHeaderFontSize = report_font_size + 1
  const reportExtraDataFontSize = report_font_size - 1

  let print_orientation =
    (account_type === "8" && parseInt(reportdata.reportid) === re.LedgerReport) ||
    checkCondition(reportdata.reportid, [
      re.ProductExpiredReport,
      re.ProductExpiryReport,
      re.InterestReport,
      re.StoneAnalysis,
      re.StoneCostAnalysis,
      re.StoneHistoryReport,
      re.ProcessStockInOutReport,
      re.MultiOutstandingAgeingReport,
    ]) ||
    (checkCondition(reportdata.reportid, [
      re.SalesOrder,
      re.InvoiceMissinginGST,
      re.PaymentRegister,
      re.ReceiptRegister,
      re.Estimate,
      re.GSTR_1Summary,
      re.LiveStockReport,
      re.PurchaseRegister,
      re.SalesRegister,
      re.SalesReturnRegister,
      re.PurchaseReturnRegister,
      re.GSTSummary,
    ]) &&
      tax === 1)
      ? "landscape"
      : "portrait" //GOLD ACCOUNT

  if (!isblank(Print_page_type)) {
    print_orientation = Print_page_type
  }

  return {
    info: {
      title: reportdata.name,
      author: "",
      subject: reportdata.name,
      keywords: "",
    },
    pageSize: "A4",
    pageMargins: [15, 15, 15, 25], //top,left,right,bottom
    pageOrientation: print_orientation,
    content: finalcontain,
    footer: function(currentPage, pageCount) {
      return {
        columns: [
          {
            text: datePrint,
            alignment: "left",
            style: "todaydate",
          },
          {
            text: parseInt(pageCount) !== 1 ? "Page " + parseInt(currentPage) + " of " + pageCount : "",
            alignment: "right",
            style: "pageno",
          },
        ],
      }
    },
    styles: {
      header: {
        bold: true,
        fontSize: reportHeaderFontSize,
        margin: [0, 0, 0, 2],
      },
      subheader: {
        fontSize: reportSubHeaderFontSize,
        margin: [0, 0, 0, 2],
      },
      todaydate: {
        fontSize: reportExtraDataFontSize,
        margin: [15, 4, 0, 0],
      },
      pageno: {
        fontSize: reportExtraDataFontSize,
        margin: [0, 4, 15, 0],
      },
    },
    defaultStyle: {
      fontSize: reportBodyFontSize,
    },
  }
}

export default (props) => {
  const { custom_fields_data, reportdata, formValues, totalDifference, reportLevel, data, requireTotal, user_data, functions, columnDatafinal, subcolumnDatafinal, subsubcolumnDatafinal } = props
  const { getAccountSitesetting, getAccountSessionUserData, getNameFromType, setSettingFieldValue } = functions
  const siteSettingForformatField = {
    inventory_decimalpoint: getAccountSitesetting("inventory_decimalpoint", user_data),
    rate_decimalpoint: getAccountSitesetting("rate_decimalpoint", user_data),
    timezone: getAccountSitesetting("timezone", user_data),
    currency: getAccountSitesetting("currency", user_data, "symbol"),
  }
  const showline_in_fReports = parseInt(getAccountSitesetting("showline-in-fReports"))
  const adjust_bill = parseInt(getAccountSitesetting("adjust_bill", user_data))

  let detailColumn = []
  let detailDetailColumn = []
  let report_level = isblank(reportLevel) ? 1 : reportLevel

  var masterColumn = columnDatafinal?.filter((column) => {
    if (column.visible && !column.noprint) {
      if (isblank(column.display)) {
        return true
      } else if (getAccountSitesetting(column.display, user_data) === 1) {
        return true
      }
      return false
    }
    return false
  })
  if (!isblank(subcolumnDatafinal) && subcolumnDatafinal.length > 0) {
    detailColumn = subcolumnDatafinal?.filter((column) => {
      if (column.visible && !column.noprint) {
        if (isblank(column.display)) {
          return true
        } else if (getAccountSitesetting(column.display, user_data) === 1) {
          return true
        }
        return false
      }
      return false
    })
  }
  if (!isblank(subcolumnDatafinal) && subcolumnDatafinal.length > 0) {
    detailDetailColumn = subsubcolumnDatafinal?.filter((column) => {
      if (column.visible && !column.noprint) {
        if (isblank(column.display)) {
          return true
        } else if (getAccountSitesetting(column.display, user_data) === 1) {
          return true
        }
        return false
      }
      return false
    })
  }

  if (isblank(masterColumn)) {
    masterColumn = []
  }

  if (isblank(detailColumn)) {
    detailColumn = []
  }

  if (isblank(detailDetailColumn)) {
    detailDetailColumn = []
  }

  var printdata = data

  if (parseInt(reportdata.reportid) === re.GoodsReceivedNoteRegister && formValues.order_view_type === "summary") {
    //GOODSRECEIVEDNOTES
    report_level = 1

    printdata = showDetailInMasterRow({ data: printdata, user_data, functions: { getAccountSitesetting } })
  }

  var PDF_MASTER_COL_WIDTHS = masterColumn.map((column) => {
    if (column.numeric === true || column.format === "dmy") {
      return "auto"
    } else {
      return column.width ? column.width * 0.4 : "*"
    }
  })
  var PDF_COL_WIDTHS = detailColumn.map((column) => {
    if (column.numeric === true || column.format === "dmy") {
      return "auto"
    } else {
      return column.width ? column.width * 0.4 : "*"
    }
  })
  var PDF_DETAIL_DETAIL_COL_WIDTHS = detailDetailColumn.map((column) => {
    if (column.numeric === true || column.format === "dmy") {
      return "auto"
    } else {
      return column.width ? column.width * 0.4 : "*"
    }
  })

  var finalcontain = addCommonContent(props)
  var PDF_MASTER_HEADING = ""
  var PDF_HEADING = ""
  var PDF_DETAIL_DETAIL_HEADING = ""
  var PDF_MASTER_DATA = ""
  var PDF_DATA = ""
  var PDF_DETAIL_DETAIL_DATA = ""
  var finaldata = []
  var reportViewType = isblank(formValues.reportViewType) ? "0" : formValues.reportViewType
  if (parseInt(reportdata.reportid) === re.LotCostingReport) {
    Object.keys(printdata)
      .filter((e) => e !== "costing_data" && e !== "OtherCharges" && e !== "costPerQty" && e !== "costPerQty" && e !== "totalQty" && e !== "modifiedCostPerQty" && e !== "total_otherCharges")
      .map((key) => {
        if (key === "packing_slip_mix") {
          masterColumn = [
            ...masterColumn.filter((e) => e.id !== "rate" && e.id !== "netamt"),
            { id: "lotqty", numeric: true, total: true, format: "qty_decimal", textbox: true, visible: true, label: "Lot Qty", width: 150 },
          ]
        }
        PDF_HEADING = masterColumn.map((column) => {
          var align = column.numeric ? "right" : "left"
          return { text: column.label, bold: true, alignment: align }
        })
        if (printdata[key].length > 0) {
          if (key !== "packing_slip_mix") {
            finalcontain.push({
              text: [{ text: getNameFromType(key, false, user_data), style: "subheader", bold: true }],
              margin: [0, 10, 0, 2],
            })
          } else {
            finalcontain.push({
              text: [{ text: "Packing Slip Mix", style: "subheader", bold: true }],
              margin: [0, 10, 0, 2],
            })
          }
          finaldata = printdata[key]
          PDF_DATA = generateTableData({ data: finaldata, mycol: masterColumn, user_data, requireTotal, totalDifference, functions })
          finalcontain.push({
            style: "tableExample",
            table: {
              headerRows: 1,
              widths: cloneObj(PDF_COL_WIDTHS),
              body: [cloneObj(PDF_HEADING), ...PDF_DATA],
            },
            layout: {
              fillColor: function(rowIndex) {
                return rowIndex === 0 ? colors.pdfTableHeader : null
              },
              hLineWidth: function(i, node) {
                return 0.1
              },
              vLineWidth: function(i, node) {
                return 0.1
              },
            },
          })
        }
      })
    var FooterDetail = [
      [{ text: "Total Expense" }, { text: siteSettingForformatField.currency + amount_format(printdata.totalAmount), alignment: "right" }],
      [{ text: "Total Stock Qty" }, { text: amount_formatDecimal(printdata.totalQty, siteSettingForformatField.inventory_decimalpoint), alignment: "right" }],
      [{ text: "Costing As Per Book(Per Unit)" }, { text: siteSettingForformatField.currency + amount_format(printdata.costPerQty), alignment: "right" }],
    ]

    if (printdata.total_otherCharges > 0) {
      FooterDetail = [
        ...FooterDetail,
        [{ text: "Other Charges" }, { text: siteSettingForformatField.currency + amount_format(printdata.total_otherCharges), alignment: "right" }],
        [{ text: "Modified Costing(Per Unit)" }, { text: siteSettingForformatField.currency + amount_format(printdata.modifiedCostPerQty), alignment: "right" }],
      ]
    }

    var otherDetail = {}
    if (!isblank(printdata.OtherCharges) && printdata.OtherCharges.length > 0) {
      var otherDetailArray = printdata.OtherCharges.filter((e) => !isblank(e.accountName) && !isblank(e.amount))
      if (otherDetailArray.length > 0) {
        var totalOtherCharges = 0
        var Other_Detail = otherDetailArray.map((n, index) => {
          var row = [{ text: n.accountName }, { text: amount_format(n.amount), alignment: "right" }]
          totalOtherCharges = totalOtherCharges + parseFloat(getNumberonly(n.amount))
          return row
        })

        Other_Detail.push([
          { text: "Total", bold: true, alignment: "right" },
          { text: amount_format(totalOtherCharges), bold: true, alignment: "right" },
        ])

        otherDetail = [
          { text: `Other Charges`, margin: [0, 5, 0, 2], bold: true },
          {
            style: "tableExample",
            width: "*",
            table: {
              headerRows: 1,
              widths: ["*", 80],
              body: [
                [
                  { text: "Account", bold: true },
                  { text: "Amount", bold: true },
                ],
                ...Other_Detail,
              ],
            },
            layout: {
              fillColor: function(rowIndex) {
                return rowIndex === 0 ? colors.pdfTableHeader : null
              },
              hLineWidth: function(i, node) {
                return 0.1
              },
              vLineWidth: function(i, node) {
                return 0.1
              },
            },
          },
        ]
      }
    }
    finalcontain.push({
      columns: [
        otherDetail,
        [
          { text: `Summary`, margin: [5, 5, 0, 2], bold: true },
          {
            style: "tableExample",
            width: 250,
            table: {
              headerRows: 1,
              widths: ["*", 80],
              body: [...FooterDetail],
            },
            layout: {
              hLineWidth: function(i, node) {
                return 0.1
              },
              vLineWidth: function(i, node) {
                return 0.1
              },
            },
            margin: [5, 0, 0, 0],
          },
        ],
      ],
    })
  } else if (report_level === 2) {
    var totalData = {}
    printdata.forEach((k, key) => {
      if (key !== "sum") {
        PDF_MASTER_HEADING = masterColumn.map((column) => {
          var align = column.numeric ? "right" : "left"
          return { text: column.label, bold: true, alignment: align }
        })
        PDF_HEADING = detailColumn.map((column) => {
          var align = column.numeric ? "right" : "left"
          return { text: column.label, bold: true, alignment: align }
        })

        PDF_MASTER_DATA = generateTableData({ data: [k], mycol: masterColumn, user_data, requireTotal: false, totalDifference, functions })
        PDF_DATA = generateTableData({ data: k.DetailGrid, mycol: detailColumn, user_data, requireTotal, totalDifference, functions })

        if (
          parseInt(reportdata.reportid) === re.MultiOutstandingReport ||
          parseInt(reportdata.reportid) === re.MultiOutstandingAgeingReport ||
          parseInt(reportdata.reportid) === re.MultiLedgerReport
        ) {
          finaldata = printdata[key].DetailGrid
        }

        if (requireTotal) {
          masterColumn.forEach((m) => {
            if (m.total === "label") {
              totalData[m.id] = "Grand Total"
            } else if (m.total === "lastRow") {
              if (finaldata.length > 0) {
                if (m.format === "qty_decimal") {
                  totalData[m.id] = amount_formatDecimal(
                    parseFloat(getNumberonlyDecimal(totalData[m.id], siteSettingForformatField.inventory_decimalpoint)) +
                      parseFloat(getNumberonlyDecimal(finaldata[finaldata.length - 1][m.id], siteSettingForformatField.inventory_decimalpoint)),
                    siteSettingForformatField.inventory_decimalpoint
                  )
                } else if (!isblank(m.decimalPoint)) {
                  totalData[m.id] = amount_formatDecimal(
                    parseFloat(getNumberonlyDecimal(totalData[m.id], m.decimalPoint)) + parseFloat(getNumberonlyDecimal(finaldata[finaldata.length - 1][m.id], m.decimalPoint)),
                    m.decimalPoint
                  )
                } else {
                  if (m.format) {
                    totalData[m.id] = format_report_fieldV1({
                      value: parseFloat(getNumberonly(totalData[m.id])) + finaldata[finaldata.length - 1][m.id],
                      format: m.format,
                      localSetting: setSettingFieldValue,
                    })
                  } else {
                    totalData[m.id] = amount_format(parseFloat(getNumberonly(totalData[m.id])) + parseFloat(getNumberonly(finaldata[finaldata.length - 1][m.id])))
                  }
                }
              }
            } else if (m.total) {
              if (m.format === "qty_decimal") {
                totalData[m.id] = amount_formatDecimal(
                  lodashSum(lodashMap(printdata, (d) => parseFloat(getNumberonlyDecimal(d[m.id], siteSettingForformatField.inventory_decimalpoint)))),
                  siteSettingForformatField.inventory_decimalpoint
                )
              } else if (!isblank(m.decimalPoint)) {
                totalData[m.id] = amount_formatDecimal(
                  parseFloat(getNumberonlyDecimal(totalData[m.id], m.decimalPoint)) + lodashSum(lodashMap(finaldata, (d) => parseFloat(getNumberonlyDecimal(d[m.id], m.decimalPoint)))),
                  m.decimalPoint
                )
              } else {
                if (m.format) {
                  totalData[m.id] = format_report_fieldV1({
                    value: lodashSum(lodashMap(printdata, (d) => parseFloat(getNumberonly(d[m.id])))),
                    format: m.format,
                    localSetting: siteSettingForformatField,
                  })
                } else {
                  totalData[m.id] = amount_format(lodashSum(lodashMap(printdata, (d) => parseFloat(getNumberonly(d[m.id])))))
                }
              }
            } else {
              totalData[m.id] = ""
            }
          })
        }

        finalcontain.push({
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: cloneObj(PDF_MASTER_COL_WIDTHS),
            body: [cloneObj(PDF_MASTER_HEADING), ...PDF_MASTER_DATA],
          },
          layout: {
            fillColor: function(rowIndex) {
              return rowIndex === 0 ? colors.pdfTableHeader : null
            },
            hLineWidth: function(i, node) {
              return 0.1
            },
            vLineWidth: function(i, node) {
              return 0.1
            },
          },
        })
        finalcontain.push({
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: PDF_COL_WIDTHS.length > 0 ? cloneObj(PDF_COL_WIDTHS) : cloneObj(PDF_MASTER_COL_WIDTHS),
            body: [cloneObj(PDF_HEADING), ...PDF_DATA],
          },
          layout: {
            fillColor: function(rowIndex) {
              return rowIndex === 0 ? colors.pdfDetailTableHeader : null
            },
            hLineWidth: function(i, node) {
              return 0.1
            },
            vLineWidth: function(i, node) {
              return 0.1
            },
          },
          margin: [0, 8, 0, 8],
        })

        if (
          parseInt(reportdata.reportid) === re.In_OutRegister ||
          parseInt(reportdata.reportid) === re.PackingSlipRegister ||
          parseInt(reportdata.reportid) === re.GoodsReceivedNoteRegister ||
          parseInt(reportdata.reportid) === re.DeliveryChallanRegister ||
          parseInt(reportdata.reportid) === re.JobInwardRegister ||
          parseInt(reportdata.reportid) === re.FinishStockRegister ||
          parseInt(reportdata.reportid) === re.ManageLotRegister ||
          parseInt(reportdata.reportid) === re.JobInwardsWithoutJobInvoice
        ) {
          var parameterizedDetail = []
          printdata[key].DetailGrid.forEach((item) => {
            if (!isblank(item.itemParameterizedDetail) && item.itemParameterizedDetail.length > 0) {
              parameterizedDetail.push({ item: item.item, detail: item.itemParameterizedDetail })
            }
          })

          if (parameterizedDetail.length > 0) {
            parameterizedDetail.forEach((it) => {
              var paramColumn = [
                { id: "description", numeric: false, visible: true, label: "Description" },
                ...getCustomFieldColumn({ ddcustomfield: custom_fields_data, type: "detailLevel2" }),
                { id: "qtydata", numeric: true, visible: true, total: true, label: "Qty", width: 100, format: "qty_decimal" },
              ]

              var PARAM_COL_WIDTHS = paramColumn.map((column) => {
                if (column.numeric === true || column.format === "dmy") {
                  return "auto"
                } else {
                  return column.width ? column.width * 0.4 : "*"
                }
              })

              var PARAM_HEADING = paramColumn.map((column) => {
                var align = column.numeric ? "right" : "left"
                return { text: column.label, bold: true, alignment: align }
              })

              var PARAM_DATA = generateTableData({ data: it.detail, mycol: paramColumn, user_data, requireTotal: false, totalDifference: false, functions })
              finalcontain.push({
                columns: [
                  { text: `${it.item}`, style: "subheader", margin: [0, 10, 0, 2], width: 210 },
                  {
                    style: "tableExample",
                    width: "*",
                    margin: [5, 10, 0, 0],
                    table: {
                      headerRows: 1,
                      widths: cloneObj(PARAM_COL_WIDTHS),
                      body: [cloneObj(PARAM_HEADING), ...PARAM_DATA],
                    },
                    layout: {
                      fillColor: function(rowIndex) {
                        return rowIndex === 0 ? colors.pdfTableHeader : null
                      },
                      hLineWidth: function(i, node) {
                        return 0.1
                      },
                      vLineWidth: function(i, node) {
                        return 0.1
                      },
                    },
                  },
                ],
              })
            })
          }
        }
      }
    })

    if (requireTotal) {
      var TOTAL_DATA = generateTableData({
        data: [totalData],
        mycol: masterColumn.map((m) => {
          const updated = { ...m }
          updated.bold = true
          if (updated.total === "label") {
            updated.numeric = true
          }
          return updated
        }),
        user_data,
        requireTotal: false,
        totalDifference: false,
        functions,
      })

      PDF_MASTER_COL_WIDTHS = masterColumn.map((column) => {
        if (column.numeric === true || column.format === "dmy") {
          return "auto"
        } else {
          return column.width ? column.width * 0.4 : "*"
        }
      })

      finalcontain.push({
        style: "tableExample",
        table: {
          widths: cloneObj(PDF_MASTER_COL_WIDTHS),
          body: [...TOTAL_DATA],
        },
        margin: [0, 8, 0, 0],
        layout: {
          hLineWidth: function(i, node) {
            return 0.1
          },
          vLineWidth: function(i, node) {
            return 0.1
          },
        },
      })
    }
  } else if (report_level === 1) {
    PDF_HEADING = masterColumn.map((column) => {
      var align = column.numeric ? "right" : "left"

      if (column.currencySign === true) {
        return { text: column.label + "(" + siteSettingForformatField.currency + ")", bold: true, alignment: align }
      } else {
        return { text: column.label, bold: true, alignment: align }
      }
    })

    PDF_COL_WIDTHS = masterColumn.map((column) => {
      if (column.numeric === true || column.format === "dmy") {
        return "auto"
      } else {
        return column.width ? column.width * 0.4 : "*"
      }
    })

    if (adjust_bill === 1 && (parseInt(reportdata.reportid) === re.OutstandingReport || parseInt(reportdata.reportid) === re.OutstandingAgeingReport) && reportViewType === "1") {
      if (reportViewType === "1" && ((!isblank(printdata.bills) && printdata.bills.length > 0) || (!isblank(printdata.onaccountDetail) && printdata.onaccountDetail.length > 0))) {
        if (!isblank(printdata.mobile)) {
          finalcontain.push({ text: `Mobile: ${printdata.mobile}`, style: "subheader", margin: [0, 0, 0, 0] })
        }
      }

      if (reportViewType === "1" && !isblank(printdata.bills) && printdata.bills.length > 0) {
        PDF_HEADING = masterColumn.map((column) => {
          var align = column.numeric ? "right" : "left"
          return { text: column.label, bold: true, alignment: align }
        })
        finalcontain.push({ text: `Invoices`, style: "subheader", margin: [0, 5, 0, 2] })
        finaldata = printdata.bills
        PDF_DATA = generateTableData({ data: finaldata, mycol: masterColumn, user_data, requireTotal, totalDifference, functions })

        finalcontain.push({
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: PDF_COL_WIDTHS.length > 0 ? cloneObj(PDF_COL_WIDTHS) : cloneObj(PDF_MASTER_COL_WIDTHS),
            body: [cloneObj(PDF_HEADING), ...PDF_DATA],
          },
          layout: {
            fillColor: function(rowIndex) {
              return rowIndex === 0 ? colors.pdfTableHeader : null
            },
            hLineWidth: function(i, node) {
              return 0.1
            },
            vLineWidth: function(i, node) {
              return 0.1
            },
          },
        })
      }

      if (reportViewType === "1" && !isblank(printdata.onaccountDetail) && printdata.onaccountDetail.length > 0) {
        PDF_HEADING = masterColumn.map((column) => {
          var align = column.numeric ? "right" : "left"
          return { text: column.label, bold: true, alignment: align }
        })
        finalcontain.push({ text: `Onaccount`, style: "subheader", margin: [0, 5, 0, 2] })
        finaldata = printdata.onaccountDetail

        PDF_DATA = generateTableData({ data: finaldata, mycol: masterColumn, user_data, requireTotal, totalDifference, functions })

        if (!isblank(printdata.bills) && printdata.bills.length > 0 && !isblank(printdata.onaccountDetail) && printdata.onaccountDetail.length > 0) {
          finaldata = []
          var updatedItem = {}
          masterColumn.forEach((element) => {
            updatedItem[element.id] = ""
            if (element.difference === true || element.difference === "true") {
              updatedItem[element.id] =
                lodashSum(lodashMap(printdata.bills, (d) => parseFloat(getNumberonly(d[element.id])))) -
                lodashSum(lodashMap(printdata.onaccountDetail, (d) => parseFloat(getNumberonly(d[element.id]))))
            }
            if (element.difference !== true && element.difference !== "true" && element.difference) {
              updatedItem[element.id] = element.difference
            }
          })
          finaldata[0] = updatedItem

          const diffData = generateTableData({ data: finaldata, mycol: masterColumn, user_data, requireTotal: false, totalDifference, functions })

          /* finalcontain.push({
            style: "tableExample",
            table: {
              headerRows: 1,
              // widths: PDF_COL_WIDTHS.length > 0 ? PDF_COL_WIDTHS : PDF_MASTER_COL_WIDTHS,
              widths: PDF_MASTER_COL_WIDTHS,
              body: [...PDF_DATA],
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0.1
              },
              vLineWidth: function (i, node) {
                return 0.1
              },
            },
          }) */
          PDF_DATA = [...PDF_DATA, ...diffData]
        }

        finalcontain.push({
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: PDF_COL_WIDTHS.length > 0 ? cloneObj(PDF_COL_WIDTHS) : cloneObj(PDF_MASTER_COL_WIDTHS),
            body: [cloneObj(PDF_HEADING), ...PDF_DATA],
          },
          layout: {
            fillColor: function(rowIndex) {
              return rowIndex === 0 ? colors.pdfTableHeader : null
            },
            hLineWidth: function(i, node) {
              return 0.1
            },
            vLineWidth: function(i, node) {
              return 0.1
            },
          },
        })
      }
    } else {
      var oneLevelData = cloneObj(printdata)
      if (parseInt(reportdata.reportid) === re.LedgerReport) {
        oneLevelData = oneLevelData.map((dataItem) => {
          const updatedItem = { ...dataItem }
          if (!isblank(dataItem.Narration)) {
            // updatedItem.Description = `${dataItem.Description}\n${breakString(dataItem.Narration, 25)}`
            updatedItem.Description = `${dataItem.Description}\n${dataItem.Narration}`
          }
          return updatedItem
        })
      } else if (parseInt(reportdata.reportid) === re.ProcessStockInOutReport) {
        var newOneLevelData = []
        var insertedArray = []
        oneLevelData.forEach((item, index) => {
          if (index === 0 || insertedArray.filter((it) => it === `${item.code}___${item.mainType}`).length === 0) {
            newOneLevelData.push({
              bill_no: item.bill_no,
              bill_date: item.bill_date,
              partyname: item.partyname,
              lot_party: item.lot_party,
              title: item.title,
              itemName: item.itemName,
              qty: item.qty,
              penqty: item.penqty,
              lotnumber: item.lotnumber,
              balance: item.balance,
              pcs: item.pcs,
              penpcs: item.penpcs,
              pcs_balance: item.pcs_balance,
            })
            insertedArray.push(`${item.code}___${item.mainType}`)
          } else if (insertedArray.filter((it) => it === `${item.code}___${item.mainType}`).length > 0) {
            newOneLevelData.push({
              bill_no: "",
              bill_date: "",
              partyname: "",
              lot_party: "",
              title: "",
              itemName: item.itemName,
              qty: item.qty,
              penqty: item.penqty,
              lotnumber: "",
              balance: item.balance,
              pcs: item.pcs,
              penpcs: item.penpcs,
              pcs_balance: item.pcs_balance,
            })
          }
        })
        oneLevelData = cloneObj(newOneLevelData)
      } else if (parseInt(reportdata.reportid) === re.ProcessLongation_ShortageReport) {
        var newOneLevelData = []
        var insertedArray = []
        oneLevelData.forEach((item, index) => {
          if (index === 0 || insertedArray.filter((it) => it === `${item.code}___${item.invoice_type}`).length === 0) {
            newOneLevelData.push(item)
            insertedArray.push(`${item.code}___${item.invoice_type}`)
          } else if (insertedArray.filter((it) => it === `${item.code}___${item.invoice_type}`).length > 0) {
            newOneLevelData.push({
              billno_str: "",
              bill_date: "",
              name: "",
              itemname: item.itemname,
              qty: item.qty,
              __cust_longation: item.__cust_longation,
              __cust_shortage: item.__cust_shortage,
            })
          }
        })
        oneLevelData = cloneObj(newOneLevelData)
      }
      PDF_DATA = generateTableData({ data: oneLevelData, mycol: masterColumn, user_data, requireTotal, totalDifference, functions })
      if (parseInt(reportdata.reportid) === re.BalanceSheet || parseInt(reportdata.reportid) === re.P_LAccount || parseInt(reportdata.reportid) === re.TradingAccount) {
        var PDF_COL_WIDTHS1 = PDF_COL_WIDTHS.filter((e, index) => index <= 1)
        var PDF_COL_WIDTHS2 = PDF_COL_WIDTHS.filter((e, index) => index > 1)
        var PDF_DATA1 = PDF_DATA.map((item) => {
          return item.filter((e, index) => index <= 1)
        })
        var PDF_DATA2 = PDF_DATA.map((item) => {
          return item.filter((e, index) => index > 1)
        })
        var TOTAL1 = PDF_DATA1.filter((e, index) => index >= PDF_DATA1.length - 2)
        var TOTAL2 = PDF_DATA2.filter((e, index) => index >= PDF_DATA2.length - 2)
        var PDF_DATA1 = PDF_DATA1.filter((e, index) => index < PDF_DATA1.length - 2)
        var PDF_DATA2 = PDF_DATA2.filter((e, index) => index < PDF_DATA2.length - 2)

        PDF_DATA1 = PDF_DATA1.filter((item) => {
          var itemAccept = false
          item.forEach((e) => {
            if (e.text !== "") {
              itemAccept = true
            }
          })
          return itemAccept
        })
        PDF_DATA2 = PDF_DATA2.filter((item) => {
          var itemAccept = false
          item.forEach((e) => {
            if (e.text !== "") {
              itemAccept = true
            }
          })
          return itemAccept
        })

        TOTAL1 = TOTAL1.map((item) => {
          item = item.map((e) => {
            if (e.text === "") {
              e.text = "\n"
            }
            return e
          })
          return item
        })
        TOTAL2 = TOTAL2.map((item) => {
          item = item.map((e) => {
            if (e.text === "") {
              e.text = "\n"
            }
            return e
          })
          return item
        })

        if (PDF_DATA1.length === 0) {
          PDF_DATA1.push([{ text: " " }, { text: " " }])
        }
        if (PDF_DATA2.length === 0) {
          PDF_DATA2.push([{ text: " " }, { text: " " }])
        }

        PDF_DATA1 = PDF_DATA1.map((item) => {
          if (showline_in_fReports !== 1) {
            if (!isblank(item[1].text) && isblank(item[0].text)) {
              item[1].border = [false, true, false, false]
            }
          }
          return item
        })

        PDF_DATA2 = PDF_DATA2.map((item) => {
          if (showline_in_fReports !== 1) {
            if (!isblank(item[1].text) && isblank(item[0].text)) {
              item[1].border = [false, true, false, false]
            }
          }
          return item
        })

        var InTableLayout = {
          defaultBorder: false,
        }
        var outTableLayout = {
          hLineWidth: function(i, node) {
            return i === 0 || i === 1 || i === node.table.body.length || i === node.table.body.length - 1 || i === node.table.body.length - 2 ? 1 : 0
          },
          vLineWidth: function(i, node) {
            return i === 0 || i === node.table.widths.length || i === 2 ? 1 : 0
          },
        }

        if (showline_in_fReports === 1) {
          InTableLayout = {
            fillColor: function(rowIndex) {
              return rowIndex === 0 ? colors.pdfTableHeader : null
            },
            hLineWidth: function(i, node) {
              return i === 0 || i === node.table.body.length ? 0 : 1
            },
            vLineWidth: function(i, node) {
              return 0
            },
          }
          outTableLayout = {
            fillColor: function(rowIndex) {
              return rowIndex === 0 ? colors.pdfTableHeader : null
            },
            hLineWidth: function(i, node) {
              return i === 0 || i === 1 || i === node.table.body.length || i === node.table.body.length - 1 || i === node.table.body.length - 2 || showline_in_fReports === 1 ? 1 : 0
            },
            vLineWidth: function(i, node) {
              return i === 0 || i === node.table.widths.length || i === 2 ? 1 : 0
            },
          }
        }

        finalcontain.push({
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: [...PDF_COL_WIDTHS1, ...PDF_COL_WIDTHS2],
            body: [
              [...PDF_HEADING],
              [
                {
                  style: "tableExample",
                  table: {
                    widths: cloneObj(PDF_COL_WIDTHS1),
                    body: [...PDF_DATA1],
                  },
                  layout: InTableLayout,
                  colSpan: 2,
                },
                {},
                {
                  style: "tableExample",
                  table: {
                    widths: cloneObj(PDF_COL_WIDTHS2),
                    body: [...PDF_DATA2],
                  },
                  layout: InTableLayout,
                  colSpan: 2,
                },
                {},
              ],
              [...TOTAL1[0], ...TOTAL2[0]],
              [...TOTAL1[1], ...TOTAL2[1]],
            ],
          },
          layout: outTableLayout,
        })
      } else if (parseInt(reportdata.reportid) === re.TrialBalance) {
        finalcontain.push({
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: cloneObj(PDF_COL_WIDTHS),
            body: [cloneObj(PDF_HEADING), ...PDF_DATA],
          },
          layout: {
            fillColor: function(rowIndex) {
              return rowIndex === 0 ? colors.pdfTableHeader : null
            },
            hLineWidth: function(i, node) {
              return i === 0 || i === 1 || i === node.table.body.length || i === node.table.body.length - 1 || showline_in_fReports === 1 ? 1 : 0
            },
            vLineWidth: function(i, node) {
              return i === 0 || i === node.table.widths.length || showline_in_fReports === 1 ? 1 : 0
            },
          },
        })
      } else {
        finalcontain.push({
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: PDF_COL_WIDTHS.length > 0 ? cloneObj(PDF_COL_WIDTHS) : cloneObj(PDF_MASTER_COL_WIDTHS),
            body: [cloneObj(PDF_HEADING), ...PDF_DATA],
          },
          layout: {
            fillColor: function(rowIndex) {
              return rowIndex === 0 ? colors.pdfTableHeader : null
            },
            hLineWidth: function(i, node) {
              return 0.1
            },
            vLineWidth: function(i, node) {
              return 0.1
            },
          },
        })
      }
    }
  } else if (report_level === 3) {
    if (
      (isblank(subsubcolumnDatafinal) && subsubcolumnDatafinal.length === 0) ||
      ((parseInt(reportdata.reportid) === re.MultiOutstandingReport || parseInt(reportdata.reportid) === re.MultiOutstandingAgeingReport) && formValues.viewTypeSelectedValue === "Detail")
    ) {
      var outstandingtype = isblank(formValues.multiOutstandingSelValue) ? "All" : formValues.multiOutstandingSelValue
      var viewType = isblank(formValues.viewTypeSelectedValue) ? "Detail" : formValues.viewTypeSelectedValue

      printdata.forEach((j, level1) => {
        if (outstandingtype === "All") {
          if (viewType === "Detail" && ((reportViewType === "1" && parseInt(reportdata.reportid) === re.MultiLedgerReport) || reportViewType !== "1")) {
            PDF_MASTER_HEADING = masterColumn.map((column) => {
              var align = column.numeric ? "right" : "left"
              return { text: column.label, bold: true, alignment: align }
            })
            PDF_HEADING = detailColumn.map((column) => {
              var align = column.numeric ? "right" : "left"
              return { text: column.label, bold: true, alignment: align }
            })
            finaldata = printdata[level1].DetailGrid

            PDF_MASTER_DATA = generateTableData({ data: [j], mycol: masterColumn, user_data, requireTotal: false, totalDifference, functions })
            PDF_DATA = generateTableData({ data: finaldata, mycol: detailColumn, user_data, requireTotal, totalDifference, functions })

            finalcontain.push({
              style: "tableExample",
              table: {
                headerRows: 1,
                widths: cloneObj(PDF_MASTER_COL_WIDTHS),
                body: [cloneObj(PDF_MASTER_HEADING), ...PDF_MASTER_DATA],
              },
              layout: {
                fillColor: function(rowIndex) {
                  return rowIndex === 0 ? colors.pdfTableHeader : null
                },
                hLineWidth: function(i, node) {
                  return 0.1
                },
                vLineWidth: function(i, node) {
                  return 0.1
                },
              },
            })

            finalcontain.push({
              style: "tableExample",
              table: {
                headerRows: 1,
                widths: cloneObj(PDF_COL_WIDTHS),
                body: [cloneObj(PDF_HEADING), ...PDF_DATA],
              },
              layout: {
                fillColor: function(rowIndex) {
                  return rowIndex === 0 ? colors.pdfDetailTableHeader : null
                },
                hLineWidth: function(i, node) {
                  return 0.1
                },
                vLineWidth: function(i, node) {
                  return 0.1
                },
              },
              margin: [0, 8, 0, 8],
            })
          }
          if (viewType === "Detail" && reportViewType === "1") {
            if ((!isblank(printdata[level1].bills) && printdata[level1].bills.length > 0) || (!isblank(printdata[level1].onaccountDetail) && printdata[level1].onaccountDetail.length > 0)) {
              PDF_MASTER_HEADING = masterColumn.map((column) => {
                var align = column.numeric ? "right" : "left"
                return { text: column.label, bold: true, alignment: align }
              })

              PDF_MASTER_DATA = generateTableData({ data: [j], mycol: masterColumn, user_data, requireTotal: false, totalDifference, functions })

              finalcontain.push({
                style: "tableExample",
                table: {
                  headerRows: 1,
                  widths: cloneObj(PDF_MASTER_COL_WIDTHS),
                  body: [cloneObj(PDF_MASTER_HEADING), ...PDF_MASTER_DATA],
                },
                layout: {
                  fillColor: function(rowIndex) {
                    return rowIndex === 0 ? colors.pdfTableHeader : null
                  },
                  hLineWidth: function(i, node) {
                    return 0.1
                  },
                  vLineWidth: function(i, node) {
                    return 0.1
                  },
                },
              })

              if (!isblank(printdata[level1].bills) && printdata[level1].bills.length > 0) {
                finalcontain.push({
                  text: [{ text: "Invoices", style: "subheader", bold: true }],
                  margin: [0, 2, 0, 2],
                })

                PDF_HEADING = detailColumn.map((column) => {
                  var align = column.numeric ? "right" : "left"
                  return { text: column.label, bold: true, alignment: align }
                })
                finaldata = printdata[level1].bills

                PDF_DATA = generateTableData({ data: finaldata, mycol: detailColumn, user_data, requireTotal, totalDifference, functions })

                PDF_COL_WIDTHS = detailColumn.map((column) => {
                  if (column.numeric === true || column.format === "dmy") {
                    return "auto"
                  } else {
                    return column.width ? column.width * 0.4 : "*"
                  }
                })

                finalcontain.push({
                  style: "tableExample",
                  table: {
                    headerRows: 1,
                    widths: PDF_COL_WIDTHS.length > 0 ? cloneObj(PDF_COL_WIDTHS) : cloneObj(PDF_MASTER_COL_WIDTHS),
                    body: [cloneObj(PDF_HEADING), ...PDF_DATA],
                  },
                  layout: {
                    fillColor: function(rowIndex) {
                      return rowIndex === 0 ? colors.pdfDetailTableHeader : null
                    },
                    hLineWidth: function(i, node) {
                      return 0.1
                    },
                    vLineWidth: function(i, node) {
                      return 0.1
                    },
                  },
                  margin: [0, 8, 0, 0],
                })
              }
            }

            if (!isblank(printdata[level1].onaccountDetail) && printdata[level1].onaccountDetail.length > 0) {
              finalcontain.push({
                text: [{ text: "On Account", style: "subheader", bold: true }],
                margin: [0, 2, 0, 2],
              })

              // detailColumn = detailColumn.filter((e) => e.id !== "due_date")
              const accountDetailColumn = detailColumn.filter((e) => e.id !== "due_date")

              PDF_HEADING = accountDetailColumn.map((column) => {
                var align = column.numeric ? "right" : "left"
                return { text: column.label, bold: true, alignment: align }
              })
              finaldata = printdata[level1].onaccountDetail

              PDF_COL_WIDTHS = accountDetailColumn.map((column) => {
                if (column.numeric === true || column.format === "dmy") {
                  return "auto"
                } else {
                  return column.width ? column.width * 0.4 : "*"
                }
              })

              PDF_DATA = generateTableData({ data: finaldata, mycol: accountDetailColumn, user_data, requireTotal, totalDifference, functions })

              finalcontain.push({
                style: "tableExample",
                table: {
                  headerRows: 1,
                  widths: PDF_COL_WIDTHS.length > 0 ? cloneObj(PDF_COL_WIDTHS) : cloneObj(PDF_MASTER_COL_WIDTHS),
                  body: [cloneObj(PDF_HEADING), ...PDF_DATA],
                },
                layout: {
                  fillColor: function(rowIndex) {
                    return rowIndex === 0 ? colors.pdfDetailTableHeader : null
                  },
                  hLineWidth: function(i, node) {
                    return 0.1
                  },
                  vLineWidth: function(i, node) {
                    return 0.1
                  },
                },
                margin: [0, 8, 0, 8],
              })
            }
            if (!isblank(printdata[level1].bills) && printdata[level1].bills.length > 0 && !isblank(printdata[level1].onaccountDetail) && printdata[level1].onaccountDetail.length > 0) {
              PDF_HEADING = detailColumn.map((column) => {
                var align = column.numeric ? "right" : "left"
                return { text: column.label, bold: true, alignment: align }
              })
              finaldata = []
              var updatedItem = {}
              detailColumn.forEach((element) => {
                updatedItem[element.id] = ""
                if (element.difference === true) {
                  updatedItem[element.id] =
                    lodashSum(lodashMap(printdata[level1].bills, (d) => parseFloat(getNumberonly(d[element.id])))) -
                    lodashSum(lodashMap(printdata[level1].onaccountDetail, (d) => parseFloat(getNumberonly(d[element.id]))))
                }
                if (element.difference !== true && element.difference) {
                  updatedItem[element.id] = element.difference
                }
              })
              finaldata[0] = updatedItem

              PDF_DATA = generateTableData({ data: finaldata, mycol: detailColumn, user_data, requireTotal, totalDifference, functions })

              PDF_COL_WIDTHS = detailColumn.map((column) => {
                if (column.numeric === true || column.format === "dmy") {
                  return "auto"
                } else {
                  return column.width ? column.width * 0.4 : "*"
                }
              })

              finalcontain.push({
                style: "tableExample",
                table: {
                  headerRows: 1,

                  widths: PDF_COL_WIDTHS.length > 0 ? cloneObj(PDF_COL_WIDTHS) : cloneObj(PDF_MASTER_COL_WIDTHS),
                  body: [...PDF_DATA],
                },
                layout: {
                  fillColor: function(rowIndex) {
                    return rowIndex === 0 ? colors.pdfDetailDetailTableHeader : null
                  },
                  hLineWidth: function(i, node) {
                    return 0.1
                  },
                  vLineWidth: function(i, node) {
                    return 0.1
                  },
                },
                margin: [0, 0, 0, 8],
              })
            }
          }
        } else {
          PDF_MASTER_HEADING = masterColumn.map((column) => {
            var align = column.numeric ? "right" : "left"
            return { text: column.label, bold: true, alignment: align }
          })
          PDF_HEADING = detailColumn.map((column) => {
            var align = column.numeric ? "right" : "left"
            return { text: column.label, bold: true, alignment: align }
          })
          PDF_DETAIL_DETAIL_HEADING = detailDetailColumn.map((column) => {
            var align = column.numeric ? "right" : "left"
            return { text: column.label, bold: true, alignment: align }
          })

          PDF_MASTER_DATA = generateTableData({ data: [j], mycol: masterColumn, user_data, requireTotal: false, totalDifference, functions })

          finalcontain.push({
            style: "tableExample",
            table: {
              headerRows: 1,
              widths: cloneObj(PDF_MASTER_COL_WIDTHS),
              body: [cloneObj(PDF_MASTER_HEADING), ...PDF_MASTER_DATA],
            },
            layout: {
              fillColor: function(rowIndex) {
                return rowIndex === 0 ? colors.pdfTableHeader : null
              },
              hLineWidth: function(i, node) {
                return 0.1
              },
              vLineWidth: function(i, node) {
                return 0.1
              },
            },
          })
          // for (var level2 in printdata[level1].DetailGrid) {

          printdata[level1].DetailGrid.forEach((i, level2) => {
            if (viewType === "Detail" && ((reportViewType === "1" && parseInt(reportdata.reportid) === re.MultiLedgerReport) || reportViewType !== "1")) {
              PDF_DATA = generateTableData({ data: [i], mycol: detailColumn, user_data, requireTotal: false, totalDifference, functions })
              finalcontain.push({
                style: "tableExample",
                table: {
                  headerRows: 1,
                  widths: cloneObj(PDF_COL_WIDTHS),
                  body: [cloneObj(PDF_HEADING), ...PDF_DATA],
                },
                layout: {
                  fillColor: function(rowIndex) {
                    return rowIndex === 0 ? colors.pdfDetailTableHeader : null
                  },
                  hLineWidth: function(i, node) {
                    return 0.1
                  },
                  vLineWidth: function(i, node) {
                    return 0.1
                  },
                },
                margin: [0, 8, 0, 8],
              })

              finaldata = printdata[level1].DetailGrid[level2].DetailGrid

              PDF_DETAIL_DETAIL_DATA = generateTableData({ data: finaldata, mycol: detailDetailColumn, user_data, requireTotal, totalDifference, functions })
              finalcontain.push({
                style: "tableExample",
                table: {
                  headerRows: 1,
                  widths: cloneObj(PDF_DETAIL_DETAIL_COL_WIDTHS),
                  body: [cloneObj(PDF_DETAIL_DETAIL_HEADING), ...PDF_DETAIL_DETAIL_DATA],
                },
                layout: {
                  fillColor: function(rowIndex) {
                    return rowIndex === 0 ? colors.pdfDetailDetailTableHeader : null
                  },
                  hLineWidth: function(i, node) {
                    return 0.1
                  },
                  vLineWidth: function(i, node) {
                    return 0.1
                  },
                },
                margin: [0, 8, 0, 8],
              })
            }

            if (
              viewType === "Detail" &&
              reportViewType === "1" &&
              ((!isblank(printdata[level1].DetailGrid[level2].bills) && printdata[level1].DetailGrid[level2].bills.length > 0) ||
                (!isblank(printdata[level1].DetailGrid[level2].onaccountDetail) && printdata[level1].DetailGrid[level2].onaccountDetail.length > 0))
            ) {
              PDF_DATA = generateTableData({ data: [i], mycol: detailColumn, user_data, requireTotal: false, totalDifference, functions })
              finalcontain.push({
                style: "tableExample",
                table: {
                  headerRows: 1,
                  widths: cloneObj(PDF_COL_WIDTHS),
                  body: [cloneObj(PDF_HEADING), ...PDF_DATA],
                },
                layout: {
                  fillColor: function(rowIndex) {
                    return rowIndex === 0 ? colors.pdfDetailTableHeader : null
                  },
                  hLineWidth: function(i, node) {
                    return 0.1
                  },
                  vLineWidth: function(i, node) {
                    return 0.1
                  },
                },
                margin: [0, 8, 0, 8],
              })

              if (!isblank(printdata[level1].DetailGrid[level2].bills) && printdata[level1].DetailGrid[level2].bills.length > 0) {
                finalcontain.push({
                  text: [{ text: "Invoices", style: "subheader", bold: true }],
                  margin: [0, 2, 0, 2],
                })

                finaldata = printdata[level1].DetailGrid[level2].bills
                PDF_DETAIL_DETAIL_DATA = generateTableData({ data: finaldata, mycol: detailDetailColumn, user_data, requireTotal, totalDifference, functions })

                PDF_DETAIL_DETAIL_COL_WIDTHS = detailDetailColumn.map((column) => {
                  if (column.numeric === true || column.format === "dmy") {
                    return "auto"
                  } else {
                    return column.width ? column.width * 0.4 : "*"
                  }
                })

                finalcontain.push({
                  style: "tableExample",
                  table: {
                    headerRows: 1,
                    widths: cloneObj(PDF_DETAIL_DETAIL_COL_WIDTHS),
                    body: [cloneObj(PDF_DETAIL_DETAIL_HEADING), ...PDF_DETAIL_DETAIL_DATA],
                  },
                  layout: {
                    fillColor: function(rowIndex) {
                      return rowIndex === 0 ? colors.pdfDetailDetailTableHeader : null
                    },
                    hLineWidth: function(i, node) {
                      return 0.1
                    },
                    vLineWidth: function(i, node) {
                      return 0.1
                    },
                  },
                  margin: [0, 0, 0, 8],
                })
              }
            }

            if (viewType === "Detail" && reportViewType === "1" && !isblank(printdata[level1].DetailGrid[level2].onaccountDetail) && printdata[level1].DetailGrid[level2].onaccountDetail.length > 0) {
              finalcontain.push({
                text: [{ text: "On Account", style: "subheader", bold: true }],
                margin: [0, 2, 0, 2],
              })
              // detailDetailColumn = detailDetailColumn.filter((e) => e.id !== "due_date")
              const accountDetailDetailColumn = cloneObj(detailDetailColumn.filter((e) => e.id !== "due_date"))
              const onAccountCol = accountDetailDetailColumn.map((column) => {
                var align = column.numeric ? "right" : "left"
                return { text: column.label, bold: true, alignment: align }
              })
              finaldata = printdata[level1].DetailGrid[level2].onaccountDetail

              PDF_DETAIL_DETAIL_COL_WIDTHS = accountDetailDetailColumn.map((column) => {
                if (column.numeric === true || column.format === "dmy") {
                  return "auto"
                } else {
                  return column.width ? column.width * 0.4 : "*"
                }
              })

              PDF_DETAIL_DETAIL_DATA = generateTableData({ data: finaldata, mycol: accountDetailDetailColumn, user_data, requireTotal, totalDifference, functions })

              finalcontain.push({
                style: "tableExample",
                table: {
                  headerRows: 1,
                  widths: cloneObj(PDF_DETAIL_DETAIL_COL_WIDTHS),
                  body: [cloneObj(onAccountCol), ...PDF_DETAIL_DETAIL_DATA],
                },
                layout: {
                  fillColor: function(rowIndex) {
                    return rowIndex === 0 ? colors.pdfDetailDetailTableHeader : null
                  },
                  hLineWidth: function(i, node) {
                    return 0.1
                  },
                  vLineWidth: function(i, node) {
                    return 0.1
                  },
                },
                margin: [0, 0, 0, 8],
              })
            }

            if (
              !isblank(printdata[level1].DetailGrid[level2].bills) &&
              printdata[level1].DetailGrid[level2].bills.length > 0 &&
              !isblank(printdata[level1].DetailGrid[level2].onaccountDetail) &&
              printdata[level1].DetailGrid[level2].onaccountDetail.length > 0
            ) {
              PDF_DETAIL_DETAIL_HEADING = detailDetailColumn.map((column) => {
                var align = column.numeric ? "right" : "left"
                return { text: column.label, bold: true, alignment: align }
              })
              finaldata = []
              var updatedItem = {}
              detailDetailColumn.forEach((element) => {
                updatedItem[element.id] = ""
                if (element.difference === true) {
                  updatedItem[element.id] =
                    lodashSum(lodashMap(printdata[level1].DetailGrid[level2].bills, (d) => parseFloat(getNumberonly(d[element.id])))) -
                    lodashSum(lodashMap(printdata[level1].DetailGrid[level2].onaccountDetail, (d) => parseFloat(getNumberonly(d[element.id]))))
                }
                if (element.difference !== true && element.difference) {
                  updatedItem[element.id] = element.difference
                }
              })
              finaldata[0] = updatedItem

              PDF_DETAIL_DETAIL_COL_WIDTHS = detailDetailColumn.map((column) => {
                if (column.numeric === true || column.format === "dmy") {
                  return "auto"
                } else {
                  return column.width ? column.width * 0.4 : "*"
                }
              })

              PDF_DETAIL_DETAIL_DATA = generateTableData({ data: finaldata, mycol: detailDetailColumn, user_data, requireTotal, totalDifference, functions })
              finalcontain.push({
                style: "tableExample",
                table: {
                  headerRows: 1,

                  widths: cloneObj(PDF_DETAIL_DETAIL_COL_WIDTHS),
                  body: [...PDF_DETAIL_DETAIL_DATA],
                },
                layout: {
                  fillColor: function(rowIndex) {
                    return rowIndex === 0 ? colors.pdfTableHeader : null
                  },
                  hLineWidth: function(i, node) {
                    return 0.1
                  },
                  vLineWidth: function(i, node) {
                    return 0.1
                  },
                },
              })
            }
          })
          // }
        }

        if (
          viewType === "Summary" &&
          outstandingtype !== "All" &&
          (reportViewType !== "1" ||
            (reportViewType === "1" &&
              (parseInt(reportdata.reportid) === re.MultiOutstandingReport ||
                parseInt(reportdata.reportid) === re.MultiLedgerReport ||
                parseInt(reportdata.reportid) === re.MultiOutstandingAgeingReport)))
        ) {
          PDF_HEADING = masterColumn.map((column) => {
            var align = column.numeric ? "right" : "left"
            return { text: column.label, bold: true, alignment: align }
          })

          finaldata = printdata[level1].DetailGrid

          PDF_DATA = generateTableData({ data: finaldata, mycol: masterColumn, user_data, requireTotal, totalDifference, functions })
          finalcontain.push({
            style: "tableExample",
            table: {
              headerRows: 1,

              widths: PDF_COL_WIDTHS.length > 0 ? cloneObj(PDF_COL_WIDTHS) : cloneObj(PDF_MASTER_COL_WIDTHS),
              body: [cloneObj(PDF_HEADING), ...PDF_DATA],
            },
            layout: {
              fillColor: function(rowIndex) {
                return rowIndex === 0 ? colors.pdfTableHeader : null
              },
              hLineWidth: function(i, node) {
                return 0.1
              },
              vLineWidth: function(i, node) {
                return 0.1
              },
            },
          })
        }
      })

      const totalData = getGrandTotal({ requireTotal, masterColumn, printdata, siteSettingForformatField })

      if (requireTotal) {
        PDF_MASTER_COL_WIDTHS = masterColumn.map((column) => {
          if (column.numeric === true || column.format === "dmy") {
            return "auto"
          } else {
            return column.width ? column.width * 0.4 : "*"
          }
        })

        var TOTAL_DATA = generateTableData({
          data: [totalData],
          mycol: masterColumn.map((m) => {
            const updated = { ...m }
            updated.bold = true
            if (updated.total === "label") {
              updated.numeric = true
            }
            return updated
          }),
          user_data,
          requireTotal: false,
          totalDifference: false,
          functions,
        })

        finalcontain.push({
          style: "tableExample",
          table: {
            widths: cloneObj(PDF_MASTER_COL_WIDTHS),
            body: [...TOTAL_DATA],
          },
          margin: [0, 8, 0, 0],
          layout: {
            hLineWidth: function(i, node) {
              return 0.1
            },
            vLineWidth: function(i, node) {
              return 0.1
            },
          },
        })
      }
    }
    if (
      !isblank(subsubcolumnDatafinal) &&
      subsubcolumnDatafinal.length !== 0 &&
      parseInt(reportdata.reportid) !== re.MultiOutstandingReport &&
      parseInt(reportdata.reportid) !== re.MultiOutstandingAgeingReport
    ) {
      printdata.forEach((k, key) => {
        if (key !== "sum") {
          PDF_MASTER_HEADING = masterColumn.map((column) => {
            var align = column.numeric ? "right" : "left"
            return { text: column.label, bold: true, alignment: align }
          })
          PDF_HEADING = detailColumn.map((column) => {
            var align = column.numeric ? "right" : "left"
            return { text: column.label, bold: true, alignment: align }
          })
          PDF_DETAIL_DETAIL_HEADING = detailDetailColumn.map((column) => {
            var align = column.numeric ? "right" : "left"
            return { text: column.label, bold: true, alignment: align }
          })

          PDF_MASTER_DATA = generateTableData({ data: [k], mycol: masterColumn, user_data, requireTotal: false, totalDifference, functions })

          finalcontain.push({
            style: "tableExample",
            table: {
              headerRows: 1,
              widths: cloneObj(PDF_MASTER_COL_WIDTHS),
              body: [cloneObj(PDF_MASTER_HEADING), ...PDF_MASTER_DATA],
            },
            layout: {
              fillColor: function(rowIndex) {
                return rowIndex === 0 ? colors.pdfTableHeader : null
              },
              hLineWidth: function(i, node) {
                return 0.1
              },
              vLineWidth: function(i, node) {
                return 0.1
              },
            },
          })

          k.DetailGrid.forEach((it) => {
            PDF_DATA = generateTableData({ data: [it], mycol: detailColumn, user_data, requireTotal: false, totalDifference, functions })
            finalcontain.push({
              style: "tableExample",
              table: {
                headerRows: 1,
                widths: cloneObj(PDF_COL_WIDTHS),
                body: [cloneObj(PDF_HEADING), ...PDF_DATA],
              },
              layout: {
                fillColor: function(rowIndex) {
                  return rowIndex === 0 ? colors.pdfDetailTableHeader : null
                },
                hLineWidth: function(i, node) {
                  return 0.1
                },
                vLineWidth: function(i, node) {
                  return 0.1
                },
              },
              margin: [0, 8, 0, 8],
            })

            if (parseInt(reportdata.reportid) !== re.MultiLedgerReport) {
              PDF_DETAIL_DETAIL_DATA = generateTableData({ data: it.DetailDetailGrid, mycol: detailDetailColumn, user_data, requireTotal, totalDifference, functions })
            } else {
              PDF_DETAIL_DETAIL_DATA = generateTableData({ data: it.DetailGrid, mycol: detailDetailColumn, user_data, requireTotal, totalDifference, functions })
            }
            finalcontain.push({
              style: "tableExample",
              table: {
                headerRows: 1,
                widths: cloneObj(PDF_DETAIL_DETAIL_COL_WIDTHS),
                body: [cloneObj(PDF_DETAIL_DETAIL_HEADING), ...PDF_DETAIL_DETAIL_DATA],
              },
              layout: {
                fillColor: function(rowIndex) {
                  return rowIndex === 0 ? colors.pdfDetailDetailTableHeader : null
                },
                hLineWidth: function(i, node) {
                  return 0.1
                },
                vLineWidth: function(i, node) {
                  return 0.1
                },
              },
              margin: [0, 0, 0, 8],
            })
          })
        }
      })

      const totalData = getGrandTotal({ requireTotal, masterColumn, printdata, siteSettingForformatField })

      if (requireTotal) {
        var TOTAL_DATA = generateTableData({
          data: [totalData],
          mycol: masterColumn.map((m) => {
            const updated = { ...m }
            updated.bold = true
            if (updated.total === "label") {
              updated.numeric = true
            }
            return updated
          }),
          user_data,
          requireTotal: false,
          totalDifference: false,
          functions,
        })

        PDF_COL_WIDTHS = masterColumn.map((column) => {
          if (column.numeric === true || column.format === "dmy") {
            return "auto"
          } else {
            return column.width ? column.width * 0.4 : "*"
          }
        })

        finalcontain.push({
          style: "tableExample",
          table: {
            widths: PDF_COL_WIDTHS.length > 0 ? cloneObj(PDF_COL_WIDTHS) : cloneObj(PDF_MASTER_COL_WIDTHS),
            body: [...TOTAL_DATA],
          },
          margin: [0, 8, 0, 0],
          layout: {
            hLineWidth: function(i, node) {
              return 0.1
            },
            vLineWidth: function(i, node) {
              return 0.1
            },
          },
        })
      }
    }
  }

  let Print_page_type = ""
  if (
    (formValues.productSerialTypeSelectedValue === "1" || formValues.productSerialTypeSelectedValue === "2") &&
    (parseInt(reportdata.reportid) === re.ProductBatchDetailReport || parseInt(reportdata.reportid) === re.ProductSerialNumberReport)
  ) {
    Print_page_type = "landscape"
  }

  /*if (!isblank(PDF_COL_WIDTHS) && PDF_COL_WIDTHS.length > 6) {
    Print_page_type = "landscape"
  }*/

  return getdocDefinition({ reportdata, getAccountSessionUserData, user_data, getAccountSitesetting, finalcontain, Print_page_type })
}

export const groupedpdf = (props) => {
  const { reportdata, groupBy, data, columnDatafinal, user_data, functions, formValues } = props
  const { getAccountSitesetting, getAccountSessionUserData } = functions

  var masterColumn = columnDatafinal
    .filter((e) => !groupBy.includes(e.id))
    .filter((column) => {
      if (column.visible && !column.noprint) {
        if (isblank(column.display)) {
          return true
        } else if (getAccountSitesetting(column.display, user_data) === 1) {
          return true
        }
        return false
      }
      return false
    })

  var PDF_COL_WIDTHS = masterColumn.map((column) => {
    if (column.numeric === true || column.format === "dmy") {
      return "auto"
    } else {
      return column.width ? column.width * 0.4 : "*"
    }
  })

  var finalcontain = addCommonContent(props)
  var PDF_HEADING = masterColumn.map((column) => {
    var align = column.numeric ? "right" : "left"
    return { text: column.label, bold: true, alignment: align }
  })
  var PDF_DATA = ""

  const getSubRows = (e) => {
    var returnData = []
    if (e.subRows && e.subRows.length > 0) {
      e.subRows.forEach((i) => {
        returnData = [...returnData, ...getSubRows(i)]
      })
    } else {
      returnData = [...returnData, e.values]
    }
    return returnData
  }

  const addTotalItem = (props) => {
    const { printdata, masterColumn, totalValues, totalLabel } = props

    var totalData = []
    var totalItem = {}
    masterColumn.forEach((j, index) => {
      if (index === 0) {
        totalItem[j.id] = totalLabel
      } else if (j.total === true) {
        totalItem[j.id] = totalValues[j.id]
      }
    })
    totalData.push(totalItem)
    var totalRow = generateTableData({
      data: totalData,
      mycol: masterColumn.map((e) => {
        return { ...e, bold: true }
      }),
      user_data,
      requireTotal: false,
      totalDifference: false,
      functions,
    })
    printdata.push({
      style: "tableExample",
      table: {
        headerRows: 1,
        widths: cloneObj(PDF_COL_WIDTHS),
        body: [...totalRow],
      },
      layout: {
        hLineWidth: function(i, node) {
          return 0.1 //(i === 0) ? 0 : 0.1;
        },
        vLineWidth: function(i, node) {
          return 0.1
        },
      },
    })
    return printdata
  }

  const getTotalItem = (props) => {
    const { printdata, masterColumn, totalValues, totalLabel } = props

    var totalData = []
    var totalItem = {}
    masterColumn.forEach((j, index) => {
      if (index === 0) {
        totalItem[j.id] = totalLabel
      } else if (j.total === true) {
        totalItem[j.id] = totalValues[j.id]
      }
    })
    totalData.push(totalItem)
    var totalRow = generateTableData({
      data: totalData,
      mycol: masterColumn.map((e) => {
        return { ...e, bold: true }
      }),
      user_data,
      requireTotal: false,
      totalDifference: false,
      functions,
    })

    return totalRow
  }

  const addRowsInPrintData = (subRows, depth, oldprintdata) => {
    var printdata = [...oldprintdata]
    subRows.forEach((e, index) => {
      if (e.depth === depth) {
        if (groupBy.length - 1 === depth) {
          groupBy.forEach((i) => {
            var topMargin = printdata[printdata.length - 1] && !isblank(printdata[printdata.length - 1].table) ? 10 : 0
            printdata.push({
              text: `${getdropdownfieldvalue({ dropDownData: columnDatafinal, field: "id", value: i, displayvalue: "label" })}: ${e.values[i]}`,
              margin: [0, topMargin, 0, 2],
              bold: true,
            })
          })
          PDF_DATA = generateTableData({ data: getSubRows(e), mycol: masterColumn, user_data, requireTotal: false, totalDifference: false, functions })

          var totalLabel = `${getdropdownfieldvalue(masterColumn, "id", groupBy[depth], "label")} Total`
          const TotalData = getTotalItem({ totalLabel, printdata, masterColumn, totalValues: e.values })

          PDF_COL_WIDTHS = masterColumn.map((column) => {
            if (column.numeric === true || column.format === "dmy") {
              return "auto"
            } else {
              return column.width ? column.width * 0.4 : "*"
            }
          })

          printdata.push({
            style: "tableExample",
            table: {
              // headerRows: 1,
              widths: cloneObj(PDF_COL_WIDTHS),
              body: [cloneObj(PDF_HEADING), ...PDF_DATA, ...TotalData],
            },
            layout: {
              fillColor: function(rowIndex) {
                return rowIndex === 0 ? "#F1F5F7" : null
              },
              hLineWidth: function(i, node) {
                return 0.1
              },
              vLineWidth: function(i, node) {
                return 0.1
              },
            },
          })
          // var totalLabel = `${getdropdownfieldvalue({ dropDownData: masterColumn, field: "id", value: groupBy[depth], displayvalue: "label" })} Total`
          // printdata = addTotalItem({ totalLabel, printdata, masterColumn, totalValues: e.values })
        } else if (e.subRows && e.subRows.length > 0) {
          var newAddedData = [...addRowsInPrintData(e.subRows, depth + 1, printdata)]

          var totalLabel = `${getdropdownfieldvalue({ dropDownData: masterColumn, field: "id", value: groupBy[depth], displayvalue: "label" })} Total`
          printdata = addTotalItem({ totalLabel, printdata: newAddedData, masterColumn, totalValues: e.values })
        }
      }
    })
    return printdata
  }
  finalcontain = [...addRowsInPrintData(data, 0, finalcontain)]
  const Gtotal = getTotalItem({
    totalLabel: "Grand Total",
    printdata: finalcontain,
    masterColumn,
    totalValues: getTotalOfData({ getAccountSitesetting, data: data.map((e) => e.values), columns: masterColumn }),
  })
  finalcontain[finalcontain.length - 1].table.body.push(...Gtotal)
  return getdocDefinition({ reportdata, getAccountSessionUserData, user_data, getAccountSitesetting, finalcontain })
}

const getGrandTotal = ({ requireTotal, masterColumn, printdata, siteSettingForformatField }) => {
  var totalData = {}
  if (requireTotal) {
    masterColumn.forEach((m) => {
      if (m.total === "label") {
        totalData[m.id] = "Grand Total"
      } else if (m.total) {
        if (m.format === "qty_decimal") {
          totalData[m.id] = amount_formatDecimal(
            lodashSum(lodashMap(printdata, (d) => parseFloat(getNumberonlyDecimal(d[m.id], siteSettingForformatField.inventory_decimalpoint)))), //finaldata
            siteSettingForformatField.inventory_decimalpoint
          )
        } else if (!isblank(m.decimalPoint)) {
          totalData[m.id] = amount_formatDecimal(
            parseFloat(getNumberonlyDecimal(totalData[m.id], m.decimalPoint)) + lodashSum(lodashMap(finaldata, (d) => parseFloat(getNumberonlyDecimal(d[m.id], m.decimalPoint)))),
            m.decimalPoint
          )
        } else {
          if (m.format) {
            totalData[m.id] = format_report_fieldV1({
              value: lodashSum(lodashMap(printdata, (d) => parseFloat(getNumberonly(d[m.id])))),
              format: m.format,
              localSetting: siteSettingForformatField,
            })
          } else {
            totalData[m.id] = amount_format(parseFloat(getNumberonly(totalData[m.id])) + lodashSum(lodashMap(finaldata, (d) => parseFloat(getNumberonly(d[m.id])))))
          }
        }
      } else {
        totalData[m.id] = ""
      }
    })
  }
  return totalData
}
