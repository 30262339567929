import React from "react"
import { Dashboard } from "@material-ui/icons"
import { Person } from "@material-ui/icons"
import { Store } from "@material-ui/icons"
import { PersonAdd } from "@material-ui/icons"
import { TrendingUp } from "@material-ui/icons"
import { DonutLarge } from "@material-ui/icons"
import { AccountBalanceWallet } from "@material-ui/icons"
import { TrendingDown } from "@material-ui/icons"
import { BarChart } from "@material-ui/icons"
import { Assignment } from "@material-ui/icons"
import { ExitToApp } from "@material-ui/icons"
import { Receipt } from "@material-ui/icons"
import { Today } from "@material-ui/icons"
import { Folder } from "@material-ui/icons"
import { Lock } from "@material-ui/icons"
import { Textsms } from "@material-ui/icons"
import { Smartphone } from "@material-ui/icons"
import { Settings } from "@material-ui/icons"
import { Description } from "@material-ui/icons"
import { PieChart } from "@material-ui/icons"
import { Timeline } from "@material-ui/icons"
import { ClearAll } from "@material-ui/icons"
import { MergeType } from "@material-ui/icons"
import { FileCopy } from "@material-ui/icons"
import { AirplanemodeActive } from "@material-ui/icons"
import { Typography, Box } from "@material-ui/core"

export const getmenuicon = (title) => {
  if (title === "Dashboard") {
    return <Dashboard />
  } else if (title === "Masters") {
    return <PersonAdd />
  } else if (title === "Inventory") {
    return <Store />
  } else if (title === "GetMyStore") {
    return <Store />
  } else if (title === "Invoice") {
    return <Receipt />
  } else if (title === "Transaction") {
    return <AccountBalanceWallet />
  } else if (title === "Reports") {
    return <BarChart />
  } else if (title === "Process") {
    return <DonutLarge />
  } else if (title === "Utilities") {
    return <Assignment />
  } else if (title === "LogOut") {
    return <ExitToApp />
  } else if (title === "fb-arrow-graph-down-right") {
    return <TrendingDown />
  } else if (title === "fb-flow-branch") {
    return <MergeType />
  } else if (title === "fb-credit-card") {
    return <Receipt />
  } else if (title === "fb-calendar") {
    return <Today />
  } else if (title === "fb-folder") {
    return <Folder />
  } else if (title === "ion-paper-airplane") {
    return <AirplanemodeActive />
  } else if (title === "") {
    return
  } else if (title === "fb-lock") {
    return <Lock />
  } else if (title === "fb-sms-1") {
    return <Textsms />
  } else if (title === "fb-arrow-graph-up-right") {
    return <TrendingUp />
  } else if (title === "fb-clear") {
    return <ClearAll />
  } else if (title === "fb-group-sms") {
    return <Smartphone />
  } else if (title === "fb-user") {
    return <Person />
  } else if (title === "fb-cog") {
    return <Settings />
  } else if (title === "fb-doc-text") {
    return <Description />
  } else if (title === "fb-chart-pie") {
    return <PieChart />
  } else if (title === "fb-chart-area") {
    return <Timeline />
  } else if (title === "fb-line-chart") {
    return <Timeline />
  } else if (title === "fb-gst") {
    return <FileCopy />
  } else {
    return <Lock />
  }
}

export function TabContainer(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`nav-tabpanel-${index}`} aria-labelledby={`nav-tab-${index}`} {...other}>
      {children}
    </Typography>
  )
}
