// import usePrevious from "@project/components/Hooks/usePrevious"
// import { getStorage } from "@project/components/sessionStoreFunctions"
// import { isblank } from "@project/sharedcomponents/utilityFunctions"
// import React, { useEffect, useState } from "react"
// import { useDispatch } from "react-redux"
// import { Redirect, Route, useLocation } from "react-router-dom"
// import { setLastMenuKey } from "../../Actions"
// import { checkViewrightsV1, getWindowYear } from "../../Lib/commonfunctions"

// export function PrivateRoute({ component: Component, path, t, ...rest }) {
//   const location = useLocation()
//   const dispatch = useDispatch()
//   const [isValidPath, setIsValidPath] = useState(false)
//   const [isLoading, setIsLoading] = useState(true)

//   var newPath = location.pathname.replace(`/`, "")
//   newPath = newPath.split("/")
//   if (isNaN(newPath[0])) {
//     newPath = newPath.join("/")
//   } else {
//     newPath = newPath.filter((e, index) => index !== 0).join("/")
//   }

//   const prePath = usePrevious(newPath)

//   useEffect(() => {
//     let check_View_rights = false
//     if (
//       newPath === "welcome" ||
//       newPath === "invite" ||
//       newPath === "newdashboard" ||
//       newPath === "quicksell" ||
//       newPath === "paymentsuccess" ||
//       newPath === "sitesettingsold" ||
//       newPath === "" ||
//       newPath === "manageorganizations" ||
//       newPath === "onboarding"
//     ) {
//       check_View_rights = true

//       if (prePath !== newPath) {
//         dispatch(setLastMenuKey({ key: "" }))
//         setIsValidPath(true)
//         setIsLoading(false)
//       }
//     } else {
//       if (prePath !== newPath) {
//         const _check_View_rights = checkViewrightsV1({ url: newPath, location: location, setLastMenuKey, dispatch })
//         check_View_rights = _check_View_rights.isAllow
//         dispatch(setLastMenuKey({ key: _check_View_rights.menuKey }))
//         if (check_View_rights) {
//           setIsValidPath(true)
//         } else {
//           setIsValidPath(false)
//         }
//         setIsLoading(false)
//       }
//     }
//   }, [newPath, prePath])

//   return (
//     <Route
//       {...rest}
//       render={(props) => {
//         if (!isblank(getStorage(`auth_token_${getWindowYear()}`))) {
//           if (isblank(location.pathname) || location.pathname === "/") {
//             return <Redirect to={{ pathname: `/${getWindowYear()}/welcome` }} />
//           }
//           if (isLoading === false) {
//             if (isValidPath) {
//               return <Component {...props} t={t} {...rest} />
//             } else {
//               return <Redirect to={{ pathname: `/${getWindowYear()}/welcome` }} />
//             }
//           }
//         } else {
//           return <Redirect to={{ pathname: "/login" }} />
//         }
//       }}
//     />
//   )
// }

import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import usePrevious from "@project/components/Hooks/usePrevious"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { setLastMenuKey } from "../../Actions"
import { checkViewrightsV1, getWindowYear } from "../../Lib/commonfunctions"

export const PrivateRoute = ({ path, element: Element, ...props }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [isValidPath, setIsValidPath] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  var newPath = location.pathname.replace(`/`, "")
  newPath = newPath.split("/")
  if (isNaN(newPath[0])) {
    newPath = newPath.join("/")
  } else {
    newPath = newPath.filter((e, index) => index !== 0).join("/")
  }

  const prePath = usePrevious(newPath)

  useEffect(() => {
    let check_View_rights = false
    if (
      newPath === "welcome" ||
      newPath === "invite" ||
      newPath === "newdashboard" ||
      newPath === "quicksell" ||
      newPath === "paymentsuccess" ||
      newPath === "sitesettingsold" ||
      newPath === "" ||
      newPath === "manageorganizations" ||
      newPath === "onboarding"
    ) {
      check_View_rights = true

      if (prePath !== newPath) {
        dispatch(setLastMenuKey({ key: "" }))
        setIsValidPath(true)
        setIsLoading(false)
      }
    } else {
      if (prePath !== newPath) {
        const _check_View_rights = checkViewrightsV1({ url: newPath, location: location, setLastMenuKey, dispatch })
        check_View_rights = _check_View_rights.isAllow
        dispatch(setLastMenuKey({ key: _check_View_rights.menuKey }))
        if (check_View_rights) {
          setIsValidPath(true)
        } else {
          setIsValidPath(false)
        }
        setIsLoading(false)
      }
    }
  }, [newPath, prePath])

  const { islogin } = useSelector((state) => state.AuthReducer)
  if (isLoading === false) {
    if (isValidPath) {
      return islogin ? <Outlet /> : <Navigate to="/login" />
    } else {
      return <Navigate to={`/${getWindowYear()}/welcome`} />
    }
  }
}
