import { makeStyles, Tooltip } from "@material-ui/core"
import { Help } from "@material-ui/icons"
import React from "react"

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: "0.75rem",
  },
  helpIconButton: {
    color: theme.palette.grey[500],
    cursor: "help",
    fontSize: "1rem",
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
}))

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap()
  return <Tooltip arrow {...props} />
}

const HelpBtnComponent = ({ help, style, toolTipStyle }) => {
  const classes = useStylesBootstrap()

  return (
    <BootstrapTooltip
      PopperProps={{
        disablePortal: true,
      }}
      arrow
      {...(toolTipStyle && {
        style: toolTipStyle,
      })}
      disableFocusListener
      disableTouchListener
      title={help}
    >
      <Help
        fontSize="inherit"
        color="inherit"
        className={classes.helpIconButton}
        {...(style
          ? {
              style: { ...style },
            }
          : {
              style: { marginTop: 12 },
            })}
      />
    </BootstrapTooltip>
  )
}
export default HelpBtnComponent
