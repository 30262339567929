const setting = {
  pageMargins: [30, 35, 30, 30],
  logo_height: 80,
  logo_width: 80,
}

const header = [
  {
    field_id: "header",
    columns: [
      {
        text: "{left_text}",
        style: "head_left_text",
      },
      {
        text: "{center_text}",
        style: "head_center_text",
        bold: true,
      },
      {
        text: "{right_text}",
        style: "head_right_text",
      },
    ],
    margin: [15, 15, 15, 5],
  },
]

const style = {
  valueField: {
    fontSize: 9,
  },
  head_left_text: {
    alignment: "left",
  },
  head_center_text: {
    fontSize: 12,
    alignment: "center",
  },
  head_right_text: {
    alignment: "right",
  },
  contentTable: {
    margin: [0, 15, 0, 0],
  },
  noMarginTable: {
    margin: [0, 0, 0, 0],
  },
  left: {
    alignment: "left",
  },
  right: {
    alignment: "right",
  },
}

const bodyHeader = [
  {
    field_id: "bodyHeader",
    table: {
      widths: ["*", 200],
      body: [
        [
          {
            style: "invoiceAddressDetails",
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    columns: [
                      {
                        field_id: "company_logo",
                        width: setting.logo_width,
                        image: "{logo}",
                        fit: [setting.logo_width, setting.logo_height],
                        margin: [0, 0, 0, 0],
                      },
                      {
                        width: "*",
                        stack: [
                          { field_id: "company_name", text: "{companyName}", style: "valueField", bold: true, fontSize: 10.5 },
                          {
                            field_id: "company_address",
                            stack: [
                              { text: "{company_address1}", style: "valueField" },
                              { text: "{company_address2}", style: "valueField" },
                              { field_id: "company_contact", text: "Contact: {company_contact}", style: "valueField" },
                              { field_id: "company_gstin", text: "GSTIN: {company_gstin}", style: "valueField" },
                              { field_id: "company_pan", text: "PAN: {companyPAN}", style: "valueField" },
                              { field_id: "company_aadhaar", text: "Aadhaar: {company_aadhaar}", style: "valueField" },
                              { field_id: "company_OtherNumbers", text: "{companyOtherNumbers}", style: "valueField" },
                            ],
                          },
                        ],
                      },
                    ],
                    columnGap: 10,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    field_id: "billing_address_block",
                    stack: [
                      { field_id: "party_title", text: "To,", style: "valueField", margin: [0, 0, 0, 0] },
                      { field_id: "party_name", text: "{partyName}", style: "valueField", bold: true, fontSize: 10.5 },
                      {
                        field_id: "party_address",
                        stack: [
                          { text: "{party_address1}", style: "valueField" },
                          { text: "{party_address2}", style: "valueField" },
                          { field_id: "party_gstin", text: "GSTIN: {party_gstin}", style: "valueField" },
                          { field_id: "party_pan", text: "PAN: {companyPAN}", style: "valueField" },
                          { field_id: "party_aadhaar", text: "Aadhaar: {party_aadhaar}", style: "valueField" },
                          { field_id: "party_contact", text: "Contact: {party_contact}", style: "valueField" },
                        ],
                      },
                    ],
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    field_id: "narration_details",
                    stack: [
                      { text: "Note/Remark", margin: [0, 0, 0, 2] },
                      { text: "{narration}", style: "valueField" },
                    ],
                    border: [false, false, false, false],
                  },
                ],
              ],
            },
            border: [true, true, true, true],
            margin: [-5, 0, -5, 0],
          },
          {
            stack: [
              {
                field_id: "invno_details",
                table: {
                  widths: ["*", "*"],
                  heights: [20],
                  body: [
                    [
                      { field_id: "invoice_no", stack: [{ text: "{title}" }, { text: "{billno}", style: "valueField", bold: true }], border: [false, false, true, true] },
                      { field_id: "invoice_date", stack: [{ text: "Date" }, { text: "{inwardDate}", style: "valueField", bold: true }], border: [false, false, false, true] },
                    ],
                  ],
                },
              },
              {
                field_id: "trans_details",
                table: {
                  widths: ["*", "*"],
                  heights: [20],
                  body: [[{ field_id: "transport", colSpan: 2, stack: [{ text: "Transport" }, { text: "{transport}", style: "valueField", bold: true }], border: [false, false, true, true] }]],
                },
              },
              {
                field_id: "eway_details",
                table: {
                  widths: ["*", "*"],
                  heights: [20],
                  body: [
                    [
                      { field_id: "eway_bill_no", stack: [{ text: "E-Way Bill No" }, { text: "{ewaybill_no}", style: "valueField", bold: true }], border: [false, false, true, true] },
                      { field_id: "vehicle_no", stack: [{ text: "Vehicle No" }, { text: "{vehicle_no}", style: "valueField", bold: true }], border: [false, false, false, true] },
                    ],
                  ],
                },
              },
              {
                field_id: "lr_details",
                table: {
                  widths: ["*", "*"],
                  heights: [20],
                  body: [
                    [
                      { field_id: "lr_no", stack: [{ text: "LR No" }, { text: "{lr_no}", style: "valueField", bold: true }], border: [false, false, true, true] },
                      { field_id: "lr_date", stack: [{ text: "LR Date" }, { text: "{lr_date}", style: "valueField", bold: true }], border: [false, false, false, true] },
                    ],
                  ],
                },
              },
              {
                field_id: "agent_details",
                table: {
                  widths: ["*", "*"],
                  heights: [20],
                  body: [[{ field_id: "agent", colSpan: 2, stack: [{ text: "Agent" }, { text: "{agent}", style: "valueField", bold: true }], border: [false, false, true, true] }]],
                },
              },
              {
                field_id: "godown_details",
                table: {
                  widths: ["*", "*"],
                  heights: [20],
                  body: [[{ field_id: "godown", colSpan: 2, stack: [{ text: "Godown" }, { text: "{godown}", style: "valueField", bold: true }], border: [false, false, false, true] }]],
                },
              },
              {
                field_id: "customFieldNew",
                replacePath: "table.body",
                table: {
                  widths: ["*", "*"],
                  heights: 20,
                  body: [
                    [
                      { stack: [{ text: "Custom Field 1" }, { text: "Custom Field Value", style: "valueField" }], border: [false, false, true, true] },
                      { stack: [{ text: "Custom Field 2" }, { text: "Custom Field Value", style: "valueField" }], border: [false, false, false, true] },
                    ],
                  ],
                },
              },
            ],
            border: [true, true, true, true],
            margin: [-5, 0, -5, 0],
          },
        ],
      ],
    },
    margin: [0, 0, 0, 15],
  },
]

const bodyFooter = [{}]

const body = [{}]
const footer = [{}]

export default {
  header: JSON.stringify(header),
  bodyHeader: JSON.stringify(bodyHeader),
  bodyFooter: JSON.stringify(bodyFooter),
  footer: JSON.stringify(footer),
  body: JSON.stringify(body),
  setting,
  style,
}
