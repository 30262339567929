import { Button, DialogActions, DialogContent, DialogTitle, Grid, IconButton, useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Close, Send } from "@material-ui/icons"
import LoadingButton from "@project/components/common/LoadingButton"
import StandardTextField from "@project/components/formComponents/standardTextField"
import ToggleButton from "@project/components/formComponents/toggleButton"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import { isblank, validateEmail, validateMobile } from "@project/sharedcomponents/utilityFunctions"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { clearLastPageData } from "../../Actions"
import { CONFIRM_DIALOG } from "../../Actions/types"
import { getconfigsettingdata, maskEmail, maskPhoneNo } from "../../Lib/commonfunctions"
import DisplayField from "../common/DisplayField"

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const ShareComponent = React.memo((props) => {
  const { notifyPartyCode, sendClick, mode, buttonVariant, buttonColor, dataTestid, getPartyDetailV2 } = props
  // const [modal, setModal] = React.useState(openDialog)
  const [sendEmail, setSendEmail] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [sendSMS, setSendSMS] = React.useState(false)
  const [defaultMobile, setDefaultMobile] = React.useState(false)
  const [defaultEmail, setDefaultEmail] = React.useState(false)
  const [sendWhatsApp, setSendWhatsApp] = React.useState(false)
  const [mobile, setMobile] = React.useState("")
  const [emailError, setEmailError] = React.useState("")
  const [mobileError, setMobileError] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [partyName, setPartyName] = React.useState("")
  const [partyCountry, setPartyCountry] = React.useState("")
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const __cust_hideContactInfo = parseInt(getconfigsettingdata("__cust_hideContactInfo")) || 0
  const email_module = getconfigsettingdata("email_module")
  const sms_module = getconfigsettingdata("sms_module")
  const enable_whatsapp_api = getconfigsettingdata("enable_whatsapp_api")

  const { lastpagetype, lastpageaction, lastpagedata, lastpagetime, ConfirmDialogData, ConfirmDialog } = useSelector((state) => state.CommonReducer)

  const handleClose = () => {
    setSendEmail("")
    setEmail("")
    setMobile("")
    setSendSMS("")
    setSendWhatsApp("")
    setEmailError("")
    setMobileError("")
    // setModal(false)
    setDefaultMobile(false)
    setDefaultEmail(false)

    dispatch({
      type: CONFIRM_DIALOG,
      payload: false,
      confirm: false,
      title: "",
      form: "",
      message: "",
      meta: "",
    })
  }

  const onSuccess = (response) => {
    if (!isblank(response)) {
      setEmail(response.email)
      setMobile(response.mobile)
      if (email_module === "yes") {
        setSendEmail(response.enableEmail)
      }
      if (sms_module === "yes") {
        setSendSMS(response.enableSMS)
      }
      if (enable_whatsapp_api === "yes") {
        setSendWhatsApp(response.enableWhatsapp)
      }
      setDefaultMobile(false)
      setDefaultEmail(false)
      setPartyName(response.name)
      setPartyCountry(response.country_id)
    }
  }

  useEffect(() => {
    dispatch(getPartyDetailV2({ code: notifyPartyCode, loading: true, type: "shareReport", onSuccess }))

    return () => {
      dispatch(clearLastPageData())
    }
  }, [])

  useEffect(() => {
    if (lastpagetype === "sendNotification" && (lastpageaction === "error" || lastpageaction === "success")) {
      setLoading(false)
    }
    if (lastpagetype === "sendNotification" && lastpageaction === "success") {
      setLoading(false)
      handleClose()
    }
  }, [lastpagetime])

  const handleSend = () => {
    var returnWithError = false
    if (sendEmail) {
      if (isblank(email)) {
        setEmailError(ErrorMessages["E00013"])
        returnWithError = true
      } else if (!validateEmail(email)) {
        setEmailError(ErrorMessages["E00010"])
        returnWithError = true
      }
    }
    if (sendSMS || sendWhatsApp) {
      if (isblank(mobile)) {
        setMobileError(ErrorMessages["E00014"])
        returnWithError = true
      } else if (!validateMobile(mobile)) {
        setMobileError(ErrorMessages["E00117"])
        returnWithError = true
      }
    }
    if (returnWithError) {
      return true
    }
    setLoading(true)
    sendClick({ sendEmail, email, sendSMS, sendWhatsApp, mobile, defaultMobile, defaultEmail, notifyPartyCode, partyCountry })
  }

  const handleChange = (val, field) => {
    if (field === "sendEmail") {
      setSendEmail(val)
    } else if (field === "sendSMS") {
      setSendSMS(val)
    } else if (field === "sendWhatsApp") {
      setSendWhatsApp(val)
    } else if (field === "email") {
      setEmailError("")
      setEmail(val)
    } else if (field === "mobile") {
      setMobileError("")
      setMobile(val)
    } else if (field === "defaultMobile") {
      setDefaultMobile(val)
    } else if (field === "defaultEmail") {
      setDefaultEmail(val)
    }
  }

  return (
    <React.Fragment>
      <DialogTitle id="alert-dialog-slide-title" disableTypography style={{ paddingBottom: 0 }}>
        <IconButton size="small" aria-label="close" className={classes.closeButton} disabled={loading} onClick={handleClose}>
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ToggleButton disabled={loading} value={sendEmail} label="Send Email" name={`sendEmail`} onChange={(value) => handleChange(value, "sendEmail")} />
            <ToggleButton disabled={loading} value={sendSMS} label="Send SMS" name={`sendSMS`} onChange={(value) => handleChange(value, "sendSMS")} />
            <ToggleButton disabled={loading} value={sendWhatsApp} label="Send WhatsApp" name={`sendWhatsApp`} onChange={(value) => handleChange(value, "sendWhatsApp")} />
          </Grid>
          {__cust_hideContactInfo !== 1 ? (
            <Grid item xs={12} md={6}>
              <StandardTextField
                noLableMargin={true}
                placeholder="Enter email address"
                disabled={loading}
                disableAutoCaps={true}
                fieldType="email"
                label="Email address"
                isRequired={sendEmail}
                fullWidth
                onChange={(value) => handleChange(value, "email")}
                value={email}
                error={emailError}
              />
              <ToggleButton size="small" disabled={loading} value={defaultEmail} label="Set as default" name={`defaultEmail`} onChange={(value) => handleChange(value, "defaultEmail")} />
              <StandardTextField
                disabled={loading}
                placeholder="Enter mobile no"
                fieldType="mobile"
                disableAutoCaps={true}
                label="Mobile no"
                helpText="Enter 10 digit mobile no."
                isRequired={sendWhatsApp || sendSMS}
                fullWidth
                onChange={(value) => handleChange(value, "mobile")}
                value={mobile}
                error={mobileError}
              />
              <ToggleButton size="small" disabled={loading} value={defaultMobile} label="Set as default" name={`defaultMobile`} onChange={(value) => handleChange(value, "defaultMobile")} />
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <DisplayField label="Email" value={maskEmail(email)} />
              <DisplayField label="Mobile" value={maskPhoneNo(mobile)} />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {sendClick && (
        <DialogActions>
          <Button disabled={loading} onClick={handleClose} variant="text">
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            label="Send"
            disabled={loading || !((sendEmail && email.length > 0) || ((sendSMS || sendWhatsApp) && mobile.length > 0))}
            startIcon={<Send fontSize="small" />}
            onClick={handleSend}
            color="primary"
            variant="contained"
          />
        </DialogActions>
      )}
    </React.Fragment>
  )
})

export default ShareComponent
