import { list_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import {
  LOADING_STATE,
  QUICK_LINK_SERVICE_CLEAR,
  SNACKBAR_STATE,
  USERRIGHTS_CHANGE,
  USERRIGHTS_SERVICE_CLEAR,
  USERRIGHTS_SERVICE_FAIL,
  USERRIGHTS_SERVICE_START,
  USERRIGHTS_SERVICE_SUCCESS,
} from "./types"

export const fetch_userrights_data = ({ dispatch, code, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: USERRIGHTS_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()

    data.append("type", "userrights")
    data.append("code", code)

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "userrights" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          userrightsServiceFail({ dispatch, response: response.data.meta.message, code })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          userrightsServiceSuccess({ dispatch, user: response, code })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          userrightsServiceFail({ dispatch, response: getCatchErrorMessage(error), code })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const userrightsServiceSuccess = ({ dispatch, user, code }) => {
  dispatch({
    type: USERRIGHTS_SERVICE_SUCCESS,
    payload: user,
    code: code,
  })
}

const userrightsServiceFail = ({ dispatch, response, code }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })

  dispatch({
    type: USERRIGHTS_SERVICE_FAIL,
    payload: response,
    code: code,
  })
}

export const userrightsChange = (dispatch, key, sub_menu_id, checked, field, type) => {
  return (dispatch) => {
    if (field === "quick_links") {
      dispatch({ type: QUICK_LINK_SERVICE_CLEAR })
    }
    dispatch({
      type: USERRIGHTS_CHANGE,
      key: key,
      sub_menu_id: sub_menu_id,
      checked: checked,
      field: field,
      rtype: type,
    })
  }
}

export const userrightsServiceClear = () => {
  return (dispatch) => {
    dispatch({
      type: USERRIGHTS_SERVICE_CLEAR,
    })
  }
}
