import { getallSupplyType, getallTransactionType, getallVehicleType, gettransportmode } from "@project/components/utilityFunctions"
import { amount_formatDecimal, isblank } from "./utilityFunctions"

export default ({ printdata, meta, pageSize, functions }) => {
  const stateData = meta.otherDetail.dd_state.find((e) => e.name === "India").states
  const { getAccountSessionUserData, getAccountSitesetting } = functions
  const e_invoice = parseInt(parseInt(getAccountSitesetting("e_invoice")))
  var Values = []
  let data = ""
  let EwbNo = ""
  let EwbDate = ""
  let generatedBy = ""
  let transportMode = ""
  let supplyType = ""
  let vehicleType = ""
  let vehNo = ""
  let transactionType = ""
  let fromState = ""
  let distance = ""
  let docType = ""
  let docNo = ""
  let docDate = ""
  let IRN = ""
  let sellerGstin = ""
  let sellerTrdNm = ""
  let sellerState = ""
  let dispGstin = ""
  let dispTrdNm = ""
  let dispStcd = ""
  let buyerGstin = ""
  let buyerTrdNm = ""
  let buyerState = ""
  let shipGstin = ""
  let shipTrdNm = ""
  let shipStcd = ""
  let transId = ""
  let transName = ""
  let transDocNo = ""
  let transDocDate = ""
  let totInvVal = 0
  let cgstVal = 0
  let sgstVal = 0
  let igstVal = 0
  let cesVal = 0
  let nonCesVal = 0
  let othChrg = 0
  let totAmt = 0
  var irnDetail = [
    {
      text: "",
      style: "LabelField",
    },
    {
      text: "",
      style: "ValueField",
      colSpan: 5,
    },
  ]
  if (e_invoice === 1 && !isblank(printdata.master_table_data[0].e_invoiceDetail)) {
    data = JSON.parse(printdata.master_table_data[0].e_invoiceDetail)
  }
  if (e_invoice === 1 && !isblank(printdata.master_table_data[0].e_invoiceDetail) && !isblank(data.EwbNo)) {
    if (!isblank(data.EwbNo)) {
      EwbNo = data.EwbNo
      EwbDate = data.EwbDt
      const ExtractedData = data.ExtractedSignedInvoiceData
      Values = generateItemDetailValues(ExtractedData.ItemList, getAccountSitesetting)

      IRN = ExtractedData.Irn
      if (!isblank(IRN)) {
        irnDetail = [
          {
            text: "IRN",
            style: "LabelField",
          },
          {
            text: IRN,
            style: "ValueField",
            colSpan: 5,
          },
        ]
      }

      if (!isblank(ExtractedData.DocDtls)) {
        docType = ExtractedData.DocDtls.Typ
        docNo = ExtractedData.DocDtls.No
        docDate = ExtractedData.DocDtls.Dt
      }
      if (!isblank(ExtractedData.EwbDtls)) {
        transportMode = gettransportmode().find((e) => e.id === ExtractedData.EwbDtls.TransMode)
        vehicleType = getallVehicleType().find((e) => e.id === ExtractedData.EwbDtls.VehType)
        transId = ExtractedData.EwbDtls.TransId
        transName = ExtractedData.EwbDtls.TransName
        transDocNo = ExtractedData.EwbDtls.TransDocNo
        transDocDate = ExtractedData.EwbDtls.TransDocDt
        vehNo = ExtractedData.EwbDtls.VehNo
        distance = ExtractedData.EwbDtls.Distance
      }
      if (!isblank(ExtractedData.ValDtls)) {
        totInvVal = ExtractedData.ValDtls.TotInvVal
        cgstVal = ExtractedData.ValDtls.CgstVal
        sgstVal = ExtractedData.ValDtls.SgstVal
        igstVal = ExtractedData.ValDtls.IgstVal
        cesVal = ExtractedData.ValDtls.CesVal
        nonCesVal = ExtractedData.ValDtls.StCesVal
        othChrg = ExtractedData.ValDtls.OthChrg
        totAmt = ExtractedData.ValDtls.AssVal
      }
      if (!isblank(ExtractedData.SellerDtls)) {
        generatedBy = ExtractedData.SellerDtls.Gstin
        sellerGstin = ExtractedData.SellerDtls.Gstin
        sellerTrdNm = ExtractedData.SellerDtls.TrdNm
        sellerState = stateData.find((e) => e.code === String(ExtractedData.SellerDtls.Stcd))
        fromState = stateData.find((e) => e.code === String(ExtractedData.SellerDtls.Stcd))
      }
      if (!isblank(ExtractedData.DispDtls)) {
        dispGstin = ExtractedData.DispDtls.Gstin
        dispTrdNm = ExtractedData.DispDtls.TrdNm
        dispStcd = ExtractedData.DispDtls.Stcd
      }
      if (!isblank(ExtractedData.BuyerDtls)) {
        buyerGstin = ExtractedData.BuyerDtls.Gstin
        buyerTrdNm = ExtractedData.BuyerDtls.TrdNm
        buyerState = stateData.find((e) => e.code === String(ExtractedData.BuyerDtls.Stcd))
      }
      if (!isblank(ExtractedData.ShipDtls)) {
        shipGstin = ExtractedData.ShipDtls.Gstin
        shipTrdNm = ExtractedData.ShipDtls.TrdNm
        shipStcd = ExtractedData.ShipDtls.Stcd
      }
    }
  } else {
    if (!isblank(printdata.master_table_data[0].ewaybill_printdata)) {
      data = JSON.parse(printdata.master_table_data[0].ewaybill_printdata)
      Values = generateItemDetailValues1(data.itemList, getAccountSitesetting)
      if (!isblank(data.ewbNo)) {
        EwbNo = data.ewbNo
        EwbDate = data.ewayBillDate
        vehicleType = getallVehicleType().find((e) => e.id === data.vehicleType)
        transId = data.transporterId
        transName = data.transporterName
        distance = data.actualDist
        docType = data.docType
        docNo = data.docNo
        docDate = data.docDate
        transactionType = getallTransactionType().find((e) => e.id === String(data.transactionType))
        supplyType = getallSupplyType().find((e) => e.id === data.supplyType)
        generatedBy = data.fromGstin
        sellerGstin = data.fromGstin
        sellerTrdNm = data.fromTrdName
        sellerState = stateData.find((e) => e.code === String(data.fromStateCode))
        buyerGstin = data.toGstin
        buyerTrdNm = data.toTrdName
        buyerState = stateData.find((e) => e.code === String(data.toStateCode))
        totInvVal = data.totInvValue
        cgstVal = data.cgstValue
        sgstVal = data.sgstValue
        igstVal = data.igstValue
        cesVal = data.cessValue
        nonCesVal = data.cessNonAdvolValue
        othChrg = data.otherValue
        totAmt = data.totalValue

        if (!isblank(data.VehiclListDetails) && data.VehiclListDetails.length > 0) {
          const vehDetail = data.VehiclListDetails[0]
          transportMode = gettransportmode().find((e) => e.id === vehDetail.transMode)
          transDocNo = vehDetail.transDocNo
          transDocDate = vehDetail.transDocDate
          vehNo = vehDetail.vehicleNo
          fromState = stateData.find((e) => e.code === String(vehDetail.fromState))
        }
      }
    }
  }

  var dd = {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [20, 20, 20, 20],
    content: [
      [
        {
          table: {
            widths: ["*", "auto"],
            body: [
              [
                {
                  text: "e-Way Bill",
                  style: "mainhead",
                },
                {
                  qr: "661632281809/24AAPHR6038A1Z8/21-10-2023 12: 13: 00",
                  fit: "75",
                },
              ],
            ],
          },
          layout: "noBorders",
        },

        {
          text: "1. E-WAY BILL Details",
          style: "heading",
        },
        {
          table: {
            widths: ["*", "*", "*", "*", "*", "*"],
            body: [
              [
                {
                  text: "eWay Bill No",
                  style: "LabelField",
                },
                {
                  text: EwbNo,
                  style: "ValueField",
                },
                {
                  text: "Generated Date",
                  style: "LabelField",
                },
                {
                  text: EwbDate,
                  style: "ValueField",
                },
                {
                  text: "Generated By",
                  style: "LabelField",
                },
                {
                  text: generatedBy,
                  style: "ValueField",
                },
              ],
              [
                {
                  text: "Mode",
                  style: "LabelField",
                },
                {
                  text: transportMode.name,
                  style: "ValueField",
                },
                {
                  text: "Approx Distance",
                  style: "LabelField",
                },
                {
                  text: distance,
                  style: "ValueField",
                },
                {
                  text: "",
                  style: "LabelField",
                },
                {
                  text: "",
                  style: "ValueField",
                },
              ],
              [
                {
                  text: "Type",
                  style: "LabelField",
                },
                {
                  text: supplyType ? supplyType.name : "",
                  style: "ValueField",
                },
                {
                  text: "Document Details",
                  style: "LabelField",
                },
                {
                  text: `${docType} - ${docNo} - ${docDate}`,
                  style: "ValueField",
                },
                {
                  text: "Transaction type",
                  style: "LabelField",
                },
                {
                  text: transactionType ? transactionType.name : "",
                  style: "ValueField",
                },
              ],
              irnDetail,
            ],
          },
          layout: "noBorders",
          margin: [0, 0, 0, 10],
        },

        {
          text: "2. Address Details",
          style: "heading",
        },
        {
          field_id: "ewaybillDetails",
          table: {
            widths: ["50%", "50%"],
            body: [
              [
                {
                  stack: [
                    {
                      text: "::From::",
                      style: "subheading",
                      margin: [0, 0, 0, 2],
                    },
                    {
                      stack: [
                        {
                          text: `GSTIN: ${sellerGstin}`,
                        },
                        {
                          text: sellerTrdNm,
                        },
                        {
                          text: sellerState ? sellerState.name : "",
                        },
                      ],
                    },

                    {
                      text: "::Dispatch From::",
                      style: "subheading",
                      margin: [0, 5, 0, 2],
                    },
                    {
                      stack: [
                        {
                          text: dispGstin ? `GSTIN: ${dispGstin}` : "",
                        },
                        {
                          text: dispTrdNm ? dispTrdNm : "",
                        },
                        {
                          text: dispStcd ? dispStcd : "",
                        },
                      ],
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: "::To::",
                      style: "subheading",
                      margin: [0, 0, 0, 2],
                    },
                    {
                      stack: [
                        {
                          text: `GSTIN: ${buyerGstin}`,
                        },
                        {
                          text: buyerTrdNm,
                        },
                        {
                          text: buyerState ? buyerState.name : "",
                        },
                        {
                          text: "::Ship To::",
                          style: "subheading",
                          margin: [0, 5, 0, 2],
                        },
                        {
                          stack: [
                            {
                              text: shipGstin ? `GSTIN: ${shipGstin}` : "",
                            },
                            {
                              text: shipTrdNm ? shipTrdNm : "",
                            },
                            {
                              text: shipStcd ? shipStcd : "",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            ],
          },
          margin: [0, 0, 0, 10],
        },

        {
          text: "3. Goods Details",
          style: "heading",
        },
        {
          table: {
            widths: ["*", "*", "*", "*", "*"],
            body: [...Values],
          },
          margin: [0, 0, 0, 10],
        },
        {
          table: {
            widths: ["*", "*", "*", "*", "*", "*", "*", "*"],
            body: [
              [
                {
                  text: "Tot. Taxable Amt",
                  style: "LabelField",
                },
                {
                  text: "CGST Amt",
                  style: "LabelField",
                },
                {
                  text: "SGST Amt",
                  style: "LabelField",
                },
                {
                  text: "IGST Amt",
                  style: "LabelField",
                },
                {
                  text: "CESS Amt",
                  style: "LabelField",
                },
                {
                  text: "CESS Non.Advol Amt",
                  style: "LabelField",
                },
                {
                  text: "Other Amt",
                  style: "LabelField",
                },
                {
                  text: "Total Inv.Amt",
                  style: "LabelField",
                },
              ],
              [
                {
                  text: totAmt,
                  style: "ValueField",
                },
                {
                  text: cgstVal,
                  style: "ValueField",
                },
                {
                  text: sgstVal,
                  style: "ValueField",
                },
                {
                  text: igstVal,
                  style: "ValueField",
                },
                {
                  text: cesVal,
                  style: "ValueField",
                },
                {
                  text: nonCesVal,
                  style: "ValueField",
                },
                {
                  text: othChrg,
                  style: "ValueField",
                },
                {
                  text: totInvVal,
                  style: "ValueField",
                },
              ],
            ],
          },
          margin: [0, 0, 0, 10],
        },

        {
          text: "4. Transportation Details",
          style: "heading",
        },
        {
          field_id: "ewaybillDetails",
          table: {
            widths: ["*", "*", "*", "*"],
            body: [
              [
                {
                  text: "Transporter ID & Name",
                  style: "LabelField",
                },
                {
                  text: transId && transName ? `${transId} & ${transName}` : "",
                  style: "ValueField",
                },
                {
                  text: "Transporter Doc. No & Date",
                  style: "LabelField",
                },
                {
                  text: transDocNo && transDocDate ? `${transDocNo} & ${transDocDate}` : "",
                  style: "ValueField",
                },
              ],
            ],
          },
          layout: "noBorders",
          margin: [0, 0, 0, 10],
        },

        {
          text: "5. Vehicle Details",
          style: "heading",
        },
        {
          table: {
            widths: ["*", "*", "*", "*", "*", "auto", "auto"],
            body: [
              [
                {
                  text: "Mode",
                  style: "LabelField",
                },
                {
                  text: "Vehicle/Trans\nDoc No & Dt.",
                  style: "LabelField",
                },
                {
                  text: "From",
                  style: "LabelField",
                },
                {
                  text: "Entered Date",
                  style: "LabelField",
                },
                {
                  text: "Entered By",
                  style: "LabelField",
                },
                {
                  text: "CEWB No.\n(if any)",
                  style: "LabelField",
                },
                {
                  text: "Multi Veh.Info\n(if any)",
                  style: "LabelField",
                },
              ],
              [
                {
                  text: vehicleType ? vehicleType.name : "",
                  style: "ValueField",
                },
                {
                  text: vehNo,
                  style: "ValueField",
                },
                {
                  text: fromState ? fromState.name : "",
                  style: "ValueField",
                },
                {
                  text: EwbDate,
                  style: "ValueField",
                },
                {
                  text: sellerGstin,
                  style: "ValueField",
                },
                {
                  text: "-",
                  style: "ValueField",
                },
                {
                  text: "-",
                  style: "ValueField",
                },
              ],
            ],
          },
          margin: [0, 0, 0, 10],
        },
      ],
    ],
    defaultStyle: {
      fontSize: 8,
    },
    styles: {
      mainhead: {
        fontSize: 18,
        bold: true,
      },
      ValueField: {
        bold: true,
      },
      heading: {
        fontSize: 9,
        bold: true,
        margin: [0, 5, 0, 5],
      },
      subheading: {
        fontSize: 9,
        bold: true,
        margin: [0, 0, 0, 2],
      },
    },
  }
  return dd
}

const generateItemDetailValues = (data, getAccountSitesetting) => {
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")

  var Values = [
    [
      {
        text: "HSN Code",
        style: "ValueField",
      },
      {
        text: "Product Description",
        style: "ValueField",
      },
      {
        text: "Quantity",
        style: "ValueField",
      },
      {
        text: "Taxable Amount Rs.",
        style: "ValueField",
      },
      {
        text: "Tax Rate (C+S+I+Cess+Cess Non.Advol)",
        style: "ValueField",
      },
    ],
  ]

  if (!isblank(data) && data.length > 0) {
    data.forEach((item, i) => {
      const cgst = amount_formatDecimal(item.CgstAmt, inventory_decimalpoint)
      const sgst = amount_formatDecimal(item.SgstAmt, inventory_decimalpoint)
      const igdt = amount_formatDecimal(item.IgstAmt, inventory_decimalpoint)
      const cess = amount_formatDecimal(item.CesAmt, inventory_decimalpoint)
      const cessNonAdvol = amount_formatDecimal(item.CesNonAdvlAmt, inventory_decimalpoint)

      var singleValue = [
        {
          text: item.HsnCd,
          style: "LabelField",
        },
        {
          text: item.PrdDesc,
          style: "LabelField",
        },
        {
          text: `${item.Qty} ${item.Unit}`,
          style: "LabelField",
        },
        {
          text: item.TotAmt,
          style: "LabelField",
        },
        {
          text: `${cgst} + ${sgst} + ${igdt} + ${cess} + ${cessNonAdvol}`,
          style: "LabelField",
        },
      ]
      Values.push(singleValue)
    })
  }
  return Values
}

const generateItemDetailValues1 = (data, getAccountSitesetting) => {
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")

  var Values = [
    [
      {
        text: "HSN Code",
        style: "ValueField",
      },
      {
        text: "Product Description",
        style: "ValueField",
      },
      {
        text: "Quantity",
        style: "ValueField",
      },
      {
        text: "Taxable Amount Rs.",
        style: "ValueField",
      },
      {
        text: "Tax Rate (C+S+I+Cess+Cess Non.Advol)",
        style: "ValueField",
      },
    ],
  ]

  if (!isblank(data) && data.length > 0) {
    data.forEach((item, i) => {
      const cgst = amount_formatDecimal(item.cgstRate, inventory_decimalpoint)
      const sgst = amount_formatDecimal(item.sgstRate, inventory_decimalpoint)
      const igdt = amount_formatDecimal(item.igstRate, inventory_decimalpoint)
      const cess = amount_formatDecimal(item.cessRate, inventory_decimalpoint)
      const cessNonAdvol = amount_formatDecimal(item.cessNonAdvol, inventory_decimalpoint)

      var singleValue = [
        {
          text: item.hsnCode,
          style: "LabelField",
        },
        {
          text: item.productName,
          style: "LabelField",
        },
        {
          text: `${item.quantity} ${item.qtyUnit}`,
          style: "LabelField",
        },
        {
          text: item.taxableAmount,
          style: "LabelField",
        },
        {
          text: `${cgst} + ${sgst} + ${igdt} + ${cess} + ${cessNonAdvol}`,
          style: "LabelField",
        },
      ]
      Values.push(singleValue)
    })
  }
  return Values
}
