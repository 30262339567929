import { BILLING_LOCAL_SEARCH, BILLING_SERVICE_CLEAR, BILLING_SERVICE_FAIL, BILLING_SERVICE_START, BILLING_SERVICE_SUCCESS } from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  billing: [],
  referral: [],
  billing_count: 0,
  referral_count: 0,
  rowsPerPage: 0,
  page: 0,
  order: "asc",
  orderBy: "description",
  search_text: "",
  data: "",
  columnData: [
    { id: "billing_date", numeric: false, visible: true, label: "Date", width: 150, minWidth: 125 },
    { id: "description", numeric: false, visible: true, label: "Description", minWidth: 200 },
    { id: "billing_amount", numeric: true, visible: true, label: "Amount", width: 150, format: "indian_rupee" },
    { id: "balance", numeric: true, visible: true, label: "Balance", format: "indian_rupee" },
  ],
  referralData: [
    { id: "bonus_type", numeric: false, visible: true, label: "Bonus type", width: 150 },
    { id: "company", numeric: false, visible: true, label: "Company", width: 150 },
    { id: "rdatetime", numeric: false, visible: true, label: "Updated" },
    { id: "percentage", numeric: true, visible: true, label: "Percentage", width: 150 },
    { id: "status", numeric: false, visible: true, label: "Status" },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BILLING_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case BILLING_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case BILLING_SERVICE_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        data: action.payload.data.result,
        billing: action.payload.data.result.billing,
        referral: action.payload.data.result.referral,
        billing_count: action.payload.data.result.billing_count,
        referral_count: action.payload.data.result.referral_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
        islogin: true,
      }
    case BILLING_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        billing: [],
        referral: [],
        loading: false,
        totalcount: 0,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
      }
    case BILLING_LOCAL_SEARCH:
      return { ...state, search_text: action.search_text }

    default:
      return state
  }
}
