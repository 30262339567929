import { Chip, CircularProgress, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { Add, Cancel, Close, ExpandMore, Info, Update } from "@material-ui/icons"
import HelpBtnComponent from "@project/components/common/HelpBtnComponent"
import { checkCondition, convertIntif, isblank } from "@project/sharedcomponents/utilityFunctions"
import React, { useEffect, useRef } from "react"
import { components } from "react-select"
import { VariableSizeList as RWList } from "react-window"
import DebounceLink from "../common/DebounceLink"
import LabelComponent from "../common/labelComponent"

var height = 40

export const Option = ({ children, accountSiteSettingKeyValue, extraDisplayFields, __cust_hideContactInfo, ...props }) => {
  const { mode } = props.selectProps
  if (mode === "CUSTOMER" && !props.data.__isNew__) {
    var city = ""
    var gstin = ""
    if (isblank(props.data.row.city) && isblank(props.data.row.state) && isblank(props.data.row.mobile)) {
      city = "N/A"
    } else {
      city = props.data.row.city
      if (!isblank(props.data.row.state)) {
        if (!isblank(props.data.row.city)) {
          city = `${city} - ${props.data.row.state}`
        } else {
          city = props.data.row.state
        }
      }
    }

    if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.tax === 1 && parseInt(accountSiteSettingKeyValue.show_gstinCutomerList) === 1) {
      if (!isblank(props.data.row.gstin)) {
        gstin = props.data.row.gstin
      } else {
        gstin = "N/A"
      }
    }
    return (
      <components.Option {...props}>
        <Typography variant="subtitle2" noWrap={true}>
          {props.data.label}
        </Typography>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Typography noWrap variant="caption" display="block">
              {city}
            </Typography>
            <Typography noWrap variant="caption" display="block">
              {gstin}
            </Typography>
          </Grid>
          {__cust_hideContactInfo !== 1 && (
            <Grid item xs={6}>
              <Typography style={{ textAlign: "right" }} noWrap variant="caption" display="block">
                {props.data.row.mobile}
              </Typography>
            </Grid>
          )}
        </Grid>
      </components.Option>
    )
  } else if (mode === "WITHDESCRIPTION" && !props.data.__isNew__) {
    var description = ""
    if (isblank(props.data.row.description)) {
      description = "N/A"
    } else {
      description = props.data.row.description
    }
    return (
      <components.Option {...props}>
        <Typography variant="subtitle2" noWrap>
          {props.data.label}
        </Typography>

        <Typography noWrap variant="caption" display="block">
          {description}
        </Typography>
      </components.Option>
    )
  } else if (mode === "ITEM" && !props.data.__isNew__) {
    var group = ""
    var subgroup = ""
    var subgroupLevel2 = ""
    var barcode = ""
    var hsncode = ""

    if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.item_group === 1) {
      group = props.data.row.itemgroup
      if (isblank(group)) {
        group = "N/A"
      }
      group = `${group}`

      if (accountSiteSettingKeyValue.item_category === 1) {
        subgroup = props.data.row.itemcate_name
        if (isblank(subgroup)) {
          subgroup = "N/A"
        }
        subgroup = `${subgroup}`
        if (accountSiteSettingKeyValue.item_subgroupLevel2 === 1) {
          subgroupLevel2 = props.data.row.itemsubgrouplevel2_name
          if (isblank(subgroupLevel2)) {
            subgroupLevel2 = "N/A"
          }
          subgroupLevel2 = `${subgroupLevel2}`
        }
      }
    }
    if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.sku_number === 1) {
      if (accountSiteSettingKeyValue.barcode_mng === 1 && !isblank(props.data.row.stockku)) {
        barcode = `SKU: ${props.data.row.stockku}`
      }
      if (isblank(barcode)) {
        barcode = "N/A"
      }
      barcode = `${barcode}`
    }
    if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.tax === 1) {
      if (!isblank(props.data.row.hsn_code)) {
        hsncode = props.data.row.hsn_code
      }
      if (isblank(hsncode)) {
        hsncode = "N/A"
      }
      hsncode = `HSN: ${hsncode}`
    }
    return (
      <components.Option {...props}>
        <Typography variant="subtitle2" noWrap>
          {props.data.label}
        </Typography>

        {!isblank(extraDisplayFields) &&
          extraDisplayFields.length > 0 &&
          extraDisplayFields.map((e, ind) => {
            return (
              <Typography key={`opt-${ind}`} variant="caption" noWrap display="block">
                {`${e.label}: ${props.data.row[e.value]}`}
              </Typography>
            )
          })}

        <Grid container spacing={0}>
          <Grid item xs={6}>
            {accountSiteSettingKeyValue && accountSiteSettingKeyValue.item_group === 1 && (
              <React.Fragment>
                <Typography noWrap variant="caption" display="block">
                  {group}
                </Typography>
                {accountSiteSettingKeyValue && accountSiteSettingKeyValue.item_category === 1 && (
                  <React.Fragment>
                    <Typography noWrap variant="caption" display="block">
                      {subgroup}
                    </Typography>
                    {accountSiteSettingKeyValue && accountSiteSettingKeyValue.item_subgroupLevel2 === 1 && (
                      <Typography noWrap variant="caption" display="block">
                        {subgroupLevel2}
                      </Typography>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Grid>
          <Grid item xs={6}>
            {accountSiteSettingKeyValue && (accountSiteSettingKeyValue.sku_number === 1 || accountSiteSettingKeyValue.tax === 1) && (
              <React.Fragment>
                {!isblank(hsncode) && (
                  <Typography style={{ textAlign: "right" }} noWrap variant="caption" display="block">
                    {hsncode}
                  </Typography>
                )}
                {!isblank(barcode) && (
                  <Typography style={{ textAlign: "right" }} noWrap variant="caption" display="block">
                    {barcode}
                  </Typography>
                )}
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </components.Option>
    )
  } else if (mode === "MEMBER" && !props.data.__isNew__) {
    return (
      <components.Option {...props}>
        <Typography variant="subtitle2" noWrap>
          {props.data.label}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography noWrap variant="caption" display="block">
            MemberId: {props.data.row.memberid}
          </Typography>
          <Typography noWrap variant="caption" display="block">
            Mobile: {props.data.row.mobile}
          </Typography>
        </div>
      </components.Option>
    )
  } else if (mode === "ALLACCOUNT" && !props.data.__isNew__) {
    return (
      <components.Option {...props}>
        <Typography variant="subtitle2" noWrap>
          {props.data.label}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography noWrap variant="caption" display="block">
            {props.data.row.groupName}
          </Typography>
          {!isblank(props.data.row.city) && (
            <Typography noWrap variant="caption" display="block">
              {props.data.row.city}
            </Typography>
          )}
        </div>
      </components.Option>
    )
  } else if (mode === "STOCKS" && !props.data.__isNew__) {
    return (
      <components.Option {...props}>
        <Typography variant="subtitle2" noWrap>
          {props.data.label}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography noWrap variant="caption" display="block">
            SYMBOL: {props.data.row.SYMBOL}
          </Typography>
          <Typography noWrap variant="caption" display="block">
            SERIES: {props.data.row._SERIES}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography noWrap variant="caption" display="block">
            ISIN NUMBER: {props.data.row._ISIN_NUMBER}
          </Typography>
        </div>
      </components.Option>
    )
  } else if (mode === "MUTUALFUNDS" && !props.data.__isNew__) {
    return (
      <components.Option {...props}>
        <Typography variant="subtitle2">{props.data.label}</Typography>
        <Typography noWrap variant="caption" display="block">
          {props.data.row.code}
        </Typography>
      </components.Option>
    )
  } else {
    return (
      <components.Option {...props}>
        <Typography variant="subtitle2" noWrap>
          {props.data.label}
        </Typography>
      </components.Option>
    )
  }
}

export const getdropDownoptions = (options) => (options ? options : [])

export const MenuList = (props) => {
  const { children, getValue, accountSiteSettingKeyValue, options, onImportClick, removeAddIcon, label, extraDisplayFields } = props
  const [value] = getValue()
  const { mode } = props.selectProps
  height = 37
  if (mode === "CUSTOMER") {
    height = height + 19
    if (accountSiteSettingKeyValue && parseInt(accountSiteSettingKeyValue.show_gstinCutomerList) === 1) {
      height = height + 19
    }
  } else if (mode === "WITHDESCRIPTION" || mode === "MEMBER" || mode === "ALLACCOUNT") {
    height = height + 19
  } else if (mode === "ITEM") {
    if (!isblank(extraDisplayFields) && extraDisplayFields.length > 0) {
      height = height + 19 * extraDisplayFields.length
    }
    if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.item_group === 1) {
      height = height + 19
      if (accountSiteSettingKeyValue.item_category === 1 || (accountSiteSettingKeyValue.sku_number === 1 && accountSiteSettingKeyValue.tax === 1)) {
        height = height + 19
      }
      if (accountSiteSettingKeyValue.item_category === 1 && accountSiteSettingKeyValue.item_subgroupLevel2 === 1) {
        height = height + 19
      }
    } else if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.sku_number === 1) {
      height = height + 19
    } else if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.tax === 1) {
      height = height + 19
    }
  } else if (mode === "STOCKS") {
    height = height + 38
  } else if (mode === "MUTUALFUNDS") {
    height = height + 38
  }
  const dropDownoptions = getdropDownoptions(options)
  const initialOffset = dropDownoptions.indexOf(value) * height
  const getItemSize = (index) => height
  // Scroll to item API of `react-window`
  // See https://react-window.now.sh/#/api/FixedSizeList
  const scrollToIndex = children.length ? children.findIndex((child) => child.props.isFocused) : 0

  // Use `useRef` hook to maintain the access to the menu list
  const listRef = useRef(null)

  // effect that happens after rendering to properly adjust the selection
  useEffect(() => {
    if (listRef.current) {
      if (dropDownoptions.length > 0) {
        listRef.current.scrollToItem(scrollToIndex)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToIndex])

  var listheight = 250
  if (checkCondition(mode, ["CUSTOMER", "MEMBER", "ALLACCOUNT", "ITEM", "STOCKS", "MUTUALFUNDS"])) {
    listheight = 300
  }

  if (children.length > 0) {
    return (
      <RWList
        ref={listRef}
        height={children.length * height < listheight ? children.length * height : listheight}
        //height={300}
        itemCount={children.length}
        itemSize={getItemSize}
        initialScrollOffset={initialOffset}
        data-testid="dropDown-item"
      >
        {({ index, style }) => {
          return (
            <div
              style={{
                ...style,
                ...{
                  //backgroundColor: theme.palette.background.paper,
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "center",
                  borderRadius: 0,
                },
              }}
            >
              {children[index]}
            </div>
          )
        }}
      </RWList>
    )
  } else {
    if (onImportClick) {
      return (
        <React.Fragment>
          {onImportClick && (
            <ListItem button disableRipple disbletouchripple="true" component="div" dense onClick={onImportClick}>
              {!removeAddIcon && (
                <ListItemIcon style={{ marginRight: 0 }}>
                  <Add />
                </ListItemIcon>
              )}
              <ListItemText style={{ paddingLeft: 5 }} primary={!isblank(label) ? `Import ${label}` : `Import`} />
            </ListItem>
          )}
        </React.Fragment>
      )
    } else {
      return <components.NoOptionsMessage {...props} />
    }
  }
}

export const getSelectTheme = (theme, touched, error) => {
  return {
    /*
     * multiValue(remove)/color:hover
     */
    danger: theme.palette.text.primary,

    /*
     * multiValue(remove)/backgroundColor(focused)
     * multiValue(remove)/backgroundColor:hover
     */
    //dangerLight: theme.palette.grey[200],
    dangerLight: theme.palette.text.primary,

    /*
     * control/backgroundColor
     * menu/backgroundColor
     * option/color(selected)
     */
    // neutral0: theme.palette.background.default,
    neutral0: theme.palette.background.paper,

    /*
     * control/backgroundColor(disabled)
     */
    neutral5: "rgba(239, 239, 239, 0.3)",

    /*
     * control/borderColor(disabled)
     * multiValue/backgroundColor
     * indicators(separator)/backgroundColor(disabled)
     */
    neutral10: "rgba(0, 0, 0, 0.1)",

    /*
     * control/borderColor
     * option/color(disabled)
     * indicators/color
     * indicators(separator)/backgroundColor
     * indicators(loading)/color
     */
    neutral20: Boolean(touched && error) ? theme.palette.error.main : theme.palette.input.border,

    /*
     * control/borderColor(focused)
     * control/borderColor:hover
     */
    // this should be the white, that's normally selected
    neutral30: theme.palette.text.primary,

    /*
     * menu(notice)/color
     * singleValue/color(disabled)
     * indicators/color:hover
     */
    neutral40: "rgba(0, 0, 0, 0.3)",

    /*
     * placeholder/color
     */
    // seen in placeholder text
    neutral50: theme.palette.grey["A200"],

    /*
     * indicators/color(focused)
     * indicators(loading)/color(focused)
     */
    neutral60: theme.palette.text.primary,
    neutral70: theme.palette.text.primary,

    /*
     * input/color
     * multiValue(label)/color
     * singleValue/color
     * indicators/color(focused)
     * indicators/color:hover(focused)
     */
    neutral80: theme.palette.text.primary,

    // no idea
    neutral90: "pink",

    /*
     * control/boxShadow(focused)
     * control/borderColor(focused)
     * control/borderColor:hover(focused)
     * option/backgroundColor(selected)
     * option/backgroundColor:active(selected)
     */
    //primary: theme.palette.text.primary,
    primary: Boolean(touched && error) ? theme.palette.error.main : theme.palette.primary.main,

    /*
     * option/backgroundColor(focused)
     */
    primary25: theme.palette.type == "dark" ? theme.palette.background.default : "#DEEBFF",

    /*
     * option/backgroundColor:active
     */
    primary50: theme.palette.type == "dark" ? theme.palette.background.default : "#B2D4FF",
    primary75: theme.palette.background.paper,
  }
}

export const defaultStringify = (option, props) => {
  const { mode, accountSiteSettingKeyValue, extraDisplayFields } = props
  if (mode === "CUSTOMER") {
    if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.tax === 1 && parseInt(accountSiteSettingKeyValue.show_gstinCutomerList) === 1) {
      return `${option.label} ${option.data.row.city} ${option.data.row.gstin}`
    } else {
      return `${option.label} ${option.data.row.city} ${option.data.row.mobile}`
    }
  } else if (mode === "ITEM") {
    var itemstring = `${option.label}`
    if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.item_group === 1) {
      itemstring = `${itemstring} ${option.data.row.itemgroup}`
      if (accountSiteSettingKeyValue.item_category === 1) {
        itemstring = `${itemstring} ${option.data.row.itemcate_name}`
      }
    }

    if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.barcode_mng === 1 && accountSiteSettingKeyValue.sku_number === 1) {
      itemstring = `${itemstring} ${option.data.row.stockku}`
    } else if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.barcode_mng === 4) {
      itemstring = `${itemstring} ${option.data.row.code}`
    }

    if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.tax === 1) {
      itemstring = `${itemstring} ${option.data.row.hsn_code}`
    }

    if (!isblank(extraDisplayFields) && extraDisplayFields.length > 0) {
      extraDisplayFields.forEach((e, ind) => {
        itemstring = `${itemstring} ${option.data.row[e.value]}`
      })
    }

    return itemstring
  } else if (mode === "MEMBER") {
    return `${option.label} ${option.data.row.mobile} ${option.data.row.memberid}`
  } else if (mode === "ALLACCOUNT") {
    return `${option.label} ${option.data.row.groupName} ${option.data.row.city}`
  } else if (mode === "STOCKS") {
    return `${option.label} ${option.data.row.SYMBOL} ${option.data.row._ISIN_NUMBER} ${option.data.row._SERIES}`
  } else if (mode === "MUTUALFUNDS") {
    return `${option.label} ${option.data.row.code}`
  } else {
    return `${option.label}`
  }
}

export const filterConfig = (accountSiteSettingKeyValue, mode, extraDisplayFields) => {
  return {
    ignoreCase: true,
    ignoreAccents: false,
    trim: true,
    matchFrom: accountSiteSettingKeyValue && accountSiteSettingKeyValue.search_filter === 1 ? "start" : "any",
    stringify: (option) => defaultStringify(option, { mode, accountSiteSettingKeyValue, extraDisplayFields }),
  }
}

export const LoadingIndicator = (prop) => {
  return <CircularProgress color="primary" size={18} style={{ marginRight: 4 }} />
}

export function multiChangeHandler(func, onvaluechange, dataindex) {
  return function handleMultiHandler(values) {
    if (values != null) {
      func(values.map((value) => value.value))
      if (onvaluechange) {
        onvaluechange(
          dataindex,
          values.map((value) => value.value),
          values.map((value) => value.row)
        )
      }
    } else {
      func([])
      if (onvaluechange) {
        onvaluechange([])
      }
    }
  }
}

export function singleChangeHandler(func, onvaluechange, dataindex, onRecentChange, mode) {
  return function handleSingleChange(value) {
    func(value ? value.value : "")
    if (onvaluechange || onRecentChange) {
      var objvalue = value ? value.row : ""
      var objdataindex = dataindex >= 0 ? dataindex : ""

      if (onvaluechange) {
        onvaluechange(objvalue, objdataindex)
      }
      if ((mode === "ITEM" || mode === "ALLACCOUNT") && onRecentChange) {
        onRecentChange(objvalue, objdataindex, mode)
      }
    }
  }
}

export function MultiValue(props) {
  return <Chip size="small" style={{ margin: "4px 2px" }} tabIndex={-1} label={props.children} onDelete={props.removeProps.onClick} deleteIcon={<Cancel {...props.removeProps} />} />
}

export const colourStyles = (setUnderLine, mode, error, theme, HelpComponent) => {
  const noBorder = {}
  if (!(isblank(setUnderLine) || setUnderLine == true)) {
    noBorder.borderWidth = 0
    noBorder.borderColor = "transparent"
  }
  if (!isblank(error) && error !== false) {
    noBorder.borderColor = theme.palette.error.main
  }
  var minWidth = 250
  if (checkCondition(mode, ["ITEM", "CUSTOMER", "ALLACCOUNT", "MEMBER"])) {
    minWidth = 450
  }

  return {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    control: (base, state) => ({
      ...base,
      ...noBorder,
      boxShadow: "none",
      minHeight: noBorder.borderWidth === 0 ? 30 : 32,
      outline: state.isFocused ? "auto" : "none",
      outlineStyle: state.isFocused ? "solid" : "none",
      outlineWidth: noBorder.borderWidth === 0 ? (state.isFocused ? 2 : 0) : state.isFocused ? 1 : 0,
      outlineColor: state.isFocused ? theme.palette.primary.main : "transparent",
    }),
    valueContainer: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 8,
      paddingLeft: HelpComponent ? 38 : 8,
    }),
    container: (base) => ({
      ...base,
      marginRight: noBorder.borderWidth === 0 ? 2 : 0,
      marginLeft: noBorder.borderWidth === 0 ? 2 : 0,
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "1rem",
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: "1rem",
    }),
    menu: (base) => ({
      ...base,
      minWidth: minWidth,
      zIndex: 9999,
    }),
    option: (base) => ({
      ...base,
    }),
  }
}

export const handleBlur = (value, onBlur) => {
  setTimeout(() => {
    onBlur(value)
  }, 1)
}

export const getMultiValue = (values, options) => {
  var returnValue = []
  const formattedOptions = {}
  const dropDownoptions = getdropDownoptions(options)
  dropDownoptions.forEach((obj) => {
    formattedOptions[obj.value] = obj
  })
  if (!isblank(values)) {
    if (typeof values === "string") {
      values.split(", ").forEach((e) => {
        if (!isblank(formattedOptions) && !isblank(formattedOptions[e])) {
          returnValue.push(formattedOptions[e])
        }
      })
    } else if (values.length > 0) {
      values.forEach((e) => {
        if (!isblank(formattedOptions) && !isblank(formattedOptions[e])) {
          returnValue.push(formattedOptions[e])
        }
      })
    }
  }
  return returnValue
}

export const ClearIndicator = (props) => {
  const {
    innerProps: { ref, ...restInnerProps },
    isClearable,
    input,
  } = props
  if ((!isblank(isClearable) && isClearable !== false) || isblank(isClearable)) {
    if (!isblank(input.value)) {
      return (
        <IconButton style={{ marginRight: 4 }} ref={ref} {...restInnerProps} size="small" tabIndex="-1">
          {" "}
          <Close fontSize="small" />
        </IconButton>
      )
    }
    return null
  } else {
    return (
      <IconButton ref={ref} style={{ marginRight: 4 }} {...restInnerProps} size="small" tabIndex="-1">
        {" "}
        <Close fontSize="small" />
      </IconButton>
    )
  }
}

export const DropdownIndicator = (prop) => {
  const {
    innerProps: { ref, ...restInnerProps },
    options,
    props,
    input,
    isClearable,
  } = prop
  const dropDownoptions = getdropDownoptions(options)
  if (!props.isLoading && (isblank(input.value) || input.value.length === 0 || dropDownoptions?.filter((e) => convertIntif(e?.value) === convertIntif(input.value)).length === 0)) {
    return (
      <IconButton ref={ref} {...restInnerProps} size="small" tabIndex="-1">
        {" "}
        <ExpandMore fontSize="medium" />
      </IconButton>
    )
  } else {
    if ((!isblank(isClearable) && isClearable !== false) || props.isLoading || isblank(isClearable)) {
      return null
    } else {
      return (
        <IconButton ref={ref} {...restInnerProps} size="small" tabIndex="-1">
          {" "}
          <ExpandMore fontSize="medium" />
        </IconButton>
      )
    }
  }
}

const indicatorSeparatorStyle = (theme) => {
  return {
    alignSelf: "stretch",
    backgroundColor: theme.palette.divider,
    marginBottom: 8,
    marginTop: 8,
    width: 1,
  }
}

export const IndicatorSeparator = ({ innerProps, input, props, theme, isClearable }) => {
  if ((isblank(input.value) || input.value.length === 0) && !props.isLoading) {
    return <span style={indicatorSeparatorStyle(theme)} {...innerProps} />
  } else {
    if ((!isblank(isClearable) && isClearable !== false) || props.isLoading || isblank(isClearable)) {
      return null
    } else {
      return <span style={indicatorSeparatorStyle(theme)} {...innerProps} />
    }
  }
}

export const formThemeColors = (theme, touched, error) => {
  return { ...getSelectTheme(theme, touched, error) }
}

export const renderLabel = ({
  name,
  label,
  noLableMargin,
  labelStyle,
  isRequired,
  theme,
  touched,
  error,
  helpText,
  helpStyle,
  btnText,
  toolTipStyle,
  onSecondbtnClick,
  secondbtnText,
  onbtnClick,
  input,
}) => {
  let inputLabelStyle = {
    color: isRequired ? theme.palette.error.main : theme.palette.text.main,
    marginTop: 8,
    marginBottom: 4,
  }

  if (labelStyle) {
    inputLabelStyle = { ...inputLabelStyle, ...labelStyle }
  }

  if (isblank(label)) {
    return null
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <LabelComponent name={`${name}`} noLableMargin={noLableMargin} label={label} isRequired={isRequired} error={touched && error} labelStyle={labelStyle} />
        {helpText && <HelpBtnComponent help={helpText} style={helpStyle ? helpStyle : { marginLeft: 4 }} toolTipStyle={toolTipStyle} />}
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        {onSecondbtnClick && (
          <DebounceLink
            data-testid={`${input.name}_link_1`}
            component="button"
            variant="body2"
            underline="always"
            //color='error'
            onClick={onSecondbtnClick}
          >
            {secondbtnText}
          </DebounceLink>
        )}
        {!isblank(onSecondbtnClick) && !isblank(onbtnClick) && input.value && <Typography style={{ fontSize: 10, marginLeft: 5, marginRight: 5 }}>{`||`}</Typography>}
        {onbtnClick && input.value && (
          <DebounceLink component="button" data-testid={`${input.name}_link_2`} variant="body2" underline="always" onClick={onbtnClick}>
            {btnText}
          </DebounceLink>
        )}
      </div>
    </div>
  )
}

export const ValueContainer = ({ showOptions, children, ...props }) => {
  let [values, input] = children
  if (Array.isArray(values)) {
    if (showOptions === 0) {
      const plural = values.length === 1 ? "" : "s"
      values = `${values.length} item${plural} selected`
    } else {
      const val = (i) => values[i].props.children
      let { length } = values

      if (showOptions === 1) {
        switch (length) {
          case 1:
            values = `${val(0)} selected`
            break
          default:
            const plural = values.length === 1 + 1 ? "" : "s"
            const otherCount = length - 1
            values = `${val(0)} +${otherCount} other${plural} selected`
            break
        }
      } else if (showOptions === 2) {
        switch (length) {
          case 1:
            values = `${val(0)} selected`
            break
          case 2:
            values = `${val(0)} and ${val(1)} selected`
            break
          default:
            const plural = values.length === 2 + 1 ? "" : "s"
            const otherCount = length - 2
            values = `${val(0)} +${otherCount} other${plural} selected`
            break
        }
      } else {
        switch (length) {
          case 1:
            values = `${val(0)} selected`
            break
          case 2:
            values = `${val(0)} and ${val(1)} selected`
            break
          case 3:
            values = `${val(0)}, ${val(1)} and ${val(2)} selected`
            break
          default:
            const plural = values.length === 3 + 1 ? "" : "s"
            const otherCount = length - 3
            values = `${val(0)}, ${val(1)}, ${val(2)} +${otherCount} other${plural} selected`
            break
        }
      }
    }
  }
  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  helpComponentIcon: {
    color: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  helpComponentBox: {
    position: "absolute",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 38,
    cursor: "help",
  },
}))

export const RenderHelpComponent = ({ HelpComponent }) => {
  const classes = useStyles()
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.background.paper,
      maxWidth: 350,
      border: `1px solid ${theme.palette.divider}`,
    },
    arrow: {
      color: theme.palette.background.paper,
    },
  }))(Tooltip)

  if (HelpComponent) {
    return (
      <HtmlTooltip title={HelpComponent} arrow placement="top-start">
        <div className={classes.helpComponentBox} style={{ height: 30 }}>
          <Info tabIndex="-1" className={classes.helpComponentIcon} style={{ fontSize: 20 }} />
        </div>
      </HtmlTooltip>
    )
  }
  return null
}

export const RenderButtonComponent = ({ ButtonComponent }) => {
  if (ButtonComponent) {
    const classes = useStyles()
    const { component, onClick, col, row, index } = ButtonComponent
    const toolTip = col?.popover?.toolTip ? col?.popover?.toolTip : ""

    if (component) {
      return (
        <Tooltip title={toolTip} arrow placement="top-start">
          <div className={classes.helpComponentBox} style={{ height: 30 }}>
            <Update tabIndex="-1" className={classes.helpComponentIcon} style={{ fontSize: 20 }} onClick={(e) => onClick(e, col, row, index)} />
          </div>
        </Tooltip>
      )
    }
  }
  return null
}
