import { Dialog, DialogTitle, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography, makeStyles } from "@material-ui/core"
import Slide from "@material-ui/core/Slide"
import { Close, Comment, GetApp, Help, HelpOutline, Mail, Phone, WhatsApp } from "@material-ui/icons"
import React from "react"

const useStyles = makeStyles((theme) => ({
  dialog: {
    position: "absolute",
    right: 16,
    bottom: 16,
    width: 300,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default ({ visible, onClose, handleHelpChatClick }) => {
  const classes = useStyles()

  const downloadRemoteDesktop = () => {
    onClose()
    const win = window.open("https://remotedesktop.google.com/")
    win.focus()
  }

  const remoteDesktopSetup = () => {
    onClose()
    const win = window.open("https://youtu.be/ZCINcElw2o8?si=W8ZiQeddxR1AgUXL")
    win.focus()
  }

  const helpCenterClick = () => {
    onClose()
    const win = window.open("https://help.finalbooks.in")
    win.focus()
  }

  const whatsappClick = () => {
    onClose()
    const win = window.open("https://wa.me/send?phone=919714776230")
    win.focus()
  }

  const phoneClick = () => {
    onClose()
    const win = window.open("tel:919714776230")
    win.focus()
  }

  const mailClick = () => {
    onClose()
    const win = window.open("mailto:hello@finalbooks.in")
    win.focus()
  }

  return (
    <Dialog
      open={visible}
      TransitionComponent={Transition}
      classes={{
        paper: classes.dialog,
      }}
      onClose={onClose}
    >
      <DialogTitle disableTypography style={{ paddingBottom: 0 }}>
        <Typography variant="h6">Help</Typography>
        {onClose ? (
          <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        ) : null}
      </DialogTitle>

      <List dense>
        {handleHelpChatClick && (
          <ListItem
            button
            onClick={() => {
              onClose()
              handleHelpChatClick()
            }}
          >
            <ListItemIcon>
              <Comment color="primary" />
            </ListItemIcon>
            <ListItemText primary="Chat Support" secondary="Mon to Fri - 10AM to 6PM" />
          </ListItem>
        )}

        <ListItem button onClick={helpCenterClick}>
          <ListItemIcon>
            <Help color="primary" />
          </ListItemIcon>
          <ListItemText primary="Help center" secondary="Help Documentation" />
        </ListItem>
        <ListItem button onClick={phoneClick}>
          <ListItemIcon>
            <Phone color="primary" />
          </ListItemIcon>
          <ListItemText primary="+91 9714776230" secondary="Mon to Fri - 10AM to 6PM " />
        </ListItem>
        <ListItem button onClick={mailClick}>
          <ListItemIcon>
            <Mail color="primary" />
          </ListItemIcon>
          <ListItemText primary="hello@finalbooks.in" secondary="We usually reply same day" />
        </ListItem>
        <ListItem button onClick={whatsappClick}>
          <ListItemIcon>
            <WhatsApp color="primary" />
          </ListItemIcon>
          <ListItemText primary="+91 9714776230" secondary="WhatsApp us your queries" />
        </ListItem>
        <ListItem button onClick={downloadRemoteDesktop}>
          <ListItemIcon>
            <GetApp color="primary" />
          </ListItemIcon>
          <ListItemText primary="Download Remote Desktop" secondary="For remote support" />
        </ListItem>
        <ListItem button onClick={remoteDesktopSetup}>
          <ListItemIcon>
            <HelpOutline color="primary" />
          </ListItemIcon>
          <ListItemText primary="Google Remote Desktop Setup" secondary="Learn how to setup" />
        </ListItem>
      </List>
    </Dialog>
  )
}
