import { getdropdownvalue } from "@project/components/utilityFunctions"
import { convertIntif } from "@project/sharedcomponents/utilityFunctions"
import { BOT_SERVICE_CLEAR, BOT_SERVICE_DELETED, BOT_SERVICE_FAIL, BOT_SERVICE_INSERTED, BOT_SERVICE_START, BOT_SERVICE_SUCCESS, BOT_SERVICE_UPDATED } from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  totalcount: 0,
  rowsPerPage: 0,
  page: 0,
  order: "asc",
  deleted: false,
  orderBy: "code",
  norecord: false,
  search_text: "",
  columnData: [
    { id: "name", numeric: false, visible: true, label: "Name" },
    { id: "description", numeric: false, visible: true, label: "Description", width: 250 },
    { id: "type", numeric: false, visible: true, label: "Type", width: 150 },
    {
      id: "status",
      numeric: false,
      visible: true,
      label: "Status",
      width: 75,
      badge: {
        Active: { color: "green" },
        InActive: { color: "red" },
      },
    },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BOT_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case BOT_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case BOT_SERVICE_SUCCESS:
      var finalData = action.payload.data.result.map((item, index) => {
        const updatedItem = { ...item }
        updatedItem.status = item.status === "1" ? "Active" : "InActive"
        return updatedItem
      })
      return {
        ...state,
        data: finalData,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        islogin: true,
        norecord: action.payload.data.result.length === 0,
      }
    case BOT_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        data: [],
        loading: false,
        totalcount: 0,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
      }

    case BOT_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_code
      inserteditem.name = action.payload.name
      inserteditem.description = action.payload.description
      inserteditem.type = getdropdownvalue({ formValues: action.payload, field: "type", prop: "template_title" })
      inserteditem.status = "Active"
      return { ...state, data: [inserteditem, ...state.data] }
    case BOT_SERVICE_DELETED:
      return { ...state, data: state.data.filter((item) => convertIntif(item.code) !== convertIntif(action.payload)), deleted: !state.deleted }
    case BOT_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.update_code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.name = action.payload.name
        updateditem.description = action.payload.description
        updateditem.type = getdropdownvalue({ formValues: action.payload, field: "type", prop: "template_title" })
        updateditem.status = "Active"
        return updateditem
      })
      return { ...state, data: updateddata }
    default:
      return state
  }
}
