import { makeStyles } from "@material-ui/core"
import { CheckCircle, Error, Info, Warning } from "@material-ui/icons"
import { Alert } from "@material-ui/lab"
import React from "react"

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: theme.palette.alert.warning,
    color: theme.palette.alert.warningText,
  },
  error: {
    backgroundColor: theme.palette.alert.error,
    color: theme.palette.alert.errorText,
  },
  info: {
    backgroundColor: theme.palette.alert.info,
    color: theme.palette.alert.infoText,
  },
  success: {
    backgroundColor: theme.palette.alert.success,
    color: theme.palette.alert.successText,
  },
  warningIcon: {
    color: theme.palette.alert.warningText,
  },
  infoIcon: {
    color: theme.palette.alert.infoText,
  },
  successIcon: {
    color: theme.palette.alert.successText,
  },
  errorIcon: {
    color: theme.palette.alert.errorText,
  },
}))

function CustomAlert({ ...props }) {
  const classes = useStyles()
  return (
    <Alert
      className={classes[props.severity]}
      {...(props.icon
        ? {
            icon: props.icon,
          }
        : props.severity === "warning"
          ? {
              icon: <Warning className={classes.warningIcon} />,
            }
          : props.severity === "info"
            ? {
                icon: <Info className={classes.infoIcon} />,
              }
            : props.severity === "success"
              ? {
                  icon: <CheckCircle className={classes.successIcon} />,
                }
              : props.severity === "error"
                ? {
                    icon: <Error className={classes.errorIcon} />,
                  }
                : null)}
      {...props}
    />
  )
}

export default CustomAlert
