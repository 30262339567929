import session_store from "store"
import { isblank } from "@project/sharedcomponents/utilityFunctions"
import { commonSiteSetting } from "@project/components/utilityFunctions"
import { encryption, decryption } from "./cryptoJSFunctions"

export const setStorage = (key, data) => {
  session_store.set(key, encryption({ data }))
}

export const clearAll = () => {
  session_store.clearAll()
}

export const removeStorage = (key) => {
  session_store.remove(key)
}

export const getStorage = (key) => {
  var ciphertext = ""
  var bytes = ""
  ciphertext = session_store.get(key)
  if (isblank(ciphertext)) {
    return undefined
  }
  bytes = decryption({ data: ciphertext })
  return bytes
}

export const getauthtoken = () => {
  var auth_token = getStorage("auth_token")
  return auth_token !== undefined ? auth_token : ""
}

export const getlastpageuserright = () => {
  if (getStorage("last_menu_key") !== null && getStorage("last_menu_key") !== undefined) {
    var key = getStorage("last_menu_key")
    var userrights = getStorage("user_rights")
    return userrights !== undefined ? (userrights.hasOwnProperty(key) ? userrights[key].split("|") : []) : []
  } else {
    return []
  }
}

export const getsessionuserdata = (key) => {
  var firmdetails = getStorage("user_data")
  return firmdetails !== undefined ? (firmdetails.hasOwnProperty(key) ? firmdetails[key] : "") : ""
}

export const getheaders = () => {
  var auth_token = getStorage("auth_token")
  auth_token = auth_token !== undefined ? auth_token : ""
  return {
    Authorization: auth_token,
    version: process.env.REACT_APP_VERSION,
    devicetype: 0, //website
  }
}

export const getsitesetting = (key, meta) => {
  var firmdetails = ""
  if (getStorage("module_type") === "payroll") {
    firmdetails = getStorage("payroll_site_setting")
  } else {
    firmdetails = getStorage("site_setting")
  }
  return commonSiteSetting({ key, settingData: firmdetails, meta })
}

export const getstartyear = () => {
  var minYear = parseInt(getsessionuserdata("financial_year"), 10)
  return minYear
}

export const getendyear = () => {
  var minYear = parseInt(getsessionuserdata("financial_year"), 10)
  var maxYear = minYear + 1
  return maxYear
}

export const isFBDate = (str) => {
  var VALUE = ""
  var d = new Date()
  var cm = d.getMonth() + 1
  var cy = d.getFullYear()
  /* eslint-disable-next-line */
  var parms = str.split(/[\.\-\/]/)
  var yyyy = parseInt(parms[2], 10)
  var mm = parseInt(parms[1], 10)
  var dd = parseInt(parms[0], 10)

  if (isNaN(mm)) {
    mm = cm
  }

  if (isNaN(yyyy)) {
    if (mm > 3) {
      yyyy = getstartyear()
    } else {
      yyyy = getendyear()
    }
  } else {
    if (yyyy.toString().length === 4) {
    } else if (yyyy.toString().length === 2) {
      yyyy = parseInt(Math.floor(cy / 100).toString() + yyyy.toString())
    } else {
      yyyy = ""
    }
  }

  var date = new Date(yyyy, mm - 1, dd, 0, 0, 0, 0)
  if (mm === date.getMonth() + 1 && dd === date.getDate() && (yyyy === date.getFullYear() || yyyy === date.getYear())) {
    if (dd < 10) {
      dd = "0" + dd.toString()
    }
    if (mm < 10) {
      mm = "0" + mm.toString()
    }

    VALUE = dd + "-" + mm + "-" + date.getFullYear()
  } else {
    VALUE = ""
  }
  return VALUE
}
