import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Close } from "@material-ui/icons"
import { capitalizeFirstLetter, cleanStringForPDFMake, convertIntif, getCapitalize, getNumberonly, getNumberonlyDecimal, isblank } from "@project/sharedcomponents/utilityFunctions"
import React, { useEffect, useRef } from "react"
import { isMobileOnly } from "react-device-detect"
import { useSelector, useDispatch } from "react-redux"
import { components, createFilter } from "react-select"
import CreatableSelect from "react-select/creatable"
import {
  ClearIndicator,
  DropdownIndicator,
  IndicatorSeparator,
  LoadingIndicator,
  MenuList,
  MultiValue,
  Option,
  RenderHelpComponent,
  colourStyles,
  filterConfig,
  formThemeColors,
  getMultiValue,
  getdropDownoptions,
  handleBlur,
  multiChangeHandler,
  renderLabel,
  singleChangeHandler,
} from "./reactSelectComponent"

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const RFReactSelect = ({
  input,
  options,
  isClearable,
  isSearchable,
  noLableMargin,
  helpStyle,
  toolTipStyle,
  multi,
  mode,
  label,
  HelpComponent,
  helpText,
  disabled,
  setUnderLine,
  className,
  onvaluechange,
  onImportClick,
  getconfigsettingdata,
  addRecentItem,
  addRecentAllAccount,
  onbtnClick,
  btnText,
  secondbtnText,
  onSecondbtnClick,
  dataindex,
  isRequired,
  removeAddIcon,
  meta,
  onAddNewClick,
  isNumeric,
  decimal,
  placeholder,
  extraDisplayFields,
  disableAutoCaps,
  domref = null,
  ...props
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const dialogButtonRef = useRef()
  const accountSiteSettingKeyValue = useSelector((state) => state.AuthReducer.accountSiteSettingKeyValue)
  const { recentItems, recentAllAccount } = useSelector((state) => state.CommonReducer)
  const __cust_hideContactInfo = getconfigsettingdata ? parseInt(getconfigsettingdata("__cust_hideContactInfo")) || 0 : 0
  const dispatch = useDispatch()
  const { name, onBlur, onChange } = input
  const { error, touched } = meta

  const formattedOptions = {}
  const [confirmCreate, setconfirmCreate] = React.useState(false)
  const [newValue, setNewValue] = React.useState("")
  let dropDownoptions = getdropDownoptions(options)
  const on_Change = (value) => {
    onChange(value)
  }

  dropDownoptions.forEach((obj) => {
    formattedOptions[obj.value] = obj
  })

  if (mode === "ITEM") {
    dropDownoptions = [...recentItems.map((opt) => dropDownoptions.find((e) => e.value === opt)), ...dropDownoptions.filter((opt) => !recentItems.some((recent) => recent === opt.value))]
  }
  if (mode === "ALLACCOUNT") {
    dropDownoptions = [...recentAllAccount.map((opt) => dropDownoptions.find((e) => e.value === opt)), ...dropDownoptions.filter((opt) => !recentAllAccount.some((recent) => recent === opt.value))]
  }

  const isValidNewOption = (inputValue) => {
    if (onAddNewClick && !isblank(inputValue)) {
      if (convertIntif(getNumberonly(isNumeric)) === 1) {
        var decimalPoint = 2
        if (!isblank(decimal)) {
          decimalPoint = convertIntif(getNumberonly(decimal))
        }
        return options.filter((e) => parseFloat(getNumberonlyDecimal(e.label, decimalPoint)) === parseFloat(getNumberonlyDecimal(inputValue, decimalPoint))).length === 0
      } else {
        return options.filter((e) => e.label.toLowerCase() === inputValue.toLowerCase(), decimalPoint).length === 0
      }
    }
    return false
  }

  useEffect(() => {
    setTimeout(() => {
      if (dialogButtonRef && dialogButtonRef.current) {
        // dialogButtonRef.current.focus()
      }
    }, 0)
  }, [confirmCreate])

  const customAddNewClick = (value) => {
    let newValue = value
    if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.textTransformation === "u" && !disableAutoCaps) {
      newValue = newValue.toUpperCase()
    } else if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.textTransformation === "c" && !disableAutoCaps) {
      newValue = getCapitalize(newValue)
    }

    newValue = cleanStringForPDFMake(newValue)

    setNewValue(newValue)
    setconfirmCreate(true)
  }

  const closeConfirmDialog = () => {
    // document.getElementById(name).focus()
    setconfirmCreate(false)
  }

  const handleCreate = () => {
    // document.getElementById(name).focus()
    setconfirmCreate(false)
    onAddNewClick(newValue)
  }

  // const handleInputChange = (inputValue, action) => {
  // 	if (action.action !== 'input-blur' && action.action !== 'menu-close') {
  // 		//   this.setState({ inputValue });
  // 	}
  // }

  const onRecentChange = (value, index, mode) => {
    if (!isblank(value)) {
      if (mode === "ITEM") {
        dispatch(addRecentItem(value.code))
      } else if (mode === "ALLACCOUNT") {
        dispatch(addRecentAllAccount(value.code))
      }
    }
  }

  return (
    <React.Fragment>
      {renderLabel({ label, isRequired, theme, noLableMargin, touched, error, helpText, helpStyle, btnText, toolTipStyle, onSecondbtnClick, secondbtnText, onbtnClick, input })}

      <div style={{ position: "relative" }}>
        <RenderHelpComponent HelpComponent={HelpComponent} />
        <CreatableSelect
          {...props}
          {...(placeholder && {
            placeholder: capitalizeFirstLetter(placeholder),
          })}
          ref={domref}
          type="text"
          isMulti={multi}
          menuPortalTarget={document.body}
          isValidNewOption={isValidNewOption}
          menuShouldBlockScroll={true}
          menuShouldScrollIntoView={true}
          filterOption={createFilter(filterConfig(accountSiteSettingKeyValue, mode, extraDisplayFields))}
          styles={colourStyles(setUnderLine, mode, Boolean(touched && error), theme, HelpComponent)}
          menuPlacement="auto"
          isClearable={isClearable !== undefined ? isClearable : true}
          isSearchable={isSearchable !== undefined ? isSearchable : true}
          {...(multi
            ? {
              value: !isblank(input.value) ? getMultiValue(input.value, dropDownoptions) : [],
              defaultValue: !isblank(input.value) ? getMultiValue(input.value, dropDownoptions) : [],
            }
            : {
              value: !isblank(input.value) ? formattedOptions[input.value] : null,
              defaultValue: !isblank(input.value) ? input.value : null,
            })}
          isDisabled={disabled}
          // onInputChange={handleInputChange}
          onChange={multi ? multiChangeHandler(on_Change, onvaluechange, dataindex) : singleChangeHandler(on_Change, onvaluechange, dataindex, onRecentChange, mode)}
          {...(isMobileOnly && {
            blurInputOnSelect: false,
          })}
          onBlur={() => (multi ? handleBlur([...input.value], onBlur) : handleBlur(input.value, onBlur))}
          components={{
            Option: (OptionProps) => Option({ ...OptionProps, accountSiteSettingKeyValue, mode, extraDisplayFields, __cust_hideContactInfo }),
            MenuList: (MenuProps) => MenuList({ ...MenuProps, accountSiteSettingKeyValue, options: dropDownoptions, onImportClick, components, removeAddIcon, extraDisplayFields }),
            MultiValue,
            ClearIndicator: (cIProps) => ClearIndicator({ ...cIProps, isClearable, input }),
            DropdownIndicator: (DdProps) => DropdownIndicator({ ...DdProps, accountSiteSettingKeyValue, options: dropDownoptions, props, input, isClearable }),
            IndicatorSeparator: (IdProps) => IndicatorSeparator({ ...IdProps, input, props, theme, isClearable }),
            LoadingIndicator,
          }}
          onFocuses={() => onFocus(input.value)}
          mode={mode}
          options={dropDownoptions}
          inputId={name}
          label={label}
          {...(onAddNewClick && {
            onCreateOption: customAddNewClick,
          })}
          error={Boolean(touched && error)}
          getOptionLabel={(option) => `${option.label}`}
          closeMenuOnSelect={true}
          theme={(theme1) => ({
            ...theme1,
            borderRadius: 0,
            colors: {
              ...formThemeColors(theme, touched, error),
            },
          })}
          name={name}
        />
      </div>

      <Dialog
        open={confirmCreate}
        maxWidth={"xs"}
        fullWidth={true}
        onClose={(event, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            closeConfirmDialog()
          } else {
            closeConfirmDialog()
          }
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" disableTypography style={{ paddingBottom: 0 }}>
          <Typography variant="h6">Create New</Typography>
          <IconButton size="small" tabIndex={-1} aria-label="close" className={classes.closeButton} onClick={closeConfirmDialog}>
            <Close fontSize="small" />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <React.Fragment>
            <DialogContentText id="alert-dialog-slide-description">Are you sure want to create new {label ? label : "option"}?</DialogContentText>
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button tabIndex={-1} onClick={closeConfirmDialog} variant="text">
            No
          </Button>
          <Button onClick={handleCreate} variant="text" color={"primary"} ref={dialogButtonRef}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default RFReactSelect
