import { DASHBOARD_SERVICE_FAIL, DASHBOARD_SERVICE_START, DASHBOARD_SERVICE_SUCCESS } from "../Actions/types"

const INITIAL_STATE = {
  error: "",
  loading: false,

  data: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DASHBOARD_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case DASHBOARD_SERVICE_SUCCESS:
      return { ...state, data: action.payload.data.result }
    case DASHBOARD_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false }

    default:
      return state
  }
}
