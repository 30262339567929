import { alpha, createTheme } from "@material-ui/core/styles"
import fbcolor from "./fbcolors"

export default createTheme({
  typography: {
    useNextVariants: true,
    fontSize: 14,
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    type: "light",
    background: {
      default: fbcolor.grey.lightest,
    },
    kanbanBackground: {
      default: fbcolor.grey.lighter,
    },
    primary: {
      main: fbcolor.primary.main,
      light: fbcolor.primary.light,
      dark: fbcolor.primary.dark,
    },
    secondary: {
      main: fbcolor.secondary.main,
      light: fbcolor.secondary.light,
      dark: fbcolor.secondary.dark,
    },
    error: {
      main: fbcolor.error.main,
      light: fbcolor.error.light,
      dark: fbcolor.error.dark,
    },
    success: {
      main: fbcolor.success.main,
      light: fbcolor.success.light,
      dark: fbcolor.success.dark,
    },
    warning: {
      main: fbcolor.warning.main,
      light: fbcolor.warning.light,
      dark: fbcolor.warning.dark,
    },
    info: {
      main: fbcolor.info.main,
      light: fbcolor.info.light,
      dark: fbcolor.info.dark,
    },
    input: {
      border: alpha("#000", 0.23),
      hover: alpha("#000", 1),
    },
    alert: {
      warning: fbcolor.warning.lighter,
      success: fbcolor.success.lighter,
      error: fbcolor.error.lighter,
      info: fbcolor.info.lighter,
      warningText: fbcolor.warning.dark,
      successText: fbcolor.success.dark,
      infoText: fbcolor.info.dark,
      errorText: fbcolor.error.dark,
    },
    chip: {
      red: {
        message: fbcolor.error.dark,
        background: fbcolor.error.light,
      },
      green: {
        message: fbcolor.success.dark,
        background: fbcolor.success.light,
      },
      yellow: {
        message: fbcolor.warning.dark,
        background: fbcolor.warning.light,
      },
      blue: {
        message: fbcolor.info.dark,
        background: fbcolor.info.light,
      },
      purple: {
        message: fbcolor.secondary.dark,
        background: fbcolor.secondary.light,
      },
      black: {
        message: fbcolor.grey.dark,
        background: fbcolor.grey.light,
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "10px",
          height: "10px",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          borderRadius: "10px",
        },
        "*::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        divLoader: {
          background: "red",
          overflow: "hidden",
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },
    MuiLink: {
      root: {
        textAlign: "left",
      },
    },
    MuiAvatar: {
      img: {
        objectFit: "contain",
        height: "100%",
      },
    },
    MuiInputBase: {
      input: {
        padding: "9.5px 0",
        "&:disabled": {
          backgroundColor: fbcolor.grey.lightest,
        },
      },
    },
    MuiButtonGroup: {
      contained: {
        boxShadow: "none",
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "9.5px 10px",
        backgroundColor: "#ffffff",
      },
      multiline: {
        padding: "0 !important",
      },
      inputMultiline: {
        padding: "9.5px 10px",
      },
      notchedOutline: {
        borderRadius: 0,
      },
    },

    MuiAppBar: {
      root: {
        boxShadow: "none",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.9em",
        color: "#fff",
        backgroundColor: fbcolor.black.dark,
      },
      arrow: {
        color: fbcolor.black.dark,
      },
    },
    MuiTableCell: {
      root: {
        borderRight: `1px solid rgba(224, 224, 224, 1)`,
        padding: "8px 10px 8px 10px",
        "&:last-child": {
          paddingRight: 10,
        },
        "&:last-child": {
          borderRight: 0,
        },
        "&:first-child": {
          borderLeft: 0,
        },
        "&:focus": {
          outlineColor: fbcolor.black.dark,
        },
      },
      head: {
        backgroundColor: fbcolor.primary.light,
        color: fbcolor.primary.dark,
      },
      body: {
        wordBreak: "break-all",
      },
      sizeSmall: {
        padding: "4px 5px 4px 5px",
        "&:last-child": {
          paddingRight: 5,
        },
      },
      stickyHeader: {
        zIndex: 100,
        backgroundColor: fbcolor.primary.light,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "0.75rem",
        fontWeight: "bold",
        marginBottom: 8,
        marginTop: 16,
        display: "block",
      },
    },
    MuiListItemText: {
      dense: {},
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        borderRadius: 4,
      },
    },
  },
  fbcolor,
})
