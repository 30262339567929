import { Avatar, Box, Card, CardContent, Container, Grid, Typography } from "@material-ui/core"
import { decryptionV2 } from "@project/components/cryptoJSFunctions"
import driller from "@project/components/Images/driller.svg"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import { isblank } from "@project/sharedcomponents/utilityFunctions"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { change_company, snackbarstate } from "../../Actions"
import { changeURL } from "../../Lib/commonfunctions"

const SwitchCompany = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    var full = window.location.host
    var parts = full.split(".")
    var subdomain = parts[0]
    var companyname = window.location.pathname.split("/")
    var state = {}

    state.company_url = subdomain
    var company_data = companyname[2]
    // company_data = company_data.replace(/[$]+/g, "/")
    company_data = decryptionV2(company_data)

    if (!isblank(company_data)) {
      state.company_name = company_data.name
      state.financial_year = company_data.year
      state.user_name = company_data.user
      state.token = company_data.token
      state.old = company_data.old
      dispatch(change_company(state))
    } else {
      dispatch(snackbarstate({ open: true, message: ErrorMessages["E00474"], snackbarType: "warning" }))
      if (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "staging") {
        console.log(companyname)
        console.log(subdomain)
        console.log(company_data)
        console.log(state)
      } else {
        setTimeout(() => {
          window.location.href = changeURL(subdomain, "")
        }, 1500)
      }
    }
  }, [])

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="calc(100vh - 100px)">
      <Container maxWidth="sm">
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Avatar alt="driller" src={driller} style={{ height: 108, width: 108 }} />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="h5" gutterBottom>
                  Please wait.
                </Typography>
                <Typography variant="h5" gutterBottom>
                  Switching Company...
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}

export default SwitchCompany
