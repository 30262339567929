import { list_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { LOADING_STATE, OPSTOCK_LOCAL_SEARCH, OPSTOCK_SERVICE_CLEAR, OPSTOCK_SERVICE_FAIL, OPSTOCK_SERVICE_START, OPSTOCK_SERVICE_SUCCESS, SNACKBAR_STATE } from "./types"
import { getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"

export const opstock_data_clear = () => {
  return (dispatch) => {
    dispatch({
      type: OPSTOCK_SERVICE_CLEAR,
    })
  }
}

export const local_search_opstock = ({ search_text }) => {
  return (dispatch) => {
    dispatch({
      type: OPSTOCK_LOCAL_SEARCH,
      search_text: search_text,
    })
  }
}

export const fetch_opstock_data = ({ other_type, search_text, mode, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: OPSTOCK_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("offset", 0)
    data.append("type", "opening_stock")
    data.append("other_type", other_type)
    data.append("limit", "All")
    data.append("sort_order", "asc")
    data.append("mode", mode)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "opening_stock" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          opstockServiceFail({ dispatch, response: response.data.meta.message, other_type, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          opstockServiceSuccess({ dispatch, user: response, other_type, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          opstockServiceFail({ dispatch, response: getCatchErrorMessage(error), other_type, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const opstockServiceSuccess = ({ dispatch, user, other_type, search_text }) => {
  dispatch({
    type: OPSTOCK_SERVICE_SUCCESS,
    payload: user,
    other_type: other_type,
    search_text: search_text,
  })
}

const opstockServiceFail = ({ dispatch, response, other_type, search_text }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })

  dispatch({
    type: OPSTOCK_SERVICE_FAIL,
    payload: response,
    other_type: other_type,
    search_text: search_text,
  })
}
