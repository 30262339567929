import { list_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, isblank, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import { LOADING_STATE, PARTYGROUPPRICE_SERVICE_CLEAR, PARTYGROUPPRICE_SERVICE_FAIL, PARTYGROUPPRICE_SERVICE_START, PARTYGROUPPRICE_SERVICE_SUCCESS, SNACKBAR_STATE } from "./types"

export const partygroupprice_data_clear = () => {
  return (dispatch) => {
    dispatch({
      type: PARTYGROUPPRICE_SERVICE_CLEAR,
    })
  }
}
export const fetch_partygroupprice_data = ({ dispatch, code, productgroupSelectedValue, productSubgroupSelectedValue, productSubgroupLevel2SelectedValue, itemSelectedValue, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: PARTYGROUPPRICE_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", "partygroupprice")
    data.append("code", code)

    if (!isblank(productgroupSelectedValue)) {
      data.append("productgroupSelectedValue", productgroupSelectedValue)
    }
    if (!isblank(productSubgroupSelectedValue)) {
      data.append("productSubgroupSelectedValue", productSubgroupSelectedValue)
    }
    if (!isblank(productSubgroupLevel2SelectedValue)) {
      data.append("productSubgroupLevel2SelectedValue", productSubgroupLevel2SelectedValue)
    }
    if (!isblank(itemSelectedValue)) {
      data.append("itemSelectedValue", itemSelectedValue)
    }

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "partygroupprice" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          partygrouppriceServiceFail({
            dispatch,
            response: response.data.meta.message,
            code,
            productgroupSelectedValue,
            productSubgroupSelectedValue,
            productSubgroupLevel2SelectedValue,
            itemSelectedValue,
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          partygrouppriceServiceSuccess({ dispatch, user: response, code, productgroupSelectedValue, productSubgroupSelectedValue, productSubgroupLevel2SelectedValue, itemSelectedValue })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          partygrouppriceServiceFail({
            dispatch,
            response: getCatchErrorMessage(error),
            code,
            productgroupSelectedValue,
            productSubgroupSelectedValue,
            productSubgroupLevel2SelectedValue,
            itemSelectedValue,
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}
const partygrouppriceServiceSuccess = ({ dispatch, user, code, productgroupSelectedValue, productSubgroupSelectedValue, productSubgroupLevel2SelectedValue, itemSelectedValue }) => {
  dispatch({
    type: PARTYGROUPPRICE_SERVICE_SUCCESS,
    payload: user,
    code: code,
    productgroupSelectedValue,
    productSubgroupSelectedValue,
    productSubgroupLevel2SelectedValue,
    itemSelectedValue,
  })
}
const partygrouppriceServiceFail = ({ dispatch, response, code, productgroupSelectedValue, productSubgroupSelectedValue, productSubgroupLevel2SelectedValue, itemSelectedValue }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })

  dispatch({
    type: PARTYGROUPPRICE_SERVICE_FAIL,
    payload: response,
    code: code,
    productgroupSelectedValue,
    productSubgroupSelectedValue,
    productSubgroupLevel2SelectedValue,
    itemSelectedValue,
  })
}
