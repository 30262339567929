import { Button, Typography, makeStyles } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles((theme) => ({
  hero: {
    display: "block",
    position: "relative",
    width: "400px",
    minWidth: "400px",
    height: "400px",
    borderRadius: "30px",
    overflow: "hidden",
    margin: "0 auto",
    backgroundColor: theme.palette.background.paper,
  },
  heroProfileImg: {
    height: "70%",
    position: "absolute",
    zIndex: 0,
  },
  heroDescriptionBk: {
    backgroundImage: "linear-gradient(0deg, #3f5efb, #fc466b)",
    borderRadius: "30px",
    position: "absolute",
    top: "55%",
    left: "-5px",
    height: "65%",
    width: "108%",
    transform: "skew(19deg, -9deg)",
    "&.second": {
      backgroundImage: "linear-gradient(-20deg, #bb7413, #e7d25c)",
    },
  },
  heroLogo: {
    height: "80px",
    width: "80px",
    borderRadius: "20px",
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    bottom: "30%",
    left: "30px",
    overflow: "hidden",
    boxShadow: "5px 5px 30px rgba(0, 0, 0, 0.7)",
  },
  heroLogoImg: {
    height: "100%",
  },
  heroDescription: {
    position: "absolute",
    fontWeight: 900,
    left: "150px",
    bottom: "26%",
  },
  heroBtn: {
    position: "absolute",
    right: "30px",
    bottom: "10%",
  },
  heroDate: {
    position: "absolute",
    left: "30px",
    bottom: "10%",
    maxWidth: 200,
  },
  heroTitle: {
    padding: 24,
    position: "absolute",
    zIndex: 1,
  },
}))

const Hero = ({ profileUrl, title, description, descriptionBk = false, status, logoUrl, btnText = "Learn More", btnUrl }) => {
  const classes = useStyles()

  // https://miscmedia-9gag-fun.9cache.com/images/thumbnail-facebook/1557291375.3948_Dy2yZu_n.jpg
  // https://i.pinimg.com/originals/9f/b1/ff/9fb1ffb05dd28039d1a06ad8e90cbd75.jpg

  return (
    <div className={`${classes.hero}`} role="article">
      <div className={classes.heroTitle}>{title}</div>
      {profileUrl && <img className={classes.heroProfileImg} src="" alt="Profile" />}
      {descriptionBk && <div aria-hidden="true" className={`${classes.heroDescriptionBk}`}></div>}
      {logoUrl && (
        <div className={classes.heroLogo}>
          <img className={classes.heroLogoImg} src="" alt="Logo" />
        </div>
      )}
      {description && (
        <div className={classes.heroDescription}>
          <Typography>{description}</Typography>
        </div>
      )}
      {status && (
        <div className={classes.heroDate}>
          <Typography>{status}</Typography>
        </div>
      )}
      {btnUrl && (
        <Button variant="contained" color="secondary" target="_blank" rel="noopener noreferrer" href={btnUrl} aria-label={btnText} tabIndex={0} className={classes.heroBtn}>
          {btnText}
        </Button>
      )}
    </div>
  )
}

export default Hero
