import { isblank, replaceBRtoNewLine, replacefunction, setSetting } from "@project/sharedcomponents/utilityFunctions"
import {
  AUTHENTICATE_KEY_GENERATED,
  AUTH_LOADING_STATE,
  AUTH_SNACKBAR_STATE,
  AUTH_USER_SUCCESS,
  COMPANYCODE_CHANGED,
  CUSTOM_PAGE_ITEM_UPDATED,
  EMAIL_CHANGED,
  FILTERED_CHANGED,
  FYYEARS,
  LOGIN_USER,
  LOGIN_USER_FAIL,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_SUCCESS2,
  LOGIN_USER_VALIDATION_FAIL,
  LOGOUT_USER_SUCCESS,
  NEW_SITE_SETTING_UPDATED,
  PASSWORD_CHANGED,
  RELOGIN_USER_SUCCESS,
  REPORT_DATA_UPDATED,
  SET_CHECK_LOADING,
  SITE_SETTING_FIELD_SUCCESS,
  SITE_SETTING_UPDATED,
  UPDATE_CONFIG_SETTING,
  UPDATE_USER_DATA,
  USER_RIGHTS_UPDATED,
} from "../Actions/types"

const INITIAL_STATE = {
  email: "",
  password: "",
  auth_data: "",
  companycode: "",
  config_setting: "",
  userdata: null,
  error: "",
  loading: false,
  Snackbarerror: false,
  Snackbarerrormessage: "",
  checkloginloading: true,
  islogin: false,
  customerList2: [],
  report_data: {},
  customerList3: [],
  customPages: [],
  customPagesSet: false,
  siteSettingFields: [],
  financialyears: [],
  financialyearsSet: false,
  accountSiteSetting: [],
  accountSiteSettingKeyValue: {},
  accountSiteSettingSet: false,
  userRights: {},
  userRightsSet: false,
  accountSessionUserData: "",
  auth_token: "",
  totalcount: 0,
  authenticate_data: null,
  rowsPerPage: 0,
  page: 0,
  order: "asc",
  orderBy: "name",
  search_text: "",
  columnData: [
    { id: "name", numeric: false, visible: true, label: "Name" },
    { id: "mobile", numeric: true, visible: true, label: "Mobile" },
    { id: "city", numeric: true, visible: true, label: "City" },
    { id: "state", numeric: true, visible: true, label: "State" },
    { id: "address", numeric: true, visible: true, label: "Address" },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NEW_SITE_SETTING_UPDATED:
      return { ...state, accountSiteSetting: action.payload, accountSiteSettingKeyValue: setSetting({ settingData: action.payload, rights: state.userRights, from: "reducer" }) }
    case EMAIL_CHANGED:
      return { ...state, email: action.payload, error: "" }
    case PASSWORD_CHANGED:
      return { ...state, password: action.payload, error: "" }
    case COMPANYCODE_CHANGED:
      return { ...state, companycode: action.payload, error: "" }
    case SITE_SETTING_FIELD_SUCCESS:
      return { ...state, siteSettingFields: action.siteSettingFields }
    case LOGIN_USER:
      return { ...state, loading: true, error: "" }

    case AUTH_LOADING_STATE:
      return { ...state, loading: action.state }
    case SET_CHECK_LOADING:
      return { ...state, checkloginloading: action.state }
    case CUSTOM_PAGE_ITEM_UPDATED:
      return { ...state, customPages: action.customPages, customPagesSet: true }
    case SITE_SETTING_UPDATED:
      return {
        ...state,
        accountSiteSettingKeyValue: setSetting({ settingData: action.payload, rights: state.userRights, from: "reducer" }),
        accountSiteSetting: action.payload,
        accountSiteSettingSet: true,
      }
    case USER_RIGHTS_UPDATED:
      return { ...state, userRights: action.payload, userRightsSet: true, accountSiteSettingKeyValue: setSetting({ settingData: state.accountSiteSetting, rights: action.payload, from: "reducer" }) }
    case REPORT_DATA_UPDATED:
      return { ...state, report_data: action.payload }
    case LOGIN_USER_SUCCESS:
      if (!isblank(action.payload.data.company_detail.company_address1)) {
        action.payload.data.company_detail.company_address1 = replacefunction(replaceBRtoNewLine(action.payload.data.company_detail.company_address1))
      }
      if (!isblank(action.payload.data.company_detail.company_street)) {
        action.payload.data.company_detail.company_street = replacefunction(replaceBRtoNewLine(action.payload.data.company_detail.company_street))
      }

      return {
        ...state,
        ...INITIAL_STATE,
        financialyears: action.payload.data.firm_years,
        siteSettingFields: state.siteSettingFields,
        financialyearsSet: true,
        loading: false,
        checkloginloading: false,
        islogin: true,
        report_data: action.payload.data.reports,
        userRightsSet: true,
        userRights: action.payload.data.user_rights,
        accountSiteSettingSet: true,
        customPages: action.payload.data.customPages,
        customPagesSet: true,
        accountSiteSetting: action.payload.data.site_setting[0],
        accountSiteSettingKeyValue: setSetting({ settingData: action.payload.data.site_setting[0], rights: action.payload.data.user_rights, from: "reducer" }),
        accountSessionUserData: action.payload.data.company_detail,
        auth_token: action.payload.data.auth_token,
        config_setting: action.payload.data.config_setting,
      }

    case AUTHENTICATE_KEY_GENERATED:
      return { ...state, authenticate_data: action.payload, loading: false }

    case UPDATE_CONFIG_SETTING:
      return { ...state, config_setting: action.payload }

    case AUTH_USER_SUCCESS:
      return {
        ...state,
        auth_data: action.payload,
      }
    case LOGOUT_USER_SUCCESS:
      return { ...state, ...INITIAL_STATE, checkloginloading: false, islogin: false }
    case UPDATE_USER_DATA:
      return { ...state, accountSessionUserData: action.user_data }
    case RELOGIN_USER_SUCCESS:
      return {
        ...state,
        islogin: true,
        financialyears: action.firm_years,
        financialyearsSet: true,
        accountSiteSettingSet: true,
        accountSiteSettingKeyValue: setSetting({ settingData: action.payload, rights: action.user_rights, from: "reducer" }),
        accountSiteSetting: action.payload,
        report_data: action.report_data,
        userRightsSet: true,
        userRights: action.user_rights,
        accountSessionUserData: action.user_data,
        auth_token: action.auth_token,
        customPages: action.customPages,
        customPagesSet: true,
        config_setting: action.config_setting,
      }
    case LOGIN_USER_SUCCESS2:
      return { ...state, islogin: true }
    case FYYEARS:
      return { ...state, financialyearsSet: true, financialyears: action.payload.years === undefined ? [] : action.payload.years, loading: false }
    case LOGIN_USER_FAIL:
      return { ...state, error: action.payload, password: "", Snackbarerror: true, Snackbarerrormessage: action.payload, loading: false, checkloginloading: false }
    case AUTH_SNACKBAR_STATE:
      return { ...state, Snackbarerror: action.message !== "-401" ? action.payload : false, Snackbarerrormessage: action.message, loading: false, checkloginloading: false }
    case LOGIN_USER_VALIDATION_FAIL:
      return { ...state, error: action.payload.validation_message, loading: false }
    case FILTERED_CHANGED:
      return { ...state, columnData: action.payload }
    default:
      return state
  }
}
