import { convertIntif } from "@project/sharedcomponents/utilityFunctions"
import { PARTYGROUPPRICE_SERVICE_CLEAR, PARTYGROUPPRICE_SERVICE_FAIL, PARTYGROUPPRICE_SERVICE_START, PARTYGROUPPRICE_SERVICE_SUCCESS, PARTYGROUPPRICE_SERVICE_UPDATED } from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,

  data: [],
  totalcount: 0,
  rowsPerPage: 0,
  page: 0,
  order: "desc",
  norecord: false,
  orderBy: "",
  search_text: "",
  columnData: [
    { id: "name", numeric: false, visible: true, label: "Description of Goods" },
    { id: "item_rate", numeric: true, visible: true, label: "Item Rate", width: 200, format: "rate_decimal" },
    { id: "partygroup_rate", numeric: true, visible: true, label: "Account Rate", width: 200, textbox: true, format: "rate_decimal" },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PARTYGROUPPRICE_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case PARTYGROUPPRICE_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case PARTYGROUPPRICE_SERVICE_SUCCESS:
      return { ...state, ...INITIAL_STATE, data: action.payload.data.result, totalcount: action.payload.data.total_count, islogin: true, norecord: action.payload.data.result.length === 0 }
    case PARTYGROUPPRICE_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false, totalcount: 0 }
    case PARTYGROUPPRICE_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.partygroup_rate = action.payload.rate
        return updateditem
      })
      return { ...state, data: updateddata }
    default:
      return state
  }
}
