import React from "react"
import { RenderTextField, renderToggle as RenderToggle, renderRadioGroup as RenderRadioGroup, renderCheckbox as RenderCheckbox } from "@project/components/reduxFormComponents"
import RFAsyncSelect from "@project/components/reduxFormComponents/RFAsyncSelect"
import RFCreatableSelect from "@project/components/reduxFormComponents/RFCreatableSelect"
import RFReactSelect from "@project/components/reduxFormComponents/RFReactSelect"
import RFCreatableInput from "@project/components/reduxFormComponents/RFCreatableInput"
import { isblank } from "@project/sharedcomponents/utilityFunctions"
import { Controller } from "react-hook-form"
import RenderRadioButton from "@project/components/reduxFormComponents/renderRadioButton"

export default ({ control, name, watch, rules, inputType, domref, ...inputProps }) => {
  return (
    <Controller
      control={control}
      name={name}
      key={name}
      rules={rules}
      render={({ fieldState, field }) => {
        let meta = {
          error: fieldState.error,
          touched: control._formState.touchedFields.hasOwnProperty(name) ? control._formState.touchedFields[name] : Boolean(fieldState.error),
        }
        if (watch) {
          field.value = watch(name)
        }
        if (isblank(field.value)) {
          field.value = ""
        }
        if (inputType === "RenderTextField") {
          return <RenderTextField input={field} meta={meta} {...inputProps} />
        } else if (inputType === "RFCreatableSelect") {
          return <RFCreatableSelect input={field} meta={meta} domref={domref} {...inputProps} />
        } else if (inputType === "RFReactSelect") {
          return <RFReactSelect input={field} meta={meta} domref={domref} {...inputProps} />
        } else if (inputType === "RFAsyncSelect") {
          return <RFAsyncSelect input={field} meta={meta} {...inputProps} />
        } else if (inputType === "renderToggle") {
          return <RenderToggle input={field} meta={meta} {...inputProps} />
        } else if (inputType === "RenderRadioButton") {
          return <RenderRadioButton input={field} meta={meta} {...inputProps} />
        } else if (inputType === "renderCheckbox") {
          return <RenderCheckbox input={field} meta={meta} {...inputProps} />
        } else if (inputType === "renderRadioGroup") {
          return <RenderRadioGroup input={field} meta={meta} {...inputProps} />
        } else if (inputType === "RFCreatableInput") {
          return <RFCreatableInput input={field} meta={meta} {...inputProps} />
        }

        return null
      }}
    />
  )
}
