import { AppBar, Box, Button, IconButton, Toolbar, Typography } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"

import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import React, { useEffect, useState, useRef } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { authsnackbarstate, forgotPassword } from "../../Actions"
import Field from "../common/reduxFormComponents"

const ForgotPassword = ({ switchPage, company_url }) => {
  const { lastpagetype, lastpageaction, lastpagetime } = useSelector((state) => state.CommonReducer)
  const [forgotLoading, setForgotLoading] = useState(false)
  const dispatch = useDispatch()
  const prevStatus = useRef({ lastpagetime })
  const defaultValues = {
    username: "",
    fbmoduleType: "accounting",
  }
  const { handleSubmit, control } = useForm({ defaultValues })

  useEffect(() => {
    if (prevStatus.current && lastpagetime !== prevStatus.current.lastpagetime && lastpagetype === "forgot_password" && lastpageaction === "success") {
      setForgotLoading(false)
    }
    if (prevStatus.current && lastpagetime !== prevStatus.current.lastpagetime && lastpagetype === "forgot_password" && lastpageaction === "error") {
      setForgotLoading(false)
    }
  }, [lastpagetime])

  const handlePasswordChange = (e) => {
    e.preventDefault()
    document.getElementById("resetButton").focus()
    setTimeout(() => handleSubmit(onSubmitData, onSubmitError)(), 100)
  }

  const onSubmitData = (values) => {
    const { username, fbmoduleType } = values
    const module_type = fbmoduleType
    setForgotLoading(true)
    dispatch(forgotPassword({ username, module_type, company_url }))
  }

  const onSubmitError = (values) => {
    setForgotLoading(false)
    const err = Object.keys(values)
    dispatch(authsnackbarstate(true, values[err[0]].message, "error"))
    setTimeout(function () {
      document.getElementsByName(err[0])[0].focus()
    }, 100)
  }

  return (
    <Box>
      <AppBar position="static" color="transparent">
        <Toolbar disableGutters>
          <IconButton onClick={() => switchPage("login")} edge="start" aria-label="back">
            <ArrowBack />
          </IconButton>
          <Typography variant="h5" style={{ flexGrow: 1 }}>
            Reset password
          </Typography>
        </Toolbar>
      </AppBar>
      <Typography>We’ll email you instructions to reset your password.</Typography>
      <form onSubmit={handlePasswordChange}>
        <Field
          name="username"
          inputType={"RenderTextField"}
          control={control}
          label="Username"
          autoFocus={true}
          placeholder="Enter username"
          isRequired={true}
          rules={{ required: ErrorMessages.E00541 }}
        />
        <Box mt={2} mb={2}>
          <Button id="resetButton" type="submit" fullWidth disabled={forgotLoading} variant="contained" color="primary">
            Send recovery link
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default ForgotPassword
