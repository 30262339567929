import { Button } from "@material-ui/core"
import CallMadeIcon from "@material-ui/icons/CallMade"
import FBChip from "@project/components/common/ChipComponent"
import React from "react"

function ActionButton({ label, count, ...props }) {
  return (
    <Button style={{ borderRadius: 0 }} fullWidth endIcon={<CallMadeIcon />} {...(count && { startIcon: <FBChip size="small" style={{ fontSize: "inherit" }} label={count}></FBChip> })} {...props}>
      {label}
    </Button>
  )
}

export default ActionButton
