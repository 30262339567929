import { Grid, ListItem, Paper, Typography, makeStyles, useTheme } from "@material-ui/core"
import ReactSelect from "@project/components/formComponents/reactSelectV2"
import { formThemeColors } from "@project/components/reduxFormComponents/reactSelectComponent"
import { commonSiteSetting } from "@project/components/utilityFunctions"
import { list_url } from "@project/sharedcomponents/apiURL"
import { getCatchErrorMessage, getdropdownfieldvalue, getgstbusinesstypelist, isblank } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import React, { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { components } from "react-select"
import AsyncSelect from "react-select/async"
import { VariableSizeList as RWList } from "react-window"
import { gotoReport, ledgerReport, modalInvoiceDetail, modalTransactionDetail, modalpartyDetail, modalproductDetail, post_form_data, snackbarstate } from "../../Actions"
import { getAccountSitesetting, getheaders, postErrorOnException } from "../../Lib/commonfunctions"

const useStyles = makeStyles((theme) => ({
  global_search: {
    padding: "0px 4px",
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    padding: 5,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
}))

const colourStyles = (theme) => {
  return {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    control: (base) => ({
      ...base,
      borderWidth: 0,
      borderRadius: 0,
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "1rem",
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: "1rem",
    }),
    noOptionsMessage: (base) => ({ ...base, ...msgStyles }),
  }
}

export default (props) => {
  const { searchFilters } = props
  const { accountSiteSettingKeyValue } = useSelector((state) => state.AuthReducer)

  var timeout = 0

  var listheight = 300
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()
  const [searchdropdown, setsearchdropdown] = React.useState("")
  const dS = {
    invoice_type_detail: getAccountSitesetting("invoice_type_detail"),
    purchase_type_detail: getAccountSitesetting("purchase_type_detail"),
    inward_type_detail: getAccountSitesetting("inward_type_detail"),
    outward_type_detail: getAccountSitesetting("outward_type_detail"),
    currency: getAccountSitesetting("currency"),
  }

  var height = 40
  if (searchdropdown === "customers" || searchdropdown === "suppliers") {
    height = 75
  } else if (searchdropdown === "ledger") {
    height = 100
  } else if (searchdropdown === "items" || searchdropdown === "reports") {
    height = 60
  } else {
    height = 75
  }

  useEffect(() => {
    if (searchFilters.length > 0) {
      if (isblank(searchdropdown)) {
        setsearchdropdown(searchFilters[0].value)
      }
    }
  }, [searchFilters])

  useEffect(() => {
    if (searchFilters.length > 0) {
      if (isblank(searchdropdown)) {
        setsearchdropdown(searchFilters[0].value)
      }
    }
  }, [])

  const SiteSetting = (key) => {
    return commonSiteSetting({ key, settingData: accountSiteSettingKeyValue })
  }

  const handleChange = (item, selectedItem) => {
    var formtype = ""
    const formaction = "edit"
    var formdata = item.code
    var billno = item.billno
    var otherDetail = {}
    var sales_invoices_types = SiteSetting("invoice_type_detail")
    if (!isblank(sales_invoices_types)) {
      if (sales_invoices_types.filter((e) => e.id === selectedItem).length > 0) {
        formtype = selectedItem
        otherDetail.title = sales_invoices_types.find((item) => item.id === selectedItem).name
      }
    }
    var purchase_invoices_types = SiteSetting("purchase_type_detail")

    if (!isblank(purchase_invoices_types)) {
      if (purchase_invoices_types.filter((e) => e.id === selectedItem).length > 0) {
        formtype = selectedItem
        otherDetail.title = purchase_invoices_types.find((item) => item.id === selectedItem).name
      }
    }
    var inward_invoices_types = SiteSetting("inward_type_detail")
    var outward_invoices_types = SiteSetting("outward_type_detail")

    if (!isblank(inward_invoices_types)) {
      if (inward_invoices_types.filter((e) => e.id === selectedItem).length > 0) {
        formtype = selectedItem
        otherDetail.title = inward_invoices_types.find((item) => item.id === selectedItem).name
      }
    }
    if (!isblank(outward_invoices_types)) {
      if (outward_invoices_types.filter((e) => e.id === selectedItem).length > 0) {
        formtype = selectedItem
        otherDetail.title = outward_invoices_types.find((item) => item.id === selectedItem).name
      }
    }
    if (selectedItem === "creditnote") {
      otherDetail.title = "Credit Note"
      formtype = "salesreturn"
    }
    if (selectedItem === "debitnote") {
      otherDetail.title = "Debit Note"
      formtype = "purchasereturn"
    }
    if (selectedItem === "receipt") {
      otherDetail.title = "Receipt"
      formtype = "receipt"
      billno = item.billno_str
    }
    if (selectedItem === "payment") {
      otherDetail.title = "Payment"
      formtype = "payment"
      billno = item.billno_str
    }
    if (selectedItem === "recordexpense") {
      formtype = "recordexpense"
    }
    if (selectedItem === "journal") {
      otherDetail.title = "Journal Entry"
      formtype = "journal_entry"
      billno = item.billno_str
    }
    if (selectedItem === "items") {
      dispatch(modalproductDetail({ code: item.code, other_type: "profile" }))
      return true
    }
    if (selectedItem === "customers") {
      dispatch(modalpartyDetail({ code: item.code, type: "customer", other_type: "profile" }))
    }
    if (selectedItem === "suppliers") {
      dispatch(modalpartyDetail({ code: item.code, type: "supplier", other_type: "profile" }))
    }
    if (selectedItem === "ledger") {
      dispatch(ledgerReport({ code: item.code }))
    }
    if (selectedItem === "reports") {
      dispatch(gotoReport(item))
    }
    if (
      sales_invoices_types.filter((e) => e.id === selectedItem).length > 0 ||
      purchase_invoices_types.filter((e) => e.id === selectedItem).length > 0 ||
      outward_invoices_types.filter((e) => e.id === selectedItem).length > 0 ||
      inward_invoices_types.filter((e) => e.id === selectedItem).length > 0 ||
      selectedItem === "creditnote" ||
      selectedItem === "debitnote"
    ) {
      otherDetail.type = formtype
      dispatch(
        modalInvoiceDetail({
          code: formdata,
          otherType: formtype,
          title: `Bill No: ${billno}`,
          filenameOld: `${formtype}_${billno}`,
          otherDetail,
          tab_flag: 1,
        })
      )
    } else if (selectedItem === "journal" || selectedItem === "payment" || selectedItem === "receipt") {
      otherDetail.type = formtype
      const tab_flag = selectedItem !== "journal" ? 1 : ""
      dispatch(modalTransactionDetail({ code: formdata, type: formtype, title: `${otherDetail.title}: #${billno}`, filenameOld: `${formtype}_${billno}`, otherDetail: otherDetail, tab_flag }))
    } else if (selectedItem !== "customers" && selectedItem !== "suppliers" && selectedItem !== "ledger" && selectedItem !== "reports") {
      dispatch(post_form_data({ dispatch, formtype, formaction, formdata }))
    }
  }

  const promiseOptions = (search_text) =>
    new Promise((resolve) => {
      if (isblank(search_text)) {
        resolve([])
      } else {
        if (timeout) {
          clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
          let data = new FormData()
          data.append("search_text", search_text)
          data.append("search_field", searchdropdown)
          axios.defaults.headers.common = getheaders()
          axios({
            method: "post",
            url: list_url({ type: "global_search" }),
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: data,
          })
            .then((response) => {
              if (response.data.meta.code === 401) {
                resolve([])
              } else {
                var returnValue = response.data.result.map((e) => {
                  const updated = {}
                  updated.value = e.code
                  updated.label = e.name
                  updated.row = e
                  return updated
                })
                resolve(returnValue)
              }
            })
            .catch((error) => {
              postErrorOnException(error)
              dispatch(snackbarstate({ open: true, message: getCatchErrorMessage(error) }))
              resolve([])
            })
        }, 1000)
      }
    })

  const onSearchTypeChange = (val) => {
    setsearchdropdown(val.value)
  }

  const Option = ({ children, ...props }) => {
    if (searchdropdown === "customers" || searchdropdown === "suppliers") {
      return (
        <components.Option {...props}>
          <Typography color="primary" variant="subtitle2" noWrap>
            {props.data.label}
          </Typography>
          <Grid container justifyContent="space-between">
            {props.data.row.gst_treatment > 0 && (
              <Grid item md={8}>
                <Typography variant="caption" noWrap>
                  {getdropdownfieldvalue({ dropDownData: getgstbusinesstypelist(), field: "id", value: props.data.row.gst_treatment, displayvalue: "name" })}
                </Typography>
              </Grid>
            )}
            <Grid item md={4}>
              <Typography variant="caption" noWrap>
                {props.data.row.city}
              </Typography>
            </Grid>
            <Grid item md={8}>
              <Typography variant="caption" noWrap>
                {props.data.row.email}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="caption" noWrap>
                {props.data.row.mobile}
              </Typography>
            </Grid>
          </Grid>
        </components.Option>
      )
    } else if (searchdropdown === "items") {
      return (
        <components.Option {...props}>
          <Typography variant="subtitle2" noWrap color="primary">
            {props.data.label}
          </Typography>
          <Grid container justifyContent="space-between">
            <Grid item md={8}>
              <Typography variant="caption" noWrap>
                {props.data.row.itemgroup}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="caption" noWrap>
                {props.data.row.unit}
              </Typography>
            </Grid>
          </Grid>
        </components.Option>
      )
    } else if (
      dS.invoice_type_detail.filter((e) => e.id === searchdropdown).length > 0 ||
      searchdropdown === "creditnote" ||
      searchdropdown === "debitnote" ||
      dS.purchase_type_detail.filter((e) => e.id === searchdropdown).length > 0
    ) {
      return (
        <components.Option {...props}>
          <Typography variant="subtitle2" noWrap>
            {props.data.label}
          </Typography>
          <React.Fragment>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption">Doc No: {props.data.row.billno}</Typography>
                <Typography variant="caption">{props.data.row.city}</Typography>
              </div>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", textAlign: "right" }}>
                <Typography variant="caption">
                  ({dS.currency}){props.data.row.netamt}
                </Typography>
                <Typography variant="caption">{props.data.row.b_date}</Typography>
              </div>
            </div>
          </React.Fragment>
        </components.Option>
      )
    } else if (dS.inward_type_detail.filter((e) => e.id === searchdropdown).length > 0 || dS.outward_type_detail.filter((e) => e.id === searchdropdown).length > 0) {
      return (
        <components.Option {...props}>
          <Typography variant="subtitle2" noWrap>
            {props.data.label}
          </Typography>
          <React.Fragment>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption">Doc No: {props.data.row.billno}</Typography>
              </div>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", textAlign: "right" }}>
                <Typography variant="caption">{props.data.row.b_date}</Typography>
              </div>
            </div>
          </React.Fragment>
        </components.Option>
      )
    } else if (searchdropdown === "ledger") {
      return (
        <components.Option {...props}>
          <Typography color="primary" variant="subtitle2" noWrap>
            {props.data.label}
          </Typography>
          <Typography variant="caption" noWrap>
            {props.data.row.groupname}
          </Typography>
          {props.data.row.gst_treatment > 0 && (
            <Grid container justifyContent="space-between">
              <Grid item md={8}>
                <Typography variant="caption" noWrap>
                  {getdropdownfieldvalue({ dropDownData: getgstbusinesstypelist(), field: "id", value: props.data.row.gst_treatment, displayvalue: "name" })}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography variant="caption" noWrap>
                  {props.data.row.city}
                </Typography>
              </Grid>
              {!isblank(props.data.row.email) && (
                <Grid item md={8}>
                  <Typography variant="caption" noWrap>
                    {props.data.row.email}
                  </Typography>
                </Grid>
              )}
              <Grid item md={4}>
                <Typography variant="caption" noWrap>
                  {props.data.row.mobile}
                </Typography>
              </Grid>
            </Grid>
          )}
        </components.Option>
      )
    } else if (searchdropdown === "reports") {
      return (
        <components.Option {...props}>
          <Typography variant="subtitle2" noWrap color="primary">
            {props.data.label}
          </Typography>
          <React.Fragment>
            <Typography variant="caption">{props.data.row.category_name}</Typography>
          </React.Fragment>
        </components.Option>
      )
    } else if (searchdropdown === "receipt" || searchdropdown === "payment" || searchdropdown === "journal" || searchdropdown === "recordexpense") {
      return (
        <components.Option {...props}>
          <Typography variant="subtitle2" noWrap>
            Doc No: {props.data.row.billno_str}
          </Typography>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="caption">{props.data.row.name}</Typography>
              <Typography variant="caption">{props.data.row.name1}</Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", textAlign: "right" }}>
              <Typography variant="caption">
                ({dS.currency}){props.data.row.netamt}
              </Typography>
              <Typography variant="caption">{props.data.row.b_date}</Typography>
            </div>
          </div>
        </components.Option>
      )
    }
    return null
  }

  const MenuList = (props) => {
    const { options, children, getValue } = props
    const [value] = getValue()
    const { mode } = props.selectProps
    // height = 37 + 19 + 19

    const getItemSize = (index) => height
    const initialOffset = options.indexOf(value) * height

    // Scroll to item API of `react-window`
    // See https://react-window.now.sh/#/api/FixedSizeList
    const scrollToIndex = children.length ? children.findIndex((child) => child.props.isFocused) : 0

    // Use `useRef` hook to maintain the access to the menu list
    const listRef = useRef(null)

    // effect that happens after rendering to properly adjust the selection
    useEffect(() => {
      if (listRef.current) {
        if (options.length > 0) {
          listRef.current.scrollToItem(scrollToIndex)
        }
      }
      // react-hooks/exhaustive-deps
      // eslint-disable-next-line
    }, [scrollToIndex])

    if (children.length > 0) {
      return (
        <RWList
          ref={listRef}
          height={children.length * height < listheight ? children.length * height : listheight}
          itemCount={children.length}
          itemSize={getItemSize}
          initialScrollOffset={initialOffset}
        >
          {({ index, style }) => {
            return (
              <div
                style={{
                  ...style,
                  ...{
                    backgroundColor: theme.palette.background.paper,
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                    borderRadius: 0,
                  },
                }}
              >
                {children[index]}
              </div>
            )
          }}
        </RWList>
      )
    } else {
      return (
        <ListItem component="div" dense style={{ height: 36 }}>
          <Typography component="div">
            <components.NoOptionsMessage {...props} />
          </Typography>
        </ListItem>
      )
    }
  }

  const onSearchClick = (val) => {
    handleChange(val.row, searchdropdown)
  }

  const DropdownIndicator = (props) => {
    return (
      <div style={{ width: 150 }}>
        <ReactSelect
          name="searchdropdown"
          placeholder="Select type"
          onChange={onSearchTypeChange}
          value={searchdropdown}
          isSearchable={false}
          isClearable={false}
          margin="none"
          setUnderLine={false}
          options={searchFilters.map(function (value) {
            return { value: value.value, label: value.label, row: value }
          })}
        />
      </div>
    )
  }

  return (
    <div style={{ position: "relative", marginLeft: 15 }}>
      <Paper elevation={0} style={{ width: 350 }}>
        <AsyncSelect
          defaultOptions
          loadOptions={promiseOptions}
          styles={colourStyles}
          value=""
          onChange={onSearchClick}
          placeholder="Search"
          theme={(theme1) => ({
            ...theme1,
            borderRadius: 0,
            colors: {
              ...formThemeColors(theme, false, false),
            },
          })}
          components={{
            Option,
            MenuList,
            DropdownIndicator,
            IndicatorSeparator: () => null,
          }}
        />
      </Paper>
    </div>
  )
}
