import re from "@project/sharedcomponents/constantData/reportsEnum"
import { getReportColumnDataById } from "@project/sharedcomponents/reportFunction"
import { convertIntif, isblank, } from "@project/sharedcomponents/utilityFunctions"
import { setColumnDataFilterWise, todaydate } from "@project/components/utilityFunctions"
import {
  GSTIN_SERVICE_UPDATED,
  GSTR2_SERVICE_UPDATED,
  GST_SERVICE_FILLED,
  GST_SERVICE_RESET,
  GST_SET_DETAIL,
  REPORT_GENERATE_COLUMNS,
  REPORT_SEARCH_TEXT_UPDATE,
  REPORT_SERVICE_CLEAR,
  REPORT_SERVICE_FAIL,
  REPORT_SERVICE_START,
  REPORT_SERVICE_SUCCESS,
  UPDATE_REPORT_DATA,
} from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  originalData: [],
  report_success: false,
  search_text: "",
  setconfig: {},
  columnData: [],
  subcolumnData: [],
  subsubcolumnData: [],
  finalcolumnData: [],
  custom_fields_data: [],
  page: 0,
  totalcount: 0,
  rowsPerPage: 0,
  norecord: false,
  meta: "",
  filled_status: "",
  firstinit: false,
  uniqueGSTIN: [],
  rawData: [],
  reset_btn_flag: 0,
  nextFileOn: "",
  share_detail: "",
  filterName: "",
  filterMasterName: "",
  filterDetailDetailName: "",
}

export default (state = INITIAL_STATE, action) => {
  var filled_status = ""
  var reset_btn_flag = ""
  switch (action.type) {
    case REPORT_GENERATE_COLUMNS:
      var columnData = []
      var reportid = action.hasOwnProperty("reportdata") ? action.reportdata.reportid : 0
      var formValues = action.hasOwnProperty("formValues") ? action.reportdata.formValues : {}

      columnData = getReportColumnDataById({ reportid, functions: { account_type: action.account_type, formValues: formValues } })
      return { ...state, columnData: columnData, data: [], page: 0, totalcount: 0, rowsPerPage: 0, norecord: false }

    case REPORT_SERVICE_START:
      return { ...state, loading: true, error: "", norecord: false, data: state.data, firstinit: true, report_success: false }
    case REPORT_SEARCH_TEXT_UPDATE:
      return { ...state, search_text: action.search_text, loading: true, norecord: false }
    case GST_SET_DETAIL:
      return { ...state, rawData: action.rawData }
    case REPORT_SERVICE_SUCCESS:
      // var updatedcoloumn = state.columnData
      var updatedcoloumn = []
      var uniqueGSTIN = []

      var subupdatedcoloumn = []
      var subsubupdatedcoloumn = []
      if (action.payload.data.hasOwnProperty("filter_master") && !isblank(action.payload.data.filter_master)) {
        updatedcoloumn = setColumnDataFilterWise({ data: action.payload.data, columnData: [], filterType: "filter_master" })
        subupdatedcoloumn = setColumnDataFilterWise({ data: action.payload.data, columnData: [] })
        if (action.payload.data.hasOwnProperty("filter_detail_detail")) {
          subsubupdatedcoloumn = setColumnDataFilterWise({ data: action.payload.data, columnData: [], filterType: "filter_detail_detail" })
        }
      } else {
        updatedcoloumn = setColumnDataFilterWise({ data: action.payload.data, columnData: [] })
      }

      if (parseInt(action.setconfig.report_id) === re.GSTR_1Summary || (parseInt(action.setconfig.report_id) === re.RawGST && action.setconfig.type === "gstr1")) {
        filled_status = {}
        filled_status.status = action.payload.data.result.file_type
        filled_status.date = action.payload.data.result.filed_date
        filled_status.gstr1_version = action.payload.data.result.gstr1_version

        reset_btn_flag = action.payload.data.result.reset_btn_flag ? action.payload.data.result.reset_btn_flag : ""

        uniqueGSTIN = !isblank(action.payload.data.result.uniqueGSTIN) ? action.payload.data.result.uniqueGSTIN : []
      } else if (parseInt(action.setconfig.report_id) === re.GSTR_2Summary || (parseInt(action.setconfig.report_id) === re.RawGST && action.setconfig.type === "gstr2")) {
        filled_status = {}
        filled_status.status = action.payload.data.result.records.file_type
        filled_status.date = action.payload.data.result.records.filed_date

        reset_btn_flag = action.payload.data.result.records.reset_btn_flag ? action.payload.data.result.records.reset_btn_flag : ""
      } else {
        filled_status = action.payload.data.result.file_type
        reset_btn_flag = action.payload.data.result.reset_btn_flag ? action.payload.data.result.reset_btn_flag : ""
      }

      /*  if (parseInt(action.setconfig.report_id) === re.GSTR_2Summary || (parseInt(action.setconfig.report_id) === re.RawGST && action.setconfig.type === "gstr2")) {
          filled_status = {}
          filled_status.status = action.payload.data.result.records.file_type
          filled_status.date = action.payload.data.result.records.filed_date
        } else {
          filled_status = action.payload.data.result.records.file_type
        }
  */

      let meta = action.payload.data.result.types
      if (parseInt(action.setconfig.report_id) === re.MissingTDSTCSNumber) {
        meta = action.payload.data.result.party
      }

      return {
        ...state,
        meta: meta,
        share_detail: action.payload.data.result.share_detail,
        uniqueGSTIN: uniqueGSTIN,
        report_success: true,
        columnData: updatedcoloumn,
        subcolumnData: subupdatedcoloumn,
        subsubcolumnData: subsubupdatedcoloumn,
        filled_status: filled_status,
        filterName: action.payload.data.filter_name,
        filterMasterName: action.payload.data.filter_master_name,
        filterDetailDetailName: action.payload.data.filter_detail_detail_name,
        custom_fields_data: action.payload.data.result.custom_fields_data ? action.payload.data.result.custom_fields_data : [],
        originalData: action.payload.data.result.records,
        data: action.payload.data.result.records,
        totalcount: action.payload.data.result.records.length === 0 ? 0 : action.payload.data.result.total_count,
        rowsPerPage: action.setconfig.rowsPerPage,
        page: action.setconfig.page,
        setconfig: action.setconfig,
        norecord: action.payload.data.result.records.length === 0 && state.search_text === "",
        reset_btn_flag: reset_btn_flag,
        nextFileOn: action.payload.data.result.nextFileOn ? action.payload.data.result.nextFileOn : "",
      }
    case REPORT_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        data: [],
        custom_fields_data: [],
        report_success: false,
        loading: false,
        totalcount: 0,
        rowsPerPage: action.setconfig.rowsPerPage,
        page: action.setconfig.page,
        search_text: action.setconfig.search_text,
        setconfig: action.setconfig,
        norecord: false,
      }
    case REPORT_SERVICE_CLEAR:
      return { ...state, error: action.payload, data: [], share_detail: "", custom_fields_data: [], report_success: false, loading: false, totalcount: 0, search_text: "", norecord: false }
    case GST_SERVICE_FILLED:
      filled_status = {}
      filled_status.status = "filed"
      filled_status.date = todaydate("DMY")
      return { ...state, filled_status: filled_status }
    case GST_SERVICE_RESET:
      filled_status = {}
      filled_status.status = "not file"
      return { ...state, filled_status: filled_status }
    case UPDATE_REPORT_DATA:
      return { ...state, data: action.payload }
    case GSTR2_SERVICE_UPDATED:
      //var finalData = state.data
      //let updateddata = finalData.partially_invoice.filter((item) => convertIntif(item.code) !== convertIntif(action.payload))
      //finalData.partially_invoice = updateddata
      return { ...state, loading: false }
    case GSTIN_SERVICE_UPDATED:
      if (action.other_type === "59") {
        let updateddata = state.data.map((item, index) => {
          if (convertIntif(item.partycode) !== convertIntif(action.payload.partycode)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.gstin = action.payload.gstin
          return updateditem
        })

        return { ...state, loading: false, data: updateddata }
      } else {
        let updateddata = state.data.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.payload.accountcode)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.gstin = action.payload.gstin
          return updateditem
        })

        return { ...state, data: updateddata }
      }

    default:
      return state
  }
}
