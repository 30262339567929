import React from "react"
import { Avatar, Box, Button, Card, CardContent, Container, Grid, Typography } from "@material-ui/core"
import warning from "../Images/warning.svg"
const SubscriptionExpired = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="calc(100vh - 100px)">
      <Container maxWidth="sm">
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Avatar alt="warning" src={warning} style={{ height: 108, width: 108 }} />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="h5" gutterBottom>
                  Your Subscription has expired.
                </Typography>
                <Typography variant="h4" gutterBottom>
                  Renew Your Subscription
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Contact administrator for more details.
                </Typography>
                <Button variant="contained" color="primary" component="a" href="/">
                  GO BACK TO DASHBOARD
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}

export default SubscriptionExpired
