import { post_form_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getAccountSitesetting, getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import {
  LOADING_STATE,
  SNACKBAR_STATE,
  TDS_CATEGORY_MASTER_LOCAL_SEARCH,
  TDS_CATEGORY_MASTER_SERVICE_CLEAR,
  TDS_CATEGORY_MASTER_SERVICE_FAIL,
  TDS_CATEGORY_MASTER_SERVICE_START,
  TDS_CATEGORY_MASTER_SERVICE_SUCCESS,
} from "./types"

export const TdsCategoryMaster_data_clear = () => {
  return (dispatch) => {
    dispatch({
      type: TDS_CATEGORY_MASTER_SERVICE_CLEAR,
    })
  }
}

export const local_search_tds_category_master = ({ search_text }) => {
  return (dispatch) => {
    dispatch({
      type: TDS_CATEGORY_MASTER_LOCAL_SEARCH,
      search_text: search_text,
    })
  }
}

export const fetch_TdsCategoryMaster_data = ({ form_data, search_text, dd_tdspayee, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: TDS_CATEGORY_MASTER_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("form_data", form_data)
    data.append("type", "tdspayee")
    data.append("action", "edit")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: post_form_url({ formtype: "tdspayee", props: { getAccountSitesetting } }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          TdsCategoryMasterServiceFail({ dispatch, response: response.data.meta.message, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          TdsCategoryMasterServiceSuccess({ dispatch, user: response, search_text, dd_tdspayee })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          TdsCategoryMasterServiceFail({ dispatch, response: getCatchErrorMessage(error), search_text })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const TdsCategoryMasterServiceSuccess = ({ dispatch, user, search_text, dd_tdspayee }) => {
  dispatch({
    type: TDS_CATEGORY_MASTER_SERVICE_SUCCESS,
    payload: user.data.form_data.tdsDetail,
    search_text: search_text,
    dd_tdspayee: dd_tdspayee,
  })
}

const TdsCategoryMasterServiceFail = ({ dispatch, response, search_text }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })
  dispatch({
    type: TDS_CATEGORY_MASTER_SERVICE_FAIL,
    payload: response,
    search_text: search_text,
  })
}
