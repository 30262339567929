import { QUICK_LINK_SERVICE_CLEAR, QUICK_LINK_SERVICE_FAIL, QUICK_LINK_SERVICE_START, QUICK_LINK_SERVICE_SUCCESS } from "../Actions/types"

const INITIAL_STATE = {
  error: "",
  data: [],
  report_data: [],
  totalcount: 0,
  rowsPerPage: 0,
  page: 0,
  order: "asc",
  orderBy: "",
  search_text: "",
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QUICK_LINK_SERVICE_START:
      return { ...state, error: "" }
    case QUICK_LINK_SERVICE_SUCCESS:
      return { ...state, ...INITIAL_STATE, data: action.payload.data.result.quick_links, report_data: action.payload.data.result.report_quick_links, islogin: true }
    case QUICK_LINK_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [] }
    case QUICK_LINK_SERVICE_CLEAR:
      return { ...state, data: [] }
    default:
      return state
  }
}
