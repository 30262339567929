import { getdatatype, getdatatype_all } from "@project/components/fbMenuRightFn"
import { list_url } from "@project/sharedcomponents/apiURL"
import cF from "@project/sharedcomponents/constantData/customFieldsEnum"
import {
  DatetoDMY,
  cloneObj,
  getyesno as common_getyesno,
  convertIntif,
  getCatchErrorMessage,
  getMultiDropDownPostValue,
  getNumberonly,
  getNumberonlyDecimal,
  getdropdownfieldvalue,
  getgstbusinesstypelist,
  isblank,
} from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import export_currency_data from "./export_currency_data"

export const getallattribute = () => {
  return [
    {
      id: 1,
      name: "Size",
    },
    {
      id: 2,
      name: "Flavours",
    },
    {
      id: 3,
      name: "Color",
    },
  ]
}

export const getdateformat = () => {
  return [
    {
      id: "0",
      name: "dd-mm-yyyy",
    },
    {
      id: "1",
      name: "mm-dd-yyyy",
    },
  ]
}

export const cartesianProduct = (arr) => {
  return arr.reduce(
    function (a, b) {
      return a
        .map(function (x) {
          return b.map(function (y) {
            return x.concat(y)
          })
        })
        .reduce(function (a, b) {
          return a.concat(b)
        }, [])
    },
    [[]]
  )
}
export const taxReason = () => {
  return [
    {
      id: "1",
      name: "Nil Rated",
      description: "Supplies that have declared rate of 0% GST",
    },
    {
      id: "2",
      name: "Exempt",
      description: "Supplies taxable but no GST and ITC cannot be claimed",
    },
    {
      id: "3",
      name: "Non-GST",
      description: "Supplies do not come under GST",
    },
    {
      id: "4",
      name: "Zero Rated",
      description: "Overseas supplies, Supply to SEZ or SEZ Developers",
    },
  ]
}
export const getaction = () => {
  return [
    {
      id: "INSERTED",
      name: "INSERTED",
    },
    {
      id: "UPDATED",
      name: "UPDATED",
    },
    {
      id: "DELETED",
      name: "DELETED",
    },
  ]
}

export const downloadJSONFile = async (jsonData, fileName) => {
  const json = JSON.stringify(jsonData)
  const blob = new Blob([json], { type: "application/json" })
  const href = await URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = href
  link.download = fileName + ".json"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const jsonToURI = (json) => {
  return encodeURIComponent(JSON.stringify(json))
}
export const uriToJSON = (urijson) => {
  return JSON.parse(decodeURIComponent(urijson))
}
export const scrollToTop = (scrollTo) => {
  setTimeout(() => {
    if (scrollTo) {
      if (!isblank(document.getElementById) && !isblank(document.getElementById(scrollTo))) {
        document.getElementById(scrollTo).scrollIntoView({ block: "start", behaviour: "smooth" })
      }
    } else {
      window.scroll(0, 0)
    }
  }, 0)
}
export const getexporttype = () => {
  return [
    {
      id: "1", //null
      name: "Deemed",
    },
    {
      id: "2", //disabled
      name: "Export under bond/LUT",
    },
    {
      id: "3",
      name: "Export with IGST",
    },
    {
      id: "4", //null
      name: "SEZ with IGST payment",
    },
    {
      id: "5", //disabled
      name: "SEZ without IGST payment",
    },
  ]
}

export const gettransportmode = () => {
  return [
    {
      id: "1",
      name: "Road",
    },
    {
      id: "2",
      name: "Rail",
    },
    {
      id: "3",
      name: "Air",
    },
    {
      id: "4",
      name: "Ship",
    },
  ]
}
export const isDateFormat = (str) => {
  if (isblank(str)) {
    return false
  }
  if (str.toString().indexOf(",") > -1) {
    return false
  }
  return true
}

export const YMDStrtoDate = (dateString) => {
  if (isblank(dateString)) {
    return ""
  }
  const dateParts = dateString.split("-")
  const dateObject = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2])
  return dateObject
}

export const createDropDown = (dropDownItem, value, prop) => {
  if (!isblank(value)) {
    if (value.hasOwnProperty(prop)) {
      return dropDownItem.find((item) => convertIntif(item[prop]) === convertIntif(value[prop]))
    } else {
      return dropDownItem.find((item) => convertIntif(item[prop]) === convertIntif(value))
    }
  } else {
    return ""
  }
}
export const createDropDownObj = (value, field) => {
  if (!isblank(value)) {
    var obj = {}

    if (value.hasOwnProperty(field)) {
      obj[field] = value[field]
    } else {
      obj[field] = value
    }
    obj["name"] = ""
    return obj
  } else {
    return ""
  }
}
export const todaydate = (format) => {
  var currentDate = new Date()
  var twoDigitMonth = currentDate.getMonth() + 1 >= 10 ? currentDate.getMonth() + 1 : "0" + (currentDate.getMonth() + 1)
  var twoDigitDate = currentDate.getDate() >= 10 ? currentDate.getDate() : "0" + currentDate.getDate()

  if (format === "YMD") {
    return currentDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate
  } else if (format === "DMY") {
    return twoDigitDate + "-" + twoDigitMonth + "-" + currentDate.getFullYear()
  }
}
// Evaluation of equation by using BOAD MASS method
function evaluate({ indexStart, indexEnd, operators, operands }) {
  // Result variables
  var result = 0
  var index = 0
  var finalResult = 0
  var indexNext = 0
  var indexNextOperator = 0
  var indexNextOperand = 0
  var indexFirstOperand = 0
  // First evaluating MULTIPLICATION and DIVISION
  for (index = indexStart; index < indexEnd; index++) {
    indexFirstOperand = index
    indexNextOperand = index + 1
    indexNextOperator = index

    if (operators[indexNextOperator] === null) {
      for (indexNext = indexNextOperator + 1; indexNext < operators.length; indexNext++) {
        if (operators[indexNext] != null) {
          indexNextOperator = indexNext
          indexFirstOperand = indexNext
          indexNextOperand = indexNext + 1
          index = indexNext - 1
          break
        }
      }
    }

    if (operands[indexNextOperand] === null) {
      for (indexNext = indexNextOperand + 1; indexNext < operands.length; indexNext++) {
        if (operands[indexNext] != null) {
          indexNextOperand = indexNext
          index = indexNext - 1
          break
        }
      }
    }

    // MULTIPLICATION
    if (operators[indexNextOperator] === "*") {
      result = operands[indexFirstOperand] * operands[indexNextOperand]

      operands[indexNextOperand] = result
      operands[indexFirstOperand] = null
      operators[indexNextOperator] = null
      finalResult = result
      result = 0
    }
    // DIVISION
    else if (operators[indexNextOperator] === "/") {
      result = operands[indexFirstOperand] / operands[indexNextOperand]

      operands[indexNextOperand] = result
      operands[indexFirstOperand] = null
      operators[indexNextOperator] = null
      finalResult = result
      result = 0
    }
  }

  // Next evaluating ADDITION and SUBSTRACTION
  for (index = indexStart; index < indexEnd; index++) {
    indexFirstOperand = index
    indexNextOperand = index + 1
    indexNextOperator = index

    if (operators[indexNextOperator] === null) {
      for (indexNext = indexNextOperator + 1; indexNext < operators.length; indexNext++) {
        if (operators[indexNext] != null) {
          indexNextOperator = indexNext
          indexFirstOperand = indexNext
          indexNextOperand = indexNext + 1
          index = indexNext - 1
          break
        }
      }
    }

    if (operands[indexNextOperand] === null) {
      for (indexNext = indexNextOperand + 1; indexNext < operands.length; indexNext++) {
        if (operands[indexNext] != null) {
          indexNextOperand = indexNext
          index = indexNext - 1
          break
        }
      }
    }

    // ADDITION
    if (operators[indexNextOperator] === "+") {
      result = operands[indexFirstOperand] + operands[indexNextOperand]

      operands[indexNextOperand] = result
      operands[indexFirstOperand] = null
      operators[indexNextOperator] = null
      finalResult = result
      result = 0
    }
    // SUBSTRACTION
    else if (operators[indexNextOperator] === "-") {
      result = operands[indexFirstOperand] - operands[indexNextOperand]

      operands[indexNextOperand] = result
      operands[indexFirstOperand] = null
      operators[indexNextOperator] = null
      finalResult = result
      result = 0
    }
  }

  // Returning FINAL RESULT
  return finalResult
}
export const startEvaluation = (equation) => {
  // var equation = prompt("Enter the equation")
  // Creating empty arrays for storing
  // brackets, operators, operands
  var brackets = []
  var index = 0
  var operatorIndex = 0
  var bracketIndex = 0
  var indexOperand = 0
  var operators = []
  var operands = []
  var operandString = ""
  var operandsTemp = []

  for (index = 0, operatorIndex = 0, bracketIndex = 0; index < equation.length; index++) {
    // Checking for operators
    if (equation.charAt(index) === "+" || equation.charAt(index) === "-" || equation.charAt(index) === "*" || equation.charAt(index) === "/") {
      // Below condition is to deal with (a+b)+(c+d) this part of equation
      if (equation.charAt(index - 1) === ")" && equation.charAt(index + 1) === "(") {
        operators[operatorIndex++] = "*"
        brackets[bracketIndex++] = null
        brackets[bracketIndex++] = null
        operators[operatorIndex++] = equation.charAt(index)
        operandString += " 1 "
      }
      // Fetching operators and storing into operators[] array
      else {
        operators[operatorIndex++] = equation.charAt(index)
        brackets[bracketIndex++] = null

        operandString += " "
      }
    }
    // Fetching brackets and storing into brackets[] array
    else if (equation.charAt(index) === "(" || equation.charAt(index) === ")") {
      brackets[bracketIndex++] = equation.charAt(index)
      operators[operatorIndex++] = null
    }
    // Fetching operand digits and storing into operandString
    else {
      operandString += equation.charAt(index)
    }
  }

  // Temp array of operands for further processing
  // before storing operands into operands[] array
  operandsTemp = operandString.split(" ")

  // Storing operands into operands[] array
  for (index = 0, indexOperand = 0; index < operators.length; index++) {
    if (brackets[index] === null) {
      operands[index] = Number(operandsTemp[indexOperand++])
    } else {
      operands[index] = null
    }
  }
  operands[operands.length] = Number(operandsTemp[indexOperand])

  // Result variables
  var finalResult = 0

  // Checking if brackets are present in the equation or not
  if (brackets.length > 0) {
    for (index = 0; index < brackets.length; index++) {
      // Checking for occurance of closing bracket
      // and then searching for its corresponding
      // opening bracket by reverse traversing
      // and then evaluating that particular bracket equation
      // PROCESSES NESTED BRACKETS AS WELL
      if (brackets[index] === ")") {
        var indexBracketEnd = index
        for (var indexBracket = indexBracketEnd; indexBracket >= 0; indexBracket--) {
          if (brackets[indexBracket] === "(") {
            var indexBracketStart = indexBracket
            evaluate({ indexStart: indexBracketStart, indexEnd: indexBracketEnd, operators, operands })
            brackets[indexBracketStart] = null
            brackets[indexBracketEnd] = null
            break
          }
        }
      }
    }
  }
  // This will evaluate remaining equation (without brackets)
  // after processing brackets (if available)
  finalResult = evaluate({ indexStart: 0, indexEnd: operators.length, operators, operands })

  return finalResult
}
export const getyesno = () => {
  return common_getyesno()
}
export const getAllFBModules = () => {
  return [
    {
      id: "accounting",
      name: "Accounting",
    },
    {
      id: "payroll",
      name: "Payroll",
    },
  ]
}

// export const getAllFBModules = () => {
//   return [
//     {
//       id: "accounting",
//       name: "Accounting",
//       desc: "Customized Online Accounting & Inventory Management",
//     },
//     {
//       id: "analytics",
//       name: "Business Analytics",
//       desc: "Advance Business Intelligence",
//     },
//     {
//       id: "portfolio",
//       name: "Portfolio Management",
//       desc: "Multi-asset Portfolio Management",
//     },
//     {
//       id: "bot",
//       name: "WhatsApp Chat Bot",
//       desc: "WhatsApp Business API Integration",
//     },
//     {
//       id: "payroll",
//       name: "Payroll Management",
//       desc: "Employee Payroll Management",
//     },
//   ]
// }

export const getdropdownvalue = ({ formValues, field, prop }) => {
  var selectedvalue = ""
  if (formValues !== null && formValues !== undefined && formValues !== "") {
    if (formValues.hasOwnProperty(field)) {
      if (formValues[field] === null || formValues[field] === undefined || formValues[field] === "") {
        selectedvalue = ""
      } else {
        if (formValues[field].hasOwnProperty(prop)) {
          selectedvalue = formValues[field][prop]
        } else {
          selectedvalue = ""
        }
      }
    }
  }
  return selectedvalue
}
export const getFirstCharOfString = (string) => {
  if (isblank(string)) {
    return ""
  }
  var matches = string.match(/\b(\w)/g)
  var finalString = []
  if (!isblank(matches)) {
    finalString[0] = matches[0]
    finalString[1] = matches[1]
  }
  return finalString.join("")
}

export const ArrayShuffle = (array) => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }
  return array
}
export const getMultiSelectEditValue = (value) => {
  var returnValue = []
  if (isblank(value)) {
    return ""
  }
  if (typeof value === "object" && value.length > 0) {
    returnValue = [...value]
  } else {
    var string = value.split(", ")
    if (string.length > 0) {
      returnValue = [...string]
    }
  }
  return returnValue
}
export const getMultiSelectPostValue = (value) => {
  if (isblank(value)) {
    return ""
  }
  if (typeof value === "object" && value.length > 0) {
    var returnValue = []
    value.forEach((item) => {
      if (typeof item === "object") {
        if (!isblank(item.value)) {
          returnValue.push(item.value)
        }
      } else {
        if (!isblank(item)) {
          returnValue.push(item)
        }
      }
    })
    if (returnValue.length > 0) {
      var returnString = ""
      returnValue.forEach((it, index) => {
        if (index === 0) {
          returnString = it
        } else {
          returnString = returnString + ", " + it
        }
      })
      return returnString
    }
  }
  return value
}
export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}
export const mmToPoints = (mm, type) => {
  let measureType = type ? type : "mm"
  if (measureType === "mm") {
    const MILLIMETERS_IN_INCH = 25.4
    const POINTS_IN_INCH = 72
    const inches = parseFloat(mm) / MILLIMETERS_IN_INCH
    return inches * POINTS_IN_INCH
  } else {
    const POINTS_IN_INCH = 72
    return parseFloat(mm) * POINTS_IN_INCH
  }
}

export const getexportcurrency = () => {
  return export_currency_data
}

export const getAllTypeOfRate = () => {
  return [
    {
      id: "amount",
      name: "Fixed Amount",
    },
    {
      id: "percentage",
      name: "Fixed Percentage",
    },
  ]
}
export const getproducttype = () => {
  return [
    {
      id: "1",
      name: "Product",
    },
    {
      id: "2",
      name: "Service",
    },
  ]
}
export const commonFnCheckReportId = ({ accept, reportid, reportidList }) => {
  if (accept === "not") {
    if (reportid) {
      return !reportidList.includes(parseInt(reportid, 10))
    }
    return true
  } else {
    if (reportid) {
      return reportidList.includes(parseInt(reportid, 10))
    }
    return false
  }
}
export const getproducttypegold = (jM, type) => {
  var productType = [
    {
      id: "1",
      name: "Product",
    },
    {
      id: "2",
      name: "Service",
    },
  ]
  if (type === "reducer" || (jM !== 1 && jM !== 2)) {
    productType.push({
      id: "3",
      name: "Gold",
    })
    productType.push({
      id: "4",
      name: "Silver",
    })
  } else {
    if (jM === 1) {
      productType.push({
        id: "3",
        name: "Gold",
      })
    } else if (jM === 2) {
      productType.push({
        id: "4",
        name: "Silver",
      })
    }
  }
  return productType
}

export const getItcType = () => {
  return [
    {
      id: "1",
      name: "Eligible for ITC",
    },
    {
      id: "2",
      name: "Ineligible for ITC",
    },
  ]
}

export const setColumnDataFilterWise = ({ data, columnData, filterType }) => {
  var filterdata = ""
  if (filterType === "filter_master") {
    filterdata = data.filter_master
  } else if (filterType === "filter_detail_detail") {
    filterdata = data.filter_detail_detail
  } else {
    filterdata = data.filter
  }

  let updatedcoloumn = cloneObj(columnData)
  if (filterdata && filterdata.length !== 0) {
    const getColumn = (id) => {
      return columnData.find((e) => e.id === id)
    }
    updatedcoloumn = []
    filterdata.forEach((item) => {
      let updatedItem = {}

      const currentColumn = getColumn(item.elementvalue)
      if (currentColumn) {
        updatedItem = { ...currentColumn }
      } else {
        updatedItem.numeric = parseInt(item.dataType) === cF.Decimal
        updatedItem.disableSorting = item.disableSorting
        updatedItem.parent = item.parent
        updatedItem.bold = item.bold
        updatedItem.depthKey = item.depthKey
        updatedItem.total = item.total
        updatedItem.noprint = item.noprint
      }

      if (item.elementText === "Pcs In" || item.elementText === "Pcs Out" || item.elementText === "Pcs Bal") {
        updatedItem.disableResizing = true
      }

      if (item.width) {
        updatedItem.width = parseFloat(item.width)
      }
      if (item.printWidth) {
        updatedItem.printWidth = parseFloat(item.printWidth)
      }
      if (item.printcharcount) {
        updatedItem.printcharcount = parseFloat(item.printcharcount)
      }
      if (item.printVisible) {
        updatedItem.printVisible = item.printVisible
      }
      updatedItem.id = item.elementvalue
      updatedItem.label = item.elementText
      updatedItem.visible = item.elementprop
      updatedItem.is_customField = item.is_customField

      if (!isblank(item.sort_order)) {
        updatedItem.sort_order = item.sort_order
      }
      if (!isblank(item.difference)) {
        updatedItem.difference = item.difference
      }
      if (!isblank(item.disableFilter)) {
        updatedItem.disableFilter = item.disableFilter
      }
      if (!isblank(item.display)) {
        updatedItem.display = item.display
      }
      if (!isblank(item.badge)) {
        updatedItem.badge = item.badge
      }
      if (!isblank(item.showlink)) {
        updatedItem.showlink = item.showlink
      }

      if (!isblank(item.numeric)) {
        updatedItem.numeric = item.numeric
      }

      if (!isblank(item.currencySign)) {
        updatedItem.currencySign = item.currencySign
      }

      if (!isblank(item.canGroup_By)) {
        updatedItem.canGroup_By = item.canGroup_By
      }
      if (!isblank(item.removelinkonly)) {
        updatedItem.removelinkonly = item.removelinkonly
      }
      if (!isblank(item.format)) {
        updatedItem.format = item.format
      }
      if (!isblank(item.toggle)) {
        updatedItem.toggle = item.toggle
      }
      if (!isblank(item.popover)) {
        updatedItem.popover = item.popover
      }

      updatedItem.dataType = item.dataType
      if (parseInt(item.dataType) === cF.Photo || parseInt(item.dataType) === cF.Dropbox) {
        updatedItem.isImage = true
        updatedItem.disableSorting = true
        updatedItem.ifblank = ""
      }

      if (item.format === "indian_rupee") {
        updatedItem.format = "indian_rupee"
        //updatedItem.total = true
        updatedItem.numeric = true
        updatedItem.currencySign = true
      }

      if (item.format === "qty_decimal") {
        updatedItem.format = "qty_decimal"
        updatedItem.numeric = true
      }

      if (item.total === true) {
        updatedItem.total = true
      }

      if (item.format === "rate_decimal") {
        updatedItem.format = "rate_decimal"
        updatedItem.numeric = true
      }

      if (item.format === "dmy") {
        updatedItem.format = "dmy"
        updatedItem.numeric = false
        updatedItem.date = true
      }

      if (item.url === true) {
        updatedItem.url = true
      }

      updatedcoloumn.push(updatedItem)
    })
    columnData.forEach((e) => {
      if (updatedcoloumn.filter((i) => i.id === e.id).length === 0) {
        e.visible = false
        updatedcoloumn.push(e)
      }
    })
  }
  return updatedcoloumn
}

export const setOrderFilterWise = (action) => {
  let newAction = { ...action }
  if ((isblank(newAction.order) || isblank(newAction.orderBy)) && action.payload && action.payload.data && action.payload.data.filter) {
    let defaultSorting = action.payload.data["filter"].find((e) => !isblank(e.sort_order))
    if (defaultSorting) {
      newAction.order = defaultSorting.sort_order
      newAction.orderBy = defaultSorting.elementvalue
    }
  }
  return newAction
}

export const getadvancetracking = () => {
  return [
    {
      code: "0",
      name: "None",
    },
    {
      code: "1",
      name: "Track Batches",
    },
    {
      code: "2",
      name: "Track Serial Number",
    },
  ]
}

export const deletedataFromForm = ({ props, state, ConfirmDialogData, getAccountSitesetting }) => {
  var confirmdata = state.confirmdata
  const formtype = confirmdata.formtype
  const formdata = {}
  formdata.code = confirmdata.formdata
  const reason = parseInt(getAccountSitesetting("reason"))
  if (reason === 1) {
    formdata.reason = ConfirmDialogData
  }
  const dispatch = confirmdata.dispatch
  const formaction = confirmdata.formaction
  const other_type = !isblank(confirmdata.other_type) ? confirmdata.other_type : ""
  props.post_form_data({ dispatch, formtype, formaction, formdata, otherDetail: "fromForm", other_type })
  confirmdata = null
}

export const deletedataFromFormNew = ({ post_form_data, confirmdata, ConfirmDialogData, getAccountSitesetting }) => {
  const formtype = confirmdata.formtype
  const formdata = {}
  const reason = parseInt(getAccountSitesetting("reason"))
  formdata.code = confirmdata.formdata
  if (reason === 1) {
    formdata.reason = ConfirmDialogData
  }
  const dispatch = confirmdata.dispatch
  const formaction = confirmdata.formaction
  const other_type = !isblank(confirmdata.other_type) ? confirmdata.other_type : ""
  dispatch(post_form_data({ formtype, formaction, formdata, otherDetail: "fromForm", other_type }))
}

export function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  }
}

export const getgroup = () => {
  return [
    {
      id: "1",
      name: "SUNDRY DEBTORS",
    },
    {
      id: "2",
      name: "SUNDRY CREDITORS",
    },
  ]
}

export const getStaticDropDownOption = (optionId) => {
  var staticOption = {
    201: getgstbusinesstypelist(),
    202: getgroup(),
  }
  if (optionId && parseFloat(optionId) > 200) {
    return staticOption[optionId.toString()]
  }
}

export const getParentType = (other_type, getAccountSitesetting) => {
  const iTD = getAccountSitesetting && getAccountSitesetting("invoice_type_detail")
  const pTD = getAccountSitesetting && getAccountSitesetting("purchase_type_detail")
  const inTD = getAccountSitesetting && getAccountSitesetting("inward_type_detail")
  const otTD = getAccountSitesetting && getAccountSitesetting("outward_type_detail")
  if (other_type.indexOf("lot_transfer") > -1) {
    return "lot_transfer"
  } else if (getAccountSitesetting && iTD.filter((e) => e.id === other_type).length > 0) {
    return "sales_invoice"
  } else if (getAccountSitesetting && pTD.filter((e) => e.id === other_type).length > 0) {
    return "purchase_invoice"
  } else if (getAccountSitesetting && inTD.filter((e) => e.id === other_type).length > 0) {
    return "inward"
  } else if (getAccountSitesetting && otTD.filter((e) => e.id === other_type).length > 0) {
    return "outward"
  }
  return other_type
}

export const setCustomFieldData = ({ ddcustomfield, showFieldsin, getMenuData, getAccountSitesetting }) => {
  var CustomField = []
  ddcustomfield.filter(function (item, index) {
    let details = item.detail.filter(function (detailitem) {
      if (detailitem.showFieldsin === showFieldsin) {
        return true
      }
      return false
    })
    if (details.length > 0) {
      var obj = details[0]
      obj.labelName = item.labelName
      obj.dataType = item.dataType
      obj.name = item.name
      obj.code = item.code
      obj.dropDownMulti = item.dropDownMulti
      obj.dependency = getdropdownfieldvalue({ dropDownData: getdatatype(getMenuData), field: "id", value: item.dataType, displayvalue: "dependency" })
      obj.dropdown_string = getdropdownfieldvalue({ dropDownData: getdatatype(getMenuData), field: "id", value: item.dataType, displayvalue: "type" })
      obj.editor_size = item.editor_size ? item.editor_size : ""
      obj.dropDownOptions = item.dropDownOptions
      obj.decimalPoint = item.decimalPoint
      obj.cal_total = item.cal_total
      obj.isNumeric = item.isNumeric
      obj.linkwith = item.linkwith
      //obj.sortNo = item.sortNo
      obj.photo_resolution = item.photo_resolution
      obj.mainType = getParentType(obj.showFieldsin, getAccountSitesetting)
      CustomField.push(obj)
    }
    return index
  })

  if (CustomField.length > 0) {
    CustomField = CustomField.sort(function (a, b) {
      return a.Sort - b.Sort
    })
  }

  return CustomField
}

export const setCustomFieldData_all = ({ ddcustomfield, showFieldsin, getAccountSitesetting }) => {
  var CustomField = []
  ddcustomfield.filter(function (item, index) {
    let details = item.detail.filter(function (detailitem) {
      if (detailitem.showFieldsin === showFieldsin) {
        return true
      }
      return false
    })
    if (details.length > 0) {
      var obj = details[0]
      obj.labelName = item.labelName
      obj.dataType = item.dataType
      obj.name = item.name
      obj.code = item.code
      obj.dropDownMulti = item.dropDownMulti
      obj.dependency = getdropdownfieldvalue({ dropDownData: getdatatype_all(), field: "id", value: item.dataType, displayvalue: "dependency" })
      obj.dropdown_string = getdropdownfieldvalue({ dropDownData: getdatatype_all(), field: "id", value: item.dataType, displayvalue: "type" })
      obj.editor_size = item.editor_size ? item.editor_size : ""
      obj.dropDownOptions = item.dropDownOptions
      obj.decimalPoint = item.decimalPoint
      obj.cal_total = item.cal_total
      obj.isNumeric = item.isNumeric
      obj.linkwith = item.linkwith
      //obj.sortNo = item.sortNo
      obj.photo_resolution = item.photo_resolution
      obj.mainType = getParentType(obj.showFieldsin, getAccountSitesetting)
      CustomField.push(obj)
    }
    return index
  })
  if (CustomField.length > 0) {
    CustomField = CustomField.sort(function (a, b) {
      return a.Sort - b.Sort
    })
  }
  return CustomField
}
export const setColumnDataForFilter = (columndata, getMenuData) => {
  var NewColumnData = columndata.map((item) => {
    const updatedItem = { ...item }
    updatedItem.dependency = getdropdownfieldvalue({ dropDownData: getdatatype(getMenuData), field: "id", value: item.dataType, displayvalue: "dependency" })
    updatedItem.dependency_type = getdropdownfieldvalue({ dropDownData: getdatatype(getMenuData), field: "id", value: item.dataType, displayvalue: "type" })
    if (updatedItem.parent) {
      updatedItem.accessor = `${updatedItem.parent}`
    } else {
      updatedItem.accessor = item.id
    }
    updatedItem.header = item.label
    if (updatedItem.dependency_type) {
      updatedItem.dataType = "CommonDependency"
    } else if (item.dataType && parseFloat(item.dataType) > 200) {
      updatedItem.dataType = "StaticDropDown"
      updatedItem.dropDownOption = getStaticDropDownOption(item.dataType)
    } else {
      updatedItem.dataType = getdropdownfieldvalue({ dropDownData: getdatatype(getMenuData), field: "id", value: item.dataType, displayvalue: "name" })
      if (updatedItem.dataType) {
        updatedItem.dataType = updatedItem.dataType.replace(/ /g, "_")
        updatedItem.dataType = updatedItem.dataType.replace("/", "_")
      }
    }
    return updatedItem
  })
  NewColumnData = NewColumnData.filter((item) => item.parent !== null && item.visible === true)
  return NewColumnData
}

export const makedropdowncompatible = (data, key) => {
  return data.map((item, index) => {
    const updateditem = { ...item }
    updateditem.name = updateditem[key]
    return updateditem
  })
}

export const stringToComponentTypeMapper = {
  1: "text",
  2: "text",
  3: "date",
  4: "text",
  5: "text",
  6: "text",
  8: "text",
  9: "text",
  10: "text",
  11: "text",
  12: "text",
  13: "text",
  14: "text",
  15: "text",
  16: "text",
  17: "text",
  18: "text",
  19: "text",
  20: "text",
  21: "text",
  22: "text",
  23: "text",
  24: "text",
  25: "text",
  27: "text",
  28: "text",
  29: "text",
  30: "text",
  31: "text",
  32: "text",
}

export const getAllShippingMethods = () => {
  return [
    {
      id: "free",
      name: "Free Shipping",
      description: "Offer free once order totals reach a certain amount (this attracts customers to buy more stuff)",
    },
    {
      id: "flat_rate",
      name: "Flat Rate Per Order",
      description: `Charge customers a flat rate shipping fee to their order total (it's easier for everyone)`,
    },
    {
      id: "weight_based",
      name: "Weight-based",
      description: "Charge different amounts based on how much an order weighs (lift with your legs)",
    },
    /*  {
            id: 'local_delivery',
            name: 'Local Delivery',
            description: 'Deliver to local addresses for free or for a fee'
          },
          {
            id: 'local_pickup',
            name: 'In-Person Pickup',
            description: 'Customers can pick up products from your store'
          }*/
  ]
}
export const booleanToNumeric = (value) => {
  if (isblank(value) || value === false || convertIntif(value) === 0) {
    return "0"
  }
  return "1"
}
export const DateToHMSDMY = (date) => {
  if (isblank(date)) {
    return ""
  }
  var newDate = date.split(" ")
  newDate[0] = DatetoDMY(newDate[0])
  return `${newDate[1]} ${newDate[0]}`
}
export const getusertype = () => {
  return [
    {
      id: "SA",
      name: "Super Admin",
    },
    {
      id: "A",
      name: "Admin",
    },
    {
      id: "M",
      name: "Manager",
    },
    {
      id: "C",
      name: "Cashier",
    },
    {
      id: "T",
      name: "Trainer",
    },
    {
      id: "F",
      name: "Front desk",
    },
    {
      id: "S",
      name: "Sales",
    },
    {
      id: "O",
      name: "Other",
    },
  ]
}
export const getAccountUserType = () => {
  return [
    {
      id: "A",
      name: "Admin",
    },
    {
      id: "M",
      name: "Manager",
    },
    {
      id: "C",
      name: "Cashier",
    },
    {
      id: "U",
      name: "Account User",
    },
  ]
}

export const loginSessionOptin = () => {
  return [
    {
      id: "60",
      name: "1 Hour",
    },
    {
      id: "120",
      name: "2 Hours",
    },
    {
      id: "180",
      name: "3 Hours",
    },
    {
      id: "360",
      name: "6 Hours",
    },
    {
      id: "720",
      name: "12 Hours",
    },
    {
      id: "1440",
      name: "1 Day",
    },
    {
      id: "2880",
      name: "2 Days",
    },
    {
      id: "4320",
      name: "3 Days",
    },
    {
      id: "10080",
      name: "7 Days",
    },
    {
      id: "14400",
      name: "10 Days",
    },
    {
      id: "21600",
      name: "15 Days",
    },
    {
      id: "43200",
      name: "30 Days",
    },
  ]
}

export const getMultiDropDownEditValue = ({ dropDown, field, values, labelField }) => {
  var returnValues = []
  if (!isblank(values)) {
    getMultiDropDownPostValue(values).forEach((e) => {
      if (!returnValues.includes(e)) {
        returnValues.push(e)
      }
    })
    // returnValues = returnValues.map((e) => {
    //     const updatedItem = {}
    //     updatedItem.value = getdropdownfieldvalue(dropDown, field, getDropDownPostValue(e), field);
    //     updatedItem.label = getdropdownfieldvalue(dropDown, field, getDropDownPostValue(e), labelField);
    //     updatedItem.row = dropDown.find((i) => i[field] === getDropDownPostValue(e));
    //     return updatedItem
    // })
  }
  return returnValues
}

export const taxOptions = () => {
  return [
    { id: "0", name: "None" },
    { id: "1", name: "GST(India)" },
    { id: "3", name: "Vat" },
    { id: "2", name: "Tax" },
  ]
}

export const getPrintPrice = ({ finalitem, showSalesPrice, showMrp }) => {
  var printPrice = "0.00"
  if (finalitem.sale_rate !== "" && finalitem.sale_rate !== null && showSalesPrice === 1) {
    printPrice = finalitem.sale_rate
  } else if (finalitem.mrp !== "" && finalitem.mrp !== null && showMrp === 1 && showSalesPrice !== 1) {
    printPrice = finalitem.mrp
  }
  return printPrice
}
export const getItemTotalAmount = ({ item, showSalesPrice, showMrp, rDP, iDP }) => {
  var price = getPrintPrice({ finalitem: item, showSalesPrice, showMrp })
  var total = 0.0
  if (parseFloat(getNumberonlyDecimal(price, rDP)) > 0 && parseFloat(getNumberonlyDecimal(item.qty, iDP)) > 0) {
    total = parseFloat(getNumberonlyDecimal(price, rDP)) * parseFloat(getNumberonlyDecimal(item.qty, iDP))
  }
  return total
}
export const getCartTotal = ({ cart, showSalesPrice, showMrp, rDP, iDP }) => {
  var totalAmount = 0.0
  cart.forEach((element) => {
    totalAmount = totalAmount + getItemTotalAmount({ item: element, showSalesPrice, showMrp, rDP, iDP })
  })
  return totalAmount
}
export const getNameFromType = ({ invoice_type, short_form, getAccountSitesetting }) => {
  var title = ""
  const iTD = getAccountSitesetting("invoice_type_detail")
  const pTD = getAccountSitesetting("purchase_type_detail")
  const inTD = getAccountSitesetting("inward_type_detail")
  const otTD = getAccountSitesetting("outward_type_detail")
  if (iTD.filter((item) => item.id === invoice_type).length > 0) {
    title = iTD.find((item) => item.id === invoice_type).name
  } else if (pTD.filter((item) => item.id === invoice_type).length > 0) {
    title = pTD.find((item) => item.id === invoice_type).name
  } else if (inTD.filter((item) => item.id === invoice_type).length > 0) {
    title = inTD.find((item) => item.id === invoice_type).name
  } else if (otTD.filter((item) => item.id === invoice_type).length > 0) {
    title = otTD.find((item) => item.id === invoice_type).name
  } else if (invoice_type === "salesreturn") {
    title = "Credit Note"
  } else if (invoice_type === "purchasereturn") {
    title = "Debit Note"
  } else if (invoice_type === "lot_transfer") {
    title = "Process Transfer"
  } else if (invoice_type === "stock_transferGodown") {
    title = "Godown Stock Transfer"
  }
  if (short_form) {
    var shortTitle = ""
    var Shortform = title.split(" ")
    Shortform.forEach((it) => {
      shortTitle = shortTitle + it[0]
    })
    return shortTitle
  }
  return title
}

export const getOrderTypes = (all_menu) => {
  var returnTypes = []
  if (!isblank(all_menu) && !isblank(all_menu.Invoice) && all_menu.Invoice.length > 0) {
    const orders = all_menu.Invoice.find((e) => e.id === "9000")
    if (!isblank(orders)) {
      returnTypes = orders.dd_orderTypes.map((e) => {
        return { id: e.type, name: e.name }
      })
    }
  }
  if (returnTypes.length > 1) {
    returnTypes = [{ id: "all", name: "All" }, ...returnTypes]
  }
  return returnTypes
}

export const getOrderMenuidTypes = (all_menu, type) => {
  if (!isblank(all_menu) && !isblank(all_menu.Invoice) && all_menu.Invoice.length > 0) {
    const orders = all_menu.Invoice.find((e) => e.id === "9000")
    if (!isblank(orders)) {
      const menu = orders.dd_orderTypes
        .filter((e) => e.type === type)
        .map((e) => {
          return e.id
        })

      return menu[0]
    }
  }
}

export const common_getAccountSitesetting = ({ key, type, meta }) => {
  const { store } = meta
  const state = store.getState()
  const { accountSiteSettingKeyValue } = state.AuthReducer
  return commonSiteSetting({ key, settingData: accountSiteSettingKeyValue, type })
}

export const commonSiteSetting = ({ key, settingData, type, meta }) => {
  if (isblank(settingData)) {
    return ""
  }
  var firmdetails = ""
  firmdetails = cloneObj(settingData)
  if (key === "all") {
    return firmdetails
  } else if (key === "inward_type_detail") {
    let returnValue = firmdetails[key] ? firmdetails[key] : []
    var inwardSetting = cloneObj(returnValue.find((e) => e.id === "inward"))
    if (inwardSetting) {
      inwardSetting.id = "op_inward"
      inwardSetting.name = "Old Inward"
      returnValue.push(inwardSetting)
    }
    return returnValue
  } else if (key === "outward_type_detail") {
    let returnValue = firmdetails[key] ? firmdetails[key] : []
    var inwardSetting = cloneObj(returnValue.find((e) => e.id === "outward"))
    if (inwardSetting) {
      inwardSetting.id = "op_outward"
      inwardSetting.name = "Old Outward"
      returnValue.push(inwardSetting)
    }
    return returnValue
  } else if (key === "invoice_type_detail" || key === "purchase_type_detail" || key === "salesreturn_type_detail" || key === "purchasereturn_type_detail") {
    return firmdetails[key] ? firmdetails[key] : []
  } else if (key === "currency" && type !== "id") {
    if (firmdetails[key] === "&#8377;" || firmdetails[key] === "\u20B9" || firmdetails[key] === "₹") {
      return "₹"
    }
    return firmdetails[key]
  } else if (key === "store_contact" || key === "store_whatsapp") {
    return !isblank(firmdetails) && !isblank(firmdetails[key]) ? firmdetails[key] : ""
  } else {
    return !isblank(firmdetails) && !isblank(firmdetails[key]) ? convertIntif(firmdetails[key]) : ""
  }
}

export const common_getSettingForUpdate = ({ key, type, meta }) => {
  const { store } = meta
  const state = store.getState()
  const { accountSiteSetting } = state.AuthReducer
  return commonSettingForUpdate({ key, settingData: accountSiteSetting, type, meta })
}

export const commonSettingForUpdate = ({ key, settingData, type, meta }) => {
  var firmdetails = ""
  firmdetails = cloneObj(settingData)
  if (key === "all") {
    return firmdetails
  } else if (
    key === "invoice_type_detail" ||
    key === "purchase_type_detail" ||
    key === "inward_type_detail" ||
    key === "outward_type_detail" ||
    key === "salesreturn_type_detail" ||
    key === "purchasereturn_type_detail"
  ) {
    return firmdetails[key] ? firmdetails[key] : []
  } else {
    if (!isblank(firmdetails)) {
      Object.keys(firmdetails).forEach((item) => {
        if (
          item === "taxSetting" ||
          item === "contactSetting" ||
          item === "itemSetting" ||
          item === "transactionPrintSetting" ||
          item === "generalSetting" ||
          item === "invoiceSetting" ||
          item === "moduleSetting" ||
          item === "fbShopSetting" ||
          item === "transaction_setting"
        ) {
          if (!isblank(firmdetails[item]) && typeof firmdetails[item] === "object") {
            Object.keys(firmdetails[item]).forEach((it) => {
              if (item === "fbShopSetting" && (it === "contact" || it === "general_setting")) {
                if (!isblank(firmdetails[item][it]) && typeof firmdetails[item][it] === "object") {
                  Object.keys(firmdetails[item][it]).forEach((e) => {
                    firmdetails[e] = firmdetails[item][it][e]
                  })
                }
              } else if (item === "transaction_setting" && (it === "receipt" || it === "payment" || it === "journal_entry")) {
                if (!isblank(firmdetails[item][it]) && typeof firmdetails[item][it] === "object") {
                  Object.keys(firmdetails[item][it]).forEach((e) => {
                    firmdetails[e] = firmdetails[item][it][e]
                  })
                }
              } else {
                firmdetails[it] = firmdetails[item][it]
              }
            })
          }
        }
      })
    }
    if (key === "currency") {
      var currency = !isblank(firmdetails) && firmdetails.hasOwnProperty(key) ? firmdetails[key] : ""
      if (currency === "&#8377;" || currency === "\u20B9" || currency === "₹") {
        return "₹"
      }
      return currency
    }
    var returnValue = !isblank(firmdetails) ? (firmdetails.hasOwnProperty(key) ? convertIntif(firmdetails[key]) : "") : ""
    //if blank then default settings
    if (isblank(returnValue)) {
      if (key === "e_invoice" || key === "jwellery_metal" || key === "tds" || key === "customerListAuto" || key === "itemListAuto") {
        returnValue = 0
      }
    }
    return returnValue
  }
}

export const deleteInReducer = ({ action, state, key }) => {
  if (action.otherDetail === "multi_delete") {
    var finalData = []
    var deletedCount = 0
    var applyToDelete = action.payload.code.codes.map((a) => a.toString())
    if (action.response.data.meta.code === 403) {
      //Error
      var NotDelete = action.response.data.meta.message.map((a) => a.code.toString())
      finalData = state[key].filter((item) => {
        item.code = item.code.toString()
        if (!(applyToDelete.includes(item.code) && !NotDelete.includes(item.code))) {
          return item
        }
        deletedCount = deletedCount + 1
        return false
      })
    } //All delete
    else {
      finalData = state[key].filter((item) => {
        item.code = item.code.toString()
        if (!applyToDelete.includes(item.code)) {
          return item
        }
        deletedCount = deletedCount + 1
        return false
      })
    }
    return { ...state, [key]: finalData, deleted: !state.deleted, totalcount: parseInt(getNumberonly(state.totalcount)) - deletedCount }
  } else {
    return { ...state, [key]: state[key].filter((item) => item.code.toString() !== action.payload.code.toString()), deleted: !state.deleted, totalcount: parseInt(getNumberonly(state.totalcount)) - 1 }
  }
}
export const multiDelete = ({ action, array }) => {
  if (action.otherDetail === "multi_delete") {
    var finalData = []
    var applyToDelete = action.payload.code.codes.map((a) => a.toString())
    if (action.response.data.meta.code === 403) {
      //Error
      var NotDelete = action.response.data.meta.message.map((a) => a.code.toString())
      finalData = array.filter((item) => {
        item.code = item.code.toString()
        if (!(applyToDelete.includes(item.code) && !NotDelete.includes(item.code))) {
          return item
        }
        return false
      })
    } //All delete
    else {
      finalData = array.filter((item) => {
        item.code = item.code.toString()
        if (!applyToDelete.includes(item.code)) {
          return item
        }
        return false
      })
    }
    return finalData
  } else {
    if (!isblank(action.payload.code)) {
      return array.filter((item) => item.code.toString() !== action.payload.code.toString())
    }
    return array
  }
}
export const multiDeleteRecent = ({ action, array }) => {
  if (action.otherDetail === "multi_delete") {
    var finalData = []
    var applyToDelete = action.payload.code.codes.map((a) => a.toString())
    if (action.response.data.meta.code === 403) {
      //Error
      var NotDelete = action.response.data.meta.message.map((a) => a.code.toString())
      finalData = array.filter((item) => {
        item = item.toString()
        if (!(applyToDelete.includes(item) && !NotDelete.includes(item))) {
          return item
        }
        return false
      })
    } //All delete
    else {
      finalData = array.filter((item) => {
        item = item.toString()
        if (!applyToDelete.includes(item)) {
          return item
        }
        return false
      })
    }
    return finalData
  } else {
    if (!isblank(action.payload.code)) {
      return array.filter((item) => item.toString() !== action.payload.code.toString())
    }
    return array
  }
}

export const getAccountRightsByID = ({ id, getUSerRights }) => {
  if (!isblank(id)) {
    const userRights = getUSerRights()
    var key = id
    return userRights !== undefined ? (userRights.hasOwnProperty(key) ? userRights[key].split("|") : []) : []
  } else {
    return []
  }
}

export const getCookie = (name) => {
  const regex = new RegExp(`(?:(?:^|.*;*)${name}*=*([^;]*).*$)|^.*$`)
  return document.cookie.replace(regex, "$1")
}

export const setCookie = ({ name, value }) => {
  document.cookie = `${name}=${value};path=/;max-age=31536000`
}

export const whatsappLink = ({ mobileNo, message }) => {
  var whatsAppLink = "https://wa.me/send/?"
  var waData = ""
  if (!isblank(message)) {
    waData = message
    waData = encodeURIComponent(waData)
    if (!isblank(mobileNo)) {
      return `${whatsAppLink}phone=${mobileNo}&text=${waData}`
    } else {
      return `${whatsAppLink}?text=${waData}`
    }
  } else {
    if (!isblank(mobileNo)) {
      return `${whatsAppLink}phone=${mobileNo}`
    } else {
      return `${whatsAppLink}`
    }
  }
}

export const getallListStatus = () => {
  var newArray = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "10",
      name: "Waiting for approval",
    },
  ]
  return newArray.concat(getAllStatus())
}

export const getAllStatus = () => {
  return [
    {
      id: "1",
      name: "Allow",
    },
    {
      id: "0",
      name: "Not Allow",
    },
    {
      id: "2",
      name: "Allow for 15 min",
    },
    {
      id: "3",
      name: "Allow for 30 min",
    },
    {
      id: "4",
      name: "Allow for 45 min",
    },
    {
      id: "5",
      name: "Allow for 1 hour",
    },
    {
      id: "6",
      name: "Allow for 3 hour",
    },
    {
      id: "7",
      name: "Allow for 6 hour",
    },
    {
      id: "8",
      name: "Allow for 1 day",
    },
  ]
}

export const getAllPromoOption = () => {
  return [
    {
      id: "1",
      name: "Product",
    },
    {
      id: "2",
      name: "Promo Images",
    },
    {
      id: "3",
      name: "Slider",
    },
  ]
}

export const getAllListViewType = () => {
  return [
    {
      id: "H",
      name: "Horizontal List",
    },
    {
      id: "V",
      name: "Vertical List",
    },
    {
      id: "1",
      name: "1 Column",
    },
    {
      id: "2",
      name: "2 Column",
    },
    {
      id: "3",
      name: "3 Column",
    },
  ]
}

export const getAllPromoType = () => {
  return [
    {
      id: "1",
      name: "Tag",
    },
    {
      id: "2",
      name: "Product Link",
    },
    {
      id: "3",
      name: "Catagory Link",
    },
  ]
}

export const getCodeOfCustomPageByName = ({ name, store }) => {
  const state = store.getState()
  const { customPages } = state.AuthReducer
  if (isblank(name) || isblank(customPages) || customPages.length === 0) {
    return ""
  }
  var customPage = customPages.find((e) => e.name === name)
  if (!isblank(customPage)) {
    return customPage.code
  }
  return ""
}

export const checkCustomPageDependancy = ({ strdependency, store }) => {
  const state = store.getState()
  const { dd_custom_page_dropdown } = state.CommonReducer
  strdependency = strdependency.split(",")
  var newstrdepemdency = ""
  strdependency.forEach((e) => {
    var addnew = false
    var customPageCode = getCodeOfCustomPageByName({ name: e, store })
    if (isblank(dd_custom_page_dropdown[customPageCode]) || dd_custom_page_dropdown[customPageCode].length === 0) {
      addnew = true
    }
    if (addnew) {
      if (newstrdepemdency === "") {
        newstrdepemdency = customPageCode
      } else {
        newstrdepemdency = newstrdepemdency + "," + customPageCode
      }
    }
  })
  return newstrdepemdency
}

export const getallPackingStatus = () => {
  return [
    {
      id: "0",
      name: "Pending",
    },
    {
      id: "1",
      name: "Archive",
    },
    {
      id: "2",
      name: "Closed",
    },
  ]
}

const createCORSRequest = ({ method, url, opts }) => {
  var opts = opts || {}
  var xhr = new XMLHttpRequest()

  if (xhr.withCredentials != null) {
    xhr.open(method, url, true)
    if (opts.withCredentials != null) {
      xhr.withCredentials = opts.withCredentials
    }
  } else {
    xhr = null
  }
  return xhr
}

function getFileMimeType(file) {
  return file.type || mime.lookup(file.name)
}

export const uploadToS3 = ({ file, signResult, onProgress, onError, setRequest }) => {
  var xhr = createCORSRequest({ method: "PUT", url: signResult.signedUrl })
  if (!xhr) {
    onError("CORS not supported", file, {})
  } else {
    xhr.onload = function () {
      if (xhr.status === 200 || xhr.status === 201) {
        onProgress(100, "Upload completed", file)
        // return this.onFinishS3Put(signResult, file);
      } else {
        onError("Upload error: " + xhr.status, file, xhr)
      }
    }
    xhr.onerror = function () {
      return onError("XHR error", file, xhr)
    }
    xhr.upload.onprogress = function (e) {
      var percentLoaded
      if (e.lengthComputable) {
        percentLoaded = Math.round((e.loaded / e.total) * 100)
        return onProgress(percentLoaded, percentLoaded === 100 ? "Finalizing" : "Uploading", file)
      }
    }
  }

  var fileType = getFileMimeType(file)
  var headers = {
    "content-type": fileType,
    "x-amz-acl": "public-read",
  }
  Object.entries(headers).forEach(function (pair) {
    xhr.setRequestHeader(pair[0], pair[1])
  })
  setRequest(xhr)
  xhr.send(file)
}

export const getSignedUrlFn = async ({ index, file, callback, onProgress, onError, type, oldKey, functions }) => {
  const { getAccountSessionUserData, getKeyAndPostName, getheaders } = functions
  let contentType = file.type
  var LocalOrOnline = ""
  if (process.env.REACT_APP_BASE_URL.includes(".local")) {
    LocalOrOnline = "FB-Local/"
  }
  const company_url = getAccountSessionUserData("company_url")
  var Key = ""
  if (oldKey) {
    var Key = oldKey
  } else {
    var getKeyPostName = getKeyAndPostName(file)
    file.postName = getKeyPostName.postName
    var Key = oldKey ? oldKey : LocalOrOnline + company_url + getKeyPostName.Key
  }
  if (type === "small") {
    Key = Key.replace("large", "small")
  }
  let data = new FormData()
  data.append("s3path", Key)
  data.append("contentType", contentType)
  axios.defaults.headers.common = getheaders()
  axios({
    method: "post",
    url: list_url({ type: "s3_createPresignedRequest" }),
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  })
    .then((response) => {
      if (response.data.meta.code === 401) {
        onError(response.data.meta.message, { file, callback, onProgress, onError, type, oldKey })
      } else {
        file.Key = Key
        callback({
          setRequest: (xhr) => this.setRequest(xhr, index, "Cancelled", { file, callback, onProgress, onError, type, oldKey }),
          file,
          signResult: { signedUrl: response.data.result },
          onProgress,
          onError: (errorMessage) => onError(errorMessage, { file, callback, onProgress, onError, type, oldKey }),
        })
      }
    })
    .catch((error) => {
      postErrorOnException(error, "", "", company_url)
      onError(getCatchErrorMessage(error), { file, callback, onProgress, onError, type, oldKey })
    })
}

export const generateURL = (name) => {
  if (isblank(name) || typeof name !== "string") {
    return ""
  }
  var URL = name.toLowerCase().replace(/ /g, "-")
  return encodeURIComponent(URL)
}

export const addKeyForSearch = (dataArray) => {
  return dataArray.map((e, index) => {
    const updated = { ...e }
    updated.srNo = index
    updated.visible = true
    return updated
  })
}

export const setCustomFieldForProcess = ({ updatedItem, item }) => {
  let returnValue = { ...updatedItem }
  if (!isblank(item)) {
    let custom_fields_detail = JSON.parse(item)
    if (!isblank(custom_fields_detail) && custom_fields_detail.length > 0) {
      custom_fields_detail.forEach((e) => {
        Object.keys(e).forEach((i) => {
          returnValue[i] = e[i]
        })
      })
    }
  }
  return returnValue
}

export const getDefaultSortBy = (columnData) => {
  let defaultSortBy = []
  columnData.forEach((e) => {
    if (defaultSortBy.length === 0) {
      if (!isblank(e.sort_order)) {
        if (e.sort_order === "desc") {
          defaultSortBy.push({ id: e.id, desc: true })
        } else {
          defaultSortBy.push({ id: e.id, desc: false })
        }
      }
    }
  })
  return defaultSortBy
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const extraFieldDisplay = (mode, ddcustomfield) => {
  if (!isblank(ddcustomfield) && ddcustomfield.length > 0) {
    let showinType = ""
    if (mode === "ITEM") {
      showinType = "product"
    }

    const fields = ddcustomfield
      .filter(function (item) {
        return item.showInDropdown === "1" && item.isActive === "1" && item.showFieldsin === showinType
      })
      .map((e, index) => {
        return {
          label: e.labelName,
          value: e.name,
        }
      })
    return fields
  } else {
    return []
  }
}

export const getallSupplyType = () => {
  return [
    {
      id: "O",
      name: "Outward",
    },
    {
      id: "I",
      name: "Inward",
    },
  ]
}

export const getallTransactionType = () => {
  return [
    {
      id: "1",
      name: "Regular",
    },
    {
      id: "2",
      name: "Bill To - Ship To",
    },
    {
      id: "3",
      name: "Bill From - Dispatch From",
    },
    {
      id: "4",
      name: "Combination of 2 and 3",
    },
  ]
}

export const getallVehicleType = () => {
  return [
    {
      id: "R",
      name: "Regular",
    },
    {
      id: "O",
      name: "Over Dimensional Cargo",
    },
  ]
}
