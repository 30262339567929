import lodashFpMap from "lodash/fp/map"
import lodashOmit from "lodash/fp/omit"
import moment from "moment"
import { generateTableData } from "./common"
import { addTotalRow } from "./reportFunction"
import { cloneObj, isblank, makeExcelRequireCol, makeExcelRequiredData, replaceBRtoNewLine, replacefunction } from "./utilityFunctions"
export const generateListPrint = ({ data, columnData, functions, title }) => {
  const { getAccountSitesetting, getAccountSessionUserData } = functions
  const currency = getAccountSitesetting("currency")

  var companyName = getAccountSessionUserData("company_name")
  var company_address1 = replacefunction(replaceBRtoNewLine(getAccountSessionUserData("company_address1")))
  var company_address2 = replacefunction(replaceBRtoNewLine(getAccountSessionUserData("company_address2")))

  var mycol = columnData.filter((column) => {
    if (column.visible && !column.noprint) {
      if (isblank(column.display)) {
        return true
      } else if (getAccountSitesetting(column.display) === 1) {
        return true
      }
      return false
    }
    return false
  })
  var finalcontain = []

  var dataPrint = moment().format("DD-MM-YYYY hh:mm A")

  finalcontain.push({
    columns: [{ text: "" }, { text: `${companyName}`, style: "header", alignment: "center" }, { text: dataPrint, style: "subheader", alignment: "right" }],
    margin: [0, 0, 0, 0],
  })

  finalcontain.push({ text: `${company_address1}`, style: "subheader", alignment: "center", bold: false, margin: [0, 0, 0, 0] })
  finalcontain.push({ text: `${company_address2}`, style: "subheader", alignment: "center", bold: false, margin: [0, 0, 0, 8] })
  finalcontain.push({ text: `${title}`, style: "header", alignment: "center" })

  var PDF_HEADING = ""
  var PDF_DATA = ""

  PDF_HEADING = mycol.map((column) => {
    var align = column.numeric ? "right" : "left"

    if (column.currencySign === true) {
      return { text: column.label + "(" + currency + ")", fontSize: 8, bold: true, alignment: align }
    } else {
      return { text: column.label, fontSize: 8, bold: true, alignment: align }
    }
  })

  var PDF_COL_WIDTHS = mycol.map((column) => {
    return column.width ? column.width * 0.4 : "*"
  })

  PDF_COL_WIDTHS = mycol.map((column) => {
    return column.width ? column.width * 0.4 : "*"
  })

  PDF_DATA = generateTableData({ data, mycol, requireTotal: false, totalDifference: false, functions })

  finalcontain.push({
    style: "tableExample",
    table: {
      headerRows: 1,
      widths: PDF_COL_WIDTHS,
      body: [PDF_HEADING, ...PDF_DATA],
    },
    layout: {
      hLineWidth: function (i, node) {
        return 0.1
      },
      vLineWidth: function (i, node) {
        return 0.1
      },
    },
  })

  const docDefinition = {
    info: {
      title: title,
      author: "FinalBooks.in",
      subject: title,
      keywords: "",
    },
    pageSize: "A4",
    pageMargins: [15, 15, 15, 25], //top,left,right,bottom
    pageOrientation: mycol.length > 6 ? "landscape" : "portrait",
    content: finalcontain,
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          {
            fontSize: 9,
            text: [
              {
                text: parseInt(pageCount) !== 1 ? "Page " + parseInt(currentPage) + " of " + pageCount : "",
              },
            ],
            alignment: "right",
          },
        ],
        margin: [15, 2, 15, 0],
      }
    },
    styles: {
      header: {
        fontSize: 10,
        bold: true,
        margin: [0, 0, 0, 2],
      },
      subheader: {
        fontSize: 8,
        margin: [0, 0, 0, 2],
      },
    },
  }
  return docDefinition
}

export const generateListExcel = ({ data, columnData, functions, filename, total = false, lastRowBold = false }) => {
  const { getAccountSitesetting, exportAsExcel } = functions

  var mycol = columnData.filter((column) => {
    if (column.visible) {
      if (isblank(column.display)) {
        return true
      } else if (getAccountSitesetting(column.display) === 1) {
        return true
      }
      return false
    }
    return false
  })

  if (total === true) {
    var dataForCalc = cloneObj(data)
    data = addTotalRow({ printdata: data, mycol, user_data: "", dataForCalc, functions })
  }

  var removeColumn = Object.keys(data[0])
  removeColumn = removeColumn.filter((item) => {
    var found = mycol.filter((column) => {
      if (column.id === item) {
        return true
      }
      return false
    })

    if (found.length > 0) {
      return false
    }
    return true
  })

  removeColumn.forEach((item, index) => {
    data = lodashFpMap(lodashOmit(item), data)
  })
  data = makeExcelRequiredData({ data, column: mycol, functions: { getAccountSitesetting } })
  mycol = makeExcelRequireCol(mycol, getAccountSitesetting)

  if (total === true || lastRowBold === true) {
    exportAsExcel({ csvData: data, fileName: filename, mycol, totalIndex: [data.length - 1] })
  } else {
    exportAsExcel({ csvData: data, fileName: filename, mycol, totalIndex: [] })
  }
}
