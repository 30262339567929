import { Button, FormControl, FormControlLabel, FormHelperText, IconButton, makeStyles, Radio, RadioGroup, Select, Switch, TextField, Checkbox } from "@material-ui/core"
import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons"
import HelpBtnComponent from "@project/components/common/HelpBtnComponent"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import { amount_format, capitalizeFirstLetter, getNumberonly, isblank, toFixedFn } from "@project/sharedcomponents/utilityFunctions"
import React from "react"
import { isMobileOnly } from "react-device-detect"
import { useDispatch } from "react-redux"
import LabelComponent from "../common/labelComponent"
import renderTextFieldCommon from "./renderTextField"

const useStyles = makeStyles((theme) => ({
  textFieldInputNumber: {
    textAlign: "right",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "&$cssError $notchedOutline": {
      borderColor: theme.palette.error.main,
    },
    "&$cssFocused $notchedDateOutline": {
      borderColor: theme.palette.primary.main,
    },
    "&$cssError $notchedDateOutline": {
      borderColor: theme.palette.error.main,
    },
  },
  cssFocused: {},
  cssError: {},
  notchedOutline: {
    borderWidth: 0,
  },
  notchedDateOutline: {},
  notchedMobileDateOutline: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.87)",
  },
}))

const components = {
  DropdownIndicator: null,
}

const RenderTextField = (...props) => {
  return renderTextFieldCommon(...props)
}

const RenderTextFieldWithPlusMinus = ({
  input: { onChange, value, type, ...inputProps },
  input,
  placeholder,
  label,
  isRequired,
  number,
  onvaluechange,
  dataindex,
  setUnderLine,
  decimal,
  meta: { touched, error },
  ...custom
}) => {
  function handlePlus(event) {
    input.onChange(parseFloat(getNumberonly(value)) + 1)
  }

  function handleMinus(event) {
    input.onChange(parseFloat(getNumberonly(value)) - 1)
  }

  var variantType = "standard"

  const dispatch = useDispatch()
  const classes = useStyles()
  var ingrid = false

  if (!isblank(setUnderLine)) {
    if (!setUnderLine) {
      ingrid = true
      variantType = "outlined"
    }
  }

  if (custom.type === "date") {
    variantType = "outlined"
  }

  return (
    <div style={{ width: 300, display: "flex" }}>
      <Button onClick={handlePlus} color="secondary">
        +
      </Button>
      <TextField
        {...input}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            error: classes.cssError,
            input: number ? classes.textFieldInputNumber : classes.textFieldInput,
            notchedOutline:
              variantType === "outlined"
                ? ingrid === false && variantType === "outlined" && isMobileOnly
                  ? classes.notchedMobileDateOutline
                  : ingrid === false && variantType === "outlined"
                  ? classes.notchedDateOutline
                  : classes.notchedOutline
                : undefined,
          },
        }}
        onFocus={(e) => {
          var target = e.target

          if (value !== "") {
            input.onChange(getNumberonly(value))
            //input.onFocus();
            //target.setSelectionRange(0,getNumberonly(value).length);
            setTimeout(function() {
              target.setSelectionRange(0, getNumberonly(value).length)
            }, 10)
          }
        }}
        onBlur={(e) => {
          if (value !== "") {
            var decimalsupport = decimal || decimal === 0 ? decimal : 2
            value = toFixedFn(parseFloat(value), decimalsupport)
            if (isNaN(value)) {
              input.onChange("")
              dispatch({
                type: "snackbar_state",
                payload: true,
                message: ErrorMessages["E00066"],
              })
            } else {
              input.onChange(amount_format(value))
            }
          }
          input.onBlur()
        }}
        onChange={(e) => {
          input.onChange(e.target.value)
          if (onvaluechange) {
            if (dataindex !== undefined) {
              onvaluechange(dataindex, e.target.value)
            } else {
              onvaluechange(e.target.value)
            }
          }
        }}
        error={Boolean(touched && error)}
        autoComplete={custom.type === "password" ? "new-password" : "off"}
        margin="normal"
        fullWidth={true}
        {...(placeholder && {
          placeholder: capitalizeFirstLetter(placeholder),
        })}
        {...custom}
      />
      <Button onClick={handleMinus} color="secondary">
        -
      </Button>
    </div>
  )
}

const renderToggle = ({ input: { onChange, value, ...inputProps }, checked, onvaluechange, helperText, helpText, toolTipStyle, helpStyle, label, meta, ...props }) => {
  return (
    <React.Fragment>
      <>
        {isblank(label) ? (
          <Switch
            {...inputProps}
            {...props}
            checked={!!value}
            onChange={(event, isInputChecked) => {
              onChange(event, isInputChecked)
              if (onvaluechange) {
                onvaluechange(isInputChecked)
              }
            }}
          />
        ) : (
          <FormControlLabel
            control={
              <Switch
                {...inputProps}
                {...props}
                checked={!!value}
                onChange={(event, isInputChecked) => {
                  onChange(event, isInputChecked)
                  if (onvaluechange) {
                    onvaluechange(isInputChecked)
                  }
                }}
              />
            }
            label={label}
          />
        )}
        {helpText && <HelpBtnComponent help={helpText} style={helpStyle ? helpStyle : { marginLeft: 4 }} toolTipStyle={toolTipStyle} />}
      </>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </React.Fragment>
  )
}

const renderCheckbox = ({ input: { onChange, value, ...inputProps }, checked, onvaluechange, helperText, helpText, toolTipStyle, helpStyle, label, meta, type, ...props }) => {
  return (
    <React.Fragment>
      <>
        {isblank(label) ? (
          <Checkbox
            {...inputProps}
            {...props}
            checked={!!value}
            onChange={(event, isInputChecked) => {
              onChange(event, isInputChecked)
              if (onvaluechange) {
                onvaluechange(isInputChecked)
              }
            }}
          />
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                {...inputProps}
                {...props}
                checked={!!value}
                onChange={(event, isInputChecked) => {
                  onChange(event, isInputChecked)
                  if (onvaluechange) {
                    onvaluechange(isInputChecked)
                  }
                }}
              />
            }
            label={label}
          />
        )}
        {helpText && <HelpBtnComponent help={helpText} style={helpStyle ? helpStyle : { marginLeft: 4 }} toolTipStyle={toolTipStyle} />}
      </>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </React.Fragment>
  )
}

function renderInput(inputProps, meta, input, isOpen, isRequired, openMenu, clearSelection, toggleMenu, onAutofillbtnClick, onvaluechange, margin, dataindex, disabled) {
  const { autoFocus, value, ref, label, id, type, ...other } = inputProps
  const { error, touched } = meta

  let topposition = 40
  if (label === undefined) {
    topposition = 0
  }
  let secoundaryitemwidth = 38
  if (onAutofillbtnClick) {
    secoundaryitemwidth = secoundaryitemwidth + 96
  }

  const secondaryActionFrame = {
    overflow: "visible",
    width: secoundaryitemwidth,
    backgroundColor: "red",
    height: 38,
    position: "absolute",
    right: 0,
    top: topposition,
    zIndex: 0,
  }

  const addautofillbutton = {
    backgroundColor: "#757575",
    borderRadius: 0,
    height: 38,
    color: "#ffffff",
    width: 96,
  }

  let secondaryActionItem = isOpen ? (
    <IconButton tabIndex="-1" style={{ padding: 7 }} onClick={() => toggleMenu()}>
      <KeyboardArrowUp />
    </IconButton>
  ) : (
    <IconButton tabIndex="-1" style={{ padding: 7 }} onClick={() => toggleMenu()}>
      <KeyboardArrowDown />
    </IconButton>
  )

  if (value !== "") {
    secondaryActionItem = (
      <IconButton
        style={{ padding: 7, height: 38, width: 38 }}
        onClick={() => {
          clearSelection()
          toggleMenu()
          if (onvaluechange) {
            onvaluechange(dataindex, null)
          }
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    )
  }

  let autofillButton = null
  if (onAutofillbtnClick) {
    autofillButton = (
      <Button tabIndex="-1" onClick={onAutofillbtnClick} style={addautofillbutton}>
        AutoFill
      </Button>
    )
  }

  const secondaryAction = <span>{secondaryActionItem}</span>
  return (
    <div>
      <TextField
        {...input}
        value={value ? value : ""}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          ...other,
        }}
        onBlur={(e) => {
          if (value === "") {
            input.onChange("")
          }
          input.onBlur()
        }}
        onFocus={(e) => {
          if (value === "") {
            //openMenu();
          }
        }}
        onClick={(e) => {
          if (value === "") {
            openMenu()
          }
        }}
        autoFocus={autoFocus}
        inputRef={ref}
        label={label}
        name={id}
        error={Boolean(touched && error)}
        disabled={disabled}
        fullWidth={true}
        margin="normal"
        autoComplete={type === "password" ? "new-password" : "off"}
        {...other}
        {...(margin && {
          margin: margin,
        })}
      />

      {disabled !== true && (
        <div style={secondaryActionFrame}>
          {secondaryAction}
          {autofillButton}
        </div>
      )}
    </div>
  )
}

function getSuggestions(inputValue, dataList) {
  let count = 0
  return dataList.filter((suggestion) => {
    const keep = (!inputValue || suggestion.name.toLowerCase().includes(inputValue.toLowerCase())) && count < 20 //dataList.length
    if (keep) {
      count += 1
    }

    return keep
  })
}

const renderRadioGroup = ({ input: { onChange, value, ...inputProps }, labelPlacement, options, meta: { touched, error }, label, children, ...props }) => {
  let newValue = value
  return (
    <React.Fragment>
      <LabelComponent label={label} />
      <RadioGroup
        {...inputProps}
        {...props}
        value={newValue}
        onChange={(event, isInputChecked) => {
          onChange(event, isInputChecked)
        }}
        // children={children}
      >
        {options.map((item) => {
          return <FormControlLabel value={item.value} control={<Radio color={"primary"} />} label={item.label} labelPlacement={labelPlacement} />
        })}
      </RadioGroup>
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </React.Fragment>
  )
}

const renderSelectField = ({ input: { value, onChange, onBlur, name, ...inputProps }, meta: { touched, error }, label, onValueChange, children, ...custom }) => {
  const hasError = Boolean(touched && error)

  let newValue = value

  return (
    <FormControl error>
      <Select
        name={name}
        autoWidth={true}
        error={hasError}
        value={newValue}
        {...inputProps}
        onChange={(event) => {
          onChange(event, event.target.value)
          if (onValueChange) {
            onValueChange(event.target.value)
          }
        }}
        onBlur={() => onBlur(value)}
        children={children}
        {...custom}
      />

      {hasError && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export { getSuggestions, renderCheckbox, renderInput, renderRadioGroup, renderSelectField, RenderTextField, RenderTextFieldWithPlusMinus, renderToggle }
