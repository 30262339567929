export const EMAIL_CHANGED = "email_changed"
export const PASSWORD_CHANGED = "password_changed"
export const COMPANYCODE_CHANGED = "companycode_changed"
export const COMPANY_CHANGE_TAB = "company_change_tab"
export const LOGIN_USER_SUCCESS = "login_user_success"
export const AUTH_USER_SUCCESS = "auth_user_success"
export const LOGIN_USER_SUCCESS2 = "login_user_success2"
export const FILTERED_CHANGED = "filtered_changed"
export const LOGIN_USER_FAIL = "login_user_fail"
export const LOGIN_USER = "login_user"
export const PASSWORD_LOADING_STATE = "password_loading_state"
export const LOGIN_USER_VALIDATION_FAIL = "login_user_validation_fail"
export const LOGOUT_USER_SUCCESS = "logout_user_success"
export const LOADING_SNACKBAR_STATE = "loading_snackbar_state"
export const AUTHENTICATE_KEY_GENERATED = "authenticate_key_generated"
export const RELOGIN_USER_SUCCESS = "relogin_user_success"
export const SET_CHECK_LOADING = "set_check_loading"
export const MENUFROMSESSION = "menu_from_session"
export const SET_COUNTRY_ALL_STATE_FOR_ONBOARDING = "set_country_all_state"
export const SETMENU = "setmenu"
export const ITEM_STATUS_UPDATE = "item_status_update"
export const FYYEARS = "fyyears"
export const REPORT_SERVICE_CLEAR = "report_service_clear"
export const UPLOAD_DOCUMENT_FAIL = "upload_document_fail"
export const UPLOAD_DOCUMENT_SUCCESS = "upload_document_success"
export const UTILITY_FILE_UPLOAD_PROGRESS = "utility_file_upload_progress"
export const CASH_BANK_DETAIL_SUCCESS = "cash_bank_detail_success"
export const REPORT_SERVICE_SUCCESS = "report_service_success"
export const GST_SET_DETAIL = "gst_set_detail"
export const REPORT_SERVICE_FAIL = "report_service_fail"
export const REPORT_SERVICE_START = "report_service_start"
export const REPORT_GENERATE_COLUMNS = "report_generate_columns"
export const REPORT_SEARCH_TEXT_UPDATE = "report_search_text_update"
export const GSTIN_SERVICE_UPDATED = "gstin_service updated"
export const GST_SERVICE_FILLED = "gst_service_filled"
export const GST_SERVICE_RESET = "gst_service_reset"
export const UPDATE_REPORT_DATA = "update_report_data"
export const UPDATE_OTHER_DETAIL = "update_other_detail"
export const SET_LAST_MENU_KEY = "set_last_menu_key"
export const SET_FILTER_DATA = "set_filter_data"
export const UPDATE_FILTER_DATA = "update_filter_data"
export const AUTH_LOADING_STATE = "auth_loading_state"
export const UPDATE_FORM_DRAWER_STATE = "update_form_drawer_state"
export const UPDATE_CONFIG_SETTING = "update_config_setting"
export const RESET_COMMON_REDUCER = "reset_common_reducer"
export const CLEAR_DROPDOWNUPDATED = "clear_dropdownupdated"
export const FORM_POST_UPDATE = "form_post_update"
export const PAGE_FORM_POST_UPDATE = "page_form_post_update"

export const SET_HOOK_FORM_DATA = "set_hook_form_data"
export const RESET_HOOK_FORM_DATA = "reset_hook_form_data"

export const SITE_SETTING_FIELD_SUCCESS = "site_setting_field_success"

export const USER_RIGHTS_UPDATED = "user_rights_updated"
export const REPORT_DATA_UPDATED = "report_data_updated"

export const LOADING_STATE = "loading_state"
export const POP_OVER_LOADING_STATE = "pop_over_loading_state"
export const PROGRESS_LOADING_STATE = "progress_loading_state"
export const CONFIRM_DIALOG = "confirm_dialog"
export const DIALOG_LOADING = "dialog_loading"
export const FORM_CONFIRM_DIALOG = "form_confirm_dialog"
export const FORM_DIALOG_LOADING = "form_dialog_loading"
export const DRAWER_CONFIRM_DIALOG = "drawer_confirm_dialog"
export const DRAWER_DIALOG_LOADING = "drawer_dialog_loading"

// export const AGENT_SERVICE_SUCCESS = "agent_service_success"
// export const AGENT_SERVICE_FAIL = "agent_service_fail"
// export const AGENT_SERVICE_START = "agent_service_start"
// export const AGENT_SERVICE_DELETED = "agent_service_deleted"
// export const AGENT_SERVICE_UPDATED = "agent_service_updated"
// export const AGENT_SERVICE_INSERTED = "agent_service_inserted"
// export const AGENT_SERVICE_CLEAR = "agent_service_clear"
// export const AGENT_SEARCH_TEXT_UPDATE = "agent_search_text_update"
// export const AGENT_CHANGE_TAB = "agent_change_tab"

export const PAGES_DATA_UPDATE = "pages_data_update"
export const CUSTOM_PAGE_DROPDOWN_SUCCESS = "custom_page_dropdown_success"

export const IMPORT_COUNTRY_SERVICE_START = "import_country_service_start"
export const IMPORT_COUNTRY_SERVICE_SUCCESS = "import_country_service_success"
export const IMPORT_COUNTRY_SERVICE_FAIL = "import_country_service_fail"
// export const IMPORT_COUNTRY_SEARCH_TEXT_UPDATE = "import_country_search_text_update"
// export const IMPORT_COUNTRY_INSERTED_SUCCESS = "import_country_inserted_success"
// export const IMPORT_COUNTRY_SERVICE_INSERTED = "import_country_service_inserted"
// export const IMPORT_COUNTRY_SERVICE_CLEAR = "import_country_service_clear"

export const BOT_SERVICE_SUCCESS = "bot_service_success"
export const BOT_SERVICE_FAIL = "bot_service_fail"
export const BOT_SERVICE_START = "bot_service_start"
export const BOT_SERVICE_DELETED = "bot_service_deleted"
export const BOT_SERVICE_UPDATED = "bot_service_updated"
export const BOT_SERVICE_INSERTED = "bot_service_inserted"
export const BOT_SERVICE_CLEAR = "bot_service_clear"

export const GLOBAL_SEARCH_TEXT_UPDATE = "global_search_text_update"
export const GLOBAL_SERVICE_SUCCESS = "global_service_success"
export const GLOBAL_SERVICE_FAIL = "global_service_fail"
export const GLOBAL_SERVICE_START = "global_service_start"
export const GLOBAL_SERVICE_DELETED = "global_service_deleted"
export const GLOBAL_SERVICE_UPDATED = "global_service_updated"
export const GLOBAL_SERVICE_INSERTED = "globalagent_service_inserted"
export const GLOBAL_SERVICE_CLEAR = "global_service_clear"
export const GLOBAL_CHANGE_TAB = "global_change_tab"

export const GLOBAL_SEARCH_POST_SERVICE_SUCCESS = "global_search_post_service_success"
export const GLOBAL_SEARCH_POST_SERVICE_FAIL = "global_search_post_service_success"
export const GLOBAL_SEARCH_SERVICE_START = "global_search_post_service_start"

// export const COMPANY_SERVICE_SUCCESS = "company_service_success"
// export const COMPANY_SERVICE_FAIL = "company_service_fail"
// export const COMPANY_SERVICE_START = "company_service_start"
// export const COMPANY_SERVICE_DELETED = "company_service_deleted"
// export const COMPANY_SERVICE_UPDATED = "company_service_updated"
// export const COMPANY_SERVICE_INSERTED = "company_service_inserted"
// export const COMPANY_SERVICE_CLEAR = "company_service_clear"

// export const COUNTRY_SERVICE_SUCCESS = "country_service_success"
// export const COUNTRY_SERVICE_FAIL = "country_service_fail"
// export const COUNTRY_SERVICE_START = "country_service_start"
// export const COUNTRY_SERVICE_CLEAR = "country_service_clear"
// export const COUNTRY_SEARCH_TEXT_UPDATE = "country_search_text_update"
// export const COUNTRY_SERVICE_INSERTED = "country_service_inserted"
// export const COUNTRY_SERVICE_DELETED = "country_service_deleted"
// export const COUNTRY_SERVICE_UPDATED = "country_service_updated"

// export const CUSTOMER_SERVICE_SUCCESS = "customer_service_success"
// export const CUSTOMER_SERVICE_FAIL = "customer_service_fail"
// export const CUSTOMER_SERVICE_START = "customer_service_start"
// export const CUSTOMER_REFRESH_START = "customer_refresh_start"
// export const CUSTOMER_FOOTER_REFRESH_START = "customer_footer_refresh_start"
// export const CUSTOMER_STORE_SUCCESS = "customer_store_success"
// export const CUSTOMER_LIST_FILTER = "customer_list_filter"
// export const CUSTOMER_SEARCH_TEXT_UPDATE = "customer_search_text_update"
// export const CUSTOMER_SERVICE_DELETED = "customer_service_deleted"
// export const CUSTOMER_SERVICE_UPDATED = "customer_service_updated"
// export const CUSTOMER_ADDRESS_UPDATED = "customer_address_updated"
// export const CUSTOMER_SERVICE_INSERTED = "customer_service_inserted"
// export const CUSTOMER_SERVICE_CLEAR = "customer_service_clear"
// export const CUSTOMER_CHANGE_TAB = "customer_change_tab"

// export const ADD_CUSTOMER_SUCCESS = "add_customer_success"
// export const ADD_CUSTOMER_FAIL = "add_customer_fail"

// export const CITY_SERVICE_SUCCESS = "city_service_success"
// export const CITY_SERVICE_FAIL = "city_service_fail"
// export const CITY_SERVICE_START = "city_service_start"
// export const CITY_SERVICE_DELETED = "city_service_deleted"
// export const CITY_SERVICE_UPDATED = "city_service_updated"
// export const CITY_SERVICE_INSERTED = "city_service_inserted"
// export const CITY_SERVICE_CLEAR = "city_service_clear"
// export const CITY_SEARCH_TEXT_UPDATE = "city_search_text_update"

export const RESELLER_SERVICE_SUCCESS = "reseller_service_success"
export const RESELLER_SERVICE_FAIL = "reseller_service_fail"
export const RESELLER_SERVICE_START = "reseller_service_start"
export const RESELLER_SERVICE_DELETED = "reseller_service_deleted"
export const RESELLER_SERVICE_UPDATED = "reseller_service_updated"
export const RESELLER_SERVICE_INSERTED = "reseller_service_inserted"
export const RESELLER_SERVICE_CLEAR = "reseller_service_clear"
export const RESELLER_SEARCH_TEXT_UPDATE = "reseller_search_text_update"

export const ENVELOPE_PRINTING_SERVICE_SUCCESS = "envelope_printing_service_success"
export const ENVELOPE_PRINTING_SERVICE_FAIL = "envelope_printing_service_fail"
export const ENVELOPE_PRINTING_SERVICE_START = "envelope_printing_service_start"
export const ENVELOPE_PRINTING_SERVICE_DELETED = "envelope_printing_service_deleted"
export const ENVELOPE_PRINTING_SERVICE_UPDATED = "envelope_printing_service_updated"
export const ENVELOPE_PRINTING_SERVICE_INSERTED = "envelope_printing_service_inserted"
export const ENVELOPE_PRINTING_SERVICE_CLEAR = "envelope_printing_service_clear"
export const ENVELOPE_PRINTING_SEARCH_TEXT_UPDATE = "envelope_printing_search_text_update"

export const CHEQUE_PRINTING_SERVICE_SUCCESS = "cheque_printing_service_success"
export const CHEQUE_PRINTING_SERVICE_FAIL = "cheque_printing_service_fail"
export const CHEQUE_PRINTING_SERVICE_START = "cheque_printing_service_start"
export const CHEQUE_PRINTING_SERVICE_DELETED = "cheque_printing_service_deleted"
export const CHEQUE_PRINTING_SERVICE_UPDATED = "cheque_printing_service_updated"
export const CHEQUE_PRINTING_SERVICE_INSERTED = "cheque_printing_service_inserted"
export const CHEQUE_PRINTING_SERVICE_CLEAR = "cheque_printing_service_clear"
export const CHEQUE_PRINTING_SEARCH_TEXT_UPDATE = "cheque_printing_search_text_update"

export const SHIPPING_SERVICE_SUCCESS = "shipping_service_success"
export const SHIPPING_SERVICE_FAIL = "shipping_service_fail"
export const SHIPPING_SERVICE_START = "shipping_service_start"
export const SHIPPING_SERVICE_DELETED = "shipping_service_deleted"
export const SHIPPING_SERVICE_UPDATED = "shipping_service_updated"
export const SHIPPING_SERVICE_INSERTED = "shipping_service_inserted"
export const SHIPPING_SERVICE_CLEAR = "shipping_service_clear"
export const SHIPPING_SEARCH_TEXT_UPDATE = "shipping_search_text_update"

export const PAYMENT_UTILITY_SERVICE_SUCCESS = "payment_utility_service_success"
export const PAYMENT_UTILITY_SERVICE_FAIL = "payment_utility_service_fail"
export const PAYMENT_UTILITY_SERVICE_START = "payment_utility_service_start"
export const PAYMENT_UTILITY_SERVICE_DELETED = "payment_utility_service_deleted"
export const PAYMENT_UTILITY_SERVICE_UPDATED = "payment_utility_service_updated"
export const PAYMENT_UTILITY_SERVICE_INSERTED = "payment_utility_service_inserted"
export const PAYMENT_UTILITY_SERVICE_CLEAR = "payment_utility_service_clear"
export const PAYMENT_UTILITY_SEARCH_TEXT_UPDATE = "payment_utility_search_text_update"

export const GSTMYSTORE_USER_SERVICE_SUCCESS = "getmystore_user_service_success"
export const GSTMYSTORE_USER_SERVICE_FAIL = "getmystore_user_service_fail"
export const GSTMYSTORE_USER_SERVICE_START = "getmystore_user_service_start"
// export const GSTMYSTORE_USER_SERVICE_DELETED = "getmystore_user_service_deleted"
// export const GSTMYSTORE_USER_SERVICE_UPDATED = "getmystore_user_service_updated"
export const GSTMYSTORE_USER_SERVICE_INSERTED = "getmystore_user_service_inserted"
export const GSTMYSTORE_USER_SERVICE_CLEAR = "getmystore_user_service_clear"
export const GSTMYSTORE_USER_SEARCH_TEXT_UPDATE = "getmystore_user_search_text_update"
export const CATALOGUE_ITEM_DELETED = "catalogue_item_deleted"
export const CATALOGUE_ITEM_INSERTED = "catalogue_item_inserted"
export const CATALOGUE_LVEUSERS_UPDATED = "catalogue_liveusers_updated"

export const ORGANIZATION_SERVICE_SUCCESS = "organization_service_success"
export const ORGANIZATION_SERVICE_FAIL = "organization_service_fail"
export const ORGANIZATION_SERVICE_START = "organization_service_start"
export const ORGANIZATION_SERVICE_CLEAR = "organization_service_clear"
export const ORGANIZATION_SERVICE_DELETED = "organization_service_deleted"
export const ORGANIZATION_SERVICE_INSERTED = "organization_service_inserted"
export const NEW_ORGANIZATION_INSERTED = "new_organization_inserted"
export const CHANGE_PASSWORD_SERVICE_UPDATED = "change_password_service_updated"

export const MERGE_ACCOUNT_SERVICE_MERGED = "merge_account_service_merged"

export const USER_HISTORY_REPORT_SERVICE = "user_history_report_service"

export const MERGE_ITEM_SERVICE_MERGED = "merge_item_service_merged"

export const USER_HISTORY_REPORT_SERVICE_SUCCESS = "user_history_report_service_success"
export const USER_HISTORY_REPORT_SERVICE_FAIL = "user_history_report_service_fail"
export const USER_HISTORY_REPORT_SERVICE_START = "user_history_report_service_start"
export const USER_HISTORY_REPORT_SERVICE_CLEAR = "user_history_report_service_clear"

export const DROPDOWN_ITEM_INSERTED = "dropdown_item_inserted"
export const DROPDOWN_ITEM_UPDATED = "dropdown_item_updated"
export const DROPDOWN_ITEM_DELETED = "dropdown_item_deleted"
export const DROPDOWN_INSERTED = "dropdown_inserted"

export const SINGLE_SMS_SERVICE_SUCCESS = "single_sms_service_success"
export const SINGLE_SMS_ALL_CONTACT_SERVICE_SUCCESS = "single_sms_all_contact_service_success"
export const SINGLE_SMS_CUSTOM_SERVICE_SUCCESS = "single_sms_custom_service_success"
export const SINGLE_SMS_TEMPLATE_SERVICE_SUCCESS = "single_sms_template_service_success"

export const GROUP_SMS_SEND_SERVICE_SUCCESS = "group_sms_send_service_success"
export const GROUP_SMS_SEND_SERVICE_FAIL = "group_sms_send_service_fail"
export const GROUP_SMS_SEND_SERVICE_START = "group_sms_send_service_start"
export const GROUP_SMS_SEND_SERVICE_INSERTED = "group_sms_send_service_inserted"
export const GROUP_SMS_SEND_SERVICE_UPDATED = "group_sms_send_service_updated"
export const GROUP_SMS_SEND_SERVICE_DELETED = "group_sms_send_service_deleted"

export const GROUP_SMS_SERVICE_SUCCESS = "group_sms_service_success"
export const GROUP_SMS_SERVICE_FAIL = "group_sms_service_fail"
export const GROUP_SMS_SERVICE_START = "group_sms_service_start"
export const GROUP_SMS_SERVICE_INSERTED = "group_sms_service_inserted"
export const GROUP_SMS_SERVICE_UPDATED = "group_sms_service_updated"
export const GROUP_SMS_SERVICE_DELETED = "group_sms_service_deleted"

export const GROUP_SMS_CONTACT_SERVICE_SUCCESS = "group_sms_contact_service_success"
export const GROUP_SMS_CONTACT_SERVICE_FAIL = "group_sms_contact_service_fail"
export const GROUP_SMS_CONTACT_SERVICE_START = "group_sms_contact_service_start"
export const GROUP_SMS_CONTACT_SERVICE_INSERTED = "group_sms_contact_service_inserted"
export const GROUP_SMS_CONTACT_SERVICE_UPDATED = "group_sms_contact_service_updated"
export const GROUP_SMS_CONTACT_SERVICE_DELETED = "group_sms_contact_service_deleted"

export const WIRETRANSFER_SENDER_SERVICE_SUCCESS = "wire_transfer_sender_service_success"
export const WIRETRANSFER_SENDER_SERVICE_FAIL = "wire_transfer_sender_service_fail"
export const WIRETRANSFER_SENDER_SERVICE_START = "wire_transfer_sender_service_start"
export const WIRETRANSFER_SENDER_SERVICE_INSERTED = "wire_transfer_sender_service_inserted"
export const WIRETRANSFER_SENDER_SERVICE_UPDATED = "wire_transfer_sender_service_updated"
export const WIRETRANSFER_SENDER_SERVICE_DELETED = "wire_transfer_sender_service_deleted"
export const WIRETRANSFER_SENDER_SEARCH_TEXT_UPDATE = "wire_transfer_sender_search_text_update"

export const WIRETRANSFER_BENEFICIARY_SERVICE_SUCCESS = "wire_transfer_beneficiary_service_success"
export const WIRETRANSFER_BENEFICIARY_SERVICE_FAIL = "wire_transfer_beneficiary_service_fail"
export const WIRETRANSFER_BENEFICIARY_SERVICE_START = "wire_transfer_beneficiary_service_start"
export const WIRETRANSFER_BENEFICIARY_SERVICE_INSERTED = "wire_transfer_beneficiary_service_inserted"
export const WIRETRANSFER_BENEFICIARY_SERVICE_UPDATED = "wire_transfer_beneficiary_service_updated"
export const WIRETRANSFER_BENEFICIARY_SERVICE_DELETED = "wire_transfer_beneficiary_service_deleted"
export const WIRETRANSFER_BENEFICIARY_SEARCH_TEXT_UPDATE = "wire_transfer_beneficiary_search_text_update"

export const WIRETRANSFER_SERVICE_SUCCESS = "wire_transfer_service_success"
export const WIRETRANSFER_SERVICE_FAIL = "wire_transfer_service_fail"
export const WIRETRANSFER_SERVICE_START = "wire_transfer_service_start"
export const WIRETRANSFER_SERVICE_INSERTED = "wire_transfer_service_inserted"
export const WIRETRANSFER_SERVICE_UPDATED = "wire_transfer_service_updated"
export const WIRETRANSFER_SERVICE_DELETED = "wire_transfer_service_deleted"
export const WIRETRANSFER_SEARCH_TEXT_UPDATE = "wire_transfer_search_text_update"

export const ADD_SEND_SMS_SERVICE_SUCCESS = "add_send_sms_service_success"
export const ADD_SEND_SMS_SERVICE_FAIL = "add_send_sms_service_fail"
export const ADD_SEND_SMS_SERVICE_START = "add_send_sms_service_start"
export const ADD_SEND_SMS_SERVICE_INSERTED = "add_send_sms_service_inserted"
export const ADD_SEND_SMS_SERVICE_UPDATED = "add_send_sms_service_updated"
export const ADD_SEND_SMS_SERVICE_DELETED = "add_send_sms_service_deleted"

// export const IMPORT_TAX_SERVICE_INSERTED = "import_tax_service_inserted"

export const IMPORT_ACCOUNT_SERVICE_INSERTED = "import_account_service_inserted"

export const IMPORT_UNIT_SERVICE_INSERTED = "import_unit_service_inserted"

// export const ITEM_SERVICE_SUCCESS = "item_service_success"
// export const ITEM_SERVICE_FAIL = "item_service_fail"
// export const ITEM_SERVICE_START = "item_service_start"
// export const ITEM_REFRESH_START = "item_refresh_start"
// export const ITEM_FOOTER_REFRESH_START = "item_footer_refresh_start"
// export const ITEM_STORE_SUCCESS = "item_store_success"
// export const ITEM_LIST_FILTER = "item_list_filter"
// export const ITEM_SEARCH_TEXT_UPDATE = "item_search_text_update"
// export const ADD_ITEM_SUCCESS = "add_item_success"
// export const ADD_ITEM_FAIL = "add_item_fail"
// export const ITEM_SERVICE_DELETED = "item_service_deleted"
// export const ITEM_SERVICE_UPDATED = "item_service_updated"
// export const ITEM_SERVICE_INSERTED = "item_service_inserted"
// export const ITEM_SERVICE_CLEAR = "item_service_clear"
// export const ITEM_CHANGE_TAB = "item_change_tab"
export const PRODUCT_BATCH_SERVICE_SUCCESS = "product_batch_service_success"
export const PRODUCT_SERIAL_SERVICE_SUCCESS = "product_serial_service_success"

// export const ITEM_GROUP_SERVICE_SUCCESS = "item_group_service_success"
// export const ITEM_GROUP_SERVICE_FAIL = "item_group_service_fail"
// export const ITEM_GROUP_SERVICE_START = "item_group_service_start"
// export const ITEM_GROUP_SERVICE_DELETED = "item_group_service_deleted"
// export const ITEM_GROUP_SERVICE_UPDATED = "item_group_service_updated"
// export const ITEM_GROUP_SERVICE_INSERTED = "item_group_service_inserted"
// export const ITEM_GROUP_SERVICE_CLEAR = "item_group_service_clear"
// export const ITEM_GROUP_SEARCH_TEXT_UPDATE = "item_group_search_text_update"

// export const PARTY_GROUP_SERVICE_SUCCESS = "party_group_service_success"
// export const PARTY_GROUP_SERVICE_FAIL = "party_group_service_fail"
// export const PARTY_GROUP_SERVICE_START = "party_group_service_start"
// export const PARTY_GROUP_SERVICE_DELETED = "party_group_service_deleted"
// export const PARTY_GROUP_SERVICE_UPDATED = "party_group_service_updated"
// export const PARTY_GROUP_SERVICE_INSERTED = "party_group_service_inserted"
// export const PARTY_GROUP_SERVICE_CLEAR = "party_group_service_clear"
// export const PARTY_GROUP_SEARCH_TEXT_UPDATE = "party_group_search_text_update"

// export const PRODUCT_SUB_GROUP_SERVICE_SUCCESS = "product_sub_group_service_success"
// export const PRODUCT_SUB_GROUP_SERVICE_FAIL = "product_sub_group_service_fail"
// export const PRODUCT_SUB_GROUP_SERVICE_START = "product_sub_group_service_start"
// export const PRODUCT_SUB_GROUP_SERVICE_DELETED = "product_sub_group_service_deleted"
// export const PRODUCT_SUB_GROUP_SERVICE_UPDATED = "product_sub_group_service_updated"
// export const PRODUCT_SUB_GROUP_SERVICE_INSERTED = "product_sub_group_service_inserted"
// export const PRODUCT_SUB_GROUP_SERVICE_CLEAR = "product_sub_group_service_clear"
// export const PRODUCT_SUB_GROUP_SEARCH_TEXT_UPDATE = "product_sub_group_search_text_update"

// export const PRODUCT_SUB_GROUP_LEVEL2_SERVICE_SUCCESS = "product_sub_group_level2_service_success"
// export const PRODUCT_SUB_GROUP_LEVEL2_SERVICE_FAIL = "product_sub_group_level2_service_fail"
// export const PRODUCT_SUB_GROUP_LEVEL2_SERVICE_START = "product_sub_group_level2_service_start"
// export const PRODUCT_SUB_GROUP_LEVEL2_SERVICE_DELETED = "product_sub_group_level2_service_deleted"
// export const PRODUCT_SUB_GROUP_LEVEL2_SERVICE_UPDATED = "product_sub_group_level2_service_updated"
// export const PRODUCT_SUB_GROUP_LEVEL2_SERVICE_INSERTED = "product_sub_group_level2_service_inserted"
// export const PRODUCT_SUB_GROUP_LEVEL2_SERVICE_CLEAR = "product_sub_group_level2_service_clear"
// export const PRODUCT_SUB_GROUP_LEVEL2_SEARCH_TEXT_UPDATE = "product_sub_group_level2_search_text_update"

// export const SUB_GROUP_SERVICE_SUCCESS = "sub_group_service_success"
// export const SUB_GROUP_SERVICE_FAIL = "sub_group_service_fail"
// export const SUB_GROUP_SERVICE_START = "sub_group_service_start"
// export const SUB_GROUP_SERVICE_DELETED = "sub_group_service_deleted"
// export const SUB_GROUP_SERVICE_UPDATED = "sub_group_service_updated"
// export const SUB_GROUP_SERVICE_INSERTED = "sub_group_service_inserted"
// export const SUB_GROUP_SERVICE_CLEAR = "sub_group_service_clear"
// export const SUB_GROUP_SEARCH_TEXT_UPDATE = "sub_group_search_text_update"

export const PROCESS_TRANSFER_SERVICE_SUCCESS = "process_transfer_service_success"
export const PROCESS_TRANSFER_SERVICE_FAIL = "process_transfer_service_fail"
export const PROCESS_TRANSFER_SERVICE_START = "process_transfer_service_start"
export const PROCESS_TRANSFER_SERVICE_DELETED = "process_transfer_service_deleted"
export const PROCESS_TRANSFER_SERVICE_UPDATED = "process_transfer_service_updated"
export const PROCESS_TRANSFER_SERVICE_INSERTED = "process_transfer_service_inserted"
export const PROCESS_TRANSFER_SERVICE_CLEAR = "process_transfer_service_clear"
export const PROCESS_TRANSFER_SEARCH_TEXT_UPDATE = "process_transfer_search_text_update"

// export const MERGE_PACKING_SLIP_SERVICE_SUCCESS = "merge_packing_slip_service_success"
// export const MERGE_PACKING_SLIP_SERVICE_FAIL = "merge_packing_slip_service_fail"
// export const MERGE_PACKING_SLIP_SERVICE_START = "merge_packing_slip_service_start"
// export const MERGE_PACKING_SLIP_SERVICE_DELETED = "merge_packing_slip_service_deleted"
// export const MERGE_PACKING_SLIP_SERVICE_UPDATED = "merge_packing_slip_service_updated"
// export const MERGE_PACKING_SLIP_SERVICE_INSERTED = "merge_packing_slip_service_inserted"
// export const MERGE_PACKING_SLIP_SERVICE_CLEAR = "merge_packing_slip_service_clear"
// export const MERGE_PACKING_SLIP_SEARCH_TEXT_UPDATE = "merge_packing_slip_search_text_update"

export const PROCESS_SERVICE_SUCCESS = "process_service_success"
export const PROCESS_SERVICE_FAIL = "process_service_fail"
export const PROCESS_SERVICE_START = "process_service_start"
export const PROCESS_SERVICE_DELETED = "process_service_deleted"
export const PROCESS_SERVICE_UPDATED = "process_service_updated"
export const PROCESS_SERVICE_INSERTED = "process_service_inserted"
export const PROCESS_SERVICE_CLEAR = "process_service_clear"
export const PROCESS_SEARCH_TEXT_UPDATE = "process_search_text_update"

// export const OUTWARD_SERVICE_SUCCESS = "outward_service_success"
// export const OUTWARD_SERVICE_FAIL = "outward_service_fail"
// export const OUTWARD_SERVICE_START = "outward_service_start"
// export const OUTWARD_SERVICE_DELETED = "outward_service_deleted"
// export const OUTWARD_SERVICE_UPDATED = "outward_service_updated"
// export const OUTWARD_SERVICE_INSERTED = "outward_service_inserted"
// export const OUTWARD_SERVICE_CLEAR = "outward_service_clear"
// export const OUTWARD_SEARCH_TEXT_UPDATE = "outward_search_text_update"
// export const OUTWARD_FLAG_UPDATED = "outward_flag_updated"
// export const OUTWARD_SERVICE_CONVERTED = "outward_service_converted"
// export const OUTWARD_E_WAY_BILL_UPDATE = "outward_e_way_bill_update"

// export const INWARD_SERVICE_SUCCESS = "inward_service_success"
// export const INWARD_SERVICE_FAIL = "inward_service_fail"
// export const INWARD_SERVICE_START = "inward_service_start"
// export const INWARD_SERVICE_DELETED = "inward_service_deleted"
// export const INWARD_SERVICE_UPDATED = "inward_service_updated"
// export const INWARD_SERVICE_INSERTED = "inward_service_inserted"
// export const INWARD_SERVICE_CLEAR = "inward_service_clear"
// export const INWARD_SEARCH_TEXT_UPDATE = "inward_search_text_update"
// export const INWARD_FLAG_UPDATED = "inward_flag_updated"
// export const INWARD_SERVICE_CONVERTED = "inward_service_converted"
// export const INWARD_E_WAY_BILL_UPDATE = "inward_e_way_bill_update"

/*
export const STOCK_TRANSFER_SERVICE_SUCCESS = "stock_transfer_service_success"
export const STOCK_TRANSFER_SERVICE_FAIL = "stock_transfer_service_fail"
export const STOCK_TRANSFER_SERVICE_START = "stock_transfer_service_start"
export const STOCK_TRANSFER_SERVICE_DELETED = "stock_transfer_service_deleted"
export const STOCK_TRANSFER_SERVICE_UPDATED = "stock_transfer_service_updated"
export const STOCK_TRANSFER_SERVICE_INSERTED = "stock_transfer_service_inserted"
export const STOCK_TRANSFER_SERVICE_CLEAR = "stock_transfer_service_clear"
export const STOCK_TRANSFER_SEARCH_TEXT_UPDATE = "stock_transfer_search_text_update"
*/

// export const GODOWN_STOCK_TRANSFER_SERVICE_SUCCESS = "godown_stock_transfer_service_success"
// export const GODOWN_STOCK_TRANSFER_SERVICE_FAIL = "godown_stock_transfer_service_fail"
// export const GODOWN_STOCK_TRANSFER_SERVICE_START = "godown_stock_transfer_service_start"
// export const GODOWN_STOCK_TRANSFER_SERVICE_DELETED = "godown_stock_transfer_service_deleted"
// export const GODOWN_STOCK_TRANSFER_SERVICE_UPDATED = "godown_stock_transfer_service_updated"
// export const GODOWN_STOCK_TRANSFER_SERVICE_INSERTED = "godown_stock_transfer_service_inserted"
// export const GODOWN_STOCK_TRANSFER_SERVICE_CLEAR = "godown_stock_transfer_service_clear"
// export const GODOWN_STOCK_TRANSFER_SEARCH_TEXT_UPDATE = "godown_stock_transfer_search_text_update"

export const PARTYPRICE_SERVICE_SUCCESS = "partyprice_service_success"
export const PARTYPRICE_SERVICE_FAIL = "partyprice_service_fail"
export const PARTYPRICE_SERVICE_START = "partyprice_service_start"
export const PARTYPRICE_SERVICE_CLEAR = "partyprice_service_clear"
export const PARTYPRICE_LOCAL_SEARCH = "partyprice_local_search"
export const PARTYPRICE_SERVICE_UPDATED = "partyprice_service_updated"
export const PARTYPRICE_SERVICE_INSERTED = "partyprice_service_inserted"

export const PARTYGROUPPRICE_SERVICE_SUCCESS = "partygroupprice_service_success"
export const PARTYGROUPPRICE_SERVICE_FAIL = "partygroupprice_service_fail"
export const PARTYGROUPPRICE_SERVICE_START = "partygroupprice_service_start"
export const PARTYGROUPPRICE_SERVICE_CLEAR = "partygroupprice_service_clear"
export const PARTYGROUPPRICE_LOCAL_SEARCH = "partygroupprice_local_search"
export const PARTYGROUPPRICE_SERVICE_UPDATED = "partygroupprice_service_updated"

export const CONTACTDETAIL_SERVICE_SUCCESS = "contactdetail_service_success"
export const CONTACTDETAIL_SERVICE_FAIL = "contactdetail_service_fail"
export const CONTACTDETAIL_SERVICE_START = "contactdetail_service_start"

export const USERRIGHTS_SERVICE_SUCCESS = "userrights_service_success"
export const USERRIGHTS_SERVICE_FAIL = "userrights_service_fail"
export const USERRIGHTS_SERVICE_START = "userrights_service_start"
export const USERRIGHTS_CHANGE = "userrights_change"
export const USERRIGHTS_SERVICE_CLEAR = "userrights_service_clear"

export const SET_EXPAND_VALUE = "set_expand_value"

// export const USER_SERVICE_SUCCESS = "user_service_success"
// export const USER_SERVICE_FAIL = "user_service_fail"
// export const USER_SERVICE_START = "user_service_start"
// export const USER_SERVICE_DELETED = "user_service_deleted"
// export const USER_SERVICE_UPDATED = "user_service_updated"
// export const USER_SERVICE_INSERTED = "user_service_inserted"
// export const USER_SERVICE_CLEAR = "user_service_clear"
// export const USER_SEARCH_TEXT_UPDATE = "user_search_text_update"

export const UNIT_SERVICE_SUCCESS = "unit_service_success"
export const UNIT_SERVICE_FAIL = "unit_service_fail"
export const UNIT_SERVICE_START = "unit_service_start"
export const UNIT_SERVICE_DELETED = "unit_service_deleted"
export const UNIT_SERVICE_UPDATED = "unit_service_updated"
export const UNIT_SERVICE_INSERTED = "unit_service_inserted"
export const UNIT_SERVICE_CLEAR = "unit_service_clear"
export const UNIT_LOCAL_SEARCH = "unit_local_search"

export const BILLING_SERVICE_SUCCESS = "billing_service_success"
export const BILLING_SERVICE_FAIL = "billing_service_fail"
export const BILLING_SERVICE_START = "billing_service_start"
export const BILLING_SERVICE_DELETED = "billing_service_deleted"
export const BILLING_SERVICE_UPDATED = "billing_service_updated"
export const BILLING_SERVICE_INSERTED = "billing_service_inserted"
export const BILLING_SERVICE_CLEAR = "billing_service_clear"
export const BILLING_LOCAL_SEARCH = "billing_local_search"

export const FORM_DEP_SERVICE_SUCCESS = "form_dep_service_success"
export const FORM_DEP_SERVICE_SUCCESS_WITHOUTLOADING = "form_dep_service_success_withoutloading"

export const FORM_DEP_SERVICE_FAIL = "form_dep_service_fail"
export const FORM_DEP_SERVICE_START = "form_dep_service_start"
export const FORM_DEP_SERVICE_CLEAR = "form_dep_service_clear"

export const FORM_POST_SERVICE_SUCCESS = "form_post_service_success"
export const FORM_POST_SERVICE_FAIL = "form_post_service_fail"
export const FORM_POST_SERVICE_START = "form_post_service_start"
export const FORM_POST_SERVICE_CLEAR = "form_post_service_clear"

export const PAGE_FORM_POST_SERVICE_SUCCESS = "page_form_post_service_success"
export const PAGE_FORM_POST_SERVICE_FAIL = "page_form_post_service_fail"
export const PAGE_FORM_POST_SERVICE_START = "page_form_post_service_start"
export const PAGE_FORM_POST_SERVICE_CLEAR = "page_form_post_service_clear"

export const UTILITY_POST_SERVICE_SUCCESS = "utility_post_service_success"
export const UTILITY_POST_SERVICE_FAIL = "utility_post_service_success"
export const UTILITY_POST_SERVICE_START = "utility_post_service_start"

export const POST_FILTER_SERVICE_SUCCESS = "post_filter_service_success"
export const POST_FILTER_SERVICE_FAIL = "post_filter_service_fail"
export const POST_FILTER_SERVICE_START = "post_filter_service_start"
export const ADD_RECENT_ITEM = "ADD_RECENT_ITEM"
export const ADD_RECENT_ALLACCOUNT = "ADD_RECENT_ALLACCOUNT"
// export const PURCHASERETURN_SERVICE_SUCCESS = "purchasereturn_service_success"
// export const PURCHASERETURN_SERVICE_FAIL = "purchasereturn_service_fail"
// export const PURCHASERETURN_SERVICE_START = "purchasereturn_service_start"
// export const PURCHASERETURN_SERVICE_DELETED = "purchasereturn_service_deleted"
// export const PURCHASERETURN_SERVICE_UPDATED = "purchasereturn_service_updated"
// export const PURCHASERETURN_SERVICE_INSERTED = "purchasereturn_service_inserted"
// export const PURCHASERETURN_SEARCH_TEXT_UPDATE = "purchasereturn_search_text_update"
// export const PURCHASERETURN_E_WAY_BILL_UPDATE = "purchasereturn_e_way_bill_update"

// export const STATE_SERVICE_SUCCESS = "state_service_success"
// export const STATE_SERVICE_FAIL = "state_service_fail"
// export const STATE_SERVICE_START = "state_service_start"
// export const STATE_SERVICE_DELETED = "state_service_deleted"
// export const STATE_SERVICE_UPDATED = "state_service_updated"
// export const STATE_SERVICE_INSERTED = "state_service_inserted"
// export const STATE_SERVICE_CLEAR = "state_service_clear"
// export const STATE_SEARCH_TEXT_UPDATE = "state_search_text_update"

export const TALLY_DATA_SERVICE_SUCCESS = "tally_data_service_success"
export const TALLY_DATA_SERVICE_FAIL = "tally_data_service_fail"
export const TALLY_DATA_SERVICE_START = "tally_data_service_start"
export const TALLY_DATA_SERVICE_CLEAR = "tally_data_service_clear"

// export const CONFIGURABLEPRODUCT_SERVICE_SUCCESS = "manageconfigurableproduct_service_success"
// export const CONFIGURABLEPRODUCT_SERVICE_FAIL = "manageconfigurableproduct_service_fail"
// export const CONFIGURABLEPRODUCT_SERVICE_START = "manageconfigurableproduct_service_start"
// export const CONFIGURABLEPRODUCT_SERVICE_DELETED = "manageconfigurableproduct_service_deleted"
// export const CONFIGURABLEPRODUCT_SERVICE_UPDATED = "manageconfigurableproduct_service_updated"
// export const CONFIGURABLEPRODUCT_SERVICE_INSERTED = "manageconfigurableproduct_service_inserted"
// export const CONFIGURABLEPRODUCT_SERVICE_CLEAR = "manageconfigurableproduct_service_clear"
// export const CONFIGURABLEPRODUCT_SEARCH_TEXT_UPDATE = "manageconfigurableproduct_search_text_update"

// export const BUNDLEPRODUCT_SERVICE_SUCCESS = "bundleproduct_service_success"
// export const BUNDLEPRODUCT_SERVICE_FAIL = "bundleproduct_service_fail"
// export const BUNDLEPRODUCT_SERVICE_START = "bundleproduct_service_start"
// export const BUNDLEPRODUCT_SERVICE_DELETED = "bundleproduct_service_deleted"
// export const BUNDLEPRODUCT_SERVICE_UPDATED = "bundleproduct_service_updated"
// export const BUNDLEPRODUCT_SERVICE_INSERTED = "bundleproduct_service_inserted"
// export const BUNDLEPRODUCT_SERVICE_CLEAR = "bundleproduct_service_clear"
// export const BUNDLEPRODUCT_SEARCH_TEXT_UPDATE = "bundleproduct_search_text_update"

export const CATALOGUE_SERVICE_SUCCESS = "catalogue_service_success"
export const CATALOGUE_SERVICE_FAIL = "catalogue_service_fail"
export const CATALOGUE_SERVICE_START = "catalogue_service_start"
export const CATALOGUE_SERVICE_DELETED = "catalogue_service_deleted"
export const CATALOGUE_SERVICE_UPDATED = "catalogue_service_updated"
export const CATALOGUE_SERVICE_INSERTED = "catalogue_service_inserted"
export const CATALOGUE_SERVICE_CLEAR = "catalogue_service_clear"
export const CATALOGUE_SEARCH_TEXT_UPDATE = "catalogue_search_text_update"

// export const CATALOGUE_USER_SERVICE_SUCCESS = "catalogue_user_service_success"
// export const CATALOGUE_USER_SERVICE_FAIL = "catalogue_user_service_fail"
// export const CATALOGUE_USER_SERVICE_START = "catalogue_user_service_start"
// export const CATALOGUE_USER_SERVICE_CLEAR = "catalogue_user_service_clear"
// export const CATALOGUE_USER_SEARCH_TEXT_UPDATE = "catalogue_user_search_text_update"

// export const RECORDEXPENSE_SERVICE_SUCCESS = "recordexpense_service_success"
// export const RECORDEXPENSE_SERVICE_FAIL = "recordexpense_service_fail"
// export const RECORDEXPENSE_SERVICE_START = "recordexpense_service_start"
// export const RECORDEXPENSE_SERVICE_DELETED = "recordexpense_service_deleted"
// export const RECORDEXPENSE_SERVICE_UPDATED = "recordexpense_service_updated"
// export const RECORDEXPENSE_SERVICE_INSERTED = "recordexpense_service_inserted"
// export const RECORDEXPENSE_SERVICE_CLEAR = "recordexpense_service_clear"
// export const RECORDEXPENSE_SEARCH_TEXT_UPDATE = "recordexpense_search_text_update"

// export const ACCOUNT_SERVICE_SUCCESS = "account_service_success"
// export const ACCOUNT_SERVICE_FAIL = "account_service_fail"
// export const ACCOUNT_SERVICE_START = "account_service_start"
// export const ACCOUNT_SERVICE_DELETED = "account_service_deleted"
// export const ACCOUNT_SERVICE_UPDATED = "account_service_updated"
// export const ACCOUNT_SERVICE_INSERTED = "account_service_inserted"
// export const ACCOUNT_SERVICE_CLEAR = "account_service_clear"
// export const ACCOUNT_SEARCH_TEXT_UPDATE = "account_search_text_update"

export const DASHBOARD_SERVICE_SUCCESS = "dashboard_service_success"
export const DASHBOARD_SERVICE_FAIL = "dashboard_service_fail"
export const DASHBOARD_SERVICE_START = "dashboard_service_start"

export const PICKER_GST_TREATMENT_OPEN = "picker_gst_treatment_open"
export const PICKER_GST_TREATMENT_CLOSE = "picker_gst_treatment_close"

export const DELETE_ITEM_SUCCESS = "delete_item_success"
export const DELETE_ITEM_FAIL = "delete_item_fail"

export const FORM_FIELD_UPDATE = "form_field_update"

// export const SUPPLIER_SERVICE_SUCCESS = "supplier_service_success"
// export const SUPPLIER_SERVICE_FAIL = "supplier_service_fail"
// export const SUPPLIER_SERVICE_START = "supplier_service_start"
// export const SUPPLIER_REFRESH_START = "supplier_refresh_start"
// export const SUPPLIER_FOOTER_REFRESH_START = "supplier_footer_refresh_start"
// export const SUPPLIER_LIST_FILTER = "supplier_list_filter"
// export const SUPPLIER_SEARCH_TEXT_UPDATE = "supplier_search_text_update"
// export const SUPPLIER_STORE_SUCCESS = "supplier_store_success"
// export const SUPPLIER_SERVICE_DELETED = "supplier_service_deleted"
// export const SUPPLIER_SERVICE_UPDATED = "supplier_service_updated"
// export const SUPPLIER_SERVICE_INSERTED = "supplier_service_inserted"
// export const SUPPLIER_SERVICE_CLEAR = "supplier_service_clear"
// export const SUPPLIER_CHANGE_TAB = "supplier_change_tab"

// export const SALESINVOICE_SERVICE_SUCCESS = "salesinvoice_service_success"
// export const SALESINVOICE_SERVICE_FAIL = "salesinvoice_service_fail"
// export const SALESINVOICE_SERVICE_START = "salesinvoice_service_start"
// export const SALESINVOICE_REFRESH_START = "salesinvoice_refresh_start"
// export const SALESINVOICE_FOOTER_REFRESH_START = "salesinvoice_footer_refresh_start"
// export const SALESINVOICE_LIST_FILTER = "salesinvoice_list_filter"
// export const SALESINVOICE_SEARCH_TEXT_UPDATE = "salesinvoice_search_text_update"
// export const SALESINVOICE_STORE_SUCCESS = "salesinvoice_store_success"
// export const SAVE_FILTER_DATA_INVOICE = "save_filter_data_invoice"
// export const SALESINVOICE_SERVICE_DELETED = "salesinvoice_service_deleted"
// export const SALESINVOICE_SERVICE_UPDATED = "salesinvoice_service_updated"
// export const SALESINVOICE_SERVICE_INSERTED = "salesinvoice_service_inserted"
// export const SALESINVOICE_SERVICE_CONVERTED = "salesinvoice_service_converted"
// export const SALESINVOICE_SERVICE_CLEAR = "salesinvoice_service_clear"
export const ESTIMATE_SERVICE_CONVERTED = "estimate_service_converted"

// export const SALESINVOICE_E_INVOICE_UPDATE = "salesinvoice_e_invoice_update"
// export const SALESINVOICE_E_WAY_BILL_UPDATE = "salesinvoice_e_way_bill_update"

// export const SALESRETURN_E_INVOICE_UPDATE = "salesreturn_e_invoice_update"
export const PURCHASERETURN_E_INVOICE_UPDATE = "purchasereturn_e_invoice_update"

export const E_INVOICE_UPDATE = "e_invoice_update"
export const E_WAY_BILL_UPDATE = "e_way_bill_update"
export const BILL_SERVICE_SUCCESS = "bill_service_success"
export const BILL_SERVICE_FAIL = "bill_service_fail"
export const PUR_BILL_SERVICE_SUCCESS = "pur_bill_service_success"
export const PUR_BILL_SERVICE_FAIL = "pur_bill_service_fail"

// export const PURCHASEINVOICE_SERVICE_SUCCESS = "purchaseinvoice_service_success"
// export const PURCHASEINVOICE_SERVICE_FAIL = "purchaseinvoice_service_fail"
// export const PURCHASEINVOICE_SERVICE_START = "purchaseinvoice_service_start"
// export const PURCHASEINVOICE_REFRESH_START = "purchaseinvoice_refresh_start"
// export const PURCHASEINVOICE_FOOTER_REFRESH_START = "purchaseinvoice_footer_refresh_start"
// export const PURCHASEINVOICE_LIST_FILTER = "purchaseinvoice_list_filter"
// export const PURCHASEINVOICE_SEARCH_TEXT_UPDATE = "purchaseinvoice_search_text_update"
// export const PURCHASEINVOICE_STORE_SUCCESS = "purchaseinvoice_store_success"
// export const PURCHASEINVOICE_SERVICE_DELETED = "purchaseinvoice_service_deleted"
// export const PURCHASEINVOICE_SERVICE_UPDATED = "purchaseinvoice_service_updated"
// export const PURCHASEINVOICE_SERVICE_INSERTED = "purchaseinvoice_service_inserted"
// export const PURCHASEINVOICE_SERVICE_CLEAR = "purchaseinvoice_service_clear"
// export const PURCHASEINVOICE_SERVICE_CONVERTED = "purchaseinvoice_service_converted"

export const DEBITNOTE_SERVICE_SUCCESS = "debitnote_service_success"
export const DEBITNOTE_SERVICE_FAIL = "debitnote_service_fail"
export const DEBITNOTE_SERVICE_START = "debitnote_service_start"
export const DEBITNOTE_REFRESH_START = "debitnote_refresh_start"
export const DEBITNOTE_FOOTER_REFRESH_START = "debitnote_footer_refresh_start"
export const DEBITNOTE_LIST_FILTER = "debitnote_list_filter"
export const DEBITNOTE_SEARCH_TEXT_UPDATE = "debitnote_search_text_update"
export const DEBITNOTE_STORE_SUCCESS = "debitnote_store_success"
export const DEBITNOTE_SERVICE_DELETED = "debitnote_service_deleted"
export const DEBITNOTE_SERVICE_UPDATED = "debitnote_service_updated"
export const DEBITNOTE_SERVICE_INSERTED = "debitnote_service_inserted"
export const DEBITNOTE_SERVICE_CLEAR = "debitnote_service_clear"

export const CREDITNOTE_SERVICE_SUCCESS = "creditnote_service_success"
export const CREDITNOTE_SERVICE_FAIL = "creditnote_service_fail"
export const CREDITNOTE_SERVICE_START = "creditnote_service_start"
export const CREDITNOTE_REFRESH_START = "creditnote_refresh_start"
export const CREDITNOTE_FOOTER_REFRESH_START = "creditnote_footer_refresh_start"
export const CREDITNOTE_LIST_FILTER = "creditnote_list_filter"
export const CREDITNOTE_SEARCH_TEXT_UPDATE = "creditnote_search_text_update"
export const CREDITNOTE_STORE_SUCCESS = "creditnote_store_success"
export const CREDITNOTE_SERVICE_DELETED = "creditnote_service_deleted"
export const CREDITNOTE_SERVICE_UPDATED = "creditnote_service_updated"
export const CREDITNOTE_SERVICE_INSERTED = "creditnote_service_inserted"
export const CREDITNOTE_SERVICE_CLEAR = "creditnote_service_clear"

// export const RECEIPT_SERVICE_SUCCESS = "receipt_service_success"
// export const RECEIPT_SERVICE_FAIL = "receipt_service_fail"
// export const RECEIPT_SERVICE_START = "receipt_service_start"
// export const RECEIPT_REFRESH_START = "receipt_refresh_start"
// export const RECEIPT_FOOTER_REFRESH_START = "receipt_footer_refresh_start"
// export const RECEIPT_LIST_FILTER = "receipt_list_filter"
// export const RECEIPT_SEARCH_TEXT_UPDATE = "receipt_search_text_update"
// export const RECEIPT_STORE_SUCCESS = "receipt_store_success"
// export const RECEIPT_SERVICE_DELETED = "receipt_service_deleted"
// export const RECEIPT_SERVICE_UPDATED = "receipt_service_updated"
// export const RECEIPT_SERVICE_INSERTED = "receipt_service_inserted"
// export const RECEIPT_SERVICE_CLEAR = "receipt_service_clear"

// export const PAYMENT_SERVICE_SUCCESS = "payment_service_success"
// export const PAYMENT_SERVICE_FAIL = "payment_service_fail"
// export const PAYMENT_SERVICE_START = "payment_service_start"
// export const PAYMENT_REFRESH_START = "payment_refresh_start"
// export const PAYMENT_FOOTER_REFRESH_START = "payment_footer_refresh_start"
// export const PAYMENT_LIST_FILTER = "payment_list_filter"
// export const PAYMENT_SEARCH_TEXT_UPDATE = "payment_search_text_update"
// export const PAYMENT_STORE_SUCCESS = "payment_store_success"
// export const PAYMENT_SERVICE_DELETED = "payment_service_deleted"
// export const PAYMENT_SERVICE_UPDATED = "payment_service_updated"
// export const PAYMENT_SERVICE_INSERTED = "payment_service_inserted"
// export const PAYMENT_SERVICE_CLEAR = "payment_service_clear"

// export const JOURNAL_SERVICE_SUCCESS = "journal_service_success"
// export const JOURNAL_SERVICE_FAIL = "journal_service_fail"
// export const JOURNAL_SERVICE_START = "journal_service_start"
// export const JOURNAL_REFRESH_START = "journal_refresh_start"
// export const JOURNAL_FOOTER_REFRESH_START = "journal_footer_refresh_start"
// export const JOURNAL_LIST_FILTER = "journal_list_filter"
// export const JOURNAL_SEARCH_TEXT_UPDATE = "journal_search_text_update"
// export const JOURNAL_STORE_SUCCESS = "journal_store_success"
// export const JOURNAL_SERVICE_DELETED = "journal_service_deleted"
// export const JOURNAL_SERVICE_UPDATED = "journal_service_updated"
// export const JOURNAL_SERVICE_INSERTED = "journal_service_inserted"
// export const JOURNAL_SERVICE_CLEAR = "journal_service_clear"

export const CURRENT_TAB_CONTACT = "current_tab_contact"
export const CURRENT_TAB_INVOICE = "current_tab_invoice"
export const CURRENT_TAB_TRANSACTION = "current_tab_transaction"

export const RIGHT_DRAWER_STATE = "right_drawer_state"
export const UPDATE_RIGHT_DRAWER_STATE = "update_right_drawer_state"

export const RIGHT_DRAWER_HEADER = "right_drawer_header"

export const FORM_DRAWER_STATE = "form_drawer_state"
export const FORM_DRAWER_HEADER = "form_drawer_header"

export const UPDATERIGHT_DRAWER_STATE = "updateright_drawer_state"
export const SNACKBAR_STATE = "snackbar_state"
export const AUTH_SNACKBAR_STATE = "auth_snackbar_state"

// export const SALESRETURN_SERVICE_SUCCESS = "salesreturn_service_success"
// export const SALESRETURN_SERVICE_FAIL = "salesreturn_service_fail"
// export const SALESRETURN_SERVICE_START = "salesreturn_service_start"
// export const SALESRETURN_SERVICE_DELETED = "salesreturn_service_deleted"
// export const SALESRETURN_SERVICE_UPDATED = "salesreturn_service_updated"
// export const SALESRETURN_SERVICE_INSERTED = "salesreturn_service_inserted"
// export const SALESRETURN_SEARCH_TEXT_UPDATE = "salesreturn_search_text_update"
// export const SALESRETURNBILL_SERVICE_FAIL = "salesreturn_bill_service_fail"
// export const SALESRETURNBILL_SERVICE_SUCCESS = "salesreturn_bill_service_success"
// export const SALESRETURN_E_WAY_BILL_UPDATE = "salesreturn_e_way_bill_update"

export const GST_OLD_PEN_INV_SERVICE_SUCCESS = "gst_old_pen_inv_service_success"
export const GST_OLD_PEN_INV_SERVICE_FAIL = "gst_old_pen_inv_service_fail"
export const GST_OLD_PEN_INV_SERVICE_START = "gst_old_pen_inv_service_start"
export const GST_OLD_PEN_INV_SERVICE_DELETED = "gst_old_pen_inv_service_deleted"
export const GST_OLD_PEN_INV_SERVICE_UPDATED = "gst_old_pen_inv_service_updated"
export const GST_OLD_PEN_INV_SERVICE_INSERTED = "gst_old_pen_inv_service_inserted"
export const GST_OLD_PEN_INV_SEARCH_TEXT_UPDATE = "gst_old_pen_inv_search_text_update"
export const GST_OLD_PEN_INV_SERVICE_CLEAR = "gst_old_pen_inv_service_clear"

// export const PURCHASERETURNBILL_SERVICE_FAIL = "purchasereturn_bill_service_fail"
// export const PURCHASERETURNBILL_SERVICE_SUCCESS = "purchasereturn_bill_service_success"

// export const TAX_SERVICE_SUCCESS = "tax_service_success"
// export const TAX_SERVICE_FAIL = "tax_service_fail"
// export const TAX_SERVICE_START = "tax_service_start"
// export const TAX_SERVICE_DELETED = "tax_service_deleted"
// export const TAX_SERVICE_UPDATED = "tax_service_updated"
// export const TAX_SERVICE_INSERTED = "tax_service_inserted"
// export const TAX_SERVICE_CLEAR = "tax_service_clear"
// export const TAX_SERVICE_SEARCH = "tax_service_search"

export const IMPORT_TAX_SERVICE_SUCCESS = "import_tax_service_success"
export const IMPORT_TAX_SERVICE_FAIL = "import_tax_service_fail"
export const IMPORT_TAX_SERVICE_START = "import_tax_service_start"

export const IMPORT_ACCOUNT_SERVICE_SUCCESS = "import_account_service_success"
export const IMPORT_ACCOUNT_SERVICE_FAIL = "import_account_service_fail"
export const IMPORT_ACCOUNT_SERVICE_START = "import_account_service_start"
export const IMPORT_ACCOUNT_LOCAL_SEARCH = "import_account_local_search"

export const RECENT_ACTIVITIES_DRAWER_SERVICE_SUCCESS = "recent_activities_drawer_service_success"
export const RECENT_ACTIVITIES_DRAWER_SERVICE_FAIL = "recent_activities_drawer_service_fail"
export const RECENT_ACTIVITIES_DRAWER_SERVICE_START = "recent_activities_drawer_service_start"
export const RECENT_ACTIVITIES_DRAWER_LOCAL_SEARCH = "recent_activities_drawer_local_search"

export const QUICK_LINK_SERVICE_SUCCESS = "quick_link_service_success"
export const QUICK_LINK_SERVICE_FAIL = "quick_link_service_fail"
export const QUICK_LINK_SERVICE_START = "quick_link_service_start"
export const QUICK_LINK_SERVICE_CLEAR = "quick_link_service_clear"

export const OPBAL_SERVICE_SUCCESS = "opbal_service_success"
export const OPBAL_SERVICE_FAIL = "opbal_service_fail"
export const OPBAL_SERVICE_START = "opbal_service_start"
export const OPBAL_SERVICE_UPDATED = "opbal_service_updated"
export const OPBAL_SERVICE_CLEAR = "opbal_service_clear"
export const OPBAL_LOCAL_SEARCH = "opbal_local_search"

export const TDS_CATEGORY_MASTER_SERVICE_SUCCESS = "tds_category_master_service_success"
export const TDS_CATEGORY_MASTER_SERVICE_FAIL = "tds_category_master_service_fail"
export const TDS_CATEGORY_MASTER_SERVICE_START = "tds_category_master_service_start"
export const TDS_CATEGORY_MASTER_SERVICE_UPDATED = "tds_category_master_service_updated"
export const TDS_CATEGORY_MASTER_SERVICE_CLEAR = "tds_category_master_service_clear"
export const TDS_CATEGORY_MASTER_LOCAL_SEARCH = "tds_category_master_local_search"

export const OPSTOCK_SERVICE_SUCCESS = "opstock_service_success"
export const OPSTOCK_SERVICE_FAIL = "opstock_service_fail"
export const OPSTOCK_SERVICE_START = "opstock_service_start"
export const OPSTOCK_SERVICE_UPDATED = "opstock_service_updated"
export const OPSTOCK_SERVICE_CLEAR = "opstock_service_clear"
export const OPSTOCK_LOCAL_SEARCH = "opstock_local_search"

export const ALLOPBAL_SERVICE_SUCCESS = "allopbal_service_success"
export const ALLOPBAL_SERVICE_FAIL = "allopbal_service_fail"
export const ALLOPBAL_SERVICE_START = "allopbal_service_start"
export const ALLOPBAL_SERVICE_UPDATED = "allopbal_service_updated"
export const ALLOPBAL_SERVICE_CLEAR = "allopbal_service_clear"

export const IMPORT_UNIT_SERVICE_SUCCESS = "import_unit_service_success"
export const IMPORT_UNIT_SERVICE_FAIL = "import_unit_service_fail"
export const IMPORT_UNIT_SERVICE_START = "import_unit_service_start"
export const IMPORT_UNIT_LOCAL_SEARCH = "import_unit_local_search"

export const CLOSINGSTOCK_SERVICE_SUCCESS = "closingstock_service_success"
export const CLOSINGSTOCK_SERVICE_FAIL = "closingstock_service_fail"
export const CLOSINGSTOCK_SERVICE_START = "closingstock_service_start"

export const CLOSINGDATA_SERVICE_SUCCESS = "closingdata_service_success"
export const CLOSINGDATA_SERVICE_FAIL = "closingdata_service_fail"
export const CLOSINGDATA_SERVICE_START = "closingdata_service_start"
export const CLOSINGDATA_SERVICE_CLEAR = "closingdata_service_clear"
export const CLOSINGDATA_SERVICE_UPDATED = "closingdata_service_updated"

// export const TRANSPORT_SERVICE_SUCCESS = "transport_service_success"
// export const TRANSPORT_SERVICE_FAIL = "transport_service_fail"
// export const TRANSPORT_SERVICE_START = "transport_service_start"
// export const TRANSPORT_SERVICE_DELETED = "transport_service_deleted"
// export const TRANSPORT_SERVICE_UPDATED = "transport_service_updated"
// export const TRANSPORT_SERVICE_INSERTED = "transport_service_inserted"
// export const TRANSPORT_SERVICE_CLEAR = "transport_service_clear"
// export const TRANSPORT_SEARCH_TEXT_UPDATE = "transport_search_text_update"

// export const GODOWN_SERVICE_SUCCESS = "godown_service_success"
// export const GODOWN_SERVICE_FAIL = "godown_service_fail"
// export const GODOWN_SERVICE_START = "godown_service_start"
// export const GODOWN_SERVICE_DELETED = "godown_service_deleted"
// export const GODOWN_SERVICE_UPDATED = "godown_service_updated"
// export const GODOWN_SERVICE_INSERTED = "godown_service_inserted"
// export const GODOWN_SERVICE_CLEAR = "godown_service_clear"
// export const GODOWN_SEARCH_TEXT_UPDATE = "godown_search_text_update"

export const IPOSTOCKPARTY_SERVICE_SUCCESS = "ipo_ctyock_party_service_success"
export const IPOSTOCKPARTY_SERVICE_FAIL = "ipo_ctyock_party_service_fail"
export const IPOSTOCKPARTY_SERVICE_START = "ipo_ctyock_party_service_start"
export const IPOSTOCKPARTY_SERVICE_INSERTED = "ipo_ctyock_party_service_inserted"
export const IPOSTOCKPARTY_SERVICE_UPDATED = "ipo_ctyock_party_service_updated"
export const IPOSTOCKPARTY_SERVICE_DELETED = "ipo_ctyock_party_service_deleted"

export const IPOSTOCKMASTER_SERVICE_SUCCESS = "ipo_ctyock_master_service_success"
export const IPOSTOCKMASTER_SERVICE_FAIL = "ipo_ctyock_master_service_fail"
export const IPOSTOCKMASTER_SERVICE_START = "ipo_ctyock_master_service_start"
export const IPOSTOCKMASTER_SERVICE_INSERTED = "ipo_ctyock_master_service_inserted"
export const IPOSTOCKMASTER_SERVICE_UPDATED = "ipo_ctyock_master_service_updated"
export const IPOSTOCKMASTER_SERVICE_DELETED = "ipo_ctyock_master_service_deleted"

export const IPOSTOCK_SERVICE_SUCCESS = "ipo_ctyock_service_success"
export const IPOSTOCK_SERVICE_FAIL = "ipo_ctyock_service_fail"
export const IPOSTOCK_SERVICE_START = "ipo_ctyock_service_start"
export const IPOSTOCK_SERVICE_INSERTED = "ipo_ctyock_service_inserted"
export const IPOSTOCK_SERVICE_UPDATED = "ipo_ctyock_service_updated"
export const IPOSTOCK_SERVICE_DELETED = "ipo_ctyock_service_deleted"

// export const COSTCENTER_SERVICE_SUCCESS = "costcenter_service_success"
// export const COSTCENTER_SERVICE_FAIL = "costcenter_service_fail"
// export const COSTCENTER_SERVICE_START = "costcenter_service_start"
// export const COSTCENTER_SERVICE_DELETED = "costcenter_service_deleted"
// export const COSTCENTER_SERVICE_UPDATED = "costcenter_service_updated"
// export const COSTCENTER_SERVICE_INSERTED = "costcenter_service_inserted"
// export const COSTCENTER_SERVICE_CLEAR = "costcenter_service_clear"
// export const COSTCENTER_SEARCH_TEXT_UPDATE = "costcenter_search_text_update"

export const EWAY_BILL_SERVICE_SUCCESS = "ewaybill_service_success"
export const EWAY_BILL_SERVICE_FAIL = "ewaybill_service_fail"
export const EWAY_BILL_SERVICE_START = "ewaybill_service_start"
export const EWAY_BILL_SEARCH_TEXT_UPDATE = "ewaybill_search_text_update"
export const EWAY_BILL_STORE_SUCCESS = "ewaybill_store_success"
export const EWAY_BILL_SERVICE_UPDATED = "ewaybill_service_updated"
export const EWAY_BILL_SERVICE_CLEAR = "ewaybill_service_clear"

export const CUSTOM_FIELD_SERVICE_SUCCESS = "customfield_service_success"
export const CUSTOM_FIELD_SERVICE_FAIL = "customfield_service_fail"
export const CUSTOM_FIELD_SERVICE_START = "customfield_service_start"
export const CUSTOM_FIELD_SERVICE_DELETED = "customfield_service_deleted"
export const CUSTOM_FIELD_SERVICE_UPDATED = "customfield_service_updated"
export const CUSTOM_FIELD_SERVICE_CLEAR = "customfield_service_clear"
export const CUSTOM_FIELD_SERVICE_INSERTED = "customfield_service_inserted"
export const CUSTOM_FIELD_LOCAL_SEARCH = "customfield_local_search"
export const CUSTOMFIELD_OPTION_UPDATED = "customfield_option_updated"
export const CUSTOMFIELD_OPTION_DELETED = "customfield_option_deleted"
export const CUSTOMFIELD_OPTION_INSERTED = "customfield_option_inserted"

export const CUSTOM_PAGE_SERVICE_SUCCESS = "custompage_service_success"
export const CUSTOM_PAGE_SERVICE_FAIL = "custompage_service_fail"
export const CUSTOM_PAGE_SERVICE_START = "custompage_service_start"
export const CUSTOM_PAGE_SERVICE_DELETED = "custompage_service_deleted"
export const CUSTOM_PAGE_SERVICE_UPDATED = "custompage_service_updated"
export const CUSTOM_PAGE_SERVICE_CLEAR = "custompage_service_clear"
export const CUSTOM_PAGE_SERVICE_INSERTED = "custompage_service_inserted"
export const CUSTOM_PAGE_LOCAL_SEARCH = "custompage_local_search"
export const CUSTOM_PAGE_ITEM_UPDATED = "custom_page_item_updated"

export const PAGES_SERVICE_SUCCESS = "pages_service_success"
export const PAGES_SERVICE_FAIL = "pages_service_fail"
export const PAGES_SERVICE_START = "pages_service_start"
export const PAGES_SERVICE_DELETED = "pages_service_deleted"
export const PAGES_SERVICE_UPDATED = "pages_service_updated"
export const PAGES_SERVICE_CLEAR = "pages_service_clear"
export const PAGES_SERVICE_INSERTED = "pages_service_inserted"
export const PAGES_LOCAL_SEARCH = "pages_local_search"

export const SMS_TEMPLATE_SERVICE_SUCCESS = "sms_template_service_success"
export const SMS_TEMPLATE_SERVICE_UPDATED = "sms_template_service_updated"
export const SMS_TEMPLATE_SERVICE_START = "sms_template_service_start"

export const WHATSAPP_TEMPLATE_SERVICE_SUCCESS = "whatsapp_template_service_success"
export const WHATSAPP_TEMPLATE_SERVICE_START = "whatsapp_template_service_start"
export const WHATSAPP_TEMPLATE_SERVICE_UPDATED = "whatsapp_template_service_updated"

export const EMAIL_TEMPLATE_SERVICE_SUCCESS = "email_template_service_success"
export const EMAIL_TEMPLATE_SERVICE_UPDATED = "email_template_service_updated"
export const EMAIL_TEMPLATE_SERVICE_START = "email_template_service_start"
export const WINDOW_DIMENSION_UPDATED = "window_dimension_updated"
export const UPDATE_REPORT_STATE = "update_report_state"
export const GSTR2_SERVICE_UPDATED = "gstr2_service_updated"
export const CLOSING_BALANCE = "closing_balance"
export const SMS_BALANCE = "sms_balance"
export const EWB_BALANCE = "ewb_balance"
export const SITE_SETTING_UPDATED = "site_setting_updated"
export const NEW_SITE_SETTING_UPDATED = "new_site_setting_updated"
export const LAST_PAGE_UPDATE = "last_page_update"
export const UPDATE_SERVICEWORKER = "UPDATE_SERVICEWORKER"
export const SET_SETTING = "set_setting"

export const LABEL_SERVICE_SUCCESS = "label_service_success"
export const LABEL_SERVICE_FAIL = "label_service_fail"
export const LABEL_SERVICE_START = "label_service_start"
export const LABEL_SERVICE_DELETED = "label_service_deleted"
export const LABEL_SERVICE_UPDATED = "label_service_updated"
export const LABEL_SERVICE_INSERTED = "label_service_inserted"
export const LABEL_SERVICE_CLEAR = "label_service_clear"
export const LABEL_SEARCH_TEXT_UPDATE = "label_search_text_update"

export const LABEL_SETTING_SERVICE_SUCCESS = "label_setting_service_success"
export const LABEL_SETTING_SERVICE_FAIL = "label_setting_service_fail"
export const LABEL_SETTING_SERVICE_START = "label_setting_service_start"
export const LABEL_SETTING_SERVICE_DELETED = "label_setting_service_deleted"
export const LABEL_SETTING_SERVICE_UPDATED = "label_setting_service_updated"
export const LABEL_SETTING_SERVICE_INSERTED = "label_setting_service_inserted"
export const LABEL_SETTING_SERVICE_CLEAR = "label_setting_service_clear"
export const LABEL_SETTING_SEARCH_TEXT_UPDATE = "label_setting_search_text_update"
export const IMPORT_LABEL_SETTING_SERVICE_SUCCESS = "import_label_setting_service _success"

export const IMPORT_TEMPLATE_SERVICE_SUCCESS = "import_template_service_success"
export const IMPORT_TEMPLATE_SERVICE_FAIL = "import_template_service_fail"
export const IMPORT_TEMPLATE_SERVICE_START = "import_template_service_start"
export const IMPORT_TEMPLATE_LOCAL_SEARCH = "import_template_local_search"
export const IMPORT_TEMPLATE_SERVICE_INSERTED = "import_template_service_inserted"

export const IMPORT_UNIT_INSERTED_SUCCESS = "import_unit_inserted_success"

export const ITEM_PHOTO_UPDATED = "item_photo_updated"
export const ITEM_GROUP_PHOTO_UPDATED = "item_group_photo_updated"
export const PRODUCT_SUB_GROUP_PHOTO_UPDATED = "product_sub_group_photo_updated"
export const PRODUCT_SUB_GROUP_LEVEL2_PHOTO_UPDATED = "product_sub_group_level2_photo_updated"
export const COMPANY_PHOTO_UPDATED = "company_photo_updated"
export const USER_PHOTO_UPDATED = "user_photo_updated"
export const CONFIGURABLE_ITEM_PHOTO_UPDATED = "configurable_item_photo_updated"
export const BUNDLE_ITEM_PHOTO_UPDATED = "bundle_item_photo_updated"

export const UPDATE_USER_DATA = "update_user_data"
export const COMPANY_LOGO_DELETED = "company_logo_deleted"

export const SET_LAST_DATE = "set_last_date"
export const SET_ALL_LAST_DATE = "set_all_last_date"
export const SET_LAST_DRAFT = "set_last_draft"

export const CUSTOM_PAGE_DROPDOWN_UPDATE = "custom_page_dropdown_update"

export const SALESINVOICE_DRAFT_SUCCESS = "salesinvoice_draft_success"
export const SALESINVOICE_DRAFT_INSERTED = "salesinvoice_draft_inserted"
export const SALESINVOICE_DRAFT_UPDATED = "salesinvoice_draft_updated"
export const SALESINVOICE_DRAFT_DELETED = "salesinvoice_draft_deleted"

export const PURCHASEINVOICE_DRAFT_SUCCESS = "purchaseinvoice_draft_success"
export const PURCHASEINVOICE_DRAFT_INSERTED = "purchaseinvoice_draft_inserted"
export const PURCHASEINVOICE_DRAFT_UPDATED = "purchaseinvoice_draft_updated"
export const PURCHASEINVOICE_DRAFT_DELETED = "purchaseinvoice_draft_deleted"

export const PURCHASERETURN_DRAFT_SUCCESS = "purchasereturn_draft_success"
export const PURCHASERETURN_DRAFT_INSERTED = "purchasereturn_draft_inserted"
export const PURCHASERETURN_DRAFT_UPDATED = "purchasereturn_draft_updated"
export const PURCHASERETURN_DRAFT_DELETED = "purchasereturn_draft_deleted"

// export const SALESRETURN_DRAFT_SUCCESS = "salesreturn_draft_success"
// export const SALESRETURN_DRAFT_INSERTED = "salesreturn_draft_inserted"
// export const SALESRETURN_DRAFT_UPDATED = "salesreturn_draft_updated"
// export const SALESRETURN_DRAFT_DELETED = "salesreturn_draft_deleted"

// export const OUTWARD_DRAFT_SUCCESS = "outward_draft_success"
// export const OUTWARD_DRAFT_INSERTED = "outward_draft_inserted"
// export const OUTWARD_DRAFT_UPDATED = "outward_draft_updated"
// export const OUTWARD_DRAFT_DELETED = "outward_draft_deleted"

// export const INWARD_DRAFT_SUCCESS = "inward_draft_success"
// export const INWARD_DRAFT_INSERTED = "inward_draft_inserted"
// export const INWARD_DRAFT_UPDATED = "inward_draft_updated"
// export const INWARD_DRAFT_DELETED = "inward_draft_deleted"

// export const RECEIPT_DRAFT_SUCCESS = "receipt_draft_success"
// export const RECEIPT_DRAFT_INSERTED = "receipt_draft_inserted"
// export const RECEIPT_DRAFT_UPDATED = "receipt_draft_updated"
// export const RECEIPT_DRAFT_DELETED = "receipt_draft_deleted"

// export const PAYMENT_DRAFT_SUCCESS = "payment_draft_success"
// export const PAYMENT_DRAFT_INSERTED = "payment_draft_inserted"
// export const PAYMENT_DRAFT_UPDATED = "payment_draft_updated"
// export const PAYMENT_DRAFT_DELETED = "payment_draft_deleted"

// export const JOURNAL_DRAFT_SUCCESS = "journal_draft_success"
// export const JOURNAL_DRAFT_INSERTED = "journal_draft_inserted"
// export const JOURNAL_DRAFT_UPDATED = "journal_draft_updated"
// export const JOURNAL_DRAFT_DELETED = "journal_draft_deleted"

export const CUSTOM_DIALOG = "custom_dialog"
export const PUSH_DRAWER = "push_drawer"
export const PUSH_SHORTCUT_DRAWER = "push_shortcut_drawer"
export const REMOVE_DRAWER = "remove_drawer"
export const OPEN_DRAWER = "open_drawer"
export const CLOSE_DRAWER = "close_drawer"
export const CLOSE_ALL_DRAWER = "close_all_drawer"
export const UPDATE_DRAWER = "update_drawer"
export const UPDATE_REFRESH_DRAWER_STACK = "update_refresh_drawer_stack"

export const DIALOG_REASON_UPDATE = "dialog_reason_update"

export const FETCH_FILTER_START = "fetch_filter_start"
export const FETCH_FILTER_SUCCESS = "fetch_filter_success"
export const FETCH_FILTER_ERROR = "fetch_filter_error"

export const PRODUCTSEARCH_SERVICE_SUCCESS = "productsearch_service_success"
export const PRODUCTSEARCH_SERVICE_FAIL = "productsearch_service_fail"
export const PRODUCTSEARCH_SERVICE_START = "productsearch_service_start"
export const PRODUCTSEARCH_SERVICE_DELETED = "productsearch_service_deleted"
export const PRODUCTSEARCH_SERVICE_UPDATED = "productsearch_service_updated"
export const PRODUCTSEARCH_SERVICE_INSERTED = "productsearch_service_inserted"
export const PRODUCTSEARCH_SERVICE_CLEAR = "productsearch_service_clear"
export const PRODUCTSEARCH_SEARCH_TEXT_UPDATE = "productsearch_search_text_update"

export const COMMON_SERVICE_CLEAR = "common_service_clear"
export const COMMON_SEARCH_TEXT_UPDATE = "common_search_text_update"
export const COMMON_SERVICE_SUCCESS = "common_service_success"
export const COMMON_SERVICE_UPDATED = "common_service_updated"
export const COMMON_SERVICE_ARRAY_UPDATED = "common_service_array_updated"
export const COMMON_SERVICE_FAIL = "common_service_fail"
export const COMMON_SERVICE_START = "common_service_start"
export const COMMON_SERVICE_INSERTED = "common_service_inserted"
export const COMMON_SERVICE_ARRAY_INSERTED = "common_service_array_inserted"
export const COMMON_SERVICE_DELETED = "common_service_deleted"
export const POP_OVER_UPDATED = "pop_over_updated"

export const INVOICE_SERVICE_CONVERTED = "invoice_service_converted"
