import { isblank, replaceAllFn } from "@project/sharedcomponents/utilityFunctions"
import aes from "crypto-js/aes"
import encHex from "crypto-js/enc-hex"
import padZeroPadding from "crypto-js/pad-zeropadding"
import { Buffer } from "buffer"
var CryptoJS = require("crypto-js")

var JsonFormatter = {
  stringify: function (cipherParams) {
    // create json object with ciphertext
    var jsonObj = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) }
    // optionally add iv or salt
    if (cipherParams.iv) {
      jsonObj.iv = cipherParams.iv.toString()
    }
    if (cipherParams.salt) {
      jsonObj.s = cipherParams.salt.toString()
    }
    // stringify json object
    return JSON.stringify(jsonObj)
  },
  parse: function (jsonStr) {
    // parse json string
    var jsonObj = JSON.parse(jsonStr)
    // extract ciphertext from json object, and create cipher params object
    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct),
    })
    // optionally extract iv or salt
    if (jsonObj.iv) {
      cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv)
    }
    if (jsonObj.s) {
      cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s)
    }
    return cipherParams
  },
}

export const encryption = ({ data }) => {
  if (isblank(data)) {
    return ""
  }
  return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SALTKEY).toString()
}

export const encryptionV2 = (data) => {
  if (isblank(data)) {
    return ""
  }
  let jsonString = JSON.stringify(data)
  let encrypted = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SALTKEY).toString()
  let base64String = Buffer.from(encrypted).toString("base64")
  return encodeURIComponent(base64String)
}

export const decryption = ({ data, Json_Formatter }) => {
  if (isblank(data)) {
    return ""
  }
  try {
    var decrypted = ""
    if (Json_Formatter) {
      decrypted = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SALTKEY, {
        format: JsonFormatter,
      })
    } else {
      decrypted = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SALTKEY)
    }

    const decryptString = decrypted.toString(CryptoJS.enc.Utf8)
    return JSON.parse(decryptString)
  } catch (err) {
    return ""
  }
}

export const decryptionV2 = (data) => {
  if (isblank(data)) {
    return ""
  }
  try {
    var decrypted = ""

    let decodeBase64 = decodeURIComponent(data)
    let decodedString = Buffer.from(decodeBase64, "base64").toString("utf8")
    decrypted = CryptoJS.AES.decrypt(decodedString, process.env.REACT_APP_SALTKEY)
    const decryptString = decrypted.toString(CryptoJS.enc.Utf8)

    return JSON.parse(decryptString)
  } catch (err) {
    return ""
  }
}

export const encryptionStr = ({ data }) => {
  if (isblank(data)) {
    return ""
  }
  let key = encHex.parse(process.env.REACT_APP_ENCDECKEY)
  let iv = encHex.parse(process.env.REACT_APP_ENCDECIV)
  let encrypted = aes.encrypt(data.toString(), key, { iv: iv, padding: padZeroPadding }).toString()
  encrypted = replaceAllFn(encrypted, "/", "$")
  return encrypted
}

function remove_non_ascii(str) {
  if (str === null || str === "") return false
  else str = str.toString()
  return str.replace(/[^\x20-\x7E]/g, "")
}

export const decryptionStr = ({ data }) => {
  if (isblank(data)) {
    return ""
  }
  let key = encHex.parse(process.env.REACT_APP_ENCDECKEY)
  let iv = encHex.parse(process.env.REACT_APP_ENCDECIV)
  var string = data.replace(/[$]+/g, "/")
  var string = replaceAllFn(data, "$", "/")
  let decrypted = aes.decrypt(string, key, { iv: iv, padding: padZeroPadding })
  try {
    decrypted = decrypted.toString(CryptoJS.enc.Utf8)
    var decrypted1 = remove_non_ascii(decrypted)
    return decrypted1
  } catch (err) {
    return ""
  }
}
