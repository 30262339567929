import { Grid, ListItem, ListItemSecondaryAction, ListItemText, Paper, Typography, makeStyles } from "@material-ui/core"
import FBChip from "@project/components/common/ChipComponent"
import DebounceLink from "@project/components/common/DebounceLink"
import usePrevious from "@project/components/Hooks/usePrevious"
import { deletedataFromFormNew, getdropdownvalue } from "@project/components/utilityFunctions"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import { callError, onSubmitError } from "@project/sharedcomponents/errorFunction"
import {
  AddNumberFormat,
  RemoveNumberFormat,
  checkCondition,
  createDropDown,
  getNumberonly,
  getdropdownfieldvalue,
  isblank,
  replaceBRtoNewLine,
  replacePreFix,
  validateInputText,
} from "@project/sharedcomponents/utilityFunctions"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import {
  addRecentAllAccount,
  adjustBillList,
  clear_dropDownUpdated,
  clearformdata,
  fetchNewInvoiceNumber,
  fetch_form_dep,
  formdrawerstate,
  getBalance,
  modalTransactionDetail,
  pendingTdsList,
  post_form_data,
  rightdrawerstate,
  showformdialog,
  snackbarstate,
} from "../../Actions"
import { DROPDOWN_INSERTED } from "../../Actions/types"
import styles from "../../Css/style.css"
import { checkdropdowndependancy, getAccountSessionUserData, getAccountSitesetting, getMenuRightsByType, getTransDate, isValidFinancialDate, setLastTansDateV1 } from "../../Lib/commonfunctions"
import Footer from "../app/footer"
import PageHeader from "../app/pageHeader"
import Field from "../common/reduxFormComponents"

const useStyles = makeStyles((theme) => ({
  ...styles,
}))

const ModalTransactionSingle = (props) => {
  const { drawerType, drawerData, drawerIndex, componentId, other_type } = props
  const type = other_type
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    ddbankaccount,
    deploading,
    lastpagetype,
    lastpageaction,
    lastpagetime,
    lastpagedata,
    ddallaccount,
    loading,
    sidebarloading,
    closingbalance,
    submitting,
    pageformaction,
    pageformdata,
    dropdownupdated,
    common_formtype,
    ddsalesbill,
    ddpurbill,
  } = useSelector((state) => state.CommonReducer)

  const dropDownReceiptAllAccount = ddallaccount
  const dropDownReceiptBank = ddbankaccount
  const [billNoLoading, setBillNoLoading] = useState(false)
  const prevDepLoading = usePrevious(deploading)
  const prevDropdownupdated = usePrevious(dropdownupdated)
  const prevDropDownReceiptAllAccount = usePrevious(dropDownReceiptAllAccount)

  const dS = {
    customerListAuto: parseInt(getAccountSitesetting("customerListAuto")),
    closing_balance: parseInt(getAccountSitesetting("closing_balance")),
    reason: parseInt(getAccountSitesetting("reason")),
    cost_center: parseInt(getAccountSitesetting("cost_center")),
    adjust_bill: parseInt(getAccountSitesetting("adjust_bill")),
    purchase_adjust_bill: parseInt(getAccountSitesetting("purchase_adjust_bill")),
    tds: parseInt(getAccountSitesetting("tds")),
    receipt_new_doc_number: parseInt(getAccountSitesetting("receipt_new_doc_number")),
    payment_new_doc_number: parseInt(getAccountSitesetting("payment_new_doc_number")),
  }

  const getFormType = () => {
    let form_type = {}

    switch (type) {
      case "receiptdetail":
        form_type.type = "receipt"
        form_type.title = "Receipt"
        form_type.formName = "ReceiptForm"
        form_type.field = "invoiceCr"
        form_type.adjustType = "adjustinvoice"
        form_type.adjustSubType = "receivable_bills"
        form_type.newInvoiceNo = dS.receipt_new_doc_number
        form_type.adjustBill = dS.adjust_bill
        // form_type.dateType = "last_receiptdate"
        break

      case "paymentdetail":
        form_type.type = "payment"
        form_type.title = "Payment"
        form_type.formName = "Payment"
        form_type.field = "invoiceDr"
        form_type.adjustType = "adjustpurchaseinvoice"
        form_type.adjustSubType = "receivable_onaccount"
        form_type.newInvoiceNo = dS.payment_new_doc_number
        form_type.adjustBill = dS.purchase_adjust_bill
        // form_type.dateType = "last_paymentdate"
        break

      default:
        break
    }
    return form_type
  }

  const form_type = getFormType()

  const formaction = pageformaction
  const defaultValues = {
    receipt_type: "S",
    date: getTransDate(`last_${form_type.type}date`, "YMD"),
    costcenter: "",
  }
  const { handleSubmit, control, reset, watch, setValue, setError, clearErrors } = useForm({ defaultValues })
  const formValues = watch()

  const billNoSeries = getAccountSitesetting(`${form_type.type}_prefix`)
  const new_doc_number = getAccountSitesetting(`${form_type.type}_new_doc_number`)
  // const adjusted = getAdjustedCount(formValues.invoice)

  const formdata = pageformdata
  const format_fields = []
  format_fields.push({ fields: ["amount"] })
  var balanceDetail = ""
  if (dS.closing_balance === 1 && !isblank(closingbalance)) {
    balanceDetail = {}
    var balance = ""
    if (closingbalance.hasOwnProperty("bal")) {
      if (!isblank(closingbalance.bal)) {
        balance = closingbalance.bal
      } else {
        balance = 0.0
      }
    }
    balanceDetail.name = closingbalance.name
    balanceDetail.balance = balance
    balanceDetail.balance_date = closingbalance.balance_date
  }

  const headerTitle = form_type.title
  var bankSelected = false
  var costcenter = ""
  if (formValues !== undefined && formValues.hasOwnProperty("bankSelectedValue") && dropDownReceiptBank.length > 0) {
    var bankSelectedValue = createDropDown(dropDownReceiptBank, formValues.bankSelectedValue, "code")
    bankSelected = bankSelectedValue.typecode === "B" ? true : false
  }
  let isParty = getdropdownfieldvalue({ dropDownData: dropDownReceiptAllAccount, field: "code", value: formValues.accountSelectedValue, displayvalue: "groupcode" })

  isParty = checkCondition(isParty, ["L0021", "A0019"])

  var tds_adjusted = ""
  var tdsDetail = !isblank(formValues) ? formValues.tdsDetail : ""
  if (formValues !== undefined) {
    costcenter = formValues.hasOwnProperty("costcenter") && formValues.costcenter.length > 0 ? formValues.costcenter.length : ""
    tds_adjusted = !isblank(tdsDetail) && tdsDetail.tds_adjusted > 0 ? tdsDetail.tds_adjusted : ""
  }
  var costCenterEnable = true

  if (formValues !== undefined) {
    if (formValues.hasOwnProperty("accountSelectedValue")) {
      var accountSelectedValue = dropDownReceiptAllAccount.find((item) => parseInt(item.code) === parseInt(formValues.accountSelectedValue))
      if (!isblank(accountSelectedValue)) {
        if (accountSelectedValue.hasOwnProperty("costcenter_mng")) {
          if (accountSelectedValue.costcenter_mng === "1") {
            costCenterEnable = false
          }
        }
      }
    }
  }

  const validate = (values) => {
    const errors = {}

    if (form_type.newInvoiceNo === 1 || formaction === "edit") {
      if (!values["billNo"]) {
        errors["billNo"] = ErrorMessages["E00174"]
      }
    }
    if (!values["accountSelectedValue"]) {
      errors["accountSelectedValue"] = ErrorMessages["E00056"]
    }
    if (!isblank(values["narration"])) {
      if (!validateInputText(values["narration"])) {
        errors["narration"] = ErrorMessages["E00550"]
      }
    }
    if (!values["amount"]) {
      errors["amount"] = ErrorMessages["E00212"]
    } else {
      if (dS.adjust_bill === 1 && !isblank(values[form_type.field]) && (values[form_type.field].receivable_bills?.length > 0 || values[form_type.field].receivable_onaccount?.length > 0)) {
        var adjustedAmount = values[form_type.field][form_type.adjustSubType].reduce((sum, row) => parseFloat(getNumberonly(row.payment)) + sum, 0)
        if (parseFloat(getNumberonly(values["amount"])) < parseFloat(getNumberonly(adjustedAmount))) {
          errors["amount"] = ErrorMessages["E00205"]
        }
      }
    }
    if (!values["bankSelectedValue"]) {
      errors["bankSelectedValue"] = ErrorMessages["E00136"]
    }
    const checkdate = isValidFinancialDate({ dtStr: values["date"] })
    if (checkdate) {
      errors["date"] = checkdate
    }
    if (!values["date"]) {
      errors["date"] = ErrorMessages["E00108"]
    }

    return errors
  }

  const getInvoiceNumber = () => {
    if (!isblank(new_doc_number) && new_doc_number.toString() === "1") {
      if (formaction !== "edit") {
        setBillNoLoading(true)
        dispatch(fetchNewInvoiceNumber(form_type.type, form_type.type))
      }
    }
  }

  const toggleFormDrawer = (open, title) => () => {
    dispatch(formdrawerstate({ open, title }))
  }

  const handleprint = () => {
    var otherDetail = {}
    var printType = "print"
    otherDetail.title = form_type.title
    otherDetail.type = form_type.type
    otherDetail.printType = "html"

    dispatch(
      modalTransactionDetail({
        code: formValues.update_code.toString().split("_")[0],
        type: otherDetail.type,
        title: `${otherDetail.title}: #${formValues.docno}`,
        filenameOld: `${otherDetail.title}_${formValues.docno}`,
        otherDetail,
        printType,
      })
    )
  }
  let saveDraft = false
  const saveDrafts = () => {
    saveDraft = true
    saveandaddanother = false
    saveandprint = false
    saveandprint = false
    handleSave()
  }

  const handledelete = () => {
    var confirmdata = {}
    confirmdata.dispatch = dispatch
    confirmdata.formtype = form_type.type
    confirmdata.formaction = "delete"
    confirmdata.formdata = formValues.update_code

    const onConfirm = (reason) => {
      deletedataFromFormNew({ post_form_data, confirmdata, ConfirmDialogData: reason, getAccountSitesetting })
      dispatch(showformdialog({ yesno: false }))
    }
    dispatch(showformdialog({ yesno: true, onConfirm }))
  }
  var saveandaddanother = false
  var saveandprint = false
  var saveandshare = false
  var _reason = ""

  const handlesaveandanother = () => {
    saveandaddanother = true
    saveDraft = false
    handleSave()
    setTimeout(() => {
      reset((formValues) => {
        return {
          formValues: "",
        }
      })
    }, 500)
  }

  const opencostCenter = () => {
    if (!isblank(formValues.accountSelectedValue)) {
      if (parseFloat(getNumberonly(formValues.amount)) > 0) {
        var metadata = {}
        metadata.formname = form_type.formName
        metadata.index = ""
        metadata.row = formValues
        metadata.Grid = ""
        metadata.Gridfieldname = "costcenter"
        metadata.setValue = setValue
        dispatch(rightdrawerstate({ open: true, title: "Select Cost Center", data: metadata, fieldname: "amount", position: "right", width: 850 }))
      } else {
        dispatch(snackbarstate({ open: true, message: ErrorMessages["E00434"], snackbarType: "error" }))
      }
    }
  }

  const adjustBill = () => {
    const party_code = formValues.accountSelectedValue
    const type = form_type.adjustType
    var other_type = ""
    var code = ""

    const meta = {}
    meta.formname = form_type.formName
    meta.amount = formValues.amount
    meta.field = form_type.field
    meta.setValue = setValue

    const transactionType = form_type.type === "payment" ? "P" : "R"

    if (formaction === "edit") {
      other_type = "edit"
      code = formValues.update_code
      meta.action = "edit"
    }
    if (!isblank(formValues.accountSelectedValue)) {
      if (parseFloat(getNumberonly(formValues.amount)) > 0) {
        if (
          formValues.hasOwnProperty(form_type.field) &&
          !isblank(formValues[form_type.field]) &&
          (formValues[form_type.field].receivable_bills?.length > 0 || formValues[form_type.field].receivable_onaccount?.length > 0)
        ) {
          let data = formValues[form_type.field]
          /*  let data = formValues[form_type.field].map((item, index) => {
            const detailGrid = { ...item }
            detailGrid.InvoiceCode = item.code
            detailGrid.Description = item.Description
            detailGrid.DueDate = item.DueDate
            detailGrid.OriginalAmt = amount_format(item.netamt)
            detailGrid.OpenBalance = amount_format(item.pendingamt)
            detailGrid.Payment = amount_format(item.adjust_amt)
            return detailGrid
          }) */
          const metadata = {}
          metadata.data = data
          metadata.formname = form_type.formName
          metadata.amount = formValues.amount
          metadata.action = "edit"
          metadata.field = form_type.field
          metadata.setValue = setValue

          dispatch(rightdrawerstate({ open: true, title: "Adjust Bill By Bill", data: metadata, position: "right", width: 1024 }))
        } else {
          dispatch(adjustBillList({ party_code, type, other_type, meta, code, transactionType }))
        }
      } else {
        dispatch(snackbarstate({ open: true, message: ErrorMessages["E00434"], snackbarType: "error" }))
      }
    }
  }

  const onaccountchange = (value) => {
    if (dS.adjust_bill === 1) {
      setValue(form_type.field, [])
    }

    if (dS.cost_center === 1) {
      setValue("costcenter", [])
    }

    if (dS.customerListAuto === 0) {
      if (!isblank(value)) {
        dispatch({
          type: DROPDOWN_INSERTED,
          formtype: "allaccount",
          code: value.code,
          name: value.name,
          payload: value,
        })
      }
    }

    if (!isblank(value)) {
      if (dS.closing_balance === 1) {
        dispatch(getBalance({ code: value.code, date: formValues.date }))
      }
      if (value.enableEmail) {
        setValue("sendEmailButton", true)
      } else {
        setValue("sendEmailButton", false)
      }
      setValue("sendEmail", false)
      if (value.enableSMS) {
        setValue("sendSMSButton", true)
      } else {
        setValue("sendSMSButton", false)
      }
      setValue("sendSMS", false)
      if (value.enableWhatsapp) {
        setValue("sendWhatsAppButton", true)
      } else {
        setValue("sendWhatsAppButton", false)
      }
      setValue("sendWhatsApp", false)
    } else {
      dispatch(getBalance({}))
    }
  }
  const addNewAccount = (value) => {
    var data = {}
    data.formname = form_type.formName
    data.value = value
    data.remoteForm = 1
    dispatch(rightdrawerstate({ open: true, title: "Add New Account", data, fieldname: "accountSelectedValue", position: "right", width: 450 }))
  }

  const onabankccountchange = (value) => {
    if (!isblank(value)) {
      if (dS.closing_balance === 1) {
        dispatch(getBalance({ code: value.code, date: formValues.date }))
      }
    } else {
      dispatch(getBalance({}))
    }
  }

  const onSubmitData = (values) => {
    const error = validate(values)

    if (Object.keys(error).length > 0) {
      callError({ error, onSubmitError, dispatch, snackbarstate, setError })
    } else {
      var form_action = "insert"
      var form_data = JSON.parse(JSON.stringify(values))
      form_data.receipt_type = "S"
      form_data = RemoveNumberFormat({ obj: form_data, fields: format_fields })

      form_data.accountSelectedValue = createDropDown(dropDownReceiptAllAccount, form_data.accountSelectedValue, "code")
      form_data.bankSelectedValue = createDropDown(dropDownReceiptBank, form_data.bankSelectedValue, "code")

      const setGridData = (data) => {
        const updatedData = data
          .filter((e) => !isblank(e?.payment) && getNumberonly(e.payment) > 0)
          .map((item, index) => {
            const updatedItem = {}
            updatedItem.Type = item.Type
            updatedItem.Outstanding = getNumberonly(item.Outstanding)
            updatedItem.Code = item.Code
            updatedItem.OpenBalance = getNumberonly(item.OpenBalance)
            updatedItem.payment = getNumberonly(item.payment)
            if (!isblank(item.GridId)) {
              updatedItem.GridId = item.GridId
            }

            return updatedItem
          })
        return updatedData
      }

      if (!isblank(form_data[form_type.field])) {
        if (!isblank(form_data[form_type.field]["receivable_bills"]) && form_data[form_type.field].receivable_bills?.length > 0) {
          form_data[form_type.field].receivable_bills = setGridData(form_data[form_type.field].receivable_bills)
        } else {
          delete form_data[form_type.field].receivable_bills
        }
        if (!isblank(form_data[form_type.field]["receivable_onaccount"]) && form_data[form_type.field].receivable_onaccount?.length > 0) {
          form_data[form_type.field].receivable_onaccount = setGridData(form_data[form_type.field].receivable_onaccount)
        } else {
          delete form_data[form_type.field].receivable_onaccount
        }
      }

      if (form_type.field === "invoiceDr") {
        delete form_data.invoiceCr
      } else {
        delete form_data.invoiceDr
      }

      if (dS.cost_center === 1) {
        if (!isblank(form_data.costcenter)) {
          form_data.costcenter = form_data.costcenter.map((item, index) => {
            const updateditem = {}
            if (item.costcenterdata.hasOwnProperty("code")) {
              updateditem.costcenterdata = item.costcenterdata.code
            } else {
              updateditem.costcenterdata = item.costcenterdata
            }
            updateditem.amountdata = getNumberonly(item.amountdata)
            return updateditem
          })
          form_data.costcenter = encodeURIComponent(JSON.stringify(form_data.costcenter))
        } else {
          form_data.costcenter = ""
        }
      }

      if (saveDraft) {
        const search_data = {}
        search_data.vdate = form_data.date
        search_data.name = getdropdownvalue({ formValues: form_data, field: "bankSelectedValue", prop: "name" })
        search_data.acc_code = getdropdownvalue({ formValues: form_data, field: "bankSelectedValue", prop: "code" })
        search_data.name1 = getdropdownvalue({ formValues: form_data, field: "accountSelectedValue", prop: "name" })
        search_data.acc_code1 = getdropdownvalue({ formValues: form_data, field: "accountSelectedValue", prop: "code" })
        search_data.amount = form_data.amount
        search_data.chequeno = form_data.cheque
        search_data.chequedate = form_data.chequedate

        const meta = {
          detail: JSON.stringify({ ddsalesbill: ddsalesbill, ddpurbill: ddpurbill, ...values }),
          search_data: JSON.stringify(search_data),
          draftCode: !isblank(values.draftCode) ? values.draftCode : "",
          common_formtype: common_formtype,
        }
        meta.url = `${form_type.type}s`

        dispatch(showformdialog({ yesno: true, confirm: false, message: "Save as draft", title: "Draft", form: "Draft Dialog", meta }))
      } else {
        if (formaction === "edit") {
          form_action = "update"
          form_data.update_code = formValues.update_code
          form_data.reason = _reason
          _reason = ""
        }
        let draftCode = ""
        if (!isblank(formdata) && !isblank(formdata.data) && !isblank(formdata.data.form_data.draftCode)) {
          draftCode = formdata.data.form_data.draftCode
        }
        dispatch(
          post_form_data({
            dispatch,
            formtype: form_type.type,
            formaction: form_action,
            formdata: form_data,
            saveandaddanother,
            saveandprint,
            saveandshare,
            common_formtype: common_formtype,
            draftCode,
          })
        )
      }
    }
  }

  const handleSave = (type) => {
    saveDraft = false
    if (type === "print") {
      saveandprint = true
    } else if (type === "share") {
      saveandshare = true
    } else {
      saveandprint = false
      saveandshare = false
    }
    clearErrors()
    handleSubmit(onSubmitData, (errors) => onSubmitError({ errors, dispatch, snackbarstate }))()
  }

  const handleUpdate = (type) => {
    const error = validate(formValues)

    if (Object.keys(error).length > 0) {
      callError({ error, onSubmitError, dispatch, snackbarstate, setError })
    } else {
      if (dS.reason === 1) {
        const onConfirm = (reason) => {
          _reason = reason
          handleSave(type)
          dispatch(showformdialog({ yesno: false }))
        }
        dispatch(showformdialog({ yesno: true, message: "update", title: "Update", onConfirm }))
      } else {
        handleSave(type)
      }
    }
  }

  const adjustTDS = () => {
    const type = "tds_deduction"
    var other_type = ""
    var code = ""

    const meta = {}
    meta.formname = "Payment"
    meta.amount = formValues.amount

    if (formaction === "edit") {
      other_type = "edit"
      code = formValues.update_code
      meta.action = "edit"
    }
    if (!isblank(formValues.accountSelectedValue)) {
      if (parseFloat(getNumberonly(formValues.amount)) > 0) {
        var tdsDetail = formValues.tdsDetail
        if (!isblank(tdsDetail) && tdsDetail.hasOwnProperty("DetailGrid") && !isblank(tdsDetail.DetailGrid) && tdsDetail.DetailGrid.length > 0) {
          const metadata = {}
          metadata.data = tdsDetail.DetailGrid
          metadata.quarterSelectedValue = tdsDetail.quarterSelectedValue
          metadata.formname = "Payment"
          metadata.amount = formValues.amount
          metadata.action = "edit"
          dispatch(rightdrawerstate({ open: true, title: "Adjust TDS", data: metadata, position: "right", width: 1080 }))
        } else {
          dispatch(pendingTdsList({ type, other_type, meta, code }))
        }
      } else {
        dispatch(snackbarstate({ open: true, message: ErrorMessages["E00434"], snackbarType: "error" }))
      }
    }
  }
  useEffect(() => {
    var dropdown_string = "bankaccount"
    if (dS.customerListAuto === 1) {
      dropdown_string = dropdown_string + ",allaccount"
    }
    dropdown_string = checkdropdowndependancy(dropdown_string)
    if (dropdown_string !== "") {
      dispatch(
        fetch_form_dep({
          dropdown_string,
          form_type: form_type.type,
        })
      )
    } else {
      getInvoiceNumber()
    }

    return () => {
      clearformdata()
    }
  }, [])

  useEffect(() => {
    const form_values = pageformdata?.data?.form_data
    if ((pageformaction === "edit" || pageformaction === "draft_edit") && !isblank(pageformdata.data.form_data)) {
      pageformdata.data.form_data = AddNumberFormat({ obj: pageformdata.data.form_data, fields: format_fields })
      reset((formValues) => {
        return {
          ...formValues,
          ...pageformdata?.data?.form_data,
        }
      })
    }

    if (pageformaction === "edit") {
      if (form_values !== undefined) {
        if (!isblank(form_values.costcenter) && typeof form_values.costcenter === "string") {
          if (form_values.costcenter !== "") {
            setValue("costcenter", JSON.parse(decodeURIComponent(form_values.costcenter)))
          }
        }
      }

      setValue("narration", replaceBRtoNewLine(form_values.narration))

      if (dS.customerListAuto === 0) {
        if (form_values.hasOwnProperty("acccodeSelectedValue") && !isblank(form_values.acccodeSelectedValue) && form_values.acccodeSelectedValue.length > 0) {
          //&& form_values.acccodeSelectedValue !== form_values.acccodeSelectedValue )
          form_values.acccodeSelectedValue.map(function (find) {
            return dispatch({
              type: DROPDOWN_INSERTED,
              formtype: "allaccount",
              code: find.code,
              name: find.name,
              payload: find,
            })
          })
        } else {
          dispatch(snackbarstate({ open: true, message: ErrorMessages["E00390"], snackbarType: "error" }))
        }
      }
    }
  }, [pageformaction, pageformdata])

  useEffect(() => {
    if (prevDepLoading && !deploading && !isblank(formValues) && isblank(formValues.billNo)) {
      getInvoiceNumber()
    }
  }, [deploading, prevDepLoading])

  useEffect(() => {
    if (lastpagetype === "save_add_another" && lastpageaction === "start") {
      getInvoiceNumber()
      reset()
      saveandaddanother = false
      setLastTansDateV1({
        dateKey: "last_receiptdate",
        format: "YMD",
        params: { field: "date", setValue },
      })
      setTimeout(() => {
        document.getElementById("accountSelectedValue").focus()
      }, 500)
    }

    if (lastpagetype === "getnewbillno" && lastpageaction === "fetch") {
      setValue("billNo", lastpagedata.saleInvoiceNo)
      setBillNoLoading(false)
    }

    if (lastpagetype === "shortCut" && lastpageaction === "press") {
      var shortCutKey = lastpagedata.key
      if (shortCutKey === "SAVE") {
        if (formaction === "edit") {
          handleUpdate()
        } else {
          handleSave()
        }
      } else if (shortCutKey === "PRINT") {
        if (formaction === "edit") {
          handleprint()
        } else {
          handleSave("print")
        }
      } else if (shortCutKey === "DELETE") {
        if (formaction === "edit") {
          if (parseInt(getMenuRightsByType("Transaction", form_type.title)[2], 10) === 1) {
            handledelete()
          }
        }
      }
    }
  }, [lastpagetype, lastpageaction, lastpagetime, lastpagedata, formaction])

  useEffect(() => {
    if (prevDropdownupdated !== dropdownupdated) {
      if (dropdownupdated.formname === form_type.formName) {
        var dditem = null
        if (dropdownupdated.fieldtype === "other_account") {
          dditem = dropDownReceiptAllAccount.find((item) => parseInt(item.code) === parseInt(dropdownupdated.code))
        }
        if (isblank(dditem)) {
          setValue(dropdownupdated.fieldname, null)
        } else {
          setValue(dropdownupdated.fieldname, dditem.code)
        }
        if (dropdownupdated.fieldtype === "other_account") {
          onaccountchange(dditem)
        }
        dispatch(clear_dropDownUpdated())
      }
    }
  }, [dropdownupdated, prevDropdownupdated])

  useEffect(() => {
    if (prevDropDownReceiptAllAccount !== dropDownReceiptAllAccount && !isblank(formValues) && !isblank(formValues.accountSelectedValue)) {
      if (formaction === "edit") {
        if (!isblank(dropDownReceiptAllAccount) && dropDownReceiptAllAccount.length > 0 && !isblank(formValues.accountSelectedValue)) {
          const accDetail = dropDownReceiptAllAccount.find((f) => f.code === formValues.accountSelectedValue)
          if (!isblank(accDetail)) {
            if (accDetail.enableEmail) {
              setValue("sendEmailButton", true)
            } else {
              setValue("sendEmailButton", false)
            }
            if (accDetail.enableSMS) {
              setValue("sendSMSButton", true)
            } else {
              setValue("sendSMSButton", false)
            }
            if (accDetail.enableWhatsapp) {
              setValue("sendWhatsAppButton", true)
            } else {
              setValue("sendWhatsAppButton", false)
            }
          } else {
            setValue("sendSMS", false)
            setValue("sendSMSButton", false)
            setValue("sendWhatsApp", false)
            setValue("sendWhatsAppButton", false)
            setValue("sendEmail", false)
            setValue("sendEmailButton", false)
          }
        }
      }
    }
  }, [dropDownReceiptAllAccount, prevDropDownReceiptAllAccount])

  var adjusted = 0
  const setGridData = (data) => {
    data.forEach((e) => {
      if (!isblank(e.payment) && getNumberonly(e.payment) > 0) {
        adjusted += 1
      }
    })
  }

  if (!isblank(formValues[form_type.field])) {
    if (form_type.type === "receipt") {
      if (!isblank(formValues[form_type.field]["receivable_bills"]) && formValues[form_type.field]?.receivable_bills?.length > 0) {
        setGridData(formValues[form_type.field].receivable_bills)
      }
    } else {
      if (!isblank(formValues[form_type.field]["receivable_onaccount"]) && formValues[form_type.field].receivable_onaccount?.length > 0) {
        setGridData(formValues[form_type.field].receivable_onaccount)
      }
    }
  }

  if (formValues !== undefined) {
    costcenter = formValues.hasOwnProperty("costcenter") && formValues.costcenter.length > 0 ? formValues.costcenter.length : ""
  }

  if (formValues === undefined) {
    return <span></span>
  }
  const noSeries = replacePreFix(billNoSeries, getAccountSessionUserData)
  return (
    <div>
      <PageHeader
        drawerType={drawerType}
        {...drawerData}
        drawerIndex={drawerIndex}
        componentId={componentId}
        title={formaction === "edit" ? `Edit ${headerTitle}` : `Add New ${headerTitle}`}
        loading={loading}
        sidebarloading={sidebarloading}
        submitting={submitting}
        {...(dS.closing_balance === 1 && {
          balanceDetail: balanceDetail,
        })}
      />
      <div className={classes.sidebarContent} style={{ height: `calc(100vh - 96px)` }}>
        <div className={classes.wrapperContainer} style={{ paddingTop: 16 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={9}>
              <Paper variant="outlined" square elevation={0} style={{ padding: 8 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md lg>
                    {(new_doc_number === 1 || formaction === "edit") && (
                      <Field
                        name="billNo"
                        inputType={"RenderTextField"}
                        control={control}
                        isLoading={billNoLoading}
                        {...(noSeries && {
                          StartAdornment: noSeries,
                          AdornmentPosition: "start",
                        })}
                        label={`Doc No`}
                        trimSpace={true}
                        placeholder={`Enter docno`}
                        autoFocus={true}
                        isRequired={true}
                      />
                    )}

                    {dS.customerListAuto === 1 ? (
                      <Field
                        name="accountSelectedValue"
                        inputType={"RFCreatableSelect"}
                        control={control}
                        disabled={formaction === "edit" && parseInt(getNumberonly(formValues.invoice_adjusted)) > 0}
                        label="ACCOUNT"
                        mode="ALLACCOUNT"
                        placeholder="Select Account"
                        addRecentAllAccount={addRecentAllAccount}
                        autoFocus={!(new_doc_number === 1 || formaction === "edit")}
                        options={dropDownReceiptAllAccount.map(function (value) {
                          return { value: value.code, label: value.name, row: value }
                        })}
                        onvaluechange={onaccountchange}
                        isRequired={true}
                        onAddNewClick={addNewAccount}
                      />
                    ) : (
                      <Field
                        name="accountSelectedValue"
                        inputType={"RFAsyncSelect"}
                        control={control}
                        label="ACCOUNT"
                        mode="ALLACCOUNT"
                        placeholder="Select Account"
                        disabled={formaction === "edit" && parseInt(getNumberonly(formValues.invoice_adjusted)) > 0}
                        autoFocus={!(new_doc_number === 1 || formaction === "edit")}
                        formprops={props}
                        defaultOptions={dropDownReceiptAllAccount.map(function (value) {
                          return { value: value.code, label: value.name, row: value }
                        })}
                        options={dropDownReceiptAllAccount.map(function (value) {
                          return { value: value.code, label: value.name, row: value }
                        })}
                        modetype="allaccount"
                        onvaluechange={onaccountchange}
                        onAddNewClick={addNewAccount}
                        isRequired={true}
                      />
                    )}
                    <Field
                      name="bankSelectedValue"
                      inputType={"RFReactSelect"}
                      control={control}
                      label="BANK / CASH"
                      placeholder="Select Account"
                      disabled={loading || sidebarloading}
                      onvaluechange={onabankccountchange}
                      options={dropDownReceiptBank.map(function (value) {
                        return { value: value.code, label: value.name, row: value }
                      })}
                      isRequired={true}
                    />
                    <Field
                      name="amount"
                      inputType={"RenderTextField"}
                      control={control}
                      number={true}
                      label={type === "receiptdetail" ? "AMOUNT RECEIVED" : "AMOUNT"}
                      placeholder="Enter Amount"
                      isRequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} md lg>
                    <Field name="date" inputType={"RenderTextField"} control={control} label={type === "receiptdetail" ? "RECEIPT DATE" : "PAYMENT DATE"} type="date" isRequired={true} />
                    {bankSelected === true && (
                      <Grid container spacing={2}>
                        <Grid item xs={6} md lg>
                          <Field name="cheque" inputType={"RenderTextField"} control={control} label="CHEQUE NO" placeholder="Enter Cheque No" />
                        </Grid>
                        <Grid item xs={6} md lg>
                          <Field name="chequedate" inputType={"RenderTextField"} control={control} label="CHEQUE DATE" type="date" />
                        </Grid>
                      </Grid>
                    )}
                    <Field name="narration" inputType={"RenderTextField"} control={control} label="NARRATION" placeholder="" multiline minRows="2" fieldType="narration" />
                    {/* <Box p={2}>
                      <NotificationButtonsV2
                        sendSMSButton={formValues.sendSMSButton}
                        sendWhatsAppButton={formValues.sendWhatsAppButton}
                        sendEmailButton={formValues.sendEmailButton}
                        sendAdminButton={formValues.sendAdminButton}
                        control={control}
                      />
                    </Box> */}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={3}>
              <Paper variant="outlined" square elevation={0}>
                <Grid container spacing={0}>
                  {dS.cost_center === 1 && (
                    <Grid item xs={12} md={3} lg={12}>
                      <ListItem dense ContainerComponent="div" tabIndex="-1" divider button disabled={costCenterEnable} onClick={opencostCenter}>
                        <ListItemText
                          disableTypography
                          primary={<Typography>Cost Center {costcenter > 0 && <FBChip colorObj={{ type: "closed" }} style={{ marginLeft: 3 }} size="small" label={costcenter}></FBChip>}</Typography>}
                        />
                        <ListItemSecondaryAction>
                          <DebounceLink data-testid="add_cost_center_dl" component="button" variant="body2" tabIndex="-1" underline="always" disabled={costCenterEnable} onClick={opencostCenter}>
                            Add
                          </DebounceLink>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Grid>
                  )}

                  {dS.tds === 1 && type === "paymentdetail" && (
                    <Grid item xs={12} md={3} lg={12}>
                      <ListItem dense ContainerComponent="div" tabIndex="-1" divider button disabled={isblank(formValues.isTdsAccount)} onClick={adjustTDS}>
                        <ListItemText primary={`ADJUST TDS ${tds_adjusted}`} />
                        <ListItemSecondaryAction>
                          <DebounceLink
                            data-testid="view_tds_adjusted_dl"
                            component="button"
                            variant="body2"
                            tabIndex="-1"
                            underline="always"
                            disabled={isblank(formValues.isTdsAccount)}
                            onClick={adjustTDS}
                          >
                            View
                          </DebounceLink>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Grid>
                  )}

                  {dS.adjust_bill === 1 && isParty && (
                    <Grid item xs={12} md={3} lg={12}>
                      <ListItem dense ContainerComponent="div" tabIndex="-1" divider button disabled={isblank(formValues.accountSelectedValue)} onClick={adjustBill}>
                        <ListItemText
                          disableTypography
                          primary={<Typography>Adjust Bill {adjusted > 0 && <FBChip colorObj={{ type: "closed" }} style={{ marginLeft: 3 }} size="small" label={adjusted}></FBChip>}</Typography>}
                        />
                        <ListItemSecondaryAction>
                          <DebounceLink
                            data-testid="view_bill_adjusted_dl"
                            component="button"
                            variant="body2"
                            tabIndex="-1"
                            underline="always"
                            disabled={isblank(formValues.accountSelectedValue)}
                            onClick={adjustBill}
                          >
                            View
                          </DebounceLink>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer
        drawerType={drawerType}
        {...drawerData}
        drawerIndex={drawerIndex}
        componentId={componentId}
        classes={classes}
        {...(parseInt(getMenuRightsByType({ parent: "Transaction", name: form_type.title })[2], 10) === 1 &&
          formaction === "edit" && {
            onDeleteClick: handledelete,
          })}
        {...(formaction === "edit"
          ? { onUpdateAndPrintClick: () => handleUpdate("print"), onUpdateClick: handleUpdate, onUpdateAndShareClick: () => handleUpdate("share") }
          : { onSaveClick: handleSave, onPrintSaveClick: () => handleSave("print"), saveDraft: saveDrafts, onSaveAndShareClick: () => handleSave("share") })}
        {...(formaction !== "clone" &&
          formaction !== "draft_edit" &&
          formaction !== "edit" && {
            onSaveandAnotherClick: handlesaveandanother,
          })}
        onDismissClick={toggleFormDrawer(false, `${form_type.type}detail`)}
        loading={loading}
        sidebarloading={sidebarloading}
        submitting={submitting}
      />
    </div>
  )
}

export default ModalTransactionSingle
