import React from "react"
import LabelComponent from "../common/labelComponent"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const RenderRadioButton = ({ options, label, isRequired, input, error, noLableMargin, touched, labelStyle, ...field }) => {
  const { name, onBlur, onChange, value } = input
  return (
    <React.Fragment>
      <LabelComponent label={label} noLableMargin={noLableMargin} isRequired={isRequired} error={touched && error} labelStyle={labelStyle} />
      <RadioGroup
        field={field}
        value={value}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value)
          }
        }}
      >
        {options.map((option, index) => (
          <FormControlLabel key={index} control={<Radio />} label={option.label} value={option.value} />
        ))}
      </RadioGroup>
    </React.Fragment>
  )
}
export default RenderRadioButton
