import { IMPORT_UNIT_INSERTED_SUCCESS, IMPORT_UNIT_LOCAL_SEARCH, IMPORT_UNIT_SERVICE_FAIL, IMPORT_UNIT_SERVICE_START, IMPORT_UNIT_SERVICE_SUCCESS } from "../Actions/types"

const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  totalcount: 0,
  rowsPerPage: 0,
  page: 0,
  order: "asc",
  orderBy: "name",
  search_text: "",
  columnData: [
    { id: "name", numeric: false, visible: true, label: "Unit Code", width: 125 },
    { id: "unit_name", numeric: false, visible: true, label: "Unit Name" },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IMPORT_UNIT_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case IMPORT_UNIT_LOCAL_SEARCH:
      return { ...state, search_text: action.search_text }
    case IMPORT_UNIT_SERVICE_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        data: action.payload.data.result,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
        islogin: true,
      }
    case IMPORT_UNIT_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        data: [],
        loading: false,
        totalcount: 0,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
      }
    case IMPORT_UNIT_INSERTED_SUCCESS:
      var imported_array = action.payload.DetailGrid.map((e) => parseInt(e.code))
      var finalArray = state.data.filter((item) => !imported_array.includes(parseInt(item.code)))
      return { ...state, data: finalArray }
    default:
      return state
  }
}
