import { getStorage, removeStorage } from "@project/components/sessionStoreFunctions"
import {
  commonSiteSetting,
  checkCustomPageDependancy as common_checkCustomPageDependancy,
  deletedataFromForm as common_deletedataFromForm,
  getAccountRightsByID as common_getAccountRightsByID,
  common_getAccountSitesetting,
  getCodeOfCustomPageByName as common_getCodeOfCustomPageByName,
  getNameFromType as common_getNameFromType,
  common_getSettingForUpdate,
  setColumnDataForFilter as common_setColumnDataForFilter,
  setCustomFieldData as common_setCustomFieldData,
  setCustomFieldData_all as common_setCustomFieldData_all,
  getOrderMenuidTypes,
} from "@project/components/utilityFunctions"
import { exportAsExcel } from "@project/components/xlsxFunctions"
import { postErrorOnException as common_postErrorOnException, getPostData, getPostToken, getPostUrl } from "@project/sharedcomponents/axiosFunctions"
import menuId from "@project/sharedcomponents/constantData/menuIdEnum"
import {
  DatetoDMY,
  DatetoYMD,
  account_checkdropdowndependancy,
  amount_format,
  amount_formatDecimal,
  checkCondition,
  checkRenderConditionV1,
  cloneObj,
  amountFormatOfRate as common_amountFormatOfRate,
  getAllStates as common_getAllStates,
  getAllStatesOfcountry as common_getAllStatesOfcountry,
  getCompanyLock as common_getCompanyLock,
  getOnlyInvoice as common_getOnlyInvoice,
  getStateName as common_getStateName,
  common_getSubTypeWiseSetting,
  common_getTypeWiseKeySetting,
  isValidFinancialDate as common_isValidFinancialDate,
  replacePreFix as common_replacePreFix,
  setFormatOfInOutDetailGrid as common_setFormatOfInOutDetailGrid,
  setFormatOfInvoiceDetailGrid as common_setFormatOfInvoiceDetailGrid,
  valueOfRate as common_valueOfRate,
  convertIntif,
  createDropDown,
  format_excel_field,
  formateMobile,
  getCatchErrorMessage,
  getCheckBoxValue,
  getCustomFieldColumn,
  getNumberonly,
  getNumberonlyDecimal,
  isBlankCode,
  isValidDate,
  isblank,
  makeExcelRequireCol,
  makeExcelRequiredData,
  setSetting,
  toFixedFn,
} from "@project/sharedcomponents/utilityFunctions"

import axios from "axios"
import { push } from "connected-react-router"
import lodashFpMap from "lodash/fp/map"
import lodashOmit from "lodash/fp/omit"
import lodashMap from "lodash/map"
import lodashSum from "lodash/sum"
import { isDesktop } from "react-device-detect"
import { change, getFormValues, reset } from "redux-form"
import { LAST_PAGE_UPDATE, LOGOUT_USER_SUCCESS, RESET_COMMON_REDUCER } from "../Actions/types"
import store from "../store"

let navigate

// Configure a setter for navigate
export const setNavigate = (navigateFn) => {
  navigate = navigateFn
}

export const goToNavigate = (path, data) => {
  navigate(path, { state: data })
}

export const postErrorOnException = (error) => {
  common_postErrorOnException(error, "", "FinalBooks Web", getAccountSessionUserData("company_url"), getAccountSessionUserData("name"))
}

export const getSubDomain = () => {
  var full = window.location.host
  var parts = full.split(".")
  var subdomain = parts[0]
  return subdomain
}

export const getAllPurchaseOrderStatus = () => {
  return [
    {
      id: "0",
      name: "Pending",
    },
    {
      id: "1",
      name: "Partially",
    },
    {
      id: "2",
      name: "Closed",
    },
  ]
}

export const handleResponse = (response, dispatch) => {
  if (isblank(response) || isblank(response.data) || isblank(response.data.meta) || isblank(response.data.meta.code)) {
    response.response = response
    postErrorOnException(response)
    response = {
      data: {
        meta: {
          code: 401,
          message: getCatchErrorMessage(),
        },
      },
    }
  } else {
    if (response.data.meta.code === 401 && !isblank(response.data.db_error)) {
      var currentDateTime = new Date().toString()
      var error_data = {
        error: {
          version: process.env.REACT_APP_VERSION,
          name: "Database Error",
        },
        errorInfo: {
          db_error: response.data.db_error,
          post: getPostData(response),
          url: getPostUrl(response),
          token: getPostToken(response),
        },
        company_url: getAccountSessionUserData("company_url"),
        path: window.location.href,
        currentDateTime,
      }
      error_data = Array.isArray(error_data) ? error_data.map(JSON.stringify) : JSON.stringify(error_data)

      let data = new FormData()
      data.append("error_data", error_data)

      axios({
        method: "post",
        url: process.env.REACT_APP_ERROR_URL,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          version: process.env.REACT_APP_VERSION,
          devicetype: isDesktop ? 0 : 3,
          "X-Server-Select": "account_" + getSubDomain(),
        },
        data: data,
      })
        .then((response) => {})
        .catch((error) => {
          postErrorOnException(error)
        })
    }
    if (checkCondition(response.data.meta.code, [205, 204, 402, 207, 208])) {
      var companyLoginYears = getStorage("companyLoginYears")
      if (isblank(companyLoginYears)) {
        companyLoginYears = []
      }
      companyLoginYears.forEach((year) => {
        removeStorage(`auth_token_${year}`)
        removeStorage(`user_data_${year}`)
        removeStorage(`site_setting_${year}`)
        removeStorage(`user_rights_${year}`)
        removeStorage(`userModuleRights_${year}`)
        removeStorage(`lastTransDate_${year}`)
        removeStorage(`menu_data_${year}`)
        removeStorage(`report_data_${year}`)
        removeStorage(`company_${year}`)
        removeStorage(`config_setting_${year}`)
        removeStorage(`customPages_${year}`)
      })

      dispatch({
        type: LAST_PAGE_UPDATE,
        lasttype: "config_setting",
        lastaction: "updated",
        lasttime: new Date().getTime(),
        lastdata: { config_setting: "" },
      })

      removeStorage("firm_years")
      removeStorage("last_visited_page")
      removeStorage("last_visited_page_data")
      removeStorage("last_menu_key")
      removeStorage("companyLoginYears")
      dispatch(reset("headerSearchForm"))
    }
    if (response.data.meta.code === 205) {
      dispatch({
        type: LOGOUT_USER_SUCCESS,
        payload: null,
      })
      dispatch({
        type: RESET_COMMON_REDUCER,
      })
      goToNavigate("/subscriptionexpired", { meta: response.data.meta })
    }
    if (response.data.meta.code === 204) {
      dispatch(reset("headerSearchForm"))
      dispatch({
        type: LOGOUT_USER_SUCCESS,
        payload: null,
      })
      dispatch({
        type: RESET_COMMON_REDUCER,
      })
      response.data.meta.message = "Site maintenance"
      goToNavigate("/sitemaintenance", { meta: response.data.meta })
    }
    if (response.data.meta.code === 206) {
      dispatch()
      goToNavigate("/notfound", { meta: response.data.meta })
    }
    if (response.data.meta.code === 402) {
      dispatch({
        type: LOGOUT_USER_SUCCESS,
        payload: null,
      })
      dispatch({
        type: RESET_COMMON_REDUCER,
      })
      goToNavigate("/login")
    }
    if (response.data.meta.code === 207) {
      dispatch({
        type: LOGOUT_USER_SUCCESS,
        payload: null,
      })
      dispatch({
        type: RESET_COMMON_REDUCER,
      })
      goToNavigate("/upgradeplan", { meta: response.data.meta })
    }
    if (response.data.meta.code === 208) {
      dispatch({
        type: LOGOUT_USER_SUCCESS,
        payload: null,
      })
      dispatch({
        type: RESET_COMMON_REDUCER,
      })
      goToNavigate("/trialexpired", { meta: response.data.meta })
    }
    if (response.data.meta.code === 209) {
      dispatch({
        type: LAST_PAGE_UPDATE,
        lasttype: "new_version",
        lastaction: "force_update",
        lasttime: new Date().getTime(),
      })
    }
    if (checkCondition(response.data.meta.code, [402, 206, 209])) {
      response.data.meta.code = 401
      response.data.meta.message = "-401"
    } else if (checkCondition(response.data.meta.code, [204, 205, 207, 208])) {
      response.data.meta.code = 401
    }
  }
  return response
}

export const isValidFinancialDate = ({ dtStr }) => {
  return common_isValidFinancialDate({ dtStr, user_data: "", getAccountSessionUserData })
}

export const getTransactionsDay = (format) => {
  var financial_year = parseInt(getAccountSessionUserData("financial_year"), 10)
  var currentDate = new Date()
  var twoDigitMonth = currentDate.getMonth() + 1 >= 10 ? currentDate.getMonth() + 1 : "0" + (currentDate.getMonth() + 1)
  var twoDigitDate = currentDate.getDate() >= 10 ? currentDate.getDate() : "0" + currentDate.getDate()
  var fullDate = currentDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate

  if (!isValidFinancialDate({ dtStr: fullDate })) {
    if (format === "YMD") {
      return currentDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate
    } else if (format === "DMY") {
      return twoDigitDate + "-" + twoDigitMonth + "-" + currentDate.getFullYear()
    }
  } else {
    if (format === "YMD") {
      return `${financial_year + 1}-03-31`
    } else if (format === "DMY") {
      return `31-03-${financial_year + 1}`
    }
  }
}

export const fetchUniqueItem = (arr, key) => {
  const uniqueItem = []

  arr.forEach((item) => {
    if (!uniqueItem.includes(item[key])) {
      uniqueItem.push(item[key])
    }
  })
  return uniqueItem
}

export const detailUnitOpt = () => {
  return [
    {
      id: "0",
      name: "No",
    },
    {
      id: "1",
      name: "Yes - New Column",
    },
    {
      id: "2",
      name: "Yes - with Qty",
    },
  ]
}

export const detailTaxOpt = () => {
  return [
    {
      id: "0",
      name: "No",
    },
    {
      id: "1",
      name: "Tax Amount and Tax %",
    },
    {
      id: "2",
      name: "Tax Amount only",
    },
    {
      id: "3",
      name: "Tax % only",
    },
  ]
}

export const detailNarOpt = () => {
  return [
    {
      id: "0",
      name: "No",
    },
    {
      id: "1",
      name: "Yes - New Column",
    },
    {
      id: "2",
      name: "Yes - with Item",
    },
    {
      id: "3",
      name: "Yes - with No Item",
    },
  ]
}

export const getAllStockType = () => {
  return [
    {
      id: "0",
      name: "",
    },
    {
      id: "0",
      name: "",
    },
  ]
}

export const jwelleryMetalType = () => {
  return [
    {
      id: "0",
      name: "Both - Gold & Silver",
    },
    {
      id: "1",
      name: "Only Gold",
    },
    {
      id: "2",
      name: "Only Silver",
    },
  ]
}

export const getCustomPrintOpt = () => {
  return [
    {
      id: "0",
      name: "In New Column",
    },
    {
      id: "1",
      name: "Below the Item",
    },
    {
      id: "2",
      name: "With Item",
    },
    {
      id: "3",
      name: "Before the Item",
    },
    {
      id: "4",
      name: "Manual",
    },
  ]
}

export const isValidLedgerFromDate = (dtStr) => {
  if (isblank(dtStr)) {
    return false
  }

  var minYear = parseInt(getAccountSessionUserData("financial_year"), 10) - 1
  var maxYear = minYear + 2
  var parms = DatetoYMD(dtStr).split(/[.\-/]/)
  var yyyy = parseInt(parms[0], 10)
  var mm = parseInt(parms[1], 10)

  if (yyyy < minYear || yyyy > maxYear) {
    return "Please enter a valid financial year date,between 01-04-" + minYear + " and 31-03-" + maxYear
  }

  if ((mm <= 3 && yyyy === minYear) || (mm >= 4 && yyyy === maxYear)) {
    return "Please enter a valid financial year date,between 01-04-" + minYear + " and 31-03-" + maxYear
  }
  return false
}

export const getconfigsettingdata = (key) => {
  const state = store.getState()
  const { config_setting } = state.AuthReducer
  var firmdetails = config_setting
  if (isblank(firmdetails)) {
    firmdetails = getStorage(`config_setting_${getWindowYear()}`)
  }
  return !isblank(firmdetails) ? (firmdetails.hasOwnProperty(key) ? firmdetails[key] : "") : ""
}

export const getInvPrnTem = () => {
  return [
    {
      id: "new",
      name: "New",
    },
    {
      id: "old",
      name: "Old",
    },
  ]
}

export const getPackingSliptype = () => {
  return [
    {
      id: "S",
      name: "Single",
    },
    {
      id: "M",
      name: "Multiple",
    },
  ]
}

export const getmenudatafromname = (name) => {
  var menudetails = getMenuData()
  return menudetails !== undefined ? (menudetails.hasOwnProperty(name) ? menudetails[name] : []) : []
}

export const getAllCustomPages = () => {
  const state = store.getState()
  const { customPages, customPagesSet } = state.AuthReducer
  if (customPagesSet === true) {
    return customPages
  } else {
    var custom_Pages = getStorage(`customPages_${getWindowYear()}`, "")
    if (!isblank(custom_Pages) && custom_Pages.length > 0) {
      return custom_Pages
    }
  }
  return []
}

export const getMenuData = () => {
  const state = store.getState()
  const { all_menu, all_menuSet } = state.CommonReducer
  if (all_menuSet === true && Object.keys(all_menu).length > 0) {
    return all_menu
  } else {
    return getStorage(`menu_data_${getWindowYear()}`, "")
  }
}

export const getWindowYear = () => {
  var fyear = window.location.pathname
  fyear = fyear.replace("/", "")
  fyear = fyear.split("/")[0]
  if (isNaN(fyear)) {
    fyear = ""
    var companyLoginYears = getStorage("companyLoginYears", "")
    if (!isblank(companyLoginYears)) {
      companyLoginYears = companyLoginYears.map((e) => parseInt(e))
      companyLoginYears.sort(function (a, b) {
        return b - a
      })
      if (companyLoginYears.length > 0) {
        fyear = companyLoginYears[0]
      }
    }
  }
  return fyear
}

export const getsitesetting = (key, userData, type) => {
  var firmdetails = ""
  firmdetails = getStorage(`site_setting_${getWindowYear()}`, "")
  firmdetails = setSetting({ settingData: firmdetails, rights: getUSerRights() })
  const meta = {
    store,
    getAccountViewRightsByID,
    checkRightsAndSiteSetting,
  }
  return commonSiteSetting({ key, settingData: firmdetails, type, meta })
}

export const getSiteSetting = (key) => {
  const state = store.getState()
  const { accountSiteSettingSet } = state.AuthReducer
  if (accountSiteSettingSet === true) {
    return getAccountSitesetting(key)
  } else {
    return getsitesetting(key)
  }
}

export const checkViewrightsV1 = ({ url, location, setLastMenuKey, dispatch }) => {
  let menuKey = ""
  if (
    url === "welcome" ||
    url === "invite" ||
    url === "newdashboard" ||
    url === "quicksell" ||
    url === "paymentsuccess" ||
    url === "sitesettingsold" ||
    url === "" ||
    url === "manageorganizations" ||
    url === "onboarding"
  ) {
    return { isAllow: true, menuKey }
  } else if (url === "tdscategorymaster" && getSiteSetting("tds") === 1) {
    return { isAllow: true, menuKey }
  } else if (url === "catalogueusers") {
    return { isAllow: true, menuKey }
  } else if (url.indexOf("pages") > -1) {
    var customPages = getAllCustomPages()
    if (!isblank(customPages) && customPages.length > 0) {
      return { isAllow: true, menuKey }
    }
  } else if (url.indexOf("report/") > -1) {
    return { isAllow: validReportUrl(location.pathname), menuKey }
  } else if ((url === "shipping/add" || location.pathname === "/shipping/add") && checkViewrightsV1({ url: "shipping", location: "" }).isAllow) {
    return { isAllow: true, menuKey }
  } else {
    var splitedurl = url.split("/")[0]
    if (splitedurl === "invoice" || splitedurl === "purchaseinvoices" || splitedurl === "outwards" || splitedurl === "inwards" || splitedurl === "process") {
      var newPathname = cloneObj(location.pathname)
      newPathname = newPathname.replace("/", "")
      var splitedPathname = newPathname.split("/")
      if (!isNaN(splitedPathname[0])) {
        splitedPathname = splitedPathname.filter((e, ind) => ind !== 0)
      } else {
        splitedPathname = ["", ...splitedPathname]
      }
      if (splitedPathname[1] === "invoice") {
        splitedPathname[1] = "salesinvoices"
      }
      splitedPathname = splitedPathname.filter((e) => !isblank(e))
      if (!isblank(splitedPathname[1])) {
        splitedPathname[1] = splitedPathname[1].replace("-", "_")
      }
      if (
        (splitedurl === "process" &&
          (splitedPathname[1] === "lotmaster" || splitedPathname[1] === "job_inward" || splitedPathname[1] === "jobprocessinvoice" || splitedPathname[1] === "finish_stock")) ||
        splitedurl !== "process"
      ) {
        if (splitedPathname[1] === "lotmaster") {
          splitedPathname[0] = "outwards"
          splitedPathname[1] = "manage_lot_master"
        } else if (splitedPathname[1] === "job_inward") {
          splitedPathname[0] = "inwards"
          splitedPathname[1] = "job_inward"
        } else if (splitedPathname[1] === "jobprocessinvoice") {
          splitedPathname[0] = "purchaseinvoices"
          splitedPathname[1] = "job_process_invoice"
        } else if (splitedPathname[1] === "finish_stock") {
          splitedPathname[0] = "inwards"
          splitedPathname[1] = "finish_stock"
        } else if (splitedPathname[0] === "invoice") {
          splitedPathname[0] = "salesinvoices"
        }
        return checkInvoiceRightsV1({ location, url: splitedurl, pathname: splitedPathname, setLastMenuKey, dispatch })
      }
    }
  }
  var menudetails = getMenuData()
  var menuId = getmenuId(url)
  if (url === "labeldesign" || url === "pagetemplate") {
    menuId = "191"
  }
  if (setLastMenuKey) {
    menuKey = menuId
    // dispatch(setLastMenuKey({ key: menuId }))
  }
  if (menuId === "213" && getSiteSetting("shipping_module") !== 1) {
    return { isAllow: false, menuKey }
  }
  if (menuId === "214" && getSiteSetting("payment_module") !== 1) {
    return { isAllow: false, menuKey }
  }
  var mainArray = []
  if (isblank(menudetails) || isblank(menuId)) {
    return { isAllow: false, menuKey }
  }
  if (menudetails.hasOwnProperty("Dashboard")) {
    mainArray.push({ id: "1000", name: "Dashboard" })
  }
  if (menudetails.hasOwnProperty("Utilities")) {
    mainArray.push({ id: "1001", name: "Utilities" })
  }
  if (menudetails.hasOwnProperty("Bot Factory")) {
    mainArray.push({ id: "208", name: "Bot Factory" })
  }

  mainArray.push({ id: "1002", name: "Reports" })

  Object.keys(menudetails).forEach((item) => {
    menudetails[item].forEach((it) => {
      mainArray.push(it)
    })
  })
  if (mainArray.filter((e) => e.id === menuId).length > 0) {
    if (checkCondition(menuId, [1000, 1001, 208, 1002])) {
      return { isAllow: true, menuKey }
    } else {
      return { isAllow: checkRightsAndSiteSetting(menuId), menuKey }
    }
  }
  return { isAllow: false, menuKey }
}

export const validReportUrl = (url) => {
  var isValid = false
  var urls = url.split("/")
  if (urls.length === 5) {
    if (urls[2] === "report") {
      var ReportsList = getAllReports()
      if (isblank(ReportsList) || Object.keys(ReportsList).length === 0) {
        return false
      }
      Object.keys(ReportsList).forEach((cat) => {
        var catKeyForCompare = cat.toLowerCase().replace(/ /g, "-")
        if (urls[3] === catKeyForCompare) {
          if (!isblank(ReportsList[cat]) && ReportsList[cat].length > 0) {
            ReportsList[cat].forEach((report) => {
              if (!isblank(report.name)) {
                var reportNameForCmp = report.name.toLowerCase().replace(/ /g, "-")
                if (urls[4] === reportNameForCmp) {
                  isValid = true
                }
              }
            })
          }
        }
      })
      return isValid
    }
    return false
  }
  return false
}

/*export const checkInvoiceRights = ({ location, url, pathname, setLastMenuKey, dispatch }) => {
  var menudetails = getMenuData()
  var InvoiceMenu = ""
  if (!isblank(menudetails) && !isblank(menudetails.Invoice)) {
    if (!isblank(location.state) && typeof location.state === "string") {
      let matchKey = location.state === "op_inward" ? "inward" : location.state === "op_outward" ? "outward" : location.state
      if (menudetails.Invoice.filter((e) => e.type === matchKey).length > 0) {
        InvoiceMenu = menudetails.Invoice.filter((e) => e.type === matchKey)[0]
        dispatch(setLastMenuKey({ key: InvoiceMenu.id }))
        return true
      }
      return false
    } else {
      let matchKey1 = pathname[1] === "op_inward" ? "inward" : pathname[1] === "op_outward" ? "outward" : pathname[1]
      InvoiceMenu = menudetails.Invoice.filter((e) => e.main_type === pathname[0] && e.type === matchKey1)
      if (InvoiceMenu.length > 0) {
        dispatch(setLastMenuKey({ key: InvoiceMenu[0].id }))
        if (!isblank(location.state)) {
          return true
        }
        return pathname[1]
      }
      return false
    }
  }
  return false
}*/

export const checkInvoiceRightsV1 = ({ location, url, pathname, setLastMenuKey, dispatch }) => {
  let menuKey = ""
  var menudetails = getMenuData()
  var InvoiceMenu = ""
  if (!isblank(menudetails) && !isblank(menudetails.Invoice)) {
    if (!isblank(location.state) && typeof location.state === "string") {
      let matchKey = location.state === "op_inward" ? "inward" : location.state === "op_outward" ? "outward" : location.state
      if (menudetails.Invoice.filter((e) => e.type === matchKey).length > 0) {
        InvoiceMenu = menudetails.Invoice.filter((e) => e.type === matchKey)[0]
        menuKey = InvoiceMenu.id
        return { isAllow: true, menuKey }
      }
      return { isAllow: false, menuKey }
    } else {
      let matchKey1 = pathname[1] === "op_inward" ? "inward" : pathname[1] === "op_outward" ? "outward" : pathname[1]
      InvoiceMenu = menudetails.Invoice.filter((e) => e.main_type === pathname[0] && e.type === matchKey1)
      if (InvoiceMenu.length > 0) {
        menuKey = InvoiceMenu[0].id
        if (!isblank(location.state)) {
          return { isAllow: true, menuKey }
        }
        return { isAllow: pathname[1], menuKey }
      }
      return { isAllow: false, menuKey }
    }
  }
  return { isAllow: false, menuKey }
}

export const getAllReports = () => {
  const state = store.getState()
  const { report_data } = state.AuthReducer
  if (Object.keys(report_data).length > 0) {
    return report_data
  } else {
    return getStorage(`report_data_${getWindowYear()}`, "")
  }
}

export const getReportRights = (reportId) => {
  var ReportsList = getAllReports()
  var newreport = []
  if (isblank(ReportsList)) {
    return false
  }
  Object.keys(ReportsList).forEach((key) => {
    ReportsList[key].forEach((report) => {
      newreport.push(convertIntif(report.reportid))
    })
  })
  return newreport.includes(convertIntif(reportId))
}

export const gstbusinesstype = (key) => {
  if (key === "1") {
    return "Registered Business - Regular"
  } else if (key === "2") {
    return "Registered Business - Composition"
  } else if (key === "3") {
    return "Unregistered Business"
  } else if (key === "4") {
    return "Consumer"
  } else if (key === "5") {
    return "Overseas"
  } else if (key === "6") {
    return "Special Economic Zone"
  }
}

export const menukeys = {}
menukeys[menuId.Dashboard] = "dashboard"
menukeys[menuId.Product] = "products"
menukeys[menuId.Customer] = "customers"
menukeys[menuId.State] = "states"
menukeys[menuId.ConfigurableProduct] = "configurableproducts"
menukeys[menuId.City] = "cities"
menukeys[menuId.EnvelopePrinting] = "envelopeprinting"
menukeys[menuId.User] = "users"
menukeys[menuId.Account] = "accounts"
menukeys[menuId.Units] = "units"
menukeys[menuId.ProductGroup] = "productgroups"
menukeys[menuId.Transport] = "transports"
menukeys[menuId.Company] = "companies"
menukeys[menuId.Agent] = "agents"
menukeys[menuId.Salesman] = "salesmans"
menukeys[menuId.CustomerSupplier] = "suppliers"
menukeys[menuId.PurchaseInvoice] = "purchaseinvoices"
menukeys[menuId.Receipt] = "receipts"
menukeys[menuId.JournalEntries] = "journalentries"
menukeys[menuId.DebitNote] = "debitnotes"
menukeys[menuId.SalesInvoice] = "salesinvoices"
menukeys[menuId.TaxSchemes] = "taxschemes"
menukeys[menuId.Payment] = "payments"
menukeys[menuId.Inward] = "inwards"
menukeys[menuId.Outward] = "outwards"
menukeys[menuId.CreditNote] = "creditnotes"
menukeys[menuId.StockTransfer] = "stocktransfer"
menukeys[menuId.OpeningBalance] = "openingbalances"
menukeys[menuId.ClosingStock] = "closingstocks"
menukeys[menuId.CustomerGroup] = "partygroups"
menukeys[menuId.ProductSubGroup] = "productsubgroups"
menukeys[menuId.ProductSubGroupLevel2] = "productsubgroupslevel2"
menukeys[menuId.BundleProducts] = "bundleproducts"
menukeys[menuId.PartyPriceList] = "partypricelist"
menukeys[menuId.PartyGroupPrice] = "partygroupprice"
menukeys[menuId.Utilities] = "utilities"
menukeys[menuId.Reports] = "reports"
menukeys[menuId.Countries] = "countries"
menukeys[menuId.RecordExpenses] = "recordexpenses"
menukeys[menuId.ExpensePayment] = "expensepayments"
menukeys[menuId.StockCapital] = "stockcapital"
menukeys[menuId.WireTransfer] = "wiretransfers"
menukeys[menuId.CostCenter] = "costcenter"
menukeys[menuId.OpeningStock] = "openingstock"
menukeys[menuId.EwayBill] = "ewaybills"
menukeys[menuId.Godown] = "godowns"
menukeys[menuId.GodownStockTransfer] = "godownstocktransfer"
menukeys[menuId.Lot] = "lot"
menukeys[menuId.ProcessTransfer] = "process/transfer"
menukeys[menuId.Process] = "process"
menukeys[menuId.Bot] = "bot"
menukeys[menuId.AdjustBill] = "adjustbill"
menukeys[menuId.UserRights] = "userrights"
menukeys[menuId.SiteSetting] = "sitesettings"
menukeys[menuId.Export] = "export"
menukeys[menuId.Import] = "import"
menukeys[menuId.MergeAccount] = "mergeaccounts"
menukeys[menuId.ChangePassword] = "changepassword"
menukeys[menuId.ImportBankStatement] = "importbankstatement"
menukeys[menuId.GroupSms] = "groupsms"
menukeys[menuId.BackupDb] = "backupdb"
menukeys[menuId.Billing] = "billings"
menukeys[menuId.WireTransfer] = "wiretransfers"
menukeys[menuId.StockCapital] = "stockcapital"
menukeys[menuId.ImportDataFb] = "importdatafb"
menukeys[menuId.UserHistories] = "userhistories"
menukeys[menuId.MergeProducts] = "mergeproducts"
menukeys[menuId.ChangeYear] = "changeyear"
menukeys[menuId.SendSms] = "sendsms"
menukeys[menuId.EmailTemplates] = "emailtemplates"
menukeys[menuId.SmsTemplate] = "smstemplate"
menukeys[menuId.CustomField] = "customfield"
menukeys[menuId.CustomPage] = "custompage"
menukeys[menuId.Shipping] = "shipping"
menukeys[menuId.PaymentUtility] = "paymentutility"
menukeys[menuId.GstOldPendingInvoice] = "gstoldpendinginvoices"
menukeys[menuId.TallyExport] = "tallyexport"
menukeys[menuId.ProcessMergePackingSlip] = "process/mergepackingslip"
menukeys[menuId.ProcessDashboard] = "process/dashboard"
menukeys[menuId.BankReconciliation] = "bankreconciliation"
menukeys[menuId.ChequePrinting] = "chequeprinting"
menukeys[menuId.Catalogue] = "catalogues"
menukeys[menuId.NotificationTemplate] = "notification-templates"
menukeys[menuId.MergeCity] = "mergecity"
menukeys[menuId.SubGroup] = "managesubgroup"
menukeys[menuId.ProductSearch] = "productsearch"
menukeys[menuId.Notification] = "notification"
menukeys[menuId.DiscountCoupons] = "discount-coupons"
menukeys[menuId.MergeGodown] = "mergegodown"
menukeys[menuId.Webhook] = "webhook"

export const getmenukey = (id) => {
  return menukeys[id]
}

export const getmenuId = (url) => {
  var id = ""
  Object.keys(menukeys).forEach((it) => {
    if (menukeys[it] === url) {
      id = it
    }
  })
  return id
}

export const getmenufromname = (name) => {
  return Object.keys(menukeys).find((key) => menukeys[key] === name)
}

export const getAllNotifyType = () => {
  return [
    {
      id: "1",
      name: "Customer",
    },
    {
      id: "2",
      name: "User",
    },
  ]
}

export const getBotTransactionType = () => {
  return [
    {
      id: "1",
      name: "Transaction",
    },
    {
      id: "2",
      name: "Report",
    },
  ]
}

export const getReportName = () => {
  return [
    {
      id: "11",
      name: "Multi Ledger Report",
    },
    {
      id: "45",
      name: "Multi Outstanding Report",
    },
  ]
}

export const getAllImageType = () => {
  return [
    { id: "normal", name: "Normal" },
    { id: "square", name: "Square" },
  ]
}

export const getcomcaltype = () => {
  return [
    {
      id: "1",
      name: "Net Amount",
    },
    {
      id: "2",
      name: "Gross Amount",
    },
  ]
}

export const getfinancialyear = () => {
  const state = store.getState()
  const { financialyears, financialyearsSet } = state.AuthReducer
  if (financialyearsSet === true) {
    return financialyears
  } else {
    var firmyears = getStorage("firm_years")
    if (firmyears === undefined || firmyears === null) {
      firmyears = []
    }
    return firmyears
  }
}

export const getmyorganisation = () => {
  var firms = getStorage(`company_${getWindowYear()}`)
  if (firms === undefined || firms === null || firms === "") {
    firms = []
  }
  return firms
}

export const getallcontact = () => {
  return [
    {
      id: "0",
      name: "All",
    },
    {
      id: "1",
      name: "Customer",
    },
    {
      id: "2",
      name: "Supplier",
    },
  ]
}

export const getCalcType = () => {
  return [
    {
      id: "N",
      name: "Normal",
    },
    {
      id: "R",
      name: "Round Off",
    },
  ]
}

export const gettransactionType = () => {
  return [
    {
      id: "sales_invoice",
      name: "Sales Invoice",
    },
    {
      id: "credit_note",
      name: "Credit Note",
    },
    {
      id: "debit_note",
      name: "Debit Note",
    },
    {
      id: "inward",
      name: "Inward",
    },
    {
      id: "outward",
      name: "Outward",
    },
  ]
}

export const getamountthreshold = () => {
  return [
    {
      id: "0",
      name: ">= 50,000",
    },
    {
      id: "1",
      name: "All",
    },
  ]
}

export const getsearchdropdown = () => {
  return [
    {
      id: "0",
      name: "Customers",
    },
    {
      id: "1",
      name: "Suppliers",
    },
    {
      id: "2",
      name: "Sales Invoice",
    },
    {
      id: "3",
      name: "Purchase Invoice",
    },
    {
      id: "4",
      name: "Inward",
    },
    {
      id: "5",
      name: "Outward",
    },
    {
      id: "6",
      name: "Credit Note",
    },
    {
      id: "7",
      name: "Debit Note",
    },
    {
      id: "8",
      name: "Receipt",
    },
    {
      id: "9",
      name: "Payment",
    },
    {
      id: "10",
      name: "Journal Entries",
    },
  ]
}
export const gettemplate = () => {
  return [
    {
      id: "0",
      name: "Select Template",
    },
    {
      id: "1",
      name: "salesinvoice",
    },
    {
      id: "2",
      name: "requestGSTN",
    },
  ]
}
export const getbanktype = () => {
  return [
    {
      id: "1",
      name: "Bank of Baroda",
    },
    {
      id: "2",
      name: "Deutsche Bank",
    },
    {
      id: "3",
      name: "IDFC First Bank",
    },
    {
      id: "4",
      name: "Yes Bank",
    },
    {
      id: "5",
      name: "Kotak Bank",
    },
    {
      id: "6",
      name: "ICICI Bank",
    },
  ]
}

export const getallmonth = () => {
  return [
    {
      id: "1",
      name: "January",
      shortForm: "Jan",
    },
    {
      id: "2",
      name: "February",
      shortForm: "Feb",
    },
    {
      id: "3",
      name: "March",
      shortForm: "Mar",
    },
    {
      id: "4",
      name: "April",
      shortForm: "Apr",
    },
    {
      id: "5",
      name: "May",
      shortForm: "May",
    },
    {
      id: "6",
      name: "June",
      shortForm: "Jun",
    },
    {
      id: "7",
      name: "July",
      shortForm: "Jul",
    },
    {
      id: "8",
      name: "August",
      shortForm: "Aug",
    },
    {
      id: "9",
      name: "September",
      shortForm: "Sep",
    },
    {
      id: "10",
      name: "October",
      shortForm: "Oct",
    },
    {
      id: "11",
      name: "November",
      shortForm: "Nov",
    },
    {
      id: "12",
      name: "December",
      shortForm: "Dec",
    },
  ]
}

export const getquarter = (type) => {
  var minYear = parseInt(getAccountSessionUserData("financial_year"), 10)
  var febend = leapyear(minYear + 1) ? "29" : "28"
  if (type === "M") {
    return [
      {
        id: "Apr " + minYear,
        name: "Apr " + minYear,
        month: "04",
        year: minYear,
        startdate: minYear + "-04-01",
        enddate: minYear + "-04-30",
      },
      {
        id: "May " + minYear,
        name: "May " + minYear,
        month: "05",
        year: minYear,
        startdate: minYear + "-05-01",
        enddate: minYear + "-05-31",
      },
      {
        id: "Jun " + minYear,
        name: "Jun " + minYear,
        month: "06",
        year: minYear,
        startdate: minYear + "-06-01",
        enddate: minYear + "-06-30",
      },
      {
        id: "Jul " + minYear,
        name: "Jul " + minYear,
        month: "07",
        year: minYear,
        startdate: minYear + "-07-01",
        enddate: minYear + "-07-31",
      },
      {
        id: "Aug " + minYear,
        name: "Aug " + minYear,
        month: "08",
        year: minYear,
        startdate: minYear + "-08-01",
        enddate: minYear + "-08-31",
      },
      {
        id: "Sep " + minYear,
        name: "Sep " + minYear,
        month: "09",
        year: minYear,
        startdate: minYear + "-09-01",
        enddate: minYear + "-09-30",
      },
      {
        id: "Oct " + minYear,
        name: "Oct " + minYear,
        month: "10",
        year: minYear,
        startdate: minYear + "-10-01",
        enddate: minYear + "-10-31",
      },
      {
        id: "Nov " + minYear,
        name: "Nov " + minYear,
        month: "11",
        year: minYear,
        startdate: minYear + "-11-01",
        enddate: minYear + "-11-30",
      },
      {
        id: "Dec " + minYear,
        name: "Dec " + minYear,
        month: "12",
        year: minYear,
        startdate: minYear + "-12-01",
        enddate: minYear + "-12-31",
      },
      {
        id: "Jan " + (minYear + 1),
        name: "Jan " + (minYear + 1),
        month: "01",
        year: minYear + 1,
        startdate: minYear + 1 + "-01-01",
        enddate: minYear + 1 + "-01-31",
      },
      {
        id: "Feb " + (minYear + 1),
        name: "Feb " + (minYear + 1),
        month: "02",
        year: minYear,
        startdate: minYear + 1 + "-02-01",
        enddate: minYear + 1 + "-02-" + febend,
      },
      {
        id: "Mar " + (minYear + 1),
        name: "Mar " + (minYear + 1),
        month: "03",
        year: minYear + 1,
        startdate: minYear + 1 + "-03-01",
        enddate: minYear + 1 + "-03-31",
      },
      {
        id: "Pending_Invoices",
        name: "Pending Invoices",
        month: "04",
        year: minYear + 1,
        startdate: minYear + 1 + "-04-01",
        enddate: minYear + 1 + "-04-30",
      },
    ]
  } else {
    return [
      {
        id: "April - June," + minYear,
        name: "April - June," + minYear,
        startdate: minYear + "-04-01",
        enddate: minYear + "-06-30",
        months: [
          {
            id: "Apr " + minYear,
            name: "Apr " + minYear,
            month: "04",
            year: minYear,
            startdate: minYear + "-04-01",
            enddate: minYear + "-04-30",
            code: 1,
          },
          {
            id: "May " + minYear,
            name: "May " + minYear,
            month: "05",
            year: minYear,
            startdate: minYear + "-05-01",
            enddate: minYear + "-05-31",
            code: 2,
          },
          {
            id: "Jun " + minYear,
            name: "Jun " + minYear,
            month: "06",
            year: minYear,
            startdate: minYear + "-04-01",
            enddate: minYear + "-06-30",
            code: 3,
          },
        ],
      },
      {
        id: "July - September," + minYear,
        name: "July - September," + minYear,
        startdate: minYear + "-07-01",
        enddate: minYear + "-09-30",
        months: [
          {
            id: "Jul " + minYear,
            name: "Jul " + minYear,
            month: "07",
            year: minYear,
            startdate: minYear + "-07-01",
            enddate: minYear + "-07-31",
            code: 1,
          },
          {
            id: "Aug " + minYear,
            name: "Aug " + minYear,
            month: "08",
            year: minYear,
            startdate: minYear + "-08-01",
            enddate: minYear + "-08-31",
            code: 2,
          },
          {
            id: "Sep " + minYear,
            name: "Sep " + minYear,
            month: "09",
            year: minYear,
            startdate: minYear + "-07-01",
            enddate: minYear + "-09-30",
            code: 3,
          },
        ],
      },
      {
        id: "October - December," + minYear,
        name: "October - December," + minYear,
        startdate: minYear + "-10-01",
        enddate: minYear + "-12-31",
        months: [
          {
            id: "Oct " + minYear,
            name: "Oct " + minYear,
            month: "10",
            year: minYear,
            startdate: minYear + "-10-01",
            enddate: minYear + "-10-31",
            code: 1,
          },
          {
            id: "Nov " + minYear,
            name: "Nov " + minYear,
            month: "11",
            year: minYear,
            startdate: minYear + "-11-01",
            enddate: minYear + "-11-30",
            code: 2,
          },
          {
            id: "Dec " + minYear,
            name: "Dec " + minYear,
            month: "12",
            year: minYear,
            startdate: minYear + "-10-01",
            enddate: minYear + "-12-31",
            code: 3,
          },
        ],
      },
      {
        id: "January - March," + (minYear + 1),
        name: "January - March," + (minYear + 1),
        startdate: minYear + 1 + "-01-01",
        enddate: minYear + 1 + "-03-31",
        months: [
          {
            id: "Jan " + (minYear + 1),
            name: "Jan " + (minYear + 1),
            month: "01",
            year: minYear + 1,
            startdate: minYear + 1 + "-01-01",
            enddate: minYear + 1 + "-01-31",
            code: 1,
          },
          {
            id: "Feb " + (minYear + 1),
            name: "Feb " + (minYear + 1),
            month: "02",
            year: minYear,
            startdate: minYear + 1 + "-02-01",
            enddate: minYear + 1 + "-02-" + febend,
            code: 2,
          },
          {
            id: "Mar " + (minYear + 1),
            name: "Mar " + (minYear + 1),
            month: "03",
            year: minYear + 1,
            startdate: minYear + 1 + "-01-01",
            enddate: minYear + 1 + "-03-31",
            code: 3,
          },
        ],
      },
      {
        id: "Pending_Invoices",
        name: "Pending Invoices",
        months: [
          {
            id: "Apr " + (minYear + 1),
            name: "Apr " + (minYear + 1),
            month: "04",
            year: minYear + 1,
            startdate: minYear + 1 + "-04-01",
            enddate: minYear + 1 + "-04-31",
            code: 3,
          },
        ],
        startdate: minYear + 1 + "-04-01",
        enddate: minYear + 1 + "-04-30",
      },
    ]
  }
}

function leapyear(year) {
  return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0
}

export const getallQuarter = () => {
  return [
    {
      id: "1",
      name: "First Quarter",
    },
    {
      id: "2",
      name: "Second Quarter",
    },
    {
      id: "3",
      name: "Third Quarter",
    },
    {
      id: "4",
      name: "Fourth Quarter",
    },
  ]
}

export const getgstr2AType = () => {
  return [
    {
      id: "A",
      name: "GSTR-2A",
    },
    {
      id: "B",
      name: "GSTR-2B",
    },
  ]
}

export const getgstr2DocType = () => {
  return [
    {
      id: "B2B",
      name: "B2B",
    },
    {
      id: "CDNR",
      name: "CDNR",
    },
    {
      id: "IMPG",
      name: "IMPG",
    },
    {
      id: "IMPGSEZ",
      name: "IMPGSEZ",
    },
  ]
}

export const getAllSchedule = () => {
  return [
    {
      id: "1",
      name: "NO SCHEDULE (IMMEDIATE)",
    },
    {
      id: "2",
      name: "HOURLY",
    },
    {
      id: "3",
      name: "DAILY",
    },
    {
      id: "4",
      name: "WEEKLY",
    },
    {
      id: "5",
      name: "MONTHLY",
    },
  ]
}

export const getreportrange = () => {
  return [
    {
      id: "0",
      name: "Monthly",
    },
    {
      id: "1",
      name: "Quarterly",
    },
  ]
}

export const getallPaymentMode = () => {
  return [
    {
      id: "0",
      name: "RTGS/NEFT",
    },
    {
      id: "1",
      name: "Cheque",
    },
    {
      id: "2",
      name: "CASH",
    },
  ]
}

export const getallgender = () => {
  return [
    {
      id: "0",
      name: "Male",
    },
    {
      id: "1",
      name: "Female",
    },
    {
      id: "2",
      name: "Other",
    },
  ]
}

export const getdecimalpoint = () => {
  return [
    {
      id: "0",
      name: "0",
    },
    {
      id: "1",
      name: "1",
    },
    {
      id: "2",
      name: "2",
    },
    {
      id: "3",
      name: "3",
    },
    {
      id: "4",
      name: "4",
    },
    {
      id: "5",
      name: "5",
    },
    {
      id: "6",
      name: "6",
    },
  ]
}

export const getreceipttype = () => {
  return [
    {
      id: "S",
      name: "Single Receipt",
    },
    {
      id: "M",
      name: "Multiple Receipt",
    },
  ]
}

export const getsearchfilter = () => {
  return [
    {
      id: "0",
      name: "Full Character",
    },
    {
      id: "1",
      name: "First Character",
    },
  ]
}

export const gettheme = () => {
  return [
    {
      id: "1",
      name: "Theme-1 Dark",
    },
    {
      id: "2",
      name: "Theme-2 Light",
    },
    /*{
      id: "3",
      name: "Theme-3",
    },
    {
      id: "4",
      name: "Theme-4",
    },
    {
      id: "5",
      name: "Theme-5",
    },
    {
      id: "6",
      name: "Theme-6",
    },*/
  ]
}

export const getaccounttype = () => {
  return [
    {
      id: "1",
      name: "CA",
    },
    {
      id: "2",
      name: "SB",
    },
    {
      id: "3",
      name: "OD",
    },
    {
      id: "4",
      name: "NRE",
    },
    {
      id: "5",
      name: "Credit Card",
    },
    {
      id: "6",
      name: "PPF",
    },
  ]
}

export const gettaxtype = () => {
  return [
    {
      id: "CGST",
      name: "CGST",
    },
    {
      id: "SGST",
      name: "SGST",
    },
    {
      id: "IGST",
      name: "IGST",
    },
    {
      id: "SCESS",
      name: "CESS (Local)",
    },
    {
      id: "ICESS",
      name: "CESS (Inter state)",
    },
    {
      id: "OTHER",
      name: "OTHER",
    },
    {
      id: "VAT",
      name: "VAT",
    },
    {
      id: "ADDVAT",
      name: "ADDVAT",
    },
    {
      id: "ServiceTax",
      name: "ServiceTax",
    },
    {
      id: "Excise",
      name: "Excise",
    },
    {
      id: "TCS",
      name: "TCS",
    },
    {
      id: "TDS",
      name: "TDS",
    },
  ]
}

export const getinvoiceformwidth = () => {
  return [
    {
      id: "0",
      name: "Fixed Width",
    },
    {
      id: "1",
      name: "Full Width",
    },
  ]
}
export const showcustomersupplier = () => {
  return [
    {
      id: "0",
      name: "Both",
    },
    {
      id: "1",
      name: "Only Customers/Only Suppliers",
    },
  ]
}

export const getcreditlimitaction = () => {
  return [
    {
      id: "1",
      name: "Notify",
    },
    {
      id: "0",
      name: "Dont Allow",
    },
  ]
}
export const getinvoicetemplate = () => {
  return [
    {
      id: "1",
      name: "Invoice",
    },
    {
      id: "9",
      name: "GST Template 1",
    },
    {
      id: "11",
      name: "GST Template 2",
    },
    {
      id: "6",
      name: "GST Template 3",
    },
    {
      id: "8",
      name: "Template A5",
    },
  ]
}
export const getinvoiceprintcopies = () => {
  return [
    {
      id: "1",
      name: "One Copy",
    },
    {
      id: "2",
      name: "Two Copies",
    },
    {
      id: "3",
      name: "Three Copies",
    },
  ]
}
export const getinvoiceratesetting = () => {
  return [
    {
      id: "0",
      name: "Manually",
    },
    {
      id: "1",
      name: "Item Master",
    },
    {
      id: "2",
      name: "Account Master",
    },
    {
      id: "3",
      name: "Last Sale Rate",
    },
    {
      id: "4",
      name: "Customer Group Master",
    },
  ]
}

export const getlanguages = () => {
  return [
    {
      id: "1",
      name: "English",
    },
    {
      id: "2",
      name: "Hindi",
    },
    {
      id: "3",
      name: "Gujarati",
    },
  ]
}

export const getPurchaseinvoiceratesetting = () => {
  return [
    {
      id: "0",
      name: "Manually",
    },
    {
      id: "1",
      name: "Item Master",
    },
    {
      id: "2",
      name: "Last Purchase Rate",
    },
  ]
}

export const gettransactiontype = () => {
  return [
    {
      id: "1",
      name: "Cr",
    },
    {
      id: "2",
      name: "Dr",
    },
  ]
}

export const getnotetype = () => {
  return [
    {
      id: "S",
      name: "Sales",
    },
    {
      id: "PU",
      name: "Purchase",
    },
  ]
}

export const getAllGSTMode = () => {
  return [
    {
      id: "simple",
      name: "Simple",
    },
    {
      id: "advanced",
      name: "Advanced",
    },
  ]
}

export const getfont = () => {
  return [
    {
      id: "Helvetica",
      name: "Font-1",
    },
    {
      id: "Roboto",
      name: "Font-2",
    },
    {
      id: "Courier",
      name: "Font-3",
    },
    {
      id: "Arial",
      name: "Font-4",
    },
  ]
}

export const getfontsize = () => {
  return [
    {
      id: 7,
      name: "7",
    },
    {
      id: 8,
      name: "8",
    },
    {
      id: 8.5,
      name: "8.5",
    },
    {
      id: 9,
      name: "9",
    },
  ]
}

export const getpurchasereturnreason = () => {
  return [
    {
      id: "4",
      name: "Correction in Invoice",
    },
    {
      id: "5",
      name: "Change in POS",
    },
    {
      id: "6",
      name: "Finalization in Provisional Assesment",
    },
    {
      id: "7",
      name: "Others",
    },
  ]
}
export const getsalesreturnreason = () => {
  return [
    {
      id: "1",
      name: "Sales Return",
    },
    {
      id: "2",
      name: "Post Sale Discount",
    },
    {
      id: "3",
      name: "Deficiency in Service",
    },
    {
      id: "4",
      name: "Correction in Invoice",
    },
    {
      id: "5",
      name: "Change in POS",
    },
    {
      id: "6",
      name: "Finalization in Provisional Assesment",
    },
    {
      id: "7",
      name: "Others",
    },
  ]
}

export const getacounttype = () => {
  return [
    {
      id: "0",
      name: "Basic Accounting",
    },
    {
      id: "1",
      name: "Basic Accounting with Inventory",
    },
    {
      id: "2",
      name: "Gym Management",
    },
    {
      id: "3",
      name: "POS",
    },
    {
      id: "4",
      name: "GYM with Accounting",
    },
    {
      id: "5",
      name: "Basic Accounting - Cloth Merchant",
    },
    {
      id: "6",
      name: "Trust",
    },
  ]
}

export const getgsttype = () => {
  return [
    {
      id: "gstr1",
      name: "GSTR-1",
    },
    {
      id: "gstr3",
      name: "GSTR-3B",
    },
  ]
}

export const getBarCodeType = () => {
  return [
    {
      id: "0",
      name: "None",
    },
    {
      id: "1",
      name: "SKU",
    },
  ]
}

export const getallDocType = () => {
  return [
    {
      id: "INV",
      name: "Tax Invoice",
    },
    {
      id: "BIL",
      name: "Bill of Supply",
    },
    {
      id: "CHL",
      name: "Delivery Challan",
    },
    {
      id: "BOE",
      name: "Bill of Entry",
    },
    {
      id: "OTH",
      name: "Others",
    },
  ]
}

export const getNameFromType = (invoice_type, short_form) => {
  return common_getNameFromType({ invoice_type, short_form, getAccountSitesetting })
}

export const getallOutwardSubType = () => {
  return [
    {
      id: "1",
      name: "Supply",
    },
    {
      id: "3",
      name: "Export",
    },
    {
      id: "4",
      name: "Job Work",
    },
    {
      id: "9",
      name: "SKD/CKD/Lots",
    },
    {
      id: "11",
      name: "Recipient Not Known",
    },
    {
      id: "5",
      name: "For Own Use",
    },
    {
      id: "12",
      name: "Exhibition or Fairs",
    },
    {
      id: "10",
      name: "Line Sales",
    },
    {
      id: "8",
      name: "Others",
    },
  ]
}

export const getallInwardSubType = () => {
  return [
    {
      id: "1",
      name: "Supply",
    },
    {
      id: "2",
      name: "Import",
    },
    {
      id: "9",
      name: "SKD/CKD/Lots",
    },
    {
      id: "6",
      name: "Job Work Returns",
    },
    {
      id: "7",
      name: "Sales Return",
    },
    {
      id: "12",
      name: "Exhibition or Fairs",
    },
    {
      id: "5",
      name: "For Own Use",
    },
    {
      id: "8",
      name: "Others",
    },
  ]
}

export const getAllSubType = () => {
  return [...getallOutwardSubType(), ...getallInwardSubType()]
}

export const getallFont = () => {
  return [
    {
      id: "1",
      name: "Courier",
    },
    {
      id: "2",
      name: "Courier-Bold",
    },
    {
      id: "3",
      name: "Courier-Oblique",
    },
    {
      id: "4",
      name: "Courier-BoldOblique",
    },
    {
      id: "5",
      name: "Helvetica",
    },
    {
      id: "6",
      name: "Helvetica-Bold",
    },
    {
      id: "7",
      name: "Helvetica-Oblique",
    },
    {
      id: "8",
      name: "Helvetica-BoldOblique",
    },
    {
      id: "9",
      name: "Times-Roman",
    },
    {
      id: "10",
      name: "Times-Bold",
    },
    {
      id: "11",
      name: "Times-Italic",
    },
    {
      id: "12",
      name: "Times-BoldItalic",
    },
    {
      id: "14",
      name: "Symbol",
    },
    {
      id: "15",
      name: "ZapfDingbats",
    },
  ]
}

export const getallFontSize = () => {
  return [
    {
      id: "8",
      name: "8",
    },
    {
      id: "10",
      name: "10",
    },
    {
      id: "12",
      name: "12",
    },
    {
      id: "14",
      name: "14",
    },
    {
      id: "16",
      name: "16",
    },
    {
      id: "18",
      name: "18",
    },
    {
      id: "20",
      name: "20",
    },
    {
      id: "22",
      name: "22",
    },
    {
      id: "24",
      name: "24",
    },
    {
      id: "26",
      name: "26",
    },
    {
      id: "28",
      name: "28",
    },
  ]
}

export const getalign = () => {
  return [
    {
      id: "0",
      name: "Left",
    },
    {
      id: "1",
      name: "Right",
    },
    {
      id: "2",
      name: "Center",
    },
  ]
}

export const getallNegInvAction = () => {
  return [
    {
      id: "0",
      name: "None",
    },
    {
      id: "1",
      name: "Notify",
    },
    {
      id: "2",
      name: "Dont Allow",
    },
  ]
}

export const getSandwichRule = () => {
  return [
    {
      id: "1",
      name: "Rule 1",
    },
    {
      id: "2",
      name: "Rule 2",
    },
  ]
}

export const getfbUI = () => {
  return [
    {
      id: "1",
      name: "Normal",
    },
    {
      id: "2",
      name: "Compact",
    },
  ]
}

export const getallmatrics = () => {
  return [
    {
      id: "mm",
      name: "mm",
    },
    {
      id: "in",
      name: "in",
    },
  ]
}

export const getallpagesize = () => {
  return [
    {
      id: "Letter",
      name: "Letter",
    },
    {
      id: "A4",
      name: "A4",
    },
    {
      id: "Custom",
      name: "Custom",
    },
  ]
}

export const getAllAccessType = () => {
  return [
    {
      id: "1",
      name: "Allow login as Guest",
    },
    {
      id: "2",
      name: "Create new Customer in FinalBooks",
    },
  ]
}

export const getAllsales_price = () => {
  return [
    {
      id: "1",
      name: "Account Wise",
    },
    {
      id: "2",
      name: "Customer Group Wise",
    },
    {
      id: "3",
      name: "Item Wise",
    },
  ]
}

export const filldropdownfromcode = ({ dispatch, reduxform, fieldname, dropDownList, code, array, index }) => {
  const state = store.getState()
  var formvalue = getFormValues(reduxform)(state)

  if (array) {
    if (!formvalue.hasOwnProperty(`${array}`)) {
      return true
    }
    if (formvalue[`${array}`].length === 0) {
      return true
    }
    formvalue = formvalue[`${array}`][index]

    if (formvalue === undefined) {
      return true
    }
  }
  if (!formvalue.hasOwnProperty(fieldname)) {
    return true
  }
  var fieldvalue = formvalue[`${fieldname}`]
  if (fieldvalue !== null && fieldvalue !== undefined && fieldvalue !== null) {
    if (!fieldvalue.hasOwnProperty(code)) {
      if (dropDownList !== undefined) {
        if (dropDownList.length > 0) {
          var obj = dropDownList.find((item) => item[code] === fieldvalue)
          if (array) {
            formvalue[fieldname] = obj
            var name = `${array}[${index}]`
            dispatch(change(reduxform, `${name}`, formvalue))
          } else {
            dispatch(change(reduxform, `${fieldname}`, obj))
          }
        }
      }
    }
  }
}

export const getMenuRightsByType = ({ parent, name, type }) => {
  const state = store.getState()
  const { all_menu } = state.CommonReducer
  if (all_menu && !all_menu.hasOwnProperty(`${parent}`)) {
    if (type === "viewRights") {
      return false
    } else {
      return []
    }
  } else if (all_menu && all_menu[parent].filter((item) => item.name === name).length > 0) {
    var InvoiceID = all_menu[parent].find((item) => item.name === name).id
    if (type === "viewRights") {
      return getAccountViewRightsByID(InvoiceID)
    } else {
      return getAccountRightsByID(InvoiceID)
    }
  } else {
    if (type === "viewRights") {
      return false
    } else {
      return []
    }
  }
}

export const getUSerRights = () => {
  const state = store.getState()
  const { userRights, userRightsSet } = state.AuthReducer
  if (userRightsSet === true) {
    return userRights
  } else {
    return getStorage(`user_rights_${getWindowYear()}`)
  }
}

export const getuserright = (name) => {
  var userrights = getUSerRights()
  var key = getmenufromname(name)
  return userrights !== undefined ? (userrights.hasOwnProperty(key) ? userrights[key].split("|") : []) : []
}

export const getlastpageuserright = () => {
  if (!isblank(getLastMenuKey())) {
    var key = getLastMenuKey()
    var userrights = getUSerRights()
    return userrights !== undefined ? (userrights.hasOwnProperty(key) ? userrights[key].split("|") : []) : []
  } else {
    return []
  }
}

export const getAccountRightsByID = (id) => {
  return common_getAccountRightsByID({ id, getUSerRights })
}

export const getAccountViewRightsByID = (id) => {
  if (!isblank(id)) {
    const userRights = getUSerRights()
    var key = id
    return userRights !== undefined ? (userRights.hasOwnProperty(key) ? true : false) : false
  } else {
    return false
  }
}

export const checkRightsAndSiteSetting = (id, localSiteSetting) => {
  const get_SiteSetting = localSiteSetting ? localSiteSetting : getSiteSetting
  var viewRights = getAccountViewRightsByID(id)
  if (viewRights === false) {
    return false
  }
  if (id === menuId.CostCenter) {
    viewRights = getCheckBoxValue(get_SiteSetting("cost_center"))
  } else if (id === menuId.Transport) {
    viewRights = getCheckBoxValue(get_SiteSetting("transport_mng"))
  } else if (id === menuId.Agent) {
    viewRights = getCheckBoxValue(get_SiteSetting("agent_mng"))
  } else if (id === menuId.Salesman) {
    viewRights = getCheckBoxValue(get_SiteSetting("salesman_mng"))
  } else if (id === menuId.CustomerGroup) {
    viewRights = getCheckBoxValue(get_SiteSetting("customer_group_mng"))
  } else if (id === menuId.ProductGroup) {
    viewRights = getCheckBoxValue(get_SiteSetting("item_group"))
  } else if (id === menuId.ProductSubGroup) {
    viewRights = getCheckBoxValue(get_SiteSetting("item_category")) && checkRightsAndSiteSetting("13", get_SiteSetting)
  } else if (id === menuId.ProductSubGroupLevel2) {
    viewRights = checkRightsAndSiteSetting("147", get_SiteSetting) && checkRightsAndSiteSetting("13", get_SiteSetting) && getCheckBoxValue(get_SiteSetting("item_subgroupLevel2"))
  } else if (id === menuId.Godown) {
    viewRights = getCheckBoxValue(get_SiteSetting("multigodown_mng"))
  } else if (id === menuId.EwayBill) {
    viewRights = getCheckBoxValue(get_SiteSetting("ewaybill"))
  } else if (id === menuId.AdjustBill) {
    viewRights = getCheckBoxValue(get_SiteSetting("adjust_bill"))
  }
  return viewRights
}

export const getallMenu = () => {
  const state = store.getState()
  const { all_menu } = state.CommonReducer
  return all_menu
}

export const getAcntLstPageUserRight = (invoicetype) => {
  let userRights = []
  if (!isblank(getLastMenuKey())) {
    var key = getLastMenuKey()
    if (key === "9000" && !isblank(invoicetype)) {
      // Sales Order
      const all_menu = getallMenu()
      const menuid = getOrderMenuidTypes(all_menu, invoicetype)
      key = menuid
      userRights[key] = getOrderPageUserRight(menuid)
    } else {
      userRights = getUSerRights()
    }

    if (!isblank(key)) {
      return userRights !== undefined ? (userRights.hasOwnProperty(key) ? userRights[key].split("|") : []) : []
    } else {
      return []
    }
  } else {
    return []
  }
}

export const getOrderPageUserRight = (key) => {
  const userRights = getUSerRights()
  return userRights !== undefined ? (userRights.hasOwnProperty(key) ? userRights[key] : "") : ""
}

export const getLastMenuKey = () => {
  const state = store.getState()
  const { last_menu_key } = state.CommonReducer
  return last_menu_key
}

export const setSettingFieldValue = ({ key, type, subtype, defaultValue, from, fieldType }) => {
  const state = store.getState()
  const { accountSiteSetting } = state.AuthReducer
  var firmdetails = ""
  firmdetails = cloneObj(accountSiteSetting)
  var returnValue = ""
  var filteredSetting = {}
  if (!isblank(type) && !isblank(subtype)) {
    if (
      type === "invoice_type_detail" ||
      type === "purchase_type_detail" ||
      type === "inward_type_detail" ||
      type === "outward_type_detail" ||
      type === "salesreturn_type_detail" ||
      type === "purchasereturn_type_detail"
    ) {
      filteredSetting = firmdetails[type].find((e) => e.id === subtype)
    } else {
      filteredSetting = firmdetails[type][subtype]
    }
  } else {
    filteredSetting = firmdetails[type]
  }

  if (!isblank(filteredSetting)) {
    returnValue = filteredSetting[key]
  }

  if (isblank(returnValue)) {
    if (!isblank(defaultValue)) {
      returnValue = defaultValue
    } else {
      returnValue = ""
    }
  }

  if (from === "site_setting_form") {
    if (fieldType === "toggleButton") {
      returnValue = returnValue === "1" || returnValue === 1 ? true : false
    }
  }

  return returnValue
}

export const getCompanyLock = () => {
  return common_getCompanyLock(getAccountSessionUserData)
}

export const getAccountSitesetting = (key, userdata, type) => {
  const meta = {
    store,
    getAccountViewRightsByID,
    checkRightsAndSiteSetting,
  }
  return common_getAccountSitesetting({ key, type, meta })
}

export const getTypeWiseKeySetting = (key, type, subtype) => {
  return common_getTypeWiseKeySetting({ key, type, subtype, getAccountSitesetting })
}

export const getSubTypeWiseSetting = (type, subtype) => {
  return common_getSubTypeWiseSetting({ type, subtype, getAccountSitesetting })
}

export const getSettingForUpdate = (key, userdata, type) => {
  const meta = {
    store,
    getAccountViewRightsByID,
    checkRightsAndSiteSetting,
  }
  return common_getSettingForUpdate({ key, type, meta })
}

export const getAccountSessionUserData = (key) => {
  const state = store.getState()
  const { accountSessionUserData } = state.AuthReducer
  var firmdetails = accountSessionUserData
  if (isblank(firmdetails)) {
    var user_data = getStorage(`user_data_${getWindowYear()}`, "")
    if (user_data) {
      firmdetails = cloneObj(user_data)
    }
  }

  if (key === "all") {
    return firmdetails
  }
  var returnValue = firmdetails !== undefined ? (firmdetails.hasOwnProperty(key) ? firmdetails[key] : "") : ""
  if (isblank(returnValue)) {
    returnValue = ""
  }
  return returnValue
}

export const getDdCountryState = () => {
  const state = store.getState()
  const { dd_country_state } = state.CommonReducer
  return dd_country_state
}

export const getAllStates = () => {
  var dd_country_state = getDdCountryState()
  return common_getAllStates(getAccountSessionUserData, dd_country_state)
}

export const getAllStatesOfAllCountry = () => {
  var returnstates = []
  var dd_country_state = getDdCountryState()
  if (!isblank(dd_country_state) && dd_country_state.length > 0) {
    dd_country_state.forEach((e) => {
      if (!isblank(e) && !isblank(e.states) && e.states.length > 0) {
        returnstates = [...returnstates, ...e.states]
      }
    })
  }
  return returnstates
}

export const getCodeOfCustomPageByName = ({ name }) => {
  return common_getCodeOfCustomPageByName({ name, store })
}

export const checkCustomPageDependancy = ({ strdependency }) => {
  return common_checkCustomPageDependancy({ strdependency, store })
}

export const getAllStatesOfcountry = (countryid) => {
  var dd_country_state = getDdCountryState()
  return common_getAllStatesOfcountry(countryid, dd_country_state)
}

export const getStateName = ({ country, state }) => {
  var dd_country_state = getDdCountryState()
  return common_getStateName({ country, state, dd_country_state })
}

export const checkdropdowndependancy = (strdependency) => {
  const state = store.getState()
  return account_checkdropdowndependancy(strdependency, state)
}

export const getdependency = ({ strdependency, form_type, fetch_form_dep }) => {
  var dropdown_string = checkdropdowndependancy(strdependency)
  if (!isblank(dropdown_string)) {
    fetch_form_dep({
      dropdown_string: dropdown_string,
      form_type,
    })
  }
}

export const getdependency_dispatch = ({ strdependency, form_type, fetch_form_dep, dispatch }) => {
  var dropdown_string = checkdropdowndependancy(strdependency)
  if (!isblank(dropdown_string)) {
    dispatch(
      fetch_form_dep({
        dropdown_string: dropdown_string,
        form_type,
      })
    )
  }
}

export const valueOfRate = (rate) => {
  return common_valueOfRate({ rate, getAccountSitesetting, user_data: "" })
}

export const amountFormatOfRate = (rate) => {
  return common_amountFormatOfRate(rate, getAccountSitesetting)
}

export const getstartyear = () => {
  var minYear = parseInt(getAccountSessionUserData("financial_year"), 10)
  return minYear
}

export const getendyear = () => {
  var minYear = parseInt(getAccountSessionUserData("financial_year"), 10)
  var maxYear = minYear + 1
  return maxYear
}

export const getFBDate = (str) => {
  var d = new Date()
  var cm = d.getMonth() + 1
  var cy = d.getFullYear()
  /* eslint-disable-next-line */
  var parms = str.split(/[\.\-\/]/)
  var yyyy = parseInt(parms[2], 10)
  var mm = parseInt(parms[1], 10)
  var dd = parseInt(parms[0], 10)

  if (isNaN(mm)) {
    mm = cm
  }

  if (isNaN(yyyy)) {
    if (mm > 3) {
      yyyy = getstartyear()
    } else {
      yyyy = getendyear()
    }
  } else {
    if (yyyy.toString().length === 4) {
    } else if (yyyy.toString().length === 2) {
      yyyy = parseInt(Math.floor(cy / 100).toString() + yyyy.toString())
    } else {
      yyyy = ""
    }
  }

  var date = new Date(yyyy, mm - 1, dd, 0, 0, 0, 0)
  if (mm === date.getMonth() + 1 && dd === date.getDate() && (yyyy === date.getFullYear() || yyyy === date.getYear())) {
    if (dd < 10) {
      dd = "0" + dd.toString()
    }
    if (mm < 10) {
      mm = "0" + mm.toString()
    }
    return dd + "-" + mm + "-" + date.getFullYear()
  } else {
    return ""
  }
}

export const filterdata = (data, column, searchtext) => {
  if (!isblank(searchtext)) {
    var columndatalist = column
    return data.filter((item) => {
      return Object.keys(item).some(function (key) {
        var datakey = key
        var coloumndata = columndatalist.filter((item2) => {
          return item2.id === datakey
        })
        if (coloumndata.length > 0 && coloumndata[0].numeric) {
          return getNumberonly(item[key]).toLowerCase().includes(getNumberonly(searchtext.toLowerCase()))
        } else if (coloumndata.length > 0 && coloumndata[0].date) {
          if (getFBDate(searchtext.toLowerCase()) !== "") {
            return item[key].toLowerCase().includes(getFBDate(searchtext.toLowerCase()))
          }
        } else {
          if (coloumndata.length > 0) {
            return item[key].toLowerCase().includes(searchtext.toLowerCase())
          }
        }
        return key
      })
    })
  }
  return data
}

export const currentFinancialYear = () => {
  var d = new Date()
  var cm = d.getMonth() + 1
  var cy = d.getFullYear()

  var minYear = parseInt(getAccountSessionUserData("financial_year"), 10)
  var maxYear = minYear + 1

  if (cy < minYear || cy > maxYear) {
    return false
  }
  if ((cm <= 3 && cy === minYear) || (cm >= 4 && cy === maxYear)) {
    return false
  }
  return true
}

export const getIndianFormat = (value) => {
  return `${getAccountSitesetting("currency", "", "symbol")}${amount_format(toFixedFn(parseFloat(value)))}`
}

export const getEditorSize = () => {
  return [
    {
      id: "small",
      name: "Small",
    },
    {
      id: "large",
      name: "Large",
    },
  ]
}

export const getMyStoreTheme = () => {
  return [
    {
      id: "1",
      name: "Theme-1",
    },
    {
      id: "2",
      name: "Theme-2",
    },
  ]
}

export const setCustomFieldData = (ddcustomfield, showFieldsin) => {
  return common_setCustomFieldData({ ddcustomfield, showFieldsin, getMenuData, getAccountSitesetting })
}

export const setCustomFieldData_all = (ddcustomfield, showFieldsin) => {
  return common_setCustomFieldData_all({ ddcustomfield, showFieldsin, getAccountSitesetting })
}

export const getallLinkType = () => {
  return [
    {
      id: "0",
      name: "None",
    },
    {
      id: "1",
      name: "Customer",
    },
    {
      id: "2",
      name: "Item",
    },
  ]
}

export const getallResolution = () => {
  return [
    {
      id: "512*512",
      name: "512*512",
    },
    {
      id: "1920*1080",
      name: "1920*1080",
    },
  ]
}

export const getalltemplatesize = () => {
  return [
    {
      id: "0",
      name: "A4",
    },
    {
      id: "1",
      name: "A5",
    },
  ]
}

export const getMasterDetail = () => {
  return [
    {
      id: "0",
      name: "Master Level",
    },
    {
      id: "1",
      name: "Grid Level",
    },
  ]
}

export const ewbcanelReason = () => {
  return [
    {
      id: "1",
      name: "Duplicate",
    },
    {
      id: "2",
      name: "Order Cancelled",
    },
    {
      id: "3",
      name: "Data Entry mistake",
    },
    {
      id: "4",
      name: "Others",
    },
  ]
}

export const eInvoiceCanelReason = () => {
  return [
    {
      id: "1",
      name: "Wrong Entry",
    },
  ]
}

export const getAllOtherType = () => {
  var invoice_type_detail = getAccountSitesetting("invoice_type_detail").map((e) => {
    e.main_type = "salesinvoices"
    return e
  })
  var purchase_type_detail = getAccountSitesetting("purchase_type_detail").map((e) => {
    e.main_type = "purchaseinvoices"
    return e
  })
  invoice_type_detail = invoice_type_detail.filter((item) => item.id !== "sales_order" && item.id !== "estimate")
  purchase_type_detail = purchase_type_detail.filter((item) => item.id !== "purchase_order")
  var finalArray = [...invoice_type_detail, ...purchase_type_detail]
  if (parseInt(getMenuRightsByType({ parent: "Invoice", name: "Credit Note" })[0], 10) === 1) {
    finalArray.push({ id: "salesreturn", name: "Credit Note" })
  }
  if (parseInt(getMenuRightsByType({ parent: "Invoice", name: "Debit Note" })[0], 10) === 1) {
    finalArray.push({ id: "purchasereturn", name: "Debit Note" })
  }
  return finalArray
}

export const getAllInvoiceTemplate = () => {
  return [
    {
      id: "2",
      name: "Template 1",
    },
    {
      id: "3",
      name: "Template 2",
    },
    {
      id: "1",
      name: "GST Template 1",
    },
    {
      id: "4",
      name: "GST Template 2",
    },
    {
      id: "6",
      name: "GST Template 4",
    },
    {
      id: "7",
      name: "GST Template 5",
    },
    {
      id: "8",
      name: "GST Template 6",
    },
  ]
}

export const getalllanguage = () => {
  return [
    {
      id: "1",
      name: "English",
    },
    {
      id: "2",
      name: "Hindi",
    },
  ]
}

export const stockTransferType = () => {
  return [
    {
      id: "I",
      name: "Inward",
    },
    {
      id: "P",
      name: "Packing Slip",
    },
  ]
}

export const getAllInvoiceTypes = () => {
  var sales_invoices_types = getAccountSitesetting("invoice_type_detail")
  var purchase_invoices_types = getAccountSitesetting("purchase_type_detail")
  var inward_invoices_types = getAccountSitesetting("inward_type_detail")
  var outward_invoices_types = getAccountSitesetting("outward_type_detail")
  sales_invoices_types = sales_invoices_types.concat(purchase_invoices_types)
  sales_invoices_types = sales_invoices_types.concat(inward_invoices_types)
  sales_invoices_types = sales_invoices_types.concat(outward_invoices_types)

  if (getMenuRightsByType({ parent: "Process", name: "Manage Process Transfer", type: "viewRights" })) {
    sales_invoices_types.push({ name: "Process Transfer", id: "lot_transfer" })
  }
  if (getMenuRightsByType({ parent: "Invoice", name: "Credit Note", type: "viewRights" })) {
    sales_invoices_types.push({ name: "Credit Note", id: "sales_return" })
  }
  if (getMenuRightsByType({ parent: "Invoice", name: "Debit Note", type: "viewRights" })) {
    sales_invoices_types.push({ name: "Debit Note", id: "purchase_return" })
  }
  if (getMenuRightsByType({ parent: "Masters", name: "Customers", type: "viewRights" })) {
    sales_invoices_types.push({ name: "Customer", id: "customer" })
  }
  if (getMenuRightsByType({ parent: "Masters", name: "Suppliers", type: "viewRights" })) {
    sales_invoices_types.push({ name: "Supplier", id: "supplier" })
  }
  if (getMenuRightsByType({ parent: "Inventory", name: "Manage Product", type: "viewRights" })) {
    sales_invoices_types.push({ name: "Product", id: "product" })
  }
  if (getMenuRightsByType({ parent: "Inventory", name: "Stock Transfer", type: "viewRights" })) {
    sales_invoices_types.push({ name: "Stock Transfer", id: "stockTransfer" })
  }
  if (getMenuRightsByType({ parent: "Inventory", name: "Godown Stock Transfer", type: "viewRights" })) {
    sales_invoices_types.push({
      name: "Godown Stock Transfer",
      id: "godownStock_transfer",
    })
  }
  if (getMenuRightsByType({ parent: "Invoice", name: "Purchase Old Gold", type: "viewRights" })) {
    sales_invoices_types.push({
      name: "Purchase Old Gold",
      id: "purchase_old_gold",
    })
  }
  if (getMenuRightsByType({ parent: "Invoice", name: "Gold Sales Return", type: "viewRights" })) {
    sales_invoices_types.push({
      name: "Gold Sales Return",
      id: "gold_sales_return",
    })
  }
  return sales_invoices_types
}

export const getAllTransactionTypes = () => {
  var sales_invoices_types = getAccountSitesetting("invoice_type_detail")
  var purchase_invoices_types = getAccountSitesetting("purchase_type_detail")
  sales_invoices_types = sales_invoices_types.concat(purchase_invoices_types)
  sales_invoices_types.push({ name: "Credit Note", id: "sales_return" })
  sales_invoices_types.push({ name: "Debit Note", id: "purchase_return" })
  sales_invoices_types.push({ name: "Receipt", id: "receipt" })
  sales_invoices_types.push({ name: "Payment", id: "payment" })
  sales_invoices_types.push({ name: "Journal Entry", id: "journal_entry" })
  return sales_invoices_types
}

export const getWindowWidth = () => {
  const state = store.getState()
  const { windowWidth } = state.CommonReducer
  return windowWidth
}

export const getWindowHeight = () => {
  const state = store.getState()
  const { windowHeight } = state.CommonReducer
  return windowHeight
}

export const changeInRedux = (formValues, dispatch, reduxForm, key, newValue, numeric, decimal) => {
  if (!isblank(formValues)) {
    if (numeric) {
      if (decimal) {
        if (parseFloat(getNumberonlyDecimal(formValues[key], decimal)) !== parseFloat(getNumberonlyDecimal(newValue, decimal))) {
          dispatch(change(reduxForm, key, amount_formatDecimal(newValue, decimal)))
        }
      } else {
        if (parseFloat(getNumberonly(formValues[key])) !== parseFloat(getNumberonly(newValue))) {
          dispatch(change(reduxForm, key, amount_format(newValue)))
        }
      }
    } else {
      if (formValues[key] !== newValue) {
        dispatch(change(reduxForm, key, newValue))
      }
    }
  } else {
    dispatch(change(reduxForm, key, newValue))
  }
}

export const deletedataFromForm = ({ props, state, ConfirmDialogData }) => {
  return common_deletedataFromForm({ props, state, ConfirmDialogData, getAccountSitesetting })
}

export const validGSTIN = (gstin) => {
  if (gstin.toString().length() !== 15) {
    return false
  }
  if (/^[0-9]{2}[0-9|A-Z]{13}$/.test(gstin)) {
    return true
  } else {
    return false
  }
}

export const generateWAURL = (partyName, mobileNo, billno, date, amount) => {
  var dd_country_state = getDdCountryState()

  var CompanyName = getAccountSessionUserData("company_name")
  var whatsAppLink = "https://wa.me/send?" //"https://wa.me/send?"

  var Mobile = ""
  if (!isblank(mobileNo)) {
    Mobile = `phone=${formateMobile({ mobile: mobileNo, countryId: getAccountSessionUserData("company_country"), dd_country_state })} `
  }
  var waData = ""
  waData = `Dear ${partyName}, \nThank you for your purchase.\nInvoice No. * ${billno}*\nDate: * ${date}*\nAmount: * ${getAccountSitesetting("currency", "", "symbol")}${amount_format(
    amount
  )}*\nBest Regards, \n${CompanyName} `
  waData = encodeURIComponent(waData)
  if (!isblank(Mobile)) {
    return `${whatsAppLink}${Mobile}&text=${waData}`
  } else {
    return `${whatsAppLink}&text=${waData}`
  }
}

const checkReportId = (reportArray, reportId) => {
  return reportArray.includes(parseInt(reportId, 10))
}

export const generateExcelOtherData = ({ data, filename, title, columnDatafinal, requireTotal, totalDifference, isReport, reportId, custom_fields_data, otherData }) => {
  const siteSettingForformatField = {
    inventory_decimalpoint: getAccountSitesetting("inventory_decimalpoint"),
    rate_decimalpoint: getAccountSitesetting("rate_decimalpoint"),
    timezone: getAccountSitesetting("timezone"),
  }

  var printdata = JSON.parse(JSON.stringify(data))
  if (isReport && checkReportId([15, 89, 90, 91, 92, 93], reportId)) {
    columnDatafinal = [
      ...columnDatafinal,
      ...getCustomFieldColumn({ ddcustomfield: custom_fields_data, type: "master" }),
      otherData.In_OutDetailcolumnData[0],
      otherData.In_OutDetailcolumnData[1],
      ...getCustomFieldColumn({ ddcustomfield: custom_fields_data, type: "detail" }),
      otherData.In_OutDetailcolumnData[2],
    ]
    var tempData = []
    printdata.forEach((item) => {
      var newItem = {}
      item.DetailGrid.forEach((it) => {
        newItem = { ...item, ...it }
        tempData.push(newItem)
      })
    })
    printdata = cloneObj(tempData)
  }

  var mycol = columnDatafinal.filter((column) => {
    if (column.visible) {
      if (isblank(column.display)) {
        return true
      } else if (getAccountSitesetting(column.display) === 1) {
        return true
      }
      return false
    }
    return false
  })
  var removeColumn = Object.keys(printdata[0])
  removeColumn = removeColumn.filter((item) => {
    var found = mycol.filter((column) => {
      if (column.id === item) {
        return true
      }
      return false
    })

    if (found.length > 0) {
      return false
    }

    return true
  })
  var obj = {}
  var count = 0
  var dataForCalc = cloneObj(printdata)
  if (requireTotal) {
    mycol.forEach((colItem) => {
      obj[colItem.id] = ""
      if (colItem.total === "label" && count === 0) {
        count = count + 1
        obj[colItem.id] = "Total"
      } else if (colItem.total && colItem.total !== "label") {
        obj[colItem.id] = parseFloat(lodashSum(lodashMap(dataForCalc, (d) => parseFloat(getNumberonly(d[colItem.id])))))
      }
    })
    printdata.push(obj)
  }

  if (totalDifference) {
    var obj1 = {}
    mycol.forEach((colItem) => {
      obj1[colItem.id] = ""
      if (!isblank(colItem.totalDifferenceLabel)) {
        obj1[colItem.id] = colItem.totalDifferenceLabel
      } else if (!isblank(colItem.totalDifference)) {
        var differenceFromData = mycol.find((e) => e.id === colItem.totalDifference.diferenceFrom)
        var differenceOfData = mycol.find((e) => e.id === colItem.totalDifference.diferenceOf)
        differenceFromData.total = 0
        differenceOfData.total = 0
        if (differenceFromData.format === "qty_decimal") {
          differenceFromData.total = lodashSum(lodashMap(dataForCalc, (d) => parseFloat(getNumberonlyDecimal(d[differenceFromData.id], siteSettingForformatField.inventory_decimalpoint))))
        } else {
          differenceFromData.total = lodashSum(lodashMap(dataForCalc, (d) => parseFloat(getNumberonly(d[differenceFromData.id]))))
        }
        if (differenceOfData.format === "qty_decimal") {
          differenceOfData.total = lodashSum(lodashMap(dataForCalc, (d) => parseFloat(getNumberonlyDecimal(d[differenceOfData.id], siteSettingForformatField.inventory_decimalpoint))))
        } else {
          differenceOfData.total = lodashSum(lodashMap(dataForCalc, (d) => parseFloat(getNumberonly(d[differenceOfData.id]))))
        }
        var differenceFrom =
          differenceFromData.format === "qty_decimal" ? getNumberonlyDecimal(differenceFromData.total, siteSettingForformatField.inventory_decimalpoint) : getNumberonly(differenceFromData.total)
        var differenceOf =
          differenceOfData.format === "qty_decimal" ? getNumberonlyDecimal(differenceOfData.total, siteSettingForformatField.inventory_decimalpoint) : getNumberonly(differenceOfData.total)
        colItem.Difference = parseFloat(differenceFrom) - parseFloat(differenceOf)
        obj1[colItem.id] = colItem.Difference
      }
    })
    printdata.push(obj1)
  }

  printdata = printdata.map((item) => {
    const updatedItem = { ...item }
    mycol.forEach((colItem) => {
      if (colItem.numeric) {
        if (isblank(updatedItem[colItem.id])) {
          updatedItem[colItem.id] = ""
        } else if (colItem.format === "indian_rupee") {
          updatedItem[colItem.id] = parseFloat(getNumberonly(item[colItem.id]))
        } else if (colItem.format === "qty_decimal") {
          updatedItem[colItem.id] = parseFloat(getNumberonlyDecimal(item[colItem.id], siteSettingForformatField.inventory_decimalpoint))
        } else {
          updatedItem[colItem.id] = format_excel_field({ value: item[colItem.id], format: colItem.format, localSetting: siteSettingForformatField })
        }
      }
    })
    return updatedItem
  })

  var NewData = []

  if (isReport && checkReportId([15, 89, 90, 91, 92, 93], reportId)) {
    printdata.forEach((detailGrid) => {
      NewData.push(detailGrid)
      if (!isblank(detailGrid.itemParameterizedDetail) && detailGrid.itemParameterizedDetail.length > 0) {
        mycol = [...mycol, ...getCustomFieldColumn({ ddcustomfield: custom_fields_data, type: "detailLevel2" })]
        var Mycol = {
          item: "Parameterized Detail",
          narration: "Description",
          Qty: "Qty",
        }
        NewData.push(Mycol)
        detailGrid.itemParameterizedDetail.forEach((it, pindex) => {
          var newItem = { ...it }
          if (pindex === 0) {
            newItem.item = detailGrid.item
          } else {
            newItem.item = ""
          }
          newItem.narration = it.description
          newItem.Qty = parseFloat(getNumberonlyDecimal(it.qtydata, siteSettingForformatField.inventory_decimalpoint))
          delete newItem.description
          delete newItem.qtydata
          NewData.push(newItem)
        })
      }
    })
    printdata = NewData
  }
  removeColumn.forEach((item, index) => {
    printdata = lodashFpMap(lodashOmit(item), printdata)
  })

  const functions = { getAccountSitesetting, user_data: "" }

  printdata = makeExcelRequiredData({ data: printdata, column: mycol, functions })
  mycol = makeExcelRequireCol(mycol, getAccountSitesetting)
  exportAsExcel({ csvData: printdata, fileName: filename, title, mycol })
}

export const JSONParseInEdit = (value) => {
  if (isblank(value)) {
    return ""
  }
  if (typeof value === "string") {
    return JSON.parse(value)
  }
  return value
}

export const JSONStringifyInPost = (value) => {
  if (isblank(value)) {
    return ""
  }
  if (typeof value === "string") {
    return value
  }
  return JSON.stringify(value)
}

export const getTransDate = (dateKey, format) => {
  const state = store.getState()
  const date = state.CommonReducer[dateKey]
  const lastTransactionDate = parseInt(getAccountSitesetting("lastTransactionDate"))
  if (lastTransactionDate === 1 && isValidDate(date)) {
    if (format === "DMY") {
      return DatetoDMY(date)
    } else {
      return DatetoYMD(date)
    }
  } else {
    return getTransactionsDay(format)
  }
}

export const setLastTansDate = ({ dateKey, format, reduxParams }) => {
  const { dispatch, form, field } = reduxParams
  dispatch(change(form, field, getTransDate(dateKey, format)))
}

export const setLastTansDateV1 = ({ dateKey, format, params }) => {
  const { field, setValue } = params
  setValue(field, getTransDate(dateKey, format))
}

export const getheaders = () => {
  const state = store.getState()
  const { auth_token } = state.AuthReducer
  var final_auth_token = !isblank(auth_token) ? auth_token : getStorage(`auth_token_${getWindowYear()}`)
  final_auth_token = !isblank(final_auth_token) ? final_auth_token : ""

  return {
    Authorization: final_auth_token,
    version: process.env.REACT_APP_VERSION,
    devicetype: isDesktop ? 0 : 3,
    "X-Server-Select": "account_" + getSubDomain(),
  }
}

export const setFormatOfInvoiceDetailGrid = ({ DetailGrid, dditem }) => {
  return common_setFormatOfInvoiceDetailGrid({ DetailGrid, dditem, getAccountSitesetting })
}

export const setFormatOfInOutDetailGrid = (DetailGrid) => {
  return common_setFormatOfInOutDetailGrid(DetailGrid, getAccountSitesetting)
}

export const setColumnDataForFilter = (columnData) => {
  return common_setColumnDataForFilter(columnData, getMenuData)
}

export const changeURL = (company_url, url) => {
  if (process.env.REACT_APP_NODE_ENV === "development") {
    return "http://" + company_url + `.localhost:${window.location.port}` + url
  } else if (process.env.REACT_APP_NODE_ENV === "test") {
    return "http://" + company_url + ".finalbooks.local" + url
  } else {
    return "https://" + company_url + ".finalbooks.in" + url
  }
}

export const getOnlyInvoice = (type) => {
  return common_getOnlyInvoice(getAccountSitesetting, type)
}

export const replacePreFix = (prefixStr) => {
  return common_replacePreFix(prefixStr, getAccountSessionUserData)
}

export const getNextProcess = (finaDetailGrid, ddprocess) => {
  let returnValue = {}

  let nextProcessField = false
  let next_process = ""
  let next_supplierSelectedValue = ""
  let processes = []
  finaDetailGrid.forEach((e) => {
    if (!isBlankCode(e.processcode) && processes.filter((i) => convertIntif(i) === convertIntif(e.processcode)).length === 0) {
      processes.push(e.processcode)
    }
  })
  if (processes.length === 1) {
    let selectedProcess = createDropDown(ddprocess, processes[0], "code")
    if (!isBlankCode(selectedProcess.next_process)) {
      next_process = selectedProcess.next_process
      nextProcessField = true
      let nextProcess = createDropDown(ddprocess, selectedProcess.next_process, "code")
      if (!isBlankCode(nextProcess.default_account)) {
        next_supplierSelectedValue = nextProcess.default_account
      }
    }
  }

  returnValue = {
    nextProcessField,
    next_process,
    next_supplierSelectedValue,
  }
  return returnValue
}

export const barcodeType = () => {
  return [
    {
      id: "1",
      name: "Barcode",
    },
    {
      id: "2",
      name: "QR Code",
    },
  ]
}

export const barcodeWidth = () => {
  return [
    {
      id: "1",
      name: "1",
    },
    {
      id: "2",
      name: "2",
    },
    {
      id: "3",
      name: "3",
    },
    {
      id: "4",
      name: "4",
    },
  ]
}

export const barcodeStyle = () => {
  return [
    {
      id: "CODE128",
      name: "CODE128 auto",
    },
    {
      id: "CODE128A",
      name: "CODE128 A",
    },
    {
      id: "CODE128B",
      name: "CODE128 B",
    },
    {
      id: "CODE128C",
      name: "CODE128 C",
    },
    {
      id: "EAN13",
      name: "EAN13",
    },
    {
      id: "EAN8",
      name: "EAN8",
    },
    {
      id: "UPC",
      name: "UPC",
    },
    {
      id: "CODE39",
      name: "CODE39",
    },
    {
      id: "ITF14",
      name: "ITF14",
    },
    {
      id: "ITF",
      name: "ITF",
    },
    {
      id: "MSI",
      name: "MSI",
    },
    {
      id: "MSI10",
      name: "MSI10",
    },
    {
      id: "MSI11",
      name: "MSI11",
    },
    {
      id: "MSI1010",
      name: "MSI1010",
    },
    {
      id: "MSI1110",
      name: "MSI1110",
    },
    {
      id: "pharmacode",
      name: "pharmacode",
    },
  ]
}

export const getAllDiscountType = () => {
  return [
    {
      id: "0",
      name: "Percentage",
    },
    {
      id: "1",
      name: "Fixed Value",
    },
  ]
}

export const getAllDiscountApplyType = () => {
  return [
    {
      id: "0",
      name: "All Products",
    },
    {
      id: "1",
      name: "Specific Products",
    },
  ]
}

export const isVersionGreaterOrEqual = ({ currentVersion, latestVersion = "", minimumVersion = "" }) => {
  const componentsCurrent = currentVersion.split(".").map(Number)
  const componentsLatest = latestVersion.split(".").map(Number)
  const componentsMinimum = minimumVersion.split(".").map(Number)
  for (let i = 0; i < Math.max(componentsCurrent.length, componentsLatest.length, componentsMinimum.length); i++) {
    const valueCurrent = componentsCurrent[i] || 0
    // const valueLatest = componentsLatest[i] || 0
    const valueMinimum = componentsMinimum[i] || 0
    if (valueCurrent < valueMinimum) {
      return false // current version is less than minimum version
    } /*  else if (valueCurrent > valueLatest) {
      return false // current version is greater than latest version
    } */
  }
  return true // current version is greater than or equal to minimum version and less than or equal to latest version
}

export const getBlankRow = (columnData, dS) => {
  const zeroQty = amount_formatDecimal(0, dS.inventory_decimalpoint)
  const formatFields = columnData.map((item) => item.id)

  const blankRow = {}
  formatFields.forEach((item) => {
    if (item === "srno") {
    } else if (item === "itemQty") {
      blankRow[item] = dS.showDefaultQty === 1 ? amount_formatDecimal(1, dS.inventory_decimalpoint) : zeroQty
    } else {
      blankRow[item] = ""
    }
  })
  return blankRow
}

export const getInwardOutwardColumnData = ({
  settingDetail,
  other_type,
  invoice_type,
  classes,
  ddcustomfield,
  cF,
  theme,
  formName,
  gridName,
  data,
  dS,
  ___cust_show_godown,
  __cust_select_type,
  zeroQty,
  AccountType,
  show_rate_amount,
  barcodeButton,
}) => {
  var columnData = []
  var total = ""
  var totalAmt = ""
  var rowValue = {}
  if (
    dS.Business_Type === "Wood" &&
    !isblank(other_type) &&
    (other_type === "job_inward" || other_type === "finish_stock" || other_type === "delivery_challan") &&
    !isblank(data) &&
    !isblank(data.DetailGrid) &&
    data.DetailGrid.length > 0
  ) {
    rowValue = data.DetailGrid[0]
  }

  const getTotal = (type, f) => {
    let returnValue = ""
    if (type === "itemQty") {
      if (formName === "StockTransferform") {
        if (gridName === "outwardDetail") {
          returnValue = amount_formatDecimal(data.out_tot_qty, dS.inventory_decimalpoint)
        } else {
          returnValue = amount_formatDecimal(data.in_tot_qty, dS.inventory_decimalpoint)
        }
      } else if (formName === "ProcessTransferForm") {
        returnValue = amount_formatDecimal(data.packing_tot_qty, dS.inventory_decimalpoint)
      } else if (formName === "InwardForm" && gridName === "InwardDetailGrid") {
        returnValue = amount_formatDecimal(data[`tot_qty_${gridName}`], dS.inventory_decimalpoint)
      } else {
        returnValue = amount_formatDecimal(data.tot_qty, dS.inventory_decimalpoint)
      }
    } else if (type === "calcAmount") {
      if (formName === "StockTransferform") {
        if (gridName === "outwardDetail") {
          returnValue = amount_formatDecimal(data.out_gross_amt, dS.inventory_decimalpoint)
        } else {
          returnValue = amount_formatDecimal(data.in_gross_amt, dS.inventory_decimalpoint)
        }
      } else if (formName === "ProcessTransferForm") {
        if (gridName === "PackingDetailGrid") {
          returnValue = amount_formatDecimal(data.packing_gross_amt, dS.inventory_decimalpoint)
        } else {
          returnValue = amount_formatDecimal(data.tot_gross_amt, dS.inventory_decimalpoint)
        }
      } else if (formName === "InwardForm" && gridName === "InwardDetailGrid") {
        returnValue = amount_formatDecimal(data[`tot_gross_amt_${gridName}`], 2)
      } else if (formName === "IWForm") {
        returnValue = amount_formatDecimal(data.tot_gross_amt, 2)
      } else if (formName === "OwForm") {
        returnValue = amount_formatDecimal(data.tot_gross_amt, 2)
      } else {
        returnValue = amount_formatDecimal(data.tot_gross_amt, 2)
      }
    } else if (type === "customField") {
      if (formName === "StockTransferform") {
        if (gridName === "outwardDetail") {
          returnValue = data.hasOwnProperty("outward___cust_customFieldTotal")
            ? amount_formatDecimal(data.outward___cust_customFieldTotal[f.name], f.decimalPoint)
            : amount_formatDecimal(0, f.decimalPoint)
        } else {
          returnValue = data.hasOwnProperty("inward___cust_customFieldTotal")
            ? amount_formatDecimal(data.inward___cust_customFieldTotal[f.name], f.decimalPoint)
            : amount_formatDecimal(0, f.decimalPoint)
        }
      } else if (formName === "InwardForm" && gridName === "InwardDetailGrid") {
        returnValue = data.hasOwnProperty("__cust_customFieldTotal_InwardDetailGrid")
          ? amount_formatDecimal(data.__cust_customFieldTotal_InwardDetailGrid[f.name], f.decimalPoint)
          : amount_formatDecimal(0, f.decimalPoint)
      } else {
        returnValue = data.hasOwnProperty("__cust_customFieldTotal") ? amount_formatDecimal(data.__cust_customFieldTotal[f.name], f.decimalPoint) : amount_formatDecimal(0, f.decimalPoint)
      }
    }
    return returnValue
  }

  if (!isblank(settingDetail)) {
    columnData = [{ id: "srno", label: "#", style: { width: 50 } }]
    if (other_type === "packing_slip" && settingDetail.entry === "M") {
      columnData.push({ id: "master_billNo", label: `${settingDetail.name} No`, className: classes.w150 })
      if (
        ddcustomfield.filter(function (item) {
          return item.masterDetailLevel === "0" && item.isActive === "1" && item.isHidden !== "1" && item.manualRender !== "1"
        }).length > 0
      ) {
        ddcustomfield
          .filter(function (item) {
            return item.masterDetailLevel === "0" && item.isActive === "1" && item.isHidden !== "1" && item.manualRender !== "1"
          })
          .forEach((f) => {
            var minWidth = parseFloat(f.gridWidth)
            if (parseInt(f.dataType) === cF.Dropdown || parseInt(f.dataType) === cF.Creatable_Dropdown) {
              minWidth = parseFloat(f.gridWidth) < 100 ? 100 : parseFloat(f.gridWidth)
            }
            if (f.cal_total === "1") {
              total = getTotal("customField", f)
            }
            columnData.push({
              id: f.name,
              customField: true,
              manual: false,
              f: f,
              total,
              label: f.labelName,
              align: parseInt(f.dataType) === cF.Decimal ? "right" : "left",
              style: { minWidth: minWidth, width: parseInt(f.gridWidth), textTransform: "capitalize", color: f.isMandatory === "1" ? theme.palette.error.main : theme.palette.text.main },
            })
            total = ""
          })
      }
    }

    columnData.push({ id: "itemSelectedValue", label: "Description of Goods", style: { minWidth: 200, color: theme.palette.error.main } })

    if (formName === "StockTransferform" && gridName === "outwardDetail") {
      if (parseInt(settingDetail.showGodown) === 1 && parseInt(dS.multigodown_mng) === 1 && parseInt(___cust_show_godown) === 1 && __cust_select_type !== "delivery_challan_to_stocktransfer") {
        columnData.push({
          id: "goDownSelectedValue",
          label: "Godown",
          className: classes.w250,
          width: 200,
          style: { color: settingDetail.require_godown === "1" ? theme.palette.error.main : "" },
        })
      }
    } else {
      if (parseInt(settingDetail.showGodown) === 1 && parseInt(dS.multigodown_mng) === 1 && parseInt(___cust_show_godown) === 1) {
        columnData.push({
          id: "goDownSelectedValue",
          label: "Godown",
          className: classes.w250,
          width: 200,
          style: { color: settingDetail.require_godown === "1" ? theme.palette.error.main : "" },
        })
      }
    }
    // if (dS.iD === 1) {
    if (settingDetail.show_detail_narration === "1") {
      columnData.push({ id: "itemNarration", label: "Narration", className: classes.w250 })
    }
    if (
      ddcustomfield.filter(function (item) {
        return item.masterDetailLevel === "1" && item.isActive === "1" && item.manualRender !== "1" && item.isHidden !== "1"
      }).length > 0
    ) {
      ddcustomfield
        .filter(function (item) {
          return item.masterDetailLevel === "1" && item.isActive === "1" && item.manualRender !== "1" && item.isHidden !== "1"
        })
        .forEach((f) => {
          const render = checkRenderConditionV1({ f, data, other_type, rowValue, dS })
          if (!render) {
            return false
          }
          if (render) {
            var minWidth = parseFloat(f.gridWidth)
            if (parseInt(f.dataType) === cF.Dropdown || parseInt(f.dataType) === cF.Creatable_Dropdown) {
              minWidth = parseFloat(f.gridWidth) < 100 ? 100 : parseFloat(f.gridWidth)
            }
            if (f.cal_total === "1") {
              total = getTotal("customField", f)
            }
            columnData.push({
              id: f.name,
              customField: true,
              manual: false,
              f: f,
              total,
              label: f.labelName,
              align: parseInt(f.dataType) === cF.Decimal ? "right" : "left",
              style: { minWidth: minWidth, width: parseInt(f.gridWidth), textTransform: "capitalize", color: f.isMandatory === "1" ? theme.palette.error.main : theme.palette.text.main },
            })
            total = ""
          }
        })
    }
    if (__cust_select_type === "Finish_stock") {
      if (other_type !== "delivery_challan") {
        total = data.hasOwnProperty("__cust_total_fs_ori_qty") ? amount_formatDecimal(data.__cust_total_fs_ori_qty, dS.inventory_decimalpoint) : zeroQty
        columnData.push({ id: "__cust_finsish_stock_ori_qty", total, label: "Ori Qty", className: classes.w100, align: "right" })

        total = data.hasOwnProperty("__cust_total_fs_pen_qty") ? amount_formatDecimal(data.__cust_total_fs_pen_qty, dS.inventory_decimalpoint) : zeroQty
        columnData.push({ id: "__cust_finsish_stock_pen_qty", total, label: "Pen Qty", className: classes.w100, align: "right" })
      }

      if ((other_type === "delivery_challan" || other_type === "manage_lot_master") && dS.enable_barcode === 1) {
        columnData.push({ id: "barcode", label: "Barcode", className: classes.w100, disabled: true })
      }
    }
    if (other_type === "manage_lot_master" && data.LastType === "purchase_invoice") {
      total = data.hasOwnProperty("ori_tot_qty") ? amount_formatDecimal(data.ori_tot_qty, dS.inventory_decimalpoint) : zeroQty
      columnData.push({ id: "oriItemQty", total, label: "Ori Qty", className: classes.w100, align: "right" })

      total = data.hasOwnProperty("pen_tot_qty") ? amount_formatDecimal(data.pen_tot_qty, dS.inventory_decimalpoint) : zeroQty
      columnData.push({ id: "ps_penQty", total, label: "Pen Qty", className: classes.w100, align: "right" })
    }

    total = getTotal("itemQty")
    if (AccountType === "8") {
      columnData.push({ id: "itemQty", style: { color: theme.palette.error.main }, total, label: "Fine", className: classes.w85, align: "right" })
    } else {
      columnData.push({ id: "itemQty", style: { color: theme.palette.error.main }, total, label: "Quantity", className: classes.w100, align: "right" })
    }

    if (show_rate_amount) {
      columnData.push({ id: "itemRate", numeric: true, disabledFilter: true, label: "Rate", className: classes.w150, align: "right", currencysign: true })
      totalAmt = getTotal("calcAmount")
      columnData.push({ id: "calcAmount", numeric: true, disabledFilter: true, label: "Amount", total: totalAmt, className: classes.w150, align: "right", currencysign: true, disabled: true })
    }

    if (invoice_type === "inward" && other_type === "finish_stock" && dS.enable_barcode === 1) {
      columnData.push({ id: "barcode", label: "Barcode", className: classes.w100, align: "left", disabled: true })
    }

    if (invoice_type === "inward" && other_type === "finish_stock" && dS.quantity_multiplier === 1) {
      columnData.push({ id: "quantity_multiplier", label: "QTY Multiplier", className: classes.w100, align: "right" })
    }

    if (settingDetail.parameterizedQty === "1") {
      columnData.push({ id: "itemParameterizedDetail", label: "Parameterized Qty", className: classes.w150 })
    }
    if (dS.advance_tracking === 1) {
      columnData.push({ id: "itemBatches", label: "Batch / Serial No", className: classes.w150 })
    }
    if (settingDetail.clone_item === "1") {
      columnData.push({ id: "clone", label: "", className: classes.w50 })
    }
    if (barcodeButton && dS.enable_barcode === 1) {
      columnData.push({ id: "barcode_print", label: "", className: classes.w50 })
    }

    columnData.push({ id: "delete", label: "", className: classes.w50 })
    if (
      ddcustomfield.filter(function (item) {
        return item.masterDetailLevel === "1" && item.isActive === "1" && item.manualRender === "1"
      }).length > 0
    ) {
      ddcustomfield
        .filter(function (item) {
          return item.masterDetailLevel === "1" && item.isActive === "1" && item.manualRender === "1"
        })
        .forEach((f) => {
          var minWidth = parseFloat(f.gridWidth)
          if (parseInt(f.dataType) === cF.Dropdown || parseInt(f.dataType) === cF.Creatable_Dropdown) {
            minWidth = parseFloat(f.gridWidth) < 100 ? 100 : parseFloat(f.gridWidth)
          }
          total = ""
          if (f.cal_total === "1") {
            total = getTotal("customField", f)
          }
          columnData.splice(parseInt(f.Sort, 10) - 1, 0, {
            id: f.name,
            f: f,
            total,
            customField: true,
            manual: true,
            label: f.labelName,
            align: parseInt(f.dataType) === cF.Decimal ? "right" : "left",
            style: { minWidth: minWidth, width: parseInt(f.gridWidth), textTransform: "capitalize", color: f.isMandatory === "1" ? theme.palette.error.main : theme.palette.text.main },
          })
          total = ""
        })
    }
    if (AccountType === "8") {
      if (!settingDetail.less_wt) {
        columnData = columnData.filter((item) => item.id !== "__cust_less_wt")
      }
      if (!settingDetail.stone_wt) {
        columnData = columnData.filter((item) => item.id !== "__cust_stone_wt")
      }
      if (!settingDetail.wax_wt) {
        columnData = columnData.filter((item) => item.id !== "__cust_wax_wt")
      }
      if (!settingDetail.wastage_wt) {
        columnData = columnData.filter((item) => item.id !== "__cust_wastage_wt")
      }
      if (!(settingDetail.wastage_wt || settingDetail.wax_wt || settingDetail.stone_wt || settingDetail.less_wt)) {
        columnData = columnData.filter((item) => item.id !== "__cust_net_wt")
      }
      if (!settingDetail.wastage_per) {
        columnData = columnData.filter((item) => item.id !== "__cust_waste")
      }
    }
  }
  return columnData
}

export const maskPhoneNo = (number) => {
  // const lastFourNo = number.slice(-4)
  // const mask = number.substring(0, number.length - 4).replace(/\d/g, "*")
  // return mask + lastFourNo

  const strNumber = String(number)

  // Get the last four digits of the phone number
  const lastFourNo = strNumber.slice(-4)

  // Mask the rest of the phone number with asterisks
  const mask = strNumber.substring(0, strNumber.length - 4).replace(/\d/g, "*")

  // Return the masked phone number
  return mask + lastFourNo
}

export const maskEmail = (email) => {
  // Split the email into the local part and the domain part
  if (isblank(email)) {
    return ""
  }
  const [localPart, domain] = email.split("@")

  // Mask the local part, keeping the first character visible
  const maskedLocalPart = localPart[0] + localPart.slice(1).replace(/./g, "*")

  // Return the masked email
  return `${maskedLocalPart}@${domain}`
}

export const EncryptCode = (str) => {
  return encodeURIComponent(btoa(str))
}

export const DecryptCode = (str) => {
  return atob(decodeURIComponent(str))
}

export const unsecuredCopyToClipboard = (text) => {
  const textArea = document.createElement("textarea")
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  try {
    document.execCommand("copy")
  } catch (err) {}
  document.body.removeChild(textArea)
}

export const copyToClipboard = async (text) => {
  if (navigator.clipboard && typeof navigator.clipboard.writeText === "function") {
    try {
      await navigator.clipboard.writeText(text)
    } catch (err) {}
  } else {
    // Fallback for older browsers
    const textArea = document.createElement("textarea")
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
      document.execCommand("copy")
    } catch (err) {}
    document.body.removeChild(textArea)
  }
}
