import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Popover, Typography } from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import StandardTextField from "@project/components/formComponents/standardTextField"
import { isblank } from "@project/sharedcomponents/utilityFunctions"
import React, { useState } from "react"
export default ({ label, value, edit, editType, onSubmit }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const [editValue, setEditValue] = useState(value)
  const [error, setError] = useState(null)

  const editClick = (event) => {
    setEditValue(value)
    setAnchorEl(event.currentTarget)
  }
  const onCancelBtnClick = () => {
    setEditValue("")
    setError("")
    setAnchorEl(null)
  }
  const handleChange = (val) => {
    setEditValue(val)
  }
  const updateClick = () => {
    if (isblank(editValue)) {
      setError(`${label} is required`)
    } else {
      const onSuccess = () => {
        setEditValue("")
        setAnchorEl(null)
      }
      const onError = (apiError) => {
        setError(apiError)
      }
      onSubmit(editValue, onSuccess, onError)
    }
  }
  return (
    <React.Fragment>
      <div style={{ marginBottom: 8 }}>
        <div>
          <Typography variant="caption" style={{ fontWeight: "bold" }}>
            {label}
          </Typography>
          {edit && (
            <IconButton size="small" onClick={editClick} style={{ marginLeft: 4 }}>
              <Edit fontSize="inherit" style={{ fontSize: 14 }} />
            </IconButton>
          )}
        </div>
        <Typography variant="subtitle1">{!isblank(value) ? value : "-"}</Typography>
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onCancelBtnClick}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0 }}>
          Update
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0 }}>
          <StandardTextField id="name" onChange={(val) => handleChange(val)} label={label} autoFocus error={error} value={editValue} />
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={onCancelBtnClick} variant="text">
            Dismiss
          </Button>
          <Button size="small" onClick={updateClick} variant="text" color="primary">
            Update
          </Button>
        </DialogActions>
      </Popover>
    </React.Fragment>
  )
}
