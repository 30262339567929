import { convertIntif } from "@project/sharedcomponents/utilityFunctions"
import {
  IPOSTOCKPARTY_SERVICE_DELETED,
  IPOSTOCKPARTY_SERVICE_FAIL,
  IPOSTOCKPARTY_SERVICE_INSERTED,
  IPOSTOCKPARTY_SERVICE_START,
  IPOSTOCKPARTY_SERVICE_SUCCESS,
  IPOSTOCKPARTY_SERVICE_UPDATED,
} from "../Actions/types"

const INITIAL_STATE = {
  current_tab_index: 0,
  error: "",
  loading: false,
  data: [],
  totalcount: 0,
  rowsPerPage: "",
  norecord: false,
  page: 0,
  order: "asc",
  orderBy: "code",
  search_text: "",
  deleted: false,
  columnData: [
    { id: "code", numeric: false, visible: true, label: "Id", width: 50 },
    { id: "name", numeric: false, visible: true, label: "Name" },
  ],
  metadata: "",
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IPOSTOCKPARTY_SERVICE_START:
      return { ...state, loading: true, error: "" }

    case IPOSTOCKPARTY_SERVICE_SUCCESS:
      return {
        ...state,
        data: action.payload.data.result,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        islogin: true,
        norecord: action.payload.data.result.length === 0,
      }
    case IPOSTOCKPARTY_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        data: [],
        loading: false,
        totalcount: 0,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
      }

    case IPOSTOCKPARTY_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_code
      inserteditem.name = action.payload.name
      return { ...state, data: [inserteditem, ...state.data] }
    case IPOSTOCKPARTY_SERVICE_DELETED:
      return { ...state, data: state.data.filter((item) => convertIntif(item.code) !== convertIntif(action.payload)), deleted: !state.deleted }
    case IPOSTOCKPARTY_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.update_code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.name = action.payload.name
        return updateditem
      })

      return { ...state, data: updateddata }
    default:
      return state
  }
}
