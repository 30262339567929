import { FormControlLabel, FormHelperText, Switch } from "@material-ui/core"
import { getCheckBoxValue, isblank } from "@project/sharedcomponents/utilityFunctions"
import React from "react"

const RenderToggleButton = ({ name, label, value, fullWidth, onChange, isRequired, error, ...props }) => {
  if (isblank(label)) {
    return (
      <React.Fragment>
        <Switch id={name} required={isRequired} onChange={(e) => onChange(e.target.checked)} checked={getCheckBoxValue(value)} {...props} />
        {!isblank(error) && error && (
          <FormHelperText id="component-helper-text" error={true}>
            {error}
          </FormHelperText>
        )}
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <FormControlLabel
          component="div"
          fullwidth={fullWidth}
          margin="normal"
          control={<Switch id={name} required={isRequired} onChange={(e) => onChange(e.target.checked)} checked={getCheckBoxValue(value)} {...props} />}
          label={label}
        />
        {!isblank(error) && error && (
          <FormHelperText id="component-helper-text" error={true}>
            {error}
          </FormHelperText>
        )}
      </React.Fragment>
    )
  }
}

export default RenderToggleButton
