import { FormHelperText, useTheme } from "@material-ui/core"
import { isblank } from "@project/sharedcomponents/utilityFunctions"
import React from "react"
import { isMobileOnly } from "react-device-detect"
import { useSelector } from "react-redux"
import Select from "react-select"
import LabelComponent from "../common/labelComponent"
import {
  ClearIndicator,
  DropdownIndicator,
  IndicatorSeparator,
  LoadingIndicator,
  MenuList,
  MultiValue,
  Option,
  ValueContainer,
  colourStyles,
  formThemeColors,
  getMultiValue,
  getdropDownoptions,
} from "../reduxFormComponents/reactSelectComponent"

const renderReactSelect = ({
  name,
  label,
  noLableMargin,
  showOptions,
  isMulti,
  labelStyle,
  options,
  fullWidth,
  value,
  setUnderLine,
  isClearable,
  isRequired,
  isSearchable,
  error,
  onChange,
  ...props
}) => {
  const theme = useTheme()

  const onValueChange = (e) => {
    if (isMulti) {
      if (e != null) {
        const val = e.map((value) => value.value)
        onChange(val)
      } else {
        onChange([])
      }
    } else {
      if (isblank(e)) {
        onChange("")
      } else if (e.hasOwnProperty("row")) {
        onChange(e.row)
      } else {
        onChange(e.value)
      }
    }
  }
  const formattedOptions = {}
  options.forEach((obj) => {
    formattedOptions[obj.value] = obj
  })

  const accountSiteSettingKeyValue = useSelector((state) => state.AuthReducer.accountSiteSettingKeyValue)

  const dropDownoptions = getdropDownoptions(options)

  dropDownoptions.forEach((obj) => {
    formattedOptions[obj.value] = obj
  })

  let components = {
    Option: (OptionProps) => Option({ ...OptionProps, accountSiteSettingKeyValue }),
    MenuList: (MenuProps) =>
      MenuList({
        ...MenuProps,
        accountSiteSettingKeyValue,
        options: dropDownoptions,
        components,
        label,
      }),
    MultiValue,
    ClearIndicator: (cIProps) =>
      ClearIndicator({
        ...cIProps,
        isClearable,
        input: { value: value },
      }),

    DropdownIndicator: (DdProps) =>
      DropdownIndicator({
        ...DdProps,
        accountSiteSettingKeyValue,
        options: dropDownoptions,
        props,
        input: { value: value },
        isClearable,
      }),
    IndicatorSeparator: (IdProps) =>
      IndicatorSeparator({
        ...IdProps,
        input: { value: value },
        props,
        theme,
        isClearable,
      }),
    LoadingIndicator,
  }
  if (!isblank(showOptions)) {
    components.ValueContainer = (ValueProps) => ValueContainer({ ...ValueProps, showOptions })
  }
  return (
    <React.Fragment>
      <LabelComponent error={error} label={label} isRequired={isRequired} noLableMargin={noLableMargin} labelStyle={labelStyle} />
      <Select
        className={isMulti ? "basic-multi-select" : "basic-single"}
        classNamePrefix="select"
        styles={colourStyles(setUnderLine, "", error, theme)}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        onChange={onValueChange}
        components={components}
        name={name}
        {...(isMulti
          ? {
              value: !isblank(value) ? getMultiValue(value, dropDownoptions) : [],
            }
          : {
              value: value ? formattedOptions[value] : null,
            })}
        options={options}
        isMulti={isMulti}
        isClearable={isClearable !== undefined ? isClearable : true}
        isSearchable={isSearchable !== undefined ? isSearchable : true}
        theme={(theme1) => ({
          ...theme1,
          borderRadius: 0,
          colors: {
            ...formThemeColors(theme, false, error),
          },
        })}
        {...(isMobileOnly && {
          blurInputOnSelect: false,
        })}
        {...props}
      />
      {!isblank(error) && error && setUnderLine !== false && (
        <FormHelperText id="component-helper-text" error={true}>
          {error}
        </FormHelperText>
      )}
    </React.Fragment>
  )
}

export default renderReactSelect
