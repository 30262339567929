import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import { checkCondition } from "@project/sharedcomponents/utilityFunctions"
import { pushShortcutDrawer } from "./CommonActionsV2"
import { LAST_PAGE_UPDATE, SNACKBAR_STATE } from "./types"
import { getAccountSitesetting } from "../Lib/commonfunctions"

export const shortCutHandle = ({ shortCutkey, drawerStack }) => {
  return (dispatch) => {
    setTimeout(() => {
      var TITLE = ""
      var POSITION = ""
      var FIELDNAME = ""
      var WIDTH = ""
      if (
        checkCondition(shortCutkey, [
          "ADD_NEW_SALES",
          "ADD_NEW_RECEIPT",
          "ADD_NEW_PAYMENT",
          "ADD_NEW_JOURNAL_ENTRY",
          "ADD_NEW_CUSTOMER",
          "ADD_NEW_SUPPLIER",
          "ADD_NEW_PRODUCT",
          "ADD_NEW_OTHER_ACCOUNT",
          "ADD_NEW_PURCHASE",
          "UTILITY_BARCODE",
        ])
      ) {
        if (shortCutkey === "ADD_NEW_SALES") {
          TITLE = "sales_invoice"
          POSITION = "bottom"
        } else if (shortCutkey === "ADD_NEW_PURCHASE") {
          TITLE = "purchase_invoice"
          POSITION = "bottom"
        } else if (shortCutkey === "ADD_NEW_RECEIPT") {
          if (getAccountSitesetting("receipt_type") === "M") {
            TITLE = "receipt"
          } else {
            TITLE = "receiptdetail"
          }
          POSITION = "bottom"
        } else if (shortCutkey === "ADD_NEW_PAYMENT") {
          if (getAccountSitesetting("payment_type") === "M") {
            TITLE = "payment"
          } else {
            TITLE = "paymentdetail"
          }
          POSITION = "bottom"
        } else if (shortCutkey === "ADD_NEW_JOURNAL_ENTRY") {
          TITLE = "journal"
          POSITION = "bottom"
        } else if (shortCutkey === "ADD_NEW_CUSTOMER") {
          TITLE = "Add New CustomerSupplier"
          FIELDNAME = "customer"
          POSITION = "bottom"
        } else if (shortCutkey === "ADD_NEW_SUPPLIER") {
          TITLE = "Add New CustomerSupplier"
          FIELDNAME = "supplier"
          POSITION = "bottom"
        } else if (shortCutkey === "ADD_NEW_PRODUCT") {
          TITLE = "Add New Item"
          POSITION = "bottom"
        } else if (shortCutkey === "ADD_NEW_OTHER_ACCOUNT") {
          TITLE = "Add New Account"
          POSITION = "right"
          WIDTH = 450
        } else if (shortCutkey === "UTILITY_BARCODE") {
          TITLE = "customBarcodePrint"
          POSITION = "bottom"
        }

        pushShortcutDrawer({ title: TITLE, fieldname: FIELDNAME, position: POSITION, width: WIDTH, dispatch })
      } else if (shortCutkey === "ENTER") {
        var name = "keydown"
        var key = "tab"
        var event = createKeyboardEvent(name, key, false, false, false, false, true)
        document.addEventListener(name, () => {})
        document.dispatchEvent(event)
      } else {
        if (shortCutkey === "EDIT") {
          if (
            drawerStack &&
            drawerStack.filter((e) => checkCondition(e.drawer, ["Invoice Detail", "Transaction Detail", "Stock Transfer Detail", "Preview Customer", "Product Detail"], "not")).length > 0
          ) {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: ErrorMessages["E00052"],
              snackbarType: "warning",
              SnackbarOriginHorizontal: "center",
            })
          } else {
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "shortCut",
              lastaction: "press",
              lastdata: { key: shortCutkey },
              lasttime: new Date().getTime(),
            })
          }
        } else {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "shortCut",
            lastaction: "press",
            lastdata: { key: shortCutkey },
            lasttime: new Date().getTime(),
          })
        }
      }
    }, 50)
  }
}

function createKeyboardEvent(name, key, altKey, ctrlKey, shiftKey, metaKey, bubbles) {
  var e = new Event(name)
  e.key = key
  e.keyCode = e.key.charCodeAt(0)
  e.which = e.keyCode
  e.altKey = altKey
  e.ctrlKey = ctrlKey
  e.shiftKey = shiftKey
  e.metaKey = metaKey
  //e.bubbles = bubbles
  return e
}
