import { getAxiosCancelToken, getCatchErrorMessage, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getAccountSessionUserData, getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import { list_url } from "@project/sharedcomponents/apiURL"
import { DASHBOARD_SERVICE_FAIL, DASHBOARD_SERVICE_START, DASHBOARD_SERVICE_SUCCESS, LAST_PAGE_UPDATE, LOADING_STATE, SNACKBAR_STATE } from "./types"

export const fetch_dashboard_data = ({ companycode, other_type, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: DASHBOARD_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()

    data.append("financial_year", parseInt(getAccountSessionUserData("financial_year"), 10))
    data.append("company_url", companycode)
    data.append("uid", getAccountSessionUserData("user_id"))
    if (other_type) {
      data.append("other_type", other_type)
    }

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "dashboard" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dashboardServiceFail({ dispatch, response: response.data.meta.message })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dashboardServiceSuccess({ dispatch, user: response })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dashboardServiceFail({ dispatch, response: getCatchErrorMessage(error) })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const dashboardServiceSuccess = ({ dispatch, user }) => {
  dispatch({
    type: DASHBOARD_SERVICE_SUCCESS,
    payload: user,
  })

  dispatch({
    type: LAST_PAGE_UPDATE,
    lasttype: "dashboard",
    lastaction: "success",
    lasttime: new Date().getTime(),
    lastdata: "",
  })
}

const dashboardServiceFail = ({ dispatch, response }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })

  dispatch({
    type: DASHBOARD_SERVICE_FAIL,
    payload: response,
  })
}
