import { SvgIcon } from "@material-ui/core"
import React from "react"
function CustomSvgIcon({ name, ...props }) {
  if (name === "downloadExcel") {
    return (
      <SvgIcon viewBox="0 0 30 30" {...props}>
        <path d="M 15 3 A 2 2 0 0 0 14.599609 3.0429688 L 14.597656 3.0410156 L 4.6289062 5.0351562 L 4.6269531 5.0371094 A 2 2 0 0 0 3 7 L 3 23 A 2 2 0 0 0 4.6289062 24.964844 L 14.597656 26.958984 A 2 2 0 0 0 15 27 A 2 2 0 0 0 17 25 L 17 5 A 2 2 0 0 0 15 3 z M 19 5 L 19 8 L 21 8 L 21 10 L 19 10 L 19 12 L 21 12 L 21 14 L 19 14 L 19 16 L 21 16 L 21 18 L 19 18 L 19 20 L 21 20 L 21 22 L 19 22 L 19 25 L 25 25 C 26.105 25 27 24.105 27 23 L 27 7 C 27 5.895 26.105 5 25 5 L 19 5 z M 23 8 L 24 8 C 24.552 8 25 8.448 25 9 C 25 9.552 24.552 10 24 10 L 23 10 L 23 8 z M 6.1855469 10 L 8.5878906 10 L 9.8320312 12.990234 C 9.9330313 13.234234 10.013797 13.516891 10.091797 13.837891 L 10.125 13.837891 C 10.17 13.644891 10.258531 13.351797 10.394531 12.966797 L 11.785156 10 L 13.972656 10 L 11.359375 14.955078 L 14.050781 19.998047 L 11.716797 19.998047 L 10.212891 16.740234 C 10.155891 16.625234 10.089203 16.393266 10.033203 16.072266 L 10.011719 16.072266 C 9.9777187 16.226266 9.9105937 16.458578 9.8085938 16.767578 L 8.2949219 20 L 5.9492188 20 L 8.7324219 14.994141 L 6.1855469 10 z M 23 12 L 24 12 C 24.552 12 25 12.448 25 13 C 25 13.552 24.552 14 24 14 L 23 14 L 23 12 z M 23 16 L 24 16 C 24.552 16 25 16.448 25 17 C 25 17.552 24.552 18 24 18 L 23 18 L 23 16 z M 23 20 L 24 20 C 24.552 20 25 20.448 25 21 C 25 21.552 24.552 22 24 22 L 23 22 L 23 20 z" />
      </SvgIcon>
    )
  } else if (name === "downloadPdf") {
    return (
      <SvgIcon viewBox="0 0 30 30" {...props}>
        <path d="M14.077 13.7c-.217 0-.267.074-.3.123-.056.083-.15.291-.15.753 0 .512.102 1.112.279 1.753.262-.818.411-1.563.39-2.153-.016-.476-.129-.476-.219-.476z" />
        <path d="M24.707 9.793l-6.5-6.5A1 1 0 0017.5 3H7a2 2 0 00-2 2v21a2 2 0 002 2h16a2 2 0 002-2V10.5a1 1 0 00-.293-.707zM18.466 24.5c-1.135 0-2.399-1.152-3.491-2.729-.796.026-1.552.104-2.246.221-1.272 2.081-2.714 3.709-3.813 3.709-1.208 0-1.616-1.194-1.616-1.739 0-1.713 2.167-2.725 4.561-3.22.452-.771.89-1.605 1.268-2.432-.557-1.381-.9-2.73-.9-3.733 0-.636.131-1.152.389-1.536.322-.478.841-.74 1.46-.74.543 0 .999.229 1.282.646.228.334.338.764.338 1.315 0 .945-.424 2.429-1.083 4.018.318.721.69 1.434 1.085 2.083.918.006 2.18.09 3.209.436 1.481.496 1.792 1.312 1.792 1.91-.001.861-.7 1.791-2.235 1.791zM18 11a1 1 0 01-1-1V4.904L23.096 11H18z" />
        <path d="M8.7 23.961c.001 0 .057.339.215.339.38-.027 1.071-.752 1.833-1.826-1.265.427-2.048.983-2.048 1.487zM18.463 22.124c-.462-.155-1.066-.264-1.758-.32.643.788 1.272 1.296 1.76 1.296.576 0 .834-.197.834-.393.001-.184-.327-.412-.836-.583z" />
      </SvgIcon>
    )
  } else if (name === "whatsApp") {
    return (
      <SvgIcon viewBox="0 0 640 640" {...props}>
        <path d="M546.704 91.89C486.526 31.584 406.482-1.582 321.229-1.582 145.609-1.583 2.67 141.368 2.67 317.118c0 56.139 14.705 111.05 42.567 159.297L.001 641.595l168.959-44.34c46.595 25.382 99.013 38.835 152.222 38.835h.13C496.944 636.09 640 493.14 640 317.401c0-85.182-33.166-165.179-93.344-225.463l.047-.047zM321.323 582.315c-47.599 0-94.218-12.827-134.895-36.957l-9.697-5.788-100.265 26.257 26.776-97.726-6.272-10.04C70.312 415.965 56.4 367.244 56.4 317.13c0-146.082 118.832-264.96 265.066-264.96 70.713 0 137.328 27.65 187.302 77.622 49.996 50.127 77.493 116.588 77.493 187.42-.118 146.187-118.95 265.066-264.96 265.066l.024.036zM466.541 383.85c-7.913-4.028-47.115-23.233-54.39-25.89-7.276-2.658-12.58-4.028-17.977 4.027-5.268 7.914-20.587 25.89-25.252 31.265-4.666 5.28-9.284 6.035-17.197 2.008-7.914-4.028-33.674-12.426-64.064-39.568-23.634-21.095-39.662-47.221-44.328-55.134-4.665-7.914-.52-12.308 3.532-16.193 3.661-3.544 7.925-9.284 11.941-13.95 4.028-4.665 5.28-7.925 7.925-13.31 2.658-5.28 1.359-9.946-.637-13.95-2.008-4.015-17.977-43.217-24.485-59.185-6.39-15.603-13.063-13.43-17.965-13.701-4.665-.237-9.945-.237-15.2-.237-5.257 0-13.95 1.996-21.225 9.933-7.276 7.914-27.898 27.26-27.898 66.45 0 39.201 28.512 77.009 32.516 82.407 4.027 5.267 56.162 85.784 136.029 120.238 18.98 8.161 33.803 13.063 45.355 16.854 19.098 6.024 36.425 5.15 50.126 3.13 15.32-2.256 47.115-19.229 53.788-37.831 6.662-18.615 6.662-34.536 4.666-37.831-1.89-3.544-7.158-5.504-15.201-9.58l-.06.048z" />
      </SvgIcon>
    )
  } else if (name === "rupee") {
    return (
      <SvgIcon viewBox="0 0 344.329 344.329" width="200" height="200" {...props}>
        <path d="M277.965,90h-50.8c-2.9-28-18-54-40.1-70h90.9c5.5,0,10-4.5,10-10s-4.5-10-10-10h-211.6c-5.5,0-10,4.5-10,10s4.5,10,10,10    h60.6c41.2,0,75.2,31,80.1,70h-140.7c-5.5,0-10,4.5-10,10s4.5,10,10,10h140.7c-5,40.5-39.4,70.9-80.1,71h-60.5    c-5.6,0.1-10.1,4.7-10,10.3c0,2.7,1.2,5.3,3.1,7.2l151.3,143.1c4,3.8,10.3,3.6,14.1-0.4c3.8-4.1,3.6-10.4-0.4-14.3L91.465,201    h35.4c52.2,0,95.3-40,100.3-91h50.8c5.5,0,10-4.5,10-10S283.465,90,277.965,90z" />
      </SvgIcon>
    )
  } else if (name === "barcode") {
    return (
      <SvgIcon viewBox="0 0 83.266 83.266" {...props}>
        <path d="M0 11.434h6.09v60.398H0zM11.8 11.434h4.54v47.709H11.8zM22.825 11.434h4.54v47.709h-4.54zM33.851 11.434h4.539v47.709h-4.539zM44.875 11.434h4.54v47.709h-4.54zM55.9 11.434h4.54v47.709H55.9zM66.926 11.434h4.539v47.709h-4.539zM77.175 11.434h6.091v60.398h-6.091zM10.705 64.403v.976l1.498-.377v6.325h1.493v-7.808h-1.481zM25.542 65.829c0-1.312-.7-2.416-2.312-2.416-1.378 0-2.271.104-3.242.271V64.8h2.768c.916 0 1.287.329 1.287 1.037 0 1.236-2.978 3.507-3.976 4.179v1.312h5.474v-1.376h-2.945c.711-.565 2.946-2.373 2.946-4.123zM34.958 63.498c-1.248-.021-2.512-.031-3.45.299v1.077h2.848c.555 0 1.051.125 1.051.736v.384c0 .563-.315.73-1.051.763l-2.123.106v1.205l2.123.075c.635.027 1.029.163 1.029.808v.336c0 .679-.382.729-1.094.729h-2.709l-.011 1.088c.938.33 2.096.298 3.311.298 1.302 0 2.005-.676 2.005-1.909v-.453c0-.81-.243-1.352-.81-1.595.55-.205.81-.73.81-1.509v-.56c.001-1.14-.756-1.857-1.929-1.878zM48.481 65.61h-1.157l-.336.817v1.78h-1.932l2.332-4.688h-1.642l-2.779 5.419.363.688h3.658v1.679h1.493v-1.679l.785-.262v-1.157h-.785zM58.867 66.394c-.225-.006-.754-.046-1.165 0-.23.024-.518.086-.737.261l.081-1.76h3.307v-1.376h-4.741l-.234 4.815h1.407c0-.44.277-.624.652-.624h.997c.65 0 .714.41.714.763v.71c0 .328-.084.757-.778.757h-3.008v1.174c1.429.245 1.979.256 3.301.256.873 0 1.979-.47 1.979-1.931V68.34c-.001-1.087-.528-1.887-1.775-1.946zM70.607 66.586c-.848 0-1.534.064-2.159.225.01-.832.096-1.979 1.066-1.979h2.672v-1.133c-1.002-.18-2.326-.308-3.397-.214-.92.082-1.834.92-1.834 3.746 0 3.359.831 4.102 2.069 4.106h1.524c1.132 0 1.894-.465 1.894-1.766V68.41c0-1.014-.325-1.824-1.835-1.824zm.347 2.717c0 .558-.236.658-.675.658h-.814c-.909 0-1.017-.985-1.017-2.059h1.86c.438 0 .646.138.646.683v.718z" />
      </SvgIcon>
    )
  }
}

export default CustomSvgIcon
