import { decryption } from "@project/components/cryptoJSFunctions"
import { getStorage, removeStorage, setStorage } from "@project/components/sessionStoreFunctions"
import { list_url } from "@project/sharedcomponents/apiURL"
import menuId from "@project/sharedcomponents/constantData/menuIdEnum"
import { responseValid } from "@project/sharedcomponents/responseValidFn"
import { cloneObj, getAxiosCancelToken, getCatchErrorMessage, isblank, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { isDesktop } from "react-device-detect"
import { reset } from "redux-form"
import { changeURL, getAccountRightsByID, getheaders, getMenuRightsByType, getSubDomain, getWindowYear, goToNavigate, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import {
  AUTHENTICATE_KEY_GENERATED,
  AUTH_LOADING_STATE,
  AUTH_SNACKBAR_STATE,
  AUTH_USER_SUCCESS,
  COMPANYCODE_CHANGED,
  EMAIL_CHANGED,
  FORM_DEP_SERVICE_CLEAR,
  FORM_DEP_SERVICE_FAIL,
  FORM_DEP_SERVICE_START,
  FORM_DEP_SERVICE_SUCCESS,
  FORM_POST_SERVICE_CLEAR,
  FYYEARS,
  LAST_PAGE_UPDATE,
  LOADING_STATE,
  LOGIN_USER,
  LOGIN_USER_FAIL,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  NEW_SITE_SETTING_UPDATED,
  PAGE_FORM_POST_SERVICE_CLEAR,
  PASSWORD_CHANGED,
  RELOGIN_USER_SUCCESS,
  RESET_COMMON_REDUCER,
  SETMENU,
  SET_ALL_LAST_DATE,
  SET_CHECK_LOADING,
  SET_SETTING,
  SITE_SETTING_FIELD_SUCCESS,
  SNACKBAR_STATE,
  UPDATE_CONFIG_SETTING,
  UPDATE_USER_DATA,
  USER_RIGHTS_UPDATED,
} from "./types"

export const emailChanged = (text) => {
  return {
    type: EMAIL_CHANGED,
    payload: text,
  }
}
export const passwordChanged = (text) => {
  return {
    type: PASSWORD_CHANGED,
    payload: text,
  }
}
export const companycodeChanged = (text) => {
  return {
    type: COMPANYCODE_CHANGED,
    payload: text,
  }
}

export const getfbfinancialyear = (subdomain, onSuccess, functions) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOGIN_USER })
    let data = new FormData()
    data.append("company_url", subdomain)
    data.append("module_type", "accounting")

    axios({
      method: "post",
      url: list_url({ type: "firmyears" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded", devicetype: isDesktop ? 0 : 3, "X-Server-Select": "account_" + subdomain },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          loginUserFail(dispatch, response.data.meta.message)
        } else {
          onSuccess(response.data)
          setStorage("firm_years", response.data.years)
          dispatch({
            type: FYYEARS,
            payload: response.data,
          })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
        } else {
          postErrorOnException(error)
          loginUserFail(dispatch, getCatchErrorMessage(error))
        }
      })
  }
}

export const checkalreadylogin = ({ isloginPage = false, onCheckComplete }) => {
  var fyear = getWindowYear()
  return (dispatch) => {
    dispatch({
      type: SET_CHECK_LOADING,
      state: true,
    })
    var companyLoginYears = getStorage("companyLoginYears", "")
    if (!isblank(companyLoginYears)) {
      companyLoginYears = companyLoginYears.map((e) => parseInt(e))
      companyLoginYears.sort(function (a, b) {
        return b - a
      })
      if (companyLoginYears.length > 0) {
        var year = fyear ? fyear : companyLoginYears[0]
        var auth_token = getStorage(`auth_token_${year}`, "")

        if (!isblank(auth_token)) {
          let data = new FormData()
          data.append("test", "test")
          axios({
            method: "post",
            url: list_url({ type: "check_authtoken" }),
            headers: {
              Authorization: auth_token,
              version: process.env.REACT_APP_VERSION,
              "Content-Type": "application/x-www-form-urlencoded",
              devicetype: isDesktop ? 0 : 3,
              "X-Server-Select": "account_" + getSubDomain(),
            },
            data: data,
          })
            .then((response) => {
              if (response.data.meta.code === 209) {
                if (onCheckComplete) {
                  onCheckComplete(false)
                }
                dispatch({
                  type: LAST_PAGE_UPDATE,
                  lasttype: "new_version",
                  lastaction: "force_update",
                  lasttime: new Date().getTime(),
                })
              } else if (response.data.meta.code === 200) {
                response.data = decryption({ data: response.data.result, Json_Formatter: true })
                var site_setting = response.data.company_setting[0]
                setStorage(`site_setting_${year}`, site_setting)
                let storedLastTransDate = getStorage(`lastTransDate_${year}`, "")
                var lastTransDate = response.data.lastTransDate
                if (!isblank(storedLastTransDate)) {
                  Object.keys(storedLastTransDate).forEach((e) => {
                    if (isblank(lastTransDate[e])) {
                      lastTransDate[e] = storedLastTransDate[e]
                    }
                  })
                }
                setStorage(`lastTransDate_${year}`, lastTransDate)

                var firm_years = []
                if (getStorage(`firm_years`, "")) {
                  firm_years = getStorage(`firm_years`, "")
                }
                var user_data = ""
                if (getStorage(`user_data_${year}`, "")) {
                  user_data = getStorage(`user_data_${year}`, "")
                }
                var user_rights = ""
                if (getStorage(`user_rights_${year}`, "")) {
                  user_rights = getStorage(`user_rights_${year}`, "")
                }
                var report_data = ""
                if (getStorage(`report_data_${year}`, "")) {
                  report_data = getStorage(`report_data_${year}`, "")
                }
                var customPages = []
                if (getStorage(`customPages_${year}`, "")) {
                  customPages = getStorage(`customPages_${year}`, "")
                }
                var config_setting = ""
                if (getStorage(`config_setting_${year}`, "")) {
                  config_setting = getStorage(`config_setting_${year}`, "")
                }

                dispatch({
                  type: SET_ALL_LAST_DATE,
                  payload: lastTransDate,
                })

                dispatch({
                  type: LAST_PAGE_UPDATE,
                  lasttype: "config_setting",
                  lastaction: "updated",
                  lasttime: new Date().getTime(),
                  lastdata: { config_setting, announcement: response.data.announcement, user_data, frontend_version: response.data.frontend_version, force_update: response.data.force_update },
                })

                var menu_data = {}
                if (getStorage(`menu_data_${year}`, "")) {
                  menu_data = getStorage(`menu_data_${year}`, "")
                }

                if (responseValid({ menu: menu_data, setting: site_setting, company_url: user_data.company_url, user_name: user_data.name })) {
                  dispatch({
                    type: SET_ALL_LAST_DATE,
                    payload: lastTransDate,
                  })

                  dispatch({
                    type: RELOGIN_USER_SUCCESS,
                    payload: site_setting,
                    user_data: user_data,
                    user_rights: user_rights,
                    report_data: report_data,
                    firm_years: firm_years,
                    customPages: customPages,
                    config_setting: config_setting,
                    auth_token: auth_token,
                  })

                  if (config_setting.processModule !== "yes") {
                    delete menu_data.Process
                  }

                  dispatch({
                    type: SETMENU,
                    menu: menu_data,
                    year: year,
                  })

                  dispatch({
                    type: SET_CHECK_LOADING,
                    state: false,
                  })

                  dispatch({
                    type: LAST_PAGE_UPDATE,
                    lasttype: "login",
                    lastaction: "success",
                    lasttime: new Date().getTime(),
                  })

                  if (isloginPage || isblank(window.location.pathname) || window.location.pathname === "/") {
                    goToNavigate(`/${year}/welcome`)
                  }
                } else {
                  if (onCheckComplete) {
                    onCheckComplete(false)
                  }
                  tokenNotValid(dispatch)
                }
              } else {
                if (onCheckComplete) {
                  onCheckComplete(false)
                }
                tokenNotValid(dispatch)
              }
            })
            .catch((error) => {
              if (!(error && error.message === "Request aborted")) {
                postErrorOnException(error)
                if (onCheckComplete) {
                  onCheckComplete(false)
                }
                tokenNotValid(dispatch)
              }
            })
        } else {
          if (onCheckComplete) {
            onCheckComplete(false)
          }
          tokenNotValid(dispatch)
        }
      } else {
        if (onCheckComplete) {
          onCheckComplete(false)
        }
        tokenNotValid(dispatch)
      }
    } else {
      if (onCheckComplete) {
        onCheckComplete(false)
      }
      tokenNotValid(dispatch)
    }
  }
}

export const setLoginLoading = (state) => {
  return (dispatch) => {
    dispatch({
      type: SET_CHECK_LOADING,
      state: state,
    })
  }
}

const tokenNotValid = (dispatch) => {
  dispatch({
    type: SET_CHECK_LOADING,
    state: false,
  })

  dispatch(reset("headerSearchForm"))
  dispatch({
    type: LOGOUT_USER_SUCCESS,
    payload: null,
  })

  dispatch({
    type: RESET_COMMON_REDUCER,
  })

  userlogout(dispatch)
}

export const change_module = ({ company_url, company_name, user_name, financial_year, token, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("company_url", company_url)
    data.append("company_name", company_name)
    data.append("user_name", user_name)
    data.append("financial_year", financial_year)
    data.append("module_type", "accounting")

    axios.defaults.headers.common = token
    userlogout(dispatch)
    axios({
      method: "post",
      url: list_url({ type: "change_module" }),
      headers: {
        version: process.env.REACT_APP_VERSION,
        devicetype: isDesktop ? 0 : 3,
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Server-Select": "account_" + getSubDomain(),
      },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          loginUserFail(dispatch, response.data.meta.message)
        } else {
          response.data = decryption({ data: response.data.result, Json_Formatter: true })
          const onInvalid = () => {
            loginUserFail(dispatch, getCatchErrorMessage())
          }
          if (!isblank(response.data)) {
            if (response.data.company_detail.auth_key === "") {
              loginUserSuccess({ dispatch, user: response, year: financial_year, reload: true, onInvalid })
            } else {
              authUser({ dispatch, user: response, year: financial_year })
            }
          } else {
            onInvalid()
          }
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          loginUserFail(dispatch, getCatchErrorMessage(error))
        }
      })
  }
}

export const change_company = (user_data, functions) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("company_url", user_data.company_url)
    data.append("company_name", user_data.company_name)
    data.append("user_name", user_data.user_name)
    data.append("financial_year", user_data.financial_year)
    data.append("module_type", "accounting")
    axios({
      method: "post",
      url: list_url({ type: "change_company" }),
      headers: {
        Authorization: user_data.token,
        version: process.env.REACT_APP_VERSION,
        devicetype: isDesktop ? 0 : 3,
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Server-Select": "account_" + getSubDomain(),
      },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401 || response.data.meta.code === 405) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          setTimeout(() => {
            window.location.href = changeURL(user_data.old, `/${user_data.financial_year}`)
          }, 3000)
        } else {
          response.data = decryption({ data: response.data.result, Json_Formatter: true })
          const onInvalid = () => {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: getCatchErrorMessage(),
              snackbarType: "error",
            })
            setTimeout(() => {
              window.location.href = changeURL(user_data.old, `/${user_data.financial_year}`)
            }, 3000)
          }
          if (!isblank(response.data)) {
            loginUserSuccess({ dispatch, user: response, year: user_data.financial_year, reload: false, onInvalid })
          } else {
            onInvalid()
          }
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
        setTimeout(() => {
          window.location.href = changeURL(user_data.old, `/${user_data.financial_year}`)
        }, 3000)
      })
  }
}

export const user_logout = () => {
  return (dispatch) => {
    userlogout(dispatch)
  }
}

export const userlogout = (dispatch) => {
  var companyLoginYears = getStorage("companyLoginYears")
  if (isblank(companyLoginYears)) {
    companyLoginYears = []
  }
  companyLoginYears.forEach((year) => {
    removeStorage(`auth_token_${year}`)
    removeStorage(`user_data_${year}`)
    removeStorage(`site_setting_${year}`)
    removeStorage(`user_rights_${year}`)
    removeStorage(`userModuleRights_${year}`)
    removeStorage(`lastTransDate_${year}`)
    removeStorage(`menu_data_${year}`)
    removeStorage(`report_data_${year}`)
    removeStorage(`company_${year}`)
    removeStorage(`config_setting_${year}`)
    removeStorage(`customPages_${year}`)
  })

  dispatch({
    type: LAST_PAGE_UPDATE,
    lasttype: "config_setting",
    lastaction: "updated",
    lasttime: new Date().getTime(),
    lastdata: { config_setting: "", announcement: "", user_data: "", frontend_version: "", force_update: "" },
  })

  removeStorage("firm_years")
  removeStorage("last_visited_page")
  removeStorage("last_visited_page_data")
  removeStorage("last_menu_key")
  dispatch(reset("headerSearchForm"))
  dispatch({
    type: LOGOUT_USER_SUCCESS,
    payload: null,
  })

  dispatch({
    type: RESET_COMMON_REDUCER,
  })

  dispatch({
    type: FORM_POST_SERVICE_CLEAR,
  })

  dispatch({
    type: PAGE_FORM_POST_SERVICE_CLEAR,
  })
  dispatch({
    type: FORM_DEP_SERVICE_CLEAR,
  })
  goToNavigate("/login")
}

// export const filteredchanged = (data) => {
//   return {
//     type: FILTERED_CHANGED,
//     payload: data,
//   }
// }

export const fetch_account_packages = ({ dropdown_string, loading, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({
      type: FORM_DEP_SERVICE_START,
      inward_type_detail: [],
      outward_type_detail: [],
    })
    let data = new FormData()
    data.append("dependency", true)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "getallaccountpackage" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: FORM_DEP_SERVICE_FAIL,
            payload: response.data.meta.message,
          })
        } else {
          response.data.dd_accountPackage = response.data.result
          dispatch({
            type: FORM_DEP_SERVICE_SUCCESS,
            payload: response,
            dropdown_string: "dd_accountPackage",
          })

          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "dependency",
            lastaction: "success",
            lasttime: new Date().getTime(),
          })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: FORM_DEP_SERVICE_FAIL,
            payload: getCatchErrorMessage(error),
          })
        }
      })
  }
}

export const authsnackbarstate = (open, message) => {
  return {
    type: AUTH_SNACKBAR_STATE,
    payload: open,
    message: message,
  }
}

export const authenticateUser = ({ form_data, type, action, other_type, auth_token, year, user, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    const formdata2 = Array.isArray(form_data) ? form_data.map(JSON.stringify) : JSON.stringify(form_data)

    let data = new FormData()
    data.append("form_data", formdata2)
    data.append("type", type)
    data.append("action", action)
    data.append("other_type", other_type)
    axios.defaults.headers.common["Authorization"] = auth_token
    axios({
      method: "post",
      url: list_url({ type: "authenticateUser" }),
      headers: {
        version: process.env.REACT_APP_VERSION,
        devicetype: isDesktop ? 0 : 3,
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Server-Select": "account_" + getSubDomain(),
      },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        const onInvalid = () => {
          loginUserFail(dispatch, response.data.meta.message)
        }
        if (response.data.meta.code === 401) {
          onInvalid()
        } else {
          loginUserSuccess({ dispatch, user, year, reload: false, onInvalid })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          loginUserFail(dispatch, getCatchErrorMessage(error))
        }
      })
  }
}

export const forgotPassword = ({ username, company_url, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: AUTH_LOADING_STATE, state: true })

    let data = new FormData()
    data.append("username", username)
    data.append("company_url", company_url)
    axios({
      method: "post",
      url: list_url({ type: "forgot_password" }),
      headers: {
        version: process.env.REACT_APP_VERSION,
        devicetype: isDesktop ? 0 : 3,
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Server-Select": "account_" + getSubDomain(),
      },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "forgot_password",
            lastaction: "error",
            lasttime: new Date().getTime(),
          })

          dispatch({
            type: AUTH_SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })

          dispatch({ type: AUTH_LOADING_STATE, state: false })
        } else {
          dispatch({ type: AUTH_LOADING_STATE, state: false })
          dispatch({
            type: AUTH_SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })

          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "forgot_password",
            lastaction: "success",
            lasttime: new Date().getTime(),
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "forgot_password",
          lastaction: "error",
          lasttime: new Date().getTime(),
        })

        if (axios.isCancel(error)) {
          dispatch({ type: AUTH_LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: AUTH_LOADING_STATE, state: false })
        }
      })
  }
}

export const disableAuthenticateUser = ({ form_data, type, action, other_type, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    const formdata2 = Array.isArray(form_data) ? form_data.map(JSON.stringify) : JSON.stringify(form_data)

    let data = new FormData()
    data.append("form_data", formdata2)
    data.append("type", type)
    data.append("action", action)
    data.append("other_type", other_type)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "disable_auth" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
          var user_data = getStorage(`user_data_${getWindowYear()}`)
          user_data.auth_key = ""
          setStorage(`user_data_${getWindowYear()}`, user_data)

          dispatch({
            type: UPDATE_USER_DATA,
            user_data: user_data,
          })

          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "two_factor",
            lastaction: "disabled",
            lasttime: new Date().getTime(),
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        }
      })
  }
}

export const generateAuthenticationKey = ({ form_data, type, action, other_type, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    const formdata2 = Array.isArray(form_data) ? form_data.map(JSON.stringify) : JSON.stringify(form_data)

    let data = new FormData()
    data.append("form_data", formdata2)
    data.append("type", type)
    data.append("action", action)
    data.append("other_type", other_type)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "generate_authKey" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        dispatch({ type: LOADING_STATE, state: false })
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          dispatch({
            type: AUTHENTICATE_KEY_GENERATED,
            payload: response.data.form_data,
          })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        }
      })
  }
}

export const loginUser = ({ email, password, companycode, year, social_acc_id, social_connect_flag, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: AUTH_LOADING_STATE, state: true })
    dispatch({ type: LOGIN_USER })

    let data = new FormData()
    data.append("username", email)
    data.append("password", password)
    data.append("financial_year", year)
    data.append("company_url", companycode)
    if (social_acc_id) {
      data.append("social_acc_id", social_acc_id)
    }
    if (social_connect_flag) {
      data.append("social_connect_flag", social_connect_flag)
    }
    data.append("module_type", "accounting")
    axios({
      method: "post",
      url: list_url({ type: "login" }),
      headers: {
        version: process.env.REACT_APP_VERSION,
        devicetype: isDesktop ? 0 : 3,
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Server-Select": "account_" + getSubDomain(),
      },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          loginUserFail(dispatch, response.data.meta.message)
        } else {
          response.data = decryption({ data: response.data.result, Json_Formatter: true })
          const onInvalid = () => {
            loginUserFail(dispatch, getCatchErrorMessage())
          }
          if (!isblank(response.data)) {
            if (response.data.company_detail.auth_key === "") {
              loginUserSuccess({ dispatch, user: response, year, reload: false, onInvalid })
            } else {
              authUser({ dispatch, user: response, year })
            }
          } else {
            onInvalid()
          }
          dispatch({ type: AUTH_LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: AUTH_LOADING_STATE, state: false })
        } else if (error.hasOwnProperty("code") && error.code === 402) {
          postErrorOnException(error)
          loginUserFail(dispatch, error.message)
        } else {
          postErrorOnException(error)
          loginUserFail(dispatch, getCatchErrorMessage(error))
        }
      })
  }
}

export const sync = (from, functions) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", "syncLoginResponse")
    data.append("module_type", "accounting")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "syncLoginResponse" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        dispatch({ type: LOADING_STATE, state: false })
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: "App synced successfully",
            snackbarType: "success",
          })
          response.data = decryption({ data: response.data.result, Json_Formatter: true })
          const onInvalid = () => {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: getCatchErrorMessage(),
              snackbarType: "error",
            })
          }
          if (!isblank(response.data)) {
            const year = getWindowYear()
            loginUserSuccess({ dispatch, user: response, year, reload: false, onInvalid, from })
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "sync",
              lastaction: "completed",
              lasttime: new Date().getTime(),
            })
          } else {
            onInvalid()
          }
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_STATE, state: false })
        if (axios.isCancel(error)) {
        } else if (error.hasOwnProperty("code") && error.code === 402) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: error.message,
            snackbarType: "error",
          })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        }
      })
  }
}

const getdefaultValue = (defaultVal) => {
  if (!isblank(defaultVal)) {
    return defaultVal
  }
  return ""
}

export const fetch_site_setting_data = (functions) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("offset", 0)
    data.append("type", "site_setting")
    data.append("limit", "All")
    data.append("sort_order", "asc")
    data.append("sort_by", "srno")
    data.append("search_text", "")
    data.append("other_type", "")
    data.append("main_type", "")

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "site_setting_data" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          var site_setting = response.data.result.company_setting[0]
          response.data.result.global_setting.forEach((item) => {
            var detail = []
            if (!isblank(item.detail)) {
              if (
                item.type === "purchase_type_detail" ||
                item.type === "invoice_type_detail" ||
                item.type === "inward_type_detail" ||
                item.type === "outward_type_detail" ||
                item.type === "salesreturn_type_detail" ||
                item.type === "purchasereturn_type_detail"
              ) {
                detail = JSON.parse(item.detail)
                if (!isblank(detail) && detail.length > 0) {
                  detail = detail[0]
                }
              } else {
                detail = JSON.parse(item.detail)
              }
              if (!isblank(detail) && detail.length > 0) {
                detail.forEach((i) => {
                  if (
                    item.type === "purchase_type_detail" ||
                    item.type === "invoice_type_detail" ||
                    item.type === "inward_type_detail" ||
                    item.type === "outward_type_detail" ||
                    item.type === "salesreturn_type_detail" ||
                    item.type === "purchasereturn_type_detail"
                  ) {
                    if (!isblank(site_setting[item.type])) {
                      site_setting[item.type] = site_setting[item.type].map((e) => {
                        const updatedItem = { ...e }
                        if (updatedItem.id === item.subType) {
                          if (isblank(updatedItem[i.name])) {
                            updatedItem[i.name] = getdefaultValue(i.defaultValue)
                          }
                        }
                        return updatedItem
                      })
                    }
                  } else if (!isblank(item.subType)) {
                    if (!isblank(site_setting[item.type])) {
                      if (!isblank(site_setting[item.type][item.subType])) {
                        if (isblank(site_setting[item.type][item.subType][i.name])) {
                          site_setting[item.type][item.subType][i.name] = getdefaultValue(i.defaultValue)
                        }
                      } else {
                        site_setting[item.type][item.subType] = {
                          [i.name]: getdefaultValue(i.defaultValue),
                        }
                      }
                    } else {
                      site_setting[item.type] = {
                        [item.subType]: {
                          [i.name]: getdefaultValue(i.defaultValue),
                        },
                      }
                    }
                  } else if (!isblank(site_setting["taxSetting"]) && item.type === "taxSetting" && i.name === "tcs_settings") {
                    if (isblank(site_setting["taxSetting"]["tcs_settings"])) {
                      if (!isblank(getdefaultValue(i.defaultValue))) {
                        site_setting["taxSetting"]["tcs_settings"] = JSON.parse(getdefaultValue(i.defaultValue))
                      }
                    }
                  } else if (!isblank(site_setting["taxSetting"]) && item.type === "taxSetting" && i.name === "tds_settings") {
                    if (isblank(site_setting["taxSetting"]["tds_settings"])) {
                      if (!isblank(getdefaultValue(i.defaultValue))) {
                        site_setting["taxSetting"]["tds_settings"] = JSON.parse(getdefaultValue(i.defaultValue))
                      }
                    }
                  } else {
                    if (!isblank(site_setting[item.type])) {
                      if (isblank(site_setting[item.type][i.name])) {
                        site_setting[item.type][i.name] = getdefaultValue(i.defaultValue)
                      }
                    } else {
                      site_setting[item.type] = {
                        [i.name]: getdefaultValue(i.defaultValue),
                      }
                    }
                  }
                })
              }
            }
          })
          dispatch({
            type: NEW_SITE_SETTING_UPDATED,
            payload: site_setting,
          })
          var siteSettingFields = []
          response.data.result.global_setting = response.data.result.global_setting.filter((e) => {
            if (
              e.type === "purchase_type_detail" ||
              e.type === "invoice_type_detail" ||
              e.type === "inward_type_detail" ||
              e.type === "outward_type_detail" ||
              e.type === "salesreturn_type_detail" ||
              e.type === "purchasereturn_type_detail"
            ) {
              return getMenuRightsByType({ parent: "Invoice", name: e.subTypeName, type: "viewRights" })
            }
            return true
          })
          response.data.result.global_setting.forEach((item, index) => {
            var newItem = {
              type: item.type,
              subType: item.subType,
              typeName: item.typeName,
              subTabDetail: [{ ...item }],
            }
            if (index === 0) {
              siteSettingFields.push(newItem)
            } else if (siteSettingFields.filter((it) => it.type === item.type).length > 0) {
              siteSettingFields = siteSettingFields.map((e) => {
                const updatedItem = { ...e }
                if (e.type === item.type) {
                  updatedItem.subTabDetail = [...updatedItem.subTabDetail, { ...item }]
                }
                return updatedItem
              })
            } else if (!(siteSettingFields.filter((it) => it.type === item.type).length > 0)) {
              siteSettingFields.push(newItem)
            }
          })

          siteSettingFields = siteSettingFields.map((item) => {
            const updatedItem = { ...item }
            if (
              updatedItem.type === "purchase_type_detail" ||
              updatedItem.type === "invoice_type_detail" ||
              updatedItem.type === "inward_type_detail" ||
              updatedItem.type === "outward_type_detail" ||
              updatedItem.type === "salesreturn_type_detail" ||
              updatedItem.type === "purchasereturn_type_detail"
            ) {
              updatedItem.subTabDetail = updatedItem.subTabDetail.map((it) => {
                const updated = { ...it }
                if (!isblank(updated.detail)) {
                  updated.detail = JSON.parse(updated.detail)
                  if (!isblank(updated.detail) && updated.detail.length > 0) {
                    updated.detail = updated.detail[0]
                    updated.detail = updated.detail.map((e) => {
                      const updatedDetail = { ...e }
                      if (isblank(updatedDetail.ConditionGrid)) {
                        updatedDetail.ConditionGrid = []
                      }
                      var conditions = []
                      updatedDetail.ConditionGrid.forEach((i) => {
                        if (conditions.filter((j) => j.name === i.name).length > 0) {
                          conditions = conditions.map((k) => {
                            const updat = { ...k }
                            if (updat.value.filter((l) => l === k.value).length === 0) {
                              updat.value.push(k.value)
                            }
                            return updat
                          })
                        } else {
                          conditions.push({ name: i.name, value: [i.value] })
                        }
                      })
                      updatedDetail.conditions = conditions
                      delete updatedDetail.ConditionGrid
                      return updatedDetail
                    })
                  } else {
                    updated.detail = []
                  }
                } else {
                  updated.detail = []
                }
                return updated
              })
              if (updatedItem.subTabDetail.length === 1) {
                updatedItem.typeName = updatedItem.subTabDetail[0].subTypeName
              }
            } else {
              updatedItem.subTabDetail = updatedItem.subTabDetail.map((it) => {
                const updated = { ...it }
                if (!isblank(updated.detail)) {
                  updated.detail = JSON.parse(updated.detail)
                  updated.detail = updated.detail.map((e) => {
                    const updatedDetail = { ...e }
                    if (isblank(updatedDetail.ConditionGrid)) {
                      updatedDetail.ConditionGrid = []
                    }
                    var conditions = []
                    updatedDetail.ConditionGrid.forEach((i) => {
                      if (conditions.filter((j) => j.name === i.name).length > 0) {
                        conditions = conditions.map((k) => {
                          const updat = { ...k }
                          if (updat.value.filter((l) => l === k.value).length === 0) {
                            updat.value.push(k.value)
                          }
                          return updat
                        })
                      } else {
                        conditions.push({ name: i.name, value: [i.value] })
                      }
                    })
                    updatedDetail.conditions = conditions
                    delete updatedDetail.ConditionGrid
                    return updatedDetail
                  })
                } else {
                  updated.detail = []
                }
                return updated
              })
            }
            return updatedItem
          })
          dispatch({
            type: SITE_SETTING_FIELD_SUCCESS,
            siteSettingFields: siteSettingFields,
          })

          dispatch({
            type: SET_SETTING,
            siteSettingDefaultValue: true,
          })

          setStorage(`site_setting_${getWindowYear()}`, site_setting)
          dispatch({ type: LOADING_STATE, state: false })

          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "siteSetting",
            lastaction: "success",
            lasttime: new Date().getTime(),
          })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const loginUserFail = (dispatch, response) => {
  dispatch({
    type: LOGIN_USER_FAIL,
    payload: response,
  })
  dispatch({ type: LOADING_STATE, state: false })
  dispatch({ type: AUTH_LOADING_STATE, state: false })
}
const loginUserSuccess = ({ dispatch, user, year, reload, onInvalid, from }) => {
  if (responseValid({ menu: user.data.menu, setting: user.data.site_setting[0], company_url: user.data.company_detail.company_url, user_name: user.data.company_detail.name })) {
    var companyLoginYears = getStorage("companyLoginYears")
    var menudata = {}
    if (isblank(companyLoginYears)) {
      companyLoginYears = []
    }
    if (companyLoginYears.filter((e) => e === year).length === 0) {
      companyLoginYears.push(year)
    }
    setStorage(`auth_token_${year}`, user.data.auth_token)
    setStorage(`companyLoginYears`, companyLoginYears)
    user.data.company_detail.accountModule = "yes"
    setStorage(`user_data_${year}`, user.data.company_detail)
    setStorage(`userModuleRights_${year}`, user.data.userModuleRights)
    setStorage(`firm_years`, user.data.firm_years)
    var site_setting = user.data.site_setting[0]
    setStorage(`site_setting_${year}`, site_setting)
    if (!isblank(user.data.menu)) {
      menudata = user.data.menu
      var dd_orderTypes = []
      Object.keys(menudata).forEach(function (key) {
        if (key === "Invoice") {
          dd_orderTypes = menudata[key].filter((e) => e.type === "getmystore" || e.type === "getmycatalogue" || e.type === "sales_order")
          if (dd_orderTypes.length > 0) {
            menudata[key].push({ id: "9000", name: "Orders", hidden_menu: "0", main_type: "salesinvoices", icon: "", quick_links: "0", dd_orderTypes: dd_orderTypes, type: "orders" })
            user.data.user_rights["9000"] = "1|1|1"
          }
        }
      })
      user.data.menu = menudata
    }
    setStorage(`user_rights_${year}`, user.data.user_rights)

    let storedLastTransDate = getStorage(`lastTransDate_${year}`, "")
    var lastTransDate = user.data.lastTransDate
    if (!isblank(storedLastTransDate)) {
      Object.keys(storedLastTransDate).forEach((e) => {
        if (isblank(lastTransDate[e])) {
          lastTransDate[e] = storedLastTransDate[e]
        }
      })
    }
    setStorage(`lastTransDate_${year}`, lastTransDate)

    setStorage(`menu_data_${year}`, user.data.menu)
    setStorage(`report_data_${year}`, user.data.reports)
    setStorage(`company_${year}`, user.data.company)
    setStorage(`config_setting_${year}`, user.data.config_setting)
    setStorage(`customPages_${year}`, user.data.customPages)

    dispatch({
      type: LAST_PAGE_UPDATE,
      lasttype: "config_setting",
      lastaction: "updated",
      lasttime: new Date().getTime(),
      lastdata: {
        config_setting: user.data.config_setting,
        announcement: user.data.announcement,
        user_data: user.data.company_detail,
        frontend_version: user.data.frontend_version,
        force_update: user.data.force_update,
      },
    })

    dispatch({
      type: FORM_DEP_SERVICE_CLEAR,
    })

    dispatch({
      type: SET_ALL_LAST_DATE,
      payload: user.data.lastTransDate,
    })

    dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: user,
    })

    dispatch({
      type: USER_RIGHTS_UPDATED,
      payload: user.data.user_rights,
    })

    menudata = cloneObj(user.data.menu)
    if (user.data.config_setting.processModule !== "yes") {
      delete menudata.Process
    }
    dispatch({
      type: SETMENU,
      menu: menudata,
      year: year,
    })

    dispatch({
      type: LAST_PAGE_UPDATE,
      lasttype: "login",
      lastaction: "success",
      lasttime: new Date().getTime(),
    })

    var redirectTo = "welcome"
    if (user.data.company_detail.onboarding === "yes") {
      redirectTo = "onboarding"
    } else if (user.data.config_setting.reset_pass_flag === "yes" && parseInt(getAccountRightsByID(menuId.ChangePassword), 10) === 1) {
      redirectTo = "changepassword"
    }
    if (reload) {
      window.location.href = changeURL(user.data.company_detail.company_url, `/${year}/${redirectTo}`)
    } else {
      window.location.href = changeURL(user.data.company_detail.company_url, `/${year}/${redirectTo}`)
    }
  } else {
    onInvalid()
  }
}

const authUser = ({ dispatch, user, year }) => {
  dispatch({
    type: AUTH_USER_SUCCESS,
    payload: { user, year },
  })
  goToNavigate("/authentication")
}

export const update_user_data = (user_data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_DATA,
      user_data: user_data,
    })
  }
}

export const socialLogin = ({ year, company_url, social_acc_id, social_connect_flag, from, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    if (from === "setting") {
      dispatch({ type: LOADING_STATE, state: true })
    } else {
      dispatch({ type: AUTH_LOADING_STATE, state: true })
    }

    let data = new FormData()
    data.append("social_acc_id", social_acc_id)
    data.append("social_connect_flag", social_connect_flag)
    data.append("financial_year", year)
    data.append("company_url", company_url)
    data.append("module_type", "accounting")
    axios({
      method: "post",
      url: list_url({ type: "social_login" }),
      headers: {
        version: process.env.REACT_APP_VERSION,
        devicetype: isDesktop ? 0 : 3,
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Server-Select": "account_" + getSubDomain(),
      },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (from === "setting") {
          if (response.data.meta.code === 401) {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: response.data.meta.message,
              snackbarType: "error",
            })
          } else {
            var firmdetails = getStorage(`config_setting_${year}`)
            if (isblank(firmdetails.social_connect)) {
              firmdetails.social_connect = []
            }
            if (firmdetails.social_connect.filter((e) => e.social_connect_flag === social_connect_flag).length === 0) {
              firmdetails.social_connect.push({
                social_connect_flag: social_connect_flag,
                social_key: `${social_connect_flag}_id`,
                status: "Active",
              })
            } else {
              firmdetails.social_connect = firmdetails.social_connect.map((e) => {
                const updated = { ...e }
                if (updated.social_connect_flag === social_connect_flag) {
                  updated.status = "Active"
                }
                return updated
              })
            }
            setStorage(`config_setting_${year}`, firmdetails)
            dispatch({
              type: UPDATE_CONFIG_SETTING,
              payload: firmdetails,
            })
          }
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          if (response.data.meta.code === 401) {
            loginUserFail(dispatch, response.data.meta.message)
          } else if (response.data.meta.code === 301) {
            //social connected
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "social_connect",
              lastaction: 301,
              lasttime: new Date().getTime(),
              lastdata: { social_acc_id, social_connect_flag },
            })
            dispatch({ type: AUTH_LOADING_STATE, state: false })
          } else {
            response.data = decryption({ data: response.data.result, Json_Formatter: true })
            const onInvalid = () => {
              loginUserFail(dispatch, getCatchErrorMessage())
            }
            if (!isblank(response.data)) {
              if (response.data.company_detail.auth_key === "") {
                loginUserSuccess({ dispatch, user: response, year, reload: false, onInvalid })
              } else {
                authUser({ dispatch, user: response, year })
              }
            } else {
              onInvalid()
            }
            dispatch({ type: AUTH_LOADING_STATE, state: false })
          }
        }
      })
      .catch((error) => {
        if (from === "setting") {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          if (axios.isCancel(error)) {
            dispatch({ type: AUTH_LOADING_STATE, state: false })
          } else if (error.hasOwnProperty("code") && error.code === 402) {
            postErrorOnException(error)
            loginUserFail(dispatch, error.message)
          } else {
            postErrorOnException(error)
            loginUserFail(dispatch, getCatchErrorMessage(error))
          }
        }
      })
  }
}
