import { fbprintpdf } from "@project/components/printJSFunctions"
import { getAxiosCancelToken, getCatchErrorMessage, isblank, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getAccountSitesetting, getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import { list_url } from "@project/sharedcomponents/apiURL"
import { EnvelopePrint } from "../Lib/printFunctions"
import {
  ENVELOPE_PRINTING_SEARCH_TEXT_UPDATE,
  ENVELOPE_PRINTING_SERVICE_CLEAR,
  ENVELOPE_PRINTING_SERVICE_FAIL,
  ENVELOPE_PRINTING_SERVICE_START,
  ENVELOPE_PRINTING_SERVICE_SUCCESS,
  FORM_DEP_SERVICE_SUCCESS,
  LOADING_STATE,
  SNACKBAR_STATE,
} from "./types"

export const envelopePrinting_data_clear = () => {
  return (dispatch) => {
    dispatch({
      type: ENVELOPE_PRINTING_SERVICE_CLEAR,
    })
  }
}
export const envelopePrintingsearch_change = ({ search_text }) => {
  return (dispatch) => {
    dispatch({
      type: ENVELOPE_PRINTING_SEARCH_TEXT_UPDATE,
      search_text: search_text,
    })
  }
}
export const fetch_envelopePrinting_data = ({ rowsPerPage, page, order, orderBy, search_text, functions }) => {
  if (isblank(rowsPerPage)) {
    rowsPerPage = getAccountSitesetting("pagination_size")
  }
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: ENVELOPE_PRINTING_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    if (rowsPerPage === "All") {
      data.append("offset", 0)
    } else {
      data.append("offset", page * rowsPerPage)
    }
    data.append("type", "envelopePrinting")
    data.append("limit", rowsPerPage)
    data.append("sort_order", order)
    data.append("sort_by", orderBy)
    data.append("search_text", search_text)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "envelopePrinting" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          envelopePrintingServiceFail({ dispatch, response: response.data.meta.message, rowsPerPage, page, order, orderBy, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          if (rowsPerPage === "All") {
            dispatch({ type: FORM_DEP_SERVICE_SUCCESS, payload: { data: { dd_envelope_template: response.data.result } } })
          } else {
            envelopePrintingServiceSuccess({ dispatch, user: response, rowsPerPage, page, order, orderBy, search_text })
          }
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          envelopePrintingServiceFail({ dispatch, response: getCatchErrorMessage(error), rowsPerPage, page, order, orderBy, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const envelopePrintingServiceSuccess = ({ dispatch, user, rowsPerPage, page, order, orderBy, search_text }) => {
  dispatch({
    type: ENVELOPE_PRINTING_SERVICE_SUCCESS,
    payload: user,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
  })
}

const envelopePrintingServiceFail = ({ dispatch, response, rowsPerPage, page, order, orderBy, search_text }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })

  dispatch({
    type: ENVELOPE_PRINTING_SERVICE_FAIL,
    payload: response,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
  })
}

export const Envelope_Print = ({ partyDetail, envelopeDetail, dd_country_state }) => {
  return (dispatch) => {
    var dd = EnvelopePrint({ partyDetail, envelopeDetail, dd_country_state })
    const metadata = {
      filename: `Envelope_of_${partyDetail.name}`,
    }
    fbprintpdf({ docDefinition: dd, metadata, action: "print" })
  }
}
