import { useTheme } from "@material-ui/core/styles"
import { capitalizeFirstLetter, isblank } from "@project/sharedcomponents/utilityFunctions"
import React from "react"
import { isMobileOnly } from "react-device-detect"
import { useSelector } from "react-redux"
import Select, { components, createFilter } from "react-select"
import {
  ClearIndicator,
  DropdownIndicator,
  IndicatorSeparator,
  LoadingIndicator,
  MenuList,
  MultiValue,
  Option,
  RenderButtonComponent,
  RenderHelpComponent,
  colourStyles,
  filterConfig,
  formThemeColors,
  getMultiValue,
  getdropDownoptions,
  handleBlur,
  multiChangeHandler,
  renderLabel,
  singleChangeHandler,
} from "./reactSelectComponent"

const RFReactSelect = ({
  input,
  options,
  noLableMargin,
  labelStyle,
  isClearable,
  isSearchable,
  helpStyle,
  HelpComponent,
  ButtonComponent,
  toolTipStyle,
  multi,
  mode,
  label,
  helpText,
  getLocalSiteSetting,
  getconfigsettingdata,
  disabled,
  setUnderLine,
  className,
  onvaluechange,
  onImportClick,
  onbtnClick,
  btnText,
  secondbtnText,
  onSecondbtnClick,
  dataindex,
  isRequired,
  removeAddIcon,
  onAddNewClick,
  meta,
  placeholder,
  domref = null,
  ...props
}) => {
  const theme = useTheme()
  const accountSiteSettingKeyValue = useSelector((state) => state.AuthReducer.accountSiteSettingKeyValue)
  const { name, onBlur, onChange } = input
  const { error, touched } = meta
  const formattedOptions = {}
  const dropDownoptions = getdropDownoptions(options)
  dropDownoptions.forEach((obj) => {
    formattedOptions[obj.value] = obj
  })
  const __cust_hideContactInfo = getconfigsettingdata ? parseInt(getconfigsettingdata("__cust_hideContactInfo")) || 0 : 0

  return (
    <React.Fragment>
      {renderLabel({
        name,
        labelStyle,
        label,
        isRequired,
        theme,
        noLableMargin,
        touched,
        error,
        helpText,
        helpStyle,
        btnText,
        toolTipStyle,
        onSecondbtnClick,
        secondbtnText,
        onbtnClick,
        input,
      })}
      <div style={{ position: "relative" }}>
        <RenderHelpComponent HelpComponent={HelpComponent} />
        <RenderButtonComponent ButtonComponent={ButtonComponent} />
        <Select
          {...props}
          {...(placeholder && {
            placeholder: capitalizeFirstLetter(placeholder),
          })}
          ref={domref}
          menuPortalTarget={document.body}
          menuShouldBlockScroll={true}
          menuShouldScrollIntoView={true}
          type="text"
          styles={colourStyles(setUnderLine, mode, Boolean(touched && error), theme, HelpComponent)}
          filterOption={createFilter(filterConfig(accountSiteSettingKeyValue, mode))}
          inputId={name}
          isMulti={multi}
          getOptionLabel={(option) => `${option.label}`}
          menuPlacement="auto"
          isClearable={isClearable !== undefined ? isClearable : true}
          isSearchable={isSearchable !== undefined ? isSearchable : true}
          {...(multi
            ? {
              value: !isblank(input.value) ? getMultiValue(input.value, dropDownoptions) : [],
              defaultValue: !isblank(input.value) ? getMultiValue(input.value, dropDownoptions) : [],
            }
            : {
              value: !isblank(input.value) ? formattedOptions[input.value] : null,
              defaultValue: !isblank(input.value) ? input.value : null,
            })}
          isDisabled={disabled}
          {...(isMobileOnly && {
            blurInputOnSelect: false,
          })}
          onBlur={() => (multi ? handleBlur([...input.value], onBlur) : handleBlur(input.value, onBlur))}
          onChange={multi ? multiChangeHandler(onChange, onvaluechange, dataindex) : singleChangeHandler(onChange, onvaluechange, dataindex)}
          //components={{ MenuList: MenuList, MultiValue }}
          components={{
            Option: (OptionProps) => Option({ ...OptionProps, accountSiteSettingKeyValue, __cust_hideContactInfo }),
            MenuList: (MenuProps) =>
              MenuList({
                ...MenuProps,
                accountSiteSettingKeyValue,
                options: dropDownoptions,
                onImportClick,
                components,
                removeAddIcon,
                label,
              }),
            MultiValue,
            ClearIndicator: (cIProps) => ClearIndicator({ ...cIProps, isClearable, input }),
            DropdownIndicator: (DdProps) =>
              DropdownIndicator({
                ...DdProps,
                accountSiteSettingKeyValue,
                options: dropDownoptions,
                props,
                input,
                isClearable,
              }),
            IndicatorSeparator: (IdProps) =>
              IndicatorSeparator({
                ...IdProps,
                input,
                props,
                theme,
                isClearable,
              }),
            LoadingIndicator,
          }}
          options={dropDownoptions}
          mode={mode}
          label={label}
          closeMenuOnSelect={true}
          error={Boolean(touched && error)}
          theme={(theme1) => ({
            ...theme1,
            borderRadius: 0,
            colors: {
              ...formThemeColors(theme, touched, error),
            },
          })}
          name={name}
        />
      </div>
    </React.Fragment>
  )
}

export default RFReactSelect
