import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import DownloadMobileApp from "@project/components/common/downloadmobileapp"
import { showError } from "@project/components/showErrorFunctions"
import { isblank, replaceAllFn } from "@project/sharedcomponents/utilityFunctions"
import React, { Suspense, lazy, useState, useEffect } from "react"
import "react-perfect-scrollbar/dist/css/styles.css"
import { useDispatch, useSelector } from "react-redux"
import { change } from "redux-form"
import { dialogReasonUpdate, showdialog, snackbarstate } from "../../Actions"
import { getAccountSitesetting } from "../../Lib/commonfunctions"
import config from "../../Lib/config"
import errorList from "../common/ErrorList"

import { makeStyles } from "@material-ui/core/styles"
import StandardTextField from "@project/components/formComponents/standardTextField"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import CloseSalesOrder from "../closeSalesOrder/closeSalesOrder"
import DownloadEWayBill from "../manageEwayBill/DownloadEWayBill"
import Add_additional_address from "../ModalPages/addAdditionalAddress"
import ShareComponent from "../ShareComponent/ShareComponent"

// const Add_additional_address = lazy(() => import("../ModalPages/addAdditionalAddress"))
const CancelEInvoice = lazy(() => import("../ModalPages/cancelEInvoice"))
const cancelEwayBill = lazy(() => import("../ModalPages/cancelEwayBill"))
const importDetailDialog = lazy(() => import("../export/viewDetailDialog"))
const updateUser = lazy(() => import("../getMyStoreUser/updateUser"))
const generateE_invoice = lazy(() => import("../manageSalesInvoice/generateE_invoice"))
const modalSendSMS = lazy(() => import("../ModalPages/modalSendSMS"))
const BarcodePrintDialog = lazy(() => import("../../BarCodePrinting/Components/ModalPages/BarcodePrintDialog"))
const BarcodeScanDialog = lazy(() => import("../../BarCodePrinting/Components/ModalPages/BarcodeScanDialog"))

const DisableTwoFactorAuth = lazy(() => import("../Dialog/disableTwoFactorAuth"))
const TwoFactorAuth = lazy(() => import("../Dialog/twoFactorAuth"))
const FileGSTR1 = lazy(() => import("../GSTPages/fileGSTR1"))
const FileGSTR3B = lazy(() => import("../GSTPages/fileGSTR3B"))
const UpdateGSTR2BJSON = lazy(() => import("../GSTPages/updateGSTR2BJSON"))
const ModalImportProcessExcel = lazy(() => import("../ModalPages/modalImportProcessExcel"))
const updateEwayBillManual = lazy(() => import("../ModalPages/updateEwayBillManual"))
const UserAssign = lazy(() => import("../ModalPages/userAssign"))
const GovernmentServerStaus = lazy(() => import("../ModalPages/governmentServerStaus"))
const ClearAllData = lazy(() => import("../clearAllData/clearAllData"))
const addSignatureBoxes = lazy(() => import("../siteSettings/addSignatureBoxes"))

const addUpdateTermCond = lazy(() => import("../siteSettings/addUpdateTermCond"))
const modalImportUnitForm = lazy(() => import("../ModalPages/modalImportUnitForm"))
const updateEinvoiceManual = lazy(() => import("../ModalPages/updateEinvoiceManual"))
const adjustBillDetail = lazy(() => import("../ModalPages/adjustBillDetail"))
const NotifyDialog = lazy(() => import("../ModalPages/NotifyDialog"))
const TDSPercentage = lazy(() => import("../ModalPages/TDSPercentage"))

const EnvelopeTemplate = lazy(() => import("../envelopePrinting/selectTemplate"))
const ChequeTemplate = lazy(() => import("../chequePrinting/selectTemplate"))
const DraftDialog = lazy(() => import("../Draft/draftDialog"))
const OtpDialog = lazy(() => import("../Dialog/OtpDialog"))
const DownloadEInvoice = lazy(() => import("../manageSalesInvoice/DownloadEInvoice"))
const AddBank = lazy(() => import("../manageCompany/addUpdateBank"))
const WriteOffDues = lazy(() => import("../ModalPages/WriteOffDues"))

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

var timeout = 0
export default function DialogComponent({ formValues,  navigate }) {
  const dispatch = useDispatch()
  const [reasonText, setReasonText] = useState("")
  const classes = useStyles()
  const reason = parseInt(getAccountSitesetting("reason"))
  const { ConfirmDialog, ConfirmDialogForm, ConfirmDialogTitle, ConfirmDialogLoading, ConfirmDialogMessage, ConfirmDialogData, onConfirm, loading } = useSelector((state) => state.CommonReducer)

  const handleConfirmClose = () => {
    dispatch(change("headerSearchForm", "deleteReason", ""))
    dispatch(showdialog({ yesno: false, confirm: false, message: ConfirmDialogMessage, title: ConfirmDialogTitle, form: ConfirmDialogForm, meta: ConfirmDialogData, loading: false, confirmNo: true }))
    setReasonText("")
  }
  const handleConfirmYes = () => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      const errors = {}
      if (reason === 1 && (isblank(ConfirmDialogData) || isblank(ConfirmDialogData.reason) || ConfirmDialogData.reason !== false)) {
        if (!reasonText) {
          errors["deleteReason"] = ErrorMessages["E00023"]
        }
      }
      if (Object.keys(errors).length !== 0) {
        showError({ errors, dispatch, props: { snackbarstate } })
      } else {
        if (reason === 1 && (isblank(ConfirmDialogData) || isblank(ConfirmDialogData.reason) || ConfirmDialogData.reason !== false)) {
          var deleteReason = reasonText
          dispatch(dialogReasonUpdate(reasonText))
          if (onConfirm) {
            onConfirm(deleteReason)
          } else {
            dispatch(showdialog({ yesno: false, confirm: true, meta: deleteReason, loading: true }))
          }
        } else {
          if (onConfirm) {
            onConfirm()
          } else {
            dispatch(showdialog({ yesno: false, confirm: true, meta: ConfirmDialogData, loading: true }))
          }
        }
      }
    }, 500)
  }

  var MyDialogComponent = null
  var MyDialogSize = "xs"

  if (ConfirmDialogForm === "Cancel eway bill") {
    MyDialogComponent = cancelEwayBill
  } else if (ConfirmDialogForm === "Cancel e-Invoice") {
    MyDialogComponent = CancelEInvoice
  } else if (ConfirmDialogForm === "Import Detail Dialog") {
    MyDialogComponent = importDetailDialog
  } else if (ConfirmDialogForm === "Update e-Way Bill Manually") {
    MyDialogComponent = updateEwayBillManual
  } else if (ConfirmDialogForm === "Generate E-Invoice") {
    MyDialogComponent = generateE_invoice
  } else if (ConfirmDialogForm === "Update Catalogue User") {
    MyDialogComponent = updateUser
  } else if (ConfirmDialogForm === "Send SMS") {
    MyDialogComponent = modalSendSMS
  } else if (ConfirmDialogForm === "Barcode Print") {
    MyDialogComponent = BarcodePrintDialog
  } else if (ConfirmDialogForm === "Barcode Scan") {
    MyDialogComponent = BarcodeScanDialog
    MyDialogSize = "sm"
  } else if (ConfirmDialogForm === "Error List") {
    MyDialogComponent = errorList
  } else if (ConfirmDialogForm === "NotifyDialog") {
    MyDialogComponent = NotifyDialog
    MyDialogSize = "sm"
  } else if (ConfirmDialogForm === "downloadmobileapp") {
    MyDialogComponent = DownloadMobileApp
  } else if (ConfirmDialogForm === "Update e-Invoice Manually") {
    MyDialogComponent = updateEinvoiceManual
  } else if (ConfirmDialogForm === "Envelope Template") {
    MyDialogComponent = EnvelopeTemplate
  } else if (ConfirmDialogForm === "Cheque Template") {
    MyDialogComponent = ChequeTemplate
  } else if (ConfirmDialogForm === "Draft Dialog") {
    MyDialogComponent = DraftDialog
  } else if (ConfirmDialogForm === "Otp Dialog") {
    MyDialogComponent = OtpDialog
  } else if (ConfirmDialogForm === "Download e-Way Bill") {
    MyDialogComponent = DownloadEWayBill
    MyDialogSize = "sm"
  } else if (ConfirmDialogForm === "Download e-Invoice") {
    MyDialogComponent = DownloadEInvoice
    MyDialogSize = "sm"
  } else if (ConfirmDialogForm === "adjustBillDetail") {
    MyDialogComponent = adjustBillDetail
  } else if (ConfirmDialogForm === "TDS Percentage") {
    MyDialogComponent = TDSPercentage
  } else if (ConfirmDialogForm === "Import Unit") {
    MyDialogComponent = modalImportUnitForm
  } else if (ConfirmDialogForm === "Add additional address") {
    MyDialogComponent = Add_additional_address
  } else if (ConfirmDialogForm === "Add Bank") {
    MyDialogComponent = AddBank
  } else if (ConfirmDialogForm === "Write-off Dues") {
    MyDialogComponent = WriteOffDues
  } else if (ConfirmDialogForm === "Add TermCond") {
    MyDialogComponent = addUpdateTermCond
  } else if (ConfirmDialogForm === "Add SignBox") {
    MyDialogComponent = addSignatureBoxes
  } else if (ConfirmDialogForm === "Import Process Excel") {
    MyDialogComponent = ModalImportProcessExcel
  } else if (ConfirmDialogForm === "Clear All Data") {
    MyDialogComponent = ClearAllData
  } else if (ConfirmDialogForm === "Close Sales Order") {
    MyDialogComponent = CloseSalesOrder
  } else if (ConfirmDialogForm === "Enable Two Factor Authentication") {
    MyDialogComponent = TwoFactorAuth
  } else if (ConfirmDialogForm === "Disable Two Factor Authentication") {
    MyDialogComponent = DisableTwoFactorAuth
  } else if (ConfirmDialogForm === "File GSTR-1") {
    MyDialogComponent = FileGSTR1
    MyDialogSize = "lg"
  } else if (ConfirmDialogForm === "File GSTR-3B") {
    MyDialogComponent = FileGSTR3B
    MyDialogSize = "lg"
  } else if (ConfirmDialogForm === "Update GSTR-2B JSON") {
    MyDialogComponent = UpdateGSTR2BJSON
    MyDialogSize = "sm"
  } else if (ConfirmDialogForm === "Assign Order") {
    MyDialogComponent = UserAssign
    MyDialogSize = "md"
  } else if (ConfirmDialogForm === "Government Server Status") {
    MyDialogComponent = GovernmentServerStaus
    MyDialogSize = "sm"
  } else if (ConfirmDialogForm === "Share Component") {
    MyDialogComponent = ShareComponent
    MyDialogSize = "sm"
  }

  const handleReasonChange = (val) => {
    setReasonText(val)
  }

  useEffect(() => {
    setReasonText("")

    return () => {
      setReasonText("")
    }
  }, [])

  return (
    <React.Fragment>
      <Dialog
        disableEscapeKeyDown={true}
        open={ConfirmDialog}
        maxWidth={MyDialogSize}
        fullWidth={true}
        onClose={(event, dialogreason) => {
          if (dialogreason === "backdropClick" || dialogreason === "escapeKeyDown") {
            if (!((isblank(ConfirmDialogForm) && reason === 1) || ConfirmDialogForm === "Update Catalogue User" || ConfirmDialogForm === "Barcode Scan")) {
              handleConfirmClose()
            }
          } else {
            handleConfirmClose()
          }
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" disableTypography style={{ paddingBottom: 0 }}>
          <Typography variant="h6">{isblank(ConfirmDialogTitle) ? "Delete" : ConfirmDialogTitle}</Typography>
          <IconButton size="small" aria-label="close" className={classes.closeButton} disabled={ConfirmDialogLoading} onClick={handleConfirmClose}>
            <Close fontSize="small" />
          </IconButton>
        </DialogTitle>

        {!isblank(ConfirmDialogForm) ? (
          <Suspense
            fallback={
              <CircularProgress
                disableShrink
                size={50}
                style={{
                  position: "fixed",
                  top: "45%",
                  left: "45%",
                  zIndex: 100000001,
                }}
              />
            }
          >
            <MyDialogComponent
              {...(ConfirmDialogForm === "Generate E-Invoice" && {
                from: "List",
              })}
              {...(ConfirmDialogForm === "Share Component" && {
                ...ConfirmDialogData,
              })}
              {...(ConfirmDialogForm === "downloadmobileapp" && {
                message: config.appDownloadmessage,
                appStoreUrl: config.appStoreUrl,
                playStoreUrl: config.playStoreUrl,
              })}
              componentId={ConfirmDialogForm ? replaceAllFn(ConfirmDialogForm, " ", "_") : ""}
              onConfirm={onConfirm}
              
              
            />
          </Suspense>
        ) : (
          <React.Fragment>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {isblank(ConfirmDialogMessage) ? "Are you sure want to delete this item?" : `Are you sure want to ${ConfirmDialogMessage}`}
              </DialogContentText>
              {reason === 1 && (isblank(ConfirmDialogData) || isblank(ConfirmDialogData.reason) || ConfirmDialogData.reason !== false) && (
                <React.Fragment>
                  <StandardTextField fullWidth={true} name="deleteReason" label="REASON" placeholder="Enter Reason" multiline={2} minRows="2" isRequired={true} onValueChange={handleReasonChange} />
                </React.Fragment>
              )}
            </DialogContent>
            <DialogActions>
              <Button data-testid="dialogNo" disabled={ConfirmDialogLoading || loading} onClick={handleConfirmClose} variant="text">
                No
              </Button>
              <Button data-testid="dialogYes" disabled={ConfirmDialogLoading} onClick={handleConfirmYes} variant="text" color="primary">
                {ConfirmDialogLoading || loading ? "Submitting..." : "Yes"}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  )
}

// const modalTransferPorcessConfirm = lazy(() => import("../ModalPages/modalTransferPorcessConfirm"))
//  else if (ConfirmDialogForm === "ProcessTransferConfirm") {
//   MyDialogComponent = modalTransferPorcessConfirm
// }
