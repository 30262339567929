import { getdatatype } from "@project/components/fbMenuRightFn"
import { list_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, isblank, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { forEach } from "lodash"
import { getheaders, getMenuData, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import {
  CUSTOM_PAGE_LOCAL_SEARCH,
  CUSTOM_PAGE_SERVICE_CLEAR,
  CUSTOM_PAGE_SERVICE_FAIL,
  CUSTOM_PAGE_SERVICE_START,
  CUSTOM_PAGE_SERVICE_SUCCESS,
  LAST_PAGE_UPDATE,
  LOADING_STATE,
  SNACKBAR_STATE,
} from "./types"

export const notificationPage_data_clear = () => {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_PAGE_SERVICE_CLEAR,
    })
  }
}

export const local_search_notificationPage = ({ search_text }) => {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_PAGE_LOCAL_SEARCH,
      search_text: search_text,
    })
  }
}

export const fetch_notificationPage_data = ({ code, onComplete, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: CUSTOM_PAGE_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", "notifications")
    data.append("other_type", "list")
    if (!isblank(code)) {
      data.append("code", code)
    }

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "notification_page" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          notificationPageServiceFail({ dispatch, response: response.data.meta.message })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          if (isblank(code)) {
            notificationPageServiceSuccess({ dispatch, user: response })
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "notificationPage",
              lastaction: "list",
              lasttime: new Date().getTime(),
              lastdata: "",
            })
          } else {
            if (onComplete) {
              onComplete(response, code)
            }
          }
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          notificationPageServiceFail({ dispatch, response: getCatchErrorMessage(error) })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const notificationPageServiceSuccess = ({ dispatch, user }) => {
  dispatch({
    type: CUSTOM_PAGE_SERVICE_SUCCESS,
    payload: user,
    getdatatype: getdatatype(getMenuData),
  })
}

const notificationPageServiceFail = ({ dispatch, response }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })

  dispatch({
    type: CUSTOM_PAGE_SERVICE_FAIL,
    payload: response,
  })
}

export const sendMultipleNotifications = ({ data, onSuccess, onError, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: CUSTOM_PAGE_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })
    let form_data = new FormData()
    forEach(data, (value, key) => {
      const formdata2 = Array.isArray(value) ? value.map(JSON.stringify) : JSON.stringify(value)
      form_data.append(key, formdata2)
    })

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "send_multi_notifications" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: form_data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          onError({ response: response.data.meta.message })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          onSuccess({ response: response.data.meta.message })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}
