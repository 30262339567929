import { Button, ButtonGroup, ClickAwayListener, DialogActions, DialogContent, DialogContentText, Grid, Grow, MenuItem, MenuList, Paper, Popper, Typography } from "@material-ui/core"
import { ArrowDropDown as ArrowDropDownIcon, Print } from "@material-ui/icons"
import NotificationsActive from "@material-ui/icons/NotificationsActive"
import CustomSvgIcon from "@project/components/common/CustomSvgIcon"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import { callError, onSubmitError } from "@project/sharedcomponents/errorFunction"
import { DatetoDMY, isblank, validateEmail, validateMobile } from "@project/sharedcomponents/utilityFunctions"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { ewaybillAction, modalInvoiceDetail, modalInvoiceLocalPrint, sendNewNotifications, showdialog, snackbarstate } from "../../Actions"
import { getAccountSessionUserData, getAccountSitesetting, getconfigsettingdata } from "../../Lib/commonfunctions"
import { sendNotificationInvoiceFn } from "../../Lib/printFunctions"
import Field from "../common/reduxFormComponents"
import NotificationButtonsV2 from "../ModalPages/NotificationButtonsV2"

const DownloadEWayBill = (props) => {
  const [notificationModule, setNotificationModule] = useState(false)
  const selectedType = "Notify and Print"
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const dS = {
    notify_admin: parseInt(getAccountSitesetting("notify_admin")),
    invoice_type_detail: getAccountSitesetting("invoice_type_detail"),
    company_name: getAccountSessionUserData("company_name"),
    email_module: getconfigsettingdata("email_module"),
    sms_module: getconfigsettingdata("sms_module"),
    enable_whatsapp_api: getconfigsettingdata("enable_whatsapp_api"),
    whatsapp_module: getAccountSitesetting("whatsapp_module"),
    whatsapp_queue: getAccountSitesetting("whatsapp_queue"),
  }

  const defaultValues = {}
  const { control, watch, setValue, setError } = useForm({ defaultValues })
  const formValues = watch()
  const { sidebarloading, loading, ConfirmDialogData, drawerStack } = useSelector((state) => state.CommonReducer)
  const dispatch = useDispatch()
  const drawerdata = drawerStack && drawerStack.length > 0 ? drawerStack[drawerStack.length - 1].data : ""
  const options = ["Notify", "Notify and Print", "Notify and Download"]

  const validate = (values) => {
    const errors = {}
    if (values.sendEmail) {
      if (isblank(values.email)) {
        errors["email"] = ErrorMessages["E00013"]
      } else if (!validateEmail(values.email)) {
        errors["email"] = ErrorMessages["E00010"]
      }
    }
    if (values.sendSMS || values.sendWhatsApp) {
      if (isblank(values.mobile)) {
        errors["mobile"] = ErrorMessages["E00014"]
      } else if (!validateMobile(values.mobile)) {
        errors["mobile"] = ErrorMessages["E00117"]
      }
    }

    return errors
  }

  const download = (type, notify) => {
    const error = validate(formValues)

    if (Object.keys(error).length > 0) {
      callError({ error, onSubmitError, dispatch, snackbarstate, setError })
    } else {
      const { code, other_type, mode } = ConfirmDialogData

      var otherDetail = {}
      if (other_type === "salesreturn") {
        otherDetail.title = "Credit Note"
      } else if (dS.invoice_type_detail.filter((e) => e.id === other_type).length > 0) {
        otherDetail.title = dS.invoice_type_detail.find((item) => item.id === other_type).name
      }
      otherDetail.printType = "html"
      otherDetail.printCopy = "single"
      otherDetail.actionType = "saveandprint"
      if (notificationModule && notify && (formValues.sendSMS || formValues.sendEmail || formValues.sendWhatsApp || formValues.sendAdmin)) {
        const onCompleteBase64 = (fileData) => {
          const lastpagedata = {
            formtype: other_type,
            sendSMS: formValues.sendSMS,
            sendEmail: formValues.sendEmail,
            sendWhatsApp: formValues.sendWhatsApp,
            sendAdmin: formValues.sendAdmin,
            code,
            email: formValues.email,
            defaultMobile: formValues.defaultMobile,
            defaultEmail: formValues.defaultEmail,
            number: formValues.mobile,
            invoice_type: other_type,
            notifyPartyCode: code,
          }

          sendNotificationInvoiceFn({
            fileData,
            lastpagedata,
            sendNewNotifications: sendNewNotifications,
            lastpageaction: type === "download" ? "saveAndNotificationAndDownload" : type === "print" ? "saveAndNotificationAndPrint" : "",
            dispatch,
            dS,
          })
        }
        if (mode === "invoiceDetail") {
          dispatch(modalInvoiceLocalPrint({ data: drawerdata, other_type, otherDetail, printType: "base64", onCompleteBase64 }))
        } else {
          dispatch(modalInvoiceDetail({ code, otherType: other_type, otherDetail, printType: "base64", onCompleteBase64 }))
        }
      } else {
        if (mode === "invoiceDetail") {
          dispatch(modalInvoiceLocalPrint({ data: drawerdata, other_type, otherDetail, printType: type }))
        } else {
          dispatch(modalInvoiceDetail({ code, otherType: other_type, otherDetail, printType: type }))
        }
      }
    }
  }

  const handleMenuItemClick = (type) => {
    if (type === "Notify") {
      download("", true)
    } else if (type === "Notify and Print") {
      download("print", true)
    } else if (type === "Notify and Download") {
      download("download", true)
    }
  }

  const handleToggle = (event) => {
    setOpen(!open)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  const handleConfirmClose = () => {
    dispatch(showdialog({ yesno: false, confirm: false, title: "e-Way Bill Generated", form: "Download e-Way Bill" }))
  }

  const dismissButton = (
    <Button size="small" onClick={handleConfirmClose} disabled={sidebarloading | loading ? true : false} variant="text">
      Dismiss
    </Button>
  )

  const invoiceButtons = (
    <React.Fragment>
      <Button size="small" onClick={() => download("print", false)} startIcon={<Print fontSize="small" />} disabled={sidebarloading | loading ? true : false} variant="text" color="primary">
        Print
      </Button>
      <Button
        size="small"
        onClick={() => download("download", false)}
        startIcon={<CustomSvgIcon name="downloadPdf" fontSize="small" />}
        disabled={sidebarloading | loading ? true : false}
        variant="text"
        color="primary"
      >
        Download
      </Button>
      {notificationModule && (
        <React.Fragment>
          <ButtonGroup
            variant="outlined"
            color="primary"
            size="small"
            disabled={sidebarloading | loading || (!formValues.sendSMS && !formValues.sendEmail && !formValues.sendWhatsApp && !formValues.sendAdmin) ? true : false}
            aria-label="split button"
          >
            <Button size="small" startIcon={<NotificationsActive fontSize="small" />} onClick={() => handleMenuItemClick(selectedType ? selectedType : options[0])}>
              {selectedType ? selectedType : options[0]}
            </Button>
            <Button
              size="small"
              color="primary"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper open={open} anchorEl={anchorEl} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem key={option} selected={option === selectedType} onClick={() => handleMenuItemClick(option)}>
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </React.Fragment>
      )}
    </React.Fragment>
  )

  const downloadEwayBill = (print_flag) => {
    const { code, other_type } = ConfirmDialogData

    const printAction = () => {
      var otherDetail = {}
      otherDetail.type = other_type
      otherDetail.printType = "html"
      otherDetail.actionType = "docPrint"
      dispatch(modalInvoiceDetail({ code, otherType: other_type, otherDetail, printType: "print" }))
    }
    dispatch(ewaybillAction({ formdata: code, title: "e-Way Bill", type: "ewaybill_print", other_type, print_flag, printAction }))
  }

  const ewayDownloadbuttons = (
    <React.Fragment>
      <Button
        size="small"
        onClick={() => downloadEwayBill("0")}
        startIcon={<CustomSvgIcon name="downloadPdf" fontSize="small" />}
        disabled={sidebarloading | loading ? true : false}
        variant="text"
        color="primary"
      >
        Download
      </Button>
      <Button
        size="small"
        onClick={() => downloadEwayBill("1")}
        startIcon={<CustomSvgIcon name="downloadPdf" fontSize="small" />}
        disabled={sidebarloading | loading ? true : false}
        variant="text"
        color="primary"
      >
        Download Detailed
      </Button>
    </React.Fragment>
  )

  var invoice_Buttons = null
  var eway_Buttons = null
  if (ConfirmDialogData.ewaybill_no) {
    invoice_Buttons = (
      <DialogActions>
        {dismissButton}
        {invoiceButtons}
      </DialogActions>
    )
    eway_Buttons = <DialogActions>{ewayDownloadbuttons}</DialogActions>
  } else {
    eway_Buttons = (
      <DialogActions>
        {dismissButton}
        {ewayDownloadbuttons}
      </DialogActions>
    )
  }

  useEffect(() => {
    if (ConfirmDialogData) {
      const { userDetail } = ConfirmDialogData
      if (userDetail) {
        setValue("email", userDetail.email)
        setValue("mobile", userDetail.mobile)

        setNotificationModule(true)
        if (userDetail.enableEmail && dS.email_module === "yes") {
          setValue("sendEmailButton", true)
          setValue("sendEmail", userDetail.enableEmail)
        } else {
          setValue("sendEmailButton", false)
          setValue("sendEmail", false)
        }
        if (userDetail.enableSMS && dS.sms_module === "yes") {
          setValue("sendSMSButton", true)
          setValue("sendSMS", userDetail.enableSMS)
        } else {
          setValue("sendSMSButton", false)
          setValue("sendSMS", false)
        }
        if (userDetail.enableWhatsapp && dS.enable_whatsapp_api === "yes") {
          setValue("sendWhatsAppButton", true)
          setValue("sendWhatsApp", userDetail.enableWhatsapp)
        } else {
          setValue("sendWhatsAppButton", false)
          setValue("sendWhatsApp", false)
        }
        if (dS.notify_admin === 1) {
          setValue("sendAdminButton", true)
          setValue("sendAdmin", 1)
        } else {
          setValue("sendAdminButton", false)
          setValue("sendAdmin", false)
        }
      }
    }
  }, [])

  return (
    <React.Fragment>
      <DialogContent>
        {ConfirmDialogData.ewaybill_no ? (
          <React.Fragment>
            {!isblank(ConfirmDialogData.irn) && (
              <div style={{ marginBottom: 16 }}>
                <DialogContentText id="switch-description">e-Invoice generated succesfully with IRN no</DialogContentText>
                <Typography variant="subtitle2" noWrap>
                  {ConfirmDialogData.irn}
                </Typography>
              </div>
            )}
            <DialogContentText id="switch-description">e-Way Bill generated succesfully</DialogContentText>
            <Typography variant="subtitle2" noWrap>
              e-Way Bill No: {ConfirmDialogData.ewaybill_no}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              e-Way Bill Date: {DatetoDMY(ConfirmDialogData.ewaybill_date)}
            </Typography>
            {eway_Buttons}
            <Typography variant="h6">Invoice</Typography>
            {notificationModule && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <NotificationButtonsV2
                    sendSMSButton={formValues.sendSMSButton}
                    sendWhatsAppButton={formValues.sendWhatsAppButton}
                    sendEmailButton={formValues.sendEmailButton}
                    sendAdminButton={formValues.sendAdminButton}
                    notifyAdmin={dS.notify_admin}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="email" inputType={"RenderTextField"} control={control} label={"Email address"} placeholder="Enter email address" isRequired={formValues.sendEmailButton} />
                  <Field name="defaultEmail" inputType={"renderToggle"} control={control} label="Set as default" size="small" />

                  <Field
                    name="mobile"
                    inputType={"RenderTextField"}
                    control={control}
                    label={"Mobile no"}
                    placeholder="Enter mobile no"
                    helpText="Enter 10 digit mobile no."
                    isRequired={formValues.sendWhatsAppButton || formValues.sendSMSButton}
                  />
                  <Field name="defaultMobile" inputType={"renderToggle"} control={control} label="Set as default" size="small" />
                </Grid>
              </Grid>
            )}
            {invoice_Buttons}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DialogContentText id="switch-description">Are you sure want to download e-Way Bill?</DialogContentText>
            {eway_Buttons}
          </React.Fragment>
        )}
      </DialogContent>
    </React.Fragment>
  )
}

export default DownloadEWayBill
