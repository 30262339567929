export const all_finalbooksarray = () => {
  return [
    {
      id: "108",
      name: "Transport",
      type: "transport",
      dependency: "ddtransport",
      menu: "Transport",
    },
    {
      id: "109",
      name: "Agent",
      type: "agent",
      dependency: "ddagent",
      menu: "Agent",
    },
    {
      id: "110",
      name: "Customer",
      type: "customer",
      dependency: "ddcustomer",
      menu: "Customers",
    },
    {
      id: "111",
      name: "Supplier",
      type: "supplier",
      dependency: "ddsupplier",
      menu: "Suppliers",
    },
    {
      id: "112",
      name: "Customer/Supplier Both",
      type: "customer_supplier",
      dependency: "ddcustomer_supplier",
      menu: "Customer/Supplier",
    },
    {
      id: "113",
      name: "State",
      type: "state",
      dependency: "ddstate",
      menu: "Manage State",
    },
    {
      id: "114",
      name: "City",
      type: "city",
      dependency: "ddcity",
      menu: "Manage City",
    },
    {
      id: "115",
      name: "Unit",
      type: "unit",
      dependency: "ddunit",
      menu: "Manage Unit",
    },
    {
      id: "116",
      name: "Other Account",
      type: "other_account",
      dependency: "ddother_account",
      menu: "Manage Other Accounts",
    },
    {
      id: "117",
      name: "Product",
      type: "item",
      dependency: "dditem",
      menu: "Manage Product",
    },
    {
      id: "118",
      name: "Product Group",
      type: "itemgroup",
      dependency: "dditemgroup",
      menu: "Manage Product Group",
    },
    {
      id: "119",
      name: "User",
      type: "user",
      dependency: "dduser",
      menu: "Manage User",
    },
    {
      id: "120",
      name: "Tax scheme",
      type: "taxschemeaccount",
      dependency: "ddtaxscheme",
      menu: "Tax Scheme",
    },
    {
      id: "121",
      name: "Customer Group",
      type: "partygroup",
      dependency: "ddpartygroup",
      menu: "Manage Account Group",
    },
    {
      id: "122",
      name: "Godown",
      type: "ddgodown",
      dependency: "ddgodown",
      menu: "Manage Godown",
    },
    {
      id: "123",
      name: "Country",
      type: "dd_country_state",
      dependency: "dd_country_state",
      menu: "Manage Country",
    },
  ]
}

export const fixedCustomFieldType = () => {
  return [
    {
      id: "1",
      name: "Text",
      dependency: "",
      type: "",
    },
    {
      id: "2",
      name: "Decimal",
      dependency: "",
      type: "",
    },
    {
      id: "3",
      name: "Date",
      dependency: "",
      type: "",
    },
    {
      id: "4",
      name: "Checkbox",
      dependency: "",
      type: "",
    },
    {
      id: "5",
      name: "Dropdown",
      dependency: "",
      type: "",
    },
    {
      id: "6",
      name: "Creatable Dropdown",
      dependency: "",
      type: "",
    },
    {
      id: "7",
      name: "Photo",
      dependency: "",
      type: "",
    },
    {
      id: "8",
      name: "Yes/No",
      dependency: "",
      type: "",
    },
    {
      id: "9",
      name: "HTML Editor",
      dependency: "",
      type: "",
    },
    {
      id: "10",
      name: "Dropbox",
      dependency: "",
      type: "",
    },
  ]
}

export const finalBooksDependency = (getMenuData) => {
  return [
    {
      id: "101",
      name: "Bank Account",
      type: "bankaccount",
      dependency: "ddbankaccount",
    },
    {
      id: "102",
      name: "Expense Account",
      type: "expenseaccount",
      dependency: "ddexpenseaccount",
    },
    {
      id: "103",
      name: "All Account",
      type: "allaccount",
      dependency: "ddallaccount",
    },
    {
      id: "104",
      name: "Sales Account",
      type: "sales_account",
      dependency: "ddsalesaccount",
    },
    {
      id: "105",
      name: "Purchase Account",
      type: "purchase_account",
      dependency: "ddpurchaseaccount",
    },
    {
      id: "106",
      name: "Account Type",
      type: "accounttype",
      dependency: "ddaccounttype",
    },
    {
      id: "107",
      name: "Account Group",
      type: "accountgroup",
      dependency: "ddaccountgroup",
    },
    ...dropDownwithRights(getMenuData),
  ]
}

export const getdatatype = (getMenuData) => {
  return [...fixedCustomFieldType(), ...finalBooksDependency(getMenuData)]
}

export const getdatatype_all = (getMenuData) => {
  return [...fixedCustomFieldType(), ...all_finalbooksarray()]
}

export const dropDownwithRights = (getMenuData) => {
  const allArray = all_finalbooksarray()
  var menudetails = getMenuData()

  var mainArray = []
  var finalArray = []
  Object.keys(menudetails).forEach((item) => {
    menudetails[item].forEach((it) => {
      mainArray.push(it)
    })
  })
  mainArray = mainArray.map((e) => e.name)
  allArray.forEach((it) => {
    if (it.menu === "Customer/Supplier") {
      if (mainArray.includes("Customers") && mainArray.includes("Suppliers")) {
        finalArray.push(it)
      }
    } else if (mainArray.includes(it.menu)) {
      finalArray.push(it)
    }
  })
  return finalArray
}
