import { fbprintpdf } from "@project/components/printJSFunctions"
import { list_url } from "@project/sharedcomponents/apiURL"
import { reportFetchDataSuccess, setRequestDataFromconfig } from "@project/sharedcomponents/reportFunction"
import { getAxiosCancelToken, getCatchErrorMessage, isblank, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getAccountSessionUserData, getAccountSitesetting, getheaders, getNameFromType, getReportRights, getWindowWidth, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import { multiPackingSlipPrint } from "../Lib/printDocuments"
import { pushDrawer, showMultiError, updateDrawer } from "./CommonActionsV2"
import {
  GST_SET_DETAIL,
  LAST_PAGE_UPDATE,
  LOADING_STATE,
  REPORT_GENERATE_COLUMNS,
  REPORT_SEARCH_TEXT_UPDATE,
  REPORT_SERVICE_CLEAR,
  REPORT_SERVICE_FAIL,
  REPORT_SERVICE_START,
  REPORT_SERVICE_SUCCESS,
  SNACKBAR_STATE,
} from "./types"

export const reportsearch_change = ({ search_text }) => {
  return (dispatch) => {
    dispatch({
      type: REPORT_SEARCH_TEXT_UPDATE,
      search_text: search_text,
    })
  }
}

export const fetch_report_data = ({ setconfig, formtype, functions }) => {
  const pagination_size = getAccountSitesetting("pagination_size")
  if (isblank(setconfig.rowsPerPage)) {
    setconfig.rowsPerPage = pagination_size
  }
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    if (formtype !== "lot_costing") {
      dispatch({ type: REPORT_SERVICE_START })
    }
    dispatch({ type: LOADING_STATE, state: true })
    let data = setRequestDataFromconfig({ setconfig })
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: setconfig.url ? setconfig.url : list_url({ type: "getAllReport" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else if (response.data.meta.code === 210) {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "reportProcess",
            lastaction: "start",
            lastdata: setconfig,
            lasttime: new Date().getTime(),
          })
        } else {
          dispatch({ type: LOADING_STATE, state: false })
          if (formtype === "adjustBill") {
            if (setconfig.other_type === "3") {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: "adjustBill",
                lastaction: "fixold",
                lasttime: new Date().getTime(),
                lastdata: response.data.result,
              })
            } else if (setconfig.other_type === "2") {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: "adjustBill",
                lastaction: "unadjust",
                lasttime: new Date().getTime(),
                lastdata: response.data.result.records,
              })
            } else {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: "adjustBill",
                lastaction: "adjust",
                lasttime: new Date().getTime(),
                lastdata: response.data.result.records,
              })
            }
          } else {
            const { dd_orderTypes, ddcustomer_supplier, dd_country_state, dditem, dd_taxcode, getReportRights, ddallaccount } = functions

            reportFetchDataSuccess({
              setconfig,
              response,
              dispatch,
              GST_SET_DETAIL,
              REPORT_SERVICE_SUCCESS,
              functions: {
                showMultiError,
                getReportRights,
                getAccountSessionUserData,
                getAccountSitesetting,
                dd_taxcode,
                dd_orderTypes,
                ddcustomer_supplier,
                dd_country_state,
                dditem,
                ddallaccount,
                getNameFromType: (type, short_form) => getNameFromType(type, short_form),
              },
            })
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "reportProcess",
          lastaction: "success",
          lasttime: new Date().getTime(),
          lastdata: 100,
        })
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          reportServiceFail({ dispatch, response: getCatchErrorMessage(error), setconfig })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const getProgressStatus = ({ fileName, setconfig, type, othertype }) => {
  let data = new FormData()
  data.append("fileName", fileName)
  data.append("type", "reportProcess")

  return (dispatch) => {
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "reportProcess" }),
      headers: { "Content-Type": "multipart/form-data" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({ type: LOADING_STATE, state: false })
          if (type === "changeYear") {
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "changeYearProcess",
              lastaction: "fail",
              lasttime: new Date().getTime(),
              lastdata: "",
            })
          } else {
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "reportProcess",
              lastaction: "fail",
              lasttime: new Date().getTime(),
              lastdata: "",
            })
          }
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          if (type === "changeYear") {
            if (parseFloat(response.data.result.percent) === 100) {
              dispatch({ type: LOADING_STATE, state: false })
              dispatch({
                type: SNACKBAR_STATE,
                payload: true,
                message: "Year changed successfully",
                snackbarType: "success",
              })
            }
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "changeYearProcess",
              lastaction: "success",
              lasttime: new Date().getTime(),
              lastdata: response.data.result.percent,
            })
          } else if (type === "import" && !isblank(othertype)) {
            // IMPORT FINALBOOKS
            if (parseFloat(response.data.result.percent) === 100) {
              dispatch({ type: LOADING_STATE, state: false })
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: othertype,
                lastaction: "import",
                lasttime: new Date().getTime(),
                lastdata: response.data.result.result,
              })
            } else {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: "reportProcess",
                lastaction: "success",
                lasttime: new Date().getTime(),
                lastdata: response.data.result.percent,
              })
            }
          } else {
            if (parseFloat(response.data.result.percent) === 100) {
              dispatch({ type: LOADING_STATE, state: false })
              var reportResponse = {
                data: {
                  meta: response.data.meta,
                  result: response.data.result.result,
                  filter: response.data.filter,
                  filter_master: !isblank(response) && !isblank(response.data) && response.data.filter_master,
                },
              }
              reportFetchDataSuccess({
                setconfig,
                response: reportResponse,
                dispatch,
                GST_SET_DETAIL,
                REPORT_SERVICE_SUCCESS,
                functions: { showMultiError, getReportRights, getAccountSessionUserData, getAccountSitesetting, getNameFromType: (type, short_form) => getNameFromType(type, short_form) },
              })
            }
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "reportProcess",
              lastaction: "success",
              lasttime: new Date().getTime(),
              lastdata: response.data.result.percent,
            })
          }
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        dispatch({ type: LOADING_STATE, state: false })
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "reportProcess",
          lastaction: "fail",
          lasttime: new Date().getTime(),
          lastdata: "",
        })

        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
      })
  }
}

export const generatecolumns = (reportdata, account_type, formValues) => {
  return (dispatch) => {
    dispatch({
      type: REPORT_GENERATE_COLUMNS,
      reportdata: reportdata,
      account_type: account_type,
      formValues: formValues,
    })
  }
}

export const reportServiceClear = () => {
  return (dispatch) => {
    dispatch({
      type: REPORT_SERVICE_CLEAR,
      payload: [],
    })
  }
}

const reportServiceFail = ({ dispatch, response, setconfig }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })
  dispatch({
    type: REPORT_SERVICE_FAIL,
    payload: response,
    setconfig: setconfig,
  })
}

export const printPackingSlip = ({ code, sales_invoice_type, type }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("code", code)
    data.append("type", type)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: type }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          var meta = {
            sales_invoice_type,
            invoice_type: "packing_slip",
          }
          var printData = multiPackingSlipPrint({ printdata: response.data.result, meta })
          const metadata = {}
          fbprintpdf({ docDefinition: printData, metadata, action: "print" })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const fetchItemLevelOrder = (party_code, itemcode, meta, functions) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("type", "pen_salesorder_partyitem")
    data.append("party_code", party_code)
    data.append("itemcode", itemcode)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "pen_salesorder_partyitem" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({ type: LOADING_STATE, state: false })
          const metadata = {}
          metadata.data = response.data.result
          metadata.formname = meta.formname
          metadata.index = meta.index
          metadata.Grid = meta.Grid
          metadata.tot_qty = meta.tot_qty
          metadata.Gridfieldname = meta.Gridfieldname
          metadata.action = meta.action
          metadata.type = "itemLevelOrder"

          pushDrawer({ title: "Adjust Bill", data: metadata, position: "right", width: 1050, dispatch })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const fetchNewInvoiceNumber = (other_type, main_type) => {
  return (dispatch) => {
    let data = new FormData()
    data.append("type", "getnewbillno")
    data.append("other_type", other_type)
    data.append("main_type", main_type)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "getnewbillno" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "getnewbillno",
            lastaction: "fetch",
            lasttime: new Date().getTime(),
            lastdata: response.data.result,
            lastmeta: { other_type, main_type },
          })
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
      })
  }
}

export const fetchPackingSlip = ({ party_code, code, other_type, Selected, type, edit_type, ddcustomfield, disabled, from_type, set_Value, grid_type }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    if (type === "link_packing_slip") {
      data.append("type", "packingslip_link")
    } else if (type === "goods_received_note") {
      data.append("type", "pen_goods_received_note")
    } else if (type === "delivery_challan" || type === "delivery_challan_to_stocktransfer") {
      data.append("type", "pen_delivery_challan")
    } else {
      data.append("type", "pen_packingslip_detail")
    }
    data.append("party_code", party_code)
    data.append("code", code)
    data.append("other_type", other_type)
    data.append("edit_type", edit_type)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "fetchPackingSlip" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          const fieldname = {}
          fieldname.invoice_type = type
          fieldname.party_code = party_code
          fieldname.from_type = from_type
          fieldname.grid_type = grid_type
          response.data.Selected = Selected
          response.data.disabled = disabled ? disabled : []
          if (type === "sales_packing_slip" || type === "goods_received_note" || type === "delivery_challan" || type === "delivery_challan_to_stocktransfer" || type === "packing_slip_to_lot") {
            response.data.ddcustomfield = ddcustomfield
          }

          pushDrawer({ title: "Select Pen Order", data: { ...response.data, set_Value }, fieldname, position: "bottom", dispatch })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const fetchPenSalesOrder = ({ party_code, invoice_code, Selected, pagination, from_type, set_Value, gridClearField }) => {
  const { page, rowsPerPage, order, orderBy, search_text, from, getAccountSessionUserData, drawerIndex } = pagination
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("type", "pen_salesorder_detail")
    data.append("party_code", party_code)
    data.append("code", invoice_code)

    if (from_type) {
      data.append("from_type", from_type)
    }
    data.append("sort_order", order)
    data.append("sort_by", orderBy)
    data.append("search_text", search_text)
    if (!isblank(from) && from === "pagination") {
      data.append("limit", rowsPerPage)
      data.append("offset", page * rowsPerPage)
    } else {
      data.append("limit", "All")
      data.append("offset", 0)
      data.append("sort_order", "asc")
      data.append("sort_by", "b_date")
    }
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "pen_salesorder_detail" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false })
        } else {
          const data = {}
          data.result = response.data.result
          data.invoice_code = invoice_code
          data.Selected = Selected
          data.set_Value = set_Value
          data.gridClearField = gridClearField
          data.totalcount = response.data.total_count
          data.from_type = from_type
          data.webServiceData = {
            order: order,
            orderBy: orderBy,
            page: page,
            rowsPerPage: rowsPerPage,
            code: invoice_code,
            party_code: party_code,
          }
          data.disabled = []
          const fieldname = {}
          fieldname.invoice_type = "sales_order"
          var maxWidth = getWindowWidth()
          if (!isblank(from) && from === "pagination") {
            updateDrawer({ title: "Select Pen Order", data, fieldname, position: maxWidth <= 1248 ? "bottom" : "right", width: maxWidth <= 1248 ? maxWidth : 1248, dispatch, index: drawerIndex })
          } else {
            pushDrawer({ title: "Select Pen Order", data, fieldname, position: maxWidth <= 1248 ? "bottom" : "right", width: maxWidth <= 1248 ? maxWidth : 1248, dispatch })
          }
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const fetchSalesOrderDetail = (code, type, functions) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("type", type)
    data.append("code", code)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "sales_order_detail" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          if (type === "salesinvoice_adjust_receiptdetail") {
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "adjustBillDetail",
              lastaction: "list",
              lasttime: new Date().getTime(),
              lastdata: response.data.result.adjust_payment_detail,
            })
          } else if (type === "purchaseinvoice_adjust_paymentdetail") {
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "purchaseAdjustBillDetail",
              lastaction: "list",
              lasttime: new Date().getTime(),
              lastdata: response.data.result.adjust_payment_detail,
            })
          } else {
            const fieldname = {}
            fieldname.invoice_type = "invoice_linked_In_sales_order"
            fieldname.type = type

            pushDrawer({ title: "Select Pen Order", data: response.data, fieldname, position: "right", width: 450, dispatch })
          }

          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const fetchPenPurchaseOrder = ({ party_code, invoice_code, Selected, from_type, set_Value, gridClearField }) => {
  
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", "pen_purchaseorder_detail")
    data.append("party_code", party_code)
    data.append("code", invoice_code)
    data.append("from_type", from_type)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "pen_purchaseorder_detail" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false })
        } else {
          const data = {}
          data.result = response.data.result
          data.invoice_code = invoice_code
          data.Selected = Selected
          data.set_Value = set_Value
          data.gridClearField = gridClearField
          data.from_type = from_type
          data.disabled = []
          const fieldname = {}
          fieldname.invoice_type = "purchase_order"

          var maxWidth = getWindowWidth()

          pushDrawer({ title: "Select Pen Order", data, fieldname, position: maxWidth <= 1248 ? "bottom" : "right", width: maxWidth <= 1248 ? maxWidth : 1248, dispatch })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const fetchPurchaseOrderDetail = ({ code, type, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("type", type)
    data.append("code", code)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "purchase_order_detail" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          const fieldname = {}
          fieldname.invoice_type = "invoice_linked_In_purchase_order"
          fieldname.type = type

          pushDrawer({ title: "Select Pen Order", data: response.data, fieldname, position: "right", width: 450, dispatch })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}
