import { AppBar, Button, Toolbar, Typography } from "@material-ui/core"
import { Print, SaveAlt } from "@material-ui/icons"
import { print_file } from "@project/components/printJSFunctions"
import { exportAs } from "@project/sharedcomponents/utilityFunctions"
import React, { Component } from "react"
import "react-pdf/dist/Page/AnnotationLayer.css"

function file_url(base64data, name) {
  var binary = atob(base64data.replace(/\s/g, ""))
  var len = binary.length
  var buffer = new ArrayBuffer(len)
  var view = new Uint8Array(buffer)
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i)
  }

  // create the blob object with content-type "application/pdf"
  var blob = new Blob([view], { type: "application/pdf" })
  var url = URL.createObjectURL(blob)
  return url
}

class PDFViewer extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.updateWindowDimensions()
    }, 500)
    // this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight - 54 })
  }

  constructor(props) {
    super(props)
    this.state = {
      numPages: null,
      pageNumber: 1,
      width: 0,
      height: 0,
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }

  render() {
    const { base64data, data } = this.props
    return (
      <div>
        <AppBar color="primary" position="static" elevation={0}>
          <Toolbar variant="dense">
            <Typography color="inherit" variant="h6">
              {data.title}
            </Typography>
            <div style={{ flexGrow: 1 }}></div>
            {data.customButtons && data.customButtons.length > 0 && (
              <React.Fragment>
                {data.customButtons.map((e) => {
                  return (
                    <Button style={{ marginRight: 10 }} {...(e.icon && { startIcon: e.icon })} color={e.color ? e.color : "secondary"} variant="contained" size="small" onClick={e.onClick}>
                      {e.label}
                    </Button>
                  )
                })}
              </React.Fragment>
            )}
            <Button style={{ marginRight: 10 }} startIcon={<Print fontSize="small" />} color="secondary" variant="contained" size="small" onClick={() => print_file(base64data, data.filename)}>
              Print
            </Button>

            <Button style={{ marginRight: 100 }} startIcon={<SaveAlt fontSize="small" />} color="secondary" variant="contained" size="small" onClick={() => exportAs({ data: base64data, name: data.filename, type: "pdf" })}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <div>
          <embed src={file_url(base64data, data.filename) + "#toolbar=0&view=fitH"} id="plugin" width="100%" style={{ height: this.state.height }} type="application/pdf" />
        </div>
      </div>
    )
  }
}

export default PDFViewer
