import {
  calculateFixRowV1,
  customFirstStackLength,
  findNode,
  generateCompanyBankDetail,
  generateCompanyBankDetailWithQR,
  generatecustomFieldBody,
  generatecustomFieldBodyV2,
  generatecustomFieldBodyV3,
  generateTotalByHeader,
  generateValueByHeader,
  generateValueByHeaderThermal,
  isblankBankDetail,
  jnestedReplace,
  masterCustomField,
  masterCustomFieldV1,
  masterCustomFieldV2,
  ObjectbyString,
  setObjectByString,
} from "../common"
import invoiceTemplate from "../constantData/invoiceTemplate"
import cF from "@project/sharedcomponents/constantData/customFieldsEnum"
import {
  amount_format,
  amount_formatDecimal,
  camelize,
  checkCondition,
  cloneObj,
  convertIntif,
  DatetoDMY,
  getCountryDataById,
  getNumberonlyDecimal,
  getPageSize,
  getTaxTypeName,
  isblank,
  numForCalc,
  replaceAllFn,
  replaceAllFnPrint,
  replaceBRtoNewLine,
  replacefunction,
} from "../utilityFunctions"

export const generateCompanyAddress = ({ data, getAccountSitesetting, user_data, dd_country_state, capitalizeIfRequire }) => {
  let company_tax = getAccountSitesetting("tax", user_data)
  let tax_name = getAccountSitesetting("tax_name", user_data)
  const company_pan = !isblank(data.pan) ? data.pan : ""
  const company_name = !isblank(data.name) ? data.name : ""
  const company_gstin = !isblank(data.gstin) ? data.gstin : ""
  const company_email = !isblank(data.email) ? data.email : ""
  const company_website = !isblank(data.website) ? data.website : ""
  const company_cin = !isblank(data.cin) ? data.cin : ""
  const company_tinno = !isblank(data.tinno) ? data.tinno : ""
  const company_iec = !isblank(data.iec) ? data.iec : ""
  const company_address1 = !isblank(data.address) ? data.address : ""
  const company_mobile = !isblank(data.mobile) ? data.mobile : ""
  const company_phone = !isblank(data.phoneO) ? data.phoneO : ""
  const company_street = !isblank(data.street) ? data.street : ""
  const company_city = !isblank(data.city) ? data.city : ""
  const company_state = !isblank(data.state) ? data.state : ""
  const company_zip = !isblank(data.zipcode) ? data.zipcode : ""
  const company_country_code = !isblank(data.country) ? data.country : ""
  const company_state_code = !isblank(data.state_id) ? data.state_id : ""
  const company_banker1 = !isblank(data.banker1) ? replaceBRtoNewLine(data.banker1) : ""
  const company_banker2 = !isblank(data.banker2) ? replaceBRtoNewLine(data.banker2) : ""

  let company_address2 = ""
  if (!isblank(company_street)) {
    company_address2 = company_street
  }
  if (!isblank(company_city)) {
    if (!isblank(company_address2)) {
      company_address2 = company_address2 + ", " + company_city
    } else {
      company_address2 = company_city
    }
  }
  if (!isblank(company_state)) {
    if (!isblank(company_address2)) {
      company_address2 = company_address2 + ", " + capitalizeIfRequire(company_state)
    } else {
      company_address2 = capitalizeIfRequire(company_state)
    }
  }
  if (!isblank(company_country_code)) {
    const CountryData = getCountryDataById(company_country_code, dd_country_state)
    if (!isblank(CountryData.name)) {
      if (!isblank(company_address2)) {
        company_address2 = company_address2 + ", " + CountryData.name
      } else {
        company_address2 = CountryData.name
      }
    }
  }

  if (!isblank(company_zip)) {
    if (!isblank(company_address2)) {
      company_address2 = company_address2 + " - " + company_zip
    } else {
      company_address2 = company_zip
    }
  }

  let company_contact = ""
  if (!isblank(company_phone)) {
    company_contact = company_phone
  }
  if (!isblank(company_mobile)) {
    if (!isblank(company_contact)) {
      company_contact = company_contact + ", " + company_mobile
    } else {
      company_contact = company_mobile
    }
  }

  // Checking Tax Type
  if (numForCalc(company_tax) === 2) {
    company_tax = tax_name
  } else {
    company_tax = getTaxTypeName(company_tax)
  }

  var company_other_numbers = ""

  if (!isblank(company_cin)) {
    company_other_numbers = company_cin
  }
  if (!isblank(company_tinno)) {
    if (!isblank(company_other_numbers)) {
      company_other_numbers = `${company_other_numbers}, ${company_tinno}`
    } else {
      company_other_numbers = company_tinno
    }
  }
  if (!isblank(company_iec)) {
    if (!isblank(company_other_numbers)) {
      company_other_numbers = `${company_other_numbers}, ${company_iec}`
    } else {
      company_other_numbers = company_iec
    }
  }

  return {
    company_name,
    company_gstin,
    company_email,
    company_website,
    company_cin,
    company_tinno,
    company_iec,
    company_address1: replacefunction(replaceBRtoNewLine(company_address1)),
    company_address2: replacefunction(replaceBRtoNewLine(company_address2)),
    company_contact,
    company_pan,
    company_other_numbers,
    company_country_code,
    company_tax,
    company_banker1,
    company_banker2,
    company_state_code,
  }
}

export const generateBillingAddress = ({ data, capitalizeIfRequire, dd_country_state }) => {
  var billing_details = !isblank(data) ? JSON.parse(data) : ""

  const billing_name = !isblank(billing_details.name) ? billing_details.name : ""
  const billing_gst_no = !isblank(billing_details.gstin) ? billing_details.gstin : ""
  const billing_pan_no = !isblank(billing_details.pan_no) ? billing_details.pan_no : ""
  const billing_aadhar_no = !isblank(billing_details.aadhar_no) ? billing_details.aadhar_no : ""
  const billing_gst_treatment = !isblank(billing_details.gst_treatment) ? billing_details.gst_treatment : ""
  const billing_address2 = !isblank(billing_details.areacode) ? billing_details.areacode : ""
  const billing_state = !isblank(billing_details.state) ? billing_details.state : ""
  const billing_city = !isblank(billing_details.city) ? billing_details.city : ""
  const billing_zip = !isblank(billing_details.pincode) ? billing_details.pincode : ""
  const billing_country_code = !isblank(billing_details.country_id) ? billing_details.country_id : ""
  const billing_phone = !isblank(billing_details.phone) ? billing_details.phone : ""
  const billing_mobile = !isblank(billing_details.mobile) ? billing_details.mobile : ""
  const billing_email = !isblank(billing_details.email) ? billing_details.email : ""

  let billing_address1 = ""

  if (!isblank(billing_details)) {
    if (!isblank(billing_details.address)) {
      billing_address1 = billing_details.address
    }
    if (!isblank(billing_address2)) {
      if (!isblank(billing_address1)) {
        billing_address1 = billing_address1 + ", " + billing_address2
      } else {
        billing_address1 = billing_address2
      }
    }
    if (!isblank(billing_city)) {
      if (!isblank(billing_address1)) {
        billing_address1 = billing_address1 + ", " + billing_city
      } else {
        billing_address1 = billing_city
      }
    }
    if (!isblank(billing_state)) {
      if (!isblank(billing_address1)) {
        billing_address1 = billing_address1 + ", " + capitalizeIfRequire(billing_state)
      } else {
        billing_address1 = capitalizeIfRequire(billing_state)
      }
    }
    if (!isblank(billing_country_code)) {
      const CountryData = getCountryDataById(billing_country_code, dd_country_state)
      if (!isblank(CountryData.name)) {
        if (!isblank(billing_address1)) {
          billing_address1 = billing_address1 + ", " + CountryData.name
        } else {
          billing_address1 = CountryData.name
        }
      }
    }
    if (!isblank(billing_zip)) {
      if (!isblank(billing_address1)) {
        billing_address1 = billing_address1 + " - " + billing_zip
      } else {
        billing_address1 = billing_zip
      }
    }
  }

  let billing_contact = ""
  if (!isblank(billing_mobile)) {
    billing_contact = billing_mobile
  }
  if (!isblank(billing_phone)) {
    billing_contact = billing_mobile + ", " + billing_phone
  }

  return {
    billing_name,
    billing_address1: replacefunction(replaceBRtoNewLine(billing_address1)),
    billing_gst_no,
    billing_pan_no,
    billing_aadhar_no,
    billing_gst_treatment,
    billing_state,
    billing_contact,
    billing_email,
  }
}

export const generateShippingAddress = ({ data, capitalizeIfRequire, dd_country_state }) => {
  var shipping_details = !isblank(data) ? JSON.parse(data) : ""

  const shipping_name = !isblank(shipping_details.shipping_name) ? shipping_details.shipping_name : ""
  const shipping_gst_no = !isblank(shipping_details.shipping_gstin) ? shipping_details.shipping_gstin : ""
  const shipping_address2 = !isblank(shipping_details.areacode) ? shipping_details.areacode : ""
  const shipping_state = !isblank(shipping_details.statename) ? shipping_details.statename : ""
  const shipping_city = !isblank(shipping_details.cityname) ? shipping_details.cityname : ""
  const shipping_zip = !isblank(shipping_details.pincode) ? shipping_details.pincode : ""
  const shipping_country_code = !isblank(shipping_details.ShippingCountrySelectedValue) ? shipping_details.ShippingCountrySelectedValue : ""
  const shipping_state_code = !isblank(shipping_details.stateid) ? shipping_details.stateid : ""

  var shipping_address1 = ""

  if (!isblank(shipping_details)) {
    if (!isblank(shipping_details.address)) {
      shipping_address1 = shipping_details.address
    }
    if (!isblank(shipping_address2)) {
      shipping_address1 = shipping_address1 + ", " + shipping_address2
    }
    if (!isblank(shipping_city)) {
      shipping_address1 = shipping_address1 + ", " + shipping_city
    }
    if (!isblank(shipping_state)) {
      shipping_address1 = shipping_address1 + ", " + shipping_state
    }
    if (!isblank(shipping_country_code)) {
      const shippCountry = getCountryDataById(shipping_country_code, dd_country_state)
      if (!isblank(shippCountry.name)) {
        shipping_address1 = shipping_address1 + ", " + shippCountry.name
      }
    }
    if (!isblank(shipping_zip)) {
      shipping_address1 = shipping_address1 + " - " + shipping_zip
    }
  }
  return { shipping_address1: replacefunction(replaceBRtoNewLine(shipping_address1)), shipping_state, shipping_state_code, shipping_gst_no, shipping_name }
}

export const generateInvoiceHeader = ({ data, meta, siteSetting, agent_data, transport_data, otherDetail, placeof_statedata, capitalizeIfRequire, company_country_code }) => {
  var crdb_label = "Note No"
  var crdbdate_label = "Note Date"
  var crordb_no = ""
  var crordb_date = ""
  var billno = data.billno
  var billDate = DatetoDMY(data.b_date)
  var mainHeadingText = ""
  var invoice_title = ""
  var title = ""

  let isChallan = false
  if (meta && meta.otherDetail && meta.otherDetail.isChallan) {
    isChallan = true
  }

  if (siteSetting.length > 0) {
    mainHeadingText = siteSetting[0].mainHeadingText
    invoice_title = siteSetting[0].headingText
    title = siteSetting[0].name
  }

  if (isblank(mainHeadingText)) {
    mainHeadingText = ""
  }

  if (isblank(invoice_title)) {
    invoice_title = ""
  }

  if (meta.other_type === "salesreturn") {
    // crordb_no = data.billno_str ? data.billno_str : data.creditnote_no
    crordb_no = data.creditnote_no
    crordb_date = DatetoDMY(data.creditnote_date)
    billno = data.ref_invoice_no
    billDate = DatetoDMY(data.ref_invoice_date)
  } else if (meta.other_type === "purchasereturn") {
    // crordb_no = data.billno_str ? data.billno_str : data.debitnote_no
    crordb_no = data.debitnote_no
    crordb_date = DatetoDMY(data.debitnote_date)
    billno = data.ref_invoice_no
    billDate = DatetoDMY(data.ref_invoice_date)
  }

  if (meta.invoice_type === "getmystore" || meta.invoice_type === "getmycatalogue") {
    title = "PROFORMA INVOICE"
  } else if (meta.invoice_type === "estimate") {
    title = "ESTIMATE"
  } else if (meta.invoice_type === "sales_order") {
    title = "SALES ORDER"
  } else if (meta.invoice_type === "sales_invoice") {
    title = "TAX INVOICE"
  }

  var InvoiceNoLabel = `${camelize(title)} No`
  var InvoiceDateLabel = `${camelize(title)} Date`

  if (meta.invoice_type === "sales_invoice") {
    InvoiceNoLabel = "Invoice No"
    InvoiceDateLabel = "Invoice Date"
  }

  if (!isblank(mainHeadingText)) {
    InvoiceNoLabel = `${camelize(mainHeadingText)} No`
    InvoiceDateLabel = `${camelize(mainHeadingText)} Date`
  }

  if (meta.other_type === "salesreturn" || meta.other_type === "purchasereturn") {
    InvoiceNoLabel = "Against Invoice No"
    InvoiceDateLabel = "Invoice Date"
  }

  mainHeadingText = !isblank(mainHeadingText) ? mainHeadingText : title
  if (isChallan) {
    mainHeadingText = "DELIVERY CHALLAN"
  }

  var orderno = !isblank(data.orderno) && data.orderno !== "0" ? data.orderno : ""

  var ewaybill_no = data.ewaybill_no
  if (isblank(ewaybill_no)) {
    ewaybill_no = ""
  }
  var vehicleNo = data.vehicleno
  if (isblank(vehicleNo)) {
    vehicleNo = ""
  }

  var due_Date = DatetoDMY(data.due_date)
  var tax_payable = otherDetail.tax_reverse_charge

  var crdays = !isblank(data.crdays) && data.crdays !== "0" ? data.crdays : ""
  if (isblank(crdays)) {
    crdays = "On Receipt"
  } else {
    if (parseFloat(crdays) === 1) {
      crdays = "1 Day"
    } else {
      crdays = `${crdays} Days`
    }
  }

  var place_of_supply = ""
  if (!isblank(data.placeof_supply) && parseInt(data.placeof_supply) > 0) {
    place_of_supply = placeof_statedata.find((item) => parseInt(item.sid) === parseInt(data.placeof_supply))
    if (company_country_code === "103") {
      place_of_supply = `${capitalizeIfRequire(place_of_supply.state)} (${place_of_supply.code})`
    } else {
      place_of_supply = `${capitalizeIfRequire(place_of_supply.state)}`
    }
  }

  var agent = !isblank(agent_data) && agent_data.length > 0 ? agent_data[0].name : ""
  var transport = !isblank(transport_data) && transport_data.length > 0 ? transport_data[0].name : ""
  var lrno = !isblank(data.lrno) ? data.lrno : ""
  var lrdate = !isblank(data.lr_date) ? DatetoDMY(data.lr_date) : ""
  var godown = !isblank(data.godown_name) ? data.godown_name : ""

  return {
    crdb_label,
    crordb_no,
    crordb_date,
    crdbdate_label,
    InvoiceNoLabel,
    InvoiceDateLabel,
    mainHeadingText,
    invoice_title,
    billno,
    billDate,
    ewaybill_no,
    orderno,
    vehicleNo,
    crdays,
    due_Date,
    transport,
    lrdate,
    lrno,
    agent,
    godown,
    place_of_supply,
    tax_payable,
  }
}

export const getHeaderString = ({ printdata, getAccountSitesetting, user_data, meta, capitalizeIfRequire, dd_country_state, isCommercialCDN, isVoucherCDN }) => {
  const siteSetting = getAccountSitesetting(meta.type, user_data).filter((e) => e.id === meta.invoice_type)
  const invoice_header_center = getAccountSitesetting("invoice_header_center")

  const ewaybill = parseInt(getAccountSitesetting("ewaybill"))
  const contactDetail = parseInt(siteSetting[0].contactDetail)
  const shippingContactDetail = parseInt(siteSetting[0].shippingContactDetail)
  const Print_payment_term = parseInt(siteSetting[0].print_payment_terms)
  const Print_shipping_address = parseInt(siteSetting[0].Print_shipping_detail)
  const Print_billing_address = parseInt(siteSetting[0].print_billing_detail)
  const Print_transport = parseInt(siteSetting[0].showTransport)
  const Print_lr = parseInt(siteSetting[0].print_lr)
  const Print_agent = parseInt(siteSetting[0].print_agent_details)
  const Print_godown = parseInt(siteSetting[0].print_godown_details)
  const ewayBillDetail = ewaybill
  const Print_narration = parseInt(siteSetting[0].invoicePrintNarrationSelectedValue)
  const PrintTemplateNo = printdata.master_table_data[0].print_template_no
  const reverseCharge = getAccountSitesetting("reverseCharge", user_data)
  const logo_position = siteSetting[0].logo_position
  var customFieldParent = printdata.template_setting.setting.customFieldParent
  var PartyCustomField = printdata.custom_fields_data_party
  var MasterCustomField = printdata.custom_fields_data_master

  if (!isblank(PartyCustomField) && PartyCustomField.length > 0) {
    MasterCustomField = [...MasterCustomField, ...PartyCustomField]
  }

  var headerString = ""

  if (!isblank(printdata.base64_logo)) {
    headerString = printdata.template_setting.header
  } else {
    headerString = printdata.template_setting.header
  }

  const companyAddress = generateCompanyAddress({ data: printdata.cmpy_data[0], getAccountSitesetting, user_data, dd_country_state, capitalizeIfRequire })

  const invoiceHeader = generateInvoiceHeader({
    data: printdata.master_table_data[0],
    meta,
    siteSetting,
    agent_data: printdata.agent_data,
    transport_data: printdata.transport_data,
    otherDetail: printdata.otherDetail,
    placeof_statedata: printdata.placeof_statedata,
    capitalizeIfRequire,
    company_country_code: companyAddress.company_country_code,
  })

  const BillingAddressDetails = generateBillingAddress({ data: printdata.master_table_data[0].party_detail, capitalizeIfRequire, dd_country_state })
  const ShippingAddressDetails = generateShippingAddress({ data: printdata.master_table_data[0].shipp_address, capitalizeIfRequire, dd_country_state })

  let shipping_party = ""
  if (!isblank(ShippingAddressDetails.shipping_name)) {
    shipping_party = ShippingAddressDetails.shipping_name
  } else {
    shipping_party = BillingAddressDetails.billing_name
  }

  var narration = replacefunction(replaceBRtoNewLine(printdata.master_table_data[0].narration))

  headerString = replaceAllFnPrint(headerString, "{companyName}", companyAddress.company_name)
  headerString = replaceAllFnPrint(headerString, "{company_address1}", companyAddress.company_address1)
  headerString = replaceAllFnPrint(headerString, "{company_address2}", companyAddress.company_address2)
  headerString = replaceAllFnPrint(headerString, "{company_contact}", companyAddress.company_contact)
  headerString = replaceAllFnPrint(headerString, "{company_gstin}", companyAddress.company_gstin)
  headerString = replaceAllFnPrint(headerString, "{company_tax_type}", companyAddress.company_tax)
  headerString = replaceAllFnPrint(headerString, "{company_email}", companyAddress.company_email)
  headerString = replaceAllFnPrint(headerString, "{company_website}", companyAddress.company_website)
  headerString = replaceAllFnPrint(headerString, "{companyOtherNumbers}", companyAddress.company_other_numbers)
  headerString = replaceAllFnPrint(headerString, "{company_cin}", companyAddress.company_cin)
  headerString = replaceAllFnPrint(headerString, "{companyPAN}", companyAddress.company_pan)

  headerString = replaceAllFnPrint(headerString, "{crordb_no}", invoiceHeader.crordb_no)
  headerString = replaceAllFnPrint(headerString, "{crordb_date}", invoiceHeader.crordb_date)

  headerString = replaceAllFnPrint(headerString, "{crdb_label}", invoiceHeader.crdb_label)
  headerString = replaceAllFnPrint(headerString, "{crdbdate_label}", invoiceHeader.crdbdate_label)

  headerString = replaceAllFnPrint(headerString, "{invoice_label}", invoiceHeader.InvoiceNoLabel)
  headerString = replaceAllFnPrint(headerString, "{invoice_no}", invoiceHeader.billno)
  headerString = replaceAllFnPrint(headerString, "{invoice_date}", invoiceHeader.billDate)
  headerString = replaceAllFnPrint(headerString, "{date_label}", invoiceHeader.InvoiceDateLabel)

  headerString = replaceAllFnPrint(headerString, "{ewaybill_no}", invoiceHeader.ewaybill_no)
  headerString = replaceAllFnPrint(headerString, "{order_no}", invoiceHeader.orderno)
  headerString = replaceAllFnPrint(headerString, "{vehicleNo}", invoiceHeader.vehicleNo)
  headerString = replaceAllFnPrint(headerString, "{tax_payable}", invoiceHeader.tax_payable)
  headerString = replaceAllFnPrint(headerString, "{payment_terms}", invoiceHeader.crdays)
  headerString = replaceAllFnPrint(headerString, "{place_of_supply}", invoiceHeader.place_of_supply)
  headerString = replaceAllFnPrint(headerString, "{due_date}", invoiceHeader.due_Date)
  headerString = replaceAllFnPrint(headerString, "{transport}", invoiceHeader.transport)
  headerString = replaceAllFnPrint(headerString, "{lr_date}", invoiceHeader.lrdate)
  headerString = replaceAllFnPrint(headerString, "{lr_no}", invoiceHeader.lrno)
  headerString = replaceAllFnPrint(headerString, "{agent}", invoiceHeader.agent)
  headerString = replaceAllFnPrint(headerString, "{godown}", invoiceHeader.godown)

  if (meta.other_type === "salesreturn" || meta.type === "purchase_type_detail") {
    headerString = replaceAllFnPrint(headerString, "Details of Receiver (Billed to)", "Supplier (Bill from)")
    headerString = replaceAllFnPrint(headerString, "Details of Consignee (Shipped to)", "Supplier (Shipp from)")
  }

  headerString = replaceAllFnPrint(headerString, "{shipping_party_name}", shipping_party)
  headerString = replaceAllFnPrint(headerString, "{shipping_address}", ShippingAddressDetails.shipping_address1)
  headerString = replaceAllFnPrint(headerString, "{shipping_state}", ShippingAddressDetails.shipping_state)
  headerString = replaceAllFnPrint(headerString, "{shipping_party_gstin}", ShippingAddressDetails.shipping_gst_no)
  headerString = replaceAllFnPrint(headerString, "{shipping_party_contact}", BillingAddressDetails.billing_contact)
  headerString = replaceAllFnPrint(headerString, "{shipping_party_email}", BillingAddressDetails.billing_email)
  headerString = replaceAllFnPrint(headerString, "{shipping_party_pan}", BillingAddressDetails.billing_pan_no)
  headerString = replaceAllFnPrint(headerString, "{narration}", narration)

  if (meta.other_type === "purchase_order" || meta.other_type === "sales_order" || meta.other_type === "estimate" || meta.other_type === "getmystore" || meta.other_type === "getmycatalogue") {
    headerString = replaceAllFnPrint(headerString, "Billing Address", "To")
  }

  headerString = replaceAllFnPrint(headerString, "{party_name}", BillingAddressDetails.billing_name)
  headerString = replaceAllFnPrint(headerString, "{billing_address}", BillingAddressDetails.billing_address1)
  headerString = replaceAllFnPrint(headerString, "{billing_state}", BillingAddressDetails.billing_state)
  headerString = replaceAllFnPrint(headerString, "{place_of_supply}", invoiceHeader.place_of_supply)
  headerString = replaceAllFnPrint(headerString, "{party_contact}", BillingAddressDetails.billing_contact)
  headerString = replaceAllFnPrint(headerString, "{party_email}", BillingAddressDetails.billing_email)
  headerString = replaceAllFnPrint(headerString, "{party_gstin}", BillingAddressDetails.billing_gst_no)
  headerString = replaceAllFnPrint(headerString, "{party_pan}", BillingAddressDetails.billing_pan_no)

  headerString = JSON.parse(headerString)

  /* Remove Payment Terms According to Settings */

  if (Print_payment_term === 0 || isCommercialCDN === true || isVoucherCDN === true) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "pay_terms", newValue: {} })
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "due_date", newValue: {} })
  }

  if (Print_billing_address === 0) {
    let _newValue = { stack: [{ text: " ", style: "" }], border: [false, false, false, false] }
    if (PrintTemplateNo === invoiceTemplate.GST_Template_13) {
      _newValue = {}
    }
    headerString = jnestedReplace({
      input: headerString,
      search_key: "field_id",
      searchValue: "billing_address_block",
      newValue: _newValue,
    })
  }

  if (reverseCharge === 0) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "reverseCharge", newValue: {} })
  }

  if (Print_shipping_address === 0 || isCommercialCDN === true || isVoucherCDN === true) {
    let _newValue
    if (PrintTemplateNo === invoiceTemplate.GST_Template_6) {
      _newValue = { stack: [{ text: " ", style: "" }], border: [false, true, true, false] }
    } else {
      _newValue = { stack: [{ text: " ", style: "" }], border: [false, false, false, false] }
    }

    if (PrintTemplateNo === invoiceTemplate.GST_Template_10 || PrintTemplateNo === invoiceTemplate.GST_Template_3) {
      if (Print_billing_address !== 0) {
        let billingaddressnode = findNode(headerString, "field_id", "billing_address_block")
        billingaddressnode.border = [false, false, false, false]
        headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "billing_address_block", newValue: billingaddressnode })
      }
    }
    headerString = jnestedReplace({
      input: headerString,
      search_key: "field_id",
      searchValue: "shipping_detail_block",
      newValue: _newValue,
    })
  }

  if (Print_payment_term === 0 || isCommercialCDN === true || isVoucherCDN === true) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "pay_details", newValue: {} })
  }

  if (Print_narration !== 1) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "narration_details", newValue: {} })
  }

  if (isblank(BillingAddressDetails.billing_state)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "billing_state", newValue: {} })
  }

  if (isblank(BillingAddressDetails.billing_address1)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "billing_address", newValue: {} })
  }

  if (isblank(invoiceHeader.place_of_supply)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "place_of_supply", newValue: {} })
  }

  if (isblank(BillingAddressDetails.billing_contact)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "party_contact", newValue: {} })
  }

  if (isblank(BillingAddressDetails.billing_email)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "party_email", newValue: {} })
  }

  if (isblank(BillingAddressDetails.billing_gst_no)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "party_gst", newValue: {} })
  }
  if (isblank(BillingAddressDetails.billing_pan_no)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "party_pan", newValue: {} })
  }

  if (isblank(ShippingAddressDetails.shipping_state)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "shipping_state", newValue: {} })
  }

  if (contactDetail !== 1) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "party_contact", newValue: {} })
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "party_email", newValue: {} })
  }

  if (shippingContactDetail !== 1) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "shipping_party_contact", newValue: {} })
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "shipping_party_email", newValue: {} })
  }

  if (isblank(ShippingAddressDetails.shipping_gst_no)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "shipping_party_gstin", newValue: {} })
  }
  if (isblank(BillingAddressDetails.billing_pan_no)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "shipping_party_pan", newValue: {} })
  }

  if (Print_transport !== 1) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "trans_details", newValue: {} })
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "lr_details", newValue: {} })
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "eway_details", newValue: {} })
  }

  if (Print_lr !== 1) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "lr_details", newValue: {} })
  }

  if (ewayBillDetail !== 1) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "eway_details", newValue: {} })
  }

  if (Print_agent !== 1) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "agent_details", newValue: {} })
  }

  if (Print_godown !== 1) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "godown_details", newValue: {} })
  }

  if (
    meta.other_type === "purchase_invoice" ||
    meta.other_type === "purchase_order" ||
    meta.other_type === "sales_order" ||
    meta.other_type === "estimate" ||
    meta.other_type === "getmystore" ||
    meta.other_type === "getmycatalogue"
  ) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "eway_bill_no", newValue: {} })
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "vehicle_no", newValue: {} })
  }

  if (isblank(invoiceHeader.ewaybill_no)) {
    if (PrintTemplateNo !== invoiceTemplate.GST_Template_10) {
      headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "eway_bill_no", newValue: {} })
    }
  }

  if (isblank(invoiceHeader.vehicleNo)) {
    if (PrintTemplateNo !== invoiceTemplate.GST_Template_10) {
      headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "vehicle_no", newValue: {} })
    }
  }

  if (isblank(logo_position)) {
    logo_position = "b"
  }

  if (!isblank(printdata.base64_logo)) {
    if (PrintTemplateNo === invoiceTemplate.GST_Template_5 && invoice_header_center === 1) {
      const logoContentStructure = findNode(headerString, "field_id", "new_header_for_logo_replace")
      if (logoContentStructure.with_logo_center_detail) {
        headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "new_header_for_logo_replace", newValue: logoContentStructure.with_logo_center_detail })
      }
    }
    var Logo_url = `data:image/png;base64,${printdata.base64_logo}`
    headerString = JSON.stringify(headerString)
    headerString = replaceAllFn(headerString, '"{logo_width}"', parseFloat(printdata.template_setting.setting.logo_width))
    headerString = replaceAllFn(headerString, '"{logo_height}"', parseFloat(printdata.template_setting.setting.logo_height))
    headerString = JSON.parse(headerString)

    const logoStructure = findNode(headerString, "field_id", "logo")
    if (logoStructure) {
      if (checkCondition(logo_position, ["b", "l"])) {
        logoStructure.image = Logo_url
      } else {
        delete logoStructure.image
        delete logoStructure.fit
        logoStructure.stack = []
      }
      headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "logo", newValue: logoStructure })
    }

    if (PrintTemplateNo === invoiceTemplate.GST_Template_5 && invoice_header_center === 1) {
      const logoDualStructure = findNode(headerString, "field_id", "logo_dual")
      if (logoDualStructure) {
        logoDualStructure.image = Logo_url
        if (checkCondition(logo_position, ["b", "r"])) {
          logoDualStructure.image = Logo_url
        } else {
          delete logoDualStructure.image
          delete logoDualStructure.fit
          logoDualStructure.stack = []
        }
        headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "logo_dual", newValue: logoDualStructure })
      }
    }
  } else {
    if (PrintTemplateNo !== invoiceTemplate.GST_Template_10 && PrintTemplateNo !== invoiceTemplate.GST_Template_12 && PrintTemplateNo !== invoiceTemplate.GST_Template_13) {
      const logoContentStructure = findNode(headerString, "field_id", "new_header_for_logo_replace")
      headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "new_header_for_logo_replace", newValue: logoContentStructure.without_logo_content })
    } else {
      headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "logo", newValue: { width: 0, text: "" } })
    }
  }

  if (isblank(invoiceHeader.orderno)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "order_no", newValue: {} })
  }

  if (isblank(companyAddress.company_contact)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "company_contact", newValue: {} })
  }

  if (isblank(companyAddress.company_gstin)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "company_gstin", newValue: {} })
  }

  if (isblank(companyAddress.company_email)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "company_email", newValue: {} })
  }

  if (isblank(companyAddress.company_website)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "company_website", newValue: {} })
  }

  if (isblank(companyAddress.company_other_numbers)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "company_OtherNumbers", newValue: {} })
  }

  if (isblank(companyAddress.company_cin)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "company_cin", newValue: {} })
  }

  if (isblank(companyAddress.company_pan)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "company_pan", newValue: {} })
  }

  if (Print_transport !== 1) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "transport", newValue: {} })
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "lr_no", newValue: {} })
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "lr_date", newValue: {} })
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "lr_no_lr_date", newValue: {} })
  }

  if (Print_lr !== 1) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "lr_no", newValue: {} })
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "lr_date", newValue: {} })
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "lr_no_lr_date", newValue: {} })
  }

  if (Print_agent !== 1) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "agent", newValue: {} })
  }

  if (Print_godown !== 1) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "godown", newValue: {} })
  }

  if (isblank(invoiceHeader.crordb_no)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "crordb_details", newValue: {} })
  }

  if (isblank(invoiceHeader.crordb_no)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "crordb_no", newValue: {} })
  }

  if (isblank(invoiceHeader.crordb_date)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "crordb_date", newValue: {} })
  }

  if (isblank(invoiceHeader.due_Date)) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "due_date", newValue: {} })
  }

  var masterData = printdata.master_table_data[0]
  if (!isblank(PartyCustomField) && PartyCustomField.length > 0) {
    masterData = { ...masterData, ...printdata.user_details[0] }
  }

  if (customFieldParent === "header") {
    const customFieldStructure = findNode(headerString, "field_id", "customFieldNew")
    let customFieldBody = {}
    if (PrintTemplateNo !== invoiceTemplate.GST_Template_10) {
      customFieldBody = masterCustomFieldV1({ customFieldStructure, MasterCustomField, Values: masterData })
      headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "customFieldNew", newValue: customFieldBody, replacePath: customFieldStructure.replacePath })
    } else {
      var CustomFieldArr = masterCustomFieldV2(MasterCustomField, masterData) //get label and value
      customFieldBody = generatecustomFieldBodyV3(customFieldStructure, CustomFieldArr)
      if (customFieldBody.length > 0) {
        headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "customFieldNew", newValue: customFieldBody, replacePath: customFieldStructure.replacePath })
      } else {
        headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "customFieldNew", newValue: {} })
      }
    }
  }

  return {
    headerString,
    mainHeadingText: invoiceHeader.mainHeadingText,
    invoice_title: invoiceHeader.invoice_title,
    company_tax: companyAddress.company_tax,
    company_gstin: companyAddress.company_gstin,
    company_state_code: companyAddress.company_state_code,
  }
}

export const getFooterString = ({
  printdata,
  getAccountSitesetting,
  user_data,
  meta,
  capitalizeIfRequire,
  dd_country_state,
  hideRateAmount,
  Headers,
  detailColumnBfrItmCustomField,
  detailColumnCustomField,
  metaData,
  customAddIndex,
  customBfrAddIndex,
}) => {
  const siteSetting = getAccountSitesetting(meta.type, user_data).filter((e) => e.id === meta.invoice_type)
  const PrintTemplateNo = printdata.master_table_data[0].print_template_no
  const Party_gst_treatment = !isblank(printdata.user_details[0].gst_treatment) ? printdata.user_details[0].gst_treatment : ""
  const companyAddress = generateCompanyAddress({ data: printdata.cmpy_data[0], getAccountSitesetting, user_data, dd_country_state, capitalizeIfRequire })
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint", user_data)
  const currency = getAccountSitesetting("currency", user_data, "symbol")
  const pdfFontSizeSelectedValue = parseInt(siteSetting[0].pdfFontSizeSelectedValue)
  const Print_tds_details = parseInt(siteSetting[0].print_tds_details)
  const print_signature = parseInt(siteSetting[0].print_signature)
  const print_signature_box = parseInt(siteSetting[0].print_signature_box)
  const print_upi_qr = parseInt(siteSetting[0].print_upi_qr)
  const tax_print = parseInt(siteSetting[0].tax_print)
  const Print_narration = parseInt(siteSetting[0].invoicePrintNarrationSelectedValue)
  const Print_bank_details = parseInt(siteSetting[0].print_bank_details)
  let termConditions = siteSetting[0].termConditions
  const break_footer = parseInt(siteSetting[0].break_footer)
  const footer_text = siteSetting[0].footer_text
  const Grid_Line = parseInt(siteSetting[0].grid_line)

  var TotalHeight = !isblank(printdata.template_setting.setting.TotalHeight) ? parseFloat(printdata.template_setting.setting.TotalHeight) : 25
  var tax_payable = printdata.otherDetail.tax_reverse_charge

  if (!isblank(printdata.master_table_data[0].__cust_TermAndCondition)) {
    termConditions = printdata.master_table_data[0].__cust_TermAndCondition
  }

  if (isblank(pdfFontSizeSelectedValue)) {
    pdfFontSizeSelectedValue = 8
  }

  let isChallan = false
  if (meta && meta.otherDetail && meta.otherDetail.isChallan) {
    isChallan = true
  }

  var preparedby = !isblank(printdata.createdUser) && printdata.createdUser.length > 0 ? printdata.createdUser[0].fullname : ""
  var narration = replacefunction(replaceBRtoNewLine(printdata.master_table_data[0].narration))
  var footerString = printdata.template_setting.footer

  var footerData = []
  var grandTotal = ""
  footerString = JSON.parse(footerString)
  var grndTotalPrint = footerString.filter((e) => e.field_id === "grand_total_table").length > 0 ? true : false
  if (isChallan) {
    footerString = footerString.filter((e) => e.field_id !== "footerTable1" && e.field_id !== "grand_total_table")
  }
  footerString = JSON.stringify(footerString)

  if (!isblank(printdata.footerdetail) && printdata.footerdetail.length > 0) {
    let footerdetail = []
    if (meta.invoice_type === "export_invoice" && parseInt(Party_gst_treatment) === 5) {
      printdata.footerdetail.forEach((e, index) => {
        if (index === printdata.footerdetail.length - 2) {
          footerdetail.push(printdata.footerdetail[index + 1])
          footerdetail.push([e[0], `${currency}${amount_format(e[1])}`])
        } else if (index !== printdata.footerdetail.length - 1) {
          footerdetail.push([e[0], `${currency}${amount_format(e[1])}`])
        }
      })
    } else {
      printdata.footerdetail.forEach((e) => {
        footerdetail.push([e[0], `${currency}${amount_format(e[1])}`])
      })
    }
    if (PrintTemplateNo === invoiceTemplate.GST_Template_7) {
      footerdetail.forEach((item, index) => {
        var obj = [
          {
            text: index === footerdetail.length - 1 ? "Grand Total" : item[0],
            margin: [0, 5, 0, 5],
            bold: true,
            alignment: "right",
            fontSize: pdfFontSizeSelectedValue + 1,
            border: [false, false, false, true],
            color: index === footerdetail.length - 1 ? "#fff" : "#000",
            fillColor: index === footerdetail.length - 1 ? "#000" : "#fff",
          },
          {
            text: item[1],
            margin: [0, 5, 0, 5],
            bold: true,
            alignment: "right",
            fontSize: pdfFontSizeSelectedValue + 1,
            border: [false, false, false, true],
            color: index === footerdetail.length - 1 ? "#fff" : "#000",
            fillColor: index === footerdetail.length - 1 ? "#000" : "#fff",
          },
        ]
        footerData.push(obj)
      })
    } else if (PrintTemplateNo === invoiceTemplate.Template_3) {
      footerdetail.forEach((item, index) => {
        var obj = [
          {
            text: index === footerdetail.length - 1 ? "Grand Total" : item[0],
            margin: [0, 0, 0, 0],
            bold: index === 0 || index === footerdetail.length - 1,
            alignment: "right",
            fillColor: index === footerdetail.length - 1 ? "#dddddd" : "#fff",
          },
          {
            text: item[1],
            margin: [0, 0, 0, 0],
            bold: index === 0 || index === footerdetail.length - 1,
            alignment: "right",
            fillColor: index === footerdetail.length - 1 ? "#dddddd" : "#fff",
          },
        ]
        if (index === footerdetail.length - 1) {
          if (grndTotalPrint) {
            grandTotal = obj[1].text
          } else {
            footerData.push(obj)
          }
        } else {
          footerData.push(obj)
        }
      })
    } else if (PrintTemplateNo === invoiceTemplate.GST_Template_12) {
      footerdetail.forEach((item, index) => {
        var obj = [
          {
            text: index === footerdetail.length - 1 ? "Grand Total" : item[0],
            margin: [0, 5, 0, 5],
            bold: true,
            alignment: "right",
            fontSize: pdfFontSizeSelectedValue + 1,
            border: [false, false, false, true],
            color: index === footerdetail.length - 1 ? "#fff" : "#000",
            fillColor: index === footerdetail.length - 1 ? "#1C54B2" : "#fff",
          },
          {
            text: item[1],
            margin: [0, 5, 0, 5],
            bold: true,
            alignment: "right",
            fontSize: pdfFontSizeSelectedValue + 1,
            border: [false, false, false, true],
            color: index === footerdetail.length - 1 ? "#fff" : "#000",
            fillColor: index === footerdetail.length - 1 ? "#1C54B2" : "#fff",
          },
        ]
        footerData.push(obj)
      })
    } else {
      footerdetail.forEach((item, index) => {
        var obj = [
          {
            text: index === footerdetail.length - 1 ? "Grand Total" : item[0],
            margin: [0, 0, 0, 0],
            border: [false, false, false, false],
            bold: index === 0 || index === footerdetail.length - 1,
            alignment: "right",
            fontSize: index === 0 || index === footerdetail.length - 1 ? pdfFontSizeSelectedValue + 1 : pdfFontSizeSelectedValue,
          },
          {
            text: item[1],
            margin: [0, 0, 0, 0],
            border: [false, false, false, false],
            bold: index === 0 || index === footerdetail.length - 1,
            alignment: "right",
            fontSize: index === 0 || index === footerdetail.length - 1 ? pdfFontSizeSelectedValue + 1 : pdfFontSizeSelectedValue,
          },
        ]
        if (index === footerdetail.length - 1) {
          if (grndTotalPrint) {
            grandTotal = obj[1].text
          } else {
            footerData.push(obj)
          }
        } else {
          footerData.push(obj)
        }
      })

      // Print TDS details as per setting
      if (Print_tds_details === 1 && printdata.master_table_data[0].tds_amt !== "0.00") {
        var obj = [
          {
            text: `Less TDS to be deducted @ ${printdata.master_table_data[0].tds_percent}%`,
            margin: [0, 0, 0, 0],
            border: [false, false, false, false],
            bold: false,
            alignment: "right",
            fontSize: pdfFontSizeSelectedValue,
          },
          {
            text: `${currency}${amount_format(printdata.master_table_data[0].tds_amt)}`,
            margin: [0, 0, 0, 0],
            border: [false, false, false, false],
            bold: false,
            alignment: "right",
            fontSize: pdfFontSizeSelectedValue,
          },
        ]
        footerData.push(obj)
        // Print net payable amount after tds

        const netpay = numForCalc(printdata.master_table_data[0].netamt) - numForCalc(printdata.master_table_data[0].tds_amt)
        obj = [
          {
            text: "Net Payable Amount",
            margin: [0, 0, 0, 0],
            border: [false, false, false, false],
            bold: false,
            alignment: "right",
            fontSize: pdfFontSizeSelectedValue,
          },
          {
            text: `${currency}${amount_format(netpay)}`,
            margin: [0, 0, 0, 0],
            border: [false, false, false, false],
            bold: false,
            alignment: "right",
            fontSize: pdfFontSizeSelectedValue,
          },
        ]
        footerData.push(obj)
      }
    }
  }

  footerString = JSON.parse(footerString)
  footerString = jnestedReplace({ input: footerString, search_key: "field_id", searchValue: "irn_number", newValue: {} })
  footerString = JSON.stringify(footerString)

  if (Print_narration !== 1 || isblank(narration)) {
    footerString = JSON.parse(footerString)
    footerString = jnestedReplace({ input: footerString, search_key: "field_id", searchValue: "narration", newValue: {} })
    footerString = JSON.stringify(footerString)
  }

  footerString = replaceAllFnPrint(footerString, "{narration}", narration)
  footerString = replaceAllFnPrint(footerString, "{narration}", narration)
  footerString = replaceAllFnPrint(footerString, "{preparedby}", preparedby)
  footerString = replaceAllFnPrint(footerString, "{grand_total}", grandTotal)
  footerString = replaceAllFnPrint(footerString, "{total_amount_word}", printdata.otherDetail.netamount_words)

  if (Print_bank_details !== 1) {
    footerString = JSON.parse(footerString)
    footerString = jnestedReplace({ input: footerString, search_key: "field_id", searchValue: "bank_detail_heading", newValue: {} })
    footerString = JSON.stringify(footerString)
    footerString = replaceAllFnPrint(footerString, "{bank_detail1}", "")
    footerString = replaceAllFnPrint(footerString, "{bank_detail2}", "")
  } else {
    if (print_upi_qr === 1) {
      if (isblankBankDetail(companyAddress.company_banker1)) {
        footerString = JSON.parse(footerString)
        footerString = jnestedReplace({ input: footerString, search_key: "field_id", searchValue: "bank_detail_1", newValue: {} })
        footerString = JSON.stringify(footerString)
      } else {
        footerString = replaceAllFn(footerString, '"{bank_detail1}"', generateCompanyBankDetailWithQR(companyAddress.company_banker1, 1))
      }

      if (isblankBankDetail(companyAddress.company_banker2)) {
        footerString = JSON.parse(footerString)
        footerString = jnestedReplace({ input: footerString, search_key: "field_id", searchValue: "bank_detail_2", newValue: {} })
        footerString = JSON.stringify(footerString)
      } else {
        footerString = replaceAllFn(footerString, '"{bank_detail2}"', generateCompanyBankDetailWithQR(companyAddress.company_banker2, 2))
      }
    } else {
      footerString = replaceAllFn(footerString, '"{bank_detail1}"', generateCompanyBankDetail(companyAddress.company_banker1, 1))
      footerString = replaceAllFn(footerString, '"{bank_detail2}"', generateCompanyBankDetail(companyAddress.company_banker2, 2))
    }
  }

  footerString = replaceAllFnPrint(footerString, "{termsAndconditions}", replacefunction(termConditions))

  if (break_footer === 1) {
    footerString = replaceAllFn(footerString, '"{breakStatus}"', true)
  } else {
    footerString = replaceAllFn(footerString, '"{breakStatus}"', false)
  }

  footerString = replaceAllFnPrint(footerString, "{footer_text}", replacefunction(footer_text))
  footerString = replaceAllFn(footerString, '"{footerTable2Width}"', 250)
  footerString = replaceAllFnPrint(footerString, "{companyName}", companyAddress.company_name)
  footerString = replaceAllFnPrint(footerString, "{tot_amt}", amount_format(printdata.master_table_data[0].totamt))
  footerString = replaceAllFnPrint(footerString, "{tot_taxable_amt}", amount_format(printdata.master_table_data[0].taxable_amt_tot))
  footerString = replaceAllFnPrint(footerString, "{tax_payable}", tax_payable)

  if (isblank(termConditions)) {
    footerString = JSON.parse(footerString)
    footerString = jnestedReplace({ input: footerString, search_key: "field_id", searchValue: "terms", newValue: {} })
    footerString = JSON.stringify(footerString)
  }

  if (isblank(footer_text)) {
    footerString = JSON.parse(footerString)
    footerString = jnestedReplace({ input: footerString, search_key: "field_id", searchValue: "footer_text", newValue: {} })
    footerString = JSON.stringify(footerString)
  }

  if (isblankBankDetail(companyAddress.company_banker1, companyAddress.company_banker2)) {
    if (PrintTemplateNo !== invoiceTemplate.GST_Template_12) {
      footerString = JSON.parse(footerString)
      footerString = jnestedReplace({ input: footerString, search_key: "field_id", searchValue: "bank_detail_heading", newValue: {} })
      footerString = JSON.stringify(footerString)
    } else {
      footerString = JSON.parse(footerString)
      footerString = jnestedReplace({ input: footerString, search_key: "field_id", searchValue: "bank_details", newValue: {} })
      footerString = JSON.stringify(footerString)
    }
  }

  if (tax_print === 1 || tax_print === 2) {
    footerString = replaceAllFnPrint(footerString, "{tot_igst}", amount_format(printdata.master_table_data[0].igst_total))
    footerString = replaceAllFnPrint(footerString, "{tot_sgst}", amount_format(printdata.master_table_data[0].sgst_total))
    footerString = replaceAllFnPrint(footerString, "{tot_cgst}", amount_format(printdata.master_table_data[0].cgst_total))
  } else {
    footerString = replaceAllFnPrint(footerString, "{tot_igst}", "")
    footerString = replaceAllFnPrint(footerString, "{tot_sgst}", "")
    footerString = replaceAllFnPrint(footerString, "{tot_cgst}", "")
    footerString = replaceAllFnPrint(footerString, "{tot_gst}", "")
  }

  footerString = replaceAllFnPrint(footerString, "{tot_discount}", "")
  footerString = replaceAllFnPrint(footerString, "{tot_qty}", amount_formatDecimal(printdata.master_table_data[0].totqty, inventory_decimalpoint))
  footerString = replaceAllFnPrint(footerString, "{company_gstno}", companyAddress.company_gstin)
  footerString = JSON.parse(footerString)

  if (print_signature_box === 1) {
    if (!isblank(printdata.base64_signature) && print_signature === 1) {
      var Signature_url = `data:image/png;base64,${printdata.base64_signature}`
      var Signature = {
        field_id: "signature",
        image: Signature_url,
        fit: [100, 40],
        border: [false, false, false, false],
        margin: [PrintTemplateNo === invoiceTemplate.GST_Template_6 ? 1 : 5, 1, 0, 1],
        alignment: PrintTemplateNo === invoiceTemplate.GST_Template_6 ? "center" : "right",
      }

      footerString = jnestedReplace({ input: footerString, search_key: "field_id", searchValue: "signature", newValue: Signature })
    }
  } else {
    footerString = jnestedReplace({ input: footerString, search_key: "field_id", searchValue: "signaturebox", newValue: {} })
  }

  if (hideRateAmount) {
    footerString = footerString.filter((e) => e.field_id !== "footerTable1")
  } else {
    footerString = footerString.map((item) => {
      if (item.field_id === "footerTable1") {
        if (PrintTemplateNo !== invoiceTemplate.GST_Template_10 && PrintTemplateNo !== invoiceTemplate.Template_3 && PrintTemplateNo !== invoiceTemplate.GST_Template_13) {
          item.table.heights = ["*", "*"]
          if (PrintTemplateNo !== invoiceTemplate.GST_Template_5 && PrintTemplateNo !== invoiceTemplate.GST_Template_7 && PrintTemplateNo !== invoiceTemplate.GST_Template_12) {
            item.table.widths = ["*", "*"]
          }
        }

        item.unbreakable = true
        item.table.body[0] = item.table.body[0].map((it) => {
          let _width = ["*", "*"]
          if (PrintTemplateNo === invoiceTemplate.GST_Template_13) {
            _width = ["*", "auto"]
          }

          if (it.field_id === "taxCalc") {
            it.table = {
              widths: _width,
              body: [...JSON.parse(JSON.stringify(footerData))],
            }
          }
          return it
        })
      }
      return item
    })
  }

  footerString = footerString.map((item, index) => {
    const updatedItem = { ...item }
    if (index !== footerString.length - 1) {
      updatedItem.margin = [0, 0, 0, 0]
    }
    if (updatedItem.hasOwnProperty("table")) {
      updatedItem.table.body = updatedItem.table.body.map((it) => {
        var updatedBody = [...it]
        updatedBody = updatedBody.map((row) => {
          // delete row.border
          return row
        })
        return updatedBody
      })
    }
    return updatedItem
  })

  return { footerString }
}

export const getBodyString = ({
  printdata,
  getAccountSitesetting,
  user_data,
  meta,
  hideRateAmount,
  detailColumnCustomField,
  detailColumnBfrItmCustomField,
  functions,
  company_tax,
  company_state_code,
  isCommercialCDN,
  isVoucherCDN,
}) => {
  var bodyString = JSON.parse(printdata.template_setting.body)
  var customFieldParent = printdata.template_setting.setting.customFieldParent
  var PartyCustomField = printdata.custom_fields_data_party
  // var ProductCustomField = printdata.custom_fields_data_item
  var MasterCustomField = printdata.custom_fields_data_master

  if (!isblank(PartyCustomField) && PartyCustomField.length > 0) {
    MasterCustomField = [...MasterCustomField, ...PartyCustomField]
  }

  // if (!isblank(ProductCustomField) && ProductCustomField.length > 0) {
  //   MasterCustomField = [...MasterCustomField, ...ProductCustomField]
  // }

  var ExpenseAccount = printdata.expenseaccount

  var Headers = []
  var widthArray = []
  var otherDataLength = 0
  var customAddIndex = 0
  var customBfrAddIndex = 0
  var print_item_photo = 0

  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint", user_data)
  const siteSetting = getAccountSitesetting(meta.type, user_data).filter((e) => e.id === meta.invoice_type)
  const parameterized = parseInt(siteSetting[0].parameterizedQty) === 1 ? true : false
  const print_discount = parseInt(siteSetting[0].print_discount)
  //const discount_invoice = parseInt(siteSetting[0].discount_invoice)
  //const multi_discount = parseInt(siteSetting[0].multi_discount)
  const unit_print = parseInt(siteSetting[0].unit_print)
  const tax_print = parseInt(siteSetting[0].tax_print)
  const Print_hsn = parseInt(siteSetting[0].hsnSelectedValue)
  const Print_qty = parseInt(siteSetting[0].quantity_print)
  const Print_mrp = parseInt(siteSetting[0].print_mrp)
  const Print_sku = parseInt(siteSetting[0].printSKUNO)
  const Print_netamount = parseInt(siteSetting[0].print_net_amount)
  const Grid_Line = parseInt(siteSetting[0].grid_line)
  const pageSize = getPageSize(siteSetting[0].template_size)
  const fontStyle = siteSetting[0].fontStyle
  const Print_Detail_narration = parseInt(siteSetting[0].detailNarration)
  const Print_product_group = parseInt(siteSetting[0].product_group_print)
  const fixSingleRow = parseInt(siteSetting[0].fixSingleRow)
  const pdfFontSizeSelectedValue = parseInt(siteSetting[0].pdfFontSizeSelectedValue)
  const PrintTemplateNo = printdata.master_table_data[0].print_template_no

  const discount_invoice = printdata.master_table_data[0].__cust_discountInvoice
  const multi_discount = printdata.master_table_data[0].__cust_multiDiscount ? printdata.master_table_data[0].__cust_multiDiscount : 0

  let isChallan = false
  if (meta && meta.otherDetail && meta.otherDetail.isChallan) {
    isChallan = true
  }

  if (isblank(fixSingleRow)) {
    fixSingleRow = !isblank(printdata.template_setting.setting.fixSingleRow) ? parseFloat(printdata.template_setting.setting.fixSingleRow) : 24
  }

  var masterData = printdata.master_table_data[0]
  if (!isblank(PartyCustomField) && PartyCustomField.length > 0) {
    masterData = { ...masterData, ...printdata.user_details[0] }
  }

  // if (!isblank(ProductCustomField) && ProductCustomField.length > 0) {
  //   masterData = { ...masterData, ...printdata.details_table_data[0] }
  // }

  if (customFieldParent === "body") {
    var CustomFieldArr = masterCustomField({ customField: MasterCustomField, Values: masterData, alignment: "left", type: "blankValueAccept", valueBold: true })
    if (CustomFieldArr.length === 0) {
      bodyString = bodyString.filter((e) => e.field_id !== "customField")
    }
    bodyString = bodyString.map((item) => {
      const updatedItem = { ...item }
      if (item.field_id === "customField") {
        if (PrintTemplateNo === invoiceTemplate.GST_Template_10 || PrintTemplateNo === invoiceTemplate.Template_3) {
          updatedItem.table.body = generatecustomFieldBodyV2(updatedItem.table.widths.length, CustomFieldArr)
          otherDataLength = customFirstStackLength(updatedItem.table.widths.length, CustomFieldArr)
        } else {
          var topBorder = !isblank(updatedItem.table.body[0][0].border) ? updatedItem.table.body[0][0].border[1] : true
          updatedItem.table.body = generatecustomFieldBody({ numberOfStack: updatedItem.table.widths.length, stackData: CustomFieldArr, border: true, topBorder })
          otherDataLength = customFirstStackLength(updatedItem.table.widths.length, CustomFieldArr)
        }
      }
      return updatedItem
    })
  }

  bodyString.forEach((item) => {
    if (item.field_id === "detailTable") {
      Headers = item.table.body[0]
      if (pageSize === "A6") {
        widthArray = [20, "*", 50, 100, 50, 35, 25, 36, 50, 50, 50, 50, 50]
      } else {
        widthArray = item.table.widths
      }

      if (print_item_photo !== 1) {
        Headers = Headers.filter((item, index) => {
          if (item.field_id !== "photo") {
            return true
          } else {
            widthArray = widthArray.filter((it, widthIndex) => widthIndex !== index)
            return false
          }
        })
      }

      if (Print_mrp !== 1) {
        Headers = Headers.filter((item, index) => {
          if (item.field_id !== "mrp") {
            return true
          } else {
            widthArray = widthArray.filter((it, widthIndex) => widthIndex !== index)
            return false
          }
        })
      }

      if (Print_product_group !== 1) {
        Headers = Headers.filter((item, index) => {
          if (item.field_id !== "itemgroup_name") {
            return true
          } else {
            widthArray = widthArray.filter((it, widthIndex) => widthIndex !== index)
            return false
          }
        })
      }

      if (Print_Detail_narration !== 1) {
        Headers = Headers.filter((item, index) => {
          if (item.field_id !== "detail_narration") {
            return true
          } else {
            widthArray = widthArray.filter((it, widthIndex) => widthIndex !== index)
            return false
          }
        })
      }

      if (Print_hsn !== 1 || isCommercialCDN === true) {
        Headers = Headers.filter((item, index) => {
          if (item.field_id !== "hsn_code") {
            return true
          } else {
            widthArray = widthArray.filter((it, widthIndex) => widthIndex !== index)
            return false
          }
        })
      }

      if (Print_qty === 0 || isCommercialCDN === true) {
        Headers = Headers.filter((item, index) => {
          if (item.field_id !== "qty") {
            return true
          } else {
            widthArray = widthArray.filter((it, widthIndex) => widthIndex !== index)
            return false
          }
        })
      }

      if (isCommercialCDN === true) {
        Headers = Headers.filter((item, index) => {
          if (item.field_id !== "rate") {
            return true
          } else {
            widthArray = widthArray.filter((it, widthIndex) => widthIndex !== index)
            return false
          }
        })
      }

      if (Print_sku !== 1) {
        Headers = Headers.filter((item, index) => {
          if (item.field_id !== "sku") {
            return true
          } else {
            widthArray = widthArray.filter((it, widthIndex) => widthIndex !== index)
            return false
          }
        })
      }

      if (unit_print !== 1) {
        Headers = Headers.filter((item, index) => {
          if (item.field_id !== "unitname") {
            return true
          } else {
            widthArray = widthArray.filter((it, widthIndex) => widthIndex !== index)
            return false
          }
        })
      }

      if (unit_print === 3) {
        Headers.forEach((item, index) => {
          if (item.field_id === "qty") {
            widthArray[index] = numForCalc(widthArray[index]) + 30
          }
        })
      }

      if (hideRateAmount || isChallan) {
        var removeIndex = []
        Headers = Headers.filter((item, index) => {
          if (item.field_id !== "rate" && item.field_id !== "discount" && item.field_id !== "sgst_amt" && item.field_id !== "cgst_amt" && item.field_id !== "igst_amt" && item.field_id !== "amt") {
            return true
          } else {
            removeIndex.push(index)
            return false
          }
        })
        widthArray = widthArray.filter((it, widthIndex) => !removeIndex.includes(widthIndex))
      }

      if (tax_print === 0 || isblank(tax_print) || company_tax !== "GSTIN" || isCommercialCDN) {
        var removeIndex = []
        Headers = Headers.filter((item, index) => {
          if (item.field_id !== "sgst_amt" && item.field_id !== "cgst_amt" && item.field_id !== "igst_amt" && item.field_id !== "taxable_amt") {
            return true
          } else {
            removeIndex.push(index)
            return false
          }
        })
        widthArray = widthArray.filter((it, widthIndex) => !removeIndex.includes(widthIndex))
      } else {
        var removeIndex = []
        Headers = Headers.filter((item, index) => {
          if (parseFloat(company_state_code) === parseFloat(printdata.placeof_supply_id) && meta.invoice_type !== "export_invoice") {
            if (item.field_id !== "igst_amt") {
              return true
            } else {
              removeIndex.push(index)
              return false
            }
          } else {
            if (item.field_id !== "sgst_amt" && item.field_id !== "cgst_amt" && item.field_id !== "gst_amt") {
              return true
            } else {
              removeIndex.push(index)
              return false
            }
          }
        })
        widthArray = widthArray.filter((it, widthIndex) => !removeIndex.includes(widthIndex))

        if (tax_print === 3) {
          removeIndex = []
          Headers = Headers.filter((item, index) => {
            if (item.field_id !== "sgst_amt") {
              return true
            } else {
              removeIndex.push(index)
              return false
            }
          })
          widthArray = widthArray.filter((it, widthIndex) => !removeIndex.includes(widthIndex))
        }
      }

      if (detailColumnBfrItmCustomField.length > 0 || detailColumnCustomField.length > 0) {
        var finalHeader = []
        var finalWidthArray = []
        if (detailColumnBfrItmCustomField.length > 0) {
          var customAdded = false
          Headers.forEach((e, index) => {
            if (e.field_id === "detail_narration" && customAdded === false) {
              customAdded = true
              customBfrAddIndex = index
              detailColumnBfrItmCustomField.forEach((it) => {
                var newCustomField = {
                  bold: true,
                  field_id: it.name,
                  fillColor: e.fillColor,
                  alignment: parseInt(it.dataType) === cF.Decimal ? "right" : "left",
                  text: it.labelName,
                }
                finalHeader.push(newCustomField)
              })
              finalHeader.push(e)
            } else if (e.field_id === "itemname" && customAdded === false) {
              customAdded = true
              customBfrAddIndex = index
              detailColumnBfrItmCustomField.forEach((it) => {
                var newCustomField = {
                  bold: true,
                  field_id: it.name,
                  fillColor: e.fillColor,
                  alignment: parseInt(it.dataType) === cF.Decimal ? "right" : "left",
                  text: it.labelName,
                  calculateTotal: it.calculateTotal,
                }
                finalHeader.push(newCustomField)
              })
              finalHeader.push(e)
            } else {
              finalHeader.push(e)
            }
          })
          Headers = finalHeader
          widthArray.forEach((e, index) => {
            if (index === customBfrAddIndex) {
              detailColumnBfrItmCustomField.forEach((it) => {
                const width = parseFloat(it.width) === 0 ? "auto" : parseFloat(it.width)
                finalWidthArray.push(width)
              })
              finalWidthArray.push(e)
            } else {
              finalWidthArray.push(e)
            }
          })
          widthArray = finalWidthArray
        }

        finalHeader = []
        finalWidthArray = []

        if (detailColumnCustomField.length > 0) {
          var customAdded = false
          Headers.forEach((e, index) => {
            if (e.field_id === "detail_narration" && customAdded === false) {
              customAdded = true
              customAddIndex = index
              finalHeader.push(e)
              detailColumnCustomField.forEach((it) => {
                var newCustomField = {
                  bold: true,
                  field_id: it.name,
                  fillColor: e.fillColor,
                  color: e.color,
                  style: e.style,
                  alignment: parseInt(it.dataType) === cF.Decimal ? "right" : "left",
                  text: it.labelName,
                }
                finalHeader.push(newCustomField)
              })
            } else if (e.field_id === "itemname" && customAdded === false) {
              customAdded = true
              customAddIndex = index
              finalHeader.push(e)
              detailColumnCustomField.forEach((it) => {
                var newCustomField = {
                  bold: true,
                  field_id: it.name,
                  fillColor: e.fillColor,
                  color: e.color,
                  style: e.style,
                  alignment: parseInt(it.dataType) === cF.Decimal ? "right" : "left",
                  text: it.labelName,
                  calculateTotal: it.calculateTotal,
                }
                finalHeader.push(newCustomField)
              })
            } else {
              finalHeader.push(e)
            }
          })
          Headers = finalHeader
          widthArray.forEach((e, index) => {
            if (index === customAddIndex) {
              finalWidthArray.push(e)
              detailColumnCustomField.forEach((it) => {
                const width = parseFloat(it.width) === 0 ? "auto" : parseFloat(it.width)
                finalWidthArray.push(width)
              })
            } else {
              finalWidthArray.push(e)
            }
          })
          widthArray = finalWidthArray
        }
      }

      const amtStyle = Headers.find((header) => header.field_id === "amt")?.style
      const amtIndex = Headers.findIndex((header) => header.field_id === "amt")
      const amtWidth = widthArray[amtIndex]

      if (discount_invoice === 1 && isCommercialCDN === false && isVoucherCDN === false) {
        if (discount_invoice === 1 && multi_discount === 0) {
          if (Print_netamount === 1 || Print_netamount === 2) {
            widthArray[amtIndex] = "auto"
          }

          if (Print_netamount === 0) {
            // Dont print net amount field with discount before amount field
            Headers.splice(amtIndex, 0, { field_id: "discount", text: "Disc.", bold: true, fillColor: Headers[0].fillColor, style: amtStyle })
            widthArray.splice(amtIndex, 0, "auto")
          } else {
            // Print net amount field with discount after amount field
            Headers.splice(amtIndex + 1, 0, { field_id: "discount", text: "Disc.", bold: true, fillColor: Headers[0].fillColor, style: amtStyle })
            widthArray.splice(amtIndex + 1, 0, "auto")
          }
        }

        if (discount_invoice === 1 && multi_discount === 1) {
          const discountKeys = Object.keys(printdata.details_table_data[0]).filter((key) => key.startsWith("__cust_itemDiscount_"))
          discountKeys.sort((a, b) => {
            const discountLevelA = ExpenseAccount.find((account) => `__cust_itemDiscount_${account.code}` === a)?.discount_level || 0
            const discountLevelB = ExpenseAccount.find((account) => `__cust_itemDiscount_${account.code}` === b)?.discount_level || 0
            return parseInt(discountLevelA) - parseInt(discountLevelB)
          })
          let runningIndex = amtIndex
          discountKeys.forEach((key, index) => {
            const discountHeader = {
              field_id: key,
              text: `Disc.${index + 1}`,
              bold: true,
              fillColor: Headers[0].fillColor,
              style: amtStyle,
              alignment: "right",
            }
            if (Print_netamount === 0) {
              Headers.splice(runningIndex, 0, discountHeader)
              widthArray.splice(runningIndex, 0, "auto")
            } else {
              Headers.splice(runningIndex + 1, 0, discountHeader)
              widthArray.splice(runningIndex + 1, 0, "auto")
            }
            runningIndex++
          })
        }

        // Add NET AMOUNT field based on settings
        if (Print_netamount === 1) {
          // Remove amt field and add netamount field
          Headers = Headers.filter((header) => header.field_id !== "amt")
          widthArray = widthArray.filter((_, index) => index !== amtIndex)
          Headers.push({ field_id: "netamt", text: "Net Amount", bold: true, fillColor: Headers[0].fillColor, style: amtStyle })
          widthArray.push(amtWidth)
        } else if (Print_netamount === 2) {
          // Ensure netamount fields are present
          const netAmountField = Headers.find((header) => header.field_id === "netamt")
          if (!netAmountField) {
            Headers.push({ field_id: "netamt", text: "Net Amount", bold: true, fillColor: Headers[0].fillColor, style: amtStyle })
            widthArray.push(amtWidth)
          }
        }
      }

      if (tax_print === 3) {
        Headers = Headers.map((h_item) => {
          const updatedItem = { ...h_item }
          if (updatedItem.field_id === "cgst_amt") {
            updatedItem.text = "GST"
          }
          return updatedItem
        })
      }
    }
  })

  // fix width
  Headers.forEach((item, index) => {
    if (
      item.field_id === "sr_no" ||
      item.field_id === "hsn_code" ||
      item.field_id === "qty" ||
      item.field_id === "mrp" ||
      item.field_id === "rate" ||
      item.field_id === "discount" ||
      item.field_id === "sgst_amt" ||
      item.field_id === "igst_amt" ||
      item.field_id === "cgst_amt"
    ) {
      widthArray[index] = "auto"
    }
  })

  var irn = printdata.master_table_data[0].irn
  var fixRow = calculateFixRowV1({ fixSingleRow, otherDataLength, pdfFontSize: pdfFontSizeSelectedValue, irn })

  var metaData = {
    Headers,
    DetailGrid: printdata.details_table_data,
    DetailCustomField: printdata.custom_fields_data,
    MasterTableData: printdata.master_table_data[0],
    fixRow,
    Print_Detail_narration,
    Print_product_group,
    fontStyle,
    Print_hsn,
    Print_qty,
    Print_mrp,
    Print_sku,
    user_data,
    parameterized,
    unit_print,
    print_discount,
    Print_netamount,
    tax_print,
    widthArray,
    print_item_photo,
    discount_invoice,
    multi_discount,
  }

  let Values = []

  if (PrintTemplateNo === invoiceTemplate.GST_Template_13) {
    Headers = [
      [
        { text: "Item", style: "tableHeader", alignment: "left" },
        { text: "Rate", style: "tableHeader", alignment: "right" },
        { text: "Qty", style: "tableHeader", alignment: "right" },
        { text: "Amount", style: "tableHeader", alignment: "right" },
      ],
      [{ text: "Hsn", style: "tableHeader", alignment: "right" }, { text: "GST%", style: "tableHeader", alignment: "right" }, {}, {}],
    ]

    Values = generateValueByHeaderThermal(functions, metaData)

    // THERMAL PRINT
    bodyString = bodyString.map((item) => {
      const updatedItem = { ...item }
      if (item.field_id === "detailTable") {
        updatedItem.table = {
          headerRows: 2,
          widths: widthArray,
          body: [...Headers, ...Values],
        }
      }
      return updatedItem
    })
  } else {
    Values = generateValueByHeader(functions, metaData)

    // calculate footer total
    var TableTotal = ""
    if (!(PrintTemplateNo === invoiceTemplate.GST_Template_7 || PrintTemplateNo === invoiceTemplate.GST_Template_12)) {
      metaData.MasterData = printdata.master_table_data[0]
      metaData.Grid_Line = 1
      metaData.TotalRow = cloneObj(Headers)

      if (detailColumnBfrItmCustomField.length > 0) {
        metaData.TotalRow.forEach((item1, index) => {
          if (index === customBfrAddIndex) {
            detailColumnBfrItmCustomField.forEach((it) => {
              const rowIndex = Headers.findIndex((header) => header.field_id === it.name)
              if (parseInt(it.dataType) === cF.Decimal && it.calculateTotal === "1") {
                var Total = 0
                printdata.details_table_data.forEach((detailitem, index) => {
                  Total = Total + parseFloat(getNumberonlyDecimal(detailitem[it.name], it.decimalPoint))
                })
                metaData.TotalRow[rowIndex].text = parseFloat(Total) === 0 ? "" : amount_formatDecimal(Total, it.decimalPoint)
              } else {
                metaData.TotalRow[rowIndex].text = ""
              }
            })
          }
        })
      }

      if (detailColumnCustomField.length > 0) {
        metaData.TotalRow.forEach((item1, index) => {
          if (index === customAddIndex) {
            detailColumnCustomField.forEach((it) => {
              const rowIndex = Headers.findIndex((header) => header.field_id === it.name)
              if (parseInt(it.dataType) === cF.Decimal && it.calculateTotal === "1") {
                var Total = 0
                printdata.details_table_data.forEach((detailitem, index) => {
                  Total = Total + parseFloat(getNumberonlyDecimal(detailitem[it.name], it.decimalPoint))
                })
                metaData.TotalRow[rowIndex].text = parseFloat(Total) === 0 ? "" : amount_formatDecimal(Total, it.decimalPoint)
              } else {
                metaData.TotalRow[rowIndex].text = ""
              }
            })
          }
        })
      }

      if (metaData.discount_invoice === 1 && metaData.multi_discount === 1 && isCommercialCDN === false && isVoucherCDN === false) {
        const discountKeys = Headers.filter((header) => header.field_id.startsWith("__cust_itemDiscount_")).map((header) => header.field_id)
        discountKeys.forEach((key, index) => {
          const rowIndex = Headers.findIndex((header) => header.field_id === key)
          if (print_discount !== 1) {
            var Total = 0
            printdata.details_table_data.forEach((detailitem, index) => {
              Total = Total + parseFloat(getNumberonlyDecimal(detailitem[key], 2))
            })
            metaData.TotalRow[rowIndex].text = parseFloat(Total) === 0 ? "" : amount_formatDecimal(Total, 2)
          } else {
            metaData.TotalRow[rowIndex].text = ""
          }
        })
      }

      TableTotal = generateTotalByHeader(metaData)
      TableTotal = JSON.stringify(TableTotal)
      TableTotal = replaceAllFnPrint(TableTotal, "Net Amount", amount_format(printdata.master_table_data[0].grid_netamt_tot))
      if (print_discount !== 1) {
        TableTotal = replaceAllFnPrint(TableTotal, "Disc.", amount_format(printdata.master_table_data[0].grid_discount_tot))
      } else {
        TableTotal = replaceAllFnPrint(TableTotal, "Disc.", "")
      }

      TableTotal = replaceAllFnPrint(TableTotal, "Amount", amount_format(printdata.master_table_data[0].totamt))

      if (tax_print === 1 || tax_print === 2) {
        TableTotal = replaceAllFnPrint(TableTotal, "IGST", amount_format(printdata.master_table_data[0].igst_total))
        TableTotal = replaceAllFnPrint(TableTotal, "SGST", amount_format(printdata.master_table_data[0].sgst_total))
        TableTotal = replaceAllFnPrint(TableTotal, "CGST", amount_format(printdata.master_table_data[0].cgst_total))
      } else {
        TableTotal = replaceAllFnPrint(TableTotal, "IGST", "")
        TableTotal = replaceAllFnPrint(TableTotal, "SGST", "")
        TableTotal = replaceAllFnPrint(TableTotal, "CGST", "")
        TableTotal = replaceAllFnPrint(TableTotal, "GST", "")
      }

      TableTotal = replaceAllFnPrint(TableTotal, "Discount", "")
      TableTotal = replaceAllFnPrint(TableTotal, "No", "")
      TableTotal = replaceAllFnPrint(TableTotal, "Description of Goods", "Total")

      TableTotal = replaceAllFnPrint(TableTotal, "HSN/SAC", "")
      TableTotal = replaceAllFnPrint(TableTotal, "Narration", "")
      TableTotal = replaceAllFnPrint(TableTotal, "MRP", "")
      TableTotal = replaceAllFnPrint(TableTotal, "Rate", "")
      TableTotal = replaceAllFnPrint(TableTotal, "Unit", "")
      TableTotal = replaceAllFnPrint(TableTotal, "Quantity", "")
      TableTotal = replaceAllFnPrint(TableTotal, "Price", "")
      TableTotal = replaceAllFnPrint(TableTotal, "Item & Description", "")
      //TableTotal = replaceAllFnPrint(TableTotal, "#", "")

      TableTotal = replaceAllFnPrint(TableTotal, "Qty", amount_formatDecimal(printdata.master_table_data[0].totqty, inventory_decimalpoint))

      TableTotal = JSON.parse(TableTotal)
    }

    bodyString = bodyString.map((item) => {
      const updatedItem = { ...item }
      if (item.field_id === "detailTable") {
        updatedItem.table = {
          headerRows: 1,
          dontBreakRows: updatedItem.table.dontBreakRows ? updatedItem.table.dontBreakRows : false,
          widths: widthArray,
          body: !isblank(TableTotal) ? [Headers, ...Values, [...TableTotal]] : [Headers, ...Values],
        }
        updatedItem.layout = {
          hLineWidth: function (i, node) {
            if (PrintTemplateNo === invoiceTemplate.GST_Template_7 || PrintTemplateNo === invoiceTemplate.GST_Template_12) {
              return i > 1 ? (Grid_Line === 1 || Grid_Line === 3 ? 0.75 : 0) : 0
            } else {
              return i === 0 || i === 1 || Grid_Line === 1 || Grid_Line === 3 || updatedItem.table.body.length - 1 === i ? 1 : 0
            }
          },
          vLineWidth: function (i, node) {
            return Grid_Line === 1 || Grid_Line === 0 ? (PrintTemplateNo === invoiceTemplate.GST_Template_7 || PrintTemplateNo === invoiceTemplate.GST_Template_12 ? 0.75 : 1) : 0
          },
          hLineColor: function (i, node) {
            return PrintTemplateNo === invoiceTemplate.GST_Template_7 ? "#aaa" : "black"
          },
        }
      }
      return updatedItem
    })
  }

  return { bodyString, Headers, widthArray, metaData, customAddIndex, customBfrAddIndex }
}

export const getParseValue = (value) => {
  if (!isblank(value) && (typeof value === "string" || value instanceof String)) {
    return JSON.parse(value)
  }
  return {}
}
