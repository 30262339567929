import { list_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { LOADING_STATE, SNACKBAR_STATE, USER_HISTORY_REPORT_SERVICE_CLEAR, USER_HISTORY_REPORT_SERVICE_FAIL, USER_HISTORY_REPORT_SERVICE_START, USER_HISTORY_REPORT_SERVICE_SUCCESS } from "./types"
import { getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"

export const fetch_user_history_data = ({ setconfig, formtype, formdata, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
    dispatch({
      type: USER_HISTORY_REPORT_SERVICE_START,

      formtype: formtype,
    })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("type", "user_history")
    data.append("limit", "All")
    data.append("offset", 0)
    data.append("form_data", formdata2)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "user_history" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          userHistoryReportServiceFail({ dispatch, response: response.data.meta.message, setconfig, formdata })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          userHistoryReportServiceSuccess({ dispatch, user: response, setconfig, formtype, formdata })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          userHistoryReportServiceFail({ dispatch, response: getCatchErrorMessage(error), setconfig, formdata })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const userHistoryReportServiceSuccess = ({ dispatch, user, setconfig, formtype, formdata }) => {
  dispatch({
    type: USER_HISTORY_REPORT_SERVICE_SUCCESS,
    payload: user,
    formtype: formtype,
    setconfig: setconfig,
  })
}

export const userHistoryReportServiceClear = () => {
  return (dispatch) => {
    dispatch({
      type: USER_HISTORY_REPORT_SERVICE_CLEAR,
    })
  }
}

const userHistoryReportServiceFail = ({ dispatch, response, setconfig, formtype }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })

  dispatch({
    type: USER_HISTORY_REPORT_SERVICE_FAIL,
    payload: response,
    formtype: formtype,
    setconfig: setconfig,
  })
}
