export default {
  Account: "1",
  Inventory: "2",
  StudioBook: "3",
  POS: "4",
  Trust: "7",
  Gold: "8",
  GetMyStore: "9",
  GetMyCatalogue: "10",
  Personal: "11",
  CMS: "12",
  Process: "13",
  ProfitBook: "14",
  Payroll: "15",
  Whatsapp: "16",
  Ticket: "17",
  CRM: "18",
}
