import { checkCondition, isblank } from "./utilityFunctions"

export const post_form_url = ({ formtype, props }) => {
  const otherDetail = !isblank(props) && !isblank(props.otherDetail) ? props.otherDetail : ""
  const getAccountSitesetting = !isblank(props) && !isblank(props.getAccountSitesetting) ? props.getAccountSitesetting : ""
  const userdata = !isblank(props) && !isblank(props.userdata) ? props.userdata : ""
  const isMobileOnly = !isblank(props) && !isblank(props.isMobileOnly) ? props.isMobileOnly : ""
  const other_type = !isblank(props) && !isblank(props.other_type) ? props.other_type : ""
  const otherDetail1 = !isblank(props) && !isblank(props.otherDetail1) ? props.otherDetail1 : ""
  const formaction = !isblank(props) && !isblank(props.formaction) ? props.formaction : ""

  var BASE_URL = ""
  const BASE_URL_V2 = process.env.REACT_APP_BASE_URL_V2
  const invoice_type_detail = getAccountSitesetting && getAccountSitesetting("invoice_type_detail", userdata)
  const purchase_type_detail = getAccountSitesetting && getAccountSitesetting("purchase_type_detail", userdata)
  const inward_type_detail = getAccountSitesetting && getAccountSitesetting("inward_type_detail", userdata)
  const outward_type_detail = getAccountSitesetting && getAccountSitesetting("outward_type_detail", userdata)
  if (
    formtype === "catalogue_item" ||
    formtype === "catalogue_shipping" ||
    formtype === "catalogue_customField" ||
    formtype === "catalogue_bundleitem" ||
    formtype === "catalogue_paymentSetting" ||
    formtype === "catalogue_user" ||
    formtype === "customField" ||
    formtype === "catalogue_catalogueUser" ||
    formtype === "catalogue_updateItemShortUrl" ||
    formtype === "catalogue_reseller" ||
    formtype === "catalogue_active_inactive" ||
    formtype === "active_inactive" ||
    formtype === "catalogue_opening_stock" ||
    formtype === "catalogue_configurableitem" ||
    (!isMobileOnly && getAccountSitesetting && invoice_type_detail.filter((e) => typeof formtype === "string" && e.id === formtype?.replace("catalogue_", "")).length > 0) ||
    (!isMobileOnly && getAccountSitesetting && inward_type_detail.filter((e) => typeof formtype === "string" && e.id === formtype?.replace("catalogue_", "")).length > 0)
  ) {
    if (!isblank(process.env.REACT_APP_ACC_BASE_URL)) {
      BASE_URL = process.env.REACT_APP_ACC_BASE_URL
    } else if (props && props.config) {
      if (!isblank(props.config.ACC_BASE_URL)) {
        BASE_URL = props.config.ACC_BASE_URL
      } else {
        BASE_URL = props.config.BASE_URL
      }
    } else {
      BASE_URL = process.env.REACT_APP_BASE_URL
    }
  } else {
    if (props && props.config) {
      BASE_URL = props.config.BASE_URL
    } else {
      BASE_URL = process.env.REACT_APP_BASE_URL
    }
  }

  /* if (checkCondition(formtype, ["user", "company", "partygroup", "state", "city", "customer_supplier", "other_account", "subgroup",
    "importcountry", "country", "taxscheme", "agent", "transport", "item", "itemgroup", "godown", "productsubgroup", "productsubgrouplevel2",
    "unit", "costcenter", "importtaxscheme", "importunit",])) {
    // "inward", "job_inward", "packing_slip", "goods_received_note", "finish_stock", "outward", "delivery_challan", "manage_lot_master", "journal_entry", "payment", "receipt",
    BASE_URL = BASE_URL_V2
  } */

  var url = BASE_URL + "/user/manage_master"
  if (
    formtype === "lot_transfer" ||
    formtype === "purchaseinvoice_grid" ||
    formtype === "applyLotAndProcess" ||
    formtype === "process" ||
    formtype === "kanban_transfer" ||
    formtype === "packingslip_grid"
  ) {
    //PROCESS post URL
    url = BASE_URL + "/process/manage_master"
  } else if (formtype === "getNextProcessData" && !isblank(formaction) && formaction === "getlatestdata") {
    url = BASE_URL + "/user/getdetail"
  } else if (
    (formtype === "setting" && !isblank(otherDetail) && otherDetail.type === "new") ||
    formtype === "reconcileDate" ||
    formtype === "cancel_einvoice" ||
    (formtype === "generate_einvoice" && otherDetail1.version === "new") ||
    formtype === "bank_statement" ||
    formtype === "adjustBill" ||
    formtype === "adjustBillCr" ||
    formtype === "opening_adjust_bill_delete" ||
    formtype === "opening_adjust_receipt_delete"
  ) {
    //v1 post URL
    url = BASE_URL + "/v1/user/manage_master"
  } else if (formtype === "filegstr3" || formtype === "multi_customer_supplier_update") {
    url = BASE_URL + "/v3/user/manage_master"
  } else if (formtype === "filegstr1") {
    url = BASE_URL + "/v3/user/manage_master"
  } else if (
    (isMobileOnly &&
      !isblank(other_type) &&
      other_type.hasOwnProperty("type") &&
      !isblank(other_type) &&
      other_type.hasOwnProperty("type") &&
      other_type.type === "inward" &&
      other_type.dataArray.filter((e) => e.id == formtype).length > 0)
     || (!isMobileOnly && getAccountSitesetting && inward_type_detail.filter((e) => typeof formtype === "string" && e.id === formtype?.replace("catalogue_", "")).length > 0)
  ) {
    url = BASE_URL + "/v4/user/manage_master"
  } else if (
    (isMobileOnly && !isblank(other_type) && other_type.hasOwnProperty("type") && other_type.type === "outward" && other_type.dataArray.filter((e) => e.id == formtype).length > 0)
     || (!isMobileOnly && getAccountSitesetting && outward_type_detail.filter((e) => e.id === formtype).length > 0)
  ) {
    url = BASE_URL + "/v3/user/manage_master"
  } else if (
    formtype === "salesreturn" ||
    formtype === "purchasereturn" ||
    (isMobileOnly &&
      ((!isblank(other_type) &&
        other_type.hasOwnProperty("type") &&
        other_type.type === "salesinvoice" &&
        other_type.dataArray.filter((e) => typeof formtype === "string" && e.id == formtype?.replace("catalogue_", "")).length > 0) ||
        (!isblank(other_type) && other_type.hasOwnProperty("type") && other_type.type === "purchaseinvoice" && other_type.dataArray.filter((e) => e.id == formtype).length > 0))) ||
    (!isMobileOnly &&
      getAccountSitesetting &&
      (invoice_type_detail.filter((e) => typeof formtype === "string" && e.id === formtype?.replace("catalogue_", "")).length > 0 || purchase_type_detail.filter((e) => e.id === formtype).length > 0))
  ) {
    url = BASE_URL + "/v4/user/manage_master"
  } else if (formtype === "catalogue_item" || formtype === "itemImageInsert" || formtype === "journal_entry") {
    url = BASE_URL + "/v4/user/manage_master"
  } else if (
    formtype === "catalogue_catalogueProducts" ||
    formtype === `catalogue_itemImageInsert` ||
    formtype === "catalogue_updateGMCStatus" ||
    formtype === "catalogue_productCatalogue" ||
    formtype === "catalogue_company" ||
    formtype === "catalogue_importunit" ||
    formtype === "catalogue_defaultImage" ||
    formtype === "catalogue_setting" ||
    formtype === "catalogue_updateProductCatalogue" ||
    formtype === "catalogue_multi_item_update"
  ) {
    url = BASE_URL + "/store/manage_master"
  } else if (formtype === "studio_company") {
    url = BASE_URL + "/gym/manage_master"
  } else if (formtype === "gst_gstr2a") {
    url = BASE_URL + "/user/getdetail"
  } else if (formtype === "clear_account_data") {
    url = BASE_URL + "/utilities/getutility"
  } else if (formtype === "user") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/masters/user/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/masters/user/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/masters/user/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/masters/user/delete"
    }
  } else if (formtype === "company") {
    if (formaction === "edit") {
      url = BASE_URL_V2 + "/masters/company/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/masters/company/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/masters/company/delete"
    }
  } else if (formtype === "city") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/masters/city/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/masters/city/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/masters/city/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/masters/city/delete"
    }
  } else if (formtype === "partygroup") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/masters/customergroup/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/masters/customergroup/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/masters/customergroup/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/masters/customergroup/delete"
    }
  } else if (formtype === "costcenter") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/masters/costcenter/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/masters/costcenter/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/masters/costcenter/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/masters/costcenter/delete"
    }
  } else if (formtype === "subgroup") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/masters/subgroup/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/masters/subgroup/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/masters/subgroup/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/masters/subgroup/delete"
    }
  } else if (formtype === "country") {
    if (formaction === "edit") {
      url = BASE_URL_V2 + "/masters/country/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/masters/country/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/masters/country/delete"
    }
  } else if (formtype === "importcountry" && formaction === "insert") {
    url = BASE_URL_V2 + "/masters/country/import"
  } else if (formtype === "taxscheme") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/masters/taxscheme/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/masters/taxscheme/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/masters/taxscheme/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/masters/taxscheme/delete"
    }
  } else if (formtype === "importtaxscheme" && formaction === "insert") {
    url = BASE_URL_V2 + "/masters/taxscheme/import"
  } else if (formtype === "other_account") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/masters/otheraccount/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/masters/otheraccount/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/masters/otheraccount/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/masters/otheraccount/delete"
    }
  } else if (formtype === "customer_supplier") {
    if (other_type === "customer") {
      if (formaction === "insert") {
        url = BASE_URL_V2 + "/masters/customer/add"
      } else if (formaction === "edit") {
        url = BASE_URL_V2 + "/masters/customer/edit"
      } else if (formaction === "update") {
        url = BASE_URL_V2 + "/masters/customer/update"
      } else if (formaction === "delete") {
        url = BASE_URL_V2 + "/masters/customer/delete"
      }
    } else if (other_type === "supplier") {
      if (formaction === "insert") {
        url = BASE_URL_V2 + "/masters/supplier/add"
      } else if (formaction === "edit") {
        url = BASE_URL_V2 + "/masters/supplier/edit"
      } else if (formaction === "update") {
        url = BASE_URL_V2 + "/masters/supplier/update"
      } else if (formaction === "delete") {
        url = BASE_URL_V2 + "/masters/supplier/delete"
      }
    }
  } else if (formtype === "agent") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/masters/agent/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/masters/agent/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/masters/agent/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/masters/agent/delete"
    }
  } else if (formtype === "state") {
    if (formaction === "edit") {
      url = BASE_URL_V2 + "/masters/state/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/masters/state/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/masters/state/delete"
    }
  } else if (formtype === "transport") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/masters/transport/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/masters/transport/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/masters/transport/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/masters/transport/delete"
    }
  } else if (formtype === "item") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/inventory/product/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/inventory/product/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/inventory/product/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/inventory/product/delete"
    }
  } else if (formtype === "itemgroup") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/inventory/productgroup/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/inventory/productgroup/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/inventory/productgroup/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/inventory/productgroup/delete"
    }
  } else if (formtype === "godown") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/inventory/godown/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/inventory/godown/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/inventory/godown/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/inventory/godown/delete"
    }
  } else if (formtype === "productsubgroup") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/inventory/productsubgroup/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/inventory/productsubgroup/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/inventory/productsubgroup/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/inventory/productsubgroup/delete"
    }
  } else if (formtype === "productsubgrouplevel2") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/inventory/productsubgroupl2/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/inventory/productsubgroupl2/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/inventory/productsubgroupl2/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/inventory/productsubgroupl2/delete"
    }
  } else if (formtype === "unit" && formaction === "delete") {
    url = BASE_URL_V2 + "/inventory/unit/delete"
  } else if (formtype === "importunit" && formaction === "insert") {
    url = BASE_URL_V2 + "/inventory/unit/import"
  }/*  else if (checkCondition(formtype, ["inward", "job_inward", "packing_slip", "goods_received_note", "finish_stock"])) {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/invoice/inward/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/invoice/inward/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/invoice/inward/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/invoice/inward/delete"
    }
  } else if (checkCondition(formtype, ["outward", "delivery_challan", "manage_lot_master"])) {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/invoice/outward/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/invoice/outward/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/invoice/outward/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/invoice/outward/delete"
    }
  } */ else if (formtype === "receipt") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/transaction/receipt/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/transaction/receipt/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/transaction/receipt/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/transaction/receipt/delete"
    }
  } else if (formtype === "payment") {
    if (formaction === "insert") {
      url = BASE_URL_V2 + "/transaction/payment/add"
    } else if (formaction === "edit") {
      url = BASE_URL_V2 + "/transaction/payment/edit"
    } else if (formaction === "update") {
      url = BASE_URL_V2 + "/transaction/payment/update"
    } else if (formaction === "delete") {
      url = BASE_URL_V2 + "/transaction/payment/delete"
    }
  } /* else if (formtype === "journal_entry") {
    if (formaction === "insert") {
      url = BASE_URL + "/transaction/journal/add"
    } else if (formaction === "edit") {
      url = BASE_URL + "/transaction/journal/edit"
    } else if (formaction === "update") {
      url = BASE_URL + "/transaction/journal/update"
    } else if (formaction === "delete") {
      url = BASE_URL + "/transaction/journal/delete"
    }
  } */  else if (formtype === "webhook") {
    if (formaction == "edit") {
      url = BASE_URL_V2 + "/utilities/webhook/edit"
    } else if (formaction == "delete") {
      url = BASE_URL_V2 + "/utilities/webhook/delete"
    } else if (formaction == "update") {
      url = BASE_URL_V2 + "/utilities/webhook/update"
    } else {
      url = BASE_URL_V2 + "/utilities/webhook/add"
    }
  } else if (formtype === "webhook_fetch") {
    url = BASE_URL_V2 + "/webhook/fetch_records"
  }
  return url
}

export const list_url = ({ type, props }) => {
  const BASE_URL_V2 = process.env.REACT_APP_BASE_URL_V2
  var BASE_URL = ""
  if (
    type === "login" ||
    type === "social_login" ||
    type === "utility_upload" ||
    type === "countryallstate" ||
    type === "getWhatsappToken" ||
    type === "getGstDetail" ||
    type === "bundleproduct" ||
    type === "product_detail" ||
    type === "filter_data_post" ||
    type === "export" ||
    type === "syncdb" ||
    type === "disable_auth" ||
    type === "generate_authKey" ||
    type === "inward" ||
    type === "getAllReport" ||
    type === "getAllReportV5" ||
    type === "sendSMS" ||
    type === "sendEmail" ||
    //type === "syncLoginResponse" ||
    type === "shipping" ||
    type === "delete_image" ||
    //type === "check_authtoken" ||
    type === "paymentSetting" ||
    type === "global_search" ||
    type === "post_utility_data" ||
    type === "custom_field" ||
    type === "notification_page" ||
    type === "reseller" ||
    type === "item_price" ||
    type === "party_detail" ||
    type === "opening_stock" ||
    type === "item" ||
    type === "configurableproduct" ||
    type === "salesinvoice" ||
    type === "site_setting_data" ||
    type === "postSinglePhotoURL" ||
    type === "invoice_detail" ||
    type === "getcatalogues" ||
    type === "dependency" ||
    type === "s3_createPresignedRequest"
  ) {
    if (!isblank(process.env.REACT_APP_ACC_BASE_URL)) {
      BASE_URL = process.env.REACT_APP_ACC_BASE_URL
    } else if (props && props.config) {
      if (!isblank(props.config.ACC_BASE_URL)) {
        BASE_URL = props.config.ACC_BASE_URL
      } else {
        BASE_URL = props.config.BASE_URL
      }
    } else {
      BASE_URL = process.env.REACT_APP_BASE_URL
    }
  } else {
    if (props && props.config) {
      BASE_URL = props.config.BASE_URL
    } else {
      BASE_URL = process.env.REACT_APP_BASE_URL
    }
  }
  if (type === "error_post") {
    if (props && props.config) {
      BASE_URL = props.config.BASE_URL
    } else {
      BASE_URL = process.env.REACT_APP_ERROR_URL
    }
  }

  /*   if (
      checkCondition(type, ["user", "company", "partygroup", "state", "city", "customerList", "supplierList", "other_account", "subgroup", "country",
        "taxscheme", "agent", "transport", "item", "itemgroup", "godown", "productsubgroup", "productsubgrouplevel2", "unit", "costcenter"])
      // "inward", "outward", "journal", "payment", "receipt",
    ) {
      BASE_URL = BASE_URL_V2
    } */

  var url = ""
  if (
    type === "bundleproduct" ||
    type === "configurableproduct" ||
    type === "gstoldbill" ||
    type === "journal" ||
    type === "auto_journal" ||
    // type === "payment" ||
    // type === "receipt" ||
    type === "recordexpense" ||
    type === "getnewbillno" ||
    type === "stockTransfer" ||
    type === "pending_tds_list" ||
    type === "lot_transfer_and_lotV1" ||
    type === "adjust_bill"
  ) {
    url = BASE_URL + "/v1/user/getalllist"
  } else if (
    type === "getallorders" ||
    type === "catalogueList" ||
    type === "dropdown_closingstock" ||
    type === "closingstock" ||
    type === "dependency" ||
    type === "get_tdspercentage" ||
    type === "agentwiseparty" ||
    type === "custom_field" ||
    type === "custom_page" ||
    type === "notification_page" ||
    type === "stock_transferGodown" ||
    type === "smsgroup_contact" ||
    type === "smscategory" ||
    type === "smsgroup_send" ||
    type === "importother_account" ||
    type === "importtaxscheme" ||
    type === "importunit" ||
    type === "finish_stock" ||
    type === "available_stock" ||
    type === "useripostock" ||
    type === "ipostockmaster" ||
    type === "ipostockparty" ||
    type === "merge_packingSlip" ||
    type === "opbal" ||
    type === "opening_stock" ||
    type === "organization" ||
    type === "customPageFormList" ||
    type === "partygroupprice" ||
    type === "partyprice" ||
    type === "paymentSetting" ||
    type === "process" ||
    type === "lot_transfer" ||
    type === "lot_transfer_and_lot" ||
    type === "pen_purchaseorder_detail" ||
    type === "purchase_bill_data" ||
    type === "quicklink" ||
    type === "recent_activities" ||
    type === "pen_salesorder_detail" ||
    type === "pen_salesorder_partyitem" ||
    type === "sales_order_detail" ||
    type === "purchase_order_detail" ||
    type === "sales_bill_data" ||
    type === "shipping" ||
    type === "ewaybill" ||
    type === "userrights" ||
    type === "dropdown_batch" ||
    type === "sms_template" ||
    type === "whatsapp_template" ||
    type === "email_template" ||
    type === "envelopePrinting" ||
    type === "chequePrinting" ||
    type === "reseller" ||
    type === "draft" ||
    type === "countryallstate" ||
    type === "item_price" ||
    type === "item_avg_rate" ||
    type === "importcountry" ||
    type === "bot" ||
    type === "customField" ||
    type === "party_history" ||
    type === "label_design" ||
    type === "label_setting" ||
    type === "customPage"
  ) {
    url = BASE_URL + "/user/getalllist"
  } else if (
    type === "purchaseinvoice" ||
    type === "outward" ||
    type === "inward" ||
    type === "creditnote" ||
    type === "debitnote" ||
    type === "salesinvoice"
  ) {
    url = BASE_URL + "/v2/user/getalllist"
  } else if (type === "pageCustomField") {
    if (props && props.company_url) {
      url = BASE_URL + "/user/pageCustomField"
    } else {
      url = BASE_URL + "/user/getalllist"
    }
  } else if (type === "firmyears") {
    url = BASE_URL + "/v1/user/getfirmyears"
  } else if (type === "changeyearDB") {
    url = BASE_URL + "/v1/user/changeyearDB"
  } else if (type === "changeyear_fix") {
    url = BASE_URL + "/v1/user/changeyear_fix"
  } else if (type === "send_notifications") {
    url = BASE_URL + "/user/send_notifications"
  } else if (type === "send_multi_notifications") {
    url = BASE_URL + "/user/send_multi_notifications"
  } else if (type === "change_module" || type === "change_company") {
    url = BASE_URL + "/user/change_company"
  } else if (type === "change_year") {
    url = BASE_URL + "/user/change_year"
  } else if (
    type === "authenticateUser" ||
    type === "disable_auth" ||
    type === "generate_authKey" ||
    type === "user_history" ||
    type === "post_utility_data" ||
    type === "clearCache" ||
    type === "sendSMS" ||
    type === "sendEmail" ||
    type === "sms_balance" ||
    type === "wiretransfer" ||
    type === "beneficiary" ||
    type === "wiresender" ||
    type === "ewaybill_balance" ||
    type === "wireTransferPrint" ||
    type === "utility_upload" ||
    type === "export" ||
    type === "billing" ||
    type === "clearCompany"
  ) {
    url = BASE_URL + "/utilities/getutility"
  } else if (type === "forgot_password") {
    url = BASE_URL + "/auth/forgot_password"
  } else if (
    type === "site_setting" ||
    type === "record_expense_fix" ||
    type === "get_sku" ||
    type === "product_detail" ||
    type === "sms_detail" ||
    type === "invoice_packingslipdata" ||
    type === "multi_packingslipdata" ||
    type === "get_balance" ||
    type === "checkPartyGstin" ||
    type === "tallyExport" ||
    type === "syncLoginResponse" ||
    type === "gst_gstr2a" ||
    type === "runBot" ||
    type === "check_invoice_server_status"
  ) {
    url = BASE_URL + "/user/getdetail"
  } else if (type === "transaction_detail") {
    url = BASE_URL + "/v1/user/getdetail"
  } else if (type === "invoice_detail") {
    url = BASE_URL + "/v2/user/getdetail"
  } else if (type === "login") {
    url = BASE_URL + "/v3/user/loginV2"
  } else if (type === "site_setting_data") {
    url = BASE_URL + "/v1/user/getdetail"
  } else if (type === "getWhatsappToken") {
    url = BASE_URL + "/whatsapp/get_auth"
  } else if (type === "setGetMyStoreNotf") {
    url = BASE_URL + "/user/send_notification"
  } else if (type === "filter_data_post") {
    url = BASE_URL + "/v1/user/manage_filter"
  } else if (type === "filter_data_post_catalogue") {
    url = BASE_URL + "/user/manage_filter"
  } else if (type === "ewaybill_print" || type === "ewb_action" || type === "generate_ewb_token" || type === "eInvoiceQRCode_fb" || type === "ewaybill_clear" || type === "ein_action") {
    url = BASE_URL + "/report/getdetail"
  } else if (type === "generate_eway_bill") {
    url = BASE_URL + "/v2/report/getdetail"
  } else if (type === "social_login") {
    url = BASE_URL + "/auth/social_connect"
  } else if (type === "download_pdf") {
    url = BASE_URL + "/report/getpdf"
  } else if (type === "modal_party_detail") {
    const { other_type } = props
    if (other_type === "transport" || other_type === "other_account") {
      url = BASE_URL + "/user/getdetail"
    } else {
      url = BASE_URL + "/v1/user/getdetail"
    }
  } else if ((type === "delete_image" && !isblank(props) && !isblank(props.formdata) && props.formdata.type === "item") || type === "prev_year_unadjust") {
    url = BASE_URL + "/v1/user/manage_master"
  } else if (type === "delete_image" && !isblank(props) && !isblank(props.formdata) && props.formdata.type === "catalogue_item") {
    url = BASE_URL + "/store/manage_master"
  } else if (
    type === "delete_image" ||
    type === "delete_image_cms" ||
    type === "postSinglePhotoURL" ||
    type === "dropDown_insert" ||
    type === "gstr2_upload" ||
    type === "post_gst_form_data" ||
    type === "getGstDetail" ||
    type === "user_assign" ||
    type === "clear_sales_order"
  ) {
    url = BASE_URL + "/user/manage_master"
  } else if (type === "invoice_print") {
    url = BASE_URL + "/invoicev2/print_invoice"
  } else if (type === "download_json") {
    url = BASE_URL + "/v1/report/getdetail"
  } else if (type === "bank_cash_detail") {
    url = BASE_URL + "/dashboard/getbankcash"
  } else if (type === "batch_out") {
    url = BASE_URL + "/user/getitempendingbatch"
  } else if (type === "closing_balance" || type === "party_detail") {
    url = BASE_URL + "/v1/user/getalllist"
  } else if (type === "dashboard") {
    url = BASE_URL + "/dashboard/getallchart"
  } else if (type === "global_search") {
    url = BASE_URL + "/quick/search"
  } else if (type === "post_organization") {
    url = BASE_URL + "/v1/user/connect_company"
  } else if (type === "reportProcess") {
    url = BASE_URL + "/report/getdetail?rnd=" + new Date().getTime()
  } else if (type === "fetchPackingSlip") {
    url = BASE_URL + "/process/getalllist"
  } else if (type === "sign_up") {
    url = BASE_URL + "/master/signup"
  } else if (type === "syncdb") {
    url = BASE_URL + "/utilities/syncdb"
  } else if (type === "check_authtoken") {
    url = BASE_URL + "/v1/user/check_authtoken"
  } else if (type === "check_authtoken_cms") {
    url = BASE_URL + "/user/check_authtoken"
  } else if (type === "s3_createPresignedRequest" || type === "s3_createPresignedRequestBackend") {
    url = BASE_URL + "/user/s3_createPresignedRequest"
  } else if (type === "getAllReport") {
    url = BASE_URL + "/v4/report/getallreport"
  } else if (type === "getAllReportV5") {
    url = BASE_URL + "/v5/report/getallreport"
  } else if (type === "getallaccountpackage") {
    url = BASE_URL + "/master/getallaccountpackage"
  } else if (type === "getallcatalogues") {
    url = BASE_URL + "/store/getallcatalogues"
  } else if (type === "getcatalogues") {
    url = BASE_URL + "/getmycatalogues/getcatalogues"
  } else if (type === "getallproductpdf") {
    url = BASE_URL + "/store/getallproductpdf"
  } else if (type === "catalogueUser" || type === "contacts") {
    url = BASE_URL + "/store/getallcustomer"
  } else if (type === "catalogueUserDetail") {
    url = BASE_URL + "/store/abandoned_cartdetail"
  } else if (type === "catalogueUserCartPrint") {
    url = BASE_URL + "/store/abandoned_cartPrint"
  } else if (type === "myOrder") {
    url = BASE_URL + "/store/getalllist"
  } else if (type === "catalogue_search") {
    url = BASE_URL + "/store/search"
  } else if (type === "UPLOAD_IMAGE") {
    url = BASE_URL + "/store/manage_master"
  } else if (type === "ADD_PRODUCT_MULTIPLE") {
    url = BASE_URL + "/store/manage_master"
  } else if (type === "MASTER_DEP") {
    url = BASE_URL + "/master/getalllist"
  } else if (type === "catalogue_site_setting") {
    url = BASE_URL + "/store/getdetail"
  } else if (type === "DD_PLAN") {
    url = BASE_URL + "/master/dd_plan"
  } else if (type === "error_post") {
    url = BASE_URL + "/auth/frontend_error"
  } else if (type === "payment_link") {
    // url = BASE_URL + '/payment/payment_links'
    url = "http://api.finalbooks.local/payment/payment_links"
  } else if (type === "payment_complete") {
    // url = BASE_URL + '/payment/payment_complete'
    url = "http://api.finalbooks.local/payment/payment_complete"
  } else if (type === "whatsapp_api") {
    url = BASE_URL + "/user/send_whatsapp"
  } else if (type === "productsearch") {
    url = BASE_URL + "/simoni/getalllist"
  } else if (type === "multi_entry_page") {
    url = BASE_URL + "/simoni/manage_master"
  } else if (type === "webhook") {
    url = BASE_URL_V2 + "/utilities/webhook"
  } else if (type === "company") {
    url = BASE_URL_V2 + "/masters/company"
  } else if (type === "user") {
    url = BASE_URL_V2 + "/masters/user"
  } else if (type === "partygroup") {
    url = BASE_URL_V2 + "/masters/customergroup"
  } else if (type === "state") {
    url = BASE_URL_V2 + "/masters/state"
  } else if (type === "city") {
    url = BASE_URL_V2 + "/masters/city"
  } else if (type === "customerList") {
    url = BASE_URL_V2 + "/masters/customer"
  } else if (type === "supplierList") {
    url = BASE_URL_V2 + "/masters/supplier"
  } else if (type === "other_account") {
    url = BASE_URL_V2 + "/masters/otheraccount"
  } else if (type === "subgroup") {
    url = BASE_URL_V2 + "/masters/subgroup"
  } else if (type === "costcenter") {
    url = BASE_URL_V2 + "/masters/costcenter"
  } else if (type === "country") {
    url = BASE_URL_V2 + "/masters/country"
  } else if (type === "taxscheme") {
    url = BASE_URL_V2 + "/masters/taxscheme"
  } else if (type === "agent") {
    url = BASE_URL_V2 + "/masters/agent"
  } else if (type === "transport") {
    url = BASE_URL_V2 + "/masters/transport"
  } else if (type === "item") {
    url = BASE_URL_V2 + "/inventory/product"
  } else if (type === "itemgroup") {
    url = BASE_URL_V2 + "/inventory/productgroup"
  } else if (type === "godown") {
    url = BASE_URL_V2 + "/inventory/godown"
  } else if (type === "productsubgroup") {
    url = BASE_URL_V2 + "/inventory/productsubgroup"
  } else if (type === "productsubgrouplevel2") {
    url = BASE_URL_V2 + "/inventory/productsubgroupl2"
  } else if (type === "unit") {
    url = BASE_URL_V2 + "/inventory/unit"
  }/*  else if (type === "inward") {
    url = BASE_URL_V2 + "/invoice/inward"
  } else if (type === "outward") {
    url = BASE_URL_V2 + "/invoice/outward"
  } */ else if (type === "receipt") {
    url = BASE_URL_V2 + "/transaction/receipt"
  } else if (type === "payment") {
    url = BASE_URL_V2 + "/transaction/payment"
  } /*  else if (type === "journal") {
    url = BASE_URL + "/transaction/journal" 
  } */
  /* else if (
    type === "companyList" ||
    type === "list_filter" ||
    type === "report" ||
    type === "menu" ||
    type === "tds_detail" ||
    type === "otherAccount" ||
    type === "invoiceSubType" ||
    type === "cron" ||
    type === "email_templates" ||
    type === "invoice_template" ||
    type === "companyRequest" ||
    type === "announcement" ||
    type === "folderExist" ||
    type === "show_logs" ||
    type === "manageuser" ||
    type === "companydetail" ||
    type === "site_setting"
  ) {
    url = BASE_URL + "/backend/getalllist"
  } */
  return url
}
