import printJS from "print-js"
import { exportAs } from "@project/sharedcomponents/utilityFunctions"
import { isblank } from "@project/sharedcomponents/utilityFunctions"
import { generatePdfDoc } from "@project/components/pdfmakeFunctions"

export const print_file = (base64data, name) => {
  var binary = atob(base64data.replace(/\s/g, ""))
  var len = binary.length
  var buffer = new ArrayBuffer(len)
  var view = new Uint8Array(buffer)
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i)
  }
  // create the blob object with content-type "application/pdf"
  var blob = new Blob([view], { type: "application/pdf" })
  var url = URL.createObjectURL(blob)
  //printJS({ printable: url, type: 'pdf', showModal: true, documentTitle: (name) ? name : 'PDF' });
  printJS(url)
}

export const fbprintpdf = ({ docDefinition, metadata, action, loadingstate, functions }) => {
  if (!isblank(metadata) && !isblank(metadata.filename)) {
    if (!isblank(docDefinition)) {
      if (isblank(docDefinition.info)) {
        docDefinition.info = {}
      }
      if (isblank(docDefinition.info.title)) {
        docDefinition.info.title = metadata.filename
      }
    }
  }
  const pdfDocGenerator = generatePdfDoc(docDefinition)
  pdfDocGenerator.getBase64((printdata) => {
    if (action === "print" || action === "print_ewaybill") {
      print_file(printdata, metadata.filename)
    } else if (action === "print_preview") {
      const { dispatch, maxWidth, pushDrawer } = functions
      pushDrawer({ title: "PDFViewer", data: printdata, fieldname: metadata, position: maxWidth <= 1024 ? "bottom" : "right", width: maxWidth <= 1024 ? maxWidth : 1024, dispatch })
    } else if (action === "download") {
      exportAs({ data: printdata, name: metadata.filename, type: "pdf" })
    } else if (action === "base64") {
      if (functions && functions.onComplete) {
        functions.onComplete(printdata)
      } else {
        return printdata
      }
    } else if (action === "live_base64") {
      const { dispatch, LAST_PAGE_UPDATE } = functions
      dispatch({
        type: LAST_PAGE_UPDATE,
        lasttype: "live_base64",
        lastaction: "complete",
        lasttime: new Date().getTime(),
        lastdata: printdata,
      })
    }
  })

  if (!isblank(loadingstate)) {
    loadingstate(false)
  }
}

export const fbprintpdfV2 = (docDefinition) => {
  return new Promise(function (resolve, reject) {
    const pdfDocGenerator = generatePdfDoc(docDefinition)
    pdfDocGenerator.getBase64((printdata) => {
      resolve(printdata)
    })
  })
}
