import { Box, Card, CardContent, Container, Typography, withStyles } from "@material-ui/core"
import LoadingButton from "@project/components/common/LoadingButton"
import { postErrorOnException } from "@project/sharedcomponents/axiosFunctions"
import { isblank } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import React from "react"
import compose from "recompose/compose"
import warning from "../Images/warning.svg"

const styles = (theme) => ({
  boxClass: {
    backgroundColor: theme.palette.background.default,
  },
})

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, buttonLoading: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    if (!isblank(error) && error.name === "ChunkLoadError") {
      window.location.reload()
    } else {
      if (process.env.REACT_APP_NODE_ENV !== "development" && this.props.postErrors) {
        const company_url = this.props.getSessionUserData ? this.props.getSessionUserData("company_url") : this.props.company_url ? this.props.company_url : ""
        this.setState({
          buttonLoading: true,
        })
        var currentDateTime = new Date().toString()
        var error_data = {
          error: { version: process.env.REACT_APP_VERSION, name: error.name, message: error.message },
          errorInfo: error.stack,
          company_url: company_url,
          path: window.location.href,
          currentDateTime,
        }
        error_data = Array.isArray(error_data) ? error_data.map(JSON.stringify) : JSON.stringify(error_data)
        let data = new FormData()
        data.append("error_data", error_data)
        axios({
          method: "post",
          url: process.env.REACT_APP_ERROR_URL,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: data,
        })
          .then((response) => {
            this.setState({
              buttonLoading: false,
            })
          })
          .catch((error) => {
            postErrorOnException(error, "", "", company_url)
            this.setState({
              buttonLoading: false,
            })
          })
      }
    }
  }

  render() {
    const { classes } = this.props
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" className={classes.boxClass}>
          <Container maxWidth="sm">
            <Card variant="outlined">
              <CardContent>
                <div style={{ position: "relative" }}>
                  <Typography variant="h5" gutterBottom>
                    Oops! Something went wrong
                  </Typography>
                  <Typography variant="h2" gutterBottom>
                    500
                  </Typography>
                  <Typography style={{ marginBottom: 32 }} variant="body1" gutterBottom>
                    Sorry, it appears there has been an internal server error with the page you've requested. Please try refreshing.
                  </Typography>
                  <LoadingButton component="a" href="/" color="primary" variant="contained" loading={this.state.buttonLoading} label="GO BACK TO HOME" />
                  <div style={{ position: "absolute", top: 8, right: 8 }}>
                    <img src={warning} style={{ height: 108, width: 108 }} alt="Logo" />
                  </div>
                </div>
              </CardContent>
            </Card>
          </Container>
        </Box>
      )
    }

    return this.props.children
  }
}

export default compose(
  withStyles(styles, {
    withTheme: true,
    name: "ErrorBoundary",
  })
)(ErrorBoundary)
