import { AppBar, Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Toolbar, makeStyles } from "@material-ui/core"
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons"
import CustomButton from "@project/components/common/CustomButton"
import { getCookie } from "@project/components/utilityFunctions"
import React, { useEffect } from "react"
import { isMobileOnly } from "react-device-detect"
import { useSelector } from "react-redux"

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  pageAppBar: {
    zIndex: theme.zIndex.drawer,
  },
  errorOption: {
    color: theme.palette.error.main,
  },
}))

let timeout = {}

function Footer(props) {
  const {
    onSaveClick,
    onUpdateClick,
    componentId,
    saveDraft,
    onUpdateAndPrintClick,
    onUpdateAndShareClick,
    buttonOne,
    buttonOneColor,
    buttonOneName,
    buttonTwo,
    buttonTwoColor,
    buttonTwoName,
    onDeleteClick,
    onPrintClick,
    onPrintSaveClick,
    onSaveAndShareClick,
    onSaveandAnotherClick,
    onDismissClick,
    loading,
    sidebarloading,
    submitting,
    onImportClick,
    drawerType,
    buttonThree,
    buttonThreeColor,
    buttonThreeName,
    buttonFour,
    buttonFourColor,
    buttonFourName,
    disableLoading,
    type,
    width,
  } = props

  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [toggle, setToggle] = React.useState(false)
  const { windowWidth, common_formtype } = useSelector((state) => state.CommonReducer)

  useEffect(() => {
    timeout[componentId] = 0
  }, [componentId])

  useEffect(() => {
    timeout[componentId] = 0
  }, [])

  useEffect(() => {
    return () => {
      timeout[componentId] = 0
    }
  }, [])

  const handleClick = (clickFunction) => {
    if (disableLoading) {
      clickFunction()
    } else {
      setToggle(!toggle)
      if (timeout[componentId]) clearTimeout(timeout[componentId])
      timeout[componentId] = setTimeout(() => {
        clickFunction()
        setTimeout(() => {
          timeout[componentId] = 0
          setToggle(!toggle)
        }, 0)
      }, 250)
    }
  }

  const options = []

  let SaveButton = null
  var disableButtons = submitting | sidebarloading | loading | timeout[componentId] ? true : false
  if (onSaveClick) {
    options.push({ label: "Save", click: onSaveClick })
    SaveButton = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_1` : "footer_button_1"}
        disabled={disableButtons}
        variant="contained"
        theme="primary"
        onClick={() => handleClick(onSaveClick)}
      >
        Save
      </CustomButton>
    )
  }

  let UpdateButton = null
  if (onUpdateClick) {
    options.push({ label: "Update", click: onUpdateClick })
    UpdateButton = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_2` : "footer_button_2"}
        disabled={disableButtons}
        variant="contained"
        theme="secondary"
        onClick={() => handleClick(onUpdateClick)}
      >
        Update
      </CustomButton>
    )
  }

  let SaveandanotherButton = null
  if (onSaveandAnotherClick) {
    SaveandanotherButton = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_3` : "footer_button_3"}
        disabled={disableButtons}
        style={{ marginRight: 15 }}
        variant="outlined"
        theme="primaryText"
        onClick={() => handleClick(onSaveandAnotherClick)}
      >
        Save & Add Another
      </CustomButton>
    )
  }

  let DraftButton = null
  if (saveDraft) {
    options.push({ label: "Save as Draft", click: saveDraft })
    DraftButton = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_4` : "footer_button_4"}
        disabled={disableButtons}
        style={{ marginRight: 15 }}
        variant="outlined"
        theme="secondaryText"
        onClick={saveDraft}
      >
        Save as Draft
      </CustomButton>
    )
  }

  let PrintButton = null
  if (onPrintClick) {
    options.push({ label: "Print", click: onPrintClick })
    PrintButton = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_5` : "footer_button_5"}
        disabled={disableButtons}
        style={{ marginRight: 15 }}
        variant="outlined"
        theme="purpleText"
        onClick={() => handleClick(onPrintClick)}
      >
        Print
      </CustomButton>
    )
  }

  let Print_SaveButton = null
  if (onPrintSaveClick && common_formtype !== "draft") {
    options.push({ label: "Save & Print", click: onPrintSaveClick })
    Print_SaveButton = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_6` : "footer_button_6"}
        disabled={disableButtons}
        style={{ marginRight: 15 }}
        variant="outlined"
        theme="purpleText"
        onClick={() => handleClick(onPrintSaveClick)}
      >
        Save & Print
      </CustomButton>
    )
  }

  let Print_UpdateButton = null
  if (onUpdateAndPrintClick) {
    options.push({ label: "Update & Print", click: onUpdateAndPrintClick })
    Print_UpdateButton = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_7` : "footer_button_7"}
        disabled={disableButtons}
        style={{ marginRight: 15 }}
        variant="outlined"
        theme="purpleText"
        onClick={() => handleClick(onUpdateAndPrintClick)}
      >
        Update & Print
      </CustomButton>
    )
  }

  let Share_SaveButton = null
  if (onSaveAndShareClick && common_formtype !== "draft") {
    options.push({ label: "Save & Share", click: onSaveAndShareClick })
    Share_SaveButton = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_7` : "footer_button_7"}
        disabled={disableButtons}
        style={{ marginRight: 15 }}
        variant="outlined"
        theme="purpleText"
        onClick={() => handleClick(onSaveAndShareClick)}
      >
        Save & Share
      </CustomButton>
    )
  }

  let Share_UpdateButton = null
  if (onUpdateAndShareClick) {
    options.push({ label: "Update & Share", click: onUpdateAndShareClick })
    Share_UpdateButton = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_7` : "footer_button_7"}
        disabled={disableButtons}
        style={{ marginRight: 15 }}
        variant="outlined"
        theme="purpleText"
        onClick={() => handleClick(onUpdateAndShareClick)}
      >
        Update & Share
      </CustomButton>
    )
  }

  let ImportButton = null
  if (onImportClick) {
    options.push({ label: "Import", click: onImportClick })
    ImportButton = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_8` : "footer_button_8"}
        disabled={disableButtons}
        style={{ marginRight: 15 }}
        variant="contained"
        theme="primary"
        onClick={onImportClick}
      >
        Import
      </CustomButton>
    )
  }

  let ButtonOne = null
  if (buttonOne) {
    options.push({ label: buttonOneName, click: buttonOne })
    ButtonOne = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_9` : "footer_button_9"}
        disabled={disableButtons}
        style={{ marginRight: 15 }}
        color={buttonOneColor ? buttonOneColor : "secondary"}
        variant="contained"
        onClick={buttonOne}
      >
        {buttonOneName}
      </CustomButton>
    )
  }
  let ButtonTwo = null
  if (buttonTwo) {
    options.push({ label: buttonTwoName, click: buttonTwo })
    ButtonTwo = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_10` : "footer_button_10"}
        disabled={disableButtons}
        style={{ marginRight: 15 }}
        color={buttonTwoColor ? buttonTwoColor : "secondary"}
        variant="contained"
        onClick={buttonTwo}
      >
        {buttonTwoName}
      </CustomButton>
    )
  }

  let ButtonThree = null
  if (buttonThree) {
    options.push({ label: buttonThreeName, click: buttonThree })
    ButtonThree = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_11` : "footer_button_11"}
        disabled={disableButtons}
        style={{ marginRight: 15 }}
        color={buttonThreeColor ? buttonThreeColor : "secondary"}
        variant="contained"
        onClick={buttonThree}
      >
        {buttonThreeName}
      </CustomButton>
    )
  }

  let ButtonFour = null
  if (buttonFour) {
    options.push({ label: buttonFourName, click: buttonFour })
    ButtonFour = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_12` : "footer_button_12"}
        disabled={disableButtons}
        style={{ marginRight: 15 }}
        color={buttonFourColor ? buttonFourColor : "secondary"}
        variant="contained"
        onClick={buttonFour}
      >
        {buttonFourName}
      </CustomButton>
    )
  }

  let DeleteButton = null
  if (onDeleteClick) {
    options.push({ label: "Delete", click: onDeleteClick, class: classes.errorOption })
    DeleteButton = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_13` : "footer_button_13"}
        disabled={disableButtons}
        style={{ marginRight: 15 }}
        className={classes.errorOption}
        variant="outlined"
        theme="errorText"
        onClick={onDeleteClick}
      >
        Delete
      </CustomButton>
    )
  }

  let DismissButton = null
  if (onDismissClick) {
    DismissButton = (
      <CustomButton
        data-testid={componentId ? `${componentId}_footer_button_14` : "footer_button_14"}
        disabled={disableButtons}
        style={{ marginRight: 15 }}
        variant="outlined"
        theme="greyText"
        onClick={onDismissClick}
      >
        Dismiss
      </CustomButton>
    )
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }
  return (
    <AppBar
      className={type === "page" ? classes.pageAppBar : classes.appBar}
      color={getCookie("darkmode") === "dark" ? "inherit" : "default"}
      position="fixed"
      style={{ width: drawerType === "full" || isMobileOnly ? windowWidth : width, top: "auto", bottom: 0 }}
    >
      <Toolbar variant="dense" style={{ justifyContent: "flex-end" }}>
        {isMobileOnly ? (
          <React.Fragment>
            {options.length > 1 ? (
              <React.Fragment>
                <ButtonGroup fullWidth variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                  <Button
                    data-testid={componentId ? `${componentId}_footer_button_15` : "footer_button_15"}
                    onClick={(event) => {
                      options[selectedIndex].click()
                      handleClose(event)
                    }}
                  >
                    {options[selectedIndex].label}
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    data-testid={componentId ? `${componentId}_footer_button_16` : "footer_button_16"}
                    style={{ width: 44 }}
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu">
                            {options.map((option, index) => (
                              <MenuItem
                                key={option.label}
                                selected={index === selectedIndex}
                                className={option.class}
                                onClick={(event) => {
                                  option.click()
                                  setSelectedIndex(index)
                                  handleClose(event)
                                }}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {DeleteButton}
                {ButtonOne}
                {ButtonTwo}
                {ButtonThree}
                {ButtonFour}
                {ImportButton}
                {PrintButton}
                {Print_UpdateButton}
                {Print_SaveButton}
                {DraftButton}
                {SaveButton}
                {UpdateButton}
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {DismissButton}
            {DeleteButton}
            {ButtonOne}
            {ButtonTwo}
            {ButtonThree}
            {ButtonFour}
            {ImportButton}
            {Share_SaveButton}
            {Share_UpdateButton}
            {PrintButton}
            {Print_UpdateButton}
            {Print_SaveButton}
            {SaveandanotherButton}
            {DraftButton}
            {SaveButton}
            {UpdateButton}
          </React.Fragment>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default Footer
