import { checkCondition, convertIntif } from "@project/sharedcomponents/utilityFunctions"
import { USERRIGHTS_CHANGE, USERRIGHTS_SERVICE_CLEAR, USERRIGHTS_SERVICE_FAIL, USERRIGHTS_SERVICE_START, USERRIGHTS_SERVICE_SUCCESS } from "../Actions/types"

const INITIAL_STATE = {
  error: "",
  loading: false,
  data: {},
  report_data: {},
  module_data: [],
  linkedCompany_data: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USERRIGHTS_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case USERRIGHTS_SERVICE_SUCCESS:
      return { ...state, data: action.payload.data.result, report_data: action.payload.data.reports, module_data: action.payload.data.modules, linkedCompany_data: action.payload.data.company }
    case USERRIGHTS_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], report_data: [], loading: false }
    case USERRIGHTS_CHANGE:
      if (action.rtype === "data") {
        let updateddata = state.data[action.key].map((item, index) => {
          if (convertIntif(item.sub_menu_id) !== convertIntif(action.sub_menu_id)) {
            return item
          }
          const updateditem = { ...item }
          if (action.field === "can_view") {
            updateditem.can_view = action.checked ? "1" : "0"
            updateditem.can_edit = action.checked ? "1" : "0"
            updateditem.can_add = action.checked ? "1" : "0"
            updateditem.can_delete = action.checked ? "1" : "0"
            if (!action.checked) {
              updateditem.quick_links = "0"
            }
          } else {
            updateditem[action.field] = action.checked ? "1" : "0"
          }
          return updateditem
        })
        let newupdateddata = { ...state.data }
        newupdateddata[action.key] = updateddata
        return { ...state, data: newupdateddata }
      } else if (action.rtype === "menu_data_all") {
        let updateddata = state.data[action.key].map((item, index) => {
          const updateditem = { ...item }
          if (action.field === "can_view") {
            updateditem.can_view = action.checked ? "1" : "0"
            updateditem.can_edit = action.checked ? "1" : "0"
            updateditem.can_add = action.checked ? "1" : "0"
            updateditem.can_delete = action.checked ? "1" : "0"
            if (!action.checked) {
              updateditem.quick_links = "0"
            }
          } else {
            if (checkCondition(updateditem["name"], ["Manage State", "Manage Country", "Manage Unit", "Manage HSN", "Refill Card", "Bank Reconciliation"], "not")) {
              updateditem[action.field] = action.checked ? "1" : "0"
            } else {
              updateditem[action.field] = "0"
            }
          }
          return updateditem
        })
        let newupdateddata = { ...state.data }
        newupdateddata[action.key] = updateddata
        return { ...state, data: newupdateddata }
      } else if (action.rtype === "report_data") {
        let updateddata = state.report_data[action.key].map((item, index) => {
          if (convertIntif(item.report_id) !== convertIntif(action.sub_menu_id)) {
            return item
          }
          const updateditem = { ...item }
          if (action.field === "can_view") {
            updateditem.can_view = action.checked ? "1" : "0"
            if (!action.checked) {
              updateditem.quick_links = "0"
            }
          } else {
            updateditem[action.field] = action.checked ? "1" : "0"
          }
          return updateditem
        })
        let newupdatedreportdata = { ...state.report_data }
        newupdatedreportdata[action.key] = updateddata
        return { ...state, report_data: newupdatedreportdata }
      } else if (action.rtype === "report_data_all") {
        let updateddata = state.report_data[action.key].map((item, index) => {
          //if(item.sub_menu_id !== action.sub_menu_id) {
          //return item;
          //}
          const updateditem = { ...item }
          if (action.field === "can_view") {
            updateditem.can_view = action.checked ? "1" : "0"
            if (!action.checked) {
              updateditem.quick_links = "0"
            }
          } else {
            updateditem[action.field] = action.checked ? "1" : "0"
          }
          return updateditem
        })
        let newupdatedreportdata = { ...state.report_data }
        newupdatedreportdata[action.key] = updateddata
        return { ...state, report_data: newupdatedreportdata }
      } else if (action.rtype === "module_data") {
        let updateddata = state.module_data.map((item, index) => {
          if (convertIntif(item.id) !== convertIntif(action.sub_menu_id)) {
            return item
          }
          const updateditem = { ...item }
          updateditem[action.field] = action.checked ? "1" : "0"
          return updateditem
        })

        return { ...state, module_data: updateddata }
      } else if (action.rtype === "linkedCompany_data") {
        let updateddata = state.linkedCompany_data.map((item, index) => {
          if (convertIntif(item.id) !== convertIntif(action.sub_menu_id)) {
            return item
          }
          const updateditem = { ...item }
          updateditem[action.field] = action.checked ? "1" : "0"
          return updateditem
        })
        return { ...state, linkedCompany_data: updateddata }
      }
      return { ...state }

    case USERRIGHTS_SERVICE_CLEAR:
      return { ...state, error: "", loading: false, data: {}, report_data: {}, module_data: [], linkedCompany_data: [] }

    default:
      return state
  }
}
