import { getAllListViewType, getAllPromoOption } from "@project/components/utilityFunctions"
import cF from "@project/sharedcomponents/constantData/customFieldsEnum"
import { convertIntif, getdropdownfieldvalue, isblank } from "@project/sharedcomponents/utilityFunctions"
import {
  PAGES_DATA_UPDATE,
  PAGES_LOCAL_SEARCH,
  PAGES_SERVICE_CLEAR,
  PAGES_SERVICE_DELETED,
  PAGES_SERVICE_FAIL,
  PAGES_SERVICE_INSERTED,
  PAGES_SERVICE_START,
  PAGES_SERVICE_SUCCESS,
  PAGES_SERVICE_UPDATED,
} from "../Actions/types"

const INITIAL_STATE = {
  error: "",
  loading: false,
  showInList: [],
  data: [],
  totalcount: 0,
  rowsPerPage: 0,
  page: 0,
  order: "asc",
  orderBy: "code",
  norecord: false,
  search_text: "",
  deleted: false,
  columnData: [{ id: "code", numeric: false, visible: true, label: "Code" }],
  metadata: "",
}

const setPromoListTypeColomData = (ColumnData) => {
  if (ColumnData.filter((e) => e.id === "__cust_listViewType").length > 0) {
    return ColumnData
  }
  ColumnData.push({ id: "__cust_listViewType", numeric: false, visible: true, label: "View" })
  ColumnData.push({ id: "promo_option", numeric: false, visible: true, label: "Promo Option", showlink: true, width: "140px" })
  return ColumnData
}

const setPromoTypeColomData = (ColumnData) => {
  if (ColumnData.filter((e) => e.id === "__cust_promo___Type").length > 0) {
    return setPromoListTypeColomData(ColumnData)
  }
  ColumnData.push({ id: "__cust_promo___Type", numeric: false, visible: true, label: "Promo Type" })
  return setPromoListTypeColomData(ColumnData)
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAGES_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case PAGES_DATA_UPDATE:
      return { ...state, data: action.data }
    case PAGES_SERVICE_SUCCESS:
      let finalColumnData = state.columnData
      var showInList = state.showInList
      if (action.payload.data.hasOwnProperty("showInList") && action.payload.data.showInList.length > 0) {
        showInList = action.payload.data.showInList
        finalColumnData = action.payload.data.showInList.map((item) => {
          const updatedItem = {}
          updatedItem.id = item.name
          updatedItem.label = item.labelName
          updatedItem.visible = true
          updatedItem.numeric = parseInt(item.dataType) === cF.Decimal
          if (parseInt(item.dataType) === cF.Photo) {
            updatedItem.isImage = true
            updatedItem.disableSorting = true
            updatedItem.ifblank = ""
          }
          return updatedItem
        })
      }
      let finalData = action.payload.data.result.map((item, index) => {
        const updateditem = { ...item }
        var filter_find = action.getdatatype.filter((itemRow) => updateditem.dataType === itemRow.id)
        if (filter_find.length > 0) {
          updateditem.dataType = filter_find[0].name
        } else {
          updateditem.dataType = ""
        }
        finalColumnData.forEach((it) => {
          if (!isblank(updateditem[it.id]) && updateditem[it.id].hasOwnProperty("name") && !isblank(updateditem[it.id].name)) {
            updateditem[it.id] = updateditem[it.id].name
          } else if (!isblank(updateditem[it.id])) {
            updateditem[it.id] = updateditem[it.id]
          } else {
            updateditem[it.id] = ""
          }
        })
        if (action.other_type === "Promo") {
          const masterData = !isblank(updateditem.master) ? JSON.parse(updateditem.master) : {}
          updateditem.__cust_promo___Type = getdropdownfieldvalue({ dropDownData: getAllPromoOption(), field: "id", value: masterData.__cust_promo___Type, displayvalue: "name" })
          updateditem.__cust_listViewType = getdropdownfieldvalue({ dropDownData: getAllListViewType(), field: "id", value: masterData.__cust_listViewType, displayvalue: "name" })
        }
        return updateditem
      })

      if (action.other_type === "Promo") {
        finalColumnData = setPromoTypeColomData(finalColumnData)
      }

      return {
        ...state,
        data: finalData,
        showInList: showInList,
        columnData: finalColumnData,
        loading: false,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        islogin: true,
        norecord: action.payload.data.result.length === 0,
      }
    case PAGES_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false, totalcount: 0, search_text: action.search_text }
    case PAGES_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case PAGES_LOCAL_SEARCH:
      return { ...state, search_text: action.search_text }
    case PAGES_SERVICE_INSERTED:
      var ColumnData = state.columnData
      if (action.showInList.length > 0) {
        ColumnData = action.showInList.map((item) => {
          const updatedItem = {}
          updatedItem.id = item.name
          updatedItem.label = item.labelName
          updatedItem.visible = true
          updatedItem.numeric = parseInt(item.dataType) === cF.Decimal
          if (parseInt(item.dataType) === cF.Photo) {
            updatedItem.isImage = true
            updatedItem.disableSorting = true
            updatedItem.ifblank = ""
          }
          return updatedItem
        })
      }

      if (action.payload.tablename === "Promo") {
        ColumnData = setPromoTypeColomData(ColumnData)
      }
      const inserteditem = {}
      action.showInList.forEach((element) => {
        if (!isblank(action.payload[element.name]) && action.payload[element.name].hasOwnProperty("name") && !isblank(action.payload[element.name].name)) {
          inserteditem[element.name] = action.payload[element.name].name
        } else if (!isblank(action.payload[element.name])) {
          inserteditem[element.name] = action.payload[element.name]
        }
      })
      inserteditem.code = action.insert_code
      inserteditem.__cust_promo___Type = getdropdownfieldvalue({ dropDownData: getAllPromoOption(), field: "id", value: action.payload.__cust_promo___Type, displayvalue: "name" })
      inserteditem.__cust_listViewType = getdropdownfieldvalue({ dropDownData: getAllListViewType(), field: "id", value: action.payload.__cust_listViewType, displayvalue: "name" })
      return { ...state, data: [inserteditem, ...state.data], columnData: ColumnData }

    case PAGES_SERVICE_DELETED:
      if (action.otherDetail === "multi_delete") {
        var FinalData = []
        var applyToDelete = action.payload.code.codes.map((a) => a.toString())
        if (action.response.data.meta.code === 403) {
          //Error
          var NotDelete = action.response.data.meta.message.map((a) => a.code.toString())
          FinalData = state.data.filter((item) => {
            item.code = item.code.toString()
            if (!(applyToDelete.includes(item.code) && !NotDelete.includes(item.code))) {
              return item
            }
            return false
          })
        } //All delete
        else {
          FinalData = state.data.filter((item) => {
            item.code = item.code.toString()
            if (!applyToDelete.includes(item.code)) {
              return item
            }
            return false
          })
        }
        return { ...state, data: FinalData, deleted: !state.deleted }
      } else {
        return { ...state, data: state.data.filter((item) => convertIntif(item.code) !== convertIntif(action.payload.code)), deleted: !state.deleted }
      }
    case PAGES_SERVICE_UPDATED:
      if (state.data.filter((e) => convertIntif(e.code) === convertIntif(action.payload.update_code)).length > 0) {
        let updateddata = state.data.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.payload.update_code)) {
            return item
          }
          const updateditem = { ...item }
          state.columnData.forEach((element) => {
            if (element.id !== "code") {
              if (!isblank(action.payload[element.id]) && action.payload[element.id].hasOwnProperty("name") && !isblank(action.payload[element.id].name)) {
                updateditem[element.id] = action.payload[element.id].name
              } else if (!isblank(action.payload[element.id])) {
                updateditem[element.id] = action.payload[element.id]
              } else {
                updateditem[element.id] = ""
              }
            }
          })
          // updateditem.__cust_status = action.payload.__cust_status.name
          // updateditem.__cust_limit = action.payload.__cust_limit.name
          updateditem.__cust_promo___Type = getdropdownfieldvalue({ dropDownData: getAllPromoOption(), field: "id", value: action.payload.__cust_promo___Type, displayvalue: "name" })
          updateditem.__cust_listViewType = getdropdownfieldvalue({ dropDownData: getAllListViewType(), field: "id", value: action.payload.__cust_listViewType, displayvalue: "name" })
          return updateditem
        })

        return { ...state, data: updateddata }
      } else {
        var ColumnData = state.columnData
        if (action.showInList.length > 0) {
          ColumnData = action.showInList.map((item) => {
            const updatedItem = {}
            updatedItem.id = item.name
            updatedItem.label = item.labelName
            updatedItem.visible = true
            updatedItem.numeric = parseInt(item.dataType) === cF.Decimal
            if (parseInt(item.dataType) === cF.Photo) {
              updatedItem.isImage = true
              updatedItem.disableSorting = true
              updatedItem.ifblank = ""
            }
            return updatedItem
          })
        }
        const inserteditem = {}
        action.showInList.forEach((element) => {
          if (!isblank(action.payload[element.name]) && action.payload[element.name].hasOwnProperty("name") && !isblank(action.payload[element.name].name)) {
            inserteditem[element.name] = action.payload[element.name].name
          } else if (!isblank(action.payload[element.name])) {
            inserteditem[element.name] = action.payload[element.name]
          }
        })
        inserteditem.code = action.payload.update_code

        if (action.payload.tablename === "Promo") {
          ColumnData = setPromoTypeColomData(ColumnData)
        }

        return { ...state, data: [inserteditem, ...state.data], columnData: ColumnData }
      }
    default:
      return state
  }
}
