import { Typography } from "@material-ui/core"
import driller from "@project/components/Images/driller.svg"
import { decryption } from "@project/components/cryptoJSFunctions"
import { isblank } from "@project/sharedcomponents/utilityFunctions"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { change_module } from "../../Actions"

function SwitchModule(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    var companyname = window.location.pathname.split("/")
    var company_data = companyname[2]
    company_data = company_data.replace(/[$]+/g, "/")
    company_data = decryption({ data: company_data })
    if (!isblank(company_data)) {
      const company_url = company_data[0].company_url
      const company_name = company_data[0].name
      const financial_year = company_data[0].year
      const user_name = company_data[0].user
      const token = company_data[0].token
      dispatch(change_module({ company_url, company_name, user_name, financial_year, token }))
    }
  }, [])

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#eee", height: "100vh" }}>
      <div style={{ width: 500 }}>
        <Typography variant="h1">Switching Module...</Typography>
        <Typography variant="h4">Please wait.</Typography>
      </div>

      <div style={{ width: 350, textAlign: "center" }}>
        <img src={driller} alt="Logo" />
      </div>
    </div>
  )
}

export default SwitchModule
