import { IMPORT_ACCOUNT_SERVICE_SUCCESS, IMPORT_ACCOUNT_SERVICE_FAIL, IMPORT_ACCOUNT_SERVICE_START, IMPORT_ACCOUNT_LOCAL_SEARCH } from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,

  data: [],
  totalcount: 0,
  rowsPerPage: 0,
  page: 0,
  order: "asc",
  orderBy: "name",
  search_text: "",
  columnData: [
    { id: "name", numeric: false, visible: true, label: "Name" },
    { id: "typename", numeric: false, visible: true, label: "Type", width: 100 },
    { id: "groupname", numeric: false, visible: true, label: "Group", width: 200, display: "customer_group_mng" },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IMPORT_ACCOUNT_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case IMPORT_ACCOUNT_LOCAL_SEARCH:
      return { ...state, search_text: action.search_text }
    case IMPORT_ACCOUNT_SERVICE_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        data: action.payload.data.result,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
        islogin: true,
      }
    case IMPORT_ACCOUNT_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        data: [],
        loading: false,
        totalcount: 0,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
      }
    default:
      return state
  }
}
