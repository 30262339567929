import { DatetoDMY, capitalizeString, convertIntif, getPageSize, isblank, numForCalc, replaceAllFn, replaceAllFnPrint, uppercaseString } from "./utilityFunctions"

// import demoData from "./demoDataTemplates/demoDataTemplate1"
// import demoData from "./demoDataTemplates/demoDataTemplate2"
// import demoData from "./demoDataTemplates/demoDataTemplate3"
// import demoData from "./demoDataTemplates/demoDataTemplate4"
// import demoData from "./demoDataTemplates/demoDataTemplate5"
// import demoData from "./demoDataTemplates/demoDataTemplate6"
// import demoData from "./demoDataTemplates/demoDataTemplate7"
// import demoData from "./demoDataTemplates/demoDataTemplate10"
// import demoData from "./demoDataTemplates/demoDataTemplate12"
// import demoData from "./demoDataTemplates/demoDataTemplate13"

import { getBodyString, getFooterString, getHeaderString, getParseValue } from "./printPDF/functions"

const replaceAllNull = (printData) => {
  Object.keys(printData).forEach((e) => {
    if (isblank(printData[e])) {
      printData[e] = ""
    } else if (typeof printData[e] === "object") {
      printData[e] = replaceAllNull(printData[e])
    }
  })
  return printData
}

export default ({ print_data, meta, user_data, functions }) => {
  const { getAccountSitesetting, getAccountSessionUserData, hideRateAmount, dd_country_state } = functions
  const textTransformation = getAccountSitesetting("textTransformation")
  const siteSetting = getAccountSitesetting(meta.type, user_data).filter((e) => e.id === meta.invoice_type)

  const capitalizeIfRequire = (val) => {
    if (textTransformation === "u") {
      return uppercaseString(val)
    } else if (textTransformation === "c") {
      return capitalizeString(val)
    }
    return val
  }

  let isChallan = false
  if (meta && meta.otherDetail && meta.otherDetail.isChallan) {
    isChallan = true
  }
  const printdata = replaceAllNull(print_data)
  var invoice_template = {}
  let pre_printed_header = 0

  if (meta && meta.otherDetail && parseInt(meta.otherDetail.pre_printed_header) === 1) {
    pre_printed_header = 1
  }

  var MasterCustomField = printdata.custom_fields_data_master
  var DetailCustomField = printdata.custom_fields_data
  var irn = printdata.master_table_data[0].irn
  var isCommercialCDN = printdata.master_table_data[0].invoiceType === "CO"
  var isVoucherCDN = printdata.master_table_data[0].invoiceType === "VP" || printdata.master_table_data[0].invoiceType === "VS"

  /* start open for template design */
  // printdata.template_setting.header = demoData.header
  // printdata.template_setting.body = demoData.body
  // printdata.template_setting.footer = demoData.footer
  // printdata.template_setting.setting = demoData.setting
  /*  end open for template design */

  printdata.template_setting.setting.bottomheaderheightVariation = {
    font9: 20,
    font85: 8,
  }

  var Grid_Line = 0
  var Print_copy = 1
  var pdfFontSizeSelectedValue = 8
  var repeat_header = 0
  let pageSize = "A4"
  let page_width = ""
  let page_top_margin = ""
  let page_bottom_margin = ""
  let page_left_margin = ""
  let page_right_margin = ""

  var headerFixHeight = ""
  let prePrintedheaderFixHeight = ""
  let prePrintedHeaderSettings = {}

  if (siteSetting.length > 0) {
    if (meta.type === "invoice_type_detail") {
      Print_copy = parseInt(siteSetting[0].invoicePrintCopiesSelectedValue)
    }
    if (meta.otherDetail && meta.otherDetail.printCopy && meta.otherDetail.printCopy === "single") {
      Print_copy = 1
    }

    pdfFontSizeSelectedValue = siteSetting[0].pdfFontSizeSelectedValue
    repeat_header = parseInt(siteSetting[0].repeat_header)
    pageSize = getPageSize(siteSetting[0].template_size)
    Grid_Line = parseInt(siteSetting[0].grid_line)
    headerFixHeight = siteSetting[0].headerFixHeight
    prePrintedheaderFixHeight = siteSetting[0].prePrintedheaderFixHeight
    page_width = siteSetting[0].page_width ? siteSetting[0].page_width : 225
    prePrintedHeaderSettings = getParseValue(siteSetting[0].pre_printed_header_settings)

    page_top_margin = siteSetting[0].header_top_margin ? parseFloat(siteSetting[0].header_top_margin) : 15
    page_bottom_margin = siteSetting[0].page_bottom_margin ? parseFloat(siteSetting[0].page_bottom_margin) : 20
    page_left_margin = siteSetting[0].page_left_margin ? parseFloat(siteSetting[0].page_left_margin) : 15
    page_right_margin = siteSetting[0].page_right_margin ? parseFloat(siteSetting[0].page_right_margin) : 15

    if (isblank(pdfFontSizeSelectedValue)) {
      pdfFontSizeSelectedValue = 8
    }
  }

  pdfFontSizeSelectedValue = parseFloat(pdfFontSizeSelectedValue)

  // Header Section
  const _header = getHeaderString({ printdata, getAccountSitesetting, user_data, meta, capitalizeIfRequire, dd_country_state, isCommercialCDN, isVoucherCDN })
  var headerString = _header.headerString
  const mainHeadingText = _header.mainHeadingText
  const invoice_title = _header.invoice_title
  const company_tax = _header.company_tax
  const company_state_code = _header.company_state_code
  const company_gstin = _header.company_gstin

  var addressFontSize = printdata.template_setting.setting.addressFontSize
  if (isblank(addressFontSize)) {
    addressFontSize = pdfFontSizeSelectedValue
  }

  let processName = ""
  if (meta.invoice_type === "job_process_invoice") {
    printdata.details_table_data.forEach((e) => {
      if (!isblank(e.processName)) {
        if (!isblank(processName)) {
          if (processName.indexOf(e.processName) < 0) {
            processName = `${processName}, ${e.processName}`
          }
        } else {
          processName = e.processName
        }
      }
    })
    if (!isblank(processName)) {
      MasterCustomField.push({ showInPrint: "1", labelName: "From Process", name: "processName" })
      printdata.master_table_data[0].processName = processName
    }
  }

  //Original Copy

  var defaultBtmHdrHit = !isblank(printdata.template_setting.setting.defaultBtmHdrHit) ? parseFloat(printdata.template_setting.setting.defaultBtmHdrHit) : 98
  var defaultBtmHdrHit2 = !isblank(printdata.template_setting.setting.defaultBtmHdrHit2) ? parseFloat(printdata.template_setting.setting.defaultBtmHdrHit2) : 98
  var defaultTpHdrHit = !isblank(printdata.template_setting.setting.defaultTpHdrHit) ? parseFloat(printdata.template_setting.setting.defaultTpHdrHit) : 80
  var default_FtrHitGridLine = !isblank(printdata.template_setting.setting.default_FtrHitGridLine) ? parseFloat(printdata.template_setting.setting.default_FtrHitGridLine) : 256.5
  var default_FtrHit = !isblank(printdata.template_setting.setting.default_FtrHit) ? parseFloat(printdata.template_setting.setting.default_FtrHit) : 280.5
  var content4Height = !isblank(printdata.template_setting.setting.content4Height) ? parseFloat(printdata.template_setting.setting.content4Height) : 80
  var headerOtherHeight = !isblank(printdata.template_setting.setting.headerOtherHeight) ? parseFloat(printdata.template_setting.setting.headerOtherHeight) : 43
  var defaultFtrHit = Grid_Line === 1 || Grid_Line === 3 ? default_FtrHitGridLine : default_FtrHit
  var irnPosition = !isblank(printdata.template_setting.setting.irnPosition) ? parseFloat(printdata.template_setting.setting.irnPosition) : 1

  var detailColumnCustomField = DetailCustomField.filter((item) => item.showInPrint === "1" && item.printWithItem === "0")
  var detailColumnBfrItmCustomField = DetailCustomField.filter((item) => item.showInPrint === "1" && item.printWithItem === "3")

  // Body Section
  const _body = getBodyString({
    printdata,
    getAccountSitesetting,
    user_data,
    meta,
    hideRateAmount,
    detailColumnCustomField,
    detailColumnBfrItmCustomField,
    functions,
    company_tax,
    company_state_code,
    isCommercialCDN,
    isVoucherCDN,
  })
  var bodyString = _body.bodyString
  const Headers = _body.Headers
  const metaData = _body.metaData
  const customAddIndex = _body.customAddIndex
  const customBfrAddIndex = _body.customBfrAddIndex

  invoice_template.footerheight = defaultFtrHit
  invoice_template.topheaderheight = defaultTpHdrHit
  invoice_template.bottomheaderheight = defaultBtmHdrHit
  invoice_template.bottomheaderheight2 = defaultBtmHdrHit2

  var bottomheaderheightVariation = printdata.template_setting.setting.bottomheaderheightVariation
  var headerOtherHeightVariation = printdata.template_setting.setting.headerOtherHeightVariation
  var bottomheaderheight2Variation = printdata.template_setting.setting.bottomheaderheight2Variation

  if (pdfFontSizeSelectedValue === 9) {
    invoice_template.bottomheaderheight = defaultBtmHdrHit + bottomheaderheightVariation.font9

    if (parseFloat(defaultBtmHdrHit2) > 0) {
      invoice_template.bottomheaderheight2 = defaultBtmHdrHit2 + isblank(bottomheaderheight2Variation) ? 0 : bottomheaderheight2Variation.font9
    }

    headerOtherHeight = headerOtherHeight + isblank(headerOtherHeightVariation) ? 0 : headerOtherHeightVariation.font9
  }
  if (pdfFontSizeSelectedValue === 8.5) {
    invoice_template.bottomheaderheight = defaultBtmHdrHit + bottomheaderheightVariation.font85
    if (parseFloat(defaultBtmHdrHit2) > 0) {
      invoice_template.bottomheaderheight2 = defaultBtmHdrHit2 + isblank(bottomheaderheight2Variation) ? 0 : bottomheaderheight2Variation.font85
    }
    headerOtherHeight = headerOtherHeight + isblank(headerOtherHeightVariation) ? 0 : headerOtherHeightVariation.font85
  }

  if (meta.other_type === "salesreturn" || meta.other_type === "purchasereturn") {
    headerOtherHeight = headerOtherHeight + isblank(headerOtherHeightVariation) ? 0 : headerOtherHeightVariation.crordb
  }

  // page_top_margin = invoice_template.topheaderheight + invoice_template.bottomheaderheight + invoice_template.bottomheaderheight2 + headerOtherHeight

  invoice_template.topheader = {}
  invoice_template.header = {}
  invoice_template.body = {}
  invoice_template.footer = {}

  headerString = JSON.stringify(headerString)

  if (!isblank(headerFixHeight) || repeat_header === 0) {
    headerString = replaceAllFn(headerString, '"{headrer1Height}"', '"*"')
    headerString = replaceAllFn(headerString, '"{headrer1Height}"', '"*"')
    headerString = replaceAllFn(headerString, '"{headrer1Height}"', '"*"')
    headerString = replaceAllFn(headerString, '"{headrer3Height}"', '"*"')
    headerString = replaceAllFn(headerString, '"{headrer3Height}"', '"*"')
    headerString = replaceAllFn(headerString, '"{headrer3Height}"', '"*"')
    headerString = replaceAllFn(headerString, '"{headrer3Height2}"', '"*"')
    headerString = replaceAllFn(headerString, '"{headrer3Height2}"', '"*"')
    headerString = replaceAllFn(headerString, '"{headrer3Height2}"', '"*"')
    headerString = JSON.parse(headerString)
  } else {
    headerString = replaceAllFn(headerString, '"{headrer1Height}"', invoice_template.topheaderheight)
    headerString = replaceAllFn(headerString, '"{headrer1Height}"', invoice_template.topheaderheight)
    headerString = replaceAllFn(headerString, '"{headrer1Height}"', invoice_template.topheaderheight)
    headerString = replaceAllFn(headerString, '"{headrer3Height}"', invoice_template.bottomheaderheight)
    headerString = replaceAllFn(headerString, '"{headrer3Height}"', invoice_template.bottomheaderheight)
    headerString = replaceAllFn(headerString, '"{headrer3Height}"', invoice_template.bottomheaderheight)
    headerString = replaceAllFn(headerString, '"{headrer3Height2}"', invoice_template.bottomheaderheight2)
    headerString = replaceAllFn(headerString, '"{headrer3Height2}"', invoice_template.bottomheaderheight2)
    headerString = replaceAllFn(headerString, '"{headrer3Height2}"', invoice_template.bottomheaderheight2)
    headerString = JSON.parse(headerString)
  }

  var headerContent = []
  var headerContent1 = []
  var headerContent2 = []
  var headerContent3 = []

  if (pre_printed_header === 1) {
    const heading_x = parseFloat(prePrintedHeaderSettings.heading_x)
    const heading_y = parseFloat(prePrintedHeaderSettings.heading_y)
    const heading_alignment = prePrintedHeaderSettings.heading_alignment
    const enable_preheading = prePrintedHeaderSettings.enable_preheading

    headerString = headerString.filter((e) => e.header_key !== "logo_header" && e.header_key !== "main_heading_row")

    if (enable_preheading === "1") {
      // Add new stack for heading and gst no
      headerString.unshift({
        stack: [
          { text: mainHeadingText, style: "head_center_text_2", bold: true, alignment: heading_alignment },
          { text: company_tax === "GSTIN" ? `${company_tax}: ${company_gstin}` : "", style: "heading", alignment: heading_alignment },
        ],
        absolutePosition: { x: heading_x, y: heading_y },
      })
    }

    if (repeat_header === 1) {
      headerString = headerString.map((e, iindex) => {
        const updated = { ...e }
        if (iindex === 0) {
          if (updated.margin) {
            updated.margin = [page_left_margin, page_top_margin, page_right_margin, updated.margin[3]]
          } else {
            updated.margin = [page_left_margin, page_top_margin, page_right_margin, 0]
          }
        } else {
          if (updated.margin) {
            updated.margin = [page_left_margin, updated.margin[1], page_right_margin, updated.margin[3]]
          } else {
            updated.margin = [page_left_margin, 0, page_right_margin, 0]
          }
        }
        return updated
      })
    } else {
      page_top_margin = parseFloat(prePrintedHeaderSettings.top_margin)
      page_bottom_margin = parseFloat(prePrintedHeaderSettings.bottom_margin)
      page_left_margin = parseFloat(prePrintedHeaderSettings.left_margin)
      page_right_margin = parseFloat(prePrintedHeaderSettings.right_margin)
    }
  }

  if (!isblank(irn) && !isChallan) {
    var headerPart1 = headerString.filter((it, index) => index < irnPosition)
    var headerPart2 = headerString.filter((it, index) => index >= irnPosition)
    var e_invoiceDetail = JSON.parse(printdata.master_table_data[0].e_invoiceDetail)
    var ack_number = e_invoiceDetail.AckNo
    var ack_date = e_invoiceDetail.AckDt.split(" ")
    ack_date = DatetoDMY(ack_date[0])
    var signedQRCode = `data:image/png;base64,${e_invoiceDetail.qrcode_data}`

    if (!isblank(e_invoiceDetail.qrcode_data)) {
      signedQRCode = {
        image: signedQRCode,
        fit: [90, 90],
        alignment: "right",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
      }
    } else {
      signedQRCode = {}
    }

    var irnNumberPart = {
      style: "tableExample",
      table: {
        widths: ["*", "auto"],
        body: [
          [
            {
              stack: [
                {
                  columns: [
                    { width: "auto", text: "IRN: ", style: "valueField", margin: [0, 0, 5, 0] },
                    { width: "*", text: irn, bold: true, style: "valueField" },
                  ],
                },
                {
                  columns: [
                    { width: "auto", text: "Ack No.: ", style: "valueField", margin: [0, 0, 5, 0] },
                    { width: "*", text: ack_number, bold: true, style: "valueField" },
                  ],
                },
                {
                  columns: [
                    { width: "auto", text: "Ack Date: ", style: "valueField", margin: [0, 0, 5, 0] },
                    { width: "*", text: ack_date, bold: true, style: "valueField" },
                  ],
                },
              ],
              margin: [0, 10, 0, 0],
            },
            signedQRCode,
          ],
        ],
      },
      margin: [0, 0, 0, 5],
      layout: "noBorders",
    }
    headerString = [...headerPart1, irnNumberPart, ...headerPart2]
  }

  if (repeat_header === 0) {
    headerString = headerString.map((item) => {
      if (!isblank(item.margin) && item.margin.length === 4) {
        item.margin = [0, 0, 0, item.margin[3]]
      } else {
        item.margin = [0, 0, 0, 0]
      }
      return item
    })
    var headerContent1 = JSON.stringify(headerString)
    var headerContent2 = JSON.stringify(headerString)
    var headerContent3 = JSON.stringify(headerString)

    headerContent1 = replaceAllFnPrint(headerContent1, "{left_text}", invoice_title)
    headerContent1 = replaceAllFnPrint(headerContent1, "{center_text}", mainHeadingText)
    headerContent2 = replaceAllFnPrint(headerContent2, "{left_text}", invoice_title)
    headerContent2 = replaceAllFnPrint(headerContent2, "{center_text}", mainHeadingText)
    headerContent3 = replaceAllFnPrint(headerContent3, "{left_text}", invoice_title)
    headerContent3 = replaceAllFnPrint(headerContent3, "{center_text}", mainHeadingText)
    if (Print_copy === 2 || Print_copy === 3) {
      headerContent1 = replaceAllFnPrint(headerContent1, "{right_text}", "Original")
      headerContent2 = replaceAllFnPrint(headerContent2, "{right_text}", "Duplicate")
      headerContent3 = replaceAllFnPrint(headerContent3, "{right_text}", "Triplicate")
    } else {
      headerContent1 = replaceAllFnPrint(headerContent1, "{right_text}", "")
    }
    headerContent1 = JSON.parse(headerContent1)
    headerContent2 = JSON.parse(headerContent2)
    headerContent3 = JSON.parse(headerContent3)
  } else {
    if (pre_printed_header === 1) {
      if (!isblank(prePrintedheaderFixHeight)) {
        page_top_margin = parseFloat(prePrintedheaderFixHeight)
      }
    } else {
      if (!isblank(headerFixHeight)) {
        page_top_margin = parseFloat(headerFixHeight)
      }
    }
    headerContent = headerString
  }

  var finalContent = bodyString

  // Footer Section
  const _footer = getFooterString({
    printdata,
    getAccountSitesetting,
    user_data,
    meta,
    capitalizeIfRequire,
    dd_country_state,
    hideRateAmount,
    Headers,
    detailColumnBfrItmCustomField,
    detailColumnCustomField,
    metaData,
    customAddIndex,
    customBfrAddIndex,
  })
  var footerString = _footer.footerString

  if (!isblank(headerFixHeight) || repeat_header === 0) {
    footerString = JSON.stringify(footerString)
    footerString = replaceAllFn(footerString, '"{footerTable2Height}"', '"*"')
    footerString = replaceAllFn(footerString, '"{footerTable2Height}"', '"*"')
    footerString = JSON.parse(footerString)
  } else {
    footerString = JSON.stringify(footerString)
    footerString = replaceAllFn(footerString, '"{footerTable2Height}"', content4Height)
    footerString = replaceAllFn(footerString, '"{footerTable2Height}"', content4Height)
    footerString = JSON.parse(footerString)
  }

  finalContent = finalContent.concat(footerString)

  var finalContent1 = []
  var finalContent2 = []
  var finalContent3 = []
  if (Print_copy === 2 || Print_copy === 3) {
    finalContent2 = finalContent.map((item) => {
      if (item.hasOwnProperty("layout")) {
        var updatedItem = { ...item }
        Object.keys(item).forEach((it) => {
          if (it !== "layout") {
            updatedItem[it] = JSON.parse(JSON.stringify(updatedItem[it]))
          }
        })
        return updatedItem
      }
      return JSON.parse(JSON.stringify(item))
    })
  }
  if (Print_copy === 3) {
    finalContent3 = finalContent.map((item) => {
      if (item.hasOwnProperty("layout")) {
        var updatedItem = { ...item }
        Object.keys(item).forEach((it) => {
          if (it !== "layout") {
            updatedItem[it] = JSON.parse(JSON.stringify(updatedItem[it]))
          }
        })
        return updatedItem
      }
      return JSON.parse(JSON.stringify(item))
    })
  }

  finalContent1 = finalContent.map((item) => {
    if (item.hasOwnProperty("layout")) {
      var updatedItem = { ...item }
      Object.keys(item).forEach((it) => {
        if (it !== "layout") {
          updatedItem[it] = JSON.parse(JSON.stringify(updatedItem[it]))
        }
      })
      return updatedItem
    }
    return JSON.parse(JSON.stringify(item))
  })

  if (repeat_header === 0) {
    finalContent1 = [...headerContent1, ...finalContent1]
    finalContent2 = [...headerContent2, ...finalContent2]
    finalContent3 = [...headerContent3, ...finalContent3]
  }

  var printContent = []
  var pageBreak = { text: "", pageBreak: "after" }
  if (Print_copy === 2) {
    printContent = [...finalContent1, pageBreak, ...finalContent2]
  } else if (Print_copy === 3) {
    printContent = [...finalContent1, pageBreak, ...finalContent2, pageBreak, ...finalContent3]
  } else {
    printContent = [...finalContent1]
  }

  let customStyles = {}
  if (printdata.template_setting.setting.styles) {
    customStyles = { ...printdata.template_setting.setting.styles }
  }

  // let _pageMargin = [page_left_margin, invoice_template.headerheight, invoice_template.rightMargin, invoice_template.footerheight - 10]
  let _pageMargin = [page_left_margin, page_top_margin, page_right_margin, page_bottom_margin]

  if (pageSize === "Auto") {
    pageSize = {
      width: parseFloat(page_width),
      height: "auto",
    }
  }

  var repeat = 0
  var dd = {
    pageSize: pageSize,
    pageOrientation: "portrait",
    pageMargins: _pageMargin,
    header: function (currentPage, pageCount) {
      var rightText = ""
      var headerContent1 = JSON.stringify(headerContent)
      var headerContent2 = JSON.stringify(headerContent)
      var headerContent3 = JSON.stringify(headerContent)
      headerContent1 = replaceAllFnPrint(headerContent1, "{left_text}", invoice_title)
      headerContent1 = replaceAllFnPrint(headerContent1, "{center_text}", mainHeadingText)
      headerContent2 = replaceAllFnPrint(headerContent2, "{left_text}", invoice_title)
      headerContent2 = replaceAllFnPrint(headerContent2, "{center_text}", mainHeadingText)
      headerContent3 = replaceAllFnPrint(headerContent3, "{left_text}", invoice_title)
      headerContent3 = replaceAllFnPrint(headerContent3, "{center_text}", mainHeadingText)
      if (Print_copy === 2) {
        var Count = pageCount / 2
        if (currentPage <= Count) {
          rightText = "Original"
          headerContent1 = replaceAllFnPrint(headerContent1, "{right_text}", rightText)
          headerContent1 = JSON.parse(headerContent1)
          return headerContent1
        } else if (currentPage <= Count * 2) {
          rightText = "Duplicate"
          headerContent2 = replaceAllFnPrint(headerContent2, "{right_text}", rightText)
          headerContent2 = JSON.parse(headerContent2)
          return headerContent2
        }
      } else if (Print_copy === 3) {
        var Count = pageCount / 3
        if (currentPage <= Count) {
          rightText = "Original"
          headerContent1 = replaceAllFnPrint(headerContent1, "{right_text}", rightText)
          headerContent1 = JSON.parse(headerContent1)
          return headerContent1
        } else if (currentPage <= Count * 2) {
          rightText = "Duplicate"
          headerContent2 = replaceAllFnPrint(headerContent2, "{right_text}", rightText)
          headerContent2 = JSON.parse(headerContent2)
          return headerContent2
        } else if (currentPage <= Count * 3) {
          rightText = "Triplicate"
          headerContent3 = replaceAllFnPrint(headerContent3, "{right_text}", rightText)
          headerContent3 = JSON.parse(headerContent3)
          return headerContent3
        }
      } else {
        headerContent1 = replaceAllFnPrint(headerContent1, "{right_text}", "")
        headerContent1 = JSON.parse(headerContent1)
        return headerContent1
      }
    },
    footer: function (currentPage, pageCount) {
      if (Print_copy === 2) {
        var Count = pageCount / 2
        if (repeat === Count) {
          repeat = 0
        }
        if (currentPage <= Count) {
          repeat = repeat + 1
        } else if (currentPage <= Count * 2) {
          repeat = repeat + 1
        }
      } else if (Print_copy === 3) {
        var Count = pageCount / 3
        if (repeat === Count) {
          repeat = 0
        }
        if (currentPage <= Count) {
          repeat = repeat + 1
          rightText = ``
        } else if (currentPage <= Count * 2) {
          repeat = repeat + 1
          rightText = ``
        } else if (currentPage <= Count * 3) {
          repeat = repeat + 1
          rightText = ``
        }
      } else {
        repeat = repeat + 1
      }
      var rightText = ""
      if (Print_copy === 2) {
        if (pageCount !== 2) {
          var Count = pageCount / 2
          rightText = `Pages ${repeat}/${Count}`
        }
      } else if (Print_copy === 3) {
        if (pageCount !== 3) {
          var Count = pageCount / 3
          rightText = `Pages ${repeat}/${Count}`
        }
      } else {
        if (pageCount !== 1) {
          rightText = `Pages ${repeat}/${pageCount}`
        }
      }
      if (pageCount < 2) {
        rightText = ""
      }
      return [
        {
          columns: [{ text: "" }, { text: "" }, { text: rightText, alignment: "right" }],
          margin: [page_left_margin, page_top_margin, page_right_margin, page_bottom_margin],
        },
      ]
    },
    defaultStyle: {
      fontSize: parseFloat(pdfFontSizeSelectedValue),
    },
    content: printContent,
    styles: {
      invoiceCompanySign: {
        height: 100,
      },
      left_text: {
        fontSize: pdfFontSizeSelectedValue + 1,
      },
      center_text: {
        fontSize: pdfFontSizeSelectedValue + 1,
        alignment: "center",
      },
      right_text: {
        fontSize: pdfFontSizeSelectedValue + 1,
        alignment: "right",
      },
      termsandCondition: {
        fontSize: pdfFontSizeSelectedValue - 1,
      },
      fontSizePlusOne: {
        fontSize: pdfFontSizeSelectedValue + 1,
      },
      heading: {
        fontSize: pdfFontSizeSelectedValue + 1,
      },
      center: {
        alignment: "center",
      },
      right: {
        alignment: "right",
      },
      left: {
        alignment: "left",
      },
      // Invoice Header
      headerLogo: {
        margin: [5, 5, 5, 5],
      },
      masterCustomFields: {
        margin: [0, 0, 0, 10],
      },
      headerCompanyInfo: {
        margin: [5, 5, 5, 5],
      },
      headerInvoiceDetails: {
        margin: [5, 5, 5, 5],
      },
      companyAddress: {
        fontSize: pdfFontSizeSelectedValue + 1,
      },
      valueField: {
        fontSize: pdfFontSizeSelectedValue + 1,
        margin: [0, 0, 0, 2],
      },
      head_left_text: {
        fontSize: pdfFontSizeSelectedValue + 1,
      },
      head_center_text: {
        fontSize: pdfFontSizeSelectedValue + 2,
        alignment: "center",
      },
      head_center_text_2: {
        fontSize: pdfFontSizeSelectedValue + 4,
        alignment: "center",
      },
      head_right_text: {
        fontSize: pdfFontSizeSelectedValue + 1,
        alignment: "right",
      },
      invoiceAddress: {
        fontSize: addressFontSize,
        alignment: "left",
        margin: [0, 3, 0, 0],
      },
      irn_number: {
        // margin: [15, 15, 15, 15],
      },
      companyName: {
        bold: true,
        fontSize: pdfFontSizeSelectedValue + 1,
      },
      companyNameWithoutLogo: {
        bold: true,
        fontSize: pdfFontSizeSelectedValue + 2,
      },
      invoiceCompanyName: {
        bold: true,
        fontSize: pdfFontSizeSelectedValue + 1,
      },
      // Document Header
      documentHeaderLeft: {
        margin: [0, 0, 10, 0],
        alignment: "left",
      },
      documentHeaderCenter: {
        fontSize: pdfFontSizeSelectedValue + 1,
        margin: [10, 0, 10, 0],
        alignment: "center",
      },
      outwardBillDate: {
        margin: [0, 0, 10, 5],
        alignment: "right",
      },
      invoicetype: {
        fontSize: 20,
        bold: true,
        alignment: "right",
        // margin: [0, 5, 0, 0]
      },
      documentHeaderRight: {
        margin: [0, 0, 0, 0],
        alignment: "right",
      },
      // Document Footer
      documentFooterLeft: {
        margin: [5, 5, 5, 5],
        alignment: "left",
      },
      documentFooterCenter: {
        margin: [2, 5, 2, 2],
        alignment: "center",
      },
      documentFooterRight: {
        margin: [5, 5, 5, 5],
        alignment: "right",
      },
      signatureBox: {
        margin: [0, 20],
      },
      ...customStyles,
    },
  }
  return dd
}
