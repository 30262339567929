import { generateTableData } from "./common"
import { exempt_supplies, interstate_supplies, itc, outward_supplies } from "./gstFileFunctions"
import { amount_format, isblank, replaceBRtoNewLine, replacefunction } from "./utilityFunctions"
const gstReportAmountFormat = (value) => {
  if (isblank(value)) {
    return ""
  }
  if (value === "--") {
    return "--"
  }
  return amount_format(value)
}

export default ({ data, meta, user_data, functions }) => {
  const { getAccountSessionUserData, getAccountSitesetting, getquarter } = functions

  var companyName = getAccountSessionUserData("company_name", user_data)
  var aliasName = getAccountSessionUserData("company_alias", user_data)
  var company_address1 = replacefunction(replaceBRtoNewLine(getAccountSessionUserData("company_address1", user_data)))
  var company_street = replacefunction(replaceBRtoNewLine(getAccountSessionUserData("company_street", user_data)))
  var company_address2 = replacefunction(replaceBRtoNewLine(getAccountSessionUserData("company_address2", user_data)))

  var invoice_template = {}
  invoice_template.topheaderheight = 25
  invoice_template.bottomheaderheight = 20
  invoice_template.headerheight = 95
  invoice_template.topheader = {}
  invoice_template.header = {}
  invoice_template.body = {}
  invoice_template.footer = {}
  const column1 = [
    { id: "IGST_Amt", label: "IGST", currencySign: true, numeric: true },
    { id: "IGST_Amt", label: "CGST", currencySign: true, numeric: true },
    { id: "IGST_Amt", label: "SGST", currencySign: true, numeric: true },
  ]
  var IGST_Amt = "  "
  var CGST_Amt = "  "
  var SGST_Amt = "  "
  if (!isblank(data.estimated_gst)) {
    IGST_Amt = gstReportAmountFormat(data.estimated_gst.IGST_Amt)
    CGST_Amt = gstReportAmountFormat(data.estimated_gst.CGST_Amt)
    SGST_Amt = gstReportAmountFormat(data.estimated_gst.SGST_Amt)
  }
  var HeadersObject1 = generateHeaders(column1, getAccountSitesetting, user_data)
  var Headers1 = HeadersObject1.Headers
  var widthArray1 = HeadersObject1.width
  var data1 = [
    {
      IGST_Amt,
      CGST_Amt,
      SGST_Amt,
    },
  ]
  var Values1 = generateTableData({ data: data1, mycol: column1, user_data, requireTotal: false, totalDifference: false, functions })

  var HeadersObject2 = generateHeaders(outward_supplies, getAccountSitesetting, user_data)
  var Headers2 = HeadersObject2.Headers
  var widthArray2 = HeadersObject2.width
  var data2 = data.outward_supplies
  var Values2 = generateTableData({ data: data2, mycol: outward_supplies, user_data, requireTotal: false, totalDifference: false, functions })

  var HeadersObject3 = generateHeaders(interstate_supplies, getAccountSitesetting, user_data)
  var Headers3 = HeadersObject3.Headers
  var widthArray3 = HeadersObject3.width
  var data3 = data.interstate_supplies
  var Values3 = generateTableData({ data: data3, mycol: interstate_supplies, user_data, requireTotal: false, totalDifference: false, functions })

  var HeadersObject4 = generateHeaders(itc, getAccountSitesetting, user_data)
  var Headers4 = HeadersObject4.Headers
  var widthArray4 = HeadersObject4.width
  var data4 = data.itc
  var Values4 = generateTableData({ data: data4, mycol: itc, user_data, requireTotal: false, totalDifference: false, functions })

  var HeadersObject5 = generateHeaders(exempt_supplies, getAccountSitesetting, user_data)
  var Headers5 = HeadersObject5.Headers
  var widthArray5 = HeadersObject5.width
  var data5 = data.exempt_supplies
  var Values5 = generateTableData({ data: data5, mycol: exempt_supplies, user_data, requireTotal: false, totalDifference: false, functions })

  if (isblank(company_street)) {
    company_street = ""
  }
  if (isblank(company_address2)) {
    company_address2 = ""
  }
  company_address2 = `${company_street} ${company_address2}`

  var dd = {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [15, invoice_template.headerheight, 15, 20],

    header: [
      { text: companyName, style: "companyName", bold: true },
      { text: aliasName, style: "companyAddress", bold: true },
      { text: company_address1, style: "companyAddress", bold: true },
      { text: company_address2, style: "companyAddress", bold: true },
      { text: "GSTR-3B Summary", style: "companyAddress", bold: true },
      { text: `GSTR-3B Summary From: ${meta.From} To: ${meta.To}`, style: "companyAddress" },
    ],
    footer: [],
    content: [
      { text: "Estimated GST Payable", alignment: "center", fontSize: 9, margin: [0, 0, 0, 2] },
      {
        table: {
          headerRows: 1,
          widths: widthArray1,
          body: [Headers1, ...Values1],
        },
        layout: {
          hLineWidth: function (i, node) {
            return 1
          },
          vLineWidth: function (i, node) {
            return 1
          },
        },
      },
      { text: "3.1 Details of outward supplies and inward supplies liable to reverse charge", fontSize: 9, margin: [0, 10, 0, 2] },
      {
        table: {
          headerRows: 1,
          widths: widthArray2,
          body: [Headers2, ...Values2],
        },
        layout: {
          hLineWidth: function (i, node) {
            return 1
          },
          vLineWidth: function (i, node) {
            return 1
          },
        },
      },

      {
        text: "3.2 Of the supplies shown in 3.1 (a) above, details of inter-State supplies made to unregistered persons composition taxable persons and UIN holders",
        fontSize: 9,
        margin: [0, 10, 0, 2],
      },
      {
        table: {
          headerRows: 1,
          widths: widthArray3,
          body: [Headers3, ...Values3],
        },
        layout: {
          hLineWidth: function (i, node) {
            return 1
          },
          vLineWidth: function (i, node) {
            return 1
          },
        },
      },

      { text: "4. Eligible ITC", fontSize: 9, margin: [0, 10, 0, 2] },
      {
        table: {
          headerRows: 1,
          widths: widthArray4,
          body: [Headers4, ...Values4],
        },
        layout: {
          hLineWidth: function (i, node) {
            return 1
          },
          vLineWidth: function (i, node) {
            return 1
          },
        },
      },

      { text: "5. Exempt, nil and Non GST inward supplies", fontSize: 9, margin: [0, 10, 0, 2] },
      {
        table: {
          headerRows: 1,
          widths: widthArray5,
          body: [Headers5, ...Values5],
        },
        layout: {
          hLineWidth: function (i, node) {
            return 1
          },
          vLineWidth: function (i, node) {
            return 1
          },
        },
      },
    ],

    defaultStyle: {
      fontSize: 8,
    },
    styles: {
      center: {
        alignment: "center",
      },
      right: {
        alignment: "right",
      },
      left: {
        alignment: "left",
      },
      companyAddress: {
        alignment: "center",
        fontSize: 10,
      },
      companyName: {
        bold: true,
        fontSize: 12,
        alignment: "center",
        margin: [0, 15, 0, 0],
      },
    },
  }
  return dd
}

const generateHeaders = (columns, getAccountSitesetting, user_data) => {
  const currency = getAccountSitesetting("currency", user_data)
  var HeaderObject = {
    width: [],
  }
  HeaderObject.Headers = columns.map((e) => {
    const newItem = {
      bold: true,
      fillColor: "#ffffff",
    }
    if (e.numeric) {
      newItem.alignment = "right"
    }
    if (e.currencySign) {
      newItem.text = `${e.label}(${currency})`
    } else {
      newItem.text = `${e.label}`
    }
    if (e.width) {
      HeaderObject.width.push(e.width * 0.4)
    } else {
      HeaderObject.width.push("*")
    }
    return newItem
  })
  return HeaderObject
}
