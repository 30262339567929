import { convertIntif } from "@project/sharedcomponents/utilityFunctions"
import { OPSTOCK_LOCAL_SEARCH, OPSTOCK_SERVICE_CLEAR, OPSTOCK_SERVICE_FAIL, OPSTOCK_SERVICE_START, OPSTOCK_SERVICE_SUCCESS, OPSTOCK_SERVICE_UPDATED } from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  other_type: "",
  search_text: "",
  columnData: [
    { id: "name", numeric: false, visible: true, total: "label", label: "Description of Goods", bold: { valueblank: ["code"] } },
    {
      id: "opstock",
      numeric: true,
      visible: true,
      total: true,
      disableResizing: true,
      label: "Stock",
      toggle: true,
      popover: { key: "updateQty", placeholder: "Enter Qty", label: "Qty", name: "opstock", title: "Update Opening Stock", number: true },
      width: 200,
      format: "qty_decimal",
      bold: { valueblank: ["code"] },
    },
    {
      id: "opvalue",
      numeric: true,
      visible: true,
      total: true,
      disableResizing: true,
      label: "Stock Value",
      toggle: true,
      popover: { key: "updateValue", placeholder: "Enter Value", label: "Value", name: "opvalue", title: "Update Stock Value", number: true },
      width: 200,
      format: "amount_format",
      bold: { valueblank: ["code"] },
    },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPSTOCK_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case OPSTOCK_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case OPSTOCK_SERVICE_SUCCESS:
      return { ...state, ...INITIAL_STATE, data: action.payload.data.result.rows, loading: false, other_type: action.other_type, search_text: action.search_text }
    case OPSTOCK_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false, other_type: action.other_type, search_text: action.search_text }
    case OPSTOCK_LOCAL_SEARCH:
      return { ...state, search_text: action.search_text }
    case OPSTOCK_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        const updateditem = { ...item }
        if (convertIntif(item.code) === convertIntif(action.payload.code)) {
          updateditem[action.payload.field] = action.payload.value
        }
        return updateditem
      })
      return { ...state, data: updateddata }
    default:
      return state
  }
}
