import { generatePdfDoc } from "@project/components/pdfmakeFunctions"
import { getStorage, getsessionuserdata, setStorage } from "@project/components/sessionStoreFunctions"
import { getAllStatus, getdropdownvalue, getproducttype, getproducttypegold, makedropdowncompatible } from "@project/components/utilityFunctions"
import { list_url, post_form_url } from "@project/sharedcomponents/apiURL"
import { postSinglePhotoURL as common_postSinglePhotoURL } from "@project/sharedcomponents/axiosFunctions"
import aTP from "@project/sharedcomponents/constantData/accountPackage"
import menuId from "@project/sharedcomponents/constantData/menuIdEnum"
import dynamicTemplate from "@project/sharedcomponents/dynamicTemplate"
// import generateInwardPdf from "@project/sharedcomponents/generateInwardPdf"
import invoiceTemplate2 from "@project/sharedcomponents/invoiceTemplate2"
import invoiceTemplate3 from "@project/sharedcomponents/invoiceTemplate3"
import {
  DatetoDMY,
  amount_format,
  amount_formatDecimal,
  capitalizeArray,
  checkCondition,
  convertIntif,
  dataSetInListItem,
  dataSetInListSalesInvoice,
  getAllEstimateStatus,
  getAllOrderStatus,
  getAxiosCancelToken,
  getCatchErrorMessage,
  getCheckBoxValue,
  getDueStatus,
  getNumberonly,
  getdropdownfieldvalue,
  isblank,
  replaceBRtoNewLine,
  setAxiosCancelToken,
  uppercaseArray,
} from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { isMobileOnly } from "react-device-detect"
import { change, reset } from "redux-form"
import { getAccountSessionUserData, getAccountSitesetting, getAccountRightsByID, getWindowWidth, getendyear, getstartyear, getCodeOfCustomPageByName, getheaders, goToNavigate, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import {
  ADD_RECENT_ALLACCOUNT,
  ADD_RECENT_ITEM,
  BUNDLE_ITEM_PHOTO_UPDATED,
  CATALOGUE_ITEM_DELETED,
  CATALOGUE_ITEM_INSERTED,
  CATALOGUE_SERVICE_DELETED,
  CATALOGUE_SERVICE_INSERTED,
  CATALOGUE_SERVICE_UPDATED,
  CLEAR_DROPDOWNUPDATED,
  CLOSE_ALL_DRAWER,
  COMMON_SEARCH_TEXT_UPDATE,
  COMMON_SERVICE_ARRAY_INSERTED,
  COMMON_SERVICE_CLEAR,
  COMMON_SERVICE_DELETED,
  COMMON_SERVICE_INSERTED,
  COMMON_SERVICE_UPDATED,
  COMPANY_PHOTO_UPDATED,
  CONFIGURABLE_ITEM_PHOTO_UPDATED,
  CONFIRM_DIALOG,
  CUSTOMFIELD_OPTION_DELETED,
  CUSTOMFIELD_OPTION_UPDATED,
  CUSTOM_DIALOG,
  CUSTOM_FIELD_SERVICE_DELETED,
  CUSTOM_FIELD_SERVICE_INSERTED,
  CUSTOM_FIELD_SERVICE_UPDATED,
  CUSTOM_PAGE_DROPDOWN_SUCCESS,
  CUSTOM_PAGE_DROPDOWN_UPDATE,
  DIALOG_LOADING,
  DIALOG_REASON_UPDATE,
  DROPDOWN_ITEM_DELETED,
  DROPDOWN_ITEM_INSERTED,
  DROPDOWN_ITEM_UPDATED,
  ESTIMATE_SERVICE_CONVERTED,
  E_INVOICE_UPDATE,
  FILTERED_CHANGED,
  FORM_DEP_SERVICE_CLEAR,
  FORM_DEP_SERVICE_FAIL,
  FORM_DEP_SERVICE_START,
  FORM_DEP_SERVICE_SUCCESS,
  FORM_DEP_SERVICE_SUCCESS_WITHOUTLOADING,
  FORM_POST_SERVICE_CLEAR,
  FORM_POST_SERVICE_FAIL,
  FORM_POST_SERVICE_START,
  FORM_POST_SERVICE_SUCCESS,
  FORM_POST_UPDATE,
  IMPORT_UNIT_INSERTED_SUCCESS,
  INVOICE_SERVICE_CONVERTED,
  ITEM_GROUP_PHOTO_UPDATED,
  ITEM_PHOTO_UPDATED,
  ITEM_STATUS_UPDATE,
  LAST_PAGE_UPDATE,
  LOADING_SNACKBAR_STATE,
  LOADING_STATE,
  OPEN_DRAWER,
  OPSTOCK_SERVICE_UPDATED,
  PAGES_SERVICE_DELETED,
  PAGES_SERVICE_INSERTED,
  PAGES_SERVICE_UPDATED,
  PAGE_FORM_POST_SERVICE_CLEAR,
  PAGE_FORM_POST_SERVICE_FAIL,
  PAGE_FORM_POST_SERVICE_START,
  PAGE_FORM_POST_SERVICE_SUCCESS,
  PAGE_FORM_POST_UPDATE,
  PAYMENT_UTILITY_SERVICE_UPDATED,
  POP_OVER_LOADING_STATE,
  POST_FILTER_SERVICE_FAIL,
  POST_FILTER_SERVICE_START,
  POST_FILTER_SERVICE_SUCCESS,
  PRODUCT_BATCH_SERVICE_SUCCESS,
  PRODUCT_SERIAL_SERVICE_SUCCESS,
  PRODUCT_SUB_GROUP_LEVEL2_PHOTO_UPDATED,
  PRODUCT_SUB_GROUP_PHOTO_UPDATED,
  PUSH_DRAWER,
  PUSH_SHORTCUT_DRAWER,
  REMOVE_DRAWER,
  RESELLER_SERVICE_DELETED,
  RESELLER_SERVICE_INSERTED,
  RESELLER_SERVICE_UPDATED,
  SET_COUNTRY_ALL_STATE_FOR_ONBOARDING,
  SET_EXPAND_VALUE,
  SET_LAST_DATE,
  SET_LAST_DRAFT,
  SET_LAST_MENU_KEY,
  SET_SETTING,
  SHIPPING_SERVICE_DELETED,
  SITE_SETTING_UPDATED,
  SNACKBAR_STATE,
  UNIT_SERVICE_DELETED,
  UPDATE_DRAWER,
  UPDATE_FILTER_DATA,
  UPDATE_OTHER_DETAIL,
  UPDATE_REFRESH_DRAWER_STACK,
  UPDATE_SERVICEWORKER,
  UPDATE_USER_DATA,
  USER_PHOTO_UPDATED,
} from "./types"

export const updateServiceworker = () => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SERVICEWORKER,
    })
  }
}

export const clear_dropDownUpdated = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_DROPDOWNUPDATED,
    })
  }
}

export const setSetting = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_SETTING,
      siteSettingDefaultValue: value,
    })
  }
}

export const common_rightdrawerstate = ({ open, title, data, fieldname, position, width, height }) => {
  return (dispatch) => {
    var rights = false
    if (open === true) {
      if (title === "Add New Item") {
        rights = parseInt(getAccountRightsByID(menuId.Product)[0], 10) === 1
      } else if (title === "Add New Product Group") {
        rights = parseInt(getAccountRightsByID(menuId.ProductGroup)[0], 10) === 1
      } else if (title === "Add New Product Sub Group") {
        rights = parseInt(getAccountRightsByID(menuId.ProductSubGroup)[0], 10) === 1
      } else if (title === "Add New Product Sub Group Level2") {
        rights = parseInt(getAccountRightsByID(menuId.ProductSubGroupLevel2)[0], 10) === 1
      } else {
        rights = true
      }
    } else {
      rights = true
    }
    if (open) {
      if (rights) {
        pushDrawer({ title, data, fieldname, position: isblank(position) ? "right" : position, width: isblank(width) ? "auto" : width, dispatch })
      } else {
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: "You are not authorized",
          snackbarType: "error",
        })
      }
    } else {
      removeDrawer(dispatch)
    }
  }
}

export const formdrawerstate = ({ open, title, data, fieldname, position, width, height }) => {
  return (dispatch) => {
    if (open) {
      pushDrawer({ title, data, fieldname, position: isblank(position) ? "bottom" : position, width: isblank(width) ? "auto" : width, dispatch })
    } else {
      removeDrawer(dispatch)
    }
  }
}

export const pushShortcutDrawer = ({ title, data, fieldname, position, width, dispatch }) => {
  dispatch({
    type: PUSH_SHORTCUT_DRAWER,
    open: true,
    drawer: title,
    data: data,
    fieldname: fieldname,
    position: isblank(position) ? "right" : position,
    width: isblank(width) ? "auto" : width,
  })
}

export const pushDrawer = ({ title, data, fieldname, position, width, dispatch }) => {
  dispatch({
    type: PUSH_DRAWER,
    open: false,
    drawer: title,
    data: data,
    fieldname: fieldname,
    position: isblank(position) ? "right" : position,
    width: isblank(width) ? "auto" : width,
  })

  setTimeout(() => {
    dispatch({
      type: OPEN_DRAWER,
      drawer: title,
    })
  }, 0)
}

export const pushDrawerV1 = ({ title, data, fieldname, position, width }) => {
  return (dispatch) => {
    dispatch({
      type: PUSH_DRAWER,
      open: false,
      drawer: title,
      data: data,
      fieldname: fieldname,
      position: isblank(position) ? "right" : position,
      width: isblank(width) ? "auto" : width,
    })

    setTimeout(() => {
      dispatch({
        type: OPEN_DRAWER,
        drawer: title,
      })
    }, 0)
  }
}

export const updateDrawerData = ({ title, data, fieldname, position, width, index }) => {
  return (dispatch) => {
    updateDrawer({ title, data, fieldname, position, width, dispatch, index })
  }
}

export const updateDrawer = ({ title, data, fieldname, position, width, dispatch, index }) => {
  dispatch({
    type: UPDATE_DRAWER,
    drawer: title,
    data: data,
    fieldname: fieldname,
    position: position,
    width: width,
    index: index,
  })
}

export const removeDrawer = (dispatch) => {
  dispatch({
    type: REMOVE_DRAWER,
  })

  setTimeout(() => {
    dispatch({
      type: LAST_PAGE_UPDATE,
      lasttype: "drawerStack",
      lastaction: "update",
      lasttime: new Date().getTime(),
    })
  }, 0)
}

export const closeAllDrawer = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_ALL_DRAWER,
    })
  }
}

export const updateRefreshDrawerStack = (val) => {
  return (dispatch) => {
    update_RefreshDrawerStack(val, dispatch)
  }
}

export const update_RefreshDrawerStack = (val, dispatch) => {
  dispatch({
    type: UPDATE_REFRESH_DRAWER_STACK,
    payload: val,
  })
}

export const snackbarstate = ({ open, message, snackbarType, autoHide, SnackbarOriginVertical, SnackbarOriginHorizontal }) => {
  return {
    type: SNACKBAR_STATE,
    payload: open,
    message: message,
    snackbarType: snackbarType,
    autoHide: !isblank(autoHide) ? autoHide : true,
    SnackbarOriginVertical,
    SnackbarOriginHorizontal,
  }
}

export const clearformdata = (title) => {
  return {
    type: FORM_POST_SERVICE_CLEAR,
    payload: null,
  }
}

export const clearpageformdata = (title) => {
  return {
    type: PAGE_FORM_POST_SERVICE_CLEAR,
    payload: null,
  }
}

export const form_dep_clear = () => {
  return (dispatch) => {
    dispatch({
      type: FORM_DEP_SERVICE_CLEAR,
    })
  }
}

export const setOtherDetail = (otherDetail) => {
  return {
    type: UPDATE_OTHER_DETAIL,
    otherDetail: otherDetail,
  }
}

export const post_filter_data = ({ formtype, formaction, formdata, other_type, filterType, filter_type, filter_name, functions }) => {
  const { accountType } = functions
  return (dispatch) => {
    let sort_order = {}
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    if (formaction !== "") {
      if (filterType !== 1) {
        formdata.coloumn_data = formdata.coloumn_data.map((item, index) => {
          const updatedItem = {}
          updatedItem.dataType = item.dataType
          updatedItem.disableSorting = item.disableSorting
          updatedItem.elementText = !isblank(item.label) ? item.label : item.name
          updatedItem.elementprop = item.visible
          updatedItem.elementvalue = item.id
          updatedItem.parent = item.parent
          updatedItem.position = index + 1

          if (!isblank(formdata.print_coloumn_data) && typeof formdata.print_coloumn_data === "object" && formdata.print_coloumn_data.length > 0) {
            const printObject = formdata.print_coloumn_data.find((e) => e.id === item.id)
            if (!isblank(printObject)) {
              updatedItem.print_position = formdata.print_coloumn_data.map((e1) => e1.id).indexOf(item.id)
              if (!isblank(item.printWidth)) {
                updatedItem.printWidth = printObject.printWidth
              }
              if (!isblank(item.printVisible)) {
                updatedItem.printVisible = printObject.printVisible
              }
            }
          } else {
            updatedItem.print_position = 0
            updatedItem.printWidth = 0
            updatedItem.printVisible = false
          }
          if (!isblank(item.format)) {
            updatedItem.format = item.format
          }
          if (!isblank(item.total)) {
            updatedItem.total = item.total
          }
          if (!isblank(item.noprint)) {
            updatedItem.noprint = item.noprint
          }
          if (!isblank(item.disableFilter)) {
            updatedItem.disableFilter = item.disableFilter
          }
          if (!isblank(item.display)) {
            updatedItem.display = item.display
          }
          if (!isblank(item.badge)) {
            updatedItem.badge = item.badge
          }
          if (!isblank(item.sort_order)) {
            updatedItem.sort_order = item.sort_order
          }
          if (!isblank(item.showlink)) {
            updatedItem.showlink = item.showlink
          }
          if (!isblank(item.numeric)) {
            updatedItem.numeric = item.numeric
          }
          if (!isblank(item.currencySign)) {
            updatedItem.currencySign = item.currencySign
          }
          if (!isblank(item.canGroup_By)) {
            updatedItem.canGroup_By = item.canGroup_By
          }
          if (!isblank(item.removelinkonly)) {
            updatedItem.removelinkonly = item.removelinkonly
          }
          if (!isblank(item.width)) {
            updatedItem.width = item.width
          }
          if (!isblank(item.url)) {
            updatedItem.url = item.url
          }
          if (!isblank(item.toggle)) {
            updatedItem.toggle = item.toggle
          }
          if (!isblank(item.popover)) {
            updatedItem.popover = item.popover
          }
          if (!isblank(item.bold)) {
            updatedItem.bold = item.bold
          }
          if (!isblank(item.depthKey)) {
            updatedItem.depthKey = item.depthKey
          }
          if (!isblank(item.difference)) {
            updatedItem.difference = item.difference
          }
          if (!isblank(item.disableResizing)) {
            updatedItem.disableResizing = item.disableResizing
          }
          if (!isblank(item.sort_order)) {
            sort_order = {
              orderBy: item.id,
              order: item.sort_order,
            }
          }
          return updatedItem
        })
      }
      const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)

      dispatch({
        type: POST_FILTER_SERVICE_START,
        formaction: formaction,
        formtype: formtype,
      })
      let data = new FormData()
      data.append("type", formtype)
      // data.append("user.data", formaction)
      data.append("action", formaction)
      if (formtype === "salesinvoice" || formtype === "purchaseinvoice" || formtype === "inward" || formtype === "outward") {
        other_type = filter_type
      }
      data.append("other_type", other_type)
      data.append("form_data", formdata2)
      data.append("filterType", filterType)
      if (!isblank(filter_name)) {
        data.append("filter_name", filter_name)
      }
      data.append("offset", 0)
      data.append("limit", "All")
      axios.defaults.headers.common = getheaders()

      var url = ""
      if (accountType === "getmycatalogue") {
        url = list_url({ type: "filter_data_post_catalogue", props: { formtype, other_type } })
      } else {
        url = list_url({ type: "filter_data_post", props: { formtype, other_type } })
      }
      axios({
        method: "post",
        url: url,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
        cancelToken: getAxiosCancelToken().token,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          if (response.data.meta.code === 401) {
            filterServiceFail({ dispatch, response: response.data.meta.message })
          } else {
            if (functions.onSuccess) {
              functions.onSuccess(sort_order)
            } else {
              filterServiceSuccess({ dispatch, user: response, formtype, formaction, formdata, filterType })
            }
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            dispatch({ type: LOADING_STATE, state: false })
          } else {
            postErrorOnException(error)
            filterServiceFail({ dispatch, response: getCatchErrorMessage(error), formdata })
          }
        })
    }
  }
}

const filterServiceFail = ({ dispatch, response, formdata }) => {
  dispatch({
    type: POST_FILTER_SERVICE_FAIL,
    payload: response,
    formdata: formdata,
  })
}

const filterServiceSuccess = ({ dispatch, user, formtype, formaction, formdata, filterType }) => {
  if (filterType === 1) {
    dispatch({
      type: UPDATE_FILTER_DATA,
      filter_data: formdata.filter_data,
      insert_code: user.data.insert_code,
    })
  }
  dispatch({
    type: POST_FILTER_SERVICE_SUCCESS,
    payload: user,
    formtype: formtype,
    formaction: formaction,
  })
}

export const fetch_customPage_dep = ({ other_type, loading, sidebarloading, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: loading, sidebarstate: sidebarloading })
    let data = new FormData()
    data.append("other_type", other_type)
    data.append("offset", 0)
    data.append("limit", "All")
    data.append("type", "dd_custom_page_dropdown")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "dependency" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          dispatch({
            type: CUSTOM_PAGE_DROPDOWN_SUCCESS,
            other_type: other_type,
            payload: response.data.result,
          })
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "dd_custom_page_dropdown",
            lastaction: "success",
            lasttime: new Date().getTime(),
          })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        }
      })
  }
}

export const downloadPDF = ({ type, othertype, title, code }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    dispatch({ type: LOADING_STATE, state: true })

    dispatch({
      type: LOADING_STATE,
      state: true,
    })

    let data = new FormData()
    data.append("type", type)
    data.append("flag", 1)
    data.append("other_type", othertype)
    data.append("code", code)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "download_pdf" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false })
        } else {
          var metadata = {}
          var maxWidth = getWindowWidth()
          metadata.filename = getAccountSessionUserData("company_url") + "_" + response.data.filename.replace(/\s/gi, "_") + "_" + getAccountSessionUserData("financial_year")
          metadata.title = title

          setTimeout(() => {
            pushDrawer({ title: "PDFViewer", data: response.data.result, fieldname: metadata, position: maxWidth <= 1024 ? "bottom" : "right", width: maxWidth <= 1024 ? maxWidth : 1024, dispatch })
          }, 300)

          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const fetch_dependency = ({ dropdown_string, form_type, functions }) => {
  const outward_type_detail = getAccountSitesetting("outward_type_detail")
  const inward_type_detail = getAccountSitesetting("inward_type_detail")
  return (dispatch) => {
    if (isblank(dropdown_string)) {
      return true
    }
    dispatch({
      type: FORM_DEP_SERVICE_START,
      formtype: form_type,
      outward_type_detail: outward_type_detail,
      inward_type_detail: inward_type_detail,
    })
    let data = new FormData()
    data.append("form_data", dropdown_string)
    data.append("offset", 0)
    data.append("limit", "All")
    data.append("dependency", true)
    data.append("form_type", form_type)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "dependency" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          depServiceFail({ dispatch, response: response.data.meta.message })
        } else {
          if (response.data.hasOwnProperty("customField")) {
            const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
            response.data.customField = response.data.customField.map((item) => {
              const updatedItem = { ...item }
              if (updatedItem.decimalPoint === "7") {
                updatedItem.decimalPoint = inventory_decimalpoint
              }
              if (updatedItem.dataType === "32") {
                updatedItem.dropDownOptions = [
                  { code: "1", name: "Yes" },
                  { code: "0", name: "No" },
                ]
              }
              return updatedItem
            })
          }

          if (functions.onComplete) {
            functions.onComplete()
          }

          depServiceSuccess({ dispatch, user: response, dropdown_string })
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        depServiceFail({ dispatch, response: getCatchErrorMessage(error) })
      })
  }
}

export const fetch_dependencyWithoutLoading = ({ dropdown_string, form_type, functions = {} }) => {
  const { onComplete } = functions
  const textTransformation = getAccountSitesetting("textTransformation")
  return (dispatch) => {
    if (isblank(dropdown_string)) {
      return true
    }

    let data = new FormData()
    data.append("form_data", dropdown_string)
    data.append("offset", 0)
    data.append("limit", "All")
    data.append("dependency", true)
    data.append("form_type", form_type)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "dependency" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
        } else {
          if (response.data.hasOwnProperty("customField")) {
            const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
            response.data.customField = response.data.customField.map((item) => {
              const updatedItem = { ...item }
              if (updatedItem.decimalPoint === "7") {
                updatedItem.decimalPoint = inventory_decimalpoint
              }
              if (updatedItem.dataType === "32") {
                updatedItem.dropDownOptions = [
                  { code: "1", name: "Yes" },
                  { code: "0", name: "No" },
                ]
              }
              return updatedItem
            })
          }
          if (textTransformation === "u") {
            if (response.data.dd_country_state && response.data.dd_country_state.length > 0) {
              response.data.dd_country_state = response.data.dd_country_state.map((e) => {
                const updated = { ...e }
                if (updated.states && updated.states.length > 0) {
                  updated.states = uppercaseArray(updated.states, ["name"])
                }
                return updated
              })
            }
          } else if (textTransformation === "c") {
            if (response.data.dd_country_state && response.data.dd_country_state.length > 0) {
              response.data.dd_country_state = response.data.dd_country_state.map((e) => {
                const updated = { ...e }
                if (updated.states && updated.states.length > 0) {
                  updated.states = capitalizeArray(updated.states, ["name"])
                }
                return updated
              })
            }
          }
          dispatch({
            type: FORM_DEP_SERVICE_SUCCESS_WITHOUTLOADING,
            payload: response,
            dropdown_string: dropdown_string,
          })
          if (onComplete) {
            onComplete()
          }
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
      })
  }
}

export const fetch_form_dep = ({ dropdown_string, form_type, functions = {} }) => {
  const { onComplete } = functions
  return (dispatch) => {
    if (isblank(dropdown_string)) {
      return true
    }

    dispatch({
      type: FORM_DEP_SERVICE_START,
      formtype: form_type,
      outward_type_detail: getAccountSitesetting("outward_type_detail"),
      inward_type_detail: getAccountSitesetting("inward_type_detail"),
    })
    let data = new FormData()
    data.append("form_data", dropdown_string)
    data.append("offset", 0)
    data.append("limit", "All")
    data.append("dependency", true)
    data.append("form_type", form_type)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "dependency" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          depServiceFail({ dispatch, response: response.data.meta.message })
        } else {
          depServiceSuccess({ dispatch, user: response, dropdown_string })
          if (onComplete) {
            onComplete()
          }
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        depServiceFail({ dispatch, response: getCatchErrorMessage(error) })
      })
  }
}

export const getAllStateOfCountry = ({ country, functions }) => {
  const { onError, onSuccess } = functions
  return (dispatch) => {
    let data = new FormData()
    data.append("type", "countryallstate")
    data.append("code", country)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "countryallstate" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          if (onError) {
            onError(response.data.message)
          }
        } else {
          if (onSuccess) {
            onSuccess(response.data.result)
          }
          dispatch({
            type: SET_COUNTRY_ALL_STATE_FOR_ONBOARDING,
            payload: response.data.result,
          })
        }
      })
      .catch((error) => {
        if (onError) {
          onError(getCatchErrorMessage(error))
        }
        postErrorOnException(error)
      })
  }
}

const depServiceSuccess = ({ dispatch, user, dropdown_string }) => {
  let textTransformation = "n"
  if (getAccountSitesetting) {
    textTransformation = getAccountSitesetting("textTransformation")
  }
  dispatch({
    type: FORM_DEP_SERVICE_SUCCESS,
    payload: user,
    dropdown_string: dropdown_string,
    textTransformation,
  })

  dispatch({
    type: LAST_PAGE_UPDATE,
    lasttype: "dependency",
    lastaction: "success",
    lasttime: new Date().getTime(),
  })
}

const depServiceFail = ({ dispatch, response }) => {
  dispatch({
    type: FORM_DEP_SERVICE_FAIL,
    payload: response,
  })
}

export const common_post_form_data = ({
  formtype,
  formaction,
  formdata,
  saveandaddanother,
  files,
  other_type,
  otherDetail,
  saveandprint,
  saveandshare,
  functions = {},
  common_formtype,
  draftCode,
  drawer = true,
}) => {
  const { onComplete } = functions
  const invoice_type_detail = getAccountSitesetting("invoice_type_detail")
  const purchase_type_detail = getAccountSitesetting("purchase_type_detail")
  const inward_type_detail = getAccountSitesetting("inward_type_detail")
  const outward_type_detail = getAccountSitesetting("outward_type_detail")
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    if (formaction !== "") {
      const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
      if ((formaction === "delete" && otherDetail === "multi_delete") || formtype === "active_inactive") {
        dispatch({ type: LOADING_STATE, state: true })
      } else if (formaction === "update" && formtype === "opening_stock") {
        dispatch({ type: POP_OVER_LOADING_STATE, state: true })
      } else if (
        formtype === "cancel_einvoice" ||
        ((formtype === "generate_einvoice" || formtype === "generate_einvoiceJson") && otherDetail === "List") ||
        formtype === "ewaybill_cancel" ||
        formtype === "cancel_irn_ewaybill"
      ) {
        dispatch({
          type: DIALOG_LOADING,
          state: true,
        })
      } else {
        if (
          invoice_type_detail.filter((e) => e.id === formtype).length > 0 ||
          formtype === "envelopePrinting" ||
          formtype === "chequePrinting" ||
          formtype === "onboarding" ||
          formtype === "reconcileDate" ||
          formtype === "lotCosting" ||
          formtype === "merge_packingSlip" ||
          formtype === "gstoldbill" ||
          (formtype === "generate_irn_ewaybill" && otherDetail !== "rightDrawer") ||
          (formtype === "customField" && other_type !== "value" && otherDetail !== "getMyStore") ||
          formtype === "bank_statement" ||
          formtype === "shipping" ||
          formtype === "configurableitem" ||
          formtype === "adjustBill" ||
          formtype === "receipt" ||
          formtype === "catalogueUserStatus" ||
          formtype === "catalogueUser" ||
          formtype === "customPageForm" ||
          formtype === "setting" ||
          formtype === "recordexpense" ||
          formtype === "payment" ||
          formtype === "journal_entry" ||
          formtype === "salesreturn" ||
          formtype === "purchasereturn" ||
          purchase_type_detail.filter((e) => e.id === formtype).length > 0 ||
          inward_type_detail.filter((e) => e.id === formtype).length > 0 ||
          formtype === "lot_transfer" ||
          formtype === "stock_transferGodown" ||
          formtype === "stockTransfer" ||
          outward_type_detail.filter((e) => e.id === formtype).length > 0 ||
          formtype === "useripostock" ||
          formtype === "reset_gstr3b_reconcile" ||
          formtype === "gstr2_partially_markas_matched" ||
          formtype === "connect_company" ||
          formtype === "label_design"
        ) {
          dispatch({
            type: PAGE_FORM_POST_SERVICE_START,
            pageformaction: formaction,
            pageformtype: formtype,
            outward_type_detail: outward_type_detail,
            inward_type_detail: inward_type_detail,
            purchase_type_detail: purchase_type_detail,
            invoice_type_detail: invoice_type_detail,
          })
        } else if (!(formtype === "generate_irn_ewaybill" && otherDetail !== "rightDrawer")) {
          dispatch({
            type: FORM_POST_SERVICE_START,
            formaction: formaction,
            formtype: formtype,
            otherDetail: otherDetail,
            outward_type_detail: outward_type_detail,
            inward_type_detail: inward_type_detail,
            purchase_type_detail: purchase_type_detail,
            invoice_type_detail: invoice_type_detail,
          })
        }
      }
      var typeForUrl = formtype
      if (process.env.REACT_APP_MODULE_TYPE === "getmycatalogue") {
        typeForUrl = `catalogue_${typeForUrl}`
      }
      if (process.env.REACT_APP_MODULE_TYPE === "studiobooks") {
        typeForUrl = `studio_${typeForUrl}`
      }
      var url = post_form_url({ formtype: typeForUrl, props: { userdata: "", otherDetail, getAccountSitesetting, formaction } })
      let data = new FormData()

      if (formtype === "importcountry") {
        data.append("type", "country")
        data.append("action", "import")
      } else if (formtype === "importunit") {
        data.append("type", "unit")
        data.append("action", "import")
      } else if (checkCondition(formtype, ["itemgroup", "item"]) && otherDetail === "multi_delete") {
        data.append("type", formtype)
        data.append("action", otherDetail)
      } else {
        data.append("type", formtype)
        data.append("action", formaction)
      }
      data.append("form_data", formdata2)
      if (formtype === "setting" && !isblank(functions.from_call)) {
        data.append("from_call", functions.from_call)
      }
      if (other_type) {
        data.append("other_type", other_type)
      }
      if (formaction === "insert" || formaction === "update") {
        if (!isblank(files)) {
          Object.keys(files).forEach((item) => {
            data.append(item, files[item])
          })
        }
      } else if (formaction === "edit") {
        if (otherDetail === "Clone") {
          data.append("clone", true)
        } else {
          data.append("clone", false)
        }
      }
      if (invoice_type_detail.filter((e) => e.id === formtype).length > 0 || formtype === "recordexpense") {
        data.append("version", "V2")
      }
      data.append("offset", 0)
      data.append("limit", "All")

      axios.defaults.headers.common = getheaders()
      axios({
        method: "post",
        url: url,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
        cancelToken: getAxiosCancelToken().token,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          if (
            formtype === "cancel_einvoice" ||
            formtype === "generate_einvoice" ||
            formtype === "generate_einvoiceJson" ||
            formtype === "ewaybill_cancel" ||
            formtype === "cancel_irn_ewaybill" ||
            formtype === "active_inactive"
          ) {
            dispatch({ type: LOADING_STATE, state: false })
            dispatch({
              type: DIALOG_LOADING,
              state: false,
            })
          }
          if (response.data.meta.code === 401) {
            dispatch({ type: LOADING_STATE, state: false })
            if (formtype === "cancel_einvoice" || formtype === "generate_einvoice" || formtype === "generate_einvoiceJson" || formtype === "ewaybill_cancel" || formtype === "cancel_irn_ewaybill") {
              if ((formtype === "generate_einvoice" || formtype === "generate_einvoiceJson") && otherDetail === "rightDrawer") {
                dispatch({
                  type: CONFIRM_DIALOG,
                  payload: false,
                  confirm: false,
                  message: "",
                  title: "",
                  form: "",
                  meta: "",
                  loading: false,
                })
              } else {
                dispatch({
                  type: CONFIRM_DIALOG,
                  payload: false,
                  confirm: false,
                  message: "",
                  title: "",
                  form: "",
                  meta: "",
                  loading: false,
                })
              }
            }
            postServiceFail({ dispatch, response: response.data.meta.message, formdata, saveandaddanother, formaction, formtype, functions })
          } else if (response.data.meta.code === 408) {
            dispatch({ type: LOADING_STATE, state: false })

            dispatch({
              type: SNACKBAR_STATE,
              message: response.data.meta.message,
              snackbarType: "warning",
            })

            const lastdata = {
              getAccountSitesetting,
            }
            lastdata.formtype = formtype
            lastdata.formaction = formaction
            lastdata.formdata = formdata
            lastdata.saveandaddanother = saveandaddanother
            lastdata.saveandprint = saveandprint
            lastdata.saveandshare = saveandshare
            lastdata.other_type = other_type
            lastdata.otherDetail = otherDetail
            lastdata.files = files
            lastdata.message = response.data.meta.message
            lastdata.calLimit = response.data.meta.calLimit
            lastdata.creditLimit = response.data.meta.creditLimit
            lastdata.netamt = response.data.meta.netamt
            lastdata.outStanding = response.data.meta.outStanding
            lastdata.functions = functions
            lastdata.common_formtype = common_formtype
            lastdata.draftCode = draftCode
            lastdata.function = "common_post_form_data"
            lastdata.type = ""
            if (!isblank(response.data.meta?.confirmKey)) {
              lastdata.type = "autoConfirm"
              lastdata.confirmKey = response.data.meta.confirmKey
            } else if (response.data.meta.title === "Negative stock alert") {
              lastdata.type = "negativeStock"
            } else if (response.data.meta.title === "Confirm Delete") {
              lastdata.type = "packingSlipDelete"
            } else if (response.data.meta.title === "Negative order alert") {
              lastdata.type = "negativeOrderConfirm"
            } else if (response.data.meta.title === "Amend Invoice alert") {
              lastdata.type = "amendInvoice"
              lastdata.message = "Invoice already filled in GST. Do you want to amend invoice?"
            } else if (response.data.meta.title === "Credit limit has been reached") {
              lastdata.type = "creditLimitUpdate"
            } else if (response.data.meta.title === "Credit limit reached") {
              lastdata.type = "creditLimitReached"
            } else if (response.data.meta.title === "Gst filed alert" || response.data.meta.title === "Attention: Invoice Already Filed") {
              lastdata.type = "gstFileAlert"
            } else if (response.data.meta.title === "TDS threshold limit reached") {
              lastdata.type = "tds_threshold_limit_reached"
            } else if (response.data.meta.title === "TCS threshold limit reached") {
              lastdata.type = "tcs_threshold_limit_reached"
            } else if (response.data.meta.title === "TDS/TCS threshold limit reached") {
              lastdata.type = "tds_tcs_threshold_limit_reached"
            } else if (response.data.meta.title === "This unadjusted transaction already forwarded to the next financial year") {
              lastdata.type = "unadjusted_transaction_already_forwarded"
            } else if (response.data.meta.title === "Delete validation") {
              lastdata.type = "delete_validation"
              lastdata.meta = response.data.meta
            } else {
              lastdata.type = "limitReached"
            }
            dispatch({
              type: LAST_PAGE_UPDATE,
              lastdata: lastdata,
            })

            if (
              (formaction === "delete" && otherDetail === "fromForm") ||
              (formaction !== "delete" &&
                (invoice_type_detail.filter((e) => e.id === formtype).length > 0 ||
                  formtype === "receipt" ||
                  formtype === "payment" ||
                  formtype === "journal_entry" ||
                  formtype === "recordexpense" ||
                  formtype === "salesreturn" ||
                  formtype === "purchasereturn" ||
                  purchase_type_detail.filter((e) => e.id === formtype).length > 0 ||
                  inward_type_detail.filter((e) => e.id === formtype).length > 0 ||
                  formtype === "lot_transfer" ||
                  formtype === "stock_transferGodown" ||
                  formtype === "stockTransfer" ||
                  outward_type_detail.filter((e) => e.id === formtype).length > 0))
            ) {
              dispatch({
                type: CONFIRM_DIALOG,
                payload: true,
                title: response.data.meta.title,
                form: "NotifyDialog",
              })
            } else if ((formaction === "delete" && otherDetail !== "fromForm") || (formaction === "update" && formtype === "opening_stock")) {
              dispatch({
                type: CONFIRM_DIALOG,
                payload: true,
                title: response.data.meta.title,
                form: "NotifyDialog",
                confirm: false,
                message: "",
                meta: "",
              })
            } else {
              dispatch({
                type: CONFIRM_DIALOG,
                payload: true,
                title: response.data.meta.title,
                form: "NotifyDialog",
              })
            }
          } else if (response.data.meta.code === 403) {
            dispatch({ type: LOADING_STATE, state: false })
            var message = ""
            if (formtype === "generate_einvoice" && !isblank(response.data.irn)) {
              message = `e-Invoice generated succesfully. IRN No : ${response.data.irn}`
              dispatch({
                type: E_INVOICE_UPDATE,
                formtype: formtype,
                response: response.data.irn,
                ewayBillstatus: "0",
                payload: formdata,
              })
            }
            if ((formtype === "generate_einvoice" || formtype === "generate_irn_ewaybill" || formtype === "generate_einvoiceJson") && otherDetail === "rightDrawer") {
              dispatch({
                type: CONFIRM_DIALOG,
                payload: true,
                confirm: false,
                message: message,
                title: "Message",
                form: "Error List",
                meta: response.data.meta.message,
              })
            } else {
              dispatch({
                type: CONFIRM_DIALOG,
                payload: true,
                confirm: false,
                message: message,
                title: "Message",
                form: "Error List",
                meta: response.data.meta.message,
              })
            }
            if (formaction === "delete" && otherDetail === "multi_delete") {
              postServiceSuccess({
                dispatch,
                user: response,
                formtype,
                formaction,
                formdata,
                saveandaddanother,
                other_type,
                otherDetail,
                saveandprint,
                saveandshare,
                functions,
                common_formtype,
                draftCode,
              })
            }
          } else if (response.data.meta.code === 211) {
            dispatch({ type: LOADING_STATE, state: false })
            removeDrawer(dispatch)
            goToNavigate("/upgrade", { state: { meta: response.data.meta } })
          } else {
            if (onComplete) {
              onComplete(response.data)
            }
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "post_form",
              lastaction: "success",
              lasttime: new Date().getTime(),
              lastdata: {
                formtype,
                formaction,
                formdata,
                other_type,
              },
            })

            if (
              (formaction === "delete" && (otherDetail === "multi_delete" || otherDetail === "fromForm")) ||
              formtype === "ewaybill_cancel" ||
              formtype === "cancel_irn_ewaybill" ||
              formtype === "generate_einvoice" ||
              formtype === "generate_einvoiceJson" ||
              formtype === "cancel_einvoice"
            ) {
              if ((formtype === "generate_einvoice" || formtype === "generate_einvoiceJson") && otherDetail === "rightDrawer") {
                dispatch({
                  type: CONFIRM_DIALOG,
                  payload: false,
                  confirm: false,
                  title: "",
                  form: "",
                  message: "",
                  meta: "",
                })
              } else {
                dispatch({
                  type: CONFIRM_DIALOG,
                  payload: false,
                  confirm: false,
                  title: "",
                  form: "",
                  message: "",
                  meta: "",
                })
              }
            }
            postServiceSuccess({
              dispatch,
              user: response,
              formtype,
              formaction,
              formdata,
              saveandaddanother,
              other_type,
              otherDetail,
              saveandprint,
              saveandshare,
              functions,
              common_formtype,
              draftCode,
              drawer,
            })
          }
        })
        .catch((error) => {
          dispatch({ type: LOADING_STATE, state: false })
          if (formtype === "cancel_einvoice" || formtype === "generate_einvoice" || formtype === "generate_einvoiceJson" || formtype === "ewaybill_cancel" || formtype === "cancel_irn_ewaybill") {
            dispatch({
              type: DIALOG_LOADING,
              state: false,
            })
          }
          if (axios.isCancel(error)) {
          } else {
            postErrorOnException(error)
            postServiceFail({ dispatch, response: getCatchErrorMessage(error), formdata, saveandaddanother, formtype, functions })
          }
        })
    }
  }
}

const postServiceSuccess = ({
  dispatch,
  user,
  formtype,
  formaction,
  formdata,
  saveandaddanother,
  other_type,
  otherDetail,
  saveandprint,
  saveandshare,
  functions,
  common_formtype,
  draftCode,
  drawer = true,
}) => {
  const { getTransDate, onComplete, drawerWidth } = functions
  const invoice_type_detail = getAccountSitesetting("invoice_type_detail")
  const purchase_type_detail = getAccountSitesetting("purchase_type_detail")
  const inward_type_detail = getAccountSitesetting("inward_type_detail")
  const outward_type_detail = getAccountSitesetting("outward_type_detail")
  const invoice_cus_sup = getAccountSitesetting("invoice_cus_sup")
  dispatch(change("headerSearchForm", "deleteFormReason", ""))
  dispatch(change("headerSearchForm", "deleteDrawerReason", ""))
  dispatch(change("headerSearchForm", "deleteReason", ""))

  /*
  Clear Sales Invoice Backup 
  */

  if (formtype === "sales_invoice") {
    localStorage.setItem("formValues", "")
  }
  const onSaveAndAddAnother = () => {
    if (saveandaddanother === true) {
      if (
        invoice_type_detail.filter((e) => e.id === formtype).length > 0 ||
        formtype === "customPageForm" ||
        formtype === "catalogueUserStatus" ||
        formtype === "catalogueUser" ||
        formtype === "envelopePrinting" ||
        formtype === "chequePrinting" ||
        formtype === "receipt" ||
        formtype === "recordexpense" ||
        formtype === "payment" ||
        formtype === "journal_entry" ||
        formtype === "salesreturn" ||
        formtype === "purchasereturn" ||
        purchase_type_detail.filter((e) => e.id === formtype).length > 0 ||
        inward_type_detail.filter((e) => e.id === formtype).length > 0 ||
        formtype === "lot_transfer" ||
        formtype === "stock_transferGodown" ||
        formtype === "stockTransfer" ||
        outward_type_detail.filter((e) => e.id === formtype).length > 0
      ) {
        dispatch({
          type: PAGE_FORM_POST_SERVICE_CLEAR,
        })
      } else {
        dispatch({
          type: FORM_POST_SERVICE_CLEAR,
        })
      }

      setTimeout(() => {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "save_add_another",
          lastaction: "start",
          lasttime: new Date().getTime(),
        })
      }, 0)
    }
  }

  const onSuccess = (image_meta) => {
    if (formtype === "importunit") {
      dispatch({
        type: LOADING_STATE,
        state: false,
        sidebarstate: false,
      })
      dispatch({
        type: SNACKBAR_STATE,
        payload: true,
        message: user.data.meta.message,
        snackbarType: "success",
      })
    } else if (formtype === "opening_stock") {
      if (onComplete) {
        onComplete()
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: user.data.meta.message,
          snackbarType: "success",
        })
        dispatch({ type: POP_OVER_LOADING_STATE, state: false })
      }
    } else if (saveandshare) {
      dispatch({ type: LOADING_STATE, state: false, sidebarloading: false })
      dispatch({
        type: LAST_PAGE_UPDATE,
        lasttype: formtype,
        lastaction: "saveandshare",
        lasttime: new Date().getTime(),
        lastdata: formaction === "insert" ? user.data.insert_code : formdata.update_code,
      })
    } else {
      if (
        formtype === "onboarding" ||
        formtype === "shipping" ||
        formtype === "setting" ||
        formtype === "catalogueUser" ||
        formtype === "catalogueUserStatus" ||
        (formtype === "customField" && other_type !== "value" && otherDetail !== "getMyStore") ||
        invoice_type_detail.filter((e) => e.id === formtype).length > 0 ||
        inward_type_detail.filter((e) => e.id === formtype).length > 0 ||
        formtype === "configurableitem" ||
        formtype === "bundleitem"
      ) {
        if (formtype === "configurableitem" && otherDetail === "Convert to Bundle") {
          var newformdata = user.data.form_data
          var oldformdata = user.data.form_data
          var purchase = 0
          var selling = 0
          var mrp = 0
          newformdata.photo = ""
          newformdata.photo1 = ""
          newformdata.photo2 = ""
          newformdata.photo3 = ""
          newformdata.photo4 = ""
          newformdata.DetailGrid = oldformdata.itemdetails.map((item) => {
            const it = {
              itemSelectedValue: item.code,
              itemQty: 1,
            }
            purchase = purchase + parseFloat(getNumberonly(item.pur_rate))
            selling = selling + parseFloat(getNumberonly(item.sale_rate))
            mrp = mrp + parseFloat(getNumberonly(item.mrp))
            return it
          })
          newformdata.tot_qty = oldformdata.itemdetails.length
          newformdata.purchase = purchase
          newformdata.selling = selling
          newformdata.mrp = mrp
          user.data.form_data = newformdata
        }
        // if (formtype === "bundleitem" && formdata.converttobundle) {
        //   dispatch({
        //     type: LAST_PAGE_UPDATE,
        //     lasttype: "configureitem_delete",
        //     lastaction: "delete",
        //     lasttime: new Date().getTime(),
        //     lastdata: {
        //       code: formdata.update_code,
        //     },
        //   })
        // } else{}

        dispatch({
          type: PAGE_FORM_POST_SERVICE_SUCCESS,
          payload: user,
          pageformtype: formtype,
          pageformaction:
            formaction === "delete" && otherDetail === "multi_delete" && user.data.meta.code === 403 ? "multi_delete" : otherDetail === "Clone" && formaction === "edit" ? "clone" : formaction,
          saveandaddanother: saveandaddanother,
          otherDetail: formtype !== "bundleitem" ? otherDetail : "",
        })
        onSaveAndAddAnother()
      } else if (formtype === "customField" && other_type === "value") {
        dispatch({ type: LOADING_STATE, state: false })
        dispatch({
          type: CONFIRM_DIALOG,
          payload: false,
          confirm: false,
          message: "",
          title: "",
          form: "",
          meta: "",
          loading: false,
        })
        onSaveAndAddAnother()
      } else if (formtype === "catalogueProducts" && formaction === "delete") {
        dispatch({
          type: LOADING_STATE,
          state: false,
        })
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "catalogueProducts",
          lastaction: "delete",
          lasttime: new Date().getTime(),
        })

        const newData = functions.rightdrawerdata
        newData.catalogue_detail.detail = newData.catalogue_detail.detail.filter((e) => !formdata.code.codes.includes(e.linkCode))
        updateDrawer({ data: newData, fieldname: functions.rightdrawerfieldname, dispatch, index: functions.drawerIndex })

        dispatch({
          type: CATALOGUE_ITEM_DELETED,
          payload: formdata,
        })
        onSaveAndAddAnother()
      } else {
        if (formtype === "item" && formaction === "edit" && otherDetail === "Clone") {
          user.data.form_data.photos = []
        }
        if (formtype === "customPageForm") {
          if (formaction === "insert" || formaction === "update") {
            if (!(!isblank(otherDetail.singleEntry) && otherDetail.singleEntry === true)) {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: "customPageForm",
                lastaction: "insert_success",
                lasttime: new Date().getTime(),
                lastdata: {
                  insert_code: formaction === "insert" ? user.data.insert_code : formdata.update_code,
                  onSuccess: () => {
                    dispatch({
                      type: PAGE_FORM_POST_SERVICE_SUCCESS,
                      payload: user,
                      pageformtype: formtype,
                      pageformaction:
                        formaction === "delete" && otherDetail === "multi_delete" && user.data.meta.code === 403
                          ? "multi_delete"
                          : otherDetail === "Clone" && formaction === "edit"
                            ? "clone"
                            : formaction,
                      saveandaddanother: saveandaddanother,
                      otherDetail: otherDetail.showInList,
                    })
                  },
                },
              })
            }
          } else {
            dispatch({
              type: PAGE_FORM_POST_SERVICE_SUCCESS,
              payload: user,
              pageformtype: formtype,
              pageformaction:
                formaction === "delete" && otherDetail === "multi_delete" && user.data.meta.code === 403 ? "multi_delete" : otherDetail === "Clone" && formaction === "edit" ? "clone" : formaction,
              saveandaddanother: saveandaddanother,
              otherDetail: otherDetail,
            })
          }
        } else {
          dispatch({
            type: FORM_POST_SERVICE_SUCCESS,
            payload: user,
            formtype: formtype,
            formaction:
              formaction === "delete" && otherDetail === "multi_delete" && user.data.meta.code === 403 ? "multi_delete" : otherDetail === "Clone" && formaction === "edit" ? "clone" : formaction,
            saveandaddanother: saveandaddanother,
            otherDetail: otherDetail,
          })
        }
        dispatch({ type: LOADING_STATE, state: false })

        onSaveAndAddAnother()
        if (saveandaddanother === true) {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "saveandaddanother",
            lastaction: "completed",
            lasttime: new Date().getTime(),
            lastdata: { insert_code: formdata.update_code, formdata, onSuccess },
          })
        }
      }
    }

    var ModalMode = true
    var modalWidth = 450
    var position = "right"
    var payload = {}
    var metaData = ""
    var fieldname = ""
    var customPages = []

    if (formaction === "edit" && drawer) {
      var Modaltype = ""
      if (formtype === "itemgroup") {
        Modaltype = "Add New Product Group"
      } else if (formtype === "productsubgroup") {
        Modaltype = "Add New Product Sub Group"
      } else if (formtype === "customPageForm") {
        if (drawerWidth === "100%") {
          ModalMode = false
        }
        Modaltype = "Add New Page"
        metaData = otherDetail
      } else if (formtype === "productsubgrouplevel2") {
        Modaltype = "Add New Product Sub Group Level2"
      } else if (formtype === "productCatalogue") {
        Modaltype = "Add New Catalogue"
        modalWidth = 900
      } else if (formtype === "user") {
        Modaltype = "Add New User"
      } else if (formtype === "reseller") {
        Modaltype = "Add New Reseller"
      } else if (formtype === "item") {
        Modaltype = "Add New Item"
        modalWidth = ""
        position = "bottom"
      } else if (formtype === "paymentSetting") {
        Modaltype = "Add New PaymentSetting"
      } else if (formtype === "configurableitem") {
        ModalMode = false
        Modaltype = "configurableitem"
        if (otherDetail === "Convert to Bundle") {
          Modaltype = "bundleitem"
        }
      } else if (formtype === "customField" && otherDetail !== "getMyStore") {
        ModalMode = false
        Modaltype = "Add New Custom Field"
      } else if (formtype === "customField") {
        Modaltype = "Add New Attributes"
        modalWidth = 650
      } else if (formtype === "bundleitem") {
        ModalMode = false
        Modaltype = "bundleitem"
      } else if (invoice_type_detail.filter((e) => e.id === formtype).length > 0) {
        ModalMode = false
        if (otherDetail === "Convert Invoice" || otherDetail === "ConvertInvoiceFromEstimate") {
          Modaltype = "sales_invoice"
        } else if (otherDetail === "ConvertOrderFromEstimate") {
          Modaltype = "sales_order"
        } else if (otherDetail === "Credit Note") {
          Modaltype = "sales_return"
        } else {
          Modaltype = user.data.form_data.invoice_type
        }
      } else if (inward_type_detail.filter((e) => e.id === formtype).length > 0) {
        if (otherDetail === "ConvertInvoiceFromInward") {
          ModalMode = false
          Modaltype = "purchase_invoice"
        } else {
          ModalMode = false
          Modaltype = formtype
        }
      } else if (formtype === "company" && !isblank(other_type) && other_type !== "getmystoreweb") {
        // ModalMode = false
        Modaltype = "Add New Company"
      } else if (formtype === "company" && !isblank(other_type) && other_type === "getmystoreweb") {
        ModalMode = false
      } else if (formtype === "shipping") {
        ModalMode = false
      } else if (formtype === "country") {
        Modaltype = "Add New Country"
      } else if (formtype === "state") {
        Modaltype = "Add New State"
      } else if (formtype === "other_account") {
        Modaltype = "Add New Account"
      } else if (formtype === "taxscheme") {
        Modaltype = "Edit Tax Group"
        modalWidth = 650
      } else if (formtype === "transport") {
        Modaltype = "Add New Transport"
      } else if (formtype === "costcenter") {
        Modaltype = "Add New Cost Center"
      } else if (formtype === "subgroup") {
        Modaltype = "Add New Sub Group"
      } else if (formtype === "godown") {
        Modaltype = "Add New Godown"
      } else if (formtype === "stock_transferGodown") {
        Modaltype = "Godown Stock Transfer"
      } else {
        alert("Modal not added in common actions")
      }

      if (ModalMode && !isblank(Modaltype)) {
        pushDrawer({ title: Modaltype, data: metaData, fieldname, position, width: modalWidth, dispatch })
      } else {
        if (formtype === "company") {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "company",
            lastaction: "edit",
            lasttime: new Date().getTime(),
            lastdata: user.data.form_data,
          })
        } else if (
          formtype === "configurableitem" ||
          formtype === "customPageForm" ||
          formtype === "bundleitem" ||
          formtype === "customField" ||
          inward_type_detail.filter((e) => e.id === formtype).length > 0 ||
          invoice_type_detail.filter((e) => e.id === formtype).length > 0
        ) {
          pushDrawer({ title: Modaltype, data: metaData, fieldname, position: "bottom", dispatch })
        } else if (formtype === "shipping") {
          if (otherDetail && otherDetail.fyear) {
            goToNavigate(`/${otherDetail.fyear}/shipping/add`)
          } else {
            goToNavigate(`/shipping/add`)
          }
        } else {
          goToNavigate(`/${Modaltype}`)
        }
      }
    } else if (formaction === "insert") {
      if (formtype === "productCatalogue") {
        dispatch({
          type: CATALOGUE_SERVICE_INSERTED,
          payload: formdata,
          insert_code: user.data.insert_code,
          created_on: user.data.created_on,
        })
      } else if (formtype === "item") {
        if (formdata.list_flag === 1) {
          let inserteditem = {}
          if (user.data.list_row.length > 0) {
            inserteditem = { ...dataSetInListItem(user.data.list_row)[0] }
          } else {
            inserteditem.code = user.data.insert_code
            inserteditem.photo = image_meta ? image_meta.photo : ""
            inserteditem.name = formdata.itemname
            inserteditem.unit = getdropdownvalue({ formValues: formdata, field: "unitSelectedValue", prop: "name" })
            inserteditem.itemgroup = getdropdownvalue({ formValues: formdata, field: "itemGroupSelectedValue", prop: "name" })
            inserteditem.itemcate_name = getdropdownvalue({ formValues: formdata, field: "itemSubGroupSelectedValue", prop: "name" })
            inserteditem.sale_rate = amount_format(formdata.selling)
            inserteditem.hsn_code = formdata.hsn_code
            inserteditem.stockku = formdata.itemSKU
            inserteditem.taxscheme = getdropdownvalue({ formValues: formdata, field: "taxSelectedValue", prop: "name" })
            inserteditem.pur_rate = amount_format(formdata.purchase)
            inserteditem.openstock = amount_format(formdata.stock)
            inserteditem.show_in_gmc = getCheckBoxValue(formdata.show_in_gmc) ? "Yes" : "No"
            inserteditem.inactive = "Active"
            inserteditem.itemtype = getdropdownfieldvalue({
              dropDownData: getsessionuserdata("account_type") === aTP.Gold ? getproducttypegold("", "reducer") : getproducttype(),
              field: "id",
              value: formdata.itemtype,
              displayvalue: "name",
            }) // account_type === "8" GOLD ACCOUNT
          }
          dispatch({
            type: COMMON_SERVICE_INSERTED,
            row: inserteditem,
          })
        }

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          payload: formdata,
          list_row: user.data.list_row ? user.data.list_row : [],
        })
      } else if (formtype === "draft") {
        const Inserteditem = {}
        Object.keys(payload).forEach((e) => {
          Inserteditem[e] = payload[e]
        })
        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: Inserteditem,
        })
      } else if (formtype === "customPageForm") {
        if (!(!isblank(otherDetail.singleEntry) && otherDetail.singleEntry === true)) {
          dispatch({
            type: PAGES_SERVICE_INSERTED,
            payload: formdata,
            showInList: otherDetail.showInList,
            insert_code: user.data.insert_code,
          })
        }
        if (formdata.tablename === "Promo") {
          dispatch({
            type: CUSTOM_PAGE_DROPDOWN_UPDATE,
            payload: formdata,
            formaction: formaction,
            insert_code: user.data.insert_code,
            other_type: getCodeOfCustomPageByName({ name: formdata.tablename }),
          })
        }
      } else if (formtype === "itemgroup") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.name
        inserteditem.photo = image_meta ? image_meta.photo : ""
        inserteditem.show_in_cat = getCheckBoxValue(formdata.show_in_cat) ? "Yes" : "No"

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          name: formdata.name,
          payload: formdata,
        })
      } else if (formtype === "productsubgroup") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.name
        inserteditem.photo = image_meta ? image_meta.photo : ""
        inserteditem.itemgroupname = getdropdownvalue({ formValues: formdata, field: "productgroupSelectedValue", prop: "name" })
        inserteditem.show_in_cat = getCheckBoxValue(formdata.show_in_cat) ? "Yes" : "No"

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          name: formdata.name,
          payload: formdata,
        })
      } else if (formtype === "productsubgrouplevel2") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.name
        inserteditem.photo = image_meta ? image_meta.photo : ""
        inserteditem.itemgroupname = getdropdownvalue({ formValues: formdata, field: "productgroupSelectedValue", prop: "name" })
        inserteditem.itemsubgroupname = getdropdownvalue({ formValues: formdata, field: "productsubGroupSelectedValue", prop: "name" })
        inserteditem.show_in_cat = getCheckBoxValue(formdata.show_in_cat) ? "Yes" : "No"

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          name: formdata.name,
          payload: formdata,
        })
      } else if (formtype === "reseller") {
        dispatch({
          type: RESELLER_SERVICE_INSERTED,
          payload: formdata,
          insert_code: user.data.insert_code,
        })
      } else if (formtype === "onboarding") {
        if (!isblank(functions.year)) {
          setStorage(`user_data_${functions.year}`, user.data.result.company_detail)
        } else {
          setStorage("user_data", user.data.result.company_detail)
        }
        var site_setting = user.data.result.site_setting[0]
        dispatch({
          type: SITE_SETTING_UPDATED,
          payload: site_setting,
        })
        dispatch({
          type: UPDATE_USER_DATA,
          user_data: user.data.result.company_detail,
        })
        if (!isblank(functions.year)) {
          setStorage(`site_setting_${functions.year}`, site_setting)
        } else {
          setStorage("site_setting", site_setting)
        }
        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: "country",
          payload: formdata,
        })
        if (!isblank(functions.year)) {
          goToNavigate(`/${functions.year}/welcome`)
        } else {
          goToNavigate(`/welcome`)
        }
      } else if (formtype === "catalogueProducts") {
        dispatch({
          type: CATALOGUE_ITEM_INSERTED,
          payload: formdata,
        })
      } else if (formtype === "shipping") {
        if (otherDetail && otherDetail.fyear) {
          goToNavigate(`/${otherDetail.fyear}/shipping`)
        } else {
          goToNavigate(`/shipping`)
        }
      } else if (formtype === "configurableitem") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.itemname
        inserteditem.photo = user.data.photo
        inserteditem.total_item = formdata.itemdetails.length
        inserteditem.itemgroup = getdropdownvalue({ formValues: formdata, field: "itemGroupSelectedValue", prop: "name" })
        inserteditem.subgroup_name = getdropdownvalue({ formValues: formdata, field: "itemSubGroupSelectedValue", prop: "name" })
        inserteditem.showGetMyCatalogue = getCheckBoxValue(formdata.show_in_gmc) ? "Yes" : "No"

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })
      } else if (formtype === "customField") {
        var firmdetails = getStorage("config_setting")
        if (firmdetails.custom_fields !== "yes") {
          firmdetails.custom_fields = "yes"
          setStorage("config_setting", firmdetails)
        }
        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          payload: formdata,
          code: user.data.insert_code,
        })

        dispatch({
          type: CUSTOM_FIELD_SERVICE_INSERTED,
          payload: formdata,
          insert_code: user.data.insert_code,
        })
      } else if (formtype === "bundleitem") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.photo = user.data.photo
        inserteditem.name = formdata.itemname
        inserteditem.unit = getdropdownvalue({ formValues: formdata, field: "unitSelectedValue", prop: "name" })
        inserteditem.itemgroup = getdropdownvalue({ formValues: formdata, field: "itemGroupSelectedValue", prop: "name" })
        inserteditem.itemcate_name = getdropdownvalue({ formValues: formdata, field: "itemSubGroupSelectedValue", prop: "name" })
        inserteditem.sale_rate = formdata.selling
        inserteditem.total_item = formdata.DetailGrid.filter((e) => !isblank(e.itemSelectedValue)).length
        inserteditem.hsn_code = formdata.hsn_code
        inserteditem.stockku = formdata.itemSKU
        inserteditem.taxscheme = getdropdownvalue({ formValues: formdata, field: "taxSelectedValue", prop: "name" })
        inserteditem.pur_rate = formdata.purchase
        inserteditem.show_in_gmc = getCheckBoxValue(formdata.show_in_gmc) ? "Yes" : "No"
        inserteditem.itemtype = getdropdownfieldvalue({
          dropDownData: getsessionuserdata("account_type") === aTP.Gold ? getproducttypegold("", "reducer") : getproducttype(),
          field: "id",
          value: formdata.itemtype,
          displayvalue: "name",
        }) // account_type === "8" GOLD ACCOUNT

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          payload: formdata,
        })
      } else if (formtype === "importunit") {
        dispatch({
          type: IMPORT_UNIT_INSERTED_SUCCESS,
          payload: formdata,
        })
        if (formdata.list_flag === 1) {
          var newarray = []
          formdata.DetailGrid.forEach((detailgrid, index) => {
            if (user.data.insert_code.filter((e) => e.code.toString() === detailgrid.code.toString()).length > 0) {
              const inserteditem = {}
              inserteditem.code = user.data.insert_code.find((e) => e.code.toString() === detailgrid.code.toString()).update_code
              inserteditem.name = `${detailgrid.name} - ${detailgrid.unit_name}`
              newarray.push(inserteditem)
            }
          })

          dispatch({
            type: COMMON_SERVICE_ARRAY_INSERTED,
            row: newarray,
          })
        }

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          payload: formdata,
        })

        if (!isblank(otherDetail) && (otherDetail.from === "drawerDialog" || otherDetail.from === "formDialog")) {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "import_unit",
            lastaction: "success",
            lasttime: new Date().getTime(),
            lastdata: user.data.insert_code,
          })
        }
      } else if (formtype === "importcountry") {
        var newarray = []
        formdata.DetailGrid.forEach((detailgrid, index) => {
          const inserteditem = {}
          inserteditem.code = user.data.insert_code.find((e) => e.code.toString() === detailgrid.code.toString()).update_code
          inserteditem.name = detailgrid.name
          newarray.push(inserteditem)
        })

        dispatch({
          type: COMMON_SERVICE_ARRAY_INSERTED,
          row: newarray,
        })

        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: formtype,
          lastaction: "insert",
          lasttime: new Date().getTime(),
          lastdata: { insert_code: user.data.insert_code, otherDetail },
        })
      } else if (invoice_type_detail.filter((e) => e.id === formtype).length > 0 || (formtype === "gstoldbill" && invoice_type_detail.filter((e) => e.id === other_type).length > 0)) {
        if (formtype !== "gstoldbill") {
          setLastTransDate({ dispatch, dateKey: "last_salesinvoicedate", date: formdata.saleInvoiceDate, getTransDate })
        }
        if (otherDetail === "Convert Invoice") {
          dispatch({
            type: INVOICE_SERVICE_CONVERTED,
            payload: formdata,
            insert_data: user.data,
          })
        }
        if (otherDetail === "Convert Invoice" || otherDetail === "ConvertJobFromInward" || otherDetail === "ConvertJobFromOutward" || otherDetail === "ConvertJobFromProcess") {
        } else if (otherDetail === "ConvertOrderFromEstimate" || otherDetail === "ConvertInvoiceFromEstimate") {
          dispatch({
            type: ESTIMATE_SERVICE_CONVERTED,
            payload: formdata,
            insert_data: user.data,
          })
        } else if (otherDetail === "ConvertInvoiceFromOutward") {
          dispatch({
            type: COMMON_SERVICE_DELETED,
            payload: {
              code: formdata.outward_ref_code,
            },
            otherDetail: otherDetail,
            response: user,
          })
        } else if (otherDetail !== "ConvertInvoiceFromEstimate" && otherDetail !== "ConvertOrderFromEstimate") {
          if (common_formtype !== "draft") {
            var inserteditem = {}
            if (user.data.list_row.length > 0) {
              inserteditem = { ...dataSetInListSalesInvoice(user.data.list_row)[0] }
            } else {
              inserteditem.code = user.data.insert_code
              inserteditem.billno = user.data.docno
              inserteditem.b_date = DatetoDMY(formdata.saleInvoiceDate)
              inserteditem.name = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "name" })
              inserteditem.acc_code = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "code" })
              inserteditem.city = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "city" })
              inserteditem.state = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "state" })
              inserteditem.netamt = formdata.tot_net_amount
              inserteditem.e_invoice_status = "Not Generated"
              inserteditem.invoice_type = formdata.invoice_type
              inserteditem.ewaybill_status = "Not Generated"
              if (formdata.invoice_type === "estimate") {
                inserteditem.status = getdropdownfieldvalue({ dropDownData: getAllEstimateStatus(), field: "id", value: formdata.estimateStatus, displayvalue: "name" })
              } else if (formdata.invoice_type === "getmystore" || formdata.invoice_type === "getmycatalogue") {
                inserteditem.status = getdropdownfieldvalue({ dropDownData: getAllOrderStatus(), field: "id", value: formdata.estimateStatus, displayvalue: "name" })
              } else {
                inserteditem.status = "Pending"
              }
              inserteditem.gstr1_filed = formdata.gstr1_filed === "1" ? "Filed" : "Not Filed"
              inserteditem.gstr3B_filed = formdata.gstr3B_filed === "1" ? "Filed" : "Not Filed"
              inserteditem.is_overdue_status = user.data.is_overdue_status
              inserteditem.adjust_status =
                parseInt(user.data.adjust_status) === 0 ? "Unpaid" : parseInt(user.data.adjust_status) === 1 ? "Partially Paid" : parseInt(user.data.adjust_status) === 2 ? "Paid" : "Unpaid"
              inserteditem.due_date = DatetoDMY(formdata.dueDate)
              inserteditem = getDueStatus(inserteditem)
            }
            dispatch({
              type: COMMON_SERVICE_INSERTED,
              row: inserteditem,
            })
          } else {
            if (!isblank(draftCode)) {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: "draft_edit",
                lastaction: "delete",
                lasttime: new Date().getTime(),
                lastdata: {
                  code: draftCode,
                },
              })
            }
          }
        }
      } else if (formtype === "user") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.username
        inserteditem.fullname = formdata.fullname
        inserteditem.photo = image_meta ? image_meta.photo : ""
        inserteditem.email = formdata.email
        inserteditem.mobile = formdata.mobile
        inserteditem.usertype = getdropdownvalue({ formValues: formdata, field: "typeSelectedValue", prop: "id" })

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          name: formdata.username,
          payload: formdata,
        })
      }
      if (formdata.sendSMS || formdata.sendEmail || formdata.sendWhatsApp || formdata.sendAdmin) {
        var lastaction = "saveAndNotification"
        if (saveandprint === true) {
          lastaction = "saveAndNotificationAndPrint"
        }
        setTimeout(() => {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: formtype,
            lastaction: lastaction,
            lasttime: new Date().getTime(),
            lastdata: {
              formtype: formtype,
              sendSMS: formdata.sendSMS,
              sendEmail: formdata.sendEmail,
              sendWhatsApp: formdata.sendWhatsApp,
              sendAdmin: formdata.sendAdmin,
              code: user.data.insert_code,
            },
          })
        }, 0)
      } else if (saveandprint === true) {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: formtype,
          lastaction: "saveandprint",
          lasttime: new Date().getTime(),
          lastdata: user.data.insert_code,
        })
      }
    } else if (formaction === "delete") {
      Modaltype = ""
      if (checkCondition(formtype, ["unit", "itemgroup", "productsubgroup", "user", "productsubgrouplevel2", "catalogueUser", "catalogueUserStatus",
        "item", "configurableitem", "bundleitem", "draft"
      ])) {
        dispatch({
          type: COMMON_SERVICE_DELETED,
          payload: formdata,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "customPageForm") {
        dispatch({
          type: PAGES_SERVICE_DELETED,
          payload: formdata,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "productCatalogue") {
        dispatch({
          type: CATALOGUE_SERVICE_DELETED,
          payload: formdata,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "reseller") {
        dispatch({
          type: RESELLER_SERVICE_DELETED,
          payload: formdata,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "shipping") {
        dispatch({
          type: SHIPPING_SERVICE_DELETED,
          payload: formdata,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "customField") {
        if (other_type === "value") {
          dispatch({
            type: CUSTOMFIELD_OPTION_DELETED,
            formtype: formtype,
            code: user.data.insert_code,
            payload: formdata,
          })

          dispatch({
            type: CONFIRM_DIALOG,
            payload: false,
          })
          return true
        } else {
          dispatch({
            type: CUSTOM_FIELD_SERVICE_DELETED,
            payload: formdata,
            otherDetail: otherDetail,
            response: user,
          })
        }
      } else if (invoice_type_detail.filter((e) => e.id === formtype).length > 0 || (formtype === "gstoldbill" && invoice_type_detail.filter((e) => e.id === other_type).length > 0)) {
        Modaltype = formtype
        dispatch({
          type: COMMON_SERVICE_DELETED,
          payload: formdata,
          otherDetail: otherDetail,
          response: user,
        })
      }
      dispatch({
        type: DROPDOWN_ITEM_DELETED,
        formtype: formtype,
        payload: formdata,
        otherDetail: otherDetail,
        response: user,
        invoice_cus_sup: invoice_cus_sup,
      })
      if (otherDetail === "multi_delete") {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: formtype,
          lastaction: "multi_delete",
          lasttime: new Date().getTime(),
          lastdata: formdata,
        })
      }
      setTimeout(() => {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "post_form_data",
          lastaction: "delete",
          lasttime: new Date().getTime(),
        })
      }, 0)
    } else if (formaction === "update") {
      if (formtype === "catalogueUserStatus") {
        dispatch(change("updateUserform", "acc_code", ""))
        dispatch(change("updateUserform", "status", ""))
        dispatch({
          type: CONFIRM_DIALOG,
          payload: false,
          confirm: false,
          message: "",
          title: "Update GetMyStore User",
          form: "Update Catalogue User",
          meta: "",
        })

        const updateditem = {}
        updateditem.fb_name = getdropdownvalue({ formValues: formdata, field: "acc_code", prop: "name" })
        updateditem.statusValue = formdata.status
        updateditem.acc_code = getdropdownvalue({ formValues: formdata, field: "acc_code", prop: "code" })
        if (formdata.status === "10") {
          updateditem.status = "Waiting"
        } else {
          updateditem.status = getdropdownfieldvalue({ dropDownData: getAllStatus(), field: "id", value: formdata.status, displayvalue: "name" })
        }

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })
      } else if (formtype === "active_inactive") {
        if (formdata.type === "item") {
          if (!isblank(otherDetail) && otherDetail.from === "detailPage") {
            const rightDrawerData = otherDetail.data
            rightDrawerData.inactive = formdata.DetailGrid[0].inactive

            updateDrawer({ data: rightDrawerData, dispatch, index: otherDetail.drawerIndex })
            dispatch({
              type: DROPDOWN_ITEM_UPDATED,
              formtype: "item",
              code: formdata.DetailGrid[0].code,
              other_type: "status_update",
              status: formdata.DetailGrid[0].inactive,
            })
            if (window.location.pathname.endsWith("/products")) {
              dispatch({
                type: ITEM_STATUS_UPDATE,
                payload: formdata,
              })
            }
          } else {
            dispatch({
              type: ITEM_STATUS_UPDATE,
              payload: formdata,
            })
          }

          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: formtype,
            lastaction: "item",
            lasttime: new Date().getTime(),
            lastdata: formdata,
          })
        } else if (formdata.type === "party") {
          const rightDrawerData = otherDetail.data
          rightDrawerData.inactive = formdata.DetailGrid[0].inactive

          updateDrawer({ data: rightDrawerData, dispatch, index: otherDetail.drawerIndex })
          dispatch({
            type: DROPDOWN_ITEM_UPDATED,
            formtype: otherDetail.other_type,
            code: formdata.DetailGrid[0].code,
            other_type: "status_update",
            status: formdata.DetailGrid[0].inactive,
            invoice_cus_sup: invoice_cus_sup,
          })
        }
      } else if (formtype === "notification") {
        const rightDrawerData = otherDetail.data
        rightDrawerData.profile[formdata.type] = formdata.DetailGrid[0].status

        updateDrawer({ data: rightDrawerData, dispatch, index: otherDetail.drawerIndex })
      } else if (formtype === "item") {
        let updateditem = {}
        if (user.data.list_row.length > 0) {
          updateditem = { ...dataSetInListItem(user.data.list_row)[0] }
        } else {
          updateditem.name = formdata.itemname
          updateditem.photo = image_meta ? image_meta.photo : formdata.photos && formdata.photos[0] ? formdata.photos[0].photo : ""
          updateditem.unit = getdropdownvalue({ formValues: formdata, field: "unitSelectedValue", prop: "name" })
          updateditem.itemgroup = getdropdownvalue({ formValues: formdata, field: "itemGroupSelectedValue", prop: "name" })
          updateditem.itemcate_name = getdropdownvalue({ formValues: formdata, field: "itemSubGroupSelectedValue", prop: "name" })
          updateditem.sale_rate = amount_format(formdata.selling)
          updateditem.hsn_code = formdata.hsn_code
          updateditem.stockku = formdata.itemSKU
          updateditem.taxscheme = getdropdownvalue({ formValues: formdata, field: "taxSelectedValue", prop: "name" })
          updateditem.pur_rate = amount_format(formdata.purchase)
          updateditem.openstock = amount_format(formdata.stock)
          updateditem.show_in_gmc = getCheckBoxValue(formdata.show_in_gmc) ? "Yes" : "No"
          updateditem.itemtype = getdropdownfieldvalue({
            dropDownData: getsessionuserdata("account_type") === aTP.Gold ? getproducttypegold("", "reducer") : getproducttype(),
            field: "id",
            value: formdata.itemtype,
            displayvalue: "name",
          }) // account_type === "8" GOLD ACCOUNT
        }

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })
        if (!isblank(otherDetail) && otherDetail.hasOwnProperty("reduxform") && otherDetail.reduxform === "modalConfigureProduct") {
          const field = otherDetail.field
          const index = otherDetail.index
          dispatch(change(otherDetail.reduxform, `${field}[${index}].itemname`, formdata.itemname))
          dispatch(change(otherDetail.reduxform, `${field}[${index}].itemsku`, formdata.itemSKU))
          dispatch(change(otherDetail.reduxform, `${field}[${index}].itemsaleprice`, formdata.selling))
          dispatch(change(otherDetail.reduxform, `${field}[${index}].itempurchaseprice`, formdata.purchase))
          dispatch(change(otherDetail.reduxform, `${field}[${index}].itemopstock`, formdata.stock))
          dispatch(change(otherDetail.reduxform, `${field}[${index}].mrp`, formdata.mrp))
        }
        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          payload: formdata,
          list_row: user.data.list_row ? user.data.list_row : [],
        })
      } else if (formtype === "customPageForm") {
        if (!(!isblank(otherDetail.singleEntry) && otherDetail.singleEntry === true)) {
          dispatch({
            type: PAGES_SERVICE_UPDATED,
            payload: formdata,
            showInList: otherDetail.showInList,
          })
        }
        if (formdata.tablename === "Promo") {
          dispatch({
            type: CUSTOM_PAGE_DROPDOWN_UPDATE,
            payload: formdata,
            formaction: formaction,
            other_type: getCodeOfCustomPageByName({ name: formdata.tablename }),
          })
        }
      } else if (formtype === "company") {
        var user_data = {}
        user_data = getStorage("user_data")
        user_data.company_alias = formdata.c_alias
        user_data.company_address1 = formdata.c_billingAddress
        user_data.company_street = formdata.c_street
        user_data.company_country = getdropdownvalue({ formValues: formdata, field: "countrySelectedValue", prop: "id" })
        user_data.company_address2 = `${formdata.c_city}, ${getdropdownvalue({ formValues: formdata, field: "stateSelectedValue", prop: "name" })}, ${formdata.a_zipCode}`
        user_data.company_alias = formdata.c_alias
        user_data.company_name = formdata.c_name
        user_data.email = formdata.c_email
        user_data.mobile = formdata.c_mobile
        user_data.statecode = getdropdownvalue({ formValues: formdata, field: "stateSelectedValue", prop: "code" })
        user_data.company_banker1 = formdata.c_banker
        user_data.company_banker2 = formdata.c_banker1
        user_data.company_pan = formdata.c_pan
        user_data.company_website = formdata.website
        user_data.base64_logo = !isblank(image_meta) && !isblank(image_meta.base64_logo) ? image_meta.base64_logo : user_data.base64_logo
        user_data.logo = !isblank(image_meta) && !isblank(image_meta.photo) ? image_meta.photo : user_data.logo
        setStorage("user_data", user_data)

        dispatch({
          type: UPDATE_USER_DATA,
          user_data: user_data,
        })
      } else if (formtype === "itemgroup") {
        const updateditem = {}
        updateditem.name = formdata.name
        updateditem.photo = image_meta ? image_meta.photo : formdata.photo ? formdata.photo : ""
        updateditem.show_in_cat = getCheckBoxValue(formdata.show_in_cat) ? "Yes" : "No"

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          name: formdata.name,
          payload: formdata,
        })
      } else if (formtype === "productsubgroup") {
        const updateditem = {}
        updateditem.name = formdata.name
        updateditem.photo = image_meta ? image_meta.photo : formdata.photo ? formdata.photo : ""
        updateditem.itemgroupname = getdropdownvalue({ formValues: formdata, field: "productgroupSelectedValue", prop: "name" })
        updateditem.show_in_cat = getCheckBoxValue(formdata.show_in_cat) ? "Yes" : "No"

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          name: formdata.name,
          payload: formdata,
        })
      } else if (formtype === "productsubgrouplevel2") {
        const updateditem = {}
        updateditem.name = formdata.name
        updateditem.photo = image_meta ? image_meta.photo : formdata.photo ? formdata.photo : ""
        updateditem.itemgroupname = getdropdownvalue({ formValues: formdata, field: "productgroupSelectedValue", prop: "name" })
        updateditem.itemsubgroupname = getdropdownvalue({ formValues: formdata, field: "productsubGroupSelectedValue", prop: "name" })
        updateditem.show_in_cat = getCheckBoxValue(formdata.show_in_cat) ? "Yes" : "No"
        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          name: formdata.name,
          payload: formdata,
        })
      } else if (formtype === "opening_stock") {
        dispatch({
          type: OPSTOCK_SERVICE_UPDATED,
          payload: formdata,
        })
      } else if (formtype === "customField") {
        if (other_type === "value") {
          dispatch({
            type: CUSTOMFIELD_OPTION_UPDATED,
            formtype: formtype,
            code: formdata.update_code,
            payload: formdata,
          })
        } else {
          dispatch({
            type: DROPDOWN_ITEM_UPDATED,
            formtype: formtype,
            code: formdata.update_code,
            payload: formdata,
          })

          dispatch({
            type: CUSTOM_FIELD_SERVICE_UPDATED,
            payload: formdata,
          })
        }
      } else if (formtype === "user") {
        const updateditem = {}
        updateditem.name = formdata.username
        updateditem.fullname = formdata.fullname
        updateditem.photo = image_meta ? image_meta.photo : formdata.photo ? formdata.photo : ""
        updateditem.email = formdata.email
        updateditem.mobile = formdata.mobile
        updateditem.usertype = getdropdownvalue({ formValues: formdata, field: "typeSelectedValue", prop: "id" })

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          name: formdata.username,
          payload: formdata,
        })
      } else if (formtype === "setting") {
        if (!isblank(functions.accountType) && functions.accountType === "accounting" && !isblank(functions.year)) {
          setStorage(`site_setting_${functions.year}`, formdata.SettingData)
        } else {
          setStorage("site_setting", formdata.SettingData)
        }
        dispatch({
          type: SITE_SETTING_UPDATED,
          payload: formdata.SettingData,
        })

        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "setting",
          lastaction: "updateComplete",
          lasttime: new Date().getTime(),
        })
      } else if (formtype === "productCatalogue") {
        dispatch({
          type: CATALOGUE_SERVICE_UPDATED,
          payload: formdata,
          created_on: user.data.created_on,
        })
      } else if (formtype === "reseller") {
        dispatch({
          type: RESELLER_SERVICE_UPDATED,
          payload: formdata,
        })
      } else if (formtype === "shipping") {
        if (otherDetail && otherDetail.fyear) {
          goToNavigate(`/${otherDetail.fyear}/shipping`)
        } else {
          goToNavigate(`/shipping`)
        }
      } else if (invoice_type_detail.filter((e) => e.id === formtype).length > 0 || (formtype === "gstoldbill" && invoice_type_detail.filter((e) => e.id === other_type).length > 0)) {
        if (common_formtype !== "draft") {
          var updateditem = {}
          if (user.data.list_row.length > 0) {
            updateditem = { ...dataSetInListSalesInvoice(user.data.list_row)[0] }
          } else {
            updateditem.billno = formdata.update_code
            updateditem.b_date = DatetoDMY(formdata.saleInvoiceDate)
            updateditem.name = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "name" })
            updateditem.acc_code = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "code" })
            updateditem.city = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "city" })
            updateditem.state = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "state" })
            updateditem.netamt = formdata.tot_net_amount
            if (formdata.invoice_type === "estimate") {
              updateditem.status = getdropdownfieldvalue({ dropDownData: getAllEstimateStatus(), field: "id", value: formdata.estimateStatus, displayvalue: "name" })
            } else if (formdata.invoice_type === "getmystore" || formdata.invoice_type === "getmycatalogue") {
              updateditem.status = getdropdownfieldvalue({ dropDownData: getAllOrderStatus(), field: "id", value: formdata.estimateStatus, displayvalue: "name" })
            } else if (formdata.invoice_type === "sales_order") {
              updateditem.status = getdropdownfieldvalue({ dropDownData: getAllOrderStatus(), field: "id", value: user.data.order_status, displayvalue: "name" })
            } else {
              updateditem.status = parseInt(user.data.order_status) === 0 ? "Pending" : parseInt(user.data.order_status) === 1 ? "Partially" : "Closed"
            }
            if (formdata.invoice_type === "getmystore" && updateditem.name === "Guest Account") {
              updateditem.name = `${updateditem.name} - ${updateditem.guest_name} - ${updateditem.guest_mobile}`
            }
            if (!isblank(formdata.gstr1_filed)) {
              updateditem.gstr1_filed = formdata.gstr1_filed === "1" ? "Filed" : "Not Filed"
            }
            if (!isblank(formdata.gstr3B_filed)) {
              updateditem.gstr3B_filed = formdata.gstr3B_filed === "1" ? "Filed" : "Not Filed"
            }
            updateditem.is_overdue_status = user.data.is_overdue_status
            updateditem.adjust_status =
              parseInt(user.data.adjust_status) === 0 ? "Unpaid" : parseInt(user.data.adjust_status) === 1 ? "Partially Paid" : parseInt(user.data.adjust_status) === 2 ? "Paid" : "Unpaid"
            updateditem.due_date = DatetoDMY(formdata.dueDate)
            updateditem = getDueStatus(updateditem)
          }
          dispatch({
            type: COMMON_SERVICE_UPDATED,
            row: updateditem,
            payload: formdata,
          })
        }
      } else if (formtype === "paymentSetting") {
        dispatch({
          type: PAYMENT_UTILITY_SERVICE_UPDATED,
          payload: formdata,
        })
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "paymentSetting",
          lastaction: "list",
          lasttime: new Date().getTime(),
          lastdata: "",
        })
      } else if (formtype === "unit") {
        const updateditem = {}
        updateditem.name = `${formdata.code} - ${formdata.name}`

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })
      } else if (formtype === "configurableitem") {
        const getListPhoto = () => {
          var returnValue = ""
          if (!isblank(formdata.itemdetails) && formdata.itemdetails.length > 0) {
            if (
              !isblank(formdata.itemdetails[0]) &&
              !isblank(formdata.itemdetails[0].photos) &&
              formdata.itemdetails[0].photos.length > 0 &&
              formdata.itemdetails[0].photos[0].photo &&
              typeof formdata.itemdetails[0].photos[0].photo === "string"
            ) {
              returnValue = formdata.itemdetails[0].photos[0].photo
            }
          }
          return returnValue
        }
        const updateditem = {}
        updateditem.name = formdata.itemname
        updateditem.photo = image_meta ? image_meta.photo : formdata.photos && formdata.photos[0] ? getListPhoto() : ""
        updateditem.total_item = formdata.itemdetails.length
        updateditem.itemgroup = getdropdownvalue({ formValues: formdata, field: "itemGroupSelectedValue", prop: "name" })
        updateditem.subgroup_name = getdropdownvalue({ formValues: formdata, field: "itemSubGroupSelectedValue", prop: "name" })
        updateditem.showGetMyCatalogue = getCheckBoxValue(formdata.show_in_gmc) ? "Yes" : "No"
        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })
      } else if (formtype === "bundleitem") {
        const updateditem = {}
        updateditem.name = formdata.itemname
        updateditem.photo = image_meta ? image_meta.photo : formdata.photos && formdata.photos[0] ? formdata.photos[0].photo : ""
        updateditem.unit = getdropdownvalue({ formValues: formdata, field: "unitSelectedValue", prop: "name" })
        updateditem.itemgroup = getdropdownvalue({ formValues: formdata, field: "itemGroupSelectedValue", prop: "name" })
        updateditem.itemcate_name = getdropdownvalue({ formValues: formdata, field: "itemSubGroupSelectedValue", prop: "name" })
        updateditem.sale_rate = amount_format(formdata.selling)
        updateditem.total_item = formdata.DetailGrid.filter((e) => !isblank(e.itemSelectedValue)).length
        updateditem.hsn_code = formdata.hsn_code
        updateditem.stockku = formdata.itemSKU
        updateditem.taxscheme = getdropdownvalue({ formValues: formdata, field: "taxSelectedValue", prop: "name" })
        updateditem.pur_rate = amount_format(formdata.purchase)
        updateditem.show_in_gmc = getCheckBoxValue(formdata.show_in_gmc) ? "Yes" : "No"
        updateditem.itemtype = getdropdownfieldvalue({
          dropDownData: getsessionuserdata("account_type") === aTP.Gold ? getproducttypegold("", "reducer") : getproducttype(),
          field: "id",
          value: formdata.itemtype,
          displayvalue: "name",
        }) // account_type === "8" GOLD ACCOUNT

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })
      }

      if (formdata.sendSMS || formdata.sendEmail || formdata.sendWhatsApp || formdata.sendAdmin) {
        var lastaction = "saveAndNotification"
        if (saveandprint === true) {
          lastaction = "saveAndNotificationAndPrint"
        }
        setTimeout(() => {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: formtype,
            lastaction: lastaction,
            lasttime: new Date().getTime(),
            lastdata: {
              formtype: formtype,
              sendSMS: formdata.sendSMS,
              sendEmail: formdata.sendEmail,
              sendWhatsApp: formdata.sendWhatsApp,
              sendAdmin: formdata.sendAdmin,
              code: formdata.update_code,
            },
          })
        }, 0)
      } else if (saveandprint === true) {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: formtype,
          lastaction: "saveandprint",
          lasttime: new Date().getTime(),
          lastdata: formdata.update_code,
        })
      }

      setTimeout(() => {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "post_form_data",
          lastaction: "update",
          lasttime: new Date().getTime(),
        })
      }, 0)
    }
    if (formaction === "insert" || formaction === "update") {
      if (formaction === "update") {
        update_RefreshDrawerStack(1, dispatch)
      }
      if (!saveandaddanother) {
        if (formdata && formdata.callPhotoUpload) {
        } else {
          removeDrawer(dispatch)
        }
      }
    } else if (formaction === "delete") {
      update_RefreshDrawerStack(3, dispatch)
      removeDrawer(dispatch)
    }
  }

  if (!isblank(formdata.uploadImageArray) && formdata.uploadImageArray.length > 0 && (formaction === "insert" || formaction === "update")) {
    if (
      formtype === "item" ||
      formtype === "bundleitem" ||
      formtype === "customPageForm" ||
      formtype === "itemgroup" ||
      formtype === "company" ||
      formtype === "productCatalogue" ||
      formtype === "productsubgroup" ||
      formtype === "productsubgrouplevel2" ||
      formtype === "user"
    ) {
      var photoResponses = []
      const onUploadComplete = (e, index, response, field) => {
        if (!isblank(response) && !isblank(field)) {
          photoResponses.push({ response, field })
        }
        if (index === formdata.uploadImageArray.length - 1) {
          const onPhotoSuccess = (newresponse, newfield) => {
            if (formtype === "item" || formtype === "bundleitem") {
              if (newfield === "photos") {
                onSuccess(newresponse)
              } else {
                const filteredRes = photoResponses.filter((i) => i.field === "photos")
                if (filteredRes.length > 0) {
                  if (filteredRes.length > 1) {
                    const withoutLoading = filteredRes.filter((i) => i.response.photo !== "loading")
                    if (withoutLoading.length > 0) {
                      onSuccess(withoutLoading[0].response)
                    } else {
                      onSuccess(filteredRes[0].response)
                    }
                  } else {
                    onSuccess(filteredRes[0].response)
                  }
                } else {
                  onSuccess()
                }
              }
            } else if (formtype === "itemgroup" || formtype === "productsubgroup" || formtype === "user" || formtype === "company" || formtype === "productsubgrouplevel2") {
              if (newfield === "photo") {
                onSuccess(newresponse)
              } else {
                const filteredRes = photoResponses.filter((i) => i.field === "photo")
                if (filteredRes.length > 0) {
                  if (filteredRes.length > 1) {
                    const withoutLoading = filteredRes.filter((i) => i.response.photo !== "loading")
                    if (withoutLoading.length > 0) {
                      onSuccess(withoutLoading[0].response)
                    } else {
                      onSuccess(filteredRes[0].response)
                    }
                  } else {
                    onSuccess(filteredRes[0].response)
                  }
                } else {
                  onSuccess()
                }
              }
            } else {
              onSuccess()
            }
          }
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: `${e}_upload`,
            lastaction: "start",
            lasttime: new Date().getTime(),
            lastdata: { insert_code: formaction === "insert" ? user.data.insert_code : formdata.update_code, formdata, onSuccess: onPhotoSuccess },
          })
        } else {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: `${e}_upload`,
            lastaction: "start",
            lasttime: new Date().getTime(),
            lastdata: {
              insert_code: formaction === "insert" ? user.data.insert_code : formdata.update_code,
              formdata,
              onSuccess: (Response, Field) => onUploadComplete(formdata.uploadImageArray[index + 1], index + 1, Response, Field),
            },
          })
        }
      }
      onUploadComplete(formdata.uploadImageArray[0], 0)
    } else if (formtype === "configurableitem") {
      var photoResponses = []
      const onUploadComplete = (e, index, response, field) => {
        const getConfigCode = () => {
          var configItemIndex = e.split(".")
          if (configItemIndex.length === 3) {
            return user.data.codes[convertIntif(configItemIndex[1])]
          }
          return formaction === "insert" ? user.data.insert_code : formdata.update_code
        }

        if (!isblank(response) && !isblank(field)) {
          photoResponses.push({ response, field })
        }
        if (index === formdata.uploadImageArray.length - 1) {
          const onPhotoSuccess = (newresponse, newfield) => {
            if (newfield === `itemdetails.0.photos`) {
              onSuccess(newresponse)
            } else {
              const filteredRes = photoResponses.filter((e) => e.field === `itemdetails.0.photos`)
              if (filteredRes.length > 0) {
                if (filteredRes.length > 1) {
                  const withoutLoading = filteredRes.filter((e) => e.response.photo !== "loading")
                  if (withoutLoading.length > 0) {
                    onSuccess(withoutLoading[0].response)
                  } else {
                    onSuccess(filteredRes[0].response)
                  }
                } else {
                  onSuccess(filteredRes[0].response)
                }
              } else {
                onSuccess()
              }
            }
          }
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: `${e}_upload`,
            lastaction: "start",
            lasttime: new Date().getTime(),
            lastdata: { insert_code: getConfigCode(), formdata, onSuccess: onPhotoSuccess },
          })
        } else {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: `${e}_upload`,
            lastaction: "start",
            lasttime: new Date().getTime(),
            lastdata: { insert_code: getConfigCode(), formdata, onSuccess: (Response, Field) => onUploadComplete(formdata.uploadImageArray[index + 1], index + 1, Response, Field) },
          })
        }
      }
      onUploadComplete(formdata.uploadImageArray[0], 0)
    } else {
      onSuccess()
    }
  } else {
    onSuccess()
  }
}

export const showdialog = ({ yesno, confirm, message, title, form, meta, loading, confirmNo, dialogReason, onConfirm }) => {
  return (dispatch) => {
    if (yesno === true) {
      dispatch({
        type: CONFIRM_DIALOG,
        payload: true,
        confirm: confirm,
        message: message,
        title: title,
        form: form,
        meta: meta,
        loading: loading,
        confirmNo: confirmNo,
        dialogReason: dialogReason,
        onConfirm: onConfirm,
      })
    } else {
      dispatch({
        type: CONFIRM_DIALOG,
        payload: false,
        confirm: confirm,
        message: message,
        title: title,
        form: form,
        meta: meta,
        loading: loading,
        confirmNo: confirmNo,
        dialogReason: dialogReason,
      })
    }
  }
}

export const formPostUpdate = ({ formtype, formaction, formdata }) => {
  return (dispatch) => {
    dispatch({
      type: FORM_POST_UPDATE,
      formtype,
      formaction,
      formdata,
    })
  }
}

export const pageFormPostUpdate = ({ formtype, formaction, formdata }) => {
  return (dispatch) => {
    dispatch({
      type: PAGE_FORM_POST_UPDATE,
      formtype,
      formaction,
      formdata,
    })
  }
}

export const dialogloading = (state) => {
  return (dispatch) => {
    dispatch({
      type: DIALOG_LOADING,
      state: state,
    })
  }
}

export const formdialogloading = (state) => {
  return (dispatch) => {
    dispatch({
      type: DIALOG_LOADING,
      state: state,
    })
  }
}
export const drawerdialogloading = (state) => {
  return (dispatch) => {
    dispatch({
      type: DIALOG_LOADING,
      state: state,
    })
  }
}

export const showformdialog = ({ yesno, confirm, message, title, form, meta, loading, confirmNo, dialogReason, onConfirm }) => {
  return (dispatch) => {
    if (yesno === true) {
      dispatch({
        type: CONFIRM_DIALOG,
        payload: true,
        confirm: confirm,
        message: message,
        title: title,
        form: form,
        meta: meta,
        loading: loading,
        confirmNo: confirmNo,
        dialogReason: dialogReason,
        onConfirm: onConfirm,
      })
    } else {
      dispatch({
        type: CONFIRM_DIALOG,
        payload: false,
        confirm: confirm,
        message: message,
        title: title,
        form: form,
        meta: meta,
        loading: loading,
        confirmNo: confirmNo,
        dialogReason: dialogReason,
      })
    }
  }
}

export const showdrawerdialog = ({ yesno, confirm, message, title, form, meta, loading, confirmNo, dialogReason, onConfirm }) => {
  return (dispatch) => {
    if (yesno === true) {
      dispatch({
        type: CONFIRM_DIALOG,
        payload: true,
        confirm: confirm,
        message: message,
        title: title,
        form: form,
        meta: meta,
        loading: loading,
        confirmNo: confirmNo,
        dialogReason: dialogReason,
        onConfirm: onConfirm,
      })
    } else {
      dispatch({
        type: CONFIRM_DIALOG,
        payload: false,
        confirm: confirm,
        message: message,
        title: title,
        form: form,
        meta: meta,
        loading: loading,
        confirmNo: confirmNo,
        dialogReason: dialogReason,
      })
    }
  }
}

export const dialogReasonUpdate = (val) => {
  return (dispatch) => {
    dispatch({
      type: DIALOG_REASON_UPDATE,
      val: val,
    })
  }
}

const postServiceFail = ({ dispatch, response, formdata, saveandaddanother, formaction, formtype, functions }) => {
  const invoice_type_detail = getAccountSitesetting("invoice_type_detail")
  const inward_type_detail = getAccountSitesetting("inward_type_detail")

  if (invoice_type_detail.filter((e) => e.id === formtype).length > 0 || formtype === "customPageFormSrno" || inward_type_detail.filter((e) => e.id === formtype).length > 0) {
    dispatch({
      type: PAGE_FORM_POST_SERVICE_FAIL,
      payload: response,
      formdata: formdata,
    })
  } else {
    dispatch({
      type: FORM_POST_SERVICE_FAIL,
      payload: response,
      formdata: formdata,
    })
  }
  if (formaction === "update" && formtype === "opening_stock") {
    dispatch({ type: POP_OVER_LOADING_STATE, state: false })
  }
}

export const deleteImage = ({ formtype, formdata, formaction, functions }) => {
  const { onError } = functions
  return (dispatch) => {
    const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", formtype)
    // data.append("user.data", formaction)
    data.append("action", formaction)
    data.append("form_data", formdata2)

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "delete_image", props: { formdata } }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          if (onError) {
            onError()
          }

          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: formtype,
            lastaction: "delete",
            lasttime: new Date().getTime(),
            lastdata: formdata,
          })

          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (onError) {
          onError()
        }
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const setExpandValue = (value, drawerIndex) => {
  return (dispatch) => {
    dispatch({
      type: SET_EXPAND_VALUE,
      value: value,
      drawerIndex: drawerIndex,
    })
  }
}

export const loadingstate = (state, sidebarstate) => {
  return (dispatch) => {
    dispatch({ type: LOADING_STATE, state: state, sidebarstate: sidebarstate })
  }
}

export const lastPageDataSet = ({ lasttype, lastaction, lastdata }) => {
  return (dispatch) => {
    dispatch({
      type: LAST_PAGE_UPDATE,
      lasttype: lasttype,
      lastaction: lastaction,
      lasttime: new Date().getTime(),
      lastdata: lastdata,
    })
  }
}

export const photoUploadSuccess = ({ formtype, code, photo }) => {
  return (dispatch) => {
    if (formtype === "item_image") {
      dispatch({
        type: ITEM_PHOTO_UPDATED,
        update_code: code,
        photo: photo,
      })
    } else if (formtype === "configurable_item_image") {
      dispatch({
        type: CONFIGURABLE_ITEM_PHOTO_UPDATED,
        update_code: code,
        photo: photo,
      })
    } else if (formtype === "bundle_item_image") {
      dispatch({
        type: BUNDLE_ITEM_PHOTO_UPDATED,
        update_code: code,
        photo: photo,
      })
    } else if (formtype === "item_group_image") {
      dispatch({
        type: ITEM_GROUP_PHOTO_UPDATED,
        update_code: code,
        photo: photo,
      })
    } else if (formtype === "company_logo") {
      dispatch({
        type: COMPANY_PHOTO_UPDATED,
        update_code: code,
        photo: photo,
      })
    } else if (formtype === "user_image") {
      dispatch({
        type: USER_PHOTO_UPDATED,
        update_code: code,
        photo: photo,
      })
    } else if (formtype === "item_sub_group_image") {
      dispatch({
        type: PRODUCT_SUB_GROUP_PHOTO_UPDATED,
        update_code: code,
        photo: photo,
      })
    } else if (formtype === "item_sub_grouplevel2_image") {
      dispatch({
        type: PRODUCT_SUB_GROUP_LEVEL2_PHOTO_UPDATED,
        update_code: code,
        photo: photo,
      })
    }
  }
}

export const handleCreate = ({ formtype, formdata, setLoading, otherDetail, onSuccess, functions }) => {
  return (dispatch) => {
    setLoading(true)
    const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", formtype)
    data.append("action", "insert")
    data.append("form_data", formdata2)
    data.append("offset", 0)
    data.append("limit", "All")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "dropDown_insert" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
          setLoading(false)
        } else {
          onSuccess(response.data.insert_code.toString())
          dispatch({
            type: DROPDOWN_ITEM_INSERTED,
            formtype: formtype,
            code: response.data.insert_code,
            name: formdata.name,
            payload: formdata,
          })
          setTimeout(() => {
            dispatch(change(otherDetail.form, otherDetail.field, response.data.insert_code))
            setLoading(false)
          }, 1000)
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        setLoading(false)
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const setFormData = ({ type, payload, pageformtype, pageformaction, saveandaddanother, otherDetail }) => {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: payload,
      pageformtype: pageformtype,
      pageformaction: pageformaction,
      saveandaddanother: saveandaddanother,
      otherDetail: otherDetail,
    })
  }
}

export const setLastMenuKey = ({ key }) => {
  return (dispatch) => {
    dispatch({
      type: SET_LAST_MENU_KEY,
      key: key,
    })
  }
}

// export const common_modalOrderDetail = ({ code, otherType, title, filename, otherDetail, printType, functions }) => {
//   const { getWindowWidth, dispatch } = functions
//   const invoice_type_detail = getAccountSitesetting("invoice_type_detail")
//   const inward_type_detail = getAccountSitesetting("inward_type_detail")
//   if (typeof getAxiosCancelToken() != typeof undefined) {
//     getAxiosCancelToken().cancel("Operation canceled due to new request.")
//   }
//   setAxiosCancelToken(axios.CancelToken.source())
//   dispatch({ type: LOADING_STATE, state: true })
//   let data = new FormData()
//   data.append("type", "invoice_detaildata")
//   data.append("code", code)
//   data.append("other_type", otherType)
//   if (!isblank(functions.accountType)) {
//     data.append("from_call", functions.accountType)
//   }
//   axios.defaults.headers.common = getheaders()
//   axios({
//     method: "post",
//     url: list_url({ type: "invoice_detail" }),
//     headers: { "Content-Type": "application/x-www-form-urlencoded" },
//     data: data,
//     cancelToken: getAxiosCancelToken().token,
//   })
//     .then((response) => handleResponse(response, dispatch))
//     .then((response) => {
//       if (response.data.meta.code === 401) {
//         dispatch({
//           type: SNACKBAR_STATE,
//           payload: true,
//           message: response.data.meta.message,
//           snackbarType: "error",
//         })
//         dispatch({ type: LOADING_STATE, state: false })
//       } else {
//         if (isblank(response.data.result.master_table_data[0].invoice_type)) {
//           if (!isblank(response.data.result.master_table_data[0].inward_type)) {
//             response.data.result.master_table_data[0].invoice_type = response.data.result.master_table_data[0].inward_type
//           }
//           if (!isblank(response.data.result.master_table_data[0].outward_type)) {
//             response.data.result.master_table_data[0].invoice_type = response.data.result.master_table_data[0].outward_type
//           }
//         }

//         var other_type = !isblank(response.data.result.master_table_data[0].invoice_type) ? response.data.result.master_table_data[0].invoice_type : otherType
//         var metadata = {}
//         var maxWidth = getWindowWidth()
//         if (response.data.result.user_details && response.data.result.user_details[0] && response.data.result.user_details[0].name) {
//           metadata.filename = getAccountSessionUserData("company_url") + "_" + filename.replace(/\s/gi, "_") + "_" + response.data.result.user_details[0].name.toLowerCase().replace(/\s/gi, "_")
//         } else {
//           metadata.filename = getAccountSessionUserData("company_url") + "_" + filename.replace(/\s/gi, "_") + "_" + getAccountSessionUserData("financial_year")
//         }
//         metadata.title = title
//         metadata.invoice_type = other_type
//         metadata.other_type = other_type
//         metadata.otherDetail = otherDetail
//         if (!isblank(response.data.result.master_table_data[0].billno_str)) {
//           response.data.result.master_table_data[0].billno = response.data.result.master_table_data[0].billno_str
//         }
//         var printData = ""
//         var template_id = ""
//         if (otherDetail.printType === "html") {
//           if (inward_type_detail.filter((e) => e.id === other_type).length > 0) {
//             const pageSize = inward_type_detail.filter((e) => e.id === other_type)[0].template_size === "1" ? "A5" : "A4"
//             printData = generateInwardPdf({ printdata: response.data.result, meta: metadata, pageSize, functions })
//           } else if (invoice_type_detail.filter((e) => e.id === other_type).length > 0) {
//             const pageSize = invoice_type_detail.filter((e) => e.id === other_type)[0].template_size === "1" ? "A5" : "A4"
//             template_id = response.data.result.master_table_data[0].print_template_no
//             const Template = !isblank(template_id) ? template_id : invoice_type_detail.filter((e) => e.id === other_type)[0].template
//             if (!isblank(Template)) {
//               metadata.type = "invoice_type_detail"
//               printData = generatePrintData({ data: response.data.result, metadata, pageSize, Template, functions })
//             } else {
//               dispatch({
//                 type: SNACKBAR_STATE,
//                 payload: true,
//                 message: "Select Template First",
//                 snackbarType: "error",
//               })
//             }
//           }
//           if (!isblank(printData)) {
//             if (printType === "print" || printType === "download") {
//               fbprintpdf({ docDefinition: printData, metadata, action: printType })
//             } else {
//               const functions = { dispatch, maxWidth, pushDrawer }
//               fbprintpdf({ docDefinition: printData, metadata, action: "print_preview", functions })
//             }
//           }
//         } else {
//           setTimeout(() => {
//             if (!isblank(otherDetail) && otherDetail.from === "drawer") {
//               updateDrawer({ title: "Invoice Detail", data: response.data.result, fieldname: metadata, dispatch, index: otherDetail.drawerIndex })
//             } else {
//               if (other_type === "stockTransfer") {
//                 pushDrawer({
//                   title: "Stock Transfer Detail",
//                   data: response.data.result,
//                   fieldname: metadata,
//                   position: maxWidth <= 1024 ? "bottom" : "right",
//                   width: maxWidth <= 1024 ? maxWidth : 1024,
//                   dispatch,
//                 })
//               } else {
//                 pushDrawer({
//                   title: "Invoice Detail",
//                   data: response.data.result,
//                   fieldname: metadata,
//                   position: maxWidth <= 1024 ? "bottom" : "right",
//                   width: maxWidth <= 1024 ? maxWidth : 1024,
//                   dispatch,
//                 })
//               }
//             }
//           }, 300)
//         }
//         dispatch({ type: LOADING_STATE, state: false })
//       }
//     })
//     .catch((error) => {
//       if (axios.isCancel(error)) {
//         dispatch({ type: LOADING_STATE, state: false })
//       } else {
//         postErrorOnException(error)
//         dispatch({
//           type: SNACKBAR_STATE,
//           payload: true,
//           message: getCatchErrorMessage(error),
//           snackbarType: "error",
//         })
//         dispatch({ type: LOADING_STATE, state: false })
//       }
//     })
// }

const generatePrintData = ({ data, metadata, pageSize, Template, functions }) => {
  var printData = ""
  if (Template !== "2" && Template !== "3") {
    functions.generatePdfDoc = generatePdfDoc
    printData = dynamicTemplate({ print_data: data, meta: metadata, functions })
    return printData
  } else {
    if (Template === "2") {
      printData = invoiceTemplate2({ printdata: data, meta: metadata, pageSize, functions })
      return printData
    }
    if (Template === "3") {
      printData = invoiceTemplate3({ printdata: data, meta: metadata, pageSize, functions })
      return printData
    }
  }
}

export const sendEmail = ({ formtype, formdata, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: false, sidebarstate: true })
    const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)

    let data = new FormData()
    data.append("type", formtype)
    data.append("form_data", formdata2)
    data.append("offset", 0)
    data.append("limit", "All")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "sendEmail" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        } else {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        }
      })
  }
}

export const sendSMS = ({ formtype, formdata, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: false, sidebarstate: true })
    const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)

    let data = new FormData()
    data.append("type", formtype)
    data.append("form_data", formdata2)
    data.append("offset", 0)
    data.append("limit", "All")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "sendSMS" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        } else {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        }
      })
  }
}

export const common_getPartyDetail = ({ dispatch, code, date, loading, type, functions }) => {
  const { onSuccess } = functions
  dispatch({ type: LOADING_STATE, state: loading })
  if (!isblank(code)) {
    let data = new FormData()
    data.append("type", "party_detail")
    data.append("party_code", code)
    if (type !== "envelopePrinting" || type !== "shareReport") {
      data.append("from_date", date)
    }
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "party_detail" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 200) {
          response.data.result[0].address = replaceBRtoNewLine(response.data.result[0].address)
          response.data.result[0].shipp_address = replaceBRtoNewLine(response.data.result[0].shipp_address)

          if (type === "envelopePrinting") {
            dispatch({
              type: CONFIRM_DIALOG,
              payload: true,
              confirm: false,
              message: "",
              title: "Print Envelope",
              form: "Envelope Template",
              meta: { partyData: response.data.result[0] },
              loading: false,
            })
          } else {
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "customer",
              lastaction: "change",
              lasttime: new Date().getTime(),
              lastdata: response.data.result,
            })
          }
          if (onSuccess) {
            onSuccess(response.data.result[0])
          }
        } else {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "customer",
            lastaction: "error",
            lasttime: new Date().getTime(),
            lastdata: response.data.result,
          })

          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        }
        dispatch({ type: LOADING_STATE, state: false })
      })
      .catch((error) => {
        postErrorOnException(error)
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "customer",
          lastaction: "error",
          lasttime: new Date().getTime(),
          lastdata: "",
        })
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
        dispatch({ type: LOADING_STATE, state: false })
      })
  } else {
    dispatch({ type: LOADING_STATE, state: false })
  }
}

export const common_openitembatchout = ({ dispatch, item, metadata, functions, godowncode }) => {
  dispatch({ type: LOADING_STATE, state: true })
  if (typeof getAxiosCancelToken() != typeof undefined) {
    getAxiosCancelToken().cancel("Operation canceled due to new request.")
  }
  // save the new request for cancellation
  setAxiosCancelToken(axios.CancelToken.source())

  let data = new FormData()
  data.append("itemcode", item.code)
  data.append("mode", metadata.mode)
  data.append("invoicetype", metadata.invoicetype)
  if (!isblank(metadata.formname)) {
    data.append("formname", metadata.formname)
  }

  if (metadata.mode === "edit") {
    data.append("invoicecode", metadata.invoicecode)
  }
  if (!isblank(godowncode)) {
    data.append("godowncode", godowncode)
  }
  axios.defaults.headers.common = getheaders()
  axios({
    method: "post",
    url: list_url({ type: "batch_out" }),
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
    cancelToken: getAxiosCancelToken().token,
  })
    .then((response) => handleResponse(response, dispatch))
    .then((response) => {
      if (response.data.meta.code === 401) {
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: response.data.meta.message,
          snackbarType: "error",
        })
        dispatch({ type: LOADING_STATE, state: false })
      } else {
        if (item.advance_tracking === "1") {
          const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
          response.data.result = response.data.result.map((item) => {
            const updatedItem = { ...item }
            updatedItem.description = `Available Qty: ${amount_formatDecimal(item.qty, inventory_decimalpoint)}`
            return updatedItem
          })
          dispatch({
            type: PRODUCT_BATCH_SERVICE_SUCCESS,
            payload: makedropdowncompatible(response.data.result, "batch_referenceno"),
          })

          pushDrawer({ title: "Select Batch", data: metadata, position: "right", width: 950, dispatch })
        } else if (item.advance_tracking === "2") {
          dispatch({
            type: PRODUCT_SERIAL_SERVICE_SUCCESS,
            payload: makedropdowncompatible(response.data.result, "batch_referenceno"),
          })

          pushDrawer({ title: "Select Serial", data: metadata, position: "right", width: 550, dispatch })
        }
        dispatch({ type: LOADING_STATE, state: false })
      }
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        dispatch({ type: LOADING_STATE, state: false })
      } else {
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
        dispatch({ type: LOADING_STATE, state: false })
      }
    })
}

export const clearCache = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", "clearcache")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "clearCache" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
        dispatch({ type: LOADING_STATE, state: false })
      })
  }
}

export const syncAndRestoreDB = ({ type, dbtype, dbbackup_date }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", type)
    data.append("db_type", dbtype)
    data.append("dbbackup_date", dbbackup_date)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "syncdb" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else if (response.data.meta.code === 403) {
          dispatch({
            type: CONFIRM_DIALOG,
            payload: true,
            confirm: false,
            message: "",
            title: "Message",
            form: "Error List",
            meta: response.data.meta.message,
          })

          if (type === "restore_backupDB") {
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "syncAndRestoreDB",
              lastaction: "success",
              lasttime: new Date().getTime(),
              lastdata: response.data.result,
            })
          }

          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
          if (type === "restore_backupDB") {
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "syncAndRestoreDB",
              lastaction: "success",
              lasttime: new Date().getTime(),
              lastdata: response.data.result,
            })
          }
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
        dispatch({ type: LOADING_STATE, state: false })
      })
  }
}

export const multi_select = ({ form, field, selected }) => {
  return (dispatch) => {
    dispatch(change(form, field, selected))
    removeDrawer(dispatch)
  }
}

const setLastTransDate = ({ dispatch, dateKey, date, getTransDate }) => {
  dispatch({
    type: SET_LAST_DATE,
    dateKey: dateKey,
    lastDate: date,
  })

  const lastTransDate = {
    last_receiptdate: getTransDate("last_receiptdate", "YMD"),
    last_paymentdate: getTransDate("last_paymentdate", "YMD"),
    last_recordexpensedate: getTransDate("last_recordexpensedate", "YMD"),
    last_journalentrydate: getTransDate("last_journalentrydate", "YMD"),
    last_salesinvoicedate: getTransDate("last_salesinvoicedate", "YMD"),
    last_purchaseinvoicedate: getTransDate("last_purchaseinvoicedate", "YMD"),
    last_salesreturndate: getTransDate("last_salesreturndate", "YMD"),
    last_purchasereturndate: getTransDate("last_purchasereturndate", "YMD"),
  }
  lastTransDate[dateKey] = date
  setStorage("lastTransDate", lastTransDate)
}

export const setLastDraft = (draftData) => {
  return (dispatch) => {
    dispatch({
      type: SET_LAST_DRAFT,
      payload: draftData,
    })
  }
}

export const postSinglePhotoURL = ({ update_code, imageNames, other_type, onError, onSuccess, srNo }) => {
  const functions = { handleResponse, getheaders, config: {}, postErrorOnException, isMobileOnly }
  return (dispatch) => {
    common_postSinglePhotoURL({ dispatch, update_code, imageNames, other_type, onError, onSuccess, functions, srNo })
  }
}

export const fetchItemPrice = ({ param, setItemRate, functions }) => {
  const product_closing_balance = parseInt(getAccountSitesetting("product_closing_balance"))
  return (dispatch) => {
    let data = new FormData()
    data.append("limit", "All")
    data.append("offset", 0)
    data.append("type", "item_price")
    data.append("itemcode", param.code)
    data.append("party_code", param.partycode)
    data.append("invoice_date", param.InvoiceDate)
    if (product_closing_balance !== 1) {
      data.append("closing_bal", false)
    }
    data.append("other_type", param.other_type)

    if (param.invoice_type) {
      data.append("invoice_type", param.invoice_type)
    }
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "item_price" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "item_price",
            lastaction: "error",
            lasttime: new Date().getTime(),
            lastdata: param.index,
          })
        } else {
          setItemRate(response, true)
        }
      })
      .catch((error) => {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "item_price",
          lastaction: "error",
          lasttime: new Date().getTime(),
          lastdata: param.index,
        })
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
      })
  }
}

export const fetchAvgItemPrice = ({ param, setAvgItemRate, functions }) => {
  const { loading = false, onComplete } = functions

  return (dispatch) => {
    if (loading) {
      dispatch({ type: LOADING_STATE, state: true })
    }
    let data = new FormData()
    data.append("type", "item_avg_rate")
    data.append("itemcode", param.code)
    data.append("to_date", param.InvoiceDate)
    // data.append("rate_setting", param.rate_setting)
    data.append("other_type", param.other_type)
    data.append("invoice_type", param.invoice_type)

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "item_avg_rate" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "item_avg_rate",
            lastaction: "error",
            lasttime: new Date().getTime(),
            lastdata: param.index,
          })
          if (loading) {
            dispatch({ type: LOADING_STATE, state: false })
          }
        } else {
          setAvgItemRate(response, true)
          if (onComplete) {
            onComplete(response)
          }
          if (loading) {
            dispatch({ type: LOADING_STATE, state: false })
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "item_avg_rate",
          lastaction: "error",
          lasttime: new Date().getTime(),
          lastdata: param.index,
        })
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
        if (loading) {
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const common_modalproductDetail = ({ code, other_type, invoice_type, onComplete = false, otherDetail, dispatch, drawerIndex }) => {
  if (typeof getAxiosCancelToken() != typeof undefined) {
    getAxiosCancelToken().cancel("Operation canceled due to new request.")
  }
  // save the new request for cancellation
  setAxiosCancelToken(axios.CancelToken.source())
  if (!isblank(otherDetail) && otherDetail.state) {
    dispatch({ type: LOADING_STATE, state: false, sidebarstate: true })
  } else {
    dispatch({ type: LOADING_STATE, state: true })
  }
  let data = new FormData()
  data.append("type", "item")
  data.append("code", code)
  data.append("flag", 1)
  data.append("from_date", `${getstartyear()}-04-01`)
  data.append("to_date", `${getendyear()}-03-31`)
  if (!isblank(other_type)) {
    data.append("other_type", other_type)
  }
  if (!isblank(invoice_type)) {
    data.append("invoice_type", invoice_type)
  }
  axios.defaults.headers.common = getheaders()
  axios({
    method: "post",
    url: list_url({ type: "product_detail" }),
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
    cancelToken: getAxiosCancelToken().token,
  })
    .then((response) => handleResponse(response, dispatch))
    .then((response) => {
      if (response.data.meta.code === 401) {
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: response.data.meta.message,
          snackbarType: "error",
        })
        if (!isblank(otherDetail) && otherDetail.state) {
          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        } else {
          dispatch({ type: LOADING_STATE, state: false })
        }
      } else {
        var maxWidth = getWindowWidth()
        if (!isblank(drawerIndex) && onComplete === false) {
          updateDrawer({ title: "Product Detail", data: response.data.result, dispatch, index: drawerIndex })
        } else if (onComplete) {
          onComplete(response.data.result)
        } else {
          pushDrawer({ title: "Product Detail", data: response.data.result, position: maxWidth <= 1024 ? "bottom" : "right", width: maxWidth <= 1024 ? maxWidth : 1024, dispatch })
        }
        if (!isblank(otherDetail) && otherDetail.state) {
          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        } else {
          dispatch({ type: LOADING_STATE, state: false })
        }
      }
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        dispatch({ type: LOADING_STATE, state: false })
      } else {
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
        dispatch({ type: LOADING_STATE, state: false })
      }
    })
}

export const common_modalConfigProductDetail = ({ code, dispatch, drawerIndex }) => {
  if (typeof getAxiosCancelToken() != typeof undefined) {
    getAxiosCancelToken().cancel("Operation canceled due to new request.")
  }
  setAxiosCancelToken(axios.CancelToken.source())
  dispatch({ type: LOADING_STATE, state: true })
  const formdata2 = Array.isArray(code) ? code.map(JSON.stringify) : JSON.stringify(code)
  let data = new FormData()
  data.append("type", "configurableitem")
  // data.append("user.data", "edit")
  data.append("action", "edit")
  data.append("form_data", formdata2)
  data.append("offset", 0)
  data.append("limit", "All")
  axios.defaults.headers.common = getheaders()
  axios({
    method: "post",
    url: post_form_url({ formtype: "configurableitem", props: { getAccountSitesetting } }),
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
    cancelToken: getAxiosCancelToken().token,
  })
    .then((response) => handleResponse(response, dispatch))
    .then((response) => {
      if (response.data.meta.code === 401) {
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: response.data.meta.message,
          snackbarType: "error",
        })
        dispatch({ type: LOADING_STATE, state: false })
      } else {
        if (!isblank(drawerIndex)) {
          updateDrawer({ title: "Config Product Detail", data: response.data.form_data, dispatch, index: drawerIndex })
        } else {
          pushDrawer({ title: "Config Product Detail", data: response.data.form_data, position: "bottom", dispatch })
        }
        dispatch({ type: LOADING_STATE, state: false })
      }
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        dispatch({ type: LOADING_STATE, state: false })
      } else {
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
        dispatch({ type: LOADING_STATE, state: false })
      }
    })
}

export const loadingsnackbar = ({ open, message, snackbarType }) => {
  return {
    type: LOADING_SNACKBAR_STATE,
    payload: open,
    message: message,
    snackbarType: snackbarType,
  }
}

export const customDialog = ({ visible, title, message, buttons, dismissLabel }) => {
  return {
    type: CUSTOM_DIALOG,
    visible: visible,
    title: title,
    message: message,
    buttons: buttons,
    dismissLabel: dismissLabel,
  }
}

export const showMultiError = ({ dispatch, title, error }) => {
  dispatch({
    type: CONFIRM_DIALOG,
    payload: true,
    confirm: false,
    message: "",
    title: title,
    form: "Error List",
    meta: error,
  })
}

export const filteredchanged = (data) => {
  return {
    type: FILTERED_CHANGED,
    payload: data,
  }
}

export const common_data_clear = () => {
  return (dispatch) => {
    dispatch({
      type: COMMON_SERVICE_CLEAR,
    })
  }
}
export const common_search_change = ({ search_text }) => {
  return (dispatch) => {
    dispatch({
      type: COMMON_SEARCH_TEXT_UPDATE,
      search_text: search_text,
    })
  }
}

export const commonResetReduxFrom = (formName) => {
  return (dispatch) => {
    dispatch(reset(formName))
  }
}

export const generateProductSKU = ({ code, group, subgroup, purchase_rate, sales_rate, onSuccess, onError }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    setAxiosCancelToken(axios.CancelToken.source())
    let data = new FormData()
    data.append("code", code)
    data.append("group", group)
    data.append("subgroup", subgroup)
    data.append("sales_rate", sales_rate)
    data.append("purchase_rate", purchase_rate)
    data.append("type", "get_sku")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "get_sku" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          onError(response.data.meta.message)
        } else {
          onSuccess(response.data.result)
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          onError(getCatchErrorMessage(error))
        } else {
          postErrorOnException(error)
          onError(getCatchErrorMessage(error))
        }
      })
  }
}

export const addRecentItem = (item) => ({
  type: ADD_RECENT_ITEM,
  payload: item,
})

export const addRecentAllAccount = (item) => ({
  type: ADD_RECENT_ALLACCOUNT,
  payload: item,
})
