import { getCatchErrorMessage } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { IMPORT_TEMPLATE_LOCAL_SEARCH, IMPORT_TEMPLATE_SERVICE_FAIL, IMPORT_TEMPLATE_SERVICE_START, IMPORT_TEMPLATE_SERVICE_SUCCESS, LOADING_STATE, SNACKBAR_STATE } from "../../Actions/types"
import { getheaders, handleResponse, postErrorOnException } from "../../Lib/commonfunctions"

export const local_search_importtemplate = ({ search_text }) => {
  return (dispatch) => {
    dispatch({
      type: IMPORT_TEMPLATE_LOCAL_SEARCH,
      search_text: search_text,
    })
  }
}
export const fetch_import_template_data = (functions) => {
  return (dispatch) => {
    /* if (typeof getAxiosCancelToken() != typeof undefined) {
       getAxiosCancelToken().cancel('Operation canceled due to new request.')
     }
     // save the new request for cancellation
     setAxiosCancelToken(axios.CancelToken.source());*/

    dispatch({ type: IMPORT_TEMPLATE_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    //data.append('offset',page*rowsPerPage);
    data.append("type", "importlabel_setting")
    data.append("limit", "All")
    //data.append('sort_order', order);
    //data.append('sort_by', orderBy);
    //data.append('search_text', search_text);

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: process.env.REACT_APP_BASE_URL + "/user/getalllist",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      //cancelToken: getAxiosCancelToken().token
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          importtemplateServiceFail({ dispatch, response: response.data.meta.message })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          importtemplateServiceSuccess({ dispatch, user: response })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        /* if (axios.isCancel(error)) {
           dispatch({ type: LOADING_STATE,state:false });
          } else {*/
        postErrorOnException(error)
        importtemplateServiceFail({ dispatch, response: getCatchErrorMessage(error) })
        dispatch({ type: LOADING_STATE, state: false }) //}
      })
  }
}

const importtemplateServiceSuccess = ({ dispatch, user }) => {
  dispatch({
    type: IMPORT_TEMPLATE_SERVICE_SUCCESS,
    payload: user,
  })
}

const importtemplateServiceFail = ({ dispatch, response }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })

  dispatch({
    type: IMPORT_TEMPLATE_SERVICE_FAIL,
    payload: response,
  })
}
