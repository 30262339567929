import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core"
import { Add, Delete } from "@material-ui/icons"
import ActionButton from "@project/components/common/ActionButton"
import usePrevious from "@project/components/Hooks/usePrevious"
import { createDropDown, deletedataFromFormNew, getdropdownvalue } from "@project/components/utilityFunctions"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import { callError, onSubmitError } from "@project/sharedcomponents/errorFunction"
import {
  AddNumberFormat,
  RemoveNumberFormat,
  amount_format,
  checkCondition,
  getAdjustedCountV2,
  getNumberonly,
  getdropdownfieldvalue,
  isblank,
  replaceBRtoNewLine,
  replacePreFix,
  toFixedFn,
  validateInputText,
} from "@project/sharedcomponents/utilityFunctions"
import React, { useEffect, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import {
  addRecentAllAccount,
  adjustBillList,
  clear_dropDownUpdated,
  clearformdata,
  fetchNewInvoiceNumber,
  fetch_form_dep,
  formdrawerstate,
  getBalance,
  modalTransactionDetail,
  pendingTdsList,
  post_form_data,
  rightdrawerstate,
  showformdialog,
  snackbarstate,
} from "../../Actions"
import { DROPDOWN_INSERTED } from "../../Actions/types"
import styles from "../../Css/style.css"
import {
  JSONParseInEdit,
  JSONStringifyInPost,
  checkdropdowndependancy,
  getAccountSessionUserData,
  getAccountSitesetting,
  getMenuRightsByType,
  getTransDate,
  isValidFinancialDate,
  setLastTansDateV1,
} from "../../Lib/commonfunctions"
import Footer from "../app/footer"
import PageHeader from "../app/pageHeader"
import Field from "../common/reduxFormComponents"

const useStyles = makeStyles(() => ({
  ...styles,
}))

const format_fields = []
format_fields.push({ fields: ["amount"], Grid: "DetailGrid" })

const ModalTransactionMultiple = (props) => {
  const { drawerType, drawerData, drawerIndex, componentId, other_type } = props
  const type = other_type

  const getFormType = () => {
    let form_type = {}

    switch (type) {
      case "receipt":
        form_type.type = "receipt"
        form_type.title = "Receipt"
        form_type.formName = "modalReceipt"
        form_type.field = "invoiceCr"
        form_type.adjustType = "adjustinvoice"
        form_type.adjustSubType = "receivable_bills"
        // form_type.newInvoiceNo = receipt_new_doc_number
        // form_type.dateType = "last_receiptdate"
        break

      case "payment":
        form_type.type = "payment"
        form_type.title = "Payment"
        form_type.formName = "Payment"
        form_type.field = "invoiceDr"
        form_type.adjustType = "adjustpurchaseinvoice"
        form_type.adjustSubType = "receivable_onaccount"
        // form_type.newInvoiceNo = payment_new_doc_number
        // form_type.dateType = "last_paymentdate"
        break

      default:
        break
    }
    return form_type
  }
  const formType = getFormType()

  const defaultValues = {
    receipt_type: "M",
    date: getTransDate(`last_${formType.type}date`, "YMD"),
    DetailGrid: [
      { amount: amount_format(0), invoiceDr: {}, invoiceCr: {} },
      { amount: amount_format(0), invoiceDr: {}, invoiceCr: {} },
    ],
  }
  const { handleSubmit, control, reset, watch, setValue, setError, clearErrors } = useForm({ defaultValues })
  const formValues = watch()
  const { fields, append, remove } = useFieldArray({
    control,
    name: "DetailGrid",
  })
  const watchDetailGrid = watch("DetailGrid")
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchDetailGrid[index],
    }
  })
  // const [confirmData, setConfirmData] = useState({})
  const [billNoLoading, setBillNoLoading] = useState(false)
  const {
    ddbankaccount,
    lastpagetype,
    lastpageaction,
    deploading,
    lastpagetime,
    lastpagedata,
    ddallaccount,
    pageformaction,
    closingbalance,
    pageformdata,
    loading,
    sidebarloading,
    submitting,
    dropdownupdated,
    common_formtype,
  } = useSelector((state) => state.CommonReducer)
  const dispatch = useDispatch()
  const classes = useStyles()
  const prevDeploading = usePrevious(deploading)
  const prevLastPageTime = usePrevious(lastpagetime)
  const prevDropdownUpdated = usePrevious(dropdownupdated)
  const dS = {
    reason: parseInt(getAccountSitesetting("reason")),
    closing_balance: parseInt(getAccountSitesetting("closing_balance")),
    adjust_bill: parseInt(getAccountSitesetting("adjust_bill")),
    purchase_adjust_bill: parseInt(getAccountSitesetting("purchase_adjust_bill")),
    cost_center: parseInt(getAccountSitesetting("cost_center")),
    tds: parseInt(getAccountSitesetting("tds")),
    customerListAuto: parseInt(getAccountSitesetting("customerListAuto")),
    currency: getAccountSitesetting("currency"),
    new_doc_number: parseInt(getAccountSitesetting(`${formType.type}_new_doc_number`)),
    prefix: getAccountSitesetting(`${formType.type}_prefix`),
  }
  var saveandaddanother = false
  var saveandprint = false
  var savedraft = false
  var saveandshare = false
  const transactionNoSeries = replacePreFix(dS.prefix, getAccountSessionUserData)

  var balanceDetail = ""
  if (dS.closing_balance === 1 && !isblank(closingbalance)) {
    balanceDetail = {}
    var balance = ""
    if (closingbalance.hasOwnProperty("bal")) {
      if (!isblank(closingbalance.bal)) {
        balance = closingbalance.bal
      } else {
        balance = 0.0
      }
    }
    balanceDetail.name = closingbalance.name
    balanceDetail.balance = balance
    balanceDetail.balance_date = closingbalance.balance_date
  }
  // const adjustBill = type === "receipt" ? dS.adjust_bill : dS.purchase_adjust_bill
  const adjustBill = dS.adjust_bill
  const validate = (values) => {
    const errors = {}

    if (dS.new_doc_number === 1 || pageformaction === "edit") {
      if (!values["billNo"]) {
        errors["billNo"] = ErrorMessages["E00174"]
      }
    }
    if (!values["bankSelectedValue"]) {
      errors["bankSelectedValue"] = ErrorMessages["E00136"]
    }
    if (!isblank(values["narration"])) {
      if (!validateInputText(values["narration"])) {
        errors["narration"] = ErrorMessages["E00550"]
      }
    }
    const checkdate = isValidFinancialDate({ dtStr: values["date"] })
    if (checkdate) {
      errors["date"] = checkdate
    }
    if (!values["date"]) {
      errors["date"] = ErrorMessages["E00108"]
    }
    if (!values["DetailGrid"] || !values["DetailGrid"]["length"]) {
      errors["DetailGrid"] = ErrorMessages["E00054"]
    } else {
      const membersArrayErrors = []
      values["DetailGrid"].forEach((detailgrid, memberIndex) => {
        const memberErrors = {}
        //check if whole row is empty
        if (!detailgrid["accountSelectedValue"] && parseFloat(getNumberonly(detailgrid["amount"])) === 0 && memberIndex > 0) {
        } else {
          if (!detailgrid || !detailgrid["accountSelectedValue"]) {
            memberErrors["accountSelectedValue"] = ErrorMessages["E00056"]
            membersArrayErrors[memberIndex] = memberErrors
          }

          if (!isblank(detailgrid["Narrationitem"])) {
            if (!validateInputText(detailgrid["Narrationitem"])) {
              memberErrors["Narrationitem"] = ErrorMessages["E00550"]
              membersArrayErrors[memberIndex] = memberErrors
            }
          }

          if (type === "receipt" && detailgrid["accountSelectedValue"]) {
            var arraycount = values.DetailGrid.filter((item) => {
              return parseInt(detailgrid["accountSelectedValue"]) === parseInt(item.accountSelectedValue)
            })
            if (arraycount.length > 1) {
              memberErrors["accountSelectedValue"] = ErrorMessages["E00211"]
              membersArrayErrors[memberIndex] = memberErrors
            }
          }
          if (!detailgrid || parseFloat(getNumberonly(detailgrid["amount"])) === 0 || !detailgrid["amount"]) {
            memberErrors["amount"] = ErrorMessages["E00137"]
            membersArrayErrors[memberIndex] = memberErrors
          }
          var totalTDSAdjusted = 0
          var totalAdjusted = 0
          if (dS.cost_center === 1 && parseFloat(getNumberonly(detailgrid["amount"])) > 0 && !isblank(detailgrid.costcenter) && detailgrid.costcenter.length > 0) {
            totalAdjusted = detailgrid.costcenter.map((invoiceItem) => parseFloat(getNumberonly(invoiceItem.amountdata))).reduce((prev, next) => prev + next)
            if (totalAdjusted !== parseFloat(getNumberonly(detailgrid["amount"]))) {
              memberErrors["amount"] = ErrorMessages["E00178"]
              membersArrayErrors[memberIndex] = memberErrors
            }
          }

          if (
            type === "payment" &&
            dS.tds === 1 &&
            parseFloat(getNumberonly(detailgrid["amount"])) > 0 &&
            !isblank(detailgrid.tdsDetail) &&
            !isblank(detailgrid.tdsDetail.DetailGrid) &&
            detailgrid.tdsDetail.DetailGrid.length > 0
          ) {
            totalTDSAdjusted = detailgrid.tdsDetail.DetailGrid.map((invoiceItem) => parseFloat(getNumberonly(invoiceItem.adjust_amt))).reduce((prev, next) => prev + next)
            if (totalTDSAdjusted > parseFloat(getNumberonly(detailgrid["amount"]))) {
              memberErrors["amount"] = ErrorMessages["E00203"]
              membersArrayErrors[memberIndex] = memberErrors
            }
          }

          if (
            adjustBill === 1 &&
            parseFloat(getNumberonly(detailgrid["amount"])) > 0 &&
            !isblank(detailgrid[formType.field]) &&
            (detailgrid[formType.field].receivable_bills?.length > 0 || detailgrid[formType.field].receivable_onaccount?.length > 0)
          ) {
            totalAdjusted = detailgrid[formType.field][formType.adjustSubType].map((invoiceItem) => parseFloat(getNumberonly(invoiceItem.payment))).reduce((prev, next) => prev + next)
            if (totalAdjusted > parseFloat(getNumberonly(detailgrid["amount"]))) {
              memberErrors["amount"] = ErrorMessages["E00204"]
              membersArrayErrors[memberIndex] = memberErrors
            }
          }
          if (type === "receipt" && detailgrid["chequedate"] !== undefined) {
            const checkchequedate = isValidFinancialDate({ dtStr: detailgrid["chequedate"] })
            if (checkchequedate) {
              memberErrors["chequedate"] = checkchequedate
              membersArrayErrors[memberIndex] = memberErrors
            }
          }
        }
      })
      if (membersArrayErrors["length"]) {
        errors["DetailGrid"] = membersArrayErrors
      }
    }
    return errors
  }

  const onabankchange = (value) => {
    //opening_balance
    if (!isblank(value)) {
      if (dS.closing_balance === 1) {
        dispatch(getBalance({ code: value.code, date: formValues.date }))
      }
    } else {
      dispatch(getBalance({}))
    }
  }

  const addNewRow = (fields, index) => {
    append({ amount: amount_format(0), Narrationitem: "", accountSelectedValue: "" })
    setTimeout(function () {
      const element = document.getElementById(`DetailGrid[${fields.length}].accountSelectedValue`)
      if (!isblank(element)) {
        element.scrollIntoView({ block: "start", behaviour: "smooth" })
        var scrolledY = window.scrollY
        if (scrolledY) {
          // window.scroll(0, scrolledY - 200);
        }
        // document.getElementById(`DetailGrid[${fields.length}].accountSelectedValue`).focus();
      }
    }, 10)
  }

  const addNewAccount = (index, value) => {
    var data = {}
    data.formname = formType.formName
    data.index = index
    data.value = value
    data.remoteForm = 1
    dispatch(rightdrawerstate({ open: true, title: "Add New Account", data, fieldname: "DetailGrid[" + index + "].accountSelectedValue", position: "right", width: 450 }))
  }

  const check_item_costCenter = (index) => {
    if (formValues.DetailGrid && formValues.DetailGrid[index] && formValues.DetailGrid[index].hasOwnProperty("accountSelectedValue")) {
      var accountSelectedValue = ddallaccount.find((item) => parseInt(item.code) === parseInt(formValues.DetailGrid[index].accountSelectedValue))
      if (!isblank(accountSelectedValue)) {
        if (accountSelectedValue.hasOwnProperty("costcenter_mng")) {
          if (accountSelectedValue.costcenter_mng === "1") {
            return false
          }
        }
      }
    }
    return true
  }

  const opencostCenter = (index) => {
    if (!isblank(formValues.DetailGrid[index].accountSelectedValue)) {
      if (parseFloat(getNumberonly(formValues.DetailGrid[index].amount)) > 0) {
        var metadata = {}
        metadata.formname = formType.formName
        metadata.setValue = setValue
        metadata.index = index
        metadata.row = formValues.DetailGrid[index]
        metadata.Grid = "DetailGrid"
        metadata.Gridfieldname = "costcenter"
        dispatch(rightdrawerstate({ open: true, title: "Select Cost Center", data: metadata, fieldname: "amount", width: 650 }))
      } else {
        dispatch(snackbarstate({ open: true, message: ErrorMessages["E00434"], snackbarType: "error" }))
      }
    }
  }

  const adjust_bill = (index) => {
    const party_code = formValues.DetailGrid[index].accountSelectedValue
    const type = formType.adjustType
    var other_type = ""
    var code = ""
    const meta = {}
    var sameAcount = []
    sameAcount = formValues.DetailGrid.filter((item) => {
      return formValues.DetailGrid[index].accountSelectedValue === item.accountSelectedValue
    })
    meta.formname = formType.formName
    meta.setValue = setValue
    meta.amount = formValues.DetailGrid[index].amount
    meta.Grid = "DetailGrid"
    meta.index = index
    meta.field = formType.field
    var id = ""
    if (pageformaction === "edit") {
      id = formValues.DetailGrid[index].id
      other_type = "edit"
      code = formValues.update_code
      meta.action = "edit"
    }

    if (sameAcount.length > 1) {
      dispatch(snackbarstate({ open: true, message: ErrorMessages["E00441"], snackbarType: "error" }))
    } else {
      if (!isblank(formValues.DetailGrid[index].accountSelectedValue)) {
        if (parseFloat(getNumberonly(formValues.DetailGrid[index].amount)) > 0) {
          if (
            formValues.DetailGrid[index].hasOwnProperty(formType.field) &&
            !isblank(formValues.DetailGrid[index][formType.field]) &&
            (formValues.DetailGrid[index][formType.field].receivable_bills?.length > 0 || formValues.DetailGrid[index][formType.field].receivable_onaccount?.length > 0)
          ) {
            let data = formValues.DetailGrid[index][formType.field]
            /* let data = formValues.DetailGrid[index].invoice.map((item, index) => {
              const detailGrid = { ...item }
              detailGrid.InvoiceCode = item.code
              detailGrid.Description = item.Description
              detailGrid.DueDate = item.DueDate
              detailGrid.OriginalAmt = amount_format(item.netamt)
              detailGrid.OpenBalance = amount_format(item.pendingamt)
              detailGrid.Payment = amount_format(item.adjust_amt)
              return detailGrid
            }) */
            const metadata = {}
            metadata.data = data
            metadata.formname = formType.formName
            metadata.setValue = setValue
            metadata.amount = formValues.DetailGrid[index].amount
            metadata.Grid = "DetailGrid"
            metadata.action = "edit"
            metadata.index = index
            metadata.field = formType.field

            dispatch(rightdrawerstate({ open: true, title: "Adjust Bill By Bill", data: metadata, position: "right", width: 1024 }))
          } else {
            dispatch(adjustBillList({ party_code, type, other_type, meta, code, transactionType: formType.type === "receipt" ? "R" : "P", id }))
          }
        } else {
          dispatch(snackbarstate({ open: true, message: ErrorMessages["E00434"], snackbarType: "error" }))
        }
      }
    }
  }

  const handledelete = () => {
    var confirmdata = {}
    confirmdata.dispatch = dispatch
    confirmdata.formtype = formType.type
    confirmdata.formaction = "delete"
    confirmdata.formdata = formValues.update_code
    // setConfirmData(confirmdata)

    const onConfirm = (reason) => {
      deletedataFromFormNew({ post_form_data, confirmdata, ConfirmDialogData: reason, getAccountSitesetting })
      dispatch(showformdialog({ yesno: false }))
    }

    dispatch(showformdialog({ yesno: true, onConfirm }))
  }

  const toggleFormDrawer = (open, title) => {
    dispatch(formdrawerstate({ open, title }))
  }

  const handleprint = () => {
    var otherDetail = {}
    otherDetail.title = formType.title
    otherDetail.type = formType.type
    otherDetail.printType = "html"
    var printType = "print"
    dispatch(
      modalTransactionDetail({
        code: formValues.update_code.toString().split("_")[0],
        type: formType.type,
        title: `${otherDetail.title}: #${formValues.docno}`,
        filenameOld: `${formType.title}_${formValues.docno}`,
        otherDetail: otherDetail,
        printType: printType,
      })
    )
  }

  const onSubmitData = (values) => {
    const error = validate(values)

    if (Object.keys(error).length > 0) {
      callError({ error, onSubmitError, dispatch, snackbarstate, setError })
    } else {
      const formtype = formType.type
      var formaction = "insert"
      var formdata = JSON.parse(JSON.stringify(values))
      formdata.receipt_type = "M"

      formdata.bankSelectedValue = createDropDown(ddbankaccount, formdata.bankSelectedValue, "code")

      //remove blankrows
      formdata.DetailGrid = formdata.DetailGrid.filter((item) => {
        return !isblank(item.accountSelectedValue) && !isblank(item.amount)
      })

      const setGridData = (data) => {
        const updatedData = data
          .filter((e) => !isblank(e?.payment) && getNumberonly(e.payment) > 0)
          .map((item, index) => {
            const updatedItem = {}
            updatedItem.Type = item.Type
            updatedItem.Outstanding = getNumberonly(item.Outstanding)
            updatedItem.Code = item.Code
            updatedItem.OpenBalance = getNumberonly(item.OpenBalance)
            updatedItem.payment = getNumberonly(item.payment)
            if (!isblank(item.GridId)) {
              updatedItem.GridId = item.GridId
            }

            return updatedItem
          })
        return updatedData
      }

      formdata.DetailGrid = formdata.DetailGrid.map((detailitem, index) => {
        const updateditem = { ...detailitem }
        updateditem.accountSelectedValue = createDropDown(ddallaccount, detailitem.accountSelectedValue, "code")
        if (type === "payment") {
          updateditem.tdsDetail = JSONStringifyInPost(updateditem.tdsDetail)
        }

        if (!isblank(updateditem[formType.field])) {
          if (!isblank(updateditem[formType.field]["receivable_bills"]) && updateditem[formType.field].receivable_bills?.length > 0) {
            updateditem[formType.field].receivable_bills = setGridData(updateditem[formType.field].receivable_bills)
          } else {
            delete updateditem[formType.field].receivable_bills
          }
          if (!isblank(updateditem[formType.field]["receivable_onaccount"]) && updateditem[formType.field].receivable_onaccount?.length > 0) {
            updateditem[formType.field].receivable_onaccount = setGridData(updateditem[formType.field].receivable_onaccount)
          } else {
            delete updateditem[formType.field].receivable_onaccount
          }
        }

        if (formType.field === "invoiceDr") {
          delete updateditem.invoiceCr
        } else {
          delete updateditem.invoiceDr
        }

        return updateditem
      })

      if (dS.cost_center === 1) {
        formdata.DetailGrid = formdata.DetailGrid.map((item, index) => {
          const updateditem = { ...item }
          if (!isblank(updateditem.costcenter) && updateditem.costcenter.length > 0) {
            updateditem.costcenter = updateditem.costcenter.map((item, index) => {
              const updateditem = {}
              if (item?.costcenterdata?.hasOwnProperty("code")) {
                updateditem.costcenterdata = item.costcenterdata.code
              } else {
                updateditem.costcenterdata = item?.costcenterdata
              }
              updateditem.amountdata = getNumberonly(item.amountdata)
              return updateditem
            })

            updateditem.costcenter = encodeURIComponent(JSON.stringify(updateditem.costcenter))
          } else {
            updateditem.costcenter = ""
          }
          return updateditem
        })
      }
      formdata = RemoveNumberFormat({ obj: formdata, fields: format_fields })
      if (savedraft) {
        const search_data = {}

        search_data.vdate = formdata.date
        search_data.name = getdropdownvalue({ formValues: formdata, field: "bankSelectedValue", prop: "name" })
        search_data.name1 = ""
        search_data.amount = 0
        search_data.chequeno = ""

        formdata.DetailGrid.forEach((detailgrid, index) => {
          const name1 = getdropdownvalue({ formValues: detailgrid, field: "accountSelectedValue", prop: "name" })
          const amount = detailgrid.amount
          const chequeno = detailgrid.cheque
          if (!isblank(name1)) {
            if (!isblank(search_data.name1)) {
              if (search_data.name1.indexOf(name1) === -1) {
                search_data.name1 = `${search_data.name1}, ${name1}`
              }
            } else {
              search_data.name1 = name1
            }
          }
          if (!isblank(chequeno)) {
            if (!isblank(search_data.chequeno)) {
              if (search_data.chequeno.indexOf(chequeno) === -1) {
                search_data.chequeno = `${search_data.chequeno}, ${chequeno}`
              }
            } else {
              search_data.chequeno = chequeno
            }
          }
          search_data.amount = parseFloat(getNumberonly(search_data.amount)) + parseFloat(getNumberonly(amount))
        })

        const meta = {
          detail: JSON.stringify(values),
          search_data: JSON.stringify(search_data),
          draftCode: !isblank(values.draftCode) ? values.draftCode : "",
          common_formtype: common_formtype,
        }
        meta.url = `${formType.type}s`
        dispatch(showformdialog({ yesno: true, confirm: false, message: "Save as draft", title: "Draft", form: "Draft Dialog", meta }))
      } else {
        if (pageformaction === "edit") {
          formaction = "update"
          formdata.update_code = values.update_code
        }

        let draftCode = ""
        if (!isblank(pageformdata) && !isblank(pageformdata.data) && !isblank(pageformdata.data.form_data.draftCode)) {
          draftCode = pageformdata.data.form_data.draftCode
        }
        dispatch(post_form_data({ dispatch, formtype, formaction, formdata, saveandaddanother, saveandprint, saveandshare, common_formtype: common_formtype, draftCode }))
      }
    }
  }

  const handleSave = (type) => {
    if (type === "print") {
      saveandprint = true
    } else if (type === "saveandaddanother") {
      saveandaddanother = true
    } else if (type === "savedraft") {
      savedraft = true
    } else if (type === "share") {
      saveandshare = true
    } else {
      saveandaddanother = false
      savedraft = false
      saveandprint = false
      saveandshare = false
    }
    clearErrors()
    handleSubmit(onSubmitData, (errors) => onSubmitError({ errors, dispatch, snackbarstate }))()
  }

  const handleUpdate = (type) => {
    const error = validate(formValues)

    if (Object.keys(error).length > 0) {
      callError({ error, onSubmitError, dispatch, snackbarstate, setError })
    } else {
      if (dS.reason === 1) {
        const onConfirm = (reason) => {
          handleSave(type)
          dispatch(showformdialog({ yesno: false }))
        }

        dispatch(showformdialog({ yesno: true, message: "update", title: "Update", onConfirm }))
      } else {
        handleSave(type)
      }
    }
  }

  const saveDraft = () => {
    handleSave("savedraft")
  }

  const handlesaveandanother = () => {
    handleSave("saveandaddanother")
  }

  const onaccountchange = (index, value, fields) => {
    if (adjustBill === 1) {
      setValue(`DetailGrid[${index}].${formType.field}`, [])
    }
    if (dS.cost_center === 1) {
      setValue(`DetailGrid[${index}].costcenter`, [])
    }

    if (type === "payment") {
      if (dS.tds === 1) {
        if (value.isTds === 1) {
          setValue(`DetailGrid[${index}].isTdsAccount`, true)
        }
        setValue(`DetailGrid[${index}].tdsDetail`, "")
      } else {
        setValue(`DetailGrid[${index}].isTdsAccount`, "")
      }
    }

    if (dS.customerListAuto === 0) {
      if (!isblank(value)) {
        dispatch({
          type: DROPDOWN_INSERTED,
          formtype: "allaccount",
          code: value.code,
          name: value.name,
          payload: value,
        })
      }
    }

    if (!isblank(value)) {
      if (dS.closing_balance === 1) {
        dispatch(getBalance({ code: value.code, date: formValues.date }))
      }
    } else {
      dispatch(getBalance({}))
    }

    if (index === formValues.DetailGrid.length - 1) {
      if (!isblank(fields)) {
        addNewRow(fields, index)
      }
    }
  }

  const getInvoiceNumber = () => {
    if (!isblank(dS.new_doc_number) && dS.new_doc_number === 1) {
      if (pageformaction !== "edit") {
        setBillNoLoading(true)
        dispatch(fetchNewInvoiceNumber(formType.type, formType.type))
      }
    }
  }

  const onGridChange = (value, row) => {
    const newFormValues = row ? row : formValues.DetailGrid
    if (newFormValues !== undefined) {
      var tot_net_amount = 0

      //   if (!isEqual(prevProps.newFormValues, this.props.newFormValues)) {
      if (newFormValues) {
        newFormValues.forEach((detailgrid, index) => {
          if (!isNaN(getNumberonly(detailgrid.amount))) {
            tot_net_amount = tot_net_amount + parseFloat(getNumberonly(detailgrid.amount))
          }
        })
        setValue(`tot_net_amount`, tot_net_amount)
      }
    }
  }

  const handleRemove = (index) => {
    const filterData = controlledFields.filter((item, iindex) => iindex !== index)
    if (index === 0 && controlledFields.length === 1) {
      setValue("DetailGrid", [{ amount: amount_format(0), Narrationitem: "", accountSelectedValue: "" }])
    } else {
      remove(index)
    }
    onGridChange("", filterData)
  }

  const handleAddRow = () => {
    append({ amount: amount_format(0), Narrationitem: "", accountSelectedValue: "", invoiceCr: {}, invoiceDr: {} })
    setTimeout(function () {
      const element = document.getElementById(`DetailGrid[${controlledFields.length}].accountSelectedValue`)
      if (!isblank(element)) {
        element.scrollIntoView({ block: "start", behaviour: "smooth" })
        var scrolledY = window.scrollY
        if (scrolledY) {
          window.scroll(0, scrolledY - 200)
        }
        element.focus()
      }
    }, 50)
  }

  const adjustTDS = (index) => {
    const type = "tds_deduction"
    var other_type = ""
    var code = ""
    const meta = {}
    var sameAcount = []
    sameAcount = formValues.DetailGrid.filter((item) => {
      return formValues.DetailGrid[index].accountSelectedValue === item.accountSelectedValue
    })
    meta.formname = "Payment"
    meta.setValue = setValue
    meta.amount = formValues.DetailGrid[index].amount
    meta.Grid = "DetailGrid"
    meta.index = index
    var id = ""
    if (pageformaction === "edit") {
      id = formValues.DetailGrid[index].id
      other_type = "edit"
      code = formValues.update_code
      meta.action = "edit"
    }
    if (sameAcount.length > 1) {
      dispatch(snackbarstate({ open: true, message: ErrorMessages["E00441"], snackbarType: "error" }))
    } else {
      if (!isblank(formValues.DetailGrid[index].accountSelectedValue)) {
        if (parseFloat(getNumberonly(formValues.DetailGrid[index].amount)) > 0) {
          var tdsDetail = formValues.DetailGrid[index].tdsDetail
          if (!isblank(tdsDetail) && tdsDetail.hasOwnProperty("DetailGrid") && !isblank(tdsDetail.DetailGrid) && tdsDetail.DetailGrid.length > 0) {
            const metadata = {}
            metadata.data = tdsDetail.DetailGrid
            metadata.quarterSelectedValue = tdsDetail.quarterSelectedValue
            metadata.formname = "Payment"
            metadata.setValue = setValue
            metadata.amount = formValues.DetailGrid[index].amount
            metadata.Grid = "DetailGrid"
            metadata.action = "edit"
            metadata.index = index
            dispatch(rightdrawerstate({ open: true, title: "Adjust TDS", data: metadata, position: "right", width: 1080 }))
          } else {
            dispatch(pendingTdsList({ type, other_type, meta, code, id }))
          }
        } else {
          dispatch(snackbarstate({ open: true, message: ErrorMessages["E00434"], snackbarType: "error" }))
        }
      }
    }
  }

  useEffect(() => {
    var dropdown_string = "bankaccount"
    if (dS.customerListAuto === 1) {
      dropdown_string = dropdown_string + ",allaccount"
    }
    dropdown_string = checkdropdowndependancy(dropdown_string)
    if (dropdown_string !== "") {
      const form_type = formType.type
      dispatch(
        fetch_form_dep({
          dropdown_string,
          form_type,
        })
      )
    } else {
      getInvoiceNumber()
    }

    return () => {
      dispatch(clearformdata())
    }
  }, [])

  useEffect(() => {
    if (prevDeploading && !deploading && !isblank(formValues) && isblank(formValues.billNo)) {
      getInvoiceNumber()
    }
  }, [deploading, prevDeploading])

  useEffect(() => {
    if (lastpagetype === "save_add_another" && lastpageaction === "start" && prevLastPageTime !== lastpagetime) {
      reset()
      getInvoiceNumber()
      saveandaddanother = false
      setLastTansDateV1({
        dateKey: `last_${formType.type}date`,
        format: "YMD",
        params: { field: "date", setValue },
      })

      setTimeout(function () {
        document.getElementById("bankSelectedValue").focus()
      }, 500)
    }

    if (lastpagetype === "getnewbillno" && lastpageaction === "fetch" && prevLastPageTime !== lastpagetime) {
      setValue("billNo", lastpagedata.saleInvoiceNo)
      setBillNoLoading(false)
    }

    if (lastpagetype === "shortCut" && lastpageaction === "press" && prevLastPageTime !== lastpagetime) {
      var shortCutKey = lastpagedata.key
      if (shortCutKey === "SAVE") {
        if (pageformaction === "edit") {
          handleUpdate()
        } else {
          handleSave()
        }
      } else if (shortCutKey === "PRINT") {
        if (pageformaction === "edit") {
          handleprint()
        } else {
          handleSave("print")
        }
      } else if (shortCutKey === "DELETE") {
        if (pageformaction === "edit") {
          if (parseInt(getMenuRightsByType({ parent: "Transaction", name: formType.title })[2], 10) === 1) {
            handledelete()
          }
        }
      }
    }
  }, [lastpagetype, lastpageaction, lastpagetime, lastpagedata])

  useEffect(() => {
    if (pageformaction === "edit") {
      if (formValues !== undefined && !isblank(pageformdata.data.form_data)) {
        if (pageformdata.data.form_data.DetailGrid.length === 0) {
          setValue("DetailGrid", (pageformdata.data.form_data.DetailGrid = [{}, {}]))
        }
      }
      if (formValues !== undefined) {
        setValue("narration", replaceBRtoNewLine(formValues.narration))

        if (dS.customerListAuto === 0) {
          if (formValues.hasOwnProperty("acccodeSelectedValue") && !isblank(formValues.acccodeSelectedValue) && formValues.acccodeSelectedValue.length > 0) {
            //&& formValues.acccodeSelectedValue !== formValues.acccodeSelectedValue )
            formValues.acccodeSelectedValue.map(function (find) {
              return dispatch({
                type: DROPDOWN_INSERTED,
                formtype: "allaccount",
                code: find.code,
                name: find.name,
                payload: find,
              })
            })
          } else {
            dispatch(snackbarstate({ open: true, message: ErrorMessages["E00390"], snackbarType: "error" }))
          }
        }
      }
    }

    if ((pageformaction === "edit" || pageformaction === "draft_edit") && !isblank(pageformdata.data.form_data)) {
      pageformdata.data.form_data = AddNumberFormat({ obj: pageformdata.data.form_data, fields: format_fields })
      pageformdata.data.form_data.DetailGrid = pageformdata.data.form_data.DetailGrid.map((item, index) => {
        const updatedItem = { ...item }
        if (typeof item.costcenter === "string" && item.costcenter !== "") {
          updatedItem.costcenter = JSON.parse(decodeURIComponent(item.costcenter))
        }
        if (type === "payment") {
          updatedItem.tdsDetail = JSONParseInEdit(updatedItem.tdsDetail)
        }
        return updatedItem
      })
      reset((formValues) => ({
        ...formValues,
        ...pageformdata.data.form_data,
      }))
    }
  }, [pageformaction, pageformdata])

  useEffect(() => {
    if (prevDropdownUpdated !== dropdownupdated) {
      if (dropdownupdated.formname === formType.formName) {
        var dditem = null

        if (dropdownupdated.fieldtype === "other_account") {
          dditem = ddallaccount.find((item) => parseInt(item.code) === parseInt(dropdownupdated.code))
        }
        if (isblank(dditem)) {
          setValue(dropdownupdated.fieldname, null)
        } else {
          setValue(dropdownupdated.fieldname, dditem.code)
        }
        if (dropdownupdated.fieldtype === "other_account") {
          onaccountchange(dropdownupdated.index, dditem)
        }
        dispatch(clear_dropDownUpdated())
      }
    }
  }, [dropdownupdated, prevDropdownUpdated])

  return (
    <div>
      <PageHeader
        drawerType={drawerType}
        {...drawerData}
        drawerIndex={drawerIndex}
        componentId={componentId}
        title={pageformaction === "edit" ? `Edit ${formType.title}` : `Add New ${formType.title}`}
        loading={loading}
        sidebarloading={sidebarloading}
        submitting={submitting}
        {...(dS.closing_balance === 1 && {
          balanceDetail: balanceDetail,
        })}
      />
      <div className={classes.sidebarContent} style={{ height: `calc(100vh - 96px)`, overflowY: "auto" }}>
        <div style={{ marginRight: 15, marginLeft: 15 }}>
          <Paper variant="outlined" square elevation={0} style={{ padding: 15, marginBottom: 15, marginTop: 15 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md lg>
                {(dS.new_doc_number === 1 || pageformaction === "edit") && (
                  <Field
                    name="billNo"
                    inputType="RenderTextField"
                    control={control}
                    isLoading={billNoLoading}
                    {...(transactionNoSeries && {
                      StartAdornment: transactionNoSeries,
                      AdornmentPosition: "start",
                    })}
                    label={`Doc No`}
                    trimSpace={true}
                    placeholder={`Enter docno`}
                    autoFocus={true}
                    isRequired={true}
                  />
                )}
                <Field
                  name="bankSelectedValue"
                  inputType="RFReactSelect"
                  control={control}
                  label="BANK/CASH"
                  placeholder="Select Account"
                  autoFocus={!(dS.new_doc_number === 1 || pageformaction === "edit")}
                  options={ddbankaccount.map(function (value) {
                    return { value: value.code, label: value.name, row: value }
                  })}
                  onvaluechange={onabankchange}
                  isRequired={true}
                />
                <Field name="narration" inputType="RenderTextField" control={control} label=" NARRATION " placeholder="" multiline minRows="2" fieldType="narration" />
              </Grid>
              <Grid item xs={12} md lg>
                <Field name="date" inputType="RenderTextField" control={control} label={type === "receipt" ? "RECEIPT DATE" : "PAYMENT DATE"} type="date" isRequired={true} />
              </Grid>
              <Grid item xs={12} md lg></Grid>
            </Grid>
          </Paper>
          <Paper square variant="outlined" elevation={0} style={{ marginBottom: 15 }}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell size="small" style={{ width: 50 }}>
                      #
                    </TableCell>
                    <TableCell style={{ minWidth: 200 }}>Account Name</TableCell>
                    <TableCell className={classes.w250}>Narration</TableCell>
                    <TableCell align="right" className={classes.amountCell}>
                      Amount({dS.currency})
                    </TableCell>
                    <TableCell align="right" className={classes.w150}>
                      Cheque No
                    </TableCell>
                    <TableCell align="right" className={classes.dateCell}>
                      Cheque Date
                    </TableCell>
                    {dS.cost_center === 1 && (
                      <TableCell className={classes.w100} style={{ textAlign: "center" }}>
                        Cost Center
                      </TableCell>
                    )}
                    {type === "payment" && dS.tds === 1 && (
                      <TableCell className={classes.w100} style={{ textAlign: "center" }}>
                        Adjust TDS
                      </TableCell>
                    )}
                    {adjustBill === 1 && (
                      <TableCell className={classes.w100} style={{ textAlign: "center" }}>
                        Adjust Bill
                      </TableCell>
                    )}
                    <TableCell className={classes.w25}> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {controlledFields.map((detailgrid, index) => {
                    const DetailGrid = `DetailGrid[${index}]`
                    var tdsDetail = detailgrid.tdsDetail
                    var tds_adjusted = !isblank(tdsDetail) && !isblank(tdsDetail.tds_adjusted) ? tdsDetail.tds_adjusted : 0
                    const tranType = formType.type === "receipt" ? "receivable_bills" : "receivable_onaccount"
                    const grid_adjusted = getAdjustedCountV2(detailgrid?.[formType?.field]?.[tranType] || [])
                    let isParty = getdropdownfieldvalue({ dropDownData: ddallaccount, field: "code", value: detailgrid.accountSelectedValue, displayvalue: "groupcode" })
                    isParty = checkCondition(isParty, ["L0021", "A0019"])

                    return (
                      <TableRow key={detailgrid.id}>
                        <TableCell size="small">
                          <Typography variant="subtitle1">{index + 1}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableNoPadding}>
                          <Grid item xs>
                            {dS.customerListAuto === 1 ? (
                              <Field
                                name={`${DetailGrid}.accountSelectedValue`}
                                inputType="RFCreatableSelect"
                                control={control}
                                disabled={pageformaction === "edit" && (tds_adjusted > 0 || (!isblank(detailgrid.invoice_adjusted) && parseInt(detailgrid.invoice_adjusted) > 0))}
                                mode="ALLACCOUNT"
                                addRecentAllAccount={addRecentAllAccount}
                                onAddNewClick={(value) => addNewAccount(index, value)}
                                options={ddallaccount.map(function (value) {
                                  return { value: value.code, label: value.name, row: value }
                                })}
                                onvaluechange={(value) => onaccountchange(index, value, controlledFields)}
                                dataindex={index}
                                setUnderLine={false}
                                margin="none"
                              />
                            ) : (
                              <Field
                                name={`${DetailGrid}.accountSelectedValue`}
                                inputType="RFAsyncSelect"
                                control={control}
                                mode="ALLACCOUNT"
                                disabled={pageformaction === "edit" && (tds_adjusted > 0 || (!isblank(detailgrid.invoice_adjusted) && parseInt(detailgrid.invoice_adjusted) > 0))}
                                formprops={props}
                                setUnderLine={false}
                                defaultOptions={ddallaccount.map(function (value) {
                                  return { value: value.code, label: value.name, row: value }
                                })}
                                options={ddallaccount.map(function (value) {
                                  return { value: value.code, label: value.name, row: value }
                                })}
                                modetype="allaccount"
                                dataindex={index}
                                onAddNewClick={(value) => addNewAccount(index, value)}
                                onvaluechange={(value) => onaccountchange(index, value, controlledFields)}
                              />
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell className={classes.tableNoPadding}>
                          <Field name={`${DetailGrid}.Narrationitem`} inputType="RenderTextField" control={control} margin="none" setUnderLine={false} fieldType="narration" />
                        </TableCell>
                        <TableCell align="right" className={classes.tableNoPadding}>
                          <Field name={`${DetailGrid}.amount`} inputType="RenderTextField" control={control} number={true} margin="none" setUnderLine={false} onBlurGetValue={onGridChange} />
                        </TableCell>
                        <TableCell align="right" className={classes.tableNoPadding}>
                          <Grid item xs>
                            <Field name={`${DetailGrid}.cheque`} inputType="RenderTextField" control={control} margin="none" setUnderLine={false} />
                          </Grid>
                        </TableCell>
                        <TableCell align="right" className={classes.tableNoPadding}>
                          <Field name={`${DetailGrid}.chequedate`} inputType="RenderTextField" control={control} type="date" setUnderLine={false} margin="none" />
                        </TableCell>
                        {dS.cost_center === 1 && (
                          <TableCell style={{ textAlign: "center" }} className={classes.tableNoPadding}>
                            <ActionButton
                              size="small"
                              color="primary"
                              label="Add"
                              count={detailgrid.hasOwnProperty("costcenter") && detailgrid.costcenter.length > 0 ? detailgrid.costcenter.length : ""}
                              disabled={check_item_costCenter(index)}
                              data-testid={type === "receipt" ? `add_cost_center_${index}` : "payment_multi_cost_center"}
                              onClick={() => opencostCenter(index)}
                            />
                          </TableCell>
                        )}

                        {type === "payment" && dS.tds === 1 && (
                          <TableCell style={{ textAlign: "center" }} className={classes.tableNoPadding}>
                            <ActionButton
                              size="small"
                              data-testid="payment_multi_tds"
                              color="primary"
                              label="Add"
                              count={tds_adjusted > 0 ? tdsDetail.tds_adjusted : ""}
                              disabled={isblank(detailgrid.isTdsAccount)}
                              onClick={() => adjustTDS(index)}
                            />
                          </TableCell>
                        )}

                        {adjustBill === 1 && (
                          <TableCell style={{ textAlign: "center" }} className={classes.tableNoPadding}>
                            {isParty && (
                              <ActionButton
                                size="small"
                                color="primary"
                                label="Add"
                                count={grid_adjusted > 0 ? grid_adjusted : ""}
                                disabled={isblank(detailgrid.accountSelectedValue)}
                                data-testid={type === "receipt" ? `add_adjust_bill_${index}` : "payment_multi_adjust_bill"}
                                onClick={() => adjust_bill(index)}
                              />
                            )}
                          </TableCell>
                        )}

                        <TableCell style={{ textAlign: "center" }} className={classes.tableNoPadding}>
                          <IconButton
                            size="small"
                            disabled={pageformaction === "edit" && !isblank(detailgrid.invoice_adjusted) && parseInt(detailgrid.invoice_adjusted) > 0}
                            tabIndex="-1"
                            aria-label="trash"
                            onClick={() => handleRemove(index)}
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell style={{ border: 0 }} colSpan={2}>
                      <Button size="small" variant="contained" color="primary" onClick={handleAddRow}>
                        <Add style={{ fontSize: "1rem" }} /> Add Row
                      </Button>
                    </TableCell>
                    <TableCell style={{ border: 0 }}></TableCell>
                    <TableCell align="right" style={{ border: 0 }}>
                      <Typography variant="subtitle1">{formValues.hasOwnProperty("tot_net_amount") ? amount_format(toFixedFn(parseFloat(formValues.tot_net_amount))) : "0.00"}</Typography>
                    </TableCell>
                    <TableCell style={{ border: 0 }}></TableCell>
                    <TableCell style={{ border: 0 }}></TableCell>
                    {dS.cost_center === 1 && <TableCell style={{ border: 0 }}></TableCell>}
                    {type === "payment" && dS.tds === 1 && <TableCell style={{ border: 0 }}></TableCell>}
                    {dS.adjust_bill === 1 && <TableCell style={{ border: 0 }}></TableCell>}
                    <TableCell style={{ border: 0 }}></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>

      {pageformaction === "edit" ? (
        <Footer
          drawerType={drawerType}
          {...drawerData}
          drawerIndex={drawerIndex}
          componentId={componentId}
          classes={classes}
          {...(parseInt(getMenuRightsByType({ parent: "Transaction", name: formType.title })[2], 10) === 1 && {
            onDeleteClick: handledelete,
          })}
          onDismissClick={() => toggleFormDrawer(false, formType.type)}
          // onPrintClick={handleprint}
          onUpdateAndPrintClick={() => handleUpdate("print")}
          onUpdateClick={handleUpdate}
          onUpdateAndShareClick={() => handleUpdate("share")}
          loading={loading}
          sidebarloading={sidebarloading}
          submitting={submitting}
        />
      ) : (
        <Footer
          drawerType={drawerType}
          {...drawerData}
          drawerIndex={drawerIndex}
          componentId={componentId}
          classes={classes}
          onSaveClick={handleSave}
          saveDraft={saveDraft}
          onPrintSaveClick={() => handleSave("print")}
          onSaveAndShareClick={() => handleSave("share")}
          {...(pageformaction !== "clone" &&
            pageformaction !== "draft_edit" && {
              onSaveandAnotherClick: handlesaveandanother,
            })}
          onDismissClick={() => toggleFormDrawer(false, formType.type)}
          loading={loading}
          sidebarloading={sidebarloading}
          submitting={submitting}
        />
      )}
    </div>
  )
}

export default ModalTransactionMultiple
