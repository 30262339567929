export default {
  alignCenter: {
    textAlign: "center",
  },

  sidebarHeader: {
    display: "flex",
    zIndex: 101,
    padding: 0,
    alignItems: "center",
    height: 48,
    backgroundColor: "#dcdcdc",
    position: "fixed",
    top: 0,
    width: "100%",
  },

  sidebarFooter: {
    height: 48,
    padding: "0 15px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#dcdcdc",
    top: "auto",
    bottom: 0,
    position: "fixed",
  },

  sidebarContent: {
    marginTop: 48,
    marginBottom: 48,
    flexGrow: 1,
    overflowY: "auto",
    position: "relative",
  },

  sidebarContentPad: {
    padding: 16,
  },

  root: {
    flexGrow: 1,
  },

  uploadbtnwrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    marginRight: 15,
  },

  uploadBox: {
    width: 150,
    border: "2px dashed #d9d9d9",
    height: 150,
    position: "relative",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    fontWeight: "bold",
    justifyContent: "center",
    alignItems: "center",
  },

  uploadInput: {
    position: "absolute",
    left: 0,
    top: 0,
    height: 150,
    cursor: "pointer",
    opacity: 0,
  },

  actionContainer: {},

  flex: {
    flex: 1,
  },
  toolbar: {},
  content: {},
  appBar: {},
  drawer: {},
  drawerPaper: {},
  extraMargin: {},
  grow: {
    flexGrow: 1,
  },

  mfix: {
    marginBottom: -7,
  },

  mr_5: {
    marginRight: 5,
  },

  reportInputContainer: {
    marginRight: 5,
  },

  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },

  buttonMargin: {
    marginRight: 20,
  },

  mainContainer: {
    /*marginBottom: 100*/
  },

  wrapperContainer: {
    paddingRight: 16,
    paddingLeft: 16,
    paddingBottom: 16,
    height: "100%",
  },

  drawerContainer: {
    display: "flex",
    flexDirection: "column",
  },

  drawerContent: {
    padding: 16,
  },

  RequiredLabelColor: {
    color: "#b94a48",
  },

  WhiteLabelColor: {
    color: "white",
  },

  loginBox: {
    width: 1200,
    marginTop: 100,
    padding: 24,
  },

  appbarBottom: {
    top: "auto",
    bottom: 0,
  },

  gridCellBorder: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },

  bottomToolbar: {
    alignItems: "center",
    justifyContent: "flex-end",
  },

  table: {
    overflow: "auto",
  },

  tableNoPadding: {
    padding: 0,
  },

  dateCell: {
    minWidth: 125,
    maxWidth: 125,
    width: 125,
  },

  amountCell: {
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },

  w150: {
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },

  w250: {
    minWidth: 250,
    maxWidth: 250,
    width: 250,
  },

  w175: {
    minWidth: 175,
    maxWidth: 175,
    width: 175,
  },

  w85: {
    minWidth: 85,
    maxWidth: 85,
    width: 85,
  },

  w100: {
    minWidth: 100,
    maxWidth: 100,
    width: 100,
  },

  w125: {
    minWidth: 125,
    maxWidth: 125,
    width: 125,
  },

  w200: {
    minWidth: 200,
    maxWidth: 200,
    width: 200,
  },

  w50: {
    minWidth: 50,
    maxWidth: 50,
    width: 50,
  },

  w25: {
    minWidth: 25,
    maxWidth: 25,
    width: 25,
  },
}
