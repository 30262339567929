import { TALLY_DATA_SERVICE_SUCCESS, TALLY_DATA_SERVICE_FAIL, TALLY_DATA_SERVICE_START, TALLY_DATA_SERVICE_CLEAR } from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  totalcount: 0,
  norecord: false,
  columnData: [
    { id: "BillNo", numeric: false, visible: true, label: "Doc No.", width: 125, showlink: true, removelinkonly: { valueblank: ["BillNo"] } },
    { id: "BillDate", numeric: false, visible: true, label: "Date", width: 105, date: true, format: "dmy" },
    { id: "PartyName", numeric: false, visible: true, label: "Name", showlink: true, removelinkonly: { valueblank: ["BillNo"] } },
    { id: "State", numeric: false, visible: true, label: "State", width: 150, bold: { valueblank: ["BillNo"] } },
    { id: "PlaceOfSupply", numeric: false, visible: true, label: "Place Of Supply", width: 150, bold: { valueblank: ["BillNo"] } },
    { id: "Amt", numeric: true, visible: true, label: `Tax. Amount`, format: "indian_rupee", currencySign: true, width: 150, bold: { valueblank: ["BillNo"] }, display: "tax" },
    { id: "IGST", numeric: true, visible: true, label: `IGST`, format: "indian_rupee", currencySign: true, width: 125, bold: { valueblank: ["BillNo"] }, display: "tax" },
    { id: "CGST", numeric: true, visible: true, label: `CGST`, format: "indian_rupee", currencySign: true, width: 125, bold: { valueblank: ["BillNo"] }, display: "tax" },
    { id: "SGST", numeric: true, visible: true, label: `SGST`, format: "indian_rupee", currencySign: true, width: 125, bold: { valueblank: ["BillNo"] }, display: "tax" },
    { id: "Amount", numeric: true, visible: true, label: `Amount`, format: "indian_rupee", currencySign: true, width: 150, bold: { valueblank: ["BillNo"] } },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TALLY_DATA_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case TALLY_DATA_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case TALLY_DATA_SERVICE_SUCCESS:
      var newData = action.payload.data.result.map((item) => {
        const updatedItem = { ...item }
        if (item.DetailGrid) {
          updatedItem.code = item.update_code
          updatedItem.BillNo = item.billNo
          updatedItem.BillDate = item.date

          item.DetailGrid.forEach((itemGrid) => {
            updatedItem.PartyName = itemGrid.accountName

            //updatedItem.PlaceOfSupply = item.placeof_statedata[0].state;
            //updatedItem.State = item.user_details[0].statename;
            updatedItem.Amt = itemGrid.amount
            //updatedItem.IGST = item.master_table_data[0].igst_total;
            //updatedItem.CGST = item.master_table_data[0].cgst_total;
            //updatedItem.SGST = item.master_table_data[0].sgst_total;
            updatedItem.Amount = itemGrid.amount

            //updatedItem.invoice_type = item.master_table_data[0].invoice_type;
          })

          return updatedItem
        } else {
          updatedItem.code = item.master_table_data[0].code
          updatedItem.BillNo = item.master_table_data[0].billno
          updatedItem.BillDate = item.master_table_data[0].b_date
          updatedItem.PartyName = item.user_details[0].name
          updatedItem.invoice_type = item.master_table_data[0].invoice_type
          updatedItem.PartyCode = item.user_details[0].code
          updatedItem.PlaceOfSupply = item.placeof_statedata[0].state
          updatedItem.State = item.user_details[0].statename
          updatedItem.Amt = item.master_table_data[0].totamt
          updatedItem.IGST = item.master_table_data[0].igst_total
          updatedItem.CGST = item.master_table_data[0].cgst_total
          updatedItem.SGST = item.master_table_data[0].sgst_total
          updatedItem.Amount = item.master_table_data[0].netamt
          return updatedItem
        }
      })
      return { ...state, data: newData, totalcount: action.payload.data.total_count, norecord: action.payload.data.result.length === 0 }
    case TALLY_DATA_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false, totalcount: 0 }
    default:
      return state
  }
}
