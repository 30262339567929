export default [
  {
    id: "AED",
    name: "United Arab Emirates dirham",
  },
  {
    id: "AFN",
    name: "Afghan afghani",
  },
  {
    id: "ALL",
    name: "Albanian lek",
  },
  {
    id: "AMD",
    name: "Armenian dram",
  },
  {
    id: "ANG",
    name: "Netherlands Antillean guilder",
  },
  {
    id: "AOA",
    name: "Angolan kwanza",
  },
  {
    id: "ARS",
    name: "Argentine peso",
  },
  {
    id: "AUD",
    name: "Australian dollar",
  },
  {
    id: "AWG",
    name: "Aruban florin",
  },
  {
    id: "AZN",
    name: "Azerbaijani manat",
  },
  {
    id: "BAM",
    name: "Bosnia and Herzegovina convertible mark",
  },
  {
    id: "BBD",
    name: "Barbados dollar",
  },
  {
    id: "BDT",
    name: "Bangladeshi taka",
  },
  {
    id: "BGN",
    name: "Bulgarian lev",
  },
  {
    id: "BHD",
    name: "Bahraini dinar",
  },
  {
    id: "BIF",
    name: "Burundian franc",
  },
  {
    id: "BMD",
    name: "Bermudian dollar",
  },
  {
    id: "BND",
    name: "Brunei dollar",
  },
  {
    id: "BOB",
    name: "Boliviano",
  },
  {
    id: "BRL",
    name: "Brazilian real",
  },
  {
    id: "BSD",
    name: "Bahamian dollar",
  },
  {
    id: "BTN",
    name: "Bhutanese ngultrum",
  },
  {
    id: "BWP",
    name: "Botswana pula",
  },
  {
    id: "BYN",
    name: "New Belarusian ruble",
  },
  {
    id: "BYR",
    name: "Belarusian ruble",
  },
  {
    id: "BZD",
    name: "Belize dollar",
  },
  {
    id: "CAD",
    name: "Canadian dollar",
  },
  {
    id: "CDF",
    name: "Congolese franc",
  },
  {
    id: "CHF",
    name: "Swiss franc",
  },
  {
    id: "CLF",
    name: "Unidad de Fomento",
  },
  {
    id: "CLP",
    name: "Chilean peso",
  },
  {
    id: "CNY",
    name: "Renminbi|Chinese yuan",
  },
  {
    id: "COP",
    name: "Colombian peso",
  },
  {
    id: "CRC",
    name: "Costa Rican colon",
  },
  {
    id: "CUC",
    name: "Cuban convertible peso",
  },
  {
    id: "CUP",
    name: "Cuban peso",
  },
  {
    id: "CVE",
    name: "Cape Verde escudo",
  },
  {
    id: "CZK",
    name: "Czech koruna",
  },
  {
    id: "DJF",
    name: "Djiboutian franc",
  },
  {
    id: "DKK",
    name: "Danish krone",
  },
  {
    id: "DOP",
    name: "Dominican peso",
  },
  {
    id: "DZD",
    name: "Algerian dinar",
  },
  {
    id: "EGP",
    name: "Egyptian pound",
  },
  {
    id: "ERN",
    name: "Eritrean nakfa",
  },
  {
    id: "ETB",
    name: "Ethiopian birr",
  },
  {
    id: "EUR",
    name: "Euro",
  },
  {
    id: "FJD",
    name: "Fiji dollar",
  },
  {
    id: "FKP",
    name: "Falkland Islands pound",
  },
  {
    id: "GBP",
    name: "Pound sterling",
  },
  {
    id: "GEL",
    name: "Georgian lari",
  },
  {
    id: "GHS",
    name: "Ghanaian cedi",
  },
  {
    id: "GIP",
    name: "Gibraltar pound",
  },
  {
    id: "GMD",
    name: "Gambian dalasi",
  },
  {
    id: "GNF",
    name: "Guinean franc",
  },
  {
    id: "GTQ",
    name: "Guatemalan quetzal",
  },
  {
    id: "GYD",
    name: "Guyanese dollar",
  },
  {
    id: "HKD",
    name: "Hong Kong dollar",
  },
  {
    id: "HNL",
    name: "Honduran lempira",
  },
  {
    id: "HRK",
    name: "Croatian kuna",
  },
  {
    id: "HTG",
    name: "Haitian gourde",
  },
  {
    id: "HUF",
    name: "Hungarian forint",
  },
  {
    id: "IDR",
    name: "Indonesian rupiah",
  },
  {
    id: "ILS",
    name: "Israeli new shekel",
  },
  {
    id: "INR",
    name: "Indian rupee",
  },
  {
    id: "IQD",
    name: "Iraqi dinar",
  },
  {
    id: "IRR",
    name: "Iranian rial",
  },
  {
    id: "ISK",
    name: "Icelandic króna",
  },
  {
    id: "JMD",
    name: "Jamaican dollar",
  },
  {
    id: "JOD",
    name: "Jordanian dinar",
  },
  {
    id: "JPY",
    name: "Japanese yen",
  },
  {
    id: "KES",
    name: "Kenyan shilling",
  },
  {
    id: "KGS",
    name: "Kyrgyzstani som",
  },
  {
    id: "KHR",
    name: "Cambodian riel",
  },
  {
    id: "KMF",
    name: "Comoro franc",
  },
  {
    id: "KPW",
    name: "North Korean won",
  },
  {
    id: "KRW",
    name: "South Korean won",
  },
  {
    id: "KWD",
    name: "Kuwaiti dinar",
  },
  {
    id: "KYD",
    name: "Cayman Islands dollar",
  },
  {
    id: "KZT",
    name: "Kazakhstani tenge",
  },
  {
    id: "LAK",
    name: "Lao kip",
  },
  {
    id: "LBP",
    name: "Lebanese pound",
  },
  {
    id: "LKR",
    name: "Sri Lankan rupee",
  },
  {
    id: "LRD",
    name: "Liberian dollar",
  },
  {
    id: "LSL",
    name: "Lesotho loti",
  },
  {
    id: "LYD",
    name: "Libyan dinar",
  },
  {
    id: "MAD",
    name: "Moroccan dirham",
  },
  {
    id: "MDL",
    name: "Moldovan leu",
  },
  {
    id: "MGA",
    name: "Malagasy ariary",
  },
  {
    id: "MKD",
    name: "Macedonian denar",
  },
  {
    id: "MMK",
    name: "Myanmar kyat",
  },
  {
    id: "MNT",
    name: "Mongolian tögrög",
  },
  {
    id: "MOP",
    name: "Macanese pataca",
  },
  {
    id: "MRO",
    name: "Mauritanian ouguiya",
  },
  {
    id: "MUR",
    name: "Mauritian rupee",
  },
  {
    id: "MVR",
    name: "Maldivian rufiyaa",
  },
  {
    id: "MWK",
    name: "Malawian kwacha",
  },
  {
    id: "MXN",
    name: "Mexican peso",
  },
  {
    id: "MXV",
    name: "Mexican Unidad de Inversion",
  },
  {
    id: "MYR",
    name: "Malaysian ringgit",
  },
  {
    id: "MZN",
    name: "Mozambican metical",
  },
  {
    id: "NAD",
    name: "Namibian dollar",
  },
  {
    id: "NGN",
    name: "Nigerian naira",
  },
  {
    id: "NIO",
    name: "Nicaraguan córdoba",
  },
  {
    id: "NOK",
    name: "Norwegian krone",
  },
  {
    id: "NPR",
    name: "Nepalese rupee",
  },
  {
    id: "NZD",
    name: "New Zealand dollar",
  },
  {
    id: "OMR",
    name: "Omani rial",
  },
  {
    id: "PAB",
    name: "Panamanian balboa",
  },
  {
    id: "PEN",
    name: "Peruvian Sol",
  },
  {
    id: "PGK",
    name: "Papua New Guinean kina",
  },
  {
    id: "PHP",
    name: "Philippine peso",
  },
  {
    id: "PKR",
    name: "Pakistani rupee",
  },
  {
    id: "PLN",
    name: "Polish złoty",
  },
  {
    id: "PYG",
    name: "Paraguayan guaraní",
  },
  {
    id: "QAR",
    name: "Qatari riyal",
  },
  {
    id: "RON",
    name: "Romanian leu",
  },
  {
    id: "RSD",
    name: "Serbian dinar",
  },
  {
    id: "RUB",
    name: "Russian ruble",
  },
  {
    id: "RWF",
    name: "Rwandan franc",
  },
  {
    id: "SAR",
    name: "Saudi riyal",
  },
  {
    id: "SBD",
    name: "Solomon Islands dollar",
  },
  {
    id: "SCR",
    name: "Seychelles rupee",
  },
  {
    id: "SDG",
    name: "Sudanese pound",
  },
  {
    id: "SEK",
    name: "Swedish krona",
  },
  {
    id: "SGD",
    name: "Singapore dollar",
  },
  {
    id: "SHP",
    name: "Saint Helena pound",
  },
  {
    id: "SLL",
    name: "Sierra Leonean leone",
  },
  {
    id: "SOS",
    name: "Somali shilling",
  },
  {
    id: "SRD",
    name: "Surinamese dollar",
  },
  {
    id: "SSP",
    name: "South Sudanese pound",
  },
  {
    id: "STD",
    name: "São Tomé and Príncipe dobra",
  },
  {
    id: "SVC",
    name: "Salvadoran colón",
  },
  {
    id: "SYP",
    name: "Syrian pound",
  },
  {
    id: "SZL",
    name: "Swazi lilangeni",
  },
  {
    id: "THB",
    name: "Thai baht",
  },
  {
    id: "TJS",
    name: "Tajikistani somoni",
  },
  {
    id: "TMT",
    name: "Turkmenistani manat",
  },
  {
    id: "TND",
    name: "Tunisian dinar",
  },
  {
    id: "TOP",
    name: "Tongan paʻanga",
  },
  {
    id: "TRY",
    name: "Turkish lira",
  },
  {
    id: "TTD",
    name: "Trinidad and Tobago dollar",
  },
  {
    id: "TWD",
    name: "New Taiwan dollar",
  },
  {
    id: "TZS",
    name: "Tanzanian shilling",
  },
  {
    id: "UAH",
    name: "Ukrainian hryvnia",
  },
  {
    id: "UGX",
    name: "Ugandan shilling",
  },
  {
    id: "USD",
    name: "United States dollar",
  },
  {
    id: "UYI",
    name: "Uruguay Peso en Unidades Indexadas",
  },
  {
    id: "UYU",
    name: "Uruguayan peso",
  },
  {
    id: "UZS",
    name: "Uzbekistan som",
  },
  {
    id: "VEF",
    name: "Venezuelan bolívar",
  },
  {
    id: "VND",
    name: "Vietnamese đồng",
  },
  {
    id: "VUV",
    name: "Vanuatu vatu",
  },
  {
    id: "WST",
    name: "Samoan tala",
  },
  {
    id: "XAF",
    name: "Central African CFA franc",
  },
  {
    id: "XCD",
    name: "East Caribbean dollar",
  },
  {
    id: "XOF",
    name: "West African CFA franc",
  },
  {
    id: "XPF",
    name: "CFP franc",
  },
  {
    id: "XXX",
    name: "No currency",
  },
  {
    id: "YER",
    name: "Yemeni rial",
  },
  {
    id: "ZAR",
    name: "South African rand",
  },
  {
    id: "ZMW",
    name: "Zambian kwacha",
  },
  {
    id: "ZWL",
    name: "Zimbabwean dollar",
  },
]
