import { deleteInReducer } from "@project/components/utilityFunctions"
import {
  IMPORT_LABEL_SETTING_SERVICE_SUCCESS,
  LABEL_SETTING_SEARCH_TEXT_UPDATE,
  LABEL_SETTING_SERVICE_CLEAR,
  LABEL_SETTING_SERVICE_DELETED,
  LABEL_SETTING_SERVICE_FAIL,
  LABEL_SETTING_SERVICE_INSERTED,
  LABEL_SETTING_SERVICE_START,
  LABEL_SETTING_SERVICE_SUCCESS,
  LABEL_SETTING_SERVICE_UPDATED,
} from "../../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  totalcount: 0,
  norecord: false,
  rowsPerPage: 0,
  page: 0,
  order: "asc",
  orderBy: "name",
  search_text: "",
  deleted: false,
  columnData: [
    { id: "name", numeric: false, visible: true, label: "Name" },
    { id: "papersize", numeric: false, visible: true, label: "Paper Size", width: 150 },
    { id: "metric", numeric: false, visible: true, label: "Metric", width: 150 },
  ],
  metadata: "",
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LABEL_SETTING_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case LABEL_SETTING_SERVICE_SUCCESS:
      return {
        ...state,
        data: action.payload.data.result,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        islogin: true,
        norecord: action.payload.data.result.length === 0,
      }
    case LABEL_SETTING_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false, totalcount: 0, search_text: action.search_text }
    case LABEL_SETTING_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case LABEL_SETTING_SEARCH_TEXT_UPDATE:
      return { ...state, search_text: action.search_text, norecord: false }
    case LABEL_SETTING_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_code
      inserteditem.name = action.payload.name
      inserteditem.papersize = action.payload.pageSizeSelectedValue
      inserteditem.metric = action.payload.metricSelectedValue
      return { ...state, data: [inserteditem, ...state.data] }

    case LABEL_SETTING_SERVICE_DELETED:
      return deleteInReducer({ action, key: "data", state })
    case LABEL_SETTING_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (item.code !== action.payload.update_code) {
          return item
        }
        const updateditem = { ...item }
        updateditem.name = action.payload.name
        updateditem.papersize = action.payload.pageSizeSelectedValue
        updateditem.metric = action.payload.metricSelectedValue
        return updateditem
      })
      return { ...state, data: updateddata }

    case IMPORT_LABEL_SETTING_SERVICE_SUCCESS:
      var newarray = []
      action.payload.DetailGrid.forEach((detailgrid, index) => {
        const inserteditem = {}
        inserteditem.code = action.insert_code.find((e) => e.code === detailgrid.code).insert_code.toString()
        inserteditem.name = detailgrid.name
        inserteditem.papersize = detailgrid.papersize
        inserteditem.metric = detailgrid.metric
        newarray.push(inserteditem)
      })
      return { ...state, data: [...newarray, ...state.data] }
    default:
      return state
  }
}
