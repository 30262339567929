import { pushDrawer } from "./CommonActionsV2"
import { list_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import { LOADING_STATE, SNACKBAR_STATE } from "./types"

export const adjustBillList = ({ party_code, type, other_type, meta, code, id, transactionType, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("party_code", party_code)
    data.append("type", type)
    data.append("code", code)
    data.append("id", id)
    data.append("transactionType", transactionType)
    data.append("other_type", other_type)

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "adjust_bill" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({ type: LOADING_STATE, state: false })
          const metadata = {}
          metadata.data = response.data.result
          metadata.formname = meta.formname
          metadata.index = meta.index
          metadata.Grid = meta.Grid
          metadata.amount = meta.amount
          metadata.Gridfieldname = meta.Gridfieldname
          metadata.action = meta.action
          metadata.field = meta.field
          metadata.setValue = meta.setValue
          pushDrawer({ title: "Adjust Bill By Bill", data: metadata, position: "right", width: 1024, dispatch })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const pendingTdsList = ({ type, other_type, meta, code, id, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("type", type)
    data.append("offset", 0)
    data.append("limit", "All")
    data.append("sort_order", "desc")
    data.append("sort_by", "code")
    data.append("search_text", "")

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "pending_tds_list" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({ type: LOADING_STATE, state: false })
          const metadata = {}
          metadata.data = response.data.result
          metadata.formname = meta.formname
          metadata.setValue = meta.setValue
          metadata.index = meta.index
          metadata.Grid = meta.Grid
          metadata.amount = meta.amount
          metadata.Gridfieldname = meta.Gridfieldname
          metadata.action = meta.action
          pushDrawer({ title: "Adjust TDS", data: metadata, position: "right", width: 1080, dispatch })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}
