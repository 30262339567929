(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("exceljs"), require("xlsx-js-style"));
	else if(typeof define === 'function' && define.amd)
		define(["exceljs", "xlsx-js-style"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("exceljs"), require("xlsx-js-style")) : factory(root["ExcelJS"], root["XLSX"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__80330__, __WEBPACK_EXTERNAL_MODULE__10754__) => {
return 