import { GLOBAL_SEARCH_TEXT_UPDATE, GLOBAL_SERVICE_CLEAR, GLOBAL_SERVICE_FAIL, GLOBAL_SERVICE_START, GLOBAL_SERVICE_SUCCESS } from "../Actions/types"

const INITIAL_STATE = {
  current_tab_index: 0,
  error: "",
  loading: false,

  resultdata: [],
  resulttotalcount: 0,
  rowsPerPage: 0,
  page: 0,
  order: "asc",
  orderBy: "name",
  search_text: "",
  search_field: "",
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GLOBAL_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case GLOBAL_SERVICE_SUCCESS:
      return { ...state, resultdata: action.payload.data.result, resulttotalcount: action.payload.data.total_count, islogin: true }
    case GLOBAL_SERVICE_FAIL:
      return { ...state, error: action.payload, resultdata: [], loading: false, resulttotalcount: 0, search_text: action.search_text, search_field: action.search_field }
    case GLOBAL_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case GLOBAL_SEARCH_TEXT_UPDATE:
      return { ...state, search_text: action.search_text, search_field: action.search_field }

    default:
      return { ...state }
  }
}
