import { Box, Button, Container, Paper, Snackbar, Typography } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { VpnKey } from "@material-ui/icons"
import fblogo from "@project/components/Images/FinalBooks_Logo.png"
import { RenderTextField } from "@project/components/reduxFormComponents"
import { showError } from "@project/components/showErrorFunctions"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import { isblank, sleep } from "@project/sharedcomponents/utilityFunctions"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { Field, getFormValues, reduxForm, reset } from "redux-form"
import { authenticateUser, authsnackbarstate, user_logout } from "../../Actions"
import styles from "../../Css/style.css"

const validate = (values, props) => {
  const errors = {}
  if (!values["otp"]) {
    errors["otp"] = ErrorMessages["E00245"]
  }
  return errors
}

const afterSubmit = (result, dispatch) => {}

const postform = (values, dispatch, props) => {
  return sleep(1)
    .then(() => {
      return validate(values, props)
    })
    .then((errors) => {
      if (Object.keys(errors).length !== 0) {
        //throw new SubmissionError(errors)
      } else {
        const form_data = {}
        form_data.auth_key = props.auth_data.user.data.company_detail.auth_key
        form_data.otp = values.otp
        const type = "otp"
        const action = "update"
        const other_type = "verify_otp"
        props.authenticateUser({
          form_data,
          type,
          action,
          other_type,
          year: props.auth_data.year,
          auth_token: props.auth_data.user.data.auth_token,
          user: props.auth_data.user,
        })
      }
    })
}
const showerrors = (errors, dispatch, submitError, props) => {
  if (errors) {
    showError({ errors, dispatch, submitError, props })
  }
}

class TwoFactorAuthentication extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    this.onButtonPress = this.onButtonPress.bind(this)
  }

  componentDidMount() {
    if (isblank(this.props.auth_data)) {
      this.props.user_logout(this.props.navigate)
    }
  }

  onButtonPress(e) {
    e.preventDefault()
    document.getElementById("submitButton").focus()

    const submitter = this.props.handleSubmit(this.props.onSubmit.bind(this))

    setTimeout(() => submitter(), 100) // submits
    //const submitter = this.props.handleSubmit(this.props.onSubmit.bind(this));
    //submitter(); // submits
  }

  renderButton(classes) {
    return (
      <div>
        <Button id="submitButton" type="submit" fullWidth variant="contained" color="primary">
          Verify
        </Button>
      </div>
    )
  }

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget })
  }
  handleErrorClose = () => {
    this.props.authsnackbarstate(false, "")
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch(reset("AuthenticationForm"))
  }
  render() {
    const { classes, islogin } = this.props
    if (!islogin) {
      return (
        <div>
          <Box mt={8}>
            <Container maxWidth="xs">
              <Paper>
                <Box p={2}>
                  <div style={{ textAlign: "center" }}>
                    <img src={fblogo} alt="FinalBooks" height="64" />
                    <Typography gutterBottom variant="h6">
                      Enter security code
                    </Typography>
                    <div style={{ marginTop: 15, marginBottom: 15 }}>
                      <VpnKey style={{ fontSize: "4rem", color: "black" }} />
                    </div>
                    <Typography gutterBottom variant="subtitle2">
                      Open your authentication app and enter the security code it provides.
                    </Typography>
                  </div>
                  <form onSubmit={this.onButtonPress}>
                    <Field name="otp" component={RenderTextField} autoFocus={true} placeholder="Enter security code" />
                    <div style={{ marginTop: 15 }}>{this.renderButton(classes)}</div>
                  </form>
                </Box>
              </Paper>
            </Container>
          </Box>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={this.props.Snackbarerror ? true : false}
            onClose={this.handleErrorClose}
            autoHideDuration={3000}
            snackbarcontentprops={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{this.props.Snackbarerrormessage}</span>}
          />
        </div>
      )
    } else {
      return <span></span>
    }
  }
}

const mapStateToProps = (state) => {
  const { error, Snackbarerror, Snackbarerrormessage, auth_data } = state.AuthReducer
  return {
    error,
    Snackbarerrormessage,
    Snackbarerror,
    auth_data,
    formValues: getFormValues("AuthenticationForm")(state),
    initialValues: {},
  }
}

TwoFactorAuthentication.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles, {
    withTheme: true,
    name: "TwoFactorAuthentication",
  }),
  connect(mapStateToProps, {
    authenticateUser,
    authsnackbarstate,
    user_logout,
  }),
  reduxForm({
    form: "AuthenticationForm", // a unique identifier for this form
    validate,
    onSubmit: postform,
    touchOnBlur: false,
    onSubmitFail: showerrors,
    onSubmitSuccess: afterSubmit,
  })
)(TwoFactorAuthentication)
