import { list_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, isblank, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getAccountSitesetting, getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import {
  LAST_PAGE_UPDATE,
  LOADING_STATE,
  PRODUCTSEARCH_SEARCH_TEXT_UPDATE,
  PRODUCTSEARCH_SERVICE_CLEAR,
  PRODUCTSEARCH_SERVICE_FAIL,
  PRODUCTSEARCH_SERVICE_START,
  PRODUCTSEARCH_SERVICE_SUCCESS,
  SNACKBAR_STATE,
} from "./types"

export const productsearch_data_clear = () => {
  return (dispatch) => {
    dispatch({
      type: PRODUCTSEARCH_SERVICE_CLEAR,
    })
  }
}

export const fetch_productsearch_data = ({ rowsPerPage, page, order, orderBy, search_text, formValues, functions }) => {
  if (isblank(rowsPerPage)) {
    rowsPerPage = getAccountSitesetting("pagination_size")
  }
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: PRODUCTSEARCH_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("offset", page * rowsPerPage)
    data.append("type", "item")
    data.append("limit", rowsPerPage)
    data.append("sort_order", order)
    data.append("sort_by", orderBy)
    data.append("search_text", search_text)
    data.append("formValues", formValues)

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "productsearch" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          productsearchServiceFail({ dispatch, response: response.data.meta.message, rowsPerPage, page, order, orderBy, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          productsearchServiceSuccess({ dispatch, user: response, rowsPerPage, page, order, orderBy, search_text })
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "productsearch",
            lastaction: "list",
            lasttime: new Date().getTime(),
            lastdata: "",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          productsearchServiceFail({ dispatch, response: getCatchErrorMessage(error), rowsPerPage, page, order, orderBy, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const multi_insert_page_data = ({ formValues, action, onComplete = false, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("type", "multi_entry_page")
    data.append("form_data", formValues)
    if (!isblank(action)) {
      data.append("action", action)
    } else {
      data.append("action", "insert")
    }

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "multi_entry_page" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({ type: LOADING_STATE, state: false })
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "productsearch",
            lastaction: "multi_insert_fail",
            lasttime: new Date().getTime(),
            lastdata: response.data.meta.message,
          })
        } else {
          if (!isblank(onComplete)) {
            onComplete()
          }
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "productsearch",
            lastaction: "multi_insert_success",
            lasttime: new Date().getTime(),
            lastdata: "",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({ type: LOADING_STATE, state: false })
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "productsearch",
            lastaction: "multi_insert_fail",
            lasttime: new Date().getTime(),
            lastdata: error,
          })
        }
      })
  }
}

const productsearchServiceSuccess = ({ dispatch, user, rowsPerPage, page, order, orderBy, search_text }) => {
  dispatch({
    type: PRODUCTSEARCH_SERVICE_SUCCESS,
    payload: user,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
  })
}

const productsearchServiceFail = ({ dispatch, response, rowsPerPage, page, order, orderBy, search_text }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })
  dispatch({
    type: PRODUCTSEARCH_SERVICE_FAIL,
    payload: response,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
  })
}

export const productsearchsearch_change = ({ search_text }) => {
  return (dispatch) => {
    dispatch({
      type: PRODUCTSEARCH_SEARCH_TEXT_UPDATE,
      search_text: search_text,
    })
  }
}
