import MomentUtils from "@date-io/moment"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import moment from "moment"
import React from "react"

const StaticDatePicker = ({ value, onchange }) => {
  //const [date, changeDate,open] = useState(new Date());

  // prettier-ignore
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <DatePicker
        autoOk
        disableToolbar
        variant="static"
        value={value}
        onChange={(event) => onchange(event._d)}
      />
    </MuiPickersUtilsProvider>
  );
}

export default StaticDatePicker
