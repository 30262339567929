import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import ErrorBoundary from "@project/components/common/ErrorBoundary"
import store from "./store"
import App from "./Components/app"
import * as serviceWorker from "./serviceWorkerRegistration"
import "./index.css"
import "./i18n"
import { updateServiceworker } from "./Actions"
import { getAccountSessionUserData } from "./Lib/commonfunctions"
import { BrowserRouter } from "react-router-dom"
import { history } from "./Lib/history"

const rootElement = document.getElementById("root")
const root = ReactDOM.createRoot(rootElement)

const onUpdate = (registration) => {
  const waitingServiceWorker = registration.waiting
  if (waitingServiceWorker) {
    waitingServiceWorker.addEventListener("statechange", (event) => {
      if (event.target.state === "activated") {
        //window.location.reload()
        store.dispatch(updateServiceworker())
      }
    })
    waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
  }
}

root.render(
  <Provider store={store}>
    <BrowserRouter history={history}>
      {process.env.REACT_APP_NODE_ENV !== "development" ? (
        <ErrorBoundary getSessionUserData={getAccountSessionUserData} postErrors={true}>
          <App />
        </ErrorBoundary>
      ) : (
        <App />
      )}
    </BrowserRouter>
  </Provider>
)

var config = {}
config.onUpdate = onUpdate
serviceWorker.register(config)
