import { generateDetailHeaders, generateDetailValues, masterCustomFieldV1 } from "./common"
import { DatetoDMY, checkRenderConditionPrint, isblank, replaceBRtoNewLine, replacefunction } from "./utilityFunctions"

export default ({ printdata, meta, pageSize, user_data, functions }) => {
  const inward_type_detail = functions.getAccountSitesetting("inward_type_detail")
  const outward_type_detail = functions.getAccountSitesetting("outward_type_detail")
  var invoice_template = {}
  var title = ""
  var billno = printdata.master_table_data[0].billno

  var inbillno = printdata.inwardDetail.master_table_data[0].billno
  var outbillno = printdata.outwardDetail.master_table_data[0].billno

  var date = DatetoDMY(printdata.master_table_data[0].b_date)
  var PartyName = printdata.user_details[0].name
  var inMasterCustomField = printdata.inwardDetail.custom_fields_data_master

  /* SET MASTER CUSTOM FIELD RENDER CONDITION */
  inMasterCustomField = inMasterCustomField.filter(function(item) {
    return checkRenderConditionPrint(item, printdata.inwardDetail.master_table_data[0])
  })

  var inDetailCustomField = printdata.inwardDetail.custom_fields_data

  /* SET DETAIL CUSTOM FIELD RENDER CONDITION */
  inDetailCustomField = inDetailCustomField.filter(function(item) {
    return checkRenderConditionPrint(item, printdata.inwardDetail.master_table_data[0])
  })

  var outMasterCustomField = printdata.outwardDetail.custom_fields_data_master

  /* SET MASTER CUSTOM FIELD RENDER CONDITION */
  outMasterCustomField = outMasterCustomField.filter(function(item) {
    return checkRenderConditionPrint(item, printdata.outwardDetail.master_table_data[0])
  })

  var outDetailCustomField = printdata.outwardDetail.custom_fields_data

  var inDetoutDetailCustomFieldailCustomField = printdata.outwardDetail.custom_fields_data_master

  /* SET DETAIL CUSTOM FIELD RENDER CONDITION */
  outDetailCustomField = inDetoutDetailCustomFieldailCustomField.filter(function(item) {
    return checkRenderConditionPrint(item, printdata.outwardDetail.master_table_data[0])
  })

  var city = ""
  var cityData = ""
  cityData = printdata.city_data.find((item) => parseInt(item.city_id) === parseInt(printdata.user_details[0].city_id))

  if (!isblank(cityData)) {
    city = cityData.city
  }

  var state = ""
  var stateData = ""
  stateData = printdata.state_data.find((item) => parseInt(item.sid) === parseInt(printdata.user_details[0].state_id))
  if (!isblank(stateData)) {
    state = stateData.state
  }

  var Address = replacefunction(replaceBRtoNewLine(printdata.user_details[0].address))
  if (!isblank(printdata.user_details[0].areacode)) {
    Address = Address + ", " + printdata.user_details[0].areacode
  }
  if (!isblank(city)) {
    if (isblank(Address)) {
      Address = Address + city
    } else {
      Address = Address + ", " + city
    }
  }
  if (!isblank(state)) {
    if (isblank(Address)) {
      Address = Address + state
    } else {
      Address = Address + ", " + state
    }
  }
  if (!isblank(printdata.user_details[0].pincode) && !isblank(Address)) {
    Address = Address + " - " + printdata.user_details[0].pincode
  }

  var Contact = !isblank(printdata.user_details[0].mobile) ? printdata.user_details[0].mobile : printdata.user_details[0].phone

  if (!isblank(Contact)) {
    Contact = `Contact: ${Contact}`
  } else {
    Contact = ""
  }

  title = "Stock Transfer"

  var inHeaderObject = generateDetailHeaders({ functions, DetailCustomField: inDetailCustomField, type: "inward", pageSize, rateAmount: 1 })
  var inHeaders = inHeaderObject.Headers
  var inwidthArray = inHeaderObject.widthArray
  var inValues = generateDetailValues({
    functions,
    DetailCustomField: inDetailCustomField,
    DetailGrid: printdata.inwardDetail.details_table_data,
    MasterData: printdata.inwardDetail.master_table_data[0],
    parameterized: false,
    type: "inward",
    user_data,
    rateAmount: 1,
  })

  var outHeaderObject = generateDetailHeaders({ functions, DetailCustomField: outDetailCustomField, type: "outward", pageSize, rateAmount: 1 })
  var outHeaders = outHeaderObject.Headers
  var outwidthArray = outHeaderObject.widthArray
  var outValues = generateDetailValues({
    functions,
    DetailCustomField: outDetailCustomField,
    DetailGrid: printdata.outwardDetail.details_table_data,
    MasterData: printdata.outwardDetail.master_table_data[0],
    parameterized: false,
    type: "outward",
    user_data,
    rateAmount: 1,
  })

  invoice_template.topheaderheight = 20
  invoice_template.bottomheaderheight = 20
  invoice_template.headerheight = 75 //invoice_template.topheaderheight + invoice_template.bottomheaderheight + 48;
  invoice_template.topheader = {}
  invoice_template.header = {}
  invoice_template.body = {}
  invoice_template.footer = {}

  var inwardSetting = inward_type_detail.find((e) => e.id === "inward")
  var inGodown = []
  if (inwardSetting && inwardSetting.showGodown === "1") {
    inGodown = [
      {
        columns: [{ text: "Godown: ", bold: true, width: "auto" }, { text: printdata.inwardDetail.master_table_data[0].godown_name ? printdata.inwardDetail.master_table_data[0].godown_name : "N/A" }],
        margin: [0, 0, 0, 2],
      },
    ]
  }

  var outwardSetting = outward_type_detail.find((e) => e.id === "outward")
  var outGodown = []
  if (outwardSetting && outwardSetting.showGodown === "1") {
    outGodown = [
      {
        columns: [
          { text: "Godown: ", bold: true, width: "auto" },
          { text: printdata.outwardDetail.master_table_data[0].godown_name ? printdata.outwardDetail.master_table_data[0].godown_name : "N/A" },
        ],
        margin: [0, 0, 0, 2],
      },
    ]
  }

  var dd = {
    // a string or { width: number, height: number }
    pageSize: pageSize,
    // by default we use portrait, you can change it to landscape if you wish
    pageOrientation: "portrait",
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [10, invoice_template.headerheight, 10, 20],

    header: [
      {
        columns: [
          { text: "", style: "documentHeaderLeft" },
          { text: title, style: "documentHeaderCenter", bold: true },
          { text: "", style: "documentHeaderRight" },
        ],
      },
      {
        columns: [
          { text: "", style: "" },
          { text: "", style: "" },
          { text: `${title}: #${billno}`, style: "outwardBillDate", bold: true },
        ],
      },
      {
        columns: [
          { text: "", style: "" },
          { text: "", style: "" },
          { text: `Date: ${date}`, style: "outwardBillDate", bold: true },
        ],
      },
    ],
    footer: [],
    content: [
      {
        stack: [
          {
            text: "Account",
            style: "",
            bold: true,
            alignment: "right",
          },
          {
            text: PartyName,
            style: "",
            bold: true,
            alignment: "right",
          },
          {
            text: Address,
            style: "",
            bold: true,
            alignment: "right",
          },
          {
            text: Contact,
            style: "",
            bold: true,
            alignment: "right",
          },
        ],
        border: [false, true, false, false],
        style: "headerInvoiceDetails",
      },
      { text: "Inward Details", bold: true, fontSize: 10 },
      {
        columns: [{ text: "Inward: ", bold: true, width: "auto" }, { text: `#${inbillno}` }],
        margin: [0, 2, 0, 2],
      },
      ...inGodown,
      ...masterCustomFieldV1({ customFieldStructure: { column: 3, mode: "stack", labelBold: true }, MasterCustomField: inMasterCustomField, Values: printdata.inwardDetail.master_table_data[0] }),
      {
        table: {
          headerRows: 1,
          widths: inwidthArray,
          body: [inHeaders, ...inValues],
        },
        layout: {
          hLineWidth: function(i, node) {
            return i === 0 || i === 1 || i === node.table.body.length || i === node.table.body.length - 1 ? 1 : 0
          },
          vLineWidth: function(i, node) {
            return 1
          },
        },
      },
      { text: "Outward Details", bold: true, fontSize: 10, margin: [0, 10, 0, 0] },
      {
        columns: [{ text: "Outward: ", bold: true, width: "auto" }, { text: `#${outbillno}` }],
        margin: [0, 2, 0, 2],
      },
      ...outGodown,
      ...masterCustomFieldV1({ customFieldStructure: { column: 3, mode: "stack", labelBold: true }, MasterCustomField: outMasterCustomField, Values: printdata.outwardDetail.master_table_data[0] }),
      {
        table: {
          headerRows: 1,
          widths: outwidthArray,
          body: [outHeaders, ...outValues],
        },
        layout: {
          hLineWidth: function(i, node) {
            return i === 0 || i === 1 || i === node.table.body.length || i === node.table.body.length - 1 ? 1 : 0
          },
          vLineWidth: function(i, node) {
            return 1
          },
        },
      },
    ],

    defaultStyle: {
      fontSize: 8,
    },
    styles: {
      center: {
        alignment: "center",
      },
      right: {
        alignment: "right",
      },
      left: {
        alignment: "left",
      },
      // Invoice Header
      headerLogo: {
        margin: [5, 5, 5, 5],
      },
      masterCustomFields: {
        margin: [0, 0, 0, 10],
      },
      headerCompanyInfo: {
        margin: [5, 5, 5, 5],
      },
      headerInvoiceDetails: {
        margin: [5, 5, 5, 5],
      },
      invoiceCompanyName: {
        bold: true,
        fontSize: 14,
      },
      // Document Header
      documentHeaderLeft: {
        margin: [10, 10, 10, 10],
        alignment: "left",
      },
      documentHeaderCenter: {
        fontSize: 14,
        margin: [10, 10, 10, 10],
        alignment: "center",
      },
      outwardBillDate: {
        margin: [0, 0, 10, 5],
        alignment: "right",
      },
      documentHeaderRight: {
        margin: [10, 10, 10, 10],
        alignment: "right",
      },
      // Document Footer
      documentFooterLeft: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "left",
      },
      documentFooterCenter: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "center",
      },
      documentFooterRight: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "right",
      },
    },
  }

  return dd
}
