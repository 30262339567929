import { convertIntif } from "@project/sharedcomponents/utilityFunctions"
import { OPBAL_LOCAL_SEARCH, OPBAL_SERVICE_CLEAR, OPBAL_SERVICE_FAIL, OPBAL_SERVICE_START, OPBAL_SERVICE_SUCCESS, OPBAL_SERVICE_UPDATED } from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  other_type: "",
  search_text: "",
  columnData: [
    { id: "name", numeric: false, visible: true, label: "Account Name", showlink: true },
    { id: "city", numeric: false, visible: true, total: "label", label: "City", width: 150, disableResizing: true },
    {
      id: "debit_bal",
      numeric: true,
      visible: true,
      total: true,
      label: `Debit Bal`,
      disableResizing: true,
      toggle: true,
      popover: { key: "updateBalance", placeholder: "Enter Op Balance", label: "Opening Balance", name: "opbal", title: "Update Debit Opening Balance", number: true },
      removetoggleOnly: { valueblank: ["code"] },
      bold: { valueblank: ["code"] },
      width: 200,
      currencySign: true,
      format: "indian_rupee",
    },
    {
      id: "credit_bal",
      numeric: true,
      visible: true,
      total: true,
      label: `Credit Bal`,
      disableResizing: true,
      toggle: true,
      popover: { key: "updateBalance", placeholder: "Enter Op Balance", label: "Opening Balance", name: "opbal", title: "Update Credit Opening Balance", number: true },
      removetoggleOnly: { valueblank: ["code"] },
      bold: { valueblank: ["code"] },
      width: 200,
      currencySign: true,
      format: "indian_rupee",
    },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPBAL_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case OPBAL_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case OPBAL_SERVICE_SUCCESS:
      return { ...state, ...INITIAL_STATE, data: action.payload.data.result, loading: false, other_type: action.other_type, search_text: action.search_text }
    case OPBAL_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false, other_type: action.other_type, search_text: action.search_text }
    case OPBAL_LOCAL_SEARCH:
      return { ...state, search_text: action.search_text }
    case OPBAL_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.accountcode)) {
          return item
        }
        const updateditem = { ...item }
        if (action.payload.credit > 0) {
          updateditem.credit_bal = action.payload.credit
          updateditem.debit_bal = "0.00"
        } else {
          updateditem.debit_bal = action.payload.debit
          updateditem.credit_bal = "0.00"
        }
        return updateditem
      })
      return { ...state, data: updateddata }
    default:
      return state
  }
}
