import { list_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, isblank, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getAccountSessionUserData, getAccountSitesetting, getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import {
  LOADING_STATE,
  RECENT_ACTIVITIES_DRAWER_LOCAL_SEARCH,
  RECENT_ACTIVITIES_DRAWER_SERVICE_FAIL,
  RECENT_ACTIVITIES_DRAWER_SERVICE_START,
  RECENT_ACTIVITIES_DRAWER_SERVICE_SUCCESS,
  SNACKBAR_STATE,
} from "./types"

export const local_search_recentactivies = ({ search_text }) => {
  return (dispatch) => {
    dispatch({
      type: RECENT_ACTIVITIES_DRAWER_LOCAL_SEARCH,
      search_text: search_text,
    })
  }
}

export const fetch_recent_activities_data = ({ page, offset, onComplete, onError }) => {
  // if (isblank(rowsPerPage)) {
  //   rowsPerPage = getAccountSitesetting("pagination_size")
  // }
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: RECENT_ACTIVITIES_DRAWER_SERVICE_START })

    let data = new FormData()

    data.append("offset", offset)
    data.append("limit", 20)
    data.append("sort_order", "desc")
    data.append("sort_by", "id")
    data.append("type", "recent_activities")
    data.append("code", getAccountSessionUserData("user_id"))

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "recent_activities" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response,
            snackbarType: "error",
          })
          onError()
        } else {
          onComplete(response.data.result, page)
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          onError()
        } else {
          onError()
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response,
            snackbarType: "error",
          })
        }
      })
  }
}
