import { getAxiosCancelToken, getCatchErrorMessage, isblank, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getAccountSitesetting, getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"

import { list_url } from "@project/sharedcomponents/apiURL"
import { CLOSINGDATA_SERVICE_CLEAR, CLOSINGDATA_SERVICE_FAIL, CLOSINGDATA_SERVICE_START, CLOSINGDATA_SERVICE_SUCCESS, LOADING_STATE } from "./types"

export const closingdata_data_clear = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSINGDATA_SERVICE_CLEAR,
    })
  }
}

export const fetch_closingdata_data = ({ companycode, rowsPerPage, page, order, orderBy, search_text, code, functions }) => {
  if (isblank(rowsPerPage)) {
    rowsPerPage = getAccountSitesetting("pagination_size")
  }
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: CLOSINGDATA_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("offset", page * rowsPerPage)
    data.append("type", "closingstock")
    data.append("limit", rowsPerPage)
    data.append("sort_order", order)
    data.append("sort_by", orderBy)
    data.append("search_text", search_text)
    data.append("code", code)

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "closingstock" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          closingdataServiceFail({ dispatch, response: response.data.meta.message, rowsPerPage, code, page, order, orderBy, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          closingdataServiceSuccess({ dispatch, user: response, rowsPerPage, code, page, order, orderBy, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          closingdataServiceFail({ dispatch, response: getCatchErrorMessage(error), rowsPerPage, code, page, order, orderBy, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const closingdataServiceSuccess = ({ dispatch, user, rowsPerPage, code, page, order, orderBy, search_text }) => {
  dispatch({
    type: CLOSINGDATA_SERVICE_SUCCESS,
    payload: user,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
    code: code,
  })
}

const closingdataServiceFail = ({ dispatch, response, rowsPerPage, code, page, order, orderBy, search_text }) => {
  dispatch({
    type: CLOSINGDATA_SERVICE_FAIL,
    payload: response,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
    code: code,
  })
}
