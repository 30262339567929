import { makeStyles, withTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import React from "react"

const useStyles = makeStyles((theme) => ({
  sidebarContentPad: {
    padding: 16,
  },
}))

const FallBackComponent = () => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Skeleton variant="rect" width={"100%"} height={48} />
      <div className={classes.sidebarContentPad}>
        <Skeleton variant="text" width={"80%"} />
        <Skeleton variant="text" width={"90%"} />
        <Skeleton variant="text" width={"100%"} />
      </div>
    </React.Fragment>
  )
}

export default withTheme(FallBackComponent)

{
  /* <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={3} lg={3}>
                <Paper variant="outlined" className={classes.product}>
                    <div className={`${classes.productOverlay}`}>
                        <Skeleton variant="rect" width={"100%"} height={"100%"} />
                        <div className={classes.disChip}>
                            <Skeleton variant="circle" width={44} height={44} />
                        </div>
                        <div className={classes.oosChip}>
                            <Skeleton height={20} width={80} />
                        </div>
                        <div className={classes.photosChip}>
                            <Skeleton height={20} width={40} />
                        </div>
                    </div>
                    <div className={classes.productContent}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </div>
                    <div style={{ position: 'relative' }}>
                        <Skeleton variant="rect" width={"100%"} height={36} />
                    </div>
                </Paper>
            </Grid>
        </Grid> */
}
