import { CircularProgress, IconButton, Typography } from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import Popover from "@material-ui/core/Popover"
import TextField from "@material-ui/core/TextField"
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { DateRange, Visibility, VisibilityOff } from "@material-ui/icons"
import HelpBtnComponent from "@project/components/common/HelpBtnComponent"
import { startEvaluation } from "@project/components/utilityFunctions"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import {
  DatetoStr,
  amount_format,
  amount_formatDecimal,
  capitalizeFirstLetter,
  cleanStringForPDFMake,
  getCapitalize,
  getNumberonly,
  getNumberonlyDecimal,
  isNaNValue,
  isblank,
  numberDecimal,
  validateAddress,
  validateEmail,
  validateInputText,
  validateMobile,
} from "@project/sharedcomponents/utilityFunctions"
import React from "react"
import { isMobileOnly } from "react-device-detect"
import { useDispatch, useSelector } from "react-redux"
import DebounceLink from "../common/DebounceLink"
import StaticDatePicker from "../common/StaticDatePicker"
import LabelComponent from "../common/labelComponent"

const useStyles = makeStyles((theme) => ({
  textFieldInputNumber: {
    textAlign: "right",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "&$cssError $notchedOutline": {
      borderColor: theme.palette.error.main,
    },
    "&$cssFocused $notchedDateOutline": {
      borderColor: theme.palette.primary.main,
    },
    "&$cssError $notchedDateOutline": {
      borderColor: theme.palette.error.main,
    },
  },
  cssFocused: {},
  cssError: {},
  notchedOutline: {
    borderWidth: 0,
  },
  notchedDateOutline: {},
  notchedMobileDateOutline: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.87)",
  },
  condenseInputClass: {
    padding: "6.5px 8px",
  },
}))

const RenderTextField = ({
  input: { value, ...input },
  label,
  noLableMargin,
  trimSpace,
  labelStyle,
  disableAutoCaps,
  placeholder,
  minRows,
  isRequired,
  number,
  onvaluechange,
  onBlurGetValue,
  labelbuttontext,
  labelbuttonclick,
  labelbuttontext2,
  labelbuttonclick2,
  fieldType,
  dataindex,
  setUnderLine,
  addComma,
  decimal,
  AdornmentPosition,
  StartAdornment,
  EndAdornment,
  helpText,
  helpStyle,
  toolTipStyle,
  tabIndex,
  isLoading,
  changeOnKeyPress,
  meta: { touched, error },
  ...custom
}) => {
  const [textvalue, setTextvalue] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const theme = useTheme()
  const accountSiteSettingKeyValue = useSelector((state) => state.AuthReducer.accountSiteSettingKeyValue)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [helpOpen, sethelpOpen] = React.useState(false)

  const [passwordVisible, setpasswordVisible] = React.useState(false)

  const secondaryActionFrame = {
    width: 30,
    height: 30,
    position: "absolute",
    right: 0,
    top: 24,
    zIndex: 1,
  }

  let addcancelButton = null

  let startAdornment = null
  if (StartAdornment) {
    startAdornment = <InputAdornment position={AdornmentPosition}>{StartAdornment}</InputAdornment>
  }

  function setpassword_Visible(value) {
    setpasswordVisible(value)
  }

  let endAdornment = null
  if (custom.type === "password") {
    endAdornment = (
      <InputAdornment position="end">
        <Tooltip title={passwordVisible ? "Hide Password" : "Show Password"}>
          <IconButton size="small" aria-label="delete" color="default" tabIndex={-1} onClick={() => setpassword_Visible(!passwordVisible)}>
            {passwordVisible ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
          </IconButton>
        </Tooltip>
      </InputAdornment>
    )
  } else if (isLoading) {
    endAdornment = (
      <InputAdornment position="end">
        <CircularProgress color="primary" size={18} />
      </InputAdornment>
    )
  } else if (EndAdornment) {
    endAdornment = <InputAdornment position="end">{EndAdornment}</InputAdornment>
  }

  var variantType = "standard"
  var ingrid = false
  if (!isblank(setUnderLine)) {
    if (!setUnderLine) {
      ingrid = true
      variantType = "outlined"
    }
  }

  if (custom.type === "date") {
    variantType = "outlined"
  }

  const id = open ? "simple-popover" : undefined

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handledatechange(date) {
    setAnchorEl(null)
    input.onChange(DatetoStr(date))
    if (onvaluechange) {
      onvaluechange(DatetoStr(date))
    }
    if (onBlurGetValue) {
      onBlurGetValue(DatetoStr(date))
    }
  }

  function handleClose() {
    setAnchorEl(null)
  }
  function handleTooltipClick(value) {
    sethelpOpen(value)
  }

  function getValue(val) {
    if (!isblank(accountSiteSettingKeyValue) && accountSiteSettingKeyValue.textTransformation === "u" && !disableAutoCaps && fieldType !== "email" && custom.type !== "password") {
      return val.toUpperCase()
    } else if (!isblank(accountSiteSettingKeyValue) && accountSiteSettingKeyValue.textTransformation === "c" && !disableAutoCaps && fieldType !== "email" && custom.type !== "password") {
      return getCapitalize(val)
    } else {
      return val
    }
  }
  let inputClass = classes.textFieldInput
  if (number) {
    inputClass = classes.textFieldInputNumber
  }
  inputClass = `${inputClass} ${classes.condenseInputClass}`

  return (
    <div style={{ position: "relative", width: "100%" }}>
      {label && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <LabelComponent name={input.name} label={label} noLableMargin={noLableMargin} isRequired={isRequired} error={touched && error} labelStyle={labelStyle} />
            {helpText && <HelpBtnComponent help={helpText} style={helpStyle ? helpStyle : { marginLeft: 4 }} toolTipStyle={toolTipStyle} />}
          </div>
        </div>
      )}

      {custom.type === "date" && (
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", right: 5, top: 2, zIndex: 1 }}>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <StaticDatePicker value={textvalue === false ? value : textvalue} onchange={handledatechange} />
            </Popover>
            {custom.calIcon !== false && (
              <IconButton aria-label={id} disabled={custom.disabled} size="small" onClick={handleClick} tabIndex="-1">
                <DateRange fontSize="small" />
              </IconButton>
            )}
          </div>
        </div>
      )}
      <TextField
        {...input}
        value={textvalue === false ? value : textvalue}
        variant="outlined"
        InputProps={{
          startAdornment: startAdornment,
          endAdornment: endAdornment,
          inputProps: { "data-testid": input.name, id: input.name, tabIndex },
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            error: classes.cssError,
            input: inputClass,
            notchedOutline:
              variantType === "outlined"
                ? ingrid === false && variantType === "outlined" && isMobileOnly
                  ? classes.notchedMobileDateOutline
                  : ingrid === false && variantType === "outlined"
                    ? classes.notchedDateOutline
                    : classes.notchedOutline
                : undefined,
          },
        }}
        {...(minRows && {
          minRows: minRows,
          maxRows: minRows,
        })}
        onFocus={(e) => {
          var target = e.target
          if (number) {
            target.setSelectionRange(0, value.length)
          }
        }}
        onBlur={(e) => {
          var target = getValue(e.target.value)

          if (trimSpace) {
            target = target.replace(/^\s+|\s+$/gm, "")
          }

          target = cleanStringForPDFMake(target)

          if (number) {
            if (target.includes("+") || target.includes("-") || target.includes("*") || target.includes("/")) {
              target = target.replace(/,/g, "")
              target = target.replace(/ /g, "")
              target = startEvaluation(target)
            }
          }

          input.onChange(target)
          if (onvaluechange) {
            if (dataindex !== undefined) {
              onvaluechange(dataindex, target)
            } else {
              onvaluechange(target)
            }
          }

          if (number) {
            if (target !== "") {
              if (isNaNValue(target)) {
                if (decimal || decimal === 0) {
                  input.onChange(amount_formatDecimal(0, decimal))
                } else {
                  input.onChange(amount_format(0))
                }
                dispatch({
                  type: "snackbar_state",
                  payload: true,
                  message: ErrorMessages["E00066"],
                })
              } else {
                if (!isblank(addComma) && !addComma) {
                  if (decimal || decimal === 0) {
                    input.onChange(numberDecimal(target, decimal))
                  }
                } else {
                  if (decimal || decimal === 0) {
                    input.onChange(amount_formatDecimal(target, decimal))
                  } else {
                    input.onChange(amount_format(target))
                  }
                }
              }
            }
          } else if (fieldType === "mobile") {
            if (!isblank(target)) {
              if (isNaN(target)) {
                input.onChange("")
                dispatch({
                  type: "snackbar_state",
                  payload: true,
                  message: "Enter only number",
                })
              } else if (!validateMobile(target)) {
                dispatch({
                  type: "snackbar_state",
                  payload: true,
                  message: "Enter only 10 digit mobile number",
                })
              }
            }
          } else if (fieldType === "email") {
            if (!isblank(target)) {
              if (!validateEmail(target)) {
                input.onChange(target)
                dispatch({
                  type: "snackbar_state",
                  payload: true,
                  message: "Enter valid email address",
                })
              }
            }
          } else if (fieldType === "address") {
            if (!isblank(target)) {
              if (!validateAddress(target)) {
                input.onChange(target)
                dispatch({
                  type: "snackbar_state",
                  payload: true,
                  message: ErrorMessages["E00528"],
                })
              }
            }
          } else if (fieldType === "narration") {
            if (!isblank(target)) {
              if (!validateInputText(target)) {
                input.onChange(target)
                dispatch({
                  type: "snackbar_state",
                  payload: true,
                  message: ErrorMessages["E00550"],
                })
              }
            }
          }

          if (onBlurGetValue) {
            if (number) {
              if (decimal) {
                onBlurGetValue(getNumberonlyDecimal(target, decimal))
              } else {
                onBlurGetValue(getNumberonly(target))
              }
            } else {
              onBlurGetValue(target)
            }
          }
          setTextvalue(false)
        }}
        onChange={(e) => {
          const target = e.target
          const cursorPosition = target.selectionStart

          let newValue = getValue(target.value)

          if (changeOnKeyPress) {
            input.onChange(newValue)
          }
          if (onvaluechange) {
            onvaluechange(newValue)
          }
          setTextvalue(newValue)

          if (custom.type !== "date") {
            // Restore cursor position
            setTimeout(() => {
              target.setSelectionRange(cursorPosition, cursorPosition)
            }, 0)
          }
        }}
        error={Boolean(touched && error)}
        {...(placeholder && {
          placeholder: capitalizeFirstLetter(placeholder),
        })}
        autoComplete={custom.type === "password" && !passwordVisible ? "new-password" : "off"}
        fullWidth={true}
        {...custom}
        type={custom.type === "password" && passwordVisible ? "text" : custom.type}
      />

      {(labelbuttonclick2 || labelbuttonclick) && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {labelbuttonclick2 && (
            <DebounceLink component="button" data-testid={`${input.name}_link_4`} tabIndex="-1" variant="body2" underline="always" style={{ cursor: "pointer" }} onClick={labelbuttonclick2}>
              {labelbuttontext2}
            </DebounceLink>
          )}
          {!isblank(labelbuttonclick) && !isblank(labelbuttonclick2) && <Typography style={{ fontSize: 16, marginLeft: 5, marginRight: 5 }}>{`||`}</Typography>}
          {labelbuttonclick && (
            <DebounceLink component="button" data-testid={`${input.name}_link_3`} tabIndex="-1" variant="body2" underline="always" style={{ cursor: "pointer" }} onClick={labelbuttonclick}>
              {labelbuttontext}
            </DebounceLink>
          )}
        </div>
      )}

      {addcancelButton && <div style={secondaryActionFrame}>{addcancelButton}</div>}
    </div>
  )
}

export default RenderTextField
