import { IconButton, Link, Typography } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { isblank } from "@project/sharedcomponents/utilityFunctions"
import React from "react"

export default ({ announcementDetail }) => {
  return (
    <React.Fragment>
      {!isblank(announcementDetail) && (
        <Typography
          style={{
            color: "white",
            display: "block",
            paddingTop: 4,
            paddingBottom: 4,
            paddingRight: 8,
            paddingLeft: 8,
            textAlign: "center",
            backgroundColor: announcementDetail.color ? announcementDetail.color : "rgb(17, 82, 147)",
          }}
          variant="subtitle2"
          noWrap
        >
          {announcementDetail.text}{" "}
          {announcementDetail.linkClick && (
            <Link component="button" color="inherit" underline="always" onClick={announcementDetail.linkClick}>
              {announcementDetail.linkLabel}
            </Link>
          )}
          {announcementDetail.closeButton && (
            <IconButton size="small" onClick={announcementDetail.closeButton} style={{ margin: 0, padding: 0, position: "absolute", right: 8 }}>
              <Close fontSize="small" style={{ margin: 0, padding: 0, color: "white" }} />
            </IconButton>
          )}
        </Typography>
      )}
    </React.Fragment>
  )
}
