import { Button, DialogActions, DialogContent, Grid } from "@material-ui/core"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import { DatetoStr, getNumberonlyDecimal, isblank, numForCalc } from "@project/sharedcomponents/utilityFunctions"
import React from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { clear_sales_order, showdrawerdialog, snackbarstate } from "../../Actions"
import { currentFinancialYear, getAccountSitesetting, getendyear, getstartyear } from "../../Lib/commonfunctions"
import Field from "../common/reduxFormComponents"

const ClearSalesOrder = (props) => {
  const dispatch = useDispatch()
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")

  let toDate = `${getendyear()}-03-31`
  if (currentFinancialYear()) {
    toDate = DatetoStr(new Date())
  }

  const defaultValues = {
    from_date: `${getstartyear()}-04-01`,
    to_date: toDate,
    type: "closed_qty",
  }
  const { handleSubmit, control, watch } = useForm({ defaultValues })

  const formValues = watch()

  const { loading, sidebarloading } = useSelector((state) => state.CommonReducer)

  const onSubmitData = (values) => {
    const value = getNumberonlyDecimal(values.value, values.type === "closed_percentage" ? 2 : inventory_decimalpoint)
    const form_data = {
      from_date: values.from_date,
      to_date: values.to_date,
      invoice_type: "sales_order",
      closed_qty: values.type === "closed_qty" ? value : "",
      closed_percentage: values.type === "closed_percentage" ? value : "",
    }
    const functions = { form_data }
    dispatch(clear_sales_order(functions))
  }

  const handleConfirmClose = () => {
    dispatch(showdrawerdialog({ yesno: false }))
  }

  const onSubmitError = (values) => {
    const err = Object.keys(values)
    dispatch(snackbarstate({ open: true, message: values[err[0]].message, snackbarType: "error" }))
    setTimeout(function () {
      document.getElementsByName(err[0])[0].focus()
    }, 500)
  }
  const handleClearData = () => {
    handleSubmit(onSubmitData, onSubmitError)()
  }

  const onTypeChange = (value) => {}

  const qtyValidate = (value) => {
    if (!isblank(value)) {
      if (numForCalc(value, inventory_decimalpoint) <= 0) {
        return ErrorMessages["E00546"]
      }
    }
  }

  return (
    <React.Fragment>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Field name="from_date" inputType={"RenderTextField"} control={control} label="FROM DATE" type="date" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field name="to_date" inputType={"RenderTextField"} control={control} label="TO DATE" type="date" />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="type"
              labelPlacement="end"
              inputType={"renderRadioGroup"}
              control={control}
              options={[
                { value: "closed_qty", label: "Quantity", color: "primary" },
                { value: "closed_percentage", label: "Percentage", color: "primary" },
              ]}
              onChange={(value) => onTypeChange(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="value"
              number={true}
              decimal={formValues.type === "closed_percentage" ? 2 : inventory_decimalpoint}
              label="Enter Value"
              inputType={"RenderTextField"}
              control={control}
              isRequired={true}
              rules={{ required: ErrorMessages.E00541, validate: qtyValidate }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmClose} disabled={sidebarloading | loading ? true : false} variant="text">
          Cancel
        </Button>
        <Button onClick={handleClearData} disabled={sidebarloading | loading ? true : false} variant="text" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

export default ClearSalesOrder
