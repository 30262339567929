import { list_url } from "@project/sharedcomponents/apiURL"
import { changeDetail, changeDetailV1 } from "@project/sharedcomponents/changeDetailFn"
import cF from "@project/sharedcomponents/constantData/customFieldsEnum"
import { DatetoDMY, getAxiosCancelToken, getCatchErrorMessage, getdropdownfieldvalue, isblank, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import { pushDrawer, updateDrawer } from "./CommonActionsV2"
import {
  LAST_PAGE_UPDATE,
  LOADING_STATE,
  PROCESS_TRANSFER_SEARCH_TEXT_UPDATE,
  PROCESS_TRANSFER_SERVICE_CLEAR,
  PROCESS_TRANSFER_SERVICE_FAIL,
  PROCESS_TRANSFER_SERVICE_SUCCESS,
  REPORT_SERVICE_SUCCESS,
  SNACKBAR_STATE,
} from "./types"

export const processTransfer_data_clear = () => {
  return (dispatch) => {
    dispatch({
      type: PROCESS_TRANSFER_SERVICE_CLEAR,
    })
  }
}
export const processTransfersearch_change = ({ search_text }) => {
  return (dispatch) => {
    dispatch({
      type: PROCESS_TRANSFER_SEARCH_TEXT_UPDATE,
      search_text: search_text,
    })
  }
}

export const fetchProcessAndLot = ({
  code,
  search_text,
  entryType,
  product_code,
  lot_party,
  viewType,
  rowsPerPage,
  drawerIndex,
  page,
  order,
  orderBy,
  Selected,
  other_type,
  mode,
  party_code,
  form_type,
  other_type1,
  disabled,
  lot_number,
  processSelected,
  to_masterCustomField,
  getValues,
  set_Value,
  gridClearField,
  process_code,
  report,
  showRate = false,
  InvoiceDate = "",
  showPcs,
  customerDisabled = false,
  functions,
}) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    var type = "lot_process_find"
    if (form_type === "purchase_invoice") {
      type = "lot_link"
    }
    data.append("type", type)
    data.append("code", code)
    if (entryType) {
      data.append("entryType", entryType)
    }
    if (report) {
      data.append("report", true)
    }
    if (other_type === "kanban") {
      data.append("offset", 0)
      data.append("limit", "All")
    } else {
      data.append("offset", page * rowsPerPage)
      data.append("limit", rowsPerPage)
    }
    data.append("sort_order", order)
    data.append("sort_by", orderBy)
    data.append("other_type", other_type)
    data.append("search_text", search_text)
    data.append("party_code", party_code)

    if (!isblank(showPcs)) {
      data.append("showPcs", 1)
    }
    if (!isblank(lot_party)) {
      data.append("lot_party", lot_party)
    }

    if (!isblank(lot_number)) {
      data.append("lot_number", lot_number)
    }
    if (!isblank(process_code)) {
      data.append("process_code", process_code)
    }
    if (!isblank(product_code)) {
      data.append("product_code", product_code)
    }
    if (!isblank(code)) {
      data.append("from_page_type", form_type)
    }
    if (!isblank(viewType)) {
      data.append("viewType", viewType)
    }
    if (!isblank(viewType) && viewType === "0") {
      data.append("view_type", "summary")
    } else if (!isblank(viewType) && viewType === "1") {
      data.append("view_type", "detail")
    }

    data.append("mode", mode)

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "lot_transfer_and_lot" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false })
        } else {
          if (other_type === "list") {
            const setCustomFieldWiseDescription = (customFields, rowData) => {
              let description = ""
              customFields.forEach((i) => {
                if (!isblank(rowData[i.name])) {
                  let name = rowData[i.name]
                  if (i.dataType !== "7") {
                    if (parseInt(i.dataType) === cF.Dropdown || parseInt(i.dataType) === cF.Creatable_Dropdown) {
                      name = getdropdownfieldvalue({ dropDownData: i.dropDownOptions, field: "code", value: name, displayvalue: "value" })
                    } else if (parseInt(i.dataType) === cF.Date) {
                      name = DatetoDMY(name)
                    }
                    if (isblank(description)) {
                      description = `${i.labelName}: ${name}`
                    } else {
                      description = `${description}, ${i.labelName}: ${name}`
                    }
                  }
                }
              })
              return description
            }
            response.data.result = changeDetail(response.data.result)
            const data = {}
            data.result = response.data.result
            const masterCustomFields = response.data.custom_fields_data ? response.data.custom_fields_data.filter((e) => e.masterDetailLevel === "0") : []
            const detailCustomFields = response.data.custom_fields_data ? response.data.custom_fields_data.filter((e) => e.masterDetailLevel === "1") : []
            data.result = data.result.map((e) => {
              const updated = { ...e }
              updated.description = setCustomFieldWiseDescription(masterCustomFields, updated)
              updated.DetailGrid = updated.DetailGrid.map((i) => {
                const updtd = { ...i }
                updtd.description = setCustomFieldWiseDescription(detailCustomFields, updtd)
                return updtd
              })
              return updated
            })
            data.totalcount = response.data.total_count
            data.webServiceData = {}
            data.webServiceData.page = page
            data.webServiceData.rowsPerPage = rowsPerPage
            data.webServiceData.order = order
            data.webServiceData.orderBy = orderBy
            data.webServiceData.entryType = entryType
            data.webServiceData.viewType = viewType
            data.webServiceData.other_type = other_type
            data.webServiceData.other_type1 = other_type1
            data.webServiceData.search_text = search_text
            data.webServiceData.code = code
            data.Selected = Selected
            data.to_masterCustomField = to_masterCustomField
            data.customerDisabled = customerDisabled
            data.disabled = disabled ? disabled : []
            data.webServiceData.party_code = party_code
            // data.webServiceData.process_code = process_code
            data.webServiceData.mode = mode
            data.form_type = form_type
            data.processSelected = processSelected
            data.getValues = getValues
            data.set_Value = set_Value
            data.gridClearField = gridClearField
            data.showRate = showRate
            data.InvoiceDate = InvoiceDate
            data.result = changeDetail(data.result)
            if (other_type1 === "list_update") {
              updateDrawer({ title: "Select Process or Lot", data, dispatch, index: drawerIndex })
            } else {
              pushDrawer({ title: "Select Process or Lot", data, position: "bottom", dispatch })
            }
          } else if (other_type === "kanban") {
            const getCustomFields = (detailGrid) => {
              let returnValue = []
              if (!isblank(detailGrid)) {
                Object.keys(detailGrid).forEach((e) => {
                  if (e.indexOf("__cust_") > -1 && response.data.custom_fields_data) {
                    let custom_fields_data = response.data.custom_fields_data.find((i) => i.name === e)
                    if (!isblank(custom_fields_data)) {
                      returnValue = [...returnValue, custom_fields_data]
                    }
                  }
                })
              }
              return returnValue
            }
            response.data.result = response.data.result.map((item) => {
              const updatedItem = { ...item }
              if (!isblank(viewType) && viewType === "1") {
                updatedItem.DetailGrid = changeDetail(updatedItem.DetailGrid)
                updatedItem.DetailGrid = updatedItem.DetailGrid.map((e) => {
                  const updated = { ...e }
                  updated.custom_fields_data = getCustomFields(updated.DetailGrid[0])
                  return updated
                })
                return updatedItem
              } else {
                updatedItem.items = changeDetail(updatedItem.items)
                updatedItem.items = updatedItem.items.map((e) => {
                  const updated = { ...e }
                  // MODE == history
                  if (mode === "History") {
                    var penqty = 0
                    var qty = 0
                    e.DetailGrid.forEach((it, index) => {
                      penqty = penqty + parseFloat(it.penqty)
                      qty = qty + parseFloat(it.qty)
                    })
                    updated.qty = qty
                    updated.penqty = penqty
                  }
                  //
                  updated.custom_fields_data = getCustomFields(updated.DetailGrid[0])
                  return updated
                })
                return updatedItem
              }
            })
            if (other_type1 === "report") {
              response.data.result = response.data.result.filter((item) => item.DetailGrid.length > 0)
              var Result = []
              response.data.result.forEach((item) => {
                item.DetailGrid.forEach((e) => {
                  if (!isblank(e.billno_str)) {
                    e.bill_no = e.billno_str
                  }
                  if (viewType === "0") {
                    var itemName = []
                    var penqty = 0
                    var qty = 0

                    var penpcs = 0
                    var pcs = 0

                    e.DetailGrid.forEach((it, index) => {
                      penqty = penqty + parseFloat(it.penqty)
                      qty = qty + parseFloat(it.qty)

                      penpcs = penpcs + parseFloat(it.pen_pcs)
                      pcs = pcs + parseFloat(it.pcs)
                      if (index === 0 || itemName.filter((item1) => item1 === it.itemname).length === 0) {
                        itemName.push(it.itemname)
                      }
                    })
                    itemName.forEach((item1, index) => {
                      if (index === 0) {
                        e.itemName = item1
                      } else {
                        e.itemName = `${e.itemName},${item1}`
                      }
                    })

                    if (mode === "In") {
                      e.penqty = penqty
                      e.qty = qty
                      e.balance = qty - penqty

                      e.penpcs = penpcs
                      e.pcs = pcs
                      e.pcs_balance = pcs - penpcs
                    } else {
                      e.penqty = penqty
                      e.qty = qty - penqty
                      e.balance = qty

                      e.penpcs = penpcs
                      e.pcs = pcs - penpcs
                      e.pcs_balance = pcs
                    }

                    e.title = item.title
                    Result.push(e)
                  } else {
                    e.DetailGrid.forEach((it) => {
                      const itemObj = { ...e }
                      itemObj.itemName = it.itemname
                      itemObj.penqty = it.penqty
                      itemObj.qty = it.qty
                      itemObj.balance = it.qty - it.penqty
                      itemObj.title = item.title

                      itemObj.penpcs = it.pen_pcs
                      itemObj.pcs = it.pcs
                      itemObj.pcs_balance = it.pcs - it.pen_pcs

                      delete itemObj.DetailGrid
                      delete itemObj.custom_fields_data
                      Result.push(itemObj)

                      /*e.itemName = it.itemname
                      e.penqty = it.penqty
                      e.qty = it.qty
                      e.balance = it.qty - it.penqty
                      e.title = item.title
                      Result.push(cloneObj(e))*/
                    })
                  }
                })
              })
              var payload = {
                data: {
                  result: {
                    records: Result,
                  },
                  filter: !isblank(response) && !isblank(response.data) && response.data.filter,
                  // filter_master: !isblank(response) && !isblank(response.data) && response.data.filter_master,
                },
              }
              dispatch({
                type: REPORT_SERVICE_SUCCESS,
                payload: payload,
                setconfig: {},
              })
            } else {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: "kanban",
                lastaction: "list",
                lasttime: new Date().getTime(),
                lastdata: response.data.result,
              })
            }
          }
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const fetchProcessAndLotV1 = ({
  code,
  search_text,
  entryType,
  product_code,
  lot_party,
  viewType,
  rowsPerPage,
  drawerIndex,
  page,
  order,
  orderBy,
  Selected,
  other_type,
  mode,
  party_code,
  form_type,
  other_type1,
  disabled,
  lot_number,
  processSelected,
  to_masterCustomField,
  getValues,
  set_Value,
  gridClearField,
  process_code,
  report,
  flag,
  dueDays,
  onComplete,
  item_flag,
  toProcessSelectedValue,
  filter,
  showPcs,
  functions,
}) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    var type = "lot_process_find"
    if (form_type === "purchase_invoice") {
      type = "lot_link"
    }
    data.append("type", type)
    data.append("code", code)
    if (entryType) {
      data.append("entryType", entryType)
    }
    if (report) {
      data.append("report", true)
    }
    if (other_type === "kanban") {
      data.append("offset", 0)
      data.append("limit", "All")
    } else {
      data.append("offset", page * rowsPerPage)
      data.append("limit", rowsPerPage)
    }
    data.append("sort_order", order)
    data.append("sort_by", orderBy)
    data.append("other_type", other_type)
    data.append("search_text", search_text)
    data.append("party_code", party_code)
    data.append("flag", flag)

    if (!isblank(lot_party)) {
      data.append("lot_party", lot_party)
    }
    if (!isblank(showPcs)) {
      data.append("showPcs", 1)
    }
    if (!isblank(lot_number)) {
      data.append("lot_number", lot_number)
    }
    if (!isblank(process_code)) {
      data.append("process_code", process_code)
    }
    if (!isblank(product_code)) {
      data.append("product_code", product_code)
    }
    if (!isblank(code)) {
      data.append("from_page_type", form_type)
    }
    if (!isblank(dueDays)) {
      data.append("dueDays", dueDays)
    }
    if (!isblank(viewType)) {
      data.append("viewType", viewType)
    }
    if (!isblank(viewType) && viewType === "0") {
      data.append("view_type", "summary")
    } else if (!isblank(viewType) && viewType === "1") {
      data.append("view_type", "detail")
    }
    if (!isblank(item_flag)) {
      data.append("item_flag", item_flag)
    }
    data.append("mode", mode)

    if (!isblank(filter)) {
      data.append("filter", filter)
    }
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "lot_transfer_and_lotV1" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false })
        } else {
          if (other_type === "list") {
            const setCustomFieldWiseDescription = (customFields, rowData) => {
              let description = ""
              customFields.forEach((i) => {
                if (!isblank(rowData[i.name])) {
                  let name = rowData[i.name]
                  if (i.dataType !== "7") {
                    if (parseInt(i.dataType) === cF.Dropdown || parseInt(i.dataType) === cF.Creatable_Dropdown) {
                      name = getdropdownfieldvalue({ dropDownData: i.dropDownOptions, field: "code", value: name, displayvalue: "value" })
                    } else if (parseInt(i.dataType) === cF.Date) {
                      name = DatetoDMY(name)
                    }
                    if (isblank(description)) {
                      description = `${i.labelName}: ${name}`
                    } else {
                      description = `${description}, ${i.labelName}: ${name}`
                    }
                  }
                }
              })
              return description
            }
            response.data.result = changeDetail(response.data.result)
            const data = {}
            data.result = response.data.result
            const masterCustomFields = response.data.custom_fields_data ? response.data.custom_fields_data.filter((e) => e.masterDetailLevel === "0") : []
            const detailCustomFields = response.data.custom_fields_data ? response.data.custom_fields_data.filter((e) => e.masterDetailLevel === "1") : []
            data.result = data.result.map((e) => {
              const updated = { ...e }
              updated.description = setCustomFieldWiseDescription(masterCustomFields, updated)
              updated.DetailGrid = updated.DetailGrid.map((i) => {
                const updtd = { ...i }
                updtd.description = setCustomFieldWiseDescription(detailCustomFields, updtd)
                return updtd
              })
              return updated
            })
            data.totalcount = response.data.total_count
            data.webServiceData = {}
            data.webServiceData.page = page
            data.webServiceData.rowsPerPage = rowsPerPage
            data.webServiceData.order = order
            data.webServiceData.orderBy = orderBy
            data.webServiceData.entryType = entryType
            data.webServiceData.viewType = viewType
            data.webServiceData.other_type = other_type
            data.webServiceData.other_type1 = other_type1
            data.webServiceData.search_text = search_text
            data.webServiceData.code = code
            data.Selected = Selected
            data.to_masterCustomField = to_masterCustomField
            data.disabled = disabled ? disabled : []
            data.webServiceData.party_code = party_code
            data.webServiceData.mode = mode
            data.form_type = form_type
            data.processSelected = processSelected
            data.getValues = getValues
            data.set_Value = set_Value
            data.gridClearField = gridClearField
            data.result = changeDetail(data.result)
            if (other_type1 === "list_update") {
              updateDrawer({ title: "Select Process or Lot", data, dispatch, index: drawerIndex })
            } else {
              pushDrawer({ title: "Select Process or Lot", data, position: "bottom", dispatch })
            }
          } else if (other_type === "kanban") {
            const getCustomFields = (detailGrid) => {
              let returnValue = []
              if (!isblank(detailGrid)) {
                Object.keys(detailGrid).forEach((e) => {
                  if (e.indexOf("__cust_") > -1 && response.data.custom_fields_data) {
                    let custom_fields_data = response.data.custom_fields_data.find((i) => i.name === e)
                    if (!isblank(custom_fields_data)) {
                      returnValue = [...returnValue, custom_fields_data]
                    }
                  }
                })
              }
              return returnValue
            }

            if (flag === 1) {
              response.data.result = response.data.result.map((item) => {
                let updatedItem = { ...item }

                if (!isblank(viewType) && viewType === "0") {
                  updatedItem.DetailGrid = updatedItem.DetailGrid.map((e) => {
                    e = changeDetailV1(e)
                    return e
                  })
                } else {
                  updatedItem.items = updatedItem.items.map((e) => {
                    e = changeDetailV1(e)
                    return e
                  })
                }

                return updatedItem
              })
            } else {
              response.data.result = changeDetailV1(response.data.result)

              response.data.result.custom_fields_data = getCustomFields(response.data.result.DetailGrid[0])
            }

            if (other_type1 === "report") {
              response.data.result = response.data.result.filter((item) => item.DetailGrid.length > 0)
              var Result = []
              response.data.result.forEach((item) => {
                item.DetailGrid.forEach((e) => {
                  if (!isblank(e.billno_str)) {
                    e.bill_no = e.billno_str
                  }
                  if (!isblank(e.itemName)) {
                  }
                  if (viewType === "0") {
                    var penqty = e.tot_penqty
                    var qty = e.tot_qty

                    var penpcs = e.tot_pen_pcs
                    var pcs = e.tot_pcs

                    if (mode === "In") {
                      e.penqty = penqty
                      e.qty = qty
                      e.balance = qty - penqty

                      e.penpcs = penpcs
                      e.pcs = pcs
                      e.pcs_balance = pcs - penpcs
                    } else {
                      e.penqty = penqty
                      e.qty = qty - penqty
                      e.balance = qty

                      e.penpcs = penpcs
                      e.pcs = pcs - penpcs
                      e.pcs_balance = pcs
                    }

                    e.title = item.title
                    Result.push(e)
                  } else {
                    e.DetailGrid.forEach((it) => {
                      e.itemName = it.itemname
                      e.penqty = it.penqty
                      e.qty = it.qty
                      e.balance = it.qty - it.penqty

                      e.penpcs = it.penpcs
                      e.pcs = it.pcs
                      e.pcs_balance = it.pcs - it.penpcs

                      e.title = item.title
                      Result.push(e)
                    })
                  }
                })
              })
              var payload = {
                data: {
                  result: {
                    records: Result,
                  },
                  // filter: response?.data?.filter,
                  filter: !isblank(response) && !isblank(response.data) && response.data.filter,
                },
              }
              dispatch({
                type: REPORT_SERVICE_SUCCESS,
                payload: payload,
                setconfig: {},
              })
            } else {
              if (flag === 1) {
                dispatch({
                  type: LAST_PAGE_UPDATE,
                  lasttype: "kanban",
                  lastaction: "list",
                  lasttime: new Date().getTime(),
                  lastdata: response.data.result,
                })
              } else {
                onComplete(response.data.result)
              }
            }
          }
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}
const processTransferServiceSuccess = ({ dispatch, user, rowsPerPage, page, order, orderBy, search_text }) => {
  dispatch({
    type: PROCESS_TRANSFER_SERVICE_SUCCESS,
    payload: user,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
  })
}

const processTransferServiceFail = ({ dispatch, response, rowsPerPage, page, order, orderBy, search_text }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })

  dispatch({
    type: PROCESS_TRANSFER_SERVICE_FAIL,
    payload: response,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
  })
}
