export default {
  GST_Template_1: "1",
  GST_Template_2: "4",
  GST_Template_4: "6",
  GST_Template_5: "7",
  GST_Template_6: "8",
  GST_Template_7: "9",
  GST_Template_10: "10",
  Template_3: "11",
  GST_Template_12: "12",
  GST_Template_13: "13",
}
