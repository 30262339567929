import lodashFpMap from "lodash/fp/map"
import lodashOmit from "lodash/fp/omit"
import lodashMap from "lodash/map"
import lodashSum from "lodash/sum"
import { change } from "redux-form"
import { list_url } from "./apiURL"
import ErrorMessages from "./constantData/ErrorMessages"
import re from "./constantData/reportsEnum"
import { billNoWidth, dateWidth } from "./constantData/widths"
import colors from "./constantData/colors"
import generatePdfReport, { groupedpdf } from "./generatePdfDocForReport"
import { getFileValue } from "./gstFileFunctions"
import {
  DatetoDMY,
  DatetoYMD,
  amount_format,
  arraySum,
  arraySumTaxCondition,
  capitalizeFormat,
  changeSign,
  checkCondition,
  cloneObj,
  cloneObjV2,
  convertIntif,
  dayRangeAge,
  format_excel_field,
  getAllEstimateStatus,
  getAllNameType,
  getAllOrderStatus,
  getAllStatesOfcountry,
  getCheckBoxPostValue,
  getCheckBoxValue,
  getCustomFieldColumn,
  getCustomerSupplierType,
  getDaysCalcOption,
  getDaysCalcOptionInt,
  getDetailSummary,
  getDetailSummaryContact,
  getDropDownObj,
  getDropDownPostValue,
  getMultiDropDownPostValue,
  getMultidropdownfieldvalue,
  getNumberonly,
  getNumberonlyDecimal,
  getTCSCalcType,
  getagenttype,
  getallViewType,
  getcostcenterdropdown,
  getdropdownfieldvalue,
  getgstbusinesstypelist,
  getinterestdays,
  getinteresttype,
  getitemfiltertype,
  getitemwisetype,
  getmultiledgertype,
  getmultiledgerwisetype,
  getratetype,
  getreportformat,
  getreporttype,
  gettype,
  getyesno,
  isBlankCode,
  isEqual,
  isblank,
  makeExcelRequireCol,
  makeExcelRequiredData,
  makeExcelRequiredDataOnly,
  processInOut,
  replaceAllFn,
  replaceBRtoNewLine,
  setFiledDateToolTip,
  sortByDate,
  tds_tcs_missing_Type,
  todaydate,
  validateGSTIN,
} from "./utilityFunctions"
const ExcelJS = require("exceljs")

const getThresholdFromDate = (docDate) => {
  const thresholdDate = new Date("2024-10-01")
  const documentDate = new Date(docDate)
  if (documentDate < thresholdDate) {
    return 250000
  } else {
    return 250000
    // return 300000
  }
}

const getSetConfig = ({ reportid, formValues, company_url, dS, props, fileName, isMobileOnly, functions }) => {
  const { getAccountSitesetting, getquarter, yearlyReport, getConfigFile, getstartyear, getendyear } = functions
  const value = parseInt(reportid)
  const multigodown_mng = dS.multigodown_mng
  const pagination_size = dS.pagination_size
  const account_subgroup = dS.account_subgroup
  const report_format = dS.report_format
  const gst_report_range = dS.gst_report_range
  var user_data = props.user_data
  var setconfig = {}
  setconfig.company_url = company_url
  setconfig.report_id = reportid
  setconfig.to_date = DatetoYMD(formValues.ToDate)
  setconfig.from_date = DatetoYMD(formValues.FromDate)
  setconfig.search_text = ""
  setconfig.rowsPerPage = isblank(props.rowsPerPage) || convertIntif(props.rowsPerPage) === 0 ? pagination_size : props.rowsPerPage
  setconfig.page = isblank(props.page) || convertIntif(props.page) === 0 ? 0 : props.page
  setconfig.totalcount = props.totalcount

  if (!checkCondition(reportid, [re.BalanceSheet, re.P_LAccount, re.TradingWithP_LReport, re.TradingAccount])) {
    setconfig.url = list_url({ type: "getAllReport", props: { config: isMobileOnly ? getConfigFile() : "" } })
  }

  if (value === re.TrialBalance) {
    setconfig.to_date = formValues.TillDate
    setconfig.type = formValues.viewType === "0" ? "summary" : "detail"
    setconfig.code = getDropDownPostValue(formValues.accountgroupSelectedValue)
    setconfig.subgroup = ""
    if (account_subgroup === 1) {
      setconfig.subgroup = getDropDownPostValue(formValues.subGroupSelectedValue)
    }
    setconfig.viewType = formValues.viewType
    setconfig.fileName = fileName
  } else if (value === re.TradingAccount) {
    setconfig.fileName = fileName
    setconfig.url = list_url({ type: "getAllReportV5", props: { config: isMobileOnly ? getConfigFile() : "" } })
  } else if (value === re.SalesRegister) {
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.code = getDropDownPostValue(formValues.customerSelectedValue)
    setconfig.other_type = getDropDownPostValue(formValues.other_type)
  } else if (value === re.PaymentRegister) {
    setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    setconfig.group = getDropDownPostValue(formValues.accountgroupSelectedValue)
    setconfig.subgroup = ""
    if (account_subgroup === 1) {
      setconfig.subgroup = getDropDownPostValue(formValues.subGroupSelectedValue)
    }
  } else if (value === re.ReceiptRegister) {
    setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    setconfig.group = getDropDownPostValue(formValues.accountgroupSelectedValue)
    setconfig.subgroup = ""
    if (account_subgroup === 1) {
      setconfig.subgroup = getDropDownPostValue(formValues.subGroupSelectedValue)
    }
  } else if (value === re.P_LAccount) {
    setconfig.fileName = fileName
    setconfig.url = list_url({ type: "getAllReportV5", props: { config: isMobileOnly ? getConfigFile() : "" } })
  } else if (value === re.BalanceSheet) {
    setconfig.fileName = fileName
    setconfig.url = list_url({ type: "getAllReportV5", props: { config: isMobileOnly ? getConfigFile() : "" } })
  } else if (value === re.PurchaseRegister) {
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.code = getDropDownPostValue(formValues.supplierSelectedValue)
    setconfig.other_type = getDropDownPostValue(formValues.other_type)
  } else if (value === re.LedgerReport) {
    setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    if (report_format === 2) {
      setconfig.other_type = formValues.reportFormat
    }
  } else if (value === re.InterestReport) {
    setconfig.other_type = formValues.interesttypeSelectedValue
    setconfig.interest_per = formValues.interestRate
    setconfig.interestPayableRate = formValues.interestPayableRate
    setconfig.interest_day = formValues.interestdaysSelectedValue
    setconfig.include_op_bal = getCheckBoxPostValue(formValues.include_op_bal)
    setconfig.include_end_date = getCheckBoxPostValue(formValues.include_end_date)
    setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    if (report_format === 2) {
      setconfig.flag = formValues.reportFormat
    } else {
      setconfig.flag = "1"
    }
    setconfig.cr_days = formValues.cr_days
    setconfig.calculate_date = formValues.calculate_date
  } else if (value === re.MultiLedgerReport) {
    setconfig.type = formValues.viewTypeSelectedValue
    setconfig.crdr = formValues.crdrSelectedValue
    setconfig.other_type = formValues.multiOutstandingSelValue
    if (isMobileOnly) {
      setconfig.checkedItemsId = JSON.stringify(formValues.checkedItemsId.map((e) => e.code))
      setconfig.checkedItemsData = JSON.stringify(formValues.checkedItemsData.map((e) => e.code))
    } else {
      var checkedItemsData = ""
      var checkedItemsId = JSON.parse(formValues.checkedItemsId)
      checkedItemsId = checkedItemsId.map((a) => a.code)
      checkedItemsId = JSON.stringify(checkedItemsId)
      if (formValues.multiOutstandingSelValue !== "All") {
        checkedItemsData = JSON.parse(formValues.checkedItemsData)
        checkedItemsData = checkedItemsData.map((a) => a.code)
        checkedItemsData = JSON.stringify(checkedItemsData)
      }
      setconfig.checkedItemsId = checkedItemsId
      setconfig.checkedItemsData = checkedItemsData
    }
    setconfig.reportViewType = formValues.reportViewType
    setconfig.include_zero = formValues.includingZero === true ? "1" : "0"
    setconfig.flag = 1
    setconfig.fileName = fileName
  } else if (value === re.ProductWiseStockReport) {
    setconfig.code = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.isProcess = formValues.isProcess === true ? "1" : "0"
    setconfig.fileName = fileName
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getMultiDropDownPostValue(formValues.goDownSelectedValue)
    }
  } else if (value === re.ClosingReport) {
    setconfig.to_date = formValues.ToDate
    setconfig.itemcode = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.code = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.include_zero = formValues.includingZero === true ? "1" : "0"
    setconfig.include_amount = formValues.includingAmount === true ? "1" : "0"
    setconfig.isProcess = formValues.isProcess === true ? "1" : "0"
    setconfig.stockNegative = formValues.stockNegative === true ? "1" : "0"
    setconfig.rate_type = formValues.ratetypeSelectedValue
    setconfig.reportViewType = getDropDownPostValue(formValues.order_view_type)
    setconfig.fileName = fileName
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getMultiDropDownPostValue(formValues.goDownSelectedValue)
    }
  } else if (value === re.PartyWiseStockReport) {
    setconfig.itemcode = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.other_type = formValues.typeSelectedValue
    setconfig.code = getDropDownPostValue(formValues.customer_supplierSelectedValue)
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getMultiDropDownPostValue(formValues.goDownSelectedValue)
    }
  } else if (value === re.In_OutRegister) {
    setconfig.itemcode = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.other_type = formValues.typeSelectedValue
    setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    setconfig.fileName = fileName
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getMultiDropDownPostValue(formValues.goDownSelectedValue)
    }
  } else if (value === re.OutstandingReport) {
    setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    setconfig.crdr = formValues.crdrSelectedValue
    setconfig.reportViewType = formValues.reportViewType
    setconfig.calculate_date = formValues.calculate_date
    setconfig.url = list_url({ type: "getAllReportV5", props: { config: isMobileOnly ? getConfigFile() : "" } })
  } else if (value === re.OutstandingAgeingReport) {
    setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    setconfig.crdr = formValues.crdrSelectedValue
    setconfig.reportViewType = formValues.reportViewType
    setconfig.dayRange = formValues.dayRange
    setconfig.calculate_date = formValues.calculate_date
    setconfig.url = list_url({ type: "getAllReportV5", props: { config: isMobileOnly ? getConfigFile() : "" } })
  } else if (value === re.CreditLimitReport) {
    setconfig.to_date = formValues.TillDate
  } else if (value === re.ReorderLevelReport) {
    setconfig.to_date = formValues.TillDate
    setconfig.itemcode = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.code = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.include_zero = formValues.includingZero === true ? "1" : "0"
    setconfig.include_amount = formValues.includingAmount === true ? "1" : "0"
    setconfig.rate_type = formValues.ratetypeSelectedValue
    setconfig.fileName = fileName
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getMultiDropDownPostValue(formValues.goDownSelectedValue)
    }
  } else if (value === re.SalesReturnRegister) {
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.code = getDropDownPostValue(formValues.customer_supplierSelectedValue)
  } else if (value === re.PurchaseReturnRegister) {
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.code = getDropDownPostValue(formValues.customer_supplierSelectedValue)
  } else if (value === re.GSTR_1Summary) {
    if (yearlyReport) {
      setconfig.from_date = `${getstartyear()}-04-01`
      setconfig.to_date = `${getendyear()}-03-31`
    } else {
      setconfig.to_date = getDateRangeOfGSTReport({ formValues, getAccountSitesetting, getquarter }).to_date
      setconfig.from_date = getDateRangeOfGSTReport({ formValues, getAccountSitesetting, getquarter }).from_date
    }
    setconfig.period = getDateRangeOfGSTReport({ formValues, getAccountSitesetting, getquarter }).period
    if (setconfig.period === "all" || convertIntif(setconfig.period) === 3) {
      setconfig.other_type = "1"
    }
    setconfig.type = gst_report_range === 1 ? "quarterly" : "monthly"
    setconfig.flag = 1
    setconfig.report_id = "95"
    setconfig.type = "gstr1"
  } else if (value === re.GSTR_2Summary) {
    setconfig.to_date = getdropdownfieldvalue({ dropDownData: getquarter("M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "enddate" })
    setconfig.from_date = getdropdownfieldvalue({ dropDownData: getquarter("M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "startdate" })
    setconfig.type = "monthly"
    setconfig.other_type = formValues.gstr2_type
    setconfig.flag = 1
  } else if (value === re.GSTR_3BSummary) {
    setconfig.to_date = getdropdownfieldvalue({ dropDownData: getquarter(gst_report_range === 1 ? "Q" : "M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "enddate" })
    setconfig.from_date = getdropdownfieldvalue({
      dropDownData: getquarter(gst_report_range === 1 ? "Q" : "M", user_data),
      field: "id",
      value: formValues.quarterSelectedValue,
      displayvalue: "startdate",
    })
    setconfig.type = gst_report_range === 1 ? "quarterly" : "monthly"
    setconfig.flag = 1
    setconfig.report_id = "95"
    setconfig.type = "gstr3B"
  } else if (value === re.MultiOutstandingReport) {
    setconfig.type = formValues.viewTypeSelectedValue
    setconfig.crdr = formValues.crdrSelectedValue
    setconfig.other_type = formValues.multiOutstandingSelValue
    if (isMobileOnly) {
      setconfig.checkedItemsId = JSON.stringify(formValues.checkedItemsId.map((e) => e.code))
      setconfig.checkedItemsData = JSON.stringify(formValues.checkedItemsData.map((e) => e.code))
    } else {
      var checkedItemsData = ""
      var checkedItemsId = JSON.parse(formValues.checkedItemsId)
      checkedItemsId = checkedItemsId.map((a) => a.code)
      checkedItemsId = JSON.stringify(checkedItemsId)
      if (formValues.multiOutstandingSelValue !== "All") {
        checkedItemsData = JSON.parse(formValues.checkedItemsData)
        checkedItemsData = checkedItemsData.map((a) => a.code)
        checkedItemsData = JSON.stringify(checkedItemsData)
      }
      setconfig.checkedItemsId = checkedItemsId
      setconfig.checkedItemsData = checkedItemsData
    }
    setconfig.reportViewType = formValues.reportViewType
    setconfig.include_zero = formValues.includingZero === true ? "1" : "0"
    setconfig.include_amount = formValues.includingAmount === true ? "1" : "0"
    setconfig.flag = 1
    setconfig.fileName = fileName
    setconfig.calculate_date = formValues.calculate_date
    setconfig.url = list_url({ type: "getAllReportV5", props: { config: isMobileOnly ? getConfigFile() : "" } })
  } else if (value === re.AgentWiseSalesReport) {
    setconfig.agent_type = formValues.agentTypeSelectedValue === "1" ? "partywise" : ""
    setconfig.party_code = getDropDownPostValue(formValues.agentSelectedValue)
    setconfig.other_type = formValues.agentTypeSelectedValue
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.agentcomm_type = formValues.agentCommissionSelValue
    setconfig.flag = getDropDownPostValue(formValues.docTypeSelectedValue)
  } else if (value === re.SalesRegisterwithPaymenttype) {
    setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    setconfig.mastercode = getDropDownPostValue(formValues.bankSelectedValue)
  } else if (value === re.ItemWiseSalesReport) {
    setconfig.code = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.other_type = formValues.itemTypeSelectedValue
    setconfig.viewType = formValues.itemTypeSelectedValue === "1" ? "item_wise" : formValues.itemTypeSelectedValue === "2" ? "item_group_wise" : "item_group_item_wise"
    setconfig.flag = 1
    setconfig.type = formValues.type
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getMultiDropDownPostValue(formValues.goDownSelectedValue)
    }
  } else if (value === re.ItemWisePurchaseReport) {
    setconfig.code = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.other_type = formValues.itemTypeSelectedValue
    setconfig.viewType = formValues.itemTypeSelectedValue === "1" ? "item_wise" : formValues.itemTypeSelectedValue === "2" ? "item_group_wise" : "item_group_item_wise"
    setconfig.flag = 1
    setconfig.type = formValues.type
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getMultiDropDownPostValue(formValues.goDownSelectedValue)
    }
  } else if (value === re.TransportWiseSalesReport) {
    setconfig.party_code = getDropDownPostValue(formValues.transportSelectedValue)
    setconfig.other_type = formValues.transportTypeSelectedValue
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.flag = 1
  } else if (value === re.PartyPriceMasterReport) {
    setconfig.party_code = getDropDownPostValue(formValues.partySelectedValue)
    setconfig.itemcode = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.itemgroupcode = getDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.flag = 1
  } else if (value === re.PartyGroupPriceReport) {
    setconfig.party_code = getDropDownPostValue(formValues.partyGroupSelectedValue)
    setconfig.itemcode = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.itemgroupcode = getDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.flag = 1
  } else if (value === re.InvoiceMissinginGST) {
    setconfig.flag = 1
  } else if (value === re.JournalEntryRegister) {
    setconfig.flag = 1
  } else if (value === re.CostCenter) {
    setconfig.party_code = getDropDownPostValue(formValues.partySelectedValue)
    setconfig.other_type = getDropDownPostValue(formValues.costcenterSelectedValue)
    setconfig.flag = 1
  } else if (value === re.ProductBatchDetailReport) {
    setconfig.itemcode = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.other_type = formValues.filterTypeSelectedValue
    setconfig.include_zero = formValues.emptyBatches === true ? "1" : "0"
    setconfig.code = getMultiDropDownPostValue(formValues.batchSelectedValue)
    setconfig.reportViewType = formValues.productSerialTypeSelectedValue
    setconfig.goDownSelectedValue = getMultiDropDownPostValue(formValues.goDownSelectedValue)
    setconfig.flag = 1
  } else if (value === re.P_LAccountCostCenterWise) {
    setconfig.code = getDropDownPostValue(formValues.costcenterSelectedValue)
    setconfig.flag = 1
  } else if (value === re.ProductSerialNumberReport) {
    setconfig.itemcode = getDropDownPostValue(formValues.itemSelectedValue)
    setconfig.other_type = formValues.productSerialTypeSelectedValue
    setconfig.include_zero = formValues.showAvailable === true ? "1" : "0"
    setconfig.code = getMultiDropDownPostValue(formValues.serialSelectedValue)
    setconfig.goDownSelectedValue = getMultiDropDownPostValue(formValues.goDownSelectedValue)
    setconfig.flag = 1
  } else if (value === re.ProductExpiredReport) {
    setconfig.code = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.to_date = DatetoYMD(formValues.TillDate)
    setconfig.flag = 1
    delete setconfig.from_date
  } else if (value === re.ProductExpiryReport) {
    setconfig.code = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.flag = 1
  } else if (value === re.GSTSummary) {
    setconfig.report_id = "95"
    setconfig.type = "all"
    setconfig.reportViewType = "1"
    if (yearlyReport) {
      setconfig.from_date = `${getstartyear()}-04-01`
      setconfig.to_date = `${getendyear()}-03-31`
    } else {
      setconfig.to_date = getdropdownfieldvalue({ dropDownData: getquarter("M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "enddate" })
      setconfig.from_date = getdropdownfieldvalue({ dropDownData: getquarter("M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "startdate" })
    }
  } else if (value === re.TdsRegister) {
    setconfig.sort_by = "id"
    setconfig.sort = "desc"
    setconfig.flag = 1
    setconfig.party_code = 0
    setconfig.type = "0"
    setconfig.other_type = formValues.other_type
  } else if (value === re.ItemGroupSummaryReport) {
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.to_date = formValues.TillDate
  } else if (value === re.ItemSubGroupSummaryReport) {
    setconfig.code = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.to_date = formValues.TillDate
  } else if (value === re.RecordExpense) {
    setconfig.code = getDropDownPostValue(formValues.customer_supplierSelectedValue)
  } else if (value === re.Estimate) {
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.code = getDropDownPostValue(formValues.customerSelectedValue)
    setconfig.status = getDropDownObj(formValues.status, "name") === "All" || isblank(formValues.status) ? "" : getDropDownObj(formValues.status, "name")
  } else if (value === re.SalesOrder) {
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.code = getDropDownPostValue(formValues.customerSelectedValue)
    setconfig.other_type = "sales_order"
    setconfig.assignUser = ""
    if (!isblank(formValues) && !isblank(formValues.assignUser) && formValues.assignUser.length > 0) {
      setconfig.assignUser = formValues.assignUser
    }
    if (props.dd_orderTypes.length === 1) {
      setconfig.other_type = props.dd_orderTypes[0].id
    } else if (isblank(setconfig.other_type)) {
      // setconfig.other_type = "all"
      setconfig.other_type = "sales_order"
    }
    setconfig.status = getDropDownObj(formValues.status, "name") === "All" || isblank(formValues.status) ? "" : getDropDownObj(formValues.status, "name")
  } else if (value === re.PurchaseOrder) {
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.code = getDropDownPostValue(formValues.supplierSelectedValue)
    setconfig.status = getDropDownObj(formValues.status, "name") === "All" || isblank(formValues.status) ? "" : getDropDownObj(formValues.status, "name")
    setconfig.other_type = "purchase_order"
  } else if (value === re.MultiOutstandingAgeingReport) {
    setconfig.type = formValues.viewTypeSelectedValue
    setconfig.crdr = formValues.crdrSelectedValue
    setconfig.other_type = formValues.multiOutstandingSelValue
    if (isMobileOnly) {
      setconfig.checkedItemsId = JSON.stringify(formValues.checkedItemsId.map((e) => e.code))
      setconfig.checkedItemsData = JSON.stringify(formValues.checkedItemsData.map((e) => e.code))
    } else {
      var checkedItemsData = ""
      var checkedItemsId = JSON.parse(formValues.checkedItemsId)
      checkedItemsId = checkedItemsId.map((a) => a.code)
      checkedItemsId = JSON.stringify(checkedItemsId)
      if (formValues.multiOutstandingSelValue !== "All") {
        checkedItemsData = JSON.parse(formValues.checkedItemsData)
        checkedItemsData = checkedItemsData.map((a) => a.code)
        checkedItemsData = JSON.stringify(checkedItemsData)
      }
      setconfig.checkedItemsId = checkedItemsId
      setconfig.checkedItemsData = checkedItemsData
    }
    setconfig.reportViewType = formValues.reportViewType
    setconfig.include_zero = formValues.includingZero === true ? "1" : "0"
    setconfig.flag = 1
    setconfig.dayRange = formValues.dayRange
    setconfig.fileName = fileName
    setconfig.calculate_date = formValues.calculate_date
    setconfig.url = list_url({ type: "getAllReportV5", props: { config: isMobileOnly ? getConfigFile() : "" } })
  } else if (value === re.DaybookReport) {
    setconfig.to_date = formValues.selectedDate
    setconfig.from_date = formValues.selectedDate
  } else if (value === re.LotDetailReport) {
    setconfig.code = getDropDownPostValue(formValues.lotSelectedValue)
  } else if (value === re.ItemSubGroupLevel2SummaryReport) {
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.code = getDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.to_date = formValues.TillDate
  } else if (value === re.TcsRegister) {
    setconfig.sort_by = "id"
    setconfig.sort = "desc"
    setconfig.flag = 1
    setconfig.party_code = 0
    setconfig.type = "0"
    setconfig.other_type = formValues.other_type
  } else if (value === re.ProcessLongation_ShortageReport) {
    setconfig.code = getDropDownPostValue(formValues.lotSelectedValue)
  } else if (value === re.LotCostingReport) {
    setconfig.code = getDropDownPostValue(formValues.lotSelectedValue)
  } else if (value === re.BankReconcileReport) {
    setconfig.code = getDropDownPostValue(formValues.bankSelectedValue)
  } else if (value === re.PackingSlipRegister) {
    setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.other_type = "packing_slip"
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getDropDownPostValue(formValues.goDownSelectedValue)
    }
    setconfig.fileName = fileName
    setconfig.status = formValues.status
    if (isblank(setconfig.status) || setconfig.status === "all") {
      setconfig.status = ""
    }
  } else if (value === re.JobInwardRegister) {
    setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.other_type = "job_inward"
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getDropDownPostValue(formValues.goDownSelectedValue)
    }
    setconfig.fileName = fileName
    setconfig.status = formValues.status
    if (isblank(setconfig.status) || setconfig.status === "all") {
      setconfig.status = ""
    }
  } else if (value === re.FinishStockRegister) {
    setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.other_type = "finish_stock"
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getDropDownPostValue(formValues.goDownSelectedValue)
    }
    setconfig.fileName = fileName
    setconfig.status = formValues.status
    if (isblank(setconfig.status) || setconfig.status === "all") {
      setconfig.status = ""
    }
  } else if (value === re.ManageLotRegister) {
    setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.other_type = "manage_lot_master"
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getDropDownPostValue(formValues.goDownSelectedValue)
    }
    setconfig.fileName = fileName
    setconfig.status = formValues.status
    if (isblank(setconfig.status) || setconfig.status === "all") {
      setconfig.status = ""
    }
  } else if (value === re.JobInwardsWithoutJobInvoice) {
    setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.other_type = ""
    setconfig.status = formValues.status
    if (isblank(setconfig.status) || setconfig.status === "all") {
      setconfig.status = ""
    }
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getDropDownPostValue(formValues.goDownSelectedValue)
    }
    setconfig.fileName = fileName
  } else if (value === re.Monthlypaymentforquarterlyreturn) {
    setconfig.to_date = getdropdownfieldvalue({ dropDownData: getquarter("M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "enddate" })
    setconfig.from_date = getdropdownfieldvalue({ dropDownData: getquarter("M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "startdate" })
    setconfig.type = "monthly"
    setconfig.other_type = formValues.gstr2_type
    setconfig.flag = 1
  } else if (value === re.LiveStockReport) {
    setconfig.other_type = formValues.order_view_type
    setconfig.party_code = getDropDownPostValue(formValues.customer_supplierSelectedValue)
    setconfig.type = getCheckBoxPostValue(formValues.include_orders)
    setconfig.include_zero = getCheckBoxPostValue(formValues.includingZero)
    setconfig.include_pen_orders = formValues.include_pen_orders === true ? "1" : "0"
    setconfig.code = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.isProcess = formValues.isProcess === true ? "1" : "0"
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getMultiDropDownPostValue(formValues.goDownSelectedValue)
    }
  } else if (value === re.MissingBillNumber) {
    setconfig.type = formValues.type
  } else if (value === re.MissingTDSTCSNumber) {
    setconfig.to_date = getdropdownfieldvalue({ dropDownData: getquarter("M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "enddate" })
    setconfig.from_date = getdropdownfieldvalue({ dropDownData: getquarter("M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "startdate" })
    setconfig.party_code = getMultiDropDownPostValue(formValues.customer_supplierSelectedValue)
    setconfig.other_type = formValues.tds_tcs_type
    setconfig.type = formValues.other_type
  } else if (value === re.GoodsReceivedNoteRegister) {
    setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    setconfig.other_type = "goods_received_note"
    setconfig.reportViewType = getDropDownPostValue(formValues.order_view_type)
    setconfig.status = formValues.status
    if (isblank(setconfig.status) || setconfig.status === "all") {
      setconfig.status = ""
    }
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getDropDownPostValue(formValues.goDownSelectedValue)
    }
    setconfig.fileName = fileName
  } else if (value === re.DeliveryChallanRegister) {
    setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    setconfig.other_type = "delivery_challan"
    setconfig.reportViewType = getDropDownPostValue(formValues.order_view_type)
    setconfig.status = formValues.status
    if (isblank(setconfig.status) || setconfig.status === "all") {
      setconfig.status = ""
    }
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getDropDownPostValue(formValues.goDownSelectedValue)
    }
    setconfig.fileName = fileName
  } else if (value === re.StoneAnalysis) {
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.status = getMultiDropDownPostValue(formValues.__cust_stone_status)
    setconfig.location = getMultiDropDownPostValue(formValues.__cust_stone_location)
    setconfig.shapeid = getMultiDropDownPostValue(formValues.__cust_shape)
    setconfig.cityid = getMultiDropDownPostValue(formValues.__cust_stone_city)
    setconfig.other_type = getMultiDropDownPostValue(formValues.__cust_size_ranges)
    setconfig.all_non_sold = formValues.all_non_sold === true ? "1" : "0"
  } else if (value === re.StoneCostAnalysis) {
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.status = getMultiDropDownPostValue(formValues.__cust_stone_status)
    setconfig.location = getMultiDropDownPostValue(formValues.__cust_stone_location)
    setconfig.shapeid = getMultiDropDownPostValue(formValues.__cust_shape)
    setconfig.cityid = getMultiDropDownPostValue(formValues.__cust_stone_city)
    setconfig.other_type = getMultiDropDownPostValue(formValues.__cust_size_ranges)
    setconfig.all_non_sold = formValues.all_non_sold === true ? "1" : "0"
    setconfig.include_amount = formValues.include_amount
  } else if (value === re.StoneHistoryReport) {
    setconfig.code = getMultiDropDownPostValue(formValues.itemSelectedValue)
  } else if (value === re.ProcessHistoryReport) {
    setconfig.party_code = getDropDownPostValue(formValues.customerSelectedValue)
    setconfig.itemcode = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.group = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.code = getDropDownPostValue(formValues.processSelectedValue)
    setconfig.lot_number = getDropDownPostValue(formValues.lotSelectedValue)
  } else if (value === re.FinishStockInOutReport) {
    setconfig.itemcode = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.other_type = formValues.typeSelectedValue
    setconfig.code = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.isProcess = formValues.isProcess === true ? "1" : "0"
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getMultiDropDownPostValue(formValues.goDownSelectedValue)
    }
    setconfig.fileName = fileName
    setconfig.reportViewType = getDropDownPostValue(formValues.order_view_type)
    setconfig.from_weight = formValues.from_weight
    setconfig.to_weight = formValues.to_weight
    setconfig.show_bundle = formValues.show_bundle
    setconfig.show_opening = formValues.show_opening
  } else if (value === re.FinishStockBarcodeReport) {
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.barcode = formValues.barcode
  } else if (value === re.PurchaseLotReport) {
    setconfig.party_code = getDropDownPostValue(formValues.supplierSelectedValue)
    setconfig.code = getDropDownPostValue(formValues.billSelectedValue)
  } else if (value === re.FinishStockReport && formValues.order_view_type === "summary") {
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.itemcode = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.to_date = formValues.TillDate
    if (multigodown_mng === 1) {
      setconfig.goDownSelectedValue = getMultiDropDownPostValue(formValues.goDownSelectedValue)
    }
    setconfig.from_weight = formValues.from_weight
    setconfig.to_weight = formValues.to_weight
  } else if (value === re.JobDeliveryChallanRegister) {
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.code = getDropDownPostValue(formValues.customerSelectedValue)
  } else if (value === re.TradingWithP_LReport) {
    setconfig.fileName = fileName
    setconfig.url = list_url({ type: "getAllReportV5", props: { config: isMobileOnly ? functions.getConfigFile() : "" } })
  }

  return setconfig
}

const getDependencyStringForReports = ({ reportid, dS }) => {
  const value = parseInt(reportid)

  const invoice_cus_sup = dS.invoice_cus_sup
  const account_subgroup = dS.account_subgroup
  const item_group = dS.item_group
  const item_category = dS.item_category
  const item_subgroupLevel2 = dS.item_subgroupLevel2
  const multigodown_mng = dS.multigodown_mng

  const customer_dropdown_string = "customer"
  const supplier_dropdown_string = "supplier"
  const accountgroup_dropdown_string = "accountgroup"
  const customer_supplier_dropdown_string = "customer_supplier"
  const party_dropdown_string = "allaccount"
  const item_dropdown_string = "item"
  const agent_dropdown_string = "agent"
  const bank_dropdown_string = "bankaccount,customer_supplier"
  const productGroup_party_dropdown_string = "itemgroup,customer_supplier"
  const productGroup_partyGroup_dropdown_string = "partygroup,itemgroup"
  const transport_dropdown_string = "transport"
  const costcenter_dropdown_string = "dropdown_costcenter"
  const multiLedger_dropdown_string = "city,accountgroup,customer_supplier,allaccount,agent,partygroup"
  const party_costcenter_dropdown_string = "dropdown_costcenter,allaccount"
  const gstr_dropdown_string = "customer_supplier,item,dd_taxcode,allaccount,taxscheme,taxgroup"

  let dropdown_string = ""

  if (value === re.TrialBalance) {
    dropdown_string = accountgroup_dropdown_string
    if (account_subgroup === 1) {
      dropdown_string = dropdown_string + ",subgroup"
    }
  } else if (value === re.SalesRegister) {
    if (invoice_cus_sup === 0) {
      dropdown_string = customer_supplier_dropdown_string
    } else {
      dropdown_string = customer_dropdown_string
    }
  } else if (value === re.PaymentRegister) {
    dropdown_string = party_dropdown_string + ",accountgroup"
    if (account_subgroup === 1) {
      dropdown_string = dropdown_string + ",subgroup"
    }
  } else if (value === re.ReceiptRegister) {
    dropdown_string = party_dropdown_string + ",accountgroup"
    if (account_subgroup === 1) {
      dropdown_string = dropdown_string + ",subgroup"
    }
  } else if (value === re.PurchaseRegister) {
    if (invoice_cus_sup === 0) {
      dropdown_string = customer_supplier_dropdown_string
    } else {
      dropdown_string = supplier_dropdown_string
    }
  } else if (value === re.LedgerReport) {
    dropdown_string = party_dropdown_string
  } else if (value === re.InterestReport) {
    dropdown_string = party_dropdown_string
  } else if (value === re.MultiLedgerReport) {
    dropdown_string = multiLedger_dropdown_string
  } else if (value === re.ProductWiseStockReport) {
    dropdown_string = item_dropdown_string
    if (item_group === 1) {
      dropdown_string = dropdown_string + ",itemgroup"
      if (item_category === 1) {
        dropdown_string = dropdown_string + ",productsubgroup"
        if (item_subgroupLevel2 === 1) {
          dropdown_string = dropdown_string + ",productsubgrouplevel2"
        }
      }
    }
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.ClosingReport) {
    dropdown_string = item_dropdown_string
    if (item_group === 1) {
      dropdown_string = dropdown_string + ",itemgroup"
      if (item_category === 1) {
        dropdown_string = dropdown_string + ",productsubgroup"
        if (item_subgroupLevel2 === 1) {
          dropdown_string = dropdown_string + ",productsubgrouplevel2"
        }
      }
    }
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.PartyWiseStockReport) {
    dropdown_string = item_dropdown_string + ",customer_supplier"
    if (item_group === 1) {
      dropdown_string = dropdown_string + ",itemgroup"
      if (item_category === 1) {
        dropdown_string = dropdown_string + ",productsubgroup"
        if (item_subgroupLevel2 === 1) {
          dropdown_string = dropdown_string + ",productsubgrouplevel2"
        }
      }
    }
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.In_OutRegister) {
    dropdown_string = item_dropdown_string + ",customer_supplier"
    if (item_group === 1) {
      dropdown_string = dropdown_string + ",itemgroup"
      if (item_category === 1) {
        dropdown_string = dropdown_string + ",productsubgroup"
        if (item_subgroupLevel2 === 1) {
          dropdown_string = dropdown_string + ",productsubgrouplevel2"
        }
      }
    }
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.OutstandingReport) {
    dropdown_string = customer_supplier_dropdown_string + ",allaccount"
  } else if (value === re.OutstandingAgeingReport) {
    dropdown_string = customer_supplier_dropdown_string + ",allaccount"
  } else if (value === re.ReorderLevelReport) {
    dropdown_string = item_dropdown_string
    if (item_group === 1) {
      dropdown_string = dropdown_string + ",itemgroup"
      if (item_category === 1) {
        dropdown_string = dropdown_string + ",productsubgroup"
        if (item_subgroupLevel2 === 1) {
          dropdown_string = dropdown_string + ",productsubgrouplevel2"
        }
      }
    }
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.SalesReturnRegister) {
    dropdown_string = customer_supplier_dropdown_string
  } else if (value === re.PurchaseReturnRegister) {
    dropdown_string = customer_supplier_dropdown_string
  } else if (value === re.GSTR_1Summary) {
    dropdown_string = gstr_dropdown_string
  } else if (value === re.GSTR_2Summary) {
    dropdown_string = gstr_dropdown_string
  } else if (value === re.GSTR_3BSummary) {
    dropdown_string = gstr_dropdown_string
  } else if (value === re.MultiOutstandingReport) {
    dropdown_string = multiLedger_dropdown_string
  } else if (value === re.AgentWiseSalesReport) {
    dropdown_string = agent_dropdown_string
  } else if (value === re.SalesRegisterwithPaymenttype) {
    dropdown_string = bank_dropdown_string
  } else if (value === re.ItemWiseSalesReport) {
    dropdown_string = item_dropdown_string
    if (item_group === 1) {
      dropdown_string = dropdown_string + ",itemgroup"
      if (item_category === 1) {
        dropdown_string = dropdown_string + ",productsubgroup"
        if (item_subgroupLevel2 === 1) {
          dropdown_string = dropdown_string + ",productsubgrouplevel2"
        }
      }
    }
  } else if (value === re.ItemWisePurchaseReport) {
    dropdown_string = item_dropdown_string
    if (item_group === 1) {
      dropdown_string = dropdown_string + ",itemgroup"
      if (item_category === 1) {
        dropdown_string = dropdown_string + ",productsubgroup"
        if (item_subgroupLevel2 === 1) {
          dropdown_string = dropdown_string + ",productsubgrouplevel2"
        }
      }
    }
  } else if (value === re.TransportWiseSalesReport) {
    dropdown_string = transport_dropdown_string
  } else if (value === re.PartyPriceMasterReport) {
    dropdown_string = productGroup_party_dropdown_string + ",item"
    if (item_category === 1) {
      dropdown_string = dropdown_string + ",productsubgroup"
      if (item_subgroupLevel2 === 1) {
        dropdown_string = dropdown_string + ",productsubgrouplevel2"
      }
    }
  } else if (value === re.PartyGroupPriceReport) {
    dropdown_string = productGroup_partyGroup_dropdown_string + ",item"
    if (item_category === 1) {
      dropdown_string = dropdown_string + ",productsubgroup"
      if (item_subgroupLevel2 === 1) {
        dropdown_string = dropdown_string + ",productsubgrouplevel2"
      }
    }
  } else if (value === re.CostCenter) {
    dropdown_string = party_costcenter_dropdown_string
  } else if (value === re.ProductBatchDetailReport) {
    dropdown_string = item_dropdown_string
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.P_LAccountCostCenterWise) {
    dropdown_string = costcenter_dropdown_string
  } else if (value === re.ProductSerialNumberReport) {
    dropdown_string = item_dropdown_string
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.ProductExpiredReport) {
    dropdown_string = item_dropdown_string
    if (item_group === 1) {
      dropdown_string = dropdown_string + ",itemgroup"
      if (item_category === 1) {
        dropdown_string = dropdown_string + ",productsubgroup"
        if (item_subgroupLevel2 === 1) {
          dropdown_string = dropdown_string + ",productsubgrouplevel2"
        }
      }
    }
  } else if (value === re.ProductExpiryReport) {
    dropdown_string = item_dropdown_string
    if (item_group === 1) {
      dropdown_string = dropdown_string + ",itemgroup"
      if (item_category === 1) {
        dropdown_string = dropdown_string + ",productsubgroup"
        if (item_subgroupLevel2 === 1) {
          dropdown_string = dropdown_string + ",productsubgrouplevel2"
        }
      }
    }
  } else if (value === re.GSTSummary) {
    dropdown_string = gstr_dropdown_string
  } else if (value === re.ItemGroupSummaryReport) {
    dropdown_string = "itemgroup"
  } else if (value === re.ItemSubGroupSummaryReport) {
    dropdown_string = "productsubgroup,itemgroup"
  } else if (value === re.RecordExpense) {
    dropdown_string = customer_supplier_dropdown_string
  } else if (value === re.Estimate) {
    if (invoice_cus_sup === 0) {
      dropdown_string = customer_supplier_dropdown_string
    } else {
      dropdown_string = customer_dropdown_string
    }
  } else if (value === re.SalesOrder) {
    if (invoice_cus_sup === 0) {
      dropdown_string = customer_supplier_dropdown_string
    } else {
      dropdown_string = customer_dropdown_string
    }
    dropdown_string = dropdown_string + ",user"
  } else if (value === re.PurchaseOrder) {
    if (invoice_cus_sup === 0) {
      dropdown_string = customer_supplier_dropdown_string
    } else {
      dropdown_string = supplier_dropdown_string
    }
  } else if (value === re.MultiOutstandingAgeingReport) {
    dropdown_string = multiLedger_dropdown_string
  } else if (value === re.LotDetailReport) {
    dropdown_string = "ddlot"
  } else if (value === re.ItemSubGroupLevel2SummaryReport) {
    dropdown_string = "productsubgroup,itemgroup,productsubgrouplevel2"
  } else if (value === re.ProcessStockInOutReport) {
    dropdown_string = customer_supplier_dropdown_string + ",ddlot,item,ddprocess"
  } else if (value === re.ProcessLongation_ShortageReport) {
    dropdown_string = "ddlot"
  } else if (value === re.LotCostingReport) {
    dropdown_string = "ddlot"
  } else if (value === re.BankReconcileReport) {
    dropdown_string = "bankaccount"
  } else if (value === re.PackingSlipRegister) {
    if (invoice_cus_sup === 0) {
      dropdown_string = customer_supplier_dropdown_string
    } else {
      dropdown_string = customer_dropdown_string
    }
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.JobInwardRegister) {
    if (invoice_cus_sup === 0) {
      dropdown_string = customer_supplier_dropdown_string
    } else {
      dropdown_string = customer_dropdown_string
    }
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.FinishStockRegister) {
    if (invoice_cus_sup === 0) {
      dropdown_string = customer_supplier_dropdown_string
    } else {
      dropdown_string = customer_dropdown_string
    }
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.ManageLotRegister) {
    if (invoice_cus_sup === 0) {
      dropdown_string = customer_supplier_dropdown_string
    } else {
      dropdown_string = customer_dropdown_string
    }
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.JobInwardsWithoutJobInvoice) {
    if (invoice_cus_sup === 0) {
      dropdown_string = customer_supplier_dropdown_string
    } else {
      dropdown_string = customer_dropdown_string
    }
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.LiveStockReport) {
    dropdown_string = item_dropdown_string + ",customer_supplier"
    if (item_group === 1) {
      dropdown_string = dropdown_string + ",itemgroup"
      if (item_category === 1) {
        dropdown_string = dropdown_string + ",productsubgroup"
        if (item_subgroupLevel2 === 1) {
          dropdown_string = dropdown_string + ",productsubgrouplevel2"
        }
      }
    }
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.MissingTDSTCSNumber) {
    dropdown_string = customer_supplier_dropdown_string + ",tcsaccount"
  } else if (value === re.GoodsReceivedNoteRegister) {
    if (invoice_cus_sup === 0) {
      dropdown_string = customer_supplier_dropdown_string
    } else {
      dropdown_string = customer_dropdown_string
    }
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.DeliveryChallanRegister) {
    if (invoice_cus_sup === 0) {
      dropdown_string = customer_supplier_dropdown_string
    } else {
      dropdown_string = customer_dropdown_string
    }
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.StoneAnalysis) {
    dropdown_string = "itemgroup,customField"
  } else if (value === re.StoneCostAnalysis) {
    dropdown_string = "itemgroup,customField"
  } else if (value === re.StoneHistoryReport) {
    dropdown_string = item_dropdown_string
  } else if (value === re.ProcessHistoryReport) {
    dropdown_string = item_dropdown_string + ",customer_supplier,ddprocess,ddlot"
    if (item_group === 1) {
      dropdown_string = dropdown_string + ",itemgroup"
      if (item_category === 1) {
        dropdown_string = dropdown_string + ",productsubgroup"
        if (item_subgroupLevel2 === 1) {
          dropdown_string = dropdown_string + ",productsubgrouplevel2"
        }
      }
    }
  } else if (value === re.FinishStockInOutReport) {
    dropdown_string = item_dropdown_string
    if (item_group === 1) {
      dropdown_string = dropdown_string + ",itemgroup"
      if (item_category === 1) {
        dropdown_string = dropdown_string + ",productsubgroup"
        if (item_subgroupLevel2 === 1) {
          dropdown_string = dropdown_string + ",productsubgrouplevel2"
        }
      }
    }
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.PurchaseLotReport) {
    dropdown_string = customer_supplier_dropdown_string
  } else if (value === re.FinishStockReport) {
    dropdown_string = item_dropdown_string
    if (item_group === 1) {
      dropdown_string = dropdown_string + ",itemgroup"
      if (item_category === 1) {
        dropdown_string = dropdown_string + ",productsubgroup"
        if (item_subgroupLevel2 === 1) {
          dropdown_string = dropdown_string + ",productsubgrouplevel2"
        }
      }
    }
    if (multigodown_mng === 1) {
      dropdown_string = dropdown_string + ",ddgodown"
    }
  } else if (value === re.JobDeliveryChallanRegister) {
    if (invoice_cus_sup === 0) {
      dropdown_string = customer_supplier_dropdown_string
    } else {
      dropdown_string = customer_dropdown_string
    }
  }

  return dropdown_string
}

export const OutstandingReportCol = [
  { id: "DocNo", numeric: false, visible: true, label: "Doc No", width: billNoWidth, showlink: true },
  {
    id: "DocDate",
    numeric: false,
    visible: true,
    label: "Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  { id: "Description", numeric: false, visible: true, canGroup_By: true, label: "Description", showlink: true },
  { id: "Narration", numeric: false, visible: true, label: "Narration", format: "removeBR" },
  {
    id: "due_date",
    numeric: false,
    visible: true,
    label: "Due Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  { id: "dayDiff", numeric: false, canGroup_By: true, visible: true, label: "Overdue By Days", width: 100 },
  {
    id: "displayType",
    numeric: false,
    total: "label",
    canGroup_By: true,
    visible: true,
    difference: "Net Ost",
    label: "Type",
    width: 85,
  },
  {
    id: "Amount",
    numeric: true,
    total: true,
    visible: true,
    disableResizing: true,
    label: `Amount`,
    format: "indian_rupee",
    currencySign: true,
    width: 175,
  },
  {
    id: "Outstanding",
    numeric: true,
    total: true,
    visible: true,
    disableResizing: true,
    difference: true,
    label: `Outstanding`,
    format: "indian_rupee",
    currencySign: true,
    width: 175,
  },
]

export const OutstandingAgeingReportCol = [
  { id: "DocNo", numeric: false, visible: true, label: "Doc No", width: billNoWidth, showlink: true },
  {
    id: "DocDate",
    numeric: false,
    difference: "Net Ost",
    visible: true,
    total: "label",
    label: "Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  {
    id: "due_date",
    numeric: false,
    visible: true,
    label: "Due Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  {
    id: "30Days",
    numeric: true,
    visible: true,
    total: true,
    label: "0-30 Days",
    width: 150,
    disableResizing: true,
    format: "indian_rupee",
  },
  {
    id: "60Days",
    numeric: true,
    visible: true,
    total: true,
    label: "31-60 Days",
    width: 150,
    disableResizing: true,
    format: "indian_rupee",
  },
  {
    id: "90Days",
    numeric: true,
    visible: true,
    total: true,
    label: "61-90 Days",
    width: 150,
    disableResizing: true,
    format: "indian_rupee",
  },
  {
    id: "120Days",
    numeric: true,
    visible: true,
    total: true,
    label: "> 90 Days",
    width: 150,
    disableResizing: true,
    format: "indian_rupee",
  },
  {
    id: "NetAmount",
    numeric: true,
    difference: true,
    visible: true,
    total: true,
    label: `Total Amount`,
    disableResizing: true,
    currencySign: true,
    width: 175,
    format: "indian_rupee",
  },
]

export const FinishStockReportDetailColumn = [
  { id: "billno_str", numeric: false, visible: true, label: "Doc No", width: 125, canGroup_By: true },
  {
    id: "bill_date",
    numeric: false,
    visible: true,
    label: "Date",
    width: 125,
    date: true,
    format: "dmy",
  },
  {
    id: "partyname",
    numeric: false,
    visible: true,
    label: "Account",
    width: 200,
    canGroup_By: true,
    showlink: true,
    removelinkonly: { valueblank: ["partycode"] }
  },

  {
    id: "itemname",
    numeric: false,
    visible: true,
    label: "Description of Goods",
    minWidth: 200,
    showlink: true,
    removelinkonly: { valueblank: ["itemcode"] }
  },
  //addCustomFieldColumn(columnDataFinishStock, "1", "addPendingPcs")
  {
    id: "qty",
    numeric: true,
    visible: true,
    label: "Quantity",
    width: 125,
    format: "qty_decimal",
  },
  {
    id: "penqty",
    numeric: true,
    visible: true,
    label: "Pen Qty",
    width: 125,
    format: "qty_decimal",
  },
]
export const reportvalidation = ({ values, props, functions, isMobileOnly }) => {
  const { isValidFinancialDate, isValidLedgerFromDate, getAccountSitesetting } = functions

  const errors = {}
  var user_data = props.user_data
  const gst_report_range = parseInt(getAccountSitesetting("gst_report_range", user_data))
  if (props.reportdata !== undefined) {
    /*if (isMobileOnly) {
      values.FromDate = cloneObjV2(values.invoicedate)
      values.selectedDate = cloneObjV2(values.invoicedate)
      values.ToDate = cloneObjV2(values.invoicedate2)
    }*/
    var value = props.reportdata.reportid
    if (checkCondition(value, [re.LedgerReport, re.OutstandingAgeingReport, re.OutstandingReport, re.PartyPriceMasterReport, re.InterestReport])) {
      if (!values["partySelectedValue"]) {
        errors["partySelectedValue"] = ErrorMessages["E00328"]
      }
    }
    if (checkCondition(value, [re.InterestReport]) && values.calculate_date === "2") {
      if (isblank(values["cr_days"])) {
        errors["cr_days"] = ErrorMessages["E00525"]
      }
    }
    if (checkCondition(value, [re.BankReconcileReport])) {
      if (!values["bankSelectedValue"]) {
        errors["bankSelectedValue"] = ErrorMessages["E00422"]
      }
    }
    if (checkCondition(value, [re.MissingBillNumber])) {
      if (!values["type"]) {
        errors["type"] = ErrorMessages["E00423"]
      }
    }
    if (checkCondition(value, [re.LotDetailReport, re.LotCostingReport, re.ProcessLongation_ShortageReport])) {
      if (!values["lotSelectedValue"] || (!isblank(values) && !isblank(values["lotSelectedValue"]) && values["lotSelectedValue"].length === 0)) {
        errors["lotSelectedValue"] = ErrorMessages["E00086"]
      }
    }

    if (checkCondition(value, [re.PartyWiseStockReport, re.In_OutRegister])) {
      if (!values["typeSelectedValue"]) {
        errors["typeSelectedValue"] = ErrorMessages["E00244"]
      }
    }
    if (checkCondition(value, [re.FinishStockInOutReport, re.FinishStockReport, re.ReorderLevelReport])) {
      if (!values["TillDate"]) {
        errors["TillDate"] = ErrorMessages["E00424"]
      }
    }

    if (checkCondition(value, [re.ItemSubGroupSummaryReport, re.ItemSubGroupLevel2SummaryReport])) {
      if (!values["productgroupSelectedValue"] || values["productgroupSelectedValue"].length === 0) {
        errors["productgroupSelectedValue"] = ErrorMessages["E00007"]
      }
    }
    if (checkCondition(value, [re.ItemSubGroupLevel2SummaryReport])) {
      if (!values["productSubgroupSelectedValue"] || values["productSubgroupSelectedValue"].length === 0) {
        errors["productSubgroupSelectedValue"] = ErrorMessages["E00393"]
      }
    }

    if (checkCondition(value, [re.AgentWiseSalesReport])) {
      if (!values["agentTypeSelectedValue"]) {
        errors["agentTypeSelectedValue"] = ErrorMessages["E00244"]
      }
      if (!values["agentCommissionSelValue"]) {
        errors["agentCommissionSelValue"] = ErrorMessages["E00426"]
      }
    }
    if (checkCondition(value, [re.ItemWiseSalesReport, re.ItemWisePurchaseReport])) {
      if (!values["itemTypeSelectedValue"] || !values["itemTypeSelectedValue"].length === 0) {
        errors["itemTypeSelectedValue"] = ErrorMessages["E00244"]
      }
    }
    if (checkCondition(value, [re.TransportWiseSalesReport])) {
      if (!values["transportTypeSelectedValue"]) {
        errors["transportTypeSelectedValue"] = ErrorMessages["E00244"]
      }
    }
    if (checkCondition(value, [re.DaybookReport])) {
      if (!values["selectedDate"]) {
        errors["selectedDate"] = ErrorMessages["E00424"]
      }
      const checkdate = isValidFinancialDate({ dtStr: values["selectedDate"], user_data })
      if (checkdate) {
        errors["selectedDate"] = checkdate
      }
    }
    if (checkCondition(value, [re.PartyGroupPriceReport])) {
      if (!values["partyGroupSelectedValue"]) {
        errors["partyGroupSelectedValue"] = ErrorMessages["E00427"]
      }
    }
    if (checkCondition(value, [re.ProductBatchDetailReport])) {
      if (!values["filterTypeSelectedValue"]) {
        errors["filterTypeSelectedValue"] = ErrorMessages["E00332"]
      }
    }
    if (checkCondition(value, [re.ProductSerialNumberReport])) {
      if (!values["productSerialTypeSelectedValue"]) {
        errors["productSerialTypeSelectedValue"] = ErrorMessages["E00333"]
      }
    }
    if (checkCondition(value, [re.PartyWiseStockReport])) {
      if (!values["customer_supplierSelectedValue"]) {
        errors["customer_supplierSelectedValue"] = ErrorMessages["E00110"]
      }
    }
    if (
      checkCondition(value, [
        re.SalesRegister,
        re.Estimate,
        re.JobDeliveryChallanRegister,
        re.PurchaseOrder,
        re.SalesOrder,
        re.PurchaseRegister,
        re.PaymentRegister,
        re.ReceiptRegister,
        re.LedgerReport,
        re.BankReconcileReport,
        re.InterestReport,
        re.OutstandingReport,
        re.OutstandingAgeingReport,
        re.ProductWiseStockReport,
        re.PartyWiseStockReport,
        re.SalesReturnRegister,
        re.RecordExpense,
        re.PurchaseReturnRegister,
        re.InvoiceMissinginGST,
        re.MultiOutstandingReport,
        re.MultiOutstandingAgeingReport,
        re.MultiLedgerReport,
        re.AgentWiseSalesReport,
        re.TransportWiseSalesReport,
        re.CostCenter,
        re.P_LAccountCostCenterWise,
        re.In_OutRegister,
        re.PackingSlipRegister,
        re.GoodsReceivedNoteRegister,
        re.DeliveryChallanRegister,
        re.JobInwardRegister,
        re.FinishStockRegister,
        re.ManageLotRegister,
        re.JobInwardsWithoutJobInvoice,
        re.ItemWiseSalesReport,
        re.ItemWisePurchaseReport,
        re.ProductBatchDetailReport,
        re.ProductSerialNumberReport,
        re.ProductExpiryReport,
        re.JournalEntryRegister,
        re.GSTAnnualComputation,
        re.LiveStockReport,
        re.FinishStockInOutReport,
        re.ClosingReport,
      ])
    ) {
      if (!values["FromDate"]) {
        errors["FromDate"] = ErrorMessages["E00242"]
      }
      if (
        checkCondition(
          value,
          [
            re.LedgerReport,
            re.SalesOrder,
            re.Estimate,
            re.PurchaseOrder,
            re.GoodsReceivedNoteRegister,
            re.DeliveryChallanRegister,
            re.FinishStockRegister,
            re.ManageLotRegister,
            re.PackingSlipRegister,
            re.JobInwardRegister,
            re.SalesRegister,
            re.PurchaseRegister,
          ],
          "not",
          props
        )
      ) {
        const checkdate = isValidFinancialDate({ dtStr: values["FromDate"], user_data })
        if (checkdate) {
          errors["FromDate"] = checkdate
        }
      } else {
        const checkdate = isValidLedgerFromDate(values["FromDate"], user_data)
        if (checkdate) {
          errors["FromDate"] = checkdate
        }
      }
    }
    if (
      checkCondition(value, [
        re.SalesRegister,
        re.Estimate,
        re.JobDeliveryChallanRegister,
        re.PurchaseOrder,
        re.SalesOrder,
        re.PurchaseRegister,
        re.PaymentRegister,
        re.ReceiptRegister,
        re.LedgerReport,
        re.BankReconcileReport,
        re.InterestReport,
        re.OutstandingReport,
        re.OutstandingAgeingReport,
        re.ProductWiseStockReport,
        re.PartyWiseStockReport,
        re.SalesReturnRegister,
        re.RecordExpense,
        re.PurchaseReturnRegister,
        re.InvoiceMissinginGST,
        re.MultiOutstandingReport,
        re.MultiOutstandingAgeingReport,
        re.MultiLedgerReport,
        re.AgentWiseSalesReport,
        re.TransportWiseSalesReport,
        re.CostCenter,
        re.P_LAccountCostCenterWise,
        re.In_OutRegister,
        re.PackingSlipRegister,
        re.GoodsReceivedNoteRegister,
        re.DeliveryChallanRegister,
        re.JobInwardRegister,
        re.FinishStockRegister,
        re.ManageLotRegister,
        re.JobInwardsWithoutJobInvoice,
        re.ItemWiseSalesReport,
        re.ItemWisePurchaseReport,
        re.ProductBatchDetailReport,
        re.ProductSerialNumberReport,
        re.ProductExpiryReport,
        re.JournalEntryRegister,
        re.GSTAnnualComputation,
        re.LiveStockReport,
        re.FinishStockInOutReport,
      ])
    ) {
      if (!values["ToDate"]) {
        errors["ToDate"] = ErrorMessages["E00243"]
      }

      if (
        checkCondition(
          value,
          [
            re.SalesOrder,
            re.PurchaseOrder,
            re.Estimate,
            re.GoodsReceivedNoteRegister,
            re.DeliveryChallanRegister,
            re.FinishStockRegister,
            re.ManageLotRegister,
            re.PackingSlipRegister,
            re.JobInwardRegister,
            re.SalesRegister,
            re.PurchaseRegister,
          ],
          "not",
          props
        )
      ) {
        const checkdate = isValidFinancialDate({ dtStr: values["ToDate"], user_data })
        if (checkdate) {
          errors["ToDate"] = checkdate
        }
      }
    }

    if (checkCondition(value, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport, re.MultiLedgerReport])) {
      if (!values["viewTypeSelectedValue"]) {
        errors["viewTypeSelectedValue"] = ErrorMessages["E00335"]
      }
      if (!values["multiOutstandingSelValue"]) {
        errors["multiOutstandingSelValue"] = ErrorMessages["E00336"]
      }
      if (!values["crdrSelectedValue"]) {
        errors["crdrSelectedValue"] = ErrorMessages["E00186"]
      }
      if (
        isblank(values["checkedItemsId"]) ||
        (typeof values["checkedItemsId"] === "string" && JSON.parse(values["checkedItemsId"]).length === 0) ||
        (typeof values["checkedItemsId"] === "object" && values["checkedItemsId"].length === 0)
      ) {
        errors["checkedItemsId"] = ErrorMessages["E00428"]
      }
    }
    if (checkCondition(value, [re.TrialBalance, re.CreditLimitReport, re.FinishStockReport])) {
      if (!values["TillDate"]) {
        errors["TillDate"] = ErrorMessages["E00429"]
      }
    }
    if (checkCondition(value, [re.InterestReport])) {
      if (!values["interestRate"] && !values["interestPayableRate"]) {
        errors["interestRate"] = ErrorMessages["E00338"]
        errors["interestPayableRate"] = ErrorMessages["E00338"]
      }
      if (!values["interestdaysSelectedValue"]) {
        errors["interestdaysSelectedValue"] = ErrorMessages["E00339"]
      }
      if (!values["interesttypeSelectedValue"]) {
        errors["interesttypeSelectedValue"] = ErrorMessages["E00340"]
      }
    }

    if (checkCondition(value, [re.GSTR_1Summary, re.GSTR_3BSummary])) {
      if (!values["quarterSelectedValue"]) {
        if (gst_report_range === 1) {
          errors["quarterSelectedValue"] = ErrorMessages["E00114"]
        } else {
          errors["quarterSelectedValue"] = ErrorMessages["E00431"]
        }
      }
    }

    if (checkCondition(value, [re.GSTR_2Summary, re.GSTSummary, re.Monthlypaymentforquarterlyreturn, re.MissingTDSTCSNumber])) {
      if (!values["quarterSelectedValue"]) {
        errors["quarterSelectedValue"] = ErrorMessages["E00431"]
      }
    }

    if (checkCondition(value, [re.PurchaseLotReport])) {
      if (!values["supplierSelectedValue"]) {
        errors["supplierSelectedValue"] = ErrorMessages["E00056"]
      }
      if (!values["billSelectedValue"]) {
        errors["billSelectedValue"] = ErrorMessages["E00213"]
      }
    }
  }
  return errors
}

export const getDateRangeOfGSTReport = ({ formValues, getAccountSitesetting, getquarter }) => {
  var to_date = ""
  var from_date = ""
  var date_for_file = ""
  var periodCode = 0

  const gst_report_range = parseInt(getAccountSitesetting("gst_report_range"))

  if (gst_report_range === 1) {
    const quarter_months = getdropdownfieldvalue({ dropDownData: getquarter("Q"), field: "id", value: formValues.quarterSelectedValue, displayvalue: "months" })
    if (formValues.quarter_period === "all") {
      to_date = getdropdownfieldvalue({ dropDownData: getquarter("Q"), field: "id", value: formValues.quarterSelectedValue, displayvalue: "enddate" })
      from_date = getdropdownfieldvalue({ dropDownData: getquarter("Q"), field: "id", value: formValues.quarterSelectedValue, displayvalue: "startdate" })
      periodCode = "all"
      date_for_file = to_date
    } else {
      const period = quarter_months.find((e) => e.id === formValues.quarter_period)
      date_for_file = period.enddate
      from_date = period.startdate
      to_date = period.enddate
      if (period) {
        periodCode = period.code
      }
    }
  } else {
    to_date = getdropdownfieldvalue({ dropDownData: getquarter("M"), field: "id", value: formValues.quarterSelectedValue, displayvalue: "enddate" })
    from_date = getdropdownfieldvalue({ dropDownData: getquarter("M"), field: "id", value: formValues.quarterSelectedValue, displayvalue: "startdate" })
    date_for_file = to_date
  }
  return { to_date, from_date, period: periodCode, date_for_file }
}

export const showDetailInMasterRow = ({ data, user_data, functions }) => {
  const { getAccountSitesetting } = functions
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint", user_data)
  var updatedData = data.map((e) => {
    const updatedItem = { ...e }
    var item = ""
    var Qty = 0
    var itemArray = []
    updatedItem.DetailGrid.forEach((it, index) => {
      Qty = Qty + parseFloat(getNumberonlyDecimal(it.Qty, inventory_decimalpoint))
      if (index === 0) {
        item = it.item
        itemArray.push(it.item)
      } else {
        if (!itemArray.includes(it.item)) {
          item = `${item}, ${it.item}`
          itemArray.push(it.item)
        }
      }
    })
    updatedItem.item = item
    updatedItem.Qty = Qty
    return updatedItem
  })
  return updatedData
}

export const showDetailAmtInMasterRow = ({ data, user_data, functions }) => {
  var updatedData = data.map((e) => {
    const updatedItem = { ...e }
    var amt = 0

    updatedItem.DetailGrid.forEach((it) => {
      amt = amt + parseFloat(getNumberonlyDecimal(it.amount, 2))
    })
    updatedItem.amount = amt
    return updatedItem
  })
  return updatedData
}

export const changeColumnDataAgeingReport = (columnData, range) => {
  return columnData.map((e) => {
    const updated = { ...e }
    if (updated.id === "30Days") {
      if (range === "2") {
        updated.label = "0-90 Days"
      } else if (range === "3") {
        updated.label = "0-10 Days"
      }
    } else if (updated.id === "60Days") {
      if (range === "2") {
        updated.label = "91-120 Days"
      } else if (range === "3") {
        updated.label = "11-20 Days"
      }
    } else if (updated.id === "90Days") {
      if (range === "2") {
        updated.label = "121-180 Days"
      } else if (range === "3") {
        updated.label = "21-30 Days"
      }
    } else if (updated.id === "120Days") {
      if (range === "2") {
        updated.label = "> 180 Days"
      } else if (range === "3") {
        updated.label = "> 30 Days"
      }
    }
    return updated
  })
}

export const fetchReportData = ({ formValues, props, isMobileOnly, fileName, functions }) => {
  const { getAccountSessionUserData, getAccountSitesetting, getquarter, yearlyReport, getstartyear, getendyear, getReportRights } = functions
  props.reportServiceClear()
  var setconfig = {}
  const { reportdata } = props
  var user_data = props.user_data
  var value = reportdata.reportid
  setconfig.company_url = getAccountSessionUserData("company_url", user_data)
  setconfig.report_id = reportdata.reportid
  const report_format = getAccountSitesetting("report_format", user_data)
  const gst_report_range = getAccountSitesetting("gst_report_range", user_data)
  const pagination_size = getAccountSitesetting("pagination_size", user_data)
  const account_subgroup = parseInt(getAccountSitesetting("account_subgroup"))
  const multigodown_mng = parseInt(getAccountSitesetting("multigodown_mng", user_data))

  // if (isMobileOnly) {
  //   setconfig.to_date = DatetoYMD(formValues.invoicedate2)
  //   setconfig.from_date = DatetoYMD(formValues.invoicedate)
  // } else {
  //   setconfig.to_date = DatetoYMD(formValues.ToDate)
  //   setconfig.from_date = DatetoYMD(formValues.FromDate)
  // }

  setconfig.to_date = DatetoYMD(formValues.ToDate)
  setconfig.from_date = DatetoYMD(formValues.FromDate)
  setconfig.search_text = ""
  setconfig.rowsPerPage = isblank(props.rowsPerPage) || convertIntif(props.rowsPerPage) === 0 ? pagination_size : props.rowsPerPage
  setconfig.page = isblank(props.page) || convertIntif(props.page) === 0 ? 0 : props.page
  setconfig.totalcount = props.totalcount

  if (checkCondition(value, [re.TrialBalance, re.TradingAccount, re.P_LAccount, re.TradingWithP_LReport, re.BalanceSheet, re.CreditLimitReport, re.PaymentRegister, re.ReceiptRegister])) {
    if (checkCondition(value, [re.P_LAccount, re.TradingWithP_LReport, re.TradingAccount, re.BalanceSheet, re.PaymentRegister, re.ReceiptRegister])) {
      setconfig.to_date = DatetoYMD(formValues.ToDate)
      setconfig.from_date = DatetoYMD(formValues.FromDate)
    } else {
      setconfig.to_date = formValues.TillDate
    }

    if (checkCondition(value, [re.TrialBalance])) {
      setconfig.type = formValues.viewType === "0" ? "summary" : "detail"
      setconfig.code = getDropDownPostValue(formValues.accountgroupSelectedValue)
      setconfig.subgroup = ""
      if (account_subgroup === 1) {
        setconfig.subgroup = getDropDownPostValue(formValues.subGroupSelectedValue)
      }
    }

    if (checkCondition(value, [re.PaymentRegister, re.ReceiptRegister])) {
      setconfig.group = getDropDownPostValue(formValues.accountgroupSelectedValue)
      setconfig.subgroup = ""
      if (account_subgroup === 1) {
        setconfig.subgroup = getDropDownPostValue(formValues.subGroupSelectedValue)
      }
    }
  } else if (checkCondition(value, [re.ClosingReport])) {
    setconfig.to_date = formValues.ToDate
    setconfig.itemcode = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.code = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.include_zero = formValues.includingZero === true ? "1" : "0"
    setconfig.include_amount = formValues.includingAmount === true ? "1" : "0"
    setconfig.isProcess = formValues.isProcess === true ? "1" : "0"
    setconfig.stockNegative = formValues.stockNegative === true ? "1" : "0"
    setconfig.rate_type = formValues.ratetypeSelectedValue
    setconfig.reportViewType = getDropDownPostValue(formValues.order_view_type)
  } else if (checkCondition(value, [re.ReorderLevelReport])) {
    setconfig.to_date = formValues.TillDate
    setconfig.itemcode = getDropDownPostValue(formValues.itemSelectedValue)
    setconfig.code = getDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.include_zero = formValues.includingZero === true ? "1" : "0"
    setconfig.include_amount = formValues.includingAmount === true ? "1" : "0"
    setconfig.rate_type = formValues.ratetypeSelectedValue
  } else if (checkCondition(value, [re.PartyWiseStockReport, re.FinishStockInOutReport, re.In_OutRegister])) {
    setconfig.itemcode = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.other_type = formValues.typeSelectedValue
    if (checkCondition(value, [re.In_OutRegister])) {
      setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
      // setconfig.type = getDropDownPostValue(formValues.order_view_type)
    } else {
      setconfig.code = getDropDownPostValue(formValues.customer_supplierSelectedValue)
    }
  } else if (checkCondition(value, [re.FinishStockReport])) {
    setconfig.itemcode = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.to_date = formValues.TillDate
  } else if (checkCondition(value, [re.StoneHistoryReport])) {
    setconfig.code = getMultiDropDownPostValue(formValues.itemSelectedValue)
  } else if (checkCondition(value, [re.ItemWiseSalesReport, re.ItemWisePurchaseReport])) {
    setconfig.code = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.other_type = formValues.itemTypeSelectedValue
    setconfig.viewType = formValues.itemTypeSelectedValue === "1" ? "item_wise" : formValues.itemTypeSelectedValue === "2" ? "item_group_wise" : "item_group_item_wise"
    setconfig.flag = 1
    setconfig.type = formValues.type
  } else if (checkCondition(value, [re.ProductExpiryReport, re.ProductExpiredReport])) {
    setconfig.code = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    if (checkCondition(value, [re.ProductExpiredReport])) {
      setconfig.to_date = DatetoYMD(formValues.TillDate)
      delete setconfig.from_date
    }
  } else if (checkCondition(value, [re.ItemGroupSummaryReport])) {
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.to_date = formValues.TillDate
  } else if (checkCondition(value, [re.MissingBillNumber])) {
    setconfig.type = formValues.type
  } else if (checkCondition(value, [re.ItemSubGroupSummaryReport])) {
    setconfig.code = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.to_date = formValues.TillDate
  } else if (checkCondition(value, [re.ItemSubGroupLevel2SummaryReport])) {
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.code = getDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.to_date = formValues.TillDate
  } else if (checkCondition(value, [re.StoneAnalysis, re.StoneCostAnalysis])) {
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.status = getMultiDropDownPostValue(formValues.__cust_stone_status)
    setconfig.location = getMultiDropDownPostValue(formValues.__cust_stone_location)
    setconfig.shapeid = getMultiDropDownPostValue(formValues.__cust_shape)
    setconfig.cityid = getMultiDropDownPostValue(formValues.__cust_stone_city)
    setconfig.other_type = getMultiDropDownPostValue(formValues.__cust_size_ranges)
    setconfig.all_non_sold = formValues.all_non_sold === true ? "1" : "0"
  } else if (
    checkCondition(value, [
      re.SalesRegister,
      re.PaymentRegister,
      re.ReceiptRegister,
      re.PurchaseRegister,
      re.LedgerReport,
      re.BankReconcileReport,
      re.InterestReport,
      re.MultiLedgerReport,
      re.OutstandingReport,
      re.OutstandingAgeingReport,
      re.SalesReturnRegister,
      re.PurchaseReturnRegister,
      re.MultiOutstandingReport,
      re.AgentWiseSalesReport,
      re.SalesRegisterwithPaymenttype,
      re.TransportWiseSalesReport,
      re.PartyPriceMasterReport,
      re.PartyGroupPriceReport,
      re.InvoiceMissinginGST,
      re.JournalEntryRegister,
      re.CostCenter,
      re.ProductBatchDetailReport,
      re.P_LAccountCostCenterWise,
      re.ProductSerialNumberReport,
      re.ProductExpiredReport,
      re.ProductExpiryReport,
      re.TdsRegister,
      re.MissingTDSTCSNumber,
      re.TcsRegister,
      re.RecordExpense,
      re.Estimate,
      re.JobDeliveryChallanRegister,
      re.SalesOrder,
      re.PurchaseOrder,
      re.MultiOutstandingAgeingReport,
      re.PackingSlipRegister,
      re.GoodsReceivedNoteRegister,
      re.DeliveryChallanRegister,
      re.JobInwardRegister,
      re.FinishStockRegister,
      re.ManageLotRegister,
      re.JobInwardsWithoutJobInvoice,
    ])
  ) {
    if (
      checkCondition(value, [re.SalesRegister, re.PurchaseRegister, re.SalesReturnRegister, re.PurchaseReturnRegister, re.Estimate, re.JobDeliveryChallanRegister, re.SalesOrder, re.PurchaseOrder])
    ) {
      setconfig.type = getDropDownPostValue(formValues.order_view_type)
    }
    if (checkCondition(value, [re.SalesRegister, re.Estimate, re.JobDeliveryChallanRegister, re.SalesOrder])) {
      setconfig.code = getDropDownPostValue(formValues.customerSelectedValue)
      if (checkCondition(value, [re.SalesRegister])) {
        setconfig.other_type = getDropDownPostValue(formValues.other_type)
      }
      if (checkCondition(value, [re.SalesOrder])) {
        setconfig.other_type = getDropDownPostValue(formValues.other_type)
        if (props.dd_orderTypes.length === 1) {
          setconfig.other_type = props.dd_orderTypes[0].id
        } else if (isblank(setconfig.other_type)) {
          setconfig.other_type = "all"
        }
      }
      if (checkCondition(value, [re.Estimate, re.SalesOrder])) {
        setconfig.status = getDropDownObj(formValues.status, "name") === "All" || isblank(formValues.status) ? "" : getDropDownObj(formValues.status, "name")
      }
    } else if (checkCondition(value, [re.PurchaseOrder])) {
      setconfig.code = getDropDownPostValue(formValues.supplierSelectedValue)
      setconfig.status = getDropDownObj(formValues.status, "name") === "All" || isblank(formValues.status) ? "" : getDropDownObj(formValues.status, "name")
    }
    if (checkCondition(value, [re.SalesRegisterwithPaymenttype])) {
      setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
      setconfig.mastercode = getDropDownPostValue(formValues.bankSelectedValue)
    } else if (
      checkCondition(value, [
        re.PackingSlipRegister,
        re.GoodsReceivedNoteRegister,
        re.DeliveryChallanRegister,
        re.JobInwardRegister,
        re.FinishStockRegister,
        re.ManageLotRegister,
        re.JobInwardsWithoutJobInvoice,
      ])
    ) {
      setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
      if (checkCondition(value, [re.PackingSlipRegister])) {
        setconfig.type = getDropDownPostValue(formValues.order_view_type)
        setconfig.other_type = "packing_slip"
      } else if (checkCondition(value, [re.GoodsReceivedNoteRegister])) {
        setconfig.other_type = "goods_received_note"
        setconfig.reportViewType = getDropDownPostValue(formValues.order_view_type)
      } else if (checkCondition(value, [re.DeliveryChallanRegister])) {
        setconfig.other_type = "delivery_challan"
        setconfig.reportViewType = getDropDownPostValue(formValues.order_view_type)
      } else if (checkCondition(value, [re.JobInwardRegister])) {
        setconfig.type = getDropDownPostValue(formValues.order_view_type)
        setconfig.other_type = "job_inward"
      } else if (checkCondition(value, [re.FinishStockRegister])) {
        setconfig.type = getDropDownPostValue(formValues.order_view_type)
        setconfig.other_type = "finish_stock"
      } else if (checkCondition(value, [re.ManageLotRegister])) {
        setconfig.type = getDropDownPostValue(formValues.order_view_type)
        setconfig.other_type = "manage_lot_master"
      } else if (checkCondition(value, [re.JobInwardsWithoutJobInvoice])) {
        setconfig.type = getDropDownPostValue(formValues.order_view_type)
        setconfig.other_type = ""
      }
      setconfig.status = formValues.status
      if (isblank(setconfig.status) || setconfig.status === "all") {
        setconfig.status = ""
      }
    } else if (checkCondition(value, [re.PaymentRegister, re.ReceiptRegister])) {
      setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
    } else if (checkCondition(value, [re.SalesReturnRegister, re.PurchaseReturnRegister, re.RecordExpense])) {
      setconfig.code = getDropDownPostValue(formValues.customer_supplierSelectedValue)
    } else if (checkCondition(value, [re.PurchaseRegister])) {
      setconfig.code = getDropDownPostValue(formValues.supplierSelectedValue)
      if (checkCondition(value, [re.PurchaseRegister])) {
        setconfig.other_type = getDropDownPostValue(formValues.other_type)
      }
    } else if (checkCondition(value, [re.AgentWiseSalesReport])) {
      setconfig.agent_type = formValues.agentTypeSelectedValue === "1" ? "partywise" : ""
      setconfig.party_code = getDropDownPostValue(formValues.agentSelectedValue)
      setconfig.other_type = formValues.agentTypeSelectedValue
      setconfig.type = getDropDownPostValue(formValues.order_view_type)
      setconfig.agentcomm_type = formValues.agentCommissionSelValue
      setconfig.flag = getDropDownPostValue(formValues.docTypeSelectedValue)
    } else if (checkCondition(value, [re.TdsRegister, re.TcsRegister])) {
      // setconfig.to_date = getdropdownfieldvalue(getquarter("M", user_data), "id", formValues.quarterSelectedValue, "enddate")
      // setconfig.from_date = getdropdownfieldvalue(getquarter("M", user_data), "id", formValues.quarterSelectedValue, "startdate")
      setconfig.sort_by = "id"
      setconfig.sort = "desc"
      setconfig.flag = 1
      setconfig.party_code = 0
      setconfig.type = "0"
      setconfig.other_type = formValues.other_type
    } else if (checkCondition(value, [re.MissingTDSTCSNumber])) {
      setconfig.to_date = getdropdownfieldvalue({ dropDownData: getquarter("M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "enddate" })
      setconfig.from_date = getdropdownfieldvalue({ dropDownData: getquarter("M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "startdate" })
      setconfig.party_code = getMultiDropDownPostValue(formValues.customer_supplierSelectedValue)
      setconfig.other_type = formValues.tds_tcs_type
      setconfig.type = formValues.other_type
    } /*  else if (checkCondition(value, [re.TcsRegister])) {
      setconfig.sort_by = "id"
      setconfig.sort = "desc"
      setconfig.flag = 1
      setconfig.party_code = 0
      setconfig.type = "0"
      setconfig.other_type = formValues.other_type
    }  */ else if (checkCondition(value, [re.MultiLedgerReport, re.MultiOutstandingReport, re.MultiOutstandingAgeingReport])) {
      setconfig.type = formValues.viewTypeSelectedValue
      setconfig.crdr = formValues.crdrSelectedValue
      setconfig.other_type = formValues.multiOutstandingSelValue
      if (isMobileOnly) {
        setconfig.checkedItemsId = JSON.stringify(formValues.checkedItemsId.map((e) => e.code))
        setconfig.checkedItemsData = typeof formValues.checkedItemsData === "string" ? formValues.checkedItemsData : JSON.stringify(formValues.checkedItemsData.map((e) => e.code))
      } else {
        var checkedItemsData = ""
        var checkedItemsId = typeof formValues.checkedItemsId === "string" ? JSON.parse(formValues.checkedItemsId) : formValues.checkedItemsId
        checkedItemsId = checkedItemsId.map((a) => a.code)
        checkedItemsId = JSON.stringify(checkedItemsId)
        if (formValues.multiOutstandingSelValue !== "All") {
          checkedItemsData = JSON.parse(formValues.checkedItemsData)
          checkedItemsData = checkedItemsData.map((a) => a.code)
          checkedItemsData = JSON.stringify(checkedItemsData)
        }
        setconfig.checkedItemsId = checkedItemsId
        setconfig.checkedItemsData = checkedItemsData
      }
      setconfig.reportViewType = formValues.reportViewType
      setconfig.include_zero = formValues.includingZero === true ? "1" : "0"
      setconfig.flag = 1

      if (checkCondition(value, [re.MultiOutstandingAgeingReport])) {
        setconfig.dayRange = formValues.dayRange
      }
    } else if (checkCondition(value, [re.OutstandingReport, re.OutstandingAgeingReport])) {
      setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
      setconfig.crdr = formValues.crdrSelectedValue
      setconfig.reportViewType = formValues.reportViewType
      if (checkCondition(value, [re.OutstandingAgeingReport])) {
        setconfig.dayRange = formValues.dayRange
      }
    } else if (checkCondition(value, [re.LedgerReport])) {
      setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
      if (report_format === 2) {
        setconfig.other_type = formValues.reportFormat
      }
    } else if (checkCondition(value, [re.BankReconcileReport])) {
      setconfig.code = getDropDownPostValue(formValues.bankSelectedValue)
    } else if (checkCondition(value, [re.InterestReport])) {
      setconfig.other_type = formValues.interesttypeSelectedValue
      setconfig.interest_per = formValues.interestRate
      setconfig.interestPayableRate = formValues.interestPayableRate
      setconfig.interest_day = formValues.interestdaysSelectedValue
      setconfig.include_op_bal = getCheckBoxPostValue(formValues.include_op_bal)
      setconfig.include_end_date = getCheckBoxPostValue(formValues.include_end_date)
      setconfig.code = getDropDownPostValue(formValues.partySelectedValue)
      if (report_format === 2) {
        setconfig.flag = formValues.reportFormat
      } else {
        setconfig.flag = "1"
      }
      setconfig.cr_days = formValues.cr_days
    } else if (checkCondition(value, [re.TransportWiseSalesReport])) {
      setconfig.party_code = getDropDownPostValue(formValues.transportSelectedValue)
      setconfig.other_type = formValues.transportTypeSelectedValue
      setconfig.type = getDropDownPostValue(formValues.order_view_type)
      setconfig.flag = 1
    } else if (checkCondition(value, [re.PartyPriceMasterReport, re.PartyGroupPriceReport])) {
      if (checkCondition(value, [re.PartyPriceMasterReport])) {
        setconfig.party_code = getDropDownPostValue(formValues.partySelectedValue)
      } else {
        setconfig.party_code = getDropDownPostValue(formValues.partyGroupSelectedValue)
      }
      setconfig.itemgroupcode = getDropDownPostValue(formValues.productgroupSelectedValue)
      setconfig.flag = 1
    } else if (checkCondition(value, [re.InvoiceMissinginGST, re.JournalEntryRegister, re.ProductExpiredReport, re.ProductExpiryReport])) {
      setconfig.flag = 1
    } else if (checkCondition(value, [re.ProductBatchDetailReport])) {
      setconfig.itemcode = getMultiDropDownPostValue(formValues.itemSelectedValue)
      setconfig.other_type = formValues.filterTypeSelectedValue
      setconfig.include_zero = formValues.emptyBatches === true ? "1" : "0"
      setconfig.code = getMultiDropDownPostValue(formValues.batchSelectedValue)

      setconfig.reportViewType = formValues.productSerialTypeSelectedValue
      setconfig.goDownSelectedValue = getMultiDropDownPostValue(formValues.goDownSelectedValue)
      setconfig.flag = 1
    } else if (checkCondition(value, [re.CostCenter])) {
      setconfig.party_code = getDropDownPostValue(formValues.partySelectedValue)
      setconfig.other_type = getDropDownPostValue(formValues.costcenterSelectedValue)
      setconfig.flag = 1
    } else if (checkCondition(value, [re.P_LAccountCostCenterWise])) {
      setconfig.code = getDropDownPostValue(formValues.costcenterSelectedValue)
      setconfig.flag = 1
    } else if (checkCondition(value, [re.ProductSerialNumberReport])) {
      setconfig.itemcode = getDropDownPostValue(formValues.itemSelectedValue)
      setconfig.other_type = formValues.productSerialTypeSelectedValue
      setconfig.include_zero = formValues.showAvailable === true ? "1" : "0"
      setconfig.code = getMultiDropDownPostValue(formValues.serialSelectedValue)
      setconfig.goDownSelectedValue = getMultiDropDownPostValue(formValues.goDownSelectedValue)
      setconfig.flag = 1
    }
  } else if (checkCondition(value, [re.LiveStockReport, re.ProductWiseStockReport, re.FinishStockInOutReport])) {
    if (checkCondition(value, [re.LiveStockReport])) {
      setconfig.other_type = formValues.order_view_type
      setconfig.party_code = getDropDownPostValue(formValues.customer_supplierSelectedValue)
      setconfig.type = getCheckBoxPostValue(formValues.include_orders)
      setconfig.include_zero = getCheckBoxPostValue(formValues.includingZero)
    }
    setconfig.code = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.itemgroupcode = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.isProcess = formValues.isProcess === true ? "1" : "0"
  } else if (checkCondition(value, [re.DaybookReport])) {
    setconfig.to_date = formValues.selectedDate
    setconfig.from_date = formValues.selectedDate
  } else if (checkCondition(value, [re.LotDetailReport, re.ProcessLongation_ShortageReport, re.LotCostingReport])) {
    setconfig.code = getDropDownPostValue(formValues.lotSelectedValue)
  } else if (checkCondition(value, [re.PurchaseLotReport])) {
    setconfig.code = getDropDownPostValue(formValues.billSelectedValue)
  }
  if (checkCondition(value, [re.StoneCostAnalysis])) {
    setconfig.include_amount = formValues.include_amount
  }

  if (checkCondition(value, [re.ProcessHistoryReport])) {
    setconfig.party_code = getDropDownPostValue(formValues.customerSelectedValue)
    setconfig.itemcode = getMultiDropDownPostValue(formValues.itemSelectedValue)
    setconfig.group = getMultiDropDownPostValue(formValues.productgroupSelectedValue)
    setconfig.subgroup = getMultiDropDownPostValue(formValues.productSubgroupSelectedValue)
    setconfig.subgrouplevel2 = getMultiDropDownPostValue(formValues.productSubgroupLevel2SelectedValue)
    setconfig.code = getDropDownPostValue(formValues.processSelectedValue)
    setconfig.lot_number = getDropDownPostValue(formValues.lotSelectedValue)
  }

  if (
    multigodown_mng === 1 &&
    checkCondition(value, [
      re.ProductWiseStockReport,
      re.FinishStockInOutReport,
      re.FinishStockReport,
      re.ClosingReport,
      re.PartyWiseStockReport,
      re.In_OutRegister,
      re.ReorderLevelReport,
      re.PackingSlipRegister,
      re.GoodsReceivedNoteRegister,
      re.DeliveryChallanRegister,
      re.JobInwardRegister,
      re.FinishStockRegister,
      re.ManageLotRegister,
      re.JobInwardsWithoutJobInvoice,
      re.LiveStockReport,
      re.ItemWiseSalesReport,
      re.ItemWisePurchaseReport,
    ])
  ) {
    if (
      checkCondition(value, [
        re.ProductWiseStockReport,
        re.FinishStockInOutReport,
        re.FinishStockReport,
        re.ItemWiseSalesReport,
        re.ItemWisePurchaseReport,
        re.ClosingReport,
        re.PartyWiseStockReport,
        re.In_OutRegister,
        re.LiveStockReport,
      ])
    ) {
      setconfig.goDownSelectedValue = getMultiDropDownPostValue(formValues.goDownSelectedValue)
    } else {
      setconfig.goDownSelectedValue = getDropDownPostValue(formValues.goDownSelectedValue)
    }
  }
  if (
    checkCondition(value, [
      re.TrialBalance,
      re.TradingAccount,
      re.P_LAccount,
      re.TradingWithP_LReport,
      re.BalanceSheet,
      re.MultiLedgerReport,
      re.FinishStockInOutReport,
      re.ProductWiseStockReport,
      re.ClosingReport,
      re.In_OutRegister,
      re.ReorderLevelReport,
      re.MultiOutstandingReport,
      re.MultiOutstandingAgeingReport,
      re.PackingSlipRegister,
      re.GoodsReceivedNoteRegister,
      re.DeliveryChallanRegister,
      re.JobInwardRegister,
      re.FinishStockRegister,
      re.ManageLotRegister,
      re.JobInwardsWithoutJobInvoice,
    ])
  ) {
    setconfig.fileName = fileName
  }
  if (checkCondition(value, [re.TrialBalance])) {
    setconfig.viewType = formValues.viewType
  }
  if (checkCondition(value, [re.GSTR_1Summary])) {
    if (yearlyReport) {
      setconfig.from_date = `${getstartyear()}-04-01`
      setconfig.to_date = `${getendyear()}-03-31`
    } else {
      setconfig.to_date = getDateRangeOfGSTReport({ formValues, getAccountSitesetting, getquarter }).to_date
      setconfig.from_date = getDateRangeOfGSTReport({ formValues, getAccountSitesetting, getquarter }).from_date
    }
    setconfig.period = getDateRangeOfGSTReport({ formValues, getAccountSitesetting, getquarter }).period
    if (setconfig.period === "all" || convertIntif(setconfig.period) === 3) {
      setconfig.other_type = "1"
    }
    setconfig.type = gst_report_range === 1 ? "quarterly" : "monthly"
    setconfig.flag = 1
    setconfig.report_id = "95"
    setconfig.type = "gstr1"
  }
  if (checkCondition(value, [re.GSTR_3BSummary])) {
    setconfig.to_date = getdropdownfieldvalue({ dropDownData: getquarter(gst_report_range === 1 ? "Q" : "M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "enddate" })
    setconfig.from_date = getdropdownfieldvalue({
      dropDownData: getquarter(gst_report_range === 1 ? "Q" : "M", user_data),
      field: "id",
      value: formValues.quarterSelectedValue,
      displayvalue: "startdate",
    })
    setconfig.type = gst_report_range === 1 ? "quarterly" : "monthly"
    setconfig.flag = 1
    setconfig.report_id = "95"
    setconfig.type = "gstr3B"
  }
  if (checkCondition(value, [re.GSTR_2Summary, re.Monthlypaymentforquarterlyreturn])) {
    setconfig.to_date = getdropdownfieldvalue({ dropDownData: getquarter("M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "enddate" })
    setconfig.from_date = getdropdownfieldvalue({ dropDownData: getquarter("M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "startdate" })
    setconfig.type = "monthly"
    setconfig.other_type = formValues.gstr2_type
    setconfig.flag = 1
  }
  if (checkCondition(value, [re.GSTSummary])) {
    setconfig.report_id = "95"
    setconfig.type = "all"
    setconfig.reportViewType = "1"
    if (yearlyReport) {
      setconfig.from_date = `${getstartyear()}-04-01`
      setconfig.to_date = `${getendyear()}-03-31`
    } else {
      setconfig.to_date = getdropdownfieldvalue({ dropDownData: getquarter("M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "enddate" })
      setconfig.from_date = getdropdownfieldvalue({ dropDownData: getquarter("M", user_data), field: "id", value: formValues.quarterSelectedValue, displayvalue: "startdate" })
    }
  }
  if (checkCondition(value, [re.InterestReport, re.OutstandingReport, re.MultiOutstandingReport, re.OutstandingAgeingReport, re.MultiOutstandingAgeingReport])) {
    setconfig.calculate_date = formValues.calculate_date
  }
  if (checkCondition(value, [re.FinishStockInOutReport])) {
    setconfig.reportViewType = getDropDownPostValue(formValues.order_view_type)
    setconfig.from_weight = formValues.from_weight
    setconfig.to_weight = formValues.to_weight
    setconfig.show_bundle = formValues.show_bundle
    setconfig.show_opening = formValues.show_opening
  }

  if (checkCondition(value, [re.FinishStockReport])) {
    setconfig.from_weight = formValues.from_weight
    setconfig.to_weight = formValues.to_weight
  }

  if (checkCondition(value, [re.FinishStockBarcodeReport])) {
    setconfig.type = getDropDownPostValue(formValues.order_view_type)
    setconfig.barcode = formValues.barcode
  }

  if (checkCondition(value, [re.ProcessStockInOutReport])) {
    var partyCode = getDropDownPostValue(formValues.partySelectedValue)
    var lot_party = formValues.lot_party ? formValues.lot_party : ""
    var dueDays = formValues.dueDays ? formValues.dueDays : ""
    var Mode = formValues.mode
    var viewType = formValues.viewType
    var lot_number = ""
    var flag = ""
    if (!isblank(formValues) && !isblank(formValues.lotSelectedValue)) {
      lot_number = formValues.lotSelectedValue
    }
    if (!isblank(viewType)) {
      if (viewType === "0") {
        flag = 1
      } else {
        flag = 2
      }
    }
    var process = getDropDownPostValue(formValues.processSelectedValue)

    if (viewType === "0") {
      props.fetchProcessAndLotV1({
        party_code: partyCode,
        viewType,
        lot_party,
        other_type1: "report",
        rowsPerPage: pagination_size,
        page: 0,
        order: "desc",
        orderBy: "code",
        search_text: "",
        other_type: "kanban",
        mode: Mode,
        product_code: getDropDownPostValue(formValues.itemSelectedValue),
        lot_number,
        report: true,
        process_code: process,
        flag,
        item_flag: 1,
        dueDays,
      })
    } else {
      props.fetchProcessAndLot({
        party_code: partyCode,
        viewType,
        lot_party,
        other_type1: "report",
        rowsPerPage: pagination_size,
        page: 0,
        order: "desc",
        orderBy: "code",
        search_text: "",
        other_type: "kanban",
        mode: Mode,
        product_code: getDropDownPostValue(formValues.itemSelectedValue),
        lot_number,
        report: true,
        process_code: process,
      })
    }
  } else {
    if (checkCondition(value, [re.BalanceSheet, re.P_LAccount, re.TradingWithP_LReport, re.TradingAccount])) {
      setconfig.url = list_url({ type: "getAllReportV5", props: { config: isMobileOnly ? functions.getConfigFile() : "" } })
    } else {
      setconfig.url = list_url({ type: "getAllReport", props: { config: isMobileOnly ? functions.getConfigFile() : "" } })
    }

    props.fetch_report_data({
      setconfig,
      user_data,
      formtype: "",
      functions: {
        getReportRights,
        dd_orderTypes: props.dd_orderTypes,
        getAccountSitesetting,
        dditem: props.dditem,
        ddallaccount: props.ddallaccount,
        item: props.item,
        ddcustomer_supplier: props.ddcustomer_supplier,
        dd_country_state: props.dd_country_state,
        dd_taxcode: props.dd_taxcode,
        getAccountSessionUserData,
      },
    })
  }
}

export const fetchReportDataV1 = ({ formValues, props, isMobileOnly, fileName, functions }) => {
  const { getAccountSessionUserData, getAccountSitesetting, getReportRights } = functions
  props.reportServiceClear()
  let setconfig = {}
  const { reportdata, dispatch } = props
  var user_data = props.user_data
  var value = reportdata.reportid
  var company_url = getAccountSessionUserData("company_url", user_data)
  const report_format = getAccountSitesetting("report_format", user_data)
  const gst_report_range = getAccountSitesetting("gst_report_range", user_data)
  const pagination_size = getAccountSitesetting("pagination_size", user_data)
  const account_subgroup = parseInt(getAccountSitesetting("account_subgroup"))
  const multigodown_mng = parseInt(getAccountSitesetting("multigodown_mng", user_data))

  const dS = { report_format, gst_report_range, pagination_size, account_subgroup, multigodown_mng }

  if (checkCondition(value, [re.FinishStockReport]) && formValues.order_view_type === "detail") {
    const rowsPerPage = 20
    //const page = 0
    const order = "desc"
    const orderBy = "code"
    const search_text = ""
    var code = ""
    dispatch(
      props.fetch_finish_stockData({
        functions,
        Selected: [],
        disabled: "",
        from_type: "kanban",
        other_type1: "report",
        party_code: formValues.party_code,
        code,
        onEFComplete: props.onComplete,
        rowsPerPage,
        page: 0,
        order,
        orderBy,
        search_text,
        // otherDetail: "list",
        getValues: "",
        set_Value: "",
        gridClearField: "",
        salesCustomField: [],
        viewType: formValues.order_view_type,
      })
    )
  } else if (checkCondition(value, [re.ProcessStockInOutReport])) {
    var partyCode = getDropDownPostValue(formValues.partySelectedValue)
    var lot_party = formValues.lot_party ? formValues.lot_party : ""
    var dueDays = formValues.dueDays ? formValues.dueDays : ""
    var showPcs = formValues.showPcs ? formValues.showPcs : ""
    var Mode = formValues.mode
    var viewType = formValues.viewType
    var lot_number = ""
    var flag = ""
    if (!isblank(formValues) && !isblank(formValues.lotSelectedValue)) {
      lot_number = formValues.lotSelectedValue
    }
    if (!isblank(viewType)) {
      if (viewType === "0") {
        flag = 1
      } else {
        flag = 2
      }
    }
    var process = getDropDownPostValue(formValues.processSelectedValue)

    if (viewType === "0") {
      dispatch(
        props.fetchProcessAndLotV1({
          party_code: partyCode,
          viewType,
          lot_party,
          other_type1: "report",
          rowsPerPage: pagination_size,
          page: 0,
          order: "desc",
          orderBy: "code",
          search_text: "",
          other_type: "kanban",
          mode: Mode,
          product_code: getDropDownPostValue(formValues.itemSelectedValue),
          lot_number,
          report: true,
          process_code: process,
          flag,
          item_flag: 1,
          dueDays,
          showPcs,
        })
      )
    } else {
      dispatch(
        props.fetchProcessAndLot({
          party_code: partyCode,
          viewType,
          lot_party,
          other_type1: "report",
          rowsPerPage: pagination_size,
          page: 0,
          order: "desc",
          orderBy: "code",
          search_text: "",
          other_type: "kanban",
          mode: Mode,
          product_code: getDropDownPostValue(formValues.itemSelectedValue),
          lot_number,
          report: true,
          process_code: process,
          showPcs,
        })
      )
    }
  } else {
    setconfig = getSetConfig({ reportid: reportdata.reportid, formValues, company_url, dS, props, fileName, isMobileOnly, functions })

    return {
      setconfig,
      user_data,
      formtype: "",
      functions: {
        getReportRights,
        dd_orderTypes: props.dd_orderTypes,
        getAccountSitesetting,
        dditem: props.dditem,
        ddallaccount: props.ddallaccount,
        item: props.item,
        ddcustomer_supplier: props.ddcustomer_supplier,
        dd_country_state: props.dd_country_state,
        dd_taxcode: props.dd_taxcode,
        getAccountSessionUserData,
      },
    }
  }
}

export const setRequestDataFromconfig = ({ setconfig }) => {
  let data = new FormData()
  if (setconfig.search_text) {
    data.append("search_text", setconfig.search_text)
  }
  if (setconfig.report_id) {
    data.append("report_id", setconfig.report_id)
  }
  if (setconfig.to_date) {
    data.append("to_date", setconfig.to_date)
  }
  if (setconfig.itemcode) {
    data.append("itemcode", setconfig.itemcode)
  }
  if (setconfig.from_date) {
    data.append("from_date", setconfig.from_date)
  }
  if (setconfig.include_end_date) {
    data.append("include_end_date", setconfig.include_end_date)
  }
  if (setconfig.calculate_date) {
    data.append("calculate_date", setconfig.calculate_date)
  }
  if (setconfig.rowsPerPage) {
    data.append("limit", "All")
  }
  if (setconfig.page !== "") {
    data.append("page", parseInt(setconfig.page, 10))
    data.append("offset", setconfig.page * setconfig.rowsPerPage)
  }
  if (setconfig.totalcount) {
    data.append("totalcount", parseInt(setconfig.totalcount, 10))
  }
  if (setconfig.code) {
    data.append("code", setconfig.code)
  }
  if (setconfig.reportViewType) {
    data.append("reportViewType", setconfig.reportViewType)
  }
  if (setconfig.subgroup) {
    data.append("subgroup", setconfig.subgroup)
  }
  if (setconfig.group) {
    data.append("group", setconfig.group)
  }
  if (setconfig.other_type) {
    data.append("other_type", setconfig.other_type)
  }
  if (setconfig.interest_per) {
    data.append("interest_per", setconfig.interest_per)
  }
  if (setconfig.interestPayableRate) {
    data.append("interestPayableRate", setconfig.interestPayableRate)
  }
  if (setconfig.goDownSelectedValue) {
    data.append("goDownSelectedValue", setconfig.goDownSelectedValue)
  }
  if (setconfig.interest_day) {
    data.append("interest_day", setconfig.interest_day)
  }
  if (setconfig.include_zero) {
    data.append("include_zero", setconfig.include_zero)
  }
  if (setconfig.include_amount) {
    data.append("include_amount", setconfig.include_amount)
  }

  if (setconfig.all_non_sold) {
    data.append("all_non_sold", setconfig.all_non_sold)
  }

  if (setconfig.flag) {
    data.append("flag", setconfig.flag)
  }
  if (setconfig.party_code) {
    data.append("party_code", setconfig.party_code)
  }
  if (setconfig.itemgroupcode) {
    data.append("itemgroupcode", setconfig.itemgroupcode)
  }
  if (setconfig.isProcess) {
    data.append("isProcess", setconfig.isProcess)
  }
  if (setconfig.stockNegative) {
    data.append("stockNegative", setconfig.stockNegative)
  }
  if (setconfig.subgrouplevel2) {
    data.append("subgrouplevel2", setconfig.subgrouplevel2)
  }
  if (setconfig.agentcomm_type) {
    data.append("agentcomm_type", setconfig.agentcomm_type)
  }
  if (setconfig.amt_type) {
    data.append("amt_type", setconfig.amt_type)
  }
  if (setconfig.rate_type) {
    data.append("rate_type", setconfig.rate_type)
  }
  if (setconfig.checkedItemsId) {
    data.append("checkedItemsId", setconfig.checkedItemsId)
  }
  if (setconfig.checkedItemsData) {
    data.append("checkedItemsData", setconfig.checkedItemsData)
  }
  if (setconfig.type) {
    data.append("type", setconfig.type)
  }
  if (setconfig.crdr) {
    data.append("crdr", setconfig.crdr)
  }
  if (setconfig.mastercode) {
    data.append("mastercode", setconfig.mastercode)
  }
  if (setconfig.fileName) {
    data.append("fileName", setconfig.fileName)
  }
  if (setconfig.status) {
    data.append("status", setconfig.status)
  }
  if (setconfig.dayRange) {
    data.append("dayRange", setconfig.dayRange)
  }
  if (setconfig.cr_days) {
    data.append("cr_days", setconfig.cr_days)
  }
  if (setconfig.include_op_bal) {
    data.append("include_op_bal", setconfig.include_op_bal)
  }

  if (setconfig.cityid) {
    data.append("cityid", setconfig.cityid)
  }

  if (setconfig.shapeid) {
    data.append("shapeid", setconfig.shapeid)
  }
  if (setconfig.location) {
    data.append("location", setconfig.location)
  }

  if (setconfig.lot_number) {
    data.append("lot_number", setconfig.lot_number)
  }

  if (setconfig.from_weight) {
    data.append("from_weight", setconfig.from_weight)
  }
  if (setconfig.to_weight) {
    data.append("to_weight", setconfig.to_weight)
  }

  if (setconfig.show_bundle) {
    data.append("show_bundle", setconfig.show_bundle)
  }

  if (setconfig.show_opening) {
    data.append("show_opening", setconfig.show_opening)
  }

  if (setconfig.barcode) {
    data.append("barcode", setconfig.barcode)
  }

  if (setconfig.viewType) {
    data.append("viewType", setconfig.viewType)
  }
  if (setconfig.agent_type) {
    data.append("agent_type", setconfig.agent_type)
  }

  if (setconfig.assignUser) {
    data.append("assignUser", setconfig.assignUser)
  }
  if (setconfig.include_pen_orders) {
    data.append("include_pen_orders", setconfig.include_pen_orders)
  }
  return data
}

export const setLiveStockData = (parameters) => {
  const { setconfig, functions, response } = parameters
  const { getAccountSitesetting } = functions

  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
  var data = []
  const setCustomfields = (i, common, array) => {
    const customfields = {}
    response.data.result.custom_fields_data.forEach((e) => {
      if ((common && e.common) || !common) {
        customfields[e.name] = i[e.name]
      }
    })
    return customfields
  }
  response.data.result.records.productwisestock.forEach((e) => {
    var inData = []
    var outData = []
    var orderData = []
    if (e.detail.length > 0) {
      if (e.detail[0].DocNo === "Opening Balance") {
        e.detail[0].BillNo = "Op. Bal"
        e.detail[0].Date = DatetoDMY(setconfig.from_date)
      }
    }
    e.detail.forEach((i) => {
      if (parseFloat(getNumberonlyDecimal(i.In, inventory_decimalpoint)) > 0) {
        i.type = "in"
        inData.push(i)
      } else if (parseFloat(getNumberonlyDecimal(i.Out, inventory_decimalpoint)) > 0) {
        i.type = "out"
        outData.push(i)
      } else {
        if (i.BillNo === "Op. Bal") {
          if (parseFloat(getNumberonlyDecimal(i.In, inventory_decimalpoint)) > 0 || parseFloat(getNumberonlyDecimal(i.Out, inventory_decimalpoint)) > 0) {
            i.type = "in"
            inData.push(i)
          }
        } else {
          i.type = "in"
          inData.push(i)
        }
      }
    })
    var AllData = [...inData, ...outData]
    if (convertIntif(setconfig.type) === 1) {
      response.data.result.records.sales_order.forEach((i) => {
        if (convertIntif(i.ItemCode) === convertIntif(e.code)) {
          const newOrderItem = {
            Balance: "",
            BillNo: i.BillNo,
            Date: i.BillDate,
            DocNo: i.BillNo,
            In: "0",
            Out: i.penQty,
            PartyName: i.PartyName,
            invoiceCode: i.code,
            invoiceType: i.invoice_type,
            partyCode: i.PartyCode,
            subType: "SO",
            type: "order",
            ...setCustomfields(i),
          }
          orderData.push(newOrderItem)
        }
      })
      AllData = [...AllData, ...orderData]
    }

    AllData.forEach((i) => {
      const iCustomFields = setCustomfields(i, true)
      if (data.filter((j) => isEqual(setCustomfields(j, true), iCustomFields) && convertIntif(j.code) === convertIntif(e.code)).length > 0) {
        data = data.map((j) => {
          const updated = { ...j }
          if (isEqual(setCustomfields(j, true), iCustomFields) && convertIntif(j.code) === convertIntif(e.code)) {
            updated.DetailGrid = [...updated.DetailGrid, i]
          }
          return updated
        })
      } else {
        const customFields = {
          ...iCustomFields,
        }
        Object.keys(i).forEach((key) => {
          if (key.includes("__custCode_")) {
            customFields[key] = i[key]
          }
        })

        data.push({
          code: e.code,
          name: e.name,
          itemGroupCode: e.itemGroupCode,
          itemGroupName: e.itemGroupName,
          itemSubGroupCode: e.itemSubGroupCode,
          itemSubGroupLevel2Code: e.itemSubGroupLevel2Code,
          itemSubGroupLevel2Name: e.itemSubGroupLevel2Name,
          itemSubGroupName: e.itemSubGroupName,
          ...customFields,
          uniqueKey: `${e.code}_${JSON.stringify(iCustomFields)}`,
          DetailGrid: [i],
        })
      }
    })
  })
  const __cust_pcs = response.data.result.custom_fields_data.find((e) => e.name === "__cust_pcs")
  response.data.result.records = data.map((e) => {
    const masterItem = { ...e }
    masterItem.tot_in = 0
    masterItem.tot_out = 0
    masterItem.balance = 0
    masterItem.__cust_pcsBalance = 0
    masterItem.__cust_pcsIn = 0
    masterItem.__cust_pcsOut = 0
    masterItem.order_qty = 0
    masterItem.net_balance = 0

    var __cust_pcsBalance = 0
    masterItem.DetailGrid.forEach((i) => {
      if (i.type === "in") {
        masterItem.tot_in = masterItem.tot_in + parseFloat(getNumberonlyDecimal(i.In, inventory_decimalpoint))
        if (!isblank(__cust_pcs)) {
          masterItem.__cust_pcsBalance = masterItem.__cust_pcsBalance + parseFloat(getNumberonlyDecimal(i.__cust_pcs, convertIntif(__cust_pcs.decimalPoint)))
          masterItem.__cust_pcsIn = masterItem.__cust_pcsIn + parseFloat(getNumberonlyDecimal(i.__cust_pcs, convertIntif(__cust_pcs.decimalPoint)))
          i.__cust_pcsIn = i.__cust_pcs
          __cust_pcsBalance = __cust_pcsBalance + parseFloat(getNumberonlyDecimal(i.__cust_pcs, convertIntif(__cust_pcs.decimalPoint)))
          i.__cust_pcsBalance = __cust_pcsBalance
        }
      } else if (i.type === "out") {
        masterItem.tot_out = masterItem.tot_out + parseFloat(getNumberonlyDecimal(i.Out, inventory_decimalpoint))
        if (!isblank(__cust_pcs)) {
          masterItem.__cust_pcsBalance = masterItem.__cust_pcsBalance - parseFloat(getNumberonlyDecimal(i.__cust_pcs, convertIntif(__cust_pcs.decimalPoint)))
          masterItem.__cust_pcsOut = masterItem.__cust_pcsOut + parseFloat(getNumberonlyDecimal(i.__cust_pcs, convertIntif(__cust_pcs.decimalPoint)))
          i.__cust_pcsOut = i.__cust_pcs
          __cust_pcsBalance = __cust_pcsBalance - parseFloat(getNumberonlyDecimal(i.__cust_pcs, convertIntif(__cust_pcs.decimalPoint)))
          i.__cust_pcsBalance = __cust_pcsBalance
        }
      } else if (i.type === "order") {
        if (convertIntif(setconfig.type) === 1) {
          masterItem.order_qty = masterItem.order_qty + parseFloat(getNumberonlyDecimal(i.Out, inventory_decimalpoint))
          if (!isblank(__cust_pcs)) {
            masterItem.__cust_pcsBalance = masterItem.__cust_pcsBalance - parseFloat(getNumberonlyDecimal(i.__cust_pcs, convertIntif(__cust_pcs.decimalPoint)))
            masterItem.__cust_pcsOut = masterItem.__cust_pcsOut + parseFloat(getNumberonlyDecimal(i.__cust_pcs, convertIntif(__cust_pcs.decimalPoint)))
            i.__cust_pcsOut = i.__cust_pcs
            __cust_pcsBalance = __cust_pcsBalance - parseFloat(getNumberonlyDecimal(i.__cust_pcs, convertIntif(__cust_pcs.decimalPoint)))
            i.__cust_pcsBalance = __cust_pcsBalance
          }
        }
      }
    })
    masterItem.balance = masterItem.tot_in - masterItem.tot_out
    if (convertIntif(setconfig.type) === 1) {
      masterItem.net_balance = masterItem.balance - masterItem.order_qty
    }
    if (setconfig.order_view_type === "detail") {
      masterItem.DetailGrid = sortByDate(masterItem.DetailGrid, "Date")
      var currentBalance = 0
      masterItem.DetailGrid = masterItem.DetailGrid.map((i) => {
        const updated = { ...i }
        currentBalance = currentBalance + parseFloat(getNumberonlyDecimal(i.In, inventory_decimalpoint)) - parseFloat(getNumberonlyDecimal(i.Out, inventory_decimalpoint))
        updated.Balance = currentBalance
        return updated
      })
    }
    return masterItem
  })
  return response
}

export const reportFetchDataSuccess = (parameters) => {
  const { setconfig, dispatch, user_data, REPORT_SERVICE_SUCCESS, GST_SET_DETAIL, functions, onComplete } = parameters
  const { getNameFromType, getAccountSitesetting, dd_orderTypes, getAccountSessionUserData, getReportRights, isMobileOnly, showMultiError } = functions
  var inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
  var item_category = parseInt(getAccountSitesetting("item_category"))
  var item_subgroupLevel2 = parseInt(getAccountSitesetting("item_subgroupLevel2"))
  const invoice_amendment = parseInt(getAccountSitesetting("invoice_amendment"))
  var response = parameters.response
  if (response?.data?.result?.custom_fields_data && response?.data?.result?.custom_fields_data?.length > 0) {
    response.data.result.custom_fields_data = response.data.result.custom_fields_data.map((e) => {
      const updated = { ...e }
      if (updated.decimalPoint === "7") {
        updated.decimalPoint = inventory_decimalpoint
      }
      return updated
    })
  }
  const ReportId = convertIntif(setconfig.report_id)
  if (ReportId === re.LotDetailReport) {
    const decimal_point = inventory_decimalpoint
    response.data.result.records = response.data.result.records.map((item) => {
      const updatedItem = { ...item }
      updatedItem.tot_inqty = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonlyDecimal(d.inQty, decimal_point))))
      updatedItem.tot_outqty = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonlyDecimal(d.outQty, decimal_point))))

      return updatedItem
    })
  } else if (ReportId === re.ProcessLongation_ShortageReport) {
    var Result = []
    response.data.result.records.forEach((item) => {
      item.DetailGrid.forEach((it) => {
        item.itemname = it.itemname
        item.itemcode = it.itemcode
        item.__cust_longation = it.__cust_longation
        item.__cust_shortage = it.__cust_shortage
        item.inqty = it.inQty
        item.outqty = it.outQty
        item.invoiceType = getNameFromType(item.invoice_type, true, user_data)
        Result.push(item)
      })
    })
    response.data.result.records = Result
  } else if (ReportId === re.LotCostingReport) {
    var Result = {}
    var costing_data = ""
    if (!isblank(response.data.result.records.costing_data)) {
      costing_data = JSON.parse(response.data.result.records.costing_data)
      if (!isblank(costing_data.OtherCharges)) {
        Result.OtherCharges = costing_data.OtherCharges
      }
      Result.costPerQty = costing_data.costPerQty
      Result.modifiedCostPerQty = costing_data.modifiedCostPerQty
      Result.totalAmount = costing_data.totalAmount
      Result.totalQty = costing_data.totalQty
      Result.total_otherCharges = costing_data.total_otherCharges
      dispatch(change("LotCostingReportForm", "OtherCharges", costing_data.OtherCharges))
    } else {
      Result.costPerQty = 0
      Result.modifiedCostPerQty = 0
      Result.totalAmount = 0
      Result.totalQty = 0
      Result.total_otherCharges = 0
    }
    Object.keys(response.data.result.records)
      .filter((e) => e !== "costing_data")
      .forEach((key) => {
        Result[key] = []
        response.data.result.records[key].forEach((item) => {
          if (!isblank(item.billno_str)) {
            item.billno = item.billno_str
          }
          if (key === "raw_material") {
            var newItem = cloneObjV2(item)
            newItem.itemname = item.itemname
            newItem.netamt = item.qty * item.rate
            newItem.itemcode = item.itemcode
            newItem.rate = item.rate
            newItem.itemNarration = item.itemNarration
            newItem.qty = item.qty
            newItem.invoiceType = "Raw Material"
            newItem.code = `${item.itemcode}`
            Result[key].push(newItem)
          } else {
            item.DetailGrid.forEach((it) => {
              var newItem = cloneObjV2(item)
              newItem.itemname = it.itemname
              newItem.netamt = it.netamt
              newItem.itemcode = it.itemcode
              newItem.rate = it.rate
              newItem.itemNarration = it.itemNarration
              newItem.qty = it.qty
              newItem.invoiceType = getNameFromType(item.invoice_type, true, user_data)
              newItem.code = `${item.code}|${it.detailid}`
              if (!isblank(costing_data)) {
                if (key === "job_inward") {
                  if (!isblank(costing_data.job_inward) && costing_data.job_inward.length > 0) {
                    if (costing_data.job_inward.filter((e) => e.code === newItem.code).length > 0) {
                      newItem.netamt = costing_data.job_inward.filter((e) => e.code === newItem.code)[0].netamt
                    }
                  }
                } else if (key === "packing_slip_mix") {
                  if (!isblank(costing_data.packing_slip_mix) && costing_data.packing_slip_mix.length > 0) {
                    if (costing_data.packing_slip_mix.filter((e) => e.code === newItem.code).length > 0) {
                      newItem.lotqty = costing_data.packing_slip_mix.filter((e) => e.code === newItem.code)[0].lotqty
                    }
                  }
                }
              }
              Result[key].push(newItem)
            })
          }
        })
      })
    response.data.result.records = Result
  } else if (ReportId === re.RawMaterialStockInHand) {
    var Result = []
    response.data.result.records.forEach((item) => {
      if (!isblank(item.billno_str)) {
        item.billno = item.billno_str
      }
      item.DetailGrid.forEach((it) => {
        item.detail_itemname = it.detail_itemname
        item.detail_itemcode = it.detail_itemcode
        item.detail_penqty = it.detail_penqty
        item.detail_qty = it.detail_qty
        item.code = it.code
        Result.push(item)
      })
    })
    response.data.result.records = Result
  } else if (ReportId === re.TrialBalance) {
    if (setconfig.viewType === "0") {
      response.data.result.records = response.data.result.records.map((item) => {
        const updatedItem = { ...item }
        if (parseFloat(getNumberonly(updatedItem.Balance)) > 0) {
          updatedItem.CreditAmount = updatedItem.Balance
          updatedItem.DebitAmount = ""
        } else {
          updatedItem.DebitAmount = Math.abs(parseFloat(updatedItem.Balance))
          updatedItem.CreditAmount = ""
        }
        return updatedItem
      })
      response.data.result.records = response.data.result.records.filter((e) => parseFloat(getNumberonly(e.CreditAmount)) > 0 || parseFloat(getNumberonly(e.DebitAmount)) > 0)
    }
  } else if (ReportId === re.SalesOrder) {
    response.data.result.records = response.data.result.records.map((item) => {
      const updatedItem = { ...item }
      updatedItem.invoice_type_label = getdropdownfieldvalue({ dropDownData: dd_orderTypes, field: "id", value: updatedItem.invoice_type, displayvalue: "name" })
      return updatedItem
    })
  } else if (ReportId === re.RawGST && checkCondition(setconfig.type, ["gstr1", "gstr3B"])) {
    const { dditem, dd_taxcode, ddallaccount } = functions
    const newdditem = {}
    dditem.forEach((e, ind) => {
      const new_item = {
        code: e.code,
        name: e.name,
        unit: e.unit,
      }
      newdditem[e.code] = new_item
    })
    const newddallaccount = {}
    ddallaccount.forEach((e, ind) => {
      const new_item = {
        code: e.code,
        name: e.name,
      }
      newddallaccount[e.code] = new_item
    })

    const rawData = cloneObj(parameters)
    const records = cloneObjV2(response.data.result.records)

    var taxCodeArray = []
    var taxCodeArray1 = []
    if (dd_taxcode) {
      if (dd_taxcode.cgst) {
        taxCodeArray = [...taxCodeArray, ...dd_taxcode.cgst.split(",")]
        taxCodeArray1 = [...taxCodeArray1, ...dd_taxcode.cgst.split(",")]
      }
      if (dd_taxcode.sgst) {
        taxCodeArray = [...taxCodeArray, ...dd_taxcode.sgst.split(",")]
      }
      if (dd_taxcode.igst) {
        taxCodeArray = [...taxCodeArray, ...dd_taxcode.igst.split(",")]
        taxCodeArray1 = [...taxCodeArray1, ...dd_taxcode.igst.split(",")]
      }
    }

    var B2B = getTypeData("4A, 4B, 4C, 6B, 6C - B2B Invoices", "B2B")
    var B2BA = getTypeData("4A, 4B, 4C, 6B, 6C - B2BA Invoices", "B2BA")
    var ITC_IG = getTypeData("(1) Import of Goods", "itc_eligible_import_goods")
    var ITC = getTypeData("(5) All other ITC", "itc_eligible_inward_supplies")
    var inward_supplies = getTypeData("(3) Inward supplies liable to reverse charge (other than 1 & 2 above)", "inward_supplies")
    var CDNR = getTypeData("Credit Notes (Registered) 9B", "CDNR")
    var B2CL = getTypeData("5A, 5B - B2C (Large) Invoices", "B2CL")
    var CDNUR = getTypeData("Credit Notes (Unregistered) 9B", "CDNUR")
    var Export_Invoices = getTypeData("6A - Exports Invoices", "Export_Invoices")
    var B2CS = getTypeData("7 - B2C (Others)", "B2CS")
    var NIL = getTypeData("8A, 8B, 8C, 8D - Nil Rated Supplies", "NIL")
    var HSN = getTypeData("12 - HSN-wise summary of outward supplies", "HSN")
    var ZERO = getTypeData("(b) Outward taxable supplies * (zero rated )", "zero_rated_outward_supplies")

    const meta_Data = {
      company_stateCode: getAccountSessionUserData("statecode", user_data),
      setconfig: setconfig,
      dd_country_state: functions.dd_country_state,
    }

    let partyDetailMissing = []

    var uniqueGSTIN = []
    const addUniqueGSTIN = (partyData) => {
      if (!isblank(partyData) && uniqueGSTIN.filter((e) => convertIntif(e.code) === convertIntif(partyData.code)).length === 0) {
        uniqueGSTIN.push(partyData)
      }
    }
    const params = { getAccountSitesetting, user_data, getNameFromType }
    if (!isblank(records.salesInvoice) && records.salesInvoice.length > 0) {
      meta_Data.dataKey = "salesInvoice"
      records.salesInvoice.forEach((e) => {
        if (isblank(e.party_detail)) {
          partyDetailMissing.push({ key: "salesInvoice", data: e })
        } else {
          addUniqueGSTIN(e.party_detail)
          if (convertIntif(e.amend_flag) !== 1) {
            if (checkCondition(setconfig.type, ["gstr1"]) && setconfig.period === 3) {
              if (response.data.result.file_type === "filed") {
                if (e.gstr1_filed_date === setconfig.to_date) {
                  B2B = checkGSTConditions({ mainType: B2B, e: { ...e }, type: "B2B", formType: "salesinvoice", taxCodeArray, taxCodeArray1, meta_Data })
                }
              } else {
                if (e.gstr1_filed !== "1") {
                  B2B = checkGSTConditions({ mainType: B2B, e: { ...e }, type: "B2B", formType: "salesinvoice", taxCodeArray, taxCodeArray1, meta_Data })
                }
              }
            } else {
              B2B = checkGSTConditions({ mainType: B2B, e: { ...e }, type: "B2B", formType: "salesinvoice", taxCodeArray, taxCodeArray1, meta_Data })
            }
            B2CL = checkGSTConditions({ mainType: B2CL, e: { ...e }, type: "B2CL", formType: "salesinvoice", taxCodeArray, taxCodeArray1, meta_Data })
            Export_Invoices = checkGSTConditions({ mainType: Export_Invoices, e: { ...e }, type: "Export_Invoices", formType: "salesinvoice", taxCodeArray, taxCodeArray1, meta_Data })
            B2CS = checkGSTConditions({ mainType: B2CS, e: { ...e }, type: "B2CS", formType: "salesinvoice", taxCodeArray, taxCodeArray1, meta_Data })
            NIL = checkGSTConditions({ mainType: NIL, e: { ...e }, type: "NIL", formType: "salesinvoice", taxCodeArray, taxCodeArray1, meta_Data })
            HSN = checkHSNConditions({ mainType: { ...HSN }, e: { ...e }, dditem: newdditem, ddallaccount: newddallaccount, type: "salesinvoice", functions: params, setconfig })
            ZERO = checkGSTConditions({ mainType: ZERO, e: { ...e }, type: "ZERO", formType: "salesinvoice", taxCodeArray, taxCodeArray1, meta_Data })
          } else {
            B2BA = checkGSTConditions({ mainType: B2BA, e: { ...e }, type: "B2BA", formType: "salesinvoice", taxCodeArray, taxCodeArray1, meta_Data })
          }
        }
      })
    }
    if (!isblank(records.salesReturn) && records.salesReturn.length > 0) {
      meta_Data.dataKey = "salesReturn"
      records.salesReturn.forEach((e) => {
        if (isblank(e.party_detail)) {
          partyDetailMissing.push({ key: "salesReturn", data: e })
        } else {
          addUniqueGSTIN(e.party_detail)
          // NOTE: CHECK VS VP CONDITION IF ANY PROBLEM
          if (e.invType === "S" || e.invType === "VS") {
            B2CS = checkGSTConditions({ mainType: B2CS, e: { ...e }, type: "B2CS", formType: "salesreturn", taxCodeArray, taxCodeArray1, meta_Data, change_sign: true })
            CDNUR = checkGSTConditions({ mainType: CDNUR, e: { ...e }, type: "CDNUR", formType: "salesreturn", taxCodeArray, taxCodeArray1, meta_Data, change_sign: true })

            if (checkCondition(setconfig.type, ["gstr1"]) && setconfig.period === 3) {
              if (response.data.result.file_type === "filed") {
                if (e.gstr1_filed_date === setconfig.to_date) {
                  CDNR = checkGSTConditions({ mainType: CDNR, e: { ...e }, type: "CDNR", formType: "salesreturn", taxCodeArray, taxCodeArray1, meta_Data, change_sign: true })
                }
              } else {
                if (e.gstr1_filed !== "1") {
                  CDNR = checkGSTConditions({ mainType: CDNR, e: { ...e }, type: "CDNR", formType: "salesreturn", taxCodeArray, taxCodeArray1, meta_Data, change_sign: true })
                }
              }
            } else {
              CDNR = checkGSTConditions({ mainType: CDNR, e: { ...e }, type: "CDNR", formType: "salesreturn", taxCodeArray, taxCodeArray1, meta_Data, change_sign: true })
            }

            HSN = checkHSNConditions({ mainType: { ...HSN }, e: { ...e }, dditem: newdditem, ddallaccount: newddallaccount, type: "salesreturn", functions: params, setconfig })
          } else if (e.invType === "PU" || e.invType === "VP") {
            // B2CS = checkGSTConditions(B2CS, {...e}, 'B2CS', 'salesreturn', taxCodeArray, taxCodeArray1, meta_Data, false);
            ITC = checkGSTConditions({ mainType: ITC, e: { ...e }, type: "ITC", formType: "salesreturn", taxCodeArray, taxCodeArray1, meta_Data, change_sign: false })
            ITC_IG = checkGSTConditions({ mainType: ITC_IG, e: { ...e }, type: "ITC_IG", formType: "salesreturn", taxCodeArray, taxCodeArray1, meta_Data, change_sign: false })
            inward_supplies = checkGSTConditions({
              mainType: inward_supplies,
              e: { ...e },
              type: "inward_supplies",
              formType: "salesreturn",
              taxCodeArray,
              taxCodeArray1,
              meta_Data,
              change_sign: false,
            })
          }
        }
      })
    }
    if (!isblank(records.purchaseReturn) && records.purchaseReturn.length > 0) {
      meta_Data.dataKey = "purchaseReturn"
      records.purchaseReturn.forEach((e) => {
        if (isblank(e.party_detail)) {
          partyDetailMissing.push({ key: "purchaseReturn", data: e })
        } else {
          addUniqueGSTIN(e.party_detail)
          if (e.invType === "S" || e.invType === "VS") {
            B2CS = checkGSTConditions({ mainType: B2CS, e: { ...e }, type: "B2CS", formType: "purchasereturn", taxCodeArray, taxCodeArray1, meta_Data })
            CDNUR = checkGSTConditions({ mainType: CDNUR, e: { ...e }, type: "CDNUR", formType: "purchasereturn", taxCodeArray, taxCodeArray1, meta_Data })

            if (checkCondition(setconfig.type, ["gstr1"]) && setconfig.period === 3) {
              if (response.data.result.file_type === "filed") {
                if (e.gstr1_filed_date === setconfig.to_date) {
                  CDNR = checkGSTConditions({ mainType: CDNR, e: { ...e }, type: "CDNR", formType: "purchasereturn", taxCodeArray, taxCodeArray1, meta_Data })
                }
              } else {
                if (e.gstr1_filed !== "1") {
                  CDNR = checkGSTConditions({ mainType: CDNR, e: { ...e }, type: "CDNR", formType: "purchasereturn", taxCodeArray, taxCodeArray1, meta_Data })
                }
              }
            } else {
              CDNR = checkGSTConditions({ mainType: CDNR, e: { ...e }, type: "CDNR", formType: "purchasereturn", taxCodeArray, taxCodeArray1, meta_Data })
            }

            HSN = checkHSNConditions({ mainType: { ...HSN }, e: { ...e }, dditem: newdditem, ddallaccount: newddallaccount, type: "purchasereturn", functions: params, setconfig })
          } else if (e.invType === "PU" || e.invType === "VP") {
            // B2CS = checkGSTConditions(B2CS, {...e}, 'B2CS', 'purchasereturn', taxCodeArray, taxCodeArray1, meta_Data, true);
            // CDNR = checkGSTConditions(CDNR, {...e}, 'CDNR', 'purchasereturn', taxCodeArray, taxCodeArray1, meta_Data);
            // CDNUR = checkGSTConditions(CDNUR, {...e}, 'CDNUR', 'purchasereturn', taxCodeArray, taxCodeArray1, meta_Data);
            ITC = checkGSTConditions({ mainType: ITC, e: { ...e }, type: "ITC", formType: "purchasereturn", taxCodeArray, taxCodeArray1, meta_Data, change_sign: true })

            ITC_IG = checkGSTConditions({ mainType: ITC_IG, e: { ...e }, type: "ITC_IG", formType: "purchasereturn", taxCodeArray, taxCodeArray1, meta_Data, change_sign: true })

            inward_supplies = checkGSTConditions({
              mainType: inward_supplies,
              e: { ...e },
              type: "inward_supplies",
              formType: "purchasereturn",
              taxCodeArray,
              taxCodeArray1,
              meta_Data,
              change_sign: true,
            })
          }
        }
      })
    }
    if (!isblank(records.purchaseInvoice) && records.purchaseInvoice.length > 0) {
      meta_Data.dataKey = "purchaseInvoice"
      records.purchaseInvoice.forEach((e) => {
        if (isblank(e.party_detail)) {
          partyDetailMissing.push({ key: "purchaseInvoice", data: e })
        } else {
          addUniqueGSTIN(e.party_detail)
          ITC = checkGSTConditions({ mainType: ITC, e: { ...e }, type: "ITC", formType: "purchaseinvoice", taxCodeArray, taxCodeArray1, meta_Data, change_sign: false })
          ITC_IG = checkGSTConditions({ mainType: ITC_IG, e: { ...e }, type: "ITC_IG", formType: "purchaseinvoice", taxCodeArray, taxCodeArray1, meta_Data, change_sign: false })
          inward_supplies = checkGSTConditions({
            mainType: inward_supplies,
            e: { ...e },
            type: "inward_supplies",
            formType: "purchaseinvoice",
            taxCodeArray,
            taxCodeArray1,
            meta_Data,
            change_sign: false,
          })
        }
      })
    }
    if (!isblank(records.journalEntry) && records.journalEntry.length > 0) {
      meta_Data.dataKey = "journalEntry"
      records.journalEntry.forEach((e) => {
        if (isblank(e.party_detail)) {
          partyDetailMissing.push({ key: "journalEntry", data: e })
        } else {
          addUniqueGSTIN(e.party_detail)
          ITC = checkJournalEntryConditions({ mainType: ITC, e: { ...e }, formType: "journalEntry", taxCodeArray, taxCodeArray1, meta_Data })
          //ITC_IG = checkJournalEntryConditions(ITC_IG, cloneObj(e), "journalEntry", taxCodeArray, taxCodeArray1, meta_Data)
        }
      })
    }
    if (partyDetailMissing.length > 0) {
      partyDetailMissing = partyDetailMissing.map((e, index) => {
        let updated = {
          docno: index + 1,
        }
        if (e.key === "purchaseInvoice" || e.key === "salesInvoice") {
          updated.status = `${getNameFromType(e.data.invType, false, getAccountSitesetting)}: ${e.data.billno}, Date: ${DatetoDMY(e.data.b_date)} Amount: ${amount_format(e.data.netamt)}`
        } else if (e.key === "salesReturn") {
          updated.status = `Credit Note: ${e.data.billno}, Date: ${DatetoDMY(e.data.b_date)} Amount: ${amount_format(e.data.netamt)}`
        } else if (e.key === "purchaseReturn") {
          updated.status = `Debit Note: ${e.data.billno}, Date: ${DatetoDMY(e.data.b_date)} Amount: ${amount_format(e.data.netamt)}`
        } else if (e.key === "journalEntry") {
          updated.status = `Journal Entry: ${e.data.billno}, Date: ${DatetoDMY(e.data.b_date)} Amount: ${amount_format(e.data.netamount)}`
        }
        return updated
      })
      showMultiError({ dispatch, title: "Party detail missing", error: partyDetailMissing })
    } else {
      if (setconfig.type === "gstr1") {
        response.data.result.records = []
        if (B2B.detailData.records.list.length === 0 && B2B.detailData.records.pending.length === 0) {
          B2B.btype = ""
        }
        response.data.result.records.push(B2B)

        if (invoice_amendment === 1) {
          if (B2BA.detailData.records.list.length === 0 && B2BA.detailData.records.pending.length === 0) {
            B2BA.btype = ""
          }
          response.data.result.records.push(B2BA)
        }

        if (setconfig.period !== 1 && setconfig.period !== 2) {
          if (B2CL.detailData.records.list.length === 0 && B2CL.detailData.records.pending.length === 0) {
            B2CL.btype = ""
          }
          response.data.result.records.push(B2CL)
        }
        if (CDNR.detailData.records.list.length === 0 && CDNR.detailData.records.pending.length === 0) {
          CDNR.btype = ""
        }
        response.data.result.records.push(CDNR)
        if (setconfig.period !== 1 && setconfig.period !== 2) {
          if (CDNUR.detailData.records.list.length === 0 && CDNUR.detailData.records.pending.length === 0) {
            CDNUR.btype = ""
          }
          response.data.result.records.push(CDNUR)
          if (Export_Invoices.detailData.records.list.length === 0 && Export_Invoices.detailData.records.pending.length === 0) {
            Export_Invoices.btype = ""
          }
          response.data.result.records.push(Export_Invoices)
          if (B2CS.detailData.records.list.length === 0 && B2CS.detailData.records.pending.length === 0) {
            B2CS.btype = ""
          }
          response.data.result.records.push(B2CS)
          if (NIL.detailData.records.list.length === 0 && NIL.detailData.records.pending.length === 0) {
            NIL.btype = ""
          }
          response.data.result.records.push(NIL)
          if (HSN.detailData.records.list.length === 0 && HSN.detailData.records.pending.length === 0) {
            HSN.btype = ""
          }
          response.data.result.records.push(HSN)
        }
        response.data.result.uniqueGSTIN = uniqueGSTIN
      } else {
        var newRecords = {
          exempt_supplies: [],
          filed_date: response.data.result.filed_date,
          gst_filed: response.data.result.file_type === "filed",
          gst_summary_month: response.data.result.gst_summary_month,
          interstate_supplies: [],
          itc: [],
          outward_supplies: [],
        }
        var gstr1_igst =
          B2B.detailData.records.detail.tot_igst +
          B2CL.detailData.records.detail.tot_igst +
          B2CS.detailData.records.detail.tot_igst +
          CDNR.detailData.records.detail.tot_igst +
          CDNUR.detailData.records.detail.tot_igst
        var gstr1_cgst =
          B2B.detailData.records.detail.tot_cgst +
          B2CL.detailData.records.detail.tot_cgst +
          B2CS.detailData.records.detail.tot_cgst +
          CDNR.detailData.records.detail.tot_cgst +
          CDNUR.detailData.records.detail.tot_cgst
        var gstr1_sgst =
          B2B.detailData.records.detail.tot_sgst +
          B2CL.detailData.records.detail.tot_sgst +
          B2CS.detailData.records.detail.tot_sgst +
          CDNR.detailData.records.detail.tot_sgst +
          CDNUR.detailData.records.detail.tot_sgst

        var gstr1_totaltaxamt = B2B.Taxable_Amt + B2CL.Taxable_Amt + B2CS.Taxable_Amt + CDNR.Taxable_Amt + CDNUR.Taxable_Amt

        //const keyArray = ["amount", "cgst_amount", "igst_amount", "netamount", "sgst_amount", "tax_amount"]

        newRecords.outward_supplies.push({
          CESS_Amt: "--",
          CGST_Amt: gstr1_cgst,
          Diff_CGST_Amt: 0,
          Diff_IGST_Amt: 0,
          Diff_SGST_Amt: 0,
          Diff_Taxable_Amt: 0,
          IGST_Amt: gstr1_igst,
          Oldinvoice_flag: B2B.Oldinvoice_flag + B2CL.Oldinvoice_flag + B2CS.Oldinvoice_flag + CDNR.Oldinvoice_flag + CDNUR.Oldinvoice_flag,
          GSTR3BPendindinovice_flag: B2B.GSTR3BPendindinovice_flag + B2CL.GSTR3BPendindinovice_flag + B2CS.GSTR3BPendindinovice_flag + CDNR.GSTR3BPendindinovice_flag + CDNUR.GSTR3BPendindinovice_flag,
          GSTR1Pendindinovice_flag: B2B.GSTR1Pendindinovice_flag + B2CL.GSTR1Pendindinovice_flag + B2CS.GSTR1Pendindinovice_flag + CDNR.GSTR1Pendindinovice_flag + CDNUR.GSTR1Pendindinovice_flag,
          Previousinvoice_flag: B2B.Previousinvoice_flag + B2CL.Previousinvoice_flag + B2CS.Previousinvoice_flag + CDNR.Previousinvoice_flag + CDNUR.Previousinvoice_flag,
          Re_CGST_Amt: gstr1_cgst,
          Re_IGST_Amt: gstr1_igst,
          Re_SGST_Amt: gstr1_sgst,
          Re_Taxable_Amt: gstr1_totaltaxamt,
          Re_Type: "Government Data",
          SGST_Amt: gstr1_sgst,
          Taxable_Amt: gstr1_totaltaxamt,
          Type: "(a) Outward taxable supplies * (other than zero rated, nil rated and exempted)",
          btype: "taxable_outward_supplies",
          detailData: {
            records: {
              detail: {
                tot_cess:
                  B2B.detailData.records.detail.tot_cess +
                  B2CL.detailData.records.detail.tot_cess +
                  B2CS.detailData.records.detail.tot_cess +
                  CDNR.detailData.records.detail.tot_cess +
                  CDNUR.detailData.records.detail.tot_cess,
                tot_cgst:
                  B2B.detailData.records.detail.tot_cgst +
                  B2CL.detailData.records.detail.tot_cgst +
                  B2CS.detailData.records.detail.tot_cgst +
                  CDNR.detailData.records.detail.tot_cgst +
                  CDNUR.detailData.records.detail.tot_cgst,
                tot_igst:
                  B2B.detailData.records.detail.tot_igst +
                  B2CL.detailData.records.detail.tot_igst +
                  B2CS.detailData.records.detail.tot_igst +
                  CDNR.detailData.records.detail.tot_igst +
                  CDNUR.detailData.records.detail.tot_igst,
                tot_netamount:
                  B2B.detailData.records.detail.tot_netamount +
                  B2CL.detailData.records.detail.tot_netamount +
                  B2CS.detailData.records.detail.tot_netamount +
                  CDNR.detailData.records.detail.tot_netamount +
                  CDNUR.detailData.records.detail.tot_netamount,
                tot_sgst:
                  B2B.detailData.records.detail.tot_sgst +
                  B2CL.detailData.records.detail.tot_sgst +
                  B2CS.detailData.records.detail.tot_sgst +
                  CDNR.detailData.records.detail.tot_sgst +
                  CDNUR.detailData.records.detail.tot_sgst,
                tot_transaction:
                  B2B.detailData.records.detail.tot_transaction +
                  B2CL.detailData.records.detail.tot_transaction +
                  B2CS.detailData.records.detail.tot_transaction +
                  CDNR.detailData.records.detail.tot_transaction +
                  CDNUR.detailData.records.detail.tot_transaction,
              },
              list: [...B2B.detailData.records.list, ...B2CL.detailData.records.list, ...B2CS.detailData.records.list, ...CDNR.detailData.records.list, ...CDNUR.detailData.records.list],
              pending: [
                ...B2B.detailData.records.pending,
                ...B2CL.detailData.records.pending,
                ...B2CS.detailData.records.pending,
                ...CDNR.detailData.records.pending,
                ...CDNUR.detailData.records.pending,
              ],
            },
          },
        })

        newRecords.outward_supplies.push({
          CESS_Amt: "--",
          CGST_Amt: ZERO.detailData.records.detail.tot_cgst,
          Diff_CGST_Amt: 0,
          Diff_IGST_Amt: 0,
          Diff_SGST_Amt: 0,
          Diff_Taxable_Amt: 0,
          IGST_Amt: ZERO.detailData.records.detail.tot_igst,
          Oldinvoice_flag: ZERO.Oldinvoice_flag,
          GSTR3BPendindinovice_flag: ZERO.GSTR3BPendindinovice_flag,
          GSTR1Pendindinovice_flag: ZERO.GSTR1Pendindinovice_flag,
          Previousinvoice_flag: ZERO.Previousinvoice_flag,
          Re_CGST_Amt: ZERO.detailData.records.detail.tot_cgst,
          Re_IGST_Amt: ZERO.detailData.records.detail.tot_igst,
          Re_SGST_Amt: ZERO.detailData.records.detail.tot_sgst,
          Re_Taxable_Amt: ZERO.Taxable_Amt,
          Re_Type: "Government Data",
          SGST_Amt: ZERO.detailData.records.detail.tot_sgst,
          Taxable_Amt: ZERO.Taxable_Amt,
          Type: "(b) Outward taxable supplies * (zero rated )",
          btype: "zero_rated_outward_supplies",
          detailData: ZERO.detailData,
        })

        newRecords.outward_supplies.push({
          CESS_Amt: "--",
          CGST_Amt: NIL.detailData.records.detail.tot_cgst,
          Diff_CGST_Amt: 0,
          Diff_IGST_Amt: 0,
          Diff_SGST_Amt: 0,
          Diff_Taxable_Amt: 0,
          IGST_Amt: NIL.detailData.records.detail.tot_igst,
          Oldinvoice_flag: NIL.Oldinvoice_flag,
          GSTR3BPendindinovice_flag: NIL.GSTR3BPendindinovice_flag,
          GSTR1Pendindinovice_flag: NIL.GSTR1Pendindinovice_flag,
          Previousinvoice_flag: NIL.Previousinvoice_flag,
          Re_CGST_Amt: NIL.detailData.records.detail.tot_cgst,
          Re_IGST_Amt: NIL.detailData.records.detail.tot_igst,
          Re_SGST_Amt: NIL.detailData.records.detail.tot_sgst,
          Re_Taxable_Amt: NIL.Taxable_Amt,
          Re_Type: "Government Data",
          SGST_Amt: NIL.detailData.records.detail.tot_sgst,
          Taxable_Amt: NIL.Taxable_Amt,
          Type: "(c) Other outward supplies (Nil rated, exempted)",
          btype: "other_outward_supplies",
          detailData: NIL.detailData,
        })

        newRecords.outward_supplies.push({
          CESS_Amt: "--",
          CGST_Amt: inward_supplies.detailData.records.detail.tot_cgst,
          Diff_CGST_Amt: 0,
          Diff_IGST_Amt: 0,
          Diff_SGST_Amt: 0,
          IGST_Amt: inward_supplies.detailData.records.detail.tot_igst,
          Oldinvoice_flag: inward_supplies.Oldinvoice_flag,
          GSTR3BPendindinovice_flag: inward_supplies.GSTR3BPendindinovice_flag,
          GSTR1Pendindinovice_flag: inward_supplies.GSTR1Pendindinovice_flag,
          Previousinvoice_flag: inward_supplies.Previousinvoice_flag,
          Re_CGST_Amt: inward_supplies.detailData.records.detail.tot_cgst,
          Re_IGST_Amt: inward_supplies.detailData.records.detail.tot_igst,
          Re_SGST_Amt: inward_supplies.detailData.records.detail.tot_sgst,
          Re_Type: "Government Data",
          Re_Taxable_Amt: inward_supplies.Taxable_Amt,
          SGST_Amt: inward_supplies.detailData.records.detail.tot_sgst,
          Taxable_Amt: inward_supplies.Taxable_Amt,
          Type: "(d) Inward supplies (liable to reverse charge)",
          btype: "inward_supplies",
          detailData: inward_supplies.detailData,
        })

        var outwardSuppliesTotalRow = {
          Taxable_Amt: 0,
          IGST_Amt: 0,
          CGST_Amt: 0,
          SGST_Amt: 0,
          CESS_Amt: "--",
          Re_CGST_Amt: 0,
          Re_IGST_Amt: 0,
          Re_SGST_Amt: 0,
          Re_Type: "Government Data",
          Type: "Total",
          btype: "",
          detailData: [],
        }
        newRecords.outward_supplies.forEach((e) => {
          outwardSuppliesTotalRow.Taxable_Amt = parseFloat(getNumberonly(outwardSuppliesTotalRow.Taxable_Amt)) + parseFloat(getNumberonly(e.Taxable_Amt))
          outwardSuppliesTotalRow.IGST_Amt = parseFloat(getNumberonly(outwardSuppliesTotalRow.IGST_Amt)) + parseFloat(getNumberonly(e.IGST_Amt))
          outwardSuppliesTotalRow.CGST_Amt = parseFloat(getNumberonly(outwardSuppliesTotalRow.CGST_Amt)) + parseFloat(getNumberonly(e.CGST_Amt))
          outwardSuppliesTotalRow.SGST_Amt = parseFloat(getNumberonly(outwardSuppliesTotalRow.SGST_Amt)) + parseFloat(getNumberonly(e.SGST_Amt))
          outwardSuppliesTotalRow.Re_CGST_Amt = parseFloat(getNumberonly(outwardSuppliesTotalRow.Re_CGST_Amt)) + parseFloat(getNumberonly(e.Re_CGST_Amt))
          outwardSuppliesTotalRow.Re_IGST_Amt = parseFloat(getNumberonly(outwardSuppliesTotalRow.Re_IGST_Amt)) + parseFloat(getNumberonly(e.Re_IGST_Amt))
          outwardSuppliesTotalRow.Re_SGST_Amt = parseFloat(getNumberonly(outwardSuppliesTotalRow.Re_SGST_Amt)) + parseFloat(getNumberonly(e.Re_SGST_Amt))
        })
        newRecords.outward_supplies.push(outwardSuppliesTotalRow)

        newRecords.itc.push({
          CESS_Amt: "--",
          CGST_Amt: ITC_IG.detailData.records.detail.tot_cgst,
          Diff_CGST_Amt: 0,
          Diff_IGST_Amt: 0,
          Diff_SGST_Amt: 0,
          IGST_Amt: ITC_IG.detailData.records.detail.tot_igst,
          Oldinvoice_flag: ITC_IG.Oldinvoice_flag,
          GSTR3BPendindinovice_flag: ITC_IG.GSTR3BPendindinovice_flag,
          GSTR1Pendindinovice_flag: ITC_IG.GSTR1Pendindinovice_flag,
          Previousinvoice_flag: ITC_IG.Previousinvoice_flag,
          Re_CGST_Amt: ITC_IG.detailData.records.detail.tot_cgst,
          Re_IGST_Amt: ITC_IG.detailData.records.detail.tot_igst,
          Re_SGST_Amt: ITC_IG.detailData.records.detail.tot_sgst,
          Re_Type: "Government Data",
          SGST_Amt: ITC_IG.detailData.records.detail.tot_sgst,
          Type: "(1) Import Goods",
          btype: "itc_eligible_import_goods",
          detailData: ITC_IG.detailData,
        })

        newRecords.itc.push({
          CESS_Amt: "--",
          CGST_Amt: inward_supplies.detailData.records.detail.tot_cgst,
          Diff_CGST_Amt: 0,
          Diff_IGST_Amt: 0,
          Diff_SGST_Amt: 0,
          IGST_Amt: inward_supplies.detailData.records.detail.tot_igst,
          Oldinvoice_flag: inward_supplies.Oldinvoice_flag,
          GSTR3BPendindinovice_flag: inward_supplies.GSTR3BPendindinovice_flag,
          GSTR1Pendindinovice_flag: inward_supplies.GSTR1Pendindinovice_flag,
          Previousinvoice_flag: inward_supplies.Previousinvoice_flag,
          Re_CGST_Amt: inward_supplies.detailData.records.detail.tot_cgst,
          Re_IGST_Amt: inward_supplies.detailData.records.detail.tot_igst,
          Re_SGST_Amt: inward_supplies.detailData.records.detail.tot_sgst,
          Re_Type: "Government Data",
          SGST_Amt: inward_supplies.detailData.records.detail.tot_sgst,
          Type: "(3) Inward supplies liable to reverse charge (other than 1 & 2 above)",
          btype: "inward_supplies",
          detailData: inward_supplies.detailData,
        })

        newRecords.itc.push({
          CESS_Amt: "--",
          CGST_Amt: ITC.detailData.records.detail.tot_cgst,
          Diff_CGST_Amt: 0,
          Diff_IGST_Amt: 0,
          Diff_SGST_Amt: 0,
          IGST_Amt: ITC.detailData.records.detail.tot_igst,
          Oldinvoice_flag: ITC.Oldinvoice_flag,
          GSTR3BPendindinovice_flag: ITC.GSTR3BPendindinovice_flag,
          GSTR1Pendindinovice_flag: ITC.GSTR1Pendindinovice_flag,
          Previousinvoice_flag: ITC.Previousinvoice_flag,
          Re_CGST_Amt: ITC.detailData.records.detail.tot_cgst,
          Re_IGST_Amt: ITC.detailData.records.detail.tot_igst,
          Re_SGST_Amt: ITC.detailData.records.detail.tot_sgst,
          Re_Type: "Government Data",
          SGST_Amt: ITC.detailData.records.detail.tot_sgst,
          Type: "(5) All other ITC",
          btype: "itc_eligible_inward_supplies",
          detailData: ITC.detailData,
        })

        var itcTotalRow = {
          CESS_Amt: "--",
          CGST_Amt: 0,
          Diff_CGST_Amt: 0,
          Diff_IGST_Amt: 0,
          Diff_SGST_Amt: 0,
          IGST_Amt: 0,
          Oldinvoice_flag: 0,
          GSTR3BPendindinovice_flag: 0,
          GSTR1Pendindinovice_flag: 0,
          Previousinvoice_flag: 0,
          Re_CGST_Amt: 0,
          Re_IGST_Amt: 0,
          Re_SGST_Amt: 0,
          Re_Type: "Government Data",
          SGST_Amt: 0,
          Type: "Total",
          btype: "",
          detailData: [],
        }
        newRecords.itc.forEach((e) => {
          itcTotalRow.CGST_Amt = parseFloat(getNumberonly(itcTotalRow.CGST_Amt)) + parseFloat(getNumberonly(e.CGST_Amt))
          itcTotalRow.Diff_CGST_Amt = parseFloat(getNumberonly(itcTotalRow.Diff_CGST_Amt)) + parseFloat(getNumberonly(e.Diff_CGST_Amt))
          itcTotalRow.Diff_IGST_Amt = parseFloat(getNumberonly(itcTotalRow.Diff_IGST_Amt)) + parseFloat(getNumberonly(e.Diff_IGST_Amt))
          itcTotalRow.Diff_SGST_Amt = parseFloat(getNumberonly(itcTotalRow.Diff_SGST_Amt)) + parseFloat(getNumberonly(e.Diff_SGST_Amt))
          itcTotalRow.IGST_Amt = parseFloat(getNumberonly(itcTotalRow.IGST_Amt)) + parseFloat(getNumberonly(e.IGST_Amt))
          itcTotalRow.Re_CGST_Amt = parseFloat(getNumberonly(itcTotalRow.Re_CGST_Amt)) + parseFloat(getNumberonly(e.Re_CGST_Amt))
          itcTotalRow.Re_IGST_Amt = parseFloat(getNumberonly(itcTotalRow.Re_IGST_Amt)) + parseFloat(getNumberonly(e.Re_IGST_Amt))
          itcTotalRow.Re_SGST_Amt = parseFloat(getNumberonly(itcTotalRow.Re_SGST_Amt)) + parseFloat(getNumberonly(e.Re_SGST_Amt))
          itcTotalRow.SGST_Amt = parseFloat(getNumberonly(itcTotalRow.SGST_Amt)) + parseFloat(getNumberonly(e.SGST_Amt))
        })
        newRecords.itc.push(itcTotalRow)

        meta_Data.taxCodeArray = taxCodeArray
        meta_Data.taxCodeArray1 = taxCodeArray1
        newRecords.interstate_supplies = generateInterstate_supplies({ records, functions, meta_Data })

        var interstateSuppliesTotalRow = {
          Taxable_Amt: 0,
          IGST_Amt: 0,
          Re_Type: "Government Data",
          Type: "Total",
          btype: "",
          detailData: [],
        }
        newRecords.interstate_supplies.forEach((e) => {
          interstateSuppliesTotalRow.Taxable_Amt = parseFloat(getNumberonly(interstateSuppliesTotalRow.Taxable_Amt)) + parseFloat(getNumberonly(e.Taxable_Amt))
          interstateSuppliesTotalRow.IGST_Amt = parseFloat(getNumberonly(interstateSuppliesTotalRow.IGST_Amt)) + parseFloat(getNumberonly(e.IGST_Amt))
        })
        newRecords.interstate_supplies.push(interstateSuppliesTotalRow)

        const getGstr1Error = (dataArray) => {
          var returnValue = []
          dataArray.forEach((e) => {
            if (!isblank(e.detailData) && !isblank(e.detailData) && !isblank(e.detailData.records) && !isblank(e.detailData.records.list) && e.detailData.records.list.length > 0) {
              e.detailData.records.list.forEach((i) => {
                // if (i.gstr1_filed === "Not Filed") {
                if (i.gstr1_filed === "0") {
                  if (!isblank(i.formData) && (i.formData.type === "purchasereturn" || i.formData.type === "salesreturn")) {
                    if (i.formData.other_type === "S") {
                      returnValue.push(i)
                    }
                  } else {
                    returnValue.push(i)
                  }
                }
              })
            }
          })
          return returnValue
        }

        const addCount = (dataArray) => {
          return dataArray.map((e) => {
            const updated = { ...e }
            updated.invoices = ""
            if (!isblank(updated.detailData) && !isblank(updated.detailData.records) && !isblank(updated.detailData.records.list) && updated.detailData.records.list.length > 0) {
              updated.invoices = updated.detailData.records.list.length
            }
            return updated
          })
        }

        newRecords.exempt_supplies = addCount(newRecords.exempt_supplies)
        newRecords.interstate_supplies = addCount(newRecords.interstate_supplies)
        newRecords.itc = addCount(newRecords.itc)
        newRecords.outward_supplies = addCount(newRecords.outward_supplies)
        newRecords.gstr1Error = []
        newRecords.gstr1Error = [...getGstr1Error(newRecords.exempt_supplies)]
        newRecords.gstr1Error = [...newRecords.gstr1Error, ...getGstr1Error(newRecords.interstate_supplies)]
        newRecords.gstr1Error = [...newRecords.gstr1Error, ...getGstr1Error(newRecords.outward_supplies)]
        response.data.result.records = newRecords
      }
      dispatch({
        type: GST_SET_DETAIL,
        rawData: rawData,
      })
    }
  } else if (ReportId === re.RawGST && checkCondition(setconfig.type, ["all"])) {
    /*------------*/

    const params = { getAccountSitesetting, user_data, getNameFromType }
    response = common_setGSTSummaryData({ response, functions, params, setconfig })
  } else if (checkCondition(ReportId, [re.MultiLedgerReport])) {
    if (setconfig.type === "Detail") {
      if (setconfig.other_type === "All") {
        response.data.result.records = response.data.result.records.map((e) => {
          const updatedItem = { ...e }
          updatedItem.credit = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.credit))))
          updatedItem.debit = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.debit))))
          updatedItem.balance = updatedItem.credit - updatedItem.debit
          return updatedItem
        })
      } else {
        response.data.result.records = response.data.result.records.map((e) => {
          const updatedItem = { ...e }
          updatedItem.DetailGrid = updatedItem.DetailGrid.map((i) => {
            const updated = { ...i }
            updated.credit = lodashSum(lodashMap(updated.DetailGrid, (d) => parseFloat(getNumberonly(d.credit))))
            updated.debit = lodashSum(lodashMap(updated.DetailGrid, (d) => parseFloat(getNumberonly(d.debit))))
            updated.balance = updated.credit - updated.debit
            return updated
          })
          updatedItem.credit = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.credit))))
          updatedItem.debit = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.debit))))
          updatedItem.balance = updatedItem.credit - updatedItem.debit
          return updatedItem
        })
      }
    } else {
      if (setconfig.other_type !== "All") {
        response.data.result.records = response.data.result.records.map((e) => {
          const updatedItem = { ...e }
          updatedItem.credit = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.credit))))
          updatedItem.debit = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.debit))))
          updatedItem.balance = updatedItem.credit - updatedItem.debit
          return updatedItem
        })
      }
    }
  } else if (checkCondition(ReportId, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport])) {
    const setDetail = (updatedItem) => {
      if (checkCondition(ReportId, [re.MultiOutstandingReport])) {
        updatedItem.amount = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.amount))))
        updatedItem.balance = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.balance))))
        updatedItem.onaccount = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.onaccount))))
        updatedItem.outstanding = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.outstanding))))
      } else {
        updatedItem["30Days"] = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d["30Days"]))))
        updatedItem["60Days"] = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d["60Days"]))))
        updatedItem["90Days"] = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d["90Days"]))))
        updatedItem["120Days"] = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d["120Days"]))))
        updatedItem.NetAmount = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.NetAmount))))
        updatedItem.onAccountNetAmount = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.onAccountNetAmount))))
        updatedItem.outstanding = parseFloat(getNumberonly(updatedItem.NetAmount)) - parseFloat(getNumberonly(updatedItem.onAccountNetAmount))
      }
      return updatedItem
    }

    const setDetail1 = (updatedItem) => {
      if (checkCondition(ReportId, [re.MultiOutstandingReport])) {
        updatedItem.amount = lodashSum(lodashMap(updatedItem.bills, (d) => parseFloat(getNumberonly(d.amount))))
        updatedItem.onaccount = lodashSum(lodashMap(updatedItem.onaccountDetail, (d) => parseFloat(getNumberonly(d.outstanding))))
        updatedItem.outstanding = lodashSum(lodashMap(updatedItem.bills, (d) => parseFloat(getNumberonly(d.outstanding))))
        updatedItem.balance = updatedItem.outstanding - updatedItem.onaccount
        if (!isblank(updatedItem.bills) && updatedItem.bills.length > 0) {
          updatedItem.bills = updatedItem.bills.map((it) => {
            const updated = { ...it }
            updated.description = replaceBRtoNewLine(it.description)
            return updated
          })
        }

        if (!isblank(updatedItem.onaccountDetail) && updatedItem.onaccountDetail.length > 0) {
          updatedItem.onaccountDetail = updatedItem.onaccountDetail.map((it) => {
            const updated = { ...it }
            updated.description = replaceBRtoNewLine(it.description)
            return updated
          })
        }
      } else {
        updatedItem["30Days"] = lodashSum(lodashMap(updatedItem.bills, (d) => parseFloat(getNumberonly(d["30Days"]))))
        updatedItem["60Days"] = lodashSum(lodashMap(updatedItem.bills, (d) => parseFloat(getNumberonly(d["60Days"]))))
        updatedItem["90Days"] = lodashSum(lodashMap(updatedItem.bills, (d) => parseFloat(getNumberonly(d["90Days"]))))
        updatedItem["120Days"] = lodashSum(lodashMap(updatedItem.bills, (d) => parseFloat(getNumberonly(d["120Days"]))))

        updatedItem.NetAmount = lodashSum(lodashMap(updatedItem.bills, (d) => parseFloat(getNumberonly(d.NetAmount))))
        updatedItem.onAccountNetAmount = lodashSum(lodashMap(updatedItem.onaccountDetail, (d) => parseFloat(getNumberonly(d.NetAmount))))
        updatedItem.outstanding = parseFloat(getNumberonly(updatedItem.NetAmount)) - parseFloat(getNumberonly(updatedItem.onAccountNetAmount))
      }
      return updatedItem
    }
    const setDetail2 = (updatedItem) => {
      if (checkCondition(ReportId, [re.MultiOutstandingReport])) {
        updatedItem.amount = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.amount))))
        updatedItem.balance = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.balance))))
        updatedItem.onaccount = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.onaccount))))
        updatedItem.outstanding = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.outstanding))))
      } else {
        updatedItem["30Days"] = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d["30Days"]))))
        updatedItem["60Days"] = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d["60Days"]))))
        updatedItem["90Days"] = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d["90Days"]))))
        updatedItem["120Days"] = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d["120Days"]))))
        updatedItem.NetAmount = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.NetAmount))))
        updatedItem.onAccountNetAmount = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonly(d.onAccountNetAmount))))
        updatedItem.outstanding = parseFloat(getNumberonly(updatedItem.NetAmount)) - parseFloat(getNumberonly(updatedItem.onAccountNetAmount))
      }
      return updatedItem
    }

    if (setconfig.type === "Detail") {
      if (setconfig.other_type === "All") {
        if (convertIntif(setconfig.reportViewType) === 1) {
          response.data.result.records = response.data.result.records.map((e) => {
            return setDetail1({ ...e })
          })
        } else {
          response.data.result.records = response.data.result.records.map((e) => {
            return setDetail({ ...e })
          })
        }
      } else {
        if (convertIntif(setconfig.reportViewType) === 1) {
          response.data.result.records = response.data.result.records.map((e) => {
            var updatedItem = { ...e }
            updatedItem.DetailGrid = updatedItem.DetailGrid.map((i) => {
              return setDetail1({ ...i })
            })
            updatedItem = setDetail({ ...updatedItem })
            return updatedItem
          })
        } else {
          response.data.result.records = response.data.result.records.map((e) => {
            var updatedItem = { ...e }
            updatedItem.DetailGrid = updatedItem.DetailGrid.map((i) => {
              return setDetail({ ...i })
            })
            updatedItem = setDetail({ ...updatedItem })
            return updatedItem
          })
        }
      }
    } else {
      if (setconfig.other_type !== "All") {
        if (convertIntif(setconfig.reportViewType) === 1) {
          response.data.result.records = response.data.result.records.map((e) => {
            return setDetail2({ ...e })
          })
        } else {
          response.data.result.records = response.data.result.records.map((e) => {
            return setDetail({ ...e })
          })
        }
      }
    }
  } else if (ReportId === re.AmendBillsReport) {
    const newRecords = []
    const setAmendData = (dataArray) => {
      return dataArray.map((e) => {
        const updated = { ...e }
        const before_amend_invoice = JSON.parse(e.before_amend_invoice)
        updated.netamt_old = before_amend_invoice.master_table_data[0].netamt
        updated.gstr1_filed_date = setFiledDateToolTip(e, "gstr1")
        updated.gstr3B_filed_date = setFiledDateToolTip(e, "gstr3b")
        updated.gstr3B_filed = e.gstr3B_filed
        updated.gstr1_filed = e.gstr1_filed
        return updated
      })
    }
    if (!isblank(response.data.result.records.salesInvoice) && response.data.result.records.salesInvoice.length > 0) {
      newRecords.push({
        type: "salesInvoice",
        label: "Sales Invoice",
        detailGrid: setAmendData(response.data.result.records.salesInvoice),
      })
    }
    if (!isblank(response.data.result.records.purchaseInvoice) && response.data.result.records.purchaseInvoice.length > 0) {
      newRecords.push({
        type: "purchaseInvoice",
        label: "Purchase Invoice",
        detailGrid: setAmendData(response.data.result.records.purchaseInvoice),
      })
    }
    if (!isblank(response.data.result.records.salesReturn) && response.data.result.records.salesReturn.length > 0) {
      newRecords.push({
        type: "salesReturn",
        label: "Credit Note",
        detailGrid: setAmendData(response.data.result.records.salesReturn),
      })
    }
    if (!isblank(response.data.result.records.purchaseReturn) && response.data.result.records.purchaseReturn.length > 0) {
      newRecords.push({
        type: "purchaseReturn",
        label: "Debit Note",
        detailGrid: setAmendData(response.data.result.records.purchaseReturn),
      })
    }
    response.data.result.records = [...newRecords]
  } else if (ReportId === re.ItemGroupSummaryReport && !isMobileOnly) {
    var removeCode = false
    if (item_category === 1) {
      removeCode = !getReportRights(re.ItemSubGroupSummaryReport)
    } else {
      removeCode = !getReportRights(re.ClosingReport)
    }
    if (removeCode) {
      response.data.result.records = response.data.result.records.map((e) => {
        const updated = { ...e }
        updated.Productgroupcode = ""
        return updated
      })
    }
  } else if (ReportId === re.ItemSubGroupSummaryReport && !isMobileOnly) {
    var removeCode = false
    if (item_subgroupLevel2 === 1) {
      removeCode = !getReportRights(re.ItemSubGroupLevel2SummaryReport)
    } else {
      removeCode = !getReportRights(re.ClosingReport)
    }
    if (removeCode) {
      response.data.result.records = response.data.result.records.map((e) => {
        const updated = { ...e }
        updated.Productsubgroupcode = ""
        return updated
      })
    }
  } else if (ReportId === re.ItemSubGroupLevel2SummaryReport && !isMobileOnly) {
    var removeCode = !getReportRights(re.ClosingReport)
    if (removeCode) {
      response.data.result.records = response.data.result.records.map((e) => {
        const updated = { ...e }
        updated.ProductsubgroupLevel2code = ""
        return updated
      })
    }
  } else if (ReportId === re.DaybookReport) {
    response.data.result.records = response.data.result.records.map((e) => {
      const updated = { ...e }
      updated.inv_type = capitalizeFormat(updated.trantype)
      return updated
    })
  } else if (ReportId === re.AgentWiseSalesReport || ReportId === re.TransportWiseSalesReport) {
    if (setconfig.type === "detail") {
      if (setconfig.other_type === "0") {
        response.data.result.records = response.data.result.records.map((e) => {
          const updated = { ...e }
          updated.DetailGrid = updated.DetailGrid.map((i) => {
            const updatedItem = { ...i }
            updatedItem.invoiceType = capitalizeFormat(updatedItem.invoice_type)
            return updatedItem
          })
          return updated
        })
      } else {
        response.data.result.records = response.data.result.records.map((e) => {
          const updatedE = { ...e }
          updatedE.DetailGrid = updatedE.DetailGrid.map((j) => {
            const updated = { ...j }
            updated.DetailDetailGrid = updated.DetailDetailGrid.map((i) => {
              const updatedItem = { ...i }
              updatedItem.invoiceType = capitalizeFormat(updatedItem.invoice_type)
              return updatedItem
            })
            return updated
          })
          return updatedE
        })
      }
    }

    if (convertIntif(setconfig.other_type) === 1) {
      response.data.result.records = response.data.result.records.map((e) => {
        const updated = { ...e }
        updated.netamt = lodashSum(lodashMap(updated.DetailGrid, (d) => parseFloat(getNumberonly(d.netamt))))
        if (ReportId === re.AgentWiseSalesReport) {
          updated.agentcomm_amt = lodashSum(lodashMap(updated.DetailGrid, (d) => parseFloat(getNumberonly(d.agentcomm_amt))))
        }
        return updated
      })
    }
  } else if (ReportId === re.MissingBillNumber) {
    response.data.result.records = response.data.result.records.map((e) => {
      const updated = { ...e }
      updated.invoiceType = capitalizeFormat(updated.invoice_type)
      updated.missingBillNumber = replaceAllFn(updated.missingBillNumber, ",", ", ")
      return updated
    })
  } else if (
    ReportId === re.ProductWiseStockReport ||
    (ReportId === re.ClosingReport && setconfig.reportViewType === "detail")
    // ReportId === re.FinishStockBarcodeReport
  ) {
    response.data.result.records = response.data.result.records.map((e) => {
      const updated = { ...e }
      updated.InQty = lodashSum(lodashMap(updated.DetailGrid, (d) => parseFloat(getNumberonlyDecimal(d.In, inventory_decimalpoint))))
      updated.OutQty = lodashSum(lodashMap(updated.DetailGrid, (d) => parseFloat(getNumberonlyDecimal(d.Out, inventory_decimalpoint))))
      updated.ClosingQty = updated.DetailGrid[updated.DetailGrid.length - 1].Balance
      updated.Amount = updated.DetailGrid[updated.DetailGrid.length - 1].Amount
      return updated
    })
  } else if (ReportId === re.FinishStockBarcodeReport || (ReportId === re.FinishStockInOutReport && setconfig.reportViewType === "detail")) {
    response.data.result.records = response.data.result.records.map((e) => {
      const updated = { ...e }
      updated.In = lodashSum(lodashMap(updated.DetailGrid, (d) => parseFloat(getNumberonlyDecimal(d.In, inventory_decimalpoint))))
      updated.Out = lodashSum(lodashMap(updated.DetailGrid, (d) => parseFloat(getNumberonlyDecimal(d.Out, inventory_decimalpoint))))
      updated.Balance = updated.DetailGrid[updated.DetailGrid.length - 1].Balance
      updated.Amount = updated.DetailGrid[updated.DetailGrid.length - 1].Amount
      return updated
    })
  } else if (ReportId === re.ProductBatchDetailReport || ReportId === re.ProductSerialNumberReport) {
    response.data.result.records = response.data.result.records.map((e) => {
      const updated = { ...e }
      updated.qin = lodashSum(lodashMap(updated.DetailGrid, (d) => parseFloat(getNumberonlyDecimal(d.qin, inventory_decimalpoint))))
      updated.qout = lodashSum(lodashMap(updated.DetailGrid, (d) => parseFloat(getNumberonlyDecimal(d.qout, inventory_decimalpoint))))
      updated.bal = lodashSum(lodashMap(updated.DetailGrid, (d) => parseFloat(getNumberonlyDecimal(d.bal, inventory_decimalpoint))))
      return updated
    })
  } else if (ReportId === re.BalanceSheet || ReportId === re.P_LAccount || ReportId === re.TradingWithP_LReport || ReportId === re.TradingAccount) {
    response.data.result.records = response.data.result.records.map((e) => {
      const updated = { ...e }
      updated.c_Link = true
      updated.c_bold = true
      updated.d_Link = true
      updated.d_bold = true
      if (isblank(updated.CCode) && updated.CGCode) {
        updated.CCode = updated.CGCode
      }
      if (isblank(updated.DCode) && updated.DGCode) {
        updated.DCode = updated.DGCode
      }
      if (
        !(updated.CGCode && updated.CCode) &&
        updated.CDescription !== "Gross Profit" &&
        updated.CDescription !== "Gross Loss" &&
        updated.CDescription !== "Net Profit" &&
        updated.CDescription !== "Net Loss"
      ) {
        updated.c_Link = ""
      }
      if (
        !(updated.DGCode && updated.DCode) &&
        updated.DDescription !== "Gross Profit" &&
        updated.DDescription !== "Gross Loss" &&
        updated.DDescription !== "Net Profit" &&
        updated.DDescription !== "Net Loss"
      ) {
        updated.d_Link = ""
      }
      if (updated.CGCode === updated.CCode) {
        updated.c_bold = ""
      }
      if (updated.DGCode === updated.DCode) {
        updated.d_bold = ""
      }
      return updated
    })
  }
  dispatch({
    type: REPORT_SERVICE_SUCCESS,
    payload: response,
    setconfig: setconfig,
  })
  if (onComplete) {
    onComplete(response.data.result.records)
  }
}

/* const setInvoiceData = (salesData, newItem, functions, type) => {
  const { getAccountSitesetting } = functions
  var returnData = cloneObj(salesData)
  if (returnData.filter((e) => e.type === newItem.invType).length > 0) {
    returnData = returnData.map((e) => {
      const updated = { ...e }
      if (e.type === newItem.invType) {
        updated.detailGrid = [...updated.detailGrid, getGstSummaryData(newItem, functions, newItem.invType)]
      }
      return updated
    })
  } else {
    returnData.push({
      type: newItem.invType,
      label: getdropdownfieldvalue(getAccountSitesetting(type), "id", newItem.invType, "name"),
      detailGrid: [getGstSummaryData(newItem, functions, newItem.invType)],
    })
  }
  return returnData
} */

const getGstSummaryData = (newItem, functions, invoice_type, addRefBill) => {
  const { taxCodeArray1, getAccountSessionUserData, dd_country_state } = functions
  const partyData = newItem.party_detail
  const returnItem = {
    amount: arraySumTaxCondition(newItem.invoiceDetailDetail, "assable_amt", taxCodeArray1),
    b_date: newItem.b_date,
    billno: newItem.billno,
    cgst_amt: arraySum(newItem.invoiceDetail, "CGST"),
    code: newItem.code,
    aadhar_no: partyData.aadhar_no ? partyData.aadhar_no : "N/A",
    pan_no: partyData.pan_no ? partyData.pan_no : "N/A",
    gstin: partyData.gstin ? partyData.gstin : "N/A",
    gst_treatment_label: partyData.gst_treatment ? getdropdownfieldvalue({ dropDownData: getgstbusinesstypelist(), field: "id", value: partyData.gst_treatment, displayvalue: "name" }) : "N/A",
    igst_amt: arraySum(newItem.invoiceDetail, "IGST"),
    invoice_type: invoice_type,
    invType: newItem.invType === "S" ? "Sales" : newItem.invType === "PU" ? "Purchase" : newItem.invType,
    name: partyData.name,
    netamount: newItem.netamt,
    pan: "",
    partycode: newItem.partycode,
    sgst_amt: arraySum(newItem.invoiceDetail, "SGST"),
    statename: getdropdownfieldvalue({
      dropDownData: getAllStatesOfcountry(getAccountSessionUserData("company_country"), dd_country_state),
      field: "code",
      value: newItem.placeof_supply,
      displayvalue: "name",
    }),
  }
  if (addRefBill) {
    returnItem.ref_b_date = !isblank(newItem.linkedInvoiceDetail) ? newItem.linkedInvoiceDetail[0].b_date : ""
    returnItem.ref_billno = !isblank(newItem.linkedInvoiceDetail) ? newItem.linkedInvoiceDetail[0].billno : ""
  }
  return returnItem
}

const setInvoiceDataV2 = ({ salesData, newItem, functions, type, sType, allState }) => {
  const { getAccountSitesetting } = functions
  var returnData = [...salesData]
  if (returnData.filter((e) => e.type === newItem.invType).length > 0) {
    returnData = returnData.map((e) => {
      const updated = { ...e }
      if (e.type === newItem.invType) {
        updated.detailGrid = [...updated.detailGrid, getGstSummaryDataV2({ newItem, functions, invoice_type: newItem.invType, allState })]
      }
      return updated
    })
  } else {
    returnData.push({
      type: newItem.invType,
      label: getdropdownfieldvalue({ dropDownData: sType, field: "id", value: newItem.invType, displayvalue: "name" }),
      detailGrid: [getGstSummaryDataV2({ newItem, functions, invoice_type: newItem.invType, allState })],
    })
  }
  return returnData
}

const getGstSummaryDataV2 = ({ newItem, functions, invoice_type, addRefBill, allState }) => {
  const { taxCodeArray1 } = functions
  const partyData = newItem.party_detail
  const returnItem = {
    amount: arraySumTaxCondition(newItem.invoiceDetailDetail, "assable_amt", taxCodeArray1),
    b_date: newItem.b_date,
    billno: newItem.billno,
    cgst_amt: arraySum(newItem.invoiceDetail, "CGST"),
    code: newItem.code,
    aadhar_no: partyData.aadhar_no ? partyData.aadhar_no : "N/A",
    pan_no: partyData.pan_no ? partyData.pan_no : "N/A",
    gstin: partyData.gstin ? partyData.gstin : "N/A",
    gst_treatment_label: partyData.gst_treatment ? getdropdownfieldvalue({ dropDownData: getgstbusinesstypelist(), field: "id", value: partyData.gst_treatment, displayvalue: "name" }) : "N/A",
    igst_amt: arraySum(newItem.invoiceDetail, "IGST"),
    invoice_type: invoice_type,
    invType: newItem.invType === "S" ? "Sales" : newItem.invType === "PU" ? "Purchase" : newItem.invType,
    name: partyData.name,
    netamount: newItem.netamt,
    pan: "",
    partycode: newItem.partycode,
    sgst_amt: arraySum(newItem.invoiceDetail, "SGST"),
    statename: getdropdownfieldvalue({ dropDownData: allState, field: "code", value: newItem.placeof_supply, displayvalue: "name" }),
  }
  if (addRefBill) {
    returnItem.ref_b_date = !isblank(newItem.linkedInvoiceDetail) ? newItem.linkedInvoiceDetail[0].b_date : ""
    returnItem.ref_billno = !isblank(newItem.linkedInvoiceDetail) ? newItem.linkedInvoiceDetail[0].billno : ""
  }
  return returnItem
}

const SuppliesMadeToData = ({ invoices, functions, meta_Data, type, gst_treatment, change_sign, invoice_type, btype }) => {
  const { dd_country_state, dd_taxcode, getAccountSitesetting, getNameFromType } = functions
  const { company_stateCode, setconfig, dataKey } = meta_Data

  var igstCodes = []
  if (!isblank(dd_taxcode)) {
    igstCodes = dd_taxcode.igst.split(",").map((e) => convertIntif(e))
  }

  var returnInvoices = []
  invoices.forEach((e) => {
    if ((invoice_type === "salesinvoice" && convertIntif(e.amend_flag) !== 1) || (invoice_type !== "salesinvoice" && e.invType === "S")) {
      e.formData = { type: invoice_type, other_type: e.invType, invType_voucher: e.invType_voucher }
      e.dataKey = dataKey
      e.type = invoice_type === "salesreturn" ? "Credit Note (S)" : invoice_type === "purchasereturn" ? "Debit Note (S)" : getNameFromType(e.invType, false, getAccountSitesetting)
      e.invoicetype = invoice_type === "salesreturn" ? "Credit Note (S)" : invoice_type === "purchasereturn" ? "Debit Note (S)" : getNameFromType(e.invType, false, getAccountSitesetting)
      const partyData = e.party_detail
      if (checkCondition(partyData.gst_treatment, gst_treatment) && convertIntif(e.placeof_supply) !== convertIntif(company_stateCode)) {
        if (returnInvoices.filter((j) => j.Stateid === e.placeof_supply).length > 0) {
          returnInvoices = returnInvoices.map((j) => {
            const updated = { ...j }
            if (j.Stateid === e.placeof_supply) {
              if (convertIntif(e.gstr3B_status) === 0) {
                if (change_sign) {
                  updated.IGST_Amt = parseFloat(getNumberonly(updated.IGST_Amt)) + changeSign(arraySumTaxCondition(e.invoiceDetailDetail, "amt", igstCodes))
                  updated.Re_IGST_Amt = parseFloat(getNumberonly(updated.Re_IGST_Amt)) + changeSign(arraySumTaxCondition(e.invoiceDetailDetail, "amt", igstCodes))
                  updated.Re_Taxable_Amt = parseFloat(getNumberonly(updated.Re_Taxable_Amt)) + changeSign(arraySumTaxCondition(e.invoiceDetailDetail, "assable_amt", igstCodes))
                  updated.Taxable_Amt = parseFloat(getNumberonly(updated.Taxable_Amt)) + changeSign(arraySumTaxCondition(e.invoiceDetailDetail, "assable_amt", igstCodes))
                } else {
                  updated.IGST_Amt = parseFloat(getNumberonly(updated.IGST_Amt)) + arraySumTaxCondition(e.invoiceDetailDetail, "amt", igstCodes)
                  updated.Re_IGST_Amt = parseFloat(getNumberonly(updated.Re_IGST_Amt)) + arraySumTaxCondition(e.invoiceDetailDetail, "amt", igstCodes)
                  updated.Re_Taxable_Amt = parseFloat(getNumberonly(updated.Re_Taxable_Amt)) + arraySumTaxCondition(e.invoiceDetailDetail, "assable_amt", igstCodes)
                  updated.Taxable_Amt = parseFloat(getNumberonly(updated.Taxable_Amt)) + arraySumTaxCondition(e.invoiceDetailDetail, "assable_amt", igstCodes)
                }
              }
              updated.invoiceDetail = [...updated.invoiceDetail, e]
            }
            return updated
          })
        } else {
          changeSign(arraySumTaxCondition(e.invoiceDetailDetail, "assable_amt", igstCodes))

          if (change_sign) {
            returnInvoices.push({
              IGST_Amt: convertIntif(e.gstr3B_status) === 0 ? changeSign(arraySumTaxCondition(e.invoiceDetailDetail, "amt", igstCodes)) : 0,
              Placeof_Supply: getdropdownfieldvalue({ dropDownData: getAllStatesOfcountry(partyData.country_id, dd_country_state), field: "code", value: e.placeof_supply, displayvalue: "name" }),
              Re_IGST_Amt: convertIntif(e.gstr3B_status) === 0 ? changeSign(arraySumTaxCondition(e.invoiceDetailDetail, "amt", igstCodes)) : 0,
              Re_Taxable_Amt: convertIntif(e.gstr3B_status) === 0 ? changeSign(arraySumTaxCondition(e.invoiceDetailDetail, "assable_amt", igstCodes)) : 0,
              Re_Type: "Government Data",
              Stateid: e.placeof_supply,
              Taxable_Amt: convertIntif(e.gstr3B_status) === 0 ? changeSign(arraySumTaxCondition(e.invoiceDetailDetail, "assable_amt", igstCodes)) : 0,
              Type: type,
              btype: btype,
              invoiceDetail: [e],
              change_sign: change_sign,
            })
          } else {
            returnInvoices.push({
              IGST_Amt: convertIntif(e.gstr3B_status) === 0 ? arraySumTaxCondition(e.invoiceDetailDetail, "amt", igstCodes) : 0,
              Placeof_Supply: getdropdownfieldvalue({ dropDownData: getAllStatesOfcountry(partyData.country_id, dd_country_state), field: "code", value: e.placeof_supply, displayvalue: "name" }),
              Re_IGST_Amt: convertIntif(e.gstr3B_status) === 0 ? arraySumTaxCondition(e.invoiceDetailDetail, "amt", igstCodes) : 0,
              Re_Taxable_Amt: convertIntif(e.gstr3B_status) === 0 ? arraySumTaxCondition(e.invoiceDetailDetail, "assable_amt", igstCodes) : 0,
              Re_Type: "Government Data",
              Stateid: e.placeof_supply,
              Taxable_Amt: convertIntif(e.gstr3B_status) === 0 ? arraySumTaxCondition(e.invoiceDetailDetail, "assable_amt", igstCodes) : 0,
              Type: type,
              btype: btype,
              invoiceDetail: [e],
              change_sign: change_sign,
            })
          }
        }
      }
    }
  })
  return returnInvoices
}

const generateStateData = ({ records, functions, meta_Data, type, gst_treatment, btype }) => {
  var salesReturnData = []
  var purchaseReturnData = []
  var salesInvoiceData = []
  var returnInvoices = []
  if (!isblank(records.salesReturn) && records.salesReturn.length > 0) {
    meta_Data.dataKey = "salesReturn"
    salesReturnData = SuppliesMadeToData({ invoices: records.salesReturn, functions, meta_Data, type, gst_treatment, change_sign: true, invoice_type: "salesreturn", btype })
  }
  if (!isblank(records.purchaseReturn) && records.purchaseReturn.length > 0) {
    meta_Data.dataKey = "purchaseReturn"
    purchaseReturnData = SuppliesMadeToData({ invoices: records.purchaseReturn, functions, meta_Data, type, gst_treatment, change_sign: false, invoice_type: "purchasereturn", btype })
  }
  if (!isblank(records.salesInvoice) && records.salesInvoice.length > 0) {
    meta_Data.dataKey = "salesInvoice"
    salesInvoiceData = SuppliesMadeToData({ invoices: records.salesInvoice, functions, meta_Data, type, gst_treatment, change_sign: false, invoice_type: "salesinvoice", btype })
  }

  salesInvoiceData.forEach((e) => {
    if (returnInvoices.filter((i) => convertIntif(i.Stateid) === convertIntif(e.Stateid)).length > 0) {
      returnInvoices = returnInvoices.map((i) => {
        const updated = { ...i }
        if (convertIntif(updated.Stateid) === convertIntif(e.Stateid)) {
          updated.IGST_Amt = parseFloat(getNumberonly(updated.IGST_Amt)) + parseFloat(getNumberonly(e.IGST_Amt))
          updated.Re_IGST_Amt = parseFloat(getNumberonly(updated.Re_IGST_Amt)) + parseFloat(getNumberonly(e.Re_IGST_Amt))
          updated.Re_Taxable_Amt = parseFloat(getNumberonly(updated.Re_Taxable_Amt)) + parseFloat(getNumberonly(e.Re_Taxable_Amt))
          updated.Taxable_Amt = parseFloat(getNumberonly(updated.Taxable_Amt)) + parseFloat(getNumberonly(e.Taxable_Amt))
          updated.invoiceDetail = [...updated.invoiceDetail, ...e.invoiceDetail]
        }
        return updated
      })
    } else {
      returnInvoices.push(e)
    }
  })

  salesReturnData.forEach((e) => {
    if (returnInvoices.filter((i) => convertIntif(i.Stateid) === convertIntif(e.Stateid)).length > 0) {
      returnInvoices = returnInvoices.map((i) => {
        const updated = { ...i }
        if (convertIntif(updated.Stateid) === convertIntif(e.Stateid)) {
          updated.IGST_Amt = parseFloat(getNumberonly(updated.IGST_Amt)) + parseFloat(getNumberonly(e.IGST_Amt))
          updated.Re_IGST_Amt = parseFloat(getNumberonly(updated.Re_IGST_Amt)) + parseFloat(getNumberonly(e.Re_IGST_Amt))
          updated.Re_Taxable_Amt = parseFloat(getNumberonly(updated.Re_Taxable_Amt)) + parseFloat(getNumberonly(e.Re_Taxable_Amt))
          updated.Taxable_Amt = parseFloat(getNumberonly(updated.Taxable_Amt)) + parseFloat(getNumberonly(e.Taxable_Amt))
          updated.invoiceDetail = [...updated.invoiceDetail, ...e.invoiceDetail]
        }
        return updated
      })
    } else {
      returnInvoices.push(e)
    }
  })

  purchaseReturnData.forEach((e) => {
    if (returnInvoices.filter((i) => convertIntif(i.Stateid) === convertIntif(e.Stateid)).length > 0) {
      returnInvoices = returnInvoices.map((i) => {
        const updated = { ...i }
        if (convertIntif(updated.Stateid) === convertIntif(e.Stateid)) {
          updated.IGST_Amt = parseFloat(getNumberonly(updated.IGST_Amt)) + parseFloat(getNumberonly(e.IGST_Amt))
          updated.Re_IGST_Amt = parseFloat(getNumberonly(updated.Re_IGST_Amt)) + parseFloat(getNumberonly(e.Re_IGST_Amt))
          updated.Re_Taxable_Amt = parseFloat(getNumberonly(updated.Re_Taxable_Amt)) + parseFloat(getNumberonly(e.Re_Taxable_Amt))
          updated.Taxable_Amt = parseFloat(getNumberonly(updated.Taxable_Amt)) + parseFloat(getNumberonly(e.Taxable_Amt))
          updated.invoiceDetail = [...updated.invoiceDetail, ...e.invoiceDetail]
        }
        return updated
      })
    } else {
      returnInvoices.push(e)
    }
  })

  return returnInvoices
}

const generateInterstate_supplies = ({ records, functions, meta_Data }) => {
  const { dd_country_state } = functions
  const { taxCodeArray, taxCodeArray1, setconfig } = meta_Data
  var Interstate_supplies = []
  var UnregisteredPersonsData = generateStateData({ records, functions, meta_Data, type: "Supplies made to Unregistered Persons", gst_treatment: [3, 4], btype: "unreg_details" })
  var CompositionTaxablePersons = generateStateData({ records, functions, meta_Data, type: "Supplies made to Composition Taxable Persons", gst_treatment: [2], btype: "comp_details" })
  var UinHolders = []
  Interstate_supplies = [...UnregisteredPersonsData, ...CompositionTaxablePersons, ...UinHolders]

  Interstate_supplies = Interstate_supplies.map((e) => {
    const updatedItem = { ...e }
    updatedItem.detailData = {
      records: {
        detail: {
          tot_cess: 0,
          tot_cgst: 0,
          tot_igst: e.IGST_Amt,
          tot_netamount: e.Taxable_Amt,
          tot_sgst: 0,
          tot_transaction: 0,
        },
        list: [],
        pending: [],
      },
    }

    e.invoiceDetail.forEach((i) => {
      const partyData = i.party_detail
      const newItem = {
        amount: parseFloat(getNumberonly(arraySumTaxCondition(i.invoiceDetailDetail, "assable_amt", taxCodeArray1))),
        invoiceDetail: i.invoiceDetail,
        party_detail: i.party_detail,
        billType: i.billType,
        billno: i.billno,
        cess: 0,
        cgst_amount: arraySum(i.invoiceDetail, "CGST"),
        gstr3B_pending_status: i.gstr3B_status,
        gstr1_pending_status: i.gstr1_status,
        code: i.code,
        date: i.b_date,
        formData: i.formData,
        gstin: partyData.gstin ? partyData.gstin : "N/A",
        gst_treatment_label: partyData.gst_treatment ? getdropdownfieldvalue({ dropDownData: getgstbusinesstypelist(), field: "id", value: partyData.gst_treatment, displayvalue: "name" }) : "N/A",
        placeof_supply: !isBlankCode(i.placeof_supply)
          ? `${i.placeof_supply}-${getdropdownfieldvalue({
            dropDownData: getAllStatesOfcountry(partyData.country_id, dd_country_state),
            field: "code",
            value: i.placeof_supply,
            displayvalue: "name",
          })}`
          : "",
        placeof_supplyId: i.placeof_supply,
        gstin_error: 0,
        gstr1_filed_date: setFiledDateToolTip(e, "gstr1"),
        gstr1_filed: i.gstr1_filed, // === "1" ? "Filed" : "Not Filed",
        gstr2_filed_date: setFiledDateToolTip(e, "gstr2"),
        gstr2_filed: i.gstr2_filed, // === "1" ? "Filed" : "Not Filed",
        gstr3B_filed_date: setFiledDateToolTip(e, "gstr3b"),
        gstr3B_filed: i.gstr3B_filed, // === "1" ? "Filed" : "Not Filed",
        igst_amount: arraySum(i.invoiceDetail, "IGST"),
        reversable_charge: convertIntif(i.reversable_charge) === 1 ? "Y" : "N",
        invoiceType: "Regular",
        invoice_code: i.code,
        oldBillCode: i.oldBillCode,
        invoicetype: i.invoicetype,
        name: partyData.name,
        netamount: i.netamt,
        partycode: i.partycode,
        sgst_amount: arraySum(i.invoiceDetail, "SGST"),
        tax_amount: arraySumTaxCondition(i.invoiceDetailDetail, "amt", taxCodeArray),
        type: i.type,
        formTypeLabel: i.type,
        oldOrPrevious_flag: checkOldOrPrevInvoiceFlag(setconfig, i),
      }
      if (i.dataKey === "salesReturn") {
        newItem.amount = parseFloat(getNumberonly(changeSign(newItem.amount)))
        newItem.cgst_amount = changeSign(newItem.cgst_amount)
        newItem.igst_amount = changeSign(newItem.igst_amount)
        newItem.netamount = changeSign(newItem.netamount)
        newItem.sgst_amount = changeSign(newItem.sgst_amount)
        newItem.tax_amount = changeSign(newItem.tax_amount)
        newItem.tax_amount = changeSign(newItem.tax_amount)
      }
      if (parseFloat(getNumberonly(newItem.cgst_amount + newItem.igst_amount + newItem.sgst_amount)) !== parseFloat(getNumberonly(newItem.tax_amount))) {
        newItem.error = "Error"
        newItem.errorData = ["Tax amount mismatch"]
      }
      if ((setconfig.type === "gstr3B" && convertIntif(newItem.gstr3B_pending_status) === 1) || (setconfig.type === "gstr1" && convertIntif(newItem.gstr1_pending_status) === 1)) {
        updatedItem.detailData.records.pending.push(newItem)
      } else {
        updatedItem.detailData.records.list.push(newItem)
      }
    })
    updatedItem.detailData.records.detail.tot_transaction = updatedItem.detailData.records.list.length
    // updatedItem.Oldinvoice_flag =
    //   updatedItem.detailData.records.list.filter((e) => e.oldOrPrevious_flag === "Old").length + updatedItem.detailData.records.pending.filter((e) => e.oldOrPrevious_flag === "Old").length
    updatedItem.Oldinvoice_flag = updatedItem.detailData.records.list.filter((e) => e.oldOrPrevious_flag === "Old").length
    updatedItem.Previousinvoice_flag =
      updatedItem.detailData.records.list.filter((e) => e.oldOrPrevious_flag === "Previous").length + updatedItem.detailData.records.pending.filter((e) => e.oldOrPrevious_flag === "Previous").length
    updatedItem.GSTR3BPendindinovice_flag = updatedItem.detailData.records.pending.length
    updatedItem.GSTR1Pendindinovice_flag = updatedItem.detailData.records.pending.length
    return updatedItem
  })
  Interstate_supplies = Interstate_supplies.filter((e) => e.detailData.records.detail.tot_transaction > 0)
  return Interstate_supplies
}

const getTypeData = (type, btype) => {
  return {
    Diff_Tax_Amt: 0,
    Diff_Taxable_Amt: 0,
    Diff_Total_Amt: 0,
    Error_flag: 0,
    Invoices: 0,
    Oldinvoice_flag: 0,
    GSTR3BPendindinovice_flag: 0,
    GSTR1Pendindinovice_flag: 0,
    Previousinvoice_flag: 0,
    Re_Invoices: 0,
    Re_Tax_Amt: 0,
    Re_Taxable_Amt: 0,
    Re_Total_Amt: 0,
    Re_Type: "Government Data",
    Tax_Amt: 0,
    cgst_Amt: 0,
    sgst_Amt: 0,
    igst_Amt: 0,
    cess_Amt: 0,
    Taxable_Amt: 0,
    Total_Amt: 0,
    Type: type,
    btype: btype,
    detailData: {
      records: {
        detail: {
          tot_cess: 0,
          tot_cgst: 0,
          tot_igst: 0,
          tot_netamount: 0,
          tot_sgst: 0,
          tot_transaction: 0,
        },
        list: [],
        pending: [],
      },
    },
  }
}

const addError = (prevError, newError) => {
  prevError.push(newError)
  return prevError
}

const getDetailByType = ({ metaData, invoicetype, change_sign }) => {
  const { mainType, e, partyData, taxCodeArray1, taxCodeArray, type, formType, setconfig, dataKey, dd_country_state } = metaData
  if (0 < convertIntif(getNumberonly(e.placeof_supply)) && convertIntif(getNumberonly(e.placeof_supply)) < 10) {
    e.placeof_supply = `0${e.placeof_supply}`
  }

  var formTypeLabel = ""
  if (formType === "salesreturn") {
    formTypeLabel = "Credit Note"
  } else if (formType === "purchasereturn") {
    formTypeLabel = "Debit Note"
  } else if (formType === "salesinvoice") {
    formTypeLabel = "Sales"
  } else if (formType === "purchaseinvoice") {
    formTypeLabel = "Purchase"
  }
  const newDetailItem = {
    dataKey: dataKey,
    party_detail: e.party_detail,
    billType: e.billType,
    oldBillCode: e.oldBillCode,
    amount: parseFloat(getNumberonly(arraySumTaxCondition(e.invoiceDetailDetail, "assable_amt", taxCodeArray1))),
    invoiceDetail: e.invoiceDetail,
    gstr3B_pending_status: e.gstr3B_status,
    gstr1_pending_status: e.gstr1_status,
    billno: e.billno,
    cess: 0,
    cgst_amount: arraySum(e.invoiceDetail, "CGST"),
    code: e.code,
    exp_typ: checkCondition(e.export_type, [2, 5]) ? "WOPAY" : "WPAY",
    expShippDetail: e.expShippDetail,
    date: e.b_date,
    formTypeLabel: formTypeLabel,
    formData: { type: formType, other_type: e.invType, invType_voucher: e.invType_voucher },
    gstin: partyData.gstin ? partyData.gstin : "N/A",
    gst_treatment_label: partyData.gst_treatment ? getdropdownfieldvalue({ dropDownData: getgstbusinesstypelist(), field: "id", value: partyData.gst_treatment, displayvalue: "name" }) : "N/A",
    gst_treatment: partyData.gst_treatment,
    placeof_supply: !isBlankCode(e.placeof_supply)
      ? `${e.placeof_supply}-${getdropdownfieldvalue({ dropDownData: getAllStatesOfcountry(partyData.country_id, dd_country_state), field: "code", value: e.placeof_supply, displayvalue: "name" })}`
      : "",
    placeof_supplyId: e.placeof_supply,
    gstin_error: 0,
    gstr1_filed: e.gstr1_filed, // === "1" ? "Filed" : "Not Filed",
    gstr1_filed_date: setFiledDateToolTip(e, "gstr1"),
    gstr2_filed: e.gstr2_filed, // === "1" ? "Filed" : "Not Filed",
    gstr2_filed_date: setFiledDateToolTip(e, "gstr2"),
    gstr3B_filed: e.gstr3B_filed, // === "1" ? "Filed" : "Not Filed",
    gstr3B_filed_date: setFiledDateToolTip(e, "gstr3b"),

    igst_amount: arraySum(e.invoiceDetail, "IGST"),
    reversable_charge: convertIntif(e.reversable_charge) === 1 ? "Y" : "N",
    invoice_code: e.code,
    invoicetype: invoicetype ? invoicetype : "S",
    invoiceType: "Regular",
    name: partyData.name,
    netamount: e.netamt,
    partycode: e.partycode,
    zeroRatedType: e.zeroRatedType,
    sgst_amount: arraySum(e.invoiceDetail, "SGST"),
    tax_amount: arraySumTaxCondition(e.invoiceDetailDetail, "amt", taxCodeArray),
    type: type === "ITC" || type === "inward_supplies" ? invoicetype : type,
    prev_billno: e.linkedInvoiceDetail ? e.linkedInvoiceDetail[0].billno : "",
    prev_billdate: e.linkedInvoiceDetail ? e.linkedInvoiceDetail[0].b_date : "",
    change_sign: change_sign,
    oldOrPrevious_flag: checkOldOrPrevInvoiceFlag(setconfig, e),
  }

  if (type === "B2BA") {
    newDetailItem.original_billno = e.original_billno
    newDetailItem.original_date = e.original_b_date
    newDetailItem.original_placeof_supply = !isBlankCode(e.original_placeof_supply)
      ? `${e.original_placeof_supply}-${getdropdownfieldvalue({
        dropDownData: getAllStatesOfcountry(partyData.country_id, dd_country_state),
        field: "code",
        value: e.original_placeof_supply,
        displayvalue: "name",
      })}`
      : ""
    newDetailItem.original_placeof_supplyId = e.original_placeof_supply
  }
  var errorData = []
  if (parseFloat(getNumberonly(newDetailItem.cgst_amount + newDetailItem.igst_amount + newDetailItem.sgst_amount)) !== parseFloat(getNumberonly(newDetailItem.tax_amount))) {
    errorData = addError(errorData, "Tax amount mismatch")
  }

  if (!validateGSTIN(newDetailItem.gstin)) {
    if (checkCondition(type, ["B2B", "CDNR"])) {
      errorData = addError(errorData, "GSTIN invalid")
    }
  }
  if (isblank(newDetailItem.billno)) {
    if (checkCondition(type, ["B2B", "B2CL", "Export_Invoices"])) {
      errorData = addError(errorData, "Bill no is required")
    }
    if (checkCondition(type, ["CDNR", "CDNUR"])) {
      errorData = addError(errorData, "Note no is required")
    }
  }
  // if (isblank(newDetailItem.prev_billno)) {
  //   if (checkCondition(type, ["CDNR", "CDNUR"])) {
  //     if (!newDetailItem.formData.invType_voucher) {
  //       errorData = addError(errorData, "Invoice no is required")
  //     }
  //   }
  // }
  // if (isblank(newDetailItem.prev_billdate) || newDetailItem.prev_billdate === "0000-00-00") {
  //   if (checkCondition(type, ["CDNR", "CDNUR"])) {
  //     if (!newDetailItem.formData.invType_voucher) {
  //       errorData = addError(errorData, "Invoice date is required")
  //     }
  //   }
  // }
  if (isblank(newDetailItem.date)) {
    if (checkCondition(type, ["B2B"])) {
      errorData = addError(errorData, "Bill date is required")
    }
    if (checkCondition(type, ["CDNR", "CDNUR"])) {
      errorData = addError(errorData, "Note date is required")
    }
  }
  if (isblank(newDetailItem.netamount)) {
    if (checkCondition(type, ["B2B", "B2CL", "Export_Invoices"])) {
      errorData = addError(errorData, "Bill Amount is required")
    }
    if (checkCondition(type, ["CDNR", "CDNUR"])) {
      errorData = addError(errorData, "Note Amount is required")
    }
  }
  if (isBlankCode(newDetailItem.placeof_supply)) {
    if (checkCondition(type, ["B2B", "B2CL", "CDNR", "B2CS"])) {
      errorData = addError(errorData, "Place of supply is required")
    }
  }
  if (isblank(newDetailItem.amount)) {
    if (checkCondition(type, ["B2B", "B2CL", "CDNR", "B2CS"])) {
      errorData = addError(errorData, "Taxable amount is required")
    }
  }
  if (!isblank(errorData) && errorData.length > 0) {
    newDetailItem.error = `Error`
    newDetailItem.errorData = errorData
  }
  if (change_sign) {
    newDetailItem.amount = parseFloat(getNumberonly(changeSign(newDetailItem.amount)))
    newDetailItem.netamount = parseFloat(getNumberonly(changeSign(newDetailItem.netamount)))
    newDetailItem.sgst_amount = parseFloat(getNumberonly(changeSign(newDetailItem.sgst_amount)))
    newDetailItem.tax_amount = parseFloat(getNumberonly(changeSign(newDetailItem.tax_amount)))
    newDetailItem.igst_amount = parseFloat(getNumberonly(changeSign(newDetailItem.igst_amount)))
    newDetailItem.cgst_amount = parseFloat(getNumberonly(changeSign(newDetailItem.cgst_amount)))
  }
  if ((setconfig.type === "gstr3B" && convertIntif(newDetailItem.gstr3B_pending_status) === 1) || (setconfig.type === "gstr1" && convertIntif(newDetailItem.gstr1_pending_status) === 1)) {
    mainType.detailData.records.pending.push(newDetailItem)
  } else {
    mainType.detailData.records.detail.tot_cess = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_cess)) + parseFloat(getNumberonly(newDetailItem.cess))
    mainType.detailData.records.detail.tot_cgst = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_cgst)) + parseFloat(getNumberonly(newDetailItem.cgst_amount))
    mainType.detailData.records.detail.tot_igst = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_igst)) + parseFloat(getNumberonly(newDetailItem.igst_amount))
    mainType.detailData.records.detail.tot_netamount = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_netamount)) + parseFloat(getNumberonly(newDetailItem.amount))
    mainType.detailData.records.detail.tot_sgst = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_sgst)) + parseFloat(getNumberonly(newDetailItem.sgst_amount))
    if (change_sign) {
      mainType.Taxable_Amt = parseFloat(getNumberonly(mainType.Taxable_Amt)) + parseFloat(getNumberonly(changeSign(arraySumTaxCondition(e.invoiceDetailDetail, "assable_amt", taxCodeArray1))))
      mainType.Tax_Amt = parseFloat(getNumberonly(mainType.Tax_Amt)) + parseFloat(getNumberonly(changeSign(arraySumTaxCondition(e.invoiceDetailDetail, "amt", taxCodeArray))))
      mainType.Total_Amt = parseFloat(getNumberonly(mainType.Total_Amt)) + parseFloat(getNumberonly(changeSign(parseFloat(getNumberonly(e.netamt)))))
    } else {
      mainType.Taxable_Amt = parseFloat(getNumberonly(mainType.Taxable_Amt)) + parseFloat(getNumberonly(arraySumTaxCondition(e.invoiceDetailDetail, "assable_amt", taxCodeArray1)))
      mainType.Tax_Amt = parseFloat(getNumberonly(mainType.Tax_Amt)) + parseFloat(getNumberonly(arraySumTaxCondition(e.invoiceDetailDetail, "amt", taxCodeArray)))
      mainType.Total_Amt = parseFloat(getNumberonly(mainType.Total_Amt)) + parseFloat(getNumberonly(e.netamt))
    }
    mainType.cgst_Amt = parseFloat(getNumberonly(mainType.cgst_Amt)) + parseFloat(getNumberonly(newDetailItem.cgst_amount))
    mainType.sgst_Amt = parseFloat(getNumberonly(mainType.sgst_Amt)) + parseFloat(getNumberonly(newDetailItem.sgst_amount))
    mainType.igst_Amt = parseFloat(getNumberonly(mainType.igst_Amt)) + parseFloat(getNumberonly(newDetailItem.igst_amount))
    mainType.cess_Amt = 0

    mainType.detailData.records.list.push(newDetailItem)
    mainType.Invoices = mainType.Invoices + 1
    mainType.detailData.records.detail.tot_transaction = mainType.detailData.records.detail.tot_transaction + 1

    if (!isblank(newDetailItem.error)) {
      mainType.Error_flag = mainType.Error_flag + 1
    }
  }

  // mainType.Oldinvoice_flag =
  //   mainType.detailData.records.list.filter((e) => e.oldOrPrevious_flag === "Old").length + mainType.detailData.records.pending.filter((e) => e.oldOrPrevious_flag === "Old").length
  mainType.Oldinvoice_flag = mainType.detailData.records.list.filter((e) => e.oldOrPrevious_flag === "Old").length
  mainType.Previousinvoice_flag =
    mainType.detailData.records.list.filter((e) => e.oldOrPrevious_flag === "Previous").length + mainType.detailData.records.pending.filter((e) => e.oldOrPrevious_flag === "Previous").length
  mainType.GSTR3BPendindinovice_flag = mainType.detailData.records.pending.length
  mainType.GSTR1Pendindinovice_flag = mainType.detailData.records.pending.length
  return mainType
}

const checkOldOrPrevInvoiceFlag = (setconfig, invoiceData) => {
  if (!isblank(invoiceData)) {
    if (invoiceData.billType === "old") {
      return "Previous"
    } else {
      const { from_date } = setconfig
      var invoice_date = new Date(DatetoYMD(invoiceData.b_date))
      var FromDate = new Date(DatetoYMD(from_date))
      if (invoice_date < FromDate) {
        return "Old"
      }
      return ""
    }
  } else {
    return ""
  }
}

export const getTaxRate = (taxdetail) => {
  var taxRate = 0.0
  if (!isblank(taxdetail) && taxdetail.length > 0) {
    taxdetail.forEach((j) => {
      if (parseFloat(getNumberonly(j.taxamount)) > 0) {
        if (j.taxtype === "IGST") {
          taxRate = parseFloat(getNumberonly(j.taxper))
        } else if (j.taxtype === "CCGST" || j.taxtype === "SGST") {
          taxRate = parseFloat(getNumberonly(j.taxper)) * 2
        }
      }
    })
  }
  return taxRate
}

// const description = getdropdownfieldvalue(dditem, "code", i.itemcode, "name")
// const uqc_short = convertIntif(itemtype) === 2 ? "NA" : getdropdownfieldvalue(dditem, "code", i.itemcode, "unit")

const getFileValues = (obj) => {
  return {
    cgst_amount: getFileValue(parseFloat(getNumberonly(obj.CGST))),
    sgst_amount: getFileValue(parseFloat(getNumberonly(obj.SGST))),
    igst_amount: getFileValue(parseFloat(getNumberonly(obj.IGST))),
    taxable_amt: getFileValue(parseFloat(getNumberonly(obj.taxable_amt))),
    netamount: getFileValue(parseFloat(getNumberonly(obj.netamount))),
    amount: getFileValue(parseFloat(getNumberonly(obj.amount))),
    tax_amt: getFileValue(parseFloat(getNumberonly(obj.tax_amount))),
  }
}
const getFileValuesV1 = (obj) => {
  return {
    cgst_amount: getFileValue(parseFloat(getNumberonly(obj.cgst_amount))),
    sgst_amount: getFileValue(parseFloat(getNumberonly(obj.sgst_amount))),
    igst_amount: getFileValue(parseFloat(getNumberonly(obj.igst_amount))),
    taxable_amt: getFileValue(parseFloat(getNumberonly(obj.taxable_amt))),
    netamount: getFileValue(parseFloat(getNumberonly(obj.netamount))),
    amount: getFileValue(parseFloat(getNumberonly(obj.amount))),
    tax_amt: getFileValue(parseFloat(getNumberonly(obj.tax_amount))),
    cess: getFileValue(parseFloat(getNumberonly(obj.cess))),
  }
}
// const cgst_amount = getFileValue(parseFloat(getNumberonly(i.CGST)))
// const sgst_amount = getFileValue(parseFloat(getNumberonly(i.SGST)))
// const igst_amount = getFileValue(parseFloat(getNumberonly(i.IGST)))
// const taxable_amt = getFileValue(parseFloat(getNumberonly(i.taxable_amt)))
// const exp_typ = checkCondition(e.export_type, [2, 5]) ? "WOPAY" : "WPAY"

const checkHSNConditions = ({ mainType, e, dditem, ddallaccount, type, functions, setconfig }) => {
  if ((setconfig.type === "gstr3B" && convertIntif(e.gstr3B_status) === 1) || (setconfig.type === "gstr1" && convertIntif(e.gstr1_status) === 1)) {
    return mainType
  }
  const { getAccountSitesetting, user_data, getNameFromType } = functions
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")

  const addItemCodes = (itemcodesArray, itemcode) => {
    if (itemcodesArray.filter((i) => i === itemcode).length === 0) {
      itemcodesArray.push(itemcode)
    }
    return itemcodesArray
  }

  e.invoiceDetail.forEach((i) => {
    const hsnCode = i.hsn_code
    const itemtype = !isblank(i.itemtype) ? i.itemtype : i.item_type
    const unitCode = convertIntif(itemtype) === 2 ? "NA" : `${i.unit}-${i.unit_name}`
    var Qty = 0
    if (convertIntif(itemtype) !== 2) {
      if (type === "salesinvoice" || type === "purchasereturn") {
        Qty = getFileValue(i.qty, inventory_decimalpoint)
      } else if (type === "salesreturn") {
        Qty = getFileValue(i.qty, inventory_decimalpoint) * -1
      }
    }

    var taxRate = getTaxRate(i.taxdetail)
    var invoice_type = ""
    if (type === "salesinvoice") {
      invoice_type = getNameFromType(e.invType, false, getAccountSitesetting)
    } else {
      invoice_type = getNameFromType(type, false, getAccountSitesetting)
    }

    const FileValue = getFileValues(i)

    //getdropdownfieldvalue(ddallaccount, "code", i.accountSelectedValue, "name")
    const description = i.itemcode === "0" ? ddallaccount[i.accountSelectedValue].name : dditem[i.itemcode].name
    const exp_typ = checkCondition(e.export_type, [2, 5]) ? "WOPAY" : "WPAY"
    const uqc_short = convertIntif(itemtype) === 2 ? "NA" : i.itemcode === "0" ? "" : dditem[i.itemcode].unit

    var newItemPlus = {
      amount: FileValue.cgst_amount + FileValue.igst_amount + FileValue.sgst_amount + FileValue.taxable_amt,
      netamount: FileValue.taxable_amt,
      formData: { type: type, other_type: e.invType, invType_voucher: e.invType_voucher },
      description: description,
      invoice_type: invoice_type,
      invoice_code: e.code,
      oldBillCode: e.oldBillCode,
      b_date: e.b_date,
      billno: e.billno,
      exp_typ: exp_typ,
      expShippDetail: e.expShippDetail,
      cess: "0.00",
      cgst_amount: FileValue.cgst_amount,
      itemcode: i.itemcode,
      accountSelectedValue: i.accountSelectedValue,
      hsn_key: hsnCode,
      igst_amount: FileValue.igst_amount,
      rate: taxRate,
      sgst_amount: FileValue.sgst_amount,
      tax_amount: FileValue.cgst_amount + FileValue.igst_amount + FileValue.sgst_amount,
      tot_qty: Qty,
      uqc: unitCode,
      uqc_short: uqc_short,
    }
    var newItemMinus = {
      amount: FileValue.cgst_amount * -1 + FileValue.igst_amount * -1 + FileValue.sgst_amount * -1 + FileValue.taxable_amt * -1,
      netamount: FileValue.taxable_amt * -1,
      cess: "0.00",
      formData: { type: type, other_type: e.invType, invType_voucher: e.invType_voucher },
      description: description,
      invoice_type: invoice_type,
      invoice_code: e.code,
      oldBillCode: e.oldBillCode,
      b_date: e.b_date,
      billno: e.billno,
      exp_typ: exp_typ,
      expShippDetail: e.expShippDetail,
      cgst_amount: FileValue.cgst_amount * -1,
      itemcode: i.itemcode,
      accountSelectedValue: i.accountSelectedValue,
      hsn_key: hsnCode,
      igst_amount: FileValue.igst_amount * -1,
      rate: taxRate,
      sgst_amount: FileValue.sgst_amount * -1,
      tax_amount: FileValue.cgst_amount * -1 + FileValue.igst_amount * -1 + FileValue.sgst_amount * -1,
      tot_qty: Qty,
      uqc: unitCode,
      uqc_short: uqc_short,
    }
    if (mainType.detailData.records.list.filter((k) => k.hsn_key === hsnCode && convertIntif(k.rate) === convertIntif(taxRate) && k.uqc === unitCode).length > 0) {
      mainType.detailData.records.list = mainType.detailData.records.list.map((l) => {
        const updatedItem = { ...l }
        const FileValueUI = getFileValuesV1(updatedItem)
        const tot_qty = parseFloat(getNumberonlyDecimal(updatedItem.tot_qty, inventory_decimalpoint))
        const qty = parseFloat(getNumberonlyDecimal(Qty, inventory_decimalpoint))

        if (updatedItem.hsn_key === hsnCode && convertIntif(updatedItem.rate) === convertIntif(taxRate) && updatedItem.uqc === unitCode) {
          if (type === "salesinvoice" || type === "purchasereturn") {
            updatedItem.netamount = FileValueUI.netamount + FileValue.taxable_amt
            updatedItem.amount = FileValueUI.amount + FileValue.cgst_amount + FileValue.igst_amount + FileValue.sgst_amount + FileValue.taxable_amt
            updatedItem.cgst_amount = FileValueUI.cgst_amount + FileValue.cgst_amount
            updatedItem.igst_amount = FileValueUI.igst_amount + FileValue.igst_amount
            updatedItem.sgst_amount = FileValueUI.sgst_amount + FileValue.sgst_amount
            updatedItem.tax_amount = FileValueUI.tax_amt + FileValue.cgst_amount + FileValue.igst_amount + FileValue.sgst_amount
            updatedItem.tot_qty = tot_qty + qty
            updatedItem.DetailGrid = [...updatedItem.DetailGrid, newItemPlus]
          } else if (type === "salesreturn") {
            updatedItem.amount = FileValueUI.amount - FileValue.cgst_amount - FileValue.igst_amount - FileValue.sgst_amount - FileValue.taxable_amt
            updatedItem.netamount = FileValueUI.netamount - FileValue.taxable_amt
            updatedItem.cgst_amount = FileValueUI.cgst_amount - FileValue.cgst_amount
            updatedItem.igst_amount = FileValueUI.igst_amount - FileValue.igst_amount
            updatedItem.sgst_amount = FileValueUI.sgst_amount - FileValue.sgst_amount
            updatedItem.tax_amount = FileValueUI.tax_amt - FileValue.cgst_amount - FileValue.igst_amount - FileValue.sgst_amount
            updatedItem.tot_qty = tot_qty + qty
            updatedItem.DetailGrid = [...updatedItem.DetailGrid, newItemMinus]
          }
        }

        return updatedItem
      })
    } else {
      if (type === "salesinvoice" || type === "purchasereturn") {
        mainType.detailData.records.list.push({ ...newItemPlus, DetailGrid: [newItemPlus] })
      } else if (type === "salesreturn") {
        mainType.detailData.records.list.push({ ...newItemMinus, DetailGrid: [newItemMinus] })
      }
    }
  })

  mainType.Taxable_Amt = 0
  mainType.cgst_Amt = 0
  mainType.sgst_Amt = 0
  mainType.igst_Amt = 0
  mainType.cess_Amt = 0
  mainType.Tax_Amt = 0
  mainType.Total_Amt = 0
  mainType.detailData.records.detail.tot_cess = 0
  mainType.detailData.records.detail.tot_cgst = 0
  mainType.detailData.records.detail.tot_igst = 0
  mainType.detailData.records.detail.tot_netamount = 0
  mainType.detailData.records.detail.tot_sgst = 0

  mainType.detailData.records.list = mainType.detailData.records.list.map((i) => {
    const updated = { ...i }
    const FileValues = getFileValuesV1(i)

    mainType.Taxable_Amt = parseFloat(getNumberonly(mainType.Taxable_Amt)) + FileValues.netamount
    mainType.Tax_Amt = parseFloat(getNumberonly(mainType.Tax_Amt)) + FileValues.tax_amt
    mainType.cgst_Amt = parseFloat(getNumberonly(mainType.cgst_Amt)) + FileValues.cgst_amount
    mainType.sgst_Amt = parseFloat(getNumberonly(mainType.sgst_Amt)) + FileValues.sgst_amount
    mainType.igst_Amt = parseFloat(getNumberonly(mainType.igst_Amt)) + FileValues.igst_amount
    mainType.cess_Amt = parseFloat(getNumberonly(mainType.cess_Amt)) + FileValues.cess

    mainType.Total_Amt = parseFloat(getNumberonly(mainType.Total_Amt)) + FileValues.amount

    mainType.detailData.records.detail.tot_cess = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_cess)) + FileValues.cess
    mainType.detailData.records.detail.tot_cgst = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_cgst)) + FileValues.cgst_amount
    mainType.detailData.records.detail.tot_igst = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_igst)) + FileValues.igst_amount
    mainType.detailData.records.detail.tot_netamount = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_netamount)) + FileValues.amount
    mainType.detailData.records.detail.tot_sgst = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_sgst)) + FileValues.sgst_amount
    updated.itemcodes = []
    updated.allaccountcodes = []

    if (updated.DetailGrid.length > 1) {
      updated.DetailGrid.forEach((j) => {
        if (j.itemcode === "0") {
          updated.allaccountcodes = addItemCodes(updated.allaccountcodes, j.accountSelectedValue)
        } else {
          updated.itemcodes = addItemCodes(updated.itemcodes, j.itemcode)
        }
      })

      if (updated.itemcodes.length > 1) {
        updated.description = i.itemcode === "0" ? "" : dditem[i.itemcode].name //`${getdropdownfieldvalue(dditem, "code", i.itemcode, "name")}  (+ ${updated.itemcodes.length - 1} more)`
      } else if (updated.allaccountcodes.length > 1) {
        updated.description = `${ddallaccount[i.accountSelectedValue].name} +  (+ ${updated.allaccountcodes.length - 1} more)` //`${getdropdownfieldvalue(ddallaccount, "code", i.accountSelectedValue, "name")}  (+ ${updated.allaccountcodes.length - 1} more)`
      } else if (updated.itemcodes.length > 0) {
        updated.description = i.itemcode === "0" ? "" : dditem[i.itemcode].name
      } else if (updated.allaccountcodes.length > 0) {
        updated.description = ddallaccount[i.accountSelectedValue].name //getdropdownfieldvalue(ddallaccount, "code", i.accountSelectedValue, "name")
      }
    } else {
      if (i.itemcode === "0") {
        updated.description = ddallaccount[i.accountSelectedValue].name //getdropdownfieldvalue(ddallaccount, "code", i.accountSelectedValue, "name")
      } else {
        updated.description = dditem[i.itemcode].name
      }
    }
    var errorData = []
    if (isblank(updated.description)) {
      errorData = addError(errorData, "Description is required")
    }
    if (isblank(updated.hsn_key)) {
      errorData = addError(errorData, "HSN is required")
    }
    if (isblank(updated.uqc)) {
      errorData = addError(errorData, "Unit is required")
    }
    if (isblank(updated.tot_qty)) {
      errorData = addError(errorData, "Total Qty is required")
    }
    if (isblank(updated.netamount)) {
      errorData = addError(errorData, "Total taxable amount is required")
    }
    if (isblank(updated.amount)) {
      errorData = addError(errorData, "Total amount is required")
    }
    if (!isblank(errorData) && errorData.length > 0) {
      updated.error = `Error`
      updated.errorData = errorData
    }
    return updated
  })
  mainType.detailData.records.detail.tot_transaction = mainType.detailData.records.list.length
  mainType.Invoices = mainType.detailData.records.list.length
  mainType.Error_flag = mainType.detailData.records.list.filter((f) => !isblank(f.error)).length
  return mainType
}

const checkHSNConditionsV2 = ({ mainType, e, dditem, type, functions, setconfig, inventory_decimalpoint, TypeNames }) => {
  if ((setconfig.type === "gstr3B" && convertIntif(e.gstr3B_status) === 1) || (setconfig.type === "gstr1" && convertIntif(e.gstr1_status) === 1)) {
    return mainType
  }

  const addItemCodes = (itemcodesArray, itemcode) => {
    if (itemcodesArray.filter((i) => i === itemcode).length === 0) {
      itemcodesArray.push(itemcode)
    }
    return itemcodesArray
  }

  e.invoiceDetail.forEach((i) => {
    const hsnCode = i.hsn_code
    const itemtype = !isblank(i.itemtype) ? i.itemtype : i.item_type
    const unitCode = convertIntif(itemtype) === 2 ? "NA" : `${i.unit}-${i.unit_name}`
    var Qty = 0

    if (convertIntif(itemtype) !== 2) {
      if (type === "salesinvoice" || type === "purchasereturn") {
        Qty = getFileValue(i.qty, inventory_decimalpoint)
      } else if (type === "salesreturn") {
        Qty = getFileValue(i.qty, inventory_decimalpoint) * -1
      }
    }

    var taxRate = getTaxRate(i.taxdetail)
    var invoice_type = ""
    if (type === "salesinvoice") {
      invoice_type = TypeNames[e.invType] // getNameFromType(e.invType, false, getAccountSitesetting)
    } else {
      invoice_type = TypeNames[type] //getNameFromType(type, false, getAccountSitesetting)
    }

    const description = i.itemcode === "0" ? "" : dditem[i.itemcode].name //getdropdownfieldvalue(dditem, "code", i.itemcode, "name")
    const uqc_short = convertIntif(itemtype) === 2 ? "NA" : i.itemcode === "0" ? "" : dditem[i.itemcode].unit //getdropdownfieldvalue(dditem, "code", i.itemcode, "unit")

    const FileValue = getFileValues(i)

    //const cgst_amount = FileValue.cgst_amount
    //const sgst_amount = getFileValue(parseFloat(getNumberonly(i.SGST)))
    //const igst_amount = getFileValue(parseFloat(getNumberonly(i.IGST)))
    //const taxable_amt = getFileValue(parseFloat(getNumberonly(i.taxable_amt)))

    const exp_typ = checkCondition(e.export_type, [2, 5]) ? "WOPAY" : "WPAY"

    var newItemPlus = {
      amount: FileValue.cgst_amount + FileValue.igst_amount + FileValue.sgst_amount + FileValue.taxable_amt,
      netamount: FileValue.taxable_amt,
      formData: { type: type, other_type: e.invType, invType_voucher: e.invType_voucher },
      description: description,
      invoice_type: invoice_type,
      invoice_code: e.code,
      oldBillCode: e.oldBillCode,
      b_date: e.b_date,
      billno: e.billno,
      exp_typ: exp_typ,
      expShippDetail: e.expShippDetail,
      cess: "0.00",
      cgst_amount: FileValue.cgst_amount,
      itemcode: i.itemcode,
      accountSelectedValue: i.accountSelectedValue,
      hsn_key: hsnCode,
      igst_amount: FileValue.igst_amount,
      rate: taxRate,
      sgst_amount: FileValue.sgst_amount,
      tax_amount: FileValue.cgst_amount + FileValue.igst_amount + FileValue.sgst_amount,
      tot_qty: Qty,
      uqc: unitCode,
      uqc_short: uqc_short,
    }
    var newItemMinus = {
      amount: FileValue.cgst_amount * -1 + FileValue.igst_amount * -1 + FileValue.sgst_amount * -1 + FileValue.taxable_amt * -1,
      netamount: FileValue.taxable_amt * -1,
      cess: "0.00",
      formData: { type: type, other_type: e.invType, invType_voucher: e.invType_voucher },
      description: description,
      invoice_type: invoice_type,
      invoice_code: e.code,
      oldBillCode: e.oldBillCode,
      b_date: e.b_date,
      billno: e.billno,
      exp_typ: exp_typ,
      expShippDetail: e.expShippDetail,
      cgst_amount: FileValue.cgst_amount * -1,
      itemcode: i.itemcode,
      accountSelectedValue: i.accountSelectedValue,
      hsn_key: hsnCode,
      igst_amount: FileValue.igst_amount * -1,
      rate: taxRate,
      sgst_amount: FileValue.sgst_amount * -1,
      tax_amount: FileValue.cgst_amount * -1 + FileValue.igst_amount * -1 + FileValue.sgst_amount * -1,
      tot_qty: Qty,
      uqc: unitCode,
      uqc_short: uqc_short,
    }
    if (mainType.detailData.records.list.filter((k) => k.hsn_key === hsnCode && convertIntif(k.rate) === convertIntif(taxRate) && k.uqc === unitCode).length > 0) {
      mainType.detailData.records.list = mainType.detailData.records.list.map((l) => {
        const updatedItem = { ...l }
        const FileValueUI = getFileValuesV1(updatedItem)
        const tot_qty = parseFloat(getNumberonlyDecimal(updatedItem.tot_qty, inventory_decimalpoint))
        const qty = parseFloat(getNumberonlyDecimal(Qty, inventory_decimalpoint))

        if (updatedItem.hsn_key === hsnCode && convertIntif(updatedItem.rate) === convertIntif(taxRate) && updatedItem.uqc === unitCode) {
          if (type === "salesinvoice" || type === "purchasereturn") {
            updatedItem.netamount = FileValueUI.netamount + FileValue.taxable_amt
            updatedItem.amount = FileValueUI.amount + FileValue.cgst_amount + FileValue.igst_amount + FileValue.sgst_amount + FileValue.taxable_amt
            updatedItem.cgst_amount = FileValueUI.cgst_amount + FileValue.cgst_amount
            updatedItem.igst_amount = FileValueUI.igst_amount + FileValue.igst_amount
            updatedItem.sgst_amount = FileValueUI.sgst_amount + FileValue.sgst_amount
            updatedItem.tax_amount = FileValueUI.tax_amt + FileValue.cgst_amount + FileValue.igst_amount + FileValue.sgst_amount
            updatedItem.tot_qty = tot_qty + qty
            updatedItem.DetailGrid = [...updatedItem.DetailGrid, newItemPlus]
          } else if (type === "salesreturn") {
            updatedItem.amount = FileValueUI.amount - FileValue.cgst_amount - FileValue.igst_amount - FileValue.sgst_amount - FileValue.taxable_amt
            updatedItem.netamount = FileValueUI.netamount - FileValue.taxable_amt
            updatedItem.cgst_amount = FileValueUI.cgst_amount - FileValue.cgst_amount
            updatedItem.igst_amount = FileValueUI.igst_amount - FileValue.igst_amount
            updatedItem.sgst_amount = FileValueUI.sgst_amount - FileValue.sgst_amount
            updatedItem.tax_amount = FileValueUI.tax_amt - FileValue.cgst_amount - FileValue.igst_amount - FileValue.sgst_amount
            updatedItem.tot_qty = tot_qty + qty
            updatedItem.DetailGrid = [...updatedItem.DetailGrid, newItemMinus]
          }
        }

        return updatedItem
      })
    } else {
      if (type === "salesinvoice" || type === "purchasereturn") {
        mainType.detailData.records.list.push({ ...newItemPlus, DetailGrid: [newItemPlus] })
      } else if (type === "salesreturn") {
        mainType.detailData.records.list.push({ ...newItemMinus, DetailGrid: [newItemMinus] })
      }
    }
  })

  mainType.Taxable_Amt = 0
  mainType.cgst_Amt = 0
  mainType.sgst_Amt = 0
  mainType.igst_Amt = 0
  mainType.cess_Amt = 0
  mainType.Tax_Amt = 0
  mainType.Total_Amt = 0
  mainType.detailData.records.detail.tot_cess = 0
  mainType.detailData.records.detail.tot_cgst = 0
  mainType.detailData.records.detail.tot_igst = 0
  mainType.detailData.records.detail.tot_netamount = 0
  mainType.detailData.records.detail.tot_sgst = 0

  mainType.detailData.records.list = mainType.detailData.records.list.map((i) => {
    const updated = { ...i }
    const FileValues = getFileValuesV1(i)

    mainType.Taxable_Amt = parseFloat(getNumberonly(mainType.Taxable_Amt)) + FileValues.netamount
    mainType.Tax_Amt = parseFloat(getNumberonly(mainType.Tax_Amt)) + FileValues.tax_amt
    mainType.cgst_Amt = parseFloat(getNumberonly(mainType.cgst_Amt)) + FileValues.cgst_amount
    mainType.sgst_Amt = parseFloat(getNumberonly(mainType.sgst_Amt)) + FileValues.sgst_amount
    mainType.igst_Amt = parseFloat(getNumberonly(mainType.igst_Amt)) + FileValues.igst_amount
    mainType.cess_Amt = parseFloat(getNumberonly(mainType.cess_Amt)) + FileValues.cess

    mainType.Total_Amt = parseFloat(getNumberonly(mainType.Total_Amt)) + FileValues.amount

    mainType.detailData.records.detail.tot_cess = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_cess)) + FileValues.cess
    mainType.detailData.records.detail.tot_cgst = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_cgst)) + FileValues.cgst_amount
    mainType.detailData.records.detail.tot_igst = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_igst)) + FileValues.igst_amount
    mainType.detailData.records.detail.tot_netamount = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_netamount)) + FileValues.amount
    mainType.detailData.records.detail.tot_sgst = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_sgst)) + FileValues.sgst_amount
    updated.itemcodes = []
    updated.allaccountcodes = []

    if (updated.DetailGrid.length > 1) {
      updated.DetailGrid.forEach((j) => {
        updated.itemcodes = addItemCodes(updated.itemcodes, j.itemcode)
      })

      if (updated.itemcodes.length > 1) {
        updated.description = i.itemcode === "0" ? "" : dditem[i.itemcode].name // `${getdropdownfieldvalue(dditem, "code", i.itemcode, "name")}  (+ ${updated.itemcodes.length - 1} more)`
      } else if (updated.itemcodes.length > 0) {
        updated.description = i.itemcode === "0" ? "" : dditem[i.itemcode].name //getdropdownfieldvalue(dditem, "code", i.itemcode, "name")
      }
    } else {
      updated.description = i.itemcode === "0" ? "" : dditem[i.itemcode].name //getdropdownfieldvalue(dditem, "code", i.itemcode, "name")
    }
    var errorData = []
    if (isblank(updated.description)) {
      errorData = addError(errorData, "Description is required")
    }
    if (isblank(updated.hsn_key)) {
      errorData = addError(errorData, "HSN is required")
    }
    if (isblank(updated.uqc)) {
      errorData = addError(errorData, "Unit is required")
    }
    if (isblank(updated.tot_qty)) {
      errorData = addError(errorData, "Total Qty is required")
    }
    if (isblank(updated.netamount)) {
      errorData = addError(errorData, "Total taxable amount is required")
    }
    if (isblank(updated.amount)) {
      errorData = addError(errorData, "Total amount is required")
    }
    if (!isblank(errorData) && errorData.length > 0) {
      updated.error = `Error`
      updated.errorData = errorData
    }
    return updated
  })
  mainType.detailData.records.detail.tot_transaction = mainType.detailData.records.list.length
  mainType.Invoices = mainType.detailData.records.list.length
  mainType.Error_flag = mainType.detailData.records.list.filter((f) => !isblank(f.error)).length
  return mainType
}

const checkHSNConditionsInV2 = ({ mainType, e, dditem, type, functions, setconfig, inventory_decimalpoint, TypeNames }) => {
  if ((setconfig.type === "gstr3B" && convertIntif(e.gstr3B_status) === 1) || (setconfig.type === "gstr1" && convertIntif(e.gstr1_status) === 1)) {
    return mainType
  }

  const addItemCodes = (itemcodesArray, itemcode) => {
    if (itemcodesArray.filter((i) => i === itemcode).length === 0) {
      itemcodesArray.push(itemcode)
    }
    return itemcodesArray
  }

  e.invoiceDetail.forEach((i) => {
    const hsnCode = i.itemcode === "0" ? i.hsncode : i.hsn_code
    const itemtype = !isblank(i.itemtype) ? i.itemtype : i.item_type
    const unitCode = convertIntif(itemtype) === 2 ? "NA" : `${i.unit}-${i.unit_name}`
    var Qty = 0

    if (convertIntif(itemtype) !== 2) {
      if (type === "purchaseinvoice" || type === "salesreturn") {
        Qty = getFileValue(i.qty, inventory_decimalpoint)
      } else if (type === "purchasereturn") {
        Qty = getFileValue(i.qty, inventory_decimalpoint) * -1
      }
    }

    var taxRate = getTaxRate(i.taxdetail)
    var invoice_type = ""
    if (type === "purchaseinvoice") {
      invoice_type = TypeNames[e.invType] // getNameFromType(e.invType, false, getAccountSitesetting)
    } else {
      invoice_type = TypeNames[type] //getNameFromType(type, false, getAccountSitesetting)
    }

    const FileValue = getFileValues(i)

    const description = i.itemcode === "0" ? i.itemSelectedValue : dditem[i.itemcode].name //getdropdownfieldvalue(dditem, "code", i.itemcode, "name")
    const uqc_short = convertIntif(itemtype) === 2 ? "NA" : i.itemcode === "0" ? "" : dditem[i.itemcode].unit //getdropdownfieldvalue(dditem, "code", i.itemcode, "unit")
    // const cgst_amount = getFileValue(parseFloat(getNumberonly(i.CGST)))
    // const sgst_amount = getFileValue(parseFloat(getNumberonly(i.SGST)))
    // const igst_amount = getFileValue(parseFloat(getNumberonly(i.IGST)))
    // const taxable_amt = getFileValue(parseFloat(getNumberonly(i.taxable_amt)))
    const exp_typ = checkCondition(e.export_type, [2, 5]) ? "WOPAY" : "WPAY"

    var newItemPlus = {
      amount: FileValue.cgst_amount + FileValue.igst_amount + FileValue.sgst_amount + FileValue.taxable_amt,
      netamount: FileValue.taxable_amt,
      formData: { type: type, other_type: e.invType, invType_voucher: e.invType_voucher },
      description: description,
      invoice_type: invoice_type,
      invoice_code: e.code,
      oldBillCode: e.oldBillCode,
      b_date: e.b_date,
      billno: e.billno,
      exp_typ: exp_typ,
      expShippDetail: e.expShippDetail,
      cess: "0.00",
      cgst_amount: FileValue.cgst_amount,
      itemcode: i.itemcode,
      accountSelectedValue: i.accountSelectedValue,
      hsn_key: hsnCode,
      igst_amount: FileValue.igst_amount,
      rate: taxRate,
      sgst_amount: FileValue.sgst_amount,
      tax_amount: FileValue.cgst_amount + FileValue.igst_amount + FileValue.sgst_amount,
      tot_qty: Qty,
      uqc: unitCode,
      uqc_short: uqc_short,
    }
    var newItemMinus = {
      amount: FileValue.cgst_amount * -1 + FileValue.igst_amount * -1 + FileValue.sgst_amount * -1 + FileValue.taxable_amt * -1,
      netamount: FileValue.taxable_amt * -1,
      cess: "0.00",
      formData: { type: type, other_type: e.invType, invType_voucher: e.invType_voucher },
      description: description,
      invoice_type: invoice_type,
      invoice_code: e.code,
      oldBillCode: e.oldBillCode,
      b_date: e.b_date,
      billno: e.billno,
      exp_typ: exp_typ,
      expShippDetail: e.expShippDetail,
      cgst_amount: FileValue.cgst_amount * -1,
      itemcode: i.itemcode,
      accountSelectedValue: i.accountSelectedValue,
      hsn_key: hsnCode,
      igst_amount: FileValue.igst_amount * -1,
      rate: taxRate,
      sgst_amount: FileValue.sgst_amount * -1,
      tax_amount: FileValue.cgst_amount * -1 + FileValue.igst_amount * -1 + FileValue.sgst_amount * -1,
      tot_qty: Qty,
      uqc: unitCode,
      uqc_short: uqc_short,
    }
    if (mainType.detailData.records.list.filter((k) => k.hsn_key === hsnCode && convertIntif(k.rate) === convertIntif(taxRate) && k.uqc === unitCode).length > 0) {
      mainType.detailData.records.list = mainType.detailData.records.list.map((l) => {
        const updatedItem = { ...l }
        const FileValueUI = getFileValuesV1(updatedItem)
        const tot_qty = parseFloat(getNumberonlyDecimal(updatedItem.tot_qty, inventory_decimalpoint))
        const qty = parseFloat(getNumberonlyDecimal(Qty, inventory_decimalpoint))

        if (updatedItem.hsn_key === hsnCode && convertIntif(updatedItem.rate) === convertIntif(taxRate) && updatedItem.uqc === unitCode) {
          if (type === "purchaseinvoice" || type === "salesreturn") {
            updatedItem.netamount = FileValueUI.netamount + FileValue.taxable_amt
            updatedItem.amount = FileValueUI.amount + FileValue.cgst_amount + FileValue.igst_amount + FileValue.sgst_amount + FileValue.taxable_amt
            updatedItem.cgst_amount = FileValueUI.cgst_amount + FileValue.cgst_amount
            updatedItem.igst_amount = FileValueUI.igst_amount + FileValue.igst_amount
            updatedItem.sgst_amount = FileValueUI.sgst_amount + FileValue.sgst_amount
            updatedItem.tax_amount = FileValueUI.tax_amt + FileValue.cgst_amount + FileValue.igst_amount + FileValue.sgst_amount
            updatedItem.tot_qty = tot_qty + qty
            updatedItem.DetailGrid = [...updatedItem.DetailGrid, newItemPlus]
          } else if (type === "purchasereturn") {
            updatedItem.amount = FileValueUI.amount - FileValue.cgst_amount - FileValue.igst_amount - FileValue.sgst_amount - FileValue.taxable_amt
            updatedItem.netamount = FileValueUI.netamount - FileValue.taxable_amt
            updatedItem.cgst_amount = FileValueUI.cgst_amount - FileValue.cgst_amount
            updatedItem.igst_amount = FileValueUI.igst_amount - FileValue.igst_amount
            updatedItem.sgst_amount = FileValueUI.sgst_amount - FileValue.sgst_amount
            updatedItem.tax_amount = FileValueUI.tax_amt - FileValue.cgst_amount - FileValue.igst_amount - FileValue.sgst_amount
            updatedItem.tot_qty = tot_qty + qty
            updatedItem.DetailGrid = [...updatedItem.DetailGrid, newItemMinus]
          }
        }

        return updatedItem
      })
    } else {
      if (type === "purchaseinvoice" || type === "salesreturn") {
        mainType.detailData.records.list.push({ ...newItemPlus, DetailGrid: [newItemPlus] })
      } else if (type === "purchasereturn") {
        mainType.detailData.records.list.push({ ...newItemMinus, DetailGrid: [newItemMinus] })
      }
    }
  })

  mainType.Taxable_Amt = 0
  mainType.cgst_Amt = 0
  mainType.sgst_Amt = 0
  mainType.igst_Amt = 0
  mainType.cess_Amt = 0
  mainType.Tax_Amt = 0
  mainType.Total_Amt = 0
  mainType.detailData.records.detail.tot_cess = 0
  mainType.detailData.records.detail.tot_cgst = 0
  mainType.detailData.records.detail.tot_igst = 0
  mainType.detailData.records.detail.tot_netamount = 0
  mainType.detailData.records.detail.tot_sgst = 0

  mainType.detailData.records.list = mainType.detailData.records.list.map((i) => {
    const updated = { ...i }
    const FileValues = getFileValuesV1(i)

    mainType.Taxable_Amt = parseFloat(getNumberonly(mainType.Taxable_Amt)) + FileValues.netamount
    mainType.Tax_Amt = parseFloat(getNumberonly(mainType.Tax_Amt)) + FileValues.tax_amt
    mainType.cgst_Amt = parseFloat(getNumberonly(mainType.cgst_Amt)) + FileValues.cgst_amount
    mainType.sgst_Amt = parseFloat(getNumberonly(mainType.sgst_Amt)) + FileValues.sgst_amount
    mainType.igst_Amt = parseFloat(getNumberonly(mainType.igst_Amt)) + FileValues.igst_amount
    mainType.cess_Amt = parseFloat(getNumberonly(mainType.cess_Amt)) + FileValues.cess

    mainType.Total_Amt = parseFloat(getNumberonly(mainType.Total_Amt)) + FileValues.amount

    mainType.detailData.records.detail.tot_cess = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_cess)) + FileValues.cess
    mainType.detailData.records.detail.tot_cgst = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_cgst)) + FileValues.cgst_amount
    mainType.detailData.records.detail.tot_igst = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_igst)) + FileValues.igst_amount
    mainType.detailData.records.detail.tot_netamount = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_netamount)) + FileValues.amount
    mainType.detailData.records.detail.tot_sgst = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_sgst)) + FileValues.sgst_amount
    updated.itemcodes = []
    updated.allaccountcodes = []

    if (updated.DetailGrid.length > 1) {
      updated.DetailGrid.forEach((j) => {
        if (j.itemcode === "0") {
          updated.allaccountcodes = addItemCodes(updated.allaccountcodes, j.description)
        } else {
          updated.itemcodes = addItemCodes(updated.itemcodes, j.itemcode)
        }
      })

      if (updated.itemcodes.length > 1) {
        updated.description = i.itemcode === "0" ? i.itemSelectedValue : dditem[i.itemcode].name //`${getdropdownfieldvalue(dditem, "code", i.itemcode, "name")}  (+ ${updated.itemcodes.length - 1} more)`
      } else if (updated.allaccountcodes.length > 1) {
        //updated.description =  `${i.description} (+ ${updated.allaccountcodes.length - 1} more)`
        updated.description = i.description
      } else if (updated.itemcodes.length > 0) {
        updated.description = i.itemcode === "0" ? i.itemSelectedValue : dditem[i.itemcode].name //getdropdownfieldvalue(dditem, "code", i.itemcode, "name")
      } else if (updated.allaccountcodes.length > 0) {
        updated.description = i.description
      }
    } else {
      if (i.itemcode === "0") {
        updated.description = i.description
      } else {
        updated.description = dditem[i.itemcode].name //getdropdownfieldvalue(dditem, "code", i.itemcode, "name")
      }
    }
    var errorData = []
    if (isblank(updated.description)) {
      errorData = addError(errorData, "Description is required")
    }
    if (isblank(updated.hsn_key)) {
      errorData = addError(errorData, "HSN is required")
    }
    if (isblank(updated.uqc)) {
      errorData = addError(errorData, "Unit is required")
    }
    if (isblank(updated.tot_qty)) {
      errorData = addError(errorData, "Total Qty is required")
    }
    if (isblank(updated.netamount)) {
      errorData = addError(errorData, "Total taxable amount is required")
    }
    if (isblank(updated.amount)) {
      errorData = addError(errorData, "Total amount is required")
    }
    if (!isblank(errorData) && errorData.length > 0) {
      updated.error = `Error`
      updated.errorData = errorData
    }
    return updated
  })
  mainType.detailData.records.detail.tot_transaction = mainType.detailData.records.list.length
  mainType.Invoices = mainType.detailData.records.list.length
  mainType.Error_flag = mainType.detailData.records.list.filter((f) => !isblank(f.error)).length
  return mainType
}
const checkJournalEntryConditions = ({ mainType, e, formType, taxCodeArray, taxCodeArray1, meta_Data }) => {
  const { setconfig, dataKey } = meta_Data
  const partyData = e.party_detail
  var formTypeLabel = "Journal Entry"
  const newDetailItem = {
    dataKey: dataKey,
    amount: parseFloat(getNumberonly(e.amount)),
    gstr3B_pending_status: e.gstr3B_status,
    gstr1_pending_status: e.gstr1_status,
    billno: e.billno,
    cess: 0,
    cgst_amount: parseFloat(getNumberonly(e.cgst_amt)),
    code: e.code,
    date: e.b_date,
    formTypeLabel: formTypeLabel,
    formData: { type: formType, other_type: e.invType, invType_voucher: e.invType_voucher },
    gstin: partyData.gstin ? partyData.gstin : "N/A",
    gst_treatment_label: partyData.gst_treatment ? getdropdownfieldvalue({ dropDownData: getgstbusinesstypelist(), field: "id", value: partyData.gst_treatment, displayvalue: "name" }) : "N/A",
    gst_treatment: partyData.gst_treatment,
    gstin_error: 0,
    gstr1_filed_date: setFiledDateToolTip(e, "gstr1"),
    gstr3B_filed_date: setFiledDateToolTip(e, "gstr3b"),
    gstr1_filed: e.gstr1_filed, // === "1" ? "Filed" : "Not Filed",
    gstr3B_filed: e.gstr3B_filed, // === "1" ? "Filed" : "Not Filed",
    igst_amount: parseFloat(getNumberonly(e.igst_amt)),
    reversable_charge: convertIntif(e.reversable_charge) === 1 ? "Y" : "N",
    invoice_code: e.code,
    invoicetype: "J",
    name: partyData.name,
    netamount: parseFloat(getNumberonly(e.netamount)),
    partycode: e.partycode,
    zeroRatedType: e.zeroRatedType,
    sgst_amount: parseFloat(getNumberonly(e.sgst_amt)),
    tax_amount: parseFloat(getNumberonly(e.igst_amt)) + parseFloat(getNumberonly(e.cgst_amt)) + parseFloat(getNumberonly(e.sgst_amt)),
    type: "J",
    prev_billno: "",
    prev_billdate: "",
    change_sign: false,
    oldOrPrevious_flag: checkOldOrPrevInvoiceFlag(setconfig, e),
  }

  if (convertIntif(newDetailItem.gstr3B_pending_status) === 1) {
    mainType.detailData.records.pending.push(newDetailItem)
  } else {
    mainType.detailData.records.detail.tot_cess = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_cess)) + parseFloat(getNumberonly(newDetailItem.cess))
    mainType.detailData.records.detail.tot_cgst = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_cgst)) + parseFloat(getNumberonly(newDetailItem.cgst_amount))
    mainType.detailData.records.detail.tot_igst = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_igst)) + parseFloat(getNumberonly(newDetailItem.igst_amount))
    mainType.detailData.records.detail.tot_netamount = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_netamount)) + parseFloat(getNumberonly(newDetailItem.amount))
    mainType.detailData.records.detail.tot_sgst = parseFloat(getNumberonly(mainType.detailData.records.detail.tot_sgst)) + parseFloat(getNumberonly(newDetailItem.sgst_amount))

    mainType.Taxable_Amt = parseFloat(getNumberonly(mainType.Taxable_Amt)) + parseFloat(getNumberonly(arraySumTaxCondition(e.invoiceDetailDetail, "assable_amt", taxCodeArray1)))
    mainType.Tax_Amt = parseFloat(getNumberonly(mainType.Tax_Amt)) + parseFloat(getNumberonly(arraySumTaxCondition(e.invoiceDetailDetail, "amt", taxCodeArray)))
    mainType.Total_Amt = parseFloat(getNumberonly(mainType.Total_Amt)) + parseFloat(getNumberonly(e.netamt))

    mainType.cgst_Amt = parseFloat(getNumberonly(mainType.cgst_Amt)) + parseFloat(getNumberonly(newDetailItem.cgst_amount))
    mainType.sgst_Amt = parseFloat(getNumberonly(mainType.sgst_Amt)) + parseFloat(getNumberonly(newDetailItem.sgst_amount))
    mainType.igst_Amt = parseFloat(getNumberonly(mainType.igst_Amt)) + parseFloat(getNumberonly(newDetailItem.igst_amount))
    mainType.cess_Amt = 0

    mainType.detailData.records.list.push(newDetailItem)
    mainType.Invoices = mainType.Invoices + 1
    mainType.detailData.records.detail.tot_transaction = mainType.detailData.records.detail.tot_transaction + 1

    if (!isblank(newDetailItem.error)) {
      mainType.Error_flag = mainType.Error_flag + 1
    }
  }

  // mainType.Oldinvoice_flag =
  //   mainType.detailData.records.list.filter((e) => e.oldOrPrevious_flag === "Old").length + mainType.detailData.records.pending.filter((e) => e.oldOrPrevious_flag === "Old").length
  mainType.Oldinvoice_flag = mainType.detailData.records.list.filter((e) => e.oldOrPrevious_flag === "Old").length
  mainType.Previousinvoice_flag =
    mainType.detailData.records.list.filter((e) => e.oldOrPrevious_flag === "Previous").length + mainType.detailData.records.pending.filter((e) => e.oldOrPrevious_flag === "Previous").length
  mainType.GSTR3BPendindinovice_flag = mainType.detailData.records.pending.length
  mainType.GSTR1Pendindinovice_flag = mainType.detailData.records.pending.length
  return mainType
}

const checkGSTConditions = ({ mainType, e, type, formType, taxCodeArray, taxCodeArray1, meta_Data, change_sign }) => {
  const { company_stateCode, setconfig, dataKey, dd_country_state } = meta_Data
  const partyData = e.party_detail
  const metaData = {
    mainType,
    e,
    partyData,
    taxCodeArray1,
    taxCodeArray,
    type,
    formType,
    setconfig,
    dataKey,
    dd_country_state,
  }
  if (type === "B2B" || type === "B2BA") {
    if (e.invType !== "export_invoice" && convertIntif(e.reversable_charge) !== 1) {
      if (checkCondition(partyData.gst_treatment, [3, 4, 5], "not")) {
        mainType = getDetailByType({ metaData, invoicetype: "S" })
      }
    }
  } else if (type === "B2CL") {
    if (
      convertIntif(e.placeof_supply) !== convertIntif(company_stateCode) &&
      convertIntif(e.reversable_charge) !== 1 &&
      checkCondition(partyData.gst_treatment, [3, 4]) &&
      parseFloat(getNumberonly(e.netamt)) > getThresholdFromDate(e.b_date)
    ) {
      mainType = getDetailByType({ metaData, invoicetype: "S" })
    }
  } else if (type === "CDNR") {
    if (metaData.e.invType === "VS" || metaData.e.invType === "VP") {
      metaData.e.invType_voucher = true
    }
    metaData.e.invType = formType
    if (convertIntif(e.no_gst) !== 1 && convertIntif(e.reversable_charge) !== 1) {
      if (checkCondition(partyData.gst_treatment, [1, 2])) {
        metaData.e.invType = formType
        if (formType === "purchasereturn") {
          mainType = getDetailByType({ metaData, invoicetype: "PU", change_sign })
        } else {
          mainType = getDetailByType({ metaData, invoicetype: "S", change_sign })
        }
      }
    }
  } else if (type === "CDNUR") {
    if (metaData.e.invType === "VS" || metaData.e.invType === "VP") {
      metaData.e.invType_voucher = true
    }
    metaData.e.invType = formType
    if (convertIntif(e.no_gst) !== 1 && convertIntif(e.reversable_charge) !== 1) {
      if (
        checkCondition(partyData.gst_treatment, [3, 4]) &&
        convertIntif(e.placeof_supply) !== convertIntif(meta_Data.company_stateCode) &&
        parseFloat(getNumberonly(e.linkedInvoiceDetail[0].netamt)) > getThresholdFromDate(e.b_date)
      ) {
        if (formType === "purchasereturn") {
          mainType = getDetailByType({ metaData, invoicetype: "PU", change_sign })
        } else {
          mainType = getDetailByType({ metaData, invoicetype: "S", change_sign })
        }
      }
    }
  } else if (type === "Export_Invoices") {
    if (e.invType === "export_invoice" && convertIntif(e.reversable_charge) !== 1 && checkCondition(partyData.gst_treatment, [5, 6])) {
      mainType = getDetailByType({ metaData, invoicetype: "S" })
    }
  } else if (type === "B2CS") {
    if (checkCondition(partyData.gst_treatment, [3, 4]) && convertIntif(e.reversable_charge) !== 1 && convertIntif(e.no_gst) !== 1) {
      if (formType === "salesreturn" || formType === "purchasereturn") {
        if (
          convertIntif(e.placeof_supply) === convertIntif(company_stateCode) ||
          (convertIntif(e.placeof_supply) !== convertIntif(company_stateCode) && parseFloat(getNumberonly(e.linkedInvoiceDetail[0].netamt)) <= getThresholdFromDate(e.b_date))
        ) {
          if (formType === "purchasereturn") {
            mainType = getDetailByType({ metaData, invoicetype: "PU", change_sign })
          } else {
            mainType = getDetailByType({ metaData, invoicetype: "S", change_sign })
          }
        }
      } else {
        if (
          convertIntif(e.placeof_supply) === convertIntif(company_stateCode) ||
          (convertIntif(e.placeof_supply) !== convertIntif(company_stateCode) && parseFloat(getNumberonly(e.netamt)) <= getThresholdFromDate(e.b_date))
        ) {
          mainType = getDetailByType({ metaData, invoicetype: "S", change_sign })
        }
      }
    }
  } else if (type === "NIL") {
    if (checkCondition(e.zeroRatedType, [1, 2, 3]) && convertIntif(e.reversable_charge) !== 1) {
      mainType = getDetailByType({ metaData, invoicetype: "S" })
    }
  } else if (type === "ZERO") {
    // if (checkCondition(e.zeroRatedType, [4]) && convertIntif(e.reversable_charge) !== 1 && checkCondition(partyData.gst_treatment, [1, 2]) && convertIntif(partyData.sid) !== convertIntif(company_stateCode)) {
    //     mainType = getDetailByType(metaData, 'S')
    // }

    if (checkCondition(e.zeroRatedType, [4]) && convertIntif(e.reversable_charge) !== 1) {
      mainType = getDetailByType({ metaData, invoicetype: "S" })
    }
  } else if ((type === "ITC" || type === "inward_supplies") && !checkCondition(partyData.gst_treatment, [5, 6]) && e.invType !== "import_invoice") {
    // WITHOUT OVERSEAS And SEZ
    if ((type === "ITC" && convertIntif(e.reversable_charge) !== 1) || (type === "inward_supplies" && convertIntif(e.reversable_charge) === 1)) {
      if (formType === "purchasereturn") {
        mainType = getDetailByType({ metaData, invoicetype: `Debit Note (${e.invType})`, change_sign })
      } else if (formType === "salesreturn") {
        mainType = getDetailByType({ metaData, invoicetype: `Credit Note (${e.invType})`, change_sign })
      } else {
        mainType = getDetailByType({ metaData, invoicetype: "Purchase Invoice", change_sign })
      }
    }
  } else if ((type === "ITC_IG" || type === "inward_supplies") && e.invType === "import_invoice") {
    // Import Goods
    if ((type === "ITC_IG" && convertIntif(e.reversable_charge) !== 1) || (type === "inward_supplies" && convertIntif(e.reversable_charge) === 1)) {
      if (formType === "purchasereturn") {
        mainType = getDetailByType({ metaData, invoicetype: `Debit Note (${e.invType})`, change_sign })
      } else if (formType === "salesreturn") {
        mainType = getDetailByType({ metaData, invoicetype: `Credit Note (${e.invType})`, change_sign })
      } else {
        mainType = getDetailByType({ metaData, invoicetype: "Purchase Invoice", change_sign })
      }
    }
  }
  return mainType
}

export const setColumnData = ({ formValues, user_data, dd_orderTypes, reportdata, columnData = [], subcolumnData = [], subsubcolumnData = [], functions }) => {
  const { getAccountSitesetting } = functions
  // var columnDatafinal = cloneObj(columnData)
  var columnDatafinal = cloneObjV2(columnData)
  var subcolumnDatafinal = cloneObjV2(subcolumnData)
  var subsubcolumnDatafinal = cloneObjV2(subsubcolumnData)
  var reportLevel = 1
  var includingamount = "0"
  var itemViewtype = "0"
  var viewType = "0"
  var agentCommType = "0"
  var serialType = "0"
  var transporttype = "0"
  var partywise = "0"
  var tdsType = "0"
  var reportFormat = "1"
  var order_view_type = "summary"
  const report_format = getAccountSitesetting("report_format", user_data)
  const adjust_bill = parseInt(getAccountSitesetting("adjust_bill", user_data))

  includingamount = formValues.includingAmount === true ? "1" : "0"
  itemViewtype = isblank(formValues.itemTypeSelectedValue) ? "0" : formValues.itemTypeSelectedValue
  agentCommType = isblank(formValues.agentCommissionSelValue) ? "0" : formValues.agentCommissionSelValue
  viewType = isblank(formValues.viewTypeSelectedValue) ? "Detail" : formValues.viewTypeSelectedValue
  transporttype = isblank(formValues.transportTypeSelectedValue) ? "0" : formValues.transportTypeSelectedValue
  var outstandingtype = isblank(formValues.multiOutstandingSelValue) ? "All" : formValues.multiOutstandingSelValue
  partywise = isblank(formValues.agentTypeSelectedValue) ? "0" : formValues.agentTypeSelectedValue
  serialType = isblank(formValues.productSerialTypeSelectedValue) ? "0" : formValues.productSerialTypeSelectedValue
  if (report_format === 2) {
    reportFormat = isblank(formValues.reportFormat) ? report_format : formValues.reportFormat
  }
  tdsType = isblank(formValues.type) ? "0" : formValues.type
  var reportViewType = ""
  if (adjust_bill === 1) {
    reportViewType = isblank(formValues.reportViewType) ? "0" : formValues.reportViewType
  }
  order_view_type = isblank(formValues.order_view_type) ? order_view_type : formValues.order_view_type
  /*   columnDatafinal = columnData
  subsubcolumnDatafinal = subsubcolumnData */

  if (!isblank(reportdata)) {
    var value = reportdata.reportid

    if (checkCondition(value, [re.FinishStockInOutReport])) {
      // remove all __cust_pcs
      columnDatafinal = columnDatafinal.filter((item, index) => {
        if (item.id === "__cust_pcs_in" || item.id === "__cust_pcs_out") {
          return false
        } else {
          return true
        }
      })
      subcolumnDatafinal = subcolumnDatafinal.filter((item, index) => {
        if (item.id === "__cust_pcs_in" || item.id === "__cust_pcs_out") {
          return false
        } else {
          return true
        }
      })

      if (formValues.show_bundle !== true) {
        columnDatafinal = columnDatafinal.filter((item, index) => {
          if (item.id === "bundle_in" || item.id === "bundle_out" || item.id === "bundle_balance") {
            return false
          } else {
            return true
          }
        })
        subcolumnDatafinal = subcolumnDatafinal.filter((item, index) => {
          if (item.id === "bundle_in" || item.id === "bundle_out" || item.id === "bundle_balance") {
            return false
          } else {
            return true
          }
        })
      }

      if (formValues.show_opening !== true) {
        columnDatafinal = columnDatafinal.filter((item, index) => {
          if (item.id === "op_pcs" || item.id === "op_bundle" || item.id === "op") {
            return false
          } else {
            return true
          }
        })
        subcolumnDatafinal = subcolumnDatafinal.filter((item, index) => {
          if (item.id === "op_pcs" || item.id === "op_bundle" || item.id === "op") {
            return false
          } else {
            return true
          }
        })
      }
    } else if (checkCondition(value, [re.ProductBatchDetailReport])) {
      if (serialType === "0") {
        columnDatafinal = columnDatafinal.filter((e) => e.id !== "qin" && e.id !== "qout")
      }
    } else if (checkCondition(value, [re.ReorderLevelReport]) && includingamount === "1") {
      columnDatafinal = closingReportcolumn
    } else if (checkCondition(value, [re.AgentWiseSalesReport])) {
      if (agentCommType === "0") {
        columnDatafinal = columnDatafinal.filter((e) => e.id !== "agentcomm_amt")
        subcolumnDatafinal = subcolumnDatafinal.filter((e) => e.id !== "agentcomm_amt")
        subsubcolumnDatafinal = subsubcolumnDatafinal.filter((e) => e.id !== "partyname" && e.id !== "agentcomm_amt")
      } else {
        subsubcolumnDatafinal = subsubcolumnDatafinal.filter((e) => e.id !== "partyname")
      }
    } else if (checkCondition(value, [re.ProcessStockInOutReport]) && formValues.showPcs !== true) {
      columnDatafinal = columnDatafinal.filter((e) => e.id !== "pcs" && e.id !== "penpcs" && e.id !== "pcs_balance")
    }
    if (checkCondition(value, [re.OutstandingReport]) && formValues.reportViewType === "0") {
      columnDatafinal = columnDatafinal.map((e) => {
        const row = { ...e }
        if (row.id === "Outstanding") {
          row.total = true
          row.difference = ""
        }
        return row
      })
    } else if (checkCondition(value, [re.MultiOutstandingReport])) {
      columnDatafinal = columnMultiSummary
      if (reportViewType === "1" && viewType === "Summary") {
        columnDatafinal = columnMultiOutSummary
      }
      if (viewType === "Summary" && outstandingtype !== "All") {
        reportLevel = 3
      }
      if (viewType === "Detail") {
        reportLevel = 3
        columnDatafinal = columnMultiDetail
      }

      // if (!isblank(formValues) && formValues.reportViewType === "1") {
      //   columnDatafinal = columnDatafinal.filter((e) => e.id !== "dayDiff")
      // }
    } else if (checkCondition(value, [re.MultiOutstandingAgeingReport])) {
      if (viewType === "Summary" && outstandingtype === "All") {
        if (convertIntif(reportViewType) === 1) {
          columnDatafinal = columnMultiAgeingSummary
        } else {
          columnDatafinal = columnMultiAgeingSummary.filter((e) => e.id !== "onAccountNetAmount")
        }
      } else if (viewType === "Summary" && outstandingtype !== "All") {
        reportLevel = 3
        if (convertIntif(reportViewType) === 1) {
          columnDatafinal = columnMultiAgeingSummary
        } else {
          columnDatafinal = columnMultiAgeingSummary.filter((e) => e.id !== "onAccountNetAmount")
        }
      }
      if (formValues.dayRange === "2" || formValues.dayRange === "3") {
        columnDatafinal = changeColumnDataAgeingReport(columnDatafinal, formValues.dayRange)
      }
      if (viewType === "Detail") {
        reportLevel = 3
        columnDatafinal = MultiOutstandingAgeingDetailColumn
      }
    } else if (checkCondition(value, [re.MultiLedgerReport])) {
      columnDatafinal = columnMultiLedgerSummary
      if (viewType === "Summary" && outstandingtype !== "All") {
        columnDatafinal = multiOutFirstDetail.filter((e) => e.id !== "mobile")
        subcolumnData = columnMultiLedgerSummary.filter((e) => e.id !== "balance")
        reportLevel = 3
      }
      if (viewType === "Detail") {
        reportLevel = 3
        columnDatafinal = columnMultiLedger
        if (outstandingtype !== "All") {
          columnDatafinal = columnDatafinal.map((item) => {
            const updatedItem = { ...item }
            if (updatedItem.id === "docno") {
              //delete updatedItem.showlink
            }
            return updatedItem
          })
        }
      }
    } else if (checkCondition(value, [re.TdsRegister])) {
      if (tdsType === "1") {
        columnDatafinal = columnDataTDS
      }
    } /*  else if (checkCondition(value, [re.OutstandingReport])) {
      if (!isblank(formValues) && formValues.reportViewType === "1") {
        columnDatafinal = columnDatafinal.filter((e) => e.id !== "dayDiff")
      }
    }  */ else if (checkCondition(value, [re.LedgerReport]) && report_format === 2) {
      if (reportFormat.toString() === "2") {
        columnDatafinal = columnDataVerticalLedger
      }
    } else if (checkCondition(value, [re.InterestReport]) && report_format === 2) {
      if (reportFormat.toString() === "2") {
        columnDatafinal = columnDataVerticalInterest
      }
    } else if (
      checkCondition(value, [re.SalesRegister, re.PurchaseRegister, re.SalesReturnRegister, re.PurchaseReturnRegister, re.Estimate, re.JobDeliveryChallanRegister, re.SalesOrder, re.PurchaseOrder])
    ) {
      // if (formValues.status !== "All" && !isblank(formValues.status)) {
      //   columnDatafinal = columnDatafinal.filter((it) => it.id !== "Status")
      // }
      if (!isblank(formValues.status) && formValues.status.length === 1 && formValues.status[0] !== "All") {
        columnDatafinal = columnDatafinal.filter((it) => it.id !== "Status")
      }
      if (!isblank(formValues.customerSelectedValue)) {
        columnDatafinal = columnDatafinal.filter((it) => it.id !== "PartyName" && it.id !== "City")
      }
      if (dd_orderTypes.length < 2 || (!isblank(formValues.other_type) && formValues.other_type !== "all")) {
        columnDatafinal = columnDatafinal.filter((it) => it.id !== "invoice_type_label")
      }
    } else if (checkCondition(value, [re.PackingSlipRegister, re.GoodsReceivedNoteRegister, re.DeliveryChallanRegister, re.JobInwardRegister, re.FinishStockRegister, re.ManageLotRegister])) {
      if (formValues.status !== "all" && !isblank(formValues.status)) {
        columnDatafinal = columnDatafinal.filter((e) => e.id !== "Status")
      }
    } else if (checkCondition(value, [re.OutstandingAgeingReport])) {
      if (formValues.dayRange === "2" || formValues.dayRange === "3") {
        columnDatafinal = changeColumnDataAgeingReport(columnDatafinal, formValues.dayRange)
      }
    } else if (checkCondition(value, [re.ClosingReport])) {
      if (includingamount !== "1") {
        columnDatafinal = columnDatafinal.filter((e) => e.id !== "Rate" && e.id !== "Amount")
      }
    } else if (checkCondition(value, [1])) {
      if (formValues.viewType === "0") {
        columnDatafinal = columnDatafinal.filter((item) => item.id !== "Opbal" && item.id !== "Balance")
      }
    } else if (checkCondition(value, [re.LiveStockReport])) {
      let customFields = columnDatafinal.filter((e) => !e.common || e.name === "__cust_pcs")
      columnDatafinal = getColumnByCustomField({ columns: columnDatafinal, formValues, custom_fields_data: customFields, reportid: value })
    }
  }

  return { reportLevel, columnDatafinal, subcolumnDatafinal, subsubcolumnDatafinal }
}

export const getColumnByCustomField = ({ columns, formValues, custom_fields_data, reportid }) => {
  var staticCustomFieldsColumns = columns.filter((e) => e.staticCustomField).map((e) => e.id)
  var removeColumns = []
  staticCustomFieldsColumns.forEach((e) => {
    var remove = true
    custom_fields_data.forEach((i) => {
      if (i.name === e) {
        remove = false
      }
    })
    if (remove) {
      removeColumns.push(e)
    }
  })
  var columnData = []
  const viewType = formValues ? formValues.order_view_type : ""
  if (
    checkCondition(reportid, [re.SalesRegister, re.PurchaseRegister, re.SalesReturnRegister, re.PurchaseReturnRegister, re.Estimate, re.JobDeliveryChallanRegister, re.SalesOrder, re.PurchaseOrder])
  ) {
    if (viewType === "detail") {
      columnData = [...columns, ...getCustomFieldColumn({ ddcustomfield: custom_fields_data, type: "master" }), ...getCustomFieldColumn({ ddcustomfield: custom_fields_data, type: "detail" })]
    } else {
      columnData = [...columns, ...getCustomFieldColumn({ ddcustomfield: custom_fields_data, type: "master" })]
    }
  } else if (checkCondition(reportid, [re.LiveStockReport])) {
    const __cust_pcs = custom_fields_data.find((e) => e.name === "__cust_pcs")
    if (__cust_pcs) {
      var requireTotal = false
      if (__cust_pcs.cal_total === "1" || __cust_pcs.calculateTotal === "1") {
        requireTotal = true
      }
      columnData = [
        ...columns,
        ...getCustomFieldColumn({ ddcustomfield: custom_fields_data, type: "master" }),
        {
          id: `__cust_pcsIn`,
          customFieldType: __cust_pcs.dataType,
          numeric: true,
          total: requireTotal,
          visible: true,
          customField: true,
          decimalPoint: __cust_pcs.decimalPoint ? convertIntif(__cust_pcs.decimalPoint) : 0,
          label: `Pcs In`,
          disableResizing: true,
          width: __cust_pcs.gridWidth,
          aggregate: "sum",
          Aggregated: ({ value }) => `${value}`,
        },
        {
          id: `__cust_pcsOut`,
          customFieldType: __cust_pcs.dataType,
          numeric: true,
          total: requireTotal,
          visible: true,
          customField: true,
          disableResizing: true,
          decimalPoint: __cust_pcs.decimalPoint ? convertIntif(__cust_pcs.decimalPoint) : 0,
          label: `Pcs Out`,
          width: __cust_pcs.gridWidth,
          aggregate: "sum",
          Aggregated: ({ value }) => `${value}`,
        },
        {
          id: `__cust_pcsBalance`,
          customFieldType: __cust_pcs.dataType,
          numeric: true,
          total: false,
          visible: true,
          customField: true,
          disableResizing: true,
          decimalPoint: __cust_pcs.decimalPoint ? convertIntif(__cust_pcs.decimalPoint) : 0,
          label: `Pcs Bal`,
          width: __cust_pcs.gridWidth,
          aggregate: "sum",
          Aggregated: ({ value }) => `${value}`,
        },
        ...getCustomFieldColumn({
          ddcustomfield: custom_fields_data.filter((e) => e.name !== "__cust_pcs"),
          type: "detail",
        }),
      ]
    } else {
      columnData = [
        ...columns,
        ...getCustomFieldColumn({ ddcustomfield: custom_fields_data, type: "master" }),
        ...getCustomFieldColumn({
          ddcustomfield: custom_fields_data.filter((e) => e.name !== "__cust_pcs"),
          type: "detail",
        }),
      ]
    }

    if (isblank(formValues) || (!isblank(formValues) && !getCheckBoxValue(formValues.include_orders))) {
      columnData = columnData.filter((e) => !checkCondition(e.id, ["order_qty", "net_balance", "not"]))
    }
  } else if (checkCondition(reportid, [re.FinishStockInOutReport])) {
    if (formValues.order_view_type === "detail") {
      columnData = FinishStockInOutDetailColumn
    } else {
      columnData = []
    }

    if (formValues.show_bundle !== true) {
      columnData = columnData.filter((item, index) => {
        if (item.id === "bundle_in" || item.id === "bundle_out" || item.id === "bundle_balance") {
          return false
        } else {
          return true
        }
      })
    }
    if (formValues.show_opening !== true) {
      columnData = columnData.filter((item, index) => {
        if (item.id === "op_pcs" || item.id === "op_bundle" || item.id === "op") {
          return false
        } else {
          return true
        }
      })
    } else {
      columnData = [...columns, ...getCustomFieldColumn({ ddcustomfield: custom_fields_data, type: "master" }), ...getCustomFieldColumn({ ddcustomfield: custom_fields_data, type: "detail" })]
    }

    if (isblank(formValues) || (!isblank(formValues) && !getCheckBoxValue(formValues.include_orders))) {
      columnData = columnData.filter((e) => !checkCondition(e.id, ["order_qty", "net_balance", "not"]))
    }
  }
  var returnColumnData = []
  columnData.forEach((e) => {
    if (returnColumnData.filter((i) => i.id === e.id).length === 0) {
      returnColumnData.push(e)
    }
  })
  returnColumnData = returnColumnData.filter((e) => !removeColumns.includes(e.id))
  return returnColumnData
}

export const getReportColumnDataById = ({ reportid, functions }) => {
  var columnData = []
  const ReportInt = convertIntif(reportid)

  if (ReportInt === re.LedgerReport) {
    const { account_type } = functions
    if (account_type === "8") {
      columnData = [
        {
          id: "DocNo",
          numeric: false,
          visible: true,
          label: "Doc No",
          width: billNoWidth,
          showlink: true,
          disableFilter: true,
        },
        {
          id: "DocDate",
          numeric: false,
          visible: true,
          total: "label",
          label: "Date",
          canGroup_By: true,
          disableResizing: true,
          width: dateWidth,
          date: true,
          format: "dmy",
          disableFilter: true,
        },
        {
          id: "Description",
          numeric: false,
          visible: true,
          label: "Description",
          canGroup_By: true,
          showlink: true,
          removelinkonly: { valueblank: ["DocNo"] },
          disableFilter: true,
        },
        { id: "Narration", numeric: false, visible: false, label: "Narration", noprint: true, format: "removeBR" },
        {
          id: "ChequeNo",
          numeric: false,
          visible: false,
          label: "Cheque No",
          disableResizing: true,
          width: 125,
          noprint: true,
        },
        {
          id: "Type",
          numeric: false,
          visible: true,
          label: "Type",
          canGroup_By: true,
          disableResizing: true,
          width: 100,
        },
        {
          id: "Debit",
          numeric: true,
          visible: true,
          total: true,
          disableResizing: true,
          label: `Debit`,
          format: "indian_rupee",
          currencySign: true,
          width: 175,
          disableFilter: true,
        },
        {
          id: "Credit",
          numeric: true,
          visible: true,
          total: true,
          disableResizing: true,
          label: `Credit`,
          format: "indian_rupee",
          currencySign: true,
          width: 175,
          disableFilter: true,
        },
        {
          id: "Balance",
          numeric: true,
          visible: true,
          disableResizing: true,
          label: `Balance`,
          currencySign: true,
          width: 175,
          format: "Dr_Cr_indian_rupee",
        },
        {
          id: "GoldDebit",
          numeric: true,
          visible: true,
          disableResizing: true,
          total: true,
          label: "Gold Debit",
          width: 150,
          format: "qty_decimal",
          display: [
            { key: "jwellery_metal", value: 1 },
            { key: "jwellery_metal", value: 0 },
          ],
        },
        {
          id: "GoldCredit",
          numeric: true,
          visible: true,
          disableResizing: true,
          total: true,
          label: "Gold Credit",
          width: 150,
          format: "qty_decimal",
          display: [
            { key: "jwellery_metal", value: 1 },
            { key: "jwellery_metal", value: 0 },
          ],
        },
        {
          id: "GoldBalance",
          numeric: true,
          visible: true,
          disableResizing: true,
          label: "Gold Balance",
          width: 150,
          format: "Dr_Cr_qty_decimal",
          display: [
            { key: "jwellery_metal", value: 1 },
            { key: "jwellery_metal", value: 0 },
          ],
        },
        {
          id: "SilverDebit",
          numeric: true,
          visible: true,
          disableResizing: true,
          total: true,
          label: "Silver Debit",
          width: 150,
          format: "qty_decimal",
          display: [
            { key: "jwellery_metal", value: 2 },
            { key: "jwellery_metal", value: 0 },
          ],
        },
        {
          id: "SilverCredit",
          numeric: true,
          visible: true,
          disableResizing: true,
          total: true,
          label: "Silver Credit",
          width: 150,
          format: "qty_decimal",
          display: [
            { key: "jwellery_metal", value: 2 },
            { key: "jwellery_metal", value: 0 },
          ],
        },
        {
          id: "SilverBalance",
          numeric: true,
          visible: true,
          disableResizing: true,
          label: "Silver Balance",
          width: 150,
          format: "Dr_Cr_qty_decimal",
          display: [
            { key: "jwellery_metal", value: 2 },
            { key: "jwellery_metal", value: 0 },
          ],
        },
      ]
    } else {
      columnData = [
        {
          id: "DocNo",
          numeric: false,
          visible: true,
          label: "Doc No",
          width: billNoWidth,
          showlink: true,
          disableFilter: true,
        },
        {
          id: "DocDate",
          numeric: false,
          visible: true,
          total: "label",
          label: "Date",
          canGroup_By: true,
          disableResizing: true,
          width: dateWidth,
          date: true,
          format: "dmy",
          disableFilter: true,
        },
        {
          id: "Description",
          numeric: false,
          visible: true,
          label: "Description",
          canGroup_By: true,
          showlink: true,
          removelinkonly: { valueblank: ["ledgercode"] },
          disableFilter: true,
        },
        { id: "Narration", numeric: false, visible: true, label: "Narration", noprint: true, format: "removeBR" },
        {
          id: "ChequeNo",
          numeric: false,
          visible: true,
          label: "Cheque No",
          disableResizing: true,
          width: 125,
          noprint: true,
        },
        {
          id: "Type",
          numeric: false,
          visible: true,
          canGroup_By: true,
          label: "Type",
          disableResizing: true,
          width: 100,
        },
        {
          id: "Debit",
          numeric: true,
          visible: true,
          total: true,
          label: `Debit`,
          disableResizing: true,
          currencySign: true,
          width: 175,
          format: "indian_rupee",
          disableFilter: true,
        },
        {
          id: "Credit",
          numeric: true,
          visible: true,
          total: true,
          label: `Credit`,
          disableResizing: true,
          currencySign: true,
          width: 175,
          format: "indian_rupee",
          disableFilter: true,
        },
        {
          id: "Balance",
          numeric: true,
          visible: true,
          disableResizing: true,
          label: `Balance`,
          currencySign: true,
          width: 200,
          format: "Dr_Cr_indian_rupee",
        },
      ]
    }
  } else if (ReportInt === re.GSTAnnualComputation) {
    columnData = [
      { id: "Particulars", numeric: false, visible: true, label: "Particulars" },
      {
        id: "TaxableValue",
        numeric: true,
        total: true,
        visible: true,
        currencySign: true,
        disableResizing: true,
        label: "Taxable Value",
        width: 150,
        format: "indian_rupee",
      },
      {
        id: "IntegratedTax",
        numeric: true,
        total: true,
        visible: true,
        currencySign: true,
        disableResizing: true,
        label: "Integrated Tax",
        width: 150,
        format: "indian_rupee",
      },
      {
        id: "CentralTax",
        numeric: true,
        total: true,
        visible: true,
        currencySign: true,
        disableResizing: true,
        label: "Central Tax",
        width: 150,
        format: "indian_rupee",
      },
      {
        id: "StateTax",
        numeric: true,
        total: true,
        visible: true,
        currencySign: true,
        disableResizing: true,
        label: "State Tax",
        width: 150,
        format: "indian_rupee",
      },
      {
        id: "CESS",
        numeric: true,
        total: true,
        visible: true,
        currencySign: true,
        disableResizing: true,
        label: "State Tax",
        width: 150,
        format: "indian_rupee",
      },
      {
        id: "Tax",
        numeric: true,
        total: true,
        visible: true,
        label: `Tax`,
        disableResizing: true,
        currencySign: true,
        width: 150,
        format: "indian_rupee",
      },
    ]
  } else if (ReportInt === re.GSTR_1Summary) {
    columnData = [
      {
        id: "Type",
        numeric: false,
        visible: true,
        label: "Type",
        showlink: true,
        removelinkonly: { valueblank: ["btype"] },
      },
      { id: "Invoices", numeric: true, visible: true, disableResizing: true, label: "Invoices", width: 75 },
      {
        id: "Taxable_Amt",
        numeric: true,
        visible: true,
        disableResizing: true,
        label: `Taxable Amount`,
        currencySign: true,
        width: 150,
        format: "indian_rupee",
      },
      {
        id: "cgst_Amt",
        numeric: true,
        visible: true,
        label: `CGST Amount`,
        format: "indian_rupee",
        disableResizing: true,
        currencySign: true,
        width: 150,
        display: "tax",
      },
      {
        id: "sgst_Amt",
        numeric: true,
        visible: true,
        label: `SGST Amount`,
        format: "indian_rupee",
        disableResizing: true,
        currencySign: true,
        width: 150,
        display: "tax",
      },
      {
        id: "igst_Amt",
        numeric: true,
        visible: true,
        label: `IGST Amount`,
        format: "indian_rupee",
        disableResizing: true,
        currencySign: true,
        width: 150,
        display: "tax",
      },
      {
        id: "cess_Amt",
        numeric: true,
        visible: true,
        label: `CESS Amount`,
        format: "indian_rupee",
        disableResizing: true,
        currencySign: true,
        width: 150,
        display: "tax",
      },
      {
        id: "Tax_Amt",
        numeric: true,
        visible: true,
        disableResizing: true,
        label: `Tax Amount`,
        currencySign: true,
        width: 150,
        format: "indian_rupee",
      },
      {
        id: "Total_Amt",
        numeric: true,
        visible: true,
        disableResizing: true,
        label: `Total Amount`,
        currencySign: true,
        width: 200,
        format: "indian_rupee",
      },
    ]
  } else if (ReportInt === re.GSTR_2Summary) {
    columnData = [
      { id: "Type", numeric: false, visible: true, label: "Description" },
      {
        id: "IGST_Amt",
        numeric: true,
        visible: true,
        label: `IGST Amount`,
        format: "indian_rupee",
        disableResizing: true,
        currencySign: true,
        width: 150,
        display: "tax",
      },
      {
        id: "CGST_Amt",
        numeric: true,
        visible: true,
        label: `CGST Amount`,
        format: "indian_rupee",
        disableResizing: true,
        currencySign: true,
        width: 150,
        display: "tax",
      },
      {
        id: "SGST_Amt",
        numeric: true,
        visible: true,
        label: `SGST Amount`,
        format: "indian_rupee",
        disableResizing: true,
        currencySign: true,
        width: 150,
        display: "tax",
      },
      {
        id: "Total_Amt",
        numeric: true,
        visible: true,
        disableResizing: true,
        label: `Total Amount`,
        currencySign: true,
        width: 200,
      },
    ]
  } else if (ReportInt === re.GSTR_3BSummary || ReportInt === re.Monthlypaymentforquarterlyreturn) {
    columnData = [
      { id: "Type", numeric: false, visible: true, label: "Nature of Supplies" },
      {
        id: "Taxable_Amt",
        numeric: true,
        visible: true,
        disableResizing: true,
        label: `Total Taxable Value`,
        currencySign: true,
        width: 150,
        format: "indian_rupee",
      },
      {
        id: "IGST_Amt",
        numeric: true,
        visible: true,
        label: `Integrated Tax`,
        format: "indian_rupee",
        disableResizing: true,
        currencySign: true,
        width: 150,
        display: "tax",
      },
      {
        id: "CGST_Amt",
        numeric: true,
        visible: true,
        label: `Central Tax`,
        format: "indian_rupee",
        disableResizing: true,
        currencySign: true,
        width: 150,
        display: "tax",
      },
      {
        id: "SGST_Amt",
        numeric: true,
        visible: true,
        label: `State/UT Tax`,
        format: "indian_rupee",
        disableResizing: true,
        currencySign: true,
        width: 150,
        display: "tax",
      },
      {
        id: "CESS_Amt",
        numeric: true,
        visible: true,
        disableResizing: true,
        label: `Cess `,
        currencySign: true,
        width: 150,
        format: "indian_rupee",
      },
    ]
  }
  return columnData
}

export const getDependencyString = ({ reportid, user_data, functions }) => {
  const { getAccountSitesetting } = functions
  const invoice_cus_sup = getAccountSitesetting("invoice_cus_sup", user_data)
  const account_subgroup = parseInt(getAccountSitesetting("account_subgroup"))
  const item_group = parseInt(getAccountSitesetting("item_group", user_data))
  const item_category = parseInt(getAccountSitesetting("item_category", user_data))
  const item_subgroupLevel2 = parseInt(getAccountSitesetting("item_subgroupLevel2", user_data))
  const multigodown_mng = parseInt(getAccountSitesetting("multigodown_mng", user_data))

  const dS = { invoice_cus_sup, account_subgroup, item_group, item_category, item_subgroupLevel2, multigodown_mng }

  const dropdown_string = getDependencyStringForReports({ reportid, dS })

  return dropdown_string
}

export const requireTotalById = (reportid) => {
  return checkCondition(reportid, [
    re.MultiOutstandingReport,
    re.InterestReport,
    re.ProcessStockInOutReport,
    re.CreditLimitReport,
    re.ProcessLongation_ShortageReport,
    re.ProductBatchDetailReport,
    re.SalesOrder,
    re.Estimate,
    re.JobDeliveryChallanRegister,
    re.PurchaseOrder,
    re.ProductSerialNumberReport,
    re.AgentWiseSalesReport,
    re.TrialBalance,
    re.ItemSubGroupSummaryReport,
    re.LedgerReport,
    re.BankReconcileReport,
    re.TdsRegister,
    re.MissingTDSTCSNumber,
    re.TcsRegister,
    re.ItemSubGroupLevel2SummaryReport,
    re.JournalEntryRegister,
    re.ItemGroupSummaryReport,
    re.ItemWiseSalesReport,
    re.ItemWisePurchaseReport,
    re.TransportWiseSalesReport,
    re.GSTSummary,
    re.ClosingReport,
    re.ReorderLevelReport,
    re.In_OutRegister,
    re.PackingSlipRegister,
    re.GoodsReceivedNoteRegister,
    re.DeliveryChallanRegister,
    re.JobInwardRegister,
    re.FinishStockRegister,
    re.ManageLotRegister,
    re.JobInwardsWithoutJobInvoice,
    re.ProductWiseStockReport,
    re.FinishStockInOutReport,
    re.FinishStockReport,
    re.PaymentRegister,
    re.ReceiptRegister,
    re.SalesReturnRegister,
    re.PurchaseReturnRegister,
    re.OutstandingAgeingReport,
    re.OutstandingReport,
    re.MultiLedgerReport,
    re.MultiOutstandingAgeingReport,
    re.SalesRegister,
    re.SalesRegisterwithPaymenttype,
    re.PurchaseRegister,
    re.InvoiceMissinginGST,
    re.LiveStockReport,
    re.StoneAnalysis,
    re.StoneCostAnalysis,
    re.ProcessHistoryReport,
  ])
}

export const fromDateToDate = (reportid) => {
  return checkCondition(reportid, [
    re.SalesRegister,
    re.RawMaterialStockInHand,
    re.PurchaseRegister,
    re.PaymentRegister,
    re.ReceiptRegister,
    re.LedgerReport,
    re.BankReconcileReport,
    re.InterestReport,
    re.OutstandingReport,
    re.OutstandingAgeingReport,
    re.ProductWiseStockReport,
    re.FinishStockInOutReport,
    re.PartyWiseStockReport,
    re.SalesReturnRegister,
    re.RecordExpense,
    re.PurchaseReturnRegister,
    re.AgentWiseSalesReport,
    re.In_OutRegister,
    re.PackingSlipRegister,
    re.GoodsReceivedNoteRegister,
    re.DeliveryChallanRegister,
    re.JobInwardRegister,
    re.FinishStockRegister,
    re.ManageLotRegister,
    re.JobInwardsWithoutJobInvoice,
    re.JournalEntryRegister,
    re.SalesRegisterwithPaymenttype,
    re.ItemWiseSalesReport,
    re.ItemWisePurchaseReport,
    re.TransportWiseSalesReport,
    re.MultiOutstandingReport,
    re.MultiOutstandingAgeingReport,
    re.MultiLedgerReport,
    re.CostCenter,
    re.P_LAccountCostCenterWise,
    re.P_LAccount,
    re.TradingWithP_LReport,
    re.BalanceSheet,
    re.TradingAccount,
    re.InvoiceMissinginGST,
    re.ProductBatchDetailReport,
    re.ProductSerialNumberReport,
    re.ProductExpiryReport,
    re.TdsRegister,
    re.MissingTDSTCSNumber,
    re.TcsRegister,
    re.Estimate,
    re.JobDeliveryChallanRegister,
    re.SalesOrder,
    re.PurchaseOrder,
    re.LiveStockReport,
    re.GSTSummary,
    re.StoneHistoryReport,
    re.ProcessHistoryReport,
    re.ClosingReport,
  ])
}

export const generateExcelForLotCosting = ({ data, columnDatafinal, user_data, reportdata, ddlot, formValues, functions }) => {
  const { getAccountSessionUserData, getAccountSitesetting, exportAsExcel } = functions
  const siteSettingForformatField = {
    inventory_decimalpoint: getAccountSitesetting("inventory_decimalpoint", user_data),
    rate_decimalpoint: getAccountSitesetting("rate_decimalpoint", user_data),
    timezone: getAccountSitesetting("timezone", user_data),
  }
  var mycol = []
  var newdata = cloneObjV2(data)
  const inventory_decimalpoint = siteSettingForformatField.inventory_decimalpoint
  var printdata = {}
  if (isblank(reportdata.name)) {
    if (!isblank(reportdata.title)) {
      reportdata.name = reportdata.title
    } else {
      reportdata.name = ""
    }
  }
  Object.keys(newdata).forEach((key) => {
    if (!isblank(newdata[key]) && typeof newdata[key] === "object" && newdata[key].length > 0) {
      var removeColumn = Object.keys(newdata[key][0])
      if (key === "packing_slip_mix") {
        mycol = [
          ...columnDatafinal.filter((e) => e.id !== "rate" && e.id !== "netamt"),
          {
            id: "lotqty",
            numeric: true,
            total: true,
            format: "qty_decimal",
            textbox: true,
            visible: true,
            label: "Lot Qty",
            width: 150,
          },
        ]
      } else if (key === "OtherCharges") {
        mycol = [
          { id: "accountName", numeric: false, visible: true, label: "Account", total: "label" },
          { id: "itemname", numeric: false, visible: true, label: "" },
          { id: "amount", numeric: true, visible: true, format: "indian_rupee", label: "Amount", total: true },
        ]
      } else {
        var mycol = columnDatafinal
      }
      mycol = mycol.filter((column) => {
        if (column.visible) {
          if (isblank(column.display)) {
            return true
          } else if (getAccountSitesetting(column.display, user_data) === 1) {
            return true
          }
          return false
        }
        return false
      })

      removeColumn = removeColumn.filter((item) => {
        var found = mycol.filter((column) => {
          if (column.id === item) {
            return true
          }
          return false
        })

        if (found.length > 0) {
          return false
        }

        return true
      })

      var obj = {}
      mycol.forEach((colItem) => {
        obj[colItem.id] = ""
        if (colItem.total === "label") {
          obj[colItem.id] = "Total"
        } else if (colItem.total) {
          obj[colItem.id] = parseFloat(lodashSum(lodashMap(newdata[key], (d) => parseFloat(getNumberonly(d[colItem.id])))))
        }
      })
      newdata[key] = newdata[key].map((item) => {
        const updatedItem = { ...item }
        mycol.forEach((colItem) => {
          if (colItem.numeric) {
            if (isblank(updatedItem[colItem.id])) {
              updatedItem[colItem.id] = ""
            } else if (colItem.format === "indian_rupee") {
              updatedItem[colItem.id] = parseFloat(getNumberonly(item[colItem.id]))
            } else if (colItem.format === "qty_decimal") {
              updatedItem[colItem.id] = parseFloat(getNumberonlyDecimal(item[colItem.id], inventory_decimalpoint))
            } else {
              updatedItem[colItem.id] = format_excel_field({ value: item[colItem.id], format: colItem.format, localSetting: siteSettingForformatField })
            }
          }
        })
        return updatedItem
      })
      newdata[key].push(obj)
      removeColumn.forEach((item, index) => {
        newdata[key] = lodashFpMap(lodashOmit(item), newdata[key])
      })

      var Mycol = {}
      for (var i = 0; i < mycol.length; i++) Mycol[mycol[i].id] = mycol[i].label

      newdata[key].unshift(Mycol)
      if (key === "purchase_invoice") {
        printdata[key] = [{ billno: "Purchase Invoice" }, ...newdata[key]]
      } else if (key === "job_process_invoice") {
        printdata[key] = [{ billno: "Job Process Invoice" }, ...newdata[key]]
      } else if (key === "job_process_invoice_with_jobinward") {
        printdata[key] = [{ billno: "Job Process Invoice With Job Inward" }, ...newdata[key]]
      } else if (key === "job_inward") {
        printdata[key] = [{ billno: "Job Inward" }, ...newdata[key]]
      } else if (key === "packing_slip") {
        printdata[key] = [{ billno: "Packing Slip" }, ...newdata[key]]
      } else if (key === "packing_slip_mix") {
        printdata[key] = [{ billno: "Packing Slip With Multiple Lots" }, ...newdata[key]]
      } else if (key === "OtherCharges") {
        printdata[key] = [...newdata[key]]
      }
    }
  })
  var finalPrintData = []
  if (printdata.hasOwnProperty("purchase_invoice")) {
    finalPrintData = [...finalPrintData, {}, ...printdata["purchase_invoice"]]
  }
  if (printdata.hasOwnProperty("job_process_invoice")) {
    finalPrintData = [...finalPrintData, {}, ...printdata["job_process_invoice"]]
  }
  if (printdata.hasOwnProperty("job_process_invoice_with_jobinward")) {
    finalPrintData = [...finalPrintData, {}, ...printdata["job_process_invoice_with_jobinward"]]
  }
  if (printdata.hasOwnProperty("job_inward")) {
    finalPrintData = [...finalPrintData, {}, ...printdata["job_inward"]]
  }
  if (printdata.hasOwnProperty("OtherCharges") && printdata["OtherCharges"].filter((e) => !isblank(e.accountName) && parseFloat(getNumberonly(e.amount)) > 0).length > 0) {
    printdata["OtherCharges"] = printdata["OtherCharges"]
      .filter((e) => !isblank(e.accountName) && parseFloat(getNumberonly(e.amount)) > 0)
      .map((it) => {
        const updatedItem = { ...it }
        if (isblank(updatedItem.billno)) {
          updatedItem.billno = updatedItem.accountName
        }
        updatedItem.netamt = updatedItem.amount
        delete updatedItem.amount
        delete updatedItem.accountName
        return updatedItem
      })
    finalPrintData = [...finalPrintData, {}, { billno: "Other Charges" }, ...printdata["OtherCharges"]]
  }
  if (printdata.hasOwnProperty("packing_slip")) {
    finalPrintData = [...finalPrintData, {}, ...printdata["packing_slip"]]
  }
  if (printdata.hasOwnProperty("packing_slip_mix")) {
    printdata["packing_slip_mix"] = printdata["packing_slip_mix"].map((it) => {
      const updatedItem = { ...it }
      updatedItem.netamt = updatedItem.lotqty
      delete updatedItem.lotqty
      return updatedItem
    })
    finalPrintData = [...finalPrintData, {}, ...printdata["packing_slip_mix"]]
  }
  finalPrintData.push({})
  finalPrintData.push({ billno: "Summary", netamt: "" })
  if (!isblank(newdata.totalAmount)) {
    finalPrintData.push({ billno: "Total Expense", netamt: newdata.totalAmount })
  }
  if (!isblank(newdata.totalQty)) {
    finalPrintData.push({ billno: "Total Stock Qty", netamt: newdata.totalQty })
  }
  if (!isblank(newdata.costPerQty)) {
    finalPrintData.push({ billno: "Costing As Per Book(Per Unit)", netamt: newdata.costPerQty })
  }
  if (!isblank(newdata.total_otherCharges)) {
    finalPrintData.push({ billno: "Other Charges", netamt: newdata.total_otherCharges })
  }
  if (!isblank(newdata.modifiedCostPerQty)) {
    finalPrintData.push({ billno: "Modified Costing(Per Unit)", netamt: newdata.modifiedCostPerQty })
  }
  var filename = getAccountSessionUserData("company_url", user_data) + "_" + reportdata.name.replace(/\s/gi, "_") + "_" + getAccountSessionUserData("financial_year", user_data)
  var title = {}

  title.title1 = getAccountSessionUserData("company_name", user_data)
  title.title2 = getAccountSessionUserData("company_address1", user_data)
  title.title3 = getAccountSessionUserData("company_address2", user_data)
  title.title4 = reportdata.name
  title.title5 = `Lot: ${ddlot.find((item) => item.code === formValues.lotSelectedValue).name}`

  exportAsExcel({ csvData: finalPrintData, fileName: filename, title, mycol: [] })
}

export const generateExcel = ({ user_data, props, functions }) => {
  const { getAccountSessionUserData, getAccountSitesetting, exportAsExcel, onComplete } = functions
  const { reportdata, formValues, ddlot, snackbarstate, dropDowns, extraTitle } = props

  var columnDatafinal = cloneObjV2(props.columnDatafinal)
  var subcolumnDatafinal = !isblank(props.subcolumnDatafinal) ? cloneObjV2(props.subcolumnDatafinal) : []
  var subsubcolumnDatafinal = !isblank(props.subsubcolumnDatafinal) ? cloneObjV2(props.subsubcolumnDatafinal) : []
  var reportLevel = 1

  if (!isblank(subcolumnDatafinal) && subcolumnDatafinal.length > 0) {
    reportLevel = 2
  }

  if (!isblank(subsubcolumnDatafinal) && subsubcolumnDatafinal.length > 0) {
    reportLevel = 3
  }
  var data = props.data
  var printdata = cloneObj(data)

  var reportViewType = isblank(formValues.reportViewType) ? "0" : formValues.reportViewType
  var agentCommType = isblank(formValues.agentCommissionSelValue) ? "0" : formValues.agentCommissionSelValue
  var reportid = reportdata.reportid
  var viewType = ""
  var outstandingtype = ""

  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint", user_data)

  var headerIndex = []
  var totalIndex = []

  if (checkCondition(reportid, [re.LotCostingReport])) {
    return generateExcelForLotCosting({ data, columnDatafinal, user_data, reportdata, ddlot, formValues, functions })
  }

  if (checkCondition(reportid, [re.LiveStockReport]) && reportLevel === 2) {
    reportLevel = 1
    columnDatafinal = [
      ...columnDatafinal.filter((e) => !checkCondition(e.id, ["__cust_in_qty", "tot_in", "tot_out", "balance", "__cust_pcsIn", "__cust_pcsOut", "__cust_pcsBalance"])),
      ...subcolumnDatafinal,
    ]

    if (isblank(formValues) || (!isblank(formValues) && !getCheckBoxValue(formValues.include_orders))) {
      columnDatafinal = columnDatafinal.filter((e) => !checkCondition(e.id, ["order_qty", "net_balance", "not"]))
    }

    columnDatafinal = columnDatafinal.map((e) => {
      const item = { ...e }
      if (item.id === "Balance" || item.id === "__cust_pcsBalance") {
        item.total = false
      }
      return item
    })
    var tempData = []
    printdata.forEach((item) => {
      item.__cust_pcsIn = 0
      item.__cust_pcsOut = 0
      item.__cust_pcsBalance = 0
      item.__cust_in_qty = 0
      item.DetailGrid.forEach((it) => {
        var newItem = { ...item, ...it }
        tempData.push(newItem)
      })
    })
    printdata = cloneObjV2(tempData)

    // printdata = sortByDate(printdata, "Date")
    // if (printdata.length > 0) {
    //   let lastrow = printdata[printdata.length - 1]
    //   printdata[printdata.length - 1].Balance = parseFloat(getNumberonlyDecimal(lastrow.In, inventory_decimalpoint)) - parseFloat(getNumberonlyDecimal(lastrow.Out, inventory_decimalpoint))
    //   printdata[printdata.length - 1].__cust_pcsBalance = parseFloat(getNumberonlyDecimal(lastrow.__cust_pcsIn, inventory_decimalpoint)) - parseFloat(getNumberonlyDecimal(lastrow.__cust_pcsOut, inventory_decimalpoint))
    // }

    /*     var addTemp = {}
        addTemp["___isBold"] = true
    
        tempData = [
          ...printdata,
          addTemp,
          ...addTotalRow({
            printdata: cloneObjV2(printdata),
            mycol: columnDatafinal,
            user_data,
            dataForCalc: cloneObjV2(printdata),
            functions,
          }),
        ]
        printdata = cloneObjV2(tempData)
        

    printdata = cloneObjV2(tempData)*/
  }

  if (checkCondition(reportid, [re.In_OutRegister]) && reportLevel === 2) {
    reportLevel = 1
    columnDatafinal = [...columnDatafinal.filter((e) => !checkCondition(e.id, ["item", "Qty"])), ...subcolumnDatafinal]

    var tempData = []
    printdata.forEach((item) => {
      item.DetailGrid.forEach((it) => {
        var newItem = { ...item, ...it }
        tempData.push(newItem)
      })
    })
    printdata = cloneObjV2(tempData)
    // printdata = sortByDate(printdata, "B_Date")
  }

  if (
    checkCondition(reportid, [
      re.JobInwardRegister,
      re.FinishStockRegister,
      re.ManageLotRegister,
      re.JobInwardsWithoutJobInvoice,
      re.FinishStockInOutReport,
      re.DeliveryChallanRegister,
      re.GoodsReceivedNoteRegister,
      re.ProductSerialNumberReport,
      re.ProductBatchDetailReport,
    ]) &&
    reportLevel === 2
  ) {
    reportLevel = 1

    subcolumnDatafinal = subcolumnDatafinal.map((e) => {
      const newItem = { ...e }
      newItem.id = `_${newItem.id}`
      return newItem
    })

    if (checkCondition(reportid, [re.FinishStockInOutReport])) {
      columnDatafinal = [
        ...columnDatafinal.filter(
          (e) => !checkCondition(e.id, ["DocNo", "BillNo", "Date", "PartyName", "subType", "Godown", "__cust_barcode", "__cust_avg_weight", "pcs_in", "pcs_out", "pcs_balance", "In", "Out", "Balance"])
        ),
        ...subcolumnDatafinal,
      ]
      subcolumnDatafinal = []
    } else if (checkCondition(reportid, [re.ProductSerialNumberReport])) {
      columnDatafinal = [...columnDatafinal.filter((e) => !checkCondition(e.id, ["serialno"])), ...subcolumnDatafinal]
      subcolumnDatafinal = []
    } else if (checkCondition(reportid, [re.ProductBatchDetailReport])) {
      columnDatafinal = [...columnDatafinal.filter((e) => !checkCondition(e.id, ["batch_referenceno", "manufacturer_batch", "manufactured_date", "expiry_date"])), ...subcolumnDatafinal]
      subcolumnDatafinal = []
    }
    columnDatafinal = [...columnDatafinal.filter((e) => !checkCondition(e.id, ["item", "Qty"])), ...subcolumnDatafinal]
    subcolumnDatafinal = []

    var tempData = []
    printdata.forEach((item) => {
      item.DetailGrid.forEach((it) => {
        const obj = {}
        Object.keys(it).forEach((j) => {
          obj[`_${j}`] = it[j]
        })
        var newItem = { ...item, ...obj }
        tempData.push(newItem)
      })
    })
    printdata = cloneObjV2(tempData)
  } else if (
    checkCondition(reportid, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport]) &&
    formValues.viewTypeSelectedValue === "Summary" &&
    formValues.multiOutstandingSelValue !== "All"
  ) {
    reportLevel = 1
    columnDatafinal = [{ id: formValues.multiOutstandingSelValue.replace(/ /g, "_"), label: formValues.multiOutstandingSelValue, visible: true, numeric: false }, ...subcolumnDatafinal]
    subcolumnDatafinal = []
  } else if (checkCondition(reportid, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport]) && formValues.viewTypeSelectedValue === "Detail" && formValues.multiOutstandingSelValue === "All") {
    reportLevel = 1
    columnDatafinal = [
      { id: "name", numeric: false, visible: true, label: "Name", canGroup_By: false, showlink: true, removelinkonly: { valueblank: ["code"] } },
      { id: "mobile", label: "Mobile", visible: true, numeric: false },
      ...subcolumnDatafinal,
    ]
    subcolumnDatafinal = []
  } else if (checkCondition(reportid, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport]) && formValues.viewTypeSelectedValue === "Detail" && formValues.multiOutstandingSelValue !== "All") {
    let onAccountColumn = []
    if (formValues.reportViewType === "1") {
      // onAccountColumn = [{ currencySign: true, disableResizing: true, format: "indian_rupee", id: "onAccountNetAmount", label: "On Account", numeric: true, total: true, visible: true, width: 150 }]
    }

    reportLevel = 1
    columnDatafinal = [
      { id: formValues.multiOutstandingSelValue.replace(/ /g, "_"), label: formValues.multiOutstandingSelValue, visible: true, numeric: false },
      { id: "name", numeric: false, visible: true, label: "Name", canGroup_By: false, showlink: true },
      { id: "mobile", numeric: false, visible: true, total: "label", canGroup_By: false, label: "Mobile", width: 150 },
      ...subsubcolumnDatafinal,
      ...onAccountColumn,
    ]
    subsubcolumnDatafinal = []
  }

  if (checkCondition(reportid, [re.MultiLedgerReport, re.MultiOutstandingReport, re.MultiOutstandingAgeingReport])) {
    viewType = isblank(formValues.viewTypeSelectedValue) ? "Detail" : formValues.viewTypeSelectedValue
    outstandingtype = isblank(formValues.multiOutstandingSelValue) ? "All" : formValues.multiOutstandingSelValue
  }
  if (isblank(reportdata.name)) {
    if (!isblank(reportdata.title)) {
      reportdata.name = reportdata.title
    } else {
      reportdata.name = ""
    }
  }

  var requireTotal = false
  var totalDifference = false
  totalDifference = checkCondition(reportid, [re.InterestReport]) && formValues.reportFormat === "2"
  requireTotal = requireTotalById(reportid)
  if (checkCondition(reportid, [re.LiveStockReport])) {
    // requireTotal = false
    requireTotal = true
  }
  var filename = ""
  if (!isblank(formValues) && checkCondition(reportdata.reportid, [re.LedgerReport])) {
    const PartyName = getdropdownfieldvalue({ dropDownData: dropDowns.ddallaccount, field: "code", value: formValues.partySelectedValue, displayvalue: "name" })
    filename =
      getAccountSessionUserData("company_url", user_data) +
      "_" +
      reportdata.name.toLowerCase().replace(/\s/gi, "_") +
      "_" +
      PartyName.toLowerCase().replace(/\s/gi, "_") +
      "_" +
      getAccountSessionUserData("financial_year", user_data)
  } else {
    filename = getAccountSessionUserData("company_url", user_data) + "_" + reportdata.name.toLowerCase().replace(/\s/gi, "_") + "_" + getAccountSessionUserData("financial_year", user_data)
  }

  var title = {}
  title.title1 = getAccountSessionUserData("company_name", user_data)
  title.title2 = getAccountSessionUserData("company_address1", user_data)
  title.title3 = getAccountSessionUserData("company_address2", user_data)
  title.title4 = reportdata.name
  var headers = getReportHeaders({ reportdata, formValues, dropDowns, getAccountSitesetting })
  const checkFromTo = fromDateToDate(reportdata.reportid)
  if (checkFromTo) {
    headers = headers.filter((e, i) => !(e.label === "From" || e.label === "To"))
  }

  if (headers.length > 0) {
    headers.forEach((e, iindex) => {
      title[`title${5 + iindex}`] = `${e.label} - ${e.value}`
    })
  }
  if (fromDateToDate(reportdata.reportid)) {
    title[`title${5 + headers.length}`] = `From: ${DatetoDMY(formValues.FromDate)} To: ${DatetoDMY(formValues.ToDate)}`
    if (extraTitle && extraTitle.length > 0) {
      extraTitle.forEach((e, iindex) => {
        title[`title${6 + headers.length + iindex}`] = e
      })
    }
  } else {
    if (extraTitle && extraTitle.length > 0) {
      extraTitle.forEach((e, iindex) => {
        title[`title${5 + headers.length + iindex}`] = e
      })
    }
  }

  if (checkCondition(reportid, [re.AgentWiseSalesReport]) && agentCommType === "0") {
    columnDatafinal = columnDatafinal.filter((e) => e.id !== "agentcomm_amt")
    subcolumnDatafinal = subcolumnDatafinal.filter((e) => e.id !== "agentcomm_amt")
    subsubcolumnDatafinal = subsubcolumnDatafinal.filter((e) => e.id !== "agentcomm_amt" && e.id !== "partyname")
  }
  if (checkCondition(reportid, [re.AgentWiseSalesReport]) && agentCommType === "1") {
    subsubcolumnDatafinal = subsubcolumnDatafinal.filter((e) => e.id !== "partyname")
  }

  if (checkCondition(reportid, [re.MultiLedgerReport])) {
    if (formValues.viewTypeSelectedValue === "Summary" && formValues.multiOutstandingSelValue === "All") {
      // summary all case
      columnDatafinal = columnDatafinal.filter((e) => e.id !== "balance")
    }
  }

  if (checkCondition(reportid, [re.GoodsReceivedNoteRegister, re.DeliveryChallanRegister]) && formValues.order_view_type === "summary") {
    reportLevel = 1
    printdata = showDetailInMasterRow({ data: printdata, user_data, functions: { getAccountSitesetting } })
  }

  var mycol = columnDatafinal.filter((column) => {
    if (column.visible === "true" || column.visible) {
      if (isblank(column.display)) {
        return true
      } else if (getAccountSitesetting(column.display, user_data) === 1) {
        return true
      }
      return false
    }
    return false
  })
  var subcol = subcolumnDatafinal.filter((column) => {
    if (column.visible === "true" || column.visible) {
      if (isblank(column.display)) {
        return true
      } else if (getAccountSitesetting(column.display, user_data) === 1) {
        return true
      }
      return false
    }
    return false
  })
  var subsubcol = subsubcolumnDatafinal.filter((column) => {
    if (column.visible === "true" || column.visible) {
      if (isblank(column.display)) {
        return true
      } else if (getAccountSitesetting(column.display, user_data) === 1) {
        return true
      }
      return false
    }
    return false
  })

  if (checkCondition(reportid, [re.TradingAccount, re.P_LAccount, re.TradingWithP_LReport, re.BalanceSheet])) {
    // totalIndex.push(printdata.length - 1)
    totalIndex.push(printdata.length)
  }

  if (reportViewType === "1" && reportLevel === 1 && checkCondition(reportid, [re.OutstandingReport, re.OutstandingAgeingReport])) {
    var bills = []
    var onaccountDetail = []
    if (printdata.hasOwnProperty("bills")) {
      bills = !isblank(printdata.bills) && printdata.bills.length > 0 ? printdata.bills : []
      onaccountDetail = !isblank(printdata.onaccountDetail) && printdata.onaccountDetail.length > 0 ? printdata.onaccountDetail : []
      var addTemp = {}
      addTemp[mycol[0].id] = "Invoices"
      addTemp["___isBold"] = true
      printdata = [addTemp, ...addTotalRow({ printdata: cloneObjV2(bills), mycol, user_data, dataForCalc: cloneObjV2(bills), functions })]

      totalIndex.push(printdata.length)

      if (onaccountDetail.length > 0) {
        var addTemp1 = {}
        addTemp1[mycol[0].id] = "On Account"
        addTemp1["___isBold"] = true
        printdata = [
          ...printdata,
          addTemp1,
          ...addTotalRow({
            printdata: cloneObjV2(onaccountDetail),
            mycol,
            user_data,
            dataForCalc: cloneObjV2(onaccountDetail),
            functions,
          }),
        ]
      }

      totalIndex.push(printdata.length)

      var updatedItem = {}
      if (bills.length > 0 && onaccountDetail.length > 0) {
        columnDatafinal.forEach((element) => {
          updatedItem[element.id] = ""
          if (element.difference === true || element.difference === "true") {
            updatedItem[element.id] =
              lodashSum(lodashMap(bills, (d) => parseFloat(getNumberonly(d[element.id])))) - lodashSum(lodashMap(onaccountDetail, (d) => parseFloat(getNumberonly(d[element.id]))))
          }
          if (element.difference !== true && element.difference !== "true" && element.difference) {
            updatedItem[element.id] = element.difference
          }
        })
        updatedItem["___isBold"] = true
        printdata = [...printdata, updatedItem]
        totalIndex.push(printdata.length)
      }
      totalDifference = false
      requireTotal = false
    }
  } else if (
    checkCondition(reportid, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport]) &&
    formValues.viewTypeSelectedValue === "Summary" &&
    formValues.multiOutstandingSelValue !== "All" &&
    reportViewType === "0"
  ) {
    var NewData = []
    printdata.forEach((row) => {
      const obj = { [formValues.multiOutstandingSelValue.replace(/ /g, "_")]: row.name }
      row.DetailGrid.forEach((it) => {
        const cols = {
          ...obj,
          ...it,
        }
        NewData.push(cols)
      })
    })
    printdata = cloneObjV2(NewData)
    NewData = []
  } else if (
    checkCondition(reportid, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport]) &&
    formValues.viewTypeSelectedValue === "Detail" &&
    formValues.multiOutstandingSelValue === "All" &&
    reportViewType === "0"
  ) {
    var NewData = []
    printdata.forEach((row) => {
      const obj = { name: row.name, mobile: row.mobile }

      var invoices = !isblank(row.DetailGrid) && row.DetailGrid.length > 0 ? row.DetailGrid : []
      if (invoices.length > 0) {
        if (reportViewType === "1") {
          NewData.push({ name: "Invoices", ___isBold: true })
        }
      }
      invoices = invoices.forEach((e) => {
        const cols = {
          ...obj,
          ...e,
        }
        NewData.push(cols)
      })
    })
    printdata = cloneObjV2(NewData)
    NewData = []
  } else if (
    checkCondition(reportid, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport]) &&
    formValues.viewTypeSelectedValue === "Detail" &&
    formValues.multiOutstandingSelValue !== "All" &&
    reportViewType === "0"
  ) {
    var NewData = []
    printdata.forEach((row) => {
      const obj = { [formValues.multiOutstandingSelValue.replace(/ /g, "_")]: row.name }
      row.DetailGrid.forEach((detailGrid) => {
        const obj2 = { name: detailGrid.name, mobile: detailGrid.mobile }

        var invoices = !isblank(detailGrid.DetailGrid) && detailGrid.DetailGrid.length > 0 ? detailGrid.DetailGrid : []
        if (invoices.length > 0) {
          if (reportViewType === "1") {
            NewData.push({ [formValues.multiOutstandingSelValue.replace(/ /g, "_")]: "Invoices", ___isBold: true })
          }
        }

        invoices = invoices.forEach((e) => {
          const cols = {
            ...obj,
            ...obj2,
            ...e,
          }
          NewData.push(cols)
        })
      })
      printdata = cloneObjV2(NewData)
      NewData = []
    })
  } else if (reportLevel === 1 && checkCondition(reportid, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport]) && reportViewType === "1" && viewType === "Detail") {
    const outstanding = checkCondition(reportid, [re.MultiOutstandingReport]) ? "outstanding" : "NetAmount"

    if (outstandingtype === "All") {
      var _NewData = []
      var grandTotal = 0
      printdata.forEach((detailGrid) => {
        const obj = { name: detailGrid.name, mobile: detailGrid.mobile }

        var bills = []
        var onaccountDetail = []
        if (detailGrid.hasOwnProperty("bills")) {
          bills = !isblank(detailGrid.bills) && detailGrid.bills.length > 0 ? detailGrid.bills : []
          if (bills.length > 0) {
            _NewData.push({ name: "Invoices", ___isBold: true })
          }
          bills = bills.forEach((e) => {
            const cols = {
              ...obj,
              ...e,
            }

            grandTotal = grandTotal + e[`${outstanding}`]
            _NewData.push(cols)
          })

          onaccountDetail = !isblank(detailGrid.onaccountDetail) && detailGrid.onaccountDetail.length > 0 ? detailGrid.onaccountDetail : []
          if (onaccountDetail.length > 0) {
            _NewData.push({ name: "On Account", ___isBold: true })
          }
          onaccountDetail = onaccountDetail.forEach((e) => {
            const cols = {
              ...obj,
              ...e,
            }
            grandTotal = grandTotal - e[`${outstanding}`]
            _NewData.push(cols)
          })

          var updatedItem = {}
          if (!isblank(detailGrid.bills) && detailGrid.bills.length > 0 && !isblank(detailGrid.onaccountDetail) && detailGrid.onaccountDetail.length > 0) {
            mycol.forEach((element) => {
              updatedItem[element.id] = ""
              if (element.difference === true || element.difference === "true") {
                updatedItem[element.id] =
                  lodashSum(lodashMap(detailGrid.bills, (d) => parseFloat(getNumberonly(d[element.id])))) -
                  lodashSum(lodashMap(detailGrid.onaccountDetail, (d) => parseFloat(getNumberonly(d[element.id]))))
              }
              if (element.difference !== true && element.difference !== "true" && element.difference) {
                updatedItem[element.id] = element.difference
              }
            })
          } else if (!isblank(detailGrid.bills) && detailGrid.bills.length > 0) {
            mycol.forEach((element) => {
              updatedItem[element.id] = ""
              if (element.difference === true || element.difference === "true") {
                updatedItem[element.id] = lodashSum(lodashMap(detailGrid.bills, (d) => parseFloat(getNumberonly(d[element.id]))))
              }
              if (element.difference !== true && element.difference !== "true" && element.difference) {
                updatedItem[element.id] = element.difference
              }
            })
          } else if (!isblank(detailGrid.onaccountDetail) && detailGrid.onaccountDetail.length > 0) {
            mycol.forEach((element) => {
              updatedItem[element.id] = ""
              if (element.difference === true || element.difference === "true") {
                updatedItem[element.id] = -lodashSum(lodashMap(detailGrid.onaccountDetail, (d) => parseFloat(getNumberonly(d[element.id]))))
              }
              if (element.difference !== true && element.difference !== "true" && element.difference) {
                updatedItem[element.id] = element.difference
              }
            })
          }

          updatedItem["___isBold"] = true
          _NewData = [..._NewData, updatedItem]

          totalDifference = false
          requireTotal = false
        }
      })

      var updatedItem = {}
      updatedItem[`${outstanding}`] = grandTotal
      updatedItem["name"] = "Grand Total"
      updatedItem["___isBold"] = true
      _NewData = [..._NewData, updatedItem]

      printdata = cloneObjV2(_NewData)
      _NewData = []
    } else if (outstandingtype !== "All") {
      var NewData = []
      var grandTotal = 0
      printdata.forEach((detailGrid1) => {
        const obj = { [formValues.multiOutstandingSelValue.replace(/ /g, "_")]: detailGrid1.name }
        detailGrid1.DetailGrid.forEach((detailGrid) => {
          const obj2 = { name: detailGrid.name, mobile: detailGrid.mobile }

          if (formValues.reportViewType === "1") {
            obj2.onAccountNetAmount = detailGrid.onAccountNetAmount
          }

          var bills = []
          var onaccountDetail = []
          if (detailGrid.hasOwnProperty("bills")) {
            bills = !isblank(detailGrid.bills) && detailGrid.bills.length > 0 ? detailGrid.bills : []

            if (bills.length > 0) {
              NewData.push({ [formValues.multiOutstandingSelValue.replace(/ /g, "_")]: "Invoices", ___isBold: true })
            }
            bills = bills.forEach((e) => {
              const cols = {
                ...obj,
                ...obj2,
                ...e,
              }
              grandTotal = grandTotal + e[`${outstanding}`]
              NewData.push(cols)
            })

            onaccountDetail = !isblank(detailGrid.onaccountDetail) && detailGrid.onaccountDetail.length > 0 ? detailGrid.onaccountDetail : []

            if (onaccountDetail.length > 0) {
              NewData.push({ [formValues.multiOutstandingSelValue.replace(/ /g, "_")]: "On Account", ___isBold: true })
            }
            onaccountDetail = onaccountDetail.forEach((e) => {
              const cols = {
                ...obj,
                ...obj2,
                ...e,
              }
              grandTotal = grandTotal - e[`${outstanding}`]
              NewData.push(cols)
            })

            var updatedItem = {}
            if (!isblank(detailGrid.bills) && detailGrid.bills.length > 0 && !isblank(detailGrid.onaccountDetail) && detailGrid.onaccountDetail.length > 0) {
              mycol.forEach((element) => {
                updatedItem[element.id] = ""
                if (element.difference === true || element.difference === "true") {
                  updatedItem[element.id] =
                    lodashSum(lodashMap(detailGrid.bills, (d) => parseFloat(getNumberonly(d[element.id])))) -
                    lodashSum(lodashMap(detailGrid.onaccountDetail, (d) => parseFloat(getNumberonly(d[element.id]))))
                }
                if (element.difference !== true && element.difference !== "true" && element.difference) {
                  updatedItem[element.id] = element.difference
                }
              })
            } else if (!isblank(detailGrid.bills) && detailGrid.bills.length > 0) {
              mycol.forEach((element) => {
                updatedItem[element.id] = ""
                if (element.difference === true || element.difference === "true") {
                  updatedItem[element.id] = lodashSum(lodashMap(detailGrid.bills, (d) => parseFloat(getNumberonly(d[element.id]))))
                }
                if (element.difference !== true && element.difference !== "true" && element.difference) {
                  updatedItem[element.id] = element.difference
                }
              })
            } else if (!isblank(detailGrid.onaccountDetail) && detailGrid.onaccountDetail.length > 0) {
              mycol.forEach((element) => {
                updatedItem[element.id] = ""
                if (element.difference === true || element.difference === "true") {
                  updatedItem[element.id] = -lodashSum(lodashMap(detailGrid.onaccountDetail, (d) => parseFloat(getNumberonly(d[element.id]))))
                }
                if (element.difference !== true && element.difference !== "true" && element.difference) {
                  updatedItem[element.id] = element.difference
                }
              })
            }

            updatedItem["___isBold"] = true
            NewData = [...NewData, updatedItem]

            totalDifference = false
            requireTotal = false
          }
        })
        var updatedItem = {}
        updatedItem[`${outstanding}`] = grandTotal
        updatedItem[[formValues.multiOutstandingSelValue.replace(/ /g, "_")]] = "Grand Total"
        updatedItem["___isBold"] = true
        NewData = [...NewData, updatedItem]
      })
      printdata = cloneObjV2(NewData)
      NewData = []
    }
  } else if (
    checkCondition(reportid, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport]) &&
    formValues.viewTypeSelectedValue === "Summary" &&
    formValues.multiOutstandingSelValue !== "All"
  ) {
    var NewData = []
    printdata.forEach((row) => {
      const obj = { [formValues.multiOutstandingSelValue.replace(/ /g, "_")]: row.name }
      row.DetailGrid.forEach((it) => {
        const cols = {
          ...obj,
          ...it,
        }
        NewData.push(cols)
      })
    })
    printdata = cloneObjV2(NewData)
    NewData = []
  }
  if (isblank(printdata[0])) {
    snackbarstate({ open: true, message: ErrorMessages["E00486"], snackbarType: "error" })
    return true
  }

  var removeColumn = Object.keys(printdata[0])
  removeColumn = removeColumn.filter((item) => {
    var found = mycol.filter((column) => {
      if (column.id === item) {
        return true
      }
      return false
    })

    if (found.length > 0) {
      return false
    }
    return true
  })
  var dataForCalc = cloneObjV2(printdata)

  if (totalDifference) {
    var obj1 = {}
    mycol.forEach((colItem) => {
      obj1[colItem.id] = ""
      if (!isblank(colItem.totalDifferenceLabel)) {
        obj1[colItem.id] = colItem.totalDifferenceLabel
      } else if (!isblank(colItem.totalDifference)) {
        var differenceFromData = mycol.find((e) => e.id === colItem.totalDifference.diferenceFrom)
        var differenceOfData = mycol.find((e) => e.id === colItem.totalDifference.diferenceOf)
        differenceFromData.total = 0
        differenceOfData.total = 0
        if (differenceFromData.format === "qty_decimal") {
          differenceFromData.total = lodashSum(lodashMap(dataForCalc, (d) => parseFloat(getNumberonlyDecimal(d[differenceFromData.id], inventory_decimalpoint))))
        } else {
          differenceFromData.total = lodashSum(lodashMap(dataForCalc, (d) => parseFloat(getNumberonly(d[differenceFromData.id]))))
        }
        if (differenceOfData.format === "qty_decimal") {
          differenceOfData.total = lodashSum(lodashMap(dataForCalc, (d) => parseFloat(getNumberonlyDecimal(d[differenceOfData.id], inventory_decimalpoint))))
        } else {
          differenceOfData.total = lodashSum(lodashMap(dataForCalc, (d) => parseFloat(getNumberonly(d[differenceOfData.id]))))
        }
        var differenceFrom = differenceFromData.format === "qty_decimal" ? getNumberonlyDecimal(differenceFromData.total, inventory_decimalpoint) : getNumberonly(differenceFromData.total)
        var differenceOf = differenceOfData.format === "qty_decimal" ? getNumberonlyDecimal(differenceOfData.total, inventory_decimalpoint) : getNumberonly(differenceOfData.total)
        colItem.Difference = parseFloat(differenceFrom) - parseFloat(differenceOf)
        obj1[colItem.id] = colItem.Difference
      }
    })
    totalIndex.push(printdata.length)
    printdata.push(obj1)
  }

  /* START */

  mycol = makeExcelRequireCol(mycol, getAccountSitesetting, user_data)
  subcol = makeExcelRequireCol(subcol, getAccountSitesetting, user_data)
  subsubcol = makeExcelRequireCol(subsubcol, getAccountSitesetting, user_data)

  // Function to add header row
  function addHeaderRow(worksheet, headers, rowIndex, level = 1, column) {
    let colour = colors.excelTableHeader
    if (level === 2) {
      colour = colors.excelDetailTableHeader
    } else if (level === 3) {
      colour = colors.excelDetailDetailTableHeader
    }
    headers.forEach((header, colIndex) => {
      const cell = worksheet.getCell(rowIndex + 1, colIndex + 1) // ExcelJS is 1-indexed
      cell.value = header
      cell.alignment = { vertical: "bottom", horizontal: column[colIndex].numeric === true ? "right" : "left" }
      cell.font = { bold: true }
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: colour },
        bgColor: { argb: colour },
      }
      cell.border = {
        top: { style: "thin", color: { argb: "00000000" } },
        bottom: { style: "medium", color: { argb: "00000000" } },
        left: { style: "thin", color: { argb: "00000000" } },
        right: { style: "thin", color: { argb: "00000000" } },
      }
    })
  }

  // Function to add a body row
  function addBodyRow(worksheet, row, rowIndex, level = 1, column) {
    // Add the row data
    const addedRow = worksheet.addRow(row)
    // Get the added row
    //const addedRow = worksheet.getRow(rowIndex + 1)
    // Iterate over each cell in the row
    addedRow.eachCell((cell, colIndex) => {
      let bold = false

      if (row.___isBold) {
        bold = true
      }

      // Check if bold styling is required
      if (!isblank(column[colIndex - 1].bold) && !isblank(column[colIndex - 1].bold.valueblank)) {
        let check_bold = false
        column[colIndex - 1].bold.valueblank.forEach((e) => {
          if (!isblank(row[e])) {
            check_bold = true
          }
        })

        if (!check_bold) {
          bold = true
        }
      }
      // Apply cell formatting
      cell.alignment = {
        vertical: "bottom",
        horizontal: column[colIndex - 1].numeric ? "right" : "left",
        wrapText: true,
      }
      cell.border = {
        top: { style: "thin", color: { argb: "00000000" } },
        bottom: { style: "thin", color: { argb: "00000000" } },
        left: { style: "thin", color: { argb: "00000000" } },
        right: { style: "thin", color: { argb: "00000000" } },
      }
      cell.font = {
        bold: bold,
      }

      // Apply badge formatting if present
      /* if (column[colIndex - 1].badge) {
        const colorObj = column[colIndex - 1].badge[cell.value]
        if (colorObj && colorObj.label) {
          cell.value = colorObj.label
        }
        if (colorObj && colorObj.color) {
          let colour = "e2e2e2"
          if (colorObj.color !== "black") {
            colour = colorToHex(colorObj.color).replace("#", "")
          }
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF0000" },
            bgColor: { argb: "FFFF0000" },
          }
        }
      } */
    })
  }

  // Function to add a footer total row
  function addFooterTotalRow(worksheet, row, rowIndex, level = 1, column) {
    // Add the row data
    const addedRow = worksheet.addRow(row)
    // Get the added row
    //const addedRow = worksheet.getRow(rowIndex + 1)
    // Iterate over each cell in the row
    addedRow.eachCell((cell, colIndex) => {
      // Apply cell formatting
      cell.alignment = {
        vertical: "bottom",
        horizontal: column[colIndex - 1].numeric ? "right" : "left",
        wrapText: true,
      }
      cell.border = {
        top: { style: "medium", color: { argb: "00000000" } },
        bottom: { style: "thin", color: { argb: "00000000" } },
        left: { style: "thin", color: { argb: "00000000" } },
        right: { style: "thin", color: { argb: "00000000" } },
      }
      cell.font = {
        bold: true,
      }
    })
  }

  function colorToRGBA(color) {
    var cvs, ctx
    cvs = document.createElement("canvas")
    cvs.height = 1
    cvs.width = 1
    ctx = cvs.getContext("2d")
    ctx.fillStyle = colorg
    ctx.fillRect(0, 0, 1, 1)
    return ctx.getImageData(0, 0, 1, 1).data
  }

  function byteToHex(num) {
    // Turns a number (0-255) into a 2-character hex number (00-ff)
    return ("0" + num.toString(16)).slice(-2)
  }

  function colorToHex(color) {
    var rgba, hex
    rgba = colorToRGBA(color)
    hex = [0, 1, 2]
      .map(function (idx) {
        return byteToHex(rgba[idx])
      })
      .join("")
    return "#" + hex
  }

  const siteSettingForformatField = {
    inventory_decimalpoint: getAccountSitesetting("inventory_decimalpoint"),
    rate_decimalpoint: getAccountSitesetting("rate_decimalpoint"),
    timezone: getAccountSitesetting("timezone"),
  }

  // Create a new Excel workbook
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet("Sheet1")
  worksheet._rows = []
  // Track the current row index
  let rowIndex = 1
  // Assuming `title` is your title object
  if (title && Object.keys(title).length > 0) {
    Object.keys(title).forEach((key, index) => {
      if (!isblank(title[key])) {
        worksheet.getCell(`A${rowIndex}`).value = title[key]
        worksheet.getCell(`A${rowIndex}`).font = {
          bold: rowIndex === 1 || rowIndex === 5 ? true : false,
          size: 13,
        }
      }
      if (rowIndex === 3) {
        rowIndex++
      }
      rowIndex++
    })
  }

  if (reportLevel === 1) {
    // Add master data
    addHeaderRow(
      worksheet,
      mycol.map((e) => e.label),
      rowIndex,
      1,
      mycol
    )

    printdata.forEach((row) => {
      const data_item = makeExcelRequiredDataOnly({ data: [row], column: mycol, siteSettingForformatField, reportid })
      rowIndex++
      addBodyRow(worksheet, data_item[0], rowIndex, 1, mycol)
    })

    if (requireTotal) {
      rowIndex++
      addFooterTotalRow(worksheet, addTotalRowOnly({ column: mycol, user_data, dataForCalc, siteSettingForformatField }), rowIndex, 1, mycol)
    }
  } else if (reportLevel === 2) {
    // Populate the worksheet with data
    printdata.forEach((item, index) => {
      if (index > 0) {
        rowIndex++
      }
      // Add master data
      addHeaderRow(
        worksheet,
        mycol.map((e) => e.label),
        rowIndex,
        1,
        mycol
      )
      rowIndex++
      const data_item = makeExcelRequiredDataOnly({ data: [item], column: mycol, siteSettingForformatField, reportid })
      addBodyRow(worksheet, data_item[0], rowIndex, 1, mycol)
      rowIndex++

      let detail_level_grid = []

      if (!isblank(item.DetailGrid)) {
        detail_level_grid = item.DetailGrid
      } else if (!isblank(item.bills)) {
        detail_level_grid = item.bills
      }
      if (detail_level_grid) {
        rowIndex++
        addHeaderRow(
          worksheet,
          subcol.map((e) => e.label),
          rowIndex,
          2,
          subcol
        )
        detail_level_grid.forEach((detail) => {
          rowIndex++
          const data_item = makeExcelRequiredDataOnly({ data: [detail], column: subcol, siteSettingForformatField, reportid })
          addBodyRow(worksheet, data_item[0], rowIndex, 2, subcol)
        })

        if (requireTotal) {
          rowIndex++
          addFooterTotalRow(worksheet, addTotalRowOnly({ column: subcol, user_data, dataForCalc: detail_level_grid, siteSettingForformatField }), rowIndex, 2, subcol)
        }

        rowIndex++
      }
    })

    if (requireTotal) {
      rowIndex++
      addFooterTotalRow(worksheet, addTotalRowOnly({ column: mycol, user_data, dataForCalc: printdata, siteSettingForformatField }), rowIndex, 1, mycol)
    }
  } else if (reportLevel === 3) {
    // Populate the worksheet with data
    printdata.forEach((item, index) => {
      if (index > 0) {
        rowIndex++
      }
      // Add master data
      addHeaderRow(
        worksheet,
        mycol.map((e) => e.label),
        rowIndex,
        1,
        mycol
      )
      rowIndex++

      const data_item = makeExcelRequiredDataOnly({ data: [item], column: mycol, siteSettingForformatField, reportid })

      addBodyRow(worksheet, data_item[0], rowIndex, 1, mycol)

      rowIndex++

      if (!isblank(item.DetailGrid)) {
        item.DetailGrid.forEach((detail) => {
          rowIndex++
          addHeaderRow(
            worksheet,
            subcol.map((e) => e.label),
            rowIndex,
            2,
            subcol
          )

          rowIndex++

          const data_item = makeExcelRequiredDataOnly({ data: [detail], column: subcol, siteSettingForformatField, reportid })

          addBodyRow(worksheet, data_item[0], rowIndex, 2, subcol)

          rowIndex++ //extra space
          rowIndex++
          addHeaderRow(
            worksheet,
            subsubcol.map((e) => e.label),
            rowIndex,
            3,
            subsubcol
          )

          let detail_detail_level_grid = []
          if (!isblank(detail.DetailDetailGrid)) {
            detail_detail_level_grid = detail.DetailDetailGrid
          } else if (!isblank(detail.DetailGrid)) {
            detail_detail_level_grid = detail.DetailGrid
          } else if (!isblank(detail.bills)) {
            detail_detail_level_grid = detail.bills
          }

          if (detail_detail_level_grid) {
            detail_detail_level_grid.forEach((detail) => {
              rowIndex++

              const data_item = makeExcelRequiredDataOnly({ data: [detail], column: subsubcol, siteSettingForformatField, reportid })
              addBodyRow(worksheet, data_item[0], rowIndex, 3, subsubcol)
            })
          }

          if (requireTotal) {
            rowIndex++
            if (detail_detail_level_grid) {
              addFooterTotalRow(worksheet, addTotalRowOnly({ column: subsubcol, user_data, dataForCalc: detail_detail_level_grid, siteSettingForformatField }), rowIndex, 3, subsubcol)
            }
          }

          rowIndex++
        })
        rowIndex++
      }
    })

    if (requireTotal || (reportLevel === 3 && checkCondition(reportid, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport]) && reportViewType === "1" && viewType === "Detail")) {
      rowIndex++
      addFooterTotalRow(worksheet, addTotalRowOnly({ column: mycol, user_data, dataForCalc: printdata, siteSettingForformatField }), rowIndex - 1, 1, mycol)
    }
  }

  if (exportAsExcel) {
    // for excel from ledger page only
    printdata = makeExcelRequiredData({ data: printdata, column: mycol, functions: { getAccountSitesetting, user_data } })
    if (!isblank(reportid) && reportid === 9) {
      printdata = [
        ...printdata,
        ...addTotalRow({
          printdata: [],
          mycol,
          user_data,
          dataForCalc,
          functions,
        }),
      ]
      totalIndex.push(printdata.length - 1)
    }
    exportAsExcel({ csvData: printdata, fileName: filename, title, mycol, headerIndex, totalIndex })
  } else if (onComplete) {
    onComplete({ worksheet, workbook, filename, mycol })
  } else {
    return exportWorkbook({ worksheet, workbook, filename, mycol })
  }
}

export const exportWorkbook = ({ worksheet, workbook, filename, mycol }) => {
  // Iterate over each row in the worksheet
  worksheet.eachRow({ includeEmpty: true }, (row, rowIndex) => {
    // Check if the row is blank (all cells are empty)
    const isRowBlank = row.values.every((value) => value === null || value === "")

    // If the row is blank, add "." in the first cell
    if (isRowBlank) {
      row.getCell(1).value = "~"
    }
  })

  const wb = workbook.worksheets.map((sheet) => ({
    name: sheet.name,
    t_rows: sheet.actualRowCount, // Number of rows
    t_cols: sheet.columnCount, // Number of columns
    rows: sheet.getSheetValues(), // Retrieve rows instead of values
    styles: worksheet.getSheetValues().map((row) => row.map(() => ({}))), // Initialize empty styles
    widths: mycol.map((e) => (isblank(e.width) ? 40 : e.width * 0.1)),
  }))

  // Modify the styles to include cell styles
  wb.forEach((worksheet_, index) => {
    // Loop through each cell in the worksheet
    Object.keys(worksheet_.styles).forEach((e) => {
      worksheet_.styles[parseInt(e)].forEach((f, ind) => {
        const cell = worksheet.getCell(parseInt(e), ind)
        wb[index].styles[e][ind] = cell.style || {} // Preserve cell style
      })
    })
  })

  return {
    wb: wb,
    fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    fileExtension: ".xlsx",
    fileName: filename,
  }
}

export const addTotalRow = ({ printdata, mycol, user_data, dataForCalc, functions }) => {
  const { getAccountSitesetting } = functions
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint", user_data)
  var obj = {}
  var count = 0
  mycol.forEach((colItem) => {
    obj[colItem.id] = ""
    if (colItem.total === "label" && count === 0) {
      count = count + 1
      obj[colItem.id] = "Total"

      obj["___isBold"] = true
    } else if (colItem.total === "label_count" && count === 0) {
      count = count + 1
      obj[colItem.id] = `Total ${dataForCalc.length}`
      obj["___isBold"] = true
    } else if (colItem.total && colItem.total !== "label") {
      if (colItem.format === "qty_decimal") {
        obj[colItem.id] = parseFloat(lodashSum(lodashMap(dataForCalc, (d) => parseFloat(getNumberonlyDecimal(d[colItem.id], inventory_decimalpoint)))))
      } else {
        obj[colItem.id] = parseFloat(lodashSum(lodashMap(dataForCalc, (d) => parseFloat(getNumberonly(d[colItem.id])))))
      }
      obj["___isBold"] = true
    }
  })
  printdata.push(obj)
  return printdata
}

export const addTotalRowOnly = ({ column, user_data, dataForCalc, siteSettingForformatField }) => {
  const inventory_decimalpoint = siteSettingForformatField.inventory_decimalpoint
  var obj = {}
  var count = 0

  column.forEach((colItem) => {
    obj[colItem.id] = ""
    if (colItem.total === "label" && count === 0) {
      count = count + 1
      obj[colItem.id] = "Total"
    } else if (colItem.total === "label_count" && count === 0) {
      count = count + 1
      obj[colItem.id] = `Total ${dataForCalc.length}`
    } else if (colItem.total && colItem.total !== "label") {
      if (colItem.format === "qty_decimal") {
        obj[colItem.id] = parseFloat(getNumberonlyDecimal(lodashSum(lodashMap(dataForCalc, (d) => parseFloat(getNumberonlyDecimal(d[colItem.id], inventory_decimalpoint)))), inventory_decimalpoint))
      } else {
        obj[colItem.id] = parseFloat(getNumberonly(lodashSum(lodashMap(dataForCalc, (d) => parseFloat(getNumberonly(d[colItem.id]))))))
      }
      obj[colItem.id] = format_excel_field({ value: obj[colItem.id], format: colItem.format, localSetting: siteSettingForformatField })
    }
  })

  const updatedItem = Object.keys(obj).map((item) => {
    return JSON.parse(JSON.stringify(obj[item]))
  })

  return updatedItem
}

export const generateReportPdf = ({ user_data, props, functions, groupBy, rows }) => {
  const { getAccountSessionUserData, getAccountSitesetting } = functions
  const { reportdata, formValues, meta, custom_fields_data, dropDowns, extraTitle } = props
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint", user_data)
  var columnDatafinal = props.columnDatafinal
  var subcolumnDatafinal = props.subcolumnDatafinal
  var subsubcolumnDatafinal = props.subsubcolumnDatafinal
  var reportLevel = props.reportLevel
  var reportid = reportdata.reportid
  var data = props.data
  if (data.length === 0) {
    return "no_data_found"
  }
  if (isblank(reportdata.name)) {
    if (!isblank(reportdata.title)) {
      reportdata.name = reportdata.title
    } else {
      reportdata.name = ""
    }
  }

  if (checkCondition(reportid, [re.FinishStockInOutReport]) && formValues.reportViewType === "1") {
    reportLevel = 1
  }

  if (
    checkCondition(reportid, [
      re.PackingSlipRegister,
      re.GoodsReceivedNoteRegister,
      re.DeliveryChallanRegister,
      re.JobInwardRegister,
      re.FinishStockRegister,
      re.ManageLotRegister,
      re.JobInwardsWithoutJobInvoice,
    ])
  ) {
    data = data.map((item) => {
      const updatedItem = { ...item }
      if (updatedItem.Status) {
        updatedItem.Status = getdropdownfieldvalue({ dropDownData: getAllOrderStatus(), field: "id", value: updatedItem.Status, displayvalue: "name" })
      }
      return updatedItem
    })
    if (!isblank(formValues.status) && formValues.status !== "all") {
      columnDatafinal = columnDatafinal.filter((e) => e.id !== "Status")
    }
  }
  var requireTotal = false
  var totalDifference = false
  totalDifference = checkCondition(reportid, [re.InterestReport]) && formValues.reportFormat === "2"
  requireTotal = requireTotalById(reportid)

  if (checkCondition(reportid, [re.ProductSerialNumberReport, re.ProductBatchDetailReport]) && formValues.productSerialTypeSelectedValue !== "0") {
    var NewData = []
    data.forEach((detailGrid) => {
      var tempData = []
      var newItem = { ...detailGrid }
      detailGrid.DetailGrid.forEach((it2) => {
        it2.bal = parseFloat(getNumberonlyDecimal(it2.qin, inventory_decimalpoint)) - parseFloat(getNumberonlyDecimal(it2.qout, inventory_decimalpoint))
        tempData.push(it2)
      })
      newItem.DetailGrid = tempData
      NewData.push(newItem)
    })
    data = cloneObjV2(NewData)
  }

  if (
    (!isblank(subsubcolumnDatafinal) && subsubcolumnDatafinal.length > 0) ||
    (checkCondition(reportid, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport]) && formValues.viewTypeSelectedValue === "Detail")
  ) {
    reportLevel = 3
  } else if (!isblank(subcolumnDatafinal) && subcolumnDatafinal.length > 0) {
    reportLevel = 2
  }

  props.loadingstate(true)
  if (checkCondition(reportid, [re.TrialBalance])) {
    var newData = []
    var lastInserted = ""
    data.forEach((item) => {
      if (lastInserted === item.Gname) {
        newData.push(item)
      } else {
        lastInserted = item.Gname
        newData.push({
          name: item.Gname,
          ledgercode: "",
        })
        newData.push(item)
      }
    })
    data = newData
    columnDatafinal = columnDatafinal.filter((it) => it.id !== "Gname" && it.id !== "SGname")
  }

  if (checkCondition(reportid, [re.MultiLedgerReport])) {
    if (formValues.viewTypeSelectedValue === "Summary" && formValues.multiOutstandingSelValue === "All") {
      columnDatafinal = columnDatafinal.filter((e) => e.id !== "balance")
    }
  }

  /*   columnDatafinal = columnDatafinal.filter((e) => e.printVisible === true)
    subcolumnDatafinal = subcolumnDatafinal.filter((e) => e.printVisible === true)
    subsubcolumnDatafinal = subsubcolumnDatafinal.filter((e) => e.printVisible === true) */

  var docDefinition = {}
  if (groupBy && groupBy.length > 0) {
    docDefinition = groupedpdf({
      groupBy,
      custom_fields_data,
      reportdata,
      data: rows,
      formValues,
      columnDatafinal,
      reportLevel,
      requireTotal,
      totalDifference,
      user_data,
      functions,
      dropDowns,
      extraTitle,
    })
  } else {
    docDefinition = generatePdfReport({
      custom_fields_data,
      reportdata,
      data,
      formValues,
      columnDatafinal,
      subcolumnDatafinal,
      subsubcolumnDatafinal,
      reportLevel,
      requireTotal,
      totalDifference,
      user_data,
      functions,
      dropDowns,
      extraTitle,
    })
  }

  var metadata = {}

  if (!isblank(formValues) && checkCondition(reportdata.reportid, [re.LedgerReport, re.OutstandingReport, re.OutstandingAgeingReport])) {
    const PartyName = getdropdownfieldvalue({ dropDownData: dropDowns.ddallaccount, field: "code", value: formValues.partySelectedValue, displayvalue: "name" })
    metadata.filename =
      getAccountSessionUserData("company_url", user_data) +
      "_" +
      reportdata.name.toLowerCase().replace(/\s/gi, "_") +
      "_" +
      PartyName.toLowerCase().replace(/\s/gi, "_") +
      "_" +
      getAccountSessionUserData("financial_year", user_data) +
      ".pdf"
  } else {
    metadata.filename =
      getAccountSessionUserData("company_url", user_data) + "_" + reportdata.name.toLowerCase().replace(/\s/gi, "_") + "_" + getAccountSessionUserData("financial_year", user_data) + ".pdf"
  }
  metadata.title = reportdata.name
  if (checkCondition(reportid, [re.SalesOrder, re.PurchaseOrder]) && !isblank(formValues.status)) {
    metadata.title = `${metadata.title} - ${getdropdownfieldvalue({ dropDownData: getAllOrderStatus(), field: "id", value: formValues.status, displayvalue: "name" })}`
  }
  if (checkCondition(reportid, [re.Estimate]) && formValues.status !== "All" && !isblank(formValues.status)) {
    metadata.title = `${metadata.title} - ${getdropdownfieldvalue({ dropDownData: getAllEstimateStatus(), field: "id", value: formValues.status, displayvalue: "name" })}`
  }
  metadata.row = reportdata
  docDefinition.info.title = metadata.filename
  return { docDefinition, metadata }
}

export const addCustomFieldForLiveStock = ({ liveStockDetailColumn, custom_fields_data }) => {
  const __cust_pcs = custom_fields_data.find((e) => e.name === "__cust_pcs")
  if (__cust_pcs) {
    var requireTotal = false
    if (__cust_pcs.cal_total === "1" || __cust_pcs.calculateTotal === "1") {
      requireTotal = true
    }
    return [
      ...liveStockDetailColumn,
      {
        id: `__cust_pcsIn`,
        customFieldType: __cust_pcs.dataType,
        numeric: true,
        total: requireTotal,
        visible: true,
        customField: true,
        disableResizing: true,
        decimalPoint: __cust_pcs.decimalPoint ? convertIntif(__cust_pcs.decimalPoint) : 0,
        label: `Pcs In`,
        width: __cust_pcs.gridWidth,
        aggregate: "sum",
        Aggregated: ({ value }) => `${value}`,
      },
      {
        id: `__cust_pcsOut`,
        customFieldType: __cust_pcs.dataType,
        numeric: true,
        total: requireTotal,
        disableResizing: true,
        visible: true,
        customField: true,
        decimalPoint: __cust_pcs.decimalPoint ? convertIntif(__cust_pcs.decimalPoint) : 0,
        label: `Pcs Out`,
        width: __cust_pcs.gridWidth,
        aggregate: "sum",
        Aggregated: ({ value }) => `${value}`,
      },
      {
        id: `__cust_pcsBalance`,
        customFieldType: __cust_pcs.dataType,
        numeric: true,
        total: requireTotal,
        disableResizing: true,
        visible: true,
        customField: true,
        decimalPoint: __cust_pcs.decimalPoint ? convertIntif(__cust_pcs.decimalPoint) : 0,
        label: `Pcs Bal`,
        width: __cust_pcs.gridWidth,
        aggregate: "sum",
        Aggregated: ({ value }) => `${value}`,
      },
    ]
  } else {
    return liveStockDetailColumn
  }
}

export const liveStockDetailColumn = [
  {
    id: "BillNo",
    numeric: false,
    visible: true,
    label: "Bill No",
    width: billNoWidth,
    showlink: true,
    removelinkonly: { valueblank: ["invoiceCode"] },
  },
  {
    id: "Date",
    numeric: false,
    visible: true,
    label: "Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  {
    id: "PartyName",
    numeric: false,
    visible: true,
    label: "Account Name",
    canGroup_By: true,
    showlink: true,
    removelinkonly: { valueblank: ["partyCode"] },
  },
  { id: "Godown", numeric: false, visible: true, canGroup_By: true, label: "Godown", display: "multigodown_mng" },
  {
    id: "subType",
    numeric: false,
    visible: true,
    total: "label",
    canGroup_By: true,
    disableResizing: true,
    label: "Type",
    width: 125,
  },
  {
    id: "In",
    numeric: true,
    visible: true,
    total: true,
    label: "In",
    disableResizing: true,
    width: 150,
    format: "qty_decimal",
  },
  {
    id: "Out",
    numeric: true,
    visible: true,
    total: true,
    label: "Out",
    disableResizing: true,
    width: 150,
    format: "qty_decimal",
  },
  {
    id: "Balance",
    numeric: true,
    visible: true,
    total: "lastRow",
    label: `Balance`,
    disableResizing: true,
    width: 200,
    format: "qty_decimal",
  },
]

export const FinishStockInOutDetailColumn = [
  {
    id: "DocNo",
    numeric: false,
    visible: true,
    label: "Doc No",
    width: billNoWidth,
    showlink: true,
    removelinkonly: { valueblank: ["invoiceCode"] },
  },
  { id: "BillNo", numeric: false, visible: true, label: "Bill No", width: billNoWidth },
  {
    id: "Date",
    numeric: false,
    visible: true,
    label: "Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  {
    id: "PartyName",
    numeric: false,
    visible: true,
    canGroup_By: true,
    label: "Account Name",
    bold: { valueblank: ["DocNo"] },
    showlink: true,
    removelinkonly: { valueblank: ["partyCode"] },
  },
  {
    id: "subType",
    numeric: false,
    visible: true,
    canGroup_By: true,
    total: "label",
    disableResizing: true,
    label: "Type",
    width: 125,
  },
  { id: "Godown", numeric: false, visible: true, canGroup_By: true, label: "Godown", display: "multigodown_mng" },
  { id: "__cust_barcode", numeric: true, visible: true, canGroup_By: false, label: "Barcode", width: 150 },
  { id: "__cust_avg_weight", numeric: false, visible: true, canGroup_By: false, label: "Avg Wt", width: 100 },
  { id: "__cust_pcs_in", numeric: true, visible: true, canGroup_By: false, total: true, label: "PcsIn", width: 100 },
  { id: "__cust_pcs_out", numeric: true, visible: true, canGroup_By: false, total: true, label: "PcsOut", width: 100 },
  { id: "pcs_balance", numeric: true, visible: true, canGroup_By: false, total: false, label: "PcsBal", width: 100 },
  {
    id: "bundle_in",
    numeric: true,
    visible: true,
    total: true,
    label: "BDL In",
    width: 100,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "bundle_out",
    numeric: true,
    total: true,
    visible: true,
    label: "BDL Out",
    width: 100,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "bundle_balance",
    numeric: true,
    visible: true,
    total: false,
    label: "BDL Bal",
    width: 100,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "In",
    numeric: true,
    visible: true,
    total: true,
    label: "In",
    disableResizing: true,
    width: 150,
    format: "qty_decimal",
  },
  {
    id: "Out",
    numeric: true,
    visible: true,
    total: true,
    label: "Out",
    disableResizing: true,
    width: 150,
    format: "qty_decimal",
  },
  {
    id: "Balance",
    numeric: true,
    visible: true,
    total: true,
    label: `Balance`,
    disableResizing: true,
    width: 175,
    format: "qty_decimal",
  },
]
export const columnMultiSummary = [
  {
    id: "name",
    numeric: false,
    visible: true,
    canGroup_By: false,
    label: "Name",
    total: "label",
    showlink: true,
    removelinkonly: { valueblank: ["code"] },
  },
  {
    id: "mobile",
    numeric: false,
    visible: true,
    canGroup_By: false,
    label: "Mobile",
    disableResizing: true,
    width: 125,
  },
  {
    id: "amount",
    numeric: true,
    visible: true,
    total: true,
    disableResizing: true,
    currencySign: true,
    label: "Amount",
    width: 150,
    format: "indian_rupee",
  },
  {
    id: "outstanding",
    numeric: true,
    visible: true,
    total: true,
    currencySign: true,
    disableResizing: true,
    label: "Outstanding",
    width: 150,
    format: "indian_rupee",
  },
]

export const columnMultiOutSummary = [
  {
    id: "name",
    numeric: false,
    visible: true,
    total: "label",
    canGroup_By: false,
    label: "Name",
    showlink: true,
    removelinkonly: { valueblank: ["code"] },
  },
  {
    id: "cityname",
    numeric: false,
    visible: true,
    disableResizing: true,
    canGroup_By: true,
    label: "City",
    width: 150,
  },
  {
    id: "mobile",
    numeric: false,
    visible: true,
    label: "Mobile",
    disableResizing: true,
    canGroup_By: false,
    width: 125,
  },
  {
    id: "amount",
    numeric: true,
    visible: true,
    total: true,
    disableResizing: true,
    currencySign: true,
    format: "indian_rupee",
    label: `Amount`,
    width: 150,
  },
  {
    id: "outstanding",
    numeric: true,
    visible: true,
    total: true,
    disableResizing: true,
    currencySign: true,
    format: "indian_rupee",
    label: `Outstanding`,
    width: 150,
  },
  {
    id: "onaccount",
    numeric: true,
    visible: true,
    total: true,
    disableResizing: true,
    currencySign: true,
    format: "indian_rupee",
    label: `On Account`,
    width: 150,
  },
  {
    id: "balance",
    numeric: true,
    visible: true,
    currencySign: true,
    disableResizing: true,
    total: true,
    label: `Balance`,
    format: "indian_rupee",
    width: 150,
  },
]

export const columnMultiAgeingSummary = [
  {
    id: "name",
    numeric: false,
    visible: true,
    total: "label",
    canGroup_By: false,
    label: "Name",
    showlink: true,
    removelinkonly: { valueblank: ["code"] },
  },
  {
    id: "cityname",
    numeric: false,
    visible: true,
    disableResizing: true,
    canGroup_By: true,
    label: "City",
    width: 150,
  },
  {
    id: "mobile",
    numeric: false,
    visible: true,
    disableResizing: true,
    canGroup_By: false,
    label: "Mobile",
    width: 125,
  },
  {
    id: "30Days",
    numeric: true,
    visible: true,
    disableResizing: true,
    total: true,
    label: "0-30 Days",
    width: 150,
    format: "indian_rupee",
  },
  {
    id: "60Days",
    numeric: true,
    visible: true,
    disableResizing: true,
    total: true,
    label: "31-60 Days",
    width: 150,
    format: "indian_rupee",
  },
  {
    id: "90Days",
    numeric: true,
    visible: true,
    disableResizing: true,
    total: true,
    label: "61-90 Days",
    width: 150,
    format: "indian_rupee",
  },
  {
    id: "120Days",
    numeric: true,
    visible: true,
    disableResizing: true,
    total: true,
    label: "> 90 Days",
    width: 150,
    format: "indian_rupee",
  },
  {
    id: "NetAmount",
    numeric: true,
    visible: true,
    disableResizing: true,
    total: true,
    label: `Total Amount`,
    currencySign: true,
    width: 150,
    format: "indian_rupee",
  },
  {
    id: "onAccountNetAmount",
    numeric: true,
    visible: true,
    disableResizing: true,
    total: true,
    label: `On Account`,
    currencySign: true,
    width: 150,
    format: "indian_rupee",
  },
]

export const columnMultiLedgerSummary = [
  {
    id: "name",
    numeric: false,
    visible: true,
    label: "Name",
    canGroup_By: false,
    total: "label",
    showlink: true,
    removelinkonly: { valueblank: ["code"] },
  },
  {
    id: "cityname",
    numeric: false,
    visible: true,
    disableResizing: true,
    canGroup_By: true,
    label: "City",
    width: 150,
  },
  {
    id: "mobile",
    numeric: false,
    visible: true,
    disableResizing: true,
    label: "Mobile",
    canGroup_By: false,
    width: 125,
  },
  {
    id: "debit",
    numeric: true,
    visible: true,
    disableResizing: true,
    currencySign: true,
    total: true,
    label: `Debit`,
    format: "indian_rupee",
    width: 150,
  },
  {
    id: "credit",
    numeric: true,
    visible: true,
    disableResizing: true,
    total: true,
    currencySign: true,
    label: `Credit`,
    format: "indian_rupee",
    width: 150,
  },
  {
    id: "balance",
    numeric: true,
    visible: true,
    disableResizing: true,
    // total: true,
    total: false,
    currencySign: true,
    label: `Balance`,
    format: "Dr_Cr_indian_rupee",
    width: 150,
  },
]

export const columnMultiDetail = [
  { id: "DocNo", numeric: false, visible: true, label: "Doc No", width: billNoWidth, showlink: true },
  {
    id: "docdate",
    numeric: false,
    visible: true,
    label: "Doc Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  {
    id: "description",
    numeric: false,
    visible: true,
    canGroup_By: true,
    label: "Description",
    showlink: true,
    removelinkonly: { valueblank: ["acc_code"] },
  },
  {
    id: "due_date",
    numeric: false,
    visible: true,
    label: "Due Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  { id: "dayDiff", numeric: false, canGroup_By: true, visible: true, label: "Overdue By Days", width: 100 },
  {
    id: "displayType",
    numeric: false,
    visible: true,
    disableResizing: true,
    total: "label",
    canGroup_By: true,
    difference: "Net Ost",
    label: "Type",
    width: 85,
  },
  {
    id: "amount",
    numeric: true,
    visible: true,
    disableResizing: true,
    format: "indian_rupee",
    total: true,
    currencySign: true,
    label: "Amount",
    width: 150,
  },
  {
    id: "outstanding",
    numeric: true,
    visible: true,
    total: true,
    disableResizing: true,
    format: "indian_rupee",
    difference: true,
    currencySign: true,
    label: "Outstanding",
    width: 150,
  },
]

export const MultiOutstandingAgeingDetailColumn = [
  { id: "DocNo", numeric: false, visible: true, label: "Doc No", width: billNoWidth, showlink: true },
  {
    id: "DocDate",
    numeric: false,
    total: "label",
    difference: "Net Ost",
    visible: true,
    label: "Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  {
    id: "due_date",
    numeric: false,
    visible: true,
    label: "Due Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  {
    id: "30Days",
    numeric: true,
    total: true,
    visible: true,
    label: "0-30 Days",
    width: 150,
    disableResizing: true,
    format: "indian_rupee",
  },
  {
    id: "60Days",
    numeric: true,
    total: true,
    visible: true,
    label: "31-60 Days",
    width: 150,
    disableResizing: true,
    format: "indian_rupee",
  },
  {
    id: "90Days",
    numeric: true,
    total: true,
    visible: true,
    label: "61-90 Days",
    width: 150,
    disableResizing: true,
    format: "indian_rupee",
  },
  {
    id: "120Days",
    numeric: true,
    total: true,
    visible: true,
    label: "> 90 Days",
    width: 150,
    disableResizing: true,
    format: "indian_rupee",
  },
  {
    id: "NetAmount",
    numeric: true,
    total: true,
    difference: true,
    visible: true,
    label: `Total Amount`,
    disableResizing: true,
    currencySign: true,
    width: 175,
    format: "indian_rupee",
  },
]

export const columnMultiLedger = [
  { id: "docno", numeric: false, visible: true, label: "Doc No", width: billNoWidth, showlink: true },
  {
    id: "docdate",
    numeric: false,
    visible: true,
    label: "Doc Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  {
    id: "description",
    numeric: false,
    visible: true,
    canGroup_By: true,
    label: "Description",
    showlink: true,
    removelinkonly: { valueblank: ["acc_code"] },
  },
  {
    id: "type",
    numeric: false,
    visible: true,
    canGroup_By: true,
    disableResizing: true,
    label: "Type",
    total: "label",
    width: 100,
  },
  {
    id: "debit",
    numeric: true,
    visible: true,
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    total: true,
    label: "Debit",
    width: 150,
  },
  {
    id: "credit",
    numeric: true,
    visible: true,
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    total: true,
    label: "Credit",
    width: 150,
  },
  {
    id: "balance",
    numeric: true,
    visible: true,
    currencySign: true,
    label: "Balance",
    disableResizing: true,
    width: 150,
    format: "Dr_Cr_indian_rupee",
  },
]

export const closingReportcolumn = [
  {
    id: "ProductGroup",
    numeric: false,
    visible: true,
    canGroup_By: true,
    label: "Product Group",
    width: 200,
    display: "item_group",
  },
  {
    id: "ProductSubGroupName",
    numeric: false,
    visible: true,
    canGroup_By: true,
    label: "Product Subgroup",
    width: 200,
    display: "item_category",
  },
  {
    id: "ProductSubGroup2Name",
    numeric: false,
    visible: true,
    canGroup_By: true,
    label: "Product Subgroup Level 2",
    width: 200,
    display: "item_subgroupLevel2",
  },
  {
    id: "Name",
    numeric: false,
    visible: true,
    total: "label",
    label: "Name",
    canGroup_By: true,
    showlink: true,
    removelinkonly: { valueblank: ["ItemCode"] },
  },
  {
    id: "OpBal",
    numeric: true,
    visible: true,
    total: true,
    label: "Op",
    disableResizing: true,
    width: 150,
    format: "qty_decimal",
  },
  {
    id: "InQty",
    numeric: true,
    visible: true,
    total: true,
    label: "In Qty",
    disableResizing: true,
    width: 150,
    format: "qty_decimal",
  },
  {
    id: "OutQty",
    numeric: true,
    visible: true,
    total: true,
    label: "Out Qty",
    disableResizing: true,
    width: 150,
    format: "qty_decimal",
  },
  {
    id: "ClosingQty",
    numeric: true,
    visible: true,
    total: true,
    label: "Closing Qty",
    disableResizing: true,
    width: 150,
    format: "qty_decimal",
  },
  {
    id: "Rate",
    numeric: true,
    visible: true,
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    label: `Rate`,
    width: 200,
  },
  {
    id: "Amount",
    numeric: true,
    total: true,
    visible: true,
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    label: `Amount`,
    width: 200,
  },
]

export const lot_DetailColumn = [
  {
    id: "itemname",
    numeric: false,
    visible: true,
    label: "Item",
    showlink: true,
    canGroup_By: true,
    removelinkonly: { valueblank: ["itemcode"] },
  },
  { id: "itemNarration", numeric: false, visible: true, label: "Narration", staticMaxWidth: 250, width: 150 },
  { id: "inQty", numeric: true, visible: true, label: "InQty", width: 150, disableResizing: true, format: "qty_decimal" },
  { id: "outQty", numeric: true, visible: true, label: "OutQty", width: 150, disableResizing: true, format: "qty_decimal" },
]

export const multiOutFirstDetail = [
  { id: "name", numeric: false, visible: true, label: "Name", canGroup_By: false, showlink: true },
  { id: "mobile", numeric: false, visible: true, total: "label", canGroup_By: false, label: "Mobile", width: 150 },
  {
    id: "debit",
    numeric: true,
    visible: true,
    currencySign: true,
    disableResizing: true,
    totalFromSubTable: { subtableKey: "DetailGrid", totalField: "debit" },
    total: true,
    label: `Debit`,
    format: "indian_rupee",
    width: 150,
  },
  {
    id: "credit",
    numeric: true,
    visible: true,
    total: true,
    currencySign: true,
    disableResizing: true,
    totalFromSubTable: { subtableKey: "DetailGrid", totalField: "credit" },
    label: `Credit`,
    format: "indian_rupee",
    width: 150,
  },
  {
    id: "balance",
    numeric: true,
    visible: true,
    currencySign: true,
    label: "Balance",
    disableResizing: true,
    width: 150,
    format: "Dr_Cr_indian_rupee",
  },
]

export const multiOutStandingFirstDetail = [
  { id: "name", numeric: false, visible: true, label: "Name", canGroup_By: false, showlink: true, removelinkonly: { valueblank: ["code"] } },
  { id: "mobile", numeric: false, visible: true, total: "label", canGroup_By: false, label: "Mobile", width: 150 },
  {
    id: "amount",
    numeric: true,
    visible: true,
    total: true,
    currencySign: true,
    disableResizing: true,
    format: "indian_rupee",
    label: `Amount`,
    width: 150,
  },
  {
    id: "outstanding",
    numeric: true,
    visible: true,
    total: true,
    currencySign: true,
    disableResizing: true,
    format: "indian_rupee",
    label: `Outstanding`,
    width: 150,
  },
  {
    id: "onaccount",
    numeric: true,
    visible: true,
    total: true,
    currencySign: true,
    disableResizing: true,
    format: "indian_rupee",
    label: `On Account`,
    width: 150,
  },
  {
    id: "balance",
    numeric: true,
    visible: true,
    currencySign: true,
    total: true,
    disableResizing: true,
    label: `Balance`,
    format: "indian_rupee",
    width: 150,
  },
]

export const multiOutStandingAgeingFirstDetail = [
  {
    id: "name",
    numeric: false,
    visible: true,
    total: "label",
    canGroup_By: false,
    label: "Name",
    showlink: true,
    removelinkonly: { valueblank: ["code"] },
  },
  { id: "mobile", numeric: false, visible: true, label: "Mobile", canGroup_By: false, width: 125 },
  {
    id: "30Days",
    numeric: true,
    visible: true,
    total: true,
    label: "0-30 Days",
    disableResizing: true,
    width: 150,
    format: "indian_rupee",
  },
  {
    id: "60Days",
    numeric: true,
    visible: true,
    total: true,
    label: "31-60 Days",
    disableResizing: true,
    width: 150,
    format: "indian_rupee",
  },
  {
    id: "90Days",
    numeric: true,
    visible: true,
    total: true,
    label: "61-90 Days",
    disableResizing: true,
    width: 150,
    format: "indian_rupee",
  },
  {
    id: "120Days",
    numeric: true,
    visible: true,
    total: true,
    label: "> 90 Days",
    disableResizing: true,
    width: 150,
    format: "indian_rupee",
  },
  {
    id: "NetAmount",
    numeric: true,
    visible: true,
    total: true,
    label: `Total Amount`,
    disableResizing: true,
    currencySign: true,
    width: 150,
    format: "indian_rupee",
  },
  {
    id: "onAccountNetAmount",
    numeric: true,
    visible: true,
    total: true,
    label: `On Account`,
    disableResizing: true,
    currencySign: true,
    width: 150,
    format: "indian_rupee",
  },
  {
    id: "outstanding",
    numeric: true,
    visible: true,
    total: true,
    disableResizing: true,
    format: "indian_rupee",
    difference: true,
    currencySign: true,
    label: "Net Outstanding",
    width: 150,
  },
]

export const columnTransportWise = [
  { id: "name", numeric: false, visible: true, total: "label", canGroup_By: true, label: "Name", showlink: true },
  {
    id: "netamt",
    numeric: true,
    visible: true,
    total: true,
    disableResizing: true,
    currencySign: true,
    label: `Amount`,
    width: 175,
    format: "indian_rupee",
    disableResizing: true,
  },
]

export const columnDataTDS = [
  { id: "name", numeric: false, visible: true, label: "Account Name", canGroup_By: true, showlink: true },
  {
    id: "displayType",
    numeric: false,
    visible: true,
    canGroup_By: true,
    label: "Type",
    disableResizing: true,
    width: 100,
  },
  {
    id: "pano",
    numeric: false,
    visible: true,
    total: "label",
    canGroup_By: true,
    disableResizing: true,
    label: "Pan No",
    width: 150,
  },
  {
    id: "netamt",
    numeric: true,
    visible: true,
    total: true,
    label: `Amount`,
    disableResizing: true,
    currencySign: true,
    width: 200,
    format: "indian_rupee",
  },
  { id: "tds_percent", numeric: true, visible: true, label: "TDS(%)", disableResizing: true, width: 100 },
  {
    id: "tds_amt",
    numeric: true,
    visible: true,
    total: true,
    label: `TDS Amount`,
    disableResizing: true,
    currencySign: true,
    width: 200,
    format: "indian_rupee",
  },
]

export const columnDataVerticalLedger = [
  {
    id: "CDocNo",
    numeric: false,
    visible: true,
    label: "Doc No",
    width: billNoWidth,
    showlink: true,
    removelinkonly: { valueblank: ["CDocNo"] },
  },
  {
    id: "CDocDate",
    numeric: false,
    visible: true,
    total: "label",
    label: "Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  {
    id: "CDescription",
    numeric: false,
    visible: true,
    canGroup_By: true,
    label: "Description",
    showlink: true,
    removelinkonly: { valueblank: ["CDocNo"] },
    bold: { valueblank: ["CDocNo"] },
  },
  {
    id: "CCredit",
    numeric: true,
    visible: true,
    total: true,
    label: `Credit`,
    disableResizing: true,
    format: "indian_rupee",
    currencySign: true,
    width: 150,
    bold: { valueblank: ["CDocNo"] },
  },
  {
    id: "DDocNo",
    numeric: false,
    visible: true,
    label: "Doc No",
    width: billNoWidth,
    showlink: true,
    removelinkonly: { valueblank: ["DDocNo"] },
  },
  {
    id: "DDocDate",
    numeric: false,
    visible: true,
    label: "Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  {
    id: "DDescription",
    numeric: false,
    visible: true,
    canGroup_By: true,
    label: "Description",
    showlink: true,
    removelinkonly: { valueblank: ["DDocNo"] },
    bold: { valueblank: ["DDocNo"] },
  },
  {
    id: "DDebit",
    numeric: true,
    visible: true,
    label: `Debit`,
    total: true,
    disableResizing: true,
    format: "indian_rupee",
    currencySign: true,
    width: 150,
    bold: { valueblank: ["DDocNo"] },
  },
]

export const columnDataVerticalInterest = [
  { id: "C_DocNo", numeric: false, visible: true, label: "Doc No", width: billNoWidth, showlink: true },
  {
    id: "C_B_Date",
    numeric: false,
    visible: true,
    label: "Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  {
    id: "C_Narration",
    numeric: false,
    staticMaxWidth: 250,
    visible: true,
    format: "removeBR",
    total: "label",
    label: "Narration",
    printcharcount: 22,
  },
  {
    id: "C_Days",
    numeric: true,
    visible: true,
    disableResizing: true,
    label: "Days",
    width: 80,
  },
  {
    id: "C_Credit",
    numeric: true,
    visible: true,
    disableResizing: true,
    label: `Credit`,
    total: true,
    format: "indian_rupee",
    currencySign: true,
    width: 150,
    bold: { valueblank: ["C_DocNo"] },
  },
  {
    id: "C_Interest",
    numeric: true,
    visible: true,
    disableResizing: true,
    label: `Interest`,
    total: true,
    format: "indian_rupee",
    currencySign: true,
    width: 150,
    bold: { valueblank: ["C_DocNo"] },
  },
  { id: "D_DocNo", numeric: false, visible: true, label: "Doc No", width: billNoWidth, showlink: true },
  {
    id: "D_B_Date",
    numeric: false,
    visible: true,
    label: "Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  {
    id: "D_Narration",
    numeric: false,
    staticMaxWidth: 250,
    visible: true,
    format: "removeBR",
    label: "Narration",
    totalDifferenceLabel: "Total Balance",
    printcharcount: 22,
  },
  { id: "D_Days", numeric: true, visible: true, disableResizing: true, label: "Days", width: 80 },
  {
    id: "D_Debit",
    numeric: true,
    visible: true,
    disableResizing: true,
    label: `Debit`,
    totalDifference: { diferenceFrom: "D_Debit", diferenceOf: "C_Credit" },
    total: true,
    format: "indian_rupee",
    currencySign: true,
    width: 150,
    bold: { valueblank: ["D_DocNo"] },
  },
  {
    id: "D_Interest",
    numeric: true,
    visible: true,
    disableResizing: true,
    label: `Interest`,
    total: true,
    format: "indian_rupee",
    totalDifference: { diferenceFrom: "D_Interest", diferenceOf: "C_Interest" },
    currencySign: true,
    width: 150,
    bold: { valueblank: ["D_DocNo"] },
  },
]

export const In_OutDetailcolumnData = [
  {
    id: "item",
    numeric: false,
    visible: true,
    label: "Item",
    canGroup_By: true,
    showlink: true,
    removelinkonly: { valueblank: ["ItemCode"] },
  },
  {
    id: "narration",
    numeric: false,
    visible: true,
    total: "label",
    staticMaxWidth: 250,
    label: "Narration",
    printcharcount: 24,
  },
  {
    id: "Qty",
    numeric: true,
    visible: true,
    total: true,
    label: "Qty",
    disableResizing: true,
    width: 150,
    format: "qty_decimal",
  },
  {
    id: "itemGroupName",
    numeric: false,
    visible: true,
    label: "Product Group",
    disableResizing: true,
    width: 150,
  },
  {
    id: "sku",
    numeric: false,
    visible: true,
    label: "SKU",
    disableResizing: true,
    width: 150,
  },
  {
    id: "unit",
    numeric: false,
    visible: true,
    label: "Unit",
    disableResizing: true,
    width: 150,
  },
]

export const VOUCHERSCOLUMN = [
  { id: "type", numeric: false, canGroup_By: true, visible: true, label: "Type" },
  { id: "no", numeric: true, disableResizing: true, visible: true, label: "No", width: billNoWidth },
]

export const columnGSTR2 = [
  {
    id: "name",
    numeric: false,
    visible: true,
    canGroup_By: true,
    label: "Name",
    showlink: true,
    removelinkonly: { valueblank: ["partycode"] },
  },
  { id: "billno", numeric: false, visible: true, label: "Doc No", width: billNoWidth },
  {
    id: "date",
    numeric: false,
    visible: true,
    label: "Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  {
    id: "gstin",
    numeric: false,
    visible: true,
    label: "GSTIN No",
    width: 200,
    display: "tax",
    disableResizing: true,
    canGroup_By: true,
  },
  { id: "type", numeric: false, visible: true, canGroup_By: true, disableResizing: true, label: "Type", width: 100 },
  {
    id: "amount",
    numeric: true,
    visible: true,
    disableResizing: true,
    format: "indian_rupee",
    label: "Taxable Amount",
    width: 150,
    currencySign: true,
  },
  {
    id: "igst_amount",
    numeric: true,
    visible: true,
    label: "IGST",
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    width: 150,
    display: "tax",
  },
  {
    id: "cgst_amount",
    numeric: true,
    visible: true,
    label: "CGST",
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    width: 150,
    display: "tax",
  },
  {
    id: "sgst_amount",
    numeric: true,
    visible: true,
    label: "SGST",
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    width: 150,
    display: "tax",
  },
  {
    id: "cess",
    numeric: true,
    visible: true,
    label: "CESS",
    disableResizing: true,
    width: 150,
    currencySign: true,
  },
  {
    id: "netamount",
    numeric: true,
    visible: true,
    format: "indian_rupee",
    label: "Total Amount",
    disableResizing: true,
    width: 200,
    currencySign: true,
  },
  {
    id: "gstr3B_field",
    numeric: false,
    visible: true,
    canGroup_By: true,
    disableResizing: true,
    label: "Status",
    width: 100,
    badge: {
      0: { color: "red", label: "Not Filed" },
      1: { color: "green", label: "Filed" },
      2: { color: "yellow", label: "Pending" },
    },
  },
  {
    id: "itcavl",
    numeric: false,
    visible: true,
    canGroup_By: true,
    disableResizing: true,
    label: "ITC Avl",
    width: 100,
    badge: {
      Y: { color: "green" },
      N: { color: "red" },
    },
  },
  {
    id: "rsn",
    numeric: false,
    visible: true,
    canGroup_By: true,
    disableResizing: true,
    label: "Reason",
    width: 100,
  },
  {
    id: "rev",
    numeric: false,
    visible: true,
    canGroup_By: true,
    disableResizing: true,
    label: "Reverse Charge",
    width: 100,
    badge: {
      Y: { color: "green" },
      N: { color: "red" },
    },
  },
]

export const columnGSTR2partially = [
  {
    id: "name",
    numeric: false,
    visible: true,
    label: "Name",
    canGroup_By: true,
    showlink: true,
    removelinkonly: { valueblank: ["partycode"] },
  },
  { id: "billno", numeric: false, visible: true, label: "Doc No", width: billNoWidth },
  {
    id: "date",
    numeric: false,
    visible: true,
    label: "Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  {
    id: "gstin",
    numeric: false,
    visible: true,
    label: "GSTIN No",
    width: 200,
    display: "tax",
    disableResizing: true,
    canGroup_By: true,
  },
  { id: "type", numeric: false, visible: true, canGroup_By: true, disableResizing: true, label: "Type", width: 100 },
  {
    id: "amount",
    numeric: true,
    visible: true,
    format: "indian_rupee",
    label: "Taxable Amount",
    disableResizing: true,
    width: 150,
    currencySign: true,
  },
  {
    id: "igst_amount",
    numeric: true,
    visible: true,
    label: "IGST",
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    width: 150,
    display: "tax",
  },
  {
    id: "cgst_amount",
    numeric: true,
    visible: true,
    label: "CGST",
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    width: 150,
    display: "tax",
  },
  {
    id: "sgst_amount",
    numeric: true,
    visible: true,
    label: "SGST",
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    width: 150,
    display: "tax",
  },
  {
    id: "cess",
    numeric: true,
    visible: true,
    label: "CESS",
    disableResizing: true,
    format: "indian_rupee",
    width: 150,
    currencySign: true,
  },
  {
    id: "netamount",
    numeric: true,
    visible: true,
    label: "Total Amount",
    disableResizing: true,
    format: "indian_rupee",
    width: 200,
    currencySign: true,
  },
  {
    id: "govnetamount",
    numeric: true,
    visible: true,
    label: "GSTR-2 Amount",
    format: "indian_rupee",
    disableResizing: true,
    width: 200,
    currencySign: true,
  },
  {
    id: "gstr3B_field",
    numeric: false,
    visible: true,
    canGroup_By: true,
    disableResizing: true,
    label: "Status",
    width: 100,
    badge: {
      0: { color: "red", label: "Not Filed" },
      1: { color: "green", label: "Filed" },
      2: { color: "yellow", label: "Pending" },
    },
  },
  {
    id: "itcavl",
    numeric: false,
    visible: true,
    canGroup_By: true,
    disableResizing: true,
    label: "ITC Avl",
    width: 100,
    badge: {
      Y: { color: "green" },
      N: { color: "red" },
    },
  },
  {
    id: "rsn",
    numeric: false,
    visible: true,
    canGroup_By: true,
    disableResizing: true,
    label: "Reason",
    width: 100,
  },
  {
    id: "rev",
    numeric: false,
    visible: true,
    canGroup_By: true,
    disableResizing: true,
    label: "Reverse Charge",
    width: 100,
    badge: {
      Y: { color: "green" },
      N: { color: "red" },
    },
  },
]

export const columnGSTRmissing = [
  {
    id: "name",
    numeric: false,
    visible: true,
    label: "Name",
    canGroup_By: true,
    showlink: true,
    removelinkonly: { valueblank: ["bill_no"] },
  },
  {
    id: "gstin",
    numeric: false,
    visible: true,
    label: "GSTIN No",
    width: 200,
    display: "tax",
    disableResizing: true,
    canGroup_By: true,
  },
  { id: "billno", numeric: false, visible: true, label: "Doc No", width: billNoWidth },
  {
    id: "date",
    numeric: false,
    visible: true,
    label: "Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  { id: "type", numeric: false, visible: true, canGroup_By: true, disableResizing: true, label: "Type", width: 100 },
  {
    id: "amount",
    numeric: true,
    visible: true,
    label: "Taxable Amount",
    format: "indian_rupee",
    disableResizing: true,
    width: 150,
    currencySign: true,
  },
  {
    id: "igst_amount",
    numeric: true,
    visible: true,
    label: "IGST",
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    width: 150,
    display: "tax",
  },
  {
    id: "cgst_amount",
    numeric: true,
    visible: true,
    label: "CGST",
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    width: 150,
    display: "tax",
  },
  {
    id: "sgst_amount",
    numeric: true,
    visible: true,
    label: "SGST",
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    width: 150,
    display: "tax",
  },
  {
    id: "cess",
    numeric: true,
    visible: true,
    label: "CESS",
    format: "indian_rupee",
    width: 150,
    disableResizing: true,
    currencySign: true,
  },
  {
    id: "netamount",
    numeric: true,
    visible: true,
    label: "Total Amount",
    format: "indian_rupee",
    width: 200,
    disableResizing: true,
    currencySign: true,
  },
  {
    id: "itcavl",
    numeric: false,
    visible: true,
    canGroup_By: true,
    disableResizing: true,
    label: "ITC Avl",
    width: 100,
    badge: {
      Y: { color: "green" },
      N: { color: "red" },
    },
  },
  {
    id: "rsn",
    numeric: false,
    visible: true,
    canGroup_By: true,
    disableResizing: true,
    label: "Reason",
    width: 100,
  },
  {
    id: "rev",
    numeric: false,
    visible: true,
    canGroup_By: true,
    disableResizing: true,
    label: "Reverse Charge",
    width: 100,
    badge: {
      Y: { color: "green" },
      N: { color: "red" },
    },
  },
]

export const columnAmendBills = [
  { id: "billno", numeric: false, visible: true, label: "Doc No", width: billNoWidth, showlink: true },
  {
    id: "b_date",
    numeric: false,
    visible: true,
    label: "Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  { id: "name", numeric: false, visible: true, canGroup_By: true, label: "Name", showlink: true },
  {
    id: "netamt_old",
    numeric: true,
    visible: true,
    total: true,
    currencySign: true,
    format: "indian_rupee",
    disableResizing: true,
    label: `Old Net Amt.`,
    width: 200,
  },
  {
    id: "netamt",
    numeric: true,
    visible: true,
    total: true,
    currencySign: true,
    format: "indian_rupee",
    disableResizing: true,
    label: `Net Amount`,
    width: 200,
  },
  {
    id: "gstr3B_filed",
    numeric: false,
    visible: true,
    label: "GSTR-3B",
    width: 150,
    badge: {
      0: { color: "red", label: "Not Filed" },
      1: { color: "green", label: "Filed", toolTipField: "gstr3B_filed_date" },
      2: { color: "yellow", label: "Pending" },
    },
  },
  {
    id: "gstr1_filed",
    numeric: false,
    visible: true,
    label: "GSTR-1",
    width: 150,

    badge: {
      0: { color: "red", label: "Not Filed" },
      1: { color: "green", label: "Filed", toolTipField: "gstr1_filed_date" },
      2: { color: "yellow", label: "Pending" },
      3: { color: "blue", label: "Missing" },
    },
  },
]

export const columnGSTSummary = [
  { id: "billno", numeric: false, visible: true, label: "Doc No", width: billNoWidth, showlink: true },
  {
    id: "b_date",
    numeric: false,
    visible: true,
    label: "Date",
    canGroup_By: true,
    disableResizing: true,
    width: dateWidth,
    date: true,
    format: "dmy",
  },
  { id: "name", numeric: false, visible: true, canGroup_By: true, label: "Name", showlink: true },
  {
    id: "gstin",
    numeric: false,
    visible: true,
    label: "GSTIN No",
    width: 200,
    display: "tax",
    disableResizing: true,
    canGroup_By: true,
  },
  {
    id: "aadhar_no",
    numeric: false,
    visible: true,
    canGroup_By: true,
    disableResizing: true,
    label: "AADHAAR No",
    width: 150,
  },
  {
    id: "pan_no",
    numeric: false,
    visible: true,
    canGroup_By: true,
    disableResizing: true,
    label: "PAN No",
    width: 150,
  },
  {
    id: "statename",
    numeric: false,
    visible: true,
    canGroup_By: true,
    total: "label",
    disableResizing: true,
    label: "POS",
    width: 150,
  },
  {
    id: "amount",
    numeric: true,
    visible: true,
    total: true,
    currencySign: true,
    format: "indian_rupee",
    disableResizing: true,
    label: `Amount`,
    width: 150,
  },
  {
    id: "igst_amt",
    numeric: true,
    visible: true,
    total: true,
    label: `IGST`,
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    width: 150,
    display: "tax",
  },
  {
    id: "sgst_amt",
    numeric: true,
    visible: true,
    total: true,
    label: `SGST`,
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    width: 150,
    display: "tax",
  },
  {
    id: "cgst_amt",
    numeric: true,
    visible: true,
    total: true,
    label: `CGST`,
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    width: 150,
    display: "tax",
  },
  {
    id: "netamount",
    numeric: true,
    visible: true,
    total: true,
    currencySign: true,
    format: "indian_rupee",
    disableResizing: true,
    label: `Net Amount`,
    width: 200,
  },
]

export const estimated_gstColumns = [
  {
    id: "IGST_Amt",
    numeric: true,
    visible: true,
    label: `IGST`,
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    width: 150,
    display: "tax",
  },
  {
    id: "CGST_Amt",
    numeric: true,
    visible: true,
    label: `CGST`,
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    width: 150,
    display: "tax",
  },
  {
    id: "SGST_Amt",
    numeric: true,
    visible: true,
    label: `SGST`,
    format: "indian_rupee",
    disableResizing: true,
    currencySign: true,
    width: 150,
    display: "tax",
  },
]

export const productWiseStockMasterColumnData = [
  { id: "itemGroupName", numeric: false, visible: true, label: "Group", canGroup_By: true, display: "item_group" },
  {
    id: "itemSubGroupName",
    numeric: false,
    visible: true,
    label: "Sub Group",
    canGroup_By: true,
    display: "item_category",
  },
  {
    id: "itemSubGroupLevel2Name",
    numeric: false,
    visible: true,
    label: "Sub Group Level2",
    display: "item_subgroupLevel2",
  },
  {
    id: "name",
    numeric: false,
    visible: true,
    label: "Product",
    showlink: true,
    removelinkonly: { valueblank: ["code"] },
  },
  {
    id: "OpBal",
    numeric: true,
    visible: true,
    total: true,
    label: "Op",
    width: 150,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "In",
    numeric: true,
    visible: true,
    total: true,
    label: "In",
    width: 150,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "Out",
    numeric: true,
    total: true,
    visible: true,
    label: "Out",
    width: 150,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "Balance",
    numeric: true,
    visible: true,
    total: true,
    label: "Balance",
    width: 200,
    disableResizing: true,
    format: "qty_decimal",
  },
]

export const finishStockInOutMasterColumnData = [
  { id: "itemGroupName", numeric: false, visible: true, label: "Group", canGroup_By: true, display: "item_group" },
  {
    id: "itemSubGroupName",
    numeric: false,
    visible: true,
    label: "Sub Group",
    canGroup_By: true,
    display: "item_category",
  },
  {
    id: "itemSubGroupLevel2Name",
    numeric: false,
    visible: true,
    canGroup_By: true,
    label: "Sub Group Level2",
    display: "item_subgroupLevel2",
  },
  {
    id: "name",
    numeric: false,
    visible: true,
    label: "Product",
    canGroup_By: true,
    showlink: true,
    removelinkonly: { valueblank: ["code"] },
  },
  {
    id: "op_pcs",
    numeric: true,
    visible: true,
    total: true,
    label: "Op Pcs",
    width: 100,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "pcs_in",
    numeric: true,
    visible: true,
    total: true,
    label: "Pcs In",
    width: 100,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "pcs_out",
    numeric: true,
    total: true,
    visible: true,
    label: "Pcs Out",
    width: 100,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "pcs_balance",
    numeric: true,
    visible: true,
    total: true,
    label: "Pcs Bal",
    width: 100,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "op_bundle",
    numeric: true,
    visible: true,
    total: true,
    label: "Op BDL",
    width: 100,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "bundle_in",
    numeric: true,
    visible: true,
    total: true,
    label: "BDL In",
    width: 100,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "bundle_out",
    numeric: true,
    total: true,
    visible: true,
    label: "BDL Out",
    width: 100,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "bundle_balance",
    numeric: true,
    visible: true,
    total: true,
    label: "BDL Bal",
    width: 100,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "op",
    numeric: true,
    visible: true,
    total: true,
    label: "Op",
    width: 150,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "In",
    numeric: true,
    visible: true,
    total: true,
    label: "In",
    width: 150,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "Out",
    numeric: true,
    total: true,
    visible: true,
    label: "Out",
    width: 150,
    disableResizing: true,
    format: "qty_decimal",
  },
  {
    id: "Balance",
    numeric: true,
    visible: true,
    total: true,
    label: "Balance",
    width: 150,
    disableResizing: true,
    format: "qty_decimal",
  },
]

export const getReportHeaders = (props) => {
  const { reportdata, formValues, dropDowns, getAccountSitesetting, extraTitle } = props
  var returnHeaders = []
  const reportid = reportdata.reportid
  const invoice_cus_sup = getAccountSitesetting("invoice_cus_sup")
  const purchase_type_detail = getAccountSitesetting("purchase_type_detail")
  const tcs_settings = getAccountSitesetting("tcs_settings")
  const tds_settings = getAccountSitesetting("tds_settings")
  const item_group = parseInt(getAccountSitesetting("item_group"))
  const item_subgroupLevel2 = parseInt(getAccountSitesetting("item_subgroupLevel2"))
  const item_category = parseInt(getAccountSitesetting("item_category"))
  const report_format = parseInt(getAccountSitesetting("report_format"))
  const adjust_bill = parseInt(getAccountSitesetting("adjust_bill"))
  const purchase_adjust_bill = parseInt(getAccountSitesetting("purchase_adjust_bill"))
  const multigodown_mng = parseInt(getAccountSitesetting("multigodown_mng"))
  const invoice_type_detail = getAccountSitesetting("invoice_type_detail")

  var includingamount = formValues.includingAmount === true ? "1" : "0"

  if (checkCondition(reportid, [re.PartyGroupPriceReport])) {
    if (!isBlankCode(formValues.partyGroupSelectedValue)) {
      const { ddpartygroup } = dropDowns
      returnHeaders.push({
        label: "Account Group",
        value: getdropdownfieldvalue({ dropDownData: ddpartygroup, field: "code", value: getDropDownPostValue(formValues.partyGroupSelectedValue), displayvalue: "name" }),
      })
    }
  }

  if (
    checkCondition(reportid, [re.ItemGroupSummaryReport, re.ItemSubGroupSummaryReport, re.ItemSubGroupLevel2SummaryReport, re.StoneAnalysis, re.StoneCostAnalysis]) ||
    (checkCondition(reportid, [
      re.LiveStockReport,
      re.ProductWiseStockReport,
      re.FinishStockInOutReport,
      re.FinishStockReport,
      re.ClosingReport,
      re.ProductExpiryReport,
      re.ProductExpiredReport,
      re.ReorderLevelReport,
      re.ItemWiseSalesReport,
      re.PartyWiseStockReport,
      re.ItemWisePurchaseReport,
      re.PartyPriceMasterReport,
      re.PartyGroupPriceReport,
      re.In_OutRegister,
      re.ProcessHistoryReport,
    ]) &&
      item_group === 1)
  ) {
    if (!isBlankCode(formValues.productgroupSelectedValue)) {
      const { dditemgroup } = dropDowns
      if (
        checkCondition(reportid, [
          re.ItemGroupSummaryReport,
          re.StoneAnalysis,
          re.StoneCostAnalysis,
          re.ItemSubGroupSummaryReport,
          re.ItemSubGroupLevel2SummaryReport,
          re.ItemWiseSalesReport,
          re.ItemWisePurchaseReport,
          re.LiveStockReport,
          re.PartyWiseStockReport,
          re.In_OutRegister,
          re.ProductExpiryReport,
          re.ProductExpiredReport,
          re.FinishStockInOutReport,
          re.FinishStockReport,
          re.ProductWiseStockReport,
          re.ClosingReport,
          re.ProcessHistoryReport,
          re.ReorderLevelReport,
          re.PartyPriceMasterReport,
        ])
      ) {
        if (formValues.productgroupSelectedValue.length > 0) {
          returnHeaders.push({
            label: "Product Group",
            value: getMultidropdownfieldvalue({ dropDownData: dditemgroup, field: "code", value: formValues.productgroupSelectedValue, displayvalue: "name" }),
          })
        }
      } else {
        returnHeaders.push({
          label: "Product Group",
          value: getdropdownfieldvalue({ dropDownData: dditemgroup, field: "code", value: getDropDownPostValue(formValues.productgroupSelectedValue), displayvalue: "name" }),
        })
      }
    }
  }

  if (
    (checkCondition(reportid, [
      re.ItemWiseSalesReport,
      re.ItemSubGroupSummaryReport,
      re.ItemWisePurchaseReport,
      re.ClosingReport,
      re.PartyWiseStockReport,
      re.In_OutRegister,
      re.ReorderLevelReport,
      re.LiveStockReport,
      re.ProductExpiryReport,
      re.ProductExpiredReport,
      re.FinishStockInOutReport,
      re.FinishStockReport,
      re.ProductWiseStockReport,
      re.ProcessHistoryReport,
      re.PartyGroupPriceReport,
      re.PartyPriceMasterReport,
    ]) &&
      item_category === 1) ||
    checkCondition(reportid, [re.ItemSubGroupLevel2SummaryReport])
  ) {
    if (!isBlankCode(formValues.productSubgroupSelectedValue)) {
      const { ddpructsubgroup } = dropDowns
      if (
        checkCondition(reportid, [
          re.ItemSubGroupSummaryReport,
          re.ProductExpiryReport,
          re.ProductExpiredReport,
          re.ItemWiseSalesReport,
          re.ItemSubGroupLevel2SummaryReport,
          re.ItemWisePurchaseReport,
          re.LiveStockReport,
          re.FinishStockInOutReport,
          re.FinishStockReport,
          re.ProductWiseStockReport,
          re.PartyWiseStockReport,
          re.In_OutRegister,
          re.ClosingReport,
          re.ProcessHistoryReport,
          re.ReorderLevelReport,
          re.PartyGroupPriceReport,
          re.PartyPriceMasterReport,
        ])
      ) {
        if (formValues.productSubgroupSelectedValue.length > 0) {
          returnHeaders.push({
            label: "Product Subgroup",
            value: getMultidropdownfieldvalue({ dropDownData: ddpructsubgroup, field: "code", value: formValues.productSubgroupSelectedValue, displayvalue: "name" }),
          })
        }
      } else {
        returnHeaders.push({
          label: "Product Subgroup",
          value: getdropdownfieldvalue({ dropDownData: ddpructsubgroup, field: "code", value: getDropDownPostValue(formValues.productSubgroupSelectedValue), displayvalue: "name" }),
        })
      }
    }

    if (checkCondition(reportid, [re.ItemSubGroupSummaryReport], "not") && (checkCondition(reportid, [re.ItemSubGroupLevel2SummaryReport]) || item_subgroupLevel2 === 1)) {
      if (!isBlankCode(formValues.productSubgroupLevel2SelectedValue)) {
        const { ddpructsubgrouplevel2 } = dropDowns
        if (
          checkCondition(reportid, [
            re.ItemWiseSalesReport,
            re.ItemWisePurchaseReport,
            re.ProductExpiryReport,
            re.ProductExpiredReport,
            re.ItemSubGroupLevel2SummaryReport,
            re.LiveStockReport,
            re.FinishStockInOutReport,
            re.FinishStockReport,
            re.PartyWiseStockReport,
            re.In_OutRegister,
            re.ClosingReport,
            re.ProcessHistoryReport,
            re.ReorderLevelReport,
            re.PartyGroupPriceReport,
            re.PartyPriceMasterReport,
          ])
        ) {
          if (formValues.productSubgroupLevel2SelectedValue.length > 0) {
            returnHeaders.push({
              label: "Product Subgroup level 2",
              value: getMultidropdownfieldvalue({ dropDownData: ddpructsubgrouplevel2, field: "code", value: formValues.productSubgroupLevel2SelectedValue, displayvalue: "name" }),
            })
          }
        } else {
          returnHeaders.push({
            label: "Product Subgroup level 2",
            value: getdropdownfieldvalue({ dropDownData: ddpructsubgrouplevel2, field: "code", value: getDropDownPostValue(formValues.productSubgroupLevel2SelectedValue), displayvalue: "name" }),
          })
        }
      }
    }
  }

  if (checkCondition(reportid, [re.LotDetailReport, re.LotCostingReport, re.ProcessLongation_ShortageReport, re.ProcessHistoryReport, re.ProcessStockInOutReport])) {
    if (!isBlankCode(formValues.lotSelectedValue)) {
      const { ddlot } = dropDowns
      if (checkCondition(reportid, [re.LotDetailReport, re.ProcessHistoryReport, re.ProcessStockInOutReport])) {
        if (formValues.lotSelectedValue.length > 0) {
          returnHeaders.push({
            label: "Lot",
            value: getMultidropdownfieldvalue({ dropDownData: ddlot, field: "code", value: formValues.lotSelectedValue, displayvalue: "name" }),
          })
        }
      } else {
        returnHeaders.push({
          label: "Lot",
          value: getdropdownfieldvalue({ dropDownData: ddlot, field: "code", value: getDropDownPostValue(formValues.lotSelectedValue), displayvalue: "name" }),
        })
      }
    }
  }

  if (checkCondition(reportid, [re.ClosingReport, re.ReorderLevelReport]) && includingamount === "1") {
    if (!isblank(formValues.ratetypeSelectedValue)) {
      returnHeaders.push({
        label: "Rate",
        value: getdropdownfieldvalue({ dropDownData: getratetype(), field: "id", value: getDropDownPostValue(formValues.ratetypeSelectedValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.SalesRegister, re.Estimate, re.JobDeliveryChallanRegister, re.SalesOrder, re.ProcessHistoryReport])) {
    if (!isBlankCode(formValues.customerSelectedValue)) {
      const { ddcustomer, ddcustomer_supplier } = dropDowns
      returnHeaders.push({
        label: "Account",
        value: getdropdownfieldvalue({
          dropDownData: invoice_cus_sup === 0 ? ddcustomer_supplier : ddcustomer,
          field: "code",
          value: getDropDownPostValue(formValues.customerSelectedValue),
          displayvalue: "name",
        }),
      })
    }
  }

  if (checkCondition(reportid, [re.SalesRegister]) && dropDowns && dropDowns.getOnlyInvoice().length > 1) {
    if (!isblank(formValues.other_type)) {
      returnHeaders.push({
        label: "Invoice Type",
        value: getdropdownfieldvalue({ dropDownData: dropDowns.getOnlyInvoice(), field: "id", value: getDropDownPostValue(formValues.other_type), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.MissingBillNumber])) {
    if (!isblank(formValues.type)) {
      returnHeaders.push({
        label: "Type",
        value: getdropdownfieldvalue({ dropDownData: [{ id: "all", name: "All" }, ...invoice_type_detail], field: "id", value: getDropDownPostValue(formValues.type), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.PurchaseRegister, re.PurchaseOrder, re.PurchaseLotReport])) {
    if (!isBlankCode(formValues.supplierSelectedValue)) {
      const { ddsupplier, ddcustomer_supplier } = dropDowns
      returnHeaders.push({
        label: "Account",
        value: getdropdownfieldvalue({
          dropDownData: invoice_cus_sup === 0 ? ddcustomer_supplier : ddsupplier,
          field: "code",
          value: getDropDownPostValue(formValues.supplierSelectedValue),
          displayvalue: "name",
        }),
      })
    }
  }
  if (checkCondition(reportid, [re.Estimate])) {
    if (!isblank(formValues.status)) {
      returnHeaders.push({
        label: "Status",
        value: getdropdownfieldvalue({ dropDownData: [{ id: "All", name: "All" }, ...getAllEstimateStatus()], field: "id", value: getDropDownPostValue(formValues.status), displayvalue: "name" }),
      })
    }
  }
  if (checkCondition(reportid, [re.SalesOrder, re.PurchaseOrder])) {
    if (!isblank(formValues.status)) {
      returnHeaders.push({
        label: "Status",
        value: getMultidropdownfieldvalue({ dropDownData: [{ id: "All", name: "All" }, ...getAllOrderStatus()], field: "id", value: getDropDownPostValue(formValues.status), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.SalesOrder]) && dropDowns && dropDowns.dd_orderTypes.length > 1) {
    if (!isblank(formValues.other_type)) {
      returnHeaders.push({
        label: "Type",
        value: getdropdownfieldvalue({ dropDownData: dropDowns.dd_orderTypes, field: "id", value: getDropDownPostValue(formValues.other_type), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.PurchaseRegister]) && purchase_type_detail.filter((e) => e.id !== "purchase_order").length > 1) {
    if (!isblank(formValues.other_type)) {
      returnHeaders.push({
        label: "Invoice Type",
        value: getdropdownfieldvalue({
          dropDownData: purchase_type_detail.filter((e) => e.id !== "purchase_order"),
          field: "id",
          value: getDropDownPostValue(formValues.other_type),
          displayvalue: "name",
        }),
      })
    }
  }

  if (checkCondition(reportid, [re.PartyWiseStockReport, re.In_OutRegister])) {
    if (!isblank(formValues.typeSelectedValue)) {
      returnHeaders.push({
        label: "Type",
        value: getdropdownfieldvalue({ dropDownData: getreporttype(), field: "id", value: getDropDownPostValue(formValues.typeSelectedValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.MissingTDSTCSNumber, re.TdsRegister, re.TcsRegister])) {
    if (!isblank(formValues.other_type)) {
      returnHeaders.push({
        label: "Type",
        value: getdropdownfieldvalue({ dropDownData: tds_tcs_missing_Type(), field: "id", value: getDropDownPostValue(formValues.other_type), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.MissingTDSTCSNumber, re.PartyWiseStockReport, re.SalesReturnRegister, re.RecordExpense, re.PurchaseReturnRegister, re.LiveStockReport])) {
    if (!isBlankCode(formValues.customer_supplierSelectedValue)) {
      const { ddcustomer_supplier } = dropDowns
      if (checkCondition(reportid, [re.MissingTDSTCSNumber])) {
        if (formValues.customer_supplierSelectedValue.length > 0) {
          returnHeaders.push({
            label: "Account",
            value: getMultidropdownfieldvalue({ dropDownData: ddcustomer_supplier, field: "code", value: formValues.customer_supplierSelectedValue, displayvalue: "name" }),
          })
        }
      } else {
        returnHeaders.push({
          label: "Account",
          value: getdropdownfieldvalue({ dropDownData: ddcustomer_supplier, field: "code", value: getDropDownPostValue(formValues.customer_supplierSelectedValue), displayvalue: "name" }),
        })
      }
    }
  }

  if (checkCondition(reportid, [re.MissingTDSTCSNumber])) {
    if (!isblank(formValues.tds_tcs_type)) {
      returnHeaders.push({
        label: "TDS/TCS",
        value: getdropdownfieldvalue({
          dropDownData: [
            { id: "tds", name: "TDS" },
            { id: "tcs", name: "TCS" },
          ],
          field: "id",
          value: getDropDownPostValue(formValues.tds_tcs_type),
          displayvalue: "name",
        }),
      })
      if (formValues.tds_tcs_type === "tcs" && !isblank(tcs_settings)) {
        if (!isblank(tcs_settings.tcsCalcType)) {
          returnHeaders.push({
            label: "Calculation Type",
            value: getdropdownfieldvalue({ dropDownData: getTCSCalcType(), field: "id", value: getDropDownPostValue(tcs_settings.tcsCalcType), displayvalue: "name" }),
          })
        }
        if (!isblank(tcs_settings.tcs_acc)) {
          const { ddtcs } = dropDowns
          const acc = ddtcs.find((f) => f.code === tcs_settings.tcs_acc)
          returnHeaders.push({
            label: "TCS A/C",
            value: acc?.name,
          })
        }
        if (!isblank(tcs_settings.tcs_amount)) {
          returnHeaders.push({
            label: "Amount Calculation",
            value: getdropdownfieldvalue({
              dropDownData: [
                { id: "N", name: "Normal" },
                { id: "R", name: "Round Off" },
              ],
              field: "id",
              value: getDropDownPostValue(tcs_settings.tcs_amount),
              displayvalue: "name",
            }),
          })
        }
        if (!isblank(tcs_settings.tcs_threshold_limit)) {
          returnHeaders.push({
            label: "Threshold Limit",
            value: amount_format(tcs_settings.tcs_threshold_limit),
          })
        }
      }
      if (formValues.tds_tcs_type === "tds" && !isblank(tds_settings)) {
        if (!isblank(tds_settings.tds_amount)) {
          returnHeaders.push({
            label: "Amount Calculation",
            value: getdropdownfieldvalue({
              dropDownData: [
                { id: "N", name: "Normal" },
                { id: "R", name: "Round Off" },
              ],
              field: "id",
              value: getDropDownPostValue(tds_settings.tds_amount),
              displayvalue: "name",
            }),
          })
        }
        if (!isblank(tds_settings.tds_threshold_limit)) {
          returnHeaders.push({
            label: "Threshold Limit",
            value: amount_format(tds_settings.tds_threshold_limit),
          })
        }
      }
    }
  }

  if (checkCondition(reportid, [re.P_LAccountCostCenterWise, re.CostCenter])) {
    if (!isblank(formValues.costcenterSelectedValue)) {
      const { ddcostcenter } = dropDowns
      const outputStr = getcostcenterdropdown(ddcostcenter)
      returnHeaders.push({
        label: "Cost center",
        value: getdropdownfieldvalue({ dropDownData: outputStr, field: "code", value: getDropDownPostValue(formValues.costcenterSelectedValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.PaymentRegister, re.ReceiptRegister, re.LedgerReport, re.InterestReport, re.JobInwardsWithoutJobInvoice, re.SalesRegisterwithPaymenttype, re.CostCenter])) {
    if (!isBlankCode(formValues.partySelectedValue)) {
      const { ddallaccount } = dropDowns
      let name = getdropdownfieldvalue({ dropDownData: ddallaccount, field: "code", value: getDropDownPostValue(formValues.partySelectedValue), displayvalue: "name" })
      const city = getdropdownfieldvalue({ dropDownData: ddallaccount, field: "code", value: getDropDownPostValue(formValues.partySelectedValue), displayvalue: "city" })
      if (!isblank(city)) {
        name = name + " (" + city + ")"
      }
      returnHeaders.push({
        label: "Account",
        value: name,
      })
    }
  }

  if (
    checkCondition(reportid, [re.PackingSlipRegister, re.GoodsReceivedNoteRegister, re.DeliveryChallanRegister, re.JobInwardRegister, re.FinishStockRegister, re.ManageLotRegister, re.In_OutRegister])
  ) {
    if (!isBlankCode(formValues.partySelectedValue)) {
      const { ddcustomer, ddcustomer_supplier } = dropDowns
      let ddropdown = []
      if (reportid === re.In_OutRegister) {
        ddropdown = ddcustomer_supplier
      } else {
        ddropdown = invoice_cus_sup === 0 ? ddcustomer_supplier : ddcustomer
      }

      let name = getdropdownfieldvalue({ dropDownData: ddropdown, field: "code", value: getDropDownPostValue(formValues.partySelectedValue), displayvalue: "name" })
      const city = getdropdownfieldvalue({ dropDownData: ddropdown, field: "code", value: getDropDownPostValue(formValues.partySelectedValue), displayvalue: "city" })
      if (!isblank(city)) {
        name = name + " (" + city + ")"
      }

      returnHeaders.push({
        label: "Account",
        value: name,
      })
    }
  }

  if (checkCondition(reportid, [re.PackingSlipRegister, re.GoodsReceivedNoteRegister, re.DeliveryChallanRegister, re.JobInwardRegister, re.FinishStockRegister, re.ManageLotRegister])) {
    if (!isblank(formValues.status)) {
      returnHeaders.push({
        label: "Status",
        value: getdropdownfieldvalue({ dropDownData: getAllOrderStatus(), field: "id", value: getDropDownPostValue(formValues.status), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.PartyPriceMasterReport, re.ProcessStockInOutReport, re.OutstandingReport, re.OutstandingAgeingReport])) {
    if (!isblank(formValues.partySelectedValue)) {
      const { ddcustomer_supplier } = dropDowns
      if (checkCondition(reportid, [re.ProcessStockInOutReport])) {
        if (formValues.partySelectedValue.length > 0) {
          returnHeaders.push({
            label: "Account",
            value: getMultidropdownfieldvalue({ dropDownData: ddcustomer_supplier, field: "code", value: formValues.partySelectedValue, displayvalue: "name" }),
          })
        }
      } else {
        returnHeaders.push({
          label: "Account",
          value: getdropdownfieldvalue({ dropDownData: ddcustomer_supplier, field: "code", value: getDropDownPostValue(formValues.partySelectedValue), displayvalue: "name" }),
        })
      }
    }
  }

  if (checkCondition(reportid, [re.OutstandingAgeingReport, re.MultiOutstandingAgeingReport])) {
    if (!isblank(formValues.dayRange)) {
      returnHeaders.push({
        label: "Day Range",
        value: getdropdownfieldvalue({ dropDownData: dayRangeAge(), field: "id", value: getDropDownPostValue(formValues.dayRange), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.ProcessStockInOutReport])) {
    if (!isblank(formValues.mode)) {
      returnHeaders.push({
        label: "Goods In/Out",
        value: getdropdownfieldvalue({ dropDownData: processInOut(), field: "id", value: getDropDownPostValue(formValues.mode), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.ProcessStockInOutReport, re.TrialBalance])) {
    if (!isblank(formValues.viewType)) {
      returnHeaders.push({
        label: "View type",
        value: getdropdownfieldvalue({ dropDownData: getDetailSummary(), field: "id", value: getDropDownPostValue(formValues.viewType), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.SalesRegisterwithPaymenttype])) {
    if (!isblank(formValues.bankSelectedValue)) {
      const { ddbankaccount } = dropDowns
      returnHeaders.push({
        label: "Bank/Cash",
        value: getdropdownfieldvalue({ dropDownData: ddbankaccount, field: "code", value: getDropDownPostValue(formValues.bankSelectedValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.BankReconcileReport])) {
    if (!isblank(formValues.bankSelectedValue)) {
      const { ddbankaccount } = dropDowns
      returnHeaders.push({
        label: "Bank account",
        value: getdropdownfieldvalue({ dropDownData: ddbankaccount, field: "code", value: getDropDownPostValue(formValues.bankSelectedValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.ProductBatchDetailReport])) {
    if (!isblank(formValues.filterTypeSelectedValue)) {
      returnHeaders.push({
        label: "Filter type",
        value: getdropdownfieldvalue({ dropDownData: getitemfiltertype(), field: "id", value: getDropDownPostValue(formValues.filterTypeSelectedValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.ProductSerialNumberReport, re.ProductBatchDetailReport])) {
    if (!isblank(formValues.productSerialTypeSelectedValue)) {
      returnHeaders.push({
        label: "View type",
        value: getdropdownfieldvalue({ dropDownData: getDetailSummaryContact(), field: "id", value: getDropDownPostValue(formValues.productSerialTypeSelectedValue), displayvalue: "name" }),
      })
    }

    // if (!isblank(formValues.serialSelectedValue) && formValues.serialSelectedValue.length > 0) {
    //   returnHeaders.push({
    //     label: "Serial: ",
    //     value: formValues.serialSelectedValue.join(", "),
    //   })
    // }
  }

  if (
    checkCondition(reportid, [
      re.ProductWiseStockReport,
      re.ClosingReport,
      re.ProductExpiryReport,
      re.ProductExpiredReport,
      re.PartyWiseStockReport,
      re.In_OutRegister,
      re.ItemWiseSalesReport,
      re.ItemWisePurchaseReport,
      re.ProductBatchDetailReport,
      re.ProductSerialNumberReport,
      re.LiveStockReport,
      re.ProcessHistoryReport,
      re.ReorderLevelReport,
      re.PartyGroupPriceReport,
      re.PartyPriceMasterReport,
    ])
  ) {
    if (!isBlankCode(formValues.itemSelectedValue)) {
      const { dditem } = dropDowns
      if (
        checkCondition(reportid, [
          re.ItemWiseSalesReport,
          re.ProductExpiryReport,
          re.ProductExpiredReport,
          re.ItemWisePurchaseReport,
          re.LiveStockReport,
          re.FinishStockInOutReport,
          re.FinishStockReport,
          re.ProductWiseStockReport,
          re.PartyWiseStockReport,
          re.ProductBatchDetailReport,
          re.ProductSerialNumberReport,
          re.ClosingReport,
          re.In_OutRegister,
          re.ProcessHistoryReport,
          re.ReorderLevelReport,
          re.PartyGroupPriceReport,
          re.PartyPriceMasterReport,
        ])
      ) {
        if (formValues.itemSelectedValue.length > 0) {
          returnHeaders.push({
            label: "Product",
            value: getMultidropdownfieldvalue({ dropDownData: dditem, field: "code", value: formValues.itemSelectedValue, displayvalue: "name" }),
          })
        }
      } else {
        returnHeaders.push({
          label: "Product",
          value: getdropdownfieldvalue({ dropDownData: dditem, field: "code", value: getDropDownPostValue(formValues.itemSelectedValue), displayvalue: "name" }),
        })
      }
    }
  }

  if (checkCondition(reportid, [re.ProcessHistoryReport, re.ProcessStockInOutReport])) {
    if (!isBlankCode(formValues.processSelectedValue)) {
      const { ddprocess } = dropDowns
      if (checkCondition(reportid, [re.ProcessHistoryReport, re.ProcessStockInOutReport])) {
        if (formValues.processSelectedValue.length > 0) {
          returnHeaders.push({
            label: "Process",
            value: getMultidropdownfieldvalue({ dropDownData: ddprocess, field: "code", value: formValues.processSelectedValue, displayvalue: "name" }),
          })
        }
      } else {
        returnHeaders.push({
          label: "Process",
          value: getdropdownfieldvalue({ dropDownData: ddprocess, field: "code", value: getDropDownPostValue(formValues.processSelectedValue), displayvalue: "name" }),
        })
      }
    }
  }

  if (checkCondition(reportid, [re.ProcessStockInOutReport])) {
    if (!isBlankCode(formValues.lot_party)) {
      const { ddcustomer_supplier } = dropDowns
      if (checkCondition(reportid, [re.ProcessStockInOutReport])) {
        if (formValues.lot_party.length > 0) {
          returnHeaders.push({
            label: "Lot Party",
            value: getMultidropdownfieldvalue({ dropDownData: ddcustomer_supplier, field: "code", value: formValues.lot_party, displayvalue: "name" }),
          })
        }
      } else {
        returnHeaders.push({
          label: "Lot Party",
          value: getdropdownfieldvalue({ dropDownData: ddcustomer_supplier, field: "code", value: getDropDownPostValue(formValues.lot_party), displayvalue: "name" }),
        })
      }
    }
  }

  if (
    !checkCondition(reportid, [
      re.PartyPriceMasterReport,
      re.DaybookReport,
      re.FinishStockReport,
      re.TrialBalance,
      re.ReorderLevelReport,
      re.CreditLimitReport,
      re.ItemGroupSummaryReport,
      re.ItemSubGroupSummaryReport,
      re.ItemSubGroupLevel2SummaryReport,
      re.ProductExpiredReport,
    ])
  ) {
    returnHeaders.push({
      label: "From",
      value: DatetoDMY(formValues.FromDate),
    })

    returnHeaders.push({
      label: "To",
      value: DatetoDMY(formValues.ToDate),
    })
  }

  if (checkCondition(reportid, [re.OutstandingAgeingReport])) {
    returnHeaders.push({
      label: "Today",
      value: todaydate("DMY"),
    })
  }

  if (checkCondition(reportid, [re.DaybookReport])) {
    if (!isblank(formValues.selectedDate)) {
      returnHeaders.push({
        label: "Date",
        value: DatetoDMY(formValues.selectedDate),
      })
    }
  }

  if (
    checkCondition(reportid, [
      re.ReorderLevelReport,
      re.TrialBalance,
      re.ProductExpiredReport,
      re.CreditLimitReport,
      re.ItemGroupSummaryReport,
      re.ItemSubGroupLevel2SummaryReport,
      re.ItemSubGroupSummaryReport,
      re.FinishStockReport,
    ])
  ) {
    if (!isblank(formValues.TillDate)) {
      returnHeaders.push({
        label: "Till Date",
        value: DatetoDMY(formValues.TillDate),
      })
    }
  }

  if (
    checkCondition(reportid, [
      re.SalesRegister,
      re.PurchaseRegister,
      re.TransportWiseSalesReport,
      re.AgentWiseSalesReport,
      re.SalesReturnRegister,
      re.PurchaseReturnRegister,
      re.Estimate,
      re.JobDeliveryChallanRegister,
      re.SalesOrder,
      re.PurchaseOrder,
      re.LiveStockReport,
      re.GoodsReceivedNoteRegister,
      re.DeliveryChallanRegister,
    ])
  ) {
    if (!isblank(formValues.order_view_type)) {
      returnHeaders.push({
        label: "View Type",
        value: getdropdownfieldvalue({ dropDownData: gettype(), field: "id", value: getDropDownPostValue(formValues.order_view_type), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.LedgerReport, re.InterestReport]) && report_format === 2) {
    if (!isblank(formValues.reportFormat)) {
      returnHeaders.push({
        label: "Format",
        value: getdropdownfieldvalue({ dropDownData: getreportformat(), field: "id", value: getDropDownPostValue(formValues.reportFormat), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.AgentWiseSalesReport])) {
    if (!isBlankCode(formValues.agentSelectedValue)) {
      const { ddagent } = dropDowns
      returnHeaders.push({
        label: "Agent",
        value: getdropdownfieldvalue({ dropDownData: ddagent, field: "code", value: getDropDownPostValue(formValues.agentSelectedValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.TransportWiseSalesReport])) {
    if (!isBlankCode(formValues.transportSelectedValue)) {
      const { ddtransport } = dropDowns
      returnHeaders.push({
        label: "Transport",
        value: getdropdownfieldvalue({ dropDownData: ddtransport, field: "code", value: getDropDownPostValue(formValues.transportSelectedValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.InterestReport])) {
    if (!isblank(formValues.getDaysCalcOptionInt)) {
      returnHeaders.push({
        label: "Calculated On",
        value: getdropdownfieldvalue({ dropDownData: getDaysCalcOptionInt(), field: "id", value: getDropDownPostValue(formValues.calculate_date), displayvalue: "name" }),
      })

      if (formValues.getDaysCalcOptionInt === "2") {
        if (!isblank(formValues.cr_days)) {
          returnHeaders.push({
            label: "Cr Days",
            value: formValues.cr_days,
          })
        }
      }
    }

    if (!isblank(formValues.interesttypeSelectedValue)) {
      returnHeaders.push({
        label: "Interest type",
        value: getdropdownfieldvalue({ dropDownData: getinteresttype(), field: "id", value: getDropDownPostValue(formValues.interesttypeSelectedValue), displayvalue: "name" }),
      })
    }
    if (!isblank(formValues.interestRate)) {
      returnHeaders.push({
        label: "Interest Receivable",
        value: `${formValues.interestRate} %`,
      })
    }

    if (!isblank(formValues.interestPayableRate)) {
      returnHeaders.push({
        label: "Interest Payable",
        value: `${formValues.interestPayableRate} %`,
      })
    }

    if (!isblank(formValues.interestdaysSelectedValue)) {
      returnHeaders.push({
        label: "Interest days",
        value: getdropdownfieldvalue({ dropDownData: getinterestdays(), field: "id", value: getDropDownPostValue(formValues.interestdaysSelectedValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.TrialBalance, re.PaymentRegister, re.ReceiptRegister])) {
    if (!isBlankCode(formValues.accountgroupSelectedValue)) {
      const { dropDownAccountgroup } = dropDowns
      returnHeaders.push({
        label: "Account group",
        value: getdropdownfieldvalue({ dropDownData: dropDownAccountgroup, field: "code", value: getDropDownPostValue(formValues.accountgroupSelectedValue), displayvalue: "name" }),
      })
    }

    if (!isBlankCode(formValues.subGroupSelectedValue)) {
      const { subgroup } = dropDowns
      returnHeaders.push({
        label: "Sub group",
        value: getdropdownfieldvalue({ dropDownData: subgroup, field: "code", value: getDropDownPostValue(formValues.subGroupSelectedValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.AgentWiseSalesReport])) {
    if (!isblank(formValues.agentTypeSelectedValue)) {
      returnHeaders.push({
        label: "Type",
        value: getdropdownfieldvalue({ dropDownData: getagenttype(), field: "id", value: getDropDownPostValue(formValues.agentTypeSelectedValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.TransportWiseSalesReport])) {
    if (!isblank(formValues.transportTypeSelectedValue)) {
      returnHeaders.push({
        label: "Type",
        value: getdropdownfieldvalue({ dropDownData: getagenttype(), field: "id", value: getDropDownPostValue(formValues.transportTypeSelectedValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.AgentWiseSalesReport])) {
    if (!isblank(formValues.agentCommissionSelValue)) {
      returnHeaders.push({
        label: "Commission",
        value: getdropdownfieldvalue({ dropDownData: getyesno(), field: "id", value: getDropDownPostValue(formValues.agentCommissionSelValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.ItemWiseSalesReport, re.ItemWisePurchaseReport])) {
    if (!isblank(formValues.itemTypeSelectedValue)) {
      returnHeaders.push({
        label: "Type",
        value: getdropdownfieldvalue({ dropDownData: getitemwisetype(), field: "id", value: getDropDownPostValue(formValues.itemTypeSelectedValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.ItemWiseSalesReport, re.ItemWisePurchaseReport])) {
    if (!isblank(formValues.type)) {
      returnHeaders.push({
        label: checkCondition(reportid, [re.ItemWiseSalesReport]) ? "Inc Outward" : "Inc Inward",
        value: getdropdownfieldvalue({ dropDownData: getyesno(), field: "id", value: getDropDownPostValue(formValues.type), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport, re.MultiLedgerReport])) {
    if (!isblank(formValues.viewTypeSelectedValue)) {
      returnHeaders.push({
        label: "View type",
        value: getdropdownfieldvalue({ dropDownData: getmultiledgertype(), field: "id", value: getDropDownPostValue(formValues.viewTypeSelectedValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport, re.MultiLedgerReport])) {
    if (!isblank(formValues.multiOutstandingSelValue)) {
      returnHeaders.push({
        label: "Type",
        value: getdropdownfieldvalue({ dropDownData: getmultiledgerwisetype(), field: "id", value: getDropDownPostValue(formValues.multiOutstandingSelValue), displayvalue: "name" }),
      })
    }
  }

  if (checkCondition(reportid, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport, re.OutstandingReport, re.OutstandingAgeingReport])) {
    if (!isblank(formValues.reportViewType)) {
      returnHeaders.push({
        label: "Adjust",
        value: getdropdownfieldvalue({ dropDownData: getallViewType(), field: "id", value: getDropDownPostValue(formValues.reportViewType), displayvalue: "name" }),
      })
    }

    if (!isblank(formValues.calculate_date)) {
      returnHeaders.push({
        label: "Calculated On",
        value: getdropdownfieldvalue({ dropDownData: getDaysCalcOption(), field: "id", value: getDropDownPostValue(formValues.calculate_date), displayvalue: "name" }),
      })
    }

    if (adjust_bill === 1 && purchase_adjust_bill === 1) {
      if (!isblank(formValues.crdrSelectedValue)) {
        returnHeaders.push({
          label: "Outstanding",
          value: getdropdownfieldvalue({ dropDownData: getCustomerSupplierType(), field: "id", value: getDropDownPostValue(formValues.crdrSelectedValue), displayvalue: "name" }),
        })
      }
    }
  }

  if (checkCondition(reportid, [re.ProductBatchDetailReport])) {
    if (!isblank(formValues.batchSelectedValue) && formValues.batchSelectedValue.length > 0) {
      returnHeaders.push({
        label: "Batch",
        value: formValues.batchSelectedValue.join(", "),
      })
    }
  }

  if (checkCondition(reportid, [re.ProductSerialNumberReport])) {
    if (!isblank(formValues.serialSelectedValue) && formValues.serialSelectedValue.length > 0) {
      returnHeaders.push({
        label: "Serial",
        value: formValues.serialSelectedValue.join(", "),
      })
    }
  }

  if (
    multigodown_mng === 1 &&
    checkCondition(reportid, [
      re.ProductWiseStockReport,
      re.FinishStockInOutReport,
      re.FinishStockReport,
      re.ItemWiseSalesReport,
      re.ItemWisePurchaseReport,
      re.PartyWiseStockReport,
      re.ClosingReport,
      re.In_OutRegister,
      re.ReorderLevelReport,
      re.PartyWiseStockReport,
      re.PackingSlipRegister,
      re.GoodsReceivedNoteRegister,
      re.DeliveryChallanRegister,
      re.JobInwardRegister,
      re.FinishStockRegister,
      re.ManageLotRegister,
      re.JobInwardsWithoutJobInvoice,
      re.In_OutRegister,
      re.LiveStockReport,
    ])
  ) {
    if (!isBlankCode(formValues.goDownSelectedValue)) {
      const { ddgodown } = dropDowns
      if (
        checkCondition(reportid, [
          re.ProductWiseStockReport,
          re.FinishStockInOutReport,
          re.FinishStockReport,
          re.ItemWiseSalesReport,
          re.ItemWisePurchaseReport,
          re.ClosingReport,
          re.PartyWiseStockReport,
          re.In_OutRegister,
          re.LiveStockReport,
          re.ReorderLevelReport,
        ])
      ) {
        if (formValues.goDownSelectedValue.length > 0) {
          returnHeaders.push({
            label: "Godown",
            value: getMultidropdownfieldvalue({ dropDownData: ddgodown, field: "code", value: formValues.goDownSelectedValue, displayvalue: "name" }),
          })
        }
      } else {
        returnHeaders.push({
          label: "Godown",
          value: getdropdownfieldvalue({ dropDownData: ddgodown, field: "code", value: getDropDownPostValue(formValues.goDownSelectedValue), displayvalue: "name" }),
        })
      }
    }
  }

  return returnHeaders
}

export const common_setGSTSummaryData = ({ response, functions, params, setconfig }) => {
  const { getAccountSitesetting } = functions
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
  const TypeNames = getAllNameType(getAccountSitesetting)

  const invoice_type_detail = getAccountSitesetting("invoice_type_detail")
  const purchase_type_detail = getAccountSitesetting("purchase_type_detail")
  const salesreturn = getAccountSitesetting("salesreturn")
  const purchasereturn = getAccountSitesetting("purchasereturn")

  const { dd_taxcode, dditem, ddallaccount, getAccountSessionUserData, dd_country_state } = functions
  const newdditem = {}
  dditem.forEach((e, ind) => {
    const new_item = {
      code: e.code,
      name: e.name,
      unit: e.unit,
    }
    newdditem[e.code] = new_item
  })

  const allState = getAllStatesOfcountry(getAccountSessionUserData("company_country"), dd_country_state)
  var HSN = getTypeData("12 - HSN-wise summary of outward supplies", "HSN")
  var HSNIN = getTypeData("12 - HSN-wise summary of inward supplies", "HSNIN")
  var taxCodeArray = []
  var taxCodeArray1 = []
  if (dd_taxcode) {
    if (dd_taxcode.cgst) {
      taxCodeArray = [...taxCodeArray, ...dd_taxcode.cgst.split(",")]
      taxCodeArray1 = [...taxCodeArray1, ...dd_taxcode.cgst.split(",")]
    }
    if (dd_taxcode.sgst) {
      taxCodeArray = [...taxCodeArray, ...dd_taxcode.sgst.split(",")]
    }
    if (dd_taxcode.igst) {
      taxCodeArray = [...taxCodeArray, ...dd_taxcode.igst.split(",")]
      taxCodeArray1 = [...taxCodeArray1, ...dd_taxcode.igst.split(",")]
    }
  }

  functions.taxCodeArray = taxCodeArray
  functions.taxCodeArray1 = taxCodeArray1

  var records = {
    salesInvoice: [],
    purchaseInvoice: [],
    salesReturn: [],
    purchaseReturn: [],
    journalEntry: [],
  }
  Object.keys(response.data.result.records).forEach((e) => {
    if (e === "salesInvoice") {
      response.data.result.records[e].forEach((i) => {
        HSN = checkHSNConditionsV2({ mainType: cloneObjV2(HSN), e: cloneObjV2(i), dditem: newdditem, type: "salesinvoice", functions: params, setconfig, inventory_decimalpoint, TypeNames })
        records[e] = setInvoiceDataV2({ salesData: records[e], newItem: i, functions, type: "invoice_type_detail", sType: invoice_type_detail, allState })
      })
    } else if (e === "purchaseInvoice") {
      response.data.result.records[e].forEach((i) => {
        HSNIN = checkHSNConditionsInV2({ mainType: cloneObjV2(HSNIN), e: cloneObjV2(i), dditem: newdditem, type: "purchaseinvoice", functions: params, setconfig, inventory_decimalpoint, TypeNames })
        records[e] = setInvoiceDataV2({ salesData: records[e], newItem: i, functions, type: "purchase_type_detail", sType: purchase_type_detail, allState })
      })
    } else if (e === "salesReturn") {
      response.data.result.records[e].forEach((i) => {
        if (i.invType === "VS" || i.invType === "S") {
          HSN = checkHSNConditionsV2({ mainType: cloneObjV2(HSN), e: cloneObjV2(i), dditem: newdditem, type: "salesreturn", functions: params, setconfig, inventory_decimalpoint, TypeNames })
        } else {
          HSNIN = checkHSNConditionsInV2({ mainType: cloneObjV2(HSNIN), e: cloneObjV2(i), dditem: newdditem, type: "salesreturn", functions: params, setconfig, inventory_decimalpoint, TypeNames })
        }

        // HSN = checkHSNConditionsV2(cloneObj(HSN), cloneObj(i), dditem, ddallaccount, "salesreturn", params, setconfig, inventory_decimalpoint, TypeNames)
        // HSNIN = checkHSNConditionsV2(cloneObj(HSNIN), cloneObj(i), dditem, ddallaccount, "salesreturn", params, setconfig, inventory_decimalpoint, TypeNames)
        records[e] = [...records[e], getGstSummaryDataV2({ newItem: i, functions, invoice_type: "salesreturn", addRefBill: true, allState })]
      })
    } else if (e === "purchaseReturn") {
      response.data.result.records[e].forEach((i) => {
        if (i.invType === "VS" || i.invType === "S") {
          HSN = checkHSNConditionsV2({ mainType: cloneObjV2(HSN), e: cloneObjV2(i), dditem: newdditem, type: "purchasereturn", functions: params, setconfig, inventory_decimalpoint, TypeNames })
        } else {
          HSNIN = checkHSNConditionsInV2({ mainType: cloneObjV2(HSNIN), e: cloneObjV2(i), dditem: newdditem, type: "purchasereturn", functions: params, setconfig, inventory_decimalpoint, TypeNames })
        }
        //
        records[e] = [...records[e], getGstSummaryDataV2({ newItem: i, functions, invoice_type: "purchasereturn", addRefBill: true, allState })]
      })
    } else if (e === "journalEntry") {
      response.data.result.records[e].forEach((i) => {
        records[e] = [...records[e], i]
      })
    }
  })
  response.data.result.records = [...records.salesInvoice.filter((e) => e.detailGrid.length > 0), ...records.purchaseInvoice.filter((e) => e.detailGrid.length > 0)]
  if (records.journalEntry.length > 0) {
    response.data.result.records = [...response.data.result.records, { id: "journalEntry", label: "Journal Entry", detailGrid: [...records.journalEntry] }]
  }
  if (records.salesReturn.length > 0) {
    response.data.result.records = [...response.data.result.records, { id: "salesReturn", label: "Credit Note", detailGrid: [...records.salesReturn] }]
  }
  if (records.purchaseReturn.length > 0) {
    response.data.result.records = [...response.data.result.records, { id: "purchaseReturn", label: "Debit Note", detailGrid: [...records.purchaseReturn] }]
  }

  if (HSN.detailData.records.list.length > 0) {
    response.data.result.records = [...response.data.result.records, { id: "hsn", label: "HSN Outward", detailGrid: [...HSN.detailData.records.list] }]
  }

  if (HSNIN.detailData.records.list.length > 0) {
    response.data.result.records = [...response.data.result.records, { id: "hsnin", label: "HSN Inward", detailGrid: [...HSNIN.detailData.records.list] }]
  }
  return response
}
