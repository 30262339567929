import * as FileSaver from "file-saver"
import { generateExcelData } from "@project/sharedcomponents/xlsxFunctions"
import * as XLSX from "xlsx-js-style"

export const exportAsExcel = ({ csvData, fileName, title, mycol, skipFormat, headerIndex, totalIndex, groupTitleIndex }) => {
  const ws = generateExcelData({ csvData, title, mycol, skipIndex: skipFormat, headerIndex, total_Index: totalIndex, groupTitle_Index: groupTitleIndex, fileName })
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  const fileExtension = ".xlsx"
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, fileName.toLowerCase() + fileExtension)
}

export const exportAsExcelSheets = ({ fileName, SheetNames, Sheets }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  const fileExtension = ".xlsx"
  const wb = { Sheets, SheetNames }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, fileName.toLowerCase() + fileExtension)
}
