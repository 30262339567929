import { EWB_BALANCE, SMS_BALANCE, UPLOAD_DOCUMENT_SUCCESS, UTILITY_FILE_UPLOAD_PROGRESS } from "../Actions/types"

const INITIAL_STATE = {
  progress: 0,
  data: [],
  sms_balance: {},
  ewb_balance: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UTILITY_FILE_UPLOAD_PROGRESS:
      return { ...state, progress: action.progress }
    case UPLOAD_DOCUMENT_SUCCESS:
      return { ...state, data: action.payload }
    case SMS_BALANCE:
      return { ...state, sms_balance: action.payload }
    case EWB_BALANCE:
      return { ...state, ewb_balance: action.payload }
    default:
      return state
  }
}
