import {
  isblank,
  DatetoDMY,
  replaceBRtoNewLine,
  replaceDropBoxUrl,
  replaceAllFn,
  replacefunction,
  amount_formatDecimal,
  checkRenderCondition,
  checkRenderConditionPrint,
  numForCalc,
  checkRenderConditionV1,
  replaceAllFnPrint,
} from "./utilityFunctions"
import {
  addBlank,
  divideArrayInColumn,
  findNode,
  generatecustomFieldBody,
  generatecustomFieldBodyV3,
  generatecustomFieldBodyV5,
  generateDetailValues,
  generateDetailValuesMutiColumn,
  generateMasterCustomFieldsTable,
  generateMasterCustomFieldsTableV2,
  generateSignatureBoxes,
  getPrintValue,
  jnestedReplace,
  masterCustomFieldV2,
  removeTempField,
} from "./common"
import { generateDetailHeaders, masterCustomField } from "./common"
import defaultTemplateData from "./outwardDataTemplates/defaultTemplate"
import gatePassTemplate1Data from "./outwardDataTemplates/gatePassTemplate1"
import customFieldTemplate3 from "./outwardDataTemplates/customfieldsTemplate3"
import cF from "@project/sharedcomponents/constantData/customFieldsEnum"

export default ({ printdata, meta, pageSize, user_data, functions }) => {
  const { getAccountSitesetting, getAccountSessionUserData, getconfigsettingdata } = functions
  const dS = {
    Business_Type: getconfigsettingdata("company_status")["business_type"],
  }
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint", user_data)
  const outward_type_detail = getAccountSitesetting("outward_type_detail", user_data)
  var invoice_template = {}
  var title = ""
  var billno = printdata.master_table_data[0].billno

  let print_header = true
  if (meta && meta.otherDetail && meta.otherDetail.print_without_header === "1") {
    print_header = false
  }

  /* All Company Details */
  const company_data = getPrintValue(printdata, "company_data")
  var companyName = company_data.company_name
  var company_url = getAccountSessionUserData("company_url")
  let photo_base_url = getAccountSessionUserData("photo_base_url")
  var company_address1 = company_data.company_address1
  var gstin = company_data.company_gstin
  var company_email = company_data.company_email
  var pan = company_data.company_pan
  var aadhaar = company_data.company_aadhaar_no
  var company_address2 = getPrintValue(printdata, "company_address2")
  var companyContact = getPrintValue(printdata, "company_contact")
  var companyOtherNumbers = getPrintValue(printdata, "company_other_no")
  var companyLogo = getPrintValue(printdata, "company_logo")

  /* All Party Details */
  const party_data = getPrintValue(printdata, "party_data")
  var party_name = replacefunction(party_data.party_name)
  var party_address1 = party_data.party_address1
  var party_gstin = party_data.party_gstin
  var party_email = party_data.party_email
  var party_pan = party_data.party_pan
  var party_aadhaar = party_data.party_aadhaar_no
  var party_address2 = getPrintValue(printdata, "new_party_address")
  var partyContact = getPrintValue(printdata, "party_contact")

  /* Other Details */
  var outwardDate = DatetoDMY(printdata.master_table_data[0].b_date)
  var MasterCustomField = printdata.custom_fields_data_master
  var preparedby = !isblank(printdata.createdUser) && printdata.createdUser.length > 0 ? printdata.createdUser[0].fullname : ""
  var agent = !isblank(printdata.agent_data) && printdata.agent_data.length > 0 ? printdata.agent_data[0].name : ""
  var transport = !isblank(printdata.transport_data) && printdata.transport_data.length > 0 ? printdata.transport_data[0].name : ""
  var lrno = !isblank(printdata.master_table_data[0].lrno) ? printdata.master_table_data[0].lrno : ""
  var lrdate = !isblank(printdata.master_table_data[0].lr_date) ? DatetoDMY(printdata.master_table_data[0].lr_date) : ""
  var godown = !isblank(printdata.master_table_data[0].godown_name) ? printdata.master_table_data[0].godown_name : ""
  var narration = !isblank(printdata.master_table_data[0].narration) ? replacefunction(replaceBRtoNewLine(printdata.master_table_data[0].narration)) : ""
  var ewaybill_no = !isblank(printdata.master_table_data[0].ewaybill_no) ? printdata.master_table_data[0].ewaybill_no : ""
  var vehicleNo = !isblank(printdata.master_table_data[0].vehicleNo) ? printdata.master_table_data[0].vehicleNo : ""
  var invoice_type = meta.invoice_type
  var DetailCustomField = printdata.custom_fields_data
  let outward_print_template = ""

  var Print_company_logo = 0
  var Print_company_name = 0
  var Print_company_address = 0
  var Print_party_name = 0
  var Print_party_address = 0
  var Print_contactDetail = 0
  var parameterized = false
  var invoice_title = ""
  var mainHeadingText = ""
  var Grid_Line = 0
  var Print_Detail_narration = 0
  var Print_hsn = 0
  var Print_uom = 0
  var Print_sku = 0
  var Print_transport = 0
  var Print_agent = 0
  var Print_godown = 0
  var Print_narration = 0
  let ewayBillDetail = 0
  var PageOrientation = "portrait"
  var pdfFontSizeSelectedValue = 8
  var multiColumnPrint = 0
  var columnGap = 90
  var noColumnPrint = 3
  var signBoxesOn = ""
  var signBoxes = []
  let termConditions = ""
  let footer_text = ""
  let page_top_margin = ""
  let page_bottom_margin = ""
  let page_left_margin = ""
  let page_right_margin = ""

  const outwardSiteSettings = outward_type_detail.filter((e) => e.id === meta.invoice_type)

  if (outwardSiteSettings.length > 0) {
    if (print_header) {
      Print_company_logo = parseInt(outwardSiteSettings[0].print_company_logo)
      Print_company_name = parseInt(outwardSiteSettings[0].print_company_name)
      Print_company_address = parseInt(outwardSiteSettings[0].print_company_address)
    } else {
      Print_company_logo = 0
      Print_company_name = 0
      Print_company_address = 0
    }

    outward_print_template = outwardSiteSettings[0].outward_print_template
    pdfFontSizeSelectedValue = outwardSiteSettings[0].pdfFontSizeSelectedValue
    title = outwardSiteSettings[0].name
    mainHeadingText = outwardSiteSettings[0].mainHeadingText
    invoice_title = outwardSiteSettings[0].headingText
    Print_Detail_narration = parseInt(outwardSiteSettings[0].detailNarration)
    Print_hsn = parseInt(outwardSiteSettings[0].hsnSelectedValue)
    Print_uom = parseInt(outwardSiteSettings[0].unit_print)
    Print_sku = parseInt(outwardSiteSettings[0].printSKUNO)
    Print_narration = parseInt(outwardSiteSettings[0].invoicePrintNarrationSelectedValue)
    Print_agent = parseInt(outwardSiteSettings[0].showAgent)
    Print_godown = parseInt(outwardSiteSettings[0].showGodown)
    Print_transport = parseInt(outwardSiteSettings[0].showTransport)
    PageOrientation = outwardSiteSettings[0].page_orientation
    Print_party_name = parseInt(outwardSiteSettings[0].print_party_name)
    Print_party_address = parseInt(outwardSiteSettings[0].print_party_address)
    Print_contactDetail = parseInt(outwardSiteSettings[0].contactDetail)
    multiColumnPrint = parseInt(outwardSiteSettings[0].multiColumnPrint) === 1 ? true : false
    noColumnPrint = parseInt(outwardSiteSettings[0].noColumnPrint)
    signBoxesOn = parseInt(outwardSiteSettings[0].signature_box)
    signBoxes = outwardSiteSettings[0].__hidden_boxes
    termConditions = outwardSiteSettings[0].termConditions
    footer_text = outwardSiteSettings[0].footer_text

    if (!isblank(outwardSiteSettings[0].__cust_TermAndCondition)) {
      termConditions = outwardSiteSettings.__cust_TermAndCondition
    }

    if (isblank(mainHeadingText)) {
      mainHeadingText = title
    }
    if (isblank(invoice_title)) {
      invoice_title = ""
    }
    parameterized = parseInt(outwardSiteSettings[0].parameterizedQty) === 1 ? true : false
    Grid_Line = parseInt(outwardSiteSettings[0].grid_line)

    page_top_margin = outwardSiteSettings[0].header_top_margin ? parseFloat(outwardSiteSettings[0].header_top_margin) : 15
    page_bottom_margin = outwardSiteSettings[0].page_bottom_margin ? parseFloat(outwardSiteSettings[0].page_bottom_margin) : 15
    page_left_margin = outwardSiteSettings[0].page_left_margin ? parseFloat(outwardSiteSettings[0].page_left_margin) : 15
    page_right_margin = outwardSiteSettings[0].page_right_margin ? parseFloat(outwardSiteSettings[0].page_right_margin) : 15
  }

  let fromProcess = []
  let processName = ""
  if (meta.invoice_type === "job_inward") {
    printdata.details_table_data.forEach((e) => {
      if (!isblank(e.processName)) {
        if (!isblank(processName)) {
          if (processName.indexOf(e.processName) < 0) {
            processName = `${processName}, ${e.processName}`
          }
        } else {
          processName = e.processName
        }
      }
    })
  }

  if (outward_print_template === "0") {
    printdata.template_setting.setting = defaultTemplateData.setting
    printdata.template_setting.header = defaultTemplateData.header
    printdata.template_setting.footer = defaultTemplateData.footer
    printdata.template_setting.body = defaultTemplateData.body
    printdata.template_setting.bodyHeader = defaultTemplateData.bodyHeader
    printdata.template_setting.bodyFooter = defaultTemplateData.bodyFooter
    printdata.template_setting.style = defaultTemplateData.style
  } else if (outward_print_template === "1") {
    printdata.template_setting.setting = gatePassTemplate1Data.setting
    printdata.template_setting.body = gatePassTemplate1Data.body
    printdata.template_setting.bodyHeader = gatePassTemplate1Data.bodyHeader
    printdata.template_setting.bodyFooter = gatePassTemplate1Data.bodyFooter
    printdata.template_setting.header = gatePassTemplate1Data.header
    printdata.template_setting.footer = gatePassTemplate1Data.footer
    printdata.template_setting.style = gatePassTemplate1Data.style
  } else if (outward_print_template === "2") {
    printdata.template_setting.setting = customFieldTemplate3.setting
    printdata.template_setting.body = customFieldTemplate3.body
    printdata.template_setting.bodyHeader = customFieldTemplate3.bodyHeader
    printdata.template_setting.bodyFooter = customFieldTemplate3.bodyFooter
    printdata.template_setting.header = customFieldTemplate3.header
    printdata.template_setting.footer = customFieldTemplate3.footer
    printdata.template_setting.style = customFieldTemplate3.style
  } else {
    printdata.template_setting.setting = defaultTemplateData.setting
    printdata.template_setting.header = defaultTemplateData.header
    printdata.template_setting.footer = defaultTemplateData.footer
    printdata.template_setting.body = defaultTemplateData.body
    printdata.template_setting.bodyHeader = defaultTemplateData.bodyHeader
    printdata.template_setting.bodyFooter = defaultTemplateData.bodyFooter
    printdata.template_setting.style = defaultTemplateData.style
  }

  /* template setting according to page size and orientation */

  const customFieldBorder = !isblank(printdata.template_setting.setting["customFieldBorder"]) ? printdata.template_setting.setting["customFieldBorder"] : true
  const logo_height = pageSize === "A4" ? printdata.template_setting.setting["logo_height_a4"] : printdata.template_setting.setting["logo_height_a5"]
  const logo_width = pageSize === "A4" ? printdata.template_setting.setting["logo_width_a4"] : printdata.template_setting.setting["logo_width_a5"]
  const header_right_section_width =
    pageSize === "A4" && PageOrientation === "portrait"
      ? printdata.template_setting.setting["header_right_section_width_a4_P"]
      : pageSize === "A5" && PageOrientation === "portrait"
        ? printdata.template_setting.setting["header_right_section_width_a5_P"]
        : pageSize === "A4" && PageOrientation === "landscape"
          ? printdata.template_setting.setting["header_right_section_width_a4_L"]
          : printdata.template_setting.setting["header_right_section_width_a5_L"]
  const footer_column_gap =
    pageSize === "A4" && PageOrientation === "portrait"
      ? printdata.template_setting.setting["footer_column_gap_a4_P"]
      : pageSize === "A5" && PageOrientation === "portrait"
        ? printdata.template_setting.setting["footer_column_gap_a5_P"]
        : pageSize === "A4" && PageOrientation === "landscape"
          ? printdata.template_setting.setting["footer_column_gap_a4_L"]
          : printdata.template_setting.setting["footer_column_gap_a5_L"]

  let images = {}

  let tot_pcs = printdata.master_table_data[0].__cust_pcs
  let __cust_rowcount = printdata.master_table_data[0].__cust_rowcount
  if (isblank(tot_pcs)) {
    tot_pcs = ""
  }

  MasterCustomField.filter((e) => parseInt(e.dataType) === cF.Photo && e.showInPrint === "1").forEach((e, index) => {
    if (!isblank(printdata.master_table_data[0][e.name])) {
      images[`${printdata.master_table_data[0][e.name]}`] = `${photo_base_url}${printdata.master_table_data[0][e.name]}`
    }
  })

  MasterCustomField.filter((e) => parseInt(e.dataType) === cF.Dropbox && e.showInPrint === "1").forEach((e, index) => {
    if (!isblank(printdata.master_table_data[0][e.name])) {
      printdata.master_table_data[0][e.name].forEach((i, iindex) => {
        images[i.name] = replaceDropBoxUrl(i.link) //"data:image/jpeg;base64," + i.linkBase64Data
      })
    }
  })

  var rowValue = {}
  if (dS.Business_Type === "Wood" && !isblank(printdata) && !isblank(printdata.details_table_data) && printdata.details_table_data.length > 0) {
    rowValue = printdata.details_table_data[0]
  }

  /* SET MASTER CUSTOM FIELD RENDER CONDITION */
  MasterCustomField = MasterCustomField.filter(function (item) {
    // return checkRenderConditionPrint(item, printdata.master_table_data[0])
    return checkRenderConditionV1({ f: item, data: printdata.master_table_data[0], other_type: invoice_type, rowValue, dS, print_flag: true })
  })

  /* SET DETAIL CUSTOM FIELD RENDER CONDITION */
  DetailCustomField = DetailCustomField.filter(function (item) {
    // return checkRenderConditionPrint(item, printdata.master_table_data[0])
    return checkRenderConditionV1({ f: item, data: printdata.master_table_data[0], other_type: invoice_type, rowValue, dS, print_flag: true })
  })

  if (dS.Business_Type === "Wood") {
    if (!isblank(rowValue) && rowValue.__cust_ply_height && parseInt(rowValue.__cust_ply_height) > 0 && parseInt(rowValue.__cust_actual_height) === 0) {
      DetailCustomField = DetailCustomField.filter(
        (e) =>
          !(
            e.name === "__cust_width" ||
            e.name === "__cust_height" ||
            e.name === "__cust_actual_height" ||
            e.name === "__cust_actual_width" ||
            e.name === "__cust_flat_no" ||
            e.name === "__cust_door_thikness"
          )
      )
    } else {
      DetailCustomField = DetailCustomField.filter((e) => !(e.name === "__cust_ply_height" || e.name === "__cust_ply_thk" || e.name === "__cust_ply_width"))
    }
  }

  /* CUSTOM FIELDS FOR Demo wood */

  if (dS.Business_Type === "Wood") {
    if (invoice_type === "delivery_challan") {
      /* ADDING SQUARE FEET*/
      functions.PrintSQF = true
      printdata.details_table_data = printdata.details_table_data.map((e, index) => {
        const obj = { ...e }
        if (numForCalc(obj.__cust_actual_height) > 0 && numForCalc(obj.__cust_actual_width) > 0) {
          obj.SQF = amount_formatDecimal((numForCalc(obj.__cust_actual_height) / 1000) * (numForCalc(obj.__cust_actual_width) / 1000) * 10.764 * e.qty, 2)
        } else {
          obj.SQF = 0
        }
        return obj
      })

      printdata.details_table_data = printdata.details_table_data.sort((a, b) => {
        /* if (a.__cust_flat_no === b.__cust_flat_no) {
          return a.__cust_area < b.__cust_area ? -1 : 1
        } else {
          return a.__cust_flat_no < b.__cust_flat_no ? -1 : 1
        } */

        if (a.__cust_area === b.__cust_area) {
          return a.__cust_flat_no < b.__cust_flat_no ? -1 : 1
        } else {
          return a.__cust_area < b.__cust_area ? -1 : 1
        }
      })
    }

    if (getconfigsettingdata("company_status")["business_type"] === "Wood") {
      if (!isblank(printdata.master_table_data[0].invoice_type) && printdata.master_table_data[0].invoice_type === "delivery_challan") {
        functions.PrintHWT = true
      } else {
        if (!isblank(printdata.master_table_data[0].__cust_product_type_code) && printdata.master_table_data[0].__cust_product_type_code === "16") {
          functions.PrintHWT = true
        }
      }
    }
  }

  var HeaderObject = generateDetailHeaders({
    functions,
    SiteSettings: outwardSiteSettings,
    DetailCustomField,
    type: "outward",
    pageSize,
    Print_Detail_narration,
    Print_hsn,
    Print_sku,
    printdata,
    Print_uom,
  })
  var Headers = HeaderObject.Headers
  var widthArray = HeaderObject.widthArray
  var Values = ""
  if (multiColumnPrint) {
    Values = generateDetailValuesMutiColumn({ functions, SiteSettings: outwardSiteSettings, printdata, meta, noColumnPrint, pageSize, PageOrientation })
  } else {
    Values = generateDetailValues({
      functions,
      SiteSettings: outwardSiteSettings,
      DetailCustomField,
      DetailGrid: printdata.details_table_data,
      MasterData: printdata.master_table_data[0],
      parameterized,
      type: "outward",
      user_data,
      Print_Detail_narration,
      Print_hsn,
      Print_sku,
      Print_uom,
    })
  }
  let head_String = printdata.template_setting.header
  head_String = replaceAllFn(head_String, "{center_text}", mainHeadingText)
  head_String = replaceAllFn(head_String, "{left_text}", invoice_title)
  head_String = replaceAllFn(head_String, "{right_text}", "")

  let headerString = printdata.template_setting.bodyHeader

  headerString = replaceAllFn(headerString, "{logo}", companyLogo)
  headerString = replaceAllFn(headerString, "{companyName}", companyName)
  headerString = replaceAllFn(headerString, "{company_address1}", replacefunction(company_address1))
  headerString = replaceAllFn(headerString, "{company_address2}", replacefunction(company_address2))
  headerString = replaceAllFn(headerString, "{company_contact}", companyContact)
  headerString = replaceAllFn(headerString, "{company_gstin}", gstin)
  headerString = replaceAllFn(headerString, "{company_email}", company_email)
  headerString = replaceAllFn(headerString, "{companyOtherNumbers}", companyOtherNumbers)
  headerString = replaceAllFn(headerString, "{companyPAN}", pan)
  headerString = replaceAllFn(headerString, "{company_aadhaar}", aadhaar)

  headerString = replaceAllFn(headerString, "{partyName}", party_name)
  headerString = replaceAllFn(headerString, "{party_address1}", replacefunction(party_address1))
  headerString = replaceAllFn(headerString, "{party_address2}", replacefunction(party_address2))
  headerString = replaceAllFn(headerString, "{party_gstin}", party_gstin)
  headerString = replaceAllFn(headerString, "{party_pan}", party_pan)
  headerString = replaceAllFn(headerString, "{party_aadhaar}", party_aadhaar)
  headerString = replaceAllFn(headerString, "{party_contact}", partyContact)
  headerString = replaceAllFn(headerString, "{party_email}", party_email)

  headerString = replaceAllFn(headerString, "{title}", title)
  headerString = replaceAllFn(headerString, "{billno}", billno)
  headerString = replaceAllFn(headerString, "{outwardDate}", outwardDate)
  headerString = replaceAllFn(headerString, "{processName}", processName)
  headerString = replaceAllFn(headerString, "{transport}", transport)
  headerString = replaceAllFn(headerString, "{lr_date}", lrdate)
  headerString = replaceAllFn(headerString, "{lr_no}", lrno)
  headerString = replaceAllFn(headerString, "{agent}", agent)
  headerString = replaceAllFn(headerString, "{godown}", godown)
  headerString = replaceAllFn(headerString, "{narration}", narration)
  headerString = replaceAllFn(headerString, "{ewaybill_no}", ewaybill_no)
  headerString = replaceAllFn(headerString, "{vehicle_no}", vehicleNo)

  /* LOGO HEIGHT WIDTH */
  headerString = replaceAllFn(headerString, '"{logo_width}"', logo_width)
  headerString = replaceAllFn(headerString, '"{logo_height}"', logo_height)
  headerString = replaceAllFn(headerString, '"{header_right_section_width}"', header_right_section_width)

  let fieldsArray = [
    {
      field_id: "company_aadhaar",
      value: aadhaar,
    },
    {
      field_id: "company_gstin",
      value: gstin,
    },
    {
      field_id: "company_pan",
      value: pan,
    },
    {
      field_id: "company_contact",
      value: companyContact,
    },
    {
      field_id: "company_email",
      value: company_email,
    },
    {
      field_id: "company_OtherNumbers",
      value: companyOtherNumbers,
    },
    {
      field_id: "party_aadhaar",
      value: party_aadhaar,
    },
    {
      field_id: "party_gstin",
      value: party_gstin,
    },
    {
      field_id: "party_pan",
      value: party_pan,
    },
    {
      field_id: "party_contact",
      value: partyContact,
    },
  ]

  if (meta.invoice_type !== "job_inward") {
    fieldsArray.push({
      field_id: "process_info",
      value: "",
    })
  }

  if (Print_company_logo !== 1 || isblank(companyLogo)) {
    fieldsArray.push({
      field_id: "company_logo",
      value: "",
      width: 0,
    })
  }

  if (Print_company_name !== 1) {
    fieldsArray.push({
      field_id: "company_name",
      value: "",
    })
  }

  if (Print_company_address !== 1) {
    fieldsArray.push({
      field_id: "company_address",
      value: "",
    })
  }

  if (Print_party_name !== 1) {
    fieldsArray.push({
      field_id: "party_name",
      value: "",
    })
  }

  if (Print_party_address !== 1) {
    fieldsArray.push({
      field_id: "party_address",
      value: "",
    })
  }

  if (Print_contactDetail !== 1) {
    fieldsArray.push({
      field_id: "party_contact",
      value: "",
    })
  }

  if (Print_party_name !== 1 && Print_party_address !== 1 && Print_contactDetail !== 1) {
    fieldsArray.push({
      field_id: "billing_address_block",
      value: "",
    })
  }

  headerString = removeTempField(headerString, fieldsArray)

  /* if (meta.invoice_type !== "job_inward") {
    headerString = JSON.parse(headerString)
    headerString = jnestedReplace(headerString, "field_id", "process_info", {}, "")
    headerString = JSON.stringify(headerString)
  } */

  if (Print_narration !== 1 || isblank(narration)) {
    headerString = JSON.parse(headerString)
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "narration", newValue: {} })
    headerString = JSON.stringify(headerString)
  }

  if (Print_agent !== 1) {
    headerString = JSON.parse(headerString)
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "agent_details", newValue: {} })
    headerString = JSON.stringify(headerString)
  }

  if (Print_godown !== 1) {
    headerString = JSON.parse(headerString)
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "godown_details", newValue: {} })
    headerString = JSON.stringify(headerString)
  }

  if (Print_transport !== 1) {
    headerString = JSON.parse(headerString)
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "trans_details", newValue: {} })
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "lr_details", newValue: {} })
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "eway_details", newValue: {} })
    headerString = JSON.stringify(headerString)
  }

  let customFieldTable = []

  /* headerString = JSON.parse(headerString)
  const customFieldStructure = findNode(headerString, "field_id", "customFieldNew") */
  let customFieldBody = {}
  var CustomFieldArr = masterCustomFieldV2(MasterCustomField, printdata.master_table_data[0]) //get label and value

  var objQty = {
    label: "Tot Qty",
    value: amount_formatDecimal(printdata.master_table_data[0].totqty, inventory_decimalpoint),
  }
  if (dS.Business_Type === "Wood" && !isblank(printdata) && !isblank(printdata.master_table_data) && printdata.master_table_data.length > 0) {
    if (printdata.master_table_data[0]["__cust_product_type_code"] === "14") {
      //door
      CustomFieldArr.unshift(objQty)
    }
  } else {
    CustomFieldArr.unshift(objQty)
  }

  if (__cust_rowcount) {
    var objBundle = {
      label: "Tot Bundle",
      value: __cust_rowcount,
    }
    CustomFieldArr.unshift(objBundle)
  }

  if (outward_print_template === "1") {
    headerString = JSON.parse(headerString)
    const customFieldStructure = findNode(headerString, "field_id", "customFieldNew")
    customFieldBody = generatecustomFieldBodyV3(customFieldStructure, CustomFieldArr)
    if (customFieldBody.length > 0) {
      headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "customFieldNew", newValue: customFieldBody, replacePath: customFieldStructure.replacePath })
    } else {
      headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "customFieldNew", newValue: {} })
    }
    headerString = JSON.stringify(headerString)
  } else if (outward_print_template === "2" || outward_print_template === "0") {
    headerString = JSON.parse(headerString)
    const customFieldStructure = findNode(headerString, "field_id", "customFieldNew")
    customFieldBody = generatecustomFieldBodyV5(customFieldStructure, CustomFieldArr, customFieldBorder)

    if (customFieldBody.length > 0) {
      headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "customFieldNew", newValue: customFieldBody, replacePath: customFieldStructure.replacePath })
    } else {
      headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "customFieldNew", newValue: {} })
    }
    headerString = JSON.stringify(headerString)
  } else {
    const customFieldStructure = generateMasterCustomFieldsTableV2(MasterCustomField, printdata)
    headerString = JSON.parse(headerString)
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "customFieldNew", newValue: customFieldStructure })
    headerString = JSON.stringify(headerString)
  }

  let customSignBoxes = {}
  if (!isblank(signBoxesOn) && signBoxesOn !== 0 && !isblank(signBoxes)) {
    customSignBoxes = generateSignatureBoxes(signBoxes)
  }

  let FooterText = {}
  if (!isblank(footer_text)) {
    FooterText = { text: footer_text, alignment: "center", margin: [0, 5, 0, 5] }
  }

  let footer_String = printdata.template_setting.bodyFooter
  footer_String = replaceAllFnPrint(footer_String, "{termsAndconditions}", replacefunction(termConditions))
  footer_String = replaceAllFn(footer_String, '"{columnGap}"', footer_column_gap)
  footer_String = replaceAllFn(footer_String, "{preparedby}", preparedby)

  if (isblank(termConditions)) {
    footer_String = JSON.parse(footer_String)
    footer_String = jnestedReplace({ input: footer_String, search_key: "field_id", searchValue: "terms", newValue: {} })
    footer_String = JSON.stringify(footer_String)
  }

  let contentValues = {}
  if (multiColumnPrint) {
    contentValues = Values
  } else {
    contentValues = {
      table: {
        headerRows: 1,
        widths: widthArray,
        body: [Headers, ...Values],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === 1 || i === Values.length || i === Values.length + 1 || Grid_Line === 1 || Grid_Line === 3 ? 1 : 0
        },
        vLineWidth: function (i, node) {
          return Grid_Line === 1 || Grid_Line === 0 ? 1 : 0
        },
        hLineColor: function (i, node) {
          return "black"
        },
      },
      margin: [0, 0, 0, 0],
    }
  }

  // topmargin +25 for printing header title
  let _pageMargin = [page_left_margin, page_top_margin + 25, page_right_margin, page_bottom_margin]

  var dd = {
    pageSize: pageSize,
    pageOrientation: PageOrientation,
    pageMargins: _pageMargin,
    header: JSON.parse(head_String),
    footer: function (currentPage, pageCount) {
      if (pageCount > 1) {
        return {
          columns: [
            {
              text: `Page ${currentPage} of ${pageCount}`,
              alignment: "center",
            },
          ],
          margin: [0, 0, 0, 20],
        }
      }
      return {}
    },
    content: [JSON.parse(headerString), contentValues, JSON.parse(footer_String), customSignBoxes, FooterText],
    images,
    defaultStyle: {
      fontSize: parseFloat(pdfFontSizeSelectedValue),
    },
    styles: printdata.template_setting.style,
  }
  return dd
}
