import { CircularProgress, CssBaseline, MuiThemeProvider } from "@material-ui/core"
import { responsiveFontSizes } from "@material-ui/core/styles"
import usePrevious from "@project/components/Hooks/usePrevious"
import MobileOnlyPlan from "@project/components/errorPages/mobileonlyplan"
import NotFound from "@project/components/errorPages/notFound"
import SiteMaintenance from "@project/components/errorPages/siteMaintenance"
import SubscriptionExpired from "@project/components/errorPages/subscriptionExpired"
import TrialExpired from "@project/components/errorPages/trialExpired"
import UpdateVersion from "@project/components/errorPages/updateVersion"
import DarkTheme from "@project/components/theme/DarkTheme"
import LightTheme from "@project/components/theme/LightTheme"
import { getCookie, setCookie } from "@project/components/utilityFunctions"
import { isblank } from "@project/sharedcomponents/utilityFunctions"
import React, { Suspense, lazy, useEffect, useRef } from "react"
import { HotKeys, configure } from "react-hotkeys"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes, useNavigate } from "react-router"
import { useLocation } from "react-router-dom"
import { checkalreadylogin, gotoPage, setLoginLoading, setSetting, shortCutHandle, snackbarstate } from "../../Actions"
import { LAST_PAGE_UPDATE, LOADING_SNACKBAR_STATE, LOADING_STATE } from "../../Actions/types"
import { getAccountSessionUserData, getAccountSitesetting, getCompanyLock, getMenuRightsByType, isVersionGreaterOrEqual, setNavigate } from "../../Lib/commonfunctions"
import SwitchCompany from "../ModalPages/switchCompany"
import { TawkChat } from "../common/TawkChat"
import SwitchModule from "../errorPages/switchModule"
import Login from "../login"
import TwoFactorAuthentication from "../twoFactorAuthentication"
import { PrivateRoute } from "./PrivateRoute"
import Header from "./header"

const Welcome = lazy(() => import("../welcome"))
const Errortest = lazy(() => import("../errortest"))
const ProductSearch = lazy(() => import("../productSearch"))
const PaymentSuccess = lazy(() => import("../paymentSuccess"))
const UserRights = lazy(() => import("../manageRights"))
const SiteSettings = lazy(() => import("../siteSettings"))
const UpgradePlan = lazy(() => import("../upgradePlan"))
const CustomField = lazy(() => import("../ModalPages/customField"))

const NotificationPage = lazy(() => import("../ModalPages/notificationPage"))
const Pages = lazy(() => import("../pages"))
const FinancialReport = lazy(() => import("../financialReport"))
const MultiOutstanding = lazy(() => import("../financialReport/multiOutReport"))
const Utilities = lazy(() => import("../utilities"))
const Reports = lazy(() => import("../reports"))
const Dashboard = lazy(() => import("../dashboard"))
const TallyExport = lazy(() => import("../tallyExport"))
// const GetMyStoreUser = lazy(() => import("../getMyStorecontacts"))
// const ProductAttribute = lazy(() => import("../productAttribute"))
const AdjustBill = lazy(() => import("../adjustBill"))
const ManageOpeningBalance = lazy(() => import("../manageOpeningBalance"))
const ManageOpeningStock = lazy(() => import("../manageOpeningStock"))
const Organizations = lazy(() => import("../organizations"))
// const Signup = lazy(() => import("../signup"))
const OnBoarding = lazy(() => import("../onBoarding"))
const ManageClosingStock = lazy(() => import("../manageClosingStock"))
const ListPage = lazy(() => import("../common/ListPage"))
const ManagePartyPrice = lazy(() => import("../managePartyPrice"))
const ManagePartyGroupPrice = lazy(() => import("../managePartyGroupPrice"))
const Export = lazy(() => import("../export"))
const ImportUtility = lazy(() => import("../importUtility"))
const MergeAccountUtility = lazy(() => import("../mergeAccountUtility"))
const MergeCityUtility = lazy(() => import("../mergeCityUtility"))
const MergeGodownUtility = lazy(() => import("../mergeGodownUtility"))
const ChangePasswordUtility = lazy(() => import("../changePasswordUtility"))
const ImportBankStatement = lazy(() => import("../importBankStatement"))
// const GroupSMSUtility = lazy(() => import("../groupSMSUtility"))
// const SingleSMSUtility = lazy(() => import("../singleSMSUtility"))
const BillingUtility = lazy(() => import("../billingUtility"))
const TdsCategoryMaster = lazy(() => import("../tdsCategoryMaster"))
const SMSTemplateUtility = lazy(() => import("../smsTemplateUtility"))
const EmailTemplateUtility = lazy(() => import("../emailTemplateUtility"))
const NotificationTemplateUtility = lazy(() => import("../notificationTemplates"))
const WiretransferUtility = lazy(() => import("../wiretransferUtility"))
const StockCapitalUtility = lazy(() => import("../stockCapitalUtility"))
const ImportdataFBUtility = lazy(() => import("../importDataFBUtility"))
const UserHistoryUtility = lazy(() => import("../userHistoryUtility"))
const MergeItemUtility = lazy(() => import("../mergeItemUtility"))
const ChangeYearUtility = lazy(() => import("../changeYearUtility"))
const GSTR1 = lazy(() => import("../GSTPages/gstr1"))
const GSTR2 = lazy(() => import("../GSTPages/gstr2"))
const GSTR3b = lazy(() => import("../GSTPages/gstr3b"))
// const MonthlyGST = lazy(() => import("../GSTPages/monthlyGST"))
const GSTRSummary = lazy(() => import("../GSTPages/gstrsummary"))
// const AmendBills = lazy(() => import("../GSTPages/amendbills"))
// const GSTAnnualReport = lazy(() => import("../GSTPages/gstAnnualReport"))
const LotCostingReport = lazy(() => import("../LotCostingReport"))
const Invite = lazy(() => import("../invite"))
const EwayBill = lazy(() => import("../manageEwayBill"))
const AddExpensePayment = lazy(() => import("../ModalPages/addExpensePaymentForm"))
// const RecordExpense = lazy(() => import("../recordExpense"))
const ProcessKanbanView = lazy(() => import("../ProcessKanbanView"))
const GSTOldPendingInvoice = lazy(() => import("../gstOldPendingInvoice"))
const BankReconciliation = lazy(() => import("../BankReconciliation"))
const Webhooks = lazy(() => import("../ModalPages/Webhooks"))

const keyMap = {
  ALT: "alt",
  ADD_NEW_CUSTOMER: "shift+a",
  ADD_NEW_SUPPLIER: "shift+s",
  ADD_NEW_PRODUCT: "shift+d",
  ADD_NEW_OTHER_ACCOUNT: "shift+o",
  ADD_NEW_SALES: "shift+i",
  ADD_NEW_PURCHASE: "shift+u",
  ADD_NEW_RECEIPT: "shift+r",
  ADD_NEW_PAYMENT: "shift+p",
  ADD_NEW_JOURNAL_ENTRY: "shift+j",
  UTILITY_BARCODE: "shift+b",
  ADD: "alt+w",
  ENTER: "enter",
  EDIT: "alt+t",
  DELETE: "alt+d",
  PRINT: "alt+p",
  DOWNLOAD: "alt+o",
  SAVE: "alt+s",
}

function App({ isDisconnected = false, t }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const myDiv = useRef()
  const { lastpagedata, lastpagetype, lastpageaction, lastpagetime, drawerStack } = useSelector((state) => state.CommonReducer)
  const { islogin, checkloginloading } = useSelector((state) => state.AuthReducer)
  const [finalKeyMap, setFinalKeyMap] = React.useState({})
  const [darkmode, setDarkmode] = React.useState("light")
  const [announcementDetail, setAnnouncementDetail] = React.useState("")
  const [announcementId, setAnnouncementId] = React.useState("")
  const whatsapp_module = getAccountSitesetting("whatsapp_module")
  const [forceUpdate, setForceUpdate] = React.useState(false)
  const [appLatestVersion, setAppLatestVersion] = React.useState("")
  const companyName = getAccountSessionUserData("company_name")
  const fyear = getAccountSessionUserData("financial_year")
  const prevLastpagetime = usePrevious(lastpagetime)
  let location = useLocation()

  // Set the navigate function once
  setNavigate(navigate)

  useEffect(() => {
    var darkmode = "light"
    if (getCookie("darkmode") === "dark") {
      darkmode = "dark"
    } else {
      darkmode = "light"
    }
    document.body.classList.remove(darkmode === "light" ? "dark" : "light")
    document.body.classList.add(darkmode)
    setDarkmode(darkmode)

    if (window.location.pathname.indexOf("switchcompany") === -1) {
      dispatch(checkalreadylogin({ isloginPage: false }))
    } else {
      dispatch(setLoginLoading(false))
    }

    if (process.env.REACT_APP_NODE_ENV === "production") {
      if (!isblank(companyName)) {
        var company_url = getAccountSessionUserData("company_url")
        var email = getAccountSessionUserData("company_email")
        var username = getAccountSessionUserData("name")
        var mobile = getAccountSessionUserData("mobile")
        var company_phoneO = getAccountSessionUserData("company_phoneO")
        var company_phoneR = getAccountSessionUserData("company_phoneR")
        var financial_year = getAccountSessionUserData("financial_year")
        var companyDetail = {
          company_url,
          companyName,
          username,
          email,
          version: `v${process.env.REACT_APP_VERSION}`,
          mobile,
          company_phoneO,
          company_phoneR,
          financial_year,
        }
        TawkChat({ companyDetail })
      }
    }
  }, [])

  useEffect(() => {
    if (lastpagetype === "config_setting" && lastpageaction === "updated" && lastpagetime !== prevLastpagetime) {
      let latest_version = lastpagedata?.frontend_version
      let current_version = process.env.REACT_APP_VERSION
      let min_version = lastpagedata?.forceUpdate

      if (!isVersionGreaterOrEqual({ currentVersion: current_version, latestVersion: latest_version, minimumVersion: min_version })) {
        setForceUpdate(true)
        setAppLatestVersion(latest_version)
      }

      if (!isblank(lastpagedata.config_setting.subscription_day_left)) {
        setAnnouncementDetail({
          text: `Your Subscription period is about to expire in ${lastpagedata.config_setting.subscription_day_left} days. Renew your subscription for uninterrupted services or contact administrator.`,
          linkClick: gotoBilling,
          linkLabel: "View detail",
        })
      } else if (!isblank(lastpagedata.config_setting.trial_day_left)) {
        setAnnouncementDetail({
          announcementDetail: {
            text: `Your Trial is about to expire in ${lastpagedata.config_setting.trial_day_left} days. Renew your subscription for uninterrupted services or contact administrator.`,
            linkClick: gotoBilling,
            linkLabel: "View detail",
          },
        })
      } else if (!isblank(lastpagedata.announcement) && lastpagedata.announcement.length > 0) {
        var ancmntCloseId = getCookie("ancmntCloseId")
        if (ancmntCloseId !== lastpagedata.announcement[0].id) {
          setAnnouncementDetail({
            announcementDetail: {
              text: lastpagedata.announcement[0].description,
              closeButton: closeAnnouncement,
            },
          })
          setAnnouncementId(lastpagedata.announcement[0].id)
        }
      } else {
        setAnnouncementDetail("")
      }
    }
    if (lastpagetype === "login" && lastpageaction === "success" && lastpagetime !== prevLastpagetime) {
      var _finalKeyMap = {}
      Object.keys(keyMap).forEach((item) => {
        if (item === "ADD_NEW_SALES") {
          if (parseInt(getMenuRightsByType({ parent: "Invoice", name: "Sales Invoice" })[0], 10) === 1 && !getCompanyLock()) {
            _finalKeyMap[item] = keyMap[item]
          }
        } else if (item === "ADD_NEW_PURCHASE") {
          if (parseInt(getMenuRightsByType({ parent: "Invoice", name: "Purchase Invoice" })[0], 10) === 1 && !getCompanyLock()) {
            _finalKeyMap[item] = keyMap[item]
          }
        } else if (item === "ADD_NEW_RECEIPT") {
          if (parseInt(getMenuRightsByType({ parent: "Transaction", name: "Receipt" })[0], 10) === 1 && !getCompanyLock()) {
            _finalKeyMap[item] = keyMap[item]
          }
        } else if (item === "ADD_NEW_PAYMENT") {
          if (parseInt(getMenuRightsByType({ parent: "Transaction", name: "Payment" })[0], 10) === 1 && !getCompanyLock()) {
            _finalKeyMap[item] = keyMap[item]
          }
        } else if (item === "ADD_NEW_JOURNAL_ENTRY") {
          if (parseInt(getMenuRightsByType({ parent: "Transaction", name: "Journal Entry" })[0], 10) === 1 && !getCompanyLock()) {
            _finalKeyMap[item] = keyMap[item]
          }
        } else if (item === "ADD_NEW_CUSTOMER") {
          if (parseInt(getMenuRightsByType({ parent: "Masters", name: "Customers" })[0], 10) === 1 && !getCompanyLock()) {
            _finalKeyMap[item] = keyMap[item]
          }
        } else if (item === "ADD_NEW_SUPPLIER") {
          if (parseInt(getMenuRightsByType({ parent: "Masters", name: "Suppliers" })[0], 10) === 1 && !getCompanyLock()) {
            _finalKeyMap[item] = keyMap[item]
          }
        } else if (item === "ADD_NEW_PRODUCT") {
          if (parseInt(getMenuRightsByType({ parent: "Inventory", name: "Manage Product" })[0], 10) === 1 && !getCompanyLock()) {
            _finalKeyMap[item] = keyMap[item]
          }
        } else if (item === "ADD_NEW_OTHER_ACCOUNT") {
          if (parseInt(getMenuRightsByType({ parent: "Masters", name: "Manage Other Accounts" })[0], 10) === 1 && !getCompanyLock()) {
            _finalKeyMap[item] = keyMap[item]
          }
        } else {
          _finalKeyMap[item] = keyMap[item]
        }
      })

      setFinalKeyMap(_finalKeyMap)
    }
  }, [lastpagetype, lastpageaction, lastpagedata, lastpagetime, prevLastpagetime])

  useEffect(() => {
    if (whatsapp_module === "whatsapp_web") {
      const handleWhatsAppResponse = (e) => {
        dispatch({
          type: LOADING_STATE,
          state: false,
        })
        dispatch({
          type: LOADING_SNACKBAR_STATE,
          payload: false,
          message: "Sending Notifications",
        })
        if (e.detail.success) {
          dispatch(snackbarstate({ open: true, message: "WhatsApp sent succesfully", snackbarType: "success" }))
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "sendNotification",
            lastaction: "success",
            lasttime: new Date().getTime(),
          })
        } else {
          dispatch(snackbarstate({ open: true, message: "Unable to send WhatsApp. Restart WhatsApp Web and try again", snackbarType: "error" }))
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "sendNotification",
            lastaction: "error",
            lasttime: new Date().getTime(),
          })
        }
      }

      document.addEventListener("whatsappSendResponse", handleWhatsAppResponse)

      // Cleanup function to remove the event listener
      return () => {
        document.removeEventListener("whatsappSendResponse", handleWhatsAppResponse)
      }
    }
  }, [whatsapp_module])

  const handleNightModeChange = () => {
    var darkmode = getCookie("darkmode")
    if (darkmode === "") {
      darkmode = "dark"
    } else {
      darkmode = darkmode === "light" ? "dark" : "light"
    }
    document.body.classList.remove(darkmode === "light" ? "dark" : "light")
    document.body.classList.add(darkmode)
    setCookie({ name: "darkmode", value: darkmode })
    setDarkmode(darkmode)
  }

  const handleThemeChange = (theme) => {
    var darkmode = "light"
    if (theme.name === "Theme-1 Dark") {
      darkmode = "dark"
    } else {
      darkmode = "light"
    }
    setCookie({ name: "darkmode", value: darkmode })
    setDarkmode(darkmode)
  }

  const closeAnnouncement = () => {
    setCookie({ name: "ancmntCloseId", value: announcementId })
    setAnnouncementDetail("")
  }

  const gotoBilling = () => {
    dispatch(gotoPage({ key: "billings" }))
  }

  const handleShortCut = (shortCutkey) => {
    dispatch(shortCutHandle({ shortCutkey, drawerStack }))
  }

  if (forceUpdate) {
    return <UpdateVersion current_version={process.env.REACT_APP_VERSION} latest_version={appLatestVersion} />
  }
  if (checkloginloading) {
    return <CircularProgress disableShrink size={50} style={{ position: "fixed", top: "50%", left: "50%", zIndex: 100000001, marginLeft: "-25px", marginTop: "-25px" }} />
  }
  configure({
    ignoreTags: ["input", "select", "textarea"],
  })

  const handlers = {
    ADD_NEW_SALES: () => handleShortCut("ADD_NEW_SALES"),
    ALT: () => myDiv.current.focus(),
    EDIT: () => handleShortCut("EDIT"),
    DELETE: () => handleShortCut("DELETE"),
    PRINT: () => handleShortCut("PRINT"),
    DOWNLOAD: () => handleShortCut("DOWNLOAD"),
    SAVE: () => handleShortCut("SAVE"),
    ENTER: () => handleShortCut("ENTER"),
    ADD: () => handleShortCut("ADD"),
    ADD_NEW_PURCHASE: () => handleShortCut("ADD_NEW_PURCHASE"),
    ADD_NEW_RECEIPT: () => handleShortCut("ADD_NEW_RECEIPT"),
    ADD_NEW_PAYMENT: () => handleShortCut("ADD_NEW_PAYMENT"),
    ADD_NEW_JOURNAL_ENTRY: () => handleShortCut("ADD_NEW_JOURNAL_ENTRY"),
    ADD_NEW_CUSTOMER: () => handleShortCut("ADD_NEW_CUSTOMER"),
    ADD_NEW_SUPPLIER: () => handleShortCut("ADD_NEW_SUPPLIER"),
    ADD_NEW_PRODUCT: () => handleShortCut("ADD_NEW_PRODUCT"),
    ADD_NEW_OTHER_ACCOUNT: () => handleShortCut("ADD_NEW_OTHER_ACCOUNT"),
    UTILITY_BARCODE: () => handleShortCut("UTILITY_BARCODE"),
  }

  let theme = darkmode === "dark" ? DarkTheme : LightTheme
  theme = responsiveFontSizes(theme)

  return (
    <MuiThemeProvider theme={theme}>
      <HotKeys keyMap={finalKeyMap} handlers={handlers}>
        <div ref={myDiv} tabIndex="-1" style={{ outline: 0, height: "100vh" }}>
          <CssBaseline />
          <Header t={t} handleNightModeChange={handleNightModeChange} announcementDetail={announcementDetail} isDisconnected={isDisconnected} />
          <div style={{ height: islogin && location.pathname !== "/login" ? (!isblank(announcementDetail) ? 78 : 48) : 0 }}></div>
          <Suspense fallback={<CircularProgress disableShrink size={50} style={{ position: "fixed", top: "50%", left: "50%", zIndex: 100000001, marginLeft: "-25px", marginTop: "-25px" }} />}>
            <Routes>
              <Route element={<PrivateRoute />}>
                <Route exact path={`/${fyear}`} element={<Welcome t={t} />} />
                <Route exact path={`/${fyear}/welcome`} element={<Welcome t={t} />} />

                <Route exact path={`/${fyear}/sitesettings`} element={<SiteSettings announcementDetail={announcementDetail} handleThemeChange={handleThemeChange} setSetting={setSetting} />} />

                <Route exact path={`/${fyear}/userrights`} element={<UserRights />} />
                <Route exact path={`/${fyear}/customfield`} element={<CustomField />} />
                <Route exact path={`/${fyear}/webhook`} element={<Webhooks />} />

                <Route
                  exact
                  path={`/${fyear}/custompage`}
                  element={
                    <ListPage
                      componentId="custompage"
                      type="customPage"
                      menutitle="Custom Pages"
                      drawer="Add New Custom Page"
                      listpagetitle=""
                      catmenutitle="custompage"
                      norecordfound_title="All Custom Pages"
                      arrowBack="utilities"
                    />
                  }
                />

                <Route exact path={`/${fyear}/notification`} element={<NotificationPage />} />
                <Route exact path={`/${fyear}/adjustbill`} element={<AdjustBill />} />
                <Route exact path={`/${fyear}/tallyexport`} element={<TallyExport />} />
                <Route exact path={`/${fyear}/pages/:id/:name`} element={<Pages />} />
                <Route exact path={`/${fyear}/expensepayments`} element={<AddExpensePayment />} />
                {/* <Route exact path={`/${fyear}/recordexpenses`} element={<RecordExpense />} /> */}

                <Route
                  exact
                  path={`/${fyear}/payments`}
                  element={
                    <ListPage
                      componentId="payment"
                      type="payment"
                      menutitle="payment"
                      drawer="payment"
                      listpagetitle=""
                      catmenutitle="transaction"
                      norecordfound_title="Manage Payments"
                      uniqueKey="newCode"
                      mobile={true}
                    />
                  }
                />

                <Route exact path={`/${fyear}/bot`} element={<ListPage type="bot" componentId="bot" menutitle="bots" catmenutitle="master" drawer="Add New Bot" drawerWidth={950} />} />

                <Route exact path={`/${fyear}/productsearch`} element={<ProductSearch />} />

                <Route
                  exact
                  path={`/${fyear}/receipts`}
                  element={
                    <ListPage
                      componentId="receipt"
                      type="receipt"
                      menutitle="receipt"
                      drawer="receipt"
                      listpagetitle=""
                      catmenutitle="transaction"
                      norecordfound_title="Manage Receipts"
                      uniqueKey="newCode"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/journalentries`}
                  element={
                    <ListPage
                      componentId="journal"
                      type="journal"
                      menutitle="journal entry"
                      drawer="journal"
                      listpagetitle=""
                      catmenutitle="transaction"
                      norecordfound_title="Manage Journal Entries"
                      uniqueKey="newCode"
                      mobile={true}
                    />
                  }
                />

                <Route exact path={`/${fyear}/tdscategorymaster`} element={<TdsCategoryMaster />} />

                <Route
                  exact
                  path={`/${fyear}/process/mergepackingslip`}
                  element={
                    <ListPage
                      componentId="merge_packingSlip"
                      type="merge_packingSlip"
                      menutitle="merge packing slip"
                      drawer="Merge Packing Slip"
                      listpagetitle="All Merge Packing Slip"
                      catmenutitle="process"
                      norecordfound_title="Merge Packing Slip"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/purchaseinvoices/:other_type`}
                  element={
                    <ListPage
                      componentId="purchaseinvoice"
                      type="purchaseinvoice"
                      menutitle=""
                      drawer=""
                      listpagetitle=""
                      catmenutitle="invoice"
                      norecordfound_title="Manage Purchase Invoice"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/invoice/:other_type`}
                  element={
                    <ListPage componentId="salesinvoice" type="salesinvoice" menutitle="" drawer="" listpagetitle="" catmenutitle="invoice" norecordfound_title="Manage Sales Invoice" mobile={true} />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/inwards/:other_type`}
                  element={<ListPage componentId="inward" type="inward" menutitle="" drawer="" listpagetitle="" catmenutitle="invoice" norecordfound_title="Manage Inward" mobile={true} />}
                />

                <Route
                  exact
                  path={`/${fyear}/process/job-inward`}
                  element={
                    <ListPage
                      componentId="inward"
                      type="inward"
                      menutitle=""
                      other_type="job_inward"
                      drawer=""
                      listpagetitle=""
                      catmenutitle="invoice"
                      norecordfound_title="Manage Job Inward"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/process/finish-stock`}
                  element={
                    <ListPage
                      componentId="inward"
                      type="inward"
                      other_type="finish_stock"
                      menutitle=""
                      drawer=""
                      listpagetitle=""
                      catmenutitle="invoice"
                      norecordfound_title="Manage Finish Stock"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/outwards/:other_type`}
                  element={<ListPage componentId="outward" type="outward" menutitle="" drawer="" listpagetitle="" catmenutitle="invoice" norecordfound_title="Manage Outward" mobile={true} />}
                />

                <Route
                  exact
                  path={`/${fyear}/process/lotmaster`}
                  element={
                    <ListPage
                      componentId="outward"
                      other_type="manage_lot_master"
                      type="outward"
                      menutitle=""
                      drawer=""
                      listpagetitle=""
                      catmenutitle="invoice"
                      norecordfound_title="Manage Lot Master"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/creditnotes`}
                  element={
                    <ListPage
                      componentId="creditnote"
                      type="creditnote"
                      menutitle="credit note"
                      drawer="sales_return"
                      listpagetitle=""
                      catmenutitle="invoice"
                      norecordfound_title="Manage Credit Notes"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/debitnotes`}
                  element={
                    <ListPage
                      componentId="debitnote"
                      type="debitnote"
                      menutitle="debit note"
                      drawer="purchase_return"
                      listpagetitle=""
                      catmenutitle="invoice"
                      norecordfound_title="Manage Debit Notes"
                      mobile={true}
                    />
                  }
                />

                <Route exact path={`/${fyear}/ewaybills`} element={<EwayBill location={location} />} />

                <Route
                  exact
                  path={`/${fyear}/stocktransfer`}
                  element={
                    <ListPage
                      componentId="stockTransfer"
                      type="stockTransfer"
                      menutitle="Stock Transfer"
                      drawer="Stock Transfer"
                      listpagetitle="All Stock Transfer"
                      catmenutitle="invoice"
                      norecordfound_title="Manage Stock Transfer"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/godownstocktransfer`}
                  element={
                    <ListPage
                      componentId="stock_transferGodown"
                      type="stock_transferGodown"
                      menutitle="Godown Stock Transfer"
                      drawer="Godown Stock Transfer"
                      listpagetitle="All Godown Stock Transfer"
                      catmenutitle="inventory"
                      norecordfound_title="Manage Godown Stock Transfer"
                      mobile={true}
                    />
                  }
                />

                <Route exact path={`/${fyear}/dashboard`} element={<Dashboard />} />
                <Route exact path={`/${fyear}/utilities`} element={<Utilities />} />
                <Route exact path={`/${fyear}/reports`} element={<Reports componentId="reports" />} />

                <Route
                  exact
                  path={`/${fyear}/products`}
                  element={
                    <ListPage
                      componentId="item"
                      type="item"
                      menutitle="product"
                      drawer="Add New Item"
                      listpagetitle="All Products"
                      catmenutitle="inventory"
                      norecordfound_title="Manage Products"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/customers`}
                  element={
                    <ListPage
                      componentId="customerList"
                      type="customerList"
                      menutitle="customers"
                      drawer="Add New CustomerSupplier"
                      listpagetitle="All Customers"
                      catmenutitle="masters"
                      norecordfound_title="Manage Customers"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/states`}
                  element={
                    <ListPage
                      componentId="state"
                      type="state"
                      menutitle="state"
                      drawer="Add New State"
                      listpagetitle="All States"
                      catmenutitle="masters"
                      norecordfound_title="Manage State"
                      hideAddButton={true}
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/configurableproducts`}
                  element={
                    <ListPage
                      componentId="configurableproduct"
                      type="configurableproduct"
                      menutitle="configurable product"
                      drawer="configurableitem"
                      listpagetitle="All Configurable Products"
                      catmenutitle="inventory"
                      norecordfound_title="Manage Configurable Products"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/bundleproducts`}
                  element={
                    <ListPage
                      componentId="bundleproduct"
                      type="bundleproduct"
                      menutitle="bundle product"
                      drawer="bundleitem"
                      listpagetitle="All Bundle Products"
                      catmenutitle="inventory"
                      norecordfound_title="Manage Bundle Products"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/cities`}
                  element={
                    <ListPage componentId="city" type="city" menutitle="city" drawer="Add New City" listpagetitle="All Cities" catmenutitle="masters" norecordfound_title="Manage City" mobile={true} />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/discount-coupons`}
                  element={
                    <ListPage
                      componentId="discount-coupons"
                      type="city"
                      menutitle="Discount Coupons"
                      drawer="Add New Discount Coupon"
                      listpagetitle="All Coupons"
                      catmenutitle="utilities"
                      norecordfound_title="No Coupons found"
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/envelopeprinting`}
                  element={
                    <ListPage
                      componentId="envelopeprinting"
                      type="envelopePrinting"
                      menutitle="envelope"
                      drawer="Add New Envelope"
                      listpagetitle="All Envelope"
                      catmenutitle="utilities"
                      norecordfound_title="No record found"
                      arrowBack="utilities"
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/chequeprinting`}
                  element={
                    <ListPage
                      componentId="chequeprinting"
                      type="chequePrinting"
                      menutitle="Cheque"
                      drawer="Add New Cheque"
                      listpagetitle="All Cheque"
                      catmenutitle="utilities"
                      norecordfound_title="No Cheque found"
                      arrowBack="utilities"
                    />
                  }
                />

                {/* <Route exact path={`/${fyear}/catalogueusers`} element={<GetMyStoreUser />} /> */}
                <Route
                  exact
                  path={`/${fyear}/catalogueusers`}
                  element={
                    <ListPage
                      componentId="catalogueusers"
                      type="catalogueUser"
                      menutitle="contacts"
                      listpagetitle="All Contacts"
                      catmenutitle=""
                      norecordfound_title="Manage Contacts"
                      ButtonTwoDrawer="Send Notification"
                      ButtonTwoTitle="Send Notification To All"
                      hideAddButton={true}
                      accountType="getmystore"
                      drawerWidth={450}
                    />
                  }
                />

                {/* <Route exact path={`/${fyear}/productattributes`} element={<ProductAttribute   />} /> */}

                <Route
                  exact
                  path={`/${fyear}/accounts`}
                  element={
                    <ListPage
                      componentId="other_account"
                      type="other_account"
                      menutitle="manage accounts"
                      drawer="Add New Account"
                      listpagetitle="All Other Accounts"
                      catmenutitle="masters"
                      norecordfound_title="Manage Accounts"
                      ButtonTwoDrawer="Add New ImportAccount"
                      ButtonTwoTitle="Import Account"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/users`}
                  element={
                    <ListPage componentId="user" type="user" menutitle="users" drawer="Add New User" listpagetitle="All Users" catmenutitle="masters" norecordfound_title="Manage User" mobile={true} />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/units`}
                  element={
                    <ListPage
                      componentId="unit"
                      type="unit"
                      menutitle="import unit"
                      drawer="Add New ImportUnit"
                      listpagetitle="All Units"
                      catmenutitle="inventory"
                      norecordfound_title="Manage Units"
                      mobile={true}
                      AddButtonTitle="Import Unit"
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/productgroups`}
                  element={
                    <ListPage
                      componentId="itemgroup"
                      type="itemgroup"
                      menutitle="product group"
                      drawer="Add New Product Group"
                      listpagetitle="All Product Groups"
                      catmenutitle="inventory"
                      norecordfound_title="Manage Product Groups"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/transports`}
                  element={
                    <ListPage
                      componentId="transport"
                      type="transport"
                      menutitle="transport"
                      drawer="Add New Transport"
                      listpagetitle="All Transports"
                      catmenutitle="masters"
                      norecordfound_title="Manage Transports"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/godowns`}
                  element={
                    <ListPage componentId="godown" type="godown" menutitle="godown" drawer="Add New Godown" listpagetitle="All Godowns" catmenutitle="inventory" norecordfound_title="Manage Godowns" />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/companies`}
                  element={
                    <ListPage
                      componentId="company"
                      type="company"
                      menutitle="company"
                      listpagetitle="All Companies"
                      catmenutitle="masters"
                      norecordfound_title="Manage Companies"
                      hideAddButton={true}
                      uniqueKey="firm_id"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/agents`}
                  element={
                    <ListPage
                      componentId="agent"
                      type="agent"
                      menutitle="agent"
                      drawer="Add New Agent"
                      drawerWidth={650}
                      listpagetitle="All Agents"
                      catmenutitle="masters"
                      norecordfound_title="Manage Agent"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/salesmans`}
                  element={
                    <ListPage
                      componentId="salesman"
                      type="salesman"
                      menutitle="salesman"
                      drawer="Add New Salesman"
                      drawerWidth={650}
                      listpagetitle="All Salesman"
                      catmenutitle="masters"
                      norecordfound_title="Manage Salesman"
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/suppliers`}
                  element={
                    <ListPage
                      componentId="supplierList"
                      type="supplierList"
                      menutitle="suppliers"
                      drawer="Add New CustomerSupplier"
                      listpagetitle="All Suppliers"
                      catmenutitle=""
                      norecordfound_title="Manage Suppliers"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/taxschemes`}
                  element={
                    <ListPage
                      componentId="taxscheme"
                      type="taxscheme"
                      menutitle="import tax group"
                      drawer="Add New TaxScheme"
                      listpagetitle="All Tax Schemes"
                      catmenutitle="masters"
                      norecordfound_title="Manage Tax Schemes"
                      AddButtonTitle="Import Tax"
                      mobile={true}
                    />
                  }
                />

                <Route exact path={`/${fyear}/openingbalances`} element={<ManageOpeningBalance />} />
                <Route exact path={`/${fyear}/openingstock`} element={<ManageOpeningStock mobile={true} />} />
                <Route exact path={`/${fyear}/manageorganizations`} element={<Organizations />} />
                <Route exact path={`/${fyear}/closingstocks`} element={<ManageClosingStock mobile={true} />} />

                <Route
                  exact
                  path={`/${fyear}/partygroups`}
                  element={
                    <ListPage
                      componentId="partygroup"
                      type="partygroup"
                      menutitle="customer group"
                      drawer="Add New Customer Group"
                      listpagetitle="All Customer Group"
                      catmenutitle="masters"
                      norecordfound_title="Manage Party Groups"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/productsubgroups`}
                  element={
                    <ListPage
                      componentId="productsubgroup"
                      type="productsubgroup"
                      menutitle="product subgroup"
                      drawer="Add New Product Sub Group"
                      listpagetitle="All Product Subgroups"
                      catmenutitle="inventory"
                      norecordfound_title="Manage Product Subgroups"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/managesubgroup`}
                  element={
                    <ListPage
                      componentId="subgroup"
                      type="subgroup"
                      menutitle="subgroup"
                      drawer="Add New Sub Group"
                      listpagetitle="All Subgroups"
                      catmenutitle="master"
                      norecordfound_title="Manage SubGroups"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/productsubgroupslevel2`}
                  element={
                    <ListPage
                      componentId="productsubgrouplevel2"
                      type="productsubgrouplevel2"
                      menutitle="product subgroup level 2"
                      drawer="Add New Product Sub Group Level2"
                      listpagetitle="All Product Subgroups Level 2"
                      catmenutitle="inventory"
                      norecordfound_title="Manage Product Subgroup Level 2"
                      mobile={true}
                    />
                  }
                />

                <Route exact path={`/${fyear}/partypricelist`} element={<ManagePartyPrice />} />
                <Route exact path={`/${fyear}/partygroupprice`} element={<ManagePartyGroupPrice />} />

                <Route
                  exact
                  path={`/${fyear}/countries`}
                  element={
                    <ListPage
                      componentId="country"
                      type="country"
                      menutitle="country"
                      drawer="Import Country"
                      listpagetitle="All Countries"
                      catmenutitle="master"
                      norecordfound_title="Manage Country"
                      AddButtonTitle="Import Country"
                      mobile={true}
                    />
                  }
                />

                <Route exact path={`/${fyear}/export`} element={<Export />} />
                <Route exact path={`/${fyear}/import`} element={<ImportUtility />} />
                <Route exact path={`/${fyear}/mergeaccounts`} element={<MergeAccountUtility />} />
                <Route exact path={`/${fyear}/mergecity`} element={<MergeCityUtility />} />
                <Route exact path={`/${fyear}/mergegodown`} element={<MergeGodownUtility />} />
                <Route exact path={`/${fyear}/changepassword`} element={<ChangePasswordUtility />} />
                <Route exact path={`/${fyear}/importbankstatement`} element={<ImportBankStatement />} />
                {/* <Route exact path={`/${fyear}/groupsms`} element={<GroupSMSUtility />} /> */}
                {/* <Route exact path={`/${fyear}/sendsms`} element={<SingleSMSUtility />} /> */}
                <Route exact path={`/${fyear}/smstemplate`} element={<SMSTemplateUtility />} />
                <Route exact path={`/${fyear}/emailtemplates`} element={<EmailTemplateUtility />} />
                <Route exact path={`/${fyear}/notification-templates`} element={<NotificationTemplateUtility />} />
                <Route exact path={`/${fyear}/billings`} element={<BillingUtility />} />
                <Route exact path={`/${fyear}/paymentsuccess`} element={<PaymentSuccess />} />
                <Route exact path={`/${fyear}/wiretransfers`} element={<WiretransferUtility />} />
                <Route exact path={`/${fyear}/stockcapital`} element={<StockCapitalUtility />} />
                <Route exact path={`/${fyear}/importdatafb`} element={<ImportdataFBUtility />} />
                <Route exact path={`/${fyear}/changeyear`} element={<ChangeYearUtility />} />
                <Route exact path={`/${fyear}/userhistories`} element={<UserHistoryUtility />} />
                <Route exact path={`/${fyear}/mergeproducts`} element={<MergeItemUtility />} />
                <Route exact path={`/${fyear}/onboarding`} element={<OnBoarding />} />
                <Route exact path={`/${fyear}/invite`} element={<Invite />} />

                <Route
                  exact
                  path={`/${fyear}/costcenter`}
                  element={
                    <ListPage
                      componentId="costcenter"
                      type="costcenter"
                      menutitle="cost center"
                      drawer="Add New Cost Center"
                      listpagetitle="All Cost Centers"
                      catmenutitle="master"
                      norecordfound_title="Manage Cost Center"
                      mobile={true}
                    />
                  }
                />

                <Route exact path={`/${fyear}/report/gst-report/gstr-1-summary`} componentId="reports" element={<GSTR1 />} />
                <Route exact path={`/${fyear}/report/process-reports/lot-costing-report`} componentId="reports" element={<LotCostingReport location={location} />} />
                <Route exact path={`/${fyear}/report/gst-report/gstr-2-summary`} componentId="reports" element={<GSTR2 />} />
                {/* <Route exact path={`/${fyear}/report/gst-report/gst-annual-computation`} componentId="reports" element={<GSTAnnualReport location={location} />} /> */}
                <Route exact path={`/${fyear}/report/gst-report/gstr-3b-summary`} componentId="reports" element={<GSTR3b />} />
                {/* <Route exact path={`/${fyear}/report/gst-report/monthly-payment-for-quarterly-return`} componentId="reports" element={<MonthlyGST location={location} />} /> */}
                <Route exact path={`/${fyear}/report/gst-report/gst-summary`} componentId="reports" element={<GSTRSummary />} />
                {/* <Route exact path={`/${fyear}/report/gst-report/amend-bills-report`} componentId="reports" element={<AmendBills location={location} />} /> */}
                <Route exact path={`/${fyear}/report/:category/multi-outstanding-report`} componentId="multioutreports" element={<MultiOutstanding />} />
                <Route exact path={`/${fyear}/report/:category/multi-outstanding-ageing-report`} componentId="multioutageingreports" element={<MultiOutstanding />} />
                <Route exact path={`/${fyear}/report/:category/:name`} componentId="reports" element={<FinancialReport />} />

                <Route
                  exact
                  path={`/${fyear}/labeldesign`}
                  element={
                    <ListPage
                      componentId="labeldesign"
                      type="label_design"
                      menutitle="barcode printing"
                      drawer="Add Label Design"
                      listpagetitle="All Label Design"
                      catmenutitle="utilities"
                      norecordfound_title="No record found"
                      arrowBack="utilities"
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/pagetemplate`}
                  element={
                    <ListPage
                      componentId="page template"
                      type="label_setting"
                      menutitle="Barcode Printing"
                      drawer="Add Page Template"
                      listpagetitle="Label Page Templates"
                      catmenutitle="utilities"
                      norecordfound_title="No record found"
                      arrowBack="utilities"
                      ButtonTwoDrawer="Import Template"
                      ButtonTwoTitle="Import Template"
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/process/transfer`}
                  element={
                    <ListPage
                      componentId="lot_transfer"
                      type="lot_transfer"
                      menutitle="manage transfer"
                      drawer="Add New Process Transfer"
                      listpagetitle="All Process Transfer"
                      catmenutitle="process"
                      norecordfound_title="Manage Process Transfer"
                      mobile={true}
                    />
                  }
                />

                <Route
                  exact
                  path={`/${fyear}/process`}
                  element={
                    <ListPage
                      componentId="process"
                      type="process"
                      menutitle="process"
                      drawer="Add New Process"
                      listpagetitle="All Process"
                      catmenutitle="process"
                      norecordfound_title="Manage Process"
                      mobile={true}
                    />
                  }
                />

                <Route exact path={`/${fyear}/process/dashboard`} element={<ProcessKanbanView />} />
                <Route exact path={`/${fyear}/gstoldpendinginvoices`} element={<GSTOldPendingInvoice />} />
                <Route exact path={`/${fyear}/bankreconciliation`} element={<BankReconciliation />} />
              </Route>

              <Route exact path="/sitemaintenance" element={<SiteMaintenance />} />
              <Route exact path="/authentication" element={<TwoFactorAuthentication />} />
              <Route exact path="/errortest" element={<Errortest />} />
              <Route exact path="/notfound" element={<NotFound />} />
              <Route exact path="/upgrade" element={<UpgradePlan />} />
              <Route exact path="/subscriptionexpired" element={<SubscriptionExpired />} />
              <Route exact path="/trialexpired" element={<TrialExpired />} />
              <Route exact path="/upgradeplan" element={<MobileOnlyPlan />} />
              <Route exact path="/switchmodule/:id" element={<SwitchModule />} />
              <Route exact path="/login" element={<Login isDisconnected={isDisconnected} />} />
              <Route exact path="/switchcompany/:id" element={<SwitchCompany />} />
              <Route exact path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </div>
      </HotKeys>
    </MuiThemeProvider>
  )
}

export default App
