import { CONFIRM_DIALOG, GSTIN_SERVICE_UPDATED, LAST_PAGE_UPDATE, LOADING_STATE, POP_OVER_LOADING_STATE, SNACKBAR_STATE } from "./types"
import { list_url, post_form_url } from "@project/sharedcomponents/apiURL"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import { convertIntif, getAxiosCancelToken, getCatchErrorMessage, isblank, setAxiosCancelToken, validateGSTIN } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getAccountSitesetting, getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import { pushDrawer, updateDrawer } from "./CommonActionsV2"

export const post_gst_form_data = ({ dispatch, formtype, formaction, formdata, othertype, otherDetail, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    if (formaction !== "") {
      const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)

      dispatch({ type: LOADING_STATE, state: true })
      if (formaction === "update" && formtype === "update_gstin") {
        dispatch({ type: POP_OVER_LOADING_STATE, state: true })
      }
      let data = new FormData()
      data.append("type", formtype)
      data.append("action", formaction)
      data.append("form_data", formdata2)
      data.append("other_type", othertype)
      axios.defaults.headers.common = getheaders()
      axios({
        method: "post",
        url: post_form_url({ formtype, props: { getAccountSitesetting } }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
        cancelToken: getAxiosCancelToken().token,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          if (response.data.meta.code === 401) {
            postServiceFail({ dispatch, response: response.data.meta.message, formdata, othertype })
            dispatch({ type: LOADING_STATE, state: false })
          } else {
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "gst_detail_new",
              lastaction: "update",
              lasttime: new Date().getTime(),
              lastdata: {
                formdata,
                formtype,
              },
            })
            postServiceSuccess({ dispatch, user: response, formtype, formaction, formdata, othertype, otherDetail })
            dispatch({ type: LOADING_STATE, state: false })
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            dispatch({ type: LOADING_STATE, state: false })
          } else {
            postErrorOnException(error)
            postServiceFail({ dispatch, response: getCatchErrorMessage(error), formdata, othertype })
            dispatch({ type: LOADING_STATE, state: false })
          }
        })
    }
  }
}

export const get_gst_detail = ({ from_date, to_date, column, type, row, report_id, rowdata, drawerIndex, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("flag", 1)
    data.append("from_date", from_date)
    data.append("to_date", to_date)
    axios.defaults.headers.common = getheaders()

    if (column === "Placeof_Supply") {
      data.append("type", type)
      data.append("stateid", row.Stateid)
    } else {
      data.append("type", row.btype)
    }
    data.append("report_id", report_id)
    data.append("limit", "All")
    data.append("other_type", "detail")
    data.append("offset", 0)
    data.append("sort", "desc")
    data.append("sort_by", "b_date")
    axios({
      method: "post",
      url: list_url({ type: "getAllReport" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          rowdata.newrequest = { from_date, to_date, column, type, row, report_id, rowdata }
          if (!isblank(drawerIndex)) {
            updateDrawer({ title: "GST Detail", data: response.data.result, fieldname: rowdata, dispatch, index: drawerIndex })
          } else {
            pushDrawer({ title: "GST Detail", data: response.data.result, fieldname: rowdata, position: "bottom", dispatch })
          }
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const validatePartyGSTIN = ({ form_data, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    const formdata2 = Array.isArray(form_data) ? form_data.map(JSON.stringify) : JSON.stringify(form_data)

    let data = new FormData()
    data.append("form_data", formdata2)
    data.append("type", "checkPartyGstin")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "checkPartyGstin" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        dispatch({ type: LOADING_STATE, state: false })
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        }
        if (response.data.meta.code === 403) {
          dispatch({
            type: CONFIRM_DIALOG,
            payload: true,
            confirm: false,
            message: "",
            title: "Message",
            form: "Error List",
            meta: response.data.meta.message,
          })
        } else {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const postServiceFail = ({ dispatch, response, formdata, othertype }) => {
  dispatch({
    type: LAST_PAGE_UPDATE,
    lasttype: "gst_detail_new",
    lastaction: "error",
    lasttime: new Date().getTime(),
  })

  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })
}

const postServiceSuccess = ({ dispatch, user, formtype, formaction, formdata, othertype, otherDetail }) => {
  if (othertype === "59") {
    dispatch({
      type: GSTIN_SERVICE_UPDATED,
      payload: formdata,
      action_type: formtype,
      other_type: othertype,
    })
  } else {
    dispatch({
      type: SNACKBAR_STATE,
      payload: true,
      message: user.data.meta.message,
      snackbarType: "success",
    })
  }
  if (formtype === "update_gstin") {
    if (!isblank(otherDetail) && otherDetail.onComplete) {
      otherDetail.onComplete()
      dispatch({ type: POP_OVER_LOADING_STATE, state: false })
    }
  }
}

export const getGstDetail = ({ functions, onSuccess, onError }) => {
  const { update_code } = functions
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    dispatch({ type: LOADING_STATE, state: true })
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    let formdata = { update_code: update_code }
    const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
    let data = new FormData()
    data.append("type", "track_gst_return_api")
    data.append("action", "update")
    data.append("form_data", formdata2)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "getGstDetail" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          onError(response.data.meta.message)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          onSuccess(response.data.result)
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_STATE, state: false })
        if (axios.isCancel(error)) {
        } else {
          postErrorOnException(error)
          onError(getCatchErrorMessage(error))
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        }
      })
  }
}

export const fetchfromGST = ({ gstin, onSuccess, onError, url, mode = "", ddcity = [], dd_country_state = [], functions }) => {
  return (dispatch) => {
    if (isblank(gstin)) {
      dispatch({
        type: SNACKBAR_STATE,
        payload: true,
        message: ErrorMessages["E00219"],
        snackbarType: "error",
      })
    } else if (!validateGSTIN(gstin)) {
      dispatch({
        type: SNACKBAR_STATE,
        payload: true,
        message: ErrorMessages["E00004"],
        snackbarType: "error",
      })
    } else {
      var formdata = {}
      formdata.gstin = gstin
      const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
      const apiURL = !isblank(url) ? url : "/user/manage_master"
      if (typeof getAxiosCancelToken() != typeof undefined) {
        getAxiosCancelToken().cancel("Operation canceled due to new request.")
      }
      // save the new request for cancellation
      setAxiosCancelToken(axios.CancelToken.source())
      dispatch({ type: LOADING_STATE, state: true })
      let data = new FormData()
      if (!isblank(url)) {
        data.append("gstin", gstin)
        if (mode === "onboard") {
          data.append("verify", 1)
        }
      } else {
        data.append("type", "get_partygstaddress")
        data.append("action", "insert")
        data.append("form_data", formdata2)
        data.append("offset", 0)
        data.append("limit", "All")
      }

      axios.defaults.headers.common = getheaders()
      axios({
        method: "post",
        url: process.env.REACT_APP_BASE_URL + apiURL,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
        cancelToken: getAxiosCancelToken().token,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          if (response.data.meta.code === 401) {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: response.data.meta.message,
              snackbarType: "error",
            })

            onError(response.data.meta.message)
            dispatch({
              type: LOADING_STATE,
              sidebarstate: false,
            })
          } else {
            let data = response.data.result
            const countryState = dd_country_state.filter((e) => convertIntif(e.id) === 103)

            data.address.forEach((address) => {
              const stateObj = countryState[0].states.find((f) => f.name.toLowerCase() === address.state.toLowerCase())
              address.state_code = stateObj ? stateObj.code : ""
              if (mode !== "onboard") {
                const cityObj = ddcity.find((i) => i.name.toLowerCase() === address.city.toLowerCase())
                address.city_code = cityObj ? cityObj.code : ""
              } else {
                address.city_code = address.city
              }
            })

            if (mode !== "onboard") {
              const registration_status = data.registration_status === "Regular" ? 1 : data.registration_status === "Composition" ? 2 : 6
              data.gst_status_code = registration_status
            }

            onSuccess(data)
            dispatch({
              type: LOADING_STATE,
              sidebarstate: false,
            })
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            dispatch({ type: LOADING_STATE, state: false })
          } else {
            postErrorOnException(error)
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: ErrorMessages["E00107"],
              snackbarType: "error",
            })

            dispatch({
              type: LOADING_STATE,
              sidebarstate: false,
            })
          }
        })
    }
  }
}
