import { generateDetailHeaders, generateDetailValues, masterCustomField } from "./common"
import { DatetoDMY, isblank, replaceBRtoNewLine, replacefunction } from "./utilityFunctions"

export default ({ printdata, meta, pageSize, user_data, functions }) => {
  const { getAccountSitesetting, getAccountSessionUserData } = functions

  const currency = getAccountSitesetting("currency", user_data, "symbol")
  const siteSetting = getAccountSitesetting(meta.type, user_data).filter((e) => e.id === meta.invoice_type)
  var invoice_template = {}
  var billno = printdata.master_table_data[0].billno
  var billDate = DatetoDMY(printdata.master_table_data[0].b_date)
  //var crordb_no = '';
  //var crordb_date = '';
  var narration = ""
  /*if (meta.other_type === 'salesreturn') {
        crordb_no = printdata.master_table_data[0].creditnote_no;
        crordb_date = DatetoDMY(printdata.master_table_data[0].creditnote_date);
    }
    else if (meta.other_type === 'purchasereturn') {
        crordb_no = printdata.master_table_data[0].debitnote_no;
        crordb_date = DatetoDMY(printdata.master_table_data[0].debitnote_date);
    }*/
  var PartyName = printdata.user_details[0].name
  var agent = !isblank(printdata.agent_data) && printdata.agent_data.length > 0 ? printdata.agent_data[0].name : ""
  var transport = !isblank(printdata.transport_data) && printdata.transport_data.length > 0 ? printdata.transport_data[0].name : ""
  var lrno = !isblank(printdata.master_table_data[0].lrno) ? printdata.master_table_data[0].lrno : ""
  var lrdate = !isblank(printdata.master_table_data[0].lr_date) ? DatetoDMY(printdata.master_table_data[0].lr_date) : ""
  var crdays = !isblank(printdata.master_table_data[0].crdays) && printdata.master_table_data[0].crdays !== "0" ? printdata.master_table_data[0].crdays : ""
  var godown = !isblank(printdata.master_table_data[0].godown_name) ? printdata.master_table_data[0].godown_name : ""
  var MasterCustomField = printdata.custom_fields_data_master
  var invoice_type = meta.invoice_type
  var DetailCustomField = printdata.custom_fields_data
  var companyName = getAccountSessionUserData("company_name", user_data)
  var companyMobile = getAccountSessionUserData("mobile", user_data)
  var companyPhone = getAccountSessionUserData("company_phoneO", user_data)
  var companyContact = ""

  var parameterized = false
  var Grid_Line = 0
  var title = ""
  // var invoice_title = '';
  var termConditions = ""
  var Print_copy = 1
  var Print_narration = 0
  var mainHeadingText = ""
  var Print_Detail_narration = 0
  var Print_transport = 0
  var Print_agent = 0
  var contactDetail = 0
  // var Print_hsn = '0';
  if (siteSetting.length > 0) {
    title = siteSetting[0].name
    // invoice_title = getAccountSitesetting(meta.type, user_data).filter(e => e.id === meta.invoice_type)[0].headingText;
    termConditions = siteSetting[0].termConditions
    parameterized = parseInt(siteSetting[0].parameterizedQty) === 1 ? true : false
    if (meta.type === "invoice_type_detail") {
      Print_copy = parseInt(siteSetting[0].invoicePrintCopiesSelectedValue)
    }
    Print_narration = parseInt(siteSetting[0].invoicePrintNarrationSelectedValue)
    // Print_hsn = getAccountSitesetting(meta.type, user_data).filter(e => e.id === meta.invoice_type)[0].hsnSelectedValue;
    Grid_Line = parseInt(siteSetting[0].grid_line)
    Print_transport = parseInt(siteSetting[0].showTransport)
    Print_Detail_narration = parseInt(siteSetting[0].detailNarration)
    Print_agent = parseInt(siteSetting[0].showAgent)
    contactDetail = parseInt(siteSetting[0].contactDetail)
    mainHeadingText = siteSetting[0].mainHeadingText
  }

  if (isblank(mainHeadingText)) {
    mainHeadingText = "INVOICE"
  }

  if (!isblank(companyPhone)) {
    companyContact = `Contact: ${companyPhone}`
  }
  if (!isblank(companyMobile)) {
    if (!isblank(companyContact)) {
      companyContact = `${companyContact}, ${companyMobile}`
    } else {
      companyContact = `Contact: ${companyMobile}`
    }
  }

  var city = ""
  var cityData = ""
  cityData = printdata.city_data.find((item) => parseInt(item.city_id) === parseInt(printdata.user_details[0].city_id))

  if (!isblank(cityData)) {
    city = cityData.city
  }

  var state = ""
  var stateData = ""
  stateData = printdata.state_data.find((item) => parseInt(item.sid) === parseInt(printdata.user_details[0].state_id))
  if (!isblank(stateData)) {
    state = stateData.state
  }

  //Billing Address
  var Address = replacefunction(replaceBRtoNewLine(printdata.user_details[0].address))
  if (!isblank(printdata.user_details[0].areacode)) {
    Address = Address + ", " + printdata.user_details[0].areacode
  }
  if (!isblank(city)) {
    if (isblank(Address)) {
      Address = Address + city
    } else {
      Address = Address + ", " + city
    }
  }
  if (!isblank(state)) {
    if (isblank(Address)) {
      Address = Address + state
    } else {
      Address = Address + ", " + state
    }
  }
  if (!isblank(printdata.user_details[0].pincode) && !isblank(Address)) {
    Address = Address + " - " + printdata.user_details[0].pincode
  }

  //Shipping Address

  var Shipp_Address = ""
  var shipp_city = ""
  var shipp_state = ""
  var shipp_state_id = ""
  var shipp_address = !isblank(printdata.master_table_data[0].shipp_address) ? JSON.parse(printdata.master_table_data[0].shipp_address) : ""
  if (!isblank(shipp_address)) {
    Shipp_Address = replacefunction(replaceBRtoNewLine(shipp_address.address))
    if (!isblank(shipp_address.areacode)) {
      Shipp_Address = Shipp_Address + ", " + shipp_address.areacode
    }
    if (!isblank(shipp_address.cityname)) {
      if (isblank(Shipp_Address)) {
        Shipp_Address = Shipp_Address + shipp_address.cityname
      } else {
        Shipp_Address = Shipp_Address + ", " + shipp_address.cityname
      }
    }
    if (!isblank(shipp_address.statename)) {
      if (isblank(Shipp_Address)) {
        Shipp_Address = Shipp_Address + shipp_address.statename
      } else {
        Shipp_Address = Shipp_Address + ", " + shipp_address.statename
      }
    }
    if (!isblank(shipp_address.pincode) && !isblank(Shipp_Address)) {
      Shipp_Address = Shipp_Address + " - " + shipp_address.pincode
    }
    shipp_city = shipp_address.cityname
    shipp_state = shipp_address.statename
    shipp_state_id = shipp_address.stateid
  }

  var Party_Contact = !isblank(printdata.user_details[0].mobile) ? printdata.user_details[0].mobile : printdata.user_details[0].phone
  if (meta.invoice_type === "getmystore") {
    Party_Contact = !isblank(printdata.catalogueapp_user) ? printdata.catalogueapp_user.mobile : ""
  }
  if (!isblank(Party_Contact) && contactDetail === 1) {
    Party_Contact = {
      columns: [
        {
          width: "auto",
          text: "Contact: ",
          margin: [0, 0, 5, 0],
        },
        {
          width: "*",
          text: Party_Contact,
        },
      ],
    }
  } else {
    Party_Contact = {}
  }

  if (!isblank(shipp_city)) {
    shipp_city = {
      columns: [
        {
          text: `${shipp_city}, ${shipp_state}`,
          margin: [0, 0, 5, 0],
        },
      ],
    }
  } else {
    shipp_city = {}
  }

  if (!isblank(shipp_state)) {
    shipp_state = {
      columns: [
        {
          width: "auto",
          text: "State: ",
          margin: [0, 0, 5, 0],
        },
        {
          width: "*",
          text: `${shipp_state} (${shipp_state_id})`,
          bold: true,
        },
      ],
    }
  } else {
    shipp_state = {}
  }

  var Party_gst = !isblank(printdata.user_details[0].gstin) ? printdata.user_details[0].gstin : ""
  if (!isblank(Party_gst)) {
    Party_gst = {
      columns: [
        {
          width: "auto",
          text: "GSTIN/UIN: ",
          margin: [0, 0, 5, 0],
        },
        {
          width: "*",
          text: Party_gst,
        },
      ],
    }
  } else {
    Party_gst = {}
  }

  var Party_pan = !isblank(printdata.user_details[0].pano) ? printdata.user_details[0].pano : ""
  if (!isblank(Party_pan)) {
    Party_pan = {
      columns: [
        {
          width: "auto",
          text: "PAN No: ",
          margin: [0, 0, 5, 0],
        },
        {
          width: "*",
          text: Party_pan,
        },
      ],
    }
  } else {
    Party_pan = {}
  }

  var Party_Email = !isblank(printdata.user_details[0].email) ? printdata.user_details[0].email : ""
  var PartyName_GetMyStore = PartyName
  if (meta.invoice_type === "getmystore") {
    Party_Email = !isblank(printdata.catalogueapp_user) ? printdata.catalogueapp_user.email : ""
    if (!isblank(printdata.catalogueapp_user) && !isblank(printdata.catalogueapp_user.name)) {
      PartyName_GetMyStore = printdata.catalogueapp_user.name
    }
  }
  if (!isblank(Party_Email) && contactDetail === 1) {
    Party_Email = {
      columns: [
        {
          width: "auto",
          text: "Email: ",
          margin: [0, 0, 5, 0],
        },
        {
          width: "*",
          text: Party_Email,
        },
      ],
    }
  } else {
    Party_Email = {}
  }

  var OtherDetailArray = []
  var CustomFieldArr = masterCustomField({ customField: MasterCustomField, Values: printdata.master_table_data[0], alignment: "left" })
  if (!isblank(CustomFieldArr) && CustomFieldArr.length > 0) {
    CustomFieldArr.forEach((item) => {
      OtherDetailArray.push(item)
    })
  }
  if (!isblank(crdays)) {
    crdays = {
      columns: [
        {
          text: "Payment Terms: ",
        },
        {
          text: crdays,
        },
      ],
    }
    OtherDetailArray.push(crdays)
  } else {
    crdays = {
      columns: [
        {
          text: "Payment Terms: ",
        },
        {
          text: "On Receipt",
          bold: true,
        },
      ],
    }
    OtherDetailArray.push(crdays)
  }

  if (Print_transport === 1 && !isblank(transport)) {
    transport = {
      columns: [
        {
          text: "Transport: ",
        },
        {
          text: transport,
        },
      ],
    }
    OtherDetailArray.push(transport)
    if (!isblank(lrno)) {
      lrno = {
        columns: [
          {
            text: "Lr No: ",
          },
          {
            text: lrno,
          },
        ],
      }
      OtherDetailArray.push(lrno)
    }
    if (!isblank(lrdate)) {
      lrdate = {
        columns: [
          {
            text: "Lr Date: ",
          },
          {
            text: lrdate,
          },
        ],
      }
      OtherDetailArray.push(lrdate)
    }
  } else {
    transport = {}
  }

  if (!isblank(agent) && Print_agent === 1) {
    agent = {
      columns: [
        {
          text: "Agent: ",
        },
        {
          text: agent,
        },
      ],
    }
    OtherDetailArray.push(agent)
  } else {
    agent = {}
  }

  if (!isblank(godown)) {
    godown = {
      columns: [
        {
          text: "Godown: ",
        },
        {
          text: godown,
        },
      ],
    }
    OtherDetailArray.push(godown)
  } else {
    godown = {}
  }

  var due_Date = DatetoDMY(printdata.master_table_data[0].due_date)

  if (!isblank(due_Date)) {
    due_Date = {
      columns: [
        {
          text: "Due Date: ",
        },
        {
          text: due_Date,
          bold: true,
        },
      ],
    }
    OtherDetailArray.push(due_Date)
  } else {
    due_Date = {}
  }

  narration = printdata.master_table_data[0].narration
  if (!isblank(narration) && Print_narration === 1) {
    narration = [
      {
        text: "Narration",
        style: "",
        bold: true,
      },
      {
        text: narration,
        style: "invoiceCompanyAddress",
      },
    ]
  } else {
    narration = []
  }

  if (!isblank(termConditions)) {
    termConditions = [
      {
        text: "Terms & Conditions",
        style: "",
        bold: true,
      },
      {
        text: termConditions,
        fontSize: 7,
      },
    ]
  } else {
    termConditions = []
  }

  //Original Copy

  var HeaderObject = generateDetailHeaders({ functions, DetailCustomField, type: "sales_invoice", placeof_supply_id: printdata.placeof_supply_id, template: "template2", pageSize, Print_Detail_narration })
  var Headers = HeaderObject.Headers
  var widthArray = HeaderObject.widthArray
  var Values = generateDetailValues({
    functions,
    DetailCustomField,
    DetailGrid: printdata.details_table_data,
    MasterData: printdata.master_table_data[0],
    parameterized,
    type: "sales_invoice",
    placeof_supply_id: printdata.placeof_supply_id,
    template: "template2",
    user_data,
    Print_Detail_narration
  })

  //Duplicate Copy
  var HeaderObject1 = generateDetailHeaders({ functions, DetailCustomField, type: "sales_invoice", placeof_supply_id: printdata.placeof_supply_id, template: "template2", pageSize, Print_Detail_narration })
  var Headers1 = HeaderObject1.Headers
  var widthArray1 = HeaderObject1.widthArray
  var Values1 = generateDetailValues({
    functions,
    DetailCustomField,
    DetailGrid: printdata.details_table_data,
    MasterData: printdata.master_table_data[0],
    parameterized,
    type: "sales_invoice",
    placeof_supply_id: printdata.placeof_supply_id,
    template: "template2",
    user_data,
    Print_Detail_narration
  })

  //Triplicate Copy
  var HeaderObject2 = generateDetailHeaders({ functions, DetailCustomField, type: "sales_invoice", placeof_supply_id: printdata.placeof_supply_id, template: "template2", pageSize, Print_Detail_narration })
  var Headers2 = HeaderObject2.Headers
  var widthArray2 = HeaderObject2.widthArray
  var Values2 = generateDetailValues({
    functions,
    DetailCustomField,
    DetailGrid: printdata.details_table_data,
    MasterData: printdata.master_table_data[0],
    parameterized,
    type: "sales_invoice",
    placeof_supply_id: printdata.placeof_supply_id,
    template: "template2",
    user_data,
    Print_Detail_narration
  })

  invoice_template.headerheight = 60
  invoice_template.topheader = {}
  invoice_template.header = {}
  invoice_template.body = {}
  invoice_template.footer = {}

  var footerDetail = {}
  var footerData = []
  if (!isblank(printdata.footerdetail) && printdata.footerdetail.length > 0) {
    printdata.footerdetail.forEach((item, index) => {
      var obj = [
        {
          text: item[0],
          margin: [0, -2, 0, -2],
          bold: index === 0 || index === printdata.footerdetail.length - 1,
          alignment: "right",
          fontSize: index === 0 || index === printdata.footerdetail.length - 1 ? 9 : 8,
        },
        {
          text: `${currency}${item[1]}`,
          margin: [0, -2, 0, -2],
          bold: index === 0 || index === printdata.footerdetail.length - 1,
          alignment: "right",
          fontSize: index === 0 || index === printdata.footerdetail.length - 1 ? 9 : 8,
        },
      ]
      footerData.push(obj)
    })
  }
  footerDetail.table = {
    widths: ["*", "*"],
    body: [...JSON.parse(JSON.stringify(footerData))],
  }
  footerDetail.border = [false, false, false, false]

  var content1 = {
    style: "tableExample",
    table: {
      //heights: [invoice_template.bottomheaderheight, invoice_template.bottomheaderheight],
      heights: ["*", "*"],
      widths: ["*", "*"],
      body: [
        [
          {
            stack: [
              {
                text: "From",
                bold: true,
              },
              {
                text: companyName,
                bold: true,
                fontSize: 11,
              },
            ],
            border: [false, true, false, false],
            style: "headerCompanyInfo",
          },
          {
            stack: [
              {
                text: "To",
                bold: true,
              },
              {
                text: meta.invoice_type === "getmystore" ? PartyName_GetMyStore : PartyName,
                bold: true,
                fontSize: 11,
              },
              {
                text: Shipp_Address,
              },
              Party_Contact,
              Party_Email,
            ],
            border: [false, true, false, false],
            style: "headerCompanyInfo",
          },
        ],
      ],
    },
    margin: [0, 0, 0, 0],
  }

  var content2 = {
    table: {
      headerRows: 1,
      widths: widthArray,
      body: [Headers, ...Values],
    },
    layout: {
      hLineWidth: function (i, node) {
        return i === 0 || i === 1 || i === node.table.body.length || (Grid_Line === 1 && i !== node.table.body.length) ? 1 : 0
      },
      vLineWidth: function (i, node) {
        return 1
      },
    },
  }

  var content22 = {
    table: {
      headerRows: 1,
      widths: widthArray1,
      body: [Headers1, ...Values1],
    },
    layout: {
      hLineWidth: function (i, node) {
        return i === 0 || i === 1 || i === node.table.body.length || (Grid_Line === 1 && i !== node.table.body.length) ? 1 : 0
      },
      vLineWidth: function (i, node) {
        return 1
      },
    },
  }

  var content23 = {
    table: {
      headerRows: 1,
      widths: widthArray2,
      body: [Headers2, ...Values2],
    },
    layout: {
      hLineWidth: function (i, node) {
        return i === 0 || i === 1 || i === node.table.body.length || (Grid_Line === 1 && i !== node.table.body.length) ? 1 : 0
      },
      vLineWidth: function (i, node) {
        return 1
      },
    },
  }

  var content3 = {
    table: {
      widths: ["*", "*"],
      body: [
        [
          {
            stack: [
              ...OtherDetailArray,
              ...narration,
              {
                text: "Note",
                style: "",
                bold: true,
              },
              {
                text: "Thank you for choosing our product.",
                style: "invoiceCompanyAddress",
              },
              {
                text: "We believe you will be satisfied by our services.",
                style: "invoiceCompanyAddress",
              },
            ],
            border: [false, false, false, false],
            style: "",
          },
          footerDetail,
        ],
      ],
    },
    margin: [-5, 10, -5, 0],
  }

  var finalContent = [JSON.parse(JSON.stringify(content1)), content2, JSON.parse(JSON.stringify(content3))]

  if (Print_copy === 2 || Print_copy === 3) {
    finalContent.push({ text: "", pageBreak: "after" })
    finalContent.push(JSON.parse(JSON.stringify(content1)))
    finalContent.push(content22)
    finalContent.push(JSON.parse(JSON.stringify(content3)))
  }
  if (Print_copy === 3) {
    finalContent.push({ text: "", pageBreak: "after" })
    finalContent.push(JSON.parse(JSON.stringify(content1)))
    finalContent.push(content23)
    finalContent.push(JSON.parse(JSON.stringify(content3)))
  }
  var dd = {
    // a string or { width: number, height: number }
    pageSize: pageSize,
    // by default we use portrait, you can change it to landscape if you wish
    pageOrientation: "portrait",
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [15, invoice_template.headerheight, 15, 20],

    header: function (currentPage, pageCount) {
      // var rightText = "";
      var Count = 0
      if (Print_copy === 2) {
        Count = pageCount / 2
        if (currentPage <= Count) {
          // rightText = 'Original';
        } else if (currentPage <= Count * 2) {
          // rightText = 'Duplicate';
        }
      } else if (Print_copy === 3) {
        Count = pageCount / 3
        if (currentPage <= Count) {
          //  rightText = 'Original';
        } else if (currentPage <= Count * 2) {
          //  rightText = 'Duplicate';
        } else if (currentPage <= Count * 3) {
          //  rightText = 'Triplicate';
        }
      }

      var headerObj = [
        {
          columns: [
            { text: mainHeadingText, style: "documentHeaderLeft", bold: true },
            { text: `${title}: #${billno}\nDate: ${billDate}`, style: "documentHeaderBill", bold: true },
          ],
        },
      ]
      return headerObj
    },
    footer: [
      {
        columns: [{ text: "THIS IS A COMPUTER GENERATED INVOICE", style: "documentFooterCenter" }],
      },
    ],
    content: finalContent,
    defaultStyle: {
      fontSize: 8,
    },
    styles: {
      invoiceCompanySign: {
        height: 100,
      },
      termsandCondition: {
        fontSize: 7,
      },
      headerTopInfo: {
        margin: [5, 5, 5, 5],
        fontSize: 10,
      },
      center: {
        alignment: "center",
      },
      right: {
        alignment: "right",
      },
      left: {
        alignment: "left",
      },
      // Invoice Header
      headerLogo: {
        margin: [5, 5, 5, 5],
      },
      masterCustomFields: {
        margin: [0, 0, 0, 10],
      },
      headerCompanyInfo: {
        margin: [5, 5, 5, 5],
      },
      headerInvoiceDetails: {
        margin: [5, 5, 5, 5],
      },
      companyAddress: {
        alignment: "center",
        fontSize: 10,
      },
      companyName: {
        bold: true,
        fontSize: 12,
        alignment: "center",
      },
      invoiceCompanyName: {
        bold: true,
        fontSize: 14,
      },
      // Document Header
      documentHeaderLeft: {
        fontSize: 11,
        margin: [15, 20, 10, 15],
        alignment: "left",
      },
      documentHeaderBill: {
        fontSize: 9,
        margin: [0, 20, 15, 10],
        alignment: "right",
      },
      documentHeaderCenter: {
        fontSize: 11,
        margin: [10, 10, 10, 10],
        alignment: "center",
      },
      outwardBillDate: {
        margin: [0, 0, 10, 5],
        alignment: "right",
      },
      documentHeaderRight: {
        margin: [10, 10, 10, 10],
        alignment: "right",
      },
      // Document Footer
      documentFooterLeft: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "left",
      },
      documentFooterCenter: {
        fontSize: 8,
        margin: [2, 2, 2, 2],
        alignment: "center",
      },
      documentFooterRight: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "right",
      },
    },
  }
  return dd
}
