import {
  AppBar,
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Paper,
  Popover,
  Snackbar,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core"
import ButtonBase from "@material-ui/core/ButtonBase"
import { AccountCircle, AddCircle, Brightness4, Brightness7, Help, History, Home, KeyboardArrowDown as KeyboardArrowDownIcon, Keyboard as KeyboardIcon, Lock, Menu, Refresh } from "@material-ui/icons"
import usePrevious from "@project/components/Hooks/usePrevious"
import Alert from "@project/components/common/AlertComponent"
import ChipComponent from "@project/components/common/ChipComponent"
import { encryption, encryptionV2 } from "@project/components/cryptoJSFunctions"
import { fbprintpdf } from "@project/components/printJSFunctions"

import { commonSiteSetting, getCookie } from "@project/components/utilityFunctions"
import { callError, onSubmitError } from "@project/sharedcomponents/errorFunction"
import { isblank, replaceAllFn } from "@project/sharedcomponents/utilityFunctions"
import React, { useEffect, useState } from "react"
import { isMobileOnly } from "react-device-detect"
import { useForm } from "react-hook-form"
import { getApplicationKeyMap } from "react-hotkeys"
import "react-perfect-scrollbar/dist/css/styles.css"
import { connect, useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { compose } from "recompose"
import { reduxForm } from "redux-form"
import {
  customDialog,
  fetch_dependencyWithoutLoading,
  gotoPage,
  lastPageDataSet,
  modalInvoiceDetail,
  modalInvoiceLocalPrint,
  modalInvoicePrint,
  modalTransactionDetail,
  rightdrawerstate,
  sendNewNotifications,
  snackbarstate,
  sync,
} from "../../Actions"
import { WINDOW_DIMENSION_UPDATED } from "../../Actions/types"
import {
  changeURL,
  getAccountSessionUserData,
  getAccountSitesetting,
  getAccountViewRightsByID,
  getAllReports,
  getCompanyLock,
  getReportRights,
  getconfigsettingdata,
  getheaders,
} from "../../Lib/commonfunctions"
import { sendNotificationInvoiceFn } from "../../Lib/printFunctions"
import HeaderSearch from "../appComponents/HeaderSearch"
import HelpDialog from "../appComponents/HelpDialog"
import AnnouncementBar from "../appComponents/announcementBar"
import DialogComponent from "./DialogComponent"
import DrawerComponent from "./DrawerComponent"
import FMenu from "./FMenu"
import NavigationMenu from "./navigationMenu"
import Field from "../common/reduxFormComponents"

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    backgroundColor: "transparent",
    zIndex: 100000000,
  },
  global_search: {
    padding: "0px 4px",
    display: "flex",
    alignItems: "center",
  },
  menu_nav: {
    width: 275,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  input: {
    marginLeft: 8,
    flex: 1,
  },

  iconButton: {
    padding: 5,
  },

  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },

  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },

  nested: {
    paddingLeft: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  secondaryActionFrame: {
    overflow: "visible",
    position: "fixed",
    right: 8,
    top: 4,
    zIndex: 10001,
  },
  listselected: {
    backgroundColor: "#2196F3",
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    //display: 'flex',
  },
}))

const Header = (props) => {
  const { handleNightModeChange, data, announcementDetail, isDisconnected } = props
  const { islogin, accountSiteSettingKeyValue } = useSelector((state) => state.AuthReducer)
  const {
    ddcustomfield,
    SnackbarOriginVertical,
    SnackbarOriginHorizontal,
    drawerStack,
    rightdrawer,
    lastpagedata,
    lastpagetype,
    lastpageaction,
    lastpagetime,
    ConfirmDialogLoading,
    formdrawer,
    SnackbarType,
    Snackbarerror,
    SnackbarAutoHide,
    Snackbarerrormessage,
    progress_loading,
    progress_loading_title,
    loading,
    loadingSnackbar,
    loadingSnackbarMessage,
    loadingSnackbarType,
    customDialogVisible,
    customDialogTitle,
    customDialogContent,
    customDialogDismissLabel,
    customDialogButtons,
    ConfirmDialog,
    sidebarloading,
    dd_country_state,
  } = useSelector((state) => state.CommonReducer)
  const { serviceWorkerUpdated } = useSelector((state) => state.ServiceWorkerReducer)

  let location = useLocation()
  var changeyear = ""

  const dispatch = useDispatch()

  const classes = useStyles()
  const theme = useTheme()

  const [menudrawer, setMenudrawer] = useState(false)
  const [shortCutDialog, setShortCutDialog] = useState(false)
  const [apppopper, setApppopper] = useState(false)
  const [apppopperanchor, setApppopperanchor] = useState(null)
  const [stateLastData, setStateLastData] = useState([])
  const [helpDialog, setHelpDialog] = useState(false)
  const [confirmBtnDisable, setConfirmBtnDisable] = useState(false)
  const [searchFilters, setSearchFilters] = useState([])
  const [newversion, setNewversion] = useState(false)

  const dS = {
    company_name: getAccountSessionUserData("company_name"),
    company_url: getAccountSessionUserData("company_url"),
    financial_year: getAccountSessionUserData("financial_year"),
    name: getAccountSessionUserData("name"),
    invoice_type_detail: getAccountSitesetting("invoice_type_detail"),
    purchase_type_detail: getAccountSitesetting("purchase_type_detail"),
    inward_type_detail: getAccountSitesetting("inward_type_detail"),
    outward_type_detail: getAccountSitesetting("outward_type_detail"),
    email_module: getconfigsettingdata("email_module"),
    sms_module: getconfigsettingdata("sms_module"),
    enable_whatsapp_api: getconfigsettingdata("enable_whatsapp_api"),
    whatsapp_module: getAccountSitesetting("whatsapp_module"),
    whatsapp_queue: getAccountSitesetting("whatsapp_queue"),
  }

  const defaultValues = {
    searchdropdown: searchFilters.length > 0 ? searchFilters[0].value : "",
    year: dS.financial_year,
  }
  const { handleSubmit, control, watch, setValue, setError } = useForm({ defaultValues })
  const formValues = watch()

  const prevServiceWorkerUpdated = usePrevious(serviceWorkerUpdated)
  const prevConfirmDialogLoading = usePrevious(ConfirmDialogLoading)
  const prevCustomDialogVisible = usePrevious(customDialogVisible)
  const prevLastpagetime = usePrevious(lastpagetime)
  const prevDDcustomfield = usePrevious(ddcustomfield)

  const updateWindowDimensions = () => {
    dispatch({
      type: WINDOW_DIMENSION_UPDATED,
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  const getDefaultDependency = () => {
    var dropdown_string = "dd_country_state"
    if (getconfigsettingdata("custom_fields") === "yes") {
      dropdown_string = "dd_country_state,customField"
    }
    dispatch(
      fetch_dependencyWithoutLoading({
        dropdown_string,
      })
    )
  }

  const resetCusomFields = () => {
    let dropdown_string = ""
    /*if (getconfigsettingdata("custom_fields") === "yes") {
      dropdown_string = "customField"
    }*/
    dispatch(
      fetch_dependencyWithoutLoading({
        dropdown_string,
      })
    )
  }

  const SiteSetting = (key) => {
    return commonSiteSetting({ key, settingData: accountSiteSettingKeyValue })
  }

  const handleyearChange = (change_year) => {
    changeyear = change_year
    handleSave()
  }

  const handleSave = () => {
    handleSubmit(onSubmitData, (errors) => onSubmitError({ errors, dispatch, snackbarstate }))()
  }

  const validate = (values) => {
    const errors = {}
    return errors
  }

  const onSubmitData = (values) => {
    const error = validate(values)

    if (Object.keys(error).length > 0) {
      callError({ error, onSubmitError, dispatch, snackbarstate, setError })
    } else {
      var data = {
        name: dS.company_name,
        id: dS.company_url,
        token: getheaders(),
        user: dS.name,
        year: parseInt(changeyear, 10),
        old: dS.company_url,
      }

      var encrypted = encryptionV2(data)
      // encrypted = replaceAllFn(encrypted, "/", "$")
      var url = changeURL(dS.company_url, `/switchcompany/${encrypted}`)
      const win = window.open(url)
      win.focus()
      setValue("year", dS.financial_year)
    }
  }

  const renderloading = () => {
    if (!islogin) {
      return null
    }
    if (progress_loading > 0 && progress_loading < 100) {
      return (
        <Backdrop className={classes.backdrop} open={true}>
          <div
            style={{
              position: "fixed",
              top: "50%",
              width: 200,
              left: "50%",
              zIndex: 100000001,
              marginLeft: "-100px",
              marginTop: "-100px",
            }}
          >
            <Paper elevation={3} style={{ padding: 8, textAlign: "center" }}>
              <Typography gutterBottom variant="body1">
                {progress_loading_title}
              </Typography>
              <LinearProgress style={{ marginBottom: 8 }} variant="determinate" color="primary" value={progress_loading} />
              <Typography gutterBottom variant="body2">{`${Math.round(progress_loading)}%`}</Typography>
            </Paper>
          </div>
        </Backdrop>
      )
    } else if (loading) {
      return (
        <Backdrop className={classes.backdrop} style={{ zIndex: theme.zIndex.drawer + drawerStack.length }} open={loading}>
          <CircularProgress
            disableShrink
            size={50}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              zIndex: 100000001,
              marginLeft: "-25px",
              marginTop: "-25px",
            }}
          />
        </Backdrop>
      )
    }
  }

  const handlecompanyClick = () => {
    dispatch(gotoPage({ key: "welcome" }))
  }

  const handleClose = () => {
    dispatch(snackbarstate({ open: false, message: "", snackbarType: "" }))
  }

  const handleCloseLoadingSnackBar = (event, reason) => {}

  const toggleMenuDrawer = (open) => {
    setMenudrawer(open)
  }

  const toggleAppPopper = () => (event) => {
    setApppopper(!apppopper)
    setApppopperanchor(event.currentTarget)
  }

  const handlePopOverClose = () => {
    setApppopper(false)
    setApppopperanchor("")
  }

  const toggleRightHeader = (open, title, data, fieldname, position, width, height) => () => {
    var rightDrawerdata = data
    if (title === "Add New SettingsDrawer") {
      rightDrawerdata = {
        QuickLink: toggleRightHeader(true, "Add New QuickLink", "", "", "", 450),
        history: toggleRightHeader(true, "Add New RecentActivitiesDrawer", "", "", "", 650),
        handleNightModeChange: () => handleNightModeChange(),
        shortCutDialog: () => setShortCutDialog(true),
        homeClick: () => handlecompanyClick(),
        chatSupport: () => handleHelpClick(),
      }
    }
    dispatch(rightdrawerstate({ open, title, data: rightDrawerdata, fieldname, position, width, height }))
  }

  const handleShortCutClose = () => {
    setShortCutDialog(false)
  }

  const renderShortCutDialog = (type) => {
    var visible = false
    if (type === "formDrawer" && formdrawer) {
      visible = shortCutDialog
    } else if (type === "drawer" && rightdrawer) {
      visible = shortCutDialog
    } else {
      visible = shortCutDialog
    }
    const keyMap = getApplicationKeyMap()

    return (
      <Dialog
        open={visible}
        //fullScreen={true}
        maxWidth="md"
        fullWidth={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{ paddingBottom: 0 }}>
          Keyboard Shortcuts
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem key="tab">
              <div style={{ marginRight: 15, flex: 0.5 }}>
                <Button style={{ textTransform: "none" }} color="primary" disableElevation variant="contained" key="tab">
                  tab
                </Button>
              </div>
              <div style={{ flex: 1 }}>
                <Typography variant="body1">Go to next field</Typography>
              </div>
            </ListItem>
            <ListItem key="shift+tab">
              <div style={{ marginRight: 15, flex: 0.5 }}>
                <Button style={{ textTransform: "none" }} color="primary" disableElevation variant="contained" key="shift+tab">
                  shift+tab
                </Button>
              </div>
              <div style={{ flex: 1 }}>
                <Typography variant="body1">Go to prev field</Typography>
              </div>
            </ListItem>

            {Object.keys(keyMap).map((memo, actionName) => {
              const { sequences, name } = keyMap[memo]
              if (memo !== "ALT" && memo !== "ENTER") {
                return (
                  <ListItem key={name || actionName}>
                    <div style={{ marginRight: 15, flex: 0.5 }}>
                      {sequences.map(({ sequence }) => (
                        <Button style={{ textTransform: "none" }} color="primary" disableElevation variant="contained" key={sequence}>
                          {sequence}
                        </Button>
                      ))}
                    </div>
                    <div style={{ flex: 1 }}>
                      <Typography variant="body1">{memo.replace(/_/g, " ")}</Typography>
                    </div>
                  </ListItem>
                )
              }
              return null
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShortCutClose} variant="text">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const handleHelpChatClick = () => {
    if (!isblank(window.Tawk_API)) {
      window.Tawk_API.toggle()
    }
  }

  const handleHelpClick = () => {
    setHelpDialog(true)
  }

  const closeHelpDialog = () => {
    setHelpDialog(false)
  }

  const change_module = (type) => {
    if (type === "payroll") {
      var data = [
        {
          name: dS.company_name,
          company_url: dS.company_url,
          token: getheaders(),
          user: dS.name,
          year: dS.financial_year,
        },
      ]
      var encrypted = encryption({ data })
      encrypted = replaceAllFn(encrypted, "/", "$")
      var url = changeURL("payroll", `/switchmodule/${encrypted}`)
      const win = window.open(url)
      win.focus()
    }
  }

  const closeCustomDialog = () => {
    dispatch(customDialog({ visible: false }))

    setConfirmBtnDisable(false)
  }

  const confirmDialogAction = (_action) => {
    setConfirmBtnDisable(true)
    _action()
  }

  const renderCustomDialog = () => {
    return (
      <Dialog
        open={customDialogVisible}
        maxWidth={"xs"}
        fullWidth={true}
        onClose={(event, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            closeCustomDialog()
          } else {
            closeCustomDialog()
          }
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {!isblank(customDialogTitle) && (
          <React.Fragment>
            {typeof customDialogTitle === "string" ? (
              <DialogTitle id="switch-title" style={{ paddingBottom: 0 }}>
                {customDialogTitle}
              </DialogTitle>
            ) : (
              <React.Fragment>{customDialogTitle}</React.Fragment>
            )}
          </React.Fragment>
        )}

        {!isblank(customDialogContent) && (
          <React.Fragment>
            {typeof customDialogContent === "string" ? (
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">{customDialogContent}</DialogContentText>
              </DialogContent>
            ) : (
              <React.Fragment>{customDialogContent}</React.Fragment>
            )}
          </React.Fragment>
        )}
        <DialogActions>
          <Button data-testid="customDialogDismiss" disabled={ConfirmDialogLoading} onClick={closeCustomDialog} variant="text">
            {customDialogDismissLabel ? customDialogDismissLabel : "Dismiss"}
          </Button>
          {customDialogButtons.map((e, dindex) => {
            return (
              <Button
                disabled={ConfirmDialogLoading || confirmBtnDisable}
                data-testid={`customDialogButton_${dindex}`}
                onClick={() => confirmDialogAction(e.onClick)}
                variant="text"
                color={e.color ? e.color : "primary"}
              >
                {e.label}
              </Button>
            )
          })}
        </DialogActions>
      </Dialog>
    )
  }

  // const sendNewNotifications_call = (obj) => {
  //   dispatch(sendNewNotifications(obj))
  // }

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener("resize", updateWindowDimensions)

    var searchFilters = []
    if (getReportRights("9")) {
      searchFilters.push({ value: "ledger", label: "Ledger" })
    }
    if (getAccountViewRightsByID("7")) {
      searchFilters.push({ value: "customers", label: "Customers" })
    }
    if (getAccountViewRightsByID("8")) {
      searchFilters.push({ value: "suppliers", label: "Suppliers" })
    }
    if (getAccountViewRightsByID("15")) {
      searchFilters.push({ value: "items", label: "Products" })
    }
    if (accountSiteSettingKeyValue["invoice_type_detail"]) {
      accountSiteSettingKeyValue["invoice_type_detail"].forEach((invoice, i) => {
        searchFilters.push({ value: invoice.id, label: invoice.name })
      })
    }
    if (accountSiteSettingKeyValue["purchase_type_detail"]) {
      accountSiteSettingKeyValue["purchase_type_detail"].forEach((invoice, i) => {
        searchFilters.push({ value: invoice.id, label: invoice.name })
      })
    }
    if (accountSiteSettingKeyValue["inward_type_detail"]) {
      accountSiteSettingKeyValue["inward_type_detail"].forEach((invoice, i) => {
        searchFilters.push({ value: invoice.id, label: invoice.name })
      })
    }
    if (accountSiteSettingKeyValue["outward_type_detail"]) {
      accountSiteSettingKeyValue["outward_type_detail"].forEach((invoice, i) => {
        searchFilters.push({ value: invoice.id, label: invoice.name })
      })
    }
    if (getAccountViewRightsByID("134")) {
      searchFilters.push({ value: "creditnote", label: "Credit Note" })
    }
    if (getAccountViewRightsByID("135")) {
      searchFilters.push({ value: "debitnote", label: "Debit Note" })
    }
    if (getAccountViewRightsByID("22")) {
      searchFilters.push({ value: "receipt", label: "Receipt" })
    }
    if (getAccountViewRightsByID("23")) {
      searchFilters.push({ value: "payment", label: "Payment" })
    }
    if (getAccountViewRightsByID("24")) {
      searchFilters.push({ value: "journal", label: "Journal Entries" })
    }
    if (getAccountViewRightsByID("149")) {
      searchFilters.push({ value: "recordexpense", label: "Record Expense" })
    }
    if (!isblank(getAllReports()) && Object.keys(getAllReports()).length > 0) {
      searchFilters.push({ value: "reports", label: "Reports" })
    }

    setSearchFilters(searchFilters)

    return () => {
      window.removeEventListener("resize", updateWindowDimensions)
    }
  }, [])

  useEffect(() => {
    if (lastpagetype === "help" && lastpageaction === "clicked" && lastpagetime !== prevLastpagetime) {
      handleHelpClick()
    }
    if (lastpagetype === "importcountry" && lastpageaction === "insert" && lastpagetime !== prevLastpagetime) {
      var dropdown_string = "dd_country_state"

      setStateLastData(lastpagedata)
      const onComplete = () => {
        if (stateLastData && stateLastData.otherDetail && stateLastData.otherDetail.from === "dropDownImport") {
          dispatch(lastPageDataSet({ lasttype: "import_country", lastaction: "success", lastdata: stateLastData.insert_code }))
        }
      }
      dispatch(fetch_dependencyWithoutLoading({ dropdown_string, functions: { onComplete } }))
    }
    if ((lastpagetype === "login" || lastpagetype === "onboarding") && lastpageaction === "success" && lastpagetime !== prevLastpagetime) {
      getDefaultDependency()
    }

    var otherDetail = {}
    var object = ""
    if ((lastpageaction === "saveAndNotification" || lastpageaction === "saveAndNotificationAndPrint") && lastpagetime !== prevLastpagetime) {
      if (dS.invoice_type_detail.filter((e) => e.id === lastpagetype).length > 0 || lastpagetype === "salesreturn" || lastpagetype === "purchasereturn" || lastpagetype === "purchase_order") {
        otherDetail = {}
        if (lastpagedata.formtype === "salesreturn") {
          otherDetail.title = "Credit Note"
        } else if (lastpagedata.formtype === "purchasereturn") {
          otherDetail.title = "Debit Note"
        } else if (dS.invoice_type_detail.filter((e) => e.id === lastpagetype).length > 0) {
          otherDetail.title = dS.invoice_type_detail.find((item) => item.id === lastpagedata.formtype).name
        }
        otherDetail.printType = "html"
        const onCompleteBase64 = (fileData) => {
          sendNotificationInvoiceFn({
            fileData,
            lastpagedata,
            sendNewNotifications: sendNewNotifications,
            lastpageaction,
            dispatch,
            dS,
          })

          otherDetail.printCopy = ""
          if (lastpageaction === "saveAndNotificationAndPrint") {
            dispatch(modalInvoiceLocalPrint({ data: fileData.invoiceDetail, other_type: lastpagedata.formtype, otherDetail, printType: "print" }))
          }
          if (lastpageaction === "saveAndNotificationAndDownload") {
            dispatch(modalInvoiceLocalPrint({ data: fileData.invoiceDetail, other_type: lastpagedata.formtype, otherDetail, printType: "download" }))
          }
        }
        otherDetail.printCopy = "single"
        if (lastpageaction === "saveAndNotificationAndPrint") {
          otherDetail.actionType = "saveandprint"
        }
        dispatch(modalInvoiceDetail({ code: lastpagedata.code, otherType: lastpagedata.formtype, otherDetail, printType: "base64", onCompleteBase64 }))
      } else if (lastpagetype === "receipt" || lastpagetype === "payment") {
        const onCompleteBase64 = (fileData) => {
          sendNotificationInvoiceFn({
            fileData,
            lastpagedata,
            sendNewNotifications: sendNewNotifications,
            lastpageaction,
            dispatch,
            dS,
            other_type: "transaction",
          })

          if (lastpageaction === "saveAndNotificationAndPrint") {
            fbprintpdf({ docDefinition: fileData.printData, metadata: fileData.metadata, action: "print" })
          }
        }
        otherDetail = {}
        if (lastpagedata.formtype === "receipt") {
          otherDetail.title = "Receipt"
        } else if (lastpagedata.formtype === "payment") {
          otherDetail.title = "Payment"
        }
        otherDetail.printType = "html"
        otherDetail.printCopy = "single"
        if (lastpageaction === "saveAndNotificationAndPrint") {
          otherDetail.actionType = "saveandprint"
        }
        dispatch(
          modalTransactionDetail({
            code: lastpagedata.code,
            type: lastpagedata.formtype,
            title: otherDetail.title,
            filenameOld: "",
            otherDetail: otherDetail,
            printType: "base64",
            onCompleteBase64: onCompleteBase64,
          })
        )
      }
    }
    if (lastpageaction === "saveandprint" && prevLastpagetime !== lastpagetime && dS.invoice_type_detail.filter((e) => e.id === lastpagetype).length > 0) {
      otherDetail = {}
      otherDetail.title = dS.invoice_type_detail.find((item) => item.id === lastpagetype).name
      otherDetail.printType = "html"
      otherDetail.actionType = "saveandprint"
      dispatch(modalInvoiceDetail({ code: lastpagedata, otherType: lastpagetype, otherDetail, printType: "print" }))
    }
    if (lastpagetype === "stock_transferGodown" && lastpageaction === "saveandprint" && prevLastpagetime !== lastpagetime) {
      object = data.find((item) => parseInt(item.code) === parseInt(lastpagedata))
      dispatch(
        modalInvoicePrint({
          row: object,
          code: object.code,
          type: "stock_transferGodown",
          title: `Bill No: ${object.billno}`,
          filename: `stock_transferGodowninvoice_${object.billno}`,
          action: "print",
        })
      )
    }
    if (lastpagetype === "journal_entry" && lastpageaction === "saveandprint" && prevLastpagetime !== lastpagetime) {
      otherDetail = {}
      otherDetail.title = "Journal Entry"
      otherDetail.type = "journal"
      otherDetail.printType = "html"
      dispatch(modalTransactionDetail({ code: lastpagedata, type: "journal_entry", title: ``, filenameOld: ``, otherDetail: otherDetail, printType: "print" }))
    }
    if (lastpageaction === "saveandprint" && prevLastpagetime !== lastpagetime && dS.outward_type_detail.filter((e) => e.id === lastpagetype).length > 0) {
      otherDetail = {}
      otherDetail.printType = "html"
      var defaultType = lastpagetype
      dispatch(modalInvoiceDetail({ code: lastpagedata, otherType: defaultType, otherDetail, printType: "print" }))
    }
    if (lastpagetype === "payment" && lastpageaction === "saveandprint" && prevLastpagetime !== lastpagetime) {
      otherDetail = {}
      otherDetail.title = "Payment"
      otherDetail.type = "payment"
      otherDetail.printType = "html"
      dispatch(modalTransactionDetail({ code: lastpagedata.toString().split("_")[0], type: "payment", title: ``, filenameOld: ``, otherDetail: otherDetail, printType: "print" }))
    }
    if (lastpagetype === "salesreturn" && lastpageaction === "saveandprint" && prevLastpagetime !== lastpagetime) {
      otherDetail = {}
      otherDetail.title = "Credit Note"
      otherDetail.printType = "html"
      dispatch(modalInvoiceDetail({ code: lastpagedata, otherType: lastpagetype, otherDetail, printType: "print" }))
    }
    if (lastpageaction === "saveandprint" && prevLastpagetime !== lastpagetime && dS.purchase_type_detail.filter((e) => e.id === lastpagetype).length > 0) {
      otherDetail = {}
      otherDetail.title = dS.purchase_type_detail.find((item) => item.id === lastpagetype).name
      otherDetail.printType = "html"
      dispatch(modalInvoiceDetail({ code: lastpagedata, otherType: lastpagetype, otherDetail, printType: "print" }))
    }
    if (lastpagetype === "purchasereturn" && lastpageaction === "saveandprint" && prevLastpagetime !== lastpagetime) {
      otherDetail = {}
      otherDetail.title = "Debit Note"
      otherDetail.printType = "html"
      dispatch(modalInvoiceDetail({ code: lastpagedata, otherType: lastpagetype, otherDetail, printType: "print" }))
    }
    if (lastpagetype === "receipt" && lastpageaction === "saveandprint" && prevLastpagetime !== lastpagetime) {
      otherDetail = {}
      otherDetail.title = "Receipt"
      otherDetail.type = "receipt"
      otherDetail.printType = "html"
      dispatch(modalTransactionDetail({ code: lastpagedata.toString().split("_")[0], type: "receipt", title: ``, filenameOld: ``, otherDetail: otherDetail, printType: "print" }))
    }
    if (lastpagetype === "stockTransfer" && lastpageaction === "saveandprint" && prevLastpagetime !== lastpagetime) {
      object = data.find((item) => parseInt(item.code) === parseInt(lastpagedata))
      dispatch(
        modalInvoicePrint({
          row: object,
          code: object.code,
          type: "stockTransfer",
          title: `Bill No: ${object.billno}`,
          filename: `stock_transfer_invoice_${object.billno}`,
          action: "print",
        })
      )
    }
    if (lastpageaction === "saveandprint" && prevLastpagetime !== lastpagetime && dS.inward_type_detail.filter((e) => e.id === lastpagetype).length > 0) {
      otherDetail = {}
      otherDetail.printType = "html"
      dispatch(modalInvoiceDetail({ code: lastpagedata, otherType: lastpagetype, otherDetail, printType: "print" }))
    }

    if (lastpagetype === "receipt" && lastpageaction === "saveandshare" && prevLastpagetime !== lastpagetime) {
      otherDetail = {}
      otherDetail.title = "Receipt"
      otherDetail.type = "receipt"
      otherDetail.printType = "share"
      otherDetail.sidebarloading = sidebarloading
      otherDetail.submitting = props.submitting
      otherDetail.dS = dS

      dispatch(modalTransactionDetail({ code: lastpagedata.toString().split("_")[0], type: "receipt", title: ``, filenameOld: ``, otherDetail: otherDetail, printType: "share" }))
    }
    if (lastpagetype === "payment" && lastpageaction === "saveandshare" && prevLastpagetime !== lastpagetime) {
      otherDetail = {}
      otherDetail.title = "Payment"
      otherDetail.type = "payment"
      otherDetail.printType = "share"
      otherDetail.sidebarloading = sidebarloading
      otherDetail.submitting = props.submitting
      otherDetail.dS = dS

      dispatch(modalTransactionDetail({ code: lastpagedata.toString().split("_")[0], type: "payment", title: ``, filenameOld: ``, otherDetail: otherDetail, printType: "share" }))
    }
    if (lastpageaction === "saveandshare" && prevLastpagetime !== lastpagetime && dS.invoice_type_detail.filter((e) => e.id === lastpagetype).length > 0) {
      otherDetail = {}
      otherDetail.title = dS.invoice_type_detail.find((item) => item.id === lastpagetype).name
      otherDetail.printType = "share"
      otherDetail.actionType = "saveandshare"
      otherDetail.ddcustomfield = ddcustomfield
      otherDetail.dd_country_state = dd_country_state
      otherDetail.dS = dS

      dispatch(modalInvoiceDetail({ code: lastpagedata, otherType: lastpagetype, otherDetail, printType: "share" }))
    }
    if (lastpagetype === "salesreturn" && lastpageaction === "saveandshare" && prevLastpagetime !== lastpagetime) {
      otherDetail = {}
      otherDetail.title = "Credit Note"
      otherDetail.printType = "share"
      otherDetail.ddcustomfield = ddcustomfield
      otherDetail.dd_country_state = dd_country_state
      otherDetail.dS = dS

      dispatch(modalInvoiceDetail({ code: lastpagedata, otherType: lastpagetype, otherDetail, printType: "share" }))
    }
    if (lastpageaction === "saveandshare" && prevLastpagetime !== lastpagetime && dS.purchase_type_detail.filter((e) => e.id === lastpagetype).length > 0) {
      otherDetail = {}
      otherDetail.title = dS.purchase_type_detail.find((item) => item.id === lastpagetype).name
      otherDetail.printType = "share"
      otherDetail.ddcustomfield = ddcustomfield
      otherDetail.dd_country_state = dd_country_state
      otherDetail.dS = dS

      dispatch(modalInvoiceDetail({ code: lastpagedata, otherType: lastpagetype, otherDetail, printType: "share" }))
    }
    if (lastpagetype === "purchasereturn" && lastpageaction === "saveandshare" && prevLastpagetime !== lastpagetime) {
      otherDetail = {}
      otherDetail.title = "Debit Note"
      otherDetail.printType = "share"
      otherDetail.ddcustomfield = ddcustomfield
      otherDetail.dd_country_state = dd_country_state
      otherDetail.dS = dS

      dispatch(modalInvoiceDetail({ code: lastpagedata, otherType: lastpagetype, otherDetail, printType: "share" }))
    }
  }, [lastpagedata, lastpagetype, lastpageaction, lastpagetime, prevLastpagetime])

  useEffect(() => {
    if (prevServiceWorkerUpdated !== serviceWorkerUpdated) {
      if (serviceWorkerUpdated === true) {
        setNewversion(true)
      }
    }
  }, [serviceWorkerUpdated, prevServiceWorkerUpdated])

  useEffect(() => {
    if (prevConfirmDialogLoading !== ConfirmDialogLoading || (prevCustomDialogVisible === true && ConfirmDialogLoading === false)) {
      if (ConfirmDialogLoading === false) {
        setConfirmBtnDisable(false)
      }
    }
  }, [ConfirmDialogLoading, prevConfirmDialogLoading, prevCustomDialogVisible])

  /*  useEffect(() => {
    if (dialogReason !== formValues.deleteReason) {
      dispatch(dialogReasonUpdate(formValues.deleteReason))
    }

    // if (!isblank(prevFormValues)) {
    //     // if (isblank(prevFormValues.year)) {
    //     //     setValue("year", dS.financial_year)
    //     // }
    //     if (!isblank(formValues.year)) {
    //         if (parseInt(dS.financial_year, 10) !== parseInt(formValues.year)) {
    //             setValue("year", formValues.year)
    //         }
    //     }
    // }
  }, [dialogReason, formValues, prevFormValues]) */

  useEffect(() => {
    if (!isblank(prevDDcustomfield) && prevDDcustomfield.length > 0 && ddcustomfield.length === 0) {
      resetCusomFields()
    }
  }, [ddcustomfield, prevDDcustomfield])

  useEffect(() => {
    if (!isblank(searchFilters) && searchFilters.length > 0) {
      setValue("searchdropdown", searchFilters[0].value)
    }
  }, [searchFilters])

  if (formValues === undefined) {
    return <span></span>
  }

  const withOutLogin = (
    <React.Fragment>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={newversion ? true : false}>
        <Alert
          severity="warning"
          variant="filled"
          action={
            <Button key="undo" size="small" onClick={() => window.location.reload()}>
              Reload
            </Button>
          }
        >
          The app has been updated! Refresh your browser to enjoy the latest and greatest!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: SnackbarOriginVertical,
          horizontal: SnackbarOriginHorizontal,
        }}
        open={Snackbarerror && Snackbarerrormessage !== "-401" ? true : false}
        onClose={handleClose}
        autoHideDuration={SnackbarAutoHide ? 3000 : null}
      >
        <Alert elevation={0} variant="filled" onClose={handleClose} severity={SnackbarType}>
          {Snackbarerrormessage}
        </Alert>
      </Snackbar>

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={loadingSnackbar} onClose={handleCloseLoadingSnackBar} autoHideDuration={null}>
        <Alert icon={<CircularProgress size={20} color="inherit" />} elevation={0} variant="filled" severity={loadingSnackbarType}>
          {loadingSnackbarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  )

  if (!islogin) {
    return withOutLogin
  }
  let navHeader = null
  const buttonBaseStyle = {
    display: "inline-block",
    textAlign: "left",
    marginRight: 4,
  }

  if (isMobileOnly) {
    buttonBaseStyle.maxWidth = 200
  }

  if (islogin && location.pathname !== "/login") {
    navHeader = (
      <AppBar color={getCookie("darkmode") === "dark" ? "inherit" : "primary"} position="fixed">
        <AnnouncementBar announcementDetail={announcementDetail} />
        <Toolbar variant="dense">
          <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={() => toggleMenuDrawer(true)}>
            <Menu />
          </IconButton>

          <ButtonBase
            onClick={toggleAppPopper()}
            color="default"
            style={{
              paddingRight: 8,
              paddingLeft: 8,
              borderRadius: 4,
              flexDirection: "row",
            }}
          >
            <div style={buttonBaseStyle}>
              <Typography noWrap style={{ lineHeight: 1.2 }} variant="subtitle1">
                {dS.company_name}
              </Typography>
              <Typography noWrap style={{ lineHeight: 1.2 }} variant="subtitle2" data-testid="company_financial_year">
                {dS.financial_year}-{parseInt(dS.financial_year, 10) + 1}
              </Typography>
            </div>
            <div>
              <KeyboardArrowDownIcon />
            </div>
          </ButtonBase>

          <Hidden smDown>
            <HeaderSearch searchFilters={searchFilters} />
          </Hidden>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Hidden mdDown>
              {process.env.REACT_APP_NODE_ENV !== "production" && <Field name="newHookForm" inputType={"renderToggle"} control={control} />}
              {isDisconnected && <ChipComponent size="small" colorObj={{ color: "red" }} label="Offline"></ChipComponent>}

              <Tooltip id="tooltip-icon" title="Home">
                <IconButton color="inherit" onClick={() => dispatch(gotoPage({ key: "/" }))}>
                  <Home />
                </IconButton>
              </Tooltip>

              <Tooltip id="tooltip-icon" title="Help">
                <IconButton color="inherit" onClick={() => handleHelpClick()}>
                  <Help />
                </IconButton>
              </Tooltip>
              {getCompanyLock() === true && (
                <IconButton color="inherit">
                  <Lock />
                </IconButton>
              )}

              <Tooltip id="tooltip-icon" title="Keyboard Shortcuts">
                <IconButton color="inherit" onClick={() => setShortCutDialog(true)}>
                  <KeyboardIcon />
                </IconButton>
              </Tooltip>

              <Tooltip id="tooltip-icon" title="Sync">
                <IconButton color="inherit" onClick={() => dispatch(sync(""))}>
                  <Refresh />
                </IconButton>
              </Tooltip>

              <Tooltip id="tooltip-icon" title="Dark mode on/off ">
                <IconButton color="inherit" onClick={() => handleNightModeChange()}>
                  {getCookie("darkmode") === "dark" ? <Brightness7 /> : <Brightness4 />}
                </IconButton>
              </Tooltip>

              <Tooltip id="tooltip-icon" title="Recent Activities ">
                <IconButton color="inherit" onClick={toggleRightHeader(true, "Add New RecentActivitiesDrawer", "", "", "", 650)}>
                  <History />
                </IconButton>
              </Tooltip>

              <Tooltip id="tooltip-icon" title="Quick Links ">
                <IconButton color="inherit" onClick={toggleRightHeader(true, "Add New QuickLink", "", "", "", 450)}>
                  <AddCircle />
                </IconButton>
              </Tooltip>
            </Hidden>
            <Tooltip id="tooltip-icon" title="Settings">
              <IconButton color="inherit" aria-label="RightMenu" onClick={toggleRightHeader(true, "Add New SettingsDrawer", "", "", "", 350)}>
                <AccountCircle />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
    )
  }

  return (
    <React.Fragment>
      {navHeader}
      {renderloading()}
      <NavigationMenu menudrawer={menudrawer} toggleMenuDrawer={toggleMenuDrawer} />
      {drawerStack.length > 0 && <DrawerComponent t={props.t} formValues={formValues} />}
      {renderShortCutDialog()}
      {ConfirmDialog && <DialogComponent formValues={formValues} />}
      <Popover
        id={"menu-popover"}
        open={apppopper}
        anchorEl={apppopperanchor}
        onClose={handlePopOverClose}
        TransitionComponent={Collapse}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <FMenu change_module={(e) => change_module(e.id)} year={dS.financial_year} active_module="accounting" close_popover={handlePopOverClose} handleyearChange={handleyearChange} />
      </Popover>
      {withOutLogin}
      {renderCustomDialog()}
      {islogin && <HelpDialog visible={helpDialog} onClose={closeHelpDialog} handleHelpChatClick={handleHelpChatClick} />}
    </React.Fragment>
  )
}
// export default Header

const mapStateToProps = (state) => {
  return {
    initialValues: {},
  }
}

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: "headerSearchForm",
    //validate,
    //onSubmit: postform,
    touchOnBlur: false,
    //onSubmitFail: showerrors,
    //onSubmitSuccess: afterSubmit,
  })
)(Header)

/*export default compose(
  connect(mapStateToProps, {
    sync,
  }),
  reduxForm({
    form: "headerSearchForm", // a unique identifier for this form
    touchOnBlur: false,
  })
)(Header)*/
