export default {
  TrialBalance: 1,
  TradingAccount: 2,
  SalesRegister: 3,
  PaymentRegister: 4,
  ReceiptRegister: 5,
  P_LAccount: 6,
  BalanceSheet: 7,
  PurchaseRegister: 8,
  LedgerReport: 9,
  InterestReport: 10,
  MultiLedgerReport: 11,
  ProductWiseStockReport: 12,
  ClosingReport: 13,
  PartyWiseStockReport: 14,
  In_OutRegister: 15,
  OutstandingReport: 16,
  OutstandingAgeingReport: 17,
  CardBalanceReport: 24,
  RefillCardReport: 25,
  MemberCardBalance: 26,
  PosSalesRegister: 28,
  CreditLimitReport: 30,
  ReorderLevelReport: 31,
  AttendanceAbsentReport: 32,
  AttendancePresentReport: 33,
  UnauthorizedAttendanceReport: 34,
  InvoiceWeightDifference: 36,
  OrderAnalysis: 37,
  SalesReturnRegister: 40,
  PurchaseReturnRegister: 41,
  GSTR_1Summary: 42,
  GSTR_2Summary: 43,
  GSTR_3BSummary: 44,
  MultiOutstandingReport: 45,
  AgentWiseSalesReport: 47,
  SalesRegisterwithPaymenttype: 48,
  ItemWiseSalesReport: 49,
  ItemWisePurchaseReport: 50,
  TransportWiseSalesReport: 51,
  ElectronicCashLedgerReport: 52,
  PartyPriceMasterReport: 56,
  PartyGroupPriceReport: 57,
  InvoiceMissinginGST: 59,
  JournalEntryRegister: 60,
  CostCenter: 61,
  ProductBatchSummaryReport: 62,
  ProductBatchDetailReport: 63,
  P_LAccountCostCenterWise: 64,
  ProductSerialNumberReport: 65,
  ProductExpiredReport: 66,
  ProductExpiryReport: 67,
  GSTSummary: 68,
  GSTR_9: 69,
  SMSLogsReport: 70,
  TdsRegister: 71,
  ItemGroupSummaryReport: 72,
  ItemSubGroupSummaryReport: 73,
  RecordExpense: 74,
  Estimate: 75,
  SalesOrder: 76,
  PurchaseOrder: 77,
  MultiOutstandingAgeingReport: 78,
  DaybookReport: 79,
  LotDetailReport: 80,
  GSTAnnualComputation: 81,
  ItemSubGroupLevel2SummaryReport: 82,
  TcsRegister: 83,
  ProcessStockInOutReport: 84,
  ProcessLongation_ShortageReport: 85,
  LotCostingReport: 86,
  RawMaterialStockInHand: 87,
  BankReconcileReport: 88,
  PackingSlipRegister: 89,
  JobInwardRegister: 90,
  FinishStockRegister: 91,
  ManageLotRegister: 92,
  JobInwardsWithoutJobInvoice: 93,
  Monthlypaymentforquarterlyreturn: 94,
  RawGST: 95,
  LiveStockReport: 96,
  AmendBillsReport: 97,
  MissingBillNumber: 98,
  MissingTDSTCSNumber: 99,
  GoodsReceivedNoteRegister: 100,
  DeliveryChallanRegister: 101,
  StoneAnalysis: 102,
  StoneCostAnalysis: 103,
  StoneHistoryReport: 104,
  ProcessHistoryReport: 105,
  FinishStockInOutReport: 106,
  FinishStockBarcodeReport: 107,
  PurchaseLotReport: 108,
  FinishStockReport: 109,
  JobDeliveryChallanRegister: 110,
  TradingWithP_LReport: 111,
}
