import React from "react"
import { Button, DialogActions, DialogContent, Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from "@material-ui/core"
import { showdialog, showdrawerdialog } from "../../Actions"
import styles from "../../Css/style.css"
import { useDispatch, useSelector } from "react-redux"
const useStyles = makeStyles((theme) => ({
  ...styles,
}))
const ErrorList = (props) => {
  const classes = useStyles()
  const { ConfirmDialogData } = useSelector((state) => state.CommonReducer)
  const dispatch = useDispatch()
  var errormessage = []
  errormessage = ConfirmDialogData

  const handleErrorClose = () => {
    if (props.dialog === "drawerDialog") {
      dispatch(showdrawerdialog({ yesno: false }))
    } else {
      dispatch(showdialog({ yesno: false }))
    }
  }

  return (
    <React.Fragment>
      <DialogContent>
        <div>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }} className={classes.w125} size="small">
                  Sr. No
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} size="small">
                  Message
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errormessage?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell size="small">{row.docno}</TableCell>
                  <TableCell size="small">{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleErrorClose} variant="text">
          Cancel
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

export default ErrorList
