import React from "react"
import { Avatar, Box, Button, Card, CardContent, Container, Grid, Typography } from "@material-ui/core"
import { DatetoDMMYHM } from "@project/sharedcomponents/utilityFunctions"
import driller from "@project/components/Images/driller.svg"
import { useLocation } from "react-router-dom"
const siteMaintenance = () => {
  let location = useLocation()
  //   const maintenancedata = this.props.location.state.meta.message
  return (
    <div>
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="calc(100vh - 100px)">
        <Container maxWidth="sm">
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Avatar alt="driller" src={driller} style={{ height: 108, width: 108 }} />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography variant="h5" gutterBottom>
                    Site is down for maintenance.
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    We'll be back soon!
                  </Typography>
                  {/* <Typography variant="subtitle1" gutterBottom>
                    {maintenancedata.message}
                  </Typography> */}
                  {/* <Typography variant="subtitle1" gutterBottom>
                    {DatetoDMMYHM(maintenancedata.timer)}
                  </Typography> */}
                  <Button variant="contained" color="primary" component="a" href="/">
                    GO BACK TO DASHBOARD
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </div>
  )
}

export default siteMaintenance
