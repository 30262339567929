import { convertIntif, getdropdownfieldvalue, getewaybillstatus, isblank } from "@project/sharedcomponents/utilityFunctions"
import { EWAY_BILL_SEARCH_TEXT_UPDATE, EWAY_BILL_SERVICE_CLEAR, EWAY_BILL_SERVICE_FAIL, EWAY_BILL_SERVICE_START, EWAY_BILL_SERVICE_SUCCESS, EWAY_BILL_SERVICE_UPDATED } from "../Actions/types"

const INITIAL_STATE = {
  error: "",
  loading: false,
  other_type: "",
  data: [],
  totalcount: 0,
  norecord: false,
  rowsPerPage: 0,
  page: 0,
  order: "desc",
  orderBy: "billno",
  search_text: "",
  deleted: false,
  columnData: [
    { id: "billno", numeric: false, visible: true, label: "No", width: 150 },
    { id: "invoice_type", numeric: false, visible: true, label: "Invoice Type", width: 150 },
    { id: "b_date", numeric: false, visible: true, label: "Date", width: 125 },
    { id: "name", numeric: false, visible: true, label: "Account Name", showlink: true },
    { id: "gstin", numeric: false, visible: true, label: "GSTIN", width: 175, display: "tax" },
    { id: "city", numeric: false, visible: true, label: "City", width: 150 },
    { id: "state", numeric: false, visible: true, label: "State", width: 150 },
    {
      id: "ewaybill_status",
      numeric: false,
      visible: true,
      label: "Status",
      width: 150,
      disableSorting: true,
      badge: {
        "Not Generated": { color: "red" },
        Generated: { color: "green" },
        Pending: { color: "yellow" },
        Cancelled: { color: "black" },
      },
    },
    { id: "netamt", numeric: true, visible: true, label: `Net Amount`, width: 200, format: "indian_rupee", currencySign: true },
  ],
  metadata: "",
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EWAY_BILL_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case EWAY_BILL_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case EWAY_BILL_SERVICE_SUCCESS:
      let finaldata = action.payload.data.result.map((item, index) => {
        const updateditem = { ...item }
        if (updateditem.invoice_type === "salesreturn") {
          updateditem.invoice_type = "Credit Note"
        } else if (updateditem.invoice_type === "purchasereturn") {
          updateditem.invoice_type = "Debit Note"
        } else {
          updateditem.invoice_type = action.invoice_type_detail.filter((item) => item.id === updateditem.invoice_type)[0].name
        }

        if (!isblank(updateditem.irn)) {
          updateditem.generateEwayBill = true
        }

        updateditem.ewaybill_status = getdropdownfieldvalue({ dropDownData: getewaybillstatus(), field: "id", value: updateditem.ewaybill_status, displayvalue: "name" })
        return updateditem
      })
      return {
        ...state,
        data: finaldata,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        islogin: true,
        norecord: action.payload.data.result.length === 0,
      }
    case EWAY_BILL_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        data: [],
        loading: false,
        other_type: action.other_type,
        totalcount: 0,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
      }
    case EWAY_BILL_SEARCH_TEXT_UPDATE:
      return { ...state, search_text: action.search_text, norecord: false }

    case EWAY_BILL_SERVICE_UPDATED:
      return { ...state, data: state.data.filter((item) => convertIntif(item.code) !== convertIntif(action.payload)) }
    default:
      return state
  }
}
