import React from "react"
import { Avatar, Box, Button, Card, CardContent, Container, Grid, Typography } from "@material-ui/core"
import notfound from "../Images/notfound.svg"
const NotFound = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="calc(100vh - 100px)">
      <Container maxWidth="sm">
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Avatar alt="not found" src={notfound} style={{ height: 108, width: 108 }} />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="h5" gutterBottom>
                  Oops! Something went wrong
                </Typography>
                <Typography variant="h2">404</Typography>
                <Typography variant="subtitle1" gutterBottom>
                  We can't seem to find the page you're looking for.
                </Typography>
                <Button variant="contained" color="primary" component="a" href="/">
                  GO BACK TO DASHBOARD
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}

export default NotFound
