import { list_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, isblank, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getAccountSitesetting, getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import { LOADING_STATE, SNACKBAR_STATE, WIRETRANSFER_SEARCH_TEXT_UPDATE, WIRETRANSFER_SERVICE_FAIL, WIRETRANSFER_SERVICE_START, WIRETRANSFER_SERVICE_SUCCESS } from "./types"

export const fetch_wireTransfer_data = ({ companycode, rowsPerPage, page, order, orderBy, search_text, functions }) => {
  if (isblank(rowsPerPage)) {
    rowsPerPage = getAccountSitesetting("pagination_size")
  }

  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    dispatch({ type: WIRETRANSFER_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("offset", page * rowsPerPage)
    data.append("type", "wiretransfer")
    data.append("limit", rowsPerPage)
    data.append("search_text", search_text)

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "wiretransfer" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          wireTransferServiceFail({ dispatch, response: response.data.meta.message, rowsPerPage, page, order, orderBy, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          wireTransferServiceSuccess({ dispatch, user: response, rowsPerPage, page, order, orderBy, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          wireTransferServiceFail({ dispatch, response: getCatchErrorMessage(error), rowsPerPage, page, order, orderBy, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const wireTransferSearch_change = ({ search_text }) => {
  return (dispatch) => {
    dispatch({
      type: WIRETRANSFER_SEARCH_TEXT_UPDATE,
      search_text: search_text,
    })
  }
}

const wireTransferServiceSuccess = ({ dispatch, user, rowsPerPage, page, order, orderBy, search_text }) => {
  dispatch({
    type: WIRETRANSFER_SERVICE_SUCCESS,
    payload: user,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
  })
}

const wireTransferServiceFail = ({ dispatch, response, rowsPerPage, page, order, orderBy, search_text }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })

  dispatch({
    type: WIRETRANSFER_SERVICE_FAIL,
    payload: response,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
  })
}
