import cF from "@project/sharedcomponents/constantData/customFieldsEnum"
import {
  chunk,
  findNode,
  generatecustomFieldBodyV3,
  generateDetailHeaders,
  generateDetailPSValues,
  generateDetailValues,
  generateSignatureBoxes,
  getPrintValue,
  jnestedReplace,
  masterCustomFieldV2,
  removeTempField,
} from "./common"
import packingSlipMultiTemplate from "./inwardDataTemplates/packingSlipMultiTemplate"
import packingSlipTemplate from "./inwardDataTemplates/packingSlipTemplate"
import { amount_formatDecimal, camelize, DatetoDMY, isblank, numForCalc, replaceAllFn, replaceBRtoNewLine, replacefunction } from "./utilityFunctions"

export const multiPackingSlipPrint = ({ printdata, meta, pageSize, user_data, functions }) => {
  const { getAccountSitesetting } = functions
  const invoice_type_detail = getAccountSitesetting("invoice_type_detail", user_data)
  const inward_type_detail = getAccountSitesetting("inward_type_detail", user_data)
  var printType = "S"
  if (invoice_type_detail.filter((e) => e.id === meta.sales_invoice_type).length > 0) {
    printType = invoice_type_detail.find((e) => e.id === meta.sales_invoice_type).packing_slip_print
    if (printType === "M") {
      return packingSlipMultiPrint({ functions, printdata, meta, pageSize: "A4", user_data })
    } else {
      var pageSize = inward_type_detail.filter((e) => e.id === meta.invoice_type)[0].template_size === "1" ? "A5" : "A4"
      meta.type = "inward_type_detail"
      return packingSlipSinglePrint({ functions, print_data: printdata, meta, pageSize, user_data })
    }
  }
}

export const generatePackingSlipPdf = ({ printdata, meta, pageSize, user_data, functions }) => {
  var print_data = [printdata]
  return packingSlipSinglePrint({ functions, print_data, meta, pageSize, user_data })
}

export const packingSlipSinglePrint = ({ functions, print_data, meta, pageSize, user_data }) => {
  const { getAccountSitesetting, getAccountSessionUserData } = functions
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint", user_data)
  var MultiContent = []
  var Header = []
  var Content = []
  var repeat_header = 0
  var Grid_Line = 0
  var Print_Detail_narration = 0
  var pdfFontSizeSelectedValue = 8
  var pdfFontSelectedValue = "Roboto"
  var mainHeadingText = ""
  var headingText = ""
  var invoice_template = {}
  var termConditions = ""
  let outward_print_template = ""
  let inward_print_template = ""

  /* All Company Details */
  const company_data = getPrintValue(print_data[0], "company_data")
  var companyName = company_data.company_name
  var company_address1 = company_data.company_address1
  var gstin = company_data.company_gstin
  var company_email = company_data.company_email
  var pan = company_data.company_pan
  var aadhaar = company_data.company_aadhaar_no
  var company_address2 = getPrintValue(print_data[0], "company_address2")
  var companyContact = getPrintValue(print_data[0], "company_contact")
  var companyOtherNumbers = getPrintValue(print_data[0], "company_other_no")
  var companyLogo = getPrintValue(print_data[0], "company_logo")

  /* All Party Details */
  const party_data = getPrintValue(print_data[0], "party_data")
  var party_name = replacefunction(party_data.party_name)
  var party_address1 = party_data.party_address1
  var party_gstin = party_data.party_gstin
  var party_email = party_data.party_email
  var party_pan = party_data.party_pan
  var party_aadhaar = party_data.party_aadhaar_no
  var party_address2 = getPrintValue(print_data[0], "new_party_address")
  var partyContact = getPrintValue(print_data[0], "party_contact")

  var billno = print_data[0].master_table_data[0].billno_str
  var inwardDate = DatetoDMY(print_data[0].master_table_data[0].b_date)
  // var PartyName = print_data[0].user_details[0].name
  var MasterCustomField = print_data[0].custom_fields_data_master
  var agent = !isblank(print_data[0].agent_data) && print_data[0].agent_data.length > 0 ? print_data[0].agent_data[0].name : ""
  var transport = !isblank(print_data[0].transport_data) && print_data[0].transport_data.length > 0 ? print_data[0].transport_data[0].name : ""
  var lrno = !isblank(print_data[0].master_table_data[0].lrno) ? print_data[0].master_table_data[0].lrno : ""
  var lrdate = !isblank(print_data[0].master_table_data[0].lr_date) ? DatetoDMY(print_data[0].master_table_data[0].lr_date) : ""
  var godown = !isblank(print_data[0].master_table_data[0].godown_name) ? print_data[0].master_table_data[0].godown_name : ""
  var narration = !isblank(print_data[0].master_table_data[0].narration) ? replacefunction(replaceBRtoNewLine(print_data[0].master_table_data[0].narration)) : ""
  var ewaybill_no = !isblank(print_data[0].master_table_data[0].ewaybill_no) ? print_data[0].master_table_data[0].ewaybill_no : ""
  var vehicleNo = !isblank(print_data[0].master_table_data[0].vehicleNo) ? print_data[0].master_table_data[0].vehicleNo : ""

  var Print_company_logo = 0
  var Print_company_name = 0
  var Print_company_address = 0
  var Print_party_name = 0
  var Print_party_address = 0
  var Print_contactDetail = 0
  var Print_transport = 0
  var Print_agent = 0
  var fixSingleRow = 0
  var Print_godown = 0
  var Print_narration = 0
  var PageOrientation = "P"
  var title = ""
  var invoice_title = ""
  var signBoxesOn = ""
  var signBoxes = []
  const inwardSiteSettings = getAccountSitesetting(meta.type, user_data).filter((e) => e.id === meta.invoice_type)

  if (inwardSiteSettings.length > 0) {
    mainHeadingText = inwardSiteSettings[0].mainHeadingText
    termConditions = inwardSiteSettings[0].termConditions
    headingText = inwardSiteSettings[0].headingText
    repeat_header = parseInt(inwardSiteSettings[0].repeat_header)
    Grid_Line = parseInt(inwardSiteSettings[0].grid_line)
    Print_Detail_narration = parseInt(inwardSiteSettings[0].detailNarration)
    pdfFontSizeSelectedValue = inwardSiteSettings[0].pdfFontSizeSelectedValue
    outward_print_template = inwardSiteSettings[0].outward_print_template
    inward_print_template = inwardSiteSettings[0].inward_print_template
    fixSingleRow = parseInt(inwardSiteSettings[0].fixSingleRow)
    Print_company_logo = parseInt(inwardSiteSettings[0].print_company_logo)
    Print_company_name = parseInt(inwardSiteSettings[0].print_company_name)
    Print_company_address = parseInt(inwardSiteSettings[0].print_company_address)
    Print_party_name = parseInt(inwardSiteSettings[0].Print_party_name)
    Print_party_address = parseInt(inwardSiteSettings[0].print_party_address)
    Print_contactDetail = parseInt(inwardSiteSettings[0].contactDetail)
    PageOrientation = inwardSiteSettings[0].page_orientation
    title = inwardSiteSettings[0].name
    invoice_title = inwardSiteSettings[0].headingText
    signBoxesOn = parseInt(inwardSiteSettings[0].signature_box)
    signBoxes = inwardSiteSettings[0].__hidden_boxes

    if (isblank(pdfFontSizeSelectedValue)) {
      pdfFontSizeSelectedValue = 8
    }
    pdfFontSelectedValue = getAccountSitesetting(meta.type, user_data).filter((e) => e.id === meta.invoice_type)[0].pdfFontSelectedValue
    if (isblank(pdfFontSelectedValue)) {
      pdfFontSelectedValue = "Roboto"
    }
  }

  let print_header = true
  if (meta && meta.otherDetail && meta.otherDetail.print_without_header === "1") {
    print_header = false
  }

  if (isblank(outward_print_template)) {
    outward_print_template = "0"
  }

  if (isblank(inward_print_template)) {
    inward_print_template = "0"
  }

  if (isblank(invoice_title)) {
    invoice_title = ""
  }

  if (isblank(mainHeadingText)) {
    mainHeadingText = title
    /*if (meta.invoice_type === "delivery_challan") {
      mainHeadingText = "DELIVERY CHALLAN"
    } else {
      mainHeadingText = "PACKING SLIP"
    }*/
  }

  if (isblank(headingText)) {
    headingText = ""
  }

  let head_String = packingSlipTemplate.header
  head_String = replaceAllFn(head_String, "{center_text}", mainHeadingText)
  head_String = replaceAllFn(head_String, "{left_text}", invoice_title)
  head_String = replaceAllFn(head_String, "{right_text}", "")

  let headerString = packingSlipTemplate.bodyHeader

  headerString = replaceAllFn(headerString, "{logo}", companyLogo)
  headerString = replaceAllFn(headerString, "{companyName}", companyName)
  headerString = replaceAllFn(headerString, "{company_address1}", replacefunction(company_address1))
  headerString = replaceAllFn(headerString, "{company_address2}", replacefunction(company_address2))
  headerString = replaceAllFn(headerString, "{company_contact}", companyContact)
  headerString = replaceAllFn(headerString, "{company_gstin}", gstin)
  headerString = replaceAllFn(headerString, "{company_email}", company_email)
  headerString = replaceAllFn(headerString, "{companyOtherNumbers}", companyOtherNumbers)
  headerString = replaceAllFn(headerString, "{companyPAN}", pan)
  headerString = replaceAllFn(headerString, "{company_aadhaar}", aadhaar)
  headerString = replaceAllFn(headerString, "{title}", `${camelize(mainHeadingText)} No`)
  headerString = replaceAllFn(headerString, "{billno}", billno)
  headerString = replaceAllFn(headerString, "{inwardDate}", inwardDate)

  headerString = replaceAllFn(headerString, "{partyName}", party_name)
  headerString = replaceAllFn(headerString, "{party_address1}", replacefunction(party_address1))
  headerString = replaceAllFn(headerString, "{party_address2}", replacefunction(party_address2))
  headerString = replaceAllFn(headerString, "{party_gstin}", party_gstin)
  headerString = replaceAllFn(headerString, "{party_pan}", party_pan)
  headerString = replaceAllFn(headerString, "{party_aadhaar}", party_aadhaar)
  headerString = replaceAllFn(headerString, "{party_contact}", partyContact)
  headerString = replaceAllFn(headerString, "{party_email}", party_email)

  headerString = replaceAllFn(headerString, "{transport}", transport)
  headerString = replaceAllFn(headerString, "{lr_date}", lrdate)
  headerString = replaceAllFn(headerString, "{lr_no}", lrno)
  headerString = replaceAllFn(headerString, "{agent}", agent)
  headerString = replaceAllFn(headerString, "{godown}", godown)
  headerString = replaceAllFn(headerString, "{narration}", narration)
  headerString = replaceAllFn(headerString, "{ewaybill_no}", ewaybill_no)
  headerString = replaceAllFn(headerString, "{vehicle_no}", vehicleNo)

  let fieldsArray = [
    {
      field_id: "company_aadhaar",
      value: aadhaar,
    },
    {
      field_id: "company_gstin",
      value: gstin,
    },
    {
      field_id: "company_pan",
      value: pan,
    },
    {
      field_id: "company_contact",
      value: companyContact,
    },
    {
      field_id: "company_email",
      value: company_email,
    },
    {
      field_id: "company_OtherNumbers",
      value: companyOtherNumbers,
    },
    {
      field_id: "party_aadhaar",
      value: party_aadhaar,
    },
    {
      field_id: "party_gstin",
      value: party_gstin,
    },
    {
      field_id: "party_pan",
      value: party_pan,
    },
    {
      field_id: "party_contact",
      value: partyContact,
    },
  ]

  if (meta.invoice_type !== "job_inward") {
    fieldsArray.push({
      field_id: "process_info",
      value: "",
    })
  }

  if (Print_company_logo !== 1 || isblank(companyLogo)) {
    fieldsArray.push({
      field_id: "company_logo",
      value: "",
      width: 0,
    })
  }

  if (Print_company_name !== 1) {
    fieldsArray.push({
      field_id: "company_name",
      value: "",
    })
  }

  if (Print_company_address !== 1) {
    fieldsArray.push({
      field_id: "company_address",
      value: "",
    })
  }

  if (Print_party_name !== 1) {
    fieldsArray.push({
      field_id: "party_name",
      value: "",
    })
  }

  if (Print_party_address !== 1) {
    fieldsArray.push({
      field_id: "party_address",
      value: "",
    })
  }

  if (Print_contactDetail !== 1) {
    fieldsArray.push({
      field_id: "party_contact",
      value: "",
    })
  }

  if (Print_party_name !== 1 && Print_party_address !== 1 && Print_contactDetail !== 1) {
    fieldsArray.push({
      field_id: "billing_address_block",
      value: "",
    })
  }

  headerString = removeTempField(headerString, fieldsArray)

  if (Print_narration !== 1 || isblank(narration)) {
    headerString = JSON.parse(headerString)
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "narration", newValue: {} })
    headerString = JSON.stringify(headerString)
  }

  if (Print_agent !== 1) {
    headerString = JSON.parse(headerString)
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "agent_details", newValue: {} })
    headerString = JSON.stringify(headerString)
  }

  if (Print_godown !== 1) {
    headerString = JSON.parse(headerString)
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "godown_details", newValue: {} })
    headerString = JSON.stringify(headerString)
  }

  if (Print_transport !== 1) {
    headerString = JSON.parse(headerString)
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "trans_details", newValue: {} })
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "lr_details", newValue: {} })
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "eway_details", newValue: {} })
    headerString = JSON.stringify(headerString)
  }

  print_data.forEach((printdata) => {
    let tot_pcs = printdata.master_table_data[0].__cust_pcs
    let __cust_rowcount = printdata.master_table_data[0].__cust_rowcount
    if (isblank(tot_pcs)) {
      tot_pcs = ""
    }

    var invoice_type = meta.invoice_type
    var DetailCustomField = printdata.custom_fields_data

    var parameterized = printdata.master_table_data[0].prizedQty_flag
    if (isblank(parameterized)) {
      parameterized = false
    }

    let mainBody = []
    let mainBody2 = []
    if (outward_print_template === "2" || inward_print_template === "2") {
      let columnData = [
        { id: "sr_no", label: "No", width: 15 },
        { id: "__cust_avg_weight", numeric: true, label: "Wt Rng", width: 44, total: "label", decimal: inventory_decimalpoint },
        { id: "__cust_pcs", numeric: true, label: "Qty", width: "*", total: true, decimal: 0 },
        { id: "qty", numeric: true, label: "Gross Wt", width: 44, total: true, decimal: inventory_decimalpoint },
      ]
      let requireTotalRow = columnData.filter((e) => e.total === true).length > 0
      let tableWidth = columnData.map((e) => e.width)
      var tableValues = []
      let DetailGrid = []

      printdata.details_table_data.forEach((e) => {
        if (DetailGrid.filter((i) => i.itemname === e.itemname).length > 0) {
          DetailGrid = DetailGrid.map((i) => {
            const updated = { ...i }
            if (updated.itemname === e.itemname) {
              updated.detail = [...updated.detail, e]
            }
            return updated
          })
        } else {
          DetailGrid.push({
            itemname: e.itemname,
            detail: [e],
          })
        }
      })

      DetailGrid.forEach((it, index) => {
        let srNo = 0
        let itemNameRow = []
        columnData.forEach((e, cIndex) => {
          if (cIndex > 0) {
            itemNameRow.push({ text: "" })
          }
        })
        tableValues.push([{ text: it.itemname, bold: true, colSpan: columnData.length, alignment: "center" }, ...itemNameRow])
        let headerRow = []
        columnData.forEach((k) => {
          if (k.id === "sr_no") {
            headerRow.push({ text: k.label, bold: true })
          } else {
            headerRow.push({ text: k.label, bold: true, alignment: k.numeric ? "right" : "left" })
          }
        })
        tableValues.push(headerRow)
        let rowTotal = {}
        it.detail.forEach((j) => {
          let rowValue = []
          columnData.forEach((k) => {
            if (k.id === "sr_no") {
              srNo = srNo + 1
              rowValue.push({ text: srNo })
            } else {
              if (!isblank(k.decimal)) {
                rowValue.push({ text: amount_formatDecimal(j[k.id], k.decimal), alignment: k.numeric ? "right" : "left" })
              } else {
                rowValue.push({ text: j[k.id], alignment: k.numeric ? "right" : "left" })
              }
            }
            if (k.total === true) {
              rowTotal[k.id] = amount_formatDecimal(numForCalc(rowTotal[k.id], k.decimal) + numForCalc(j[k.id], k.decimal), k.decimal)
            }
          })
          tableValues.push(rowValue)
        })
        if (requireTotalRow) {
          let totalRow = []
          columnData.forEach((k) => {
            if (k.total === "label") {
              totalRow.push({ text: "Total", bold: true })
            } else if (k.total === true) {
              totalRow.push({ text: rowTotal[k.id], bold: true, alignment: k.numeric ? "right" : "left" })
            } else {
              totalRow.push({ text: "", bold: true, alignment: k.numeric ? "right" : "left" })
            }
          })
          tableValues.push(totalRow)
        }
        tableValues.push([{ text: "  ", colSpan: 4, border: [false, false, false, false] }, ...itemNameRow])
      })

      // FIXME: need new setting for dynamic this no of lines
      let n = fixSingleRow //tweak this to add more items per line
      const col = 3 //NO OF ROW

      const first_page = n * col
      let table_values = tableValues
      let table_values2 = []
      if (tableValues.length > first_page) {
        table_values = tableValues.slice(0, first_page)
        table_values2 = tableValues.slice(first_page, tableValues.length)
      }

      const result = new Array(Math.ceil(table_values.length / n)).fill().map((_) => table_values.splice(0, n))

      let detailTable = chunk(result, col, 1)

      detailTable.forEach((e, dIndex) => {
        let columns = []
        e.forEach((i) => {
          columns.push({
            table: {
              widths: tableWidth,
              body: [...i],
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0.5
              },
              vLineWidth: function (i, node) {
                return 0.5
              },
              hLineColor: function (i, node) {
                return "black"
              },
              vLineColor: function (i, node) {
                return "black"
              },
            },
            width: (595 - 70) / col,
          })
        })
        if (columns.length < col) {
          for (let index = 0; index <= col - columns.length; index++) {
            columns.push({ text: " ", width: "*", border: [false, false, false, false] })
          }
        }

        if (dIndex < detailTable.length - 1) {
          mainBody.push({ columns: columns, pageBreak: "after", columnGap: 5 })
        } else {
          mainBody.push({ columns: columns, columnGap: 5 })
        }
      })

      if (table_values2.length > 0) {
        mainBody2.push({ text: "", pageBreak: "after" })
        n = fixSingleRow + 10
        const result2 = new Array(Math.ceil(table_values2.length / n)).fill().map((_) => table_values2.splice(0, n))

        let detailTable2 = chunk(result2, col, 1)

        detailTable2.forEach((e, dIndex) => {
          let columns = []
          e.forEach((i) => {
            columns.push({
              table: {
                widths: tableWidth,
                body: [...i],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return 0.5
                },
                vLineWidth: function (i, node) {
                  return 0.5
                },
                hLineColor: function (i, node) {
                  return "black"
                },
                vLineColor: function (i, node) {
                  return "black"
                },
              },
              width: (595 - 70) / col,
            })
          })
          if (columns.length < col) {
            for (let index = 0; index <= col - columns.length; index++) {
              columns.push({ text: " ", width: "*", border: [false, false, false, false] })
            }
          }

          if (dIndex < detailTable.length - 1) {
            mainBody2.push({ columns: columns, pageBreak: "after", columnGap: 5 })
          } else {
            mainBody2.push({ columns: columns, columnGap: 5 })
          }
        })
      }
    } else {
      /* Single Packing Slip Content Print */
      var Values = []
      var detailTable = {}
      var HeaderObject = generateDetailHeaders({ functions, DetailCustomField, type: "packing_slip", pageSize, Print_Detail_narration, printdata })
      var Headers = HeaderObject.Headers
      var widthArray = HeaderObject.widthArray
      if (parameterized) {
        var parmeterizedCustomField = printdata.custom_fields_data_prmzd
        {
          functions, DetailCustomField, DetailGrid, MasterData, parameterized, user_data, Print_Detail_narration, parmeterizedCustomField
        }
        Values = generateDetailPSValues({
          functions,
          DetailCustomField,
          DetailGrid: printdata.details_table_data,
          MasterData: printdata.master_table_data[0],
          parameterized,
          user_data,
          Print_Detail_narration,
          parmeterizedCustomField,
        })
        detailTable = {
          headerRows: 1,
          widths: widthArray,
          body: [Headers, ...Values],
        }
      } else {
        Values = generateDetailValues({
          functions,
          DetailCustomField,
          DetailGrid: printdata.details_table_data,
          MasterData: printdata.master_table_data[0],
          parameterized,
          type: "packing_slip",
          user_data,
          Print_Detail_narration,
        })
        detailTable = {
          headerRows: 1,
          widths: widthArray,
          body: [Headers, ...Values],
        }
      }

      mainBody = [
        {
          table: detailTable,
        },
      ]
    }
    var stack1 = []
    var stack2 = []
    var CustomFieldArr = []

    //CustomFieldArr = [...CustomFieldArr, ...masterCustomField(MasterCustomField, printdata.master_table_data[0], "left", "valueLabel")]
    //let newbody = generatecustomFieldBodyV4(2, CustomFieldArr)

    headerString = JSON.parse(headerString)
    const customFieldStructure = findNode(headerString, "field_id", "customFieldNew")
    let customFieldBody = {}
    var CustomFieldArr = masterCustomFieldV2(MasterCustomField, printdata.master_table_data[0]) //get label and value

    var objQty = {
      label: "Tot Qty",
      value: amount_formatDecimal(printdata.master_table_data[0].totqty, inventory_decimalpoint),
    }
    CustomFieldArr.unshift(objQty)

    if (__cust_rowcount) {
      var objBundle = {
        label: "Tot Bundle",
        value: __cust_rowcount,
      }
      CustomFieldArr.unshift(objBundle)
    }
    customFieldBody = generatecustomFieldBodyV3(customFieldStructure, CustomFieldArr)
    if (customFieldBody.length > 0) {
      headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "customFieldNew", newValue: customFieldBody, replacePath: customFieldStructure.replacePath })
    } else {
      headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "customFieldNew", newValue: {} })
    }
    headerString = JSON.stringify(headerString)

    let customSignBoxes = {}
    if (!isblank(signBoxesOn) && signBoxesOn !== 0 && !isblank(signBoxes)) {
      customSignBoxes = generateSignatureBoxes(signBoxes)
    }

    let footer_String = packingSlipTemplate.bodyFooter

    var otherDetail = []
    if (!isblank(termConditions)) {
      otherDetail.push({
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: `Terms and Conditions`,
                bold: true,
              },
            ],
            [
              {
                text: termConditions,
                fontSize: parseFloat(pdfFontSizeSelectedValue) - 1,
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 ? 1 : 0
          },
          vLineWidth: function (i, node) {
            return 1
          },
          paddingBottom: function (i, node) {
            return i === 1 ? 5 : 2
          },
          paddingTop: function (i, node) {
            return i === 0 ? 5 : 2
          },
        },
      })
    }

    Content = [JSON.parse(headerString), ...mainBody, ...mainBody2, ...otherDetail, customSignBoxes, JSON.parse(footer_String)]
  })

  var dd = {
    // a string or { width: number, height: number }
    pageSize: pageSize,
    // by default we use portrait, you can change it to landscape if you wish
    pageOrientation: "portrait",
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: packingSlipTemplate.setting.pageMargins,
    header: JSON.parse(head_String),
    footer: function (currentPage, pageCount) {
      var rightText = ""
      if (pageCount !== 1) {
        rightText = `Pages ${currentPage}/${pageCount}`
      }
      return [
        {
          columns: [{ text: "" }, { text: "", style: "documentFooterCenter" }, { text: rightText, alignment: "right" }],
          margin: [0, 0, 30, 0],
        },
      ]
    },
    content: Content,
    defaultStyle: {
      fontSize: parseFloat(pdfFontSizeSelectedValue),
    },
    styles: packingSlipTemplate.style,
  }
  return dd
}

export const packingSlipMultiPrint = ({ functions, printdata, meta, pageSize, user_data }) => {
  const { getAccountSitesetting, getAccountSessionUserData } = functions
  let photo_base_url = getAccountSessionUserData("photo_base_url")

  /* All Company Details */
  const company_data = getPrintValue(printdata[0], "company_data")
  var companyName = company_data.company_name
  var company_address1 = company_data.company_address1
  var gstin = company_data.company_gstin
  var company_email = company_data.company_email
  var pan = company_data.company_pan
  var aadhaar = company_data.company_aadhaar_no
  var company_address2 = getPrintValue(printdata[0], "company_address2")
  var companyContact = getPrintValue(printdata[0], "company_contact")
  var companyOtherNumbers = getPrintValue(printdata[0], "company_other_no")
  var companyLogo = getPrintValue(printdata[0], "company_logo")
  var MasterCustomField = printdata[0].custom_fields_data_master

  /* All Party Details */
  const party_data = getPrintValue(printdata[0], "party_data")
  var party_name = party_data.party_name
  var party_address1 = party_data.party_address1
  var party_gstin = party_data.party_gstin
  var party_email = party_data.party_email
  var party_pan = party_data.party_pan
  var party_aadhaar = party_data.party_aadhaar_no
  var party_address2 = getPrintValue(printdata[0], "party_address2")
  var partyContact = getPrintValue(printdata[0], "party_contact")

  var Print_company_logo = 0
  var Print_company_name = 0
  var Print_company_address = 0
  var Print_party_name = 0
  var Print_party_address = 0
  var Print_contactDetail = 0

  var pdfFontSizeSelectedValue = 9
  var PageOrientation = "portrait"
  var title = ""
  var invoice_title = ""
  var mainHeadingText = ""
  var Print_Detail_narration = 0
  var signBoxesOn = ""
  var signBoxes = []
  var inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint", user_data)
  const inward_type_detail = getAccountSitesetting("inward_type_detail", user_data)

  const inwardSiteSettings = inward_type_detail.filter((e) => e.id === meta.invoice_type)
  if (inwardSiteSettings.length > 0) {
    Print_company_logo = parseInt(inwardSiteSettings[0].print_company_logo)
    Print_company_name = parseInt(inwardSiteSettings[0].print_company_name)
    Print_company_address = parseInt(inwardSiteSettings[0].print_company_address)
    Print_party_name = parseInt(inwardSiteSettings[0].Print_party_name)
    Print_party_address = parseInt(inwardSiteSettings[0].print_party_address)
    Print_contactDetail = parseInt(inwardSiteSettings[0].contactDetail)
    pdfFontSizeSelectedValue = inwardSiteSettings[0].pdfFontSizeSelectedValue
    PageOrientation = inwardSiteSettings[0].page_orientation
    title = inwardSiteSettings[0].name
    mainHeadingText = inwardSiteSettings[0].mainHeadingText
    invoice_title = inwardSiteSettings[0].headingText
    Print_Detail_narration = parseInt(inwardSiteSettings[0].detailNarration)
    signBoxesOn = parseInt(inwardSiteSettings[0].signature_box)
    signBoxes = inwardSiteSettings[0].__hidden_boxes

    if (isblank(mainHeadingText)) {
      mainHeadingText = title
    }
    if (isblank(invoice_title)) {
      invoice_title = ""
    }
  }

  let head_String = packingSlipMultiTemplate.header
  head_String = replaceAllFn(head_String, "{center_text}", invoice_title)
  head_String = replaceAllFn(head_String, "{left_text}", "")
  head_String = replaceAllFn(head_String, "{right_text}", "")

  let headerString = packingSlipMultiTemplate.bodyHeader

  headerString = replaceAllFn(headerString, "{logo}", companyLogo)
  headerString = replaceAllFn(headerString, "{companyName}", companyName)
  headerString = replaceAllFn(headerString, "{company_address1}", replacefunction(company_address1))
  headerString = replaceAllFn(headerString, "{company_address2}", replacefunction(company_address2))
  headerString = replaceAllFn(headerString, "{company_contact}", companyContact)
  headerString = replaceAllFn(headerString, "{company_gstin}", gstin)
  headerString = replaceAllFn(headerString, "{company_email}", company_email)
  headerString = replaceAllFn(headerString, "{companyOtherNumbers}", companyOtherNumbers)
  headerString = replaceAllFn(headerString, "{companyPAN}", pan)
  headerString = replaceAllFn(headerString, "{company_aadhaar}", aadhaar)

  headerString = replaceAllFn(headerString, "{partyName}", party_name)
  headerString = replaceAllFn(headerString, "{party_address1}", replacefunction(party_address1))
  headerString = replaceAllFn(headerString, "{party_address2}", replacefunction(party_address2))
  headerString = replaceAllFn(headerString, "{party_gstin}", party_gstin)
  headerString = replaceAllFn(headerString, "{party_pan}", party_pan)
  headerString = replaceAllFn(headerString, "{party_aadhaar}", party_aadhaar)
  headerString = replaceAllFn(headerString, "{party_contact}", partyContact)
  headerString = replaceAllFn(headerString, "{party_email}", party_email)

  let fieldsArray = [
    {
      field_id: "company_aadhaar",
      value: aadhaar,
    },
    {
      field_id: "company_gstin",
      value: gstin,
    },
    {
      field_id: "company_pan",
      value: pan,
    },
    {
      field_id: "company_contact",
      value: companyContact,
    },
    {
      field_id: "company_email",
      value: company_email,
    },
    {
      field_id: "company_OtherNumbers",
      value: companyOtherNumbers,
    },
    {
      field_id: "party_aadhaar",
      value: party_aadhaar,
    },
    {
      field_id: "party_gstin",
      value: party_gstin,
    },
    {
      field_id: "party_pan",
      value: party_pan,
    },
    {
      field_id: "party_contact",
      value: partyContact,
    },
  ]

  if (Print_company_logo !== 1 || isblank(companyLogo)) {
    fieldsArray.push({
      field_id: "company_logo",
      value: "",
      width: 0,
    })
  }

  if (Print_company_name !== 1) {
    fieldsArray.push({
      field_id: "company_name",
      value: "",
    })
  }

  if (Print_company_address !== 1) {
    fieldsArray.push({
      field_id: "company_address",
      value: "",
    })
  }

  if (Print_party_name !== 1) {
    fieldsArray.push({
      field_id: "party_name",
      value: "",
    })
  }

  if (Print_party_address !== 1) {
    fieldsArray.push({
      field_id: "party_address",
      value: "",
    })
  }

  if (Print_contactDetail !== 1) {
    fieldsArray.push({
      field_id: "party_contact",
      value: "",
    })
  }

  let images = {}

  MasterCustomField.filter((e) => parseInt(e.dataType) === cF.Photo && e.showInPrint === "1").forEach((e, index) => {
    if (!isblank(printdata.master_table_data[0][e.name])) {
      images[`${printdata.master_table_data[0][e.name]}`] = `${photo_base_url}${printdata.master_table_data[0][e.name]}`
    }
  })

  MasterCustomField.filter((e) => parseInt(e.dataType) === cF.Dropbox && e.showInPrint === "1").forEach((e, index) => {
    if (!isblank(printdata.master_table_data[0][e.name])) {
      printdata.master_table_data[0][e.name].forEach((i, iindex) => {
        images[i.name] = replaceDropBoxUrl(i.link)
      })
    }
  })

  headerString = removeTempField(headerString, fieldsArray)

  var tableValues = []

  printdata.forEach((item) => {
    var DetailCustomField = item.custom_fields_data
    if (!isblank(item.master_table_data[0].billno_str)) {
      tableValues.push([{ text: "Pkg Slip No:", margin: [0, 0, 0, 0], bold: true }, { text: `${item.master_table_data[0].billno_str}` }, { text: "" }, { text: "" }])
    } else {
      tableValues.push([{ text: "Pkg Slip No:", margin: [0, 0, 0, 0], bold: true }, { text: `${item.master_table_data[0].billno}` }, { text: "" }, { text: "" }])
    }
    tableValues.push([
      { text: "Bale No:", margin: [0, 0, 0, 0], bold: true },
      { text: `${!isblank(item.master_table_data[0].__cust_bale_no) ? item.master_table_data[0].__cust_bale_no : ""}` },
      { text: "Date:", margin: [0, 0, 0, 0], bold: true },
      { text: `${DatetoDMY(item.master_table_data[0].b_date)}` },
    ])

    var ItemArray = []
    item.details_table_data.forEach((it, index) => {
      var OtherString = ""
      var ItemName = `${index + 1}. ${it.itemname}`
      if (Print_Detail_narration > 0) {
        OtherString = `${it.narration}`
      }

      if (DetailCustomField.length > 0) {
        DetailCustomField.filter(function (customItem) {
          return customItem.showInPrint === "1"
        }).forEach((customItem, customIndex) => {
          var CustomData =
            parseInt(customItem.dataType) === cF.Decimal
              ? amount_formatDecimal(it[customItem.name], customItem.decimal)
              : parseInt(customItem.dataType) === cF.Date
                ? DatetoDMY(it[customItem.name])
                : it[customItem.name]
          if (!isblank(CustomData)) {
            if (!isblank(OtherString)) {
              OtherString = `${OtherString}, ${customItem.labelName}: ${CustomData}`
            } else {
              OtherString = `${customItem.labelName}: ${CustomData}`
            }
          }
        })
      }

      if (!isblank(OtherString)) {
        ItemName = ItemName + "\n" + OtherString
      }
      ItemArray = [{ text: ItemName, margin: [0, 0, 0, 0], colSpan: 3 }, {}, {}]
      tableValues.push([...ItemArray, { text: amount_formatDecimal(it.qty, inventory_decimalpoint), alignment: "right" }])
    })

    tableValues.push([
      { text: "Total", alignment: "right", bold: true, colSpan: 3 },
      {},
      {},
      { text: amount_formatDecimal(item.master_table_data[0].totqty, inventory_decimalpoint), alignment: "right", bold: true },
    ])
    tableValues.push([{ text: "", border: [false, false, false, false], margin: [0, 0, 0, 0], colSpan: 3 }, {}, {}, { text: "", border: [false, false, false, false], margin: [0, 0, 0, 0] }])
  })

  var PrintArray = []
  tableValues.forEach((item, index) => {
    PrintArray.push(item)
  })

  // FIXME: need new setting for dynamic this no of lines
  const n = 25 //tweak this to add more items per line

  const result = new Array(Math.ceil(PrintArray.length / n)).fill().map((_) => PrintArray.splice(0, n))

  var newArray1 = []
  var newArray2 = []
  result.forEach((item, index) => {
    if (index % 2 === 0) {
      newArray1 = [...newArray1, ...item]
    } else {
      newArray2 = [...newArray2, ...item]
    }
  })

  if (newArray1.length === 0) {
    newArray1.push([{ text: "", colSpan: 4, border: [false, false, false, false] }, {}, {}, {}])
  }
  if (newArray2.length === 0) {
    newArray2.push([{ text: "", colSpan: 4, border: [false, false, false, false] }, {}, {}, {}])
  }

  var detailTable = {
    //style: "noMarginTable",
    table: {
      widths: ["*", "*"],
      body: [
        [
          {
            style: "contentTable",
            table: {
              widths: ["*", "*", "*", "*"],
              body: [...newArray1],
            },
          },
          {
            style: "contentTable",
            table: {
              widths: ["*", "*", "*", "*"],
              body: [...newArray2],
            },
          },
        ],
      ],
    },
    layout: "noBorders",
  }

  let customSignBoxes = {}
  if (!isblank(signBoxesOn) && signBoxesOn !== 0 && !isblank(signBoxes)) {
    customSignBoxes = generateSignatureBoxes(signBoxes)
  }

  let footer_String = packingSlipMultiTemplate.bodyFooter

  var dd = {
    // a string or { width: number, height: number }
    pageSize: pageSize,
    // by default we use portrait, you can change it to landscape if you wish
    pageOrientation: PageOrientation,
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: packingSlipTemplate.setting.pageMargins,
    header: JSON.parse(head_String),
    content: [JSON.parse(headerString), detailTable, customSignBoxes, JSON.parse(footer_String)],
    images,
    defaultStyle: {
      fontSize: parseFloat(pdfFontSizeSelectedValue),
    },
    styles: packingSlipMultiTemplate.style,
  }
  return dd
}
