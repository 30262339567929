import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import { isblank, validateGSTIN } from "@project/sharedcomponents/utilityFunctions"
import React from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { lastPageDataSet, showdrawerdialog, snackbarstate } from "../../Actions"
import { getAccountSessionUserData } from "../../Lib/commonfunctions"
import AddressComponentV2 from "../common/AddressComponentV2"
import Field from "../common/reduxFormComponents"

const ModalAdditionalAddress = (props) => {
  let defaultValues = {
    countryid: getAccountSessionUserData("company_country"),
  }

  var id = ""
  const { sidebarloading, loading, ConfirmDialogData } = useSelector((state) => state.CommonReducer)
  if (!isblank(ConfirmDialogData) && ConfirmDialogData.action === "edit") {
    defaultValues = ConfirmDialogData.formdata
    id = ConfirmDialogData.formdata.id
  }
  const { handleSubmit, control, reset, watch, setValue } = useForm({ defaultValues })
  const formValues = watch()
  const dispatch = useDispatch()
  const formaction = ConfirmDialogData.action

  const handleClose = () => {
    dispatch(showdrawerdialog({ yesno: false, confirm: false, title: "Additional Shipping Address", form: "Add additional address" }))
  }

  const onSubmitData = (values) => {
    const lastdata = JSON.parse(JSON.stringify(values))
    var lastaction = ""
    if (formaction === "edit") {
      lastaction = "update"
      lastdata.id = id
    } else {
      lastdata.id = new Date().getTime()
      lastaction = "insert"
    }
    dispatch(lastPageDataSet({ lasttype: "additionalAddress", lastaction, lastdata }))
    dispatch(showdrawerdialog({ yesno: false, confirm: false, title: "Additional Shipping Address", form: "Add additional address" }))
  }

  const onSubmitError = (values) => {
    const err = Object.keys(values)
    dispatch(snackbarstate({ open: true, message: values[err[0]].message, snackbarType: "error" }))
  }

  const handleSave = () => {
    handleSubmit(onSubmitData, onSubmitError)()
  }

  const gstinValidate = (values) => {
    if (!validateGSTIN(values)) {
      return ErrorMessages["E00004"]
    }
  }
  return (
    <div>
      <React.Fragment>
        <DialogContent>
          <Field name="name" inputType={"RenderTextField"} control={control} label="TRADE NAME" isRequired={true} placeholder="Enter trade name" rules={{ required: ErrorMessages.E00101 }} />
          <Field name="gstin" inputType={"RenderTextField"} control={control} label="GSTIN" placeholder="Enter gstin" rules={{ validate: gstinValidate }} />

          <AddressComponentV2
            control={control}
            formValues={formValues}
            watch={watch}
            reset={reset}
            setValue={setValue}
            dispatch={dispatch}
            address="address"
            street="areacode"
            zipcode="pincode"
            country="countryid"
            state="stateid"
            city="cityid"
            cityType="dropDown"
            district="districtid"
            require={{
              countryid: ErrorMessages.E00133,
              stateid: ErrorMessages.E00104,
              pincode: ErrorMessages.E00105,
              address: ErrorMessages.E00103,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={sidebarloading | loading ? true : false} variant="text">
            Dismiss
          </Button>
          <Button variant="text" onClick={handleSave} disabled={sidebarloading | loading ? true : false} color="primary">
            {formaction === "edit" ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </React.Fragment>
    </div>
  )
}

export default ModalAdditionalAddress
