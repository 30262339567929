var outputStr = []
var _axiosToken
import cF from "@project/sharedcomponents/constantData/customFieldsEnum"
import re from "./constantData/reportsEnum"
import { getsessionuserdata } from "@project/components/sessionStoreFunctions"
import { getproducttype, getproducttypegold } from "@project/components/utilityFunctions"
import aTP from "@project/sharedcomponents/constantData/accountPackage"

export const getewaybillstatus = () => {
  return [
    {
      id: "0",
      name: "Not Generated",
    },
    {
      id: "1",
      name: "Generated",
    },
    {
      id: "2",
      name: "Cancelled",
    },
    {
      id: "3",
      name: "Pending",
    },
  ]
}

export const getAxiosCancelToken = () => {
  return _axiosToken
}

export const setAxiosCancelToken = (token) => {
  _axiosToken = token
}

export const getTaxTypeName = (number) => {
  var num = numForCalc(number)
  if (num === 0) {
    return ""
  } else if (num === 1) {
    return "GSTIN"
  } else if (num === 2) {
    return "TAX No"
  } else if (num === 3) {
    return "VAT No"
  } else {
    return ""
  }
}

export const getyesno = () => {
  return [
    {
      id: "0",
      name: "No",
    },
    {
      id: "1",
      name: "Yes",
    },
  ]
}

export const todaydate = (format) => {
  var currentDate = new Date()
  var twoDigitMonth = currentDate.getMonth() + 1 >= 10 ? currentDate.getMonth() + 1 : "0" + (currentDate.getMonth() + 1)
  var twoDigitDate = currentDate.getDate() >= 10 ? currentDate.getDate() : "0" + currentDate.getDate()

  if (format === "YMD") {
    return currentDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate
  } else if (format === "DMY") {
    return twoDigitDate + "-" + twoDigitMonth + "-" + currentDate.getFullYear()
  }
}

export const replaceAllFn = (datastring, replacing, replaceBy) => {
  if (typeof String.prototype.replaceAll == "undefined") {
    String.prototype.replaceAll = function (match, replace) {
      return this.replace(new RegExp(match, "g"), () => replace)
    }
  }
  if (isblank(datastring)) {
    return ""
  }
  return datastring.replaceAll(replacing, replaceBy)
}

export function cleanStringForPDFMake(inputString) {
  // Remove control characters except for newline (\n)
  const cleanedStringWithoutControl = inputString.replace(/[\x00-\x08\x0B\x0C\x0E-\x1F\x7F-\x9F]/g, "")
  // Remove unsupported special characters except for newline (\n)
  const cleanedString = cleanedStringWithoutControl.replace(/[^\x20-\x7E\u00A0-\u2027\u202F-\uFFFF\n]/g, "")
  return cleanedString
}

export const replaceAllFnPrint = (datastring, replacing, replaceBy) => {
  if (typeof String.prototype.replaceAll == "undefined") {
    String.prototype.replaceAll = function (match, replace) {
      return this.replace(new RegExp(match, "g"), () => replace)
    }
  }
  if (isblank(datastring)) {
    return ""
  }
  let replaceDoubleQuote = !isblank(replaceBy) ? replaceBy.replace(/"/g, '\\"') : ""
  replaceDoubleQuote = cleanStringForPDFMake(replaceDoubleQuote)
  return datastring.replaceAll(replacing, replaceDoubleQuote)
}

export const checkCondition = (value, acceptArray, condition) => {
  const newArray = acceptArray.map((e) => convertIntif(e))
  if (condition === "not") {
    return !newArray.includes(convertIntif(value))
  }
  return newArray.includes(convertIntif(value))
}

export const createDropDown = (dropDownItem, value, prop) => {
  if (!isblank(value)) {
    if (value.hasOwnProperty(prop)) {
      return dropDownItem.find((item) => convertIntif(item[prop]) === convertIntif(value[prop]))
    } else {
      return dropDownItem.find((item) => convertIntif(item[prop]) === convertIntif(value))
    }
  } else {
    return ""
  }
}

export const getPhoneCodeByCountry = (country, dd_country_state) => {
  if (isblank(country)) {
    return ""
  }
  var countryData = dd_country_state.find((e) => convertIntif(e.id) === convertIntif(country))
  if (!isblank(countryData) && !isblank(countryData.phone_code)) {
    return countryData.phone_code
  }
  return ""
}

export const getAllStatesOfcountry = (countryid, dd_country_state) => {
  if (dd_country_state.filter((e) => convertIntif(e.id) === convertIntif(countryid)).length > 0) {
    return dd_country_state.find((e) => convertIntif(e.id) === convertIntif(countryid)).states
  }
  return []
}

export const getCountryDataById = (countryid, dd_country_state) => {
  if (isblank(countryid)) {
    return {}
  }
  var returnValue = dd_country_state.find((e) => convertIntif(e.id) === convertIntif(countryid))
  if (!isblank(returnValue)) {
    return returnValue
  }
  return {}
}

export const convertIntif = (str) => {
  if (isblank(str)) {
    return ""
  } else if (str === []) {
    return []
  } else {
    if (!isNaN(str)) {
      return parseInt(str)
    } else {
      return str
    }
  }
}

export const isblank = (value) => {
  if (value === undefined || value === null || value === "") {
    return true
  }
  return false
}

export const isValidDate = (value) => {
  if (isblank(value)) {
    return false
  }
  if (value === "00-00-0000" || value === "0000-00-00" || value === "00-00-00") {
    return false
  }
  return true
}

export const isBlankCode = (value) => {
  if (value === undefined || value === null || value === "" || convertIntif(value) === 0) {
    return true
  }
  return false
}

export const batchDecode = (value) => {
  if (isblank(value)) {
    return ""
  }
  if (typeof value === "string") {
    if (value !== "") {
      return JSON.parse(decodeURIComponent(value))
    }
  } else {
    return value
  }
}

export const cloneObjV2 = (source) => {
  if (Array.isArray(source)) {
    return [...source]
  } else if (is_object(source)) {
    return { ...source }
  } else {
    return source
  }
}

export const is_object = (obj) => {
  if ((typeof obj === "object" || obj instanceof Object) && obj !== null) {
    return true
  }
  return false
}

export const cloneObj = (source) => {
  var clone = []

  if (Object.prototype.toString.call(source) === "[object Array]") {
    for (var i = 0; i < source.length; i++) {
      clone[i] = cloneObj(source[i])
    }
    return clone
  } else if (typeof source === "object") {
    if (isblank(source)) {
      return ""
    }
    clone = {}
    for (var prop in source) {
      if (source.hasOwnProperty(prop)) {
        clone[prop] = cloneObj(source[prop])
      }
    }
    return clone
  } else {
    return source
  }
}

export const getNumberonlyDecimal = (value, decimal, ifNaNReturn) => {
  if (value !== undefined && isNaN(value) && value !== null) {
    if (value.toString() === "NaN") {
      if (ifNaNReturn && ifNaNReturn.toString() === "NaN") {
        return value
      }
      return toFixedFn(parseFloat("0"), decimal)
    } else {
      /* eslint-disable-next-line */
      let returnValue = toFixedFn(parseFloat(value.replace(/[^0-9\.-]+/g, "")), decimal)
      if (isNaN(returnValue)) {
        if (ifNaNReturn && ifNaNReturn.toString() === "NaN") {
          return returnValue
        }
        return toFixedFn(parseFloat("0"), decimal)
      }
      return returnValue
    }
  } else {
    if (isblank(value)) {
      return toFixedFn(parseFloat("0"), decimal)
    }

    return toFixedFn(parseFloat(value), decimal)
  }
}

export const getNumberonly = (value, ifNaNReturn) => {
  if (isblank(value)) {
    return toFixedFn(parseFloat("0"))
  }
  if (typeof value !== "string" && typeof value !== "number" && typeof value !== "bigint") {
    return ""
  }
  if (!isblank(value) && isNaN(value)) {
    if (value.toString() === "NaN") {
      if (ifNaNReturn && ifNaNReturn.toString() === "NaN") {
        return returnValue
      }
      return toFixedFn(parseFloat("0"))
    } else {
      /* eslint-disable-next-line */
      let returnValue = toFixedFn(parseFloat(value.replace(/[^0-9\.-]+/g, "")))
      if (isNaN(returnValue)) {
        return toFixedFn(parseFloat("0"))
      }
      return returnValue
    }
  } else {
    if (isblank(value)) {
      return toFixedFn(parseFloat("0"))
    }
    return toFixedFn(parseFloat(value))
  }
}

export const isNaNValue = (value) => {
  if (isblank(value)) {
    return false
  }
  if (typeof value !== "string" && typeof value !== "number" && typeof value !== "bigint") {
    return true
  }
  if (!isblank(value) && isNaN(value)) {
    if (value.toString() === "NaN") {
      return true
    } else {
      /* eslint-disable-next-line */
      let returnValue = parseFloat(replaceAllFn(value, ",", ""))
      return isNaN(returnValue)
    }
  }
  return false
}

export const DatetoYMD = (date) => {
  if (isblank(date)) {
    return ""
  } else if (typeof date === "string" || date instanceof String) {
    var dateString = date.split("-")
    var str = ""
    if (dateString[0] && dateString[1] && dateString[2]) {
      if (parseFloat(dateString[0]) > 31) {
        str = dateString[0] + "-" + dateString[1] + "-" + dateString[2]
        return str
      } else {
        str = dateString[2] + "-" + dateString[1] + "-" + dateString[0]
        return str
      }
    } else {
      return date
    }
  } else {
    return ""
  }
}
export const getDropDownPostValue = (value) => {
  var obj = ""
  if (isblank(value)) {
    return obj
  } else {
    if (value.hasOwnProperty("code")) {
      obj = value.code
    } else if (value.hasOwnProperty("id")) {
      obj = value.id
    } else {
      obj = value
    }
    return obj
  }
}
export const getDropDownObj = (value, field) => {
  var obj = ""

  if (isblank(value)) {
    return obj
  } else {
    if (value.hasOwnProperty(field)) {
      obj = value[field]
    } else {
      obj = value
    }
    return obj
  }
}
export const getAllOrderStatus = () => {
  return [
    {
      id: "0",
      name: "Pending",
    },
    {
      id: "5",
      name: "Confirmed",
    },
    {
      id: "4",
      name: "Cancelled",
    },
    {
      id: "2",
      name: "Closed",
    },
    {
      id: "1",
      name: "Partially",
    },
    {
      id: "3",
      name: "Ready to ship",
    },
    {
      id: "0,1",
      name: "Pending + Partially",
    },
  ]
}

export const getMultidropdownfieldvalue = ({ dropDownData, field, value, displayvalue }) => {
  var returnValue = ""
  if (!isblank(value) && typeof value === "object" && value.length > 0) {
    value.forEach((e, index) => {
      if (index === 0) {
        returnValue = getdropdownfieldvalue({ dropDownData, field, value: e, displayvalue })
      } else {
        returnValue = `${returnValue}, ${getdropdownfieldvalue({ dropDownData, field, value: e, displayvalue })}`
      }
    })
  }
  return returnValue
}

export const getdropdownfieldvalue = ({ dropDownData, field, value, displayvalue }) => {
  if (!isblank(value) && typeof value === "object" && !isblank(value[field]) && !isblank(value[displayvalue])) {
    return value[displayvalue]
  }
  let val = value
  if (typeof value === "object") {
    val = getDropDownPostValue(value)
  }
  if (!isblank(dropDownData) && dropDownData.find((item) => convertIntif(item[field]) === convertIntif(val))) {
    return dropDownData.find((item) => convertIntif(item[field], 10) === convertIntif(val, 10))[displayvalue]
  } else if (!isblank(val)) {
    return val
  }
  return ""
}

export const DMYtoYMD = (DMY) => {
  if (isblank(DMY)) {
    return ""
  } else if (DMY === "00-00-0000") {
    return "00-00-0000"
  } else if (typeof DMY === "string" || DMY instanceof String) {
    var dateString = DMY.split("-")
    var str = ""
    if (dateString[0] && dateString[1] && dateString[2]) {
      str = dateString[2] + "-" + dateString[1] + "-" + dateString[0]
      return str
    } else {
      return DMY
    }
  } else {
    return ""
  }
}

export const DatetoDMY = (YMD) => {
  if (isblank(YMD)) {
    return ""
  } else if (YMD === "0000-00-00") {
    return "00-00-0000"
  } else if (typeof YMD === "string" || YMD instanceof String) {
    var dateString = YMD.split("-")
    var str = ""
    if (dateString[0] && dateString[1] && dateString[2]) {
      if (parseFloat(dateString[0]) > 31) {
        str = dateString[2] + "-" + dateString[1] + "-" + dateString[0]
        return str
      } else {
        str = dateString[0] + "-" + dateString[1] + "-" + dateString[2]
        return str
      }
    } else {
      return YMD
    }
  } else {
    return ""
  }
}

export const DatetoYM = (YMD) => {
  if (isblank(YMD)) {
    return ""
  } else if (YMD === "0000-00-00") {
    return "0000-00"
  } else if (typeof YMD === "string" || YMD instanceof String) {
    var dateString = YMD.split("-")
    var str = ""
    if (dateString[0] && dateString[1] && dateString[2]) {
      if (parseFloat(dateString[0]) > 31) {
        str = dateString[0] + "-" + dateString[1]
        return str
      } else {
        str = dateString[2] + "-" + dateString[1]
        return str
      }
    } else {
      return YMD
    }
  } else {
    return ""
  }
}

export const getMultiDropDownPostValue = (values) => {
  if (isblank(values)) {
    return []
  }
  const newArray = []
  values.forEach((e) => {
    if (e.hasOwnProperty("value")) {
      if (!isblank(e.value)) {
        newArray.push(e.value)
      }
    } else if (!isblank(e)) {
      newArray.push(e)
    }
  })
  return newArray
}

export const makeExcelRequiredData = ({ data, column, functions }) => {
  const { getAccountSitesetting } = functions
  const siteSettingForformatField = {
    inventory_decimalpoint: getAccountSitesetting("inventory_decimalpoint"),
    rate_decimalpoint: getAccountSitesetting("rate_decimalpoint"),
    timezone: getAccountSitesetting("timezone"),
  }
  var newData = data.map((e) => {
    const updatedItem = { ...e }
    column.forEach((i) => {
      updatedItem[i.id] = format_excel_field({ value: updatedItem[i.id], format: i.format, localSetting: siteSettingForformatField })
    })
    return updatedItem
  })

  var Mycol = {}
  for (var i = 0; i < column.length; i++) Mycol[column[i].id] = column[i].label

  newData.unshift(Mycol)
  var columnIds = column.map((e) => e.id)
  newData = newData.map((item) => {
    const updatedItem = {}

    Object.keys(item).forEach((e) => {
      if (columnIds.includes(e)) {
        updatedItem[e] = JSON.parse(JSON.stringify(item[e]))
      }
    })
    return updatedItem
  })
  return newData
}

export const makeExcelRequiredDataOnly = ({ data, column, siteSettingForformatField, reportid }) => {
  var newData = data.map((e) => {
    const updatedItem = { ...e }

    column.forEach((i) => {
      updatedItem[i.id] = format_excel_field({ value: updatedItem[i.id], format: i.format, localSetting: siteSettingForformatField })
    })
    return updatedItem
  })

  /*var Mycol = {}
  for (var i = 0; i < column.length; i++) Mycol[column[i].id] = column[i].label
  newData.unshift(Mycol)
  */
  var columnIds = column.map((e) => e.id)
  newData = newData.map((item) => {
    const updatedItem = []

    if (checkCondition(reportid, [re.BalanceSheet, re.P_LAccount, re.TradingWithP_LReport, re.TradingAccount])) {
      updatedItem["d_bold"] = item["d_bold"]
      updatedItem["c_bold"] = item["c_bold"]
    }

    if (checkCondition(reportid, [re.MultiOutstandingReport, re.MultiOutstandingAgeingReport, re.OutstandingReport, re.OutstandingAgeingReport])) {
      updatedItem["___isBold"] = item["___isBold"]
    }

    if (checkCondition(reportid, [re.FinishStockRegister, re.GoodsReceivedNoteRegister, re.JobInwardRegister, re.ManageLotRegister, re.DeliveryChallanRegister])) {
      if (parseInt(item["Status"]) === 0) {
        item["Status"] = "Pending"
      } else if (parseInt(item["Status"]) === 1) {
        item["Status"] = "Partially"
      } else if (parseInt(item["Status"]) === 2) {
        item["Status"] = "Closed"
      }
    }

    columnIds.forEach((e) => {
      updatedItem.push(JSON.parse(JSON.stringify(item[e])))
    })

    return updatedItem
  })
  return newData
}

export const makeExcelRequireCol = (mycol, getAccountSitesetting) => {
  const siteSettingForformatField = {
    inventory_decimalpoint: getAccountSitesetting("inventory_decimalpoint"),
    rate_decimalpoint: getAccountSitesetting("rate_decimalpoint"),
  }
  const MYCOL = mycol.map((item) => {
    const updatedItem = { ...item }
    if (updatedItem.numeric) {
      if (updatedItem.format === "indian_rupee") {
        updatedItem.fmt = "0.00"
      } else if (updatedItem.customField && updatedItem.customFieldType === "2") {
        updatedItem.fmt = getNumberonlyDecimal(0, updatedItem.decimalPoint)
      } else if (updatedItem.format === "qty_decimal") {
        updatedItem.fmt = getNumberonlyDecimal(0, siteSettingForformatField.inventory_decimalpoint)
      } else if (updatedItem.format === "rate_decimal") {
        updatedItem.fmt = getNumberonlyDecimal(0, siteSettingForformatField.rate_decimalpoint)
      }
    }
    return updatedItem
  })
  return MYCOL
}

export const sortByDate = (ArrayData, dateField) => {
  return ArrayData.slice().sort((a, b) => {
    var tempA = a[dateField]
    var tempB = b[dateField]
    tempA = DatetoDMY(tempA)
    tempB = DatetoDMY(tempB)
    var a1 = new Date(DMYStrtoDate(tempA)).getTime()
    var b1 = new Date(DMYStrtoDate(tempB)).getTime()
    if (a1 > b1) return 1
    else if (a1 < b1) return -1
    else return 0
  })
}

export const getAllStates = (getAccountSessionUserData, dd_country_state) => {
  var countryCode = getAccountSessionUserData("company_country")
  if (!isblank(dd_country_state) && dd_country_state.filter((e) => convertIntif(e.id) === convertIntif(countryCode)).length > 0) {
    return dd_country_state.find((e) => convertIntif(e.id) === convertIntif(countryCode)).states
  }
  return []
}

export const DMYStrtoDate = (dateString) => {
  if (isblank(dateString)) {
    return ""
  }
  const dateParts = dateString.split("-")
  const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
  return dateObject
}

export const YMDStrtoDate = (dateString) => {
  if (isblank(dateString)) {
    return ""
  }
  const dateParts = dateString.split("-")
  const dateObject = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2])
  return dateObject
}

export const getCustomFieldColumn = ({ ddcustomfield, type, data, rowValue, dS }) => {
  var allCustomFields = []
  if (type === "detail") {
    allCustomFields = ddcustomfield.filter((e) => e.masterDetailLevel === "1")
  } else if (type === "detailLevel2") {
    allCustomFields = ddcustomfield.filter((e) => e.masterDetailLevel === "2")
  } else {
    allCustomFields = ddcustomfield.filter((e) => e.masterDetailLevel === "0" && (e.showInList === "1" || e.showInPrint === "1"))
  }

  var column = []
  if (allCustomFields.length > 0) {
    allCustomFields.forEach((item) => {
      let render = true
      if (!isblank(data)) {
        render = checkRenderConditionV1({ f: item, data, other_type: type, rowValue, dS })
      }

      if (render) {
        if (parseInt(item.dataType) === cF.Dropdown || parseInt(item.dataType) === cF.Creatable_Dropdown) {
          if (item.isNumeric === "1") {
            var requireTotal = false
            if (item.cal_total === "1" || item.calculateTotal === "1") {
              requireTotal = true
            }
            if (requireTotal) {
              column.push({
                id: `${item.name}`,
                customFieldType: item.dataType,
                numeric: true,
                total: requireTotal,
                visible: true,
                customField: true,
                decimalPoint: item.decimalPoint ? convertIntif(item.decimalPoint) : 0,
                label: `${item.labelName}`,
                width: item.gridWidth,
                aggregate: "sum",
                Aggregated: ({ value }) => `${value}`,
              })
            } else {
              column.push({
                id: `${item.name}`,
                customFieldType: item.dataType,
                numeric: true,
                total: requireTotal,
                visible: true,
                customField: true,
                decimalPoint: item.decimalPoint ? convertIntif(item.decimalPoint) : 0,
                label: `${item.labelName}`,
                width: item.gridWidth,
              })
            }
          } else {
            column.push({ id: `${item.name}`, customFieldType: item.dataType, numeric: false, visible: true, customField: true, label: `${item.labelName}`, width: item.gridWidth, canGroup_By: true })
          }
        } else if (parseInt(item.dataType) === cF.Decimal) {
          if (type === "detail") {
            var requireTotal = false
            if (item.cal_total === "1" || item.calculateTotal === "1") {
              requireTotal = true
            }
          }
          if (requireTotal) {
            column.push({
              id: `${item.name}`,
              customFieldType: item.dataType,
              numeric: true,
              total: requireTotal,
              visible: true,
              customField: true,
              decimalPoint: item.decimalPoint ? convertIntif(item.decimalPoint) : 0,
              label: `${item.labelName}`,
              width: item.gridWidth,
              aggregate: "sum",
              Aggregated: ({ value }) => `${value}`,
            })
          } else {
            column.push({
              id: `${item.name}`,
              customFieldType: item.dataType,
              numeric: true,
              total: requireTotal,
              visible: true,
              customField: true,
              decimalPoint: item.decimalPoint ? convertIntif(item.decimalPoint) : 0,
              label: `${item.labelName}`,
              width: item.gridWidth,
            })
          }
        } else if (parseInt(item.dataType) === cF.Date) {
          column.push({
            id: `${item.name}`,
            customFieldType: item.dataType,
            format: "dmy",
            date: true,
            numeric: false,
            customField: true,
            visible: true,
            label: `${item.labelName}`,
            width: item.gridWidth,
            canGroup_By: true,
          })
        } else {
          column.push({ id: `${item.name}`, customFieldType: item.dataType, numeric: false, customField: true, visible: true, label: `${item.labelName}`, width: item.gridWidth, canGroup_By: true })
        }
      }
    })
  }
  column = column.map((e) => {
    const updated = { ...e }
    if (isblank(updated.width) || parseFloat(getNumberonly(updated.width)) === 0) {
      delete updated.width
    } else {
      updated.width = parseFloat(getNumberonly(updated.width))
    }
    return updated
  })
  return column
}

export const replaceBRtoNewLine = (text) => {
  if (isblank(text)) {
    return text
  }
  /* eslint-disable-next-line */
  return text.toString().replace(/<br\s*[\/]?>/gi, "\n")
}

export const replaceNewLinetoBR = (text) => {
  if (isblank(text)) {
    return text
  }
  /* eslint-disable-next-line */
  return text.toString().replace(/\n/g, "<br />")
}

export const removeBR = (text) => {
  if (isblank(text)) {
    return text
  }
  /* eslint-disable-next-line */
  return text
    .toString()
    .replace(/<br\s*[\/]?>/gi, " ")
    .replace(/\n/g, " ")
}

export const createDropDownObj = (value, field) => {
  if (!isblank(value)) {
    var obj = {}

    if (value.hasOwnProperty(field)) {
      obj[field] = value[field]
    } else {
      obj[field] = value
    }
    obj["name"] = ""
    return obj
  } else {
    return ""
  }
}

export const getitemfiltertype = () => {
  return [
    {
      id: "0",
      name: "All",
    },
    {
      id: "1",
      name: "Expiry Date",
    },
    {
      id: "2",
      name: "Manufactured Date",
    },
  ]
}
export const getDetailSummary = () => {
  return [
    {
      id: "0",
      name: "Summary",
    },
    {
      id: "1",
      name: "Detail",
    },
  ]
}

export const getDetailSummaryContact = () => {
  return [
    {
      id: "0",
      name: "Summary",
    },
    {
      id: "1",
      name: "Detail",
    },
    {
      id: "2",
      name: "Detail With Contact",
    },
  ]
}

export const convertToTimeAgo = (timeCreated) => {
  var periods = {
    month: 30 * 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
  }
  var diff = Date.now() - timeCreated
  if (diff > periods.month) {
    // it was at least a month ago
    return Math.floor(diff / periods.month) + "m"
  } else if (diff > periods.week) {
    return Math.floor(diff / periods.week) + "w"
  } else if (diff > periods.day) {
    return Math.floor(diff / periods.day) + "d"
  } else if (diff > periods.hour) {
    return Math.floor(diff / periods.hour) + "h"
  } else if (diff > periods.minute) {
    return Math.floor(diff / periods.minute) + "m"
  }
  return "Just now"
}
export const getCheckBoxPostValue = (value) => {
  if (value === "1" || value === true || value === 1) {
    return "1"
  } else {
    return "0"
  }
}
export const getCheckBoxValue = (value) => {
  if (value === "1" || value === true || value === 1) {
    return true
  } else {
    return false
  }
}

// export const format_report_field = (value, format, localSetting) => {
//   if (!isblank(value)) {
//     if (format === "indian_rupee") {
//       return amount_format(value)
//     } else if (format === "qty_decimal") {
//       return amount_formatDecimal(value, localSetting("inventory_decimalpoint"))
//     } else if (format === "rate_decimal") {
//       return amountFormatOfRate(value, localSetting)
//     } else if (format === "removeBR") {
//       return replaceBRtoNewLine(value)
//     } else if (format === "dmy") {
//       return DatetoDMY(value)
//     } else if (format === "dmmy") {
//       return DatetoDMMY(value)
//     } else if (format === "dmmyy") {
//       return DatetoDMMYY(value)
//     } else if (format === "date_time") {
//       return DatetoDMMYHM(value)
//     } else if (format === "time_ago") {
//       return convertToTimeAgo(value)
//     } else if (format === "utc") {
//       return convertTZ(value, localSetting("timezone")).toString()
//     } else if (format === "Dr_Cr_indian_rupee") {
//       if (parseFloat(getNumberonly(value)) === 0) {
//         return ""
//       }
//       if (parseFloat(getNumberonly(value)) < 0) {
//         return `${amount_format(toFixedFn(Math.abs(parseFloat(getNumberonly(value)))))} Dr`
//       } else {
//         return `${amount_format(value)} Cr`
//       }
//     } else if (format === "Dr_Cr_Excel") {
//       if (parseFloat(getNumberonly(value)) === 0) {
//         return ""
//       }
//       if (parseFloat(getNumberonly(value)) < 0) {
//         return `${toFixedFn(Math.abs(parseFloat(getNumberonly(value))))} Dr`
//       } else {
//         return `${value} Cr`
//       }
//     } else if (format === "Dr_Cr_qty_decimal") {
//       var decimalPoint = localSetting("inventory_decimalpoint")
//       if (parseFloat(getNumberonlyDecimal(value), decimalPoint) === 0) {
//         return ""
//       }
//       if (parseFloat(getNumberonlyDecimal(value), decimalPoint) < 0) {
//         return `${amount_formatDecimal(toFixedFn(Math.abs(parseFloat(getNumberonlyDecimal(value, decimalPoint))), decimalPoint), decimalPoint)} Dr`
//       } else {
//         return `${amount_formatDecimal(value, decimalPoint)} Cr`
//       }
//     } else {
//       return value
//     }
//   } else {
//     return ""
//   }
// }

export const format_report_fieldV1 = ({ value, format, localSetting, user_data, column }) => {
  if (!isblank(value)) {
    if (format === "indian_rupee") {
      return amount_format(value)
    } else if (format === "qty_decimal") {
      return amount_formatDecimal(value, localSetting.inventory_decimalpoint)
    } else if (format === "rate_decimal") {
      return amountFormatOfRateV1(value, localSetting.rate_decimalpoint, user_data)
    } else if (format === "removeBR") {
      return replaceBRtoNewLine(value)
    } else if (format === "dmy") {
      return DatetoDMY(value)
    } else if (format === "dmmy") {
      return DatetoDMMY(value)
    } else if (format === "dmmyy") {
      return DatetoDMMYY(value)
    } else if (format === "date_time") {
      return DatetoDMMYHM(value)
    } else if (format === "date_time2") {
      return DatetoDMYHM(value)
    } else if (format === "time_ago") {
      return convertToTimeAgo(value)
    } else if (format === "utc") {
      return convertTZ(value, localSetting.timezone).toString()
    } else if (format === "Dr_Cr_indian_rupee") {
      if (parseFloat(getNumberonly(value)) === 0) {
        return !isblank(column) && !isblank(column.popover) ? "Update" : ""
      }
      if (parseFloat(getNumberonly(value)) < 0) {
        return `${amount_format(toFixedFn(Math.abs(parseFloat(getNumberonly(value)))))} Dr`
      } else {
        return `${amount_format(value)} Cr`
      }
    } else if (format === "Dr_Cr_Excel") {
      if (parseFloat(getNumberonly(value)) === 0) {
        return ""
      }
      if (parseFloat(getNumberonly(value)) < 0) {
        return `${toFixedFn(Math.abs(parseFloat(getNumberonly(value))))} Dr`
      } else {
        return `${value} Cr`
      }
    } else if (format === "Dr_Cr_qty_decimal") {
      var decimalPoint = localSetting.inventory_decimalpoint
      if (parseFloat(getNumberonlyDecimal(value), decimalPoint) === 0) {
        return ""
      }
      if (parseFloat(getNumberonlyDecimal(value), decimalPoint) < 0) {
        return `${amount_formatDecimal(toFixedFn(Math.abs(parseFloat(getNumberonlyDecimal(value, decimalPoint))), decimalPoint), decimalPoint)} Dr`
      } else {
        return `${amount_formatDecimal(value, decimalPoint)} Cr`
      }
    } else {
      return value
    }
  } else {
    return ""
  }
}

export const format_for_search = ({ value, format, localSetting }) => {
  if (!isblank(value)) {
    if (format === "indian_rupee") {
      return amount_format(value, NaN)
    } else if (format === "qty_decimal") {
      return amount_formatDecimal(value, localSetting.inventory_decimalpoint, NaN)
    } else if (format === "rate_decimal") {
      return amountFormatOfRateV1(value, localSetting.rate_decimalpoint, NaN)
    } else if (format === "removeBR") {
      return replaceBRtoNewLine(value)
    } else if (format === "dmy") {
      return DatetoDMY(value)
    } else if (format === "dmmy") {
      return DatetoDMMY(value)
    } else if (format === "dmmyy") {
      return DatetoDMMYY(value)
    } else if (format === "date_time") {
      return DatetoDMMYHM(value)
    } else if (format === "time_ago") {
      return convertToTimeAgo(value)
    } else if (format === "utc") {
      return convertTZ(value, localSetting.timezone).toString()
    } else if (format === "Dr_Cr_indian_rupee") {
      if (parseFloat(getNumberonly(value, NaN)) === 0) {
        return ""
      }
      if (parseFloat(getNumberonly(value, NaN)) < 0) {
        return `${amount_format(toFixedFn(Math.abs(parseFloat(getNumberonly(value, NaN)))), NaN)} Dr`
      } else {
        return `${amount_format(value, NaN)} Cr`
      }
    } else if (format === "Dr_Cr_Excel") {
      if (parseFloat(getNumberonly(value, NaN)) === 0) {
        return ""
      }
      if (parseFloat(getNumberonly(value, NaN)) < 0) {
        return `${toFixedFn(Math.abs(parseFloat(getNumberonly(value, NaN))))} Dr`
      } else {
        return `${value} Cr`
      }
    } else if (format === "Dr_Cr_qty_decimal") {
      var decimalPoint = localSetting.inventory_decimalpoint
      if (parseFloat(getNumberonlyDecimal(value, decimalPoint, NaN)) === 0) {
        return ""
      }
      if (parseFloat(getNumberonlyDecimal(value, decimalPoint, NaN)) < 0) {
        return `${amount_formatDecimal(toFixedFn(Math.abs(parseFloat(getNumberonlyDecimal(value, decimalPoint, NaN))), decimalPoint), decimalPoint, NaN)} Dr`
      } else {
        return `${amount_formatDecimal(value, decimalPoint, NaN)} Cr`
      }
    } else {
      return value
    }
  } else {
    return ""
  }
}

export const format_excel_field = ({ value, format, localSetting, user_data }) => {
  if (!isblank(value)) {
    if (format === "indian_rupee") {
      return parseFloat(getNumberonly(value))
    } else if (format === "qty_decimal") {
      return parseFloat(getNumberonlyDecimal(value, localSetting.inventory_decimalpoint))
    } else if (format === "rate_decimal") {
      return parseFloat(getNumberonlyDecimal(value, localSetting.rate_decimalpoint))
    } else if (format === "removeBR") {
      return replaceBRtoNewLine(value)
    } else if (format === "dmy") {
      return DatetoDMY(value)
    } else if (format === "dmmy") {
      return DatetoDMMY(value)
    } else if (format === "dmmyy") {
      return DatetoDMMYY(value)
    } else if (format === "date_time") {
      return DatetoDMMYHM(value)
    } else if (format === "Dr_Cr_indian_rupee") {
      if (parseFloat(getNumberonly(value)) === 0) {
        return ""
      }
      if (parseFloat(getNumberonly(value)) < 0) {
        return `${getNumberonly(toFixedFn(Math.abs(parseFloat(getNumberonly(value)))))} Dr`
      } else {
        return `${getNumberonly(value)} Cr`
      }
    } else if (format === "Dr_Cr_qty_decimal") {
      var decimalPoint = localSetting.inventory_decimalpoint
      if (parseFloat(getNumberonlyDecimal(value), decimalPoint) === 0) {
        return ""
      }
      if (parseFloat(getNumberonlyDecimal(value), decimalPoint) < 0) {
        return `${getNumberonlyDecimal(toFixedFn(Math.abs(parseFloat(getNumberonlyDecimal(value, decimalPoint))), decimalPoint), decimalPoint)} Dr`
      } else {
        return `${getNumberonlyDecimal(value, decimalPoint)} Cr`
      }
    } else {
      return value
    }
  } else {
    return ""
  }
}

export const amount_formatDecimal = (amount, decimal, ifNaNReturn) => {
  if (isblank(amount)) {
    return "0.00"
  }
  amount = getNumberonlyDecimal(amount, decimal, ifNaNReturn)
  var isnegative = false
  if (amount < 0) {
    isnegative = true

    amount = toFixedFn(Math.abs(amount), decimal)
  }
  var x = amount
  x = x.toString()
  var afterPoint = ""
  if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length)
  x = Math.floor(x)
  x = x.toString()

  var lastThree = x.substring(x.length - 3)
  var otherNumbers = x.substring(0, x.length - 3)
  if (otherNumbers !== "") lastThree = "," + lastThree
  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint
  if (isnegative) {
    res = "-" + res
  }
  return res
}
export const amount_format = (amount, ifNaNReturn) => {
  if (isblank(amount)) {
    return "0.00"
  }
  amount = getNumberonly(amount, ifNaNReturn)
  var isnegative = false
  if (amount < 0) {
    isnegative = true

    amount = toFixedFn(Math.abs(amount))
  }
  var x = amount
  x = x.toString()
  var afterPoint = ""
  if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length)
  x = Math.floor(x)
  x = x.toString()
  var lastThree = x.substring(x.length - 3)
  var otherNumbers = x.substring(0, x.length - 3)
  if (otherNumbers !== "") lastThree = "," + lastThree
  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint
  if (isnegative) {
    res = "-" + res
  }
  return res
}

export const numberDecimal = (value, decimal) => {
  if (isblank(value)) {
    return "0.00"
  }
  value = getNumberonlyDecimal(value, decimal)
  var isnegative = false
  if (value < 0) {
    isnegative = true

    value = toFixedFn(Math.abs(value), decimal)
  }
  var x = value
  x = x.toString()
  var afterPoint = ""
  if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length)
  x = Math.floor(x)

  var res = x + afterPoint
  if (isnegative) {
    res = "-" + res
  }
  return res
}

export const replacefunction = (string) => {
  if (isblank(string)) {
    return ""
  }
  return string.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t").replace(/"/g, '\\"')
}
export const daysBetweenDates = (date1, date2) => {
  if (date1 === "" || date1 === "0" || date1 === undefined || date1 === null) {
    return 0
  }
  if (date2 === "" || date2 === "0" || date2 === undefined || date2 === null) {
    return 0
  }
  //Get 1 day in milliseconds
  var one_day = 1000 * 60 * 60 * 24
  // Convert both dates to milliseconds
  var date1_ms = date1.getTime()
  var date2_ms = date2.getTime()
  // Calculate the difference in milliseconds
  var difference_ms = date2_ms - date1_ms
  // Convert back to days and return
  return Math.round(difference_ms / one_day)
}
export const isdate = (dtStr) => {
  var timestamp = Date.parse(dtStr)
  if (isNaN(timestamp) === false) {
    return true
  } else {
    return false
  }
}
export const isValidFinancialDate = ({ dtStr, user_data, getAccountSessionUserData }) => {
  if (isblank(dtStr)) {
    return false
  }
  var minYear = parseInt(getAccountSessionUserData("financial_year", user_data), 10)
  var maxYear = minYear + 1
  var parms = DatetoYMD(dtStr).split(/[.\-/]/)
  var yyyy = parseInt(parms[0], 10)
  var mm = parseInt(parms[1], 10)

  if (yyyy < minYear || yyyy > maxYear) {
    return "Please enter a valid financial year date, between 01-04-" + minYear + " and 31-03-" + maxYear
  }

  if ((mm <= 3 && yyyy === minYear) || (mm >= 4 && yyyy === maxYear)) {
    return "Please enter a valid financial year date, between 01-04-" + minYear + " and 31-03-" + maxYear
  }
  return false
}
export const isRawValue = (code) => {
  return code != undefined && !code.hasOwnProperty("code") && code != "" && code != null
}
export const DatetoStr = (YMD) => {
  if (YMD === "" || YMD === "0" || YMD === undefined || YMD === null) {
    return ""
  }
  var yyyy = YMD.getFullYear().toString()
  var mm = (YMD.getMonth() + 1).toString() // getMonth() is zero-based
  var dd = YMD.getDate().toString()
  mm = mm[1] ? mm : "0" + mm[0]
  dd = dd[1] ? dd : "0" + dd[0]
  var str = yyyy + "-" + mm + "-" + dd
  return str
}
export const isEqual = (value, other) => {
  // Get the value type
  var type = Object.prototype.toString.call(value)

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false

  // Compare the length of the length of the two items
  var valueLen = type === "[object Array]" ? value.length : Object.keys(value).length
  var otherLen = type === "[object Array]" ? other.length : Object.keys(other).length
  if (valueLen !== otherLen) return false

  // Compare two items
  var compare = function (item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1)

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false
    } else {
      // Otherwise, do a simple comparison
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false
      } else {
        if (item1 !== item2) return false
      }
    }
  }

  // Compare properties
  if (type === "[object Array]") {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false
      }
    }
  }

  // If nothing failed, return true
  return true
}
export const valueOfRate = ({ rate, getAccountSitesetting, user_data }) => {
  var rate_decimalpoint = getAccountSitesetting("rate_decimalpoint", user_data)
  if (isblank(rate_decimalpoint)) {
    rate_decimalpoint = 2
  }
  if (isblank(rate)) {
    return parseFloat(getNumberonlyDecimal(0, rate_decimalpoint))
  } else {
    return parseFloat(getNumberonlyDecimal(rate, rate_decimalpoint))
  }
}

export const valueOfRateV1 = (rate, rateDecimalPoint) => {
  if (isblank(rateDecimalPoint)) {
    rateDecimalPoint = 2
  }
  if (isblank(rate)) {
    return parseFloat(getNumberonlyDecimal(0, rateDecimalPoint))
  } else {
    return parseFloat(getNumberonlyDecimal(rate, rateDecimalPoint))
  }
}

export const amountFormatOfRate = (rate, getAccountSitesetting) => {
  var rate_decimalpoint = getAccountSitesetting("rate_decimalpoint")
  if (isblank(rate_decimalpoint)) {
    rate_decimalpoint = 2
  }
  if (isblank(rate)) {
    return amount_formatDecimal(0, rate_decimalpoint)
  } else {
    return amount_formatDecimal(rate, rate_decimalpoint)
  }
}

export const qtyFormat = (qty, inventory_decimalpoint) => {
  if (isblank(inventory_decimalpoint)) {
    inventory_decimalpoint = 2
  }
  if (isblank(qty)) {
    return amount_formatDecimal(0, inventory_decimalpoint)
  } else {
    return amount_formatDecimal(qty, inventory_decimalpoint)
  }
}

export const amountFormatOfRateV1 = (rate, rateDecimalPoint, ifNaNReturn) => {
  if (isblank(rateDecimalPoint)) {
    rateDecimalPoint = 2
  }
  if (isblank(rate)) {
    return amount_formatDecimal(0, rateDecimalPoint, ifNaNReturn)
  } else {
    return amount_formatDecimal(rate, rateDecimalPoint, ifNaNReturn)
  }
}

export const getCompanyLock = (getSessionUserData, user_data) => {
  var lock = getSessionUserData("company_lock", user_data)
  if (lock === "lock") {
    return true
  }
  return false
}
export const addString = (original, addNew, seprater) => {
  var returnValue = original
  if (!isblank(addNew)) {
    if (isblank(returnValue)) {
      returnValue = addNew
    } else {
      if (!isblank(seprater)) {
        returnValue = returnValue + seprater + addNew
      } else {
        returnValue = returnValue + addNew
      }
    }
  }
  return returnValue
}

export const RemoveCustomFieldNumberFormat = ({ obj, ddcustomfield, fields }) => {
  ddcustomfield
    .filter((item) => {
      return item.masterDetailLevel === "0"
    })
    .forEach((f) => {
      if (parseInt(f.dataType) === cF.Decimal) {
        obj[f.name] = getNumberonlyDecimal(obj[f.name], f.decimalPoint)
      }
    })

  for (var i = 0; i < fields.length; i++) {
    var field_obj = fields[i]

    for (var j = 0; j < field_obj.fields.length; j++) {
      if (field_obj.Grid && obj.hasOwnProperty(field_obj.Grid)) {
        for (var k = 0; k < obj[field_obj.Grid].length; k++) {
          // eslint-disable-next-line
          ddcustomfield
            .filter((item) => {
              return item.masterDetailLevel === "1"
            })
            .forEach((f) => {
              if (parseInt(f.dataType) === cF.Decimal) {
                obj[field_obj.Grid][k][f.name] = getNumberonlyDecimal(obj[field_obj.Grid][k][f.name], f.decimalPoint)
              }
            })
        }
      }
    }
  }
  return obj
}
export const AddCustomFieldNumberFormat = ({ obj, ddcustomfield, fields }) => {
  ddcustomfield
    .filter((item) => {
      return item.masterDetailLevel === "0"
    })
    .forEach((f) => {
      if (parseInt(f.dataType) === cF.Decimal) {
        obj[f.name] = amount_formatDecimal(obj[f.name], f.decimalPoint)
      }
    })

  for (var i = 0; i < fields.length; i++) {
    var field_obj = fields[i]

    for (var j = 0; j < field_obj.fields.length; j++) {
      if (field_obj.Grid && obj.hasOwnProperty(field_obj.Grid)) {
        for (var k = 0; k < obj[field_obj.Grid].length; k++) {
          // eslint-disable-next-line
          ddcustomfield
            .filter((item) => {
              return item.masterDetailLevel === "1"
            })
            .forEach((f) => {
              if (parseInt(f.dataType) === cF.Decimal && field_obj.decimal) {
                obj[field_obj.Grid][k][f.name] = amount_formatDecimal(obj[field_obj.Grid][k][f.name], f.decimalPoint)
              }
            })
        }
      }
    }
  }
  return obj
}
export const AddNumberFormat = ({ obj, fields, localSiteSetting }) => {
  for (var i = 0; i < fields.length; i++) {
    var field_obj = fields[i]
    for (var j = 0; j < field_obj.fields.length; j++) {
      let decimalJSetting = 2
      if (field_obj.decimal && field_obj.decimal[j]) {
        if (typeof localSiteSetting === "function") {
          decimalJSetting = localSiteSetting(field_obj.decimal[j])
        } else {
          decimalJSetting = localSiteSetting[field_obj.decimal[j]]
        }
      }
      if (field_obj.Grid && obj.hasOwnProperty(field_obj.Grid)) {
        for (var k = 0; k < obj[field_obj.Grid].length; k++) {
          if (obj[field_obj.Grid][k][field_obj.fields[j]] !== "--") {
            if (field_obj.decimal) {
              if (!isblank(field_obj.decimal[j])) {
                if (typeof field_obj.decimal[j] === "number") {
                  obj[field_obj.Grid][k][field_obj.fields[j]] = amount_formatDecimal(obj[field_obj.Grid][k][field_obj.fields[j]], field_obj.decimal[j])
                } else {
                  obj[field_obj.Grid][k][field_obj.fields[j]] = amount_formatDecimal(obj[field_obj.Grid][k][field_obj.fields[j]], isblank(decimalJSetting) ? 2 : decimalJSetting)
                }
              } else {
                obj[field_obj.Grid][k][field_obj.fields[j]] = amount_format(obj[field_obj.Grid][k][field_obj.fields[j]])
              }
            } else {
              obj[field_obj.Grid][k][field_obj.fields[j]] = amount_format(obj[field_obj.Grid][k][field_obj.fields[j]])
            }
          }
        }
      } else {
        if (obj[field_obj.fields[j]] !== "--") {
          if (field_obj.decimal) {
            if (!isblank(field_obj.decimal[j])) {
              if (typeof field_obj.decimal[j] === "number") {
                obj[field_obj.fields[j]] = amount_formatDecimal(obj[field_obj.fields[j]], field_obj.decimal[j])
              } else {
                obj[field_obj.fields[j]] = amount_formatDecimal(obj[field_obj.fields[j]], isblank(decimalJSetting) ? 2 : decimalJSetting)
              }
            } else {
              obj[field_obj.fields[j]] = amount_format(obj[field_obj.fields[j]])
            }
          } else {
            obj[field_obj.fields[j]] = amount_format(obj[field_obj.fields[j]])
          }
        }
      }
    }
  }
  return obj
}
export const RemoveNumberFormat = ({ obj, fields, localSiteSetting }) => {
  for (var i = 0; i < fields.length; i++) {
    var field_obj = fields[i]
    for (var j = 0; j < field_obj.fields.length; j++) {
      let decimalJSetting = 2
      if (field_obj.decimal && field_obj.decimal[j]) {
        if (typeof localSiteSetting === "function") {
          decimalJSetting = localSiteSetting(field_obj.decimal[j])
        } else {
          decimalJSetting = localSiteSetting[field_obj.decimal[j]]
        }
      }
      if (field_obj.Grid && obj.hasOwnProperty(field_obj.Grid)) {
        for (var k = 0; k < obj[field_obj.Grid].length; k++) {
          if (field_obj.decimal) {
            if (obj[field_obj.Grid][k][field_obj.fields[j]] !== "--") {
              if (!isblank(field_obj.decimal[j])) {
                if (typeof field_obj.decimal[j] === "number") {
                  obj[field_obj.Grid][k][field_obj.fields[j]] = getNumberonlyDecimal(obj[field_obj.Grid][k][field_obj.fields[j]], field_obj.decimal[j])
                } else {
                  obj[field_obj.Grid][k][field_obj.fields[j]] = getNumberonlyDecimal(obj[field_obj.Grid][k][field_obj.fields[j]], isblank(decimalJSetting) ? 2 : decimalJSetting)
                }
              } else {
                obj[field_obj.Grid][k][field_obj.fields[j]] = getNumberonly(obj[field_obj.Grid][k][field_obj.fields[j]])
              }
            }
          } else {
            if (obj[field_obj.Grid][k][field_obj.fields[j]] !== "--") {
              obj[field_obj.Grid][k][field_obj.fields[j]] = getNumberonly(obj[field_obj.Grid][k][field_obj.fields[j]])
            }
          }
        }
      } else {
        if (obj[field_obj.fields[j]] !== "--") {
          if (field_obj.decimal) {
            if (!isblank(field_obj.decimal[j])) {
              if (typeof field_obj.decimal[j] === "number") {
                obj[field_obj.fields[j]] = getNumberonlyDecimal(obj[field_obj.fields[j]], field_obj.decimal[j])
              } else {
                obj[field_obj.fields[j]] = getNumberonlyDecimal(obj[field_obj.fields[j]], isblank(decimalJSetting) ? 2 : decimalJSetting)
              }
            } else {
              obj[field_obj.fields[j]] = getNumberonly(obj[field_obj.fields[j]])
            }
          } else {
            obj[field_obj.fields[j]] = getNumberonly(obj[field_obj.fields[j]])
          }
        }
      }
    }
  }
  return obj
}

export const formateMobile = ({ mobile, countryId, dd_country_state }) => {
  var finalMobile = mobile
  if (!isblank(mobile)) {
    if (mobile.length > 10) {
      finalMobile = mobile.substring(mobile.length - 10)
    }
    finalMobile = `${getPhoneCodeByCountry(countryId, dd_country_state)}${finalMobile}`
    return finalMobile
  } else {
    return ""
  }
}

export const formatIndianPhoneNumber = (num) => {
  let number = num.toString().trim()

  // Check if the number is 10 digits long
  if (number.length === 10 && /^[0-9]+$/.test(number)) {
    num = "91" + number
  }

  return num
}

export const arraySum = (itemArray, key, decimalPoint) => {
  const decimal_Point = decimalPoint ? decimalPoint : 2
  return itemArray.reduce((sum, row) => numForCalc(numForCalc(row[key], decimal_Point) + numForCalc(sum, decimal_Point), decimal_Point), 0)
}

export const arraySumTaxCondition = (itemArray, key, taxCodeArray) => {
  if (!isblank(itemArray) && itemArray.length > 0) {
    return itemArray.reduce((sum, row) => {
      if (checkCondition(row.acccode, taxCodeArray)) {
        return numForCalc(numForCalc(row[key]) + numForCalc(sum))
      }
      return numForCalc(sum)
    }, 0)
  }
  return 0
}

export const getStringOfMonth = (number) => {
  if (number == "1" || number == "01") {
    return "Jan"
  } else if (number == "2" || number == "02") {
    return "Feb"
  } else if (number == "3" || number == "03") {
    return "Mar"
  } else if (number == "4" || number == "04") {
    return "Apr"
  } else if (number == "5" || number == "05") {
    return "May"
  } else if (number == "6" || number == "06") {
    return "Jun"
  } else if (number == "7" || number == "07") {
    return "Jul"
  } else if (number == "8" || number == "08") {
    return "Aug"
  } else if (number == "9" || number == "09") {
    return "Sep"
  } else if (number == "10") {
    return "Oct"
  } else if (number == "11") {
    return "Nov"
  } else if (number == "12") {
    return "Dec"
  }
}

export const DatetoDMMY = (dateString) => {
  const YMD = DatetoYMD(dateString)
  if (YMD === undefined || YMD === null || YMD === "0000-00-00") {
    return ""
  } else if (typeof YMD === "string" || YMD instanceof String) {
    var dat = YMD.split("-").reverse()
    var month = getStringOfMonth(dat[1])
    return `${dat[0]},${month}-${dat[2]}`
  } else {
    return ""
  }
}

export const DatetoDMMYY = (dateString) => {
  const YMD = DatetoYMD(dateString)
  if (YMD === undefined || YMD === null || YMD === "0000-00-00") {
    return ""
  } else if (typeof YMD === "string" || YMD instanceof String) {
    var dat = YMD.split("-").reverse()
    var month = getStringOfMonth(dat[1])
    return `${dat[0]}-${month}-${dat[2].substring(2)}`
  } else {
    return ""
  }
}

function formatTime(date) {
  var d = date.split(":")
  var hh = convertIntif(d[0])
  var m = convertIntif(d[1])
  var s = convertIntif(d[2])

  var dd = "AM"
  var h = hh
  if (h >= 12) {
    h = hh - 12
    dd = "PM"
  }
  if (h == 0) {
    h = 12
  }
  m = m < 10 ? "0" + m : m
  s = s < 10 ? "0" + s : s

  var replacement = h + ":" + m
  /* if you want to add seconds
    replacement += ":"+s;  */
  replacement += " " + dd

  return replacement
}

export const DatetoDMMYHM = (dateString) => {
  if (isblank(dateString)) {
    return ""
  }
  var dateTime = dateString.split(" ")
  var date = DatetoDMMY(dateTime[0])
  var time = formatTime(dateTime[1])
  return `${date} ${time}`
}

export const DatetoDMYHM = (dateString) => {
  if (isblank(dateString)) {
    return ""
  }
  var dateTime = dateString.split(" ")
  var date = DatetoDMY(dateTime[0])
  var time = formatTime(dateTime[1])
  return `${date} ${time}`
}

export const RemoveCustomPageNumberFormat = (obj, field_data) => {
  if (!isblank(field_data) && !isblank(field_data.master)) {
    field_data.master
      .filter((item) => {
        return item.masterDetailLevel === "0"
      })
      .forEach((f) => {
        if (parseInt(f.dataType) === cF.Decimal) {
          obj[f.name] = getNumberonlyDecimal(obj[f.name], f.decimalPoint)
        }
      })
  }
  if (!isblank(field_data) && !isblank(field_data.linkData)) {
    Object.keys(field_data.linkData).forEach((e) => {
      for (var k = 0; k < obj[e].length; k++) {
        field_data.linkData[e].forEach((f) => {
          if (parseInt(f.dataType) === cF.Decimal) {
            obj[e][k][f.name] = getNumberonlyDecimal(obj[e][k][f.name], f.decimalPoint)
          }
        })
      }
    })
  }
  return obj
}

export const AddCustomPageNumberFormat = (obj, field_data) => {
  if (!isblank(field_data) && !isblank(field_data.master)) {
    field_data.master
      .filter((item) => {
        return item.masterDetailLevel === "0"
      })
      .forEach((f) => {
        if (parseInt(f.dataType) === cF.Decimal) {
          obj[f.name] = amount_formatDecimal(obj[f.name], f.decimalPoint)
        }
      })
  }
  if (!isblank(field_data) && !isblank(field_data.linkData)) {
    Object.keys(field_data.linkData).forEach((e) => {
      for (var k = 0; k < obj[e].length; k++) {
        field_data.linkData[e].forEach((f) => {
          if (parseInt(f.dataType) === cF.Decimal) {
            obj[e][k][f.name] = amount_formatDecimal(obj[e][k][f.name], f.decimalPoint)
          }
        })
      }
    })
  }
  return obj
}

export const account_checkdropdowndependancy = (strdependency, state) => {
  const {
    ddstate,
    subgroup,
    dd_tdspayee,
    dd_taxcode,
    dd_country_state,
    dd_allcountryForOnboarding,
    barcodeTemplate,
    dd_draft,
    dd_plan,
    dd_accountPackage,
    dd_envelope_template,
    dd_cheque_template,
    dd_tdscategory,
    ddcity,
    ddsupplier,
    ddagent,
    ddtransport,
    ddcustomer,
    ddcustomer_supplier,
    ddlot_party,
    dditem,
    dd_invoice_template,
    dd_bottransaction,
    ddtaxscheme,
    ddother_account,
    ddaccountgroup,
    ddcreditnote,
    dd_bank,
    dddebitnote,
    ddsender,
    ddbeneficiary,
    dditemgroup,
    ddunit,
    ddopbal,
    ddcompany,
    ddbankaccount,
    ddexpenseaccount,
    ddtradename,
    ddallaccount,
    ddsalesaccount,
    ddpurchaseaccount,
    ddaccounttype,
    dduser,
    ddtaxgroup,
    ddsmscategory,
    ddcostcenter,
    ddactivity_type,
    dddropdown_closingstock,
    ddtaxschemeaccount,
    ddpartygroup,
    ddIPOStockparty,
    ddcustomfield,
    ddcustomPage,
    ddpructsubgroup,
    ddpructsubgrouplevel2,
    ddgodown,
    ddlabelsetting,
    ddtcs,
    ddprocess,
    ddlot,
    dd_whatsapp_template,
    dd_email_template,
    dd_sms_template,
    filterData,
    webhook_event_type,
  } = state.CommonReducer
  strdependency = strdependency.split(",")
  var newstrdepemdency = ""

  strdependency.forEach(function (element) {
    var addnew = false
    if (element === "customer") {
      if (ddcustomer.length === 0) {
        addnew = true
      }
    } else if (element === "supplier") {
      if (ddsupplier.length === 0) {
        addnew = true
      }
    } else if (element === "dd_allcountry") {
      if (dd_allcountryForOnboarding.length === 0) {
        addnew = true
      }
    } else if (element === "subgroup") {
      if (subgroup.length === 0) {
        addnew = true
      }
    } else if (element === "dd_country_state") {
      if (dd_country_state.length === 0) {
        addnew = true
      }
    } else if (element === "dd_draft") {
      if (dd_draft.length === 0) {
        addnew = true
      }
    } else if (element === "dd_accountPackage") {
      if (dd_accountPackage.length === 0) {
        addnew = true
      }
    } else if (element === "barcodeTemplate") {
      if (barcodeTemplate.length === 0) {
        addnew = true
      }
    } else if (element === "dd_taxcode") {
      if (dd_taxcode.length === 0) {
        addnew = true
      }
    } else if (element === "dd_envelope_template") {
      if (dd_envelope_template.length === 0) {
        addnew = true
      }
    } else if (element === "dd_cheque_template") {
      if (dd_cheque_template.length === 0) {
        addnew = true
      }
    } else if (element === "dd_bank") {
      if (dd_bank.length === 0) {
        addnew = true
      }
    } else if (element === "dd_plan") {
      if (dd_plan.length === 0) {
        addnew = true
      }
    } else if (element === "customer_supplier") {
      if (ddcustomer_supplier.length === 0) {
        addnew = true
      }
    } else if (element === "lot_party") {
      if (ddlot_party.length === 0) {
        addnew = true
      }
    } else if (element === "dd_tdspayee") {
      if (dd_tdspayee.length === 0) {
        addnew = true
      }
    } else if (element === "dd_tdscategory") {
      if (dd_tdscategory.length === 0) {
        addnew = true
      }
    } else if (element === "dd_invoice_template") {
      if (dd_invoice_template.length === 0) {
        addnew = true
      }
    } else if (element === "dd_bottransaction") {
      if (dd_bottransaction.length === 0) {
        addnew = true
      }
    } else if (element === "customer") {
      if (ddcustomer.length === 0) {
        addnew = true
      }
    } else if (element === "supplier") {
      if (ddsupplier.length === 0) {
        addnew = true
      }
    } else if (element === "customer_supplier") {
      if (ddcustomer_supplier.length === 0) {
        addnew = true
      }
    } else if (element === "agent") {
      if (ddagent.length === 0) {
        addnew = true
      }
    } else if (element === "transport") {
      if (ddtransport.length === 0) {
        addnew = true
      }
    } else if (element === "ddgodown") {
      if (ddgodown.length === 0) {
        addnew = true
      }
    } else if (element === "taxscheme") {
      if (ddtaxscheme.length === 0) {
        addnew = true
      }
    } else if (element === "item") {
      if (dditem.length === 0) {
        addnew = true
      }
    } else if (element === "other_account") {
      if (ddother_account.length === 0) {
        addnew = true
      }
    } else if (element === "accountgroup") {
      if (ddaccountgroup.length === 0) {
        addnew = true
      }
    } else if (element === "creditnote") {
      if (ddcreditnote.length === 0) {
        addnew = true
      }
    } else if (element === "debitnote") {
      if (dddebitnote.length === 0) {
        addnew = true
      }
    } else if (element === "state") {
      if (ddstate.length === 0) {
        addnew = true
      }
    } else if (element === "city") {
      if (ddcity.length === 0) {
        addnew = true
      }
    } else if (element === "dropdown_wiresender") {
      if (ddsender.length === 0) {
        addnew = true
      }
    } else if (element === "dropdown_beneficiary") {
      if (ddbeneficiary.length === 0) {
        addnew = true
      }
    } else if (element === "itemgroup") {
      if (dditemgroup.length === 0) {
        addnew = true
      }
    } else if (element === "unit") {
      if (ddunit.length === 0) {
        addnew = true
      }
    } else if (element === "opbal") {
      if (ddopbal.length === 0) {
        addnew = true
      }
    } else if (element === "company") {
      if (ddcompany.length === 0) {
        addnew = true
      }
    } else if (element === "bankaccount") {
      if (ddbankaccount.length === 0) {
        addnew = true
      }
    } else if (element === "expenseaccount") {
      if (ddexpenseaccount.length === 0) {
        addnew = true
      }
    } else if (element === "tradename") {
      if (ddtradename.length === 0) {
        addnew = true
      }
    } else if (element === "allaccount") {
      if (ddallaccount.length === 0) {
        addnew = true
      }
    } else if (element === "sales_account") {
      if (ddsalesaccount.length === 0) {
        addnew = true
      }
    } else if (element === "purchase_account") {
      if (ddpurchaseaccount.length === 0) {
        addnew = true
      }
    } else if (element === "accounttype") {
      if (ddaccounttype.length === 0) {
        addnew = true
      }
    } else if (element === "user") {
      if (dduser.length === 0) {
        addnew = true
      }
    } else if (element === "taxgroup") {
      if (ddtaxgroup.length === 0) {
        addnew = true
      }
    } else if (element === "smscategory") {
      if (ddsmscategory.length === 0) {
        addnew = true
      }
    } else if (element === "dropdown_costcenter") {
      if (ddcostcenter.length === 0) {
        addnew = true
      }
    } else if (element === "activity_type") {
      if (ddactivity_type.length === 0) {
        addnew = true
      }
    } else if (element === "dropdown_closingstock") {
      if (dddropdown_closingstock.length === 0) {
        addnew = true
      }
    } else if (element === "taxschemeaccount") {
      if (ddtaxschemeaccount.length === 0) {
        addnew = true
      }
    } else if (element === "partygroup") {
      if (ddpartygroup.length === 0) {
        addnew = true
      }
    } else if (element === "dropdown_ipostockparty") {
      if (ddIPOStockparty.length === 0) {
        addnew = true
      }
    } else if (element === "customField") {
      if (ddcustomfield.length === 0) {
        addnew = true
      }
    } else if (element === "ddcustomPage") {
      if (ddcustomPage.length === 0) {
        addnew = true
      }
    } else if (element === "productsubgroup") {
      if (ddpructsubgroup.length === 0) {
        addnew = true
      }
    } else if (element === "productsubgrouplevel2") {
      if (ddpructsubgrouplevel2.length === 0) {
        addnew = true
      }
    } else if (element === "ddprocess") {
      if (ddprocess.length === 0) {
        addnew = true
      }
    } else if (element === "ddlot") {
      if (ddlot.length === 0) {
        addnew = true
      }
    } else if (element === "tcsaccount") {
      if (ddtcs.length === 0) {
        addnew = true
      }
    } else if (element === "ddlabelsetting") {
      if (ddlabelsetting.length === 0) {
        addnew = true
      }
    } else if (element === "dd_email_template") {
      if (dd_email_template.length === 0) {
        addnew = true
      }
    } else if (element === "dd_sms_template") {
      if (dd_sms_template.length === 0) {
        addnew = true
      }
    } else if (element === "dd_whatsapp_template") {
      if (dd_whatsapp_template.length === 0) {
        addnew = true
      }
    } else if (element === "webhook_event_type") {
      if (webhook_event_type.length === 0) {
        addnew = true
      }
    } else if (element.indexOf("filter_") > -1) {
      if (isblank(filterData) || isblank(filterData[element])) {
        addnew = true
      }
    }
    if (addnew) {
      if (newstrdepemdency === "") {
        newstrdepemdency = element
      } else {
        newstrdepemdency = newstrdepemdency + "," + element
      }
    }
  })
  return newstrdepemdency
}

export const changeSign = (value) => {
  if (isblank(value)) {
    return 0
  }
  return 0 - parseFloat(value)
}

function checkGstn(gst) {
  var factor = 2,
    sum = 0,
    checkCodePoint = 0,
    i,
    j,
    digit,
    mod,
    codePoint,
    cpChars,
    inputChars
  cpChars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  inputChars = gst.trim().toUpperCase()

  mod = cpChars.length
  for (i = inputChars.length - 1; i >= 0; i = i - 1) {
    codePoint = -1
    for (j = 0; j < cpChars.length; j = j + 1) {
      if (cpChars[j] === inputChars[i]) {
        codePoint = j
      }
    }

    digit = factor * codePoint
    factor = factor === 2 ? 1 : 2
    digit = digit / mod + (digit % mod)
    sum += Math.floor(digit)
  }
  checkCodePoint = (mod - (sum % mod)) % mod

  return gst + cpChars[checkCodePoint]
}
function gstin(gst) {
  var GstinPttn = /[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Zz1-9A-Ja-j]{1}[0-9a-zA-Z]{1}/
  if (testPattern(gst, GstinPttn)) {
    var substrgst = gst.substring(0, 14)
    if (gst === checkGstn(substrgst)) {
      return true
    }
  }
  return false
}
/*function uin(uin) {
  var UinPttn = /[0-9]{4}[A-Z]{3}[0-9]{5}[UO]{1}[N][A-Z0-9]{1}/;
  if (testPattern(uin, UinPttn)) {
    var substrgst = uin.substring(0, 14);
    if (uin === checkGstn(substrgst)) {
      return true;
    }
  }
  return false;
};*/
function testPattern(iString, iPattern) {
  var patt = new RegExp(iPattern),
    isPatternValid = patt.test(iString)
  return isPatternValid
}
//To validate GSTIN/UIN
export const validateGSTIN = (ctin) => {
  if (!isblank(ctin)) {
    return gstin(ctin)
  }
  return true
}

export const changeSignInArray = (data, keyArray) => {
  return data.map((e) => {
    const updatedItem = { ...e }
    keyArray.forEach((i) => {
      updatedItem[i] = changeSign(updatedItem[i])
    })
    return updatedItem
  })
}

export const validateEmail = (email) => {
  if (!isblank(email)) {
    var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (email.match(mailformat)) {
      return true
    }
  }
  return false
}

export const validateZipcode = (zipcode) => {
  if (!isblank(zipcode) && zipcode.length === 6 && !isNaN(zipcode)) {
    return true
  }
  return false
}

export const validateAddress = (address, minLength = 1, maxLength = 100) => {
  if (!isblank(address)) {
    const regex = new RegExp(`^([^\\\\\"]){${minLength},${maxLength}}$`)
    const isValid = regex.test(address)
    return isValid
  }
  return false
}

export const validateInputText = (string) => {
  if (!isblank(string)) {
    const regex = new RegExp(`^([^\\\\\"])*$`)
    const isValid = regex.test(string)
    return isValid
  }
  return false
}

export const validateAddress2 = (address, minLength = 1, maxLength = 100) => {
  if (!isblank(address)) {
    const regex = new RegExp(`^([^\\\\\"]){${minLength},${maxLength}}$`)
    const isValid = regex.test(address)
    return isValid
  }
  return true
}

export const validateMobile = (mobile) => {
  if (!isblank(mobile)) {
    var pattern = /^([0-9]{6,12})$/
    if (mobile.match(pattern)) {
      return true
    }
  }
  return false
}

export const validatePan = (pan) => {
  if (!isblank(pan) && pan.length === 10) {
    return true
  }
  return false
}

export const validateAadhar = (aadhar) => {
  if (!isblank(aadhar) && aadhar.length === 12 && !isNaN(aadhar)) {
    return true
  }
  return false
}

export const getActiveDropDown = (dd, formaction) => {
  if (formaction === "edit") {
    return dd
  }
  return dd.filter((e) => convertIntif(e.inactive) !== 1)
}

export const validateJSON = (itembody) => {
  try {
    return JSON.parse(itembody)
  } catch (error) {
    return null // or some default/fallback value
  }
}

export const formatValue = ({ value, type, limit, limit1, other_type }) => {
  if (type === "Text") {
    if (!isblank(value)) {
      if (value.length <= limit) {
        return value.toString()
      }
      return "error"
    }
    return ""
  } else if (type === "Text_FixLength") {
    if (!isblank(value)) {
      if (value.length === limit) {
        return value.toString()
      }
      return "error"
    }
    return ""
  } else if (type === "Number") {
    if (!isblank(value)) {
      if (value.length <= limit && !isNaN(value)) {
        return parseInt(getNumberonly(value))
      }
      return "error"
    }
    return ""
  } else if (type === "Number_FixLength") {
    if (!isblank(value)) {
      if (value.length === limit && !isNaN(value)) {
        return parseInt(getNumberonly(value))
      }
      return "error"
    }
    return ""
  } else if (type === "Decimal") {
    if (!isblank(value)) {
      var temp = value.toString().split(".")
      if (!isblank(temp[0]) && temp[0].length <= limit) {
        if (!isblank(limit1)) {
          if (other_type === "String") {
            return getNumberonlyDecimal(value, limit1)
          }
          return parseFloat(getNumberonlyDecimal(value, limit1))
        }
        if (other_type === "String") {
          return getNumberonly(value)
        }
        return parseFloat(getNumberonly(value))
      }
      return "error"
    }
    return 0
  }
  return ""
}

export const formatValueWithMin = ({ value, type, limit, minLength, limit1, other_type }) => {
  var returnValue = formatValue({ value, type, limit, limit1, other_type })
  if (minLength && returnValue !== "error") {
    if (isblank(returnValue)) {
      return ""
    }
    if (returnValue.toString().length < minLength) {
      return "error"
    }
  }
  return returnValue
}

export const generateEWayBillJSON = (props) => {
  const { formValues, ddcity, dropDownTransport, getAccountSessionUserData, getAccountSitesetting, generateJSONOnly } = props
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
  const inward_type_detail = getAccountSitesetting("inward_type_detail")
  const outward_type_detail = getAccountSitesetting("outward_type_detail")
  const multigodown_mng = parseInt(getAccountSitesetting("multigodown_mng"))
  const use_godown_address = parseInt(getAccountSitesetting("use_godown_address"))

  var itemList = []
  var errors = []
  formValues.invoiceDetail.details_table_data.forEach((e, index) => {
    const newItem = {
      itemNo: index + 1,
      productName: formatValue({ value: e.itemname, type: "Text", limit: 100 }),
      productDesc: formatValue({ value: e.shortDescription, type: "Text", limit: 100 }),
      hsnCode: formatValue({ value: e.hsn_code, type: "Number", limit: 8 }),
      quantity: formatValue({ value: e.qty, type: "Decimal", limit: 8, limit1: inventory_decimalpoint, other_type: "String" }),
      qtyUnit: formatValue({ value: e.unitname, type: "Text", limit: 3 }),
      taxableAmount: formatValue({ value: e.taxable_amt, type: "Decimal", limit: 18 }),
      sgstRate: formatValue({ value: e.sgst_rate, type: "Decimal", limit: 6, limit1: 3 }),
      cgstRate: formatValue({ value: e.cgst_rate, type: "Decimal", limit: 6, limit1: 3 }),
      igstRate: formatValue({ value: e.igst_rate, type: "Decimal", limit: 6, limit1: 3 }),
      cessRate: 0,
    }
    itemList.push(newItem)
  })

  // var discountAccounts = formValues.invoiceDetail.expenseaccount.filter
  var otherValue = parseFloat(getNumberonly(formValues.invoiceDetail.master_table_data[0].roundoffamt))

  if (formValues.invoiceDetail.details_sub_table_data && formValues.invoiceDetail.details_sub_table_data.length > 0) {
    formValues.invoiceDetail.details_sub_table_data
      .filter((e) => convertIntif(e.apply_gst) !== 1)
      .forEach((e) => {
        if (checkCondition(e.taxtype, ["IGST", "CGST", "SGST"], "not")) {
          if (convertIntif(e.discount_enable) === 1) {
            otherValue = otherValue - parseFloat(getNumberonly(e.amt))
          } else {
            otherValue = otherValue + parseFloat(getNumberonly(e.amt))
          }
        }
      })
  }

  var shipp_address = {}
  if (!isblank(formValues.invoiceDetail.master_table_data[0].shipp_address)) {
    shipp_address = JSON.parse(formValues.invoiceDetail.master_table_data[0].shipp_address)
  }

  let billno = ""
  let b_date = ""
  if (formValues && formValues.invoiceDetail && formValues.invoiceDetail.master_table_data) {
    billno = formValues.invoiceDetail.master_table_data[0].billno
    b_date = formValues.invoiceDetail.master_table_data[0].b_date
    if (formValues.invoiceDetail.master_table_data[0].invoice_type === "salesreturn") {
      billno = formValues.invoiceDetail.master_table_data[0].creditnote_no
      b_date = formValues.invoiceDetail.master_table_data[0].creditnote_date
    } else if (formValues.invoiceDetail.master_table_data[0].invoice_type === "purchasereturn") {
      billno = formValues.invoiceDetail.master_table_data[0].debitnote_no
      b_date = formValues.invoiceDetail.master_table_data[0].debitnote_date
    }

    if (
      inward_type_detail.filter((e) => e.id === formValues.invoiceDetail.master_table_data[0].invoice_type).length > 0 ||
      outward_type_detail.filter((e) => e.id === formValues.invoiceDetail.master_table_data[0].invoice_type).length > 0
    ) {
      shipp_address = {
        address: formValues.invoiceDetail.user_details[0].shipp_address,
        areacode: formValues.invoiceDetail.user_details[0].shipp_areacode,
        cityname: getdropdownfieldvalue({ dropDownData: formValues.invoiceDetail.city_data, field: "city_id", value: formValues.invoiceDetail.user_details[0].shipp_city_id, displayvalue: "city" }),
        pincode: formValues.invoiceDetail.user_details[0].shipp_pincode,
        stateid: formValues.invoiceDetail.user_details[0].shipp_state_id,
      }
    }
  }

  let totalValue = numForCalc(formValues.invoiceDetail.master_table_data[0].totamt)
  var itemDiscount = 0
  var itemOtherAmt = 0
  formValues.invoiceDetail.details_table_data.forEach((e, index) => {
    if (!isblank(e.otherminus_detail)) {
      var otherminus_detail = JSON.parse(decodeURIComponent(e.otherminus_detail))
      Object.keys(otherminus_detail).forEach((i) => {
        itemDiscount = itemDiscount + numForCalc(otherminus_detail[i])
      })
    }
    if (!isblank(e.otherplus_detail)) {
      var otherplus_detail = JSON.parse(decodeURIComponent(e.otherplus_detail))
      Object.keys(otherplus_detail).forEach((i) => {
        itemOtherAmt = itemOtherAmt + numForCalc(otherplus_detail[i])
      })
    }
  })
  totalValue = totalValue - itemDiscount + itemOtherAmt
  totalValue = formatValue({ value: totalValue, type: "Decimal", limit: 18 })

  const supplyType = formatValue({ value: formValues.supplyType, type: "Text", limit: 1 })
  let fromGstin = ""
  let fromTrdName = ""
  let fromAddr1 = ""
  let fromAddr2 = ""
  let fromPlace = ""
  let fromPincode = ""
  let fromStateCode = ""
  let actFromStateCode = ""

  let toGstin = ""
  let toTrdName = ""
  let toAddr1 = ""
  let toAddr2 = ""
  let toPlace = ""
  let toPincode = ""
  let toStateCode = ""
  let actToStateCode = ""

  if (supplyType === "O") {
    fromGstin = formatValue({ value: getAccountSessionUserData("gstin"), type: "Text_FixLength", limit: 15 })
    fromTrdName = formatValue({ value: getAccountSessionUserData("company_name"), type: "Text", limit: 100 })
    fromAddr1 = formatValue({ value: removeBR(getAccountSessionUserData("company_address1")), type: "Text", limit: 120 })
    fromAddr2 = formatValue({ value: removeBR(getAccountSessionUserData("company_address2")), type: "Text", limit: 120 })
    fromPlace = formatValue({ value: getAccountSessionUserData("company_city"), type: "Text", limit: 50 })
    fromPincode = formatValue({ value: getAccountSessionUserData("company_pincode"), type: "Number_FixLength", limit: 6 })
    fromStateCode = formatValue({ value: getAccountSessionUserData("statecode"), type: "Number", limit: 2 })
    actFromStateCode = formatValue({ value: getAccountSessionUserData("statecode"), type: "Number", limit: 2 })

    toGstin = formatValue({ value: formValues.invoiceDetail.user_details[0].gstin, type: "Text_FixLength", limit: 15 })
    toTrdName = formatValue({ value: formValues.invoiceDetail.user_details[0].name, type: "Text", limit: 100 })
    toAddr1 = removeBR(shipp_address.address)
    toAddr2 = removeBR(shipp_address.areacode)
    toPlace = formatValue({ value: shipp_address.cityname, type: "Text", limit: 50 })
    toPincode = !isblank(formValues.partyPincode)
      ? formatValue({ value: formValues.partyPincode, type: "Number_FixLength", limit: 6 })
      : formatValue({ value: shipp_address.pincode, type: "Number_FixLength", limit: 6 })
    toStateCode = formatValue({ value: shipp_address.stateid, type: "Number", limit: 2 })
    actToStateCode = formatValue({ value: shipp_address.stateid, type: "Number", limit: 2 })
  } else {
    // TYPE INWARD CHANGE FROM ADDR TO TO ADDR
    toGstin = formatValue({ value: getAccountSessionUserData("gstin"), type: "Text_FixLength", limit: 15 })
    toTrdName = formatValue({ value: getAccountSessionUserData("company_name"), type: "Text", limit: 100 })
    toAddr1 = formatValue({ value: removeBR(getAccountSessionUserData("company_address1")), type: "Text", limit: 120 })
    toAddr2 = formatValue({ value: removeBR(getAccountSessionUserData("company_address2")), type: "Text", limit: 120 })
    toPlace = formatValue({ value: getAccountSessionUserData("company_city"), type: "Text", limit: 50 })
    toPincode = formatValue({ value: getAccountSessionUserData("company_pincode"), type: "Number_FixLength", limit: 6 })
    toStateCode = formatValue({ value: getAccountSessionUserData("statecode"), type: "Number", limit: 2 })
    actToStateCode = formatValue({ value: getAccountSessionUserData("statecode"), type: "Number", limit: 2 })

    if (formValues.invoiceDetail.user_details[0].gst_treatment === "3") {
      fromGstin = "URP"
    } else {
      fromGstin = formatValue({ value: formValues.invoiceDetail.user_details[0].gstin, type: "Text_FixLength", limit: 15 })
    }
    fromTrdName = formatValue({ value: formValues.invoiceDetail.user_details[0].name, type: "Text", limit: 100 })
    fromAddr1 = removeBR(shipp_address.address)
    fromAddr2 = removeBR(shipp_address.areacode)
    fromPlace = formatValue({ value: shipp_address.cityname, type: "Text", limit: 50 })
    fromPincode = !isblank(formValues.partyPincode)
      ? formatValue({ value: formValues.partyPincode, type: "Number_FixLength", limit: 6 })
      : formatValue({ value: shipp_address.pincode, type: "Number_FixLength", limit: 6 })
    fromStateCode = formatValue({ value: shipp_address.stateid, type: "Number", limit: 2 })
    actFromStateCode = formatValue({ value: shipp_address.stateid, type: "Number", limit: 2 })
  }

  const billLists = {
    userGstin: formatValue({ value: getAccountSessionUserData("gstin"), type: "Text_FixLength", limit: 15 }),
    supplyType: formatValue({ value: formValues.supplyType, type: "Text", limit: 1 }),
    subSupplyType: formatValue({ value: formValues.subType, type: "Text", limit: 2 }),
    docType: formatValue({ value: formValues.docType, type: "Text", limit: 3 }),
    transactionType: formatValue({ value: formValues.transactionType, type: "Number", limit: 1 }),
    docNo: formatValue({ value: billno, type: "Text", limit: 16 }),
    docDate: replaceAllFn(DatetoDMY(b_date), "-", "/"),
    fromGstin,
    fromTrdName,
    fromAddr1,
    fromAddr2,
    fromPlace,
    fromPincode,
    fromStateCode,
    actFromStateCode,
    toGstin,
    toTrdName,
    toAddr1,
    toAddr2,
    toPlace,
    toPincode,
    toStateCode,
    actToStateCode,
    dispatchFromGSTIN: formatValue({ value: formValues.dispatchFromGSTIN, type: "Text_FixLength", limit: 15 }),
    dispatchFromTradeName: formatValue({ value: formValues.dispatchFromTradeName, type: "Text", limit: 100 }),
    shipToGSTIN: formatValue({ value: formValues.shipToGSTIN, type: "Text_FixLength", limit: 15 }),
    shipToTradeName: formatValue({ value: formValues.shipToTradeName, type: "Text", limit: 100 }),
    totalValue: totalValue,
    subSupplyDesc: "",
    TotNonAdvolVal: 0,
    totInvValue: formatValue({ value: formValues.invoiceDetail.master_table_data[0].netamt, type: "Decimal", limit: 18 }),
    cgstValue: formatValue({ value: formValues.invoiceDetail.master_table_data[0].cgst_total, type: "Decimal", limit: 18 }),
    sgstValue: formatValue({ value: formValues.invoiceDetail.master_table_data[0].sgst_total, type: "Decimal", limit: 18 }),
    igstValue: formatValue({ value: formValues.invoiceDetail.master_table_data[0].igst_total, type: "Decimal", limit: 18 }),
    otherValue: otherValue,
    cessValue: 0,
    transMode: formatValue({ value: formValues.transportmodeSelectedValue, type: "Number", limit: 1 }),
    transDistance: formatValue({ value: formValues.distance_km, type: "Number", limit: 5 }),
    transporterName: formatValue({
      value: getdropdownfieldvalue({ dropDownData: dropDownTransport, field: "code", value: formValues.transportSelectedValue, displayvalue: "name" }),
      type: "Text",
      limit: 100,
    }),
    transporterId: formatValue({
      value: getdropdownfieldvalue({ dropDownData: dropDownTransport, field: "code", value: formValues.transportSelectedValue, displayvalue: "gstin" }),
      type: "Text_FixLength",
      limit: 15,
    }),
    transDocNo: formatValue({ value: formValues.transportDocNo, type: "Text", limit: 15 }),
    transDocDate: replaceAllFn(DatetoDMY(formValues.transportDocDate), "-", "/"),
    vehicleNo: formatValue({ value: formValues.vehicleno, type: "Text", limit: 15 }),
    vehicleType: formValues.vehicleType,
    itemList: itemList,
    mainHsnCode: itemList[0].hsnCode,
  }

  if (convertIntif(billLists.subSupplyType) === 8) {
    billLists.subSupplyDesc = removeBR(formValues.subSupplyDesc)
  }

  if (isblank(billLists.transMode) || convertIntif(billLists.transMode) === 0) {
    billLists.transMode = 1
  }

  if (multigodown_mng === 1 && use_godown_address === 1 && formValues.invoiceDetail.godown_detail.length > 0) {
    if (!isblank(formValues.invoiceDetail.godown_detail[0].name)) {
      billLists.fromAddr1 = formatValue({ value: removeBR(formValues.invoiceDetail.godown_detail[0].fromAddr1), type: "Text", limit: 120 })
      billLists.fromAddr2 = formatValue({ value: removeBR(formValues.invoiceDetail.godown_detail[0].fromAddr2), type: "Text", limit: 120 })
      billLists.fromPlace = formatValue({ value: formValues.invoiceDetail.godown_detail[0].city, type: "Text", limit: 50 })
      billLists.fromPincode = formatValue({ value: formValues.invoiceDetail.godown_detail[0].pincode, type: "Number_FixLength", limit: 6 })
      billLists.fromStateCode = formatValue({ value: formValues.invoiceDetail.godown_detail[0].state_id, type: "Number", limit: 2 })
    }
  }

  if (supplyType === "O" && formValues.invoiceDetail.user_details[0].gst_treatment === "3") {
    billLists.toGstin = "URP"
  }

  if (convertIntif(formValues.transactionType) === 2 || convertIntif(formValues.transactionType) === 4) {
    billLists.toPincode = formatValue({ value: formValues.shipToPin, type: "Number_FixLength", limit: 6 })
    billLists.toAddr1 = removeBR(formValues.shipToAddr1)
    billLists.toAddr2 = removeBR(formValues.shipToAddr2)
    billLists.toStateCode = formatValue({ value: formValues.invoiceDetail.user_details[0].state_id, type: "Number", limit: 2 })
    billLists.actToStateCode = formatValue({ value: formValues.shipToStcd, type: "Number", limit: 2 })
    billLists.toPlace = formatValue({ value: getdropdownfieldvalue({ dropDownData: ddcity, field: "code", value: formValues.shipToLoc, displayvalue: "name" }), type: "Text", limit: 50 })
  }
  if (convertIntif(formValues.transactionType) === 3 || convertIntif(formValues.transactionType) === 4) {
    billLists.fromPincode = formatValue({ value: formValues.dispatchFromPin, type: "Number_FixLength", limit: 6 })
    billLists.fromAddr1 = formatValue({ value: removeBR(formValues.dispatchFromAddr1), type: "Text", limit: 120 })
    billLists.fromAddr2 = formatValue({ value: removeBR(formValues.dispatchFromAddr2), type: "Text", limit: 120 })
    //billLists.fromStateCode = formatValue(formValues.dispatchFromStcd, "Number", 2)
    billLists.actFromStateCode = formatValue({ value: formValues.dispatchFromStcd, type: "Number", limit: 2 })
    billLists.fromPlace = formatValue({ value: getdropdownfieldvalue({ dropDownData: ddcity, field: "code", value: formValues.dispatchFromLoc, displayvalue: "name" }), type: "Text", limit: 50 })
  }

  Object.keys(billLists).forEach((e) => {
    if (billLists[e] === "error") {
      errors.push(`${e} is not valid`)
    } else if (e === "fromPincode" && isblank(billLists[e])) {
      errors.push(`Company Zipcode missing`)
    } else if (e === "fromStateCode" && isblank(billLists[e])) {
      errors.push(`Company State missing`)
    } else if (e === "toGstin" && isblank(billLists[e])) {
      errors.push(`Customer GSTIN missing`)
    } else if (e === "toPincode" && isblank(billLists[e])) {
      errors.push(`Customer Zipcode missing`)
    } else if (e === "toStateCode" && isblank(billLists[e])) {
      errors.push(`Customer State missing`)
    } else if (e === "transporterId" && isblank(billLists[e])) {
      errors.push(`Transport GSTIN missing`)
    } else if (e === "transDistance") {
      if (parseFloat(getNumberonly(billLists[e])) > 4000) {
        errors.push(`Distance cannot be greater than 4000`)
      }
    } else if (e === "itemList") {
      if (isblank(billLists[e]) || billLists[e].length === 0) {
        errors.push(`Invoice must include at least one product.`)
      } else {
        billLists[e].forEach((i, index) => {
          if (i.productName === "error") {
            errors.push(`Product name invalid at Sr No. ${index + 1}`)
          }
          if (i.productDesc === "error") {
            errors.push(`Product description invalid at Sr No. ${index + 1}`)
          }
          if (i.hsnCode === "error") {
            errors.push(`HSN code invalid at Sr No. ${index + 1}`)
          }
          if (isblank(i.hsnCode)) {
            errors.push(`HSN code missing at Sr No. ${index + 1}`)
          }
          if (i.quantity === "error") {
            errors.push(`Quantity invalid at Sr No. ${index + 1}`)
          }
          if (i.qtyUnit === "error") {
            errors.push(`Unit invalid at Sr No. ${index + 1}`)
          }
          if (i.taxableAmount === "error") {
            errors.push(`Taxable amount invalid at Sr No. ${index + 1}`)
          }
          if (i.sgstRate === "error") {
            errors.push(`SGST Rate invalid at Sr No. ${index + 1}`)
          }
          if (i.cgstRate === "error") {
            errors.push(`CGST Rate invalid at Sr No. ${index + 1}`)
          }
          if (i.igstRate === "error") {
            errors.push(`IGST Rate invalid at Sr No. ${index + 1}`)
          }
        })
      }
    } else if (e === "totInvValue" && parseFloat(getNumberonly(billLists[e])) <= 0) {
      if (
        !(
          inward_type_detail.filter((e) => e.id === formValues.invoiceDetail.master_table_data[0].invoice_type).length > 0 ||
          outward_type_detail.filter((e) => e.id === formValues.invoiceDetail.master_table_data[0].invoice_type).length > 0
        )
      ) {
        errors.push(`Invoice amount must be greater than 0`)
      }
    }
  })

  if (
    !isblank(billLists.transporterName) &&
    (billLists.transporterName.toUpperCase() === "SELF" || billLists.transporterId === billLists.userGstin || billLists.transporterName.toUpperCase() === "DIRECT")
  ) {
    billLists.transporterName = ""
    billLists.transporterId = ""
  } else {
    // billLists.vehicleNo = ""
    // billLists.vehicleType = ""
    // billLists.transMode = ""
  }

  if (isblank(billLists.vehicleNo)) {
    billLists.vehicleType = ""
    billLists.transMode = ""
  }

  // changekeys in download json
  if (generateJSONOnly) {
    billLists.transType = billLists.transactionType
    billLists.actualFromStateCode = billLists.actFromStateCode
    billLists.actualToStateCode = billLists.actToStateCode

    delete billLists.transactionType
    delete billLists.actFromStateCode
    delete billLists.actToStateCode
  }
  return { errors, billLists }
}

export const generateEInvoiceJSON = (props) => {
  const { getAccountSessionUserData, getAccountSitesetting, invoiceDetail, dropDownTransport, withEWayBill } = props
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
  const rate_decimalpoint = getAccountSitesetting("rate_decimalpoint")
  const multigodown_mng = parseInt(getAccountSitesetting("multigodown_mng"))
  const use_godown_address = parseInt(getAccountSitesetting("use_godown_address"))
  const masterData = invoiceDetail.master_table_data[0]
  const userData = invoiceDetail.user_details[0]
  const Type = masterData.invoice_type === "salesreturn" ? "CRN" : masterData.invoice_type === "purchasereturn" ? "DBN" : "INV"
  const ewaybill_detail = masterData.ewaybill_detail
  var Discount = 0
  var OthChrg = 0
  var errors = []
  var shipp_address = {}
  if (!isblank(invoiceDetail.master_table_data[0].shipp_address)) {
    shipp_address = JSON.parse(invoiceDetail.master_table_data[0].shipp_address)
  }

  var ShipDtls = null
  var DispDtls = null

  if (!isblank(ewaybill_detail)) {
    if (convertIntif(ewaybill_detail.transactionType) === 2 || convertIntif(ewaybill_detail.transactionType) === 4) {
      ShipDtls = {
        Gstin: formatValueWithMin({ value: ewaybill_detail.shipToGSTIN, type: "Text_FixLength", limit: 15, minLength: 15 }),
        LglNm: formatValueWithMin({ value: ewaybill_detail.shipToTradeName, type: "Text", limit: 100, minLength: 3 }),
        TrdNm: formatValueWithMin({ value: ewaybill_detail.shipToTradeName, type: "Text", limit: 100, minLength: 3 }),
        Addr1: formatValueWithMin({ value: removeBR(ewaybill_detail.shipToAddr1), type: "Text", limit: 120, minLength: 1 }),
        Addr2: formatValueWithMin({ value: removeBR(ewaybill_detail.shipToAddr2), type: "Text", limit: 120, minLength: 1 }),
        Loc: formatValueWithMin({
          value: getdropdownfieldvalue({ dropDownData: invoiceDetail.city_data, field: "city_id", value: ewaybill_detail.shipToLoc, displayvalue: "city" }),
          type: "Text",
          limit: 50,
          minLength: 3,
        }),
        Stcd: formatValueWithMin({ value: ewaybill_detail.shipToStcd, type: "Text", limit: 2, minLength: 1 }),
        Pin: formatValueWithMin({ value: ewaybill_detail.shipToPin, type: "Number_FixLength", limit: 6, minLength: 6 }),
      }
    }

    if (convertIntif(ewaybill_detail.transactionType) === 3 || convertIntif(ewaybill_detail.transactionType) === 4) {
      DispDtls = {
        Nm: formatValueWithMin({ value: ewaybill_detail.dispatchFromTradeName, type: "Text", limit: 100, minLength: 3 }),
        Addr1: formatValueWithMin({ value: removeBR(ewaybill_detail.dispatchFromAddr1), type: "Text", limit: 120, minLength: 1 }),
        Addr2: formatValueWithMin({ value: removeBR(ewaybill_detail.dispatchFromAddr2), type: "Text", limit: 120, minLength: 1 }),
        Loc: formatValueWithMin({
          value: getdropdownfieldvalue({ dropDownData: invoiceDetail.city_data, field: "city_id", value: ewaybill_detail.dispatchFromLoc, displayvalue: "city" }),
          type: "Text",
          limit: 50,
          minLength: 3,
        }),
        Stcd: formatValueWithMin({ value: ewaybill_detail.dispatchFromStcd, type: "Text", limit: 2, minLength: 1 }),
        Pin: formatValueWithMin({ value: ewaybill_detail.dispatchFromPin, type: "Number_FixLength", limit: 6, minLength: 6 }),
      }
    }
  }

  invoiceDetail.details_sub_table_data
    .filter((e) => convertIntif(e.apply_gst) !== 1)
    .forEach((e) => {
      if (checkCondition(e.taxtype, ["IGST", "SGST", "CGST"], "not")) {
        if (convertIntif(e.discount_enable) === 1) {
          //set Discount as 0 as taxable amount already disount minus
          Discount = Discount + parseFloat(getNumberonly(e.amt))
        } else {
          OthChrg = OthChrg + parseFloat(getNumberonly(e.amt))
        }
      }
    })
  var ItemList = invoiceDetail.details_table_data.map((e, index) => {
    var itemDiscount = 0
    var itemOtherAmt = 0
    if (!isblank(e.otherminus_detail)) {
      var otherminus_detail = JSON.parse(decodeURIComponent(e.otherminus_detail))
      Object.keys(otherminus_detail).forEach((i) => {
        itemDiscount = itemDiscount + parseFloat(getNumberonly(otherminus_detail[i]))
      })
    }
    if (!isblank(e.otherplus_detail)) {
      var otherplus_detail = JSON.parse(decodeURIComponent(e.otherplus_detail))
      Object.keys(otherplus_detail).forEach((i) => {
        itemOtherAmt = itemOtherAmt + parseFloat(getNumberonly(otherplus_detail[i]))
      })
    }

    var TotItemVal = parseFloat(
      toFixedFn(parseFloat(parseFloat(getNumberonly(e.taxable_amt)) + parseFloat(getNumberonly(e.igst_amt)) + parseFloat(getNumberonly(e.cgst_amt)) + parseFloat(getNumberonly(e.sgst_amt))))
    )

    const newItem = {
      SlNo: (index + 1).toString(),
      PrdDesc: formatValueWithMin({ value: e.itemname, type: "Text", limit: 300, minLength: 3 }),
      IsServc: convertIntif(e.item_type) === 2 ? "Y" : "N",
      HsnCd: formatValueWithMin({ value: e.hsn_code, type: "Text", limit: 8, minLength: 4 }),
      Barcde: null,
      Qty: parseFloat(getNumberonlyDecimal(e.qty, inventory_decimalpoint)),
      FreeQty: 0,
      Unit: formatValueWithMin({ value: e.unitname, type: "Text", limit: 8, minLength: 3 }),
      UnitPrice: parseFloat(getNumberonlyDecimal(e.rate, rate_decimalpoint)),
      TotAmt: parseFloat(getNumberonlyDecimal(parseFloat(getNumberonly(e.grossamt)) + itemOtherAmt, 2)),
      Discount: itemDiscount,
      PreTaxVal: 0,
      AssAmt: parseFloat(getNumberonly(e.taxable_amt)),
      GstRt: parseFloat(getNumberonly(e.tax_scheme_rate)),
      IgstAmt: parseFloat(getNumberonly(e.igst_amt)),
      CgstAmt: parseFloat(getNumberonly(e.cgst_amt)),
      SgstAmt: parseFloat(getNumberonly(e.sgst_amt)),
      CesRt: 0,
      CesAmt: 0,
      CesNonAdvlAmt: 0,
      StateCesRt: 0,
      StateCesAmt: 0,
      StateCesNonAdvlAmt: 0,
      //OthChrg: itemOtherAmt,
      OthChrg: 0,
      TotItemVal: TotItemVal,
      OrdLineRef: null,
      OrgCntry: null,
      PrdSlNo: null,
      BchDtls: null,
      AttribDtls: null,
    }
    return newItem
  })

  let billno = ""
  let b_date = ""
  if (masterData) {
    billno = masterData.billno
    b_date = masterData.b_date
    if (masterData.invoice_type === "salesreturn") {
      billno = masterData.creditnote_no
      b_date = masterData.creditnote_date
    } else if (masterData.invoice_type === "purchasereturn") {
      billno = masterData.debitnote_no
      b_date = masterData.debitnote_date
    }
  }

  var eInvoiceJSON = [
    {
      Version: invoiceDetail.e_invoice_version,
      TranDtls: {
        TaxSch: "GST",
        SupTyp: "B2B",
        RegRev: masterData.reversable_charge === "1" ? "Y" : "N",
        EcmGstin: null,
        IgstOnIntra: "N",
      },
      DocDtls: {
        Typ: Type,
        No: formatValueWithMin({ value: billno, type: "Text", limit: 16, minLength: 1 }),
        Dt: formatValueWithMin({ value: replaceAllFn(DatetoDMY(b_date), "-", "/"), type: "Text", limit: 10, minLength: 10 }),
      },
      SellerDtls: {
        Gstin: formatValueWithMin({ value: getAccountSessionUserData("gstin"), type: "Text_FixLength", limit: 15, minLength: 15 }),
        LglNm: formatValueWithMin({ value: getAccountSessionUserData("company_name"), type: "Text", limit: 100, minLength: 3 }),
        TrdNm: formatValueWithMin({ value: getAccountSessionUserData("company_name"), type: "Text", limit: 100, minLength: 3 }),
        Addr1: formatValueWithMin({ value: removeBR(getAccountSessionUserData("company_address1")), type: "Text", limit: 100, minLength: 1 }),
        Addr2: formatValueWithMin({ value: removeBR(getAccountSessionUserData("company_address2")), type: "Text", limit: 100, minLength: 3 }),
        Loc: formatValueWithMin({ value: getAccountSessionUserData("company_city"), type: "Text", limit: 50, minLength: 3 }),
        Pin: formatValueWithMin({ value: getAccountSessionUserData("company_pincode"), type: "Number_FixLength", limit: 6, minLength: 6 }),
        Stcd: formatValueWithMin({ value: getAccountSessionUserData("statecode"), type: "Text", limit: 2, minLength: 1 }),
        Ph: formatValueWithMin({ value: getAccountSessionUserData("mobile"), type: "Text", limit: 12, minLength: 6 }),
        Em: formatValueWithMin({ value: getAccountSessionUserData("email"), type: "Text", limit: 100, minLength: 6 }),
      },
      BuyerDtls: {
        Gstin: formatValueWithMin({ value: userData.gstin, type: "Text_FixLength", limit: 15, minLength: 15 }),
        LglNm: formatValueWithMin({ value: userData.name, type: "Text", limit: 100, minLength: 3 }),
        TrdNm: formatValueWithMin({ value: userData.name, type: "Text", limit: 100, minLength: 3 }),
        Pos: formatValueWithMin({ value: masterData.placeof_supply, type: "Text", limit: 2, minLength: 1 }),
        Addr1: formatValueWithMin({ value: removeBR(userData.address), type: "Text", limit: 100, minLength: 1 }),
        Addr2: formatValueWithMin({ value: removeBR(userData.areacode), type: "Text", limit: 100, minLength: 3 }),
        Loc: formatValueWithMin({ value: userData.cityname, type: "Text", limit: 50, minLength: 3 }),
        Pin: formatValueWithMin({ value: userData.pincode, type: "Number_FixLength", limit: 6, minLength: 6 }),
        Stcd: formatValueWithMin({ value: userData.state_id, type: "Text", limit: 2, minLength: 1 }),
        Ph: formatValueWithMin({ value: userData.mobile, type: "Text", limit: 12, minLength: 6 }),
        Em: formatValueWithMin({ value: userData.email, type: "Text", limit: 100, minLength: 6 }),
      },
      DispDtls: DispDtls,
      ShipDtls: ShipDtls,
      ItemList: ItemList,
      ValDtls: {
        AssVal: formatValue({ value: masterData.taxable_amt_tot, type: "Decimal", limit: 14 }),
        CgstVal: formatValue({ value: masterData.cgst_total, type: "Decimal", limit: 14 }),
        SgstVal: formatValue({ value: masterData.sgst_total, type: "Decimal", limit: 14 }),
        IgstVal: formatValue({ value: masterData.igst_total, type: "Decimal", limit: 14 }),
        CesVal: 0,
        StCesVal: 0,
        Discount: formatValue({ value: Discount, type: "Decimal", limit: 14 }),
        OthChrg: formatValue({ value: OthChrg, type: "Decimal", limit: 14 }),
        RndOffAmt: parseFloat(getNumberonly(masterData.roundoffamt)),
        TotInvVal: formatValue({ value: masterData.netamt, type: "Decimal", limit: 14 }),
        TotInvValFc: 0,
      },
      PayDtls: null,
      RefDtls: null,
      AddlDocDtls: null,
      ExpDtls: null,
      EwbDtls: null,
    },
  ]
  if (withEWayBill) {
    var TransId = masterData.transportcode
    var Distance = masterData.distance_km
    var VehNo = masterData.vehicleno
    var VehType = masterData.vehicleType
    var TransMode = masterData.transportmode
    var TransDocNo = masterData.transportDocNo
    var TransDocDate = masterData.transportDocDate
    TransDocDate = replaceAllFn(DatetoDMY(TransDocDate), "-", "/")

    if (!isblank(ewaybill_detail.distance_km)) {
      Distance = ewaybill_detail.distance_km
    }
    if (!isblank(ewaybill_detail.transportId)) {
      TransId = ewaybill_detail.transportId
    }
    if (!isblank(ewaybill_detail.vehicleno)) {
      VehNo = ewaybill_detail.vehicleno
    }
    if (!isblank(ewaybill_detail.vehicleType)) {
      VehType = ewaybill_detail.vehicleType
    }
    if (!isBlankCode(ewaybill_detail.transportmode)) {
      TransMode = ewaybill_detail.transportmode
    }
    if (!isblank(ewaybill_detail.transportDocNo)) {
      TransDocNo = ewaybill_detail.transportDocNo
    }
    if (!isblank(ewaybill_detail.transportDocDate)) {
      TransDocDate = ewaybill_detail.transportDocDate
      TransDocDate = replaceAllFn(DatetoDMY(TransDocDate), "-", "/")
    }
    const EwbDtls = {
      TransId: formatValueWithMin({
        value: getdropdownfieldvalue({ dropDownData: dropDownTransport, field: "code", value: TransId, displayvalue: "gstin" }),
        type: "Text_FixLength",
        limit: 15,
        minLength: 15,
      }),
      TransName: formatValueWithMin({ value: getdropdownfieldvalue({ dropDownData: dropDownTransport, field: "code", value: TransId, displayvalue: "name" }), type: "Text", limit: 100, minLength: 3 }),
      Distance: formatValue({ value: Distance, type: "Number", limit: 5 }),
      TransDocNo: formatValue({ value: TransDocNo, type: "Text", limit: 20 }),
      TransDocDt: TransDocDate,
      VehNo: formatValue({ value: VehNo, type: "Text", limit: 20, limit1: 4 }),
      VehType: VehType,
      TransMode: formatValue({ value: TransMode, type: "Text", limit: 1 }),
    }
    eInvoiceJSON[0].EwbDtls = EwbDtls
  }
  if (multigodown_mng === 1 && use_godown_address === 1 && invoiceDetail.godown_detail.length > 0) {
    eInvoiceJSON[0].SellerDtls.Addr1 = formatValue({ value: removeBR(invoiceDetail.godown_detail[0].fromAddr1), type: "Text", limit: 120 })
    eInvoiceJSON[0].SellerDtls.Addr2 = formatValue({ value: removeBR(invoiceDetail.godown_detail[0].fromAddr2), type: "Text", limit: 120 })
    eInvoiceJSON[0].SellerDtls.Loc = formatValue({ value: invoiceDetail.godown_detail[0].city, type: "Text", limit: 50 })
    eInvoiceJSON[0].SellerDtls.Pin = formatValue({ value: invoiceDetail.godown_detail[0].pincode, type: "Number_FixLength", limit: 6 })
    eInvoiceJSON[0].SellerDtls.Stcd = formatValue({ value: invoiceDetail.godown_detail[0].state_id, type: "Text", limit: 2 })
  }

  Object.keys(eInvoiceJSON[0]).forEach((e) => {
    var temp = eInvoiceJSON[0][e]
    if (!isblank(temp)) {
      Object.keys(temp).forEach((i) => {
        if (temp[i] === "error") {
          errors.push(`${i} is not valid`)
        } else if (isblank(temp[i])) {
          eInvoiceJSON[0][e][i] = null
        }
      })
      if (e === "EwbDtls") {
        errors = [
          ...errors,
          ...getErrors(temp, [
            { key: "Distance", message: "Distance between from zipcode - to zipcode is required in e-Way Bill details" },
            { key: "TransName", message: "Transport is required in e-Way Bill details" },
          ]),
        ]

        if (!isblank(temp.TransName) && (temp.TransName.toUpperCase() === "SELF" || temp.TransId === eInvoiceJSON[0].SellerDtls.Gstin || temp.TransName.toUpperCase() === "DIRECT")) {
          errors = [
            ...errors,
            ...getErrors(temp, [
              { key: "VehNo", message: "Vehicle No is required in e-Way Bill details" },
              { key: "VehType", message: "Vehicle Type is required in e-Way Bill details" },
              { key: "TransMode", message: "Transport Mode is required in e-Way Bill details" },
            ]),
          ]
        } else {
          errors = [
            ...errors,
            ...getErrors(temp, [
              { key: "TransId", message: "Transport GSTIN is required" },
              { key: "TransName", message: "Transport is required in e-Way Bill details" },
            ]),
          ]
        }
      } else if (e === "ValDtls") {
        errors = [
          ...errors,
          ...getErrors(temp, [
            { key: "AssVal", message: "Total Taxable value is missing" },
            { key: "TotInvVal", message: "Total Invoice value is missing" },
          ]),
        ]
      } else if (e === "ItemList") {
        errors = [
          ...errors,
          ...getErrors(temp, [
            { key: "SlNo", message: "Sr No is missing", key2: "PrdDesc" },
            { key: "IsServc", message: "IsServc is missing", key2: "PrdDesc" },
            { key: "HsnCd", message: "HSN Code is required", key2: "PrdDesc" },
            { key: "UnitPrice", message: "Unit Price is required", key2: "PrdDesc" },
            { key: "TotAmt", message: "Gross Amount is required", key2: "PrdDesc" },
            { key: "AssAmt", message: "Total Taxable Amount is required", key2: "PrdDesc" },
            { key: "GstRt", message: "Tax % is required", key2: "PrdDesc" },
            { key: "TotItemVal", message: "Total Amount is required", key2: "PrdDesc" },
          ]),
        ]
      } else if (e === "ShipDtls") {
        errors = [
          ...errors,
          ...getErrors(temp, [
            { key: "LglNm", message: "Ship to Trade Name is required in e-Way Bill details" },
            { key: "TrdNm", message: "Ship to Trade Name is required in e-Way Bill details" },
            { key: "Addr1", message: "Ship to Address is required in e-Way Bill details" },
            { key: "Pin", message: "Ship to Zipcode is required in e-Way Bill details" },
            { key: "Stcd", message: "Ship to State is required in e-Way Bill details" },
            { key: "Loc", message: "Ship to City is required in e-Way Bill details" },
          ]),
        ]
      } else if (e === "DispDtls") {
        errors = [
          ...errors,
          ...getErrors(temp, [
            { key: "Nm", message: "Dispatch From Trade Name is required in e-Way Bill details" },
            { key: "Addr1", message: "Dispatch From Address is required in e-Way Bill details" },
            { key: "Pin", message: "Dispatch From Zipcode is required in e-Way Bill details" },
            { key: "Stcd", message: "Dispatch From State is required in e-Way Bill details" },
            { key: "Loc", message: "Dispatch From City is required in e-Way Bill details" },
          ]),
        ]
      } else if (e === "BuyerDtls") {
        errors = [
          ...errors,
          ...getErrors(temp, [
            { key: "Gstin", message: "Customer GSTIN is required" },
            { key: "LglNm", message: "Customer name is required" },
            { key: "Pos", message: "Place of Supply is required in Invoice" },
            { key: "Addr1", message: "Customer Address is required" },
            { key: "Loc", message: "Customer City is required" },
            { key: "Pos", message: "Customer Place of Supply is required" },
            { key: "Stcd", message: "Customer State is required" },
            { key: "Pin", message: "Customer Pincode is required" },
          ]),
        ]
      } else if (e === "SellerDtls") {
        var SellerDtlsGstin = "Company GSTIN is required"
        var SellerDtlsAddr1 = "Company Address is required"
        var SellerDtlsPin = "Company Zipcode is required"
        var SellerDtlsStcd = "Company State is required"
        var SellerDtlsLoc = "Company City is required"
        if (multigodown_mng === 1 && invoiceDetail.godown_detail.length > 0) {
          SellerDtlsGstin = "Godown GSTIN is required"
          SellerDtlsAddr1 = "Godown Address is required"
          SellerDtlsPin = "Godown Zipcode is required"
          SellerDtlsStcd = "Godown State is required"
          SellerDtlsLoc = "Godown City is required"
        }
        errors = [
          ...errors,
          ...getErrors(temp, [
            { key: "Gstin", message: SellerDtlsGstin },
            { key: "LglNm", message: "Company name is required" },
            { key: "Addr1", message: SellerDtlsAddr1 },
            { key: "Loc", message: SellerDtlsLoc },
            { key: "Pin", message: SellerDtlsPin },
            { key: "Stcd", message: SellerDtlsStcd },
          ]),
        ]
      } else if (e === "DocDtls") {
        errors = [
          ...errors,
          ...getErrors(temp, [
            { key: "Typ", message: "Doc Type is required" },
            { key: "No", message: "Doc No is required" },
            { key: "Dt", message: "Doc Date is required" },
          ]),
        ]
      } else if (e === "TranDtls") {
        errors = [...errors, ...getErrors(temp, [{ key: "SupTyp", message: "Document Category is required" }])]
      }
    }
  })
  if (withEWayBill) {
    if (
      !isblank(eInvoiceJSON[0].EwbDtls.TransName) &&
      (eInvoiceJSON[0].EwbDtls.TransName.toUpperCase() === "SELF" ||
        eInvoiceJSON[0].EwbDtls.TransId === eInvoiceJSON[0].SellerDtls.Gstin ||
        eInvoiceJSON[0].EwbDtls.TransName.toUpperCase() === "DIRECT")
    ) {
      eInvoiceJSON[0].EwbDtls.TransId = null
      eInvoiceJSON[0].EwbDtls.TransName = null
    } else {
      // eInvoiceJSON[0].EwbDtls.VehNo = null
      // eInvoiceJSON[0].EwbDtls.VehType = null
      // eInvoiceJSON[0].EwbDtls.TransMode = null
    }

    if (isblank(eInvoiceJSON[0].EwbDtls.VehNo)) {
      eInvoiceJSON[0].EwbDtls.VehType = null
    }
  }

  return { eInvoiceJSON, errors }
}

const getErrors = (temp, requiredField) => {
  var errors = []
  if (temp.length > 0) {
    temp.forEach((t) => {
      Object.keys(t).forEach((i) => {
        requiredField.forEach((e) => {
          if (i === e.key && isblank(t[i])) {
            if (!isblank(e.key2)) {
              errors.push(`${e.message} in ${t[e.key2]}`)
            } else {
              errors.push(e.message)
            }
          }
        })
      })
    })
  } else {
    Object.keys(temp).forEach((i) => {
      requiredField.forEach((e) => {
        if (i === e.key && isblank(temp[i])) {
          if (!isblank(e.key2)) {
            errors.push(`${e.message} in ${temp[e.key2]}`)
          } else {
            errors.push(e.message)
          }
        }
      })
    })
  }
  return errors
}

export const getOnlyInvoice = (getAccountSiteSetting, type) => {
  const purchase_type_detail = getAccountSiteSetting("purchase_type_detail")
  const invoice_type_detail = getAccountSiteSetting("invoice_type_detail")
  if (type === "purchase") {
    return purchase_type_detail.filter((e) => e.id !== "purchase_order")
  } else {
    return invoice_type_detail.filter((e) => e.id !== "sales_order" && e.id !== "estimate" && e.id !== "getmystore" && e.id !== "getmycatalogue" && e.id !== "job_delivery_challan")
  }
}

export const getAllEstimateStatus = () => {
  return [
    {
      id: "0",
      name: "Pending",
    },
    {
      id: "2",
      name: "Closed",
    },
    {
      id: "3",
      name: "Negotiation",
    },
    {
      id: "4",
      name: "Not Interested",
    },
    {
      id: "5",
      name: "Won",
    },
    {
      id: "6",
      name: "Lost",
    },
  ]
}

export const settingFormat = ({ key, value, rights, from }) => {
  var returnValue = value ? convertIntif(value) : ""
  if (isblank(returnValue)) {
    if (key === "e_invoice" || key === "jwellery_metal" || key === "tds" || key === "customerListAuto" || key === "itemListAuto") {
      returnValue = 0
    }
  }
  return returnValue
}

export const setSetting = ({ settingData, rights, from }) => {
  var firmdetails = {}
  firmdetails = cloneObj(settingData)
  if (!isblank(settingData)) {
    Object.keys(settingData).forEach((item) => {
      if (
        item === "invoice_type_detail" ||
        item === "purchase_type_detail" ||
        item === "inward_type_detail" ||
        item === "outward_type_detail" ||
        item === "salesreturn_type_detail" ||
        item === "purchasereturn_type_detail"
      ) {
        if (!isblank(settingData[item])) {
          firmdetails[item] = cloneObj(settingData[item])
        } else {
          firmdetails[item] = []
        }
      } else if (
        item === "taxSetting" ||
        item === "contactSetting" ||
        item === "itemSetting" ||
        item === "transactionPrintSetting" ||
        item === "generalSetting" ||
        item === "invoiceSetting" ||
        item === "moduleSetting" ||
        item === "fbShopSetting" ||
        item === "transaction_setting"
      ) {
        if (!isblank(settingData[item]) && typeof settingData[item] === "object") {
          Object.keys(settingData[item]).forEach((it) => {
            if (item === "fbShopSetting" && (it === "contact" || it === "general_setting")) {
              if (!isblank(settingData[item][it]) && typeof settingData[item][it] === "object") {
                Object.keys(settingData[item][it]).forEach((e) => {
                  if (e === "catalogueModule") {
                    firmdetails[e] = settingData[item][it][e]
                    firmdetails[e] = settingFormat({ key: e, value: firmdetails[e], rights, from })
                  }
                })
              }
            } else if (item === "transaction_setting" && (it === "receipt" || it === "payment" || it === "journal_entry")) {
              if (!isblank(settingData[item][it]) && typeof settingData[item][it] === "object") {
                Object.keys(settingData[item][it]).forEach((e) => {
                  firmdetails[e] = settingData[item][it][e]
                  firmdetails[e] = settingFormat({ key: e, value: firmdetails[e], rights, from })
                })
              }
            } else {
              firmdetails[it] = settingData[item][it]
              firmdetails[it] = settingFormat({ key: it, value: firmdetails[it], rights, from })
            }
          })
        }
      }
    })
  }
  return firmdetails
}

export const number2text = (value) => {
  const Value = getNumberonly(value)
  var fraction = Math.round(frac(Value) * 100)
  var f_text = ""
  if (fraction > 0) {
    f_text = "AND " + convert_number(fraction) + " PAISE "
  }
  return convert_number(Value) + " " + f_text + "ONLY"
}

function frac(f) {
  return f % 1
}

function digit_to_text(number) {
  var Gn = Math.floor(number / 10000000) /* Crore */
  number -= Gn * 10000000
  var kn = Math.floor(number / 100000) /* lakhs */
  number -= kn * 100000
  var Hn = Math.floor(number / 1000) /* thousand */
  number -= Hn * 1000
  var Dn = Math.floor(number / 100) /* Tens (deca) */
  number = number % 100 /* Ones */
  var tn = Math.floor(number / 10)
  var one = Math.floor(number % 10)
  var res = ""
  if (Gn > 0) {
    res += digit_to_text(Gn) + " CRORE"
  }
  if (kn > 0) {
    res += (res == "" ? "" : " ") + digit_to_text(kn) + " LAKH"
  }
  if (Hn > 0) {
    res += (res == "" ? "" : " ") + digit_to_text(Hn) + " THOUSAND"
  }
  if (Dn) {
    res += (res == "" ? "" : " ") + digit_to_text(Dn) + " HUNDRED"
  }
  var ones = Array(
    "",
    "ONE",
    "TWO",
    "THREE",
    "FOUR",
    "FIVE",
    "SIX",
    "SEVEN",
    "EIGHT",
    "NINE",
    "TEN",
    "ELEVEN",
    "TWELVE",
    "THIRTEEN",
    "FOURTEEN",
    "FIFTEEN",
    "SIXTEEN",
    "SEVENTEEN",
    "EIGHTEEN",
    "NINETEEN"
  )
  var tens = Array("", "", "TWENTY", "THIRTY", "FOURTY", "FIFTY", "SIXTY", "SEVENTY", "EIGHTY", "NINETY")
  if (tn > 0 || one > 0) {
    if (!(res == "")) {
      res += " AND "
    }
    if (tn < 2) {
      res += ones[tn * 10 + one]
    } else {
      res += tens[tn]
      if (one > 0) {
        res += "-" + ones[one]
      }
    }
  }
  if (res == "") {
    res = "zero"
  }
  return res
}

function convert_number(number) {
  if (number < 0) {
    return "NUMBER OUT OF RANGE!"
  }
  if (number > 999999999) {
    let prepand = number.toString().substring(0, number.toString().length - 10)
    prepand = `${prepand}`
    let extraString = digit_to_text(prepand)
    extraString = replaceAllFn(extraString, " AND ", " ")
    let append = parseFloat(getNumberonly(`${prepand}0000000`))
    if (number - append < 100) {
      return `${extraString} CRORE AND ${digit_to_text(number - append)}`
    } else {
      return `${extraString} CRORE ${digit_to_text(number - append)}`
    }
  }
  return digit_to_text(number)
}

export const getCustomTotal = (ddcustomfield, DetailGrid) => {
  const __cust_customFieldTotal = {}
  ddcustomfield
    .filter(function (item) {
      return item.masterDetailLevel === "1" && item.isActive === "1" && parseInt(item.dataType) === cF.Decimal && item.cal_total === "1"
    })
    .forEach((customField, index) => {
      if (DetailGrid && DetailGrid.length > 0) {
        __cust_customFieldTotal[customField.name] = amount_formatDecimal(
          DetailGrid.map((item) => parseFloat(getNumberonlyDecimal(item[customField.name], customField.decimalPoint))).reduce((prev, next) => prev + next),
          customField.decimalPoint
        )
      }
    })
  ddcustomfield
    .filter(function (item) {
      return (
        item.masterDetailLevel === "1" &&
        item.isActive === "1" &&
        (parseInt(item.dataType) === cF.Dropdown || parseInt(item.dataType) === cF.Creatable_Dropdown) &&
        item.isNumeric === "1" &&
        item.cal_total === "1"
      )
    })
    .forEach((customField, index) => {
      if (DetailGrid && DetailGrid.length > 0) {
        __cust_customFieldTotal[customField.name] = amount_formatDecimal(
          DetailGrid.map((item) =>
            parseFloat(
              getNumberonlyDecimal(getdropdownfieldvalue({ dropDownData: customField.dropDownOptions, field: "code", value: item[customField.name], displayvalue: "name" }), customField.decimalPoint)
            )
          ).reduce((prev, next) => prev + next),
          customField.decimalPoint
        )
      }
    })
  return __cust_customFieldTotal
}

export const getSearchValue = (individualSearch, id) => {
  let returnValue = ""
  individualSearch.every((e) => {
    if (e.id === id) {
      returnValue = e.search_text
      return false
    }
    return true
  })
  return returnValue
}

export const searchByColumnData = ({ dataRow, columnData, search_text, SiteSetting = "", num_filterValue, individualSearch }) => {
  const siteSettingForformatField =
    SiteSetting === ""
      ? {}
      : {
          inventory_decimalpoint: SiteSetting.inventory_decimalpoint,
          rate_decimalpoint: SiteSetting.rate_decimalpoint,
          timezone: SiteSetting.timezone,
        }

  var matched = false
  let forceFully = false
  if (!isblank(search_text)) {
    columnData.every((column) => {
      if (!isblank(dataRow[column.id])) {
        /* eslint-disable-next-line */
        if (column.numeric && !isNaN(num_filterValue)) {
          if (dataRow[column.id].toString().toLowerCase() === search_text.toString().toLowerCase()) {
            matched = true
            return false
          } else if (!isblank(column.format)) {
            if (column.format === "Dr_Cr_indian_rupee") {
              var dataForCheck = parseFloat(getNumberonly(dataRow[column.id], NaN))
              dataForCheck = Math.abs(dataForCheck)
              matched = dataForCheck === parseFloat(getNumberonly(num_filterValue, NaN))
            } else {
              if (
                format_for_search({ value: dataRow[column.id], format: column.format, localSetting: siteSettingForformatField }).toString().toLowerCase() ===
                format_for_search({ value: search_text, format: column.format, localSetting: siteSettingForformatField }).toString().toLowerCase()
              ) {
                matched = true
                return false
              }
            }
          }
        } else if (!column.numeric) {
          if (dataRow[column.id].toString().toLowerCase().indexOf(search_text.toString().toLowerCase()) > -1) {
            matched = true
            return false
          } else if (!isblank(column.format)) {
            if (
              format_for_search({ value: dataRow[column.id], format: column.format, localSetting: siteSettingForformatField })
                .toString()
                .toLowerCase()
                .indexOf(format_for_search({ value: search_text, format: column.format, localSetting: siteSettingForformatField }).toString().toLowerCase()) > -1
            ) {
              matched = true
              return false
            }
          }
        }
      }
      return true
    })
  } else {
    matched = true
  }
  if (isblank(search_text) && individualSearch && individualSearch.length > 0 && matched) {
    matched = false
  }
  if ((matched === true || isblank(search_text)) && individualSearch && individualSearch.length > 0) {
    if (matched === true) {
      matched = false
      forceFully = true
    }
    columnData.every((column) => {
      if (!isblank(dataRow[column.id])) {
        let search_text1 = getSearchValue(individualSearch, column.id)
        var num_filterValue1 = search_text1.toString().replace(/,/g, "")
        num_filterValue1 = num_filterValue1.replace(/-/g, "")
        if (!isblank(search_text1)) {
          /* eslint-disable-next-line */
          if (column.numeric && !isNaN(num_filterValue1)) {
            if (dataRow[column.id].toString().toLowerCase() === search_text1.toString().toLowerCase()) {
              matched = true
              return false
            } else if (!isblank(column.format)) {
              if (column.format === "Dr_Cr_indian_rupee") {
                var dataForCheck = parseFloat(getNumberonly(dataRow[column.id], NaN))
                dataForCheck = Math.abs(dataForCheck)
                matched = dataForCheck === parseFloat(getNumberonly(num_filterValue1, NaN))
              } else {
                if (
                  format_for_search({ value: dataRow[column.id], format: column.format, localSetting: siteSettingForformatField }).toString().toLowerCase() ===
                  format_for_search({ value: search_text1, format: column.format, localSetting: siteSettingForformatField }).toString().toLowerCase()
                ) {
                  matched = true
                  return false
                }
              }
            }
          } else if (!column.numeric) {
            if (dataRow[column.id].toString().toLowerCase().indexOf(search_text1.toString().toLowerCase()) > -1) {
              matched = true
              return false
            } else if (!isblank(column.format)) {
              if (
                format_for_search({ value: dataRow[column.id], format: column.format, localSetting: siteSettingForformatField })
                  .toString()
                  .toLowerCase()
                  .indexOf(format_for_search({ value: search_text1, format: column.format, localSetting: siteSettingForformatField }).toString().toLowerCase()) > -1
              ) {
                matched = true
                return false
              }
            }
          }
        }
      }
      return true
    })
  }

  if (matched === false) {
    if (isblank(search_text)) {
      if (individualSearch && individualSearch.length > 0) {
        let searchBlank = 0
        individualSearch.forEach((i) => {
          if (isblank(i.search_text)) {
            searchBlank = searchBlank + 1
          }
        })
        if (searchBlank === individualSearch.length) {
          matched = true
        }
      } else {
        matched = true
      }
    } else {
      if (individualSearch && individualSearch.length > 0) {
        let searchBlank = 0
        individualSearch.forEach((i) => {
          if (isblank(i.search_text)) {
            searchBlank = searchBlank + 1
          }
        })
        if (searchBlank === individualSearch.length) {
          matched = forceFully
        }
      }
    }
  }
  return matched
}

export const isSearchFBDate = (str, getLocalSessionUserData) => {
  var VALUE = ""
  var d = new Date()
  var cm = d.getMonth() + 1
  var cy = d.getFullYear()
  /* eslint-disable-next-line */
  var parms = str.split(/[\.\-\/]/)
  var yyyy = parseInt(parms[2], 10)
  var mm = parseInt(parms[1], 10)
  var dd = parseInt(parms[0], 10)

  if (isNaN(mm)) {
    mm = cm
  }

  if (isNaN(yyyy)) {
    if (mm > 3) {
      yyyy = parseInt(getLocalSessionUserData("financial_year"), 10)
    } else {
      yyyy = parseInt(getLocalSessionUserData("financial_year"), 10) + 1
    }
  } else {
    if (yyyy.toString().length === 4) {
    } else if (yyyy.toString().length === 2) {
      yyyy = parseInt(Math.floor(cy / 100).toString() + yyyy.toString())
    } else {
      yyyy = ""
    }
  }

  var date = new Date(yyyy, mm - 1, dd, 0, 0, 0, 0)
  if (mm === date.getMonth() + 1 && dd === date.getDate() && (yyyy === date.getFullYear() || yyyy === date.getYear())) {
    if (dd < 10) {
      dd = "0" + dd.toString()
    }
    if (mm < 10) {
      mm = "0" + mm.toString()
    }

    VALUE = dd + "-" + mm + "-" + date.getFullYear()
  } else {
    VALUE = ""
  }
  return VALUE
}

export const findObj = (data, value, key) => {
  if (isblank(value)) {
    return ""
  }
  if (isblank(key)) {
    key = "code"
  }
  return data.find((e) => convertIntif(e[key]) === convertIntif(value))
}

export const generateAddress = ({ address, areacode, city, state, country, pincode }) => {
  var shipping_addressDisplay = ""
  if (!isblank(address)) {
    shipping_addressDisplay = `${replacefunction(replaceBRtoNewLine(address))}`
  }
  if (!isblank(areacode)) {
    if (!isblank(shipping_addressDisplay)) {
      shipping_addressDisplay = `${shipping_addressDisplay}, ${areacode}`
    } else {
      shipping_addressDisplay = `${areacode}`
    }
  }
  if (!isblank(city)) {
    if (!isblank(shipping_addressDisplay)) {
      shipping_addressDisplay = `${shipping_addressDisplay}, ${city}`
    } else {
      shipping_addressDisplay = `${city}`
    }
  }
  if (!isblank(state)) {
    if (!isblank(shipping_addressDisplay)) {
      shipping_addressDisplay = `${shipping_addressDisplay}, ${state}`
    } else {
      shipping_addressDisplay = `${state}`
    }
  }
  if (!isblank(country)) {
    if (!isblank(shipping_addressDisplay)) {
      shipping_addressDisplay = `${shipping_addressDisplay}, ${country}`
    } else {
      shipping_addressDisplay = `${country}`
    }
  }
  if (!isblank(pincode)) {
    if (!isblank(shipping_addressDisplay)) {
      shipping_addressDisplay = `${shipping_addressDisplay} - ${pincode}`
    } else {
      shipping_addressDisplay = `${pincode}`
    }
  }
  return shipping_addressDisplay
}

export const generateTextAreaAddress = ({ address, areacode, city, state, country, pincode }) => {
  var shipping_addressDisplay = ""
  if (!isblank(address)) {
    shipping_addressDisplay = `${address}`
  }
  if (!isblank(areacode)) {
    if (!isblank(shipping_addressDisplay)) {
      shipping_addressDisplay = `${shipping_addressDisplay}, ${areacode}`
    } else {
      shipping_addressDisplay = `${areacode}`
    }
  }
  if (!isblank(city)) {
    if (!isblank(shipping_addressDisplay)) {
      shipping_addressDisplay = `${shipping_addressDisplay}, ${city}`
    } else {
      shipping_addressDisplay = `${city}`
    }
  }
  if (!isblank(state)) {
    if (!isblank(shipping_addressDisplay)) {
      shipping_addressDisplay = `${shipping_addressDisplay}, ${state}`
    } else {
      shipping_addressDisplay = `${state}`
    }
  }
  if (!isblank(country)) {
    if (!isblank(shipping_addressDisplay)) {
      shipping_addressDisplay = `${shipping_addressDisplay}, ${country}`
    } else {
      shipping_addressDisplay = `${country}`
    }
  }
  if (!isblank(pincode)) {
    if (!isblank(shipping_addressDisplay)) {
      shipping_addressDisplay = `${shipping_addressDisplay} - ${pincode}`
    } else {
      shipping_addressDisplay = `${pincode}`
    }
  }
  return shipping_addressDisplay
}

export const generateDisplayAddress = ({ address, areacode, city, state, country, pincode }) => {
  var shipping_addressDisplay = ""
  if (!isblank(address)) {
    shipping_addressDisplay = `${replaceNewLinetoBR(address)}`
  }
  if (!isblank(areacode)) {
    if (!isblank(shipping_addressDisplay)) {
      shipping_addressDisplay = `${shipping_addressDisplay}, ${areacode}`
    } else {
      shipping_addressDisplay = `${areacode}`
    }
  }
  if (!isblank(city)) {
    if (!isblank(shipping_addressDisplay)) {
      shipping_addressDisplay = `${shipping_addressDisplay}, ${city}`
    } else {
      shipping_addressDisplay = `${city}`
    }
  }
  if (!isblank(state)) {
    if (!isblank(shipping_addressDisplay)) {
      shipping_addressDisplay = `${shipping_addressDisplay}, ${state}`
    } else {
      shipping_addressDisplay = `${state}`
    }
  }
  if (!isblank(country)) {
    if (!isblank(shipping_addressDisplay)) {
      shipping_addressDisplay = `${shipping_addressDisplay}, ${country}`
    } else {
      shipping_addressDisplay = `${country}`
    }
  }
  if (!isblank(pincode)) {
    if (!isblank(shipping_addressDisplay)) {
      shipping_addressDisplay = `${shipping_addressDisplay} - ${pincode}`
    } else {
      shipping_addressDisplay = `${pincode}`
    }
  }
  return shipping_addressDisplay
}

export const breakString = (str, length) => {
  if (isblank(str)) {
    return ""
  }
  str = replaceBRtoNewLine(str)
  if (str.length <= length) {
    return str
  }
  if (str.indexOf("\n") > -1) {
    let stringArray = str.split("\n")
    stringArray = stringArray.map((e) => breakString(e, length))
    return stringArray.join("\n")
  }
  if (!String.prototype.cordwood) {
    String.prototype.cordwood = function (cordlen) {
      if (cordlen === undefined || cordlen > this.length) {
        cordlen = this.length
      }
      var yardstick = new RegExp(`.{${cordlen}}`, "g")
      var pieces = this.match(yardstick)
      // if (isblank(pieces)) {
      // 	return [str]
      // }
      var accumulated = pieces.length * cordlen
      var modulo = this.length % accumulated
      if (modulo) pieces.push(this.slice(accumulated))
      return pieces
    }
  }
  var strArray = str.cordwood(length)
  if (!isblank(strArray) && strArray.length > 0) {
    if (strArray.length === 1) {
      return strArray[0]
    } else {
      return strArray.join("\n")
    }
  }
  return ""
}

export const getAdjustedCount = (invoices) => {
  if (isblank(invoices) || invoices.length === 0) {
    return 0
  }
  var adjusted = 0
  invoices.forEach((e) => {
    if (parseFloat(getNumberonly(e.Payment)) > 0) {
      adjusted = adjusted + 1
    }
  })
  return adjusted
}

export const getAdjustedCountV2 = (invoices) => {
  if (isblank(invoices) || invoices.length === 0) {
    return 0
  }
  var adjusted = 0
  invoices.forEach((e) => {
    if (parseFloat(getNumberonly(e.payment)) > 0) {
      adjusted = adjusted + 1
    }
  })
  return adjusted
}

export const setFiledDateToolTip = (item, type) => {
  var filedTooltip = ""
  if (type === "gstr1") {
    if (convertIntif(item.gstr1_filed) === 1) {
      if (!isblank(item.gstr1_filed_date)) {
        var fiedDate = item.gstr1_filed_date.split("-")
        delete fiedDate[2]
        filedTooltip = `Filed Period: ${fiedDate[1]}-${fiedDate[0]}`
      }
      if (!isblank(item.gstr1_filed_date_now)) {
        if (!isblank(filedTooltip)) {
          filedTooltip = `${filedTooltip}\nFiled On: ${DatetoDMY(item.gstr1_filed_date_now)}`
        } else {
          filedTooltip = `Filed On: ${DatetoDMY(item.gstr1_filed_date_now)}`
        }
      }

      if (convertIntif(item.amend_flag) === 1) {
        if (!isblank(item.amendOn)) {
          var amendOn = item.amendOn.split("-")
          if (!isblank(filedTooltip)) {
            filedTooltip = `${filedTooltip}\nAmend On: ${amendOn[1]}-${amendOn[0]}`
          } else {
            filedTooltip = `Amend On: ${amendOn[1]}-${amendOn[0]}`
          }
        }
        if (!isblank(item.amend_date)) {
          if (!isblank(filedTooltip)) {
            filedTooltip = `${filedTooltip}\nAmend Filed On: ${DatetoDMY(item.amend_date)}`
          } else {
            filedTooltip = `Amend Filed On: ${DatetoDMY(item.amend_date)}`
          }
        }
      }
    }
  } else if (type === "gstr3B" || type === "gstr3b") {
    if (convertIntif(item.gstr3B_filed) === 1) {
      if (!isblank(item.gstr3B_filed_date)) {
        var fiedDate = item.gstr3B_filed_date.split("-")
        delete fiedDate[2]
        filedTooltip = `Filed Period: ${fiedDate[1]}-${fiedDate[0]}`
      }
      if (!isblank(item.gstr3B_filed_date_now)) {
        if (!isblank(filedTooltip)) {
          filedTooltip = `${filedTooltip}\nFiled On: ${DatetoDMY(item.gstr3B_filed_date_now)}`
        } else {
          filedTooltip = `Filed On: ${DatetoDMY(item.gstr3B_filed_date_now)}`
        }
      }
      if (convertIntif(item.amend_flag) === 1) {
        if (!isblank(item.amendOn)) {
          var amendOn = item.amendOn.split("-")
          if (!isblank(filedTooltip)) {
            filedTooltip = `${filedTooltip}\nAmend On: ${amendOn[1]}-${amendOn[0]}`
          } else {
            filedTooltip = `Amend On: ${amendOn[1]}-${amendOn[0]}`
          }
        }
        if (!isblank(item.amend_date)) {
          if (!isblank(filedTooltip)) {
            filedTooltip = `${filedTooltip}\nAmend Filed On: ${DatetoDMY(item.amend_date)}`
          } else {
            filedTooltip = `Amend Filed On: ${DatetoDMY(item.amend_date)}`
          }
        }
      }
    }
  } else if (type === "gstr2") {
    if (convertIntif(item.gstr2_filed) === 1) {
      if (!isblank(item.gstr2)) {
        var fiedDate = item.gstr2.split("-")
        delete fiedDate[2]
        filedTooltip = `Filed Period: ${fiedDate[1]}-${fiedDate[0]}`
      }
      if (!isblank(item.gstr2)) {
        if (!isblank(filedTooltip)) {
          filedTooltip = `${filedTooltip}\nFiled On: ${DatetoDMY(item.gstr2)}`
        } else {
          filedTooltip = `Filed On: ${DatetoDMY(item.gstr2_filed_date_now)}`
        }
      }
      if (!isblank(item.gstr2_filed_date)) {
        var fiedDate = item.gstr2_filed_date.split("-")
        delete fiedDate[2]
        filedTooltip = `Filed Period: ${fiedDate[1]}-${fiedDate[0]}`
      }
      if (!isblank(item.gstr2_filed_date_now)) {
        if (!isblank(filedTooltip)) {
          filedTooltip = `${filedTooltip}\nFiled On: ${DatetoDMY(item.gstr2_filed_date_now)}`
        } else {
          filedTooltip = `Filed On: ${DatetoDMY(item.gstr2_filed_date_now)}`
        }
      }
    }
  }
  return filedTooltip
}

export const getUniquePhoneCode = (allCountry) => {
  var uniquePhonecode = []
  allCountry.forEach((e) => {
    if (uniquePhonecode.filter((i) => i.phone_code === e.phone_code).length === 0) {
      uniquePhonecode.push({
        phone_code: e.phone_code,
        name: e.name,
      })
    } else {
      uniquePhonecode = uniquePhonecode.map((i) => {
        const updated = { ...i }
        if (i.phone_code === e.phone_code) {
          updated.name = `${updated.name}, ${e.name}`
        }
        return updated
      })
    }
  })
  return uniquePhonecode
}

export const getFileExtension = (fileName) => {
  if (!isblank(fileName)) {
    var fileNameArray = fileName.split(".")
    return `.${fileNameArray[fileNameArray.length - 1]}`
  }
  return ""
}

export const getFileNameFromURL = (fileName) => {
  if (!isblank(fileName)) {
    var fileNameArray = fileName.split("/")
    return `${fileNameArray[fileNameArray.length - 1]}`
  }
  return ""
}

export const generateRandomNumber = () => {
  const min = 1000000
  const max = 9999999
  var returnValue = min + Math.random() * (max - min)
  return parseInt(returnValue)
}

export const currencyCombo = () => {
  return [
    {
      id: "en-IN",
      name: "INR",
    },
    {
      id: "en-US",
      name: "USD",
    },
  ]
}

export const currencyFormat = (value, currencyId) => {
  if (isNaN(value)) {
    return ""
  }
  const currency_id = currencyId ? currencyId : "en-IN"
  const currency = currencyId ? getdropdownfieldvalue({ dropDownData: currencyCombo(), field: "id", value: currencyId, displayvalue: "name" }) : "INR"
  return new Intl.NumberFormat(currency_id, {
    style: "currency",
    currency: currency,
  }).format(value)
}

export const common_getTypeWiseKeySetting = ({ key, type, subtype, getAccountSitesetting }) => {
  var subTypeDetail = common_getSubTypeWiseSetting({ type, subtype, getAccountSitesetting })
  if (isblank(subTypeDetail)) {
    return ""
  }
  return subTypeDetail[key] ? convertIntif(subTypeDetail[key]) : ""
}

export const common_getSubTypeWiseSetting = ({ type, subtype, getAccountSitesetting }) => {
  if (isblank(type) || isblank(subtype)) {
    return {}
  }
  var typeDetail = getAccountSitesetting(type)
  if (isblank(typeDetail) || typeDetail.length === 0) {
    return {}
  }
  var subTypeDetail = typeDetail.find((e) => e.id === subtype)
  if (isblank(subTypeDetail)) {
    return {}
  }
  return subTypeDetail
}

export const getgstbusinesstypelist = () => {
  return [
    {
      id: "1",
      name: "Registered Business - Regular",
    },
    {
      id: "2",
      name: "Registered Business - Composition",
    },
    {
      id: "3",
      name: "Unregistered Business",
    },
    {
      id: "4",
      name: "Consumer",
    },
    {
      id: "5",
      name: "Overseas",
    },
    {
      id: "6",
      name: "Special Economic Zone",
    },
  ]
}

export const lettersNumbersCheck = (name) => {
  var regEx = /^[0-9a-zA-Z]+$/
  if (name.match(regEx)) {
    return true
  } else {
    return false
  }
}

export const getStateName = ({ country, state, dd_country_state }) => {
  if (isblank(country) || isblank(state)) {
    return ""
  }
  var countryCode = getDropDownPostValue(country)
  var stateCode = getDropDownPostValue(state)
  if (dd_country_state.filter((e) => convertIntif(e.id) === convertIntif(countryCode)).length > 0) {
    var stateArray = dd_country_state.find((e) => convertIntif(e.id) === convertIntif(countryCode)).states
    return getdropdownfieldvalue({ dropDownData: stateArray, field: "code", value: stateCode, displayvalue: "name" })
  }
}

export const getDueStatus = (updateditem) => {
  updateditem.due_on_and_status = ""
  updateditem.due_status = ""
  var due_date = new Date(DatetoYMD(updateditem.due_date))
  var todaydate = new Date()
  var dueDays = daysBetweenDates(todaydate, due_date)
  if (updateditem.adjust_status !== "Paid") {
    if (updateditem.is_overdue_status === 1) {
      updateditem.due_on_and_status = "Overdue"
      updateditem.due_status = "2"
      if (dueDays < -30) {
        updateditem.due_toolTip = `Overdue since more than 30days\nOn ${DatetoDMY(updateditem.due_date)}`
      } else {
        if (dueDays === 0) {
          updateditem.due_toolTip = `Overdue today\nOn ${DatetoDMY(updateditem.due_date)}`
        } else if (dueDays === -1) {
          updateditem.due_toolTip = `Overdue yesterday\nOn ${DatetoDMY(updateditem.due_date)}`
        } else {
          updateditem.due_toolTip = `Overdue since ${changeSign(dueDays)} days ago\nOn ${DatetoDMY(updateditem.due_date)}`
        }
      }
    } else {
      if (dueDays <= 30) {
        if (dueDays === 0) {
          updateditem.due_on_and_status = `Today`
        } else if (dueDays === 1) {
          updateditem.due_on_and_status = `Tomorrow`
        } else {
          updateditem.due_on_and_status = `Due in ${dueDays} days`
        }
        updateditem.due_toolTip = `Due On ${DatetoDMY(updateditem.due_date)}`
        updateditem.due_status = "1"
      } else {
        updateditem.due_on_and_status = DatetoDMY(updateditem.due_date)
        updateditem.due_status = "0"
      }
    }
  }
  return updateditem
}

export const getCustomerSupplierType = () => {
  return [
    {
      id: "2",
      name: "Receivable",
    },
    {
      id: "1",
      name: "Payable",
    },
  ]
}

export const getcostcenterdropdown = (dropDownCostCenter) => {
  const list_to_tree = (list) => {
    var map = {},
      node,
      roots = [],
      i
    for (i = 0; i < list.length; i += 1) {
      map[list[i].code] = i // initialize the map
      list[i].children = [] // initialize the children
    }
    for (i = 0; i < list.length; i += 1) {
      node = list[i]
      if (node.parent !== "0" && !isblank(node.parent)) {
        // if you have dangling branches check that map[node.parentId] exists
        list[map[node.parent]].children.push(node)
      } else {
        roots.push(node)
      }
    }
    return roots
  }

  const checkChildren = (parentObj, i) => {
    if (parentObj.hasOwnProperty("code")) {
      var obj = {}
      obj.code = parentObj.code
      obj.name = parentObj.name
      obj.depth = i
      outputStr.push(obj)

      if (parentObj.children.length > 0) {
        i = i + 1
        parentObj.children.forEach(function (value, index) {
          checkChildren(value, i)
        })
      }
    }
  }

  var tree = list_to_tree(dropDownCostCenter)
  outputStr = []
  if (tree.length > 0) {
    for (var i = 0; i < tree.length; i++) {
      checkChildren(tree[i], 0)
    }
  }

  return outputStr
}

export const capitalize = (input) => {
  var words = input.split(" ")
  var CapitalizedWords = []
  words.forEach((element) => {
    CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length))
  })
  return CapitalizedWords.join(" ")
}

//removing "_" and convert to first letter capital of each word

export const capitalizeFormat = (string) => {
  if (isblank(string)) {
    return ""
  }
  var returnValue = replaceAllFn(string, "_", " ")
  return capitalize(returnValue)
}

export const getopbal = () => {
  return [
    {
      id: "1",
      name: "ALL",
    },
    {
      id: "2",
      name: "REMOVE ZERO BALANACE",
    },
    {
      id: "3",
      name: "All Debit",
    },
    {
      id: "4",
      name: "All Credit",
    },
  ]
}

export const getopstock = () => {
  return [
    {
      id: "1",
      name: "ALL",
    },
    {
      id: "2",
      name: "REMOVE ZERO STOCK",
    },
    {
      id: "3",
      name: "MANAGE INVENTORY",
    },
  ]
}

export const setFormatOfInOutDetailGrid = (DetailGrid, getAccountSitesetting) => {
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
  const advance_tracking = parseInt(getAccountSitesetting("advance_tracking"))
  return DetailGrid.map((e) => {
    const updated = { ...e }
    if (!isblank(updated.itemQty)) {
      updated.itemQty = amount_formatDecimal(updated.itemQty, inventory_decimalpoint)
    }
    if (advance_tracking === 1) {
      if (!isblank(updated.itemBatches) && typeof updated.itemBatches === "string") {
        updated.itemBatches = JSON.parse(decodeURIComponent(updated.itemBatches))
      }
    }
    return updated
  })
}

export const setFormatOfInvoiceDetailGrid = ({ DetailGrid, dditem, getAccountSitesetting }) => {
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
  const rate_decimalpoint = getAccountSitesetting("rate_decimalpoint")
  const tcs = parseInt(getAccountSitesetting("tcs"))
  const advance_tracking = parseInt(getAccountSitesetting("advance_tracking"))
  return DetailGrid.map((e) => {
    const updated = { ...e }
    if (!isblank(updated.itemQty)) {
      updated.itemQty = amount_formatDecimal(updated.itemQty, inventory_decimalpoint)
    }
    if (!isblank(updated.itemRateIncTax)) {
      updated.itemRateIncTax = amountFormatOfRateV1(updated.itemRateIncTax, rate_decimalpoint)
    }
    if (!isblank(updated.itemRate)) {
      updated.itemRate = amountFormatOfRateV1(updated.itemRate, rate_decimalpoint)
    }
    if (!isblank(updated.calcAmount)) {
      updated.calcAmount = amount_format(updated.calcAmount)
    }
    if (!isblank(updated.itemDiscount)) {
      updated.itemDiscount = amount_format(updated.itemDiscount)
    }
    if (!isblank(updated.itemNetAmount)) {
      updated.itemNetAmount = amount_format(updated.itemNetAmount)
    }
    if (tcs === 1) {
      if (dditem) {
        var itemValue = dditem.find((e) => convertIntif(e.code) === convertIntif(updated.itemSelectedValue))
        if (itemValue && !isblank(itemValue.tcs_code) && parseFloat(getNumberonlyDecimal(itemValue.tcs_code, 3)) > 0) {
          updated.tcs = itemValue.tcs
          updated.tcs_code = itemValue.tcs_code
          updated.tcs_detail = ""
        } else {
          updated.tcs = ""
          updated.tcs_code = ""
          updated.tcs_detail = ""
        }
      }
    }
    if (advance_tracking === 1) {
      if (!isblank(updated.itemBatches) && typeof updated.itemBatches === "string") {
        updated.itemBatches = JSON.parse(decodeURIComponent(updated.itemBatches))
      }
    }
    return updated
  })
}

export const getDeviceTypes = () => {
  return [
    {
      id: "0",
      name: "Web",
    },
    {
      id: "1",
      name: "Mobile(Android)",
    },
    {
      id: "2",
      name: "Mobile(iOS)",
    },
    {
      id: "3",
      name: "Mobile Web",
    },
  ]
}

export const getCatchErrorMessage = (error) => {
  if (!isblank(error) && error.message === "Network Error") {
    return "Check your Internet connection and try again"
  }
  return "An error occurred, please try again later"
}

export const customFieldDefaultValue = ({ CustomField, formdatainitials, detailKey, prefix = "" }) => {
  var MasterCustomField = CustomField.filter((e) => e.masterDetailLevel === "0" && e.isActive === "1")
  var DetailCustomField = CustomField.filter((e) => e.masterDetailLevel === "1" && e.isActive === "1")
  MasterCustomField.forEach((e) => {
    //FIX FOR DATE CURRENT VALUE
    if (parseInt(e.dataType) === cF.Date) {
      if (e.defaultValue === "-1") {
        e.defaultValue = todaydate("YMD")
      }
    }

    //FIX FOR DROPDOWN SINGLE->MULTIPLE & MULTIPLE->SINGLE
    if (parseInt(e.dataType) === cF.Dropdown) {
      if (!isblank(e.defaultValue)) {
        if (!Array.isArray(e.defaultValue)) {
          e.defaultValue = JSON.parse(e.defaultValue)
        }
        if (e.dropDownMulti === "1") {
          if (!isblank(e.defaultValue) && !Array.isArray(e.defaultValue)) {
            e.defaultValue = [e.defaultValue]
          }
        } else {
          if (Array.isArray(e.defaultValue)) {
            e.defaultValue = e.defaultValue[0]
          }
        }
      }
    }

    if (!isblank(e.defaultValue)) {
      if (parseInt(e.dataType) === cF.Date || parseInt(e.dataType) === cF.Dropdown || parseInt(e.dataType) === cF.YesNo || parseInt(e.dataType) === cF.Decimal || parseInt(e.dataType) === cF.Text) {
        formdatainitials[`${prefix}${e.name}`] = e.defaultValue
      } else if (parseInt(e.dataType) === cF.Checkbox) {
        const isTrue = e.defaultValue === "1" || e.defaultValue === 1 || e.defaultValue === "true" || e.defaultValue === true
        formdatainitials[`${prefix}${e.name}`] = isTrue
      } else {
        formdatainitials[`${prefix}${e.name}`] = JSON.parse(e.defaultValue)
      }
    } else {
      // Make defaultValue blank if it not set
      formdatainitials[`${prefix}${e.name}`] = ""
    }
  })
  if (detailKey) {
    DetailCustomField.forEach((e) => {
      if (formdatainitials[detailKey] && formdatainitials[detailKey].length > 0) {
        formdatainitials[detailKey] = formdatainitials[detailKey].map((i) => {
          const updated = { ...i }
          if (!isblank(e.defaultValue)) {
            //FIX FOR DATE CURRENT VALUE
            if (parseInt(e.dataType) === cF.Date) {
              if (e.defaultValue === "-1") {
                e.defaultValue = todaydate("YMD")
              }
            }

            //FIX FOR DROPDOWN SINGLE->MULTIPLE & MULTIPLE->SINGLE
            if (parseInt(e.dataType) === cF.Dropdown) {
              if (!isblank(e.defaultValue)) {
                if (!Array.isArray(e.defaultValue)) {
                  e.defaultValue = JSON.parse(e.defaultValue)
                }
                if (e.dropDownMulti === "1") {
                  if (!isblank(e.defaultValue) && !Array.isArray(e.defaultValue)) {
                    e.defaultValue = [e.defaultValue]
                  }
                } else {
                  if (Array.isArray(e.defaultValue)) {
                    e.defaultValue = e.defaultValue[0]
                  }
                }
              }
            }

            if (
              parseInt(e.dataType) === cF.Date ||
              parseInt(e.dataType) === cF.Dropdown ||
              parseInt(e.dataType) === cF.YesNo ||
              parseInt(e.dataType) === cF.Decimal ||
              parseInt(e.dataType) === cF.Text
            ) {
              updated[e.name] = e.defaultValue
            } else if (parseInt(e.dataType) === cF.Checkbox) {
              const isTrue = e.defaultValue === "1" || e.defaultValue === 1 || e.defaultValue === "true" || e.defaultValue === true
              updated[e.name] = isTrue
            } else {
              updated[e.name] = JSON.parse(e.defaultValue)
            }
          } else {
            // Make defaultValue blank if it not set
            updated[e.name] = ""
          }
          return updated
        })
      }
    })
  }
  return formdatainitials
}

export const customFieldDefaultValueRow = ({ CustomField, row }) => {
  var DetailCustomField = CustomField.filter((e) => e.masterDetailLevel === "1" && e.isActive === "1")
  let updated = { ...row }

  DetailCustomField.forEach((e) => {
    if (updated) {
      if (!isblank(e.defaultValue)) {
        //FIX FOR DATE CURRENT VALUE
        if (parseInt(e.dataType) === cF.Date) {
          if (e.defaultValue === "-1") {
            e.defaultValue = todaydate("YMD")
          }
        }

        //FIX FOR DROPDOWN SINGLE->MULTIPLE & MULTIPLE->SINGLE
        if (parseInt(e.dataType) === cF.Dropdown) {
          if (!isblank(e.defaultValue)) {
            if (!Array.isArray(e.defaultValue)) {
              e.defaultValue = JSON.parse(e.defaultValue)
            }
            if (e.dropDownMulti === "1") {
              if (!isblank(e.defaultValue) && !Array.isArray(e.defaultValue)) {
                e.defaultValue = [e.defaultValue]
              }
            } else {
              if (Array.isArray(e.defaultValue)) {
                e.defaultValue = e.defaultValue[0]
              }
            }
          }
        }

        if (parseInt(e.dataType) === cF.Date || parseInt(e.dataType) === cF.Dropdown || parseInt(e.dataType) === cF.YesNo || parseInt(e.dataType) === cF.Decimal || parseInt(e.dataType) === cF.Text) {
          updated[e.name] = e.defaultValue
        } else if (parseInt(e.dataType) === cF.Checkbox) {
          const isTrue = e.defaultValue === "1" || e.defaultValue === 1 || e.defaultValue === "true" || e.defaultValue === true
          updated[e.name] = isTrue
        } else {
          updated[e.name] = JSON.parse(e.defaultValue)
        }
      }
    }
  })

  return updated
}

export const dayRangeAge = () => {
  return [
    {
      id: "1",
      name: "30-60-90-90+",
    },
    {
      id: "2",
      name: "90-120-180-180+",
    },
    {
      id: "3",
      name: "10-20-30-30+",
    },
  ]
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const gettype = () => {
  return [
    {
      id: "detail",
      name: "Detail",
    },
    {
      id: "summary",
      name: "Summary",
    },
  ]
}

export const processInOut = () => {
  return [
    {
      id: "In",
      name: "Goods given for Process",
    },
    {
      id: "Out",
      name: "Goods received from Process",
    },
  ]
}

export const getreportformat = () => {
  return [
    {
      id: "1",
      name: "Vertical Format",
    },
    {
      id: "2",
      name: "T Format",
    },
  ]
}

export const getinteresttype = () => {
  return [
    {
      id: "10",
      name: "Simple",
    },
    {
      id: "12",
      name: "Compound Monthly",
    },
    {
      id: "4",
      name: "Compound Quarterly",
    },
    {
      id: "2",
      name: "Compound Half Yearly",
    },
    {
      id: "1",
      name: "Compound Yearly",
    },
  ]
}

export const getinterestdays = () => {
  return [
    {
      id: "0",
      name: "365",
    },
    {
      id: "1",
      name: "360",
    },
    {
      id: "2",
      name: "364",
    },
  ]
}

export const getagenttype = () => {
  return [
    {
      id: "0",
      name: "Total Sales",
    },
    {
      id: "1",
      name: "Partywise Sales",
    },
  ]
}

export const getagentdoctype = () => {
  return [
    {
      id: "",
      name: "ALL",
    },
    {
      id: "S",
      name: "Sales",
    },
    {
      id: "PU",
      name: "Purchase",
    },
    {
      id: "SR",
      name: "Credit Note",
    },
    {
      id: "PR",
      name: "Debit Note",
    },
  ]
}

export const getitemwisetype = () => {
  return [
    {
      id: "1",
      name: "Item Wise",
    },
    {
      id: "2",
      name: "Item Group Wise",
    },
    {
      id: "3",
      name: "Item Group Item Wise",
    },
  ]
}

export const getTCSCalcType = () => {
  return [
    {
      id: "1",
      name: "Over All",
    },
    {
      id: "2",
      name: "Item Wise",
    },
  ]
}

export const getmultiledgertype = () => {
  return [
    {
      id: "0",
      name: "Detail",
    },
    {
      id: "1",
      name: "Summary",
    },
  ]
}

export const getmultiledgerwisetype = () => {
  return [
    {
      id: "0",
      name: "All",
    },
    {
      id: "1",
      name: "Group",
    },
    {
      id: "4",
      name: "Agent",
    },
    {
      id: "2",
      name: "City",
    },
    {
      id: "3",
      name: "State",
    },
    {
      id: "5",
      name: "Customer Group",
    },
  ]
}

export const getallViewType = () => {
  return [
    {
      id: "0",
      name: "Auto Adjust",
    },
    {
      id: "1",
      name: "Bill By Bill",
    },
  ]
}

export const getDaysCalcOption = () => {
  return [
    {
      id: "0",
      name: "Due date",
    },
    {
      id: "1",
      name: "Invoice date",
    },
  ]
}

export const getDaysCalcOptionInt = () => {
  return [
    {
      id: "0",
      name: "Due date",
    },
    {
      id: "1",
      name: "Invoice date",
    },
    {
      id: "2",
      name: "Cr days",
    },
  ]
}

export const selectDueDays = () => {
  return [
    {
      id: "",
      name: "Select",
    },
    {
      id: "7",
      name: "7",
    },
    {
      id: "14",
      name: "14",
    },
    {
      id: "21",
      name: "21",
    },
    {
      id: "30",
      name: "30",
    },
    {
      id: "100",
      name: "30+",
    },
  ]
}

export const tdsAllCalcType = () => {
  return [
    {
      id: "0",
      name: "All",
    },
    {
      id: "1",
      name: "Payable",
    },
    {
      id: "2",
      name: "Receivable",
    },
  ]
}

export const tds_tcs_missing_Type = () => {
  return [
    {
      id: "0",
      name: "Bill",
    },
    {
      id: "1",
      name: "Receipt/Payment",
    },
  ]
}

export const replacePreFix = (prefixStr, getAccountSessionUserData) => {
  if (!isblank(prefixStr)) {
    let returnValue = prefixStr
    const minYear = parseInt(getAccountSessionUserData("financial_year"), 10)
    const maxYear = minYear + 1
    const shortMinYear = minYear.toString().substring(2)
    const shortMaxYear = maxYear.toString().substring(2)

    if (returnValue.indexOf("{fy}") > -1) {
      returnValue = replaceAllFn(returnValue, "{fy}", shortMinYear)
    }
    if (returnValue.indexOf("{ey}") > -1) {
      returnValue = replaceAllFn(returnValue, "{ey}", shortMaxYear)
    }
    if (returnValue.indexOf("{FY}") > -1) {
      returnValue = replaceAllFn(returnValue, "{FY}", minYear)
    }
    if (returnValue.indexOf("{EY}") > -1) {
      returnValue = replaceAllFn(returnValue, "{EY}", maxYear)
    }
    return returnValue
  }
  return ""
}

export const format_customField = (value, column) => {
  if (isblank(value)) {
    return ""
  }
  if (convertIntif(column.dataType) === 2 && !isblank(column.decimalPoint)) {
    return amount_formatDecimal(value, convertIntif(column.decimalPoint))
  } else if (convertIntif(column.dataType) === 14 && !isblank(column.currencyId)) {
    return currencyFormat(value, column.currencyId)
  } else if (convertIntif(column.dataType) === 3) {
    return DatetoDMY(value)
  }
  return value
}

export const getCustomNumeric = (dataType) => {
  if (checkCondition(dataType, [2, 14])) {
    return true
  }
  return false
}

export const getratetype = () => {
  return [
    {
      id: "1",
      name: "Sales Rate",
      description: "Product master sales rate.",
    },
    {
      id: "2",
      name: "Purchase Rate",
      description: "Product master purchase rate.",
    },
    {
      id: "3",
      name: "Average Sales Rate",
      description: "Sales invoice average rates",
    },
    {
      id: "4",
      name: "Average Purchase Rate",
      description: "Purchase invoice average rates",
    },
    {
      id: "5",
      name: "Average Rate",
      description: "Purchase and Sales invoice running average rates",
    },
    {
      id: "6",
      name: "Last Sales Rate",
      description: "Last Sales invoice rate",
    },
    {
      id: "7",
      name: "Last Purchase Rate",
      description: "Last Purchase invoice rate",
    },
  ]
}

export const getreporttype = () => {
  return [
    {
      id: "0",
      name: "Inward",
    },
    {
      id: "1",
      name: "Outward",
    },
  ]
}

export const uppercaseArray = (data, keys) => {
  return data.map((e) => {
    const updated = { ...e }
    keys.forEach((i) => {
      updated[i] = uppercaseString(updated[i])
    })
    return updated
  })
}

export const uppercaseString = (val) => {
  if (!isblank(val) && typeof val === "string") {
    return val.toUpperCase()
  }
  return val
}

export const getCapitalize = (val) => {
  return val.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
}

export const capitalizeArray = (data, keys) => {
  return data.map((e) => {
    const updated = { ...e }
    keys.forEach((i) => {
      updated[i] = capitalizeString(updated[i])
    })
    return updated
  })
}

export const capitalizeString = (val) => {
  if (!isblank(val) && typeof val === "string") {
    return getCapitalize(val)
  }
  return val
}

export const addDaysInDate = (dateStr, days) => {
  if (!isblank(dateStr) && parseFloat(getNumberonly(days)) > 0) {
    var fb_date = new Date(dateStr)
    fb_date = new Date(fb_date.getTime() + fb_date.getTimezoneOffset() * 60000)
    var new_Date = new Date()
    new_Date.setTime(fb_date.getTime() + parseInt(getNumberonly(days), 10) * 24 * 60 * 60 * 1000)
    return DatetoStr(new_Date)
  }
  return dateStr
}

export const numForCalc = (num, decimal) => {
  if (!isblank(decimal)) {
    return parseFloat(getNumberonlyDecimal(num, decimal))
  } else {
    return parseFloat(getNumberonly(num))
  }
}

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toUpperCase() : word.toLowerCase()
  })
}

export const setDataCustomFieldWise = (dataArray, custom_fields) => {
  let custom_fieldsDropDown = custom_fields.filter((e) => checkCondition(e.dataType, [5, 6]))
  return dataArray.map((e) => {
    const updated = { ...e }
    custom_fieldsDropDown.forEach((i) => {
      updated[i.name] = getdropdownfieldvalue({ dropDownData: i.dropDownOptions, field: "code", value: e[i.name], displayvalue: "value" })
    })
    return updated
  })
}

export const replaceDropBoxUrl = (link) => {
  return link.replace("www.dropbox.com", "dl.dropboxusercontent.com")
}

export const isImage = (url) => {
  if (typeof url !== "string") return false
  return url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) != null
}

export const toFixedFn = (val, decimal) => {
  decimal = convertIntif(decimal)
  if (isblank(decimal)) {
    decimal = 2
  }
  var round = +(Math.round(+(val.toString() + "e" + decimal)).toString() + "e" + -decimal)
  round = round.toFixed(decimal)
  return round
}

export const customFieldArrayToObj = (custom_fields) => {
  let returnValue = {}
  if (!isblank(custom_fields)) {
    let customFields = JSON.parse(custom_fields)
    if (customFields && customFields.length > 0) {
      customFields.forEach((e) => {
        Object.keys(e).forEach((i) => {
          returnValue[i] = e[i]
        })
      })
    }
  }

  return returnValue
}

export const customFieldObjToObj = (custom_fields) => {
  let returnValue = {}
  if (!isblank(custom_fields)) {
    Object.keys(custom_fields).forEach((i) => {
      if (i.includes("__cust")) {
        returnValue[i] = custom_fields[i]
      }
    })
  }

  return returnValue
}

export const labelPrintOption = () => {
  return [
    {
      id: "1",
      name: "As per row",
    },
    {
      id: "2",
      name: "As per pcs",
    },
    {
      id: "0",
      name: "As per qty",
    },
    {
      id: "3",
      name: "As per box",
    },
  ]
}
export const parseDropdownValues = (val, multi) => {
  if (isblank(multi) || multi === "0") {
    return val
  }

  if (Array.isArray(val)) {
    return val
  }

  if (!isblank(val)) {
    const arr = val.split(",")
    if (arr.length > 0) {
      return arr
    } else {
      return [val]
    }
  } else {
    return []
  }
}

export const checkRenderCondition = (f, data) => {
  if (isblank(data)) {
    return false
  }
  if (!isblank(f.renderCondition)) {
    //CHANGE FOR SUPPORTING OR CONDITION
    const arr = f.renderCondition.split("OR")
    const arr_result = arr.map((e1, index) => {
      let accepted = 0
      let renderCondition = JSON.parse(e1.trim())
      Object.keys(renderCondition).forEach((e) => {
        if (data[e] === renderCondition[e]) {
          accepted = accepted + 1
        }
      })
      return accepted === Object.keys(renderCondition).length
    })
    const result = arr_result.filter((e) => e === true)
    return result.length > 0
  }
  return true
}

export const checkRenderConditionPrint = (f, data) => {
  if (isblank(data)) {
    return false
  }
  if (!isblank(f.renderCondition)) {
    //CHANGE FOR SUPPORTING OR CONDITION
    const arr = f.renderCondition.split("OR")
    const arr_result = arr.map((e1, index) => {
      let accepted = 0
      let renderCondition = JSON.parse(e1.trim())
      Object.keys(renderCondition).forEach((e) => {
        if (!isblank(data[`${e}_code`])) {
          if (data[`${e}_code`] === renderCondition[e]) {
            accepted = accepted + 1
          }
        } else {
          if (data[e] === renderCondition[e]) {
            accepted = accepted + 1
          }
        }
      })
      return accepted === Object.keys(renderCondition).length
    })
    const result = arr_result.filter((e) => e === true)
    return result.length > 0
  }
  return true
}

export const checkRenderConditionV1 = ({ f, data, other_type, rowValue, dS, print_flag }) => {
  if (!isblank(f.renderCondition)) {
    if (print_flag === true) {
      if (f.showInPrint !== "1") {
        return false
      }
    }
    //CHANGE FOR SUPPORTING OR CONDITION
    const arr = f.renderCondition.split("OR")
    const arr_result = arr.map((e1, index) => {
      let accepted = 0
      let renderCondition = JSON.parse(e1.trim())
      Object.keys(renderCondition).forEach((e) => {
        if (data[e] === renderCondition[e] || data[`${e}_code`] === renderCondition[e]) {
          accepted = accepted + 1
        }
        if (!isblank(rowValue) && (rowValue[e] === renderCondition[e] || rowValue[`${e}_code`] === renderCondition[e])) {
          accepted = accepted + 1
        }
      })
      return accepted === Object.keys(renderCondition).length
    })
    const result = arr_result.filter((e) => e === true)
    return result.length > 0
  }

  // custom render field

  if (
    dS &&
    dS.Business_Type === "Wood" &&
    !isblank(other_type) &&
    (other_type === "job_inward" ||
      other_type === "finish_stock" ||
      other_type === "delivery_challan" ||
      other_type === "lot_transfer" ||
      other_type === "manage_lot_master" ||
      other_type === "detail")
  ) {
    if (checkCondition(f.name, ["__cust_actual_height", "__cust_actual_width", "__cust_width", "__cust_height", "__cust_door_thikness"], "or")) {
      const cm = rowValue["__cust_ply_height"]
      if (!isblank(cm) && parseFloat(cm) > 0 && data.__cust_product_type === "FRAME") {
        return false
      }
    }
    if (checkCondition(f.name, ["__cust_ply_thk", "__cust_ply_width", "__cust_ply_height"], "or")) {
      const cm = rowValue["__cust_actual_height"]
      if (!isblank(cm) && parseFloat(cm) > 0 && data.__cust_product_type === "DOOR") {
        return false
      }
    }
  }
  return true
}

export const checkRenderConditionPrintV1 = ({ f, data, other_type, rowValue, dS }) => {
  if (isblank(data)) {
    return false
  }
  if (!isblank(f.renderCondition)) {
    //CHANGE FOR SUPPORTING OR CONDITION
    const arr = f.renderCondition.split("OR")
    const arr_result = arr.map((e1, index) => {
      let accepted = 0
      let renderCondition = JSON.parse(e1.trim())
      Object.keys(renderCondition).forEach((e) => {
        if (!isblank(data[`${e}_code`])) {
          if (data[`${e}_code`] === renderCondition[e]) {
            accepted = accepted + 1
          }
        } else {
          if (data[e] === renderCondition[e]) {
            accepted = accepted + 1
          }
        }
      })
      return accepted === Object.keys(renderCondition).length
    })
    const result = arr_result.filter((e) => e === true)
    return result.length > 0
  }
  return true
}

export const generateRandomFieldId = () => {
  var returnValue = new Date()
  returnValue = `${returnValue.getTime()}_${generateRandomNumber()}`
  return returnValue
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const getlastsevendays = () => {
  var result = []
  result.push({ id: "current", name: "current" })
  for (var i = 0; i < 7; i++) {
    var d = new Date()
    d.setDate(d.getDate() - i)
    result.push({ id: DatetoStrMDY(d), name: DatetoStrMDY(d) })
  }
  return result
}

export const DatetoStrMDY = (YMD) => {
  if (YMD === "" || YMD === "0" || YMD === undefined || YMD === null) {
    return ""
  }
  var yyyy = YMD.getFullYear().toString()
  var mm = (YMD.getMonth() + 1).toString() // getMonth() is zero-based
  var dd = YMD.getDate().toString()
  mm = mm[1] ? mm : "0" + mm[0]
  dd = dd[1] ? dd : "0" + dd[0]
  var str = dd + "-" + mm + "-" + yyyy
  return str
}

export const getpaginationsize = () => {
  return [
    {
      name: 10,
    },
    {
      name: 20,
    },
  ]
}

export const exportAs = ({ data, name, type }) => {
  var binary = atob(data.replace(/\s/g, ""))
  var len = binary.length
  var buffer = new ArrayBuffer(len)
  var view = new Uint8Array(buffer)
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i)
  }
  var blob = ""
  // create the blob object with content-type "application/pdf"
  if (type === "xlsx") {
    blob = new Blob([view], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" })
  } else if (type === "pdf") {
    blob = new Blob([view], { type: "application/pdf" })
  } else if (type === "json") {
    blob = new Blob([view], { type: "application/json" })
  } else if (type === "csv") {
    blob = new Blob([view], { type: "application/csv" })
  }

  var url = URL.createObjectURL(blob)
  var a = window.document.createElement("a")
  a.href = url
  a.target = "_blank"

  if (type === "xlsx") {
    a.download = name + ".xlsx"
  } else if (type === "pdf") {
    if (name.includes(".pdf")) {
      a.download = name
    } else {
      a.download = name + ".pdf"
    }
  } else if (type === "json") {
    a.download = name + ".json"
  } else if (type === "csv") {
    a.download = name + ".csv"
  }
  document.body.appendChild(a)
  a.click() // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
  document.body.removeChild(a)
}

export const indiaCurrencyShort = (value) => {
  var formattedValue = ""
  if (value >= 10000000) {
    formattedValue =
      (value / 10000000).toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + " Cr"
  } else if (value >= 100000) {
    formattedValue =
      (value / 100000).toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + " L"
  } else {
    formattedValue =
      (value / 1000).toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + " T"
  }
  return formattedValue
}

export const getPageSize = (size) => {
  if (size === "1") {
    return "A5"
  } else if (size === "2") {
    return "A6"
  } else if (size === "3") {
    return "Auto"
  } else {
    return "A4"
  }
}

export const dataSetInListSalesInvoice = (result) => {
  return result.map((item, index) => {
    var updateditem = { ...item }
    if (updateditem.invoice_type === "sales_order") {
      updateditem.status = item.status === "0" ? "Pending" : item.status === "1" ? "Partially" : "Closed"
    } else if (updateditem.invoice_type === "estimate") {
      updateditem.status = getdropdownfieldvalue({ dropDownData: getAllEstimateStatus(), field: "id", value: item.status, displayvalue: "name" })
    } else if (updateditem.invoice_type === "getmystore" || updateditem.invoice_type === "getmycatalogue") {
      updateditem.status = getdropdownfieldvalue({ dropDownData: getAllOrderStatus(), field: "id", value: item.status, displayvalue: "name" })
    }
    if (updateditem.invoice_type === "getmystore" && updateditem.name === "Guest Account") {
      updateditem.name = `${updateditem.name} - ${updateditem.guest_name} - ${updateditem.guest_mobile}`
    }
    updateditem.adjust_status = item.adjust_status === "0" ? "Unpaid" : item.adjust_status === "1" ? "Partially Paid" : item.adjust_status === "2" ? "Paid" : "Unpaid"
    updateditem.e_invoice_status = getdropdownfieldvalue({ dropDownData: getewaybillstatus(), field: "id", value: updateditem.e_invoice_status, displayvalue: "name" })
    if (updateditem.e_invoice_status === "Generated") {
      updateditem.generateEwayBill = true
    }
    updateditem.gstr1_filed_date = setFiledDateToolTip(item, "gstr1")
    updateditem.gstr3B_filed_date = setFiledDateToolTip(item, "gstr3b")
    updateditem.gstr1_filed = item.gstr1_filed === "1" ? "Filed" : "Not Filed"
    updateditem.gstr3B_filed = item.gstr3B_filed === "1" ? "Filed" : "Not Filed"
    updateditem.ewaybill_status = getdropdownfieldvalue({ dropDownData: getewaybillstatus(), field: "id", value: updateditem.ewaybill_status, displayvalue: "name" })
    updateditem = getDueStatus(updateditem)
    return updateditem
  })
}

export const dataSetInListSPReturn = (result) => {
  return result.map((item, index) => {
    const updateditem = { ...item }
    updateditem.invoice_type = getdropdownfieldvalue({ dropDownData: getnotetypeWithVoucher(), field: "id", value: item.invoice_type, displayvalue: "name" })
    updateditem.gstr1_filed_date = setFiledDateToolTip(item, "gstr1")
    updateditem.gstr1_filed = item.gstr1_filed === "1" ? "Filed" : "Not Filed"

    updateditem.gstr2_filed_date = setFiledDateToolTip(item, "gstr2")
    updateditem.gstr2_filed = item.gstr2B_filed === "1" ? "Filed" : "Not Filed"

    updateditem.gstr3B_filed_date = setFiledDateToolTip(item, "gstr3b")
    updateditem.gstr3B_filed = item.gstr3B_filed === "1" ? "Filed" : "Not Filed"

    updateditem.e_invoice_status = getdropdownfieldvalue({ dropDownData: getewaybillstatus(), field: "id", value: updateditem.e_invoice_status, displayvalue: "name" })
    if (updateditem.e_invoice_status === "Generated") {
      updateditem.generateEwayBill = true
    }
    updateditem.ewaybill_status = getdropdownfieldvalue({ dropDownData: getewaybillstatus(), field: "id", value: updateditem.ewaybill_status, displayvalue: "name" })
    return updateditem
  })
}

export const dataSetInListItem = (data) => {
  return data.map((item, index) => {
    const updateditem = { ...item }
    // updateditem.itemtype = getdropdownfieldvalue(getsessionuserdata("account_type") === aTP.Gold ? getproducttypegold("", "reducer") : getproducttype(), "id", item.itemtype, "name") // account_type === "8" GOLD ACCOUNT
    // updateditem.show_in_gmc = updateditem.show_in_gmc === "1" ? "Yes" : "No"
    updateditem.inactive = updateditem.inactive === "1" ? "Inactive" : "Active"
    return updateditem
  })
}

export const dataSetInListPurchase = (result) => {
  return result.map((item, index) => {
    let updateditem = { ...item }
    if (updateditem.invoice_type === "purchase_order") {
      updateditem.status = item.status === "0" ? "Pending" : item.status === "1" ? "Partially" : "Closed"
    }
    updateditem.adjust_status = item.adjust_status === "0" ? "Unpaid" : item.adjust_status === "1" ? "Partially Paid" : item.adjust_status === "2" ? "Paid" : "Unpaid"
    updateditem.gstr1_filed_date = setFiledDateToolTip(item, "gstr1")

    updateditem.gstr2_filed_date = setFiledDateToolTip(item, "gstr2")
    updateditem.gstr2_filed = item.gstr2B_filed === "1" ? "Filed" : "Not Filed"

    updateditem.gstr3B_filed_date = setFiledDateToolTip(item, "gstr3b")
    updateditem.gstr3B_filed = item.gstr3B_filed === "1" ? "Filed" : "Not Filed"

    updateditem = getDueStatus(updateditem)
    return updateditem
  })
}

export const dataSetInListIO = (result) => {
  return result.map((item, index) => {
    const updateditem = { ...item }
    if (updateditem.hasOwnProperty("ps_status") && !isblank(updateditem.ps_status)) {
      updateditem.ps_status = updateditem.ps_status === "2" ? "Closed" : updateditem.ps_status === "1" ? "Archive" : "Pending"
    }
    updateditem.ewaybill_status = getdropdownfieldvalue({ dropDownData: getewaybillstatus(), field: "id", value: updateditem.ewaybill_status, displayvalue: "name" })
    return updateditem
  })
}

export const dataSetInList = (data) => {
  return data.map((item, index) => {
    const updateditem = { ...item }
    updateditem.itemtype = getdropdownfieldvalue({
      dropDownData: getsessionuserdata("account_type") === aTP.Gold ? getproducttypegold("", "reducer") : getproducttype(),
      field: "id",
      value: item.itemtype,
      displayvalue: "name",
    }) // account_type === "8" GOLD ACCOUNT
    updateditem.show_in_gmc = updateditem.show_in_gmc === "1" ? "Yes" : "No"
    updateditem.inactive = updateditem.inactive === "1" ? "Inactive" : "Active"
    return updateditem
  })
}

export const getAllNameType = (getAccountSitesetting) => {
  var Names = {}
  const invoice_type_detail = getAccountSitesetting("invoice_type_detail")
  const purchase_type_detail = getAccountSitesetting("purchase_type_detail")
  const inward_type_detail = getAccountSitesetting("inward_type_detail")
  const outward_type_detail = getAccountSitesetting("outward_type_detail")
  invoice_type_detail.forEach((item) => {
    Names[`${item.id}`] = item.name
  })
  purchase_type_detail.forEach((item) => {
    Names[`${item.id}`] = item.name
  })
  inward_type_detail.forEach((item) => {
    Names[`${item.id}`] = item.name
  })
  outward_type_detail.forEach((item) => {
    Names[`${item.id}`] = item.name
  })
  Names["salesreturn"] = "Credit Note"
  Names["purchasereturn"] = "Debit Note"
  Names["lot_transfer"] = "Process Transfer"
  Names["stock_transferGodown"] = "Godown Stock Transfer"

  return Names
}

export const getnotetypeWithVoucher = () => {
  return [
    {
      id: "S",
      name: "Sales",
    },
    {
      id: "PU",
      name: "Purchase",
    },
    {
      id: "VP",
      name: "Voucher (Purchase)",
    },
    {
      id: "VS",
      name: "Voucher (Sales)",
    },
    {
      id: "CO",
      name: "Commercial",
    },
  ]
}
