import { deleteInReducer, getdropdownvalue } from "@project/components/utilityFunctions"
import {
  LABEL_SEARCH_TEXT_UPDATE,
  LABEL_SERVICE_CLEAR,
  LABEL_SERVICE_DELETED,
  LABEL_SERVICE_FAIL,
  LABEL_SERVICE_INSERTED,
  LABEL_SERVICE_START,
  LABEL_SERVICE_SUCCESS,
  LABEL_SERVICE_UPDATED,
} from "../../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  totalcount: 0,
  norecord: false,
  rowsPerPage: 0,
  page: 0,
  order: "asc",
  orderBy: "templatename",
  search_text: "",
  deleted: false,
  columnData: [
    { id: "templatename", numeric: false, visible: true, label: "Name" },
    { id: "papersize", numeric: false, visible: true, label: "Paper Size", width: 250 },
    { id: "type", numeric: false, visible: true, label: "Type", width: 150 },
  ],
  metadata: "",
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LABEL_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case LABEL_SERVICE_SUCCESS:
      return {
        ...state,
        data: action.payload.data.result,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        islogin: true,
        norecord: action.payload.data.result.length === 0,
      }
    case LABEL_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false, totalcount: 0, search_text: action.search_text }
    case LABEL_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case LABEL_SEARCH_TEXT_UPDATE:
      return { ...state, search_text: action.search_text, norecord: false }
    case LABEL_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_code
      inserteditem.templatename = action.payload.templateName
      inserteditem.papersize = getdropdownvalue({ formValues: action.payload, field: "paperSizeSelectedValue", prop: "name" })
      inserteditem.type = "product"
      return { ...state, data: [inserteditem, ...state.data] }

    case LABEL_SERVICE_DELETED:
      return deleteInReducer({ action, key: "data", state })
    case LABEL_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (item.code !== action.payload.update_code) {
          return item
        }
        const updateditem = { ...item }
        updateditem.templatename = action.payload.templateName
        updateditem.papersize = getdropdownvalue({ formValues: action.payload, field: "paperSizeSelectedValue", prop: "name" })
        updateditem.type = "product"
        return updateditem
      })
      return { ...state, data: updateddata }
    default:
      return state
  }
}
