import { mmToPoints, todaydate } from "@project/components/utilityFunctions"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import re from "@project/sharedcomponents/constantData/reportsEnum"
import {
  DatetoDMY,
  amount_format,
  checkCondition,
  convertIntif,
  formatIndianPhoneNumber,
  generateTextAreaAddress,
  getdropdownfieldvalue,
  isblank,
  number2text,
} from "@project/sharedcomponents/utilityFunctions"
import { snackbarstate } from "../Actions"
import { LAST_PAGE_UPDATE } from "../Actions/types"
import { getAccountSessionUserData, getStateName } from "./commonfunctions"

export const EnvelopePrint = ({ partyDetail, envelopeDetail, dd_country_state }) => {
  const getValue = (key) => {
    if (!isblank(envelopeDetail[key])) {
      return convertIntif(envelopeDetail[key])
    }
    return 0
  }
  var fontSize = 10
  const getFontSize = (key) => {
    if (!isblank(envelopeDetail[key])) {
      return convertIntif(envelopeDetail[key])
    }
    return fontSize
  }
  var receipantName = getAccountSessionUserData("company_name")
  var receipantAddress = getAccountSessionUserData("company_address1")
  var receipantContact = getAccountSessionUserData("mobile")

  if (!isblank(partyDetail)) {
    receipantName = partyDetail.name
    receipantAddress = generateTextAreaAddress({
      address: partyDetail.address,
      areacode: partyDetail.areacode,
      city: partyDetail.city,
      state: partyDetail.state,
      country: partyDetail.country,
      pincode: partyDetail.pincode,
    })
    receipantContact = partyDetail.mobile
  }

  const pageSize = { width: 220, height: 110 }
  if (!isblank(envelopeDetail.envelope_Height)) {
    pageSize.height = parseFloat(envelopeDetail.envelope_Height)
  }
  if (!isblank(envelopeDetail.envelope_Width)) {
    pageSize.width = parseFloat(envelopeDetail.envelope_Width)
  }
  pageSize.height = mmToPoints(pageSize.height)
  pageSize.width = mmToPoints(pageSize.width)

  var companyState = getStateName({
    country: getAccountSessionUserData("company_country"),
    state: getAccountSessionUserData("statecode"),
  })
  const senderAddress = generateTextAreaAddress({
    address: getAccountSessionUserData("company_address1"),
    areacode: getAccountSessionUserData("company_street"),
    city: getAccountSessionUserData("company_city"),
    state: companyState,
    country: getdropdownfieldvalue({ dropDownData: dd_country_state, field: "id", value: getAccountSessionUserData("company_country"), displayvalue: "name" }),
    pincode: getAccountSessionUserData("company_pincode"),
  })

  var tempData = {
    receipantName,
    receipantAddress,
    receipantContact,

    senderName: getAccountSessionUserData("company_name"),
    senderAddress: senderAddress,
    senderContact: getAccountSessionUserData("mobile"),
  }
  const pageOrientation = !isblank(envelopeDetail.envelope_orientation) ? envelopeDetail.envelope_orientation : "landscape"
  var content = []
  if (envelopeDetail.printReceipentAddress) {
    var receipentAddressStack = []

    if (envelopeDetail.to) {
      receipentAddressStack.push({
        text: "To",
        margin: [mmToPoints(getValue("to_left")), mmToPoints(getValue("to_top")), 0, 0],
        fontSize: getFontSize("to_fontSize"),
        bold: envelopeDetail.to_bold,
        italics: envelopeDetail.to_italic,
        decoration: envelopeDetail.to_underline ? "underline" : "",
      })
    }

    if (envelopeDetail.receipent_Name) {
      receipentAddressStack.push({
        text: tempData.receipantName,
        margin: [mmToPoints(getValue("receipent_Name_left")), mmToPoints(getValue("receipent_Name_top")), 0, 0],
        fontSize: getFontSize("receipent_Name_fontSize"),
        bold: envelopeDetail.receipent_Name_bold,
        italics: envelopeDetail.receipent_Name_italic,
        decoration: envelopeDetail.receipent_Name_underline ? "underline" : "",
      })
    }
    if (envelopeDetail.receipent_Address) {
      receipentAddressStack.push({
        text: tempData.receipantAddress,
        margin: [mmToPoints(getValue("receipent_Address_left")), mmToPoints(getValue("receipent_Address_top")), 0, 0],
        fontSize: getFontSize("receipent_Address_fontSize"),
        bold: envelopeDetail.receipent_Address_bold,
        italics: envelopeDetail.receipent_Address_italic,
        decoration: envelopeDetail.receipent_Address_underline ? "underline" : "",
      })
    }
    if (envelopeDetail.receipent_Contact) {
      receipentAddressStack.push({
        text: tempData.receipantContact,
        margin: [mmToPoints(getValue("receipent_Contact_left")), mmToPoints(getValue("receipent_Contact_top")), 0, 0],
        fontSize: getFontSize("receipent_Contact_fontSize"),
        bold: envelopeDetail.receipent_Contact_bold,
        italics: envelopeDetail.receipent_Contact_italic,
        decoration: envelopeDetail.receipent_Contact_underline ? "underline" : "",
      })
    }
    content.push({
      columns: [
        {
          stack: receipentAddressStack,
          width: mmToPoints(getValue("receipentAddress_width")),
        },
      ],
      absolutePosition: {
        x: mmToPoints(getValue("receipentAddress_left")),
        y: mmToPoints(getValue("receipentAddress_top")),
      },
    })
  }
  if (envelopeDetail.printSenderAddress) {
    var senderAddressStack = []
    if (envelopeDetail.from) {
      senderAddressStack.push({
        text: "From",
        margin: [mmToPoints(getValue("from_left")), mmToPoints(getValue("from_top")), 0, 0],
        fontSize: getFontSize("from_fontSize"),
        bold: envelopeDetail.from_bold,
        italics: envelopeDetail.from_italic,
        decoration: envelopeDetail.from_underline ? "underline" : "",
      })
    }
    if (envelopeDetail.sender_Name) {
      senderAddressStack.push({
        text: tempData.senderName,
        margin: [mmToPoints(getValue("sender_Name_left")), mmToPoints(getValue("sender_Name_top")), 0, 0],
        fontSize: getFontSize("sender_Name_fontSize"),
        bold: envelopeDetail.sender_Name_bold,
        italics: envelopeDetail.sender_Name_italic,
        decoration: envelopeDetail.sender_Name_underline ? "underline" : "",
      })
    }
    if (envelopeDetail.sender_Address) {
      senderAddressStack.push({
        text: tempData.senderAddress,
        margin: [mmToPoints(getValue("sender_Address_left")), mmToPoints(getValue("sender_Address_top")), 0, 0],
        fontSize: getFontSize("sender_Address_fontSize"),
        bold: envelopeDetail.sender_Address_bold,
        italics: envelopeDetail.sender_Address_italic,
        decoration: envelopeDetail.sender_Address_underline ? "underline" : "",
      })
    }
    if (envelopeDetail.sender_Contact) {
      senderAddressStack.push({
        text: tempData.senderContact,
        margin: [mmToPoints(getValue("sender_Contact_left")), mmToPoints(getValue("sender_Contact_top")), 0, 0],
        fontSize: getFontSize("sender_Contact_fontSize"),
        bold: envelopeDetail.sender_Contact_bold,
        italics: envelopeDetail.sender_Contact_italic,
        decoration: envelopeDetail.sender_Contact_underline ? "underline" : "",
      })
    }
    content.push({
      columns: [
        {
          stack: senderAddressStack,
          width: mmToPoints(getValue("senderAddress_width")),
        },
      ],
      absolutePosition: {
        x: mmToPoints(getValue("senderAddress_left")),
        y: mmToPoints(getValue("senderAddress_top")),
      },
    })
  }
  if (envelopeDetail.printSenderLogo && !isblank(getAccountSessionUserData("base64_logo"))) {
    content.push({
      image: `data:image/png;base64,${getAccountSessionUserData("base64_logo")}`,
      style: "headerLogo",
      //width: 150,
      width: getValue("sender_logo_width"),
      height: getValue("sender_logo_height"),
      absolutePosition: {
        y: mmToPoints(getValue("sender_logo_top")),
        x: mmToPoints(getValue("sender_logo_left")),
      },
    })
  }
  if (envelopeDetail.printExtraImage && !isblank(envelopeDetail.extraImage_base64)) {
    content.push({
      image: envelopeDetail.extraImage_base64,
      style: "headerLogo",
      width: getValue("extra_image_width"),
      height: getValue("extra_image_height"),
      absolutePosition: {
        y: mmToPoints(getValue("extra_image_top")),
        x: mmToPoints(getValue("extra_image_left")),
      },
    })
  }
  if (envelopeDetail.printQrCode) {
    var QRabsolutePosition = {
      y: mmToPoints(getValue("sender_qrCode_top")),
      x: mmToPoints(getValue("sender_qrCode_left")),
    }
    if (envelopeDetail.qrCodeType === "T") {
      content.push({
        qr: getValue("qr_code_action"),
        fit: "80",
        absolutePosition: QRabsolutePosition,
      })
    } else if (envelopeDetail.qrCodeType === "U") {
      content.push({
        qr: getValue("qr_code_action"),
        fit: "80",
        absolutePosition: QRabsolutePosition,
      })
    } else if (envelopeDetail.qrCodeType === "C") {
      content.push({
        qr: "BEGIN:VCARD\nVERSION:3.0\nN:Name;First Name\nORG:Organization\nEMAIL;TYPE=INTERNET:Email@gmail.com\nURL:https://www.gmailc.com\nTEL;TYPE=CELL:9904899717\nTEL:9714106230\nTEL;TYPE=FAX:22863547\nADR:;;Address;City;STate;380004;Country\nEND:VCARD",
        fit: "120",
        absolutePosition: QRabsolutePosition,
      })
    } else if (envelopeDetail.qrCodeType === "S") {
      content.push({
        qr: `SMSTO:${getValue("qr_code_action")}:${getValue("qr_code_action1")}`,
        fit: "80",
        absolutePosition: QRabsolutePosition,
      })
    } else if (envelopeDetail.qrCodeType === "P") {
      content.push({
        qr: `TEL:${getValue("qr_code_action")}`,
        fit: "80",
        absolutePosition: QRabsolutePosition,
      })
    }
  }

  var dd = {
    // a string or { width: number, height: number }
    pageSize: pageSize,
    pageOrientation: pageOrientation,
    pageMargins: [0, 0, 0, 0],
    header: [],
    footer: [],
    content: content,
    defaultStyle: {
      fontSize: fontSize,
    },
    styles: {},
  }

  return dd
}

export const splitString = (string, index) => {
  var string1 = ""
  var string2 = ""
  if (string[index] !== " ") {
    return splitString(string, index - 1)
  } else {
    string1 = string.slice(0, index)
    string2 = string.slice(index, string.length)
  }
  return {
    string1,
    string2,
  }
}

export const ChequePrint = ({ paymentDetail, chequeDetail }) => {
  const getValue = (key) => {
    if (!isblank(chequeDetail[key])) {
      return convertIntif(chequeDetail[key])
    }
    return 0
  }
  var payName = getAccountSessionUserData("company_name")
  var cheque_date = todaydate("DMY")
  var amount = "1,00,000.00"
  var amount_words = "One lakh rupees only"
  var Ac_payee = "A/c Payee"
  var content = []
  var fontSize = 10

  if (!isblank(paymentDetail)) {
    if (!isblank(paymentDetail.name1)) {
      payName = paymentDetail.name1
    }
    if (!isblank(paymentDetail.chequedate)) {
      cheque_date = DatetoDMY(paymentDetail.chequedate)
    }
    if (!isblank(paymentDetail.amount)) {
      amount = amount_format(paymentDetail.amount)
      amount_words = number2text(paymentDetail.amount)
    }
  }

  const pageSize = { width: 220, height: 110 }
  if (!isblank(chequeDetail.cheque_Height)) {
    pageSize.height = parseFloat(chequeDetail.cheque_Height)
  }
  if (!isblank(chequeDetail.cheque_Width)) {
    pageSize.width = parseFloat(chequeDetail.cheque_Width)
  }
  pageSize.height = mmToPoints(pageSize.height)
  pageSize.width = mmToPoints(pageSize.width)

  if (!isblank(chequeDetail["fontSize"])) {
    fontSize = convertIntif(chequeDetail["fontSize"])
  }
  if (chequeDetail.pay) {
    content.push({
      text: payName,
      absolutePosition: {
        y: mmToPoints(getValue("pay_top")),
        x: mmToPoints(getValue("pay_left")),
      },
    })
  }
  if (chequeDetail.date) {
    content.push({
      text: cheque_date,
      absolutePosition: {
        y: mmToPoints(getValue("date_top")),
        x: mmToPoints(getValue("date_left")),
      },
    })
  }
  if (chequeDetail.amount) {
    content.push({
      text: amount,
      fontSize: fontSize + 2,
      absolutePosition: {
        y: mmToPoints(getValue("amount_top")),
        x: mmToPoints(getValue("amount_left")),
      },
    })
  }
  if (chequeDetail.amount_in_words) {
    if (amount_words.length > getValue("amount_words_maxChar") && getValue("amount_words_maxChar") > 0) {
      content.push({
        text: splitString(amount_words, getValue("amount_words_maxChar")).string1,
        absolutePosition: {
          y: mmToPoints(getValue("amount_words_top")),
          x: mmToPoints(getValue("amount_words_left")),
        },
      })
      content.push({
        text: splitString(amount_words, getValue("amount_words_maxChar")).string2,
        absolutePosition: {
          y: mmToPoints(getValue("amount_words2_top")),
          x: mmToPoints(getValue("amount_words2_left")),
        },
      })
    } else {
      content.push({
        text: amount_words,
        absolutePosition: {
          y: mmToPoints(getValue("amount_words_top")),
          x: mmToPoints(getValue("amount_words_left")),
        },
      })
    }
  }
  if (chequeDetail.ac_payee) {
    content.push({
      text: Ac_payee,
      absolutePosition: {
        y: mmToPoints(getValue("ac_payee_top")),
        x: mmToPoints(getValue("ac_payee_left")),
      },
    })
  }

  var dd = {
    // a string or { width: number, height: number }
    pageSize: pageSize,
    pageOrientation: "landscape",
    pageMargins: [0, 0, 0, 0],
    header: [],
    footer: [],
    content: content,
    defaultStyle: {
      fontSize: fontSize,
    },
    styles: {},
  }

  return dd
}

export const sendNotificationInvoiceFn = (props) => {
  const { fileData, lastpagedata, sendNewNotifications, dispatch, dS, detail, other_type, onComplete } = props

  var send_type = []

  if (lastpagedata.sendSMS) {
    send_type.push("sms")
  }
  if (lastpagedata.sendEmail) {
    send_type.push("email")
  }
  if (lastpagedata.sendWhatsApp) {
    send_type.push("whatsapp")
  }
  if (lastpagedata.sendAdmin) {
    send_type.push("sendAdmin")
  }

  if (
    (dS.email_module !== "yes" && lastpagedata.sendEmail === true) ||
    (dS.sms_module !== "yes" && lastpagedata.sendSMS === true) ||
    (dS.enable_whatsapp_api !== "yes" && lastpagedata.sendWhatsApp === true)
  ) {
    dispatch(snackbarstate({ open: true, message: ErrorMessages["E00549"], snackbarType: "error" }))

    dispatch({
      type: LAST_PAGE_UPDATE,
      lasttype: "sendNotification",
      lastaction: "error",
      lasttime: new Date().getTime(),
    })
  } else {
    if (dS.whatsapp_module === "whatsapp_web" && dS.whatsapp_queue === "none") {
      if (!isblank(window.fbWhatsAppSender)) {
        var formatted_number = ""
        if (detail.country_id === "103") {
          // India phone number
          formatted_number = formatIndianPhoneNumber(detail.mobile)
        }
        window.fbWhatsAppSender.sendBase64Message(formatted_number, fileData.filebase64, fileData.filemimetype, fileData.filename, `Doc no: ${detail.billno}`)
        if (onComplete) {
          onComplete()
        }
      } else {
        dispatch(snackbarstate({ open: true, message: "Install FBSender Chrome Extension.", snackbarType: "error" }))
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "sendNotification",
          lastaction: "error",
          lasttime: new Date().getTime(),
        })
      }
    } else {
      dispatch(
        sendNewNotifications({
          type: "send-media",
          filename: fileData.filename,
          filebase64: fileData.filebase64,
          filemimetype: fileData.filemimetype,
          send_type: send_type,
          doc_type: lastpagedata.invoice_type,
          email: lastpagedata.email,
          number: lastpagedata.number,
          message: lastpagedata.sendSMS,
          defaultMobile: lastpagedata.defaultMobile,
          defaultEmail: lastpagedata.defaultEmail,
          partyCode: lastpagedata.notifyPartyCode,
          docDetail: detail,
          ...(onComplete && { onComplete }),
        })
      )
    }
  }
}

export const sendNotificationReportFn = (prop) => {
  const { printdata, props, sendNewNotifications, sendData, metadata, share_detail, dispatch, dS } = prop
  const { dropDowns, formValues } = props
  const { ddallaccount } = dropDowns

  var email = sendData.email
  var number = sendData.number
  var defaultMobile = !isblank(sendData.defaultMobile) ? sendData.defaultMobile : ""
  var defaultEmail = !isblank(sendData.defaultEmail) ? sendData.defaultEmail : ""
  var partyCode = !isblank(sendData.notifyPartyCode) ? sendData.notifyPartyCode : ""
  var company_name = dS.company_name
  var send_type = []
  if (sendData.sendSMS) {
    send_type.push("sms")
  }
  if (sendData.sendEmail) {
    send_type.push("email")
  }
  if (sendData.sendWhatsApp) {
    send_type.push("whatsapp")
  }
  let party_name = ""
  let date = ""
  let netamt = ""
  let reportid = props.reportdata.reportid
  if (checkCondition(reportid, [re.LedgerReport, re.OutstandingReport, re.OutstandingAgeingReport])) {
    party_name = getdropdownfieldvalue({ dropDownData: ddallaccount, field: "code", value: formValues.partySelectedValue, displayvalue: "name" })
    date = formValues.ToDate
    if (!isblank(share_detail)) {
      netamt = share_detail.bal
    }
  }
  const docDetail = {
    billno: "",
    reportid,
    party_name,
    company_name,
    date,
    netamt,
    country_id: sendData.countryId,
  }

  if ((dS.email_module !== "yes" && sendData.sendEmail === true) || (dS.sms_module !== "yes" && sendData.sendSMS === true) || (dS.enable_whatsapp_api !== "yes" && sendData.sendWhatsApp === true)) {
    dispatch(snackbarstate({ open: true, message: ErrorMessages["E00549"], snackbarType: "error" }))
    dispatch({
      type: LAST_PAGE_UPDATE,
      lasttype: "sendNotification",
      lastaction: "error",
      lasttime: new Date().getTime(),
    })
  } else {
    if (dS.whatsapp_module === "whatsapp_web" && dS.whatsapp_queue === "none") {
      if (!isblank(window.fbWhatsAppSender)) {
        var formatted_number = ""
        if (sendData.countryId === "103") {
          // India phone number
          formatted_number = formatIndianPhoneNumber(sendData.number)
        }
        window.fbWhatsAppSender.sendBase64Message(formatted_number, printdata, "application/pdf", metadata.filename, metadata.title)
      } else {
        dispatch(snackbarstate({ open: true, message: "Install FBSender Chrome Extension.", snackbarType: "error" }))
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "sendNotification",
          lastaction: "error",
          lasttime: new Date().getTime(),
        })
      }
    } else {
      dispatch(
        sendNewNotifications({
          type: "send-media",
          filename: metadata.filename,
          filebase64: printdata,
          filemimetype: "application/pdf",
          send_type: send_type,
          doc_type: `report`,
          email,
          number,
          defaultMobile,
          defaultEmail,
          partyCode,
          docDetail,
        })
      )
    }
  }
}
