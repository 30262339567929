import { postErrorOnException } from "./axiosFunctions"
import { isblank } from "./utilityFunctions"

export const responseValid = ({ menu, setting, company_url, error_url, user_name }) => {
  var inValid = []
  if (!isblank(menu) && !isblank(menu.Invoice) && menu.Invoice.length > 0) {
    menu.Invoice.forEach((e) => {
      var invoiceValid = true
      if (e.main_type === "salesinvoices" && e.name !== "Orders") {
        if (!isblank(setting) && !isblank(setting.invoice_type_detail) && setting.invoice_type_detail.length > 0) {
          invoiceValid = setting.invoice_type_detail.filter((i) => i.id === e.type).length > 0
        }
      } else if (e.main_type === "purchaseinvoices") {
        if (!isblank(setting) && !isblank(setting.purchase_type_detail) && setting.purchase_type_detail.length > 0) {
          invoiceValid = setting.purchase_type_detail.filter((i) => i.id === e.type).length > 0
        }
      } else if (e.main_type === "outwards") {
        if (!isblank(setting) && !isblank(setting.outward_type_detail) && setting.outward_type_detail.length > 0) {
          invoiceValid = setting.outward_type_detail.filter((i) => i.id === e.type).length > 0
        }
      } else if (e.main_type === "inwards") {
        if (!isblank(setting) && !isblank(setting.inward_type_detail) && setting.inward_type_detail.length > 0) {
          invoiceValid = setting.inward_type_detail.filter((i) => i.id === e.type).length > 0
        }
      }
      if (!invoiceValid) {
        inValid.push(`${e.name} setting missing`)
      }
    })
  }

  if (!isblank(menu) && !isblank(menu.Process) && menu.Process.length > 0) {
    menu.Process.forEach((e) => {
      var invoiceValid = true
      if (e.main_type === "salesinvoices") {
        if (!isblank(setting) && !isblank(setting.invoice_type_detail) && setting.invoice_type_detail.length > 0) {
          invoiceValid = setting.invoice_type_detail.filter((i) => i.id === e.type).length > 0
        }
      } else if (e.main_type === "purchaseinvoices") {
        if (!isblank(setting) && !isblank(setting.purchase_type_detail) && setting.purchase_type_detail.length > 0) {
          invoiceValid = setting.purchase_type_detail.filter((i) => i.id === e.type).length > 0
        }
      } else if (e.main_type === "outwards") {
        if (!isblank(setting) && !isblank(setting.outward_type_detail) && setting.outward_type_detail.length > 0) {
          invoiceValid = setting.outward_type_detail.filter((i) => i.id === e.type).length > 0
        }
      } else if (e.main_type === "inwards") {
        if (!isblank(setting) && !isblank(setting.inward_type_detail) && setting.inward_type_detail.length > 0) {
          invoiceValid = setting.inward_type_detail.filter((i) => i.id === e.type).length > 0
        }
      }
      if (!invoiceValid) {
        inValid.push(`${e.name} setting missing`)
      }
    })
  }

  if (inValid.length > 0) {
    const error = {
      message: inValid,
    }
    if (error_url) {
      postErrorOnException(error, error_url, "Setting Missing", company_url, user_name)
    } else {
      postErrorOnException(error, "", "Setting Missing", company_url, user_name)
    }
    return false
  } else {
    return true
  }
}
