import { RESET_HOOK_FORM_DATA, SET_HOOK_FORM_DATA } from "../Actions/types"
const INITIAL_STATE = {
  formData: {},
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_HOOK_FORM_DATA:
      let formData = state.formData
      if (action.key === "formData") {
        formData = action.value
      } else {
        formData[action.key] = action.value
      }
      return {
        ...state,
        formData: formData,
      }

    case RESET_HOOK_FORM_DATA:
      return { ...state, formData: {} }
    default:
      return state
  }
}
