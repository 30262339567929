import {
  GST_OLD_PEN_INV_SERVICE_SUCCESS,
  GST_OLD_PEN_INV_SERVICE_START,
  GST_OLD_PEN_INV_SERVICE_INSERTED,
  GST_OLD_PEN_INV_SERVICE_DELETED,
  GST_OLD_PEN_INV_SERVICE_UPDATED,
  GST_OLD_PEN_INV_SERVICE_CLEAR,
  GST_OLD_PEN_INV_SEARCH_TEXT_UPDATE,
} from "../Actions/types"
import { getdropdownvalue } from "@project/components/utilityFunctions"
import { DatetoDMY } from "@project/sharedcomponents/utilityFunctions"
import { convertIntif } from "@project/sharedcomponents/utilityFunctions"

const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  page: 0,
  order: "desc",
  norecord: false,
  orderBy: "code",
  deleted: false,
  search_text: "",
  report_success: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GST_OLD_PEN_INV_SERVICE_START:
      return { ...state, loading: true, error: "", report_success: false }
    case GST_OLD_PEN_INV_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case GST_OLD_PEN_INV_SERVICE_SUCCESS:
      return { ...state, report_success: true, data: action.payload.data.result.records, loading: false }

    case GST_OLD_PEN_INV_SEARCH_TEXT_UPDATE:
      return { ...state, search_text: action.search_text, norecord: false }

    case GST_OLD_PEN_INV_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_data.insert_code
      inserteditem.creditnote_no = action.insert_data.docno
      inserteditem.billno = action.payload.previousYearBill ? action.payload.previuos_billno : getdropdownvalue({ formValues: action.payload, field: "salesBillSelectedValue", prop: "name" })
      inserteditem.bill_date = DatetoDMY(action.payload.InvoiceDate)
      inserteditem.name = getdropdownvalue({ formValues: action.payload, field: "customerSelectedValue", prop: "name" })
      inserteditem.partycode = getdropdownvalue({ formValues: action.payload, field: "customerSelectedValue", prop: "code" })
      inserteditem.city = getdropdownvalue({ formValues: action.payload, field: "customerSelectedValue", prop: "city" })
      inserteditem.state = getdropdownvalue({ formValues: action.payload, field: "customerSelectedValue", prop: "state" })
      inserteditem.netamt = action.payload.tot_net_amount
      inserteditem.invoice_type = getdropdownvalue({ formValues: action.payload, field: "noteTypeSelectedValue", prop: "name" })

      return { ...state, data: [inserteditem, ...state.data] }
    case GST_OLD_PEN_INV_SERVICE_DELETED:
      if (action.otherDetail === "multi_delete") {
        var finalData = []
        var applyToDelete = action.payload.code.codes.map((a) => a.toString())
        if (action.response.data.meta.code === 403) {
          //Error
          var NotDelete = action.response.data.meta.message.map((a) => a.code.toString())
          finalData = state.data.filter((item) => {
            item.code = item.code.toString()
            if (!(applyToDelete.includes(item.code) && !NotDelete.includes(item.code))) {
              return item
            }
            return false
          })
        } //All delete
        else {
          finalData = state.data.filter((item) => {
            item.code = item.code.toString()
            if (!applyToDelete.includes(item.code)) {
              return item
            }
            return false
          })
        }
        return { ...state, data: finalData, deleted: !state.deleted }
      } else {
        return { ...state, data: state.data.filter((item) => item.code.toString() !== action.payload.code.toString()), deleted: !state.deleted }
      }

    case GST_OLD_PEN_INV_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.update_code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.creditnote_no = action.payload.creditnoteno
        updateditem.billno = action.payload.previousYearBill ? action.payload.previuos_billno : getdropdownvalue({ formValues: action.payload, field: "salesBillSelectedValue", prop: "name" })
        updateditem.bill_date = DatetoDMY(action.payload.InvoiceDate)
        updateditem.name = getdropdownvalue({ formValues: action.payload, field: "customerSelectedValue", prop: "name" })
        updateditem.partycode = getdropdownvalue({ formValues: action.payload, field: "customerSelectedValue", prop: "code" })
        updateditem.city = getdropdownvalue({ formValues: action.payload, field: "customerSelectedValue", prop: "city" })
        updateditem.state = getdropdownvalue({ formValues: action.payload, field: "customerSelectedValue", prop: "state" })
        updateditem.netamt = action.payload.tot_net_amount
        updateditem.invoice_type = getdropdownvalue({ formValues: action.payload, field: "noteTypeSelectedValue", prop: "name" })
        return updateditem
      })
      return { ...state, data: updateddata }

    default:
      return state
  }
}
