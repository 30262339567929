import { UPDATE_SERVICEWORKER } from "../Actions/types"

const INITIAL_STATE = {
  serviceWorkerUpdated: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SERVICEWORKER:
      return { ...state, serviceWorkerUpdated: true }
    default:
      return state
  }
}
