import { USER_HISTORY_REPORT_SERVICE_CLEAR, USER_HISTORY_REPORT_SERVICE_FAIL, USER_HISTORY_REPORT_SERVICE_START, USER_HISTORY_REPORT_SERVICE_SUCCESS } from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  search_text: "",
  norecord: false,
  columnData: [
    { id: "username", numeric: false, visible: true, label: "Username", width: 125 },
    { id: "activity_type", numeric: false, visible: true, label: "Activity Type", width: 150 },
    { id: "billno", numeric: false, visible: true, label: "Doc no", width: 125 },
    { id: "billDate", numeric: false, visible: true, label: "Doc Date", width: 150, format: "dmy" },
    { id: "entry_date", numeric: false, visible: true, label: "Entry Date", width: 150, format: "date_time2" },
    { id: "action", numeric: false, visible: true, label: "Action", width: 125 },
    { id: "devicetype", numeric: false, visible: true, label: "Device Type", width: 125 },
    { id: "old_details", numeric: false, visible: true, width: 250, label: "Old Details", ifBlank: "removeBgColor", bgColor: "rgba(244, 67, 54, 0.08)", color: "#f44336" },
    { id: "updated_details", numeric: false, visible: true, width: 250, label: "Updated Details", bgColor: "rgba(76, 175, 80, 0.08)", color: "#4caf50" },
    { id: "activity_detail", numeric: false, visible: false, width: 0, label: "Activity Detail", printcharcount: 100 },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_HISTORY_REPORT_SERVICE_START:
      return { ...state, loading: true, error: "" }

    case USER_HISTORY_REPORT_SERVICE_CLEAR:
      return { ...state, data: [], loading: false, error: "" }

    case USER_HISTORY_REPORT_SERVICE_SUCCESS:
      action.payload.data.result.rows = action.payload.data.result.rows.map((e) => {
        const updated = { ...e }
        if (updated.devicetype === "0") {
          updated.devicetype = "Web"
        }
        if (updated.devicetype === "1") {
          updated.devicetype = "Android"
        }
        if (updated.devicetype === "2") {
          updated.devicetype = "ios"
        }
        if (updated.devicetype === "3") {
          updated.devicetype = "mobile"
        }
        return updated
      })
      return { ...state, data: action.payload.data.result.rows, norecord: action.payload.data.result.num_rows === "0" }
    case USER_HISTORY_REPORT_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false }

    default:
      return state
  }
}
