import { InputLabel, useTheme } from "@material-ui/core"
import { isblank } from "@project/sharedcomponents/utilityFunctions"
import React from "react"

export default ({ label, name, noLableMargin, isRequired, labelStyle, error }) => {
  const theme = useTheme()
  let inputLabelStyle = {
    color: (!isblank(error) && Boolean(error)) || isRequired ? theme.palette.error.main : theme.palette.text.main,
    textTransform: "uppercase",
    marginTop: 8,
    marginBottom: 4,
  }

  if (noLableMargin) {
    inputLabelStyle = { ...inputLabelStyle, marginTop: 0 }
  }
  if (labelStyle) {
    inputLabelStyle = { ...inputLabelStyle, ...labelStyle }
  }
  if (!isblank(label)) {
    return (
      <InputLabel htmlFor={name} style={inputLabelStyle} error={Boolean(error)}>
        {label}
      </InputLabel>
    )
  }
  return null
}
