import * as XLSX from "xlsx-js-style"
import colors from "./constantData/colors"
import { isblank } from "./utilityFunctions"

function fitToColumn(arrayOfArray) {
  // get maximum character of each column
  return Object.keys(arrayOfArray[0]).map((a, i) => {
    return {
      wch: Math.max(...arrayOfArray.map((a2) => (!isblank(a2[a]) ? a2[a].toString().length + 5 : 5))),
    }
  })
}

export const generateExcelData = ({ csvData, title, mycol, skipIndex, headerIndex, total_Index, groupTitle_Index, fileName }) => {
  var skip_Index = skipIndex && skipIndex.length > 0 ? skipIndex : [0]
  var header_Index = headerIndex && headerIndex.length > 0 ? headerIndex : [0]
  var totalIndex = total_Index ? total_Index : []
  var groupTitleIndex = groupTitle_Index ? groupTitle_Index : []
  var originIndex = 1
  if (title && Object.keys(title).length > 0) {
    Object.keys(title).forEach((key) => {
      originIndex = originIndex + 1
    })
    originIndex = originIndex + 1
  }
  var origin = `A${originIndex}`
  const ws = XLSX.utils.json_to_sheet(csvData, { origin: origin, skipHeader: true })
  csvData.forEach((item, index) => {
    if (!skip_Index.includes(index)) {
      mycol.forEach((col, col_index) => {
        if (!isblank(col.fmt)) {
          var cell = XLSX.utils.encode_cell({ r: index + originIndex - 1, c: col_index })
          if (!isblank(cell) && !isblank(ws[cell]) && !isblank(ws[cell].v)) {
            ws[cell].z = col.fmt
          }
        }
      })
    }
  })

  header_Index.forEach((hindex) => {
    mycol.forEach((col, col_index) => {
      var cell = XLSX.utils.encode_cell({ r: hindex + originIndex - 1, c: col_index })
      if (!isblank(cell)) {
        if (isblank(ws[cell])) {
          ws[cell] = {
            v: " ",
          }
        }
        ws[cell].s = {
          font: {
            bold: true,
          },
          fill: {
            patternType: "solid",
            fgColor: { rgb: colors.excelTableHeader },
            bgColor: { rgb: colors.excelTableHeader },
          },
        }
      }
    })
  })

  totalIndex.forEach((hindex) => {
    mycol.forEach((col, col_index) => {
      var cell = XLSX.utils.encode_cell({ r: hindex + originIndex - 1, c: col_index })
      if (!isblank(cell)) {
        if (isblank(ws[cell])) {
          ws[cell] = {
            v: " ",
          }
        }
        ws[cell].s = {
          font: {
            bold: true,
          },
          border: {
            top: {
              style: "thin",
              color: { rgb: "000000" },
            },
          },
        }
      }
    })
  })

  groupTitleIndex.forEach((hindex) => {
    var cell = XLSX.utils.encode_cell({ r: hindex + originIndex - 1, c: 0 })
    if (!isblank(cell)) {
      if (isblank(ws[cell])) {
        ws[cell] = {
          v: " ",
        }
      }
      ws[cell].s = {
        font: {
          bold: true,
        },
      }
    }
  })

  ws["!cols"] = fitToColumn(csvData)
  if (title && Object.keys(title).length > 0) {
    Object.keys(title).forEach((key, index) => {
      if (!isblank(title[key])) {
        ws[`A${index + 1}`] = {}
        ws[`A${index + 1}`].v = title[key]
        ws[`A${index + 1}`].s = {
          font: {
            bold: true,
          },
          // fill: {
          //     patternType: "solid",
          //     fgColor: { rgb: "00dce6f1" },
          //     bgColor: { rgb: "00dce6f1" }
          // }
        }
      }
    })
  }
  return ws
}
