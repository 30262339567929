import { Avatar, Box, Button, Card, CardContent, Container, Grid, Typography } from "@material-ui/core"
import React from "react"
import warning from "../Images/warning.svg"

const UpdateVersion = ({ current_version, latest_version }) => {
  const reloadToNewVersion = () => {
    window.location.reload(true)
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="calc(100vh - 100px)">
      <Container maxWidth="sm">
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Avatar alt="not found" src={warning} style={{ height: 108, width: 108 }} />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="h5" gutterBottom color="primary">
                  New Version Available
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  The app has been updated! Refresh your browser to enjoy the latest and greatest!
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Your current version {current_version} is outdated. The latest version is {latest_version}.
                </Typography>
                <Button variant="contained" color="primary" onClick={() => reloadToNewVersion()}>
                  Reload
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}

export default UpdateVersion
