import { isblank } from "@project/sharedcomponents/utilityFunctions"
import findKey from "lodash/findKey"

export const showError = ({ errors, dispatch, submitError, props }) => {
  if (props) {
    props.snackbarstate({ open: true, message: errors[Object.keys(errors)[0]], snackbarType: "error" })
  }
  const invalidInput = findKey(errors, (key) => key !== undefined)

  if (document.getElementsByName(invalidInput)[0] !== undefined) {
    document.getElementsByName(invalidInput)[0].scrollIntoView({ block: "start", behaviour: "smooth" })
  } else if (!isblank(document.getElementsById) && document.getElementsById(invalidInput)[0] !== undefined) {
    document.getElementsById(invalidInput)[0].scrollIntoView({ block: "start", behaviour: "smooth" })
  } else {
    return false
  }
  setTimeout(function () {
    var elementparent = ""
    if (document.getElementsByName(invalidInput)[0] !== undefined && elementparent.type !== "hidden") {
      elementparent = document.getElementsByName(invalidInput)[0]
    } else {
      elementparent = document.getElementById(invalidInput)
    }
    if (elementparent.type === "hidden") {
      elementparent = document.getElementById(invalidInput)
      if (!isblank(elementparent)) {
        elementparent.focus()
      }
    } else if (elementparent.type === "text" || elementparent.type === "textarea" || elementparent.type === "password" || elementparent.type === "date") {
      //elementparent = document.getElementById(invalidInput);
      if (!isblank(elementparent)) {
        elementparent.focus()
      }
    } else {
      elementparent = elementparent.getElementsByTagName("input")[0]
      if (!isblank(elementparent)) {
        elementparent.focus()
      }
    }
  }, 500)
  var scrolledY = window.scrollY
  if (scrolledY) {
    window.scroll(0, scrolledY - 200)
  }
}

export const showErrorDetail = ({ errors, dispatch, submitError, props }) => {
  var invalidInput = null
  var errortext = ""
  let detailErrorFound = false
  if (Array.isArray(errors[Object.keys(errors)[0]])) {
    errors[Object.keys(errors)[0]].forEach(function (error, key) {
      if (detailErrorFound === false) {
        if (!isblank(error)) {
          invalidInput = Object.keys(errors)[0] + `[${key}].`
          errortext = error[Object.keys(error)[0]]
          invalidInput = invalidInput + Object.keys(error)[0]
          detailErrorFound = true
        }
      }
    })
  } else {
    errortext = errors[Object.keys(errors)[0]]
    invalidInput = findKey(errors, (key) => key !== undefined)
  }

  if (props.hasOwnProperty("snackbarstate") && !isblank(props.snackbarstate)) {
    props.snackbarstate({ open: true, message: errortext, snackbarType: "error" })
  }
  var elementparent = document.getElementsByName(invalidInput)[0]

  if (elementparent !== undefined && elementparent !== null && elementparent.type !== "hidden") {
    elementparent.scrollIntoView({ block: "start", behaviour: "smooth" })
  } else {
    elementparent = document.getElementById(invalidInput)
    if (!isblank(elementparent)) {
      elementparent.scrollIntoView({ block: "start", behaviour: "smooth" })
    }
  }

  setTimeout(function () {
    if (!isblank(elementparent)) {
      if (elementparent.type === "hidden") {
        elementparent = document.getElementById(invalidInput)
        elementparent.focus()
      } else if (elementparent.type === "text" || elementparent.type === "date") {
        //elementparent = document.getElementById(invalidInput);
        elementparent.focus()
      } else {
        if (elementparent.getElementsByTagName("input").length > 0) {
          elementparent.getElementsByTagName("input")[0].focus()
        }
      }
    }
  }, 500)

  if (props.hasOwnProperty("lastPageDataSet") && !isblank(props.lastPageDataSet)) {
    props.lastPageDataSet({ lasttype: "post_form", lastaction: "errors", lastdata: errors })
  }

  var scrolledY = window.scrollY
  if (scrolledY) {
    window.scroll(0, scrolledY - 200)
  }
}

export const commonShowErrorDetail = (errors, dispatch, submitError, props) => {
  if (errors) {
    showErrorDetail({ errors, dispatch, submitError, props })
  }
}
