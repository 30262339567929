import { convertIntif } from "@project/sharedcomponents/utilityFunctions"
import { SMS_TEMPLATE_SERVICE_START, SMS_TEMPLATE_SERVICE_SUCCESS, SMS_TEMPLATE_SERVICE_UPDATED } from "../Actions/types"

const INITIAL_STATE = {
  loading: false,
  data: [],
  norecord: false,
  columnData: [
    { id: "type", numeric: false, visible: true, label: "Type", width: 200 },
    { id: "message", numeric: false, visible: true, label: "Message" },
    { id: "available_tag", numeric: false, visible: true, label: "Available Tags", width: 250 },
  ],
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SMS_TEMPLATE_SERVICE_START:
      return { ...state, loading: true }
    case SMS_TEMPLATE_SERVICE_SUCCESS:
      return { ...state, data: action.payload.data.result, norecord: action.payload.data.result.length === 0 }
    case SMS_TEMPLATE_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.update_code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.type = action.payload.type
        updateditem.message = action.payload.message
        updateditem.available_tag = action.payload.availableTags
        return updateditem
      })
      return { ...state, data: updateddata }
    default:
      return state
  }
}
