import { convertIntif } from "@project/sharedcomponents/utilityFunctions"
import {
  ENVELOPE_PRINTING_SEARCH_TEXT_UPDATE,
  ENVELOPE_PRINTING_SERVICE_CLEAR,
  ENVELOPE_PRINTING_SERVICE_DELETED,
  ENVELOPE_PRINTING_SERVICE_FAIL,
  ENVELOPE_PRINTING_SERVICE_INSERTED,
  ENVELOPE_PRINTING_SERVICE_START,
  ENVELOPE_PRINTING_SERVICE_SUCCESS,
  ENVELOPE_PRINTING_SERVICE_UPDATED,
} from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  totalcount: 0,
  norecord: false,
  rowsPerPage: 0,
  page: 0,
  order: "asc",
  orderBy: "name",
  search_text: "",
  deleted: false,
  columnData: [{ id: "name", numeric: false, visible: true, label: "Name" }],
  metadata: "",
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ENVELOPE_PRINTING_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case ENVELOPE_PRINTING_SERVICE_SUCCESS:
      return {
        ...state,
        data: action.payload.data.result,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        islogin: true,
        norecord: action.payload.data.result.length === 0,
      }
    case ENVELOPE_PRINTING_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false, totalcount: 0, search_text: action.search_text }
    case ENVELOPE_PRINTING_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case ENVELOPE_PRINTING_SEARCH_TEXT_UPDATE:
      return { ...state, search_text: action.search_text, norecord: false }
    case ENVELOPE_PRINTING_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_code
      inserteditem.name = action.payload.name
      return { ...state, data: [inserteditem, ...state.data] }

    case ENVELOPE_PRINTING_SERVICE_DELETED:
      if (action.otherDetail === "multi_delete") {
        var finalData = []
        var applyToDelete = action.payload.code.codes.map((a) => a.toString())
        if (action.response.data.meta.code === 403) {
          //Error
          var NotDelete = action.response.data.meta.message.map((a) => a.code.toString())
          finalData = state.data.filter((item) => {
            item.code = item.code.toString()
            if (!(applyToDelete.includes(item.code) && !NotDelete.includes(item.code))) {
              return item
            }
            return false
          })
        } //All delete
        else {
          finalData = state.data.filter((item) => {
            item.code = item.code.toString()
            if (!applyToDelete.includes(item.code)) {
              return item
            }
            return false
          })
        }
        return { ...state, data: finalData, deleted: !state.deleted }
      } else {
        return { ...state, data: state.data.filter((item) => item.code.toString() !== action.payload.code.toString()), deleted: !state.deleted }
      }
    case ENVELOPE_PRINTING_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.update_code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.name = action.payload.name
        return updateditem
      })
      return { ...state, data: updateddata }
    default:
      return state
  }
}
