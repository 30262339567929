import { deleteInReducer, setColumnDataFilterWise, setOrderFilterWise } from "@project/components/utilityFunctions"
import { convertIntif, dataSetInList } from "@project/sharedcomponents/utilityFunctions"
import {
  PRODUCTSEARCH_SEARCH_TEXT_UPDATE,
  PRODUCTSEARCH_SERVICE_CLEAR,
  PRODUCTSEARCH_SERVICE_DELETED,
  PRODUCTSEARCH_SERVICE_FAIL,
  PRODUCTSEARCH_SERVICE_INSERTED,
  PRODUCTSEARCH_SERVICE_START,
  PRODUCTSEARCH_SERVICE_SUCCESS,
  PRODUCTSEARCH_SERVICE_UPDATED,
} from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  totalcount: 0,
  rowsPerPage: 0,
  page: 0,
  deleted: false,
  order: "",
  orderBy: "",
  norecord: false,
  search_text: "",
  columnData: [
    { id: "name", numeric: false, visible: true, label: "Stock ID", showlink: true, width: 110 },
    { id: "__cust_shape", numeric: false, visible: true, label: "Shape", width: 150, disableSorting: true },
    { id: "__cust_weight", numeric: false, visible: true, label: "Weight", width: 50, disableSorting: true },
    { id: "__cust_clarity", numeric: false, visible: true, label: "Clarity", width: 50, disableSorting: true },
    { id: "__cust_color", numeric: false, visible: true, label: "Color", width: 50, disableSorting: true },
    { id: "__cust_sale_rap_net_discount", numeric: false, visible: true, label: "Discount", width: 50, disableSorting: true },
    { id: "__cust_sale_rap_price", numeric: true, visible: true, label: "Rap", width: 75, format: "qty_decimal", disableSorting: true },
    { id: "__cust_$_per_carat", numeric: true, visible: true, label: "$PerCarets", width: 100, format: "qty_decimal", disableSorting: true },
    { id: "__cust_net_amount", numeric: true, visible: true, label: "Amount", width: 150, format: "qty_decimal", disableSorting: true },
    { id: "__cust_depth(%)", numeric: true, visible: true, label: "Depth%", width: 50, format: "qty_decimal", disableSorting: true },
    { id: "__cust_table", numeric: true, visible: true, label: "Table%", width: 50, format: "qty_decimal", disableSorting: true },
    { id: "__cust_ratio", numeric: false, visible: true, label: "Ratio", width: 50, disableSorting: true },
    { id: "__cust_cut_grade", numeric: false, visible: true, label: "Grade", width: 50, disableSorting: true },
    { id: "__cust_polish", numeric: false, visible: true, label: `Polish`, width: 50, disableSorting: true },
    { id: "__cust_symmetry", numeric: false, visible: true, label: "Symmetry", width: 50, disableSorting: true },
    { id: "__cust_fluorescence_intensity", numeric: false, visible: true, label: "Fluorescense", width: 50, disableSorting: true },
    { id: "__cust_measurements", numeric: false, visible: true, label: "Measurement", width: 150, disableSorting: true },
    { id: "__cust_lab", numeric: false, visible: true, label: "Lab", width: 100, disableSorting: true },
    { id: "__cust_black_inclusion", numeric: false, visible: true, label: "BlackInclusion", width: 100, disableSorting: true },
    { id: "__cust_white_inclusion", numeric: false, visible: true, label: "WhiteIclusion", width: 100, disableSorting: true },
    { id: "__cust_open_inclusion", numeric: false, visible: true, label: "OpenInclusion", width: 100, disableSorting: true },
    { id: "__cust_shade", numeric: false, visible: true, label: "Shade", width: 75, disableSorting: true },
    { id: "__cust_milky", numeric: false, visible: true, label: "Milky", width: 75, disableSorting: true },
    { id: "__cust_stone_city", numeric: false, visible: true, label: "City", width: 150, disableSorting: true },
    { id: "__cust_stone_location", numeric: false, visible: true, label: `Location`, width: 150, disableSorting: true },
    { id: "__cust_internal_comment", numeric: false, visible: true, label: "InternalComment", width: 150, disableSorting: true },
    { id: "__cust_stone_status", numeric: true, visible: true, label: `Status`, width: 150, disableSorting: true },
    { id: "__cust_moving_level", numeric: false, visible: true, label: "Moving Level", width: 100, disableSorting: true },
    { id: "__cust_report", numeric: false, visible: true, label: "Report", width: 150, disableSorting: true },
    { id: "share_url", numeric: false, visible: true, label: "Detail", width: 150, disableSorting: true },
    { id: "dayDiff", numeric: false, visible: true, label: "Days of Pur", width: 100, disableSorting: true },
    {
      id: "inactive",
      numeric: false,
      visible: true,
      label: "Status",
      width: 125,
      disableSorting: true,
      badge: {
        Active: { color: "green" },
        Inactive: { color: "red" },
      },
    },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PRODUCTSEARCH_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case PRODUCTSEARCH_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case PRODUCTSEARCH_SERVICE_SUCCESS:
      action.payload.data.result = dataSetInList(action.payload.data.result)
      if (action.page === 0) {
        var updatedcoloumn = setColumnDataFilterWise({ data: action.payload.data, columnData: state.columnData })
        action = setOrderFilterWise(action)
        return {
          ...state,
          data: action.payload.data.result,
          totalcount: action.payload.data.total_count,
          rowsPerPage: action.rowsPerPage,
          page: action.page,
          order: action.order,
          orderBy: action.orderBy,
          islogin: true,
          columnData: updatedcoloumn,
          norecord: action.payload.data.result.length === 0,
        }
      } else {
        return {
          ...state,
          data: action.payload.data.result,
          totalcount: action.payload.data.total_count,
          rowsPerPage: action.rowsPerPage,
          page: action.page,
          order: action.order,
          orderBy: action.orderBy,
          islogin: true,
          norecord: action.payload.data.result.length === 0,
        }
      }
    case PRODUCTSEARCH_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        data: [],
        loading: false,
        totalcount: 0,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
      }
    case PRODUCTSEARCH_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_code
      inserteditem.name = action.payload.name
      inserteditem.status = action.payload.defaultGodown === true ? "Primary" : ""
      var newData = [inserteditem, ...state.data].map((e) => {
        const updated = { ...e }
        if (action.payload.defaultGodown === true) {
          if (convertIntif(e.code) !== convertIntif(action.insert_code)) {
            updated.status = ""
          }
        }
        return updated
      })
      return { ...state, data: newData }
    case PRODUCTSEARCH_SEARCH_TEXT_UPDATE:
      return { ...state, search_text: action.search_text, norecord: false }
    case PRODUCTSEARCH_SERVICE_DELETED:
      return deleteInReducer({ action, state, key: "data" })
    case PRODUCTSEARCH_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.update_code)) {
          if (item.status === "Primary" && action.payload.defaultGodown === true) {
            const updateditem = { ...item }
            updateditem.status = ""
            return updateditem
          }
          return item
        }
        const updateditem = { ...item }
        updateditem.name = action.payload.name
        updateditem.status = action.payload.defaultGodown === true ? "Primary" : ""
        return updateditem
      })

      return { ...state, data: updateddata }

    default:
      return state
  }
}
