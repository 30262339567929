import { Button, makeStyles } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.contrastText,
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.contrastText,
    },
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
      color: "#fff",
    },
    "&:active": {
      backgroundColor: theme.palette.info.dark,
      color: "#fff",
    },
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: "#fff",
    },
    "&:active": {
      backgroundColor: theme.palette.success.dark,
      color: "#fff",
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
      color: "#fff",
    },
    "&:active": {
      backgroundColor: theme.palette.warning.dark,
      color: "#fff",
    },
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      color: "#fff",
    },
    "&:active": {
      backgroundColor: theme.palette.error.dark,
      color: "#fff",
    },
  },
  purple: {
    backgroundColor: theme.fbcolor.purple.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.fbcolor.purple.dark,
      color: "#fff",
    },
    "&:active": {
      backgroundColor: theme.fbcolor.purple.dark,
      color: "#fff",
    },
  },
  grey: {
    backgroundColor: theme.fbcolor.grey.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.fbcolor.grey.dark,
      color: "#fff",
    },
    "&:active": {
      backgroundColor: theme.fbcolor.grey.dark,
      color: "#fff",
    },
  },
  primaryOutline: {
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  primaryText: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  secondaryOutline: {
    backgroundColor: "transparent",
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  secondaryText: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.light,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  successOutline: {
    backgroundColor: "transparent",
    color: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.main,
      borderColor: theme.palette.success.main,
    },
    "&:active": {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.main,
      borderColor: theme.palette.success.main,
    },
  },
  successText: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.main,
    borderColor: theme.palette.success.light,
    "&:hover": {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.main,
      borderColor: theme.palette.success.main,
    },
    "&:active": {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.main,
      borderColor: theme.palette.success.main,
    },
  },
  infoOutline: {
    backgroundColor: "transparent",
    color: theme.palette.info.main,
    "&:hover": {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.info.main,
      borderColor: theme.palette.info.main,
    },
    "&:active": {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.info.main,
      borderColor: theme.palette.info.main,
    },
  },
  infoText: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.main,
    borderColor: theme.palette.info.light,
    "&:hover": {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.info.main,
      borderColor: theme.palette.info.main,
    },
    "&:active": {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.info.main,
      borderColor: theme.palette.info.main,
    },
  },
  warningOutline: {
    backgroundColor: "transparent",
    color: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.main,
      borderColor: theme.palette.warning.main,
    },
    "&:active": {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.main,
      borderColor: theme.palette.warning.main,
    },
  },
  warningText: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.light,
    "&:hover": {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.main,
      borderColor: theme.palette.warning.main,
    },
    "&:active": {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.main,
      borderColor: theme.palette.warning.main,
    },
  },
  errorOutline: {
    backgroundColor: "transparent",
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
    "&:active": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
  },
  errorText: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
    "&:active": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
  },
  purpleOutline: {
    backgroundColor: "transparent",
    color: theme.fbcolor.purple.main,
    "&:hover": {
      backgroundColor: theme.fbcolor.purple.light,
      color: theme.fbcolor.purple.main,
      borderColor: theme.fbcolor.purple.main,
    },
    "&:active": {
      backgroundColor: theme.fbcolor.purple.light,
      color: theme.fbcolor.purple.main,
      borderColor: theme.fbcolor.purple.main,
    },
  },
  purpleText: {
    backgroundColor: theme.fbcolor.purple.light,
    color: theme.fbcolor.purple.main,
    borderColor: theme.fbcolor.purple.light,
    "&:hover": {
      backgroundColor: theme.fbcolor.purple.light,
      color: theme.fbcolor.purple.main,
      borderColor: theme.fbcolor.purple.main,
    },
    "&:active": {
      backgroundColor: theme.fbcolor.purple.light,
      color: theme.fbcolor.purple.main,
      borderColor: theme.fbcolor.purple.main,
    },
  },
  greyOutline: {
    backgroundColor: "transparent",
    color: theme.fbcolor.grey.main,
    "&:hover": {
      backgroundColor: theme.fbcolor.grey.light,
      color: theme.fbcolor.grey.main,
      borderColor: theme.fbcolor.grey.main,
    },
    "&:active": {
      backgroundColor: theme.fbcolor.grey.light,
      color: theme.fbcolor.grey.main,
      borderColor: theme.fbcolor.grey.main,
    },
  },
  greyText: {
    backgroundColor: theme.fbcolor.grey.light,
    color: theme.fbcolor.grey.main,
    borderColor: theme.fbcolor.grey.light,
    "&:hover": {
      backgroundColor: theme.fbcolor.grey.light,
      color: theme.fbcolor.grey.main,
      borderColor: theme.fbcolor.grey.main,
    },
    "&:active": {
      backgroundColor: theme.fbcolor.grey.light,
      color: theme.fbcolor.grey.main,
      borderColor: theme.fbcolor.grey.main,
    },
  },
}))

function CustomButton({ children, theme, ...props }) {
  const classes = useStyles()
  return (
    <Button color="inherit" variant="contained" {...props} className={classes[theme]}>
      {children}
    </Button>
  )
}

export default CustomButton
