import { IconButton, ListItem, ListItemText } from "@material-ui/core"
import Chip from "@material-ui/core/Chip"
import { useTheme } from "@material-ui/core/styles"
import { Cancel, ExpandMore } from "@material-ui/icons"
import { capitalizeFirstLetter, isblank } from "@project/sharedcomponents/utilityFunctions"
import React from "react"
import { isMobileOnly } from "react-device-detect"
import { useSelector } from "react-redux"
import CreatableSelect from "react-select/creatable"
import { colourStyles, renderLabel } from "./reactSelectComponent"

const RFCreatableInput = ({
  input,
  options,
  multi,
  helpText,
  helpStyle,
  labelStyle,
  btnText,
  onSecondbtnClick,
  secondbtnText,
  onbtnClick,
  toolTipStyle,
  menuIsOpen,
  disabled,
  className,
  setUnderLine,
  isRequired,
  label,
  meta,
  placeholder,
  onvaluechange,
  dataindex,
  ...props
}) => {
  const { name, onBlur, onChange } = input
  const theme = useTheme()
  const { error, touched } = meta
  const accountSiteSettingKeyValue = useSelector((state) => state.AuthReducer.accountSiteSettingKeyValue)
  const formattedOptions = {}
  if (options !== undefined) {
    options.forEach((obj) => {
      formattedOptions[obj.value] = obj
    })
  }

  if (!isblank(input.value)) {
    input.value = input.value.map((item) => {
      if (typeof item === "string") {
        return {
          label: item,
          value: item,
        }
      }
      return item
    })
  }

  const getSelectTheme = (theme, touched, error) => {
    return {
      /*
       * multiValue(remove)/color:hover
       */
      danger: theme.palette.text.primary,

      /*
       * multiValue(remove)/backgroundColor(focused)
       * multiValue(remove)/backgroundColor:hover
       */
      //dangerLight: theme.palette.grey[200],
      dangerLight: theme.palette.text.primary,

      /*
       * control/backgroundColor
       * menu/backgroundColor
       * option/color(selected)
       */
      // neutral0: theme.palette.background.default,
      neutral0: theme.palette.background.paper,

      /*
       * control/backgroundColor(disabled)
       */
      neutral5: "rgba(239, 239, 239, 0.3)",

      /*
       * control/borderColor(disabled)
       * multiValue/backgroundColor
       * indicators(separator)/backgroundColor(disabled)
       */
      neutral10: "rgba(0, 0, 0, 0.1)",

      /*
       * control/borderColor
       * option/color(disabled)
       * indicators/color
       * indicators(separator)/backgroundColor
       * indicators(loading)/color
       */
      neutral20: Boolean(touched && error) ? theme.palette.error.main : theme.palette.grey["A200"],

      /*
       * control/borderColor(focused)
       * control/borderColor:hover
       */
      // this should be the white, that's normally selected
      neutral30: theme.palette.text.primary,

      /*
       * menu(notice)/color
       * singleValue/color(disabled)
       * indicators/color:hover
       */
      neutral40: "rgba(0, 0, 0, 0.3)",

      /*
       * placeholder/color
       */
      // seen in placeholder text
      neutral50: theme.palette.grey["A200"],

      /*
       * indicators/color(focused)
       * indicators(loading)/color(focused)
       */
      neutral60: theme.palette.text.primary,
      neutral70: theme.palette.text.primary,

      /*
       * input/color
       * multiValue(label)/color
       * singleValue/color
       * indicators/color(focused)
       * indicators/color:hover(focused)
       */
      neutral80: theme.palette.text.primary,

      // no idea
      neutral90: "pink",

      /*
       * control/boxShadow(focused)
       * control/borderColor(focused)
       * control/borderColor:hover(focused)
       * option/backgroundColor(selected)
       * option/backgroundColor:active(selected)
       */
      //primary: theme.palette.text.primary,
      primary: Boolean(touched && error) ? theme.palette.error.main : theme.palette.primary.main,

      /*
       * option/backgroundColor(focused)
       */
      primary25: theme.palette.type == "dark" ? theme.palette.background.default : "#DEEBFF",

      /*
       * option/backgroundColor:active
       */
      primary50: theme.palette.type == "dark" ? theme.palette.background.default : "#B2D4FF",
      primary75: theme.palette.background.paper,
    }
  }

  function multiChangeHandler(func) {
    return function handleMultiHandler(values) {
      if (values != null) {
        func(values.map((value) => value.value))
        if (onvaluechange) {
          onvaluechange(
            dataindex,
            values.map((value) => value.value),
            values.map((value) => value.row)
          )
        }
      } else {
        func([])
        if (onvaluechange) {
          onvaluechange([])
        }
      }
    }
  }

  const NoOptionsMessage = (props) => {
    return (
      <ListItem component="div" dense disablePadding>
        <ListItemText primary="Enter tag and press enter or tab" />
      </ListItem>
    )
  }

  function MultiValue(props) {
    return <Chip size="small" style={{ margin: "4px 2px" }} tabIndex={-1} label={props.children} onDelete={props.removeProps.onClick} deleteIcon={<Cancel {...props.removeProps} />} />
  }

  const noBorder = {}
  if (!(isblank(setUnderLine) || setUnderLine == true)) {
    noBorder.borderWidth = 0
  }

  const handleBlur = (value) => {
    setTimeout(() => {
      onBlur(value)
    }, 1)
  }

  const DropdownIndicator = (props) => {
    const {
      innerProps: { ref, ...restInnerProps },
    } = props
    return (
      <IconButton ref={ref} {...restInnerProps} size="small" tabIndex="-1">
        {" "}
        <ExpandMore fontSize="medium" />
      </IconButton>
    )
  }

  const formThemeColors = getSelectTheme(theme, touched, error)

  return (
    <React.Fragment>
      {renderLabel({
        labelStyle,
        label,
        isRequired,
        theme,
        touched,
        error,
        helpText,
        helpStyle,
        btnText,
        toolTipStyle,
        onSecondbtnClick,
        secondbtnText,
        onbtnClick,
        input,
      })}

      <CreatableSelect
        {...props}
        {...(placeholder && {
          placeholder: capitalizeFirstLetter(placeholder),
        })}
        type="text"
        inputId={name}
        menuPortalTarget={document.body}
        menuShouldBlockScroll={true}
        menuShouldScrollIntoView={true}
        components={{ DropdownIndicator, NoOptionsMessage: NoOptionsMessage, MultiValue }}
        styles={colourStyles(setUnderLine, props.mode, Boolean(touched && error), theme)}
        isDisabled={disabled}
        isMulti={true}
        {...(isMobileOnly && {
          blurInputOnSelect: false,
        })}
        isClearable
        error={Boolean(touched && error)}
        menuPlacement="auto"
        value={!isblank(input.value) ? formattedOptions[input.value] : null}
        onChange={multiChangeHandler(onChange)}
        onBlur={() => handleBlur([...input.value])}
        defaultValue={!isblank(input.value) ? input.value : null} //ADDED FOR EDIT MODE
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...formThemeColors,
          },
        })}
      />
    </React.Fragment>
  )
}

export default RFCreatableInput
