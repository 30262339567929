import { AppBar, Box, Button, Chip, Grid, Hidden, Link, Paper, Snackbar, Toolbar, Typography, makeStyles, useTheme } from "@material-ui/core"
import { LinkOff } from "@material-ui/icons"
import fblogo from "@project/components/Images/FinalBooks_Logo.png"
import { showError } from "@project/components/showErrorFunctions"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import { isblank } from "@project/sharedcomponents/utilityFunctions"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { authsnackbarstate, checkalreadylogin, getfbfinancialyear, loginUser, snackbarstate, socialLogin } from "../../Actions"
import ForgotPassword from "./ForgotPassword"
import "./login.css"
import LoginSlider from "./LoginSlider"
import { getStorage, removeStorage } from "@project/components/sessionStoreFunctions"
import Field from "../common/reduxFormComponents"

const useStyles = makeStyles((theme) => ({
  loginBox: {
    maxWidth: 380,
    flexGrow: 1,
  },
  loginCont: {
    padding: 24,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto",
  },
}))

var Fyear = ""

const Login = ({ isDisconnected }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()
  const { loading, financialyears, Snackbarerror, Snackbarerrormessage } = useSelector((state) => state.AuthReducer)
  const [companyName, setCompanyName] = useState("")
  const [passwordShow, setPasswordShow] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  // const [googleData, setGoogleData] = useState("")
  const [socialClicked, setSocialClicked] = useState(false)
  const [googleConnected, setGoogleConnected] = useState(false)
  const [pageType, setPageType] = useState("login")

  const full = window.location.host
  const parts = full.split(".")
  const subdomain = parts[0]

  const defaultValues = {
    userName: "",
    password: "",
    yearSelectedValue: "",
    social_acc_id: "",
    social_connect_flag: "",
    fbmoduleType: "accounting",
  }
  const { handleSubmit, control, setValue } = useForm({ defaultValues })

  const handleSign = (e) => {
    e.preventDefault()
    document.getElementById("loginButton").focus()
    setTimeout(() => handleSubmit(onSubmitData, onSubmitError)(), 100)
  }

  const onSubmitData = (values) => {
    const { userName, password, yearSelectedValue, fbmoduleType, social_acc_id, social_connect_flag } = values
    const year = yearSelectedValue
    const email = userName
    const companycode = subdomain
    const module_type = fbmoduleType
    dispatch(loginUser({ email, password, companycode, year, module_type, social_acc_id, social_connect_flag }))
  }

  const onSubmitError = (values) => {
    const err = Object.keys(values)
    dispatch(authsnackbarstate(true, values[err[0]].message, "error"))
    setTimeout(function () {
      document.getElementsByName(err[0])[0].focus()
    }, 100)
  }

  const switchPage = (type) => {
    setPageType(type)
  }

  const getLoginFromSocial = (id, flag) => {
    const errors = {}
    if (!Fyear) {
      errors["yearSelectedValue"] = ErrorMessages["E00082"]
    }
    if (Object.keys(errors).length !== 0) {
      showError({ errors, dispatch, props: { snackbarstate } })
    } else {
      const financial_year = Fyear
      dispatch(socialLogin({ year: financial_year, company_url: subdomain, social_acc_id: id, social_connect_flag: flag }))
    }
  }

  const clearSocial = () => {
    setSocialClicked(false)
    setGoogleConnected(false)
    setValue("social_acc_id", "")
    setValue("social_connect_flag", "")
  }

  const handleErrorClose = () => {
    dispatch(authsnackbarstate(false, ""))
  }

  const onvaluechange = (username) => {
    if (!isblank(username)) {
      if (passwordShow !== true) {
        setPasswordShow(true)
      }
    } else {
      setPasswordShow(false)
    }
  }

  const clearLocalStorage = () => {
    var companyLoginYears = getStorage("companyLoginYears")
    if (isblank(companyLoginYears)) {
      companyLoginYears = []
    }
    companyLoginYears.forEach((year) => {
      removeStorage(`auth_token_${year}`)
      removeStorage(`user_data_${year}`)
      removeStorage(`site_setting_${year}`)
      removeStorage(`user_rights_${year}`)
      removeStorage(`userModuleRights_${year}`)
      removeStorage(`lastTransDate_${year}`)
      removeStorage(`menu_data_${year}`)
      removeStorage(`report_data_${year}`)
      removeStorage(`company_${year}`)
      removeStorage(`config_setting_${year}`)
      removeStorage(`customPages_${year}`)
      removeStorage(`last_visited_page_${year}`)
      removeStorage(`last_visited_page_data_${year}`)
    })

    removeStorage("firm_years")
    removeStorage("last_visited_page")
    removeStorage("last_visited_page_data")
    removeStorage("last_menu_key")
  }

  useEffect(() => {
    const onCheckComplete = (check) => {
      setPageLoading(false)
      if (check === false) {
        clearLocalStorage()
      }
    }
    dispatch(checkalreadylogin({ isloginPage: true, onCheckComplete }))
    const onSuccess = (response) => {
      setCompanyName(response.company_detail.name)
      if (response.years.length > 0) {
        setValue("yearSelectedValue", response.years[0].id)
        Fyear = response.years[0].id
      }
    }
    dispatch(getfbfinancialyear(subdomain, onSuccess))

    // window.addEventListener("message", function (e) {
    //   if (e.data) {
    //     if (e.data.source === "react-devtools-content-script" || e.data.source === "react-devtools-bridge") {
    //       return true
    //     }
    //     if (!socialClicked) {
    //       setSocialClicked(true)
    //       const data = JSON.parse(e.data)
    //       getLoginFromSocial(data?.response?.sub, "google")
    //     }
    //   }
    // })
  }, [])

  if (pageLoading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <Grid container style={{ height: "100vh" }}>
        <Hidden smDown>
          <Grid item md={8} xs={12} style={{ height: "100%" }}>
            <Box className={classes.loginCont}>
              <Box style={{ flexGrow: 1, maxWidth: 700 }}>
                {isDisconnected && (
                  <Typography variant="subtitle1" gutterBottom style={{ color: theme.palette.error.main, textAlign: "center" }}>
                    Check your internet connection.
                  </Typography>
                )}
                <Typography variant="h5">Welcome, {companyName && <span style={{ fontWeight: "bold" }}>{companyName}</span>}</Typography>
                <Box mt={2}>
                  <LoginSlider />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Hidden>
        <Grid item md={4} xs={12} style={{ height: "100%" }}>
          <Paper className={classes.loginCont} square elevation={2}>
            <Box className={classes.loginBox}>
              <div>
                <img src={fblogo} alt="FinalBooks" height="50" />
              </div>

              {(() => {
                switch (pageType) {
                  case "login":
                    return (
                      <Box>
                        <AppBar position="static" color="transparent">
                          <Toolbar disableGutters>
                            <Typography variant="h5" style={{ flexGrow: 1 }}>
                              Sign in
                            </Typography>
                            <Typography color="primary" variant="subtitle2">
                              v{process.env.REACT_APP_VERSION}
                            </Typography>
                          </Toolbar>
                        </AppBar>
                        {/* <Typography variant="subtitle2" gutterBottom>
                          Scan QR Code to{" "}
                          <Link component="button" onClick={() => switchPage("qrcode")} variant="subtitle2">
                            Sign in
                          </Link>
                        </Typography> */}
                        {googleConnected && (
                          <Box>
                            <Chip style={{ marginBottom: 4 }} icon={<LinkOff />} label="Google connected as Demo" color="primary" onDelete={clearSocial} />
                            <div>
                              <Typography variant="caption">Enter your username/password of FinalBooks account and sign in once to connect your google account.</Typography>
                            </div>
                          </Box>
                        )}
                        <form onSubmit={handleSign}>
                          <Field
                            name="userName"
                            inputType={"RenderTextField"}
                            control={control}
                            label="Username"
                            autoFocus={true}
                            onvaluechange={onvaluechange}
                            placeholder="Enter username"
                            isRequired={true}
                            rules={{ required: ErrorMessages.E00541 }}
                          />
                          {passwordShow && (
                            <Field
                              name="password"
                              inputType={"RenderTextField"}
                              control={control}
                              label="Password"
                              type="password"
                              placeholder="Enter password"
                              isRequired={true}
                              rules={{ required: ErrorMessages.E00541 }}
                            />
                          )}
                          <Field
                            name="yearSelectedValue"
                            inputType={"RFReactSelect"}
                            control={control}
                            label="Financial Year"
                            placeholder="Select financial year"
                            options={financialyears.map(function (value) {
                              return { value: value.id, label: value.name, row: value }
                            })}
                          />
                          <Box mt={2} mb={2}>
                            <Button id="loginButton" type="submit" fullWidth disabled={loading} variant="contained" color="primary">
                              Sign in
                            </Button>
                          </Box>
                        </form>

                        <Box mt={2} mb={2}>
                          <Link component="button" onClick={() => switchPage("forgot")} variant="subtitle2">
                            Forgot password
                          </Link>
                        </Box>

                        {!googleConnected && (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "production") && (
                          <React.Fragment>
                            <div style={{ textAlign: "center", marginTop: 8, marginBottom: 8 }}>
                              <Typography gutterBottom>Or</Typography>
                              <iframe title="google_login_button" id="frame" src={process.env.REACT_APP_CONNECT_APP_URL} style={{ width: 290, height: 50, border: 0 }} />
                            </div>
                          </React.Fragment>
                        )}
                      </Box>
                    )
                  // case "qrcode":
                  //   return <ScanQrCode switchPage={switchPage} company_url={subdomain} />
                  case "forgot":
                    return <ForgotPassword switchPage={switchPage} company_url={subdomain} />
                  case "signup":
                    return <h2>Sign Up</h2>
                  default:
                    return <h2>Not Found</h2>
                }
              })()}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Snackbarerror ? true : false}
        onClose={handleErrorClose}
        autoHideDuration={3000}
        snackbarcontentprops={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{Snackbarerrormessage}</span>}
      />
    </>
  )
}

export default Login
