import { convertIntif, getNumberonly } from "@project/sharedcomponents/utilityFunctions"
import {
  WIRETRANSFER_BENEFICIARY_SEARCH_TEXT_UPDATE,
  WIRETRANSFER_BENEFICIARY_SERVICE_DELETED,
  WIRETRANSFER_BENEFICIARY_SERVICE_FAIL,
  WIRETRANSFER_BENEFICIARY_SERVICE_INSERTED,
  WIRETRANSFER_BENEFICIARY_SERVICE_START,
  WIRETRANSFER_BENEFICIARY_SERVICE_SUCCESS,
  WIRETRANSFER_BENEFICIARY_SERVICE_UPDATED,
} from "../Actions/types"

const INITIAL_STATE = {
  current_tab_index: 0,
  error: "",
  loading: false,
  data: [],
  totalcount: 0,
  rowsPerPage: 0,
  page: 0,
  order: "asc",
  norecord: false,
  deleted: false,
  orderBy: "beneficiary_ac_no",
  search_text: "",
  columnData: [
    { id: "beneficiary_ac_no", numeric: false, visible: true, label: "Account Number", width: 200 },
    { id: "beneficiary_ac_type", numeric: false, visible: true, label: "Type", width: 100 },
    { id: "beneficiary_ac_holder", numeric: false, visible: true, label: "Account Holder" },
    { id: "receiving_bank_name", numeric: false, visible: true, label: "Bank Name", width: 150 },
    { id: "receiving_branch_name", numeric: false, visible: true, label: "Branch Name", width: 150 },
    { id: "receiving_branch_ifscode", numeric: false, visible: true, label: "Branch IFS Code", width: 200 },
  ],
  metadata: "",
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WIRETRANSFER_BENEFICIARY_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case WIRETRANSFER_BENEFICIARY_SEARCH_TEXT_UPDATE:
      return { ...state, search_text: action.search_text, norecord: false }

    case WIRETRANSFER_BENEFICIARY_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.result,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        islogin: true,
        norecord: action.payload.data.result.length === 0,
      }
    case WIRETRANSFER_BENEFICIARY_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        data: [],
        loading: false,
        totalcount: 0,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
        norecord: true,
      }

    case WIRETRANSFER_BENEFICIARY_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_code
      inserteditem.beneficiary_ac_no = action.payload.beneficiaryAccountNumber
      inserteditem.beneficiary_ac_type = action.payload.beneficiaryAccountType
      inserteditem.beneficiary_ac_holder = action.payload.beneficiaryAccountHolder
      inserteditem.receiving_bank_name = action.payload.receivingBankName
      inserteditem.receiving_branch_ifscode = action.payload.receivingBranchIFSCode
      inserteditem.receiving_branch_name = action.payload.receivingBranchName
      return { ...state, data: [inserteditem, ...state.data] }
    case WIRETRANSFER_BENEFICIARY_SERVICE_DELETED:
      return { ...state, data: state.data.filter((item) => convertIntif(item.code) !== convertIntif(action.payload)), deleted: !state.deleted, totalcount: parseInt(getNumberonly(state.totalcount)) - 1 }
    case WIRETRANSFER_BENEFICIARY_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.update_code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.beneficiary_ac_no = action.payload.beneficiaryAccountNumber
        updateditem.beneficiary_ac_type = action.payload.beneficiaryAccountType
        updateditem.beneficiary_ac_holder = action.payload.beneficiaryAccountHolder
        updateditem.receiving_bank_name = action.payload.receivingBankName
        updateditem.receiving_branch_ifscode = action.payload.receivingBranchIFSCode
        updateditem.receiving_branch_name = action.payload.receivingBranchName
        return updateditem
      })

      return { ...state, data: updateddata }
    default:
      return state
  }
}
