import { getStorage, setStorage } from "@project/components/sessionStoreFunctions"
import {
  getAllListViewType,
  getAllPromoOption,
  getAllStatus,
  getdropdownvalue,
  multiDelete,
  multiDeleteRecent,
  setColumnDataFilterWise,
  setOrderFilterWise,
} from "@project/components/utilityFunctions"
import cF from "@project/sharedcomponents/constantData/customFieldsEnum"
import {
  cloneObj,
  convertIntif,
  generateDisplayAddress,
  getAllEstimateStatus,
  getAllOrderStatus,
  getCheckBoxPostValue,
  getDueStatus,
  getNumberonly,
  getdropdownfieldvalue,
  getewaybillstatus,
  getnotetypeWithVoucher,
  isblank,
  replaceAllFn,
  setFiledDateToolTip,
} from "@project/sharedcomponents/utilityFunctions"
import { isMobileOnly } from "react-device-detect"
import {
  ADD_RECENT_ITEM,
  ADD_RECENT_ALLACCOUNT,
  BILL_SERVICE_FAIL,
  BILL_SERVICE_SUCCESS,
  CASH_BANK_DETAIL_SUCCESS,
  CLEAR_DROPDOWNUPDATED,
  CLOSE_ALL_DRAWER,
  CLOSE_DRAWER,
  CLOSING_BALANCE,
  COMMON_SEARCH_TEXT_UPDATE,
  COMMON_SERVICE_ARRAY_INSERTED,
  COMMON_SERVICE_ARRAY_UPDATED,
  COMMON_SERVICE_CLEAR,
  COMMON_SERVICE_DELETED,
  COMMON_SERVICE_FAIL,
  COMMON_SERVICE_INSERTED,
  COMMON_SERVICE_START,
  COMMON_SERVICE_SUCCESS,
  COMMON_SERVICE_UPDATED,
  CONFIRM_DIALOG,
  CUSTOMFIELD_OPTION_DELETED,
  CUSTOMFIELD_OPTION_INSERTED,
  CUSTOMFIELD_OPTION_UPDATED,
  CUSTOM_DIALOG,
  CUSTOM_PAGE_DROPDOWN_SUCCESS,
  CUSTOM_PAGE_DROPDOWN_UPDATE,
  DIALOG_LOADING,
  DIALOG_REASON_UPDATE,
  DRAWER_CONFIRM_DIALOG,
  DRAWER_DIALOG_LOADING,
  DROPDOWN_INSERTED,
  DROPDOWN_ITEM_DELETED,
  DROPDOWN_ITEM_INSERTED,
  DROPDOWN_ITEM_UPDATED,
  ESTIMATE_SERVICE_CONVERTED,
  E_INVOICE_UPDATE,
  E_WAY_BILL_UPDATE,
  FORM_CONFIRM_DIALOG,
  FORM_DEP_SERVICE_CLEAR,
  FORM_DEP_SERVICE_FAIL,
  FORM_DEP_SERVICE_START,
  FORM_DEP_SERVICE_SUCCESS,
  FORM_DEP_SERVICE_SUCCESS_WITHOUTLOADING,
  FORM_DIALOG_LOADING,
  FORM_DRAWER_STATE,
  FORM_POST_SERVICE_CLEAR,
  FORM_POST_SERVICE_FAIL,
  FORM_POST_SERVICE_START,
  FORM_POST_SERVICE_SUCCESS,
  FORM_POST_UPDATE,
  INVOICE_SERVICE_CONVERTED,
  ITEM_STATUS_UPDATE,
  LAST_PAGE_UPDATE,
  LOADING_SNACKBAR_STATE,
  LOADING_STATE,
  MENUFROMSESSION,
  OPEN_DRAWER,
  PAGE_FORM_POST_SERVICE_CLEAR,
  PAGE_FORM_POST_SERVICE_FAIL,
  PAGE_FORM_POST_SERVICE_START,
  PAGE_FORM_POST_SERVICE_SUCCESS,
  PAGE_FORM_POST_UPDATE,
  POP_OVER_LOADING_STATE,
  POP_OVER_UPDATED,
  POST_FILTER_SERVICE_FAIL,
  POST_FILTER_SERVICE_START,
  POST_FILTER_SERVICE_SUCCESS,
  PRODUCT_BATCH_SERVICE_SUCCESS,
  PRODUCT_SERIAL_SERVICE_SUCCESS,
  PROGRESS_LOADING_STATE,
  PUR_BILL_SERVICE_FAIL,
  PUR_BILL_SERVICE_SUCCESS,
  PUSH_DRAWER,
  PUSH_SHORTCUT_DRAWER,
  REMOVE_DRAWER,
  RESET_COMMON_REDUCER,
  RIGHT_DRAWER_STATE,
  SETMENU,
  SET_ALL_LAST_DATE,
  SET_COUNTRY_ALL_STATE_FOR_ONBOARDING,
  SET_EXPAND_VALUE,
  SET_FILTER_DATA,
  SET_LAST_DATE,
  SET_LAST_DRAFT,
  SET_LAST_MENU_KEY,
  SET_SETTING,
  SNACKBAR_STATE,
  UPDATERIGHT_DRAWER_STATE,
  UPDATE_DRAWER,
  UPDATE_FILTER_DATA,
  UPDATE_FORM_DRAWER_STATE,
  UPDATE_OTHER_DETAIL,
  UPDATE_REFRESH_DRAWER_STACK,
  UPDATE_RIGHT_DRAWER_STATE,
  UTILITY_POST_SERVICE_START,
  UTILITY_POST_SERVICE_SUCCESS,
  WINDOW_DIMENSION_UPDATED,
} from "../Actions/types"

const INITIAL_STATE = {
  customDialogVisible: false,
  customDialogTitle: "",
  customDialogContent: "",
  customDialogButtons: [],
  customDialogDismissLabel: "",
  filterData: {},
  showInList: [],
  ddsalesbill: [],
  ddpurbill: [],
  drawerStack: [],

  dropdownupdated: "",
  rightdrawerStack: [],
  rightdrawerdata: "",
  rightdrawerfieldname: "",
  rightdrawerposition: "right",
  rightdrawerwidth: 400,
  rightdrawerheight: "100vh",
  rightdrawer: false,
  rightdrawerheader: "",
  expandDrawer: false,
  last_menu_key: "",
  progress_loading: 0,
  progress_loading_title: "",

  loading: false,
  sidebarloading: false,
  popOverloading: false,

  drawerZIndexData: [],

  formdrawerdata: "",
  formdrawerfieldname: "",
  formdrawerposition: "bottom",
  formdrawerwidth: "",
  formdrawerheight: "100vh",
  formdrawer: false,
  formdrawerheader: "",
  otherDetail: "",

  windowHeight: 0,
  windowWidth: 0,

  draftData: "",

  lastpagetype: "",
  lastpageaction: "",
  lastpagedata: "",
  lastpagetime: "",
  lastpagemeta: {},
  siteSettingDefaultValue: false,

  filter_data: [],

  loadingSnackbar: false,
  loadingSnackbarType: "warning",
  loadingSnackbarMessage: "",

  closingbalance: "",
  SnackbarOriginVertical: "top",
  SnackbarOriginHorizontal: "right",
  Snackbarerror: false,
  SnackbarType: "info",
  Snackbarerrormessage: "",
  SnackbarAutoHide: true,
  ConfirmDialog: false,
  onConfirm: "",
  ConfirmDialogMessage: "",
  ConfirmDialogTitle: "",
  ConfirmDialogForm: "",
  ConfirmDialogData: "",
  Confirm: false,
  ConfirmDialogLoading: false,
  ConfirmNo: false,

  FormConfirmDialog: false,
  FormConfirmDialogMessage: "",
  FormConfirmDialogTitle: "",
  FormConfirmDialogForm: "",
  FormConfirmDialogData: "",
  FormConfirmDialogLoading: false,
  FormConfirm: false,

  DrawerConfirmDialog: false,
  DrawerConfirmDialogMessage: "",
  DrawerConfirmDialogTitle: "",
  DrawerConfirmDialogForm: "",
  DrawerConfirmDialogData: "",
  DrawerConfirmDialogLoading: false,
  DrawerConfirm: false,

  deploading: false,
  filterloading: false,
  bankCashDetail: "",

  dialogReason: "",

  last_receiptdate: "",
  last_paymentdate: "",
  last_recordexpensedate: "",
  last_journalentrydate: "",
  last_salesinvoicedate: "",
  last_purchaseinvoicedate: "",
  last_salesreturndate: "",
  last_purchasereturndate: "",

  dropdown_string: "",
  dd_draft: [],
  subgroup: [],
  ddcustomer: [],
  ddsupplier: [],
  ddcustomer_supplier: [],
  ddlot_party: [],
  dd_accountPackage: [],
  ddagent: [],
  ddtransport: [],
  ddtaxscheme: [],
  dditem: [],
  dd_invoice_template: [],
  ddother_account: [],
  ddaccountgroup: [],
  ddcreditnote: [],
  dddebitnote: [],
  ddstate: [],
  ddcity: [],
  dditemgroup: [],
  ddunit: [],
  ddopbal: [],
  ddcompany: [],
  ddbankaccount: [],
  ddexpenseaccount: [],
  ddtradename: [],
  ddallaccount: [],
  ddsalesaccount: [],
  ddpurchaseaccount: [],
  ddsalesreturnaccount: [],
  ddpurchasereturnaccount: [],
  ddaccounttype: [],
  dduser: [],
  dddropdown_closingstock: [],
  ddtaxgroup: "",
  ddsmscategory: [],
  ddactivity_type: [],
  ddcostcenter: [],
  menu_data: [],
  all_menu: [],
  all_menuSet: false,
  ddtaxschemeaccount: [],
  dd_taxcode: [],
  ddpartygroup: [],
  ddsender: [],
  ddbeneficiary: [],
  ddIPOStockparty: [],
  ddproductbatch: [],
  ddproductserial: [],
  ddcustomfield: [],
  ddcustomPage: [],
  ddpructsubgroup: [],
  ddpructsubgrouplevel2: [],
  ddgodown: [],
  ddlabelsetting: [],
  ddprocess: [],
  ddlot: [],
  ddtcs: [],
  dd_allcountryForOnboarding: [],
  dd_country_state: [],
  countryallstateForOnboarding: [],
  dd_item_with_configitem: [],
  dd_tdspayee: [],
  dd_tdscategory: [],
  dd_bottransaction: [],
  dd_bank: [],
  dd_envelope_template: [],
  dd_cheque_template: [],
  dd_orderTypes: [],
  dd_plan: [],
  barcodeTemplate: [],
  dd_whatsapp_template: [],
  dd_email_template: [],
  dd_sms_template: [],
  dd_custom_page_dropdown: {},
  refreshDrawerStack: 0,
  webhook_event_type: [],
  field_formatter: [],

  common_error: "",
  common_data: [],
  common_totalcount: 0,
  common_rowsPerPage: 0,
  common_page: 0,
  common_order: "",
  common_orderBy: "",
  common_formtype: "",
  common_search_text: "",
  common_norecord: false,
  common_deleted: false,
  common_columnData: [],
  recentItems: [],
  recentAllAccount: [],
}

const botColumnData = [
  { id: "name", numeric: false, visible: true, label: "Name" },
  { id: "description", numeric: false, visible: true, label: "Description", width: 250 },
  { id: "type", numeric: false, visible: true, label: "Type", width: 150 },
  {
    id: "status",
    numeric: false,
    visible: true,
    label: "Status",
    width: 75,
    badge: {
      Active: { color: "green" },
      InActive: { color: "red" },
    },
  },
]

const catalogueUserColumnData = [
  { id: "name", numeric: false, visible: true, label: "Business Name" },
  { id: "fb_name", numeric: false, visible: true, label: "Linked Account" },
  { id: "phone", numeric: true, visible: true, label: "Phone No", width: 125 },
  { id: "email", numeric: false, visible: true, label: "Email", width: 200 },
  { id: "abd_date", numeric: false, visible: true, label: "Date", width: 200, format: "date_time" },
  { id: "lastLoginDate", numeric: false, visible: true, label: "Last Login", format: "date_time" },
  { id: "totalTime", numeric: false, visible: true, label: "Time Remaining", isCountDown: true, width: 150 },
  {
    id: "status",
    numeric: false,
    visible: true,
    label: "Status",
    width: 150,
    badge: {
      Allow: { color: "green" },
      "Not allow": { color: "red" },
    },
    showlink: true,
  },
  { id: "type", numeric: false, visible: true, label: "Type", width: 125 },
]

const dataSetInListPurchase = (result) => {
  return result.map((item, index) => {
    let updateditem = { ...item }
    if (updateditem.invoice_type === "purchase_order") {
      updateditem.status = item.status === "0" ? "Pending" : item.status === "1" ? "Partially" : "Closed"
    }
    updateditem.adjust_status = item.adjust_status === "0" ? "Unpaid" : item.adjust_status === "1" ? "Partially Paid" : item.adjust_status === "2" ? "Paid" : "Unpaid"
    updateditem.gstr1_filed_date = setFiledDateToolTip(item, "gstr1")

    updateditem.gstr3B_filed_date = setFiledDateToolTip(item, "gstr3b")
    updateditem.gstr3B_filed = item.gstr3B_filed === "1" ? "Filed" : "Not Filed"

    updateditem.gstr2_filed_date = setFiledDateToolTip(item, "gstr2")
    updateditem.gstr2_filed = item.gstr2_filed === "1" ? "Filed" : "Not Filed"
    updateditem = getDueStatus(updateditem)
    return updateditem
  })
}

const dataSetInListItem = (data) => {
  return data.map((item, index) => {
    const updateditem = { ...item }
    // updateditem.itemtype = getdropdownfieldvalue(getsessionuserdata("account_type") === aTP.Gold ? getproducttypegold("", "reducer") : getproducttype(), "id", item.itemtype, "name") // account_type === "8" GOLD ACCOUNT
    // updateditem.show_in_gmc = updateditem.show_in_gmc === "1" ? "Yes" : "No"
    updateditem.inactive = updateditem.inactive === "1" ? "Inactive" : "Active"
    return updateditem
  })
}

const dataSetInGodown = (data) => {
  return data.map((item, index) => {
    const updateditem = { ...item }
    updateditem.status = updateditem.status === true ? "Primary" : ""
    return updateditem
  })
}

const dataSetInTrn = (data) => {
  return data.map((item, index) => {
    const updateditem = { ...item }
    updateditem.newCode = `${updateditem.code}_${index}`
    return updateditem
  })
}

const dataSetInBot = (data) => {
  return data.map((item, index) => {
    const updateditem = { ...item }
    updateditem.status = updateditem.status === "1" ? "Active" : "InActive"
    return updateditem
  })
}

const dataSetInEwayBill = (data, invoice_type_detail) => {
  return data.map((item, index) => {
    const updateditem = { ...item }
    if (updateditem.invoice_type === "salesreturn") {
      updateditem.invoice_type = "Credit Note"
    } else if (updateditem.invoice_type === "purchasereturn") {
      updateditem.invoice_type = "Debit Note"
    } else {
      updateditem.invoice_type = invoice_type_detail.filter((item) => item.id === updateditem.invoice_type)[0].name
    }

    if (!isblank(updateditem.irn)) {
      updateditem.generateEwayBill = true
    }

    updateditem.ewaybill_status = getdropdownfieldvalue({ dropDownData: getewaybillstatus(), field: "id", value: updateditem.ewaybill_status, displayvalue: "name" })
    return updateditem
  })
}

const dataSetInList = (result) => {
  return result.map((item, index) => {
    var updateditem = { ...item }
    if (updateditem.invoice_type === "sales_order") {
      updateditem.status = item.status === "0" ? "Pending" : item.status === "1" ? "Partially" : "Closed"
    } else if (updateditem.invoice_type === "estimate") {
      updateditem.status = getdropdownfieldvalue({ dropDownData: getAllEstimateStatus(), field: "id", value: item.status, displayvalue: "name" })
    } else if (updateditem.invoice_type === "getmystore" || updateditem.invoice_type === "getmycatalogue") {
      updateditem.status = getdropdownfieldvalue({ dropDownData: getAllOrderStatus(), field: "id", value: item.status, displayvalue: "name" })
    }
    if (updateditem.invoice_type === "getmystore" && updateditem.name === "Guest Account") {
      updateditem.name = `${updateditem.name} - ${updateditem.guest_name} - ${updateditem.guest_mobile}`
    }
    updateditem.adjust_status = item.adjust_status === "0" ? "Unpaid" : item.adjust_status === "1" ? "Partially Paid" : item.adjust_status === "2" ? "Paid" : "Unpaid"
    updateditem.e_invoice_status = getdropdownfieldvalue({ dropDownData: getewaybillstatus(), field: "id", value: updateditem.e_invoice_status, displayvalue: "name" })
    if (updateditem.e_invoice_status === "Generated") {
      updateditem.generateEwayBill = true
    }
    updateditem.gstr1_filed_date = setFiledDateToolTip(item, "gstr1")
    updateditem.gstr1_filed = item.gstr1_filed === "1" ? "Filed" : "Not Filed"
    updateditem.gstr3B_filed_date = setFiledDateToolTip(item, "gstr3b")
    updateditem.gstr3B_filed = item.gstr3B_filed === "1" ? "Filed" : "Not Filed"
    updateditem.gstr2_filed_date = setFiledDateToolTip(item, "gstr2")
    updateditem.gstr2_filed = item.gstr2_filed === "1" ? "Filed" : "Not Filed"
    updateditem.ewaybill_status = getdropdownfieldvalue({ dropDownData: getewaybillstatus(), field: "id", value: updateditem.ewaybill_status, displayvalue: "name" })
    updateditem = getDueStatus(updateditem)
    return updateditem
  })
}

const dataSetInListIO = (result) => {
  return result.map((item, index) => {
    const updateditem = { ...item }
    if (updateditem.hasOwnProperty("ps_status") && !isblank(updateditem.ps_status)) {
      updateditem.ps_status = updateditem.ps_status === "2" ? "Closed" : updateditem.ps_status === "1" ? "Archive" : "Pending"
    }
    updateditem.ewaybill_status = getdropdownfieldvalue({ dropDownData: getewaybillstatus(), field: "id", value: updateditem.ewaybill_status, displayvalue: "name" })
    return updateditem
  })
}

const dataSetInListSPReturn = (result) => {
  return result.map((item, index) => {
    const updateditem = { ...item }
    updateditem.invoice_type = getdropdownfieldvalue({ dropDownData: getnotetypeWithVoucher(), field: "id", value: item.invoice_type, displayvalue: "name" })
    updateditem.gstr1_filed_date = setFiledDateToolTip(item, "gstr1")
    updateditem.gstr1_filed = item.gstr1_filed === "1" ? "Filed" : "Not Filed"

    updateditem.gstr2_filed = item.gstr2_filed === "1" ? "Filed" : "Not Filed"
    updateditem.gstr2_filed_date = setFiledDateToolTip(item, "gstr2")

    updateditem.gstr3B_filed = item.gstr3B_filed === "1" ? "Filed" : "Not Filed"
    updateditem.gstr3B_filed_date = setFiledDateToolTip(item, "gstr3b")

    updateditem.e_invoice_status = getdropdownfieldvalue({ dropDownData: getewaybillstatus(), field: "id", value: updateditem.e_invoice_status, displayvalue: "name" })
    if (updateditem.e_invoice_status === "Generated") {
      updateditem.generateEwayBill = true
    }
    updateditem.ewaybill_status = getdropdownfieldvalue({ dropDownData: getewaybillstatus(), field: "id", value: updateditem.ewaybill_status, displayvalue: "name" })
    return updateditem
  })
}

const dataSetInListContact = (result) => {
  return result.map((item) => {
    const updatedItem = { ...item }
    if (item.status === "10") {
      updatedItem.status = "Waiting"
    } else {
      updatedItem.status = getdropdownfieldvalue({ dropDownData: getAllStatus(), field: "id", value: item.status, displayvalue: "name" })
    }
    updatedItem.statusValue = item.status
    if (updatedItem.type === "C") {
      updatedItem.type = "Customer"
    } else {
      updatedItem.type = "Visitor"
    }
    return updatedItem
  })
}

export default (state = INITIAL_STATE, action) => {
  var ddtaxgroup = state.ddtaxgroup
  var ddtaxscheme = state.ddtaxscheme
  var ddtcs = state.ddtcs

  const setZIndex = (type, drawer, title) => {
    var drawerZIndexData = state.drawerZIndexData
    if (type === "add") {
      drawerZIndexData.push(title)
    } else {
      if (drawer === "right") {
        if (drawerZIndexData.length > 0 && drawerZIndexData[drawerZIndexData.length - 1] === state.rightdrawerheader) {
          drawerZIndexData.pop()
        }
      } else {
        if (drawerZIndexData.length > 0 && drawerZIndexData[drawerZIndexData.length - 1] === state.formdrawerheader) {
          drawerZIndexData.pop()
        }
      }
    }
    return drawerZIndexData
  }

  const setDependency = ({ sidebarloading, loading, deploading, action }) => {
    let filterData = { ...state.filterData }
    if (!isblank(action.payload.data)) {
      Object.keys(action.payload.data).forEach((e) => {
        if (e.indexOf("filter_") > -1) {
          filterData[e] = action.payload.data[e]
        }
      })
    }
    return {
      ...state,
      filterData,
      dropdown_string: action.dropdown_string,
      ddcustomer: !isblank(action.payload.data.customer) ? action.payload.data.customer : state.ddcustomer,
      subgroup: !isblank(action.payload.data.subgroup) ? action.payload.data.subgroup : state.subgroup,
      ddsupplier: !isblank(action.payload.data.supplier) ? action.payload.data.supplier : state.ddsupplier,
      ddcustomer_supplier: !isblank(action.payload.data.customer_supplier) ? action.payload.data.customer_supplier : state.ddcustomer_supplier,
      ddlot_party: !isblank(action.payload.data.lot_party) ? action.payload.data.lot_party : state.ddlot_party,
      dd_accountPackage: action.payload.data.hasOwnProperty("dd_accountPackage") ? action.payload.data.dd_accountPackage : state.dd_accountPackage,
      ddagent: !isblank(action.payload.data.agent) ? action.payload.data.agent : state.ddagent,
      ddtransport: !isblank(action.payload.data.transport) ? action.payload.data.transport : state.ddtransport,
      ddtaxscheme: !isblank(action.payload.data.taxscheme) ? action.payload.data.taxscheme : state.ddtaxscheme,
      dditem: !isblank(action.payload.data.item) ? action.payload.data.item : state.dditem,
      dd_item_with_configitem: !isblank(action.payload.data.dd_item_with_configitem) ? action.payload.data.dd_item_with_configitem : state.dd_item_with_configitem,
      dd_invoice_template: !isblank(action.payload.data.dd_invoice_template) ? action.payload.data.dd_invoice_template : state.dd_invoice_template,
      ddother_account: !isblank(action.payload.data.other_account) ? action.payload.data.other_account : state.ddother_account,
      ddaccountgroup: !isblank(action.payload.data.accountgroup) ? action.payload.data.accountgroup : state.ddaccountgroup,
      ddcreditnote: !isblank(action.payload.data.creditnote) ? action.payload.data.creditnote : state.ddcreditnote,
      dddebitnote: !isblank(action.payload.data.debitnote) ? action.payload.data.debitnote : state.dddebitnote,
      ddstate: !isblank(action.payload.data.state) ? action.payload.data.state : state.ddstate,
      ddcity: !isblank(action.payload.data.city) ? action.payload.data.city : state.ddcity,
      ddsender: !isblank(action.payload.data.dropdown_wiresender) ? action.payload.data.dropdown_wiresender : state.ddsender,
      ddbeneficiary: !isblank(action.payload.data.dropdown_beneficiary) ? action.payload.data.dropdown_beneficiary : state.ddbeneficiary,
      dditemgroup: !isblank(action.payload.data.itemgroup) ? action.payload.data.itemgroup : state.dditemgroup,
      ddunit: !isblank(action.payload.data.unit) ? action.payload.data.unit : state.ddunit,
      ddopbal: !isblank(action.payload.data.opbal) ? action.payload.data.opbal : state.ddopbal,
      ddcompany: !isblank(action.payload.data.company) ? action.payload.data.company : state.ddcompany,
      ddbankaccount: !isblank(action.payload.data.bankaccount) ? action.payload.data.bankaccount : state.ddbankaccount,
      dd_bank: !isblank(action.payload.data.dd_bank) ? action.payload.data.dd_bank : state.dd_bank,
      dd_envelope_template: !isblank(action.payload.data.dd_envelope_template) ? action.payload.data.dd_envelope_template : state.dd_envelope_template,
      dd_cheque_template: !isblank(action.payload.data.dd_cheque_template) ? action.payload.data.dd_cheque_template : state.dd_cheque_template,
      ddexpenseaccount: !isblank(action.payload.data.expenseaccount) ? action.payload.data.expenseaccount : state.ddexpenseaccount,
      ddtradename: !isblank(action.payload.data.tradename) ? action.payload.data.tradename : state.ddtradename,
      ddallaccount: !isblank(action.payload.data.allaccount) ? action.payload.data.allaccount : state.ddallaccount,
      ddsalesaccount: !isblank(action.payload.data.sales_account) ? action.payload.data.sales_account : state.ddsalesaccount,
      ddpurchaseaccount: !isblank(action.payload.data.purchase_account) ? action.payload.data.purchase_account : state.ddpurchaseaccount,
      ddsalesreturnaccount: !isblank(action.payload.data.salesreturn_account) ? action.payload.data.salesreturn_account : state.ddsalesreturnaccount,
      ddpurchasereturnaccount: !isblank(action.payload.data.purchasereturn_account) ? action.payload.data.purchasereturn_account : state.ddpurchasereturnaccount,
      ddaccounttype: !isblank(action.payload.data.accounttype) ? action.payload.data.accounttype : state.ddaccounttype,
      dduser: !isblank(action.payload.data.user) ? action.payload.data.user : state.dduser,
      ddtaxgroup: !isblank(action.payload.data.taxgroup) ? action.payload.data.taxgroup : state.ddtaxgroup,
      ddsmscategory: !isblank(action.payload.data.smscategory) ? action.payload.data.smscategory : state.ddsmscategory,
      ddcostcenter: !isblank(action.payload.data.dropdown_costcenter) ? action.payload.data.dropdown_costcenter : state.ddcostcenter,
      ddactivity_type: !isblank(action.payload.data.activity_type) ? action.payload.data.activity_type : state.ddactivity_type,
      dddropdown_closingstock: !isblank(action.payload.data.dropdown_closingstock) ? action.payload.data.dropdown_closingstock : state.dddropdown_closingstock,
      ddtaxschemeaccount: !isblank(action.payload.data.taxschemeaccount) ? action.payload.data.taxschemeaccount : state.ddtaxschemeaccount,
      ddpartygroup: !isblank(action.payload.data.partygroup) ? action.payload.data.partygroup : state.ddpartygroup,
      dd_tdspayee: !isblank(action.payload.data.dd_tdspayee) ? action.payload.data.dd_tdspayee : state.dd_tdspayee,
      dd_tdscategory: !isblank(action.payload.data.dd_tdscategory) ? action.payload.data.dd_tdscategory : state.dd_tdscategory,
      ddIPOStockparty: !isblank(action.payload.data.dropdown_ipostockparty) ? action.payload.data.dropdown_ipostockparty : state.ddIPOStockparty,
      ddcustomfield: !isblank(action.payload.data.customField) ? action.payload.data.customField : state.ddcustomfield,
      ddcustomPage: !isblank(action.payload.data.ddcustomPage) ? action.payload.data.ddcustomPage : state.ddcustomPage,
      ddpructsubgroup: !isblank(action.payload.data.productsubgroup) ? action.payload.data.productsubgroup : state.ddpructsubgroup,
      ddpructsubgrouplevel2: !isblank(action.payload.data.productsubgrouplevel2) ? action.payload.data.productsubgrouplevel2 : state.ddpructsubgrouplevel2,
      ddgodown: !isblank(action.payload.data.ddgodown) ? action.payload.data.ddgodown : state.ddgodown,
      ddlabelsetting: !isblank(action.payload.data.ddlabelsetting) ? action.payload.data.ddlabelsetting : state.ddlabelsetting,
      ddprocess: !isblank(action.payload.data.ddprocess) ? action.payload.data.ddprocess : state.ddprocess,
      ddlot: !isblank(action.payload.data.ddlot) ? action.payload.data.ddlot : state.ddlot,
      ddtcs: !isblank(action.payload.data.tcsaccount) ? action.payload.data.tcsaccount : state.ddtcs,
      dd_draft: !isblank(action.payload.data.dd_draft) ? action.payload.data.dd_draft : state.dd_draft,
      dd_plan: !isblank(action.payload.data.dd_plan) ? action.payload.data.dd_plan : state.dd_plan,
      dd_taxcode: !isblank(action.payload.data.dd_taxcode) ? action.payload.data.dd_taxcode : state.dd_taxcode,
      dd_bottransaction: !isblank(action.payload.data.dd_bottransaction) ? action.payload.data.dd_bottransaction : state.dd_bottransaction,
      barcodeTemplate: !isblank(action.payload.data.barcodeTemplate) ? action.payload.data.barcodeTemplate : state.barcodeTemplate,
      dd_allcountryForOnboarding: !isblank(action.payload.data.dd_allcountry) ? action.payload.data.dd_allcountry : state.dd_allcountryForOnboarding,
      dd_country_state: !isblank(action.payload.data.dd_country_state) ? action.payload.data.dd_country_state : state.dd_country_state,
      dd_whatsapp_template: !isblank(action.payload.data.dd_whatsapp_template) ? action.payload.data.dd_whatsapp_template : state.dd_whatsapp_template,
      dd_email_template: !isblank(action.payload.data.dd_email_template) ? action.payload.data.dd_email_template : state.dd_email_template,
      dd_sms_template: !isblank(action.payload.data.dd_sms_template) ? action.payload.data.dd_sms_template : state.dd_sms_template,
      webhook_event_type: !isblank(action.payload.data.webhook_event_type) ? action.payload.data.webhook_event_type : state.webhook_event_type,
      field_formatter: !isblank(action.payload.data.field_formatter) ? action.payload.data.field_formatter : state.field_formatter,
      sidebarloading: sidebarloading,
      loading: loading,
      deploading: deploading,
    }
  }

  switch (action.type) {
    case MENUFROMSESSION:
      let menudata = []
      let data = []
      let dd_orderTypes = []
      if (!isblank(action.year)) {
        if (getStorage(`menu_data_${action.year}`)) {
          menudata = getStorage(`menu_data_${action.year}`)
          Object.keys(menudata).forEach(function (key) {
            data.push({ name: key, type: "header", open: false })
          })
        }
      } else {
        if (getStorage("menu_data")) {
          menudata = getStorage("menu_data")
          Object.keys(menudata).forEach(function (key) {
            data.push({ name: key, type: "header", open: false })
          })
        }
      }
      return { ...state, menu_data: data, all_menu: menudata, all_menuSet: true, dd_orderTypes: dd_orderTypes }
    case UPDATE_REFRESH_DRAWER_STACK:
      return { ...state, refreshDrawerStack: action.payload }

    case DIALOG_REASON_UPDATE:
      return { ...state, dialogReason: action.val }
    case CUSTOM_DIALOG:
      return {
        ...state,
        customDialogVisible: action.visible,
        customDialogTitle: action.title ? action.title : state.customDialogTitle,
        customDialogContent: action.message ? action.message : state.customDialogContent,
        customDialogButtons: action.buttons ? action.buttons : state.customDialogButtons,
        customDialogDismissLabel: action.dismissLabel ? action.dismissLabel : state.customDialogDismissLabel,
      }
    case UPDATE_DRAWER:
      let drawerStack4 = state.drawerStack
      if (!isblank(action.index) && !isblank(drawerStack4[action.index])) {
        drawerStack4[action.index].drawer = action.drawer ? action.drawer : drawerStack4[action.index].drawer
        drawerStack4[action.index].data = action.data ? action.data : drawerStack4[action.index].data
        drawerStack4[action.index].fieldname = action.fieldname ? action.fieldname : drawerStack4[action.index].fieldname
        drawerStack4[action.index].position = action.position ? action.position : drawerStack4[action.index].position
        drawerStack4[action.index].width = action.width ? action.width : drawerStack4[action.index].width
      } else {
        if (drawerStack4.length > 0) {
          drawerStack4[drawerStack4.length - 1].drawer = action.drawer ? action.drawer : drawerStack4[drawerStack4.length - 1].drawer
          drawerStack4[drawerStack4.length - 1].data = action.data ? action.data : drawerStack4[drawerStack4.length - 1].data
          drawerStack4[drawerStack4.length - 1].fieldname = action.fieldname ? action.fieldname : drawerStack4[drawerStack4.length - 1].fieldname
          drawerStack4[drawerStack4.length - 1].position = action.position ? action.position : drawerStack4[drawerStack4.length - 1].position
          drawerStack4[drawerStack4.length - 1].width = action.width ? action.width : drawerStack4[drawerStack4.length - 1].width
        }
      }
      return { ...state, drawerStack: drawerStack4 }
    case PUSH_DRAWER:
      let drawerStack = cloneObj(state.drawerStack)
      drawerStack.push({
        open: action.open,
        drawer: action.drawer,
        data: action.data,
        fieldname: action.fieldname,
        position: action.position,
        width: action.width,
      })
      return { ...state, drawerStack }
    case PUSH_SHORTCUT_DRAWER:
      let _drawerStack = cloneObj(state.drawerStack)

      if (_drawerStack.length > 0) {
        return {
          ...state,
          Snackbarerror: true,
          Snackbarerrormessage: "Ensure that all forms are closed before using shortcuts.",
          SnackbarAutoHide: true,
          SnackbarType: "warning",
          SnackbarOriginVertical: "top",
          SnackbarOriginHorizontal: "center",
        }
      } else {
        _drawerStack.push({
          open: action.open,
          drawer: action.drawer,
          data: action.data,
          fieldname: action.fieldname,
          position: action.position,
          width: action.width,
        })
        return { ...state, drawerStack: _drawerStack }
      }

    case OPEN_DRAWER:
      let drawerStack1 = state.drawerStack
      drawerStack1 = drawerStack1.map((e) => {
        const updated = { ...e }
        if (e.drawer === action.drawer) {
          updated.open = true
        }
        return updated
      })
      return { ...state, drawerStack: drawerStack1 }
    case CLOSE_ALL_DRAWER:
      return { ...state, drawerStack: [] }
    case CLOSE_DRAWER:
      let drawerStack2 = state.drawerStack
      if (drawerStack2.length > 0) {
        drawerStack2[drawerStack2.length - 1].open = false
      }
      return { ...state, drawerStack: drawerStack2 }
    case REMOVE_DRAWER:
      let drawerStack3 = cloneObj(state.drawerStack)
      drawerStack3.pop()
      // if (drawerStack3.length === 0 && (refreshDrawerStack === 1 || refreshDrawerStack === 3)) {
      // 	refreshDrawerStack = 0
      // }
      let setBalnkFormFields = {}
      if (drawerStack3.length === 0) {
        setBalnkFormFields = {
          formtype: "",
          formaction: "",
          formdata: "",
          pageformtype: "",
          pageformaction: "",
          pageformdata: "",
        }
      }
      return { ...state, drawerStack: cloneObj(drawerStack3), ...setBalnkFormFields }
    case SET_COUNTRY_ALL_STATE_FOR_ONBOARDING:
      return { ...state, countryallstateForOnboarding: action.payload }
    case SETMENU:
      let menudata1 = action.menu
      let data1 = []
      let dd_orderTypes1 = []
      if (!isblank(action.year)) {
        setStorage(`menu_data_${action.year}`, menudata1)
        Object.keys(menudata1).forEach(function (key) {
          data1.push({ name: key, type: "header", open: false })
        })
      } else {
        setStorage("menu_data", menudata1)
        Object.keys(menudata1).forEach(function (key) {
          data1.push({ name: key, type: "header", open: false })
        })
      }
      return { ...state, menu_data: data1, all_menu: menudata1, all_menuSet: true, dd_orderTypes: dd_orderTypes1 }

    case RESET_COMMON_REDUCER:
      return { ...state, ...INITIAL_STATE, windowWidth: state.windowWidth, windowHeight: state.windowHeight }
    // return { ...state }
    case SET_LAST_DRAFT:
      return { ...state, draftData: action.payload }
    case SET_LAST_MENU_KEY:
      return { ...state, last_menu_key: action.key }

    case SET_FILTER_DATA:
      return { ...state, filter_data: action.filter_data }

    case UPDATE_FILTER_DATA:
      let updatedFilter = state.filter_data
      if (!isblank(action.filter_data) && !isblank(action.filter_data.code)) {
        updatedFilter = updatedFilter.map((item) => {
          const updatedItem = { ...item }
          if (item.code === action.filter_data.code) {
            updatedItem.query_data = action.filter_data.query_data
            updatedItem.name = action.filter_data.name
          }
          return updatedItem
        })
      } else if (!isblank(action.filter_data) && isblank(action.filter_data.code)) {
        updatedFilter.push({ code: action.insert_code, query_data: action.filter_data.query_data, name: action.filter_data.name })
      }
      return { ...state, filter_data: updatedFilter }
    case CLOSING_BALANCE:
      return { ...state, closingbalance: action.payload, loading: false, sidebarloading: false }
    case UPDATE_RIGHT_DRAWER_STATE:
      var rightdrawerStack = state.rightdrawerStack.map((item, index) => {
        const updatedItem = { ...item }
        if (index === state.rightdrawerStack.length - 1) {
          updatedItem.rightdrawerdata = action.data
          updatedItem.rightdrawerfieldname = action.fieldname
        }
        return updatedItem
      })
      return { ...state, rightdrawerdata: action.data, rightdrawerfieldname: action.fieldname, rightdrawerStack: rightdrawerStack }
    case RIGHT_DRAWER_STATE:
      var rightdrawerStack = state.rightdrawerStack
      var drawerZIndexData = []
      if (action.payload) {
        rightdrawerStack.push({
          rightdrawerdata: action.data,
          rightdrawerfieldname: action.fieldname,
          rightdrawerposition: action.position,
          rightdrawerwidth: isMobileOnly ? "100%" : action.width,
          rightdrawerheight: action.height,
          rightdrawer: action.payload,
          rightdrawerheader: action.title,
        })
        drawerZIndexData = setZIndex("add", "right", action.title)
      } else {
        drawerZIndexData = setZIndex("remove", "right")
        rightdrawerStack = rightdrawerStack.filter((it, index) => index !== rightdrawerStack.length - 1)
      }

      if (rightdrawerStack.length > 0) {
        var currentDrawer = rightdrawerStack[rightdrawerStack.length - 1]
        return {
          ...state,
          rightdrawer: currentDrawer.rightdrawer,
          rightdrawerheader: currentDrawer.rightdrawerheader,
          rightdrawerdata: currentDrawer.rightdrawerdata,
          rightdrawerfieldname: currentDrawer.rightdrawerfieldname,
          rightdrawerposition: currentDrawer.rightdrawerposition,
          rightdrawerwidth: currentDrawer.rightdrawerwidth,
          rightdrawerheight: currentDrawer.rightdrawerheight,
          rightdrawerStack: rightdrawerStack,
          sidebarloading: false,
          drawerZIndexData,
        }
      } else {
        return {
          ...state,
          rightdrawerdata: action.data,
          rightdrawerfieldname: action.fieldname,
          rightdrawerposition: action.position,
          rightdrawerwidth: isMobileOnly ? "100%" : action.width,
          rightdrawerheight: action.height,
          rightdrawer: action.payload,
          rightdrawerheader: action.title,
          rightdrawerStack: rightdrawerStack,
          sidebarloading: false,
          drawerZIndexData,
        }
      }
    case SET_EXPAND_VALUE:
      let drawerStack5 = cloneObj(state.drawerStack)
      drawerStack5[action.drawerIndex].expandDrawer = action.value
      return { ...state, drawerStack: drawerStack5 }
    case SET_SETTING:
      return { ...state, siteSettingDefaultValue: action.siteSettingDefaultValue }
    case FORM_DRAWER_STATE:
      var drawerZIndexData = []
      if (action.payload) {
        drawerZIndexData = setZIndex("add", "form", action.title)
      } else {
        drawerZIndexData = setZIndex("remove", "form")
      }
      return {
        ...state,
        formdrawer: action.payload,
        formdrawerheader: action.title,
        formdrawerdata: action.data,
        formdrawerfieldname: action.fieldname,
        formdrawerposition: action.position,
        formdrawerwidth: action.width,
        formdrawerheight: action.height,
        sidebarloading: false,
        closingbalance: "",
        drawerZIndexData,
      }

    case UPDATE_FORM_DRAWER_STATE:
      return { ...state, formdrawerdata: action.data, formdrawerfieldname: action.fieldname }

    case UPDATERIGHT_DRAWER_STATE:
      var new_data = ""
      if (action.other_type === "gstr1" && action.action_type === "submit_gst" && action.payload.invoice_codes.length > 0) {
        let updateddata = state.rightdrawerdata.records.list.map((item, index) => {
          if (action.payload.invoice_codes.indexOf(item.code) === -1) {
            return item
          }
          const updateditem = { ...item }
          updateditem.gstr1_filed = "Not Filed"
          return updateditem
        })
        new_data = state.rightdrawerdata
        new_data.records.list = updateddata
        return { ...state, rightdrawerdata: { ...new_data }, sidebarloading: false, otherDetail: action.otherDetail }
      } else if (action.other_type === "gstr1" && action.action_type === "markas_pending" && action.payload.invoice_codes.length > 0) {
        let updateddata = state.rightdrawerdata.records.list.map((item, index) => {
          if (action.payload.invoice_codes.indexOf(item.code) === -1) {
            return item
          }
          const updateditem = { ...item }
          updateditem.gstr1_filed = "Pending"
          return updateditem
        })
        new_data = state.rightdrawerdata
        new_data.records.list = updateddata
        return { ...state, rightdrawerdata: { ...new_data }, sidebarloading: false, otherDetail: action.otherDetail }
      } else if (action.other_type === "gstr1" && action.action_type === "unmarkas_filed" && action.payload.invoice_codes.length > 0) {
        let updateddata = state.rightdrawerdata.records.list.filter(function (item) {
          return action.payload.invoice_codes.indexOf(item.code) === -1
        })
        new_data = state.rightdrawerdata
        new_data.records.list = updateddata
        return { ...state, rightdrawerdata: { ...new_data }, sidebarloading: false, otherDetail: action.otherDetail }
      } else if (action.other_type === "gstr3b" && action.action_type === "submit_gst" && action.payload.invoice_codes.length > 0) {
        let updateddata = state.rightdrawerdata.records.list.map((item, index) => {
          if (action.payload.invoice_codes.indexOf(item.code) === -1) {
            return item
          }
          const updateditem = { ...item }
          updateditem.gstr3B_filed = "Not Filed"
          return updateditem
        })
        new_data = state.rightdrawerdata
        new_data.records.list = updateddata
        return { ...state, rightdrawerdata: { ...new_data }, sidebarloading: false, otherDetail: action.otherDetail }
      } else if (action.other_type === "gstr3b" && action.action_type === "markas_pending" && action.payload.invoice_codes.length > 0) {
        let updateddata = state.rightdrawerdata.records.list.map((item, index) => {
          if (action.payload.invoice_codes.indexOf(item.code) === -1) {
            return item
          }
          const updateditem = { ...item }
          updateditem.gstr3B_filed = "Pending"
          return updateditem
        })
        new_data = state.rightdrawerdata
        new_data.records.list = updateddata
        return { ...state, rightdrawerdata: { ...new_data }, sidebarloading: false, otherDetail: action.otherDetail }
      } else if (action.other_type === "gstr3b" && action.action_type === "unmarkas_filed" && action.payload.invoice_codes.length > 0) {
        let updateddata = state.rightdrawerdata.records.list.filter(function (item) {
          return action.payload.invoice_codes.indexOf(item.code) === -1
        })
        new_data = state.rightdrawerdata
        new_data.records.list = updateddata
        return { ...state, rightdrawerdata: { ...new_data }, sidebarloading: false, otherDetail: action.otherDetail }
      } else if ((action.other_type === "gstr3b" || action.other_type === "gstr1") && action.action_type === "markas_filed" && action.payload.invoice_codes.length > 0) {
        new_data = state.rightdrawerdata
        new_data.records.list = new_data.records.list.filter(function (item) {
          return action.payload.invoice_codes.indexOf(item.code) === -1
        })
        new_data.records.pending = new_data.records.pending.filter(function (item) {
          return action.payload.invoice_codes.indexOf(item.code) === -1
        })
        return { ...state, rightdrawerdata: { ...new_data }, sidebarloading: false, otherDetail: action.otherDetail }
      } else if (action.action_type === "update_gstin" && action.payload.partycode !== undefined) {
        let drawerStack6 = state.drawerStack.map((e, gindex) => {
          const updatedGST = { ...e }
          if (gindex === action.otherDetail.drawerIndex) {
            updatedGST.data.records.list = updatedGST.data.records.list.map((i) => {
              const updateditem = { ...i }
              if (i.partycode === action.payload.partycode) {
                updateditem.gstin = action.payload.gstin
              }
              return updateditem
            })
          }
          return updatedGST
        })
        return { ...state, drawerStack: [...drawerStack6], sidebarloading: false }
      }
      return { ...state }
    case SNACKBAR_STATE:
      return {
        ...state,
        Snackbarerror: action.payload,
        Snackbarerrormessage: action.message,
        SnackbarAutoHide: !isblank(action.autoHide) ? action.autoHide : true,
        SnackbarType: action.payload ? (!isblank(action.snackbarType) ? action.snackbarType : "info") : state.SnackbarType,
        SnackbarOriginVertical: action.payload ? (!isblank(action.SnackbarOriginVertical) ? action.SnackbarOriginVertical : "top") : state.SnackbarOriginVertical,
        SnackbarOriginHorizontal: action.payload ? (!isblank(action.SnackbarOriginHorizontal) ? action.SnackbarOriginHorizontal : "right") : state.SnackbarOriginHorizontal,
      }
    case LOADING_SNACKBAR_STATE:
      return {
        ...state,
        loadingSnackbar: action.payload,
        loadingSnackbarMessage: action.message,
        loadingSnackbarType: action.payload ? (!isblank(action.snackbarType) ? action.snackbarType : "warning") : state.loadingSnackbarType,
      }
    case PRODUCT_BATCH_SERVICE_SUCCESS:
      return { ...state, ddproductbatch: action.payload }
    case PRODUCT_SERIAL_SERVICE_SUCCESS:
      return { ...state, ddproductserial: action.payload }
    case FORM_DEP_SERVICE_SUCCESS:
      return { ...setDependency({ sidebarloading: false, loading: false, deploading: false, action }) }

    case FORM_DEP_SERVICE_SUCCESS_WITHOUTLOADING:
      return {
        ...setDependency({
          sidebarloading: state.sidebarloading,
          loading: state.loading,
          deploading: state.deploading,
          action,
        }),
      }

    case CUSTOM_PAGE_DROPDOWN_SUCCESS:
      var dd_custom_page_dropdown = state.dd_custom_page_dropdown
      dd_custom_page_dropdown[action.other_type] = action.payload
      return { ...state, dd_custom_page_dropdown }

    case CUSTOM_PAGE_DROPDOWN_UPDATE:
      var dd_custom_page_dropdown = state.dd_custom_page_dropdown
      if (action.formaction === "insert") {
        if (action.payload.__cust_promo___Type === "1") {
          if (!isblank(dd_custom_page_dropdown[action.other_type])) {
            dd_custom_page_dropdown[action.other_type] = [{ name: action.payload.__cust_promo_name, code: action.insert_code.toString() }, ...dd_custom_page_dropdown[action.other_type]]
          }
        }
      } else if (action.formaction === "update") {
        if (!isblank(dd_custom_page_dropdown[action.other_type])) {
          dd_custom_page_dropdown[action.other_type] = dd_custom_page_dropdown[action.other_type].map((e) => {
            const updated = { ...e }
            if (convertIntif(updated.code) === convertIntif(action.payload.update_code)) {
              updated.name = action.payload.__cust_promo_name
            }
            return updated
          })
        }
      }
      return { ...state, dd_custom_page_dropdown }

    case LAST_PAGE_UPDATE:
      return { ...state, lastpagetype: action.lasttype, lastpageaction: action.lastaction, lastpagedata: action.lastdata, lastpagetime: action.lasttime, lastpagemeta: action.lastmeta }

    case FORM_POST_SERVICE_CLEAR:
      return { ...state, formtype: "", formaction: "", formdata: "", closingbalance: "" }

    case FORM_POST_UPDATE:
      return { ...state, formtype: action.formtype, formaction: action.formaction, formdata: action.formdata }

    case PAGE_FORM_POST_UPDATE:
      return { ...state, pageformtype: action.formtype, pageformaction: action.formaction, pageformdata: action.formdata }

    case PAGE_FORM_POST_SERVICE_CLEAR:
      return { ...state, pageformtype: "", pageformaction: "", pageformdata: "" }

    case FORM_DEP_SERVICE_CLEAR:
      return {
        ...state,
        ddcustomer: [],
        subgroup: [],
        dd_draft: [],
        dd_plan: [],
        ddsupplier: [],
        ddcustomer_supplier: [],
        ddlot_party: [],
        ddagent: [],
        ddtransport: [],
        ddtaxscheme: [],
        dd_accountPackage: [],
        dditem: [],
        dd_item_with_configitem: [],
        dd_invoice_template: [],
        ddother_account: [],
        ddaccountgroup: [],
        ddcreditnote: [],
        dddebitnote: [],
        ddstate: [],
        ddcity: [],
        dditemgroup: [],
        ddunit: [],
        ddopbal: [],
        ddcompany: [],
        ddbankaccount: [],
        ddexpenseaccount: [],
        ddtradename: [],
        ddallaccount: [],
        ddsalesaccount: [],
        ddpurchaseaccount: [],
        ddsalesreturnaccount: [],
        ddpurchasereturnaccount: [],
        ddaccounttype: [],
        dddropdown_closingstock: [],
        ddtaxgroup: "",
        ddsmscategory: [],
        ddactivity_type: [],
        ddcostcenter: [],
        ddtaxschemeaccount: [],
        ddpartygroup: [],
        ddsender: [],
        ddbeneficiary: [],
        ddIPOStockparty: [],
        ddproductbatch: [],
        ddproductserial: [],
        ddcustomfield: [],
        ddcustomPage: [],
        ddpructsubgroup: [],
        ddpructsubgrouplevel2: [],
        ddgodown: [],
        ddlabelsetting: [],
        ddprocess: [],
        ddlot: [],
        ddtcs: [],
        dd_bottransaction: [],
        dd_bank: [],
        dd_envelope_template: [],
        dd_cheque_template: [],
        dd_tdspayee: [],
        dd_tdscategory: [],
        dd_taxcode: [],
        barcodeTemplate: [],
        dd_allcountryForOnboarding: [],
        dd_country_state: [],
        dd_whatsapp_template: [],
        dd_email_template: [],
        dd_sms_template: [],
        countryallstateForOnboarding: [],
        webhook_event_type: [],
        field_formatter: [],
      }

    case UPDATE_OTHER_DETAIL:
      return { ...state, otherDetail: action.otherDetail }
    case FORM_POST_SERVICE_SUCCESS:
      var drawerZIndexData = []
      if (action.payload) {
        drawerZIndexData = setZIndex("add", "form", action.title)
      } else {
        drawerZIndexData = setZIndex("remove", "form")
      }

      if (action.formaction === "edit" || action.formaction === "clone" || action.formaction === "draft_edit") {
        if (action.formtype === "salesreturn" || action.formtype === "purchasereturn") {
          return {
            ...state,
            formtype: action.formtype,
            formaction: action.formaction,
            formdata: action.payload,
            otherDetail: action.otherDetail,
            sidebarloading: false,
            loading: false,
            ConfirmDialog: false,
            FormConfirmDialog: false,
            DrawerConfirmDialog: false,
          }
        } else {
          return {
            ...state,
            formtype: action.formtype,
            formaction: action.formaction,
            otherDetail: action.otherDetail,
            formdata: action.payload,
            sidebarloading: false,
            loading: false,
            ConfirmDialog: false,
            FormConfirmDialog: false,
            DrawerConfirmDialog: false,
          }
        }
      } else if (action.formaction === "delete") {
        if (!isblank(action.otherDetail) && action.otherDetail.from === "detail") {
          return {
            ...state,
            formtype: action.formtype,
            formaction: action.formaction,
            Snackbarerror: true,
            SnackbarType: "success",
            Snackbarerrormessage: action.payload.data.meta.message,
            sidebarloading: false,
            loading: false,
            ConfirmDialog: false,
            FormConfirmDialog: false,
            DrawerConfirmDialog: false,
            rightdrawer: false,
            formdrawer: false,
            drawerZIndexData: [],
          }
        }
        return {
          ...state,
          formtype: action.formtype,
          formaction: action.formaction,
          Snackbarerror: true,
          SnackbarType: "success",
          Snackbarerrormessage: action.payload.data.meta.message,
          sidebarloading: false,
          loading: false,
          ConfirmDialog: false,
          FormConfirmDialog: false,
          DrawerConfirmDialog: false,
        }
      } else if (action.formaction === "multi_delete") {
        return { ...state, formtype: action.formtype, formaction: action.formaction, sidebarloading: false, loading: false }
      } else if (action.formaction === "autofill") {
        if (
          action.formtype === "salesreturn" ||
          action.formtype === "purchasereturn" ||
          action.purchase_type_detail.filter((e) => e.id === action.formtype).length > 0 ||
          action.formtype === "purchaseinvoice_grid"
        ) {
          return { ...state, sidebarloading: false, loading: false }
        }
      } else {
        if (action.saveandaddanother) {
          return {
            ...state,
            formtype: action.formtype,
            formaction: action.formaction,
            formdata: action.payload,
            Snackbarerror: true,
            SnackbarType: "success",
            Snackbarerrormessage: action.payload.data.meta.message,
            sidebarloading: false,
            loading: false,
            ConfirmDialog: false,
            FormConfirmDialog: false,
            DrawerConfirmDialog: false,
          }
        } else {
          if (action.formtype === "active_inactive" || action.formtype === "notification") {
            return {
              ...state,
              sidebarloading: false,
              loading: false,
              Snackbarerror: true,
              SnackbarType: "success",
              Snackbarerrormessage: action.payload.data.meta.message,
              ConfirmDialog: false,
              FormConfirmDialog: false,
              DrawerConfirmDialog: false,
            }
          }
          if (action.formtype === "importunit" && !isblank(action.otherDetail) && action.otherDetail.from === "drawerDialog") {
            return {
              ...state,
              formtype: action.formtype,
              formaction: action.formaction,
              formdata: action.payload,
              Snackbarerror: true,
              SnackbarType: "success",
              Snackbarerrormessage: action.payload.data.meta.message,
              sidebarloading: false,
              loading: false,
              ConfirmDialog: false,
              FormConfirmDialog: false,
              DrawerConfirmDialog: false,
            }
          }
          return {
            ...state,
            formtype: action.formtype,
            formaction: action.formaction,
            formdata: action.payload,
            Snackbarerror: true,
            SnackbarType: "success",
            Snackbarerrormessage: action.payload.data.meta.message,
            rightdrawer: false,
            rightdrawerStack: [],
            drawerZIndexData: [],
            sidebarloading: false,
            loading: false,
            ConfirmDialog: false,
            FormConfirmDialog: false,
            DrawerConfirmDialog: false,
          }
        }
      }
      return { ...state }

    case PAGE_FORM_POST_SERVICE_SUCCESS:
      if (action.pageformaction === "edit" || action.pageformaction === "clone" || action.pageformaction === "draft_edit") {
        if (action.pageformtype === "salesreturn" || action.pageformtype === "purchasereturn") {
          return {
            ...state,
            pageformtype: action.pageformtype,
            pageformaction: action.pageformaction,
            pageformdata: action.payload,
            sidebarloading: false,
            loading: false,
            ConfirmDialog: false,
            FormConfirmDialog: false,
            DrawerConfirmDialog: false,
          }
        } else {
          return {
            ...state,
            pageformtype: action.pageformtype,
            pageformaction: action.pageformaction,
            otherDetail: action.otherDetail,
            pageformdata: action.payload,
            sidebarloading: false,
            loading: false,
            ConfirmDialog: false,
            FormConfirmDialog: false,
            DrawerConfirmDialog: false,
          }
        }
      } else if (action.pageformaction === "delete") {
        return {
          ...state,
          pageformtype: action.pageformtype,
          pageformaction: action.pageformaction,
          Snackbarerror: true,
          SnackbarType: "success",
          Snackbarerrormessage: action.payload.data.meta.message,
          sidebarloading: false,
          loading: false,
          ConfirmDialog: false,
          FormConfirmDialog: false,
          DrawerConfirmDialog: false,
          rightdrawer: false,
          rightdrawerStack: [],
          drawerZIndexData: [],
          formdrawer: false,
          drawerZIndexData: [],
        }
      } else if (action.pageformaction === "multi_delete") {
        return { ...state, pageformtype: action.pageformtype, pageformaction: action.pageformaction, sidebarloading: false, loading: false }
      } else {
        if (action.saveandaddanother) {
          return {
            ...state,
            pageformtype: action.pageformtype,
            pageformaction: action.pageformaction,
            otherDetail: action.otherDetail,
            pageformdata: action.payload,
            Snackbarerror: true,
            SnackbarType: "success",
            Snackbarerrormessage: action.payload.data.meta.message,
            sidebarloading: false,
            loading: false,
            ConfirmDialog: false,
            FormConfirmDialog: false,
            DrawerConfirmDialog: false,
          }
        } else {
          return {
            ...state,
            pageformtype: action.pageformtype,
            pageformaction: action.pageformaction,
            otherDetail: action.otherDetail,
            pageformdata: action.payload,
            Snackbarerror: true,
            SnackbarType: "success",
            Snackbarerrormessage: action.payload.data.meta.message,
            rightdrawer: false,
            rightdrawerStack: [],
            drawerZIndexData: [],
            drawerZIndexData: [],
            formdrawer: false,
            sidebarloading: false,
            loading: false,
            ConfirmDialog: false,
            FormConfirmDialog: false,
            DrawerConfirmDialog: false,
          }
        }
      }

    case UTILITY_POST_SERVICE_SUCCESS:
      if (action.formaction === "edit") {
        return { ...state, formtype: action.formtype, formaction: action.formaction, formdata: action.payload, sidebarloading: false, loading: false }
      } else if (action.formaction === "delete") {
        return {
          ...state,
          formtype: action.formtype,
          formaction: action.formaction,
          Snackbarerror: true,
          SnackbarType: "success",
          Snackbarerrormessage: action.payload.data.meta.message,
          sidebarloading: false,
          loading: false,
        }
      } else {
        if (action.formtype === "skip_password") {
          return {
            ...state,
            formtype: action.formtype,
            formaction: action.formaction,
            formdata: action.payload,
            rightdrawer: false,
            rightdrawerStack: [],
            drawerZIndexData: [],
            formdrawer: false,
            sidebarloading: false,
            loading: false,
          }
        }
        return {
          ...state,
          formtype: action.formtype,
          formaction: action.formaction,
          formdata: action.payload,
          Snackbarerror: true,
          SnackbarType: "success",
          Snackbarerrormessage: action.payload.data.meta.message,
          rightdrawer: false,
          rightdrawerStack: [],
          drawerZIndexData: [],
          formdrawer: false,
          sidebarloading: false,
          loading: false,
        }
      }
    case FORM_POST_SERVICE_START:
      if (
        (action.formaction === "edit" && action.formtype !== "itemGodownOpstock") ||
        action.formtype === "filegstr1" ||
        (action.formtype === "importunit" && !isblank(action.otherDetail) && action.otherDetail.from === "formDialog") ||
        action.formtype === "gstr1_reconcile" ||
        action.formtype === "gstr2_filed" ||
        action.formtype === "reset_filegstr1" ||
        action.formtype === "filegstr3" ||
        action.formtype === "salesinvoice" ||
        action.formtype === "purchaseinvoice" ||
        action.formtype === "salesreturn" ||
        action.formtype === "purchasereturn" ||
        action.formtype === "connect_company" ||
        action.inward_type_detail.filter((e) => e.id === action.formtype).length > 0 ||
        action.outward_type_detail.filter((e) => e.id === action.formtype).length > 0 ||
        action.formtype === "receipt" ||
        action.formtype === "payment" ||
        action.formtype === "recordexpense" ||
        action.formtype === "journal_entry" ||
        action.formtype === "userrights" ||
        action.formtype === "modulerights" ||
        action.formtype === "reportrights" ||
        action.formtype === "companyrights" ||
        action.formtype === "ewaybilluser" ||
        action.formtype === "get_partygstaddress"
      ) {
        return {
          ...state,
          loading: true,
        }
      } else {
        return {
          ...state,
          sidebarloading: true,
        }
      }

    case PAGE_FORM_POST_SERVICE_START:
      if (
        action.pageformaction === "edit" ||
        action.pageformtype === "onboarding" ||
        action.pageformtype === "customPageFormSrno" ||
        action.pageformtype === "remove_social_account" ||
        action.pageformtype === "envelopePrinting" ||
        action.pageformtype === "chequePrinting" ||
        action.pageformtype === "lotCosting" ||
        action.pageformtype === "reconcileDate" ||
        action.pageformtype === "bank_statement" ||
        action.pageformtype === "merge_packingSlip" ||
        action.pageformtype === "generate_irn_ewaybill" ||
        action.pageformtype === "stockTransfer" ||
        action.pageformtype === "stock_transferGodown" ||
        action.pageformtype === "lot_transfer" ||
        action.pageformtype === "gstoldbill" ||
        action.pageformtype === "cancel_einvoice" ||
        action.pageformtype === "customField" ||
        action.pageformtype === "generate_einvoice" ||
        action.pageformtype === "salesreturn" ||
        action.pageformtype === "shipping" ||
        action.pageformtype === "configurableitem" ||
        action.pageformtype === "adjustBill" ||
        action.pageformtype === "catalogueUserStatus" ||
        action.pageformtype === "catalogueUser" ||
        action.pageformtype === "setting" ||
        action.pageformtype === "purchasereturn" ||
        action.invoice_type_detail.filter((e) => e.id === action.pageformtype).length > 0 ||
        action.purchase_type_detail.filter((e) => e.id === action.pageformtype).length > 0 ||
        action.inward_type_detail.filter((e) => e.id === action.pageformtype).length > 0 ||
        action.outward_type_detail.filter((e) => e.id === action.pageformtype).length > 0 ||
        action.pageformtype === "receipt" ||
        action.pageformtype === "payment" ||
        action.pageformtype === "customPageForm" ||
        action.pageformtype === "recordexpense" ||
        action.pageformtype === "journal_entry" ||
        action.pageformtype === "reset_gstr3b_reconcile" ||
        action.pageformtype === "gstr2_partially_markas_matched"
      ) {
        return {
          ...state,
          loading: true,
        }
      } else {
        return {
          ...state,
          sidebarloading: true,
        }
      }

    case UTILITY_POST_SERVICE_START:
      if (action.formaction === "edit") {
        return {
          ...state,
          loading: true,
        }
      } else {
        return {
          ...state,
          sidebarloading: true,
        }
      }
    case DIALOG_LOADING:
      return { ...state, ConfirmDialogLoading: action.state }
    case FORM_DIALOG_LOADING:
      return { ...state, FormConfirmDialogLoading: action.state }
    case DRAWER_DIALOG_LOADING:
      return { ...state, DrawerConfirmDialogLoading: action.state }

    case CONFIRM_DIALOG:
      if (action.payload === true) {
        return {
          ...state,
          onConfirm: action.onConfirm,
          ConfirmDialog: true,
          ConfirmNo: false,
          Confirm: false,
          ConfirmDialogMessage: action.message,
          ConfirmDialogTitle: action.title,
          ConfirmDialogForm: action.form,
          ConfirmDialogData: action.meta,
          ConfirmDialogLoading: action.loading,
        }
      } else {
        var ConfirmDialogForm = action.form
        if (isblank(action.form)) {
          ConfirmDialogForm = state.ConfirmDialogForm
        }
        if (action.confirm) {
          return {
            ...state,
            ConfirmDialog: false,
            ConfirmNo: false,
            Confirm: true,
            ConfirmDialogMessage: !isblank(action.message) ? action.message : state.ConfirmDialogMessage,
            ConfirmDialogTitle: !isblank(action.title) ? action.title : state.ConfirmDialogTitle,
            ConfirmDialogForm: ConfirmDialogForm,
            ConfirmDialogData: !isblank(action.meta) ? action.meta : state.ConfirmDialogData,
            ConfirmDialogLoading: !isblank(action.loading) ? action.loading : false,
          }
        } else {
          return {
            ...state,
            ConfirmDialog: false,
            ConfirmNo: action.confirmNo,
            Confirm: false,
            ConfirmDialogMessage: !isblank(action.message) ? action.message : state.ConfirmDialogMessage,
            ConfirmDialogTitle: !isblank(action.title) ? action.title : state.ConfirmDialogTitle,
            ConfirmDialogForm: ConfirmDialogForm,
            ConfirmDialogData: !isblank(action.meta) ? action.meta : state.ConfirmDialogData,
            ConfirmDialogLoading: !isblank(action.loading) ? action.loading : false,
          }
        }
      }
    case FORM_CONFIRM_DIALOG:
      if (action.payload === true) {
        return {
          ...state,
          FormConfirmDialog: true,
          FormConfirm: false,
          FormConfirmDialogMessage: action.message,
          FormConfirmDialogTitle: action.title,
          FormConfirmDialogForm: action.form,
          FormConfirmDialogData: action.meta,
          FormConfirmDialogLoading: action.loading,
        }
      } else {
        if (action.confirm) {
          return {
            ...state,
            FormConfirmDialog: false,
            FormConfirm: true,
            FormConfirmDialogMessage: !isblank(action.message) ? action.message : state.FormConfirmDialogMessage,
            FormConfirmDialogTitle: !isblank(action.title) ? action.title : state.FormConfirmDialogTitle,
            FormConfirmDialogForm: !isblank(action.form) ? action.form : state.FormConfirmDialogForm,
            FormConfirmDialogData: !isblank(action.meta) ? action.meta : state.FormConfirmDialogData,
            FormConfirmDialogLoading: !isblank(action.loading) ? action.loading : false,
          }
        } else {
          return {
            ...state,
            FormConfirmDialog: false,
            FormConfirm: false,
            FormConfirmDialogMessage: !isblank(action.message) ? action.message : state.FormConfirmDialogMessage,
            FormConfirmDialogTitle: !isblank(action.title) ? action.title : state.FormConfirmDialogTitle,
            FormConfirmDialogForm: !isblank(action.form) ? action.form : state.FormConfirmDialogForm,
            FormConfirmDialogData: !isblank(action.meta) ? action.meta : state.FormConfirmDialogData,
            FormConfirmDialogLoading: !isblank(action.loading) ? action.loading : false,
          }
        }
      }
    case DRAWER_CONFIRM_DIALOG:
      if (action.payload === true) {
        return {
          ...state,
          DrawerConfirmDialog: true,
          DrawerConfirm: false,
          DrawerConfirmDialogMessage: action.message,
          DrawerConfirmDialogTitle: action.title,
          DrawerConfirmDialogForm: action.form,
          DrawerConfirmDialogData: action.meta,
          DrawerConfirmDialogLoading: action.loading,
        }
      } else {
        if (action.confirm) {
          return {
            ...state,
            DrawerConfirmDialog: false,
            DrawerConfirm: false,
            DrawerConfirmDialogMessage: !isblank(action.message) ? action.message : state.DrawerConfirmDialogMessage,
            DrawerConfirmDialogTitle: !isblank(action.title) ? action.title : state.DrawerConfirmDialogTitle,
            DrawerConfirmDialogForm: !isblank(action.form) ? action.form : state.DrawerConfirmDialogForm,
            DrawerConfirmDialogData: !isblank(action.meta) ? action.meta : state.DrawerConfirmDialogData,
            DrawerConfirmDialogLoading: !isblank(action.loading) ? action.loading : false,
          }
        } else {
          return {
            ...state,
            DrawerConfirmDialog: false,
            DrawerConfirm: false,
            DrawerConfirmDialogMessage: !isblank(action.message) ? action.message : state.DrawerConfirmDialogMessage,
            DrawerConfirmDialogTitle: !isblank(action.title) ? action.title : state.DrawerConfirmDialogTitle,
            DrawerConfirmDialogForm: !isblank(action.form) ? action.form : state.DrawerConfirmDialogForm,
            DrawerConfirmDialogData: !isblank(action.meta) ? action.meta : state.DrawerConfirmDialogData,
            DrawerConfirmDialogLoading: !isblank(action.loading) ? action.loading : false,
          }
        }
      }
    case FORM_DEP_SERVICE_START:
      if (
        action.formtype === "salesinvoice" ||
        action.formtype === "customPage" ||
        action.formtype === "partyPrice" ||
        action.formtype === "upgradePlan" ||
        action.formtype === "lot_transfer" ||
        action.formtype === "process_transfer" ||
        action.formtype === "userRights" ||
        action.formtype === "stockTransfer" ||
        action.formtype === "stock_transferGodown" ||
        action.formtype === "bank_statement" ||
        action.formtype === "site_setting" ||
        action.formtype === "user_history" ||
        action.formtype === "merge" ||
        action.formtype === "change_year" ||
        action.formtype === "purchaseinvoice" ||
        action.formtype === "adjustBill" ||
        action.formtype === "expense_payment" ||
        action.formtype === "salesreturn" ||
        action.formtype === "purchasereturn" ||
        action.inward_type_detail.filter((e) => e.id === action.formtype).length > 0 ||
        action.outward_type_detail.filter((e) => e.id === action.formtype).length > 0 ||
        action.formtype === "receipt" ||
        action.formtype === "payment" ||
        action.formtype === "journalentry" ||
        action.formtype === "recordexpense" ||
        action.formtype === "tdsCategory" ||
        action.formtype === "report"
      ) {
        return {
          ...state,
          loading: true,
          deploading: true,
        }
      } else {
        return {
          ...state,
          sidebarloading: true,
          deploading: true,
        }
      }

    case PROGRESS_LOADING_STATE:
      return { ...state, progress_loading: action.state, progress_loading_title: action.title }

    case LOADING_STATE:
      return {
        ...state,
        loading: action.state,
        sidebarloading: action.sidebarstate,
      }

    case POP_OVER_LOADING_STATE:
      return { ...state, popOverloading: action.state }

    case FORM_POST_SERVICE_FAIL:
      return {
        ...state,
        Snackbarerror: true,
        SnackbarType: "error",
        Snackbarerrormessage: action.payload,
        sidebarloading: false,
        loading: false,
        DrawerConfirmDialogLoading: false,
        FormConfirmDialogLoading: false,
        ConfirmDialogLoading: false,
      }

    case PAGE_FORM_POST_SERVICE_FAIL:
      return {
        ...state,
        Snackbarerror: true,
        SnackbarType: "error",
        Snackbarerrormessage: action.payload,
        sidebarloading: false,
        loading: false,
        DrawerConfirmDialogLoading: false,
        FormConfirmDialogLoading: false,
        ConfirmDialogLoading: false,
      }

    case FORM_DEP_SERVICE_FAIL:
      return {
        ...state,
        Snackbarerror: true,
        SnackbarType: "error",
        Snackbarerrormessage: action.payload,
        sidebarloading: false,
        loading: false,
        deploading: false,
      }

    case POST_FILTER_SERVICE_START:
      return {
        ...state,
        loading: action.formtype === "Ledger Report" ? false : true,
        sidebarloading: action.formtype === "Ledger Report" ? true : false,
        filterloading: true,
      }
    case POST_FILTER_SERVICE_FAIL:
      return {
        ...state,
        Snackbarerror: true,
        SnackbarType: "error",
        Snackbarerrormessage: action.payload,
        sidebarloading: false,
        loading: false,
      }
    case CASH_BANK_DETAIL_SUCCESS:
      return { ...state, bankCashDetail: action.payload, sidebarloading: false, loading: false }
    case POST_FILTER_SERVICE_SUCCESS:
      return { ...state, Snackbarerror: true, filterloading: false, SnackbarType: "success", Snackbarerrormessage: action.payload.data.meta.message, sidebarloading: false, loading: false }

    case DROPDOWN_ITEM_INSERTED:
      if (action.formtype === "customer" || action.formtype === "supplier") {
        let inserteditem = {}

        if (!isblank(action.payload.groupSelectedValue) && !isblank(action.payload.groupSelectedValue.id) && isblank(action.payload.customerGroupSelectedValue)) {
          action.payload.customerGroupSelectedValue = {
            code: parseInt(action.payload.groupSelectedValue.id) === 2 ? "L0021" : "A0019",
          }
        }

        inserteditem.code = action.code.toString()
        inserteditem.inactive = "0"
        inserteditem.name = action.name
        inserteditem.city = getdropdownvalue({ formValues: action.payload, field: "billingCitySelectedValue", prop: "name" })
        inserteditem.gst_treatment = getdropdownvalue({ formValues: action.payload, field: "gstinTreatmentSelectedValue", prop: "id" })
        inserteditem.gstin = action.payload.hasOwnProperty("gstin") ? action.payload.gstin : ""
        inserteditem.state = getdropdownvalue({ formValues: action.payload, field: "billingStateSelectedValue", prop: "name" })
        inserteditem.sid = getdropdownvalue({ formValues: action.payload, field: "billingStateSelectedValue", prop: "code" })
        inserteditem.country_id = getdropdownvalue({ formValues: action.payload, field: "billingCountrySelectedValue", prop: "id" })
        inserteditem.mobile = action.payload.hasOwnProperty("mobile") ? action.payload.mobile : ""
        inserteditem.partygroupcode = getdropdownvalue({ formValues: action.payload, field: "customerGroupSelectedValue", prop: "code" }).toString()
        inserteditem.groupcode = getdropdownvalue({ formValues: action.payload, field: "groupSelectedValue", prop: "id" }).toString()
        inserteditem.aadhar_no = action.payload.aadharNo
        inserteditem.pan_no = action.payload.panNo
        inserteditem.email = action.payload.hasOwnProperty("email") ? action.payload.email : ""

        let dropdownitem = {}
        dropdownitem.fieldtype = action.formtype
        dropdownitem.formname = state.drawerStack[state.drawerStack.length - 1].data !== undefined ? state.drawerStack[state.drawerStack.length - 1].data.formname : ""
        dropdownitem.index = state.drawerStack[state.drawerStack.length - 1].data !== undefined ? state.drawerStack[state.drawerStack.length - 1].data.index : ""
        dropdownitem.fieldname = state.drawerStack[state.drawerStack.length - 1].data !== undefined ? state.drawerStack[state.drawerStack.length - 1].data.field : ""
        dropdownitem.code = action.code

        let insertedAccount = {}
        insertedAccount.code = action.code.toString()
        insertedAccount.name = action.name
        insertedAccount.groupcode = getdropdownvalue({ formValues: action.payload, field: "customerGroupSelectedValue", prop: "code" }).toString()
        insertedAccount.city = getdropdownvalue({ formValues: action.payload, field: "billingCitySelectedValue", prop: "name" })
        insertedAccount.costcenter_mng = action.payload.costcenter_mng
        insertedAccount.isTds = 0
        insertedAccount.tdsCategory = getdropdownvalue({ formValues: action.payload, field: "tdsCategorySelectedValue", prop: "code" })
        insertedAccount.tdsPayee = getdropdownvalue({ formValues: action.payload, field: "tdsPayeeSelectedValue", prop: "code" })
        insertedAccount.mobile = action.payload.hasOwnProperty("mobile") ? action.payload.mobile : ""
        insertedAccount.email = action.payload.hasOwnProperty("email") ? action.payload.email : ""

        if (action.invoice_cus_sup === 0) {
          if (state.ddallaccount.length > 0) {
            return { ...state, ddcustomer_supplier: [inserteditem, ...state.ddcustomer_supplier], ddallaccount: [insertedAccount, ...state.ddallaccount], dropdownupdated: dropdownitem }
          }
          return { ...state, ddcustomer_supplier: [inserteditem, ...state.ddcustomer_supplier], dropdownupdated: dropdownitem }
        } else {
          if (action.formtype === "customer") {
            if (state.ddallaccount.length > 0) {
              return { ...state, ddcustomer: [inserteditem, ...state.ddcustomer], ddallaccount: [insertedAccount, ...state.ddallaccount], dropdownupdated: dropdownitem }
            }
            return { ...state, ddcustomer: [inserteditem, ...state.ddcustomer], dropdownupdated: dropdownitem }
          }
          if (action.formtype === "supplier") {
            if (state.ddallaccount.length > 0) {
              return { ...state, ddsupplier: [inserteditem, ...state.ddsupplier], ddallaccount: [insertedAccount, ...state.ddallaccount], dropdownupdated: dropdownitem }
            }
            return { ...state, ddsupplier: [inserteditem, ...state.ddsupplier], dropdownupdated: dropdownitem }
          }
        }
      }

      if (action.formtype === "bundleitem") {
        let inserteditem = {}
        inserteditem.code = action.code
        inserteditem.bundleProduct = "1"
        inserteditem.inactive = "0"
        inserteditem.name = action.payload.itemname
        inserteditem.advance_tracking = "0"
        inserteditem.inventory_mng = getCheckBoxPostValue(action.payload.manageInventory)
        inserteditem.pur_acc = getdropdownvalue({ formValues: action.payload, field: "PurchaseAccountSelectedValue", prop: "code" })
        inserteditem.sales_acc = getdropdownvalue({ formValues: action.payload, field: "SalesAccountSelectedValue", prop: "code" })
        inserteditem.taxscheme = getdropdownvalue({ formValues: action.payload, field: "taxSelectedValue", prop: "name" })
        inserteditem.taxschemecode = getdropdownvalue({ formValues: action.payload, field: "taxSelectedValue", prop: "code" })
        inserteditem.tax_reason = getdropdownvalue({ formValues: action.payload, field: "taxReasonSelectedValue", prop: "id" })
        inserteditem.tcs = ""
        inserteditem.tcs_code = ""
        inserteditem.hsn_code = action.payload.hsn_code
        inserteditem.item_breath = ""
        inserteditem.item_height = ""
        inserteditem.itemgroupCode = getdropdownvalue({ formValues: action.payload, field: "itemGroupSelectedValue", prop: "code" })
        inserteditem.itemgroup = getdropdownvalue({ formValues: action.payload, field: "itemGroupSelectedValue", prop: "name" })

        inserteditem.itemcateCode = getdropdownvalue({ formValues: action.payload, field: "itemSubGroupSelectedValue", prop: "code" })
        inserteditem.itemcate_name = getdropdownvalue({ formValues: action.payload, field: "itemSubGroupSelectedValue", prop: "name" })

        inserteditem.itemsubgrouplevel2Code = getdropdownvalue({ formValues: action.payload, field: "itemSubGroupLevel2SelectedValue", prop: "code" })
        inserteditem.itemsubgrouplevel2_name = getdropdownvalue({ formValues: action.payload, field: "itemSubGroupLevel2SelectedValue", prop: "name" })

        inserteditem.itemtype = getdropdownvalue({ formValues: action.payload, field: "itemtype", prop: "id" })
        inserteditem.stockku = ""
        inserteditem.unit = getdropdownvalue({ formValues: action.payload, field: "unitSelectedValue", prop: "name" })
        if (!isblank(inserteditem.unit)) {
          inserteditem.unit = inserteditem.unit.split("-")
          if (!isblank(inserteditem.unit[0])) {
            inserteditem.unit = replaceAllFn(inserteditem.unit[0], " ", "")
          }
        }
        inserteditem.unit_name = getdropdownvalue({ formValues: action.payload, field: "unitSelectedValue", prop: "unit_name" })
        inserteditem.unitcode = getdropdownvalue({ formValues: action.payload, field: "unitSelectedValue", prop: "code" })
        inserteditem.conversion_ratio = action.payload.conversion_ratio
        return { ...state, dditem: [inserteditem, ...state.dditem] }
      } else if (action.formtype === "envelopePrinting") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.payload.name
        inserteditem.detail = action.payload.detail
        return { ...state, dd_envelope_template: [inserteditem, ...state.dd_envelope_template] }
      }
      if (action.formtype === "item") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.inactive = "0"
        inserteditem.name = action.payload.itemname
        inserteditem.advance_tracking = getdropdownvalue({ formValues: action.payload, field: "advanceTrackingSelectedValue", prop: "code" })
        inserteditem.inventory_mng = getdropdownvalue({ formValues: action.payload, field: "manageInventory", prop: "id" })
        inserteditem.pur_acc = getdropdownvalue({ formValues: action.payload, field: "PurchaseAccountSelectedValue", prop: "code" })
        inserteditem.sales_acc = getdropdownvalue({ formValues: action.payload, field: "SalesAccountSelectedValue", prop: "code" })
        inserteditem.taxscheme = getdropdownvalue({ formValues: action.payload, field: "taxSelectedValue", prop: "name" })
        inserteditem.taxschemecode = getdropdownvalue({ formValues: action.payload, field: "taxSelectedValue", prop: "code" })
        inserteditem.tax_reason = getdropdownvalue({ formValues: action.payload, field: "taxReasonSelectedValue", prop: "id" })
        inserteditem.tcs = getdropdownvalue({ formValues: action.payload, field: "tcsSelectedValue", prop: "taxpercentage" })
        inserteditem.tcs_code = getdropdownvalue({ formValues: action.payload, field: "tcsSelectedValue", prop: "code" })
        inserteditem.hsn_code = action.payload.hsn_code
        inserteditem.item_breath = action.payload.itemBreath
        inserteditem.item_height = action.payload.itemHeight

        inserteditem.itemgroupCode = getdropdownvalue({ formValues: action.payload, field: "itemGroupSelectedValue", prop: "code" })
        inserteditem.itemgroup = getdropdownvalue({ formValues: action.payload, field: "itemGroupSelectedValue", prop: "name" })

        inserteditem.itemcateCode = getdropdownvalue({ formValues: action.payload, field: "itemSubGroupSelectedValue", prop: "code" })
        inserteditem.itemcate_name = getdropdownvalue({ formValues: action.payload, field: "itemSubGroupSelectedValue", prop: "name" })

        inserteditem.itemsubgrouplevel2Code = getdropdownvalue({ formValues: action.payload, field: "itemSubGroupLevel2SelectedValue", prop: "code" })
        inserteditem.itemsubgrouplevel2_name = getdropdownvalue({ formValues: action.payload, field: "itemSubGroupLevel2SelectedValue", prop: "name" })

        inserteditem.itemtype = getdropdownvalue({ formValues: action.payload, field: "itemtype", prop: "id" })
        inserteditem.conversion_ratio = action.payload.conversion_ratio
        inserteditem.stockku = action.payload.itemSKU
        inserteditem.unit = getdropdownvalue({ formValues: action.payload, field: "unitSelectedValue", prop: "name" })
        if (!isblank(inserteditem.unit)) {
          inserteditem.unit = inserteditem.unit.split("-")
          if (!isblank(inserteditem.unit[0])) {
            inserteditem.unit = replaceAllFn(inserteditem.unit[0], " ", "")
          }
        }

        //SHOW IN DD FIX
        let product_custom_fielsds = state.ddcustomfield.filter(
          (row) => row.detail.filter((it) => it.mainType === "product" && it.isActive === "1" && it.masterDetailLevel === "0" && it.showIn_dd === "1").length > 0
        )
        product_custom_fielsds.forEach((it) => {
          inserteditem[it.name] = action.payload[it.name]
        })

        if (action.list_row.length > 0) {
          const product_custom_fielsds_master = product_custom_fielsds.map((it2) => it2.name)
          action.list_row.forEach((it_list) => {
            Object.keys(it_list).forEach((it1) => {
              if (product_custom_fielsds_master.includes(it1)) {
                inserteditem[it1] = it_list[it1]
              }
            })
          })
        }

        // __cust_box_pcs
        // TODO: Do dynamic show in dd all custom fields
        if (!isblank(action.payload.__cust_box_pcs)) {
          inserteditem.__cust_box_pcs = action.payload.__cust_box_pcs
        }

        inserteditem.unit_name = getdropdownvalue({ formValues: action.payload, field: "unitSelectedValue", prop: "unit_name" })
        inserteditem.unitcode = getdropdownvalue({ formValues: action.payload, field: "unitSelectedValue", prop: "code" })

        let dropdownitem = {}
        dropdownitem.fieldtype = action.formtype
        dropdownitem.formname = state.drawerStack[state.drawerStack.length - 1].data !== undefined ? state.drawerStack[state.drawerStack.length - 1].data.formname : ""
        dropdownitem.index = state.drawerStack[state.drawerStack.length - 1].data !== undefined ? state.drawerStack[state.drawerStack.length - 1].data.index : ""
        dropdownitem.fieldname = state.drawerStack[state.drawerStack.length - 1].fieldname
        dropdownitem.code = action.code

        return { ...state, dditem: [inserteditem, ...state.dditem], dropdownupdated: dropdownitem }
      }
      if (action.formtype === "itemgroup") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        return { ...state, dditemgroup: [inserteditem, ...state.dditemgroup] }
      }
      if (action.formtype === "draft") {
        let inserteditem = cloneObj(action.payload)
        inserteditem.code = action.code.toString()
        return { ...state, dd_draft: [inserteditem, ...state.dd_draft] }
      }
      if (action.formtype === "godown") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name

        let dropdownitem = {}
        dropdownitem.fieldtype = action.formtype
        dropdownitem.formname = state.drawerStack[state.drawerStack.length - 1].data !== undefined ? state.drawerStack[state.drawerStack.length - 1].data.formname : ""
        dropdownitem.index = state.drawerStack[state.drawerStack.length - 1].data !== undefined ? state.drawerStack[state.drawerStack.length - 1].data.index : ""
        dropdownitem.fieldname = state.drawerStack[state.drawerStack.length - 1].fieldname
        dropdownitem.code = action.code

        return { ...state, ddgodown: [inserteditem, ...state.ddgodown], dropdownupdated: dropdownitem }
      }
      if (action.formtype === "user") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        return { ...state, dduser: [inserteditem, ...state.dduser] }
      }
      if (action.formtype === "productsubgroup") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        inserteditem.itemgroupname = action.payload.productgroupSelectedValue.name
        inserteditem.itemgroupcode = action.payload.productgroupSelectedValue.code.toString()
        return { ...state, ddpructsubgroup: [inserteditem, ...state.ddpructsubgroup] }
      }
      if (action.formtype === "subgroup") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.payload.name
        inserteditem.groupname = action.payload.groupSelectedValue.name
        inserteditem.groupcode = action.payload.groupSelectedValue.code.toString()
        return { ...state, subgroup: [inserteditem, ...state.subgroup] }
      }
      if (action.formtype === "productsubgrouplevel2") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        inserteditem.itemgroupname = action.payload.productgroupSelectedValue.name
        inserteditem.itemgroupcode = action.payload.productgroupSelectedValue.code.toString()
        inserteditem.itemsubgroupname = action.payload.productsubGroupSelectedValue.name
        inserteditem.itemsubgroupcode = action.payload.productsubGroupSelectedValue.code.toString()
        return { ...state, ddpructsubgrouplevel2: [inserteditem, ...state.ddpructsubgrouplevel2] }
      }
      if (action.formtype === "state") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.statecode = action.code.toString()
        inserteditem.name = action.name
        inserteditem.country = action.payload.countrySelectedValue.name
        inserteditem.country_code = action.payload.countrySelectedValue.id
        return { ...state, ddstate: [inserteditem, ...state.ddstate] }
      }
      if (action.formtype === "city") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        inserteditem.sid = action.payload.stateSelectedValue.code.toString()
        inserteditem.state = action.payload.stateSelectedValue.name
        inserteditem.statecode = action.payload.stateSelectedValue.code.toString()
        return { ...state, ddcity: [inserteditem, ...state.ddcity] }
      }
      if (action.formtype === "transport") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        inserteditem.email = action.payload.email
        inserteditem.fax = action.payload.fax
        inserteditem.gstin = action.payload.gstin
        inserteditem.mobile = action.payload.mobile
        inserteditem.contactperson = action.payload.person
        inserteditem.phone = action.payload.phone

        let dropdownitem = {}
        dropdownitem.fieldtype = action.formtype
        dropdownitem.formname = state.drawerStack[state.drawerStack.length - 1].data !== undefined ? state.drawerStack[state.drawerStack.length - 1].data.formname : ""
        dropdownitem.index = state.drawerStack[state.drawerStack.length - 1].data !== undefined ? state.drawerStack[state.drawerStack.length - 1].data.index : ""
        dropdownitem.fieldname = state.drawerStack[state.drawerStack.length - 1].fieldname
        dropdownitem.code = action.code

        return { ...state, ddtransport: [inserteditem, ...state.ddtransport], dropdownupdated: dropdownitem }
      }
      if (action.formtype === "agent") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        inserteditem.a_phone = action.payload.a_phone
        inserteditem.agentcomm = action.payload.a_commission
        inserteditem.agentCommissionOn = action.payload.agentCommissionOn.id

        let dropdownitem = {}
        dropdownitem.fieldtype = action.formtype
        dropdownitem.formname = state.drawerStack[state.drawerStack.length - 1].data !== undefined ? state.drawerStack[state.drawerStack.length - 1].data.formname : ""
        dropdownitem.index = state.drawerStack[state.drawerStack.length - 1].data !== undefined ? state.drawerStack[state.drawerStack.length - 1].data.index : ""
        dropdownitem.fieldname = state.drawerStack[state.drawerStack.length - 1].fieldname
        dropdownitem.code = action.code

        return { ...state, ddagent: [inserteditem, ...state.ddagent], dropdownupdated: dropdownitem }
      }
      if (action.formtype === "costcenter") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        inserteditem.costcenterdata = action.code.toString()
        inserteditem.parent = action.payload.parentcostcenterSelectedValue !== "" ? action.payload.parentcostcenterSelectedValue.code.toString() : ""
        inserteditem.parentname = action.payload.parentcostcenterSelectedValue !== "" ? action.payload.parentcostcenterSelectedValue.name : ""
        return { ...state, ddcostcenter: [inserteditem, ...state.ddcostcenter] }
      }
      if (action.formtype === "other_account") {
        var ddCustomer = state.ddcustomer
        var ddCustomer_Supplier = state.ddcustomer_supplier
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        inserteditem.groupcode = action.payload.GroupSelectedValue.code.toString()
        inserteditem.groupname = action.payload.GroupSelectedValue.name
        inserteditem.multiexpense = action.payload.a_phone
        inserteditem.opbal = action.payload.a_phone
        inserteditem.typename = action.payload.TypeSelectedValue.name
        inserteditem.defaultacc = action.payload.TypeSelectedValue.code.toString()

        let insertedAccount = {}
        insertedAccount.code = action.code.toString()
        insertedAccount.name = action.name
        insertedAccount.groupcode = action.payload.GroupSelectedValue.code.toString()
        insertedAccount.groupName = action.payload.GroupSelectedValue.name
        insertedAccount.city = ""
        insertedAccount.costcenter_mng = action.payload.costcenter_mng
        insertedAccount.enableGSTVoucher = action.payload.enableGSTVoucher
        insertedAccount.itemtype = action.payload.itemtype
        insertedAccount.hsn_code = action.payload.hsn_code
        insertedAccount.unit = getdropdownvalue({ formValues: action.payload, field: "unitSelectedValue", prop: "name" })
        if (!isblank(insertedAccount.unit)) {
          insertedAccount.unit = insertedAccount.unit.split("-")
          if (!isblank(insertedAccount.unit[0])) {
            insertedAccount.unit = replaceAllFn(insertedAccount.unit[0], " ", "")
          }
        }
        insertedAccount.unit_name = getdropdownvalue({ formValues: action.payload, field: "unitSelectedValue", prop: "unit_name" })
        insertedAccount.unitcode = getdropdownvalue({ formValues: action.payload, field: "unitSelectedValue", prop: "code" })

        var groupcode = getdropdownvalue({ formValues: action.payload, field: "GroupSelectedValue", prop: "code" })
        if (groupcode === "A0006" || groupcode === "L0001") {
          insertedAccount.isTds = getdropdownvalue({ formValues: action.payload, field: "TaxTypeSelectedValue", prop: "id" }) === "TDS" ? 1 : 0
        } else {
          insertedAccount.isTds = 0
        }
        insertedAccount.tdsCategory = getdropdownvalue({ formValues: action.payload, field: "tdsCategorySelectedValue", prop: "code" })
        insertedAccount.tdsPayee = getdropdownvalue({ formValues: action.payload, field: "tdsPayeeSelectedValue", prop: "code" })
        var bank = state.ddbankaccount
        if (action.payload.GroupSelectedValue.code.toString() === "A0002" || action.payload.GroupSelectedValue.code.toString() === "A0005") {
          var insertedBankAccount = {}
          insertedBankAccount.code = action.code.toString()
          insertedBankAccount.name = action.name
          insertedBankAccount.typecode = action.payload.GroupSelectedValue.code.toString() === "A0002" ? "B" : "C"
          bank = [insertedBankAccount, ...bank]
        }

        var expense = state.ddexpenseaccount
        if (action.payload.showinInvoice === true && (action.payload.GroupSelectedValue.code.toString() === "TE001" || action.payload.GroupSelectedValue.code.toString() === "E0003")) {
          var insertedExpAccount = {}
          insertedExpAccount.code = action.code.toString()
          insertedExpAccount.name = action.name
          insertedExpAccount.discount_enable = action.payload.discountEnable === true ? "1" : "0"
          insertedExpAccount.discount_level = !isblank(action.payload.discount_level) ? action.payload.discount_level : "0"
          insertedExpAccount.groupcode = action.payload.GroupSelectedValue.code.toString()
          insertedExpAccount.gst_enable = action.payload.gstEnable === true ? "1" : "0"
          insertedExpAccount.taxpercentage = "0.00"
          insertedExpAccount.taxtype = ""
          expense = [insertedExpAccount, ...expense]
        }

        var salesAccount = state.ddsalesaccount
        if (action.payload.GroupSelectedValue.code.toString() === "TI001") {
          var insertedSalesAccount = {}
          insertedSalesAccount.code = action.code.toString()
          insertedSalesAccount.name = action.name
          salesAccount = [insertedSalesAccount, ...salesAccount]
        }

        var purchaseAccount = state.ddpurchaseaccount
        if (action.payload.GroupSelectedValue.code.toString() === "TE003") {
          var insertedPurAccount = {}
          insertedPurAccount.code = action.code.toString()
          insertedPurAccount.name = action.name
          purchaseAccount = [insertedPurAccount, ...purchaseAccount]
        }

        var salesreturnAccount = state.ddsalesreturnaccount
        if (action.payload.GroupSelectedValue.code.toString() === "TE004") {
          var insertedSalesRetAccount = {}
          insertedSalesRetAccount.code = action.code.toString()
          insertedSalesRetAccount.name = action.name
          salesreturnAccount = [insertedSalesRetAccount, ...salesreturnAccount]
        }

        var purchasereturnAccount = state.ddpurchasereturnaccount
        if (action.payload.GroupSelectedValue.code.toString() === "TI002") {
          var insertedPurRetAccount = {}
          insertedPurRetAccount.code = action.code.toString()
          insertedPurRetAccount.name = action.name
          purchasereturnAccount = [insertedPurRetAccount, ...purchasereturnAccount]
        }

        var closingAccount = state.dddropdown_closingstock
        if (action.payload.GroupSelectedValue.code.toString() === "A0017") {
          var insertedClosingAccount = {}
          insertedClosingAccount.code = action.code.toString()
          insertedClosingAccount.name = action.name
          closingAccount = [insertedClosingAccount, ...closingAccount]
        }

        if (action.payload.GroupSelectedValue.code === "L0021" || action.payload.GroupSelectedValue.code.toString() === "A0019") {
          var insertedCustomer = {}
          insertedCustomer.code = action.code.toString()
          insertedCustomer.name = action.name
          insertedCustomer.inactive = "0"
          insertedCustomer.state = getdropdownvalue({ formValues: action.payload, field: "billingStateSelectedValue", prop: "name" })
          insertedCustomer.gst_treatment = getdropdownvalue({ formValues: action.payload, field: "gstinTreatmentSelectedValue", prop: "id" })
          insertedCustomer.gstin = action.payload.hasOwnProperty("gstin") ? action.payload.gstin : ""
          insertedCustomer.isTds = 0
          insertedCustomer.tdsCategory = getdropdownvalue({ formValues: action.payload, field: "tdsCategorySelectedValue", prop: "code" })
          insertedCustomer.tdsPayee = getdropdownvalue({ formValues: action.payload, field: "tdsPayeeSelectedValue", prop: "code" })
          if (action.invoice_cus_sup === 0) {
            ddCustomer_Supplier = [insertedCustomer, ...ddCustomer_Supplier]
          } else {
            ddCustomer = [insertedCustomer, ...ddCustomer]
          }
        }
        let ddagent = state.ddagent
        if (action.payload.GroupSelectedValue.code === "L0008") {
          var insertedAgent = {}
          insertedAgent.code = action.code.toString()
          insertedAgent.name = action.name
          insertedAgent.agentCommissionOn = "1"
          insertedAgent.agentcomm = "0.00"
          ddagent = [insertedAgent, ...state.ddagent]
        }
        if (action.payload.GroupSelectedValue.code === "E0002") {
          ddtaxgroup = []
          ddtaxscheme = []
          ddtcs = []
        }
        let dropdownitem = {}
        dropdownitem.fieldtype = action.formtype
        dropdownitem.formname = state.drawerStack[state.drawerStack.length - 1].data !== undefined ? state.drawerStack[state.drawerStack.length - 1].data.formname : ""
        dropdownitem.index = state.drawerStack[state.drawerStack.length - 1].data !== undefined ? state.drawerStack[state.drawerStack.length - 1].data.index : ""
        dropdownitem.fieldname = state.drawerStack[state.drawerStack.length - 1].fieldname
        dropdownitem.code = action.code.toString()

        return {
          ...state,
          ddtaxgroup: ddtaxgroup,
          ddtaxscheme: ddtaxscheme,
          ddagent: ddagent,
          ddtcs: ddtcs,
          ddother_account: [inserteditem, ...state.ddother_account],
          ddallaccount: [insertedAccount, ...state.ddallaccount],
          dropdownupdated: dropdownitem,
          ddbankaccount: bank,
          ddexpenseaccount: expense,
          ddsalesaccount: salesAccount,
          ddpurchaseaccount: purchaseAccount,
          ddsalesreturnaccount: salesreturnAccount,
          ddpurchasereturnaccount: purchasereturnAccount,
          dddropdown_closingstock: closingAccount,
          ddcustomer_supplier: ddCustomer_Supplier,
          ddcustomer: ddCustomer,
        }
      }
      if (action.formtype === "importunit") {
        let newarray = []
        action.payload.DetailGrid.forEach((detailgrid, index) => {
          let inserteditem = {}
          inserteditem.name = `${detailgrid.name} - ${detailgrid.unit_name}`
          inserteditem.code = detailgrid.code.toString()
          inserteditem.unit_name = detailgrid.unit_name
          newarray.push(inserteditem)
        })
        return { ...state, ddunit: [...newarray, ...state.ddunit] }
      }
      if (action.formtype === "importother_account") {
        var insertedOtherAccount = []
        var insertedAllAccount = []
        action.payload.DetailGrid.forEach((detailgrid, index) => {
          const inserteditem = {}
          inserteditem.code = detailgrid.code
          inserteditem.name = detailgrid.name
          inserteditem.groupcode = detailgrid.groupcode
          inserteditem.groupname = detailgrid.groupname
          inserteditem.multiexpense = 0
          inserteditem.opbal = "0 Dr"
          inserteditem.typename = detailgrid.typename
          inserteditem.defaultacc = detailgrid.type
          insertedOtherAccount.push(inserteditem)

          const insertedAccount = {}
          insertedAccount.code = detailgrid.code
          insertedAccount.name = detailgrid.name
          insertedAccount.groupcode = detailgrid.groupcode
          insertedAccount.isTds = 0
          insertedAccount.tdsCategory = null
          insertedAccount.tdsPayee = null
          insertedAccount.groupName = detailgrid.groupname
          insertedAccount.city = ""
          insertedAccount.costcenter_mng = "0"
          insertedAllAccount.push(insertedAccount)
        })
        return { ...state, ddother_account: [...insertedOtherAccount, ...state.ddother_account], ddallaccount: [...insertedAllAccount, ...state.ddallaccount] }
      }
      if (action.formtype === "importlabel_setting") {
        let newarray = []
        action.payload.DetailGrid.forEach((detailgrid, index) => {
          let inserteditem = {}
          inserteditem.name = detailgrid.name
          inserteditem.code = action.code.find((item) => item.code === detailgrid.code).insert_code.toString()
          newarray.push(inserteditem)
        })
        return { ...state, ddlabelsetting: [...newarray, ...state.ddlabelsetting] }
      }
      if (action.formtype === "label_setting") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        return { ...state, ddlabelsetting: [inserteditem, ...state.ddlabelsetting] }
      }
      if (action.formtype === "wiresender") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        return { ...state, ddsender: [inserteditem, ...state.ddsender] }
      }
      if (action.formtype === "beneficiary") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        return { ...state, ddbeneficiary: [inserteditem, ...state.ddbeneficiary] }
      }
      if (action.formtype === "customField") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.dataType = action.payload.dataType.id
        inserteditem.cal_total = action.payload.cal_total
        inserteditem.editor_size = action.payload.editor_size
        inserteditem.isNumeric = action.payload.isNumeric
        inserteditem.name = `__cust_${replaceAllFn(action.payload.labelName.toLowerCase(), " ", "_")}`
        if (action.payload.labelName.includes("dot")) {
          inserteditem.labelName = action.payload.labelName.replace(/dot/g, ".")
        } else {
          inserteditem.labelName = action.payload.labelName
        }
        inserteditem.linkwith = action.payload.linkwith
        inserteditem.dropDownMulti = action.payload.dropDownMulti
        inserteditem.decimalPoint = action.payload.decimalPointSelectedValue
        inserteditem.cal_total = action.payload.cal_total
        //inserteditem.sortNo = action.payload.sort
        inserteditem.detail = action.payload.detail.map((item, index) => {
          const updateditem = {}
          updateditem.code = ""
          updateditem.custom_field_code = action.code.toString()
          updateditem.defaultValue = item.defaultValue
          updateditem.isActive = item.isActive?.id
          updateditem.isDisabled = item.isDisabled?.id
          updateditem.isHidden = item.isHidden?.id
          updateditem.manualRender = item.manualRender
          updateditem.showInList = item.showInList?.id
          updateditem.isMandatory = item.isMandatory?.id
          updateditem.masterDetailLevel = item.masterDetailLevel?.id
          updateditem.printWithItem = item.printWithItem
          updateditem.showFieldsin = item.showFieldsin?.id
          updateditem.showInMasterPrint = item.showInMasterPrint?.id
          updateditem.showInPrint = item.showInPrint?.id
          updateditem.width = item.width
          updateditem.gridWidth = item.gridWidth
          updateditem.Sort = item.Sort
          return updateditem
        })
        inserteditem.decimalPoint = action.payload.decimalPointSelectedValue
        if (action.payload.dataType.id === "5" || action.payload.dataType.id === "6") {
          if (!isblank(action.payload.dropDownOptions) && action.payload.dropDownOptions.length > 0) {
            inserteditem.dropDownOptions = action.payload.dropDownOptions.map((item, index) => {
              const dropDownOptions = {}
              dropDownOptions.code = ""
              dropDownOptions.custom_field_code = action.code.toString()
              dropDownOptions.name = item
              return dropDownOptions
            })
          } else {
            inserteditem.dropDownOptions = []
          }
        } else {
          inserteditem.dropDownOptions = []
        }

        if (action.payload.dataType.id === "5" || action.payload.dataType.id === "6") {
          return { ...state, ddcustomfield: [], sidebarloading: false, loading: false }
        } else {
          var ddcustomfield = state.ddcustomfield
          ddcustomfield.splice(parseInt(action.payload.sort), 0, inserteditem)
          return { ...state, ddcustomfield: ddcustomfield, sidebarloading: false, loading: false }
        }
      }
      if (action.formtype === "customPage") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        return { ...state, ddcustomPage: [inserteditem, ...state.ddcustomPage] }
      }
      if (action.formtype === "taxscheme") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        return { ...state, ddtaxscheme: [inserteditem, ...state.ddtaxscheme] }
      }
      if (action.formtype === "importtaxscheme") {
        let newarray = []
        action.payload.DetailGrid.forEach((detailgrid, index) => {
          const inserteditem = {}
          inserteditem.name = detailgrid.name
          inserteditem.code = detailgrid.code.toString()
          newarray.push(inserteditem)
        })
        return { ...state, ddtaxscheme: [...newarray, ...state.ddtaxscheme] }
      }
      if (action.formtype === "partygroup") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        return { ...state, ddpartygroup: [inserteditem, ...state.ddpartygroup] }
      }
      if (action.formtype === "groupsms") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        return { ...state, ddsmscategory: [inserteditem, ...state.ddsmscategory] }
      }
      if (action.formtype === "country") {
        let inserteditem = {
          id: action.payload.country.code,
          name: getdropdownfieldvalue({ dropDownData: state.dd_allcountryForOnboarding, field: "id", value: action.payload.country.code, displayvalue: "name" }),
          states: state.countryallstateForOnboarding,
        }
        return { ...state, dd_country_state: [inserteditem, ...state.dd_country_state] }
      }
      if (action.formtype === "process") {
        let inserteditem = {}
        inserteditem.code = action.code.toString()
        inserteditem.name = action.name
        inserteditem.parameterized = getdropdownvalue({ formValues: action.payload, field: "parameterizedSelectedValue", prop: "id" })
        inserteditem.status = action.payload.defaultPackingSlip === true || action.payload.defaultPackingSlip === "1" ? "1" : "0"
        inserteditem.finish_stock_type = !isblank(action.payload.finish_stock_type) ? action.payload.finish_stock_type : ""
        inserteditem.autoFillPendingQty = action.payload.autoFillPendingQty
        inserteditem.inOutAtSameTime = action.payload.inOutAtSameTime
        inserteditem.default_account = action.payload.default_account
        inserteditem.next_process = action.payload.next_process
        return { ...state, ddprocess: [inserteditem, ...state.ddprocess] }
      } else {
        return { ...state }
      }
    case DROPDOWN_ITEM_UPDATED:
      if (action.formtype === "customField") {
        let updateddata = state.ddcustomfield.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.dataType = action.payload.dataType.id
          updateditem.cal_total = action.payload.cal_total
          updateditem.isNumeric = action.payload.isNumeric
          updateditem.name = `__cust_${replaceAllFn(action.payload.labelName.toLowerCase(), " ", "_")}`
          if (action.payload.labelName.includes("dot")) {
            updateditem.labelName = action.payload.labelName.replace(/dot/g, ".")
          } else {
            updateditem.labelName = action.payload.labelName
          }
          updateditem.editor_size = action.payload.editor_size
          updateditem.linkwith = action.payload.linkwith
          updateditem.dropDownMulti = action.payload.dropDownMulti
          updateditem.decimalPoint = action.payload.decimalPointSelectedValue
          updateditem.cal_total = action.payload.cal_total
          //updateditem.sortNo = action.payload.sort
          updateditem.detail = action.payload.detail.map((detailitem, index) => {
            const updatedDetail = {}
            updatedDetail.code = ""
            updatedDetail.custom_field_code = action.code
            updatedDetail.defaultValue = detailitem.defaultValue
            updatedDetail.isActive = detailitem.isActive.id
            updatedDetail.isDisabled = detailitem.isDisabled.id
            updatedDetail.isHidden = detailitem.isHidden.id
            updatedDetail.manualRender = detailitem.manualRender
            updatedDetail.showInList = detailitem.showInList.id
            updatedDetail.isMandatory = detailitem.isMandatory.id
            updatedDetail.masterDetailLevel = detailitem.masterDetailLevel.id
            updatedDetail.printWithItem = detailitem.printWithItem
            updatedDetail.showFieldsin = detailitem?.showFieldsin?.id
            updatedDetail.showInMasterPrint = detailitem.showInMasterPrint.id
            updatedDetail.showInPrint = detailitem.showInPrint.id
            updatedDetail.width = detailitem.width
            updatedDetail.gridWidth = detailitem.gridWidth
            updatedDetail.Sort = detailitem.Sort
            return updatedDetail
          })
          updateditem.decimalPoint = action.payload.decimalPointSelectedValue
          if (action.payload.dataType.id === "5" || action.payload.dataType.id === "6") {
            updateditem.dropDownOptions = action.payload.dropDownOptions.map((optionitem, index) => {
              const dropDownOptions = { ...optionitem }
              dropDownOptions.name = optionitem.name
              return dropDownOptions
            })
          } else {
            updateditem.dropDownOptions = []
          }
          return updateditem
        })
        return { ...state, ddcustomfield: updateddata, sidebarloading: false, loading: false }
      } else if (action.formtype === "city") {
        let updateddata = state.ddcity.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          updateditem.sid = action.payload.stateSelectedValue.code
          updateditem.state = action.payload.stateSelectedValue.name
          updateditem.statecode = action.payload.stateSelectedValue.code
          return updateditem
        })
        return { ...state, ddcity: updateddata }
      } else if (action.formtype === "country") {
        let updateddata = state.dd_country_state.map((item, index) => {
          if (convertIntif(item.id) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          return updateditem
        })
        return { ...state, dd_country_state: updateddata }
      } else if (action.formtype === "envelopePrinting") {
        let updateddata = state.dd_envelope_template.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.payload.name
          updateditem.detail = action.payload.detail
          return updateditem
        })
        return { ...state, dd_envelope_template: updateddata }
      } else if (action.formtype === "draft") {
        let updateddata = state.dd_draft.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          Object.keys(action.payload).forEach((e) => {
            if (e !== "update_code") {
              updateditem[e] = action.payload[e]
            }
          })
          return updateditem
        })
        return { ...state, dd_draft: updateddata }
      } else if (action.formtype === "wiresender") {
        let updateddata = state.ddsender.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          return updateditem
        })
        return { ...state, ddsender: updateddata }
      } else if (action.formtype === "customPage") {
        let updateddata = state.ddcustomPage.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          return updateditem
        })
        return { ...state, ddcustomPage: updateddata }
      } else if (action.formtype === "beneficiary") {
        let updateddata = state.ddbeneficiary.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          return updateditem
        })
        return { ...state, ddbeneficiary: updateddata }
      }
      if (action.formtype === "groupsms") {
        let updateddata = state.ddsmscategory.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          return updateditem
        })
        return { ...state, ddsmscategory: updateddata }
      } else if (action.formtype === "costcenter") {
        let updateddata = state.ddcostcenter.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          updateditem.parent = action.payload.parentcostcenterSelectedValue?.code
          updateditem.parentname = action.payload.parentcostcenterSelectedValue?.name
          return updateditem
        })
        return { ...state, ddcostcenter: updateddata }
      } else if (action.formtype === "productsubgroup") {
        let updateddata = state.ddpructsubgroup.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          updateditem.itemgroupname = action.payload.productgroupSelectedValue.name
          updateditem.itemgroupcode = action.payload.productgroupSelectedValue.code
          return updateditem
        })
        return { ...state, ddpructsubgroup: updateddata }
      } else if (action.formtype === "subgroup") {
        let updateddata = state.subgroup.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.payload.name
          updateditem.groupname = action.payload.groupSelectedValue.name
          updateditem.groupcode = action.payload.groupSelectedValue.code
          return updateditem
        })
        return { ...state, subgroup: updateddata }
      } else if (action.formtype === "productsubgrouplevel2") {
        let updateddata = state.ddpructsubgrouplevel2.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          updateditem.itemgroupname = action.payload.productgroupSelectedValue.name
          updateditem.itemgroupcode = action.payload.productgroupSelectedValue.code
          updateditem.itemsubgroupname = action.payload.productsubGroupSelectedValue.name
          updateditem.itemsubgroupcode = action.payload.productsubGroupSelectedValue.code
          return updateditem
        })
        return { ...state, ddpructsubgrouplevel2: updateddata }
      } else if (action.formtype === "user") {
        let updateddata = state.dduser.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          return updateditem
        })
        return { ...state, dduser: updateddata }
      } else if (action.formtype === "customer" || action.formtype === "supplier") {
        let updateddata = (action.invoice_cus_sup === 0 ? state.ddcustomer_supplier : action.formtype === "customer" ? state.ddcustomer : state.ddsupplier).map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          if (action.other_type === "status_update") {
            updateditem.inactive = action.status
          } else {
            updateditem.name = action.name
            updateditem.city = getdropdownvalue({ formValues: action.payload, field: "billingCitySelectedValue", prop: "name" })
            updateditem.gst_treatment = getdropdownvalue({ formValues: action.payload, field: "gstinTreatmentSelectedValue", prop: "id" })
            updateditem.gstin = action.payload.hasOwnProperty("gstin") ? action.payload.gstin : ""
            updateditem.state = getdropdownvalue({ formValues: action.payload, field: "billingStateSelectedValue", prop: "name" })
            updateditem.sid = getdropdownvalue({ formValues: action.payload, field: "billingStateSelectedValue", prop: "code" })
            updateditem.country_id = getdropdownvalue({ formValues: action.payload, field: "billingCountrySelectedValue", prop: "id" })
            updateditem.mobile = action.payload.hasOwnProperty("mobile") ? action.payload.mobile : ""
            updateditem.partygroupcode = getdropdownvalue({ formValues: action.payload, field: "customerGroupSelectedValue", prop: "code" })
            updateditem.groupcode = getdropdownvalue({ formValues: action.payload, field: "groupSelectedValue", prop: "id" })
            updateditem.aadhar_no = action.payload.aadharNo
            updateditem.pan_no = action.payload.panNo
          }
          return updateditem
        })

        let accountdata = state.ddallaccount
        if (action.other_type !== "status_update") {
          accountdata = accountdata.map((item, index) => {
            if (convertIntif(item.code) !== convertIntif(action.code)) {
              return item
            }
            const updatedAccount = { ...item }
            updatedAccount.name = action.name
            updatedAccount.groupcode = getdropdownvalue({ formValues: action.payload, field: "customerGroupSelectedValue", prop: "code" })
            updatedAccount.city = getdropdownvalue({ formValues: action.payload, field: "billingCitySelectedValue", prop: "name" })
            updatedAccount.costcenter_mng = action.payload.costcenter_mng
            updatedAccount.tdsCategory = getdropdownvalue({ formValues: action.payload, field: "tdsCategorySelectedValue", prop: "code" })
            updatedAccount.tdsPayee = getdropdownvalue({ formValues: action.payload, field: "tdsPayeeSelectedValue", prop: "code" })
            return updatedAccount
          })
        }

        if (action.invoice_cus_sup === 0) {
          return { ...state, ddcustomer_supplier: updateddata, ddallaccount: accountdata }
        } else {
          if (action.formtype === "customer") {
            return { ...state, ddcustomer: updateddata, ddallaccount: accountdata }
          } else {
            return { ...state, ddsupplier: updateddata, ddallaccount: accountdata }
          }
        }
      } else if (action.formtype === "other_account") {
        let updateddata = state.ddother_account.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          updateditem.groupcode = action.payload.GroupSelectedValue.code
          updateditem.groupname = action.payload.GroupSelectedValue.name
          updateditem.groupName = action.payload.GroupSelectedValue.name
          updateditem.multiexpense = action.payload.a_phone
          updateditem.opbal = action.payload.a_phone
          updateditem.typename = action.payload.TypeSelectedValue.name
          updateditem.defaultacc = action.payload.TypeSelectedValue.code
          return updateditem
        })

        let accountdata = state.ddallaccount.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updatedAccount = { ...item }
          updatedAccount.name = action.name
          updatedAccount.groupcode = action.payload.GroupSelectedValue.code
          updatedAccount.groupName = action.payload.GroupSelectedValue.name
          var groupcode = getdropdownvalue({ formValues: action.payload, field: "GroupSelectedValue", prop: "code" })
          if (groupcode === "A0006" || groupcode === "L0001") {
            updatedAccount.isTds = getdropdownvalue({ formValues: action.payload, field: "TaxTypeSelectedValue", prop: "id" }) === "TDS" ? 1 : 0
          } else {
            updatedAccount.isTds = 0
          }
          updatedAccount.tdsCategory = getdropdownvalue({ formValues: action.payload, field: "tdsCategorySelectedValue", prop: "code" })
          updatedAccount.tdsPayee = getdropdownvalue({ formValues: action.payload, field: "tdsPayeeSelectedValue", prop: "code" })

          updatedAccount.enableGSTVoucher = action.payload.enableGSTVoucher
          updatedAccount.itemtype = action.payload.itemtype
          updatedAccount.hsn_code = action.payload.hsn_code
          updatedAccount.unit = getdropdownvalue({ formValues: action.payload, field: "unitSelectedValue", prop: "name" })
          if (!isblank(updatedAccount.unit)) {
            updatedAccount.unit = updatedAccount.unit.split("-")
            if (!isblank(updatedAccount.unit[0])) {
              updatedAccount.unit = replaceAllFn(updatedAccount.unit[0], " ", "")
            }
          }
          updatedAccount.unit_name = getdropdownvalue({ formValues: action.payload, field: "unitSelectedValue", prop: "unit_name" })
          updatedAccount.unitcode = getdropdownvalue({ formValues: action.payload, field: "unitSelectedValue", prop: "code" })

          return updatedAccount
        })

        if (action.payload.GroupSelectedValue.code === "E0002") {
          ddtaxgroup = []
          ddtaxscheme = []
          ddtcs = []
        }

        return { ...state, ddother_account: updateddata, ddallaccount: accountdata, ddtaxgroup: ddtaxgroup, ddtaxscheme: ddtaxscheme, ddtcs: ddtcs }
      } else if (action.formtype === "itemgroup") {
        let updateddata = state.dditemgroup.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          return updateditem
        })
        return { ...state, dditemgroup: updateddata }
      } else if (action.formtype === "process") {
        let updateddata = state.ddprocess.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          updateditem.parameterized = getdropdownvalue({ formValues: action.payload, field: "parameterizedSelectedValue", prop: "id" })
          updateditem.status = action.payload.defaultPackingSlip === true || action.payload.defaultPackingSlip === "1" ? "1" : "0"
          updateditem.finish_stock_type = !isblank(action.payload.finish_stock_type) ? action.payload.finish_stock_type : ""
          updateditem.autoFillPendingQty = action.payload.autoFillPendingQty
          updateditem.inOutAtSameTime = action.payload.inOutAtSameTime
          updateditem.default_account = action.payload.default_account
          updateditem.next_process = action.payload.next_process
          return updateditem
        })
        return { ...state, ddprocess: updateddata }
      } else if (action.formtype === "state") {
        let updateddata = state.ddstate.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.code = action.code
          updateditem.statecode = action.code
          updateditem.name = action.name
          updateditem.country = action.payload.countrySelectedValue.name
          updateditem.country_code = action.payload.countrySelectedValue.id
          return updateditem
        })
        return { ...state, ddstate: updateddata }
      } else if (action.formtype === "agent") {
        let updateddata = state.ddagent.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.code = action.code
          updateditem.name = action.name
          updateditem.a_phone = action.payload.a_phone
          updateditem.agentcomm = action.payload.a_commission
          updateditem.agentCommissionOn = action.payload.agentCommissionOn.id
          return updateditem
        })
        return { ...state, ddagent: updateddata }
      } else if (action.formtype === "transport") {
        let updateddata = state.ddtransport.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.code = action.code
          updateditem.name = action.name
          updateditem.email = action.payload.email
          updateditem.fax = action.payload.fax
          updateditem.gstin = action.payload.gstin
          updateditem.mobile = action.payload.mobile
          updateditem.contactperson = action.payload.person
          updateditem.phone = action.payload.phone
          return updateditem
        })
        return { ...state, ddtransport: updateddata }
      } else if (action.formtype === "taxscheme") {
        let updateddata = state.ddtaxscheme.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          return updateditem
        })
        return { ...state, ddtaxscheme: updateddata }
      } else if (action.formtype === "godown") {
        let updateddata = state.ddgodown.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          return updateditem
        })
        return { ...state, ddgodown: updateddata }
      } else if (action.formtype === "item") {
        let updateddata = state.dditem.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          if (action.other_type === "status_update") {
            updateditem.inactive = action.status
          } else {
            updateditem.name = action.payload.itemname
            updateditem.advance_tracking = getdropdownvalue({ formValues: action.payload, field: "advanceTrackingSelectedValue", prop: "code" })
            updateditem.inventory_mng = getdropdownvalue({ formValues: action.payload, field: "manageInventory", prop: "id" })
            updateditem.item_category = getdropdownvalue({ formValues: action.payload, field: "itemGroupSelectedValue", prop: "code" })
            updateditem.pur_acc = getdropdownvalue({ formValues: action.payload, field: "PurchaseAccountSelectedValue", prop: "code" })
            updateditem.sales_acc = getdropdownvalue({ formValues: action.payload, field: "SalesAccountSelectedValue", prop: "code" })
            updateditem.taxscheme = getdropdownvalue({ formValues: action.payload, field: "taxSelectedValue", prop: "name" })
            updateditem.taxschemecode = getdropdownvalue({ formValues: action.payload, field: "taxSelectedValue", prop: "code" })
            updateditem.tax_reason = getdropdownvalue({ formValues: action.payload, field: "taxReasonSelectedValue", prop: "id" })
            updateditem.tcs = getdropdownvalue({ formValues: action.payload, field: "tcsSelectedValue", prop: "taxpercentage" })
            updateditem.tcs_code = getdropdownvalue({ formValues: action.payload, field: "tcsSelectedValue", prop: "code" })
            updateditem.hsn_code = action.payload.hsn_code
            updateditem.conversion_ratio = action.payload.conversion_ratio
            updateditem.item_breath = action.payload.itemBreath
            updateditem.item_category = getdropdownvalue({ formValues: action.payload, field: "itemSubGroupSelectedValue", prop: "code" })
            updateditem.item_height = action.payload.itemHeight
            updateditem.itemtype = getdropdownvalue({ formValues: action.payload, field: "itemtype", prop: "id" })
            updateditem.stockku = action.payload.itemSKU
            updateditem.unit = getdropdownvalue({ formValues: action.payload, field: "unitSelectedValue", prop: "name" })
            if (!isblank(updateditem.unit)) {
              updateditem.unit = updateditem.unit.split("-")
              if (!isblank(updateditem.unit[0])) {
                updateditem.unit = replaceAllFn(updateditem.unit[0], " ", "")
              }
            }
            updateditem.unit_name = getdropdownvalue({ formValues: action.payload, field: "unitSelectedValue", prop: "unit_name" })
            updateditem.unitcode = getdropdownvalue({ formValues: action.payload, field: "unitSelectedValue", prop: "code" })
            updateditem.itemgroupCode = getdropdownvalue({ formValues: action.payload, field: "itemGroupSelectedValue", prop: "code" })
            updateditem.itemgroup = getdropdownvalue({ formValues: action.payload, field: "itemGroupSelectedValue", prop: "name" })

            updateditem.itemcateCode = getdropdownvalue({ formValues: action.payload, field: "itemSubGroupSelectedValue", prop: "code" })
            updateditem.itemcate_name = getdropdownvalue({ formValues: action.payload, field: "itemSubGroupSelectedValue", prop: "name" })

            updateditem.itemsubgrouplevel2Code = getdropdownvalue({ formValues: action.payload, field: "itemSubGroupLevel2SelectedValue", prop: "code" })
            updateditem.itemsubgrouplevel2_name = getdropdownvalue({ formValues: action.payload, field: "itemSubGroupLevel2SelectedValue", prop: "name" })

            //SHOW IN DD FIX
            let product_custom_fielsds = state.ddcustomfield.filter(
              (row) => row.detail.filter((it) => it.mainType === "product" && it.isActive === "1" && it.masterDetailLevel === "0" && it.showIn_dd === "1").length > 0
            )
            product_custom_fielsds.forEach((it) => {
              updateditem[it.name] = action.payload[it.name]
            })

            if (action.list_row.length > 0) {
              const product_custom_fielsds_master = product_custom_fielsds.map((it2) => it2.name)
              action.list_row.forEach((it_list) => {
                Object.keys(it_list).forEach((it1) => {
                  if (product_custom_fielsds_master.includes(it1)) {
                    updateditem[it1] = it_list[it1]
                    //updateditem[it1] + "--" + it_list[it1])
                  }
                })
              })
            }
          }
          return updateditem
        })
        return { ...state, dditem: updateddata }
      } else if (action.formtype === "partygroup") {
        let updateddata = state.ddpartygroup.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          return updateditem
        })
        return { ...state, ddpartygroup: updateddata }
      } else if (action.formtype === "label_setting") {
        let updateddata = state.ddlabelsetting.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.code)) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = action.name
          return updateditem
        })
        return { ...state, ddlabelsetting: updateddata }
      } else {
        return { ...state }
      }

    case CUSTOMFIELD_OPTION_DELETED:
      if (action.formtype === "customField") {
        let updateddata = state.ddcustomfield.filter((row) => parseInt(row.code) === parseInt(action.payload.DropDowncode))

        let updateddatarow = updateddata[0].dropDownOptions.filter((row) => convertIntif(row.code) !== convertIntif(action.payload.code))

        let updateddataoptions = state.ddcustomfield.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.payload.DropDowncode)) {
            return item
          }
          const updatedoptions = { ...item }
          updatedoptions.dropDownOptions = updateddatarow
          return updatedoptions
        })
        return { ...state, ddcustomfield: updateddataoptions, sidebarloading: false, loading: false }
      }

      return { ...state }
    case DROPDOWN_ITEM_DELETED:
      if (action.formtype === "customer" || action.formtype === "supplier") {
        if (action.invoice_cus_sup === 0) {
          return {
            ...state,
            ddcustomer_supplier: multiDelete({ action, array: state.ddcustomer_supplier }),
            ddallaccount: multiDelete({ action, array: state.ddallaccount }),
            recentAllAccount: multiDeleteRecent({ action, array: state.recentAllAccount }),
          }
        } else {
          if (action.formtype === "customer") {
            return {
              ...state,
              ddcustomer: multiDelete({ action, array: state.ddcustomer }),
              ddallaccount: multiDelete({ action, array: state.ddallaccount }),
              recentAllAccount: multiDeleteRecent({ action, array: state.recentAllAccount }),
            }
          } else if (action.formtype === "supplier") {
            return {
              ...state,
              ddsupplier: multiDelete({ action, array: state.ddsupplier }),
              ddallaccount: multiDelete({ action, array: state.ddallaccount }),
              recentAllAccount: multiDeleteRecent({ action, array: state.recentAllAccount }),
            }
          }
        }
      } else if (action.formtype === "draft") {
        var dd_draft = multiDelete({ action, array: state.dd_draft })
        return { ...state, dd_draft: dd_draft }
      } else if (action.formtype === "envelopePrinting") {
        var dd_envelope_template = multiDelete({ action, array: state.dd_envelope_template })
        return { ...state, dd_envelope_template: dd_envelope_template }
      } else if (action.formtype === "country") {
        var dd_country_state = state.dd_country_state.map((e) => {
          const updated = { ...e }
          updated.code = e.id
          return updated
        })
        dd_country_state = multiDelete({ action, array: dd_country_state })
        dd_country_state = dd_country_state.map((e) => {
          const updated = { ...e }
          updated.id = e.code
          return updated
        })
        return { ...state, dd_country_state: dd_country_state }
      } else if (action.formtype === "item") {
        return { ...state, dditem: multiDelete({ action, array: state.dditem }), recentItems: multiDeleteRecent({ action, array: state.recentItems }) }
      } else if (action.formtype === "user") {
        return { ...state, dduser: multiDelete({ action, array: state.dduser }) }
      } else if (action.formtype === "taxscheme") {
        return { ...state, ddtaxscheme: multiDelete({ action, array: state.ddtaxscheme }) }
      }
      if (action.formtype === "partygroup") {
        return { ...state, ddpartygroup: multiDelete({ action, array: state.ddpartygroup }) }
      } else if (action.formtype === "itemgroup") {
        return { ...state, dditemgroup: multiDelete({ action, array: state.dditemgroup }) }
      } else if (action.formtype === "godown") {
        return { ...state, ddgodown: multiDelete({ action, array: state.ddgodown }) }
      } else if (action.formtype === "productsubgroup") {
        return { ...state, ddpructsubgroup: multiDelete({ action, array: state.ddpructsubgroup }) }
      } else if (action.formtype === "subgroup") {
        return { ...state, subgroup: multiDelete({ action, array: state.subgroup }) }
      } else if (action.formtype === "productsubgrouplevel2") {
        return { ...state, ddpructsubgrouplevel2: multiDelete({ action, array: state.ddpructsubgrouplevel2 }) }
      } else if (action.formtype === "state") {
        return { ...state, ddstate: multiDelete({ action, array: state.ddstate }) }
      } else if (action.formtype === "city") {
        return { ...state, ddcity: multiDelete({ action, array: state.ddcity }) }
      } else if (action.formtype === "transport") {
        return { ...state, ddtransport: multiDelete({ action, array: state.ddtransport }) }
      } else if (action.formtype === "agent") {
        return { ...state, ddagent: multiDelete({ action, array: state.ddagent }) }
      } else if (action.formtype === "other_account") {
        return {
          ...state,
          ddtcs: multiDelete({ action, array: state.ddtcs }),
          ddother_account: multiDelete({ action, array: state.ddother_account }),
          ddallaccount: multiDelete({ action, array: state.ddallaccount }),
          recentAllAccount: multiDeleteRecent({ action, array: state.recentAllAccount }),
        }
      } else if (action.formtype === "unit") {
        return { ...state, ddunit: multiDelete({ action, array: state.ddunit }) }
      } else if (action.formtype === "allaccount") {
        var ddcustomer_supplier = multiDelete({ action, array: state.ddcustomer_supplier })
        var ddallaccount = multiDelete({ action, array: state.ddallaccount })
        var ddcustomer = multiDelete({ action, array: state.ddcustomer })
        var ddsupplier = multiDelete({ action, array: state.ddsupplier })
        return {
          ...state,
          ddsupplier: ddsupplier,
          ddcustomer_supplier: ddcustomer_supplier,
          ddallaccount: ddallaccount,
          ddcustomer: ddcustomer,
          recentAllAccount: multiDeleteRecent({ action, array: state.recentAllAccount }),
        }
      } else if (action.formtype === "wiresender") {
        return { ...state, ddsender: multiDelete({ action, array: state.ddsender }) }
      } else if (action.formtype === "beneficiary") {
        return { ...state, ddbeneficiary: multiDelete({ action, array: state.ddbeneficiary }) }
      }
      if (action.formtype === "groupsms") {
        return { ...state, ddsmscategory: multiDelete({ action, array: state.ddsmscategory }) }
      } else if (action.formtype === "costcenter") {
        return { ...state, ddcostcenter: multiDelete({ action, array: state.ddcostcenter }) }
      } else if (action.formtype === "customField") {
        return { ...state, ddcustomfield: multiDelete({ action, array: state.ddcustomfield }) }
      } else if (action.formtype === "customPage") {
        return { ...state, ddcustomPage: multiDelete({ action, array: state.ddcustomPage }) }
      } else if (action.formtype === "label_setting") {
        return { ...state, ddlabelsetting: multiDelete({ action, array: state.ddlabelsetting }) }
      } else {
        return { ...state }
      }
    case CLEAR_DROPDOWNUPDATED:
      return { ...state, dropdownupdated: "" }
    case CUSTOMFIELD_OPTION_UPDATED:
      if (action.formtype === "customField") {
        let updateddata = state.ddcustomfield
          .filter(function (row) {
            return parseFloat(row.code) === parseFloat(action.payload.custom_field_code)
          })[0]
          .dropDownOptions.map((item, index) => {
            if (convertIntif(item.code) !== convertIntif(action.code)) {
              return item
            }
            const updateditem = { ...item }
            updateditem.name = action.payload.value
            return updateditem
          })
        let updateddataoptions = state.ddcustomfield.map((item, index) => {
          if (convertIntif(item.code) !== convertIntif(action.payload.custom_field_code)) {
            return item
          }
          const updatedoptions = { ...item }
          updatedoptions.dropDownOptions = updateddata
          return updatedoptions
        })
        return { ...state, ddcustomfield: updateddataoptions, sidebarloading: false, loading: false }
      } else {
        return { ...state }
      }

    case CUSTOMFIELD_OPTION_INSERTED:
      let inserteditem = {}
      inserteditem.code = action.insert_code
      inserteditem.name = action.payload.value
      inserteditem.custom_field_code = action.payload.custom_field_code
      inserteditem.linkwith = action.payload.linkwith
      inserteditem.dropDownMulti = action.payload.dropDownMulti
      let updateddataoptions = state.ddcustomfield.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.custom_field_code)) {
          return item
        }
        const updatedoptions = { ...item }
        updatedoptions.dropDownOptions = [inserteditem, ...updatedoptions.dropDownOptions]
        return updatedoptions
      })
      return { ...state, ddcustomfield: updateddataoptions, sidebarloading: false, loading: false }

    case DROPDOWN_INSERTED:
      if (action.formtype === "supplier" || action.formtype === "customer") {
        var ddcustomer_supplier = state.ddcustomer_supplier
        let find_cust_supp = state.ddcustomer_supplier.filter((item) => parseInt(item.code) === parseInt(action.code))
        if (action.formtype === "supplier") {
          let find = state.ddsupplier.filter((item) => parseInt(item.code) === parseInt(action.code))
          var ddsupplier = state.ddsupplier
          if (find.length === 0) {
            ddsupplier = [action.payload, ...state.ddsupplier]
          }
          if (find_cust_supp.length === 0) {
            ddcustomer_supplier = [action.payload, ...state.ddcustomer_supplier]
          }
          return { ...state, ddsupplier: ddsupplier, ddcustomer_supplier: ddcustomer_supplier }
        } else if (action.formtype === "customer") {
          let find = state.ddcustomer.filter((item) => parseInt(item.code) === parseInt(action.code))
          var ddcustomer = state.ddcustomer
          if (find.length === 0) {
            ddcustomer = [action.payload, ...state.ddcustomer]
          }
          if (find_cust_supp.length === 0) {
            ddcustomer_supplier = [action.payload, ...state.ddcustomer_supplier]
          }
          return { ...state, ddcustomer: ddcustomer, ddcustomer_supplier: ddcustomer_supplier }
        }

        let find = state.ddallaccount.filter((item) => parseInt(item.code) === parseInt(action.code))
        if (find.length === 0) {
          return { ...state, ddallaccount: [action.payload, ...state.ddallaccount] }
        } else {
          return { ...state }
        }
      } else if (action.formtype === "item") {
        let find = state.dditem.filter((item) => parseInt(item.code) === parseInt(action.code))
        if (find.length === 0) {
          return { ...state, dditem: [action.payload, ...state.dditem] }
        } else {
          return { ...state }
        }
      } else if (action.formtype === "allaccount") {
        let find = state.ddallaccount.filter((item) => parseInt(item.code) === parseInt(action.code))
        if (find.length === 0) {
          return { ...state, ddallaccount: [action.payload, ...state.ddallaccount] }
        } else {
          return { ...state }
        }
      } else {
        return { ...state }
      }
    case WINDOW_DIMENSION_UPDATED:
      return { ...state, windowWidth: action.width, windowHeight: action.height }

    case SET_ALL_LAST_DATE:
      const returnObj = {
        ...state,
      }
      if (!isblank(action.payload)) {
        Object.keys(action.payload).forEach((e) => {
          returnObj[e] = action.payload[e]
        })
      }
      return returnObj
    case SET_LAST_DATE:
      return { ...state, [action.dateKey]: action.lastDate }

    case COMMON_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case COMMON_SERVICE_CLEAR:
      return {
        ...state,
        common_error: "",
        common_data: [],
        common_totalcount: 0,
        common_rowsPerPage: 0,
        common_page: 0,
        common_order: "",
        common_orderBy: "",
        common_search_text: "",
        common_norecord: false,
        common_deleted: false,
        common_columnData: [],
        ddsalesbill: [],
        ddpurbill: [],
      }
    case COMMON_SERVICE_SUCCESS:
      let finalColumnData = []
      let final_data = []

      if (action.formtype === "item") {
        action.payload.data.result = dataSetInListItem(action.payload.data.result)
      } else if (action.formtype === "salesinvoice") {
        action.payload.data.result = dataSetInList(action.payload.data.result)
      } else if (action.formtype === "creditnote" || action.formtype === "debitnote") {
        action.payload.data.result = dataSetInListSPReturn(action.payload.data.result)
      } else if (action.formtype === "purchaseinvoice") {
        action.payload.data.result = dataSetInListPurchase(action.payload.data.result)
      } else if (action.formtype === "inward" || action.formtype === "outward") {
        action.payload.data.result = dataSetInListIO(action.payload.data.result)
      } else if (action.formtype === "journal" || action.formtype === "receipt" || action.formtype === "payment") {
        action.payload.data.result = dataSetInTrn(action.payload.data.result)
      } else if (action.formtype === "customPageFormList") {
        finalColumnData = [{ id: "code", numeric: false, visible: true, label: "Code" }]

        const setPromoListTypeColomData = (ColumnData) => {
          if (ColumnData.filter((e) => e.id === "__cust_listViewType").length > 0) {
            return ColumnData
          }
          ColumnData.push({ id: "__cust_listViewType", numeric: false, visible: true, label: "View" })
          return ColumnData
        }

        const setPromoTypeColomData = (ColumnData) => {
          if (ColumnData.filter((e) => e.id === "__cust_promo___Type").length > 0) {
            return setPromoListTypeColomData(ColumnData)
          }
          ColumnData.push({ id: "__cust_promo___Type", numeric: false, visible: true, label: "Promo Type" })
          return setPromoListTypeColomData(ColumnData)
        }

        if (action.payload.data.hasOwnProperty("showInList") && action.payload.data.showInList.length > 0) {
          var showInList = state.showInList
          showInList = action.payload.data.showInList
          finalColumnData = showInList.map((item) => {
            const updatedItem = {}
            updatedItem.id = item.name
            updatedItem.label = item.labelName
            updatedItem.visible = true
            updatedItem.numeric = parseInt(item.dataType) === cF.Decimal
            if (parseInt(item.dataType) === cF.Photo) {
              updatedItem.isImage = true
              updatedItem.disableSorting = true
              updatedItem.ifblank = ""
            }
            return updatedItem
          })
        }
        if (action.other_type === "Promo") {
          finalColumnData = setPromoTypeColomData(finalColumnData)
        }
        final_data = action.payload.data.result.map((item, index) => {
          const updateditem = { ...item }
          var filter_find = action.getdatatype.filter((itemRow) => updateditem.dataType === itemRow.id)
          if (filter_find.length > 0) {
            updateditem.dataType = filter_find[0].name
          } else {
            updateditem.dataType = ""
          }
          finalColumnData.forEach((it) => {
            if (!isblank(updateditem[it.id]) && updateditem[it.id].hasOwnProperty("name") && !isblank(updateditem[it.id].name)) {
              updateditem[it.id] = updateditem[it.id].name
            } else if (!isblank(updateditem[it.id])) {
              updateditem[it.id] = updateditem[it.id]
            } else {
              updateditem[it.id] = ""
            }
          })
          if (action.other_type === "Promo") {
            const masterData = !isblank(updateditem.master) ? JSON.parse(updateditem.master) : {}
            updateditem.__cust_promo___Type = getdropdownfieldvalue({ dropDownData: getAllPromoOption(), field: "id", value: masterData.__cust_promo___Type, displayvalue: "name" })
            updateditem.__cust_listViewType = getdropdownfieldvalue({ dropDownData: getAllListViewType(), field: "id", value: masterData.__cust_listViewType, displayvalue: "name" })
          }
          return updateditem
        })
      } else if (action.formtype === "contacts") {
        action.payload.data.result = dataSetInListContact(action.payload.data.result)
      } else if (action.formtype === "godown") {
        action.payload.data.result = dataSetInGodown(action.payload.data.result)
      } else if (action.formtype === "bot") {
        finalColumnData = botColumnData
        action.payload.data.result = dataSetInBot(action.payload.data.result)
      } else if (action.formtype === "ewaybill") {
        action.payload.data.result = dataSetInEwayBill(action.payload.data.result, action.invoice_type_detail)
        finalColumnData = [
          { id: "billno", numeric: false, visible: true, label: "No", width: 150 },
          { id: "invoice_type", numeric: false, visible: true, label: "Invoice Type", width: 150 },
          { id: "b_date", numeric: false, visible: true, label: "Date", width: 125 },
          { id: "name", numeric: false, visible: true, label: "Account Name", showlink: true },
          { id: "gstin", numeric: false, visible: true, label: "GSTIN", width: 175, display: "tax" },
          { id: "city", numeric: false, visible: true, label: "City", width: 150 },
          { id: "state", numeric: false, visible: true, label: "State", width: 150 },
          {
            id: "ewaybill_status",
            numeric: false,
            visible: true,
            label: "Status",
            width: 150,
            disableSorting: true,
            badge: {
              "Not Generated": { color: "red" },
              Generated: { color: "green" },
              Pending: { color: "yellow" },
              Cancelled: { color: "black" },
            },
          },
          { id: "netamt", numeric: true, visible: true, label: `Net Amount`, width: 200, format: "indian_rupee", currencySign: true },
        ]
      } else if (action.formtype === "label_design") {
        finalColumnData = [
          { id: "templatename", numeric: false, visible: true, label: "Name" },
          { id: "papersize", numeric: false, visible: true, label: "Paper Size", width: 250 },
          { id: "type", numeric: false, visible: true, label: "Type", width: 150 },
        ]
      } else if (action.formtype === "label_setting") {
        finalColumnData = [
          { id: "name", numeric: false, visible: true, label: "Name" },
          { id: "papersize", numeric: false, visible: true, label: "Paper Size", width: 150 },
          { id: "metric", numeric: false, visible: true, label: "Metric", width: 150 },
        ]
      } else if (action.formtype === "company") {
        action.payload.data.result = action.payload.data.result.map((item) => {
          const updateditem = { ...item }
          updateditem.address = generateDisplayAddress({
            address: item.address,
            areacode: item.street,
            city: item.city,
            state: item.state,
            country: item.country,
            pincode: item.zipcode,
          })
          return updateditem
        })
      } else if (action.formtype === "catalogueUser") {
        finalColumnData = catalogueUserColumnData
        action.payload.data.result = dataSetInListContact(action.payload.data.result)
      }
      //  action.payload.data.result = action.payload.data.result.map((item, index) => {
      //   const updateditem = { ...item }
      //   //updateditem.show_in_cat = updateditem.show_in_cat === "1" ? "Yes" : "No"
      //   return updateditem
      // })

      if (action.page === 0) {
        let updatedcoloumn = []
        action = setOrderFilterWise(action)
        if (action.formtype === "draft") {
          updatedcoloumn = state.common_columnData
        } else if (action.formtype === "customPageFormList") {
          action.payload.data.result = final_data
          updatedcoloumn = finalColumnData
        } else if (action.formtype === "ewaybill" || action.formtype === "label_design" || action.formtype === "label_setting" || action.formtype === "bot" || action.formtype === "catalogueUser") {
          updatedcoloumn = finalColumnData
        } else {
          updatedcoloumn = setColumnDataFilterWise({ data: action.payload.data, columnData: [{ id: "name", numeric: false, visible: true, label: "Name" }] })
        }
        return {
          ...state,
          common_data: action.payload.data.result,
          common_totalcount: action.payload.data.total_count,
          common_rowsPerPage: action.rowsPerPage,
          common_page: action.page,
          common_order: action.order,
          common_orderBy: action.orderBy,
          common_columnData: updatedcoloumn,
          common_norecord: action.payload.data.result.length === 0,
          common_formtype: action.formtype,
        }
      } else {
        return {
          ...state,
          common_data: action.payload.data.result,
          common_totalcount: action.payload.data.total_count,
          common_rowsPerPage: action.rowsPerPage,
          common_page: action.page,
          common_order: action.order,
          common_orderBy: action.orderBy,
          common_norecord: action.payload.data.result.length === 0,
          common_formtype: action.formtype,
        }
      }

    case ITEM_STATUS_UPDATE:
      const item_data = state.common_data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.DetailGrid[0].code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.inactive = parseInt(action.payload.DetailGrid[0].inactive) === 0 ? "Active" : "Inactive"
        return updateditem
      })
      return { ...state, common_data: item_data }

    case INVOICE_SERVICE_CONVERTED:
      let converteddata = state.common_data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.order_ref_code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.status = parseInt(action.insert_data.order_status[0].OrderStatus) === 0 ? "Pending" : parseInt(action.insert_data.order_status[0].OrderStatus) === 1 ? "Partially" : "Closed"
        return updateditem
      })
      return { ...state, common_data: converteddata }

    case ESTIMATE_SERVICE_CONVERTED:
      let convertedEstimte = state.common_data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.estimate_ref_code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.status = getdropdownfieldvalue({ dropDownData: getAllEstimateStatus(), field: "id", value: action.insert_data.order_status[0].OrderStatus.toString(), displayvalue: "name" })
        return updateditem
      })
      return { ...state, common_data: convertedEstimte }

    case BILL_SERVICE_SUCCESS:
      return { ...state, ddsalesbill: action.payload.data.result }

    case BILL_SERVICE_FAIL:
      return { ...state, ddsalesbill: [], error: action.payload, data: [], loading: false }

    case PUR_BILL_SERVICE_SUCCESS:
      return { ...state, ddpurbill: !isblank(action.payload.data) ? action.payload.data.result : [] }

    case PUR_BILL_SERVICE_FAIL:
      return { ...state, ddpurbill: [], error: action.payload, data: [], loading: false }

    case COMMON_SERVICE_INSERTED:
      /*
        const inserteditem1 = {}
        inserteditem1.code = action.insert_code
        inserteditem1.name = action.payload.name
      */

      return { ...state, common_data: [action.row, ...state.common_data], common_totalcount: parseInt(getNumberonly(state.common_totalcount)) + 1 }

    case COMMON_SERVICE_ARRAY_INSERTED:
      /*
        const inserteditem1 = {}
        inserteditem1.code = action.insert_code
        inserteditem1.name = action.payload.name
      */

      return { ...state, common_data: [...action.row, ...state.common_data], common_totalcount: parseInt(getNumberonly(state.common_totalcount)) + action.row?.length }
    case COMMON_SERVICE_DELETED:
      if (action.otherDetail === "multi_delete") {
        var finalData = []
        var deletedCount = 0
        var applyToDelete = action.payload.code.codes.map((a) => a.toString())
        if (action.response.data.meta.code === 403) {
          //Error
          var NotDelete = action.response.data.meta.message.map((a) => a.code.toString())
          finalData = state.common_data.filter((item) => {
            item.code = item.code.toString()
            if (!(applyToDelete.includes(item.code) && !NotDelete.includes(item.code))) {
              return item
            }
            deletedCount = deletedCount + 1
            return false
          })
        } //All delete
        else {
          finalData = state.common_data.filter((item) => {
            item.code = item.code.toString()
            if (!applyToDelete.includes(item.code)) {
              return item
            }
            deletedCount = deletedCount + 1
            return false
          })
        }
        return { ...state, common_data: finalData, deleted: !state.common_deleted, common_totalcount: parseInt(getNumberonly(state.common_totalcount)) - deletedCount }
      } else {
        return {
          ...state,
          common_data: state.common_data.filter((item) => item.code.toString() !== action.payload.code.toString()),
          common_deleted: !state.common_deleted,
          common_totalcount: parseInt(getNumberonly(state.common_totalcount)) - 1,
        }
      }

    case COMMON_SERVICE_UPDATED:
      const unique_Key = !isblank(action.uniqueKey) ? action.uniqueKey : "code"
      const updateddata = state.common_data.map((item, index) => {
        if (convertIntif(item[unique_Key]) !== convertIntif(action.payload.update_code)) {
          return item
        }

        return { ...item, ...action.row }
      })
      return { ...state, common_data: updateddata }
    case E_INVOICE_UPDATE:
      var updatedData = state.common_data.map((item) => {
        const updatedItem = { ...item }
        if (action.formtype === "generate_einvoice") {
          if (item.code.toString() === action.payload.invoiceCode.toString()) {
            updatedItem.e_invoice_status = "Generated"
            updatedItem.irn = action.response
            updatedItem.generateEwayBill = true
            updatedItem.ewaybill_status = getdropdownfieldvalue({ dropDownData: getewaybillstatus(), field: "id", value: action.ewayBillstatus, displayvalue: "name" })
          }
        } else if (action.formtype === "cancel_einvoice" || action.formtype === "einvoice_cancel_fb") {
          if (item.code.toString() === action.payload.invoiceCode.toString()) {
            if (action.eInvoiceCancel !== false) {
              updatedItem.e_invoice_status = action.e_invoice_status ? action.e_invoice_status : "Cancelled"
              updatedItem.irn = ""
            }
            if (action.eWayBillCancel !== false) {
              updatedItem.generateEwayBill = ""
              updatedItem.ewaybill_status = action.ewaybill_status ? action.ewaybill_status : "Cancelled"
            }
          }
        }
        return updatedItem
      })
      return { ...state, common_data: updatedData }
    case E_WAY_BILL_UPDATE:
      var updatedData = state.common_data.map((item) => {
        const updatedItem = { ...item }
        if (action.formtype === "generate_irn_ewaybill") {
          if (item.code.toString() === action.invoiceCode.toString()) {
            updatedItem.ewaybill_status = "Generated"
          }
        } else if (action.formtype === "cancel_irn_ewaybill" || action.formtype === "ewaybill_cancel_fb") {
          if (item.code.toString() === action.invoiceCode.toString()) {
            updatedItem.ewaybill_status = action.otherType === "ewaybill_cancel_fb" ? "Not Generated" : "Cancelled"
          }
        }
        return updatedItem
      })
      return { ...state, common_data: updatedData }

    case COMMON_SERVICE_ARRAY_UPDATED:
      const unique_Key1 = !isblank(action.uniqueKey) ? action.uniqueKey : "code"
      const updateddata1 = state.common_data.filter((item) => convertIntif(item[unique_Key1]) !== convertIntif(action.payload.update_code))
      return { ...state, common_data: [...action.row, ...updateddata1] }

    case POP_OVER_UPDATED:
      var updatedData = state.common_data.map((item) => {
        const updatedItem = { ...item }
        if (item.code.toString() === action.payload.update_code.toString()) {
          updatedItem[action.payload.field] = action.payload.value
        }
        return updatedItem
      })
      return { ...state, common_data: updatedData }

    case COMMON_SERVICE_FAIL:
      return {
        ...state,
        common_error: action.payload,
        common_data: [],
        common_loading: false,
        common_totalcount: 0,
        common_rowsPerPage: action.rowsPerPage,
        common_page: action.page,
        common_order: action.order,
        common_orderBy: action.orderBy,
        common_search_text: action.search_text,
      }
    case COMMON_SEARCH_TEXT_UPDATE:
      return { ...state, common_search_text: action.search_text, common_norecord: false }

    case ADD_RECENT_ITEM:
      const { recentItems } = state
      const updatedRecentItems = [action.payload, ...recentItems.filter((item) => item !== action.payload)]
      return {
        ...state,
        recentItems: updatedRecentItems.slice(0, 15), // Keep only the top 15 recent items, adjust as needed
      }
    case ADD_RECENT_ALLACCOUNT:
      const { recentAllAccount } = state
      const updatedRecentAllAccount = [action.payload, ...recentAllAccount.filter((item) => item !== action.payload)]
      return {
        ...state,
        recentAllAccount: updatedRecentAllAccount.slice(0, 15), // Keep only the top 15 recent items, adjust as needed
      }

    default:
      return state
  }
}
