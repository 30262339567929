import { todaydate } from "@project/components/utilityFunctions"
import { convertIntif } from "@project/sharedcomponents/utilityFunctions"
import {
  NEW_ORGANIZATION_INSERTED,
  ORGANIZATION_SERVICE_CLEAR,
  ORGANIZATION_SERVICE_DELETED,
  ORGANIZATION_SERVICE_FAIL,
  ORGANIZATION_SERVICE_INSERTED,
  ORGANIZATION_SERVICE_START,
  ORGANIZATION_SERVICE_SUCCESS,
} from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  norecord: false,
  inserted: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ORGANIZATION_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case ORGANIZATION_SERVICE_SUCCESS:
      return { ...state, data: action.payload.data.result, norecord: action.payload.data.result.length === 0 }
    case ORGANIZATION_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false }
    case ORGANIZATION_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case NEW_ORGANIZATION_INSERTED:
      var parentCompany = state.data.find((e) => e.company_url === action.payload.linkParentCompanyUrl)
      const newInserted = {
        c_datetime: todaydate("YMD"),
        company_url: action.payload.companyurl,
        name: action.payload.companyName,
        fyearend: parentCompany ? parentCompany.fyearend : "",
        fyearstart: parentCompany ? parentCompany.fyearstart : "",
      }
      return { ...state, data: [newInserted, ...state.data], inserted: !state.inserted }
    case ORGANIZATION_SERVICE_INSERTED:
      return { ...state, data: action.payload.data.result, norecord: action.payload.data.result.length === 0, inserted: !state.inserted }
    case ORGANIZATION_SERVICE_DELETED:
      return { ...state, data: state.data.filter((item) => convertIntif(item.id) !== convertIntif(action.payload)), deleted: !state.deleted }

    default:
      return state
  }
}
