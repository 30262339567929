import { DatetoDMY, isblank } from "@project/sharedcomponents/utilityFunctions"

export const getNotificationInvoiceDetail = (fileData, email, number) => {
  const masterTableData = fileData.invoiceDetail.master_table_data[0]
  const userDetails = fileData.invoiceDetail.user_details[0]
  var billno = masterTableData.billno

  var partyName = userDetails.name

  var date = ""
  const doc_detail = masterTableData
  if (doc_detail.invoice_type === "salesreturn") {
    billno = doc_detail.billno_str
    date = DatetoDMY(doc_detail.creditnote_date)
  } else if (doc_detail.invoice_type === "purchasereturn") {
    billno = doc_detail.billno_str
    date = DatetoDMY(doc_detail.debitnote_date)
  } else {
    billno = doc_detail.billno
    date = DatetoDMY(doc_detail.b_date)
  }
  var amount = masterTableData.netamt
  var due_date = DatetoDMY(masterTableData.due_date)
  var CompanyName = fileData.invoiceDetail.cmpy_data[0].name

  const docDetail = {
    party_name: partyName,
    billno,
    b_date: date,
    netamt: amount,
    company_name: CompanyName,
    country_id: userDetails.country_id,
    email: email,
    mobile: number,
    due_date,
  }
  return docDetail
}

export const getNotificationTransactionDetail = (fileData, email, number) => {
  const receipt_type = fileData.receipt_type
  const userDetail = receipt_type === "S" ? fileData.user_details[0] : fileData.DetailGrid[0].user_details[0]

  var billno = fileData.docno ? fileData.docno : ""
  var date = fileData.date ? DatetoDMY(fileData.date) : ""
  var amount = fileData.tot_net_amount
  var CompanyName = fileData.cmpy_data[0].name

  var countryId = ""
  var partyName = ""
  if (!isblank(receipt_type) && receipt_type === "M") {
    countryId = userDetail.country_id
    partyName = userDetail.name
  } else if (!isblank(receipt_type) && receipt_type === "S") {
    countryId = userDetail.country_id
    partyName = userDetail.name
  }

  const docDetail = {
    party_name: partyName,
    billno,
    b_date: date,
    netamt: amount,
    company_name: CompanyName,
    country_id: countryId,
    email: email,
    mobile: number,
  }
  return docDetail
}
