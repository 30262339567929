import { convertIntif } from "@project/sharedcomponents/utilityFunctions"
import { IPOSTOCK_SERVICE_DELETED, IPOSTOCK_SERVICE_FAIL, IPOSTOCK_SERVICE_INSERTED, IPOSTOCK_SERVICE_START, IPOSTOCK_SERVICE_SUCCESS, IPOSTOCK_SERVICE_UPDATED } from "../Actions/types"

const INITIAL_STATE = {
  current_tab_index: 0,
  error: "",
  loading: false,
  data: [],
  totalcount: 0,
  rowsPerPage: "",
  page: 0,
  order: "asc",
  norecord: false,
  orderBy: "code",
  search_text: "",
  deleted: false,
  columnData: [
    { id: "code", numeric: false, visible: false, label: "Id" },
    { id: "name", numeric: false, visible: true, label: "Name" },
    { id: "tot_gain_amt", numeric: true, visible: true, label: "Total Gain Amount", format: "indian_rupee", currencySign: true, width: 200 },
    { id: "tot_loss_amt", numeric: true, visible: true, label: "Total Loss Amount", format: "indian_rupee", currencySign: true, width: 200 },
    { id: "tot_amt", numeric: true, visible: true, label: "Total Amount", format: "indian_rupee", currencySign: true, width: 200 },
  ],
  metadata: "",
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IPOSTOCK_SERVICE_START:
      return { ...state, loading: true, error: "" }

    case IPOSTOCK_SERVICE_SUCCESS:
      return {
        ...state,
        data: action.payload.data.result,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        islogin: true,
        norecord: action.payload.data.result.length === 0,
      }
    case IPOSTOCK_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        data: [],
        loading: false,
        totalcount: 0,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
        norecord: true,
      }

    case IPOSTOCK_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_code
      inserteditem.name = action.payload.userSelectedValue.name
      inserteditem.tot_gain_amt = action.payload.tot_gain_amt
      inserteditem.tot_loss_amt = action.payload.tot_loss_amt
      inserteditem.tot_amt = action.payload.tot_amt

      return { ...state, data: [inserteditem, ...state.data] }
    case IPOSTOCK_SERVICE_DELETED:
      return { ...state, data: state.data.filter((item) => convertIntif(item.code) !== convertIntif(action.payload)), deleted: !state.deleted }
    case IPOSTOCK_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.update_code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.name = action.payload.userSelectedValue.name
        updateditem.tot_gain_amt = action.payload.tot_gain_amt
        updateditem.tot_loss_amt = action.payload.tot_loss_amt
        updateditem.tot_amt = action.payload.tot_amt
        return updateditem
      })

      return { ...state, data: updateddata }
    default:
      return state
  }
}
