const ErrorMessages = {
  E00001: "Name is a required field.",
  E00002: "Please select a GSTIN treatment.",
  E00003: "GSTIN is mandatory and cannot be left blank.",
  E00004: "The provided GSTIN is invalid.",
  E00005: "You must select at least one template.",
  E00006: "Account information is required.",
  E00007: "Please specify a product group.",
  E00008: "Bot name is essential and cannot be omitted.",
  E00009: "Please provide either a PAN number or Aadhar number.",
  E00010: "Kindly input a valid email address.",
  E00011: "The PAN number should consist of exactly 10 digits.",
  E00012: "The Aadhar number must be exactly 12 digits long.",
  E00013: "An email address is obligatory for this action.",
  E00014: "A valid mobile number is required.",
  E00015: "You need to select a template for this process.",
  E00016: "The paper size needs to be specified.",
  E00017: "A template name is necessary.",
  E00018: "Please provide a label name.",
  E00019: "There are no transactions available to adjust. Please select at least one transaction to proceed.",
  E00020: "Select at least one bill to continue.",
  E00021: "The total bill payment cannot exceed the total on-account payment.",
  E00022: "The total on-account payment cannot exceed the total bill payment.",
  E00023: "A reason for this action is required.",
  E00024: "Please provide the bank name.",
  E00025: "The schedule needs to be specified.",
  E00026: "The hour of the event is required.",
  E00027: "Your current password is mandatory.",
  E00028: "A new password is needed.",
  E00029: "Confirm your new password.",
  E00030: "The new password and confirm password fields do not match.",
  E00031: "A name is a required input.",
  E00032: "The height of the cheque is necessary.",
  E00033: "Specify the width of the cheque.",
  E00034: "Please provide the 'Notify To' information.",
  E00035: "At least one user needs to be selected.",
  E00036: "The envelope height is essential for this process.",
  E00037: "Specify the envelope width.",
  E00038: "Select a print orientation.",
  E00039: "Specify the type of QR code.",
  E00040: "Input the required text.",
  E00041: "A valid URL is necessary.",
  E00042: "Provide a valid phone number.",
  E00043: "Please enter a numeric value.",
  E00044: "A message is essential.",
  E00045: "Choose a different type.",
  E00046: "Specify the type of note.",
  E00047: "Select a customer for this action.",
  E00048: "Provide the location or place.",
  E00049: "Enter the bill number.",
  E00050: "Indicate the type of event.",
  E00051: "Please provide the bill amount.",
  E00052: "Ensure that all forms are closed before using shortcuts.",
  E00053: "Select an amendment date.",
  E00054: "At least one member's details must be entered.",
  E00055: "A product is required for this action.",
  E00056: "An account is required for this process.",
  E00057: "Tax information is essential.",
  E00058: "Specify the quantity.",
  E00059: "Provide a reason for 0% GST tax.",
  E00060: "Multiple 0% tax types are not allowed in a single invoice.",
  E00061: "A net amount is mandatory.",
  E00062: "The total gross amount needs to be provided.",
  E00063: "Please specify a bank for this action.",
  E00064: "A bank account is required.",
  E00065: "The date is necessary.",
  E00066: "Enter a numerical value only.",
  E00067: "Indicate the place of supply.",
  E00068: "Specify the invoice date.",
  E00069: "Provide the conversion rate.",
  E00070: "Select the export currency.",
  E00071: "Specify the export type.",
  E00072: "Select a godown for this process.",
  E00073: "The due date cannot be earlier than the invoice date.",
  E00074: "Please provide the due date.",
  E00075: "Select a product to proceed.",
  E00076: "Only items with 0% tax are allowed in this invoice.",
  E00077: "The quantity and batch quantity must be equal.",
  E00078: "The quantity cannot exceed the finish stock quantity.",
  E00079: "The total quantity cannot be zero.",
  E00080: "The total quantity cannot be zero.",
  E00081: "Ensure that the base quantity matches the total quantity.",
  E00082: "Select a financial year.",
  E00083: "A file is required for this action.",
  E00084: "Customer not found. Please select a valid customer.",
  E00085: "A username is required.",
  E00086: "Please provide a lot number.",
  E00087: "The account holder's name is mandatory.",
  E00088: "The account number is required.",
  E00089: "Confirm the account number.",
  E00090: "The account number and confirm account number do not match.",
  E00091: "An IFSC code is required.",
  E00092: "Please specify the bank and branch name.",
  E00093: "A password is essential for this action.",
  E00094: "Specify the product group.",
  E00095: "Provide the customer group.",
  E00096: "Product not found. Please select a valid product.",
  E00097: "A main account is required for this process.",
  E00098: "Please select the merge account.",
  E00099: "A main product is necessary for this action.",
  E00100: "Please select the merge product.",
  E00101: "A trade name is required.",
  E00102: "Please select at least one option.",
  E00103: "The address field cannot be empty.",
  E00104: "A state name is required.",
  E00105: "Please provide a valid zipcode.",
  E00106: "Please enter at least one batch.",
  E00107: "Please contact the administrator for assistance.",
  E00108: "Please enter a valid date.",
  E00109: "Please enter the quantity first.",
  E00110: "Select an account for this action.",
  E00111: "An amount is required.",
  E00112: "Ensure that the total quantity and adjusted quantity match.",
  E00113: "At least one bill is required for this process.",
  E00114: "Specify the quarter.",
  E00115: "The agent's name is required.",
  E00116: "Ensure that the quantities are equal.",
  E00117: "Please provide a valid numeric phone or mobile number.",
  E00118: "A cancel reason is required.",
  E00119: "Please provide a cancel remark.",
  E00120: "The passwords entered do not match.",
  E00121: "The business name is necessary.",
  E00122: "The cost center name is required.",
  E00123: "The data type must be specified.",
  E00124: "The sort number is mandatory.",
  E00125: "Please select a dropdown option.",
  E00126: "Please select the fields to show.",
  E00127: "Please select a mandatory option.",
  E00128: "Select the Show in Print option.",
  E00129: "An active status is required.",
  E00130: "Specify whether this is a master or detail.",
  E00131: "Please provide a page name.",
  E00132: "The company name is required.",
  E00133: "Please specify a country.",
  E00134: "The city name is required.",
  E00135: "Please provide a GSTIN/UIN.",
  E00136: "Select a bank or cash account.",
  E00137: "An amount is required.",
  E00138: "A group is necessary for this process.",
  E00139: "Please select a customer.",
  E00140: "A user is required for this action.",
  E00141: "An account name is required.",
  E00142: "Please specify the 'From' date.",
  E00143: "Please specify the 'To' date.",
  E00144: "Please enter at least one product.",
  E00145: "The total quantity is required.",
  E00146: "Please select a product first.",
  E00147: "Quantity cannot be empty.",
  E00148: "Please select a customer.",
  E00149: "Select at least one invoice.",
  E00150: "A tax scheme name is required.",
  E00151: "An account zipcode is necessary.",
  E00152: "Please specify the supply type.",
  E00153: "Please specify the sub-supply type.",
  E00154: "Please provide a sub-supply description.",
  E00155: "Select a document type.",
  E00156: "Specify the transaction type.",
  E00157: "Ship-to trade name is required.",
  E00158: "Ship-to address is required.",
  E00159: "Ship-to state is required.",
  E00160: "Ship-to zipcode is required.",
  E00161: "Ship-to city is required.",
  E00162: "Dispatch from trade name is required.",
  E00163: "Dispatch from address is required.",
  E00164: "Dispatch from state is required.",
  E00165: "Dispatch from city is required.",
  E00166: "Dispatch from zipcode is required.",
  E00167: "Transport is required.",
  E00168: "Vehicle number is required.",
  E00169: "Approximate distance is required.",
  E00170: "Godown name is required.",
  E00171: "Supplier is required.",
  E00172: "Both godowns cannot be the same.",
  E00173: "Quantity and parameterized quantity must match.",
  E00174: "Document number is required.",
  E00175: "Date is required.",
  E00176: "The debit/credit amount must be greater than 0.",
  E00177: "Please enter either a debit or credit amount.",
  E00178: "Cost center amounts do not match.",
  E00179: "Adjusted amount cannot exceed the debit amount.",
  E00180: "Multiple debit and credit accounts not allowed.",
  E00181: "Adjusted amount cannot exceed the credit amount.",
  E00182: "A name is required for this action.",
  E00183: "Please specify the tax type.",
  E00184: "GST treatment is required.",
  E00185: "A type is necessary for this process.",
  E00186: "Specify whether it is a credit or debit.",
  E00187: "Adjusted quantity cannot exceed total quantity.",
  E00188: "A state is required.",
  E00189: "Customer group name is required.",
  E00190: "A process name is required.",
  E00191: "A serial no is required.",
  E00192: "Please select the finish stock type.",
  E00193: "To process is required.",
  E00194: "Process quantity and total quantity must match.",
  E00195: "Quantity cannot exceed pen quantity.",
  E00196: "Packing slip number is required.",
  E00197: "Adjusted amount cannot exceed total amount.",
  E00198: "Cannot enter multiple godowns on a single packing slip.",
  E00199: "Unit code is required.",
  E00200: "Unit name is required.",
  E00201: "Quantity cannot exceed pending quantity.",
  E00202: "No invoices available for adjustment.",
  E00203: "Adjusted TDS amount cannot exceed the total amount.",
  E00204: "Adjusted amount cannot exceed the amount.",
  E00205: "Adjusted amount cannot exceed the transaction amount.",
  E00206: "Quantity cannot exceed job inward quantity.",
  E00207: "Debit note number is required.",
  E00208: "Bill date is required.",
  E00209: "Adjusted amount cannot exceed total receipt amount.",
  E00210: "Please select the shipping state.",
  E00211: "Duplicate accounts found.",
  E00212: "You must set a transaction amount.",
  E00213: "Invoice number is required.",
  E00214: "Please select a customer.",
  E00215: "Credit note number is required.",
  E00216: "Please select the invoice date.",
  E00217: "Shipping address is required.",
  E00218: "Outward number is required.",
  E00219: "GSTIN is required.",
  E00220: "Please select at least one product for outward.",
  E00221: "Please select at least one product for inward.",
  E00222: "Total outward quantity cannot be zero.",
  E00223: "Total inward quantity cannot be zero.",
  E00224: "Transport name is required.",
  E00225: "Batch reference is required.",
  E00226: "Quantity is required.",
  E00227: "At least one cost center must be entered.",
  E00228: "Please select a cost center.",
  E00229: "Enter a valid 10-digit mobile number.",
  E00230: "Serial number is required.",
  E00231: "Mobile number is not valid.",
  E00232: "Mobile number is required.",
  E00233: "Contact information is required.",
  E00234: "Please select a template.",
  E00235: "TDS is required.",
  E00236: "TDS account is required.",
  E00237: "IRN number is required.",
  E00238: "Invoice number is required.",
  E00239: "Please select a JSON file.",
  E00240: "Company name is required.",
  E00241: "Email address is not valid.",
  E00242: "Please select the 'From' date.",
  E00243: "Please select the 'To' date.",
  E00244: "A type is required.",
  E00245: "OTP is required.",
  E00246: "SQL Query is required.",
  E00247: "An action is required.",
  E00248: "A field is required.",
  E00249: "Element text is required.",
  E00250: "Element value is required.",
  E00251: "Position is required.",
  E00252: "A title is required.",
  E00253: "Subject is required.",
  E00254: "Template ID is required.",
  E00255: "Please enter the OTP.",
  E00256: "A job name is required.",
  E00257: "A command is required.",
  E00258: "Start date is required.",
  E00259: "End date is required.",
  E00260: "A description is required.",
  E00261: "Total amount is required.",
  E00262: "Company URL is required.",
  E00263: "A package is required.",
  E00264: "A server is required.",
  E00265: "An email address is required.",
  E00266: "Financial year is required.",
  E00267: "A plan is required.",
  E00268: "An image name is required.",
  E00269: "An ID is required.",
  E00270: "A menu serial number is required.",
  E00271: "Invoice type is required.",
  E00272: "AutoIncrement setting is required.",
  E00273: "A main menu is required.",
  E00274: "A menu name is required.",
  E00275: "Please select a product to continue.",
  E00276: "Payment type is required.",
  E00277: "Narration is required.",
  E00278: "A category is required.",
  E00279: "Module type is required.",
  E00280: "Unit code is required.",
  E00281: "Unit name is a required field.",
  E00282: "Username is a required field.",
  E00283: "Account type is a required field.",
  E00284: "Tab Id is a required field.",
  E00285: "Tab name is a required field.",
  E00286: "Subtab Id is a required field.",
  E00287: "Subtab name is a required field.",
  E00288: "Tab is a required field.",
  E00289: "Please enter at least one option.",
  E00290: "Value is a required field.",
  E00291: "Label is a required field.",
  E00292: "TDS Category is a required field.",
  E00293: "TDS Payee is a required field.",
  E00294: "At least one field must be shown in the list.",
  E00295: "Company code is a required field.",
  E00296: "Password is a required field.",
  E00297: "Company code is a required field.",
  E00298: "Message is a required field.",
  E00299: "First name is a required field.",
  E00300: "Last name is a required field.",
  E00301: "Child name is a required field.",
  E00302: "Parent name is a required field.",
  E00303: "Company name is a required field.",
  E00304: "Please select at least one packing slip.",
  E00305: "Employee code is a required field.",
  E00306: "Employee name is a required field.",
  E00307: "Employee is a required field.",
  E00308: "Please select a month.",
  E00309: "Please select a year.",
  E00310: "Family name is a required field.",
  E00311: "Member name is a required field.",
  E00312: "Transaction date is a required field.",
  E00313: "At least one transaction must be entered.",
  E00314: "Customer/Supplier and account should not be the same.",
  E00315: "TranType is a required field.",
  E00316: "Script name is a required field.",
  E00317: "Quantity is a required field.",
  E00318: "Rate is a required field.",
  E00319: "Class is a required field.",
  E00320: "Member is a required field.",
  E00321: "Start time is a required field.",
  E00322: "Class name is a required field.",
  E00323: "Size is a required field.",
  E00324: "Diet plan name is a required field.",
  E00325: "Title is a required field.",
  E00326: "Description is a required field.",
  E00327: "Please select a ledger account.",
  E00328: "Account is a required field.",
  E00329: "Please select an account group.",
  E00330: "Product is a required field.",
  E00331: "Batch is a required field.",
  E00332: "Please select a filter type.",
  E00333: "Please select a view type.",
  E00334: "Select a product name.",
  E00335: "Select a view type.",
  E00336: "Select a multioutstanding type.",
  E00337: "Select at least one account.",
  E00338: "Interest Receivable/Payable (%) is a required field.",
  E00339: "Interest days is a required field.",
  E00340: "Please select an interest type.",
  E00341: "Member id is a required field.",
  E00342: "Date of birth is a required field.",
  E00343: "Mobile is a required field.",
  E00344: "Membership name is a required field.",
  E00345: "Membership type is a required field.",
  E00346: "Month is a required field.",
  E00347: "Days is a required field.",
  E00348: "Note is a required field.",
  E00349: "Bank is a required field.",
  E00350: "Status is a required field.",
  E00351: "Next followup date is a required field.",
  E00352: "Assigned to is a required field.",
  E00353: "Assigned to and currently assigned cannot be the same.",
  E00354: "Notes is a required field.",
  E00355: "Subscription plan is a required field.",
  E00356: "Please select an account.",
  E00357: "Next date is a required field.",
  E00358: "Workout plan is a required field.",
  E00359: "Diet plan is a required field.",
  E00360: "Discount code is a required field.",
  E00361: "Discount(%) is a required field.",
  E00362: "Discount amount is a required field.",
  E00363: "Applied to is a required field.",
  E00364: "Membership plan is a required field.",
  E00365: "Limit is a required field.",
  E00366: "End date cannot be earlier than the start date.",
  E00367: "Notification Type is a required field.",
  E00368: "SMS type is a required field.",
  E00369: "Membership is a required field.",
  E00370: "Task name is a required field.",
  E00371: "Due date cannot be earlier than the task date.",
  E00372: "Exercise name is a required field.",
  E00373: "Primary muscle groups are required.",
  E00374: "Secondary muscle groups are required.",
  E00375: "Equipment is a required field.",
  E00376: "Mechanics is a required field.",
  E00377: "Instructions are required.",
  E00378: "Workout name is a required field.",
  E00379: "Unit is a required field.",
  E00380: "Sales account is a required field.",
  E00381: "Purchase account is a required field.",
  E00382: "Quantity cannot be 0.",
  E00383: "Catalogue name is a required field.",
  E00384: "Attribute is a required field.",
  E00385: "Option is a required field.",
  E00386: "Packing slip quantity and total quantity mismatch.",
  E00387: "City name is a required field.",
  E00388: "Total debit and credit amount must be equal.",
  E00389: "Please select a Date first for opening Balance.",
  E00390: "Account not found.",
  E00391: "Product subgroup name is a required field.",
  E00392: "Product group is a required field.",
  E00393: "Product subgroup is a required field.",
  E00394: "Merchant id is a required field.",
  E00395: "Access code is a required field.",
  E00396: "Encryption key is a required field.",
  E00397: "Please select at least one product to proceed.",
  E00398: "Product type is mandatory.",
  E00399: "Reference name is mandatory.",
  E00400: "The sum of total batch products and opening stock must be equal.",
  E00401: "The sum of total serial numbers and opening stock must be equal.",
  E00402: "Sort number must be greater than 0.",
  E00403: "Product group name is a required field.",
  E00404: "Shipping method is a required field.",
  E00405: "What customers see when selecting this method is required.",
  E00406: "Offer free shipping once the order total reaches is required.",
  E00407: "Charge per order is required.",
  E00408: "Shipping cost is required.",
  E00409: "Delivery charge per order is required.",
  E00410: "Upto is required.",
  E00411: "Godown total stock and opening stock must be the same.",
  E00412: "Pickup address is required.",
  E00413: "State/Province is required.",
  E00414: "Postal/Zip is required.",
  E00415: "Select at least one country.",
  E00416: "Supplier not found.",
  E00417: "Email address is required.",
  E00418: "Receiving bank name is required.",
  E00419: "Username is required.",
  E00420: "Account package is required.",
  E00421: "Full name is required.",
  E00422: "Bank account is required.",
  E00423: "Please select a type.",
  E00424: "Select date.",
  E00425: "Beneficiary is required.",
  E00426: "Select commission.",
  E00427: "Account group is required.",
  E00428: "Select at least one account.",
  E00429: "Please enter a valid financial year date.",
  E00430: "Sender is required.",
  E00431: "Select the month of return.",
  E00432: "Credit amount is required.",
  E00433: "Debit is required.",
  E00434: "Amount is required.",
  E00435: "No TDS payee account found.",
  E00436: "Multiple TDS payee accounts found.",
  E00437: "Packing slip quantity and lot total quantity mismatch.",
  E00438: "Account not found.",
  E00439: "First select an account.",
  E00440: "Select a godown first.",
  E00441: "Duplicate account.",
  E00442: "Sales Billno is required.",
  E00443: "Please select a bill number.",
  E00444: "Amount must be equal.",
  E00445: "GSTIN copied successfully.",
  E00446: "You are unauthorized.",
  E00447: "Excel file is required.",
  E00448: "Select at least 1 to print.",
  E00449: "Select year first.",
  E00450: "Select type first.",
  E00451: "Please select a financial year.",
  E00452: "Select DB server.",
  E00453: "Select assign field of.",
  E00454: "At least one field is required for preview.",
  E00455: "At least one field is required to show in the list.",
  E00456: "At least one field is required.",
  E00457: "Copied.",
  E00458: "Shipping address is required.",
  E00459: "Select shipping method.",
  E00460: "Select payment method.",
  E00461: "Invalid OTP.",
  E00462: "Abandoned cart code key missing.",
  E00463: "Quantity cannot be less than 1.",
  E00464: "Select a shipping address.",
  E00465: "User already exists. you can log in.",
  E00466: "Product added to cart.",
  E00467: "Mobile number not found.",
  E00468: "Email not found.",
  E00469: "Select coupon code first.",
  E00470: "% is required.",
  E00471: "Discount is required.",
  E00472: "Select an image first.",
  E00473: "Select at least one workout.",
  E00474: "Please relogin.",
  E00475: "Please select a state.",
  E00476: "Please select a product group.",
  E00477: "Please select a product subgroup.",
  E00478: "Select at least one product.",
  E00479: "Select a country.",
  E00480: "Enter a valid 10-digit mobile number.",
  E00481: "Mobile number not found.",
  E00482: "Template not selected.",
  E00483: "Cannot delete this product.",
  E00484: "Select order type.",
  E00485: "Select at least one unit.",
  E00486: "No data found.",
  E00487: "Enter only a 6-digit zipcode.",
  E00488: "Barcode is required.",
  E00489: "Pcs is required and cannot be 0.",
  E00490: "Envelope name is required.",
  E00491: "Cheque name is required.",
  E00492: "You are not authorized.",
  E00493: "Product subgroup level 2 name is required.",
  E00494: "From godown is required.",
  E00495: "To godown is required.",
  E00496: "Balance is required.",
  E00497: "Template type is required.",
  E00498: "Credit note date is required.",
  E00499: "Login Password is required.",
  E00500: "Party detail is required.",
  E00501: "Cannot upload files more than.",
  E00502: "Image type not supported.",
  E00503: "Amount is required and more than 0.",
  E00504: "Calculate commission on is required.",
  E00505: "Main city is required.",
  E00506: "Merge city is required.",
  E00507: "Billing state is required.",
  E00508: "HSN/SAC code is required.",
  E00509: "Account cannot be the same.",
  E00510: "Disabled is required.",
  E00511: "Hidden is required.",
  E00512: "Can't delete default custom field.",
  E00513: "Label print setting is required.",
  E00514: "Can't update e-Invoice for this Debit Note of type Purchase.",
  E00515: "Can't create e-Invoice for this Debit Note of type Purchase.",
  E00516: "Can't cancel e-Invoice for this Debit Note of type Purchase.",
  E00517: "Inward type is required.",
  E00518: "Outward type is required.",
  E00519: "Subgroup name is required.",
  E00520: "Barcode height is required.",
  E00521: "Can't update e-Invoice for this Credit Note of type Purchase.",
  E00522: "Can't create e-Invoice for this Credit Note of type Purchase.",
  E00523: "Can't cancel e-Invoice for this Credit Note of type Purchase.",
  E00524: "Select only one finish stock.",
  E00525: "Cr days is required.",
  E00526: "Select assign field of subtype.",
  E00527: "Lot No OR Lot Party is required.",
  E00528: "Invalid address. Ensure the address does not contain backslashes or double quotes and has a length between 1 and 100 characters.",
  E00529: "The sum of total qty and opening stock must be equal.",
  E00530: "Select the Show in Master Print option.",
  E00531: "Column gap is required.",
  E00532: "Margin is required.",
  E00533: "Table width is required.",
  E00534: "Top title is required.",
  E00535: "Bottom title is required.",
  E00536: "Height is required.",
  E00537: "Width is required.",
  E00538: "Border is required.",
  E00539: "Top alignment is required.",
  E00540: "Bottom alignment is required.",
  E00541: "This field is required.",
  E00542: "Main godown is required.",
  E00543: "Merge godown is required.",
  E00544: "Main godown & Merge godown must be different.",
  E00545: "Enter Opening Stock Value.",
  E00546: "Value cannot be zero.",
  E00547: "Please select a database to proceed.",
  E00548: "Report type is required.",
  E00549: "The module is not enabled. Please contact the administrator.",
  E00550: "Narration cannot contain double quotes or backslash.",
}

export default ErrorMessages
