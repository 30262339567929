const setting = {
  pageMargins: [30, 35, 30, 30],
  logo_height: 80,
  logo_width: 80,
}

const header = [
  {
    field_id: "header",
    style: "tableExample",
    table: {
      widths: ["*", "*", "*"],
      heights: [10, 10, 10],
      body: [
        [
          { stack: [{ text: "{left_text}", style: "head_left_text" }] },
          { stack: [{ text: "{center_text}", bold: true, style: "head_center_text" }] },
          { stack: [{ text: "{right_text}", style: "head_right_text" }] },
        ],
      ],
    },
    layout: { defaultBorder: false },
    margin: [30, 15, 30, 15],
  },
]

const style = {
  valueField: {
    fontSize: 9,
  },
  head_left_text: {
    alignment: "left",
  },
  head_center_text: {
    fontSize: 12,
    alignment: "center",
  },
  head_right_text: {
    alignment: "right",
  },
  contentTable: {
    margin: [0, 0, 0, 15],
  },
  noMarginTable: {
    margin: [0, 0, 0, 0],
  },
  left: {
    alignment: "left",
  },
  right: {
    alignment: "right",
  },
}

const bodyHeader = [
  {
    field_id: "bodyHeader",
    table: {
      widths: ["*", "*"],
      body: [
        [
          {
            columns: [
              {
                field_id: "company_logo",
                width: setting.logo_width,
                image: "{logo}",
                fit: [setting.logo_width, setting.logo_height],
                margin: [0, 0, 0, 0],
              },
              {
                width: "*",
                stack: [
                  { field_id: "company_name", text: "{companyName}", style: "valueField", bold: true, fontSize: 10.5 },
                  {
                    field_id: "company_address",
                    stack: [
                      { text: "{company_address1}", style: "valueField" },
                      { text: "{company_address2}", style: "valueField" },
                      { field_id: "company_contact", text: "Contact: {company_contact}", style: "valueField" },
                      { field_id: "company_gstin", text: "GSTIN: {company_gstin}", style: "valueField" },
                      { field_id: "company_pan", text: "PAN: {companyPAN}", style: "valueField" },
                      { field_id: "company_aadhaar", text: "Aadhaar: {company_aadhaar}", style: "valueField" },
                      { field_id: "company_OtherNumbers", text: "{companyOtherNumbers}", style: "valueField" },
                    ],
                  },
                ],
              },
            ],
            columnGap: 10,
            margin: [0, 0, 0, 0],
            border: [false, false, false, true],
          },

          {
            field_id: "billing_address_block",
            stack: [
              { field_id: "party_title", text: "To,", style: "valueField", margin: [0, 0, 0, 0] },
              { field_id: "party_name", text: "{partyName}", style: "valueField", bold: true, fontSize: 10.5 },
              {
                field_id: "party_address",
                stack: [
                  { text: "{party_address1}", style: "valueField" },
                  { text: "{party_address2}", style: "valueField" },
                  { field_id: "party_gstin", text: "GSTIN: {party_gstin}", style: "valueField" },
                  { field_id: "party_pan", text: "PAN: {companyPAN}", style: "valueField" },
                  { field_id: "party_aadhaar", text: "Aadhaar: {party_aadhaar}", style: "valueField" },
                  { field_id: "party_contact", text: "Contact: {party_contact}", style: "valueField" },
                ],
              },
            ],
            border: [false, false, false, true],
          },
        ],
      ],
    },
    layout: "noBorders",
    margin: [0, 0, 0, 15],
  },
]

const bodyFooter = [
  {
    field_id: "bodyFooter",
    columns: [
      {
        width: "*",
        table: {
          widths: ["*"],
          heights: ["auto", 40, "auto"],
          body: [
            [{ text: "Prepared By", alignment: "center" }],
            [
              {
                border: [true, true, true, true],
                text: "",
              },
            ],
            [{ text: "Signature", alignment: "center" }],
          ],
        },
        layout: { defaultBorder: false },
      },
      {
        width: "*",
        table: {
          widths: ["*"],
          heights: ["auto", 40, "auto"],
          body: [
            [{ text: "Approved By", alignment: "center" }],
            [
              {
                border: [true, true, true, true],
                text: "",
              },
            ],
            [{ text: "Signature", alignment: "center" }],
          ],
        },
        layout: { defaultBorder: false },
      },
    ],
    columnGap: 80,
    margin: [0, 0, 0, 0],
  },
]

const body = [{}]
const footer = [{}]

export default {
  header: JSON.stringify(header),
  bodyHeader: JSON.stringify(bodyHeader),
  bodyFooter: JSON.stringify(bodyFooter),
  footer: JSON.stringify(footer),
  body: JSON.stringify(body),
  setting,
  style,
}
