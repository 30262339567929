import { isblank } from "./utilityFunctions"

function arrayMax(arr, key) {
  var maxObject = arr.reduce(function (p, v) {
    return p[key] > v[key] ? p : v
  })
  return maxObject[key]
}

export const getTextMaxWidth = (content, generatePdfDoc) => {
  const temp = []
  temp.push(content)
  const pdf = generatePdfDoc({ content: temp, pageSize: { width: 595, height: 30 }, pageMargins: [0, 0, 0, 0] })
  pdf.getStream({})

  if (!isblank(pdf.docDefinition.content[0].positions) && pdf.docDefinition.content[0].positions.length > 0) {
    //return pdf.docDefinition.content[0].positions.length;
    var pageCount = arrayMax(pdf.docDefinition.content[0].positions, "pageNumber")
    return pageCount
  }
  return 0
}

export const tableLayouts = {
  newTemplateLayout: {
    hLineColor: function (i, node) {
      return "#aaa"
    },
    vLineWidth: function (i, node) {
      return 0
    },
    hLineWidth: function (i, node) {
      if (isblank(node.table.body[i - 1]) || Object.keys(node.table.body[i - 1]).length === 0 || Object.keys(node.table.body[i - 1][0]).length === 0 || node.table.body[i - 1][0].text === "") {
        return 0
      }

      if (i < node.table.body.length) {
        return 0.75
      }
      return 0
    },
    paddingTop: function (i, node) {
      if (isblank(node.table.body[i]) || Object.keys(node.table.body[i]).length === 0 || Object.keys(node.table.body[i][0]).length === 0 || node.table.body[i][0].text === "") {
        return 0
      }
      return 2
    },
    paddingBottom: function (i, node) {
      if (isblank(node.table.body[i]) || Object.keys(node.table.body[i]).length === 0 || Object.keys(node.table.body[i][0]).length === 0 || node.table.body[i][0].text === "") {
        return 0
      }
      return 2
    },
  },
  partyDetailTemplate9: {
    vLineWidth: function (i, node) {
      return 0
    },
    hLineWidth: function (i, node) {
      if (i > 2) {
        if (i < node.table.body.length) {
          return 0.75
        }
        return 0
      }
      return 0
    },
    hLineColor: function (i, node) {
      return "#aaa"
    },
  },
  otherDetailTemplate9: {
    vLineWidth: function (i, node) {
      return 0
    },
    hLineWidth: function (i, node) {
      if (i === 2 || i === 4) {
        if (isblank(node.table.body[i - 1]) || Object.keys(node.table.body[i - 1]).length === 0 || Object.keys(node.table.body[i - 1][0]).length === 0 || node.table.body[i - 1][0].text === "") {
          return 0
        }
        return 0.75
      }
      return 0
    },
    hLineColor: function (i, node) {
      return "#aaa"
    },
  },
  taxSummaryTemplate9: {
    vLineWidth: function (i, node) {
      return 0
    },
    hLineWidth: function (i, node) {
      if (i < node.table.body.length - 1) {
        return 0.75
      }
      return 0
    },
    hLineColor: function (i, node) {
      return "#aaa"
    },
  },
  zeroPadding: {
    paddingRight: function (i, node) {
      return 0
    },
    paddingLeft: function (i, node) {
      return 0
    },
  },
  borderBottom: {
    vLineWidth: function (i, node) {
      return 0
    },
    hLineWidth: function (i, node) {
      if (i > 2) {
        if (i < node.table.body.length) {
          return 0.75
        }
        return 0
      }
      return 0
    },
    hLineColor: function (i, node) {
      return "#000"
    },
  },
  borderBottomCF: {
    hLineColor: function (i, node) {
      return "#000"
    },
    vLineWidth: function (i, node) {
      return 0
    },
    hLineWidth: function (i, node) {
      if (isblank(node.table.body[i - 1]) || Object.keys(node.table.body[i - 1]).length === 0 || Object.keys(node.table.body[i - 1][0]).length === 0 || node.table.body[i - 1][0].text === "") {
        return 0
      }

      if (i < node.table.body.length) {
        return 0.75
      }
      return 0
    },
    paddingTop: function (i, node) {
      if (isblank(node.table.body[i]) || Object.keys(node.table.body[i]).length === 0 || Object.keys(node.table.body[i][0]).length === 0 || node.table.body[i][0].text === "") {
        return 0
      }
      return 2
    },
    paddingBottom: function (i, node) {
      if (isblank(node.table.body[i]) || Object.keys(node.table.body[i]).length === 0 || Object.keys(node.table.body[i][0]).length === 0 || node.table.body[i][0].text === "") {
        return 0
      }
      return 2
    },
  },
  borderBottomOD: {
    vLineWidth: function (i, node) {
      return 0
    },
    hLineWidth: function (i, node) {
      if (i === 2 || i === 4) {
        if (isblank(node.table.body[i - 1]) || Object.keys(node.table.body[i - 1]).length === 0 || Object.keys(node.table.body[i - 1][0]).length === 0 || node.table.body[i - 1][0].text === "") {
          return 0
        }
        return 0.75
      }
      return 0
    },
    hLineColor: function (i, node) {
      return "#000"
    },
  },
  borderBottomTS: {
    vLineWidth: function (i, node) {
      return 0
    },
    hLineWidth: function (i, node) {
      if (i < node.table.body.length - 1) {
        return 0.75
      }
      return 0
    },
    hLineColor: function (i, node) {
      return "#000"
    },
  },
  thermalLayout: {
    hLineWidth: function (i, node) {
      if (i === 0 || i === node.table.body.length ? 0.5 : 0) {
        return 0.5 // Draw lines for the first two rows
      }
      if (i === 2) {
        return 0.5 // Draw line after the second header row
      }
      return 0
    },
    vLineWidth: function (i, node) {
      return 0
    },
    hLineColor: function (i, node) {
      return "#000000"
    },
    paddingLeft: function (i, node) {
      return 1
    },
    paddingRight: function (i, node) {
      return 1
    },
    paddingTop: function (i, node) {
      return 1
    },
    paddingBottom: function (i, node) {
      return 1
    },
    hLineStyle: function (i, node) {
      return { dash: { length: 2, space: 2 } }
    },
  },
  borderDotted: {
    hLineWidth: function (i, node) {
      if (i === 0) {
        return 0.5 // Draw lines for the first two rows
      }
      return 0
    },
    vLineWidth: function (i, node) {
      return 0
    },
    hLineColor: function (i, node) {
      return "#000000"
    },
    hLineStyle: function (i, node) {
      return { dash: { length: 2, space: 2 } }
    },
  },
  noPB: {
    defaultBorder: false, // No borders by default
    paddingLeft: function (i, node) {
      return 0
    }, // No padding left
    paddingRight: function (i, node) {
      return 0
    }, // No padding right
    paddingTop: function (i, node) {
      return 0
    }, // No padding top
    paddingBottom: function (i, node) {
      return 0
    }, // No padding bottom
  },
}
