import { list_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, isblank, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getAccountSitesetting, getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import {
  EWAY_BILL_SEARCH_TEXT_UPDATE,
  EWAY_BILL_SERVICE_CLEAR,
  EWAY_BILL_SERVICE_FAIL,
  EWAY_BILL_SERVICE_START,
  EWAY_BILL_SERVICE_SUCCESS,
  LAST_PAGE_UPDATE,
  LOADING_STATE,
  SNACKBAR_STATE,
} from "./types"

export const ewaybill_data_clear = () => {
  return (dispatch) => {
    dispatch({
      type: EWAY_BILL_SERVICE_CLEAR,
    })
  }
}
export const ewaybillsearch_change = ({ search_text }) => {
  return (dispatch) => {
    dispatch({
      type: EWAY_BILL_SEARCH_TEXT_UPDATE,
      search_text: search_text,
    })
  }
}

export const generateNewToken = (type, functions) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", type)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "generate_ewb_token" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}
export const fetch_ewaybill_data = ({ rowsPerPage, page, order, orderBy, search_text, from_date, to_date, transactionType, ewaybillstatus, amountThreshold, functions }) => {
  if (isblank(rowsPerPage)) {
    rowsPerPage = getAccountSitesetting("pagination_size")
  }
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: EWAY_BILL_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("offset", page * rowsPerPage)
    data.append("type", "ewaybill")
    data.append("limit", rowsPerPage)
    data.append("sort_order", order)
    data.append("sort_by", orderBy)
    data.append("search_text", search_text)
    data.append("from_date", from_date)
    data.append("to_date", to_date)
    data.append("transactionType", transactionType)
    data.append("ewaybillstatus", ewaybillstatus)
    data.append("amountThreshold", amountThreshold)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "ewaybill" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          ewaybillServiceFail({ dispatch, response: response.data.meta.message, rowsPerPage, page, order, orderBy, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          ewaybillServiceSuccess({ dispatch, user: response, rowsPerPage, page, order, orderBy, search_text })
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "ewaybill",
            lastaction: "list",
            lasttime: new Date().getTime(),
            lastdata: "",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          ewaybillServiceFail({ dispatch, response: getCatchErrorMessage(error), rowsPerPage, page, order, orderBy, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const ewaybillServiceSuccess = ({ dispatch, user, rowsPerPage, page, order, orderBy, search_text }) => {
  dispatch({
    type: EWAY_BILL_SERVICE_SUCCESS,
    payload: user,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
    invoice_type_detail: getAccountSitesetting("invoice_type_detail"),
  })
}

const ewaybillServiceFail = ({ dispatch, response, rowsPerPage, page, order, orderBy, search_text }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })

  dispatch({
    type: EWAY_BILL_SERVICE_FAIL,
    payload: response,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
  })
}

// http://api.finalbooks.local/account/report/getdetail
// type:eInvoiceQRCode_fb
// code:3826

export const fixQrCode = ({ code, onSuccess, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("code", code)
    data.append("type", "eInvoiceQRCode_fb")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "eInvoiceQRCode_fb" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
          if (onSuccess) {
            onSuccess()
          }
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const clearEwayBill = ({ code, invoice_type, onSuccess, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("code", code)
    data.append("type", "ewaybill_clear")
    data.append("other_type", invoice_type)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "ewaybill_clear" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
          if (onSuccess) {
            onSuccess()
          }
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}
