import { Print } from "@material-ui/icons"
import { fbprintpdf, print_file } from "@project/components/printJSFunctions"
import { getStorage, setStorage } from "@project/components/sessionStoreFunctions"
import { generateURL, getAllStatus, getallPackingStatus, getdropdownvalue, todaydate } from "@project/components/utilityFunctions"
import { list_url, post_form_url } from "@project/sharedcomponents/apiURL"
import cF from "@project/sharedcomponents/constantData/customFieldsEnum"
import menuId from "@project/sharedcomponents/constantData/menuIdEnum"
import re from "@project/sharedcomponents/constantData/reportsEnum"
import {
  DatetoDMY,
  amount_format,
  amount_formatDecimal,
  checkCondition,
  cloneObj,
  common_getTypeWiseKeySetting,
  convertIntif,
  createDropDown,
  dataSetInListIO,
  dataSetInListPurchase,
  dataSetInListSPReturn,
  exportAs,
  generateDisplayAddress,
  getAxiosCancelToken,
  getCatchErrorMessage,
  getDueStatus,
  getNumberonly,
  getNumberonlyDecimal,
  getdropdownfieldvalue,
  isBlankCode,
  isblank,
  numForCalc,
  replaceAllFn,
  setAxiosCancelToken,
  toFixedFn,
} from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { trim } from "lodash"
import lodashMap from "lodash/map"
import lodashSum from "lodash/sum"
import React from "react"
import { change, reset } from "redux-form"
import { getPrintData, printBarcode } from "../BarCodePrinting/Lib/commonnfunctions"
import {
  getAccountRightsByID,
  getAccountSessionUserData,
  getAccountSitesetting,
  getNameFromType,
  getTransDate,
  getWindowWidth,
  getWindowYear,
  getconfigsettingdata,
  getendyear,
  getheaders,
  getstartyear,
  goToNavigate,
  handleResponse,
  postErrorOnException,
  setFormatOfInvoiceDetailGrid,
} from "../Lib/commonfunctions"
import { getNotificationInvoiceDetail, getNotificationTransactionDetail } from "../Lib/invoiceDetailFunctions"
import {
  dynamicTemplate,
  generateEwayBillPdf,
  generateGodownStockTransferPdf,
  generateInwardPdf,
  generateJournalPdf,
  generateOutwardPdf,
  generatePackingSlipPdf,
  generateProcessTransferPdf,
  generateStockTransferPdf,
  generateTransactionPdf,
  invoiceTemplate2,
  invoiceTemplate3,
} from "../Lib/printDocuments"
import { sendNotificationInvoiceFn } from "../Lib/printFunctions"
import {
  common_getPartyDetail,
  common_modalConfigProductDetail,
  // common_modalOrderDetail,
  common_modalproductDetail,
  common_openitembatchout,
  pushDrawer,
  removeDrawer,
  showdialog,
  showdrawerdialog,
  snackbarstate,
  updateDrawer,
  update_RefreshDrawerStack,
} from "./CommonActionsV2"
import {
  BILL_SERVICE_FAIL,
  BILL_SERVICE_SUCCESS,
  CASH_BANK_DETAIL_SUCCESS,
  CLOSINGDATA_SERVICE_UPDATED,
  CLOSING_BALANCE,
  COMMON_SERVICE_ARRAY_INSERTED,
  COMMON_SERVICE_ARRAY_UPDATED,
  COMMON_SERVICE_DELETED,
  COMMON_SERVICE_FAIL,
  COMMON_SERVICE_INSERTED,
  COMMON_SERVICE_START,
  COMMON_SERVICE_SUCCESS,
  COMMON_SERVICE_UPDATED,
  CONFIRM_DIALOG,
  CUSTOMFIELD_OPTION_DELETED,
  CUSTOMFIELD_OPTION_UPDATED,
  CUSTOM_FIELD_SERVICE_DELETED,
  CUSTOM_FIELD_SERVICE_INSERTED,
  CUSTOM_FIELD_SERVICE_UPDATED,
  CUSTOM_PAGE_ITEM_UPDATED,
  CUSTOM_PAGE_SERVICE_DELETED,
  DIALOG_LOADING,
  DROPDOWN_INSERTED,
  DROPDOWN_ITEM_DELETED,
  DROPDOWN_ITEM_INSERTED,
  DROPDOWN_ITEM_UPDATED,
  EMAIL_TEMPLATE_SERVICE_UPDATED,
  ESTIMATE_SERVICE_CONVERTED,
  EWAY_BILL_SERVICE_UPDATED,
  E_INVOICE_UPDATE,
  E_WAY_BILL_UPDATE,
  FORM_DEP_SERVICE_SUCCESS,
  FORM_POST_SERVICE_CLEAR,
  FORM_POST_SERVICE_FAIL,
  FORM_POST_SERVICE_START,
  FORM_POST_SERVICE_SUCCESS,
  GSTR2_SERVICE_UPDATED,
  IMPORT_ACCOUNT_SERVICE_INSERTED,
  IMPORT_LABEL_SETTING_SERVICE_SUCCESS,
  IMPORT_UNIT_INSERTED_SUCCESS,
  IMPORT_UNIT_SERVICE_INSERTED,
  INVOICE_SERVICE_CONVERTED,
  IPOSTOCKMASTER_SERVICE_DELETED,
  IPOSTOCKMASTER_SERVICE_INSERTED,
  IPOSTOCKMASTER_SERVICE_UPDATED,
  IPOSTOCKPARTY_SERVICE_DELETED,
  IPOSTOCKPARTY_SERVICE_INSERTED,
  IPOSTOCKPARTY_SERVICE_UPDATED,
  IPOSTOCK_SERVICE_DELETED,
  IPOSTOCK_SERVICE_INSERTED,
  IPOSTOCK_SERVICE_UPDATED,
  LAST_PAGE_UPDATE,
  LOADING_SNACKBAR_STATE,
  LOADING_STATE,
  OPBAL_SERVICE_UPDATED,
  ORGANIZATION_SERVICE_DELETED,
  PAGE_FORM_POST_SERVICE_CLEAR,
  PAGE_FORM_POST_SERVICE_FAIL,
  PAGE_FORM_POST_SERVICE_START,
  PAGE_FORM_POST_SERVICE_SUCCESS,
  PARTYPRICE_SERVICE_UPDATED,
  POP_OVER_LOADING_STATE,
  POP_OVER_UPDATED,
  PRODUCT_SERIAL_SERVICE_SUCCESS,
  PROGRESS_LOADING_STATE,
  PUR_BILL_SERVICE_FAIL,
  PUR_BILL_SERVICE_SUCCESS,
  REPORT_DATA_UPDATED,
  REPORT_SERVICE_SUCCESS,
  SETMENU,
  SET_LAST_DATE,
  SITE_SETTING_UPDATED,
  SMS_TEMPLATE_SERVICE_UPDATED,
  SNACKBAR_STATE,
  UNIT_SERVICE_DELETED,
  UNIT_SERVICE_UPDATED,
  UPDATE_CONFIG_SETTING,
  UPDATE_OTHER_DETAIL,
  UPDATE_REPORT_DATA,
  UPDATE_RIGHT_DRAWER_STATE,
  UPDATE_USER_DATA,
  UPLOAD_DOCUMENT_SUCCESS,
  USER_RIGHTS_UPDATED,
  WHATSAPP_TEMPLATE_SERVICE_UPDATED,
  WIRETRANSFER_BENEFICIARY_SERVICE_DELETED,
  WIRETRANSFER_BENEFICIARY_SERVICE_INSERTED,
  WIRETRANSFER_BENEFICIARY_SERVICE_UPDATED,
  WIRETRANSFER_SENDER_SERVICE_DELETED,
  WIRETRANSFER_SENDER_SERVICE_INSERTED,
  WIRETRANSFER_SENDER_SERVICE_UPDATED,
  WIRETRANSFER_SERVICE_DELETED,
  WIRETRANSFER_SERVICE_INSERTED,
  WIRETRANSFER_SERVICE_UPDATED,
} from "./types"
import { sendNewNotifications } from "./UtilityCommonActions"

export const rightdrawerstate = ({ open, title, data = "", fieldname = "", position, width }) => {
  return (dispatch) => {
    var rights = false

    if (open === true) {
      if (title === "Add New CustomerSupplier") {
        if (fieldname === "customer") {
          rights = parseInt(getAccountRightsByID(menuId.Customer)[0], 10) === 1
        } else {
          rights = parseInt(getAccountRightsByID(menuId.CustomerSupplier)[0], 10) === 1
        }
      } else if (title === "Add New Item") {
        rights = parseInt(getAccountRightsByID(menuId.Product)[0], 10) === 1
      } else if (title === "Add New State") {
        rights = parseInt(getAccountRightsByID(menuId.State)[0], 10) === 1
      } else if (title === "Add New City") {
        rights = parseInt(getAccountRightsByID(menuId.City)[0], 10) === 1
      } else if (title === "Add New Cost Center") {
        rights = parseInt(getAccountRightsByID(menuId.CostCenter)[0], 10) === 1
      } else if (title === "Add New Account") {
        rights = parseInt(getAccountRightsByID(menuId.Account)[0], 10) === 1
      } else if (title === "Add New Product Group") {
        rights = parseInt(getAccountRightsByID(menuId.ProductGroup)[0], 10) === 1
      } else if (title === "Add New Product Sub Group") {
        rights = parseInt(getAccountRightsByID(menuId.ProductSubGroup)[0], 10) === 1
      } else if (title === "Add New Product Sub Group Level2") {
        rights = parseInt(getAccountRightsByID(menuId.ProductSubGroupLevel2)[0], 10) === 1
      } else if (title === "Add New Transport") {
        rights = parseInt(getAccountRightsByID(menuId.Transport)[0], 10) === 1
      } else if (title === "Add New Godown") {
        rights = parseInt(getAccountRightsByID(menuId.Godown)[0], 10) === 1
      } else if (title === "Add New Agent") {
        rights = parseInt(getAccountRightsByID(menuId.Agent)[0], 10) === 1
      } else if (title === "Add New Salesman") {
        rights = parseInt(getAccountRightsByID(menuId.Salesman)[0], 10) === 1
      } else if (title === "Add New Customer Group") {
        rights = parseInt(getAccountRightsByID(menuId.CustomerGroup)[0], 10) === 1
      } else if (title === "Add New Sub Group") {
        rights = parseInt(getAccountRightsByID(menuId.SubGroup)[0], 10) === 1
      } else {
        rights = true
      }
    } else {
      rights = true
    }

    if (open) {
      if (rights) {
        pushDrawer({ title, data, fieldname, position: isblank(position) ? "right" : position, width: isblank(width) ? "auto" : width, dispatch })
      } else {
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: "You are not authorized",
          snackbarType: "error",
        })
      }
    } else {
      removeDrawer(dispatch)
    }
  }
}

export const updaterightdrawerstate = (data, fieldname) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_RIGHT_DRAWER_STATE,
      data: data,
      fieldname: fieldname,
    })
  }
}

export const setProgressLoading = (value, title) => {
  return {
    type: PROGRESS_LOADING_STATE,
    state: value,
    title: title,
  }
}

export const addproductserial = (value) => {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SERIAL_SERVICE_SUCCESS,
      payload: value,
    })
  }
}

export const gotoPage = ({ key, data }) => {
  const year = getAccountSessionUserData("financial_year")
  setStorage(`last_visited_page_${year}`, key)
  setStorage(`last_visited_page_data_${year}`, data)
  return (dispatch) => {
    goToNavigate(`/${year}/${key}`, data)
  }
}

export const gotoPageWithoutYear = ({ key, data }) => {
  return (dispatch) => {
    goToNavigate(`/${key}`, data)
  }
}

export const gotoReport = (data) => {
  const year = getAccountSessionUserData("financial_year")
  return (dispatch) => {
    dispatch({ type: LOADING_STATE, state: false })

    if (data.reportid === re.GSTR_1Summary) {
      goToNavigate(`/${year}/report/gst-report/gstr-1-summary`, data)
    } else if (data.reportid === re.GSTR_2Summary) {
      goToNavigate(`/${year}/report/gst-report/gstr-2-summary`, data)
    } else if (data.reportid === re.GSTR_3BSummary) {
      goToNavigate(`/${year}/report/gst-report/gstr-3b-summary`, data)
    } else if (data.reportid === re.Monthlypaymentforquarterlyreturn) {
      goToNavigate(`/${year}/report/gst-report/monthly-payment-for-quarterly-return`, data)
    } else if (data.reportid === re.AmendBillsReport) {
      goToNavigate(`/${year}/report/gst-report/amend-bills-report`, data)
    } else if (data.reportid === re.GSTSummary) {
      goToNavigate(`/${year}/report/gst-report/gst-summary`, data)
    } else if (data.reportid === re.GSTAnnualComputation) {
      goToNavigate(`/${year}/report/gst-report/gst-annual-computation`, data)
    } else if (data.reportid === re.LotCostingReport) {
      goToNavigate(`/${year}/report/process-reports/lot-costing-report`, data)
    } else {
      goToNavigate(`/${year}/report/${generateURL(data.category_name)}/${generateURL(data.name)}`, data)
    }
  }
}

export const post_form_data = ({
  code,
  dispatch,
  formtype,
  field_type,
  formaction,
  formdata,
  saveandaddanother,
  files,
  other_type,
  otherDetail,
  saveandprint,
  saveandshare,
  otherDetail1,
  print_barcode,
  common_formtype,
  draftCode,
  remoteForm,
  onComplete,
  limit,
}) => {
  const invoice_type_detail = getAccountSitesetting("invoice_type_detail")
  const purchase_type_detail = getAccountSitesetting("purchase_type_detail")
  const inward_type_detail = getAccountSitesetting("inward_type_detail")
  const outward_type_detail = getAccountSitesetting("outward_type_detail")
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    if (formaction !== "") {
      const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
      if (formaction === "delete" && otherDetail === "multi_delete") {
        dispatch({ type: LOADING_STATE, state: true })
      } else if (
        formaction === "update" &&
        (formtype === "popover" || formtype === "closingstock" || formtype === "opbal" || formtype === "TdsCategoryMaster" || (formtype === "customField" && other_type === "value"))
      ) {
        dispatch({ type: POP_OVER_LOADING_STATE, state: true })
      } else if (
        formtype === "cancel_einvoice" ||
        ((formtype === "generate_einvoice" || formtype === "generate_einvoiceJson") && otherDetail === "List") ||
        formtype === "ewaybill_cancel" ||
        formtype === "cancel_irn_ewaybill"
      ) {
        dispatch({
          type: DIALOG_LOADING,
          state: true,
        })
      } else {
        if (
          invoice_type_detail.filter((e) => e.id === formtype).length > 0 ||
          formtype === "remove_social_account" ||
          formtype === "customPageFormSrno" ||
          formtype === "envelopePrinting" ||
          formtype === "chequePrinting" ||
          formtype === "reconcileDate" ||
          formtype === "lotCosting" ||
          formtype === "merge_packingSlip" ||
          formtype === "gstoldbill" ||
          (formtype === "generate_irn_ewaybill" && otherDetail !== "rightDrawer") ||
          (formtype === "customField" && other_type !== "value" && otherDetail !== "getMyStore") ||
          formtype === "bank_statement" ||
          formtype === "adjustBillCr" ||
          formtype === "adjustBill" ||
          formtype === "receipt" ||
          formtype === "setting" ||
          formtype === "recordexpense" ||
          formtype === "payment" ||
          formtype === "journal_entry" ||
          formtype === "salesreturn" ||
          formtype === "purchasereturn" ||
          purchase_type_detail.filter((e) => e.id === formtype).length > 0 ||
          inward_type_detail.filter((e) => e.id === formtype).length > 0 ||
          formtype === "lot_transfer" ||
          formtype === "stock_transferGodown" ||
          formtype === "stockTransfer" ||
          outward_type_detail.filter((e) => e.id === formtype).length > 0 ||
          formtype === "useripostock" ||
          formtype === "reset_gstr3b_reconcile" ||
          formtype === "gstr2_partially_markas_matched" ||
          formtype === "connect_company" ||
          formtype === "label_design" ||
          (formtype === "getNextProcessData" && formaction === "getlatestdata")
        ) {
          dispatch({
            type: PAGE_FORM_POST_SERVICE_START,
            pageformaction: formaction,
            pageformtype: formtype,
            outward_type_detail: outward_type_detail,
            inward_type_detail: inward_type_detail,
            purchase_type_detail: purchase_type_detail,
            invoice_type_detail: invoice_type_detail,
          })
        } else if (!(formtype === "generate_irn_ewaybill" && otherDetail !== "rightDrawer")) {
          dispatch({
            type: FORM_POST_SERVICE_START,
            formaction: formaction,
            formtype: formtype,
            outward_type_detail: outward_type_detail,
            inward_type_detail: inward_type_detail,
            purchase_type_detail: purchase_type_detail,
            invoice_type_detail: invoice_type_detail,
          })
        }
      }

      var url = post_form_url({
        formtype,
        props: {
          userdata: "",
          otherDetail,
          otherDetail1,
          formaction,
          getAccountSitesetting,
          other_type
        },
      })
      let data = new FormData()

      if (formtype === "customer_supplier" && (other_type === "customer" || other_type === "supplier")) {
        data.append("type", other_type)
        data.append("action", !isblank(otherDetail) && otherDetail === "multi_delete" ? otherDetail : formaction)
      } else if (formtype === "importtaxscheme") {
        data.append("type", "taxscheme")
        data.append("action", "import")
      } else if (checkCondition(formtype, ["receipt", "payment", "godown"]) && otherDetail === "multi_delete") {
        // "inward", "job_inward", "packing_slip", "goods_received_note", "finish_stock", 
        // "outward", "delivery_challan", "manage_lot_master", 
        data.append("type", formtype)
        data.append("action", otherDetail)
      } else {
        data.append("type", formtype)
        data.append("action", formaction)
      }

      if (!isblank(code)) {
        data.append("code", code)
      }

      data.append("form_data", formdata2)
      if (other_type) {
        data.append("other_type", other_type)
      }
      if (formaction === "insert" || formaction === "update") {
        if (!isblank(files)) {
          Object.keys(files).forEach((item) => {
            data.append(item, files[item])
          })
        }
        if (!isblank(field_type)) {
          data.append("field_type", field_type)
        }
      } else if (formaction === "edit") {
        if (otherDetail === "Clone") {
          data.append("clone", true)
        } else {
          data.append("clone", false)
        }
        if (!isblank(otherDetail) && otherDetail.last_row_flag) {
          data.append("last_row_flag", otherDetail.last_row_flag)
        }
      }
      if (invoice_type_detail.filter((e) => e.id === formtype).length > 0 || formtype === "recordexpense") {
        data.append("version", "V2")
      }
      data.append("offset", 0)
      if (!isblank(limit)) {
        data.append("limit", limit)
      } else {
        data.append("limit", "All")
      }
      if (print_barcode) {
        data.append("print_barcode", true)
      }
      axios.defaults.headers.common = getheaders()
      axios({
        method: "post",
        url: url,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
        cancelToken: getAxiosCancelToken().token,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          if (formtype === "cancel_einvoice" || formtype === "generate_einvoice" || formtype === "generate_einvoiceJson" || formtype === "ewaybill_cancel" || formtype === "cancel_irn_ewaybill") {
            dispatch({
              type: DIALOG_LOADING,
              state: false,
            })
            dispatch({ type: LOADING_STATE, state: false })
          }
          if (response.data.meta.code === 401) {
            if (
              formaction === "update" &&
              (formtype === "popover" || formtype === "closingstock" || formtype === "opbal" || formtype === "TdsCategoryMaster" || (formtype === "customField" && other_type === "value"))
            ) {
              dispatch({ type: POP_OVER_LOADING_STATE, state: false })
            } else {
              dispatch({ type: LOADING_STATE, state: false })
            }
            if (formtype === "cancel_einvoice" || formtype === "generate_einvoice" || formtype === "generate_einvoiceJson" || formtype === "ewaybill_cancel" || formtype === "cancel_irn_ewaybill") {
              dispatch({
                type: CONFIRM_DIALOG,
                payload: false,
                confirm: false,
                message: "",
                title: "",
                form: "",
                meta: "",
                loading: false,
              })
            }
            postServiceFail({ dispatch, response: response.data.meta.message, formdata, saveandaddanother, formtype })
          } else if (response.data.meta.code === 408) {
            if (
              formaction === "update" &&
              (formtype === "popover" || formtype === "closingstock" || formtype === "opbal" || formtype === "TdsCategoryMaster" || (formtype === "customField" && other_type === "value"))
            ) {
              dispatch({ type: POP_OVER_LOADING_STATE, state: false })
            } else {
              dispatch({ type: LOADING_STATE, state: false })
            }

            dispatch({
              type: SNACKBAR_STATE,
              message: response.data.meta.message,
              snackbarType: "warning",
            })

            const lastdata = {
              getAccountSitesetting,
            }
            lastdata.formtype = formtype
            lastdata.formaction = formaction
            lastdata.formdata = formdata
            lastdata.saveandaddanother = saveandaddanother
            lastdata.saveandprint = saveandprint
            lastdata.saveandshare = saveandshare
            lastdata.other_type = other_type
            lastdata.otherDetail = otherDetail
            lastdata.otherDetail1 = otherDetail1
            lastdata.files = files
            lastdata.message = response.data.meta.message
            lastdata.calLimit = response.data.meta.calLimit
            lastdata.creditLimit = response.data.meta.creditLimit
            lastdata.netamt = response.data.meta.netamt
            lastdata.outStanding = response.data.meta.outStanding
            lastdata.common_formtype = common_formtype
            lastdata.draftCode = draftCode
            lastdata.functions = {}
            lastdata.function = "post_form_data"
            lastdata.type = ""

            if (!isblank(response.data.meta?.confirmKey)) {
              lastdata.type = "autoConfirm"
              lastdata.confirmKey = response.data.meta.confirmKey
            } else if (response.data.meta.title === "Negative stock alert") {
              lastdata.type = "negativeStock"
            } else if (response.data.meta.title === "Confirm Delete") {
              lastdata.type = "packingSlipDelete"
            } else if (response.data.meta.title === "Negative order alert") {
              lastdata.type = "negativeOrderConfirm"
            } else if (response.data.meta.title === "Amend Invoice alert") {
              lastdata.type = "amendInvoice"
              lastdata.message = "Invoice already filled in GST. Do you want to amend invoice?"
            } else if (response.data.meta.title === "Credit limit reached") {
              lastdata.type = "creditLimitReached"
            } else if (response.data.meta.title === "Credit limit has been reached") {
              lastdata.type = "creditLimitUpdate"
            } else if (response.data.meta.title === "Gst filed alert" || response.data.meta.title === "Attention: Invoice Already Filed") {
              lastdata.type = "gstFileAlert"
            } else if (response.data.meta.title === "TDS threshold limit reached") {
              lastdata.type = "tds_threshold_limit_reached"
            } else if (response.data.meta.title === "TCS threshold limit reached") {
              lastdata.type = "tcs_threshold_limit_reached"
            } else if (response.data.meta.title === "TDS/TCS threshold limit reached") {
              lastdata.type = "tds_tcs_threshold_limit_reached"
            } else if (response.data.meta.title === "This unadjusted transaction already forwarded to the next financial year") {
              lastdata.type = "unadjusted_transaction_already_forwarded"
            } else if (response.data.meta.title === "Delete validation") {
              lastdata.type = "delete_validation"
              lastdata.meta = response.data.meta
            } else {
              lastdata.type = "limitReached"
            }
            dispatch({
              type: LAST_PAGE_UPDATE,
              lastdata: lastdata,
            })
            dispatch({
              type: CONFIRM_DIALOG,
              payload: true,
              title: response.data.meta.title,
              form: "NotifyDialog",
              confirm: false,
              message: "",
              meta: "",
            })
          } else if (response.data.meta.code === 403) {
            if (
              formaction === "update" &&
              (formtype === "popover" || formtype === "closingstock" || formtype === "opbal" || formtype === "TdsCategoryMaster" || (formtype === "customField" && other_type === "value"))
            ) {
              dispatch({ type: POP_OVER_LOADING_STATE, state: false })
            } else {
              dispatch({ type: LOADING_STATE, state: false })
            }
            var message = ""
            if (formtype === "generate_einvoice" && !isblank(response.data.irn)) {
              message = `e-Invoice generated succesfully. IRN No : ${response.data.irn}`

              dispatch({
                type: E_INVOICE_UPDATE,
                formtype: formtype,
                ewayBillstatus: "0",
                payload: formdata,
              })
            }
            dispatch({
              type: CONFIRM_DIALOG,
              payload: true,
              confirm: false,
              message: message,
              title: "Message",
              form: "Error List",
              meta: response.data.meta.message,
            })

            if (formtype === "cancel_einvoice") {
              dispatch({
                type: E_INVOICE_UPDATE,
                formtype: formtype,
                eInvoiceCancel: response.data.result.eInvoiceCancel,
                eWayBillCancel: response.data.result.eWayBillCancel,
                payload: formdata,
              })
            }

            if (formaction === "delete" && otherDetail === "multi_delete") {
              formdata.code.codes = formdata.code?.codes?.filter((item) => {
                const codes = response.data.meta.message[0]?.code?.split(",")
                if (!codes?.includes(item)) {
                  return true
                }
              })

              postServiceSuccess({
                dispatch,
                user: response,
                formtype,
                field_type,
                formaction,
                formdata,
                saveandaddanother,
                other_type,
                otherDetail,
                saveandprint,
                saveandshare,
                otherDetail1,
                print_barcode,
                common_formtype,
                draftCode,
                remoteForm,
              })
            }
          } else {
            if (onComplete) {
              onComplete({
                user: response,
                formtype,
                field_type,
                formaction,
                formdata,
                saveandaddanother,
                other_type,
                otherDetail,
                saveandprint,
                saveandshare,
                otherDetail1,
                print_barcode,
                common_formtype,
                draftCode,
                remoteForm,
              })
              dispatch({
                type: DIALOG_LOADING,
                state: false,
              })
              dispatch({ type: LOADING_STATE, state: false })
            } else {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: "post_form",
                lastaction: "success",
                lasttime: new Date().getTime(),
                lastdata: {
                  formtype,
                  formaction,
                  formdata,
                  other_type,
                },
              })

              if (
                (formaction === "delete" && (otherDetail === "multi_delete" || otherDetail === "fromForm")) ||
                formtype === "ewaybill_cancel" ||
                formtype === "cancel_irn_ewaybill" ||
                formtype === "generate_einvoice" ||
                formtype === "generate_einvoiceJson" ||
                formtype === "cancel_einvoice"
              ) {
                dispatch({
                  type: CONFIRM_DIALOG,
                  payload: false,
                  confirm: false,
                  title: "",
                  form: "",
                  message: "",
                  meta: "",
                })
              }

              postServiceSuccess({
                dispatch,
                user: response,
                formtype,
                field_type,
                formaction,
                formdata,
                saveandaddanother,
                other_type,
                otherDetail,
                saveandprint,
                saveandshare,
                otherDetail1,
                print_barcode,
                common_formtype,
                draftCode,
                remoteForm,
              })
            }
          }
        })
        .catch((error) => {
          if (
            formaction === "update" &&
            (formtype === "popover" || formtype === "closingstock" || formtype === "opbal" || formtype === "TdsCategoryMaster" || (formtype === "customField" && other_type === "value"))
          ) {
            dispatch({ type: POP_OVER_LOADING_STATE, state: false })
          } else {
            dispatch({ type: LOADING_STATE, state: false })
          }
          if (formtype === "cancel_einvoice" || formtype === "generate_einvoice" || formtype === "generate_einvoiceJson" || formtype === "ewaybill_cancel" || formtype === "cancel_irn_ewaybill") {
            dispatch({
              type: DIALOG_LOADING,
              state: false,
            })
          }
          if (axios.isCancel(error)) {
          } else {
            postErrorOnException(error)
            postServiceFail({ dispatch, response: getCatchErrorMessage(error), formdata, saveandaddanother, formtype })
          }
        })
    }
  }
}

export const getTDSPercantage = ({ code, other_type, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    let data = new FormData()
    data.append("type", "get_tdspercentage")
    data.append("limit", "All")
    data.append("sort_order", "asc")
    data.append("sort_by", "name")
    data.append("search_text", "")
    data.append("offset", 0)
    data.append("code", code)
    data.append("other_type", other_type)

    data.append("offset", 0)
    data.append("limit", "All")

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "get_tdspercentage" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "get_tdspercentage",
            lastaction: "success",
            lasttime: new Date().getTime(),
            lastdata: response.data.result,
          })
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_STATE, state: false })
        if (axios.isCancel(error)) {
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        }
      })
  }
}

export const onlyPostFormData = ({ formtype, formaction, formdata, other_type, onComplete, functions }) => {
  return (dispatch) => {
    var loading = false
    var sidebarloading = false
    if (formtype === "opening_adjust_bill_delete" || formtype === "opening_adjust_receipt_delete" || formtype === "adjustOpeningBillsAccount") {
      loading = true
    }
    dispatch({
      type: LOADING_STATE,
      state: loading,
      sidebarstate: sidebarloading,
    })
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    if (formaction !== "") {
      const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
      var url = post_form_url({
        formtype,
        props: { userdata: "", getAccountSitesetting },
      })
      let data = new FormData()
      data.append("type", formtype)
      if (other_type) {
        data.append("other_type", other_type)
      }
      data.append("action", formaction)
      data.append("form_data", formdata2)
      data.append("offset", 0)
      data.append("limit", "All")
      axios.defaults.headers.common = getheaders()
      axios({
        method: "post",
        url: url,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
        cancelToken: getAxiosCancelToken().token,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
          if (response.data.meta.code === 401) {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: response.data.meta.message,
              snackbarType: "error",
            })
          } else {
            if (onComplete) {
              onComplete()
            }
            if (formaction !== "edit") {
              dispatch({
                type: SNACKBAR_STATE,
                payload: true,
                message: response.data.meta.message,
                snackbarType: "success",
              })
            }
            if (formtype === "adjustOpeningBillsAccount") {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: `${formtype}_${other_type}`,
                lastaction: formaction,
                lasttime: new Date().getTime(),
                lastdata: response.data,
              })
            } else {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: formtype,
                lastaction: "post_success",
                lasttime: new Date().getTime(),
              })
            }
          }
        })
        .catch((error) => {
          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
          if (axios.isCancel(error)) {
          } else {
            postErrorOnException(error)
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: getCatchErrorMessage(error),
              snackbarType: "error",
            })
          }
        })
    }
  }
}

const postServiceSuccess = ({
  dispatch,
  user,
  formtype,
  field_type,
  formaction,
  formdata,
  saveandaddanother,
  other_type,
  otherDetail,
  saveandprint,
  saveandshare,
  otherDetail1,
  print_barcode,
  common_formtype,
  draftCode,
  remoteForm,
}) => {
  const invoice_type_detail = getAccountSitesetting("invoice_type_detail")
  const purchase_type_detail = getAccountSitesetting("purchase_type_detail")
  const inward_type_detail = getAccountSitesetting("inward_type_detail")
  const outward_type_detail = getAccountSitesetting("outward_type_detail")
  const invoice_cus_sup = getAccountSitesetting("invoice_cus_sup")
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
  const customerListAuto = getAccountSitesetting("customerListAuto")
  const itemListAuto = getAccountSitesetting("itemListAuto")
  const language = getAccountSitesetting("language")
  const tcs = parseInt(getAccountSitesetting("tcs"))

  const onSuccess = (image_meta) => {
    dispatch(change("headerSearchForm", "deleteFormReason", ""))
    dispatch(change("headerSearchForm", "deleteDrawerReason", ""))
    dispatch(change("headerSearchForm", "deleteReason", ""))

    const onSaveAndAddAnother = () => {
      if (saveandaddanother === true) {
        if (
          invoice_type_detail.filter((e) => e.id === formtype).length > 0 ||
          formtype === "envelopePrinting" ||
          formtype === "chequePrinting" ||
          formtype === "receipt" ||
          formtype === "recordexpense" ||
          formtype === "payment" ||
          formtype === "journal_entry" ||
          formtype === "salesreturn" ||
          formtype === "purchasereturn" ||
          purchase_type_detail.filter((e) => e.id === formtype).length > 0 ||
          inward_type_detail.filter((e) => e.id === formtype).length > 0 ||
          formtype === "lot_transfer" ||
          formtype === "stock_transferGodown" ||
          formtype === "stockTransfer" ||
          outward_type_detail.filter((e) => e.id === formtype).length > 0
        ) {
          dispatch({
            type: PAGE_FORM_POST_SERVICE_CLEAR,
          })
        } else {
          dispatch({
            type: FORM_POST_SERVICE_CLEAR,
          })
        }

        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "save_add_another",
          lastaction: "start",
          lasttime: new Date().getTime(),
        })
      }
    }
    if (formtype === "journal_entry" && !isblank(otherDetail) && otherDetail.from === "writeOffDues") {
      dispatch({
        type: LAST_PAGE_UPDATE,
        lasttype: "writeOffDues",
        lastaction: "success",
        lasttime: new Date().getTime(),
        lastdata: otherDetail,
      })
      dispatch({
        type: CONFIRM_DIALOG,
        payload: false,
        confirm: false,
        message: "",
        title: "",
        form: "",
        meta: "",
        loading: false,
      })

      dispatch({ type: LOADING_STATE, state: false, sidebarloading: false })
    } else if (
      print_barcode &&
      (formtype === "stockTransfer" || formtype === "finish_stock_add_record" || formtype === "lot_transfer" || inward_type_detail.filter((e) => e.id === formtype).length > 0)
    ) {
      dispatch({ type: LOADING_STATE, state: false, sidebarloading: false })
      dispatch({
        type: LAST_PAGE_UPDATE,
        lasttype: "stockTransfer",
        lastaction: "print_barcode_start",
        lasttime: new Date().getTime(),
        lastdata: user.data.print_data,
      })
    } else if (saveandshare) {
      dispatch({ type: LOADING_STATE, state: false, sidebarloading: false })
      dispatch({
        type: LAST_PAGE_UPDATE,
        lasttype: formtype,
        lastaction: "saveandshare",
        lasttime: new Date().getTime(),
        lastdata: formaction === "insert" ? user.data.insert_code : formdata.update_code,
      })
    } else if (formtype === "recharge") {
      dispatch({ type: LOADING_STATE, state: false, sidebarloading: false })
      dispatch({
        type: SNACKBAR_STATE,
        payload: true,
        message: "Recharge successfully completed. API balance updated",
        snackbarType: "success",
      })
      dispatch({
        type: LAST_PAGE_UPDATE,
        lasttype: "recharge",
        lastaction: "insert",
        lasttime: new Date().getTime(),
        lastdata: user.data.result,
      })
    } else if (
      (formtype === "receipt" || formtype === "payment") &&
      formaction === "delete" &&
      !isblank(otherDetail) &&
      (otherDetail.from === "sales_invoice" || otherDetail.from === "purchase_invoice")
    ) {
      dispatch({
        type: LAST_PAGE_UPDATE,
        lasttype: "payment",
        lastaction: "delete_success",
        lasttime: new Date().getTime(),
      })
      dispatch({ type: LOADING_STATE, state: false, sidebarloading: false })
    } else if (formtype === "getNextProcessData" && formaction === "getlatestdata") {
      dispatch({
        type: LAST_PAGE_UPDATE,
        lasttype: "getNextProcessData",
        lastaction: "getlatestdata",
        lasttime: new Date().getTime(),
        lastdata: { data: user.data.result, formdata },
      })
      dispatch({ type: LOADING_STATE, state: false, sidebarloading: false })
    } else if (
      formaction === "update" &&
      (formtype === "popover" || formtype === "closingstock" || formtype === "opbal" || formtype === "TdsCategoryMaster" || (formtype === "customField" && other_type === "value"))
    ) {
      if (!isblank(otherDetail) && otherDetail.onComplete) {
        otherDetail.onComplete()
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: user.data.meta.message,
          snackbarType: "success",
        })
        dispatch({ type: POP_OVER_LOADING_STATE, state: false })
      }
    } else {
      if (formtype === "generate_irn_ewaybill" || formtype === "generate_einvoice") {
        const meta = {
          other_type: other_type,
          code: formdata.invoiceCode,
          irn: user.data.irn,
          mode: otherDetail === "rightDrawer" ? "invoiceDetail" : "",
        }
        if (otherDetail1 && otherDetail1.version === "new" && otherDetail1.userDetail) {
          meta.userDetail = otherDetail1.userDetail
        }
        if (otherDetail === "rightDrawer") {
          if (formtype === "generate_irn_ewaybill" || (formtype === "generate_einvoice" && !isblank(user.data.ewaybillStatus) && user.data.ewaybillStatus.toString() === "1")) {
            meta.ewaybill_no = user.data.ewaybillNo
            meta.ewaybill_date = user.data.ewaybillDate
            if (formtype === "generate_einvoice") {
              meta.irn = user.data.irn
            }
            dispatch({
              type: CONFIRM_DIALOG,
              payload: true,
              confirm: false,
              message: "",
              title: "e-Way Bill Generated",
              form: "Download e-Way Bill",
              meta: meta,
              loading: false,
            })
          } else if (formtype === "generate_einvoice" && user.data.meta.code === 200) {
            dispatch({
              type: CONFIRM_DIALOG,
              payload: true,
              confirm: false,
              message: "",
              title: "e-Invoice Generated",
              form: "Download e-Invoice",
              meta: meta,
              loading: false,
            })
          }
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "generate_irn_ewaybill",
            lastaction: "success",
            lasttime: new Date().getTime(),
          })
        } else {
          if (formtype === "generate_irn_ewaybill" || formtype === "generate_einvoice") {
            dispatch({
              type: FORM_POST_SERVICE_SUCCESS,
              payload: user,
              formtype: formtype,
              formaction: formaction === "delete" && otherDetail === "multi_delete" && user.data.meta.code === 403 ? "multi_delete" : formaction,
              saveandaddanother: saveandaddanother,
              otherDetail: otherDetail,
              purchase_type_detail: purchase_type_detail,
            })
          }
          if (formtype === "generate_irn_ewaybill" || (formtype === "generate_einvoice" && !isblank(user.data.ewaybillStatus) && user.data.ewaybillStatus.toString() === "1")) {
            meta.ewaybill_no = user.data.ewaybillNo
            meta.ewaybill_date = user.data.ewaybillDate

            if (formtype === "generate_einvoice") {
              meta.irn = user.data.irn
            }

            dispatch({
              type: CONFIRM_DIALOG,
              payload: true,
              confirm: false,
              message: "",
              title: "e-Way Bill Generated",
              form: "Download e-Way Bill",
              meta: meta,
              loading: false,
            })
          } else if (formtype === "generate_einvoice" && user.data.meta.code === 200) {
            dispatch({
              type: CONFIRM_DIALOG,
              payload: true,
              confirm: false,
              message: "",
              title: "e-Invoice Generated",
              form: "Download e-Invoice",
              meta: meta,
              loading: false,
            })
          }
        }
      } else if (
        invoice_type_detail.filter((e) => e.id === formtype).length > 0 ||
        formtype === "remove_social_account" ||
        formtype === "customPageFormSrno" ||
        formtype === "envelopePrinting" ||
        formtype === "chequePrinting" ||
        formtype === "reconcileDate" ||
        formtype === "lotCosting" ||
        formtype === "merge_packingSlip" ||
        formtype === "ewaybill_cancel" ||
        formtype === "cancel_irn_ewaybill" ||
        formtype === "gstoldbill" ||
        formtype === "bank_statement" ||
        formtype === "cancel_einvoice" ||
        formtype === "adjustBillCr" ||
        formtype === "adjustBill" ||
        formtype === "receipt" ||
        formtype === "setting" ||
        formtype === "bot" ||
        (formtype === "customField" && other_type !== "value" && otherDetail !== "getMyStore") ||
        formtype === "recordexpense" ||
        formtype === "payment" ||
        formtype === "journal_entry" ||
        (formtype === "salesreturn" && formaction !== "autofill") ||
        (formtype === "purchasereturn" && formaction !== "autofill") ||
        (purchase_type_detail.filter((e) => e.id === formtype).length > 0 && formaction !== "autofill") ||
        inward_type_detail.filter((e) => e.id === formtype).length > 0 ||
        formtype === "lot_transfer" ||
        formtype === "stock_transferGodown" ||
        formtype === "stockTransfer" ||
        outward_type_detail.filter((e) => e.id === formtype).length > 0 ||
        formtype === "bundleitem" ||
        formtype === "useripostock" ||
        formtype === "label_design" ||
        formtype === "reset_gstr3b_reconcile"
      ) {
        if (formaction === "edit" && outward_type_detail.filter((e) => e.id === formtype).length > 0) {
          user.data.form_data.DetailGrid = user.data.form_data.DetailGrid.map((detailitem, index) => {
            const updateditem = { ...detailitem }
            updateditem.__cust_SelectedQty = updateditem.itemQty
            updateditem.__cust_Selectedpcs = updateditem.__cust_pcs
            return updateditem
          })
        }
        dispatch({
          type: PAGE_FORM_POST_SERVICE_SUCCESS,
          payload: user,
          pageformtype: formtype,
          pageformaction:
            formaction === "delete" && otherDetail === "multi_delete" && user.data.meta.code === 403 ? "multi_delete" : otherDetail === "Clone" && formaction === "edit" ? "clone" : formaction,
          saveandaddanother: saveandaddanother,
          otherDetail: otherDetail,
        })
      } else if (formtype === "customField" && other_type === "value") {
        dispatch({
          type: CONFIRM_DIALOG,
          payload: false,
          confirm: false,
          message: "",
          title: "",
          form: "",
          meta: "",
          loading: false,
        })
      } else if (formtype === "ewaybill" && !isblank(otherDetail) && otherDetail.generateJSON) {
        otherDetail.onSuccess()
        dispatch({ type: LOADING_STATE, state: false, sidebarloading: false })
      } else {
        dispatch({
          type: FORM_POST_SERVICE_SUCCESS,
          payload: user,
          formtype: formtype,
          formaction:
            formaction === "delete" && otherDetail === "multi_delete" && user.data.meta.code === 403 ? "multi_delete" : otherDetail === "Clone" && formaction === "edit" ? "clone" : formaction,
          saveandaddanother: saveandaddanother,
          otherDetail: otherDetail,
          purchase_type_detail: purchase_type_detail,
        })
        dispatch({ type: LOADING_STATE, state: false })
        if (saveandaddanother === true) {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "saveandaddanother",
            lastaction: "completed",
            lasttime: new Date().getTime(),
            lastdata: { insert_code: formdata.update_code, formdata, onSuccess },
          })
        }
      }
    }

    var ModalMode = true
    var modalWidth = 450
    var position = "right"
    var payload = {}
    var metaData = ""
    var fieldname = ""
    var customPages = []
    var lastaction = ""
    if (formtype === "gstoldbill" && (formaction === "insert" || formaction === "update")) {
      if (formaction === "insert") {
        setStorage("last_date_gstoldbill", formdata.saleInvoiceDate)
      }

      if (purchase_type_detail.filter((e) => e.id === formdata.other_type).length > 0) {
        formdata.Billno = formdata.billNo
        formdata.invoice_type = formdata.other_type
        formdata.InvoiceDate = formdata.saleInvoiceDate
        formdata.supplierSelectedValue = formdata.customerSelectedValue
      } else if (invoice_type_detail.filter((e) => e.id === formdata.other_type).length > 0) {
        formdata.invoice_type = formdata.other_type
      } else if (formdata.other_type === "salesreturn") {
        formdata.previousYearBill = true
        formdata.InvoiceDate = formdata.saleInvoiceDate
      } else if (formdata.other_type === "purchasereturn") {
        formdata.previousYearBill = true
        formdata.supplierSelectedValue = formdata.customerSelectedValue
      }
    }
    if (formaction === "e_Invoice") {
      if (formtype === "generate_einvoice" || formtype === "cancel_einvoice") {
        dispatch({
          type: E_INVOICE_UPDATE,
          formtype: formtype,
          response: user.data.irn,
          ewayBillstatus: user.data.ewaybillStatus,
          payload: formdata,
        })
      } else if (formtype === "generate_einvoiceJson") {
        exportAs({ data: user.data.result, name: user.data.filename, type: "json" })
      }
    } else if (formaction === "edit") {
      var Modaltype = ""
      if (formtype === "city") {
        Modaltype = "Add New City"
      } else if (formtype === "costcenter") {
        Modaltype = "Add New Cost Center"
      } else if (formtype === "process") {
        Modaltype = "Add New Process"
      } else if (formtype === "country") {
        Modaltype = "Add New Country"
      } else if (formtype === "taxscheme") {
        Modaltype = "Edit Tax Group"
        modalWidth = 650
      } else if (formtype === "customer_supplier") {
        fieldname = other_type
        Modaltype = "Add New CustomerSupplier"
        position = "bottom"
        modalWidth = ""
      } else if (formtype === "other_account") {
        Modaltype = "Add New Account"
      } else if (formtype === "company") {
        Modaltype = "Add New Company"
        modalWidth = 850
      } else if (formtype === "partygroup") {
        Modaltype = "Add New Customer Group"
      } else if (formtype === "state") {
        Modaltype = "Add New State"
      } else if (formtype === "user") {
        Modaltype = "Add New User"
      } else if (formtype === "agent") {
        Modaltype = "Add New Agent"
        modalWidth = 650
      } else if (formtype === "transport") {
        Modaltype = "Add New Transport"
      } else if (formtype === "godown") {
        Modaltype = "Add New Godown"
      } else if (formtype === "label_setting") {
        Modaltype = "Add Page Template"
      } else if (formtype === "label_design") {
        ModalMode = false
        Modaltype = "Add Label Design"
      } else if (formtype === "item") {
        Modaltype = "Add New Item"
        modalWidth = ""
        position = "bottom"
      } else if (formtype === "wiretransfer") {
        Modaltype = "Add New Wire Transfer"
      } else if (formtype === "subgroup") {
        Modaltype = "Add New Sub Group"
      } else if (formtype === "wiresender") {
        Modaltype = "Add New Sender"
      } else if (formtype === "beneficiary") {
        Modaltype = "Add New Beneficiary"
      } else if (formtype === "ipostockparty") {
        Modaltype = "Add New IPO Stock Account"
      } else if (formtype === "ipostockmaster") {
        Modaltype = "Add New IPO Stock Master"
      } else if (formtype === "sms_template") {
        Modaltype = "Edit SMS Template"
        metaData = {
          type: "sms",
        }
        modalWidth = 650
      } else if (formtype === "whatsapp_template") {
        Modaltype = "Edit SMS Template"
        metaData = {
          type: "whatsapp",
        }
        modalWidth = 650
      } else if (formtype === "email_template") {
        Modaltype = "Edit Email Template"
        modalWidth = 650
      } else if (formtype === "bundleitem") {
        ModalMode = false
        Modaltype = "bundleitem"
      } else if (formtype === "merge_packingSlip") {
        ModalMode = false
        Modaltype = "Merge Packing Slip"
      } else if (formtype === "receipt") {
        if (user.data.form_data.receipt_type === "M") {
          ModalMode = false
          Modaltype = "receipt"
        } else {
          ModalMode = false
          Modaltype = "receiptdetail"
        }
      } else if (formtype === "envelopePrinting") {
        ModalMode = false
        Modaltype = "Add New Envelope"
      } else if (formtype === "chequePrinting") {
        ModalMode = false
        Modaltype = "Add New Cheque"
      } else if (formtype === "recordexpense") {
        if (user.data.form_data.receipt_type === "M") {
          ModalMode = false
          Modaltype = "recordexpense"
        } else {
          ModalMode = false
          Modaltype = "recordexpense"
        }
      } else if (formtype === "payment") {
        if (user.data.form_data.receipt_type === "M") {
          ModalMode = false
          Modaltype = "payment"
        } else {
          ModalMode = false
          Modaltype = "paymentdetail"
        }
      } else if (formtype === "journal_entry") {
        ModalMode = false
        Modaltype = "journal"
      } else if (formtype === "bot") {
        ModalMode = false
        Modaltype = "Add New Bot"
      } else if (formtype === "gstoldbill") {
        ModalMode = false
        Modaltype = "Add New GST Old Pen Inv"
      } else if (invoice_type_detail.filter((e) => e.id === formtype).length > 0) {
        ModalMode = false
        if (otherDetail === "Convert Invoice" || otherDetail === "ConvertInvoiceFromEstimate") {
          Modaltype = "sales_invoice"
        } else if (otherDetail === "ConvertJobFromOutward") {
          Modaltype = "job_delivery_challan"
        } else if (otherDetail === "ConvertOrderFromEstimate") {
          Modaltype = "sales_order"
        } else if (otherDetail === "Credit Note") {
          Modaltype = "sales_return"
        } else {
          Modaltype = user.data.form_data.invoice_type
        }
      } else if (formtype === "useripostock") {
        ModalMode = false
        Modaltype = "IPOStock"
      } else if (formtype === "salesreturn") {
        payload.data = {}
        payload.data.result = user.data.form_data.bills.result

        dispatch({
          type: BILL_SERVICE_SUCCESS,
          payload: payload,
          search_text: "",
          placeofsupply: "",
        })

        // dispatch({
        //   type: SALESRETURNBILL_SERVICE_SUCCESS,
        //   payload: payload,
        //   search_text: "",
        //   placeofsupply: "",
        // })

        ModalMode = false
        Modaltype = "sales_return"
      } else if (formtype === "purchasereturn") {
        payload.data = {}
        payload.data.result = user.data.form_data.bills.result

        dispatch({
          type: PUR_BILL_SERVICE_SUCCESS,
          payload: payload,
          search_text: "",
          placeofsupply: "",
        })

        // dispatch({
        //   type: SALESRETURNBILL_SERVICE_SUCCESS,
        //   payload: payload,
        //   search_text: "",
        //   placeofsupply: "",
        // })

        ModalMode = false
        Modaltype = "purchase_return"
      } else if (purchase_type_detail.filter((e) => e.id === formtype).length > 0) {
        ModalMode = false
        if (otherDetail === "Convert Invoice") {
          Modaltype = "purchase_invoice"
        } else {
          Modaltype = user.data.form_data.invoice_type
        }
      } else if (inward_type_detail.filter((e) => e.id === formtype).length > 0) {
        if (otherDetail === "ConvertInvoiceFromInward") {
          ModalMode = false
          Modaltype = "purchase_invoice"
        } else if (otherDetail === "ConvertJobFromInward") {
          ModalMode = false
          Modaltype = "job_delivery_challan"
        } else if (otherDetail === "add_record") {
          ModalMode = false
          Modaltype = "Add Record"
          metaData = {
            type: formtype,
          }
        } else if (formtype === "finish_stock" && !isblank(otherDetail) && otherDetail.last_row_flag) {
          Modaltype = "Add Record"
          metaData = otherDetail
          position = "bottom"
          modalWidth = ""
        } else {
          ModalMode = false
          Modaltype = formtype
        }
      } else if (formtype === "customField" && otherDetail !== "getMyStore") {
        ModalMode = false
        Modaltype = "Add New Custom Field"
      } else if (formtype === "customField") {
        Modaltype = "Add New Attributes"
      } else if (formtype === "customPage") {
        //ModalMode = false;
        Modaltype = "Add New Custom Page"
      } else if (formtype === "lot_transfer") {
        ModalMode = false
        if (otherDetail === "job_process_invoice") {
          Modaltype = "job_process_invoice"
        } else if (otherDetail === "ConvertJobFromProcess") {
          Modaltype = "job_delivery_challan"
        } else {
          Modaltype = "Add New Process Transfer"
        }
      } else if (formtype === "stock_transferGodown") {
        ModalMode = false
        Modaltype = "Godown Stock Transfer"
      } else if (formtype === "stockTransfer") {
        ModalMode = false
        Modaltype = "Stock Transfer"
      } else if (outward_type_detail.filter((e) => e.id === formtype).length > 0) {
        if (otherDetail === "ConvertInvoiceFromOutward") {
          ModalMode = false
          Modaltype = "sales_invoice"
        } else if (otherDetail === "ConvertJobFromOutward") {
          ModalMode = false
          Modaltype = "job_delivery_challan"
        } else {
          ModalMode = false
          Modaltype = formtype
        }
      } else if (formtype === "ewaybill_manual") {
        ModalMode = false
      } else if (formtype === "chequePrinting") {
        ModalMode = false
        Modaltype = "Add New Cheque"
      } else if (formtype === "webhook") {
        ModalMode = false
        Modaltype = "Add New Webhook"
      } else {
        alert("Modal not added in common actions")
      }

      if (ModalMode) {
        pushDrawer({ title: Modaltype, data: metaData, fieldname, position, width: modalWidth, dispatch })
      } else {
        if (
          invoice_type_detail.filter((e) => e.id === formtype).length > 0 ||
          formtype === "envelopePrinting" ||
          formtype === "chequePrinting" ||
          formtype === "merge_packingSlip" ||
          formtype === "bot" ||
          formtype === "gstoldbill" ||
          formtype === "receipt" ||
          formtype === "recordexpense" ||
          formtype === "payment" ||
          formtype === "journal_entry" ||
          formtype === "salesreturn" ||
          formtype === "purchasereturn" ||
          purchase_type_detail.filter((e) => e.id === formtype).length > 0 ||
          inward_type_detail.filter((e) => e.id === formtype).length > 0 ||
          formtype === "lot_transfer" ||
          formtype === "stockTransfer" ||
          formtype === "stock_transferGodown" ||
          outward_type_detail.filter((e) => e.id === formtype).length > 0 ||
          formtype === "bundleitem" ||
          formtype === "useripostock" ||
          formtype === "label_design" ||
          formtype === "customField" ||
          formtype === "webhook"
        ) {
          pushDrawer({ title: Modaltype, data: metaData, fieldname, position: "bottom", dispatch })
        } else if (formtype === "ewaybill_manual") {
          dispatch({
            type: CONFIRM_DIALOG,
            payload: true,
            confirm: false,
            message: "",
            title: "Update e-Way Bill Manually",
            form: "Update e-Way Bill Manually",
            meta: other_type,
          })
          dispatch(change("updateEwayBillManualForm", "ewbno", user.data.form_data.ewbno))
          if (isblank(user.data.form_data.ewbdate)) {
            dispatch(change("updateEwayBillManualForm", "ewbdate", todaydate("YMD")))
          } else {
            dispatch(change("updateEwayBillManualForm", "ewbdate", user.data.form_data.ewbdate))
          }
        } else {
          goToNavigate(`/${Modaltype}`)
        }
      }
    } else if (formaction === "insert") {
      if (formtype === "city") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.city
        inserteditem.state = getdropdownvalue({ formValues: formdata, field: "stateSelectedValue", prop: "name" })
        inserteditem.country = getdropdownvalue({ formValues: formdata, field: "countrySelectedValue", prop: "name" })

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })
        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          name: formdata.city,
          payload: formdata,
        })
      } else if (formtype === "generate_irn_ewaybill") {
        dispatch({
          type: E_WAY_BILL_UPDATE,
          formtype: formtype,
          invoiceCode: formdata.invoiceCode,
        })
      } else if (formtype === "envelopePrinting") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.name

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          payload: formdata,
        })
      } else if (formtype === "chequePrinting") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.name

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })
      } else if (formtype === "bot") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.name
        inserteditem.description = formdata.description
        inserteditem.type = getdropdownvalue({ formValues: formdata, field: "type", prop: "name" })
        inserteditem.status = "Active"

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })
      } else if (formtype === "costcenter") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.name
        inserteditem.parentname = getdropdownvalue({ formValues: formdata, field: "parentcostcenterSelectedValue", prop: "name" })

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          name: formdata.name,
          payload: formdata,
        })
      } else if (formtype === "label_setting") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.name
        inserteditem.papersize = formdata.pageSizeSelectedValue
        inserteditem.metric = formdata.metricSelectedValue

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          name: formdata.name,
          payload: formdata,
        })
      } else if (formtype === "adjustBill" || formtype === "adjustBillCr") {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: formtype,
          lastaction: "submit",
          lasttime: new Date().getTime(),
        })
      } else if (formtype === "label_design") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.templatename = formdata.templateName
        inserteditem.papersize = getdropdownvalue({ formValues: formdata, field: "paperSizeSelectedValue", prop: "name" })
        inserteditem.type = "product"

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })
      } else if (formtype === "merge_packingSlip") {
        let inserteditem = {}
        if (user.data.list_row.length > 0) {
          inserteditem = user.data.list_row[0]
          // eslint-disable-next-line
          inserteditem.oldNumber = inserteditem.oldNumber.replace(/[\[\]"]+/g, "")
          // eslint-disable-next-line
          inserteditem.newNumber = inserteditem.newNumber.replace(/[\[\]"]+/g, "")
        } else {
          inserteditem.code = user.data.insert_code
          inserteditem.mdate = formdata.InvoiceDate
          inserteditem.oldNumber = formdata.PackingSlipGrid.map((e) => e.billno)
          // eslint-disable-next-line
          inserteditem.oldNumber = JSON.stringify(inserteditem.oldNumber).replace(/[\[\]"]+/g, "")
          inserteditem.newNumber = formdata.PackingDetailGrid.map((e) => e.billNo)
          inserteditem.newNumber = getuniqueArray(inserteditem.newNumber)
          // eslint-disable-next-line
          inserteditem.newNumber = JSON.stringify(inserteditem.newNumber).replace(/[\[\]"]+/g, "")
        }
        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })
      } else if (formtype === "country") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.name

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })
      } else if (formtype === "customer_supplier") {
        if (formdata.list_flag === 1) {
          dispatch({
            type: COMMON_SERVICE_ARRAY_INSERTED,
            row: user.data.list_row ? user.data.list_row : [],
          })
        }
        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: other_type,
          code: user.data.insert_code,
          name: formdata.customerSupplierName,
          payload: formdata,
          invoice_cus_sup: invoice_cus_sup,
        })
      } else if (formtype === "kanban_transfer") {
        otherDetail.insertCode = user.data.insert_code
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "kanban",
          lastaction: "transfer",
          lasttime: new Date().getTime(),
          lastdata: otherDetail,
        })
      } else if (formtype === "other_account") {
        if (formdata.list_flag === 1) {
          if (formdata.GroupSelectedValue.code !== "L0021" && formdata.GroupSelectedValue.code !== "A0019") {
            const inserteditem = {}
            inserteditem.code = user.data.insert_code
            inserteditem.name = formdata.name
            inserteditem.groupname = getdropdownvalue({ formValues: formdata, field: "GroupSelectedValue", prop: "name" })
            inserteditem.typename = getdropdownvalue({ formValues: formdata, field: "TypeSelectedValue", prop: "name" })
            inserteditem.opbal = isblank(formdata.openingBalance)
              ? "0.00 " + getdropdownvalue({ formValues: formdata, field: "opbal_crdrSelectedValue", prop: "name" })
              : amount_format(formdata.openingBalance) + " " + getdropdownvalue({ formValues: formdata, field: "opbal_crdrSelectedValue", prop: "name" })

            dispatch({
              type: COMMON_SERVICE_INSERTED,
              row: inserteditem,
            })
          }
        }
        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          name: formdata.name,
          payload: formdata,
          invoice_cus_sup: invoice_cus_sup,
        })
      } else if (formtype === "partygroup") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.name

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })
      } else if (formtype === "subgroup") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.name
        inserteditem.groupname = getdropdownvalue({ formValues: formdata, field: "groupSelectedValue", prop: "name" })

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          payload: formdata,
        })
      } else if (formtype === "taxscheme") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.name

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          name: formdata.state,
          payload: formdata,
        })
      } else if (formtype === "state") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.state
        inserteditem.statecode = formdata.code
        inserteditem.country_code = getdropdownvalue({ formValues: formdata, field: "countrySelectedValue", prop: "country_code" })

        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          name: formdata.state,
          payload: formdata,
        })
      } else if (formtype === "agent") {
        if (formdata.list_flag === 1) {
          const inserteditem = {}
          inserteditem.code = user.data.insert_code
          inserteditem.name = formdata.a_name
          inserteditem.agentcomm = formdata.a_commission
          inserteditem.email = formdata.a_email
          inserteditem.mobile = formdata.a_mobile
          inserteditem.a_phone = formdata.a_phone
          inserteditem.address = formdata.a_address
          inserteditem.city = getdropdownvalue({ formValues: formdata, field: "citySelectedValue", prop: "name" })
          inserteditem.state = getdropdownvalue({ formValues: formdata, field: "stateSelectedValue", prop: "name" })
          inserteditem.opbal = isblank(formdata.a_openingBalance)
            ? "0.00 " + getdropdownvalue({ formValues: formdata, field: "opbal_crdrSelectedValue", prop: "name" })
            : amount_format(formdata.a_openingBalance) + " " + getdropdownvalue({ formValues: formdata, field: "opbal_crdrSelectedValue", prop: "name" })

          dispatch({
            type: COMMON_SERVICE_INSERTED,
            row: inserteditem,
          })
        }

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          name: formdata.a_name,
          payload: formdata,
        })
      } else if (formtype === "company") {
      } else if (formtype === "transport") {
        if (formdata.list_flag === 1) {
          const inserteditem = {}
          inserteditem.code = user.data.insert_code
          inserteditem.name = formdata.name
          inserteditem.contactperson = formdata.person
          inserteditem.mobile = formdata.mobile
          inserteditem.gstin = formdata.gstin
          inserteditem.city = getdropdownvalue({ formValues: formdata, field: "citySelectedValue", prop: "name" })
          inserteditem.state = getdropdownvalue({ formValues: formdata, field: "stateSelectedValue", prop: "name" })
          inserteditem.country = getdropdownvalue({ formValues: formdata, field: "countrySelectedValue", prop: "name" })

          dispatch({
            type: COMMON_SERVICE_INSERTED,
            row: inserteditem,
          })
        }
        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          name: formdata.name,
          payload: formdata,
        })
      } else if (formtype === "godown") {
        if (formdata.list_flag === 1) {
          const company_city = getdropdownvalue({ formValues: formdata, field: "citySelectedValue", prop: "name" })
          const company_state = getdropdownvalue({ formValues: formdata, field: "stateSelectedValue", prop: "name" })
          const company_country = getdropdownvalue({ formValues: formdata, field: "countrySelectedValue", prop: "name" })

          const inserteditem = {}
          inserteditem.code = user.data.insert_code
          inserteditem.name = formdata.name
          inserteditem.status = formdata.defaultGodown === true ? "Primary" : ""
          inserteditem.state = company_state
          inserteditem.country = company_country
          inserteditem.city = company_city
          inserteditem.address = generateDisplayAddress({
            address: formdata.address,
            areacode: formdata.areaCode,
            city: company_city,
            state: company_state,
            country: company_country,
            pincode: formdata.zipCode,
          })

          dispatch({
            type: COMMON_SERVICE_INSERTED,
            row: inserteditem,
          })
        }
        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          name: formdata.name,
          payload: formdata,
        })
      } else if (formtype === "item") {
        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          payload: formdata,
          list_row: user.data.list_row ? user.data.list_row : [],
        })

        if (!isblank(otherDetail) && !isblank(otherDetail.from) && otherDetail.from === "page") {
          if (saveandaddanother !== true) {
            setStorage(`last_visited_page_${getWindowYear()}`, "products")
            goToNavigate(`/products`)
          }
        }
      } else if (formtype === "bundleitem") {
      } else if (formtype === "receipt" || formtype === "payment") {
        if (formtype === "receipt") {
          setLastTransDate({ dispatch, dateKey: "last_receiptdate", date: formdata.date })
        } else {
          setLastTransDate({ dispatch, dateKey: "last_paymentdate", date: formdata.date })
        }
        if (common_formtype !== "draft") {
          const newarray = []
          if (formdata.receipt_type === "M") {
            const insert_code = user.data.insert_code
            const docno = user.data.docno
            const r_date = formdata.date
            const name = getdropdownvalue({ formValues: formdata, field: "bankSelectedValue", prop: "name" })
            const acc_code = getdropdownvalue({ formValues: formdata, field: "bankSelectedValue", prop: "code" })
            formdata.DetailGrid.forEach((detailgrid, index) => {
              const inserteditem = {}
              inserteditem.code = insert_code
              inserteditem.newCode = insert_code + `_${index}`
              inserteditem.docno = docno
              inserteditem.vdate = r_date
              inserteditem.name = name
              inserteditem.acc_code = acc_code
              inserteditem.name1 = getdropdownvalue({ formValues: detailgrid, field: "accountSelectedValue", prop: "name" })
              inserteditem.acc_code1 = getdropdownvalue({ formValues: detailgrid, field: "accountSelectedValue", prop: "code" })
              inserteditem.amount = detailgrid.amount
              inserteditem.chequeno = detailgrid.cheque
              newarray.push(inserteditem)
            })
          } else {
            const inserteditem = {}
            inserteditem.code = user.data.insert_code
            inserteditem.newCode = user.data.insert_code + "_0"
            inserteditem.docno = user.data.docno
            inserteditem.vdate = formdata.date
            inserteditem.name = getdropdownvalue({ formValues: formdata, field: "bankSelectedValue", prop: "name" })
            inserteditem.acc_code = getdropdownvalue({ formValues: formdata, field: "bankSelectedValue", prop: "code" })
            inserteditem.name1 = getdropdownvalue({ formValues: formdata, field: "accountSelectedValue", prop: "name" })
            inserteditem.acc_code1 = getdropdownvalue({ formValues: formdata, field: "accountSelectedValue", prop: "code" })
            inserteditem.amount = formdata.amount
            inserteditem.chequeno = formdata.cheque
            newarray.push(inserteditem)
          }

          dispatch({
            type: COMMON_SERVICE_ARRAY_INSERTED,
            row: newarray,
          })
        } else {
          if (!isblank(draftCode)) {
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "draft_edit",
              lastaction: "delete",
              lasttime: new Date().getTime(),
              lastdata: {
                code: draftCode,
              },
            })
          }
        }
      } else if (formtype === "record_expense") {
        setLastTransDate({ dispatch, dateKey: "last_recordexpensedate", date: formdata.date })
        if (common_formtype !== "draft") {
          let inserteditem = {}
          if (user.data.list_row.length > 0) {
            inserteditem = { ...dataSetInListPurchase(user.data.list_row)[0] }
          } else {
            inserteditem.code = user.data.insert_code
            inserteditem.docno = user.data.docno
            inserteditem.vdate = formdata.date
            inserteditem.invoice_no = formdata.InvoiceNo
            inserteditem.supplier_name = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "name" })
            inserteditem.acc_code2 = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "code" })
            inserteditem.name = getdropdownvalue({ formValues: formdata, field: "bankSelectedValue", prop: "name" })
            inserteditem.acc_code = getdropdownvalue({ formValues: formdata, field: "bankSelectedValue", prop: "code" })
            inserteditem.name1 = getdropdownvalue({ formValues: formdata, field: "accountSelectedValue", prop: "name" })
            inserteditem.acc_code1 = getdropdownvalue({ formValues: formdata, field: "accountSelectedValue", prop: "code" })
            inserteditem.amount = formdata.netamount
            inserteditem.chequeno = formdata.cheque
          }
          dispatch({
            type: COMMON_SERVICE_INSERTED,
            row: inserteditem,
          })
        } else {
          if (!isblank(draftCode)) {
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "draft_edit",
              lastaction: "delete",
              lasttime: new Date().getTime(),
              lastdata: {
                code: draftCode,
              },
            })
          }
        }
      } else if (formtype === "expense_payment") {
        dispatch(reset("modalAddExpensePayment"))
      } else if (formtype === "journal_entry" && isblank(otherDetail)) {
        setLastTransDate({ dispatch, dateKey: "last_journalentrydate", date: formdata.invoicedate })

        if (common_formtype !== "draft") {
          const newarray = []
          const insert_code = user.data.insert_code
          const billno = user.data.docno
          const billno_str = user.data.docno
          const j_date = DatetoDMY(formdata.invoicedate)

          formdata.JournalEntryGrid.forEach((detailgrid, index) => {
            const inserteditem = {}
            inserteditem.code = insert_code
            inserteditem.newCode = insert_code + "_" + index
            inserteditem.billno = billno
            inserteditem.billno_str = billno_str
            inserteditem.j_date = j_date
            inserteditem.name = getdropdownvalue({ formValues: detailgrid, field: "accountSelectedValue", prop: "name" })
            inserteditem.partycode = getdropdownvalue({ formValues: detailgrid, field: "accountSelectedValue", prop: "code" })
            inserteditem.debit = detailgrid.debit
            inserteditem.credit = detailgrid.credit

            newarray.push(inserteditem)
          })
          dispatch({
            type: COMMON_SERVICE_ARRAY_INSERTED,
            row: newarray,
          })
        } else {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "draft_edit",
            lastaction: "delete",
            lasttime: new Date().getTime(),
            lastdata: {
              code: draftCode,
            },
          })
        }
      } else if (invoice_type_detail.filter((e) => e.id === formtype).length > 0 || (formtype === "gstoldbill" && invoice_type_detail.filter((e) => e.id === other_type).length > 0)) {
        if (formtype !== "gstoldbill") {
          setLastTransDate({ dispatch, dateKey: "last_salesinvoicedate", date: formdata.saleInvoiceDate })
        }
        if (otherDetail === "ConvertOrderFromEstimate" || otherDetail === "ConvertInvoiceFromEstimate") {
          dispatch({
            type: ESTIMATE_SERVICE_CONVERTED,
            payload: formdata,
            insert_data: user.data,
          })
        } else if (otherDetail === "ConvertInvoiceFromOutward" || otherDetail === "ConvertJobFromOutward" || otherDetail === "ConvertJobFromProcess") {
          dispatch({
            type: COMMON_SERVICE_DELETED,
            payload: {
              code: formdata.outward_ref_code,
            },
            otherDetail: otherDetail,
            response: user,
          })
        }
      } else if ((formtype === "salesreturn" && common_formtype !== "salesinvoice") || (formtype === "gstoldbill" && other_type === "salesreturn")) {
        if (formtype === "salesreturn") {
          setLastTransDate({ dispatch, dateKey: "last_salesreturndate", date: formdata.InvoiceDate })
        }

        dispatch({
          type: UPDATE_OTHER_DETAIL,
          otherDetail: "",
        })

        if (common_formtype !== "draft") {
          var inserteditem = {}
          if (user.data.list_row.length > 0) {
            inserteditem = { ...dataSetInListSPReturn(user.data.list_row)[0] }
          } else {
            inserteditem.code = user.data.insert_code
            inserteditem.creditnote_no = user.data.docno
            inserteditem.billno = formdata.previousYearBill ? formdata.previuos_billno : getdropdownvalue({ formValues: formdata, field: "salesBillSelectedValue", prop: "name" })
            inserteditem.bill_date = DatetoDMY(formdata.InvoiceDate)
            inserteditem.name = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "name" })
            inserteditem.partycode = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "code" })
            inserteditem.city = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "city" })
            inserteditem.state = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "state" })
            inserteditem.netamt = formdata.tot_net_amount
            inserteditem.e_invoice_status = "Not Generated"
            inserteditem.ewaybill_status = "Not Generated"
            inserteditem.invoice_type = getdropdownvalue({ formValues: formdata, field: "noteTypeSelectedValue", prop: "name" })
            inserteditem.gstr1_filed = formdata.gstr1_filed === "1" ? "Filed" : "Not Filed"
            inserteditem.gstr3B_filed = formdata.gstr3B_filed === "1" ? "Filed" : "Not Filed"
          }
          dispatch({
            type: COMMON_SERVICE_INSERTED,
            row: inserteditem,
          })
        } else {
          if (!isblank(draftCode)) {
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "draft_edit",
              lastaction: "delete",
              lasttime: new Date().getTime(),
              lastdata: {
                code: draftCode,
              },
            })
          }
        }
      } else if (formtype === "purchasereturn" || (formtype === "gstoldbill" && other_type === "purchasereturn")) {
        if (formtype === "purchasereturn") {
          setLastTransDate({ dispatch, dateKey: "last_purchasereturndate", date: formdata.saleInvoiceDate })
        }
        if (common_formtype !== "draft") {
          var inserteditem = {}
          if (user.data.list_row.length > 0) {
            inserteditem = { ...dataSetInListSPReturn(user.data.list_row)[0] }
          } else {
            inserteditem.code = user.data.insert_code
            inserteditem.debitnote_no = user.data.docno
            inserteditem.billno = formdata.previousYearBill ? formdata.previuos_billno : getdropdownvalue({ formValues: formdata, field: "salesBillSelectedValue", prop: "name" })
            inserteditem.b_date = DatetoDMY(formdata.saleInvoiceDate)
            inserteditem.name = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "name" })
            inserteditem.acc_code = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "code" })
            inserteditem.city = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "city" })
            inserteditem.state = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "state" })
            inserteditem.invoice_type = getdropdownvalue({ formValues: formdata, field: "noteTypeSelectedValue", prop: "name" })
            inserteditem.netamt = formdata.tot_net_amount
            inserteditem.e_invoice_status = "Not Generated"
            inserteditem.ewaybill_status = "Not Generated"
            inserteditem.gstr3B_filed = formdata.gstr3B_filed === "1" ? "Filed" : "Not Filed"
          }
          dispatch({
            type: COMMON_SERVICE_INSERTED,
            row: inserteditem,
          })
        } else {
          if (!isblank(draftCode)) {
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "draft_edit",
              lastaction: "delete",
              lasttime: new Date().getTime(),
              lastdata: {
                code: draftCode,
              },
            })
          }
        }
      } else if (purchase_type_detail.filter((e) => e.id === formtype).length > 0 || (formtype === "gstoldbill" && purchase_type_detail.filter((e) => e.id === other_type).length > 0)) {
        if (formtype !== "gstoldbill") {
          setLastTransDate({ dispatch, dateKey: "last_purchaseinvoicedate", date: formdata.InvoiceDate })
        }
        if (otherDetail === "Convert Invoice") {
          dispatch({
            type: INVOICE_SERVICE_CONVERTED,
            payload: formdata,
            insert_data: user.data,
          })
        }
        if (otherDetail === "ConvertInvoiceFromInward" || otherDetail === "ConvertJobFromInward") {
          dispatch({
            type: COMMON_SERVICE_DELETED,
            payload: {
              code: formdata.inward_ref_code,
            },
            otherDetail: otherDetail,
            response: user,
          })
        } else {
          if (otherDetail !== "Convert Invoice") {
            if (formdata.list_flag === 1) {
              if (common_formtype !== "draft") {
                var inserteditem = {}
                if (user.data.list_row.length > 0) {
                  inserteditem = { ...dataSetInListPurchase(user.data.list_row)[0] }
                } else {
                  inserteditem.code = user.data.insert_code
                  inserteditem.billno = user.data.docno
                  inserteditem.bill_date = DatetoDMY(formdata.InvoiceDate)
                  inserteditem.name = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "name" })
                  inserteditem.partycode = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "code" })
                  inserteditem.city = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "city" })
                  inserteditem.state = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "state" })
                  inserteditem.netamt = formdata.tot_net_amount
                  inserteditem.gstr3B_filed = formdata.gstr3B_filed === "1" ? "Filed" : "Not Filed"
                  inserteditem.is_overdue_status = user.data.is_overdue_status
                  inserteditem.adjust_status =
                    parseInt(user.data.adjust_status) === 0 ? "Unpaid" : parseInt(user.data.adjust_status) === 1 ? "Partially Paid" : parseInt(user.data.adjust_status) === 2 ? "Paid" : "Unpaid"
                  inserteditem.due_date = DatetoDMY(formdata.dueDate)
                  inserteditem = getDueStatus(inserteditem)
                  inserteditem.status = "Pending"
                }
                dispatch({
                  type: COMMON_SERVICE_INSERTED,
                  row: inserteditem,
                })
              } else {
                if (!isblank(draftCode)) {
                  dispatch({
                    type: LAST_PAGE_UPDATE,
                    lasttype: "draft_edit",
                    lastaction: "delete",
                    lasttime: new Date().getTime(),
                    lastdata: {
                      code: draftCode,
                    },
                  })
                }
              }
            }
          }
        }
        dispatch({
          type: UPDATE_OTHER_DETAIL,
          otherDetail: "",
        })
      } else if (inward_type_detail.filter((e) => e.id === formtype).length > 0) {
        if (formdata.list_flag === 1) {
          if (common_formtype !== "draft") {
            var inserteditem = {}
            if (user.data.list_row?.length > 0) {
              inserteditem = { ...dataSetInListIO(user.data.list_row)[0] }
            } else {
              inserteditem.code = user.data.insert_code
              inserteditem.billno = user.data.docno
              inserteditem.b_date = DatetoDMY(formdata.InvoiceDate)
              inserteditem.name = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "name" })
              inserteditem.acc_code = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "code" })
              inserteditem.ps_status = getdropdownfieldvalue({ dropDownData: getallPackingStatus(), field: "id", value: formdata.psStatus, displayvalue: "name" })
              inserteditem.narration = formdata.narration
              inserteditem.ewaybill_status = "Not Generated"
              inserteditem.totqty = formdata.tot_qty
              inserteditem.flag = "Lock"
            }
            dispatch({
              type: COMMON_SERVICE_INSERTED,
              row: inserteditem,
            })
          } else {
            if (!isblank(draftCode)) {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: "draft_edit",
                lastaction: "delete",
                lasttime: new Date().getTime(),
                lastdata: {
                  code: draftCode,
                },
              })
            }
          }
        }
      } else if (formtype === "lot_transfer") {
        setLastTransDate({ dispatch, dateKey: "last_date_process", date: formdata.InvoiceDate })

        let inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.billno = user.data.docno
        inserteditem.b_date = DatetoDMY(formdata.InvoiceDate)
        inserteditem.name = getdropdownvalue({ formValues: formdata, field: "toProcessSelectedValue", prop: "name" })
        inserteditem.processCode = getdropdownvalue({ formValues: formdata, field: "toProcessSelectedValue", prop: "code" })
        inserteditem.partyname = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "name" })
        inserteditem.acc_code = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "code" })
        inserteditem.totQty = formdata.tot_qty
        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })
      } else if (formtype === "process") {
        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.name
        inserteditem.srno = formdata.srno
        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })
        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          name: formdata.name,
          payload: formdata,
        })
      } else if (outward_type_detail.filter((e) => e.id === formtype).length > 0) {
        if (common_formtype !== "draft") {
          var inserteditem = {}
          if (user.data.list_row.length > 0) {
            inserteditem = { ...dataSetInListIO(user.data.list_row)[0] }
          } else {
            inserteditem.code = user.data.insert_code
            inserteditem.billno = user.data.docno
            inserteditem.b_date = DatetoDMY(formdata.InvoiceDate)
            inserteditem.name = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "name" })
            inserteditem.acc_code = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "code" })
            inserteditem.narration = formdata.narration
            inserteditem.ps_status = getdropdownfieldvalue({ dropDownData: getallPackingStatus(), field: "id", value: formdata.psStatus, displayvalue: "name" })
            inserteditem.totqty = formdata.tot_qty
            inserteditem.ewaybill_status = "Not Generated"
            inserteditem.flag = "Lock"
          }
          dispatch({
            type: COMMON_SERVICE_INSERTED,
            row: inserteditem,
          })
        } else {
          if (!isblank(draftCode)) {
            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: "draft_edit",
              lastaction: "delete",
              lasttime: new Date().getTime(),
              lastdata: {
                code: draftCode,
              },
            })
          }
        }
      } else if (formtype === "stock_transferGodown") {
        setStorage("last_date_stock_transferGodown", formdata.InvoiceDate)

        let inserteditem = {}
        if (user.data.list_row.length > 0) {
          inserteditem = user.data.list_row[0]
        } else {
          inserteditem.code = user.data.insert_code
          inserteditem.billno = user.data.docno
          inserteditem.b_date = DatetoDMY(formdata.InvoiceDate)
          inserteditem.name = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "name" })
          inserteditem.acc_code = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "code" })
        }
        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })
      } else if (formtype === "stockTransfer") {
        setStorage("last_date_stock_transfer", formdata.InvoiceDate)
        let inserteditem = {}
        if (user.data.list_row.length > 0) {
          inserteditem = user.data.list_row[0]
        } else {
          inserteditem.code = user.data.insert_code
          inserteditem.billno = user.data.docno
          inserteditem.b_date = DatetoDMY(formdata.InvoiceDate)
          inserteditem.name = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "name" })
          inserteditem.acc_code = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "code" })
        }
        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })
      } else if (formtype === "importunit") {
        dispatch({
          type: IMPORT_UNIT_INSERTED_SUCCESS,
          payload: formdata,
        })

        dispatch({
          type: IMPORT_UNIT_SERVICE_INSERTED,
          payload: formdata,
          insert_code: user.data.insert_code,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          payload: formdata,
        })
      } else if (formtype === "importlabel_setting") {
        dispatch({
          type: IMPORT_LABEL_SETTING_SERVICE_SUCCESS,
          payload: formdata,
          insert_code: user.data.insert_code,
        })
        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          payload: formdata,
        })
      } else if (formtype === "importother_account") {
        dispatch({
          type: IMPORT_ACCOUNT_SERVICE_INSERTED,
          payload: formdata,
          insert_code: user.data.insert_code,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          payload: formdata,
        })
      } else if (formtype === "importtaxscheme") {
        var newarray = []
        formdata.DetailGrid.forEach((detailgrid, index) => {
          if (user.data.insert_code.filter((e) => e.code.toString() === detailgrid.code.toString()).length > 0) {
            const inserteditem = {}
            inserteditem.code = user.data.insert_code.find((e) => e.code.toString() === detailgrid.code.toString()).update_code
            inserteditem.name = detailgrid.name
            newarray.push(inserteditem)
          }
        })
        dispatch({
          type: COMMON_SERVICE_ARRAY_INSERTED,
          row: newarray,
        })
        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          payload: formdata,
        })
      } else if (formtype === "wiretransfer") {
        dispatch({
          type: WIRETRANSFER_SERVICE_INSERTED,
          payload: formdata,
          insert_code: user.data.insert_code,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          payload: formdata,
        })
      } else if (formtype === "wiresender") {
        dispatch({
          type: WIRETRANSFER_SENDER_SERVICE_INSERTED,
          payload: formdata,
          insert_code: user.data.insert_code,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          payload: formdata,
          name: formdata.bankName,
        })
      } else if (formtype === "beneficiary") {
        dispatch({
          type: WIRETRANSFER_BENEFICIARY_SERVICE_INSERTED,
          payload: formdata,
          insert_code: user.data.insert_code,
        })

        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          code: user.data.insert_code,
          payload: formdata,
          name: formdata.receivingBankName,
        })
      } else if (formtype === "useripostock") {
        dispatch({
          type: IPOSTOCK_SERVICE_INSERTED,
          payload: formdata,
          insert_code: user.data.insert_code,
        })
      } else if (formtype === "ipostockparty") {
        dispatch({
          type: IPOSTOCKPARTY_SERVICE_INSERTED,
          payload: formdata,
          insert_code: user.data.insert_code,
        })
      } else if (formtype === "ipostockmaster") {
        dispatch({
          type: IPOSTOCKMASTER_SERVICE_INSERTED,
          payload: formdata,
          insert_code: user.data.insert_code,
        })
      } else if (formtype === "bank_statement") {
        dispatch(reset("ImportBankStatementForm"))
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: formtype,
          lastaction: "ImportBankStatementSuccess",
          lasttime: new Date().getTime(),
        })
      } else if (formtype === "customField") {
        var firmdetails = getStorage(`config_setting_${getWindowYear()}`)

        if (firmdetails.custom_fields !== "yes") {
          firmdetails.custom_fields = "yes"
          setStorage(`config_setting_${getWindowYear()}`, firmdetails)
          dispatch({
            type: UPDATE_CONFIG_SETTING,
            payload: firmdetails,
          })
        }
        if (convertIntif(formdata.decimalPointSelectedValue) === 7) {
          formdata.decimalPointSelectedValue = inventory_decimalpoint
        }
        dispatch({
          type: DROPDOWN_ITEM_INSERTED,
          formtype: formtype,
          payload: formdata,
          code: user.data.insert_code,
        })

        dispatch({
          type: CUSTOM_FIELD_SERVICE_INSERTED,
          payload: formdata,
          insert_code: user.data.insert_code,
        })
      } else if (formtype === "customPage") {
        customPages = []
        if (getStorage(`customPages_${getWindowYear()}`, "")) {
          customPages = getStorage(`customPages_${getWindowYear()}`, "")
        }
        customPages.push({
          name: formdata.name,
          singleEntry: formdata.singleEntry,
          draggableList: formdata.draggableList,
          code: user.data.insert_code.toString(),
        })
        setStorage(`customPages_${getWindowYear()}`, customPages)
        dispatch({
          type: CUSTOM_PAGE_ITEM_UPDATED,
          customPages: customPages,
        })

        const inserteditem = {}
        inserteditem.code = user.data.insert_code
        inserteditem.name = formdata.name
        dispatch({
          type: COMMON_SERVICE_INSERTED,
          row: inserteditem,
        })
      }
      if (formdata.sendSMS || formdata.sendEmail || formdata.sendWhatsApp || formdata.sendAdmin) {
        lastaction = "saveAndNotification"
        if (saveandprint === true) {
          lastaction = "saveAndNotificationAndPrint"
        }
        setTimeout(() => {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: formtype,
            lastaction: lastaction,
            lasttime: new Date().getTime(),
            lastdata: {
              formtype: formtype,
              sendSMS: formdata.sendSMS,
              sendEmail: formdata.sendEmail,
              sendWhatsApp: formdata.sendWhatsApp,
              sendAdmin: formdata.sendAdmin,
              code: user.data.insert_code,
            },
          })
        }, 0)
      } else if (saveandprint === true) {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: formtype,
          lastaction: "saveandprint",
          lasttime: new Date().getTime(),
          lastdata: user.data.insert_code,
        })
      }
      onSaveAndAddAnother()
    } else if (formaction === "delete") {
      Modaltype = ""
      if (
        checkCondition(formtype, [
          "city",
          "draft",
          "envelopePrinting",
          "chequePrinting",
          "bot",
          "costcenter",
          "taxscheme",
          "merge_packingSlip",
          "country",
          "customer_supplier",
          "partygroup",
          "subgroup",
          "state",
          "user",
          "agent",
          "transport",
          "godown",
          "receipt",
          "payment",
          "record_expense",
          "process",
          "journal_entry",
          "lot_transfer",
          "stock_transferGodown",
          "stockTransfer",
          "label_setting",
          "label_design",
        ])
      ) {
        dispatch({
          type: COMMON_SERVICE_DELETED,
          payload: formdata,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "cancel_irn_ewaybill") {
        dispatch({
          type: E_WAY_BILL_UPDATE,
          formtype: formtype,
          invoiceCode: formdata.invoiceCode,
        })
      } else if (formtype === "connect_company") {
        dispatch({
          type: ORGANIZATION_SERVICE_DELETED,
          payload: formdata.code,
          otherDetail: otherDetail,
        })

        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: formtype,
          lastaction: "delete",
          lasttime: new Date().getTime(),
          lastdata: formdata,
        })
      } else if (formtype === "company") {
      } else if (formtype === "notifications") {
        dispatch({
          type: CUSTOM_PAGE_SERVICE_DELETED,
          payload: formdata,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "other_account") {
        var userpay = {}
        userpay.data = {}
        userpay.data.bankaccount = []
        userpay.data.expenseaccount = []
        userpay.data.sales_account = []
        userpay.data.purchase_account = []
        userpay.data.dropdown_closingstock = []

        dispatch({
          type: FORM_DEP_SERVICE_SUCCESS,
          payload: userpay,
        })

        dispatch({
          type: COMMON_SERVICE_DELETED,
          payload: formdata,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "bundleitem") {
      } else if (
        invoice_type_detail.filter((e) => e.id === formtype).length > 0 ||
        purchase_type_detail.filter((e) => e.id === formtype).length > 0 ||
        inward_type_detail.filter((e) => e.id === formtype).length > 0 ||
        outward_type_detail.filter((e) => e.id === formtype).length > 0 ||
        (formtype === "gstoldbill" && invoice_type_detail.filter((e) => e.id === other_type).length > 0) ||
        (formtype === "gstoldbill" && purchase_type_detail.filter((e) => e.id === other_type).length > 0)
      ) {
        Modaltype = formtype
        dispatch({
          type: COMMON_SERVICE_DELETED,
          payload: formdata,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "salesreturn" || (formtype === "gstoldbill" && other_type === "salesreturn")) {
        Modaltype = "sales_return"
        dispatch({
          type: COMMON_SERVICE_DELETED,
          payload: formdata,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "purchasereturn" || (formtype === "gstoldbill" && other_type === "purchasereturn")) {
        Modaltype = "purchase_return"
        dispatch({
          type: COMMON_SERVICE_DELETED,
          payload: formdata,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "wiretransfer") {
        dispatch({
          type: WIRETRANSFER_SERVICE_DELETED,
          payload: formdata,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "wiresender") {
        dispatch({
          type: WIRETRANSFER_SENDER_SERVICE_DELETED,
          payload: formdata.code,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "beneficiary") {
        dispatch({
          type: WIRETRANSFER_BENEFICIARY_SERVICE_DELETED,
          payload: formdata.code,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "useripostock") {
        dispatch({
          type: IPOSTOCK_SERVICE_DELETED,
          payload: formdata.code,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "ipostockparty") {
        dispatch({
          type: IPOSTOCKPARTY_SERVICE_DELETED,
          payload: formdata.code,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "ipostockmaster") {
        dispatch({
          type: IPOSTOCKMASTER_SERVICE_DELETED,
          payload: formdata.code,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "reset_filegstr1") {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "gstr1",
          lastaction: "reset",
          lasttime: new Date().getTime(),
          lastdata: user.data.result,
        })
      } else if (formtype === "reset_filegstr2") {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "gstr2",
          lastaction: "reset",
          lasttime: new Date().getTime(),
          lastdata: user.data.result,
        })
      } else if (formtype === "customField") {
        if (other_type === "value") {
          dispatch({
            type: CUSTOMFIELD_OPTION_DELETED,
            formtype: formtype,
            code: user.data.insert_code,
            payload: formdata,
          })
        } else {
          dispatch({
            type: CUSTOM_FIELD_SERVICE_DELETED,
            payload: formdata,
            otherDetail: otherDetail,
            response: user,
          })
        }
      } else if (formtype === "customPage") {
        var customPages1 = getStorage(`customPages_${getWindowYear()}`, "")
        if (!isblank(customPages1)) {
          if (otherDetail === "multi_delete") {
            var FinalData = []
            var applyToDelete = formdata.code.codes.map((a) => a.toString())
            if (user.data.meta.code === 403) {
              //Error
              var NotDelete = user.data.meta.message.map((a) => a.code.toString())
              FinalData = customPages1.filter((item) => {
                item.code = item.code.toString()
                if (!(applyToDelete.includes(item.code) && !NotDelete.includes(item.code))) {
                  return item
                }
                return false
              })
            } //All delete
            else {
              FinalData = customPages1.filter((item) => {
                item.code = item.code.toString()
                if (!applyToDelete.includes(item.code)) {
                  return item
                }
                return false
              })
            }
            setStorage(`customPages_${getWindowYear()}`, FinalData)
            dispatch({
              type: CUSTOM_PAGE_ITEM_UPDATED,
              customPages: FinalData,
            })
          } else {
            customPages = customPages1.filter((e) => convertIntif(e.code) !== convertIntif(formdata.code))
            setStorage(`customPages_${getWindowYear()}`, customPages1)
            dispatch({
              type: CUSTOM_PAGE_ITEM_UPDATED,
              customPages: customPages1,
            })
          }
        }

        dispatch({
          type: COMMON_SERVICE_DELETED,
          payload: formdata,
          otherDetail: otherDetail,
          response: user,
        })
      } else if (formtype === "remove_social_account") {
        var firmdetails1 = getStorage(`config_setting_${getWindowYear()}`)
        if (firmdetails1.social_connect && firmdetails1.social_connect.length > 0 && firmdetails1.social_connect.filter((e) => e.social_key === other_type).length > 0) {
          firmdetails1.social_connect = firmdetails1.social_connect.map((e) => {
            const updated = { ...e }
            if (updated.social_key === other_type) {
              updated.status = "Inactive"
            }
            return updated
          })
          setStorage(`config_setting_${getWindowYear()}`, firmdetails1)
          dispatch({
            type: UPDATE_CONFIG_SETTING,
            payload: firmdetails1,
          })
        }
      }
      dispatch({
        type: DROPDOWN_ITEM_DELETED,
        formtype: formtype === "customer_supplier" ? other_type : formtype,
        payload: formdata,
        otherDetail: otherDetail,
        response: user,
        invoice_cus_sup: invoice_cus_sup,
      })

      if (otherDetail === "multi_delete") {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: formtype,
          lastaction: "multi_delete",
          lasttime: new Date().getTime(),
          lastdata: formdata,
        })
      }

      setTimeout(() => {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "post_form_data",
          lastaction: "delete",
          lasttime: new Date().getTime(),
        })
      }, 0)
    } else if (formaction === "update") {
      if (formtype === "finish_stock_add_record") {
        formtype = "finish_stock"
      }
      if (formtype === "popover") {
        dispatch({
          type: POP_OVER_UPDATED,
          payload: formdata,
        })
      }
      if (formtype === "city") {
        const updateditem = {}
        updateditem.name = formdata.city
        updateditem.state = getdropdownvalue({ formValues: formdata, field: "stateSelectedValue", prop: "name" })
        updateditem.country = getdropdownvalue({ formValues: formdata, field: "countrySelectedValue", prop: "name" })

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          payload: formdata,
          code: formdata.update_code,
          name: formdata.city,
        })
      } else if (formtype === "multi_customer_supplier_update") {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "multi_customer_supplier_update",
          lastaction: "updated",
          lasttime: new Date().getTime(),
          lastdata: formdata,
        })
      } else if (formtype === "envelopePrinting") {
        const updateditem = {}
        updateditem.name = formdata.name

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })
        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          payload: formdata,
          code: formdata.update_code,
        })
      } else if (formtype === "chequePrinting") {
        const updateditem = {}
        updateditem.name = formdata.name

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })
      } else if (formtype === "bot") {
        const updateditem = {}
        updateditem.name = formdata.name
        updateditem.description = formdata.description
        updateditem.type = getdropdownvalue({ formValues: formdata, field: "type", prop: "name" })

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })
      } else if (formtype === "process") {
        const updateditem = {}
        updateditem.name = formdata.name
        updateditem.srno = formdata.srno

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })
        // dispatch({
        //   type: PROCESS_SERVICE_UPDATED,
        //   payload: formdata,
        // })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          payload: formdata,
          code: formdata.update_code,
          name: formdata.name,
        })
      } else if (formtype === "costcenter") {
        const updateditem = {}
        updateditem.name = formdata.name
        updateditem.parentname = getdropdownvalue({ formValues: formdata, field: "parentcostcenterSelectedValue", prop: "name" })

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          payload: formdata,
          code: formdata.update_code,
          name: formdata.name,
        })
      } else if (formtype === "gstr2_partially_markas_matched") {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "gstr2_partially_markas_matched",
          lastaction: "update",
          lasttime: new Date().getTime(),
          lastdata: formdata.update_code,
        })

        dispatch({
          type: GSTR2_SERVICE_UPDATED,
          payload: formdata.update_code,
        })
      } else if (formtype === "get_partygstaddress") {
      } else if (formtype === "partyprice") {
        dispatch({
          type: PARTYPRICE_SERVICE_UPDATED,
          payload: formdata,
        })
      } else if (formtype === "partygroup") {
        const updateditem = {}
        updateditem.name = formdata.name

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })
      } else if (formtype === "subgroup") {
        const updateditem = {}
        updateditem.name = formdata.name
        updateditem.groupname = getdropdownvalue({ formValues: formdata, field: "groupSelectedValue", prop: "name" })

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          payload: formdata,
          code: formdata.update_code,
        })
      } else if (formtype === "closingstock") {
        dispatch({
          type: CLOSINGDATA_SERVICE_UPDATED,
          payload: formdata,
        })
      } else if (formtype === "country") {
        const updateditem = {}
        updateditem.name = formdata.name

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          name: formdata.name,
        })
      } else if (formtype === "taxscheme") {
        const updateditem = {}
        updateditem.name = formdata.name

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          name: formdata.name,
        })
      } else if (formtype === "customer_supplier") {
        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: user.data.list_row ? user.data.list_row[0] : {},
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: other_type === "supplier" ? "supplier" : "customer",
          code: formdata.update_code,
          name: formdata.customerSupplierName,
          payload: formdata,
          invoice_cus_sup: invoice_cus_sup,
        })

        if (otherDetail === "invoice_form") {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "customer_supplier",
            lastaction: "updated",
            lasttime: new Date().getTime(),
          })
        }
      } else if (formtype === "setting") {
        if (parseInt(customerListAuto) !== parseInt(getdropdownvalue({ formValues: formdata, field: "customerListAuto", prop: "id" }))) {
          var userset = {}
          userset.data = {}
          userset.data.customer = []
          userset.data.supplier = []
          userset.data.customer_supplier = []
          userset.data.allaccount = []

          dispatch({
            type: FORM_DEP_SERVICE_SUCCESS,
            payload: userset,
          })
        }

        if (parseInt(itemListAuto) !== parseInt(getdropdownvalue({ formValues: formdata, field: "itemListAuto", prop: "id" }))) {
          var useritem = {}
          useritem.data = {}
          useritem.data.item = []

          dispatch({
            type: FORM_DEP_SERVICE_SUCCESS,
            payload: useritem,
          })
        }

        var reload = false
        var generalSetting = !isblank(formdata.generalSetting) ? JSON.parse(formdata.generalSetting) : ""
        if (!isblank(generalSetting) && !isblank(generalSetting.language) && language !== parseInt(generalSetting.language)) {
          reload = true
        }
        var site_setting1 = user.data.result[0]
        setStorage(`site_setting_${getWindowYear()}`, site_setting1)
        dispatch({
          type: SITE_SETTING_UPDATED,
          payload: site_setting1,
        })

        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "site_setting",
          lastaction: "updated",
          lasttime: new Date().getTime(),
        })

        if (reload === true) {
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      } else if (formtype === "userrights" && formdata.update_code === getAccountSessionUserData("user_id")) {
        var menudata = {}
        if (!isblank(user.data.result.menu)) {
          menudata = user.data.result.menu
          var dd_orderTypes = []
          Object.keys(menudata).forEach(function (key) {
            if (key === "Invoice") {
              dd_orderTypes = menudata[key].filter((e) => e.type === "getmystore" || e.type === "getmycatalogue" || e.type === "sales_order")
              if (dd_orderTypes.length > 0) {
                menudata[key].push({ id: "9000", name: "Orders", hidden_menu: "0", main_type: "salesinvoices", icon: "", quick_links: "0", dd_orderTypes: dd_orderTypes, type: "orders" })
                user.data.result.user_rights["9000"] = "1|1|1"
              }
            }
          })
          user.data.result.menu = menudata
        }
        setStorage(`user_rights_${getWindowYear()}`, user.data.result.user_rights)
        setStorage(`site_setting_${getWindowYear()}`, user.data.result.site_setting[0])

        dispatch({
          type: USER_RIGHTS_UPDATED,
          payload: user.data.result.user_rights,
        })
        dispatch({
          type: SITE_SETTING_UPDATED,
          payload: user.data.result.site_setting[0],
        })
        dispatch({
          type: SETMENU,
          menu: user.data.result.menu,
          year: getWindowYear(),
        })
      } else if (formtype === "reportrights" && formdata.update_code === getAccountSessionUserData("user_id")) {
        dispatch({
          type: REPORT_DATA_UPDATED,
          payload: user.data.result.reports,
        })
        setStorage(`report_data_${getWindowYear()}`, user.data.result.reports)
      } else if (formtype === "companyrights" && formdata.update_code === getAccountSessionUserData("user_id")) {
        setStorage(`company_${getWindowYear()}`, user.data.result.company)
      } else if (formtype === "other_account") {
        if (getdropdownvalue({ formValues: formdata, field: "opbal_crdrSelectedValue", prop: "name" }) === "Dr") {
          formdata.openingBalance = -1 * formdata.openingBalance
        }
        const updateditem = {}
        updateditem.name = formdata.name
        updateditem.groupname = getdropdownvalue({ formValues: formdata, field: "GroupSelectedValue", prop: "name" })
        updateditem.typename = getdropdownvalue({ formValues: formdata, field: "TypeSelectedValue", prop: "name" })
        updateditem.opbal = formdata.openingBalance //isblank(formdata.openingBalance) ? "0.00 " + getdropdownvalue({ formValues: formdata, field: "opbal_crdrSelectedValue", prop: "name"}) : amount_format(formdata.openingBalance) + " " + getdropdownvalue({ formValues: formdata, field: "opbal_crdrSelectedValue", prop: "name"})

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })

        var useracc = {}
        useracc.data = {}
        useracc.data.bankaccount = []
        useracc.data.expenseaccount = []
        useracc.data.sales_account = []
        useracc.data.purchase_account = []
        useracc.data.dropdown_closingstock = []

        dispatch({
          type: FORM_DEP_SERVICE_SUCCESS,
          payload: useracc,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          name: formdata.name,
          payload: formdata,
        })
      } else if (formtype === "state") {
        const updateditem = {}
        updateditem.name = formdata.state
        updateditem.statecode = formdata.code
        updateditem.country_code = getdropdownvalue({ formValues: formdata, field: "countrySelectedValue", prop: "country_code" })

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          name: formdata.state,
          payload: formdata,
        })
      } else if (formtype === "opbal") {
        dispatch({
          type: OPBAL_SERVICE_UPDATED,
          payload: formdata,
        })
      } else if (formtype === "agent") {
        // if (getdropdownvalue({ formValues: formdata, field: "opbal_crdrSelectedValue", prop: "name"}) === "Dr") {
        //   formdata.a_openingBalance = -1 * formdata.a_openingBalance
        // }
        if (getdropdownvalue({ formValues: formdata, field: "opbal_crdrSelectedValue", prop: "id" }) === "2") {
          // id = "1", name = "Cr"
          // id = "2", name = "Dr"
          formdata.a_openingBalance = -1 * formdata.a_openingBalance
        }
        const updateditem = {}
        updateditem.name = formdata.a_name
        updateditem.agentcomm = formdata.a_commission
        updateditem.email = formdata.a_email
        updateditem.mobile = formdata.a_mobile
        updateditem.a_phone = formdata.a_phone
        updateditem.address = formdata.a_address
        updateditem.city = getdropdownvalue({ formValues: formdata, field: "citySelectedValue", prop: "name" })
        updateditem.state = getdropdownvalue({ formValues: formdata, field: "stateSelectedValue", prop: "name" })
        updateditem.opbal = formdata.a_openingBalance //isblank(formdata.a_openingBalance) ? "0.00 " + getdropdownvalue({ formValues: formdata, field: "opbal_crdrSelectedValue", prop: "name"}) : amount_format(formdata.a_openingBalance) + " " + getdropdownvalue({ formValues: formdata, field: "opbal_crdrSelectedValue", prop: "name"})

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          name: formdata.a_name,
          payload: formdata,
        })
      } else if (formtype === "transport") {
        const updateditem = {}
        updateditem.name = formdata.name
        updateditem.contactperson = formdata.person
        updateditem.mobile = formdata.mobile
        updateditem.gstin = formdata.gstin
        updateditem.city = getdropdownvalue({ formValues: formdata, field: "citySelectedValue", prop: "name" })
        updateditem.state = getdropdownvalue({ formValues: formdata, field: "stateSelectedValue", prop: "name" })
        updateditem.country = getdropdownvalue({ formValues: formdata, field: "countrySelectedValue", prop: "name" })

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          name: formdata.name,
          payload: formdata,
        })
      } else if (formtype === "godown") {
        const company_city = getdropdownvalue({ formValues: formdata, field: "citySelectedValue", prop: "name" })
        const company_state = getdropdownvalue({ formValues: formdata, field: "stateSelectedValue", prop: "name" })
        const company_country = getdropdownvalue({ formValues: formdata, field: "countrySelectedValue", prop: "name" })

        const updateditem = {}
        updateditem.name = formdata.name
        updateditem.status = formdata.defaultGodown === true ? "Primary" : ""
        updateditem.state = company_state
        updateditem.country = company_country
        updateditem.city = company_city
        updateditem.address = generateDisplayAddress({
          address: formdata.address,
          areacode: formdata.areaCode,
          city: company_city,
          state: company_state,
          country: company_country,
          pincode: formdata.zipCode,
        })

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          name: formdata.name,
          payload: formdata,
        })
      } else if (formtype === "unit") {
        dispatch({
          type: UNIT_SERVICE_UPDATED,
          payload: formdata,
        })
      } else if (formtype === "item") {
        if (!isblank(otherDetail) && otherDetail.hasOwnProperty("reduxform") && otherDetail.reduxform === "modalConfigureProduct") {
          const field = otherDetail.field
          const index = otherDetail.index
          dispatch(change(otherDetail.reduxform, `${field}[${index}].itemname`, formdata.itemname))
          dispatch(change(otherDetail.reduxform, `${field}[${index}].itemsku`, formdata.itemSKU))
          dispatch(change(otherDetail.reduxform, `${field}[${index}].itemsaleprice`, formdata.selling))
          dispatch(change(otherDetail.reduxform, `${field}[${index}].itempurchaseprice`, formdata.purchase))
          dispatch(change(otherDetail.reduxform, `${field}[${index}].itemopstock`, formdata.stock))
          dispatch(change(otherDetail.reduxform, `${field}[${index}].mrp`, formdata.mrp))
        }
        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          payload: formdata,
          list_row: user.data.list_row ? user.data.list_row : [],
        })
        if (!isblank(otherDetail) && !isblank(otherDetail.from) && otherDetail.from === "page") {
          setStorage(`last_visited_page_${getWindowYear()}`, "products")
          goToNavigate(`/products`)
        }
      } else if (formtype === "bundleitem") {
      } else if (formtype === "receipt" || formtype === "payment") {
        if (common_formtype !== "draft") {
          let updateditem = {}
          let updatearray = []
          if (formdata.receipt_type === "M") {
            const update_date = formdata.date
            const bankName = getdropdownvalue({ formValues: formdata, field: "bankSelectedValue", prop: "name" })
            const bankAcc_code = getdropdownvalue({ formValues: formdata, field: "bankSelectedValue", prop: "code" })
            formdata.DetailGrid.forEach((detailgrid, index) => {
              updateditem = {}
              updateditem.code = formdata.update_code
              updateditem.newCode = formdata.update_code + "_" + index
              updateditem.docno = user.data.docno
              updateditem.vdate = DatetoDMY(update_date)
              updateditem.name = bankName
              updateditem.acc_code = bankAcc_code
              updateditem.name1 = getdropdownvalue({ formValues: detailgrid, field: "accountSelectedValue", prop: "name" })
              updateditem.acc_code1 = getdropdownvalue({ formValues: detailgrid, field: "accountSelectedValue", prop: "code" })
              updateditem.amount = detailgrid.amount
              updateditem.chequeno = detailgrid.cheque
              updatearray.push(updateditem)
            })

            dispatch({
              type: COMMON_SERVICE_ARRAY_UPDATED,
              row: updatearray,
              payload: formdata,
            })
          } else {
            updateditem.code = formdata.update_code
            updateditem.newCode = formdata.update_code + "_0"
            updateditem.vdate = formdata.date
            updateditem.docno = user.data.docno
            updateditem.name = getdropdownvalue({ formValues: formdata, field: "bankSelectedValue", prop: "name" })
            updateditem.acc_code = getdropdownvalue({ formValues: formdata, field: "bankSelectedValue", prop: "code" })
            updateditem.name1 = getdropdownvalue({ formValues: formdata, field: "accountSelectedValue", prop: "name" })
            updateditem.acc_code1 = getdropdownvalue({ formValues: formdata, field: "accountSelectedValue", prop: "code" })
            updateditem.amount = formdata.amount
            updateditem.chequeno = formdata.cheque

            dispatch({
              type: COMMON_SERVICE_UPDATED,
              row: updateditem,
              payload: formdata,
            })
          }
        }
      } else if (formtype === "merge_packingSlip") {
        var updateditem = {}
        if (user.data.list_row.length > 0) {
          updateditem = user.data.list_row
        } else {
          updateditem.mdate = formdata.InvoiceDate
          updateditem.oldNumber = formdata.PackingSlipGrid.map((e) => e.billno)
          // eslint-disable-next-line
          updateditem.oldNumber = JSON.stringify(updateditem.oldNumber).replace(/[\[\]"]+/g, "")
          updateditem.newNumber = formdata.PackingDetailGrid.map((e) => e.billNo)
          updateditem.newNumber = getuniqueArray(updateditem.newNumber)
          // eslint-disable-next-line
          updateditem.newNumber = JSON.stringify(updateditem.newNumber).replace(/[\[\]"]+/g, "")
        }
        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })
      } else if (formtype === "record_expense") {
        if (common_formtype !== "draft") {
          let updateditem = {}
          if (user.data.list_row.length > 0) {
            updateditem = { ...dataSetInListPurchase(user.data.list_row)[0] }
          } else {
            updateditem.vdate = formdata.date
            updateditem.invoice_no = formdata.InvoiceNo
            updateditem.supplier_name = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "name" })
            updateditem.acc_code2 = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "code" })
            updateditem.name = getdropdownvalue({ formValues: formdata, field: "bankSelectedValue", prop: "name" })
            updateditem.acc_code = getdropdownvalue({ formValues: formdata, field: "bankSelectedValue", prop: "code" })
            updateditem.name1 = getdropdownvalue({ formValues: formdata, field: "accountSelectedValue", prop: "name" })
            updateditem.acc_code1 = getdropdownvalue({ formValues: formdata, field: "accountSelectedValue", prop: "code" })
            updateditem.amount = formdata.netamount
            updateditem.chequeno = formdata.cheque
          }

          dispatch({
            type: COMMON_SERVICE_UPDATED,
            row: updateditem,
            payload: formdata,
          })
        }
      } else if (formtype === "label_setting") {
        const updateditem = {}
        updateditem.code = formdata.update_code
        updateditem.name = formdata.name
        updateditem.papersize = formdata.pageSizeSelectedValue
        updateditem.metric = formdata.metricSelectedValue

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          name: formdata.name,
          payload: formdata,
        })
      } else if (formtype === "label_design") {
        const updateditem = {}

        updateditem.code = formdata.update_code
        updateditem.templatename = formdata.templateName
        updateditem.papersize = getdropdownvalue({ formValues: formdata, field: "paperSizeSelectedValue", prop: "name" })
        updateditem.type = "product"

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })
      } else if (formtype === "journal_entry") {
        if (common_formtype !== "draft") {
          let newarray = []
          let updateditem = {}
          const update_code = formdata.update_code
          const update_billno = user.data.docno
          const update_j_date = DatetoDMY(formdata.invoicedate)
          formdata.JournalEntryGrid.forEach((detailgrid, index) => {
            updateditem = {}
            updateditem.code = update_code
            updateditem.newCode = update_code + "_" + index
            // updateditem.billno = update_billno
            updateditem.billno_str = update_billno
            updateditem.j_date = update_j_date
            updateditem.name = getdropdownvalue({ formValues: detailgrid, field: "accountSelectedValue", prop: "name" })
            updateditem.partycode = getdropdownvalue({ formValues: detailgrid, field: "accountSelectedValue", prop: "code" })
            updateditem.debit = detailgrid.debit
            updateditem.credit = detailgrid.credit
            newarray.push(updateditem)
          })
          dispatch({
            type: COMMON_SERVICE_ARRAY_UPDATED,
            row: newarray,
            payload: formdata,
          })
        }
      } else if (formtype === "company") {
        const company_city = formdata.c_city
        const company_state = getdropdownvalue({ formValues: formdata, field: "stateSelectedValue", prop: "name" })
        const company_country = getdropdownvalue({ formValues: formdata, field: "countrySelectedValue", prop: "name" })
        var user_data = {}
        user_data = getStorage(`user_data_${getWindowYear()}`)
        user_data.company_alias = formdata.c_alias
        user_data.company_address1 = formdata.c_billingAddress
        user_data.company_street = formdata.c_street
        user_data.company_country = getdropdownvalue({ formValues: formdata, field: "countrySelectedValue", prop: "id" })
        user_data.company_address2 = `${company_city}, ${company_state}, ${formdata.a_zipCode}`
        user_data.company_alias = formdata.c_alias
        user_data.company_name = formdata.c_name
        user_data.email = formdata.c_email
        user_data.mobile = formdata.c_mobile
        user_data.statecode = getdropdownvalue({ formValues: formdata, field: "stateSelectedValue", prop: "code" })
        user_data.company_city = company_city
        user_data.company_pincode = formdata.a_zipCode
        user_data.company_banker1 = formdata.c_banker
        user_data.company_banker2 = formdata.c_banker1
        user_data.company_pan = formdata.c_pan
        user_data.company_website = formdata.website
        user_data.base64_logo = !isblank(image_meta) && !isblank(image_meta.base64_logo) ? image_meta.base64_logo : user_data.base64_logo
        user_data.logo = !isblank(image_meta) && !isblank(image_meta.photo) ? image_meta.photo : user_data.logo
        setStorage(`user_data_${getWindowYear()}`, user_data)

        const updateditem = {}
        updateditem.name = formdata.c_name
        updateditem.logo = image_meta && image_meta.photo ? image_meta.photo : formdata.photo
        updateditem.gstin = formdata.c_gstin
        updateditem.city = company_city
        updateditem.state = company_state
        updateditem.country = company_country
        updateditem.address = generateDisplayAddress({
          address: formdata.c_billingAddress,
          areacode: formdata.c_street,
          city: company_city,
          state: company_state,
          country: company_country,
          pincode: formdata.a_zipCode,
        })

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
          user_data: user_data,
          uniqueKey: "firm_id",
        })

        dispatch({
          type: UPDATE_USER_DATA,
          user_data: user_data,
        })
      } else if (invoice_type_detail.filter((e) => e.id === formtype).length > 0 || (formtype === "gstoldbill" && invoice_type_detail.filter((e) => e.id === other_type).length > 0)) {
      } else if (formtype === "salesreturn" || (formtype === "gstoldbill" && other_type === "salesreturn")) {
        if (common_formtype !== "draft") {
          var updateditem = {}
          if (user.data.list_row.length > 0) {
            updateditem = { ...dataSetInListSPReturn(user.data.list_row)[0] }
          } else {
            updateditem.creditnote_no = user.data.docno
            updateditem.billno = formdata.previousYearBill ? formdata.previuos_billno : getdropdownvalue({ formValues: formdata, field: "salesBillSelectedValue", prop: "name" })
            updateditem.bill_date = DatetoDMY(formdata.InvoiceDate)
            updateditem.name = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "name" })
            updateditem.partycode = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "code" })
            updateditem.city = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "city" })
            updateditem.state = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "state" })
            updateditem.netamt = formdata.tot_net_amount
            if (!isblank(formdata.gstr1_filed)) {
              updateditem.gstr1_filed = formdata.gstr1_filed === "1" ? "Filed" : "Not Filed"
            }
            if (!isblank(formdata.gstr3B_filed)) {
              updateditem.gstr3B_filed = formdata.gstr3B_filed === "1" ? "Filed" : "Not Filed"
            }
            updateditem.invoice_type = getdropdownvalue({ formValues: formdata, field: "noteTypeSelectedValue", prop: "name" })
          }
          dispatch({
            type: COMMON_SERVICE_UPDATED,
            row: updateditem,
            payload: formdata,
          })
        }
      } else if (formtype === "purchasereturn" || (formtype === "gstoldbill" && other_type === "purchasereturn")) {
        if (common_formtype !== "draft") {
          var updateditem = {}
          if (user.data.list_row.length > 0) {
            updateditem = { ...dataSetInListSPReturn(user.data.list_row)[0] }
          } else {
            updateditem.debitnote_no = user.data.docno
            updateditem.billno = formdata.previousYearBill ? formdata.previuos_billno : getdropdownvalue({ formValues: formdata, field: "salesBillSelectedValue", prop: "name" })
            updateditem.b_date = DatetoDMY(formdata.saleInvoiceDate)
            updateditem.name = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "name" })
            updateditem.acc_code = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "code" })
            updateditem.city = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "city" })
            updateditem.state = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "state" })
            updateditem.invoice_type = getdropdownvalue({ formValues: formdata, field: "noteTypeSelectedValue", prop: "name" })
            updateditem.netamt = formdata.tot_net_amount
            if (!isblank(formdata.gstr3B_filed)) {
              updateditem.gstr3B_filed = formdata.gstr3B_filed === "1" ? "Filed" : "Not Filed"
            }
          }
          dispatch({
            type: COMMON_SERVICE_UPDATED,
            row: updateditem,
            payload: formdata,
          })
        }
      } else if (purchase_type_detail.filter((e) => e.id === formtype).length > 0 || (formtype === "gstoldbill" && purchase_type_detail.filter((e) => e.id === other_type).length > 0)) {
        if (common_formtype !== "draft") {
          var updateditem = {}
          if (user.data.list_row.length > 0) {
            updateditem = { ...dataSetInListPurchase(user.data.list_row)[0] }
          } else {
            updateditem.billno = user.data.docno
            updateditem.bill_date = DatetoDMY(formdata.InvoiceDate)
            updateditem.name = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "name" })
            updateditem.partycode = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "code" })
            updateditem.city = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "city" })
            updateditem.state = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "state" })
            if (!isblank(formdata.gstr3B_filed)) {
              updateditem.gstr3B_filed = formdata.gstr3B_filed === "1" ? "Filed" : "Not Filed"
            }
            updateditem.netamt = formdata.tot_net_amount
            updateditem.status = convertIntif(user.data.order_status) === 0 ? "Pending" : convertIntif(user.data.order_status) === 1 ? "Partially" : "Closed"
            updateditem.is_overdue_status = user.data.is_overdue_status
            updateditem.adjust_status =
              parseInt(user.data.adjust_status) === 0 ? "Unpaid" : parseInt(user.data.adjust_status) === 1 ? "Partially Paid" : parseInt(user.data.adjust_status) === 2 ? "Paid" : "Unpaid"
            updateditem.due_date = DatetoDMY(formdata.dueDate)
            updateditem = getDueStatus(updateditem)
          }
          dispatch({
            type: COMMON_SERVICE_UPDATED,
            row: updateditem,
            payload: formdata,
          })
        }
      } else if (inward_type_detail.filter((e) => e.id === formtype).length > 0) {
        if (common_formtype !== "draft") {
          var updateditem = {}
          if (user.data.list_row.length > 0) {
            updateditem = { ...dataSetInListIO(user.data.list_row)[0] }
          } else {
            if (user.data.docno) {
              updateditem.billno = user.data.docno
            }
            updateditem.b_date = DatetoDMY(formdata.InvoiceDate)
            updateditem.name = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "name" })
            updateditem.acc_code = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "code" })
            updateditem.ps_status = getdropdownfieldvalue({ dropDownData: getallPackingStatus(), field: "id", value: formdata.psStatus, displayvalue: "name" })
            updateditem.narration = formdata.narration
            updateditem.flag = "Lock"
            updateditem.totqty = formdata.tot_qty
          }
          dispatch({
            type: COMMON_SERVICE_UPDATED,
            row: updateditem,
            payload: formdata,
          })
        }
      } else if (formtype === "lot_transfer") {
        const updateditem = {}
        updateditem.code = formdata.update_code
        updateditem.billno = formdata.transfer_billNo
        updateditem.b_date = DatetoDMY(formdata.InvoiceDate)
        updateditem.name = getdropdownvalue({ formValues: formdata, field: "toProcessSelectedValue", prop: "name" })
        updateditem.processCode = getdropdownvalue({ formValues: formdata, field: "toProcessSelectedValue", prop: "code" })
        updateditem.partyname = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "name" })
        updateditem.totQty = formdata.tot_qty
        updateditem.flag = "Lock"

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })
      } else if (outward_type_detail.filter((e) => e.id === formtype).length > 0) {
        if (common_formtype !== "draft") {
          var updateditem = {}
          if (user.data.list_row.length > 0) {
            updateditem = { ...dataSetInListIO(user.data.list_row)[0] }
          } else {
            if (user.data.docno) {
              updateditem.billno = user.data.docno
            }
            updateditem.code = formdata.update_code
            updateditem.b_date = DatetoDMY(formdata.InvoiceDate)
            updateditem.name = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "name" })
            updateditem.acc_code = getdropdownvalue({ formValues: formdata, field: "customerSelectedValue", prop: "code" })
            updateditem.ps_status = getdropdownfieldvalue({ dropDownData: getallPackingStatus(), field: "id", value: formdata.psStatus, displayvalue: "name" })
            updateditem.narration = formdata.narration
            updateditem.flag = "Lock"
            updateditem.totqty = formdata.tot_qty
          }
          dispatch({
            type: COMMON_SERVICE_UPDATED,
            row: updateditem,
            payload: formdata,
          })
        }
      } else if (formtype === "stock_transferGodown" || formtype === "stockTransfer") {
        var updateditem = {}
        if (user.data.list_row.length > 0) {
          updateditem = user.data.list_row[0]
        } else {
          updateditem.billno = formdata.update_code
          updateditem.b_date = DatetoDMY(formdata.InvoiceDate)
          updateditem.name = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "name" })
          updateditem.acc_code = getdropdownvalue({ formValues: formdata, field: "supplierSelectedValue", prop: "code" })
        }
        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })
      } else if (formtype === "wiretransfer") {
        dispatch({
          type: WIRETRANSFER_SERVICE_UPDATED,
          payload: formdata,
        })
      } else if (formtype === "wiresender") {
        dispatch({
          type: WIRETRANSFER_SENDER_SERVICE_UPDATED,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          payload: formdata,
        })
      } else if (formtype === "beneficiary") {
        dispatch({
          type: WIRETRANSFER_BENEFICIARY_SERVICE_UPDATED,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          payload: formdata,
        })
      } else if (formtype === "useripostock") {
        dispatch({
          type: IPOSTOCK_SERVICE_UPDATED,
          payload: formdata,
        })
      } else if (formtype === "ipostockparty") {
        dispatch({
          type: IPOSTOCKPARTY_SERVICE_UPDATED,
          payload: formdata,
        })

        dispatch({
          type: DROPDOWN_ITEM_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          payload: formdata,
        })
      } else if (formtype === "ipostockmaster") {
        dispatch({
          type: IPOSTOCKMASTER_SERVICE_UPDATED,
          payload: formdata,
        })
      } else if (formtype === "filegstr1" || formtype === "filegstr3") {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "gstr1",
          lastaction: "filed",
          lasttime: new Date().getTime(),
          lastdata: user.data.result,
        })
      } else if (formtype === "gstr1_reconcile") {
        dispatch({
          type: UPDATE_REPORT_DATA,
          payload: formdata.DetailGrid,
        })
      } else if (formtype === "customField") {
        if (other_type === "value") {
          dispatch({
            type: CUSTOMFIELD_OPTION_UPDATED,
            formtype: formtype,
            code: formdata.update_code,
            payload: formdata,
          })
        } else {
          if (convertIntif(formdata.decimalPointSelectedValue) === 7) {
            formdata.decimalPointSelectedValue = inventory_decimalpoint
          }

          dispatch({
            type: DROPDOWN_ITEM_UPDATED,
            formtype: formtype,
            code: formdata.update_code,
            payload: formdata,
          })

          dispatch({
            type: CUSTOM_FIELD_SERVICE_UPDATED,
            payload: formdata,
          })
        }
      } else if (formtype === "customPage") {
        customPages = []
        if (getStorage(`customPages_${getWindowYear()}`, "")) {
          customPages = getStorage(`customPages_${getWindowYear()}`, "")
        }
        customPages = customPages.map((item, index) => {
          if (item.code.toString() !== formdata.update_code.toString()) {
            return item
          }
          const updateditem = { ...item }
          updateditem.name = formdata.name
          updateditem.singleEntry = formdata.singleEntry
          updateditem.draggableList = formdata.draggableList
          return updateditem
        })
        setStorage(`customPages_${getWindowYear()}`, customPages)
        dispatch({
          type: CUSTOM_PAGE_ITEM_UPDATED,
          customPages: customPages,
        })

        const updateditem = {}
        updateditem.name = formdata.name

        dispatch({
          type: COMMON_SERVICE_UPDATED,
          row: updateditem,
          payload: formdata,
        })
      } else if (formtype === "sms_template") {
        dispatch({
          type: SMS_TEMPLATE_SERVICE_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          payload: formdata,
        })
      } else if (formtype === "whatsapp_template") {
        dispatch({
          type: WHATSAPP_TEMPLATE_SERVICE_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          payload: formdata,
        })
      } else if (formtype === "email_template") {
        dispatch({
          type: EMAIL_TEMPLATE_SERVICE_UPDATED,
          formtype: formtype,
          code: formdata.update_code,
          payload: formdata,
        })
      } else if (formtype === "ewaybill_status") {
        dispatch({
          type: EWAY_BILL_SERVICE_UPDATED,
          payload: formdata.update_code,
        })
      }

      if (formdata.sendSMS || formdata.sendEmail || formdata.sendWhatsApp || formdata.sendAdmin) {
        lastaction = "saveAndNotification"
        if (saveandprint === true) {
          lastaction = "saveAndNotificationAndPrint"
        }
        setTimeout(() => {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: formtype,
            lastaction: lastaction,
            lasttime: new Date().getTime(),
            lastdata: {
              formtype: formtype,
              sendSMS: formdata.sendSMS,
              sendEmail: formdata.sendEmail,
              sendWhatsApp: formdata.sendWhatsApp,
              sendAdmin: formdata.sendAdmin,
              code: formdata.update_code,
            },
          })
        }, 0)
      } else if (saveandprint === true) {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: formtype,
          lastaction: "saveandprint",
          lasttime: new Date().getTime(),
          lastdata: formdata.update_code,
        })
      }

      setTimeout(() => {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "post_form_data",
          lastaction: "update",
          lasttime: new Date().getTime(),
        })
      }, 0)
    } else if (formaction === "autofill") {
      if (user.data.form_data.DetailGrid.length === 0) {
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: "Data Not Found",
          snackbarType: "error",
        })

        dispatch({
          type: LOADING_STATE,
          state: false,
          sidebarstate: false,
        })
      } else {
        if (itemListAuto === 0) {
          user.data.form_data.itemcodeSelectedValue.map(function (find) {
            return dispatch({
              type: DROPDOWN_INSERTED,
              formtype: "item",
              code: find.code,
              name: find.name,
              payload: find,
            })
          })
        }
        user.data.form_data.DetailGrid = setFormatOfInvoiceDetailGrid({ DetailGrid: user.data.form_data.DetailGrid })

        if (otherDetail === "goldSaleReturn") {
          if (formtype === "salesreturn") {
            dispatch(change("goldSaleReturnForm", "gold_salesReturn_DetailGrid", user.data.form_data.DetailGrid))
          }
        } else {
          var DetailGrid = user.data.form_data.DetailGrid
          if (formtype === "salesreturn" || formtype === "purchasereturn") {
            DetailGrid = user.data.form_data.DetailGrid.map((item, index) => {
              const updatedItem = { ...item }
              if (tcs === 1) {
                if (!isblank(item.tcs_detail) && typeof item.tcs_detail === "string") {
                  updatedItem.tcs_detail = JSON.parse(item.tcs_detail)
                  updatedItem.tcs = updatedItem.tcs_detail.percent
                  updatedItem.tcs_code = updatedItem.tcs_detail.tcs_code
                }
              }
              return updatedItem
            })
          }
          if (formtype === "salesreturn") {
            dispatch(change("SalesRetForm", "DetailGrid", DetailGrid))
          } else if (formtype === "purchasereturn") {
            dispatch(change("ManagePurchaseReturn", "DetailGrid", DetailGrid))
          } else if (purchase_type_detail.filter((e) => e.id === formtype).length > 0) {
            dispatch(change("ManagePurchaseInvoice", "DetailGrid", user.data.form_data.DetailGrid))
          } else if (formtype === "packingslip_grid") {
            removeDrawer(dispatch)
            dispatch(change("SIForm", "DetailGrid", user.data.form_data.DetailGrid))

            dispatch({
              type: LAST_PAGE_UPDATE,
              lasttype: formtype,
              lastaction: "autofill",
              lasttime: new Date().getTime(),
            })
          } else if (formtype === "purchaseinvoice_grid") {
            removeDrawer(dispatch)
            dispatch(change("LotForm", "usePurchaseInvoiceCount", formdata.invoice_codes.length))
            dispatch(change("LotForm", "DetailGrid", user.data.form_data.DetailGrid))
          } else if (formtype === "applyLotAndProcess") {
            removeDrawer(dispatch)
            //dispatch(change('LotForm', 'usePurchaseInvoiceCount', formdata.invoice_codes.length));
            dispatch(change("ProcessTransferForm", "DetailGrid", user.data.form_data.DetailGrid))
          }
        }
      }
    }
    var pageurl = window.location.pathname.split("/")
    if (pageurl.length > 1) {
      if (pageurl[pageurl.length - 1] === "dashboard" && pageurl[pageurl.length - 2] === "process") {
        if (
          formaction !== "edit" &&
          formaction !== "autofill" &&
          (formtype === "job_process_invoice" ||
            formtype === "finsish_stock" ||
            formtype === "manage_lot_master" ||
            formtype === "packing_slip" ||
            formtype === "job_inward" ||
            (formtype === "lot_transfer" && isblank(user.data.print_data)))
        ) {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "kanban",
            lastaction: "update",
            lasttime: new Date().getTime(),
          })
        }
        //eslint-disable-next-line
      } else if (
        (replaceAllFn(pageurl[pageurl.length - 1], "-", "_") === "packing_slip" && pageurl[pageurl.length - 2] === "inwards") ||
        (replaceAllFn(pageurl[pageurl.length - 1], "-", "_") === "finish_stock" && pageurl[pageurl.length - 2] === "process")
      ) {
        if (formaction !== "edit" && formaction !== "autofill" && formtype === "lot_transfer") {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "lot_transfer",
            lastaction: "insert_update",
            lasttime: new Date().getTime(),
          })
        }
      }
    }
    if (formtype === "gstoldbill" && (formaction === "insert" || formaction === "delete" || formaction === "update")) {
      dispatch({
        type: LAST_PAGE_UPDATE,
        lasttype: "gstoldbill",
        lastaction: "success",
        lasttime: new Date().getTime(),
        lastdata: other_type,
      })
    }

    if ((formtype !== "receipt" && formtype !== "payment") || formaction !== "delete" || isblank(otherDetail) || (otherDetail.from !== "sales_invoice" && otherDetail.from !== "purchase_invoice")) {
      if (
        formtype !== "multi_customer_supplier_update" &&
        (((formtype === "stockTransfer" || formtype === "lot_transfer" || inward_type_detail.filter((e) => e.id === formtype).length > 0) && !print_barcode) ||
          (formtype !== "stockTransfer" && formtype !== "lot_transfer" && inward_type_detail.filter((e) => e.id === formtype).length === 0))
      ) {
        if (formaction === "insert" || formaction === "update") {
          if (formaction === "update") {
            update_RefreshDrawerStack(1, dispatch)
          }
          if (!saveandaddanother) {
            removeDrawer(dispatch)
          }
        } else if (formaction === "delete") {
          update_RefreshDrawerStack(3, dispatch)
          removeDrawer(dispatch)
        }
      }
    }
  }
  if (formtype === "company" && !isblank(formdata.uploadImageArray) && formdata.uploadImageArray.length > 0 && (formaction === "insert" || formaction === "update")) {
    var photoResponses = []
    const onUploadComplete = (e, index, response, field) => {
      if (!isblank(response) && !isblank(field)) {
        photoResponses.push({ response, field })
      }
      if (index === formdata.uploadImageArray.length - 1) {
        const onPhotoSuccess = (newresponse, newfield) => {
          if (formtype === "company") {
            if (newfield === "photo") {
              onSuccess(newresponse)
            } else {
              const filteredRes = photoResponses.filter((i) => i.field === "photo")
              if (filteredRes.length > 0) {
                onSuccess(filteredRes[0].response)
              } else {
                onSuccess()
              }
            }
          } else {
            onSuccess()
          }
        }
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: `${e}_upload`,
          lastaction: "start",
          lasttime: new Date().getTime(),
          lastdata: {
            insert_code: formaction === "insert" ? user.data.insert_code : formdata.update_code,
            formdata,
            onSuccess: onPhotoSuccess,
          },
        })
      } else {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: `${e}_upload`,
          lastaction: "start",
          lasttime: new Date().getTime(),
          lastdata: {
            insert_code: formaction === "insert" ? user.data.insert_code : formdata.update_code,
            formdata,
            onSuccess: (Response, Field) => onUploadComplete(formdata.uploadImageArray[index + 1], index + 1, Response, Field),
          },
        })
      }
    }
    onUploadComplete(formdata.uploadImageArray[0], 0)
  } else {
    onSuccess()
  }
}

const postServiceFail = ({ dispatch, response, formdata, saveandaddanother, formtype }) => {
  const invoice_type_detail = getAccountSitesetting("invoice_type_detail")
  const purchase_type_detail = getAccountSitesetting("purchase_type_detail")
  const inward_type_detail = getAccountSitesetting("inward_type_detail")
  const outward_type_detail = getAccountSitesetting("outward_type_detail")
  if (
    invoice_type_detail.filter((e) => e.id === formtype).length > 0 ||
    formtype === "customPageFormSrno" ||
    formtype === "receipt" ||
    formtype === "recordexpense" ||
    formtype === "payment" ||
    formtype === "journal_entry" ||
    formtype === "salesreturn" ||
    formtype === "purchasereturn" ||
    purchase_type_detail.filter((e) => e.id === formtype).length > 0 ||
    inward_type_detail.filter((e) => e.id === formtype).length > 0 ||
    formtype === "lot_transfer" ||
    formtype === "stockTransfer" ||
    formtype === "stock_transferGodown" ||
    outward_type_detail.filter((e) => e.id === formtype).length > 0
  ) {
    dispatch({
      type: PAGE_FORM_POST_SERVICE_FAIL,
      payload: response,
      formdata: formdata,
    })
  } else {
    dispatch({
      type: FORM_POST_SERVICE_FAIL,
      payload: response,
      formdata: formdata,
    })
  }
}

export const modalpartyDetail = ({ code, type, other_type, invoice_type, drawerIndex, otherDetail, onComplete = false, onError, offset = 0, page, sub_tab_type }) => {
  return (dispatch) => {
    if (isblank(code)) {
      dispatch({
        type: SNACKBAR_STATE,
        payload: true,
        message: "Please select account",
        snackbarType: "error",
      })
    } else {
      if (typeof getAxiosCancelToken() != typeof undefined) {
        getAxiosCancelToken().cancel("Operation canceled due to new request.")
      }
      // save the new request for cancellation
      setAxiosCancelToken(axios.CancelToken.source())
      if (other_type === "history") {
        dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
      } else if (!isblank(otherDetail) && otherDetail.state) {
        dispatch({ type: LOADING_STATE, state: false, sidebarstate: true })
      } else {
        dispatch({ type: LOADING_STATE, state: true })
      }
      let data = new FormData()
      data.append("type", type)
      data.append("code", code)
      data.append("flag", 1)
      data.append("from_date", `${getstartyear()}-04-01`)
      data.append("to_date", `${getendyear()}-03-31`)
      data.append("other_type", other_type)
      if (other_type === "history") {
        data.append("offset", offset)
        data.append("limit", 20)
        data.append("sort_order", "desc")
        data.append("sort_by", "id")
      } else if (other_type === "invoice") {
        data.append("invoice_type", invoice_type)
      }
      if (sub_tab_type) {
        data.append("sub_tab_type", sub_tab_type)
      }
      axios.defaults.headers.common = getheaders()
      axios({
        method: "post",
        url: list_url({
          type: "modal_party_detail",
          props: { other_type: type },
        }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
        cancelToken: getAxiosCancelToken().token,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          if (response.data.meta.code === 401) {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: response.data.meta.message,
              snackbarType: "error",
            })
            if (!isblank(otherDetail) && otherDetail.state) {
              dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
            } else {
              dispatch({ type: LOADING_STATE, state: false })
            }
            if (onError) {
              onError()
            }
          } else {
            if (type === "transport") {
              pushDrawer({ title: "Preview Transport", data: response.data.result[0], position: "right", width: 450, dispatch })
            } else if (type === "other_account") {
              pushDrawer({ title: "Preview OtherAccount", data: response.data.result, position: "right", width: 450, dispatch })
            } else {
              var maxWidth = getWindowWidth()
              if (!isblank(drawerIndex) && onComplete === false) {
                updateDrawer({ title: "Preview Customer", data: response.data.result, dispatch, index: drawerIndex })
              } else if (onComplete) {
                onComplete(response.data.result, page)
              } else {
                pushDrawer({ title: "Preview Customer", data: response.data.result, fieldname: type, width: (maxWidth <= 1024 ? "bottom" : "right", maxWidth <= 1024 ? maxWidth : 1024), dispatch })
              }
            }
            if (!isblank(otherDetail) && otherDetail.state) {
              dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
            } else {
              dispatch({ type: LOADING_STATE, state: false })
            }
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            dispatch({ type: LOADING_STATE, state: false })
            if (onError) {
              onError()
            }
          } else {
            postErrorOnException(error)
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: getCatchErrorMessage(error),
              snackbarType: "error",
            })
            dispatch({ type: LOADING_STATE, state: false })
            if (onError) {
              onError()
            }
          }
        })
    }
  }
}

export const customer_supplier_history = ({ code, onComplete, rowsPerPage, page, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("offset", (page - 1) * rowsPerPage)
    data.append("type", "party_user_history")
    data.append("limit", rowsPerPage)
    data.append("sort_order", "")
    data.append("sort_order", "")
    data.append("search_text", "")
    data.append("from_date", `${getstartyear()}-04-01`)
    data.append("to_date", `${getendyear()}-03-31`)
    data.append("party_code", code)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "party_history" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          onComplete(response.data.result, response.data.total_count, page)
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const getsmsDetail = ({ code, type, other_type, functions }) => {
  const purchase_type_detail = getAccountSitesetting("purchase_type_detail")
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", type)
    data.append("code", code)
    data.append("other_type", other_type)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "sms_detail" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))

      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: FORM_POST_SERVICE_SUCCESS,
            payload: response,
            formtype: type,
            purchase_type_detail: purchase_type_detail,
          })

          dispatch({
            type: CONFIRM_DIALOG,
            payload: true,
            confirm: false,
            message: "",
            title: "SEND SMS",
            form: "Send SMS",
            meta: "",
          })

          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const ewaybillAction = ({ formdata, title, type, other_type, print_flag, printAction, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    dispatch({
      type: LOADING_STATE,
      state: true,
    })
    const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
    let data = new FormData()
    data.append("form_data", formdata2)
    data.append("type", type)
    data.append("other_type", other_type)
    if (!isblank(print_flag)) {
      data.append("print_flag", print_flag)
    }
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "ewb_action" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 403) {
          dispatch({
            type: CONFIRM_DIALOG,
            payload: true,
            confirm: false,
            message: "",
            title: "Message",
            form: "Error List",
            meta: response.data.meta.message,
          })
        } else if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          if (type === "ewaybill_cancel" || type === "ewaybill_cancel_fb" || type === "ewaybill_manual" || type === "ewaybill_print") {
            dispatch({
              type: CONFIRM_DIALOG,
              payload: false,
              confirm: false,
              message: "",
              title: "",
              form: "",
              meta: "",
            })
          }

          if (type === "ewaybill_cancel" || type === "ewaybill_cancel_fb" || type === "ewaybill_manual") {
            var formtype = ""
            if (type === "ewaybill_cancel" || type === "ewaybill_cancel_fb") {
              formtype = "cancel_irn_ewaybill"
            } else if (type === "ewaybill_manual") {
              formtype = "generate_irn_ewaybill"
            }
            dispatch({
              type: E_WAY_BILL_UPDATE,
              formtype: formtype,
              otherType: type,
              invoiceCode: formdata.code,
            })

            if (formdata.otherDetail === "0" || formdata.otherDetail === "2" || formdata.otherDetail === "3") {
              if (!isblank(formdata.ewbno)) {
                dispatch({
                  type: EWAY_BILL_SERVICE_UPDATED,
                  payload: formdata.code,
                })
              }
            } else if (formdata.otherDetail === "1") {
              if (isblank(formdata.ewbno)) {
                dispatch({
                  type: EWAY_BILL_SERVICE_UPDATED,
                  payload: formdata.code,
                })
              }
            } else if (type === "ewaybill_cancel" || type === "ewaybill_cancel_fb") {
              dispatch({
                type: EWAY_BILL_SERVICE_UPDATED,
                payload: formdata.code,
              })
            }

            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: response.data.meta.message,
              snackbarType: "success",
            })
          } else {
            var metadata = {}
            var maxWidth = getWindowWidth()
            metadata.filename = getAccountSessionUserData("company_url") + "_" + response.data.filename.replace(/\s/gi, "_") + "_" + getAccountSessionUserData("financial_year")
            metadata.title = title

            if (printAction) {
              metadata.customButtons = [
                {
                  label: "Print Invoice",
                  icon: <Print fontSize="small" />,
                  onClick: printAction,
                },
              ]
            }

            setTimeout(() => {
              pushDrawer({ title: "PDFViewer", data: response.data.result, fieldname: metadata, position: maxWidth <= 1024 ? "bottom" : "right", width: maxWidth <= 1024 ? maxWidth : 1024, dispatch })
            }, 300)
          }
        }

        dispatch({
          type: LOADING_STATE,
          state: false,
        })
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const einvoiceAction = ({ formdata, title, type, other_type, print_flag, printAction, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    dispatch({
      type: LOADING_STATE,
      state: true,
    })
    const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
    let data = new FormData()
    data.append("form_data", formdata2)
    data.append("type", type)
    data.append("other_type", other_type)
    if (!isblank(print_flag)) {
      data.append("print_flag", print_flag)
    }
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "ein_action" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 403) {
          dispatch({
            type: CONFIRM_DIALOG,
            payload: true,
            confirm: false,
            message: "",
            title: "Message",
            form: "Error List",
            meta: response.data.meta.message,
          })
        } else if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          if (type === "einvoice_cancel_fb") {
            dispatch({
              type: CONFIRM_DIALOG,
              payload: false,
              confirm: false,
              message: "",
              title: "",
              form: "",
              meta: "",
            })
          }
          if (other_type === "sales_invoice" || other_type === "salesreturn" || other_type === "purchasereturn") {
            dispatch({
              type: E_INVOICE_UPDATE,
              formtype: type,
              payload: formdata,
            })
          }
        }

        dispatch({
          type: LOADING_STATE,
          state: false,
        })
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const modalInvoicePrint = ({ row, code, type, title, filename, action, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("type", type)
    data.append("invoice_id", code)
    data.append("flag", 1)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "invoice_print" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          var metadata = {}
          var maxWidth = getWindowWidth()
          metadata.filename = getAccountSessionUserData("company_url") + "_" + filename.replace(/\s/gi, "_") + "_" + getAccountSessionUserData("financial_year")
          metadata.title = title
          metadata.row = row

          if (action === "print") {
            print_file(response.data.result, metadata.filename)
          } else if (action === "download") {
            exportAs({ data: response.data.result, name: metadata.filename, type: "pdf" })
          } else {
            setTimeout(() => {
              pushDrawer({ title: "PDFViewer", data: response.data.result, fieldname: metadata, position: maxWidth <= 1024 ? "bottom" : "right", width: maxWidth <= 1024 ? maxWidth : 1024, dispatch })
            }, 300)
          }
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const downloadJSON = ({ formdata, code, type, from, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    dispatch({
      type: LOADING_STATE,
      state: true,
    })

    const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
    let data = new FormData()
    data.append("type", "generate_ewaybill")
    data.append("code", code)
    data.append("form_data", formdata2)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "download_json" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        dispatch({
          type: LOADING_STATE,
          state: false,
        })
        if (response.data.meta.code === 403) {
          if (from === "rightDrawer") {
            dispatch({
              type: CONFIRM_DIALOG,
              payload: true,
              confirm: false,
              message: "",
              title: "Message",
              form: "Error List",
              meta: response.data.meta.message,
            })
          } else {
            dispatch({
              type: CONFIRM_DIALOG,
              payload: true,
              confirm: false,
              message: "",
              title: "Message",
              form: "Error List",
              meta: response.data.meta.message,
            })
          }
        } else if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          exportAs({ data: response.data.result, name: response.data.filename, type: "json" })

          if (type === "generate_ewaybill") {
            if (from === "rightDrawer") {
              dispatch({
                type: CONFIRM_DIALOG,
                payload: false,
              })
            } else {
              dispatch({
                type: CONFIRM_DIALOG,
                payload: false,
              })
            }
          } else {
            removeDrawer()
          }
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

// export const modalOrderDetail = ({ code, otherType, title, filename, otherDetail, printType }) => {
//   return (dispatch) => {
//     const functions = {
//       getWindowWidth,
//       dispatch,
//       dd_country_state: getDdCountryState(),
//       getconfigsettingdata,
//     }
//     common_modalOrderDetail({ code, otherType, title, filename, otherDetail, printType, functions })
//   }
// }

export const fetch_catalogueUser_detail = ({ code, functions, listdata }) => {
  const { accountType } = functions
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("code", code)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "catalogueUserDetail" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          response.data.result = { ...response.data.result, ...listdata }
          if (!isblank(response.data.result.status)) {
            if (response.data.result.status === "10") {
              response.data.result.status = "Waiting"
            } else {
              response.data.result.status = getdropdownfieldvalue({ dropDownData: getAllStatus(), field: "id", value: response.data.result.status, displayvalue: "name" })
            }
          }
          pushDrawer({ title: "Catalogue User Detail", data: response.data.result, fieldname: accountType, position: "right", width: 1024, dispatch })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const modalInvoiceDetail = ({
  code,
  type,
  otherType,
  title = "",
  filenameOld = "",
  otherDetail,
  printType,
  before_amend_invoice_flag = "",
  onComplete,
  onError,
  onCompleteBase64,
  tab_flag = 1,
  tab_type,
  status,
  page = "",
  from_date,
  to_date,
  offset = 0,
  limit,
  sort_order,
  sort_by,
}) => {
  const invoice_type_detail = getAccountSitesetting("invoice_type_detail")
  const purchase_type_detail = getAccountSitesetting("purchase_type_detail")
  const inward_type_detail = getAccountSitesetting("inward_type_detail")
  const outward_type_detail = getAccountSitesetting("outward_type_detail")
  const salesreturn_type_detail = getAccountSitesetting("salesreturn_type_detail")
  const purchasereturn_type_detail = getAccountSitesetting("purchasereturn_type_detail")
  const print_tds_details = common_getTypeWiseKeySetting({ key: "print_tds_details", type: "invoice_type_detail", subtype: otherType, getAccountSitesetting })
  return (dispatch) => {
    if (!isblank(tab_type) && tab_type === "history") {
    } else if (otherDetail && (otherDetail.actionType === "saveandprint" || otherDetail.actionType === "docPrint")) {
      dispatch({
        type: LOADING_SNACKBAR_STATE,
        payload: true,
        message: "Generating document, Please wait...",
      })
    } else {
      dispatch({
        type: LOADING_STATE,
        state: otherDetail && !isblank(otherDetail.loading) ? otherDetail.loading : true,
        sidebarstate: otherDetail && !isblank(otherDetail.sidebarloading) ? otherDetail.sidebarloading : false,
      })
    }

    let data = new FormData()

    if (otherDetail && otherDetail.flag === "print") {
      data.append("flag", "print")
    }
    if (type) {
      data.append("type", type)
    } else {
      data.append("type", "invoice_detaildata")
    }
    data.append("code", code)
    data.append("other_type", otherType)
    if (before_amend_invoice_flag) {
      data.append("before_amend_invoice_flag", before_amend_invoice_flag)
    }
    if (tab_flag) {
      data.append("tab_flag", tab_flag)
    }
    if (tab_type) {
      data.append("tab_type", tab_type)
    }
    if (!isblank(tab_type) && tab_type === "history") {
      data.append("from_date", from_date)
      data.append("to_date", to_date)
      data.append("offset", offset)
      data.append("limit", limit)
      data.append("sort_order", sort_order)
      data.append("sort_by", sort_by)
    }

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "invoice_detail" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then(async (response) => {
        var templateSetting = ""
        if (otherDetail && (otherDetail.actionType === "saveandprint" || otherDetail.actionType === "docPrint")) {
          dispatch({
            type: LOADING_SNACKBAR_STATE,
            payload: false,
            message: "Generating Document Print, Please wait...",
          })
        }
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
          if (onError) {
            onError()
          }
        } else {
          if (!isblank(response.data.result.master_table_data)) {
            if (!isblank(response.data.result.master_table_data[0].inward_type) || !isblank(response.data.result.master_table_data[0].outward_type)) {
              if (!isblank(response.data.result.master_table_data[0].inward_type)) {
                response.data.result.master_table_data[0].invoice_type = response.data.result.master_table_data[0].inward_type
              }
              if (!isblank(response?.data?.result?.master_table_data[0].outward_type)) {
                response.data.result.master_table_data[0].invoice_type = response.data.result.master_table_data[0].outward_type
              }
            }
          }

          if (otherDetail && otherDetail.from === "fromewayBill") {
            let ewaybill_detail = response.data.result.master_table_data[0].ewaybill_detail

            var shipp_address = response.data.result.master_table_data[0].shipp_address
            if (!isblank(shipp_address)) {
              shipp_address = JSON.parse(shipp_address)
            }

            var party_detail = response.data.result.master_table_data[0].party_detail
            if (!isblank(party_detail)) {
              party_detail = JSON.parse(party_detail)
            }

            if (!isblank(party_detail?.pincode) && !isblank(shipp_address?.pincode)) {
              if (isblank(shipp_address?.shipping_name)) {
                ewaybill_detail.shipToTradeName = party_detail?.name
              }

              if (party_detail.pincode !== shipp_address?.pincode) {
                ewaybill_detail.transactionType = "2"
              } else {
                ewaybill_detail.transactionType = "1"
              }
            }

            const ewaybillStatus = response.data.result.master_table_data[0].ewaybill_status
            const einvoiceStatus = response.data.result.master_table_data[0].e_invoiceStatus

            const payload = {
              data: {
                form_data: {
                  ...ewaybill_detail,
                  other_type: otherType,
                  transportmodeSelectedValue: ewaybill_detail.transportmode,
                  ewaybilldate: "",
                  ewaybillno: "",
                  partyPincode: shipp_address
                    ? shipp_address.pincode
                    : !isblank(response.data.result.user_details[0].shipp_pincode)
                      ? response.data.result.user_details[0].shipp_pincode
                      : !isblank(response.data.result.user_details[0].pincode)
                        ? response.data.result.user_details[0].pincode
                        : "",
                  transportSelectedValue: !isBlankCode(ewaybill_detail.transportcode) ? ewaybill_detail.transportcode : response.data.result.master_table_data[0].transportcode,
                  update_code: response.data.result.master_table_data[0].code,
                  invoiceDetail: response.data.result,
                  unableEwaybill: ewaybillStatus === "0" && einvoiceStatus === "0" ? true : false,
                },
              },
            }
            payload.data.form_data.ewaybilldate = payload.data.form_data.ewaybill_date
            payload.data.form_data.ewaybillno = payload.data.form_data.ewaybill_no

            dispatch({
              type: FORM_POST_SERVICE_SUCCESS,
              payload: payload,
              formtype: "ewaybill",
              formaction: "edit",
              saveandaddanother: false,
              purchase_type_detail: [],
            })

            var additionalAddress = []
            if (response.data.result.user_details && response.data.result.user_details[0] && response.data.result.user_details[0].additionalAddress) {
              additionalAddress = JSON.parse(response.data.result.user_details[0].additionalAddress)
            }
            const shipAddress =
              !isblank(response.data.result.master_table_data[0].shipp_address) && typeof response.data.result.master_table_data[0].shipp_address === "string"
                ? JSON.parse(response.data.result.master_table_data[0].shipp_address)
                : {}
            const shipToAdd = {
              shipping_address: !isblank(shipAddress.address) ? shipAddress.address : "",
              shipping_address2: !isblank(shipAddress.areacode) ? shipAddress.areacode : "",
              shipping_pincode: !isblank(shipAddress.pincode) ? shipAddress.pincode : "",
              shipping_city: !isblank(shipAddress.cityid) ? shipAddress.cityid : "",
              shipping_state: !isblank(shipAddress.stateid) ? shipAddress.stateid : "",
            }
            pushDrawer({ title: "Update Eway Bill", data: { type: "newEwayBill", additionalAddress, otherDetail, shipToAdd: shipToAdd }, position: "right", width: 800, dispatch })
          } else if (otherDetail === "fromEInvoice") {
            const meta = {}
            meta.other_type = otherType
            meta.code = code
            meta.otherDetail = otherDetail
            meta.invoiceDetail = response.data.result
            dispatch({
              type: CONFIRM_DIALOG,
              payload: true,
              confirm: false,
              message: "generate e-Invocie",
              title: "Generate e-Invoice",
              form: "Generate E-Invoice",
              meta: meta,
              loading: false,
            })
          } else if (!isblank(otherDetail) && otherDetail.printType === "inward_barCode_print") {
            const meta = {}
            meta.otherDetail = otherDetail
            meta.inwardDetail = response.data.result

            if (!isblank(otherDetail) && otherDetail.templates.length === 1) {
              templateSetting = createDropDown(otherDetail.templates, otherDetail.templates[0], "code")
              const printData = getPrintData(templateSetting, meta.inwardDetail)
              printBarcode({ templateSetting, printData })
            } else {
              dispatch({
                type: CONFIRM_DIALOG,
                payload: true,
                confirm: false,
                message: "",
                title: "Print Barcode",
                form: "Barcode Print",
                meta: meta,
                loading: false,
              })
            }
          } else if (!isblank(otherDetail) && otherDetail.printType === "processTransfer_barCode_print") {
            const meta = {}
            meta.otherDetail = otherDetail
            meta.inwardDetail = response.data.result

            if (!isblank(otherDetail) && otherDetail.templates.length === 1) {
              templateSetting = createDropDown(otherDetail.templates, otherDetail.templates[0], "code")
              const printData = getPrintData(templateSetting, meta.inwardDetail)
              printBarcode({ templateSetting, printData })
            } else {
              dispatch({
                type: CONFIRM_DIALOG,
                payload: true,
                confirm: false,
                message: "",
                title: "Print Barcode",
                form: "Barcode Print",
                meta: meta,
                loading: false,
              })
            }
          } else if (!isblank(otherDetail) && otherDetail.printType === "share") {
            const responseData = response.data.result
            const masterTableData = responseData.master_table_data[0]
            const invoice_type = masterTableData.invoice_type
            const invoice_name = getNameFromType(invoice_type)
            const notifyPartyCode =
              !isblank(responseData) && !isblank(responseData.user_details) && responseData.user_details.length > 0 && !isblank(responseData.user_details[0].code)
                ? responseData.user_details[0].code
                : ""
            const notifyPartyName =
              !isblank(responseData) && !isblank(responseData.user_details) && responseData.user_details.length > 0 && !isblank(responseData.user_details[0].name)
                ? responseData.user_details[0].name
                : ""

            const sendNotification = ({ sendEmail, email, sendSMS, sendWhatsApp, defaultMobile, defaultEmail, notifyPartyCode, mobile, partyCountry }) => {
              handleButtonClick("notification", { sendEmail, email, sendSMS, sendWhatsApp, defaultMobile, defaultEmail, notifyPartyCode, number: mobile, invoice_type, partyCountry })
            }

            const handleButtonClick = (action, sendData) => {
              const userType = getAccountSessionUserData("usertype")
              var billno = masterTableData.billno

              let filename = `${invoice_type}_${billno}`
              if (invoice_type === "salesreturn") {
                billno = masterTableData.creditnote_no
                filename = `creditnote_${billno}`
              } else if (invoice_type === "purchasereturn") {
                billno = masterTableData.debitnote_no
                filename = `debitnote_${billno}`
              }

              var netamt = masterTableData.netamt
              var other_Detail = {}
              if (invoice_type === "lot_transfer") {
                billno = masterTableData.code
                responseData.custom_fields_data_master = responseData.custom_fields_data_master.map((e, i) => {
                  const cf = otherDetail.ddcustomfield.find((f) => f.code === e.code)
                  const item = { ...e }
                  if (!isblank(cf)) {
                    item.dropDownOptions = cf.dropDownOptions
                  }
                  return item
                })
                responseData.custom_fields_data = responseData.custom_fields_data.map((e, i) => {
                  const cf = otherDetail.ddcustomfield.find((f) => f.code === e.code)
                  const item = { ...e }
                  if (!isblank(cf)) {
                    item.dropDownOptions = cf.dropDownOptions
                  }
                  return item
                })
                other_Detail = {}
                other_Detail.title = "Lot Transfer"
                other_Detail.printType = "html"
                dispatch(
                  modalInvoiceLocalPrint({
                    data: responseData,
                    other_type: invoice_type,
                    title: `Process Transfer No: ${billno}`,
                    filename: `${invoice_type}_${billno}`,
                    otherDetail: other_Detail,
                    printType: action,
                  })
                )
              } else if (invoice_type === "stock_transferGodown") {
                other_Detail = {}
                other_Detail.title = "Godown Stock Transfer"
                other_Detail.printType = "html"
                dispatch(
                  modalInvoiceLocalPrint({
                    data: responseData,
                    other_type: invoice_type,
                    title: `Godown Stock Transfer No: ${billno}`,
                    filename: `${invoice_type}_${billno}`,
                    otherDetail: other_Detail,
                    printType: action,
                  })
                )
              } else if (invoice_type === "delivery_challan" && getconfigsettingdata("company_status")["business_type"] === "Wood") {
                const metadata = {}
                metadata.title = invoice_type
                metadata.invoice_type = invoice_type
                metadata.other_type = invoice_type
                other_Detail = {}
                other_Detail.title = "Delivery Challan"
                other_Detail.printType = "html"
                metadata.otherDetail = otherDetail

                const functions = { getconfigsettingdata }
                const pageSize = otherDetail.dS.outward_type_detail.filter((e) => e.id === invoice_type)[0].template_size === "1" ? "A5" : "A4"
                const printData = generateOutwardPdf({ printdata: responseData, meta: metadata, pageSize, functions })

                if (action === "preview") {
                  functions.maxWidth = getWindowWidth()
                  functions.dispatch = dispatch
                  functions.pushDrawer = pushDrawer
                }
                fbprintpdf({ docDefinition: printData, metadata, action: action === "preview" ? "print_preview" : action, functions })
              } else if (action === "print_ewaybill") {
                other_Detail = {}
                other_Detail.title = ""
                other_Detail.printType = "html"
                other_Detail.dd_state = otherDetail.dd_country_state
                dispatch(
                  modalInvoiceLocalPrint({
                    data: responseData,
                    other_type: "e-wayBill",
                    title: `${otherDetail.title} Bill No: ${billno}`,
                    filename: `${invoice_type}_${billno}`,
                    otherDetail: other_Detail,
                    printType: action,
                  })
                )
              } else {
                other_Detail = {}
                other_Detail.title = invoice_name
                other_Detail.printType = "html"
                if (action === "notification") {
                  var send_type = []
                  if (sendData.sendSMS) {
                    send_type.push("sms")
                  }
                  if (sendData.sendEmail) {
                    send_type.push("email")
                  }
                  if (sendData.sendWhatsApp) {
                    send_type.push("whatsapp")
                  }

                  if (userType === "A") {
                    send_type = [send_type, "admin"]
                  }
                  other_Detail.printCopy = "single"
                  var countryId = responseData.user_details[0].country_id
                  if (isblank(countryId)) {
                    dispatch(snackbarstate({ open: true, message: "Select country", snackbarType: "warning" }))
                  } else {
                    const onCompleteBase64 = (fileData) => {
                      const doc_detail = fileData.invoiceDetail.master_table_data[0]
                      if (doc_detail.invoice_type === "salesreturn") {
                        billno = doc_detail.billno_str
                      } else if (doc_detail.invoice_type === "purchasereturn") {
                        billno = doc_detail.billno_str
                      } else {
                        billno = doc_detail.billno
                      }

                      const docDetail = getNotificationInvoiceDetail({ invoiceDetail: responseData }, sendData.email, sendData.number)

                      sendNotificationInvoiceFn({
                        fileData,
                        lastpagedata: sendData,
                        sendNewNotifications: sendNewNotifications,
                        dispatch,
                        dS: otherDetail.dS,
                        detail: docDetail,
                        other_type: invoice_type,
                      })
                    }
                    dispatch(
                      modalInvoiceLocalPrint({
                        data: responseData,
                        other_type: invoice_type,
                        title: `Bill No: ${billno}`,
                        filename: filename,
                        otherDetail: other_Detail,
                        printType: "base64",
                        onCompleteBase64,
                      })
                    )
                  }
                } else {
                  if (action === "print_without_header") {
                    other_Detail.pre_printed_header = 1
                    action = "print"
                  }
                  dispatch(modalInvoiceLocalPrint({ data: responseData, other_type: invoice_type, title: `Bill No: ${billno}`, filename, otherDetail: other_Detail, printType: action }))
                }
              }
            }

            dispatch({
              type: CONFIRM_DIALOG,
              payload: true,
              confirm: false,
              message: "",
              title: `Share to ${notifyPartyName}`,
              form: "Share Component",
              meta: {
                getPartyDetailV2: getPartyDetailV2,
                notifyPartyCode,
                sendClick: sendNotification,
              },
              loading: false,
            })
          } else {
            var other_type = ""
            var metadata = {}
            var filename = ""
            if (otherType === "stockTransfer") {
              other_type = otherType
              if (!isblank(response.data.result.inwardDetail.master_table_data[0].billno_str)) {
                response.data.result.inwardDetail.master_table_data[0].billno = response.data.result.inwardDetail.master_table_data[0].billno_str
              }
              if (!isblank(response.data.result.outwardDetail.master_table_data[0].billno_str)) {
                response.data.result.outwardDetail.master_table_data[0].billno = response.data.result.outwardDetail.master_table_data[0].billno_str
              }
            } else {
              if (otherType === "delivery_challan" || otherType === "goods_received_note") {
                other_type = otherType
              } else if (!isblank(response.data.result.master_table_data)) {
                other_type = !isblank(response.data.result.master_table_data[0].invoice_type) ? response.data.result.master_table_data[0].invoice_type : otherType
              }

              if (!isblank(response.data.result.master_table_data)) {
                if (!isblank(response.data.result.master_table_data[0].billno_str)) {
                  if (response.data.result.master_table_data[0].invoice_type === "salesreturn") {
                    response.data.result.master_table_data[0].creditnote_no = response.data.result.master_table_data[0].billno_str
                  } else if (response.data.result.master_table_data[0].invoice_type === "purchasereturn") {
                    response.data.result.master_table_data[0].debitnote_no = response.data.result.master_table_data[0].billno_str
                  } else {
                    response.data.result.master_table_data[0].billno = response.data.result.master_table_data[0].billno_str
                  }
                }

                filename = filenameOld ? filenameOld : `${other_type}_${response.data.result.master_table_data[0].billno}`
              }
            }

            var maxWidth = getWindowWidth()
            if (response.data.result.user_details && response.data.result.user_details[0] && response.data.result.user_details[0].name) {
              metadata.filename = getAccountSessionUserData("company_url") + "_" + filename.replace(/\s/gi, "_") + "_" + response.data.result.user_details[0].name.toLowerCase().replace(/\s/gi, "_")
            } else {
              metadata.filename = getAccountSessionUserData("company_url") + "_" + filename.replace(/\s/gi, "_") + "_" + getAccountSessionUserData("financial_year")
            }
            metadata.title = title
            metadata.invoice_type = other_type
            metadata.other_type = other_type
            metadata.otherDetail = otherDetail

            var printData = ""
            var template_id = ""
            if (otherDetail.printType === "html") {
              var tdsDetail = ""
              if (
                purchase_type_detail.filter((e) => e.id === other_type).length > 0 ||
                invoice_type_detail.filter((e) => e.id === other_type).length > 0 ||
                salesreturn_type_detail.filter((e) => e.id === other_type).length > 0 ||
                purchasereturn_type_detail.filter((e) => e.id === other_type).length > 0
              ) {
                if (parseFloat(getNumberonly(response.data.result.master_table_data[0].tds_amt)) > 0) {
                  tdsDetail = {
                    amount: amount_format(response.data.result.master_table_data[0].tds_amt),
                    percent: response.data.result.master_table_data[0].tds_percent,
                  }
                  var finalfooterData = []

                  response.data.result.footerdetail.forEach((item, index) => {
                    if (index === 0) {
                      finalfooterData.push(item)
                      if (print_tds_details !== 1) {
                        if (!isblank(tdsDetail) && !isblank(tdsDetail.percent)) {
                          finalfooterData.push([`TDS (${tdsDetail.percent} %)`, tdsDetail.amount])
                        } else {
                          finalfooterData.push(["TDS", tdsDetail.amount])
                        }
                      }
                    } else {
                      finalfooterData.push(item)
                    }
                  })

                  response.data.result.footerdetail = finalfooterData
                }
              }

              if (other_type === "lot_transfer") {
                response.data.result.custom_fields_data_master = otherDetail && otherDetail.custom_fields_data_master ? otherDetail.custom_fields_data_master : []
                response.data.result.custom_fields_data = otherDetail && otherDetail.custom_fields_data ? otherDetail.custom_fields_data : []
                if (!isblank(status) && status === "pending") {
                  response.data.result.details_table_data = response.data.result.details_table_data.map((item) => {
                    const row = { ...item }
                    row.qty = row.penqty
                    return row
                  })

                  response.data.result.details_table_data = response.data.result.details_table_data.filter((e) => parseInt(e.penqty) > 0)
                  const penqty = response.data.result.details_table_data.map((item) => parseFloat(getNumberonly(item.penqty))).reduce((prev, next) => prev + next)
                  response.data.result.master_table_data[0].totqty = penqty
                } else if (!isblank(status) && status === "closed") {
                  response.data.result.details_table_data = response.data.result.details_table_data.filter((e) => parseInt(e.penqty) === 0)
                  const qty = response.data.result.details_table_data.map((item) => parseFloat(getNumberonly(item.qty))).reduce((prev, next) => prev + next)
                  response.data.result.master_table_data[0].totqty = qty
                }
                printData = generateProcessTransferPdf({ printdata: response.data.result, meta: metadata, pageSize: "A4" })
              } else if (other_type === "stockTransfer") {
                printData = generateStockTransferPdf({ printdata: response.data.result, meta: metadata, pageSize: "A4" })
              } else if (other_type === "stock_transferGodown") {
                printData = generateGodownStockTransferPdf({ printdata: response.data.result, meta: metadata, pageSize: "A4" })
              } else if (inward_type_detail.filter((e) => e.id === other_type).length > 0) {
                const pageSize = inward_type_detail.filter((e) => e.id === other_type)[0].template_size === "1" ? "A5" : "A4"
                if (!isblank(status) && status === "pending") {
                  response.data.result.details_table_data = response.data.result.details_table_data.filter((e) => parseInt(e.penqty) > 0)

                  response.data.result.details_table_data = response.data.result.details_table_data.map((item) => {
                    const row = { ...item }
                    row.qty = row.penqty
                    return row
                  })

                  const penqty = response.data.result.details_table_data.map((item) => parseFloat(getNumberonly(item.penqty))).reduce((prev, next) => prev + next)
                  response.data.result.master_table_data[0].totqty = penqty
                } else if (!isblank(status) && status === "closed") {
                  response.data.result.details_table_data = response.data.result.details_table_data.filter((e) => parseInt(e.penqty) === 0)
                  const qty = response.data.result.details_table_data.map((item) => parseFloat(getNumberonly(item.qty))).reduce((prev, next) => prev + next)
                  response.data.result.master_table_data[0].totqty = qty
                }
                printData = generateInwardPdf({ printdata: response.data.result, meta: metadata, pageSize })
              } else if (outward_type_detail.filter((e) => e.id === other_type).length > 0) {
                const pageSize = outward_type_detail.filter((e) => e.id === other_type)[0].template_size === "1" ? "A5" : "A4"
                if (!isblank(status) && status === "pending") {
                  response.data.result.details_table_data = response.data.result.details_table_data.filter((e) => parseInt(e.penqty) > 0)

                  response.data.result.details_table_data.map((item) => {
                    const row = { ...item }
                    row.qty = row.penqty
                    return row
                  })

                  const penqty = response.data.result.details_table_data.map((item) => parseFloat(getNumberonly(item.penqty))).reduce((prev, next) => prev + next)
                  response.data.result.master_table_data[0].totqty = penqty
                } else if (!isblank(status) && status === "closed") {
                  response.data.result.details_table_data = response.data.result.details_table_data.filter((e) => parseInt(e.penqty) === 0)
                  const qty = response.data.result.details_table_data.map((item) => parseFloat(getNumberonly(item.qty))).reduce((prev, next) => prev + next)
                  response.data.result.master_table_data[0].totqty = qty
                }
                printData = generateOutwardPdf({ printdata: response.data.result, meta: metadata, pageSize })
              } else if (invoice_type_detail.filter((e) => e.id === other_type).length > 0) {
                const pageSize = invoice_type_detail.filter((e) => e.id === other_type)[0].template_size === "1" ? "A5" : "A4"
                template_id = response.data.result.master_table_data[0].print_template_no
                const Template = !isblank(template_id) ? template_id : invoice_type_detail.filter((e) => e.id === other_type)[0].template
                if (!isblank(Template)) {
                  metadata.type = "invoice_type_detail"
                  printData = generatePrintData({ data: response.data.result, metadata, dispatch, printType, pageSize, Template })
                } else {
                  dispatch({
                    type: SNACKBAR_STATE,
                    payload: true,
                    message: "Select Template First",
                    snackbarType: "error",
                  })
                }
              } else if (purchase_type_detail.filter((e) => e.id === other_type).length > 0) {
                const pageSize = purchase_type_detail.filter((e) => e.id === other_type)[0].template_size === "1" ? "A5" : "A4"
                template_id = response.data.result.master_table_data[0].print_template_no
                const Template = !isblank(template_id) ? template_id : purchase_type_detail.filter((e) => e.id === other_type)[0].template
                if (!isblank(Template)) {
                  metadata.type = "purchase_type_detail"
                  printData = generatePrintData({ data: response.data.result, metadata, dispatch, printType, pageSize, Template })
                } else {
                  dispatch({
                    type: SNACKBAR_STATE,
                    payload: true,
                    message: "Select Template First",
                    snackbarType: "error",
                  })
                }
              } else if (other_type === "salesreturn") {
                const pageSize = salesreturn_type_detail.filter((e) => e.id === "salesreturn")[0].template_size === "1" ? "A5" : "A4"
                template_id = response.data.result.master_table_data[0].print_template_no
                const Template = !isblank(template_id) ? template_id : salesreturn_type_detail.filter((e) => e.id === "salesreturn")[0].template
                if (!isblank(Template)) {
                  metadata.type = "salesreturn_type_detail"
                  metadata.invoice_type = "salesreturn"
                  printData = generatePrintData({ data: response.data.result, metadata, dispatch, printType, pageSize, Template })
                } else {
                  dispatch({
                    type: SNACKBAR_STATE,
                    payload: true,
                    message: "Select Template First",
                    snackbarType: "error",
                  })
                }
              } else if (other_type === "purchasereturn") {
                const pageSize = purchasereturn_type_detail.filter((e) => e.id === "purchasereturn")[0].template_size === "1" ? "A5" : "A4"
                template_id = response.data.result.master_table_data[0].print_template_no
                const Template = !isblank(template_id) ? template_id : purchasereturn_type_detail.filter((e) => e.id === "purchasereturn")[0].template
                if (!isblank(Template)) {
                  metadata.type = "purchasereturn_type_detail"
                  metadata.invoice_type = "purchasereturn"
                  printData = generatePrintData({ data: response.data.result, metadata, dispatch, printType, pageSize, Template })
                } else {
                  dispatch({
                    type: SNACKBAR_STATE,
                    payload: true,
                    message: "Select Template First",
                    snackbarType: "error",
                  })
                }
              }
              if (!isblank(printData)) {
                if (printType === "print" || printType === "download") {
                  fbprintpdf({ docDefinition: printData, metadata, action: printType })
                } else if (printType === "base64") {
                  if (onCompleteBase64) {
                    const functionsForBase64 = {
                      onComplete: (filebase64) => {
                        const returnValue = {
                          filename: `${metadata.filename}.pdf`,
                          filebase64,
                          filemimetype: "application/pdf",
                          invoiceDetail: response.data.result,
                          printData,
                          metadata,
                          printType,
                        }
                        onCompleteBase64(returnValue)
                      },
                    }
                    fbprintpdf({ docDefinition: printData, metadata, action: printType, functions: functionsForBase64 })
                  }
                } else {
                  const functions = { dispatch, maxWidth, pushDrawer }
                  fbprintpdf({ docDefinition: printData, metadata, action: "print_preview", functions })
                }
              }
            } else {
              setTimeout(() => {
                if (!isblank(otherDetail) && otherDetail.from === "drawer") {
                  updateDrawer({ title: "Invoice Detail", data: response.data.result, fieldname: metadata, dispatch, index: otherDetail.drawerIndex })
                } else {
                  if (other_type === "stockTransfer") {
                    pushDrawer({
                      title: "Stock Transfer Detail",
                      data: response.data.result,
                      fieldname: metadata,
                      position: maxWidth <= 1024 ? "bottom" : "right",
                      width: maxWidth <= 1024 ? maxWidth : 1024,
                      dispatch,
                    })
                  } else {
                    if (onComplete) {
                      onComplete(response.data.result, page)
                    } else {
                      pushDrawer({
                        title: "Invoice Detail",
                        data: response.data.result,
                        fieldname: metadata,
                        position: maxWidth <= 1024 ? "bottom" : "right",
                        width: maxWidth <= 1024 ? maxWidth : 1024,
                        dispatch,
                      })
                    }
                  }
                }
              }, 300)
            }
          }
          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        }
      })
      .catch((error) => {
        if (otherDetail && (otherDetail.actionType === "saveandprint" || otherDetail.actionType === "docPrint")) {
          dispatch({
            type: LOADING_SNACKBAR_STATE,
            payload: false,
            message: "Generating Document Print, Please wait...",
          })
        }

        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
        dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        if (onError) {
          onError()
        }
      })
  }
}

export const modalTransactionDetail = ({
  code,
  type,
  title,
  filenameOld,
  otherDetail,
  printType,
  otherType,
  onComplete = false,
  onError,
  onCompleteBase64,
  drawerIndex,
  tab_flag = 1,
  tab_type,
  page = "",
  from_date,
  to_date,
  offset = 0,
  limit,
  sort_order,
  sort_by,
  detailCode = false,
}) => {
  return (dispatch) => {
    if (!isblank(tab_type) && tab_type === "history") {
    } else {
      dispatch({
        type: LOADING_STATE,
        state: otherDetail && !isblank(otherDetail.loading) ? otherDetail.loading : true,
        sidebarstate: otherDetail && !isblank(otherDetail.sidebarloading) ? otherDetail.sidebarloading : false,
      })
    }
    const invoice_type = type
    let data = new FormData()
    if (tab_type) {
      data.append("type", "transaction_detaildata_tab")
      data.append("tab_type", tab_type)
    } else {
      data.append("type", "transaction_detaildata")
    }
    if ((type === "receipt" || type === "payment" || type === "journal_entry") && detailCode === true) {
      data.append("detailcode", code)
    } else {
      data.append("code", code)
    }

    if (otherType) {
      data.append("other_type", otherType)
    } else {
      data.append("other_type", type)
    }
    if (tab_flag) {
      data.append("tab_flag", tab_flag)
    }
    if (!isblank(tab_type) && tab_type === "history") {
      data.append("from_date", from_date)
      data.append("to_date", to_date)
      data.append("offset", offset)
      data.append("limit", limit)
      data.append("sort_order", sort_order)
      data.append("sort_by", sort_by)
    }

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "transaction_detail" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
          if (onError) {
            onError()
          }
        } else {
          var metadata = {}
          var maxWidth = getWindowWidth()
          const responseData = response.data.result
          const filename = filenameOld ? filenameOld : `${type}_${responseData.docno}`

          metadata.filename = getAccountSessionUserData("company_url") + "_" + filename.replace(/\s/gi, "_") + "_" + getAccountSessionUserData("financial_year")
          metadata.title = title
          metadata.invoice_type = type
          metadata.other_type = type
          metadata.otherDetail = otherDetail

          if (otherDetail.printType === "html") {
            var printData = ""
            if (type === "receipt" || type === "payment") {
              printData = generateTransactionPdf({ printdata: responseData, other_type: type })
            } else {
              printData = generateJournalPdf({ printdata: responseData })
            }

            if (!isblank(printData)) {
              if (printType === "print" || printType === "download") {
                fbprintpdf({ docDefinition: printData, metadata, action: printType })
              } else if (printType === "base64") {
                if (onCompleteBase64) {
                  const functionsForBase64 = {
                    onComplete: (filebase64) => {
                      const returnValue = {
                        filename: `${metadata.filename}.pdf`,
                        filebase64,
                        filemimetype: "application/pdf",
                        transactionDetail: responseData,
                        printData,
                        metadata,
                        printType,
                      }
                      onCompleteBase64(returnValue)
                    },
                  }
                  fbprintpdf({ docDefinition: printData, metadata, action: printType, functions: functionsForBase64 })
                }
              } else {
                const functions = { dispatch, maxWidth, pushDrawer }
                fbprintpdf({ docDefinition: printData, metadata, action: "print_preview", functions })
              }
            }
          } else if (printType === "share") {
            const userDetail = responseData.receipt_type === "S" ? responseData.user_details[0] : responseData.DetailGrid[0].user_details[0]

            const sendNotification = ({ sendEmail, email, sendSMS, sendWhatsApp, defaultMobile, defaultEmail, notifyPartyCode, mobile }) => {
              handleButtonClick("notification", { sendEmail, email, sendSMS, sendWhatsApp, defaultMobile, defaultEmail, notifyPartyCode, number: mobile, invoice_type })
            }

            const handleButtonClick = (action, sendData) => {
              const receipt_type = responseData.receipt_type
              const userType = getAccountSessionUserData("usertype")
              var billno = responseData.docno
              var other_Detail = {}
              if (invoice_type === "receipt") {
                other_Detail.title = "Receipt"
              } else if (invoice_type === "payment") {
                other_Detail.title = "Payment"
              }
              other_Detail.printType = "html"
              if (action === "notification") {
                var send_type = []
                if (sendData.sendSMS) {
                  send_type.push("sms")
                }
                if (sendData.sendEmail) {
                  send_type.push("email")
                }
                if (sendData.sendWhatsApp) {
                  send_type.push("whatsapp")
                }

                if (userType === "A") {
                  send_type = [send_type, "admin"]
                }
                other_Detail.printCopy = "single"

                var countryId = ""
                var partyName = ""
                if (!isblank(receipt_type) && receipt_type === "M") {
                  countryId = userDetail.country_id
                  partyName = userDetail.name
                } else if (!isblank(receipt_type) && receipt_type === "S") {
                  countryId = userDetail.country_id
                  partyName = userDetail.name
                }

                if (isblank(countryId)) {
                  dispatch(snackbarstate({ open: true, message: "Select country", snackbarType: "warning" }))

                  dispatch({
                    type: LAST_PAGE_UPDATE,
                    lasttype: "sendNotification",
                    lastaction: "error",
                    lasttime: new Date().getTime(),
                  })
                } else {
                  const onCompleteBase64 = (fileData) => {
                    const docDetail = getNotificationTransactionDetail(responseData, sendData.email, sendData.number)
                    sendNotificationInvoiceFn({
                      fileData,
                      lastpagedata: sendData,
                      sendNewNotifications: sendNewNotifications,
                      dispatch,
                      dS: otherDetail.dS,
                      detail: docDetail,
                      other_type: "transaction",
                    })
                  }
                  dispatch(
                    modaltransactionLocalPrint({
                      data: responseData,
                      other_type: invoice_type,
                      title: `Bill No: ${billno}`,
                      filename: `${invoice_type}_${billno}`,
                      otherDetail: other_Detail,
                      printType: "base64",
                      onCompleteBase64,
                    })
                  )
                }
              } else {
                dispatch(
                  modaltransactionLocalPrint({
                    data: responseData,
                    other_type: invoice_type,
                    title: `Doc No: ${billno}`,
                    filename: `${invoice_type}_${billno}`,
                    otherDetail: other_Detail,
                    printType: action,
                  })
                )
              }
            }

            dispatch({
              type: CONFIRM_DIALOG,
              payload: true,
              confirm: false,
              message: "",
              title: `Share to ${userDetail.name}`,
              form: "Share Component",
              meta: {
                getPartyDetailV2: getPartyDetailV2,
                notifyPartyCode: userDetail.code,
                sendClick: sendNotification,
              },
              loading: false,
            })
          } else {
            metadata.apiParams = {
              code,
              type,
              title,
              filenameOld,
              otherDetail,
              printType,
              onCompleteBase64,
            }
            if (!isblank(drawerIndex) && onComplete === false) {
              updateDrawer({ title: "Transaction Detail", data: response.data.result, fieldname: metadata, dispatch, index: drawerIndex })
            } else if (onComplete) {
              onComplete(response.data.result, page)
            } else {
              pushDrawer({
                title: "Transaction Detail",
                data: response.data.result,
                fieldname: metadata,
                position: maxWidth <= 1024 ? "bottom" : "right",
                width: maxWidth <= 1024 ? maxWidth : 1024,
                dispatch,
              })
            }
          }
          dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
        dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
        if (onError) {
          onError()
        }
      })
  }
}

const generatePrintData = ({ data, metadata, dispatch, printType, pageSize, Template }) => {
  var printData = ""
  if (Template !== "2" && Template !== "3") {
    printData = dynamicTemplate({ printdata: data, meta: metadata })
  } else {
    if (Template === "2") {
      printData = invoiceTemplate2({ printdata: data, meta: metadata, pageSize })
    }
    if (Template === "3") {
      printData = invoiceTemplate3({ printdata: data, meta: metadata, pageSize })
    }
  }

  return printData
}

export const modalInvoiceLocalPrint = ({ data, other_type, title, filename, otherDetail, printType, onCompleteBase64 }) => {
  const invoice_type_detail = getAccountSitesetting("invoice_type_detail")
  const purchase_type_detail = getAccountSitesetting("purchase_type_detail")
  const inward_type_detail = getAccountSitesetting("inward_type_detail")
  const outward_type_detail = getAccountSitesetting("outward_type_detail")
  const salesreturn_type_detail = getAccountSitesetting("salesreturn_type_detail")
  const purchasereturn_type_detail = getAccountSitesetting("purchasereturn_type_detail")
  return (dispatch) => {
    try {
      var metadata = {}
      var maxWidth = getWindowWidth()
      if (!filename.includes(".pdf")) {
        if (data.user_details && data.user_details[0] && data.user_details[0].name) {
          metadata.filename = getAccountSessionUserData("company_url") + "_" + filename.replace(/\s/gi, "_") + "_" + data.user_details[0].name.toLowerCase().replace(/\s/gi, "_")
        } else {
          metadata.filename = getAccountSessionUserData("company_url") + "_" + filename.replace(/\s/gi, "_") + "_" + getAccountSessionUserData("financial_year")
        }
      } else {
        metadata.filename = filename
      }
      metadata.title = title
      metadata.invoice_type = other_type
      metadata.other_type = other_type
      metadata.otherDetail = otherDetail

      var printData = ""
      var template_id = ""

      if (otherDetail.printType === "html") {
        if (other_type === "lot_transfer") {
          printData = generateProcessTransferPdf({ printdata: data, meta: metadata, pageSize: "A4" })
        } else if (other_type === "stockTransfer") {
          printData = generateStockTransferPdf({ printdata: data, meta: metadata, pageSize: "A4" })
        } else if (other_type === "stock_transferGodown") {
          printData = generateGodownStockTransferPdf({ printdata: data, meta: metadata, pageSize: "A4" })
        } else if (other_type === "e-wayBill") {
          printData = generateEwayBillPdf({ printdata: data, meta: metadata, pageSize: "A4" })
        } else if (other_type === "packing_slip") {
          const pageSize = inward_type_detail.filter((e) => e.id === other_type)[0].template_size === "1" ? "A5" : "A4"
          metadata.type = "inward_type_detail"
          printData = generatePackingSlipPdf({ printdata: data, meta: metadata, pageSize })
        } else if (other_type === "delivery_challan") {
          const pageSize = outward_type_detail.filter((e) => e.id === other_type)[0].template_size === "1" ? "A5" : "A4"
          metadata.type = "outward_type_detail"
          printData = generateOutwardPdf({ printdata: data, meta: metadata, pageSize })
          //printData = generatePackingSlipPdf(data, metadata, pageSize)
        } else if (inward_type_detail.filter((e) => e.id === other_type).length > 0) {
          const pageSize = inward_type_detail.filter((e) => e.id === other_type)[0].template_size === "1" ? "A5" : "A4"
          printData = generateInwardPdf({ printdata: data, meta: metadata, pageSize })
        } else if (outward_type_detail.filter((e) => e.id === other_type).length > 0) {
          const pageSize = outward_type_detail.filter((e) => e.id === other_type)[0].template_size === "1" ? "A5" : "A4"
          printData = generateOutwardPdf({ printdata: data, meta: metadata, pageSize })
        } else if (invoice_type_detail.filter((e) => e.id === other_type).length > 0) {
          metadata.type = "invoice_type_detail"
          const pageSize = invoice_type_detail.filter((e) => e.id === other_type)[0].template_size === "1" ? "A5" : "A4"
          template_id = data.master_table_data[0].print_template_no
          const Template = !isblank(template_id) ? template_id : invoice_type_detail.filter((e) => e.id === other_type)[0].template
          if (!isblank(Template)) {
            printData = generatePrintData({ data, metadata, dispatch, printType, pageSize, Template })
          } else {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: "Select Template First",
              snackbarType: "error",
            })
          }
        } else if (purchase_type_detail.filter((e) => e.id === other_type).length > 0) {
          metadata.type = "purchase_type_detail"
          const pageSize = purchase_type_detail.filter((e) => e.id === other_type)[0].template_size === "1" ? "A5" : "A4"
          template_id = data.master_table_data[0].print_template_no
          const Template = !isblank(template_id) ? template_id : purchase_type_detail.filter((e) => e.id === other_type)[0].template
          if (!isblank(Template)) {
            printData = generatePrintData({ data, metadata, dispatch, printType, pageSize, Template })
          } else {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: "Select Template First",
              snackbarType: "error",
            })
          }
        } else if (other_type === "salesreturn") {
          const pageSize = salesreturn_type_detail.filter((e) => e.id === "salesreturn")[0].template_size === "1" ? "A5" : "A4"
          template_id = data.master_table_data[0].print_template_no
          const Template = !isblank(template_id) ? template_id : salesreturn_type_detail.filter((e) => e.id === "salesreturn")[0].template
          if (!isblank(Template)) {
            metadata.type = "salesreturn_type_detail"
            metadata.invoice_type = "salesreturn"
            printData = generatePrintData({ data, metadata, dispatch, printType, pageSize, Template })
          } else {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: "Select Template First",
              snackbarType: "error",
            })
          }
        } else if (other_type === "purchasereturn") {
          const pageSize = purchasereturn_type_detail.filter((e) => e.id === "purchasereturn")[0].template_size === "1" ? "A5" : "A4"
          template_id = data.master_table_data[0].print_template_no
          const Template = !isblank(template_id) ? template_id : purchasereturn_type_detail.filter((e) => e.id === "purchasereturn")[0].template
          if (!isblank(Template)) {
            metadata.type = "purchasereturn_type_detail"
            metadata.invoice_type = "purchasereturn"
            printData = generatePrintData({ data, metadata, dispatch, printType, pageSize, Template })
          } else {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: "Select Template First",
              snackbarType: "error",
            })
          }
        }
        if (!isblank(printData)) {
          if (printType === "print" || printType === "download") {
            fbprintpdf({ docDefinition: printData, metadata, action: printType })
          } else if (printType === "base64") {
            if (onCompleteBase64) {
              const functionsForBase64 = {
                onComplete: (filebase64) => {
                  const returnValue = {
                    filename: `${metadata.filename}.pdf`,
                    filebase64,
                    invoiceDetail: data,
                    filemimetype: "application/pdf",
                  }
                  onCompleteBase64(returnValue)
                },
              }
              fbprintpdf({ docDefinition: printData, metadata, action: printType, functions: functionsForBase64 })
            }
          } else {
            const functions = { dispatch, maxWidth, pushDrawer }
            fbprintpdf({ docDefinition: printData, metadata, action: "print_preview", functions })
          }
        }
      } else {
        setTimeout(() => {
          pushDrawer({ title: "Invoice Detail", data, fieldname: metadata, position: maxWidth <= 1024 ? "bottom" : "right", width: maxWidth <= 1024 ? maxWidth : 1024, dispatch })
        }, 300)
      }

      dispatch({ type: LOADING_STATE, state: false })
    } catch (error) {
      dispatch({
        type: SNACKBAR_STATE,
        payload: true,
        message: getCatchErrorMessage(error),
        snackbarType: "error",
      })
    }
  }
}

export const modaltransactionLocalPrint = ({ data, other_type, title, filename, otherDetail, printType, onCompleteBase64 }) => {
  return (dispatch) => {
    try {
      var metadata = {}
      var maxWidth = getWindowWidth()
      metadata.filename = getAccountSessionUserData("company_url") + "_" + filename.replace(/\s/gi, "_") + "_" + getAccountSessionUserData("financial_year")
      metadata.title = title
      metadata.invoice_type = other_type
      metadata.other_type = other_type
      metadata.otherDetail = otherDetail

      if (otherDetail.printType === "html") {
        var printData = ""
        if (other_type === "receipt" || other_type === "payment") {
          printData = generateTransactionPdf({ printdata: data, other_type })
        } else {
          printData = generateJournalPdf({ printdata: data })
        }
        if (!isblank(printData)) {
          if (printType === "print" || printType === "download") {
            fbprintpdf({ docDefinition: printData, metadata, action: printType })
          } else if (printType === "base64") {
            if (onCompleteBase64) {
              const functionsForBase64 = {
                onComplete: (filebase64) => {
                  const returnValue = {
                    filename: `${metadata.filename}.pdf`,
                    filebase64,
                    filemimetype: "application/pdf",
                  }
                  onCompleteBase64(returnValue)
                },
              }
              fbprintpdf({ docDefinition: printData, metadata, action: printType, functions: functionsForBase64 })
            }
          } else {
            const functions = { dispatch, maxWidth, pushDrawer }
            fbprintpdf({ docDefinition: printData, metadata, action: "print_preview", functions })
          }
        }
      } else {
        setTimeout(() => {
          pushDrawer({ title: "Transaction Detail", data, fieldname: metadata, position: maxWidth <= 1024 ? "bottom" : "right", width: maxWidth <= 1024 ? maxWidth : 1024, dispatch })
        }, 300)
      }
      dispatch({ type: LOADING_STATE, state: false })
    } catch (error) {
      dispatch({
        type: SNACKBAR_STATE,
        payload: true,
        message: getCatchErrorMessage(error),
        snackbarType: "error",
      })
    }
  }
}

export const getBankCashDetail = (functions) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "bank_cash_detail" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: CASH_BANK_DETAIL_SUCCESS,
            payload: response.data.result.bank_cash,
          })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const ledgerReport = ({ code, FromDate, ToDate, loading, sidebarloading, drawerIndex, functions }) => {
  return (dispatch) => {
    if (isblank(code)) {
      dispatch({
        type: SNACKBAR_STATE,
        payload: true,
        message: "Please Select Account",
        snackbarType: "error",
      })
    } else {
      if (isblank(FromDate)) {
        FromDate = `${getstartyear()}-04-01`
      }
      if (isblank(ToDate)) {
        ToDate = `${getendyear()}-03-31`
      }

      dispatch({
        type: LOADING_STATE,
        state: !isblank(loading) ? loading : true,
        sidebarstate: !isblank(sidebarloading) ? sidebarloading : false,
      })
      if (typeof getAxiosCancelToken() != typeof undefined) {
        getAxiosCancelToken().cancel("Operation canceled due to new request.")
      }
      // save the new request for cancellation
      setAxiosCancelToken(axios.CancelToken.source())

      let data = new FormData()
      data.append("report_id", 9)
      data.append("code", code)
      data.append("flag", 1)
      data.append("from_date", FromDate)
      data.append("to_date", ToDate)
      axios.defaults.headers.common = getheaders()
      axios({
        method: "post",
        url: list_url({ type: "getAllReport" }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
        cancelToken: getAxiosCancelToken().token,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          if (response.data.meta.code === 401) {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: response.data.meta.message,
              snackbarType: "error",
            })
            dispatch({
              type: LOADING_STATE,
              state: false,
              sidebarstate: false,
            })
          } else {
            dispatch(change("ledgerReportForm", "partySelectedValue", code))
            dispatch(change("ledgerReportForm", "FromDate", FromDate))
            dispatch(change("ledgerReportForm", "ToDate", ToDate))
            var metaData = {}
            metaData.FromDate = FromDate
            metaData.ToDate = ToDate
            metaData.code = code
            metaData.filterData = response.data.filter
            metaData.share_detail = response.data.result.share_detail
            if (!isblank(drawerIndex)) {
              updateDrawer({ title: "Ledger Report", data: response.data.result.records, fieldname: metaData, position: "bottom", dispatch, index: drawerIndex })
            } else {
              pushDrawer({ title: "Ledger Report", data: response.data.result.records, fieldname: metaData, position: "bottom", dispatch })
            }
            dispatch({
              type: LOADING_STATE,
              state: false,
              sidebarstate: false,
            })
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            dispatch({
              type: LOADING_STATE,
              state: false,
              sidebarstate: false,
            })
          } else {
            postErrorOnException(error)
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: getCatchErrorMessage(error),
              snackbarType: "error",
            })
            dispatch({
              type: LOADING_STATE,
              state: false,
              sidebarstate: false,
            })
          }
        })
    }
  }
}

export const checkInvoiceServerStatus = (functions) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", "check_invoice_server_status")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "check_invoice_server_status" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch(showdialog({ yesno: true, confirm: false, title: "Government Server Status", form: "Government Server Status", meta: response }))
          // dispatch({
          //   type: SNACKBAR_STATE,
          //   payload: true,
          //   message: response.data.result.status,
          //   snackbarType: "success",
          // })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const openitembatchout = ({ item, metadata, godowncode }) => {
  return (dispatch) => {
    const functions = {}
    common_openitembatchout({ dispatch, item, metadata, functions, godowncode })
  }
}

export const getBalance = ({ code, date, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    //dispatch({ type: LOADING_STATE,state:true });

    if (!isblank(code)) {
      let data = new FormData()
      data.append("type", "closing_balance")
      data.append("party_code", code)
      data.append("from_date", date)
      axios.defaults.headers.common = getheaders()
      axios({
        method: "post",
        url: list_url({ type: "closing_balance" }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
        cancelToken: getAxiosCancelToken().token,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          dispatch({
            type: CLOSING_BALANCE,
            payload: response.data.result,
          })
          dispatch({ type: LOADING_STATE, state: false })
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            dispatch({ type: LOADING_STATE, state: false })
          } else {
            postErrorOnException(error)
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: getCatchErrorMessage(error),
              snackbarType: "error",
            })
            dispatch({ type: LOADING_STATE, state: false })
          }
        })
    } else {
      dispatch({
        type: CLOSING_BALANCE,
        payload: "",
      })
      dispatch({ type: LOADING_STATE, state: false })
    }
  }
}

export const getPartyDetail = ({ code, date, loading, type, onSuccess }) => {
  const functions = {
    onSuccess,
  }
  return (dispatch) => {
    common_getPartyDetail({ dispatch, code, date, loading, type, functions })
  }
}

export const getPartyDetailV2 = ({ code, date, loading, type, onSuccess, functions }) => {
  return (dispatch) => {
    if (!isblank(code)) {
      let data = new FormData()
      data.append("type", "party_detail")
      data.append("party_code", code)
      axios.defaults.headers.common = getheaders()
      axios({
        method: "post",
        url: list_url({ type: "party_detail" }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          if (response.data.meta.code === 200) {
            if (onSuccess) {
              onSuccess(response.data.result[0])
            }
          } else {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: response.data.meta.message,
              snackbarType: "error",
            })
          }
        })
        .catch((error) => {
          postErrorOnException(error)

          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        })
    } else {
    }
  }
}

export const fetchGSTR2 = ({ formValues, onComplete, functions }) => {
  let data = new FormData()

  const month_year = formValues.quarterSelectedValue.split(" ")
  data.append("month", month_year[0])
  data.append("year", month_year[1])
  data.append("other_type", formValues.gstr2_type)
  data.append("type", "gst_gstr2a")

  return (dispatch) => {
    dispatch({ type: LOADING_STATE, state: true })
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "gst_gstr2a" }),
      headers: { "Content-Type": "multipart/form-data" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 408) {
          //ASK FOR OTP

          dispatch({
            type: CONFIRM_DIALOG,
            payload: true,
            // title: response.data.meta.title,
            title: "Please enter the OTP received on registered mobile/email",
            form: "Otp Dialog",
            confirm: false,
            message: "",
            meta: "",
          })
          dispatch({ type: LOADING_STATE, state: false })
          //showformdialog({ yesno: true, confirm: false, message: "Enter OTP", title: "OTP", form: "Otp Dialog", meta: "" })
        } else if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          //uploadSuccess(dispatch, response, formValues)
          dispatch({ type: LOADING_STATE, state: false })
          if (onComplete) {
            onComplete()
          }
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: "Please Contact Administrator",
          snackbarType: "error",
        })
        //uploadFail(dispatch, getCatchErrorMessage(error))
        dispatch({ type: LOADING_STATE, state: false })
      })
  }
}

const uploadSuccess = (dispatch, data, formValues) => {
  dispatch({
    type: UPLOAD_DOCUMENT_SUCCESS,
    data,
  })
  dispatch({
    type: REPORT_SERVICE_SUCCESS,
    payload: data,
    setconfig: formValues,
  })
}

function uploadFail(error) {
  return {
    type: "UPLOAD_DOCUMENT_FAIL",
    error,
  }
}

export function uploadRequest({ file, formValues, onComplete, onError, functions }) {
  let data = new FormData()

  data.append("datafile", file)
  const month_year = formValues.quarterSelectedValue.split(" ")
  data.append("month", month_year[0])
  data.append("year", month_year[1])
  data.append("type", "gstr2")
  data.append("other_type", formValues.gstr2_type)
  data.append("action", "insert")

  return (dispatch) => {
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "gstr2_upload" }),
      headers: { "Content-Type": "multipart/form-data" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          if (onError) {
            onError(response)
          }
          uploadFail(response)
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          if (onComplete) {
            onComplete(response)
          }
          uploadSuccess(dispatch, response, formValues)
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        uploadFail(dispatch, getCatchErrorMessage(error))
        dispatch({ type: LOADING_STATE, state: false })
      })
  }
}

export const fetchAgentwiseParty = ({ code, reportViewType, crdrSelectedValue, functions }) => {
  let data = new FormData()

  data.append("code", code)
  data.append("type", "agentwiseparty")
  if (!isblank(reportViewType)) {
    data.append("reportViewType", reportViewType)
  }
  if (!isblank(crdrSelectedValue)) {
    data.append("crdrSelectedValue", crdrSelectedValue)
  }

  return (dispatch) => {
    dispatch({ type: LOADING_STATE, state: true })
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "agentwiseparty" }),
      headers: { "Content-Type": "multipart/form-data" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "agentwiseparty",
            lastaction: "fetch_list",
            lasttime: new Date().getTime(),
            lastdata: response.data.result,
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
        dispatch({ type: LOADING_STATE, state: false })
      })
  }
}

const setLastTransDate = ({ dispatch, dateKey, date }) => {
  dispatch({
    type: SET_LAST_DATE,
    dateKey: dateKey,
    lastDate: date,
  })

  const lastTransDate = {
    last_receiptdate: getTransDate("last_receiptdate", "YMD"),
    last_paymentdate: getTransDate("last_paymentdate", "YMD"),
    last_recordexpensedate: getTransDate("last_recordexpensedate", "YMD"),
    last_journalentrydate: getTransDate("last_journalentrydate", "YMD"),
    last_salesinvoicedate: getTransDate("last_salesinvoicedate", "YMD"),
    last_purchaseinvoicedate: getTransDate("last_purchaseinvoicedate", "YMD"),
    last_salesreturndate: getTransDate("last_salesreturndate", "YMD"),
    last_purchasereturndate: getTransDate("last_purchasereturndate", "YMD"),
  }
  lastTransDate[dateKey] = date
  setStorage(`lastTransDate_${getWindowYear()}`, lastTransDate)
}

export const modalproductDetail = ({ code, other_type, invoice_type, onComplete, otherDetail, drawerIndex }) => {
  return (dispatch) => {
    common_modalproductDetail({ code, other_type, invoice_type, onComplete, otherDetail, dispatch, drawerIndex })
  }
}

export const modalConfigProductDetail = ({ code, drawerIndex }) => {
  return (dispatch) => {
    const functions = {}
    common_modalConfigProductDetail({ code, functions, dispatch, drawerIndex })
  }
}

export const getBarCodeTemplates = ({ onSuccess }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("form_data", "barcodeTemplate")
    data.append("offset", 0)
    data.append("limit", "All")
    data.append("dependency", true)
    data.append("form_type", "barcode")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "dependency" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          if (isblank(response.data.barcodeTemplate) || response.data.barcodeTemplate.length === 0) {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: "Template not exist",
              snackbarType: "error",
            })
          } else {
            onSuccess(response.data.barcodeTemplate)
          }
        }
        dispatch({ type: LOADING_STATE, state: false })
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const fetchOrderList = ({ lot_code, Selected, other_type, set_Value, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", "getallorders")
    data.append("other_type", other_type)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "getallorders" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          const fieldname = {}
          fieldname.invoice_type = "order_link_to_lot"
          var maxWidth = getWindowWidth()
          let orderSelected = []
          if (!isblank(Selected)) {
            Selected = replaceAllFn(Selected, "{", "")
            Selected = replaceAllFn(Selected, "}", "")
            orderSelected = Selected.split(",").filter((e) => !isblank(e))
          }
          const drawerData = {
            result: response.data.result,
            Selected: orderSelected,
            set_Value,
          }
          pushDrawer({ title: "Select Pen Order", data: drawerData, fieldname, position: maxWidth <= 1248 ? "bottom" : "right", width: maxWidth <= 1248 ? maxWidth : 1248, dispatch })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const dialog_actions = ({ dialogType, formaction, formdata }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_STATE, state: true })
    if (formaction === "") {
      const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
      var url = post_form_url({ formtype: dialogType, props: { userdata: "", getAccountSitesetting } })
      let data = new FormData()
      data.append("type", dialogType)
      data.append("action", formaction)
      data.append("form_data", formdata2)
      data.append("offset", 0)
      data.append("limit", "All")
      axios.defaults.headers.common = getheaders()
      axios({
        method: "post",
        url: url,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          dispatch({ type: LOADING_STATE, state: false })
          if (response.data.meta.code === 401) {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: response.data.meta.message,
              snackbarType: "error",
            })
          } else if (response.data.meta.code === 200) {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: response.data.meta.message,
              snackbarType: "success",
            })
            removeDrawer(dispatch)
          }
        })
        .catch((error) => {
          dispatch({ type: LOADING_STATE, state: false })
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        })
    }
  }
}

export const clear_salesBill_data = () => {
  const user = {}
  user.data = {}
  user.data.result = []
  return (dispatch) => {
    dispatch({
      type: BILL_SERVICE_SUCCESS,
      payload: user,
    })
  }
}

export const fetch_salesBill_data = ({ companycode, search_text, placeofsupply, notetype }) => {
  return (dispatch) => {
    // dispatch({ type: SALESRETURN_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("code", search_text)
    data.append("other_type", placeofsupply)
    data.append("type", notetype)

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "sales_bill_data" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          fetch_salesBill_dataFail(dispatch, response.data.meta.message, search_text, placeofsupply)
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          fetch_salesBill_dataSuccess(dispatch, response, search_text, placeofsupply)
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        fetch_salesBill_dataFail(dispatch, getCatchErrorMessage(error), search_text, placeofsupply)
        dispatch({ type: LOADING_STATE, state: false })
      })
  }
}
const fetch_salesBill_dataFail = (dispatch, response, search_text, placeofsupply) => {
  dispatch({
    type: BILL_SERVICE_FAIL,
    payload: response,
    search_text: search_text,
    placeofsupply: placeofsupply,
  })
}
const fetch_salesBill_dataSuccess = (dispatch, user, search_text, placeofsupply) => {
  dispatch({
    type: BILL_SERVICE_SUCCESS,
    payload: user,
    search_text: search_text,
    placeofsupply: placeofsupply,
  })
}

export const clear_purchaseBill_data = () => {
  const user = {}
  user.data = {}
  user.data.result = []
  return (dispatch) => {
    dispatch({
      type: PUR_BILL_SERVICE_SUCCESS,
      payload: user,
    })
  }
}
export const fetch_purchaseBill_data = ({ companycode, search_text, placeofsupply, notetype }) => {
  return (dispatch) => {
    // dispatch({ type: PURCHASERETURN_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("financial_year", parseInt(getAccountSessionUserData("financial_year"), 10))
    data.append("company_url", companycode)
    data.append("code", search_text)
    data.append("other_type", placeofsupply)
    data.append("type", notetype)

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "purchase_bill_data" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          fetch_purchaseBill_dataFail(dispatch, response.data.meta.message, search_text, placeofsupply)
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          fetch_purchaseBill_dataSuccess(dispatch, response, search_text, placeofsupply)
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        fetch_purchaseBill_dataFail(dispatch, getCatchErrorMessage(error), search_text, placeofsupply)
        dispatch({ type: LOADING_STATE, state: false })
      })
  }
}
const fetch_purchaseBill_dataFail = (dispatch, response, search_text, placeofsupply) => {
  dispatch({
    type: PUR_BILL_SERVICE_FAIL,
    payload: response,
    search_text: search_text,
    placeofsupply: placeofsupply,
  })
}
const fetch_purchaseBill_dataSuccess = (dispatch, user, search_text, placeofsupply) => {
  dispatch({
    type: PUR_BILL_SERVICE_SUCCESS,
    payload: user,
    search_text: search_text,
    placeofsupply: placeofsupply,
  })
}

export const clearLastPageData = () => {
  return (dispatch) => {
    dispatch({
      type: LAST_PAGE_UPDATE,
      lasttype: "",
      lastaction: "",
      lasttime: new Date().getTime(),
      lastdata: "",
    })
  }
}

export const fetchAvailStock = ({ goDownSelectedValue, DetailGrid, formData, set_Value, gridClearField, onComplete }) => {
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalPoint")
  return (dispatch) => {
    var from_date = `${getstartyear()}-04-01`
    var to_date = `${getendyear()}-03-31`
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("report_id", "96")
    data.append("to_date", to_date)
    data.append("from_date", from_date)
    data.append("limit", "All")
    data.append("page", 0)
    data.append("offset", 0)
    data.append("code", "")
    data.append("subgroup", "")
    data.append("goDownSelectedValue", goDownSelectedValue)
    data.append("itemgroupcode", "")
    data.append("subgrouplevel2", "")
    data.append("type", 0)
    data.append("include_zero", 0)
    data.append("other_type", "summary")

    if (!isblank(formData.remove_type)) {
      data.append("remove_type", formData.remove_type)
    }
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "getAllReport" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          const __cust_pcsField = response.data.result.custom_fields_data.find((e) => e.name === "__cust_pcs")
          if (__cust_pcsField) {
            __cust_pcsField.decimalPoint = convertIntif(__cust_pcsField.decimalPoint)
          }
          const setCustomfields = (i, from) => {
            const customfields = {}
            response.data.result.custom_fields_data.forEach((e) => {
              if (e.common) {
                if (parseInt(e.dataType) === cF.Dropdown || parseInt(e.dataType) === cF.Creatable_Dropdown) {
                  if (from === "Records") {
                    customfields[e.name] = i[e.name.replace("__cust", "__custCode")]
                  } else {
                    customfields[e.name] = i[e.name]
                  }
                } else {
                  customfields[e.name] = i[e.name]
                }
              }
            })
            return customfields
          }
          var newData = []
          if (!isblank(response.data.result.records) && response.data.result.records.length > 0) {
            response.data.result.records.forEach((e) => {
              const updated = { ...e }
              updated.itemSelectedValue = JSON.parse(JSON.stringify(updated.code))
              updated.code = JSON.stringify({
                code: updated.code,
                ...setCustomfields(updated, "Records"),
              })
              var addInArray = false
              DetailGrid.forEach((i) => {
                const code = JSON.stringify({
                  code: i.itemSelectedValue,
                  ...setCustomfields(i),
                })
                if (code === updated.code) {
                  updated.balance = parseFloat(getNumberonlyDecimal(updated.balance, inventory_decimalpoint)) + parseFloat(getNumberonlyDecimal(i.__cust_SelectedQty, inventory_decimalpoint))
                  if (__cust_pcsField) {
                    updated.__cust_pcsBalance =
                      parseFloat(getNumberonlyDecimal(updated.__cust_pcsBalance, __cust_pcsField.decimalPoint)) + parseFloat(getNumberonlyDecimal(i.__cust_Selectedpcs, __cust_pcsField.decimalPoint))
                    updated.__cust_pcsOut =
                      parseFloat(getNumberonlyDecimal(updated.__cust_pcsOut, __cust_pcsField.decimalPoint)) - parseFloat(getNumberonlyDecimal(i.__cust_Selectedpcs, __cust_pcsField.decimalPoint))
                  }
                  addInArray = true
                }
              })
              if (addInArray || parseFloat(updated.balance) > 0) {
                newData.push(updated)
              }
            })
          }

          const fieldname = {}
          fieldname.invoice_type = "live_stock"
          fieldname.other_Detail = response.data.result.custom_fields_data
          var maxWidth = getWindowWidth()

          const drawerData = {
            result: newData,
            set_Value: set_Value,
            gridClearField: gridClearField,
            Selected: DetailGrid.map((e) =>
              JSON.stringify({
                code: e.itemSelectedValue,
                ...setCustomfields(e),
              })
            ),
            formData,
          }

          if (onComplete) {
            dispatch({ type: LOADING_STATE, state: false })
            onComplete(drawerData, fieldname)
          } else {
            pushDrawer({ title: "Select Pen Order", data: drawerData, fieldname: fieldname, position: maxWidth <= 1248 ? "bottom" : "right", width: maxWidth <= 1248 ? maxWidth : 1248, dispatch })
            dispatch({ type: LOADING_STATE, state: false })
          }
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}
export const fetchPenPurchaseInvoice = ({ lot_code, rowsPerPage, page, order, orderBy, search_text, Selected, disabled, other_Detail, other_type, drawerIndex, set_Value, gridClearField }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("type", "pen_purchaseinvoice_detail")
    data.append("lot_code", lot_code)
    data.append("offset", page * rowsPerPage)
    data.append("limit", rowsPerPage)
    data.append("sort_order", order)
    data.append("sort_by", orderBy)
    data.append("search_text", search_text)

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: process.env.REACT_APP_BASE_URL + "/user/getalllist",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false })
        } else {
          const data = {}
          data.result = response.data.result
          data.totalcount = response.data.total_count
          data.set_Value = set_Value
          data.gridClearField = gridClearField
          data.invoice_code = lot_code
          data.webServiceData = {}
          data.webServiceData.lot_code = lot_code
          data.webServiceData.page = page
          data.webServiceData.rowsPerPage = rowsPerPage
          data.webServiceData.order = order
          data.webServiceData.orderBy = orderBy
          data.webServiceData.search_text = search_text
          data.Selected = Selected
          data.disabled = disabled ? disabled : []
          const fieldname = {}
          fieldname.invoice_type = "purchase"
          fieldname.other_Detail = other_Detail
          if (other_type === "list_update") {
            updateDrawer({ title: "Select Pen Order", data, fieldname, dispatch, index: drawerIndex })
          } else {
            pushDrawer({ title: "Select Pen Order", data: data, fieldname: fieldname, position: "bottom", dispatch })
          }
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const getProcessLotHistory = ({ lot_code }) => {
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalPoint")
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("report_id", "80")
    data.append("lot_number", lot_code)

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "getAllReport" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          const getCustomFields = (detailGrid) => {
            let returnValue = []
            if (!isblank(detailGrid)) {
              Object.keys(detailGrid).forEach((e) => {
                if (e.indexOf("__cust_") > -1) {
                  let custom_fields_data = response.data.result.custom_fields_data.find((i) => i.name === e)
                  if (!isblank(custom_fields_data)) {
                    returnValue = [...returnValue, custom_fields_data]
                  }
                }
              })
            }
            return returnValue
          }

          response.data.result.records = response.data.result.records.map((item) => {
            const updatedItem = { ...item }
            updatedItem.tot_qty = lodashSum(lodashMap(updatedItem.DetailGrid, (d) => parseFloat(getNumberonlyDecimal(d.qty, inventory_decimalpoint))))
            updatedItem.custom_fields_data = getCustomFields(updatedItem.DetailGrid[0])
            return updatedItem
          })

          pushDrawer({ title: "Lot History", data: response.data.result, position: "bottom", dispatch })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

/*
export const updateOutwardFlag = (code) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    var formdata = {}
    formdata.update_code = code
    const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("type", "outward_flag")
    data.append("form_data", formdata2)
    data.append("action", "update")

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: process.env.REACT_APP_BASE_URL + "/user/manage_master",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: OUTWARD_FLAG_UPDATED,
            payload: code,
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}*/

export const fetch_finish_stockData = ({
  godown,
  functions,
  Selected,
  disabled,
  code,
  drawerIndex,
  rowsPerPage,
  page,
  order,
  orderBy,
  other_type1,
  search_text,
  otherDetail,
  from_type,
  salesCustomField,
  onEFComplete,
  invoice_code = "",
  invoice_notetype,
  getValues,
  set_Value,
  gridClearField,
  existingData,
  oldDetailGrid,
  party_code = "",
  viewType,
  showRate = false,
  InvoiceDate = "",
  invoice_type = "",
  other_type = "",
}) => {
  const { onConfirm, onComplete, showdialog } = functions
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalPoint")
  const tax = parseInt(getAccountSitesetting("tax"))

  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }

    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    if (from_type === "job_process_invoice") {
      data.append("type", "job_inward_find")
    } else {
      data.append("type", "finish_stock_find")
    }

    if (!isblank(otherDetail) && otherDetail === "Barcode_scan") {
      data.append("barcode", Selected)
    }

    if (!isblank(invoice_code)) {
      data.append("invoice_code", invoice_code)
    }
    if (!isblank(invoice_notetype)) {
      data.append("invoice_notetype", invoice_notetype)
    }
    if (!isblank(viewType)) {
      data.append("viewType", viewType)
    }

    data.append("code", code)

    if (!isBlankCode(party_code)) {
      data.append("party_code", party_code)
    } else {
      data.append("party_code", "")
    }
    data.append("offset", page * rowsPerPage)
    data.append("limit", rowsPerPage)
    data.append("sort_order", order)
    data.append("sort_by", orderBy)
    data.append("other_type", "list")
    data.append("search_text", search_text)
    data.append("from_type", from_type)
    if (existingData) {
      data.append("existingData", existingData)
    }

    if (!isBlankCode(godown)) {
      data.append("godown", godown)
    } else {
      data.append("godown", "")
    }
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "finish_stock" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          if (!isblank(onConfirm) && !isblank(otherDetail) && otherDetail === "Barcode_scan") {
            if (response.data.meta.message.includes("barcode not found")) {
              const barcodeArray = response.data.meta.message
                .replace("barcode not found ", "")
                .split(",")
                .map((e) => trim(e))

              const call_fun = () => {
                onConfirm(barcodeArray)
              }

              dispatch({
                type: CONFIRM_DIALOG,
                payload: true,
                confirm: false,
                message: `continue? ${response.data.meta.message}`,
                title: "Barcode not found",
                form: "",
                meta: barcodeArray,
                onConfirm: call_fun,
              })
            } else {
              dispatch({
                type: SNACKBAR_STATE,
                payload: true,
                message: response.data.meta.message,
                snackbarType: "error",
              })
            }
          } else {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: response.data.meta.message,
              snackbarType: "error",
            })
          }

          dispatch({ type: LOADING_STATE, state: false })
        } else {
          const fieldname = {}
          if (from_type === "job_process_invoice") {
            fieldname.invoice_type = "job_inward_find"
          } else {
            fieldname.invoice_type = "select_finish_stock"
          }
          const data = {}
          data.result = response.data.result
          data.totalcount = response.data.total_count
          data.webServiceData = {}
          data.webServiceData.page = page
          data.webServiceData.rowsPerPage = rowsPerPage
          data.webServiceData.order = order
          data.webServiceData.orderBy = orderBy
          data.webServiceData.search_text = search_text
          data.webServiceData.code = code
          data.webServiceData.godown = godown
          data.webServiceData.salesCustomField = salesCustomField
          data.Selected = Selected
          data.disabled = disabled ? disabled : []
          data.from_type = from_type
          data.getValues = getValues
          data.set_Value = set_Value
          data.existingData = existingData
          data.formData = { showRate, InvoiceDate, invoice_type, other_type, invoice_code, party_code }

          if (oldDetailGrid) {
            data.oldDetailGrid = oldDetailGrid
          }

          data.gridClearField = gridClearField
          data.ddcustomfield = salesCustomField

          if (from_type === "job_process_invoice") {
            //itemQty
            data.result = data.result.map((item) => {
              const updatedItem = { ...item }
              var itemName = []
              var penqty = 0
              var qty = 0
              updatedItem.DetailGrid = updatedItem.DetailGrid.map((it, index) => {
                const updtd = { ...it }
                //updtd.qty = it.itemQty
                penqty = penqty + parseFloat(updtd.penqty)
                qty = qty + parseFloat(updtd.qty)
                if (index === 0 || itemName.filter((item1) => item1 === updtd.itemname).length === 0) {
                  itemName.push(updtd.itemname)
                }
                return updtd
              })
              itemName.forEach((item1, index) => {
                if (index === 0) {
                  updatedItem.itemName = item1
                } else {
                  updatedItem.itemName = `${updatedItem.itemName},${item1}`
                }
              })
              updatedItem.penqty = penqty
              updatedItem.qty = qty
              return updatedItem
            })
          } else {
            //select_finish_stock
            //itemQty

            let new_grid = []
            data.result.forEach((item) => {
              let master = {}
              Object.keys(item).forEach((key) => {
                if (key !== "DetailGrid") {
                  if (key === "code") {
                    master["mcode"] = item[key]
                  }
                  master[key] = item[key]
                }
              })
              item.DetailGrid.forEach((it, index) => {
                new_grid.push({
                  ...master,
                  ...it,
                })
              })
            })
            data.result = new_grid
          }

          if (otherDetail === "list_update") {
            updateDrawer({ title: "Select Pen Order", data, fieldname, position: "bottom", dispatch, index: drawerIndex })
          } else if (otherDetail === "Barcode_scan") {
            let qtd = inventory_decimalpoint

            var DetailGrid = []
            var invoice_DetailCustomfield = []
            var uniqueArray = []

            if (from_type === "sales_invoice" || from_type === "sales_return") {
              data.result.forEach((it, sIndex) => {
                var uniqueKey = `${it.itemSelectedValue}`
                if (invoice_DetailCustomfield.length > 0) {
                  // if __cust_pcs exist then we are not merging the items
                  if (invoice_DetailCustomfield.filter((e) => e.name === "__cust_pcs").length > 0) {
                    uniqueKey = `${sIndex}_${uniqueKey}`
                  }
                  invoice_DetailCustomfield.forEach((e) => {
                    if (e.name !== "__cust_pcs" && it.hasOwnProperty(e.name) && !isblank(it[e.name])) {
                      uniqueKey = `${uniqueKey}_${it[e.name]}`
                    }
                  })
                }
                // CLUBING ITEM REMOVE FROM SALES INVOCIE FOR NOW FOR FINISHED STCK
                //uniqueKey = `${uniqueKey}`
                if (uniqueArray.filter((e) => e === uniqueKey).length > 0) {
                  DetailGrid = DetailGrid.map((t) => {
                    const updatedItem = { ...t }
                    if (updatedItem.__cust_uniqueKey === uniqueKey) {
                      updatedItem.__cust_finishDetail = [
                        ...updatedItem.__cust_finishDetail,
                        {
                          detailId: it.detail_id,
                          penQty: it.qty,
                          masterId: it.mcode,
                          __cust_pcs: it.pen_pcs,
                          barcode: it.barcode,
                        },
                      ]

                      updatedItem.__cust_finsish_stock_ori_qty = amount_formatDecimal(numForCalc(updatedItem.__cust_finsish_stock_ori_qty, qtd) + numForCalc(it.qty, qtd), qtd)
                      updatedItem.__cust_finsish_stock_pen_qty = amount_formatDecimal(numForCalc(updatedItem.__cust_finsish_stock_pen_qty, qtd) + numForCalc(it.penqty, qtd), qtd)
                      //if (convertIntif(auto_fill_available_stock) === 1) {

                      if (from_type === "sales_return") {
                        updatedItem.itemQty = amount_formatDecimal(numForCalc(updatedItem.__cust_finsish_stock_ori_qty) - numForCalc(updatedItem.__cust_finsish_stock_pen_qty), qtd)
                      } else {
                        updatedItem.itemQty = updatedItem.__cust_finsish_stock_pen_qty
                      }

                      updatedItem.__cust_pcs = amount_formatDecimal(updatedItem.pen_pcs, 0)
                    }

                    /* } else {
                      updatedItem.__cust_pcs = 0
                    }*/
                    return updatedItem
                  })
                } else {
                  var newItem = { ...it }
                  newItem.itemQty = it.penqty
                  newItem.__cust_finishDetail = [
                    {
                      detailId: it.detail_id,
                      penQty: it.qty,
                      masterId: it.mcode,
                      __cust_pcs: it.pen_pcs,
                      barcode: it.barcode,
                    },
                  ]

                  //newItem.itemSelectedValue = it.itemcode
                  newItem.__cust_finsish_stock_ori_qty = amount_formatDecimal(it.qty, qtd)
                  newItem.__cust_finsish_stock_pen_qty = amount_formatDecimal(it.penqty, qtd)
                  //if (convertIntif(auto_fill_available_stock) === 1) {

                  if (from_type === "sales_return") {
                    newItem.itemQty = amount_formatDecimal(numForCalc(newItem.__cust_finsish_stock_ori_qty) - numForCalc(newItem.__cust_finsish_stock_pen_qty), qtd)
                  } else {
                    newItem.itemQty = newItem.__cust_finsish_stock_pen_qty
                  }

                  newItem.__cust_pcs = amount_formatDecimal(newItem.pen_pcs, 0)
                  /*} else {
                    newItem.__cust_pcs = 0
                  }*/
                  delete newItem.code
                  delete newItem.detail_id
                  delete newItem.itemcode
                  delete newItem.penqty
                  delete newItem.processcode
                  delete newItem.qty
                  delete newItem.pen_pcs

                  newItem.__cust_uniqueKey = uniqueKey
                  DetailGrid.push(newItem)
                  uniqueArray.push(uniqueKey)
                }
              })

              DetailGrid = DetailGrid.map((it) => {
                const updatedItem = { ...it }
                updatedItem.itemQty = amount_formatDecimal(updatedItem.itemQty, qtd)
                if (tax === 1) {
                  var value = functions.dditem.find((item1) => item1.code === updatedItem.itemSelectedValue)
                  if (!isblank(value) && !isblank(value.taxschemecode)) {
                    var taxschemeavailable = value.taxschemecode
                    if (taxschemeavailable === undefined) {
                      taxschemeavailable = null
                    }
                    updatedItem.taxSelectedValue = taxschemeavailable
                    updatedItem.taxReasonSelectedValue = value.tax_reason
                    // get tax detail from ddtaxgroup
                    if (taxschemeavailable !== null) {
                      updatedItem.taxCalculation = cloneObj(functions.ddtaxgroup[taxschemeavailable])
                    } else {
                      updatedItem.taxCalculation = null
                    }
                  } else {
                    updatedItem.taxSelectedValue = null
                    updatedItem.taxReasonSelectedValue = null
                    updatedItem.taxCalculation = null
                  }
                }
                // TODO: BOX for rakhi on condition only

                if (it.__cust_box_pcs) {
                  updatedItem.__cust_box = it.__cust_finishDetail.length
                  updatedItem.itemQty = toFixedFn(parseFloat(getNumberonly(it.__cust_box_pcs) * getNumberonly(updatedItem.__cust_box)), qtd)
                }

                //updatedItem.itemRateIncTax = "0.00"
                //updatedItem.itemRate = "0.00"
                return updatedItem
              })
              DetailGrid = setFormatOfInvoiceDetailGrid({ DetailGrid, dditem: functions.dditem })
              let reduxForm = from_type === "sales_invoice" ? "SIForm" : "SalesRetForm"
              dispatch(functions.change(reduxForm, "DetailGrid", DetailGrid))
              dispatch(functions.change(reduxForm, "__cust_select_type", "finish_stock"))

              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: "finish_stock",
                lastaction: "__cust_select_type",
                lasttime: new Date().getTime(),
                lastdata: DetailGrid.length,
              })
            } else if (
              from_type === "delivery_challan" ||
              from_type === "manage_lot_master" ||
              from_type === "packing_slip" ||
              from_type === "stock_transfer_inward" ||
              from_type === "stock_transfer_outward"
            ) {
              data.result.forEach((it, sIndex) => {
                var uniqueKey = !isblank(it.itemcode) ? `${it.itemSelectedValue}` : `${it.itemSelectedValue}`

                if (from_type === "delivery_challan" || from_type === "manage_lot_master" || from_type === "stock_transfer_inward" || from_type === "stock_transfer_outward") {
                  uniqueKey = `${uniqueKey}_${sIndex}`
                }
                if (from_type === "stock_transfer_inward" || from_type === "stock_transfer_outward" || from_type === "process_transfer_inward" || from_type === "packing_slip") {
                  uniqueKey = `${uniqueKey}_${it.code}`
                }

                if (invoice_DetailCustomfield.length > 0) {
                  // if __cust_pcs exist then we are not merging the items
                  if (invoice_DetailCustomfield.filter((e) => e.name === "__cust_pcs").length > 0) {
                    uniqueKey = `${sIndex}_${uniqueKey}`
                  }
                  invoice_DetailCustomfield.forEach((e) => {
                    if (e.name !== "__cust_pcs" && it.hasOwnProperty(e.name) && !isblank(it[e.name])) {
                      uniqueKey = `${uniqueKey}_${it[e.name]}`
                    }
                  })
                }
                // CLUBING ITEM REMOVE FROM SALES INVOCIE FOR NOW FOR FINISHED STCK
                //uniqueKey = `${uniqueKey}`
                if (uniqueArray.filter((e) => e === uniqueKey).length > 0) {
                  DetailGrid = DetailGrid.map((t) => {
                    const updatedItem = { ...t }
                    if (updatedItem.__cust_uniqueKey === uniqueKey) {
                      updatedItem.__cust_finishDetail = [
                        ...updatedItem.__cust_finishDetail,
                        {
                          detailId: it.detail_id,
                          penQty: it.qty,
                          masterId: it.mcode,
                          __cust_pcs: it.pen_pcs,
                          barcode: it.barcode,
                        },
                      ]
                    }
                    updatedItem.__cust_finsish_stock_ori_qty = amount_formatDecimal(numForCalc(updatedItem.__cust_finsish_stock_ori_qty, qtd) + numForCalc(it.qty, qtd), qtd)
                    updatedItem.__cust_finsish_stock_pen_qty = amount_formatDecimal(numForCalc(updatedItem.__cust_finsish_stock_pen_qty, qtd) + numForCalc(it.penqty, qtd), qtd)
                    //if (convertIntif(auto_fill_available_stock) === 1) {
                    updatedItem.itemQty = updatedItem.__cust_finsish_stock_pen_qty
                    updatedItem.__cust_pcs = amount_formatDecimal(updatedItem.pen_pcs, 0)
                    /* } else {
                      updatedItem.__cust_pcs = 0
                    }*/
                    return updatedItem
                  })
                } else {
                  var newItem = { ...it }
                  newItem.itemSelectedValue = it.itemcode
                  newItem.itemQty = it.penqty
                  newItem.__cust_finishDetail = [
                    {
                      detailId: it.detail_id,
                      penQty: it.qty,
                      masterId: it.mcode,
                      __cust_pcs: it.pen_pcs,
                      barcode: it.barcode,
                    },
                  ]

                  //newItem.itemSelectedValue = it.itemcode
                  newItem.__cust_finsish_stock_ori_qty = amount_formatDecimal(it.qty, qtd)
                  newItem.__cust_finsish_stock_pen_qty = amount_formatDecimal(it.penqty, qtd)
                  //if (convertIntif(auto_fill_available_stock) === 1) {
                  newItem.itemQty = newItem.__cust_finsish_stock_pen_qty
                  newItem.__cust_pcs = amount_formatDecimal(newItem.pen_pcs, 0)
                  /*} else {
                    newItem.__cust_pcs = 0
                  }*/
                  delete newItem.code
                  delete newItem.detail_id
                  delete newItem.itemcode
                  delete newItem.penqty
                  delete newItem.processcode
                  delete newItem.qty
                  delete newItem.pen_pcs

                  newItem.__cust_uniqueKey = uniqueKey
                  DetailGrid.push(newItem)
                  uniqueArray.push(uniqueKey)
                }
              })

              DetailGrid = setFormatOfInvoiceDetailGrid({ DetailGrid, dditem: functions.dditem })
              if (set_Value) {
                if (from_type === "stock_transfer_outward") {
                  set_Value("outwardDetail", [])
                  set_Value("outwardDetail", DetailGrid)
                  set_Value("__cust_select_type", "Finish_stock")
                  dispatch({
                    type: LAST_PAGE_UPDATE,
                    lasttype: from_type,
                    lastaction: "update_grid",
                    lasttime: new Date().getTime(),
                    lastdata: { gridType: data.formData.other_type, data: DetailGrid },
                  })
                } else if (from_type === "stock_transfer_inward") {
                  set_Value("inwardDetail", DetailGrid)
                  set_Value("__cust_inward_select_type", "Finish_stock")
                  dispatch({
                    type: LAST_PAGE_UPDATE,
                    lasttype: from_type,
                    lastaction: "update_grid",
                    lasttime: new Date().getTime(),
                    lastdata: { gridType: data.formData.other_type, data: DetailGrid },
                  })
                } else if (from_type === "process_transfer_inward") {
                  set_Value("PackingDetailGrid", DetailGrid)
                  set_Value("__cust_inward_select_type", "Finish_stock")
                } else if (from_type === "delivery_challan" || from_type === "manage_lot_master") {
                  // set_Value("DetailGrid", [])
                  // set_Value("DetailGrid", DetailGrid)
                  set_Value("__cust_select_type", "Finish_stock")
                  dispatch({
                    type: LAST_PAGE_UPDATE,
                    lasttype: from_type,
                    lastaction: "update_grid",
                    lasttime: new Date().getTime(),
                    lastdata: { data: DetailGrid },
                  })
                } else {
                  //set_Value("DetailGrid", [])
                  //set_Value("DetailGrid", DetailGrid)
                  set_Value("__cust_select_type", "Finish_stock")
                  dispatch({
                    type: LAST_PAGE_UPDATE,
                    lasttype: from_type,
                    lastaction: "update_grid",
                    lasttime: new Date().getTime(),
                    lastdata: { data: DetailGrid },
                  })
                }
                // this.props.setHookFormData("totalcount", DetailGrid.length)

                dispatch(showdialog({ yesno: false }))
              } else {
                if (from_type === "stock_transfer_outward") {
                  dispatch(functions.change("StockTransferform", "outwardDetail", DetailGrid))
                  dispatch(functions.change("StockTransferform", "__cust_select_type", "Finish_stock"))
                } else if (from_type === "stock_transfer_inward") {
                  dispatch(functions.change("StockTransferform", "inwardDetail", DetailGrid))
                  dispatch(functions.change("StockTransferform", "__cust_inward_select_type", "Finish_stock"))
                } else if (from_type === "process_transfer_inward") {
                  dispatch(functions.change("ProcessTransferForm", "PackingDetailGrid", DetailGrid))
                  dispatch(functions.change("ProcessTransferForm", "__cust_inward_select_type", "Finish_stock"))
                } else if (from_type === "delivery_challan" || from_type === "manage_lot_master") {
                  dispatch(functions.change("OwForm", "DetailGrid", DetailGrid))
                  dispatch(functions.change("OwForm", "__cust_select_type", "Finish_stock"))
                } else {
                  dispatch(functions.change("IWForm", "DetailGrid", DetailGrid))
                  dispatch(functions.change("IWForm", "__cust_select_type", "Finish_stock"))
                }
              }
            }
            dispatch(showdialog({ yesno: false }))
            dispatch({ type: LOADING_STATE, state: false })
          } else if (otherDetail === "list" && from_type === "kanban" && other_type1 !== "report") {
            onComplete(data)
            dispatch({ type: LOADING_STATE, state: false })
          } else if (other_type1 === "report") {
            onEFComplete(data)
            dispatch({ type: LOADING_STATE, state: false })
          } else {
            if (onComplete) {
              dispatch({ type: LOADING_STATE, state: false })
              onComplete(data, fieldname)
            } else {
              pushDrawer({ title: "Select Pen Order", data: data, fieldname, position: "bottom", dispatch })
              dispatch({ type: LOADING_STATE, state: false })
            }
          }
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

/*export const updateInwardFlag = (code, functions) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    const formdata = {}
    formdata.update_code = code
    const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", "inward_flag")
    data.append("form_data", formdata2)
    data.append("action", "update")

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: post_form_url({ formtype: "inward_flag" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: INWARD_FLAG_UPDATED,
            payload: code,
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}
 */

const getuniqueArray = (array) => {
  var temp = []
  array.forEach((item, index) => {
    if (temp.filter((it) => it === item).length < 1) {
      temp.push(item)
    }
  })
  return temp
}

export const clearCompanyData = ({ code, type }) => {
  return (dispatch) => {
    let formdata = JSON.stringify(code)
    let data = new FormData()
    data.append("type", "clear_company")
    data.append("form_data", formdata)
    data.append("action", "delete")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({
        type: "clearCompany",
        props: { other_type: type },
      }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: CONFIRM_DIALOG,
            payload: false,
            confirm: false,
            title: "",
            form: "",
            message: "",
            meta: "",
          })
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: "Company data cleared",
            snackbarType: "success",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const runBot = ({ code, type, functions }) => {
  return (dispatch) => {
    let formdata = JSON.stringify(code)
    let data = new FormData()
    data.append("type", type)
    data.append("code", formdata)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({
        type: "runBot",
        props: { other_type: type },
      }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const userAssign = (functions) => {
  const { form_data } = functions
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    dispatch({ type: LOADING_STATE, state: true })
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    const formdata2 = Array.isArray(form_data) ? form_data.map(JSON.stringify) : JSON.stringify(form_data)
    let data = new FormData()
    data.append("type", "user_assign_orders")
    data.append("action", "insert")
    data.append("form_data", formdata2)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "user_assign" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch(showdialog({ yesno: false }))
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_STATE, state: false })
        if (axios.isCancel(error)) {
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        }
      })
  }
}

export const preYearUnadjustOpeningBillsAccount = ({ other_type, form_action, functions }) => {
  const { form_data } = functions
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    dispatch({ type: LOADING_STATE, state: true })
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    const formdata2 = Array.isArray(form_data) ? form_data.map(JSON.stringify) : JSON.stringify(form_data)
    let data = new FormData()
    data.append("type", "preYearUnadjustOpeningBillsAccount")
    data.append("action", form_action)
    data.append("other_type", other_type)
    data.append("form_data", formdata2)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "prev_year_unadjust" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else if (response.data.meta.code === 403) {
          dispatch({
            type: CONFIRM_DIALOG,
            payload: true,
            confirm: false,
            message: "",
            title: "Message",
            form: "Error List",
            meta: response.data.meta.message,
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch(rightdrawerstate({ open: false }))

          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })

          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "preYearUnadjustOpeningBillsAccount",
            lastaction: form_action,
            lasttime: new Date().getTime(),
            lastdata: { data: form_data, code: response.data.insert_code, other_type },
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_STATE, state: false })
        if (axios.isCancel(error)) {
        } else {
          postErrorOnException(error)

          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        }
      })
  }
}

export const clear_sales_order = (functions) => {
  const { form_data } = functions
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    dispatch({ type: LOADING_STATE, state: true })
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    const formdata2 = Array.isArray(form_data) ? form_data.map(JSON.stringify) : JSON.stringify(form_data)
    let data = new FormData()
    data.append("type", "multi_order_closed")
    data.append("action", "update")
    data.append("form_data", formdata2)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "clear_sales_order" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch(showdrawerdialog({ yesno: false }))
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_STATE, state: false })
        if (axios.isCancel(error)) {
        } else {
          postErrorOnException(error)

          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        }
      })
  }
}

export const fetch_common_data = ({
  companycode,
  rowsPerPage,
  page,
  order,
  orderBy,
  search_text,
  type,
  filter_by,
  other_type,
  is_prev_year = false,
  from_date = "",
  to_date = "",
  transactionType = "",
  ewaybillstatus = "",
  amountThreshold = "",
  invoice_type_detail,
  functions,
}) => {
  const pagination_size = getAccountSitesetting("pagination_size")
  if (isblank(rowsPerPage)) {
    rowsPerPage = pagination_size
  }
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: COMMON_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("offset", page * rowsPerPage)
    data.append("type", type) //"partygroup"
    data.append("limit", rowsPerPage)
    data.append("sort_order", order)
    data.append("sort_by", orderBy)
    data.append("search_text", search_text)
    if (type === "customerList" || type === "supplierList" || type === "item") {
      data.append("filter_by", filter_by)
    }
    if (!isblank(other_type)) {
      data.append("other_type", other_type)
    }
    if (type === "companyList") {
      data.append("account_type", "all")
    }
    if (is_prev_year) {
      data.append("is_prev_year", "1")
    }
    if (from_date) {
      data.append("from_date", from_date)
    }
    if (to_date) {
      data.append("to_date", to_date)
    }
    if (transactionType) {
      data.append("transactionType", transactionType)
    }
    if (ewaybillstatus) {
      data.append("ewaybillstatus", ewaybillstatus)
    }
    if (amountThreshold) {
      data.append("amountThreshold", amountThreshold)
    }

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          commonServiceFail({ dispatch, response: response.data.meta.message, rowsPerPage, page, order, orderBy, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          if (type === "draft") {
            response.data.result = response.data.result.map((e) => {
              const updatedItem = {
                detail: e.detail,
                code: e.code,
                newCode: e.code,
                type: e.type,
                draftReason: e.title,
              }
              if (!isblank(e.search_data)) {
                const search_data = JSON.parse(e.search_data)
                Object.keys(search_data).forEach((e) => {
                  updatedItem[e] = search_data[e]
                })
              }
              return updatedItem
            })
          }

          commonServiceSuccess({ dispatch, user: response, rowsPerPage, page, order, orderBy, search_text, type, invoice_type_detail })
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: type,
            lastaction: "list",
            lasttime: new Date().getTime(),
            lastdata: response,
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          commonServiceFail({ dispatch, response: getCatchErrorMessage(error), rowsPerPage, page, order, orderBy, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const commonServiceSuccess = ({ dispatch, user, rowsPerPage, page, order, orderBy, search_text, type, invoice_type_detail }) => {
  dispatch({
    type: COMMON_SERVICE_SUCCESS,
    payload: user,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
    formtype: type,
    invoice_type_detail,
  })
}

const commonServiceFail = ({ dispatch, response, rowsPerPage, page, order, orderBy, search_text }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })
  dispatch({
    type: COMMON_SERVICE_FAIL,
    payload: response,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
  })
}
