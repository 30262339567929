import { list_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { removeDrawer } from "./CommonActionsV2"
import { LAST_PAGE_UPDATE, LOADING_STATE, NEW_ORGANIZATION_INSERTED, SNACKBAR_STATE } from "./types"
import { getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"

export const post_signup_data = ({ formtype, formaction, formdata, functions }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_STATE, state: true })
    if (formaction !== "") {
      if (typeof getAxiosCancelToken() != typeof undefined) {
        getAxiosCancelToken().cancel("Operation canceled due to new request.")
      }
      // save the new request for cancellation
      setAxiosCancelToken(axios.CancelToken.source())
      const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
      let data = new FormData()
      data.append("type", formtype)
      data.append("action", formaction)
      data.append("form_data", formdata2)
      axios({
        method: "post",
        url: list_url({ type: "sign_up" }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        auth: {
          username: "",
          password: "",
        },
        data: data,
        cancelToken: getAxiosCancelToken().token,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          if (response.data.meta.code === 401) {
            signupServiceFail({ dispatch, response: response.data.meta.message })
            dispatch({ type: LOADING_STATE, state: false })
          } else {
            if (formdata.step === 1) {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: "signup",
                lastaction: "email",
                lasttime: new Date().getTime(),
                lastdata: { insert_code: response.data.result.insert_code },
              })
            } else if (formdata.step === 2) {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: "signup",
                lastaction: "otp",
                lasttime: new Date().getTime(),
              })
            } else if (formdata.step === 4) {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: "signup",
                lastaction: "resend",
                lasttime: new Date().getTime(),
              })
            } else {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: "signup",
                lastaction: "success",
                lasttime: new Date().getTime(),
              })
            }
            dispatch({ type: LOADING_STATE, state: false })
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            dispatch({ type: LOADING_STATE, state: false })
          } else {
            postErrorOnException(error)
            signupServiceFail({ dispatch, response: getCatchErrorMessage(error) })
            dispatch({ type: LOADING_STATE, state: false })
          }
        })
    }
  }
}

export const createAndLinkNewCompany = ({ formtype, formaction, formdata, functions }) => {
  return (dispatch) => {
    dispatch({ type: LOADING_STATE, state: false, sidebarstate: true })
    if (formaction !== "") {
      if (typeof getAxiosCancelToken() != typeof undefined) {
        getAxiosCancelToken().cancel("Operation canceled due to new request.")
      }
      // save the new request for cancellation
      setAxiosCancelToken(axios.CancelToken.source())
      const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
      let data = new FormData()
      data.append("type", formtype)
      data.append("action", formaction)
      data.append("form_data", formdata2)
      axios.defaults.headers.common = getheaders()
      axios({
        method: "post",
        url: list_url({ type: "sign_up" }),
        data: data,
        cancelToken: getAxiosCancelToken().token,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          if (response.data.meta.code === 401) {
            signupServiceFail({ dispatch, response: response.data.meta.message })
            dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
          } else {
            removeDrawer(dispatch)
            dispatch({
              type: NEW_ORGANIZATION_INSERTED,
              payload: formdata,
            })
            dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
          } else {
            postErrorOnException(error)
            signupServiceFail({ dispatch, response: getCatchErrorMessage(error) })
            dispatch({ type: LOADING_STATE, state: false, sidebarstate: false })
          }
        })
    }
  }
}

const signupServiceFail = ({ dispatch, response }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })
}
