import { Collapse, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import FinalBooksLogo from "@project/components/Images/FinalBooks_Logo.png"
import { getmenuicon } from "@project/components/materialUiFunctions"
import { setStorage } from "@project/components/sessionStoreFunctions"
import { commonSiteSetting } from "@project/components/utilityFunctions"
import { getCheckBoxValue, isblank, replaceAllFn } from "@project/sharedcomponents/utilityFunctions"
import React, { useEffect } from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { useDispatch, useSelector } from "react-redux"
import { Pages_data_clear, common_post_form_data, gotoPage, snackbarstate } from "../../Actions"
import { checkRightsAndSiteSetting, getAccountSessionUserData, getAllReports, getmenukey } from "../../Lib/commonfunctions"

const useStyles = makeStyles((theme) => ({
  menu_nav: {
    width: 275,
  },
}))

const NavigationMenu = ({ menudrawer, toggleMenuDrawer }) => {
  const [openIndex, setopenIndex] = React.useState(-1)
  const [getMyStore, setgetMyStore] = React.useState(false)
  const [pageMenu, setpageMenu] = React.useState(false)
  const CommonReducer = useSelector((state) => state.CommonReducer)
  const classes = useStyles()
  const dispatch = useDispatch()
  const { customPages, accountSiteSettingKeyValue } = useSelector((state) => state.AuthReducer)
  const { menu_data, all_menu, lastpagetype, lastpagetime, lastpageaction, last_menu_key } = CommonReducer
  const [selectedmenu, setselectedmenu] = React.useState(last_menu_key)

  const SiteSetting = (key) => {
    return commonSiteSetting({ key, settingData: accountSiteSettingKeyValue })
  }

  useEffect(() => {
    if (lastpagetype === "sync" && lastpageaction === "completed") {
      setopenIndex(-1)
      setgetMyStore(false)
      setpageMenu(false)
      setselectedmenu(0)
    }
  }, [lastpagetype, lastpagetime, lastpageaction])

  const allPages = customPages.map((item) => {
    const updtaedItem = {
      ...item,
      id: item.code,
    }
    updtaedItem.singleEntry = getCheckBoxValue(updtaedItem.singleEntry)
    return updtaedItem
  })

  const handleClick = (index) => {
    if (openIndex === index) {
      setopenIndex(-1)
    } else {
      setopenIndex(index)
    }
  }

  const handleClickPage = (value) => {
    setpageMenu(!value)
  }

  const handleClickGetMyStore = (value) => {
    setgetMyStore(!value)
  }

  const handlePagesubmenuClick = (row) => {
    setselectedmenu(row.id)
    toggleMenuDrawer(false)
    if (row.singleEntry === true) {
      dispatch(
        common_post_form_data({
          functions: { drawerWidth: "100%" },
          formtype: "customPageForm",
          formaction: "edit",
          formdata: "1",
          other_type: row.name,
          otherDetail: row,
        })
      )
    } else {
      dispatch(gotoPage({ key: `pages/${row.id}/${encodeURIComponent(row.name)}`, data: row }))
    }
  }

  const handleFbshopClick = ({ page }) => {
    toggleMenuDrawer(false)
    setselectedmenu(page)
    dispatch(gotoPage({ key: page }))
  }

  const handlereportsClick = (id) => {
    toggleMenuDrawer(false)
    setselectedmenu(id)
    setStorage("reportfiltertype", "")
    dispatch(gotoPage({ key: getmenukey(id) }))
  }

  const handlesubmenuClick = (id, meta) => {
    toggleMenuDrawer(false)
    setselectedmenu(id)
    //SALES INVOICE OR OTHER INVOCIE TYPE
    if (
      (parseInt(id, 10) >= 5000 && parseInt(id, 10) < 6000) ||
      parseInt(id) === 16 ||
      (parseInt(id, 10) >= 6000 && parseInt(id, 10) < 7000) ||
      parseInt(id) === 18 ||
      (parseInt(id, 10) >= 7000 && parseInt(id, 10) < 8000) ||
      // parseInt(id) === 221 ||
      parseInt(id) === 222 ||
      parseInt(id) === 223 ||
      parseInt(id) === 62 ||
      (parseInt(id, 10) >= 8000 && parseInt(id, 10) < 9000) ||
      parseInt(id) === 63 ||
      parseInt(id) === 193
    ) {
      if (meta.main_type === "salesinvoices") {
        dispatch(gotoPage({ key: `invoice/${replaceAllFn(meta.type, "_", "-")}`, data: meta.type }))
      } else if (meta.main_type === "outwards" && meta.type === "manage_lot_master") {
        dispatch(gotoPage({ key: `process/lotmaster`, data: meta.type }))
      } else if (meta.main_type === "inwards" && meta.type === "job_inward") {
        dispatch(gotoPage({ key: `process/job-inward`, data: meta.type }))
      } else if (meta.main_type === "inwards" && meta.type === "finish_stock") {
        dispatch(gotoPage({ key: `process/finish-stock`, data: meta.type }))
      } else {
        if (isblank(meta.main_type)) {
          dispatch(snackbarstate({ open: true, message: "Main type not found", snackbarType: "error" }))
        } else {
          dispatch(gotoPage({ key: `${meta.main_type}/${replaceAllFn(meta.type, "_", "-")}`, data: meta.type }))
        }
      }
    } else if (parseInt(id, 10) === 9000) {
      dispatch(gotoPage({ key: `invoice/sales-order`, data: meta.type }))
    } else {
      dispatch(gotoPage({ key: getmenukey(id) }))
    }
  }

  return (
    <Drawer elevation={0} open={menudrawer} onClose={() => toggleMenuDrawer(false)}>
      {menudrawer && (
        <PerfectScrollbar>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", padding: 10, paddingBottom: 5 }}>
            <div style={{ width: 160 }}>
              <img style={{ width: "100%" }} src={FinalBooksLogo} alt="logo" />
            </div>
            <div>
              <Typography style={{ marginTop: 15 }} variant="caption">
                v{process.env.REACT_APP_VERSION}
              </Typography>
            </div>
          </div>
          <Divider />

          <List disablePadding dense className={classes.menu_nav}>
            {menu_data.map(function (row, index) {
              return (
                <div key={`ListItem_${index}`}>
                  {row.name !== "Dashboard" && row.name !== "Utilities" && row.name !== "Reports" ? (
                    <ListItem button onClick={() => handleClick(index)}>
                      <ListItemIcon style={{ minWidth: 32 }}>{getmenuicon(row.name)}</ListItemIcon>
                      <ListItemText primary={row.name} />
                      {openIndex === index ? <ExpandLess color="disabled" /> : <ExpandMore color="disabled" />}
                    </ListItem>
                  ) : row.name === "Dashboard" ? (
                    <ListItem button onClick={() => handlesubmenuClick("1000")}>
                      <ListItemIcon style={{ minWidth: 32 }}>{getmenuicon(row.name)}</ListItemIcon>
                      <ListItemText primary={row.name} />
                    </ListItem>
                  ) : row.name === "Utilities" ? (
                    <ListItem button onClick={() => handlesubmenuClick("1001")}>
                      <ListItemIcon style={{ minWidth: 32 }}>{getmenuicon(row.name)}</ListItemIcon>
                      <ListItemText primary={row.name} />
                    </ListItem>
                  ) : null}
                  <Divider />
                  {row.name !== "Dashboard" && row.name !== "Utilities" && row.name !== "Reports" ? (
                    <Collapse component="li" in={openIndex === index} timeout="auto" unmountOnExit>
                      <List disablePadding dense>
                        {all_menu[row.name].map(function (row2, index2) {
                          if (row2.hidden_menu === "1") {
                            return null
                          } else if (row.name === "Invoice" && parseInt(SiteSetting("processModule")) !== 1 && SiteSetting("packingSlipModule") !== 1 && row2.name === "Packing Slip") {
                            return null
                          } else if (checkRightsAndSiteSetting(row2.id, SiteSetting) === false) {
                            return null
                          } else {
                            return (
                              <div key={`ListSubItem_${row2.id}`}>
                                <ListItem button onClick={() => handlesubmenuClick(row2.id, row2)} selected={selectedmenu === row2.id}>
                                  <ListItemText style={{ paddingLeft: 32 }} primary={row2.name} />
                                </ListItem>
                                <Divider light={true} />
                              </div>
                            )
                          }
                        })}
                      </List>
                    </Collapse>
                  ) : null}
                </div>
              )
            })}
            {allPages.length > 0 && (
              <React.Fragment>
                <ListItem button onClick={() => handleClickPage(pageMenu)}>
                  <ListItemIcon style={{ minWidth: 32 }}>{getmenuicon("Pages")}</ListItemIcon>
                  <ListItemText primary="Pages" />
                  {pageMenu ? <ExpandLess color="disabled" /> : <ExpandMore color="disabled" />}
                </ListItem>
                <Divider />
              </React.Fragment>
            )}
            <Collapse component="li" in={pageMenu} timeout="auto" unmountOnExit>
              <List disablePadding dense>
                {allPages.map(function (row2, index2) {
                  return (
                    <div key={`ListSubItem_${row2.id}`}>
                      <ListItem button onClick={() => handlePagesubmenuClick(row2)} selected={selectedmenu === row2.id}>
                        <ListItemText style={{ paddingLeft: 32 }} primary={row2.name} />
                      </ListItem>
                      <Divider />
                    </div>
                  )
                })}
              </List>
            </Collapse>
            {(SiteSetting("catalogueModule") === 1 || SiteSetting("getMyCatalogueModule") === 1) && (
              <React.Fragment>
                <ListItem button onClick={() => handleClickGetMyStore(getMyStore)}>
                  <ListItemIcon style={{ minWidth: 32 }}>{getmenuicon("GetMyStore")}</ListItemIcon>
                  <ListItemText primary="GetMyStore" />
                  {getMyStore ? <ExpandLess color="disabled" /> : <ExpandMore color="disabled" />}
                </ListItem>
                <Divider />
                <Collapse component="li" in={getMyStore} timeout="auto" unmountOnExit>
                  <List disablePadding dense>
                    {SiteSetting("catalogueModule") === 1 && (
                      <React.Fragment>
                        <ListItem button onClick={() => handleFbshopClick({ page: "catalogueusers" })} selected={selectedmenu === "getMyStoreUsers"}>
                          <ListItemText style={{ paddingLeft: 32 }} primary="Users" />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )}
                    {/* <ListItem button onClick={() => handleFbshopClick({ page: "productattributes" })} selected={selectedmenu === "Attributes"}>
                      <ListItemText style={{ paddingLeft: 32 }} primary="Attributes" />
                    </ListItem>
                    <Divider /> */}
                  </List>
                </Collapse>
              </React.Fragment>
            )}
            {!isblank(getAllReports()) && Object.keys(getAllReports()).length > 0 && (
              <ListItem button onClick={() => handlereportsClick("1002")}>
                <ListItemIcon style={{ minWidth: 32 }}>{getmenuicon("Reports")}</ListItemIcon>
                <ListItemText primary="Reports" />
              </ListItem>
            )}
          </List>
        </PerfectScrollbar>
      )}
    </Drawer>
  )
}

function propsAreEqual(prevMovie, nextMovie) {
  return prevMovie.menudrawer === nextMovie.menudrawer
}

export default React.memo(NavigationMenu, propsAreEqual)
