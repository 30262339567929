import { getdropdownvalue } from "@project/components/utilityFunctions"
import { convertIntif } from "@project/sharedcomponents/utilityFunctions"
import {
  SHIPPING_SEARCH_TEXT_UPDATE,
  SHIPPING_SERVICE_CLEAR,
  SHIPPING_SERVICE_DELETED,
  SHIPPING_SERVICE_FAIL,
  SHIPPING_SERVICE_INSERTED,
  SHIPPING_SERVICE_START,
  SHIPPING_SERVICE_SUCCESS,
  SHIPPING_SERVICE_UPDATED,
} from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: true,
  data: [],
  Local_delivery: [],
  In_PersonPickup: [],
  totalcount: 0,
  norecord: false,
  deleted: false,
  columnData: [
    { id: "name", numeric: false, visible: true, label: "City" },
    { id: "state", numeric: false, visible: true, label: "State", width: 150 },
  ],
  metadata: "",
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHIPPING_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case SHIPPING_SERVICE_SUCCESS:
      return { ...state, data: action.payload.data.result, loading: false, totalcount: action.payload.data.total_count, norecord: action.payload.data.result.length === 0 }
    case SHIPPING_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false, totalcount: 0 }
    case SHIPPING_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case SHIPPING_SEARCH_TEXT_UPDATE:
      return { ...state, norecord: false }
    case SHIPPING_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_code
      inserteditem.name = action.payload.shipping
      inserteditem.sid = getdropdownvalue({ formValues: action.payload, field: "stateSelectedValue", prop: "code" })
      inserteditem.state = getdropdownvalue({ formValues: action.payload, field: "stateSelectedValue", prop: "name" })
      inserteditem.statecode = getdropdownvalue({ formValues: action.payload, field: "stateSelectedValue", prop: "statecode" })
      return { ...state, data: [inserteditem, ...state.data] }

    case SHIPPING_SERVICE_DELETED:
      if (action.otherDetail === "multi_delete") {
        var finalData = []
        var applyToDelete = action.payload.code.codes.map((a) => a.toString())
        if (action.response.data.meta.code === 403) {
          //Error
          var NotDelete = action.response.data.meta.message.map((a) => a.code.toString())
          finalData = state.data.filter((item) => {
            item.code = item.code.toString()
            if (!(applyToDelete.includes(item.code) && !NotDelete.includes(item.code))) {
              return item
            }
            return false
          })
        } //All delete
        else {
          finalData = state.data.filter((item) => {
            item.code = item.code.toString()
            if (!applyToDelete.includes(item.code)) {
              return item
            }
            return false
          })
        }
        return { ...state, data: finalData, deleted: !state.deleted }
      } else {
        return { ...state, data: state.data.filter((item) => item.code.toString() !== action.payload.code.toString()), deleted: !state.deleted }
      }
    case SHIPPING_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.update_code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.name = action.payload.shipping
        updateditem.sid = getdropdownvalue({ formValues: action.payload, field: "stateSelectedValue", prop: "code" })
        updateditem.state = getdropdownvalue({ formValues: action.payload, field: "stateSelectedValue", prop: "name" })
        updateditem.statecode = getdropdownvalue({ formValues: action.payload, field: "stateSelectedValue", prop: "statecode" })
        return updateditem
      })
      return { ...state, data: updateddata }
    default:
      return state
  }
}
