import { IMPORT_TEMPLATE_LOCAL_SEARCH, IMPORT_TEMPLATE_SERVICE_FAIL, IMPORT_TEMPLATE_SERVICE_INSERTED, IMPORT_TEMPLATE_SERVICE_START, IMPORT_TEMPLATE_SERVICE_SUCCESS } from "../../Actions/types"

const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  search_text: "",
  columnData: [
    { id: "name", numeric: false, visible: true, label: "Name" },
    { id: "papersize", numeric: false, visible: true, label: "Papersize", width: 75 },
    { id: "metric", numeric: false, visible: true, label: "Metric", width: 75 },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IMPORT_TEMPLATE_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case IMPORT_TEMPLATE_LOCAL_SEARCH:
      return { ...state, search_text: action.search_text }
    case IMPORT_TEMPLATE_SERVICE_SUCCESS:
      return { ...state, ...INITIAL_STATE, data: action.payload.data.result, islogin: true }
    case IMPORT_TEMPLATE_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false }
    case IMPORT_TEMPLATE_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_code
      inserteditem.name = action.payload.name
      inserteditem.papersize = action.payload.papersize
      inserteditem.metric = action.payload.metric
      return { ...state, data: [inserteditem, ...state.data] }
    default:
      return state
  }
}
