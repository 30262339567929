import { combineReducers } from "redux"
import DashboardReducer from "./DashboardReducer"

import PaymentUtilityReducer from "./PaymentUtilityReducer"
import ShippingReducer from "./ShippingReducer"
// import RecordExpenseReducer from "./RecordExpenseReducer"
import ImportAccountReducer from "./ImportAccountReducer"
import ImportTaxSchemeReducer from "./ImportTaxSchemeReducer"
import OpeningBalanceReducer from "./OpeningBalanceReducer"
import RecentActivitiesDrawerReducer from "./RecentActivitiesDrawerReducer"

// import CatalogueUserReducer from "./CatalogueUserReducer"
import CustomFieldReducer from "./CustomFieldReducer"
import GetMyStoreUserReducer from "./GetMyStoreUserReducer"
import ImportUnitReducer from "./ImportUnitReducer"
import OpeningStockReducer from "./OpeningStockReducer"
import PagesReducer from "./PagesReducer"
import ReportReducer from "./ReportReducer"
import UserHistoryReportReducer from "./UserHistoryReportReducer"
import { reducer as formReducer } from "redux-form"
import importTemplateReducer from "./../BarCodePrinting/Reducers/importTemplateReducer"
import labelReducer from "./../BarCodePrinting/Reducers/labelReducer"
import labelSettingReducer from "./../BarCodePrinting/Reducers/labelSettingReducer"
import BotReducer from "./BotReducer"
import ChequePrintingReducer from "./ChequePrintingReducer"
import ClosingStockReducer from "./ClosingStockReducer"
import CustomPageReducer from "./CustomPageReducer"
import EmailTemplateReducer from "./EmailTemplateReducer"
import EnvelopePrintingReducer from "./EnvelopePrintingReducer"
import EwayBillReducer from "./EwayBillReducer"
import GSTOldPenInReducer from "./GSTOldPenInReducer"
// import GroupSMSAddContactReducer from "./GroupSMSAddContactReducer"
// import GroupSMSAddGroupReducer from "./GroupSMSAddGroupReducer"
// import GroupSMSSendSMSReducer from "./GroupSMSSendSMSReducer"
import IPOStockMasterReducer from "./IPOStockMasterReducer"
import IPOStockPartyReducer from "./IPOStockPartyReducer"
import IPOStockReducer from "./IPOStockReducer"
// import MergePackingSlipReducer from "./MergePackingSlipReducer"
import OrganizationReducer from "./OrganizationReducer"
import PartyGroupPriceReducer from "./PartyGroupPriceReducer"
import PartyPriceReducer from "./PartyPriceReducer"
import ProcessReducer from "./ProcessListReducer"
import ProcessTransferReducer from "./ProcessTransferReducer"
import ProductSearchReducer from "./ProductSearchReducer"
import QuickLinkReducer from "./QuickLinkReducer"
import SMSTemplateReducer from "./SMSTemplateReducer"
import TallyExportReducer from "./TallyExportReducer"
import TdsCategoryMasterReducer from "./TdsCategoryMasterReducer"
import UserRightsReducer from "./UserRightsReducer"
import UtilityCommonReducer from "./UtilityCommonReducer"
import WhatsappTemplateReducer from "./WhatsappTemplateReducer"
import WireTransferBeneficiaryReducer from "./WireTransferBeneficiaryReducer"
import WireTransferReducer from "./WireTransferReducer"
import WireTransferSenderReducer from "./WireTransferSenderReducer"

import AuthReducer from "./AuthReducer"
import BillingUtilityReducer from "./BillingUtilityReducer"
import CatalogueReducer from "./CatalogueReducer"
import CommonReducer from "./CommonReducer"
import GlobalSearchReducer from "./GlobalSearchReducer"
import HookFormReducer from "./HookFormReducer"
import ServiceWorkerReducer from "./ServiceWorkerReducer"

export default () =>
  combineReducers({
    form: formReducer,
    AuthReducer,
    CommonReducer,
    // RecordExpenseReducer,
    ImportTaxSchemeReducer,
    OpeningBalanceReducer,
    ImportUnitReducer,
    ImportAccountReducer,
    ClosingStockReducer,
    PartyPriceReducer,
    UserRightsReducer,
    ReportReducer,
    // GroupSMSAddGroupReducer,
    // GroupSMSAddContactReducer,
    // GroupSMSSendSMSReducer,
    UtilityCommonReducer,
    UserHistoryReportReducer,
    BillingUtilityReducer,
    RecentActivitiesDrawerReducer,
    GlobalSearchReducer,
    PartyGroupPriceReducer,
    DashboardReducer,
    WireTransferSenderReducer,
    WireTransferBeneficiaryReducer,
    WireTransferReducer,
    IPOStockMasterReducer,
    IPOStockPartyReducer,
    IPOStockReducer,
    QuickLinkReducer,
    CustomFieldReducer,
    CustomPageReducer,
    PagesReducer,
    OpeningStockReducer,
    EwayBillReducer,
    SMSTemplateReducer,
    WhatsappTemplateReducer,
    EmailTemplateReducer,
    OrganizationReducer,
    ServiceWorkerReducer,
    ProductSearchReducer,
    labelReducer,
    labelSettingReducer,
    importTemplateReducer,
    ProcessTransferReducer,
    GetMyStoreUserReducer,
    // CatalogueUserReducer,
    ProcessReducer,
    ShippingReducer,
    PaymentUtilityReducer,
    GSTOldPenInReducer,
    CatalogueReducer,
    BotReducer,
    // MergePackingSlipReducer,
    EnvelopePrintingReducer,
    ChequePrintingReducer,
    TallyExportReducer,
    TdsCategoryMasterReducer,
    HookFormReducer,
  })
