import { Button, CircularProgress } from "@material-ui/core"
import React from "react"

export default ({ label, loading, ...props }) => {
  return (
    <Button
      {...(loading && {
        endIcon: <CircularProgress disableShrink size={18} color="primary" />,
        style: { textTransform: "none", ...props.style },
        disabled: true,
      })}
      {...props}
    >
      {label}
    </Button>
  )
}
