export const onSubmitError = ({ errors, dispatch, snackbarstate }) => {
  let firstErrorKey = ""
  let firstErrorMessage = ""

  const findFirstError = (errorObj, parentKey = "") => {
    for (const key in errorObj) {
      if (errorObj[key].message) {
        firstErrorKey = parentKey ? `${parentKey}.${key}` : key
        firstErrorMessage = errorObj[key].message
        return
      }
      if (typeof errorObj[key] === "object") {
        findFirstError(errorObj[key], parentKey ? `${parentKey}[${key}]` : key)
        if (firstErrorKey) return // Exit early if first error is found
      }
    }
  }

  findFirstError(errors)

  if (firstErrorMessage) {
    dispatch(snackbarstate({ open: true, message: firstErrorMessage, snackbarType: "error" }))
    setTimeout(() => {
      const field = document.getElementsByName(firstErrorKey)[0]
      if (field) {
        field.focus()
      }
    }, 300)
  }
}

export const callError = ({ error, onSubmitError, dispatch, snackbarstate, setError }) => {
  let updatedError = {}
  let errObj = {}
  Object.entries(error).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      for (const keyIndex in value) {
        const objectKeys = Object.keys(value[keyIndex])[0]
        errObj = { [`${key}[${keyIndex}].${objectKeys}`]: { type: "required", message: value[keyIndex][objectKeys] } }

        if (Object.keys(updatedError).length === 0) {
          updatedError = errObj
        }

        setError(Object.keys(errObj)[0], { type: "custom", message: errObj[Object.keys(errObj)[0]].message })
        // break
      }
    } else {
      errObj = { [key]: { type: "required", message: value } }
      if (Object.keys(updatedError).length === 0) {
        updatedError = errObj
      }
      setError(key, { type: "custom", message: value })
    }
    onSubmitError({ errors: updatedError, dispatch, snackbarstate })
  })
}
