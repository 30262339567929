import { fbprintpdf } from "@project/components/printJSFunctions"
import { mmToPoints } from "@project/components/utilityFunctions"
import {
  DatetoDMY,
  amountFormatOfRateV1,
  amount_formatDecimal,
  cloneObj,
  convertIntif,
  getNumberonly,
  getNumberonlyDecimal,
  isBlankCode,
  isblank,
  numForCalc,
  replaceAllFn,
  validateJSON,
} from "@project/sharedcomponents/utilityFunctions"
import JsBarcode from "jsbarcode/bin/JsBarcode"
import { snackbarstate } from "../../Actions"
import { getAccountSitesetting } from "../../Lib/commonfunctions"

const textToBase64Barcode = (text, height, barcodeStyle, barcodeWidth) => {
  var canvas = document.createElement("canvas")
  JsBarcode(canvas, text, {
    format: !isblank(barcodeStyle) ? barcodeStyle : "CODE128",
    height: convertIntif(height),
    displayValue: false,
    margin: 0,
    width: !isblank(barcodeWidth) ? convertIntif(barcodeWidth) : 1,
  })
  return canvas.toDataURL("image/png")
}

export const printBarcode = (props) => {
  const { templateSetting, printData, dispatch } = props

  var fields = JSON.parse(templateSetting.fields)
  var showingFields = []
  if (!isblank(fields) && fields.length > 0) {
    showingFields = fields.filter((e) => e.show)
    showingFields = showingFields.map((e) => {
      const updated = { ...e }
      if (updated.field === "Item Name") {
        updated.field = "Description of Goods"
      }
      updated.name = replaceAllFn(updated.field, " ", "_")
      return updated
    })
  }

  const templateString = JSON.parse(templateSetting.templateString)
  let headerString = templateString.header
  let bodyString = templateString.body
  let footerString = templateString.footer
  let styleString = templateString.style

  let header = []
  let footer = []
  let stickerBody = []
  let styles = {}
  var leftmargin = mmToPoints(parseFloat(getNumberonly(templateSetting.marginLeft)), templateSetting.metric)
  var topmargin = mmToPoints(parseFloat(getNumberonly(templateSetting.marginTop)), templateSetting.metric)
  var rightmargin = mmToPoints(parseFloat(getNumberonly(templateSetting.marginRight)), templateSetting.metric)
  var bottommargin = mmToPoints(parseFloat(getNumberonly(templateSetting.marginBottom)), templateSetting.metric)
  var fontSize = parseFloat(getNumberonly(templateSetting.fontsize))
  if (fontSize === 0) {
    fontSize = 8
  }

  function extractDecimalPrecision(placeholder) {
    const match = placeholder.match(/\.(\d+)/) // Look for a pattern like '.1', '.2', etc.
    if (match && match[1]) {
      return parseInt(match[1], 10) // Convert the precision to an integer
    }
    return null // Return null if no precision found
  }

  const replaceString = (strVal) => {
    let returnValue = strVal
    showingFields.forEach((e) => {
      if (returnValue.indexOf(e.name) > -1 && !isblank(e.value)) {
        returnValue = replaceAllFn(returnValue, `{${e.name}}`, e.value)
      }
    })
    return returnValue
  }

  function replaceRandomNumbers(inputString) {
    var replacedString = inputString.replace(/\[R(\d+)\]/g, function(match, digit) {
      var numberOfDigits = parseInt(digit)
      var randomNumber = Math.floor(Math.random() * Math.pow(10, numberOfDigits))
      return randomNumber.toString().padStart(numberOfDigits, "0")
    })

    /*
    // Replace placeholders with random numbers
    var replacedString = inputString
      .replace(/\[R2\]/g, function () {
        return Math.floor(Math.random() * 10) * 10 + Math.floor(Math.random() * 100)
      })
      .replace(/\[R3\]/g, function () {
        return Math.floor(Math.random() * 10) * 10 + Math.floor(Math.random() * 1000)
      })
      .replace(/\[R1\]/g, function () {
        return Math.floor(Math.random() * 10) * 10 + Math.floor(Math.random() * 10)
      })
*/
    return replacedString
  }

  const replaceItemString = (e, bodystr, templateSetting) => {
    Object.keys(e).forEach((data, index) => {
      if (data === "Barcode" && !isblank(e.Barcode)) {
        var barcode_str = ""
        if (templateSetting.barcodePrintType === "2") {
          barcode_str = e["Barcode"]
        } else {
          barcode_str = textToBase64Barcode(e.Barcode, convertIntif(templateSetting.barcodeHeight), templateSetting.barcodeStyle, templateSetting.barcodeWidth)
        }

        bodystr = replaceAllFn(bodystr, '"{Barcode_height}"', templateSetting.barcodeHeight)
        bodystr = replaceAllFn(bodystr, `{Barcode}`, barcode_str)
        bodystr = replaceAllFn(bodystr, `{Barcode_Value}`, e["Barcode"])
      } else if (data === "MRP_Price" || data === "Selling_Price") {
        const regex = new RegExp(`{${data}\\.\\d+}`, "g")
        //const regex = /{MRP_Price\.(\d)}/g
        let match
        while ((match = regex.exec(bodystr)) !== null) {
          const placeholder = match[0] // The full placeholder, e.g., {MRP_Price.2}
          const precision = extractDecimalPrecision(placeholder)
          if (precision !== null) {
            // Assume there's a single MRP price to replace
            const formattedPrice = parseFloat(getNumberonly(e[data])).toFixed(precision)
            // Replace the placeholder with the formatted price
            bodystr = replaceAllFn(bodystr, `${placeholder}`, formattedPrice)
          } else {
            bodystr = replaceAllFn(bodystr, `{${data}}`, e[data])
          }
        }
        if (match === null) {
          bodystr = replaceAllFn(bodystr, `{${data}}`, e[data])
        }
      } else if (bodystr.indexOf(data) > -1) {
        if (!isblank(e[data])) {
          bodystr = replaceAllFn(bodystr, `{${data}}`, e[data])
        } else {
          bodystr = replaceAllFn(bodystr, `{${data}}`, "")
        }
      }
    })

    return bodystr
  }

  if (!isblank(bodyString)) {
    bodyString = replaceString(bodyString)
    bodyString = replaceRandomNumbers(bodyString)
  }

  if (!isblank(headerString)) {
    headerString = replaceString(headerString)
    headerString = replaceRandomNumbers(headerString)
  }

  if (!isblank(footerString)) {
    footerString = replaceString(footerString)
    footerString = replaceRandomNumbers(footerString)
  }

  if (!isblank(styleString)) {
    styles = JSON.parse(styleString)
  }

  var numberofColumns = parseInt(getNumberonly(templateSetting.NX))
  if (numberofColumns === 0) {
    numberofColumns = 4
  }

  var newprintData = printData
    .map((e) => {
      var itembody = bodyString
      itembody = replaceItemString(e, itembody, templateSetting)
      stickerBody = validateJSON(itembody)

      //var stack = []
      if (stickerBody === null) {
        return null
      } else {
        if (isblank(headerString)) {
          header = []
        } else {
          headerString = replaceItemString(e, headerString, templateSetting)
          header = JSON.parse(headerString)
        }

        //stack.push(stickerBody)

        if (isblank(footerString)) {
          footer = []
        } else {
          footerString = replaceItemString(e, footerString, templateSetting)
          footer = JSON.parse(footerString)
        }
        return stickerBody
      }
    })
    .filter((f) => f !== null)

  const finalPrintData = []
  for (var i = 0; i < newprintData.length; i += numberofColumns) {
    finalPrintData.push(newprintData.slice(i, i + numberofColumns))
  }
  var widthsArray = []

  for (var j = 0; j < numberofColumns; j++) {
    widthsArray.push("*")
  }

  if (finalPrintData.length > 0 && finalPrintData[finalPrintData.length - 1].length < numberofColumns) {
    const blankColumn = numberofColumns - finalPrintData[finalPrintData.length - 1].length
    for (var k = 0; k < blankColumn; k++) {
      finalPrintData[finalPrintData.length - 1].push({})
    }
  }

  if (!isblank(finalPrintData) && finalPrintData.length > 0) {
    var height = mmToPoints(parseInt(templateSetting.height), templateSetting.metric) // + templateSetting.SpaceY
    const finalContent = [
      {
        table: {
          dontBreakRows: true,
          widths: widthsArray,
          heights: height,
          body: finalPrintData,
        },
        //layout: "noBorders",
        layout: {
          hLineColor: function(i, node) {
            return i === 0 || i === node.table.body.length ? "red" : "blue"
          },
          vLineColor: function(i, node) {
            return i === 0 || i === node.table.widths.length ? "red" : "blue"
          },
          hLineWidth: function(i, node) {
            return templateSetting.showBorder === "1" ? 1 : 0
          },
          vLineWidth: function(i, node) {
            return templateSetting.showBorder === "1" ? 1 : 0
          },
          paddingLeft: function(i, node) {
            return 0
          },
          paddingRight: function(i, node) {
            return 0
          },
          paddingTop: function(i, node) {
            return 0
          },
          paddingBottom: function(i, node) {
            return 0
          },
        },
      },
    ]

    let pageSize = "A4"
    if (!isblank(templateSetting.papersize)) {
      if (templateSetting.papersize === "Custom") {
        pageSize = {
          width: mmToPoints(templateSetting.pageWidth, templateSetting.metric), //PT
          height: templateSetting.pageHeight === "auto" ? "auto" : mmToPoints(templateSetting.pageHeight, templateSetting.metric),
        }
      } else {
        pageSize = templateSetting.papersize
      }
    }

    var dd = {
      pageSize: pageSize,
      pageMargins: [leftmargin, topmargin, rightmargin, bottommargin],
      content: cloneObj(finalContent),
      header: header,
      footer: footer,
      defaultStyle: {
        fontSize: fontSize,
      },
      styles: styles,
    }

    fbprintpdf({ docDefinition: dd, metadata: { filename: "barcode" }, action: "print" })
  } else {
    dispatch(snackbarstate({ open: true, message: "Parsing error", snackbarType: "error" }))
  }
}

export const getPrintData = (templateSetting, inwardDetail) => {
  let qtd = getAccountSitesetting("inventory_decimalpoint")
  const rDP = getAccountSitesetting("rate_decimalpoint")
  var fields = JSON.parse(templateSetting.fields)
  var showingFields = []
  if (!isblank(fields) && fields.length > 0) {
    showingFields = fields.filter((e) => e.show)
    showingFields = showingFields.map((e) => {
      const updated = { ...e }
      if (updated.field === "Item Name") {
        updated.field = "Description of Goods"
      }
      updated.name = replaceAllFn(updated.field, " ", "_")
      return updated
    })
  }

  var printData = []
  var DetailGrid = []

  if (!isblank(inwardDetail) && !isblank(inwardDetail.details_table_data) && inwardDetail.details_table_data.length > 0) {
    DetailGrid = inwardDetail.details_table_data.filter((e) => parseFloat(getNumberonlyDecimal(e.qty, qtd)) > 0)
  }
  var newItem = {}
  const labelPrintSetting = templateSetting.labelPrintSetting ? templateSetting.labelPrintSetting : "0"
  const addCustomField = (rowData) => {
    let returnValue = {
      docNo: inwardDetail.master_table_data[0].billno_str,
      date: DatetoDMY(inwardDetail.master_table_data[0].b_date),
    }
    if (inwardDetail && inwardDetail.custom_fields_data_master && inwardDetail.custom_fields_data_master.length > 0) {
      inwardDetail.custom_fields_data_master.forEach((e) => {
        returnValue[e.name] = inwardDetail.master_table_data[0][e.name]
      })
    }

    if (inwardDetail && inwardDetail.custom_fields_data_prmzd && inwardDetail.custom_fields_data_prmzd.length > 0) {
      inwardDetail.custom_fields_data_prmzd.forEach((e) => {
        returnValue[e.name] = inwardDetail.master_table_data[0][e.name]
      })
    }

    if (inwardDetail && inwardDetail.custom_fields_data && inwardDetail.custom_fields_data.length > 0) {
      inwardDetail.custom_fields_data.forEach((e) => {
        returnValue[e.name] = rowData[e.name]
      })
    }
    return returnValue
  }

  const getBarcode = (rowData) => {
    const barcodeMng = getAccountSitesetting("barcode_mng")
    let barcode = ""
    if (inwardDetail && inwardDetail.master_table_data && inwardDetail.master_table_data.length > 0 && inwardDetail.master_table_data[0].inward_type === "finish_stock") {
      barcode = isBlankCode(rowData.barcode) ? new Date().getTime() : rowData.barcode
    } else {
      if (barcodeMng === 1) {
        barcode = rowData.sku
      }
      if (barcodeMng === 4) {
        barcode = rowData.itemcode
      }
    }
    return barcode
  }

  DetailGrid.forEach((e) => {
    if (labelPrintSetting === "1") {
      newItem = {
        Barcode: getBarcode(e),
        ...addCustomField(e),
      }
      showingFields.forEach((j) => {
        if (j.name === "Description_of_Goods") {
          newItem[j.name] = e.itemname
        }
        if (j.name === "Unit_Name") {
          newItem[j.name] = e.unitname
        }
        if (j.name === "Group_Name") {
          newItem[j.name] = e.itemgroup_name
        }
        if (j.name === "Sub_Group_Name") {
          newItem[j.name] = e.itemsubgroup_name
        }
        if (j.name === "Selling_Price") {
          newItem[j.name] = amountFormatOfRateV1(e.sale_rate, rDP)
        }
        if (j.name === "MRP_Price") {
          newItem[j.name] = amountFormatOfRateV1(e.mrp, rDP)
        }
        if (j.name === "HSN_Code") {
          newItem[j.name] = e.hsn_code
        }
        if (j.name === "SKU") {
          newItem[j.name] = e.sku
        }
        if (j.name === "QTY") {
          newItem[j.name] = amount_formatDecimal(e.qty, qtd)
        }
        if (j.name === "lot_billno") {
          newItem[j.name] = e.lot_billno
        }

        if (j.name === "cipher_pur_rate") {
          newItem[j.name] = e.cipher_pur_rate
        }
      })
      printData.push(newItem)
    } else if (labelPrintSetting === "2") {
      if (!isBlankCode(e.__cust_pcs) && numForCalc(e.__cust_pcs) > 0) {
        for (let i = 0; i < numForCalc(e.__cust_pcs); i++) {
          newItem = {
            Barcode: getBarcode(e),
            ...addCustomField(e),
          }
          // eslint-disable-next-line
          showingFields.forEach((j) => {
            if (j.name === "Description_of_Goods") {
              newItem[j.name] = e.itemname
            }
            if (j.name === "Unit_Name") {
              newItem[j.name] = e.unitname
            }
            if (j.name === "Group_Name") {
              newItem[j.name] = e.itemgroup_name
            }
            if (j.name === "Sub_Group_Name") {
              newItem[j.name] = e.itemsubgroup_name
            }
            if (j.name === "Selling_Price") {
              newItem[j.name] = amountFormatOfRateV1(e.sale_rate, rDP)
            }
            if (j.name === "MRP_Price") {
              newItem[j.name] = amountFormatOfRateV1(e.mrp, rDP)
            }
            if (j.name === "HSN_Code") {
              newItem[j.name] = e.hsn_code
            }
            if (j.name === "SKU") {
              newItem[j.name] = e.sku
            }
            if (j.name === "QTY") {
              newItem[j.name] = amount_formatDecimal(e.qty, qtd)
            }
            if (j.name === "lot_billno") {
              newItem[j.name] = e.lot_billno
            }
            if (j.name === "cipher_pur_rate") {
              newItem[j.name] = e.cipher_pur_rate
            }
          })
          printData.push(newItem)
        }
      }
    } else if (labelPrintSetting === "3") {
      let qty = "qty"
      if (!isBlankCode(e.__cust_box) && numForCalc(e.__cust_box) > 0) {
        qty = "__cust_box"
      }
      for (let i = 0; i < numForCalc(e[qty]); i++) {
        newItem = {
          Barcode: getBarcode(e),
          ...addCustomField(e),
        }
        // eslint-disable-next-line
        showingFields.forEach((j) => {
          if (j.name === "Description_of_Goods") {
            newItem[j.name] = e.itemname
          }
          if (j.name === "Unit_Name") {
            newItem[j.name] = e.unitname
          }
          if (j.name === "Group_Name") {
            newItem[j.name] = e.itemgroup_name
          }
          if (j.name === "Sub_Group_Name") {
            newItem[j.name] = e.itemsubgroup_name
          }
          if (j.name === "Selling_Price") {
            newItem[j.name] = amountFormatOfRateV1(e.sale_rate, rDP)
          }
          if (j.name === "MRP_Price") {
            newItem[j.name] = amountFormatOfRateV1(e.mrp, rDP)
          }
          if (j.name === "HSN_Code") {
            newItem[j.name] = e.hsn_code
          }
          if (j.name === "SKU") {
            newItem[j.name] = e.sku
          }
          if (j.name === "QTY") {
            newItem[j.name] = amount_formatDecimal(e.__cust_box_pcs, qtd)
          }
          if (j.name === "lot_billno") {
            newItem[j.name] = e.lot_billno
          }
          if (j.name === "cipher_pur_rate") {
            newItem[j.name] = e.cipher_pur_rate
          }
        })
        printData.push(newItem)
      }
    } else {
      for (let i = 0; i < parseFloat(getNumberonlyDecimal(e.qty, qtd)); i++) {
        newItem = {
          Barcode: getBarcode(e),
          ...addCustomField(e),
        }
        // eslint-disable-next-line
        showingFields.forEach((j) => {
          if (j.name === "Description_of_Goods") {
            newItem[j.name] = e.itemname
          }
          if (j.name === "Unit_Name") {
            newItem[j.name] = e.unitname
          }
          if (j.name === "Group_Name") {
            newItem[j.name] = e.itemgroup_name
          }
          if (j.name === "Sub_Group_Name") {
            newItem[j.name] = e.itemsubgroup_name
          }
          if (j.name === "Selling_Price") {
            newItem[j.name] = amountFormatOfRateV1(e.sale_rate, rDP)
          }
          if (j.name === "MRP_Price") {
            newItem[j.name] = amountFormatOfRateV1(e.mrp, rDP)
          }
          if (j.name === "HSN_Code") {
            newItem[j.name] = e.hsn_code
          }
          if (j.name === "SKU") {
            newItem[j.name] = e.sku
          }
          if (j.name === "QTY") {
            newItem[j.name] = amount_formatDecimal(e.qty, qtd)
          }
          if (j.name === "lot_billno") {
            newItem[j.name] = e.lot_billno
          }
          if (j.name === "cipher_pur_rate") {
            newItem[j.name] = e.cipher_pur_rate
          }
        })
        printData.push(newItem)
      }
    }
  })

  return printData
}
