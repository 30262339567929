import { list_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, getNumberonly, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import { LOADING_STATE, OPBAL_LOCAL_SEARCH, OPBAL_SERVICE_CLEAR, OPBAL_SERVICE_FAIL, OPBAL_SERVICE_START, OPBAL_SERVICE_SUCCESS, SNACKBAR_STATE } from "./types"

export const opbal_data_clear = () => {
  return (dispatch) => {
    dispatch({
      type: OPBAL_SERVICE_CLEAR,
    })
  }
}

export const local_search_op = ({ search_text }) => {
  return (dispatch) => {
    dispatch({
      type: OPBAL_LOCAL_SEARCH,
      search_text: search_text,
    })
  }
}
export const fetch_opbal_data = ({ other_type, search_text, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: OPBAL_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("offset", 0)
    data.append("type", "opbal")
    data.append("limit", "All")
    if (other_type === "1") {
      data.append("other_type", "show_all")
    }
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "opbal" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          opbalServiceFail({ dispatch, response: response.data.meta.message, other_type, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          opbalServiceSuccess({ dispatch, user: response, other_type, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          opbalServiceFail({ dispatch, response: getCatchErrorMessage(error), other_type, search_text })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const opbalServiceSuccess = ({ dispatch, user, other_type, search_text }) => {
  if (other_type === "3") {
    user.data.result = user.data.result.filter((e) => parseFloat(getNumberonly(e.debit_bal)) > 0)
  } else if (other_type === "4") {
    user.data.result = user.data.result.filter((e) => parseFloat(getNumberonly(e.credit_bal)) > 0)
  }
  dispatch({
    type: OPBAL_SERVICE_SUCCESS,
    payload: user,
    other_type: other_type,
    search_text: search_text,
  })
}

const opbalServiceFail = ({ dispatch, response, other_type, search_text }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })
  dispatch({
    type: OPBAL_SERVICE_FAIL,
    payload: response,
    other_type: other_type,
    search_text: search_text,
  })
}
