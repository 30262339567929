import lodashMap from "lodash/map"
import lodashSum from "lodash/sum"
import { getTextMaxWidth } from "./pdfmakeFunctions"
import {
  DatetoDMY,
  amountFormatOfRateV1,
  amount_format,
  amount_formatDecimal,
  breakString,
  cloneObj,
  convertIntif,
  customFieldArrayToObj,
  format_report_fieldV1,
  getNumberonly,
  getNumberonlyDecimal,
  isblank,
  numForCalc,
  replaceBRtoNewLine,
  replacefunction,
  toFixedFn,
} from "./utilityFunctions"
import cF from "@project/sharedcomponents/constantData/customFieldsEnum"

export const generateDetailValues = ({
  functions,
  SiteSettings,
  DetailCustomField,
  DetailGrid,
  MasterData,
  parameterized,
  type,
  placeof_supply_id,
  template,
  user_data,
  Print_Detail_narration,
  otherDataLength,
  pdfFontSize,
  Print_hsn,
  Print_sku,
  Print_uom,
  enable_barcode,
  rateAmount,
}) => {
  const { getconfigsettingdata, getAccountSitesetting, getAccountSessionUserData, hideRateAmount, common_getTypeWiseKeySetting, PrintHWT, PrintSQF, PrintLOT } = functions
  var Values = []
  var ItemArray = []
  var TaxableValue = {}
  var BatchDetail = []
  var hsn = {}
  var uom = {}
  var sku = {}
  var barcode = {}
  var unit = {}
  var Rate = {}
  var Amount = {}
  var Discount = {}
  var detail_narration = ""
  var IGST = {}
  var SGST = {}
  var CGST = {}
  var Qty = {}
  var PrintBlankRow = DetailGrid.length
  var fixRow = calculateFixRow({ template, otherDataLength, Print_Detail_narration, DetailCustomField, pdfFontSize, Print_hsn, Print_sku, Print_uom })
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
  const rate_decimalpoint = getAccountSitesetting("rate_decimalpoint")
  const discount_invoice = getAccountSitesetting("discount_invoice")
  const tax = parseInt(getAccountSitesetting("tax"))
  const Business_Type = getconfigsettingdata("company_status")["business_type"]

  const plyFields = DetailCustomField.find((f) => f.name === "__cust_ply_height")
  const invoice_type = MasterData.invoice_type
  const show_rate_amount = !isblank(rateAmount) ? rateAmount : !isblank(SiteSettings) && SiteSettings.length > 0 ? parseInt(SiteSettings[0].show_rate_amount) : 0

  const totQty = MasterData.totqty

  if (Business_Type === "Wood" && PrintHWT === true && (invoice_type === "delivery_challan" || invoice_type === "packing_slip")) {
    DetailCustomField = DetailCustomField.filter(
      (e) =>
        !(
          e.name === "__cust_actual_height" ||
          e.name === "__cust_actual_width" ||
          e.name === "__cust_door_thikness" ||
          e.name === "__cust_ply_height" ||
          e.name === "__cust_ply_thk" ||
          e.name === "__cust_ply_width"
        )
    )
  }

  if (PrintBlankRow > fixRow) {
    var Count = DetailGrid.length % fixRow
    if (Count > 0) {
      PrintBlankRow = fixRow - Count
    }
  } else {
    PrintBlankRow = fixRow - PrintBlankRow
  }
  if (!isblank(DetailGrid) && DetailGrid.length > 0) {
    var itemname = []
    DetailGrid.forEach((item, index) => {
      var ItemName = item.itemname
      var appendComma = false
      var sameLine = false
      itemname = [ItemName]

      if (Print_Detail_narration === 2) {
        if (!isblank(item.narration)) {
          itemname[0] = `${itemname[0]}\n${item.narration}`
          sameLine = true
          appendComma = true
        }
      } else if (Print_Detail_narration === 3) {
        itemname[0] = `${item.narration}`
        if (isblank(itemname[0])) {
          itemname[0] = ItemName
        }
      }

      if (!isblank(item.hsn_code)) {
        if (Print_hsn === 2) {
          if (appendComma) {
            itemname[0] = `${itemname[0]}, HSN: ${item.hsn_code}`
          } else {
            if (!sameLine) {
              itemname[0] = `${itemname[0]}\nHSN: ${item.hsn_code}`
              sameLine = true
              appendComma = true
            } else {
              itemname[0] = `${itemname[0]}HSN: ${item.hsn_code}`
            }
          }
        } else if (Print_hsn === 3) {
          if (itemname[0] === ItemName) {
            itemname[0] = `${item.hsn_code}`
          } else {
            itemname[0] = `${itemname[0]}\n${item.hsn_code}`
          }
        }
      }

      if (!isblank(item.sku)) {
        if (Print_sku === 2) {
          if (appendComma) {
            itemname[0] = `${itemname[0]}, SKU: ${item.sku}`
          } else {
            if (!sameLine) {
              itemname[0] = `${itemname[0]}\nSKU: ${item.sku}`
              sameLine = true
              appendComma = true
            } else {
              itemname[0] = `${itemname[0]}SKU: ${item.sku}`
            }
          }
        } else if (Print_sku === 3) {
          if (itemname[0] === ItemName) {
            itemname[0] = `${item.sku}`
          } else {
            itemname[0] = `${itemname[0]}\n${item.sku}`
          }
        }
      }

      if (!isblank(item.itemAdvanceTracking) && parseInt(item.itemAdvanceTracking) === 1 && !isblank(item.batches)) {
        var BatchDetail = []
        BatchDetail = JSON.parse(decodeURIComponent(item.batches))
        if (!isblank(itemname[0])) {
          itemname[0] = `${itemname[0]} \n Batch: `
        } else {
          itemname[0] = `Batch: `
        }
        BatchDetail.forEach((batchItem) => {
          itemname[0] = `${itemname[0]}, ${batchItem.referencedata}: ${amount_formatDecimal(batchItem.qtydata, inventory_decimalpoint)}`
        })
      }

      if (DetailCustomField.length > 0) {
        DetailCustomField.filter(function (item) {
          return item.showInPrint === "1" && item.masterDetailLevel === "1" && (item.printWithItem === "1" || item.printWithItem === "2")
        }).forEach((customItem) => {
          var CustomData =
            parseInt(customItem.dataType) === cF.Decimal
              ? parseFloat(item[customItem.name]) === 0
                ? ""
                : amount_formatDecimal(item[customItem.name], customItem.decimalPoint)
              : parseInt(customItem.dataType) === cF.Date
                ? DatetoDMY(item[customItem.name])
                : item[customItem.name]
          if (isblank(CustomData)) {
            CustomData = ""
          }
          if (!isblank(CustomData)) {
            if (convertIntif(customItem.printWithItem) === 2) {
              if (isblank(itemname[0])) {
                itemname[0] = `${CustomData}`
              } else {
                itemname[0] = `${itemname[0]} - ${CustomData}`
              }
            } else {
              if (appendComma) {
                itemname[0] = `${itemname[0]}, ${customItem.labelName}: ${CustomData}`
              } else {
                if (!sameLine) {
                  itemname[0] = `${itemname[0]}\n${customItem.labelName}: ${CustomData}`
                  sameLine = true
                  appendComma = true
                } else {
                  itemname[0] = `${itemname[0]}${customItem.labelName}: ${CustomData}`
                  appendComma = true
                }
              }
            }
          }
        })
      }

      if (!isblank(item.itemAdvanceTracking) && parseInt(item.itemAdvanceTracking) === 2 && !isblank(item.batches)) {
        BatchDetail = []
        BatchDetail = JSON.parse(decodeURIComponent(item.batches))
        itemname[0] = `${itemname[0]} \n Serial No:`
        BatchDetail = {
          ul: BatchDetail,
        }
        itemname.push(BatchDetail)
      }

      if (parameterized && !isblank(item.parameterizedDetail) && item.parameterizedDetail.length > 0) {
        var LotDetail = []
        LotDetail = item.parameterizedDetail
        itemname[0] = `${itemname[0]} \n`
        var LotArray = []
        LotDetail.forEach((LotItem) => {
          var Lot = []
          var lotobj = {}
          lotobj.text = LotItem.description

          var qtyObj = {}
          qtyObj.text = amount_formatDecimal(LotItem.qtydata, inventory_decimalpoint)
          qtyObj.alignment = "right"
          Lot = [lotobj, qtyObj]
          LotArray.push(Lot)
        })

        var LotTable = {
          table: {
            headerRows: 1,
            widths: [80, 50],
            body: [
              [
                {
                  text: "Description",
                },
                {
                  text: "Qty",
                  alignment: "right",
                },
              ],
              ...LotArray,
            ],
          },
        }
        itemname.push(LotTable)
      }
      var singleValue = [
        {
          text: index + 1,
          style: ["itemsHeader", "center"],
        },
      ]
      if (DetailCustomField.length > 0) {
        DetailCustomField.filter(function (item) {
          return item.showInPrint === "1" && item.printWithItem === "3" && item.masterDetailLevel === "1"
        }).forEach((customItem, index) => {
          var objCustom = {}
          objCustom.text =
            parseInt(customItem.dataType) === cF.Decimal
              ? amount_formatDecimal(item[customItem.name], item.decimal)
              : parseInt(customItem.dataType) === cF.Date
                ? DatetoDMY(item[customItem.name])
                : item[customItem.name]
          objCustom.style = ["itemsHeader", parseInt(customItem.dataType) === cF.Decimal ? "right" : "left"]
          singleValue.push(objCustom)
        })
      }
      singleValue.push(itemname)
      if (Print_Detail_narration === 1) {
        detail_narration = {}
        detail_narration.text = item.narration ? item.narration : ""
        detail_narration.style = ["itemsHeader", "left"]
        singleValue.push(detail_narration)
      }

      if (MasterData.inward_type === "packing_slip" && !isblank(MasterData.custom_fields)) {
        let customFields = customFieldArrayToObj(MasterData.custom_fields)
        if (customFields && customFields.__cust_select_type === "Finish_stock" && enable_barcode === 1) {
          let barcode = {}
          barcode.text = item.barcode ? item.barcode : ""
          barcode.style = ["itemsHeader", "left"]
          singleValue.push(barcode)
        }
      }

      if (Print_hsn === 1) {
        hsn = {}
        hsn.text = item.hsn_code
        hsn.style = ["itemsHeader", "left"]
        singleValue.push(hsn)
      }

      if (Print_sku === 1) {
        sku = {}
        sku.text = item.sku
        sku.style = ["itemsHeader", "left"]
        singleValue.push(sku)
      }

      if (type === "inward" && MasterData.inward_type === "finish_stock" && enable_barcode === 1) {
        barcode = {}
        barcode.text = item.barcode ? item.barcode : ""
        barcode.style = ["itemsHeader", "left"]
        singleValue.push(barcode)
      }

      if (DetailCustomField.length > 0) {
        DetailCustomField.filter(function (item) {
          return item.showInPrint === "1" && item.printWithItem === "0" && item.masterDetailLevel === "1"
        }).forEach((customItem, index) => {
          var objCustom = {}
          var _text =
            parseInt(customItem.dataType) === cF.Decimal
              ? amount_formatDecimal(item[customItem.name], item.decimal)
              : parseInt(customItem.dataType) === cF.Date
                ? DatetoDMY(item[customItem.name])
                : item[customItem.name]
          objCustom.text = isblank(_text) ? "" : _text
          objCustom.style = ["itemsHeader", parseInt(customItem.dataType) === cF.Decimal ? "right" : "left"]
          singleValue.push(objCustom)
        })
      }

      if (template === "template7") {
        unit = {}
        unit.text = item.unitname ? item.unitname : ""
        unit.style = ["itemsHeader", "left"]
        singleValue.push(unit)
      }

      if (Business_Type === "Wood" && PrintHWT === true) {
        var THK_Stack = []
        var WID_Stack = []
        var HIT_Stack = []

        if (!isblank(item.__cust_ply_thk) && !isblank(item.__cust_actual_height) && parseInt(item.__cust_actual_height) === 0) {
          // for frame case
          if (!isblank(item.__cust_ply_thk)) {
            THK_Stack.push({
              field_id: "ply_1_jam",
              stack: [
                {
                  text: item.__cust_ply_thk,
                  // style: "stval",
                },
              ],
            })
          }

          if (!isblank(item.__cust_c__dot__m__dot___thk)) {
            THK_Stack.push({
              field_id: "cm_1_jam",
              stack: [
                {
                  text: `C.M: ${item.__cust_c__dot__m__dot___thk}`,
                  // style: "stval",
                },
              ],
            })
          }

          var THK = {
            stack: THK_Stack,
          }
          // THK.style = ["itemsHeader", ""]
          singleValue.push(THK)

          if (!isblank(item.__cust_ply_width)) {
            WID_Stack.push({
              field_id: "ply_1_jam",
              stack: [
                {
                  text: item.__cust_ply_width,
                  // style: "stval",
                },
              ],
            })
          }

          if (!isblank(item.__cust_c__dot__m__dot___width)) {
            WID_Stack.push({
              field_id: "cm_1_jam",
              stack: [
                {
                  text: `C.M: ${item.__cust_c__dot__m__dot___width}`,
                  // style: "stval",
                },
              ],
            })
          }

          var WID = {
            stack: WID_Stack,
          }

          //    WID.style = ["itemsHeader", ""]
          singleValue.push(WID)

          if (!isblank(item.__cust_ply_height)) {
            HIT_Stack.push({
              field_id: "ply_1_jam",
              stack: [
                {
                  text: item.__cust_ply_height,
                  // style: "stval",
                },
              ],
            })
          }

          if (!isblank(item.__cust_c__dot__m__dot___height)) {
            HIT_Stack.push({
              field_id: "cm_1_jam",
              stack: [
                {
                  text: `C.M: ${item.__cust_c__dot__m__dot___height}`,
                  // style: "stval",
                },
              ],
            })
          }

          var HIT = {
            stack: HIT_Stack,
          }
          //     HIT.style = ["itemsHeader", ""]
          singleValue.push(HIT)
        } else {
          // for door case
          THK_Stack.push({
            field_id: "ply_1_jam",
            stack: [
              {
                text: item.__cust_door_thikness,
                // style: "stval",
              },
            ],
          })
          var THK = {
            stack: THK_Stack,
          }
          // THK.style = ["itemsHeader", ""]
          singleValue.push(THK)

          WID_Stack.push({
            field_id: "ply_1_jam",
            stack: [
              {
                text: item.__cust_actual_width,
                // style: "stval",
              },
            ],
          })

          var WID = {
            stack: WID_Stack,
          }

          //    WID.style = ["itemsHeader", ""]
          singleValue.push(WID)

          HIT_Stack.push({
            field_id: "ply_1_jam",
            stack: [
              {
                text: item.__cust_actual_height,
                // style: "stval",
              },
            ],
          })
          var HIT = {
            stack: HIT_Stack,
          }
          //     HIT.style = ["itemsHeader", ""]
          singleValue.push(HIT)
        }
      }

      if (Business_Type === "Wood") {
        if (PrintLOT === true) {
          var LOT = {}
          LOT.text = `${!isblank(item.lot_billno) ? item.lot_billno : ""}`
          LOT.style = ["itemsHeader", "left"]
          singleValue.push(LOT)
        }

        // if (PrintSQF === true && isblank(plyFields)) {
        if (PrintSQF === true) {
          // for door
          var SQF = {}
          SQF.text = `${!isblank(item.SQF) ? item.SQF : ""}`
          SQF.style = ["itemsHeader", "right"]
          singleValue.push(SQF)
        }
      }

      Qty = {}
      Qty.text = `${amount_formatDecimal(item.qty, inventory_decimalpoint)}`

      if (!isblank(item.unit)) {
        if (Print_uom === 1) {
          uom = {}
          uom.text = item.unit
          uom.style = ["itemsHeader", "left"]
          singleValue.push(uom)
        } else if (Print_uom === 2) {
          Qty.text = `${amount_formatDecimal(item.qty, inventory_decimalpoint)}\n${item.unit}`
        } else if (Print_uom === 3) {
          Qty.text = `${amount_formatDecimal(item.qty, inventory_decimalpoint)} ${item.unit}`
        }
      }

      Qty.style = ["itemsHeader", "right"]
      singleValue.push(Qty)

      if (show_rate_amount === 1) {
        Rate = {}
        Rate.text = amountFormatOfRateV1(item.rate, rate_decimalpoint)
        Rate.style = ["itemsHeader", "right"]
        singleValue.push(Rate)

        Amount = {}
        Amount.text = amount_format(item.amt)
        Amount.style = ["itemsHeader", "right"]
        if (template !== "template7") {
          singleValue.push(Amount)
        }
      }

      if (type !== "inward" && type !== "outward" && type !== "lot_transfer" && type !== "stock_transferGodown" && type !== "packing_slip" && !hideRateAmount) {
        Rate = {}
        Rate.text = amountFormatOfRateV1(item.rate, rate_decimalpoint)
        Rate.style = ["itemsHeader", "right"]
        singleValue.push(Rate)
        if (discount_invoice === 1) {
          Discount = {}
          Discount.text = amount_format(item.discount)
          Discount.style = ["itemsHeader", "right"]
          singleValue.push(Discount)
        }
        TaxableValue = {}
        TaxableValue.text = amount_format(item.amt) //(parseInt(getAccountSitesetting("tax")) === 1 && template !== 'template7') ? amount_format(item.taxable_amt) : amount_format(item.amt);
        TaxableValue.style = ["itemsHeader", "right"]
        if (template !== "template7") {
          singleValue.push(TaxableValue)
        }
        if (tax === 1 && template !== "template5" && template !== "template7") {
          if (parseFloat(getAccountSessionUserData("statecode")) === parseFloat(placeof_supply_id)) {
            SGST = {}
            SGST.text = `${amount_format(item.sgst_amt)} \n@${item.sgst_rate}`
            SGST.style = ["itemsHeader", "right"]
            singleValue.push(SGST)

            CGST = {}
            CGST.text = `${amount_format(item.cgst_amt)}\n@${item.cgst_rate}`
            CGST.style = ["itemsHeader", "right"]
            singleValue.push(CGST)
          } else {
            IGST = {}
            IGST.text = `${amount_format(item.igst_amt)}\n@${item.igst_rate}`
            IGST.style = ["itemsHeader", "right"]
            singleValue.push(IGST)
          }
        } else if (tax === 1 && template === "template7") {
          if (parseFloat(getAccountSessionUserData("statecode")) === parseFloat(placeof_supply_id)) {
            var GST = {}
            GST.text = `${toFixedFn(parseFloat(parseFloat(item.sgst_rate) + parseFloat(item.cgst_rate)))}%`
            GST.style = ["itemsHeader", "right"]
            singleValue.push(GST)
          } else {
            IGST = {}
            IGST.text = `${item.igst_rate}%`
            IGST.style = ["itemsHeader", "right"]
            singleValue.push(IGST)
          }
        }
        if (template === "template7") {
          singleValue.push(TaxableValue)
        }
      }

      Values.push(singleValue)
    })
    if (fixRow > DetailGrid.length) {
      if (template === "template5" || template === "template6" || template === "template4" || template === "template7") {
        for (let index = 0; index < PrintBlankRow; index++) {
          var singleValue = []

          if (template === "template7") {
            if (
              Print_Detail_narration === 2 ||
              Print_hsn === 2 ||
              Print_sku === 2 ||
              (DetailCustomField.length > 0 &&
                DetailCustomField.filter(function (item) {
                  return item.showInPrint === "1" && item.printWithItem === "1" && item.masterDetailLevel === "1"
                }).length > 0)
            ) {
              singleValue = [
                {
                  text: `\n\n`,
                  style: ["itemsHeader", "center"],
                },
              ]
            } else {
              singleValue = [
                {
                  text: `\n`,
                  style: ["itemsHeader", "center"],
                },
              ]
            }
          } else {
            singleValue = [
              {
                text: `\n\n`,
                style: ["itemsHeader", "center"],
              },
            ]
          }

          if (DetailCustomField.length > 0) {
            var objCustom = {}
            var DetailCustomFieldArray = DetailCustomField.filter((item) => item.showInPrint === "1" && item.printWithItem === "3" && item.masterDetailLevel === "1")

            for (let index = 0; index < DetailCustomFieldArray.length; index++) {
              const element = DetailCustomFieldArray[index]
              objCustom = {}
              objCustom.text = ""
              objCustom.style = ["itemsHeader", parseInt(element.dataType) === cF.Decimal ? "right" : "left"]
              singleValue.push(objCustom)
            }
          }

          if (template === "template7") {
            if (
              Print_Detail_narration === 2 ||
              Print_hsn === 2 ||
              Print_sku === 2 ||
              (DetailCustomField.length > 0 &&
                DetailCustomField.filter(function (item) {
                  return item.showInPrint === "1" && item.printWithItem === "1" && item.masterDetailLevel === "1"
                }).length > 0)
            ) {
              singleValue.push([])
            } else {
              singleValue.push([])
            }
          } else {
            singleValue.push([])
          }

          if (Print_Detail_narration === 1) {
            detail_narration = {}
            detail_narration.text = ""
            detail_narration.style = ["itemsHeader", "left"]
            singleValue.push(detail_narration)
          }

          if (Print_hsn === 1) {
            hsn = {}
            hsn.text = ""
            hsn.style = ["itemsHeader", "left"]
            singleValue.push(hsn)
          }

          if (Print_sku === 1) {
            sku = {}
            sku.text = ""
            sku.style = ["itemsHeader", "left"]
            singleValue.push(sku)
          }

          if (Print_uom === 1) {
            uom = {}
            uom.text = ""
            uom.style = ["itemsHeader", "left"]
            singleValue.push(uom)
          }

          if (type === "inward" && MasterData.inward_type === "finish_stock" && enable_barcode === 1) {
            barcode = {}
            barcode.text = ""
            barcode.style = ["itemsHeader", "left"]
            singleValue.push(barcode)
          }

          if (DetailCustomField.length > 0) {
            var objCustom = {}
            var DetailCustomFieldArray = DetailCustomField.filter((item) => item.showInPrint === "1" && item.printWithItem === "0" && item.masterDetailLevel === "1")

            for (let index = 0; index < DetailCustomFieldArray.length; index++) {
              const element = DetailCustomFieldArray[index]
              objCustom = {}
              objCustom.text = ""
              objCustom.style = ["itemsHeader", parseInt(element.dataType) === cF.Decimal ? "right" : "left"]
              singleValue.push(objCustom)
            }
          }

          if (template === "template7") {
            unit = {}
            unit.text = ""
            unit.style = ["itemsHeader", "left"]
            singleValue.push(unit)
          }

          Qty = {}

          if (
            type === "inward" ||
            type === "packing_slip" ||
            type === "outward" ||
            type === "lot_transfer" ||
            type === "stock_transferGodown" ||
            template === "template2" ||
            template === "template3"
          ) {
            Qty.text = ""
          } else {
            Qty.text = ""
          }
          Qty.style = ["itemsHeader", "right"]
          singleValue.push(Qty)

          if (type !== "inward" && type !== "outward" && type !== "lot_transfer" && type !== "stock_transferGodown" && type !== "packing_slip" && !hideRateAmount) {
            Rate = {}
            Rate.text = ""
            Rate.style = ["itemsHeader", "right"]
            singleValue.push(Rate)

            if (discount_invoice === 1) {
              Discount = {}
              Discount.text = ""
              Discount.style = ["itemsHeader", "right"]
              singleValue.push(Discount)
            }

            TaxableValue = {}
            TaxableValue.text = ""
            TaxableValue.style = ["itemsHeader", "right"]

            if (template !== "template7") singleValue.push(TaxableValue)

            if (tax === 1 && template !== "template5" && template !== "template7") {
              if (parseFloat(getAccountSessionUserData("statecode")) === parseFloat(placeof_supply_id)) {
                SGST = {}
                SGST.text = ""
                SGST.style = ["itemsHeader", "right"]
                singleValue.push(SGST)

                CGST = {}
                CGST.text = ""
                CGST.style = ["itemsHeader", "right"]
                singleValue.push(CGST)
              } else {
                IGST = {}
                IGST.text = ""
                IGST.style = ["itemsHeader", "right"]
                singleValue.push(IGST)
              }
            } else if (tax === 1 && template === "template7") {
              if (parseFloat(getAccountSessionUserData("statecode")) === parseFloat(placeof_supply_id)) {
                var GST = {}
                GST.text = ""
                GST.style = ["itemsHeader", "right"]
                singleValue.push(GST)
              } else {
                IGST = {}
                IGST.text = ""
                IGST.style = ["itemsHeader", "right"]
                singleValue.push(IGST)
              }
            }

            if (template === "template7") singleValue.push(TaxableValue)
          }
          Values.push(singleValue)
        }
      }
    }

    var Footer = [
      {
        text: "",
        style: ["itemsHeader", "center"],
      },
    ]
    if (DetailCustomField.length > 0) {
      DetailCustomField.filter(function (item) {
        return item.showInPrint === "1" && item.printWithItem === "3" && item.masterDetailLevel === "1"
      }).forEach((item, index) => {
        var objCustom = {}
        if (convertIntif(item.calculateTotal) === 1) {
          var Total = 0
          DetailGrid.forEach((detailitem, index) => {
            Total = Total + parseFloat(getNumberonlyDecimal(detailitem[item.name], item.decimalPoint))
          })
          objCustom.text = amount_formatDecimal(Total, item.decimalPoint)
        } else {
          objCustom.text = ""
        }
        objCustom.style = ["itemsHeader", parseInt(item.dataType) === cF.Decimal ? "right" : "left"]
        objCustom.bold = true
        if (item.masterDetailLevel === "1") {
          Footer.push(objCustom)
        }
      })
    }

    Footer.push({
      text: "Total",
      style: ["itemsHeader", "right"],
      bold: true,
    })

    if (Print_Detail_narration === 1) {
      detail_narration = {}
      detail_narration.text = ""
      detail_narration.style = ["itemsHeader", "left"]
      Footer.push(detail_narration)
    }

    if (MasterData.inward_type === "packing_slip" && !isblank(MasterData.custom_fields)) {
      let customFields = customFieldArrayToObj(MasterData.custom_fields)
      if (customFields && customFields.__cust_select_type === "Finish_stock" && enable_barcode === 1) {
        let barcode = {}
        barcode.text = ""
        barcode.style = ["itemsHeader", "left"]
        Footer.push(barcode)
      }
    }

    if (Print_hsn === 1) {
      hsn = {}
      hsn.text = ""
      hsn.style = ["itemsHeader", "left"]
      Footer.push(hsn)
    }

    if (Print_sku === 1) {
      sku = {}
      sku.text = ""
      sku.style = ["itemsHeader", "left"]
      Footer.push(sku)
    }

    if (type === "inward" && MasterData.inward_type === "finish_stock" && enable_barcode === 1) {
      barcode = {}
      barcode.text = ""
      barcode.style = ["itemsHeader", "left"]
      Footer.push(barcode)
    }

    if (DetailCustomField.length > 0) {
      DetailCustomField.filter(function (item) {
        return item.showInPrint === "1" && item.printWithItem === "0" && item.masterDetailLevel === "1" && item.masterDetailLevel === "1"
      }).forEach((item, index) => {
        var objCustom = {}
        if (convertIntif(item.calculateTotal) === 1) {
          var Total = 0
          DetailGrid.forEach((detailitem, index) => {
            Total = Total + parseFloat(getNumberonlyDecimal(detailitem[item.name], item.decimalPoint))
          })
          objCustom.text = amount_formatDecimal(Total, item.decimalPoint)
        } else {
          objCustom.text = ""
        }
        objCustom.style = ["itemsHeader", parseInt(item.dataType) === cF.Decimal ? "right" : "left"]
        objCustom.bold = true
        Footer.push(objCustom)
      })
    }

    if (template === "template7") {
      unit = {}
      unit.text = ""
      unit.style = ["itemsHeader", "left"]
      Footer.push(unit)
    }

    if (Business_Type === "Wood" && PrintHWT === true) {
      var THK = {}
      THK.text = ""
      THK.style = ["itemsHeader", "left"]
      Footer.push(THK)

      var WID = {}
      WID.text = ""
      WID.style = ["itemsHeader", "left"]
      Footer.push(WID)

      var HIT = {}
      HIT.text = ""
      HIT.style = ["itemsHeader", "left"]
      Footer.push(HIT)
    }

    if (Business_Type === "Wood") {
      if (PrintLOT === true) {
        var LOT = {}
        LOT.text = ""
        LOT.style = ["itemsHeader", "left"]
        Footer.push(LOT)
      }

      // if (PrintSQF === true && isblank(plyFields)) {
      if (PrintSQF === true) {
        var SQFTotal = 0
        DetailGrid.forEach((detailitem) => {
          SQFTotal = SQFTotal + parseFloat(getNumberonlyDecimal(detailitem.SQF, 2))
        })

        var SQF = {}
        SQF.text = amount_formatDecimal(SQFTotal, 2)
        SQF.style = ["itemsHeader", "right"]
        SQF.bold = true
        Footer.push(SQF)
      }
    }

    if (Print_uom === 1) {
      uom = {}
      uom.text = ""
      uom.style = ["itemsHeader", "left"]
      Footer.push(uom)
    }

    Qty = {}
    // var QtyTotal = 0
    // DetailGrid.forEach((detailitem) => {
    //   QtyTotal = QtyTotal + parseFloat(getNumberonlyDecimal(detailitem.qty, inventory_decimalpoint))
    // })

    Qty.text = amount_formatDecimal(totQty, inventory_decimalpoint)
    Qty.style = ["itemsHeader", "right"]
    Qty.bold = true
    Footer.push(Qty)

    if (show_rate_amount === 1) {
      Rate = {}
      Rate.text = ""
      Rate.style = ["itemsHeader", "right"]
      Footer.push(Rate)

      Amount = {}
      Amount.text = amount_format(MasterData.totamt)
      Amount.style = ["itemsHeader", "right"]
      Amount.bold = true

      if (template !== "template7") {
        Footer.push(Amount)
      }
    }

    if (type !== "inward" && type !== "outward" && type !== "lot_transfer" && type !== "stock_transferGodown" && type !== "packing_slip" && !hideRateAmount) {
      Rate = {}
      Rate.text = ""
      Rate.style = ["itemsHeader", "right"]
      Footer.push(Rate)

      if (discount_invoice === 1) {
        Discount = {}
        Discount.text = ""
        Discount.style = ["itemsHeader", "right"]
        Footer.push(Discount)
      }

      TaxableValue = {}
      TaxableValue.text = amount_format(MasterData.totamt)
      TaxableValue.style = ["itemsHeader", "right"]
      TaxableValue.bold = true

      if (template !== "template7") Footer.push(TaxableValue)

      if (tax === 1 && template !== "template5" && template !== "template7") {
        if (parseFloat(getAccountSessionUserData("statecode")) === parseFloat(placeof_supply_id)) {
          SGST = {}
          SGST.text = amount_format(MasterData.sgst_total)
          SGST.style = ["itemsHeader", "right"]
          SGST.bold = true
          Footer.push(SGST)
          CGST = {}
          CGST.text = amount_format(MasterData.cgst_total)
          CGST.style = ["itemsHeader", "right"]
          CGST.bold = true
          Footer.push(CGST)
        } else {
          IGST = {}
          IGST.text = amount_format(MasterData.igst_total)
          IGST.style = ["itemsHeader", "right"]
          IGST.bold = true
          Footer.push(IGST)
        }
      } else if (tax === 1 && template === "template7") {
        if (parseFloat(getAccountSessionUserData("statecode")) === parseFloat(placeof_supply_id)) {
          SGST = {}
          SGST.text = ""
          SGST.style = ["itemsHeader", "right"]
          SGST.bold = true
          Footer.push(SGST)
        } else {
          IGST = {}
          IGST.text = ""
          IGST.style = ["itemsHeader", "right"]
          IGST.bold = true
          Footer.push(IGST)
        }
      }
      if (template === "template7") Footer.push(TaxableValue)
    }
    if (template !== "template2" && template !== "template5" && template !== "template7" && template !== "template6" && template !== "template4") {
      Values.push(Footer)
    }
  }
  return Values
}

export const generateDetailPSValues = ({ functions, DetailCustomField, DetailGrid, MasterData, parameterized, user_data, Print_Detail_narration, parmeterizedCustomField }) => {
  const { getAccountSitesetting, getAccountSessionUserData } = functions
  var Values = []
  var ItemArray = []
  var BatchDetail = []
  var detail_narration = ""
  var Qty = {}
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
  if (!isblank(DetailGrid) && DetailGrid.length > 0) {
    DetailGrid.forEach((item, index) => {
      var ItemName = item.itemname
      ItemArray = [{ text: ItemName, bold: true, margin: [0, 0, 0, 0] }, ""]

      if (Print_Detail_narration === 2) {
        ItemArray[1] = item.narration ? item.narration : ""
      }

      if (!isblank(item.itemAdvanceTracking) && parseInt(item.itemAdvanceTracking) === 1 && !isblank(item.batches)) {
        BatchDetail = []
        BatchDetail = JSON.parse(decodeURIComponent(item.batches))
        if (isblank(ItemArray[1])) {
          ItemArray[1] = `Batch: `
        } else {
          ItemArray[1] = `${ItemArray[1]} \n Batch: `
        }
        var BatchArray = []
        BatchDetail.forEach((batchItem) => {
          ItemArray[1] = `${ItemArray[1]}, ${batchItem.referencedata}: ${amount_formatDecimal(batchItem.qtydata, inventory_decimalpoint)}`

          /* var Batch = []
          var batchObj = {}
          batchObj.text = batchItem.referencedata

          var qtyObj = {}
          qtyObj.text = amount_formatDecimal(batchItem.qtydata, getAccountSitesetting("inventory_decimalpoint"))
          qtyObj.alignment = "right"
          Batch = [batchObj, qtyObj]
          //ItemName = `${ItemName} \n [Batch: ${batchItem.referencedata},Qty:${amount_formatDecimal(batchItem.qtydata, getAccountSitesetting("inventory_decimalpoint",user_data))} ]`
          BatchArray.push(Batch) */
        })

        /* var BatchTable = {
          table: {
            headerRows: 1,
            widths: [80, 50],
            body: [
              [
                {
                  text: "Description",
                },
                {
                  text: "Qty",
                  alignment: "right",
                },
              ],
              ...BatchArray,
            ],
          },
        }
        ItemArray.push(BatchTable) */
      }

      if (DetailCustomField.length > 0) {
        DetailCustomField.filter(function (item) {
          return item.showInPrint === "1" && item.printWithItem === "1"
        }).forEach((customItem, customIndex) => {
          var CustomData =
            parseInt(customItem.dataType) === cF.Decimal
              ? amount_formatDecimal(item[customItem.name], item.decimal)
              : parseInt(customItem.dataType) === cF.Date
                ? DatetoDMY(item[customItem.name])
                : item[customItem.name]
          if (!isblank(CustomData)) {
            if (isblank(ItemArray[1])) {
              ItemArray[1] = `${customItem.labelName}: ${CustomData}`
            } else {
              ItemArray[1] = `${ItemArray[1]}, ${customItem.labelName}: ${CustomData}`
            }
          }
        })
      }

      if (!isblank(item.itemAdvanceTracking) && parseInt(item.itemAdvanceTracking) === 2 && !isblank(item.batches)) {
        BatchDetail = []
        BatchDetail = JSON.parse(decodeURIComponent(item.batches))

        if (isblank(ItemArray[1])) {
          ItemArray[1] = `Serial No`
        } else {
          ItemArray[1] = `${ItemArray[1]} \n Serial No`
        }
        BatchDetail = {
          ul: BatchDetail,
        }
        ItemArray.push(BatchDetail)
      }

      var singleValue = [
        {
          text: item.srno,
          style: ["itemsHeader", "center"],
        },
      ]

      if (DetailCustomField.length > 0) {
        DetailCustomField.filter(function (item) {
          return item.showInPrint === "1" && item.printWithItem === "3"
        }).forEach((customItem, index) => {
          var objCustom = {}
          objCustom.text =
            parseInt(customItem.dataType) === cF.Decimal
              ? amount_formatDecimal(item[customItem.name], item.decimal)
              : parseInt(customItem.dataType) === cF.Date
                ? DatetoDMY(item[customItem.name])
                : item[customItem.name]
          objCustom.style = ["itemsHeader", parseInt(customItem.dataType) === cF.Decimal ? "right" : "left"]
          singleValue.push(objCustom)
        })
      }

      singleValue.push(ItemArray)

      if (Print_Detail_narration === 1) {
        detail_narration = {}
        detail_narration.text = item.narration ? item.narration : ""
        detail_narration.style = ["itemsHeader", "left"]
        singleValue.push(detail_narration)
      }
      if (MasterData.inward_type === "packing_slip" && !isblank(MasterData.custom_fields)) {
        let customFields = customFieldArrayToObj(MasterData.custom_fields)
        if (customFields && customFields.__cust_select_type === "Finish_stock" && enable_barcode === 1) {
          let barcode = {}
          barcode.text = item.barcode ? item.barcode : ""
          barcode.style = ["itemsHeader", "left"]
          singleValue.push(barcode)
        }
      }
      if (DetailCustomField.length > 0) {
        DetailCustomField.filter(function (item) {
          return item.showInPrint === "1" && item.printWithItem === "0"
        }).forEach((customItem, index) => {
          var objCustom = {}
          objCustom.text =
            parseInt(customItem.dataType) === cF.Decimal
              ? amount_formatDecimal(item[customItem.name], item.decimal)
              : parseInt(customItem.dataType) === cF.Date
                ? DatetoDMY(item[customItem.name])
                : item[customItem.name]
          objCustom.style = ["itemsHeader", parseInt(customItem.dataType) === cF.Decimal ? "right" : "left"]
          singleValue.push(objCustom)
        })
      }

      Qty = {}
      Qty.text = " "
      Qty.style = ["itemsHeader", "right"]
      singleValue.push(Qty)
      Values.push(singleValue)

      if (parameterized && !isblank(item.parameterizedDetail) && item.parameterizedDetail.length > 0) {
        var LotDetail = []
        LotDetail = item.parameterizedDetail
        LotDetail.forEach((LotItem) => {
          var LotItemName = LotItem.description
          parmeterizedCustomField.forEach((it) => {
            if (!isblank(LotItem[it.name])) {
              LotItemName = `${LotItemName}, ${it.labelName}: ${LotItem[it.name]}`
            } else {
              LotItemName = `${LotItemName}, ${it.labelName}: N/A`
            }
          })
          var singleValue = [
            {
              text: "",
              style: ["itemsHeader", "center"],
            },
          ]

          if (DetailCustomField.length > 0) {
            DetailCustomField.filter(function (item) {
              return item.showInPrint === "1" && item.printWithItem === "3"
            }).forEach((customItem, index) => {
              var objCustom = {}
              objCustom.text = " "
              objCustom.style = ["itemsHeader", parseInt(customItem.dataType) === cF.Decimal ? "right" : "left"]
              singleValue.push(objCustom)
            })
          }

          singleValue.push([LotItemName])

          if (Print_Detail_narration === 1) {
            detail_narration = {}
            detail_narration.text = " "
            detail_narration.style = ["itemsHeader", "left"]
            singleValue.push(detail_narration)
          }

          if (MasterData.inward_type === "packing_slip" && !isblank(MasterData.custom_fields)) {
            let customFields = customFieldArrayToObj(MasterData.custom_fields)
            if (customFields && customFields.__cust_select_type === "Finish_stock" && enable_barcode === 1) {
              let barcode = {}
              barcode.text = item.barcode ? item.barcode : ""
              barcode.style = ["itemsHeader", "left"]
              singleValue.push(barcode)
            }
          }

          if (DetailCustomField.length > 0) {
            DetailCustomField.filter(function (item) {
              return item.showInPrint === "1" && item.printWithItem === "0"
            }).forEach((customItem, index) => {
              var objCustom = {}
              objCustom.text = " "
              objCustom.style = ["itemsHeader", parseInt(customItem.dataType) === cF.Decimal ? "right" : "left"]
              singleValue.push(objCustom)
            })
          }

          Qty = {}
          Qty.text = amount_formatDecimal(LotItem.qtydata, inventory_decimalpoint)
          Qty.style = ["itemsHeader", "right"]
          singleValue.push(Qty)
          Values.push(singleValue)
        })
      }

      singleValue = [
        {
          text: "",
          style: ["itemsHeader", "center"],
        },
      ]

      if (DetailCustomField.length > 0) {
        DetailCustomField.filter(function (item) {
          return item.showInPrint === "1" && item.printWithItem === "3"
        }).forEach((customItem, index) => {
          var objCustom = {}
          objCustom.text = " "
          objCustom.style = ["itemsHeader", parseInt(customItem.dataType) === cF.Decimal ? "right" : "left"]
          singleValue.push(objCustom)
        })
      }

      singleValue.push([" "])

      if (Print_Detail_narration === 1) {
        detail_narration = {}
        detail_narration.text = " "
        detail_narration.style = ["itemsHeader", "left"]
        singleValue.push(detail_narration)
      }

      if (MasterData.inward_type === "packing_slip" && !isblank(MasterData.custom_fields)) {
        let customFields = customFieldArrayToObj(MasterData.custom_fields)
        if (customFields && customFields.__cust_select_type === "Finish_stock" && enable_barcode === 1) {
          let barcode = {}
          barcode.text = ""
          barcode.style = ["itemsHeader", "left"]
          singleValue.push(barcode)
        }
      }

      if (DetailCustomField.length > 0) {
        DetailCustomField.filter(function (item) {
          return item.showInPrint === "1" && item.printWithItem === "0"
        }).forEach((customItem, index) => {
          var objCustom = {}
          objCustom.text = " "
          objCustom.style = ["itemsHeader", parseInt(customItem.dataType) === cF.Decimal ? "right" : "left"]
          singleValue.push(objCustom)
        })
      }

      Qty = {}
      Qty.text = amount_formatDecimal(item.qty, inventory_decimalpoint)
      Qty.style = ["itemsHeader", "right"]
      Qty.bold = true
      singleValue.push(Qty)
      Values.push(singleValue)
    })

    var Footer = [
      {
        text: "",
        style: ["itemsHeader", "center"],
      },
    ]

    if (DetailCustomField.length > 0) {
      DetailCustomField.filter(function (item) {
        return item.showInPrint === "1" && item.printWithItem === "3"
      }).forEach((item, index) => {
        var objCustom = {}
        if (convertIntif(item.calculateTotal) === 1) {
          var Total = 0
          DetailGrid.forEach((detailitem, index) => {
            Total = Total + parseFloat(getNumberonlyDecimal(detailitem[item.name], item.decimalPoint))
          })
          objCustom.text = amount_formatDecimal(Total, item.decimalPoint)
        } else {
          objCustom.text = ""
        }
        objCustom.style = ["itemsHeader", parseInt(item.dataType) === cF.Decimal ? "right" : "left"]
        objCustom.bold = true
        Footer.push(objCustom)
      })
    }

    Footer.push({
      text: "Total",
      style: ["itemsHeader", "right"],
      bold: true,
    })

    if (Print_Detail_narration === 1) {
      detail_narration = {}
      detail_narration.text = ""
      detail_narration.style = ["itemsHeader", "left"]
      Footer.push(detail_narration)
    }

    if (MasterData.inward_type === "packing_slip" && !isblank(MasterData.custom_fields)) {
      let customFields = customFieldArrayToObj(MasterData.custom_fields)
      if (customFields && customFields.__cust_select_type === "Finish_stock" && enable_barcode === 1) {
        let barcode = {}
        barcode.text = ""
        barcode.style = ["itemsHeader", "left"]
        Footer.push(barcode)
      }
    }

    if (DetailCustomField.length > 0) {
      DetailCustomField.filter(function (item) {
        return item.showInPrint === "1" && item.printWithItem === "0"
      }).forEach((item, index) => {
        var objCustom = {}
        if (convertIntif(item.calculateTotal) === 1) {
          var Total = 0
          DetailGrid.forEach((detailitem, index) => {
            Total = Total + parseFloat(getNumberonlyDecimal(detailitem[item.name], item.decimalPoint))
          })
          objCustom.text = amount_formatDecimal(Total, item.decimalPoint)
        } else {
          objCustom.text = ""
        }
        objCustom.style = ["itemsHeader", parseInt(item.dataType) === cF.Decimal ? "right" : "left"]
        objCustom.bold = true
        Footer.push(objCustom)
      })
    }

    Qty = {}
    Qty.text = amount_formatDecimal(MasterData.totqty, inventory_decimalpoint)
    Qty.style = ["itemsHeader", "right"]
    Qty.bold = true
    Footer.push(Qty)
    Values.push(Footer)
  }
  return Values
}

export const generateValueByHeader = (functions, metaData) => {
  const { getAccountSitesetting, generatePdfDoc } = functions
  const {
    Headers,
    widthArray,
    DetailGrid,
    DetailCustomField,
    fixRow,
    Print_Detail_narration,
    Print_product_group,
    fontStyle,
    tax_print,
    unit_print,
    Print_hsn,
    Print_uom,
    Print_sku,
    Print_mrp,
    discount_invoice,
    print_discount,
    multi_discount,
    Print_netamount,
    parameterized,
    print_item_photo,
    MasterTableData,
  } = metaData

  var Values = []
  var itemname = []
  var PrintBlankRow = DetailGrid.length
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
  const rate_decimalpoint = getAccountSitesetting("rate_decimalpoint")

  if (PrintBlankRow > fixRow) {
    var Count = DetailGrid.length % fixRow
    if (Count > 0) {
      PrintBlankRow = fixRow - Count
    }
  } else {
    PrintBlankRow = fixRow - PrintBlankRow
  }
  if (!isblank(DetailGrid) && DetailGrid.length > 0) {
    const discountKeys = Object.keys(DetailGrid[0]).filter((key) => key.startsWith("__cust_itemDiscount_"))
    DetailGrid.forEach((item, index) => {
      var appendComma = false
      var sameLine = false
      var itemname = []

      var ItemNameTxt = [{ text: item.itemname, bold: fontStyle === "B" }]

      if (Print_product_group === 2) {
        if (!isblank(item.itemgroup_name)) {
          ItemNameTxt = [{ text: `${item.itemgroup_name} -  ${item.itemname}`, bold: fontStyle === "B" }]
        }
      }

      if (Print_product_group === 3) {
        ItemNameTxt = [{ text: `${item.itemgroup_name}`, bold: fontStyle === "B" }]
        if (isblank(item.itemgroup_name)) {
          ItemNameTxt = [{ text: `${item.itemname}`, bold: fontStyle === "B" }]
        }
      }

      if (Print_Detail_narration === 2) {
        if (!isblank(item.narration)) {
          itemname = [`${item.narration}`]
        }
      } else if (Print_Detail_narration === 3) {
        itemname = [item.narration]
        ItemNameTxt = ""
        if (isblank(item.narration)) {
          itemname = [item.itemname]
        }
      }

      if (!isblank(item.unit)) {
        if (Print_uom === 2) {
          if (itemname.length > 0) {
            itemname[0] = `${itemname[0]}, UOM: ${item.unit}`
          } else {
            itemname = [`UOM: ${item.unit}`]
          }
        } else if (Print_uom === 3) {
          if (itemname.length > 0) {
            itemname[0] = `${itemname[0]}, UOM: ${item.unit}`
          } else {
            itemname = [`UOM: ${item.unit}`]
          }
        }
      }

      if (!isblank(item.hsn_code)) {
        if (Print_hsn === 2) {
          if (itemname.length > 0) {
            itemname[0] = `${itemname[0]}, HSN: ${item.hsn_code}`
          } else {
            itemname = [`HSN: ${item.hsn_code}`]
          }
        } else if (Print_hsn === 3) {
          itemname = [`HSN: ${item.hsn_code}`]
        }
      }

      if (!isblank(item.sku)) {
        if (Print_sku === 2) {
          if (itemname.length > 0) {
            itemname[0] = `${itemname[0]}, SKU: ${item.sku}`
          } else {
            itemname = [`SKU: ${item.sku}`]
          }
        } else if (Print_sku === 3) {
          itemname = [`SKU: ${item.sku}`]
        }
      }

      if (DetailCustomField.length > 0) {
        DetailCustomField.filter(function (item) {
          return item.showInPrint === "1" && (item.printWithItem === "1" || item.printWithItem === "2")
        }).forEach((customItem, index) => {
          var CustomData =
            parseInt(customItem.dataType) === cF.Decimal
              ? parseFloat(item[customItem.name]) === 0
                ? ""
                : amount_formatDecimal(item[customItem.name], customItem.decimalPoint)
              : parseInt(customItem.dataType) === cF.Date
                ? DatetoDMY(item[customItem.name])
                : item[customItem.name]
          if (isblank(CustomData)) {
            CustomData = ""
          }
          if (!isblank(CustomData)) {
            if (convertIntif(customItem.printWithItem) === 2) {
              if (isblank(itemname[0])) {
                itemname[0] = `${customItem.labelName}: ${CustomData}`
              } else {
                if (index === 0) {
                  itemname[0] = `${itemname[0]} - ${customItem.labelName}: ${CustomData}`
                } else {
                  itemname[0] = `${itemname[0]}, ${customItem.labelName}: ${CustomData}`
                }
              }
            } else {
              if (isblank(itemname[0])) {
                itemname[0] = `${customItem.labelName}: ${CustomData}`
              } else {
                if (index === 0) {
                  itemname[0] = `${itemname[0]}\n${customItem.labelName}: ${CustomData}`
                } else {
                  itemname[0] = `${itemname[0]}, ${customItem.labelName}: ${CustomData}`
                }
              }
            }
          }
        })
      }

      /* Batch */
      if (!isblank(item.itemAdvanceTracking) && parseInt(item.itemAdvanceTracking) === 1 && !isblank(item.batches)) {
        var BatchDetail = []
        BatchDetail = JSON.parse(decodeURIComponent(item.batches))
        if (!isblank(itemname[0])) {
          itemname[0] = `${itemname[0]}\nBatch: `
        } else {
          itemname[0] = `Batch: `
        }
        BatchDetail.forEach((batchItem, index) => {
          if (index === 0) {
            itemname[0] = `${itemname[0]}${batchItem.referencedata} (${amount_formatDecimal(batchItem.qtydata, inventory_decimalpoint)})`
          } else {
            itemname[0] = `${itemname[0]}, ${batchItem.referencedata} (${amount_formatDecimal(batchItem.qtydata, inventory_decimalpoint)})`
          }
        })
      }

      /* Serial */
      if (!isblank(item.itemAdvanceTracking) && parseInt(item.itemAdvanceTracking) === 2 && !isblank(item.batches)) {
        var BatchDetail = []
        BatchDetail = JSON.parse(decodeURIComponent(item.batches))
        if (!isblank(itemname[0])) {
          itemname[0] = `${itemname[0]}\nSerial: `
        } else {
          itemname[0] = `Serial: `
        }
        BatchDetail.forEach((batchItem, index) => {
          if (index === 0) {
            itemname[0] = `${itemname[0]}${batchItem}`
          } else {
            itemname[0] = `${itemname[0]}, ${batchItem}`
          }
        })
      }

      if (parameterized && !isblank(item.lotdetail)) {
        var LotDetail = []
        LotDetail = JSON.parse(decodeURIComponent(item.lotdetail))
        var LotArray = []
        LotDetail.forEach((LotItem) => {
          var Lot = []
          var lotobj = {}
          lotobj.text = LotItem.description

          var qtyObj = {}
          qtyObj.text = amount_formatDecimal(LotItem.qtydata, inventory_decimalpoint)
          qtyObj.alignment = "right"
          Lot = [lotobj, qtyObj]
          LotArray.push(Lot)
        })

        var LotTable = {
          table: {
            headerRows: 1,
            widths: [80, 50],
            body: [
              [
                {
                  text: "Description",
                },
                {
                  text: "Qty",
                  alignment: "right",
                },
              ],
              ...LotArray,
            ],
          },
        }
        itemname.push(LotTable)
      }

      var singleValue = []
      var srno = {}
      srno.text = item.srno
      srno.style = ["itemsHeader", "left"]

      var itemgroup_name = {}
      itemgroup_name.text = item.itemgroup_name ? item.itemgroup_name : ""
      itemgroup_name.style = ["itemsHeader", "left"]

      var detail_narration = {}
      detail_narration.text = item.narration ? item.narration : ""
      detail_narration.style = ["itemsHeader", "left"]

      var uom_code = {}
      uom_code.text = item.unit
      uom_code.style = ["itemsHeader", "left"]

      var hsn_code = {}
      hsn_code.text = item.hsn_code
      hsn_code.style = ["itemsHeader", "left"]

      var sku = {}
      sku.text = item.sku
      sku.style = ["itemsHeader", "left"]

      var barcode = {}
      barcode.text = item.sku
      barcode.style = ["itemsHeader", "left"]

      var unitname = {}
      unitname.text = item.unitname ? item.unitname : ""
      unitname.style = ["itemsHeader", "left"]

      var qty = {}
      if (unit_print === 2) {
        if (!isblank(item.unitname)) {
          qty.text = `${amount_formatDecimal(item.qty, inventory_decimalpoint)}\n${item.unitname}`
        } else {
          qty.text = `${amount_formatDecimal(item.qty, inventory_decimalpoint)}`
        }
      } else if (unit_print === 3) {
        if (!isblank(item.unitname)) {
          qty.text = `${amount_formatDecimal(item.qty, inventory_decimalpoint)} ${item.unitname}`
        } else {
          qty.text = `${amount_formatDecimal(item.qty, inventory_decimalpoint)}`
        }
      } else {
        qty.text = `${amount_formatDecimal(item.qty, inventory_decimalpoint)}`
      }
      qty.style = ["itemsHeader", "right"]

      var mrp = {}
      mrp.text = amountFormatOfRateV1(item.mrp, rate_decimalpoint)
      mrp.style = ["itemsHeader", "right"]

      var rate = {}
      rate.text = amountFormatOfRateV1(item.rate, rate_decimalpoint)
      rate.style = ["itemsHeader", "right"]

      var discount = {}
      if (discount_invoice === 1 && multi_discount === 0) {
        const hasDiscount = !isblank(item.discount) && item.discount !== 0 && item.discount !== "0.00"
        if (print_discount === 1) {
          //Only Percentage
          discount.text = hasDiscount ? `${item.__cust_itemDiscount}%` : ""
        } else if (print_discount === 2) {
          //Amount with Percentage
          discount.text = hasDiscount ? `${amount_format(item.discount)}, ${item.__cust_itemDiscount}%` : ""
        } else if (print_discount === 3) {
          // Amount and Percentage in new column
          discount.text = hasDiscount ? `${amount_format(item.discount)}\n${item.__cust_itemDiscount}%` : ""
        } else {
          discount.text = hasDiscount ? amount_format(item.discount) : ""
        }
        discount.style = ["itemsHeader", "right"]
      }

      // MULTI DISCOUNT FIELDS
      var multi_discountObj = {}
      if (discount_invoice === 1 && multi_discount === 1) {
        // Moved to top for print fix
        // const discountKeys = Object.keys(item).filter((key) => key.startsWith("__cust_itemDiscount_"))
        discountKeys.forEach((key, index) => {
          const hasDiscount = !isblank(item[key]) && item[key] !== 0 && item[key] !== "0.00"
          const disKey = key.replace("__cust_itemDiscount_", "__cust_itemDiscountPer_")
          var discount = {}
          if (print_discount === 1) {
            discount.text = hasDiscount ? `${item[disKey]}%` : ""
          } else if (print_discount === 2) {
            discount.text = hasDiscount ? `${amount_format(item[key])}, ${item[disKey]}%` : ""
          } else if (print_discount === 3) {
            discount.text = hasDiscount ? `${amount_format(item[key])}\n${item[disKey]}%` : ""
          } else {
            discount.text = hasDiscount ? amount_format(item[key]) : ""
          }
          discount.style = ["itemsHeader", "right"]
          multi_discountObj[key] = discount
        })
      }

      var taxable_amt = {}
      taxable_amt.text = amount_format(item.taxable_amt)
      taxable_amt.style = ["itemsHeader", "right"]

      var amt = {}
      amt.text = amount_format(item.amt)
      amt.style = ["itemsHeader", "right"]

      var netamt = {}
      netamt.text = amount_format(item.netamount)
      netamt.style = ["itemsHeader", "right"]

      var sgst_amt = {}
      if (!isblank(item.sgst_amt) || !isblank(item.sgst_rate)) {
        if (tax_print === 1) {
          sgst_amt.text = `${amount_format(item.sgst_amt)}\n@${toFixedFn(parseFloat(item.sgst_rate))}`
        } else if (tax_print === 2) {
          sgst_amt.text = `${amount_format(item.sgst_amt)}`
        } else if (tax_print === 3) {
          sgst_amt.text = `${toFixedFn(parseFloat(item.sgst_rate))}%`
        }
      } else {
        sgst_amt.text = ""
      }

      sgst_amt.style = ["itemsHeader", "right"]

      var cgst_amt = {}
      if (!isblank(item.cgst_amt) || !isblank(item.cgst_rate)) {
        if (tax_print === 1) {
          cgst_amt.text = `${amount_format(item.cgst_amt)}\n@${toFixedFn(parseFloat(item.cgst_rate))}`
        } else if (tax_print === 2) {
          cgst_amt.text = `${amount_format(item.cgst_amt)}`
        } else if (tax_print === 3) {
          cgst_amt.text = `${amount_format(parseFloat(item.cgst_rate) + parseFloat(item.sgst_rate))}%`
        }
      } else {
        cgst_amt.text = ""
      }
      cgst_amt.style = ["itemsHeader", "right"]

      var igst_amt = {}
      if (!isblank(item.igst_amt) || !isblank(item.igst_rate)) {
        if (tax_print === 1) {
          igst_amt.text = `${amount_format(item.igst_amt)}\n@${toFixedFn(parseFloat(item.igst_rate))}`
        } else if (tax_print === 2) {
          igst_amt.text = `${amount_format(item.igst_amt)}`
        } else if (tax_print === 3) {
          igst_amt.text = `${toFixedFn(parseFloat(item.igst_rate))}%`
        }
      } else {
        igst_amt.text = ""
      }
      igst_amt.style = ["itemsHeader", "right"]

      var photo = {
        text: "",
      }
      if (print_item_photo === 1 && !isblank(item.photo)) {
        photo = {
          image: item.photo,
          fit: [40, 40],
          alignment: "center",
        }
      }

      var valueObj = {
        igst_amt,
        cgst_amt,
        sgst_amt,
        amt,
        netamt,
        taxable_amt,
        discount,
        ...multi_discountObj,
        mrp,
        rate,
        unitname,
        uom_code,
        hsn_code,
        sku,
        srno,
        detail_narration,
        itemgroup_name,
        itemname: { stack: [ItemNameTxt, ...itemname], style: ["itemsHeader"] },
        qty,
        photo,
      }

      if (DetailCustomField.length > 0 && DetailCustomField.filter((item1) => item1.showInPrint === "1" && item1.printWithItem === "3").length > 0) {
        DetailCustomField.filter((item1) => item1.showInPrint === "1" && item1.printWithItem === "3").forEach((it) => {
          var customfieldObj = {
            text: "",
            style: ["itemsHeader", convertIntif(it.dataType) === 2 ? "right" : "left"],
          }
          if (!isblank(item[it.name])) {
            if (parseInt(it.dataType) === cF.Decimal) {
              customfieldObj.text = parseFloat(item[it.name]) === 0 ? "" : amount_formatDecimal(item[it.name], it.decimalPoint)
            } else {
              customfieldObj.text = item[it.name]
            }
          }
          valueObj[it.name] = customfieldObj
        })
      }

      if (DetailCustomField.length > 0 && DetailCustomField.filter((item1) => item1.showInPrint === "1" && item1.printWithItem === "0").length > 0) {
        DetailCustomField.filter((item1) => item1.showInPrint === "1" && item1.printWithItem === "0").forEach((it) => {
          var customfieldObj = {
            text: "",
            style: ["itemsHeader", convertIntif(it.dataType) === 2 ? "right" : "left"],
          }
          if (!isblank(item[it.name])) {
            if (parseInt(it.dataType) === cF.Decimal) {
              customfieldObj.text = parseFloat(item[it.name]) === 0 ? "" : amount_formatDecimal(item[it.name], it.decimalPoint)
            } else {
              customfieldObj.text = item[it.name]
            }
          }
          valueObj[it.name] = customfieldObj
        })
      }
      Headers.forEach((item) => {
        singleValue.push(valueObj[item.field_id])
      })
      Values.push(singleValue)
    })
  }

  if (print_item_photo !== 1) {
    var rowCount = getTextMaxWidth(
      {
        absolutePosition: { x: 0, y: 0 },
        table: {
          widths: widthArray,
          body: [...Values],
        },
      },
      generatePdfDoc
    )
    if (rowCount < fixRow) {
      for (let index = 0; index < fixRow - rowCount; index++) {
        var singleValue = []
        var srno = {}
        var itemname = []

        srno.text = "\n"
        srno.style = ["itemsHeader", ""]

        var itemgroup_name = {}
        itemgroup_name.text = ""
        itemgroup_name.style = ["itemsHeader", "left"]

        var detail_narration = {}
        detail_narration.text = ""
        detail_narration.style = ["itemsHeader", "left"]

        var uom_code = {}
        uom_code.text = ""
        uom_code.style = ["itemsHeader", "left"]

        var hsn_code = {}
        hsn_code.text = ""
        hsn_code.style = ["itemsHeader", "left"]

        var sku = {}
        sku.text = ""
        sku.style = ["itemsHeader", "left"]

        var barcode = {}
        barcode.text = ""
        barcode.style = ["itemsHeader", "left"]

        var unitname = {}
        unitname.text = ""
        unitname.style = ["itemsHeader", "left"]

        var qty = {}
        qty.text = ""
        qty.style = ["itemsHeader", "right"]

        var rate = {}
        rate.text = ""
        rate.style = ["itemsHeader", "right"]

        var discount = {}
        discount.text = ""
        discount.style = ["itemsHeader", "right"]

        var taxable_amt = {}
        taxable_amt.text = ""
        taxable_amt.style = ["itemsHeader", "right"]

        var amt = {}
        amt.text = ""
        amt.style = ["itemsHeader", "right"]

        var netamt = {}
        netamt.text = ""
        netamt.style = ["itemsHeader", "right"]

        var gst_amt = {}
        gst_amt.text = ""
        gst_amt.style = ["itemsHeader", "right"]

        var igst_amt = {}
        igst_amt.text = ""
        igst_amt.style = ["itemsHeader", "right"]

        var cgst_amt = {}
        cgst_amt.text = ""
        cgst_amt.style = ["itemsHeader", "right"]

        var sgst_amt = {}
        sgst_amt.text = ""
        sgst_amt.style = ["itemsHeader", "right"]

        var photo = { text: "", style: ["itemsHeader", "right"] }

        var valueObj = {
          gst_amt,
          igst_amt,
          cgst_amt,
          sgst_amt,
          amt,
          netamt,
          taxable_amt,
          discount,
          rate,
          unitname,
          uom_code,
          hsn_code,
          sku,
          srno,
          itemgroup_name,
          detail_narration,
          itemname,
          qty,
          photo,
        }

        if (DetailCustomField.length > 0 && DetailCustomField.filter((item1) => item1.showInPrint === "1" && item1.printWithItem === "3").length > 0) {
          DetailCustomField.filter((item1) => item1.showInPrint === "1" && item1.printWithItem === "3").forEach((it) => {
            var customfieldObj = {
              text: "",
              style: ["itemsHeader", convertIntif(it.dataType) === 2 ? "right" : "left"],
            }
            valueObj[it.name] = customfieldObj
          })
        }

        if (DetailCustomField.length > 0 && DetailCustomField.filter((item1) => item1.showInPrint === "1" && item1.printWithItem === "0").length > 0) {
          DetailCustomField.filter((item1) => item1.showInPrint === "1" && item1.printWithItem === "0").forEach((it) => {
            var customfieldObj = {
              text: "",
              style: ["itemsHeader", convertIntif(it.dataType) === 2 ? "right" : "left"],
            }
            valueObj[it.name] = customfieldObj
          })
        }
        Headers.forEach((item) => {
          singleValue.push(valueObj[item.field_id])
        })
        Values.push(singleValue)
      }
    }
  }
  return Values
}

export const generateValueByHeaderThermal = (functions, metaData) => {
  const { getAccountSitesetting, generatePdfDoc } = functions
  const { DetailGrid } = metaData

  var Values = []
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
  const rate_decimalpoint = getAccountSitesetting("rate_decimalpoint")

  if (!isblank(DetailGrid) && DetailGrid.length > 0) {
    DetailGrid.forEach((item, index) => {
      let taxPer = ""
      if (!isblank(item.igst_amt) || !isblank(item.igst_rate)) {
        taxPer = `${toFixedFn(parseFloat(item.igst_rate))}%`
      } else {
        taxPer = `${amount_format(parseFloat(item.cgst_rate) + parseFloat(item.sgst_rate))}%`
      }

      Values.push([{ text: item.itemname, colSpan: 4, alignment: "left" }, {}, {}, {}])
      Values.push([
        {},
        { text: `${amount_formatDecimal(item.qty, inventory_decimalpoint)}`, style: "", alignment: "right" },
        { text: amountFormatOfRateV1(item.rate, rate_decimalpoint), style: "", alignment: "right" },
        { text: amount_format(item.amt), style: "", alignment: "right" },
      ])
      Values.push([{ text: item.hsn_code, style: "", alignment: "right" }, { text: taxPer, style: "", alignment: "right" }, {}, {}])
    })
  }

  return Values
}

export const generateDetailHeaders = ({
  functions,
  SiteSettings,
  DetailCustomField,
  type,
  placeof_supply_id,
  template,
  pageSize,
  Print_Detail_narration,
  Print_hsn,
  Print_sku,
  printdata,
  Print_uom,
  enable_barcode,
  rateAmount,
}) => {
  const { getAccountSitesetting, getAccountSessionUserData, hideRateAmount, common_getTypeWiseKeySetting, PrintHWT, PrintSQF, PrintLOT, getconfigsettingdata } = functions
  var HeaderObject = {}
  var detail_narration = ""
  var IGST = {}
  var Discount = {}
  var Rate = {}
  var unit = {}
  var hsn = {}
  var uom = {}
  var sku = {}
  var barcode = {}
  var SGST = {}
  var TaxableValue = {}
  var Amount = {}
  var CGST = {}
  var Qty = {}
  const discount_invoice = getAccountSitesetting("discount_invoice")
  const tax = parseInt(getAccountSitesetting("tax"))
  const Business_Type = getconfigsettingdata("company_status")["business_type"]

  const plyFields = DetailCustomField.find((f) => f.name === "__cust_ply_height")
  const inv_type = printdata?.master_table_data[0]?.invoice_type
  const invoice_type = inv_type ? inv_type : type

  const show_rate_amount = !isblank(rateAmount) ? rateAmount : !isblank(SiteSettings) && SiteSettings.length > 0 ? parseInt(SiteSettings[0].show_rate_amount) : 0

  if (Business_Type === "Wood" && PrintHWT === true && (invoice_type === "delivery_challan" || invoice_type === "packing_slip")) {
    DetailCustomField = DetailCustomField.filter(
      (e) =>
        !(
          e.name === "__cust_actual_height" ||
          e.name === "__cust_actual_width" ||
          e.name === "__cust_door_thikness" ||
          e.name === "__cust_ply_height" ||
          e.name === "__cust_ply_thk" ||
          e.name === "__cust_ply_width"
        )
    )
  }

  HeaderObject.widthArray = [20]
  HeaderObject.Headers = [
    {
      text: template === "template2" || template === "template3" ? "#" : "No",
      style: ["itemsHeader", ""],
      fillColor: template === "template3" ? "#cce6ff" : "#ffffff",
      bold: true,
    },
  ]

  if (DetailCustomField.length > 0) {
    DetailCustomField.filter(function (item) {
      return item.showInPrint === "1" && item.printWithItem === "3"
    }).forEach((item, index) => {
      var objCustom = {}
      objCustom.text = item.labelName
      objCustom.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
      objCustom.style = ["itemsHeader", parseInt(item.dataType) === cF.Decimal ? "right" : "left"]
      objCustom.bold = true
      HeaderObject.Headers.push(objCustom)
      HeaderObject.widthArray.push(parseFloat(item.width))
    })
  }

  HeaderObject.Headers.push({
    text: type === "packing_slip" ? "Description" : "Description",
    style: ["itemsHeader", ""],
    bold: true,
    fillColor: template === "template3" ? "#cce6ff" : "#ffffff",
  })
  HeaderObject.widthArray.push("*")

  if (Print_Detail_narration === 1) {
    detail_narration = {}
    detail_narration.text = "Narration"
    detail_narration.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
    detail_narration.style = ["itemsHeader", ""]
    detail_narration.bold = true
    HeaderObject.Headers.push(detail_narration)
    HeaderObject.widthArray.push(pageSize === "A5" ? 75 : 100)
  }

  if (type === "packing_slip" && !isblank(printdata) && !isblank(printdata.master_table_data) && !isblank(printdata.master_table_data[0]) && !isblank(printdata.master_table_data[0].custom_fields)) {
    let customFields = customFieldArrayToObj(printdata.master_table_data[0].custom_fields)
    if (customFields && customFields.__cust_select_type === "Finish_stock" && enable_barcode === 1) {
      let barcode = {}
      barcode.text = "Barcode"
      barcode.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
      barcode.style = ["itemsHeader", ""]
      barcode.bold = true
      HeaderObject.Headers.push(barcode)
      HeaderObject.widthArray.push(pageSize === "A5" ? 50 : 75)
    }
  }

  if (Print_hsn === 1) {
    hsn = {}
    hsn.text = "HSN/SAC"
    hsn.style = ["itemsHeader", ""]
    hsn.bold = true
    HeaderObject.Headers.push(hsn)
    HeaderObject.widthArray.push(pageSize === "A5" ? 30 : 40)
  }

  if (Print_sku === 1) {
    sku = {}
    sku.text = "SKU"
    sku.style = ["itemsHeader", ""]
    sku.bold = true
    HeaderObject.Headers.push(sku)
    HeaderObject.widthArray.push(pageSize === "A5" ? 30 : 40)
  }

  if (type === "inward" && printdata && printdata.master_table_data && printdata.master_table_data[0] && printdata.master_table_data[0].invoice_type === "finish_stock" && enable_barcode === 1) {
    let barcode = {}
    barcode.text = "Barcode"
    barcode.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
    barcode.style = ["itemsHeader", ""]
    barcode.bold = true
    HeaderObject.Headers.push(barcode)
    HeaderObject.widthArray.push(pageSize === "A5" ? 50 : 75)
  }

  if (DetailCustomField.length > 0) {
    DetailCustomField.filter(function (item) {
      return item.showInPrint === "1" && item.printWithItem === "0" && item.masterDetailLevel === "1"
    }).forEach((item, index) => {
      var objCustom = {}
      objCustom.text = item.labelName
      objCustom.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
      objCustom.style = ["itemsHeader", parseInt(item.dataType) === cF.Decimal ? "right" : "left"]
      objCustom.bold = true
      HeaderObject.Headers.push(objCustom)
      HeaderObject.widthArray.push(parseFloat(item.width))
    })
  }

  if (template === "template7") {
    unit = {}
    unit.text = "Unit"
    unit.style = ["itemsHeader", ""]
    unit.bold = true
    HeaderObject.Headers.push(unit)
    HeaderObject.widthArray.push(pageSize === "A5" ? 15 : 20)
  }

  if (Business_Type === "Wood" && PrintHWT === true) {
    var THK = {}
    THK.text = "Thicknes"
    THK.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
    THK.style = ["itemsHeader", "center"]
    THK.bold = true
    HeaderObject.Headers.push(THK)
    HeaderObject.widthArray.push(35)

    var WID = {}
    WID.text = "Width"
    WID.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
    WID.style = ["itemsHeader", "center"]
    WID.bold = true
    HeaderObject.Headers.push(WID)
    HeaderObject.widthArray.push(35)

    var HIT = {}
    HIT.text = "Height"
    HIT.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
    HIT.style = ["itemsHeader", "center"]
    HIT.bold = true
    HeaderObject.Headers.push(HIT)
    HeaderObject.widthArray.push(35)
  }

  if (Business_Type === "Wood") {
    if (PrintLOT === true) {
      var LOT = {}
      LOT.text = "LOT"
      LOT.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
      LOT.style = ["itemsHeader", "center"]
      LOT.bold = true
      HeaderObject.Headers.push(LOT)
      HeaderObject.widthArray.push(50)
    }

    // if (PrintSQF === true && isblank(plyFields)) {
    if (PrintSQF === true) {
      var SQF = {}
      SQF.text = "Sq.Ft"
      SQF.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
      SQF.style = ["itemsHeader", "right"]
      SQF.bold = true
      HeaderObject.Headers.push(SQF)
      HeaderObject.widthArray.push(30)
    }
  }

  if (Print_uom === 1) {
    uom = {}
    uom.text = "UOM"
    uom.style = ["itemsHeader", ""]
    uom.bold = true
    HeaderObject.Headers.push(uom)
    HeaderObject.widthArray.push(pageSize === "A5" ? 30 : 40)
  }

  Qty = {}
  Qty.text = "Qty"
  Qty.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
  Qty.style = ["itemsHeader", "right"]
  Qty.bold = true
  HeaderObject.Headers.push(Qty)

  if (show_rate_amount === 1) {
    Rate = {}
    Rate.text = "Rate"
    Rate.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
    Rate.style = ["itemsHeader", "right"]
    Rate.bold = true
    HeaderObject.Headers.push(Rate)
    HeaderObject.widthArray.push(50)

    Amount = {}
    Amount.text = "Amount"
    Amount.style = ["itemsHeader", "right"]
    Amount.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
    Amount.bold = true

    if (template !== "template7") {
      HeaderObject.Headers.push(Amount)
      HeaderObject.widthArray.push(50)
    }
  }

  if (type === "inward" || type === "outward" || type === "lot_transfer" || type === "stock_transferGodown" || type === "packing_slip") {
    HeaderObject.widthArray.push(55)
  } else {
    HeaderObject.widthArray.push(40)
  }

  if (type !== "inward" && type !== "outward" && type !== "lot_transfer" && type !== "stock_transferGodown" && type !== "packing_slip" && !hideRateAmount) {
    Rate = {}
    Rate.text = "Rate"
    Rate.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
    Rate.style = ["itemsHeader", "right"]
    Rate.bold = true
    HeaderObject.Headers.push(Rate)
    HeaderObject.widthArray.push(50)

    if (discount_invoice === 1) {
      Discount = {}
      Discount.text = "Discount"
      Discount.style = ["itemsHeader", "right"]
      Discount.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
      Discount.bold = true
      HeaderObject.Headers.push(Discount)
      HeaderObject.widthArray.push(50)
    }

    TaxableValue = {}
    TaxableValue.text = "Amount" //(template === 'template2' || template === 'template3' || template === 'template5' || template === 'template7') ? 'Amount' : 'Taxable Value';
    TaxableValue.style = ["itemsHeader", "right"]
    TaxableValue.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
    TaxableValue.bold = true

    if (template !== "template7") {
      HeaderObject.Headers.push(TaxableValue)
      HeaderObject.widthArray.push(50)
    }

    if (tax === 1 && template !== "template5" && template !== "template7") {
      if (parseFloat(getAccountSessionUserData("statecode")) === parseFloat(placeof_supply_id)) {
        SGST = {}
        SGST.text = "SGST"
        SGST.style = ["itemsHeader", "right"]
        SGST.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
        SGST.bold = true
        HeaderObject.Headers.push(SGST)
        HeaderObject.widthArray.push(50)

        CGST = {}
        CGST.text = "CGST"
        CGST.style = ["itemsHeader", "right"]
        CGST.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
        CGST.bold = true
        HeaderObject.Headers.push(CGST)
        HeaderObject.widthArray.push(50)
      } else {
        IGST = {}
        IGST.text = "IGST"
        IGST.style = ["itemsHeader", "right"]
        IGST.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
        IGST.bold = true
        HeaderObject.Headers.push(IGST)
        HeaderObject.widthArray.push(50)
      }
    } else if (tax === 1 && template === "template7") {
      if (parseFloat(getAccountSessionUserData("statecode")) === parseFloat(placeof_supply_id)) {
        SGST = {}
        SGST.text = "GST"
        SGST.style = ["itemsHeader", "right"]
        SGST.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
        SGST.bold = true
        HeaderObject.Headers.push(SGST)
        HeaderObject.widthArray.push(40)
      } else {
        IGST = {}
        IGST.text = "IGST"
        IGST.style = ["itemsHeader", "right"]
        IGST.fillColor = template === "template3" ? "#cce6ff" : "#ffffff"
        IGST.bold = true
        HeaderObject.Headers.push(IGST)
        HeaderObject.widthArray.push(40)
      }
    }
    if (template === "template7") {
      HeaderObject.Headers.push(TaxableValue)
      HeaderObject.widthArray.push(50)
    }
  }

  return HeaderObject
}

export const calculateFixRowV1 = ({ fixSingleRow, otherDataLength, pdfFontSize, irn }) => {
  var fixRow = fixSingleRow //(template === 'template4') ? 10 : (template === 'template7') ? 24 : 13;
  if (otherDataLength > 0) {
    fixRow = fixRow - otherDataLength - 1
  }
  if (pdfFontSize === 9) {
    fixRow = fixRow - 5
  } else if (pdfFontSize === 8.5) {
    fixRow = fixRow - 2
  } else if (pdfFontSize === 7) {
    fixRow = fixRow + 2
  }
  if (!isblank(irn)) {
    fixRow = fixRow - 9
  }
  return fixRow
}

export const ObjectbyString = (o, s) => {
  if (isblank(s)) {
    return o
  }
  s = s.replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties
  s = s.replace(/^\./, "") // strip a leading dot
  var a = s.split(".")
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i]
    if (k in o) {
      o = o[k]
    } else {
      return
    }
  }
  return o
}

export const setObjectByString = (object, string, value) => {
  if (!isblank(object) && !isblank(string)) {
    string = string.replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties
    string = string.replace(/^\./, "") // strip a leading dot
    var a = string.split(".")
    for (var i = 0; i < a.length - 1; i++) {
      if (!isblank(object)) {
        object = object[a[i]]
      }
    }
    if (!isblank(object)) {
      object[a[i]] = value
    }
  }
}

export const generateTotalByHeader = (metaData) => {
  const { Grid_Line } = metaData
  var TotalRow = metaData.TotalRow
  var topBorder = convertIntif(Grid_Line) === 1 || convertIntif(Grid_Line) === 3 ? false : true
  TotalRow.forEach((item, index) => {
    var colSpan = 1
    var colId = ""
    if (item.colspan) {
      colId = item.field_id
      var customTotal = false
      TotalRow.forEach((it) => {
        if (convertIntif(it.calculateTotal) === 1) {
          customTotal = true
        }
        if (it.colspanWith === colId && customTotal === false) {
          colSpan = colSpan + 1
        }
      })
      TotalRow[index].colSpan = colSpan
    }
  })

  TotalRow = TotalRow.map((item, index) => {
    let updatedItem = { ...item }
    updatedItem.border = [true, true, true, true]
    updatedItem.fillColor = "#dddddd"
    updatedItem.bold = true

    if (updatedItem.field_id === "itemname") {
      updatedItem.alignment = "right"
    }

    delete updatedItem.field_id
    return updatedItem
  })

  return TotalRow
}

export const generatecustomFieldBody = ({ numberOfStack, stackData, border, topBorder }) => {
  var size = Math.ceil(stackData.length / numberOfStack)
  var stack = chunk(stackData, size, numberOfStack)
  var body = []
  stack.forEach((item, itemIndex) => {
    var obj = {}
    obj.stack = item
    if (border) {
      obj.border = [true, !isblank(topBorder) ? topBorder : true, itemIndex === stack.length - 1, false]
      obj.margin = [5, 5, 5, 5]
    } else {
      obj.border = [false, false, false, false]
      obj.margin = [0, 0, 0, 0]
    }
    body.push(obj)
  })
  body = [body]
  return body
}

function chunkV2(items, size) {
  const chunks = []
  items = [].concat(...items)

  while (items.length) {
    chunks.push(items.splice(0, size))
  }

  return chunks
}

export const generatecustomFieldBodyV2 = (numberOfStack, stackData) => {
  var stack = chunkV2(stackData, numberOfStack)
  var body = []
  stack.forEach((item, itemIndex) => {
    item.forEach((item1, itemIndex1) => {
      if (typeof body[itemIndex1] === "undefined") {
        body.push([])
      }
      if (body[itemIndex1].length > 0 && body[itemIndex1][0].hasOwnProperty("stack")) {
        var array_body = body[itemIndex1][0].stack
        array_body.push(item1)
        body[itemIndex1][0].stack = array_body
      } else {
        var obj = {}
        obj.stack = [item1]
        body[itemIndex1] = [obj]
      }
    })

    if (numberOfStack !== item.length) {
      //check remaining item
      for (let remitem = item.length; remitem < numberOfStack; remitem++) {
        if (typeof body[remitem] === "undefined") {
          body.push([])
        }
        if (body[remitem].length > 0 && body[remitem][0].hasOwnProperty("stack")) {
          var array_body = body[remitem][0].stack
          array_body.push({})
          body[remitem][0].stack = array_body
        } else {
          var obj = {}
          obj.stack = [{}]
          body[remitem] = [obj]
        }
      }
    }
  })
  body = [body]
  return body
}

export const generatecustomFieldBodyV4 = (numberOfStack, stackData) => {
  var stack = chunkV2(stackData, numberOfStack)
  var body = []
  stack.forEach((item, itemIndex) => {
    var innerbody = []
    item.forEach((item1, itemIndex1) => {
      //LABEL
      innerbody.push({
        text: item1.label,
        border: [true, true, true, true],
        margin: [0, 5, 0, 5],
        textTransform: "uppercase",
      })

      //Value
      innerbody.push({
        text: item1.value,
        border: [false, true, true, true],
        alignment: "left",
        margin: [0, 5, 0, 5],
        textTransform: "uppercase",
        bold: true,
      })
    })

    /*var newitem = item.map(
      (obj) => (
        {
          text: obj.label,
          border: [true, true, true, true],
          margin: [0, 5, 0, 5],
          textTransform: "uppercase",
        },
        {
          text: obj.value,
          border: [false, true, true, true],
          alignment: "left",
          margin: [0, 5, 0, 5],
          textTransform: "uppercase",
          bold: true,
        }
      )
    )*/

    body.push(innerbody)

    /* item.forEach((item1, itemIndex1) => {
      if (typeof body[itemIndex] === "undefined") {
        body.push([])
      }
      if (body[itemIndex].length > 0) {
        var obj_body = body[itemIndex]
        body[itemIndex] = [obj_body, item1]
      } else {
        body[itemIndex] = item1
      }
    })*/

    if (numberOfStack !== item.length) {
      //check remaining item
      var obj_body = body[itemIndex]
      obj_body.push(
        {
          text: " ",
          border: [true, true, true, true],
          margin: [0, 5, 0, 5],
          textTransform: "uppercase",
        },
        {
          text: " ",
          border: [false, true, true, true],
          alignment: "left",
          margin: [0, 5, 0, 5],
          textTransform: "uppercase",
          bold: true,
        }
      )
      body[itemIndex] = obj_body
    }
  })
  //body = [body]
  return body
}

export const generatecustomFieldBodyV3 = (customFieldStructure, stackData) => {
  var numberOfStack = customFieldStructure.table.widths.length
  var stack = chunkV2(stackData, numberOfStack)

  // customFieldStructure.table.body

  var allbody = []
  stack.forEach((item, itemIndex) => {
    var body = []
    item.forEach((item1, itemIndex1) => {
      if (typeof body[itemIndex1] === "undefined") {
        body.push([])
      }
      var obj = {}

      var value = !isblank(item1.image) ? { image: item1.image, fit: [100, 100], alignment: "center" } : { text: item1.value, bold: true, style: "valueField" }

      obj.stack = [{ text: item1.label }, value]
      if (itemIndex1 % 2) {
        obj.border = [false, false, false, true]
      } else {
        obj.border = [false, false, true, true]
      }

      body[itemIndex1] = obj
      //}
    })

    if (numberOfStack !== item.length) {
      //check remaining item
      for (let remitem = item.length; remitem < numberOfStack; remitem++) {
        if (typeof body[remitem] === "undefined") {
          body.push([])
        }
        var obj = {}
        obj.stack = [{}]
        if (remitem % 2) {
          obj.border = [false, false, false, true]
        } else {
          obj.border = [false, false, true, true]
        }
        body[remitem] = obj
        //}
      }
    }
    allbody.push(body)
  })
  return allbody
}

export const generatePhotoFieldBody = (customFieldStructure, stackData) => {
  var numberOfStack = customFieldStructure.table.widths.length
  var stack = chunkV2(stackData, numberOfStack)

  // customFieldStructure.table.body

  var allbody = []
  stack.forEach((item, itemIndex) => {
    var body = []
    item.forEach((item1, itemIndex1) => {
      if (typeof body[itemIndex1] === "undefined") {
        body.push([])
      }
      var obj = {}

      var value = { image: item1.name, fit: [item1.print_width, item1.print_width], width: item1.print_width, alignment: "center" }

      obj.stack = [{ text: item1.label }, value]
      body[itemIndex1] = obj
      //}
    })

    if (numberOfStack !== item.length) {
      //check remaining item
      for (let remitem = item.length; remitem < numberOfStack; remitem++) {
        if (typeof body[remitem] === "undefined") {
          body.push([])
        }
        var obj = {}
        obj.stack = [{}]
        body[remitem] = obj
        //}
      }
    }
    allbody.push(body)
  })
  return allbody
}

export const generatecustomFieldBodyV5 = (customFieldStructure, stackData, border = true) => {
  var numberOfStack = customFieldStructure.table.widths.length
  var stack = chunkV2(stackData, numberOfStack)

  // customFieldStructure.table.body

  var allbody = []
  stack.forEach((item, itemIndex) => {
    var body = []
    item.forEach((item1, itemIndex1) => {
      if (typeof body[itemIndex1] === "undefined") {
        body.push([])
      }
      var obj = {}
      var value = !isblank(item1.image) ? { image: item1.image, fit: [40, 40], alignment: "center" } : { text: item1.value, bold: true, style: "valueField" }

      obj.columns = [{ text: item1.label }, value]
      if (border) {
        obj.border = [true, true, true, true]
      } else {
        obj.border = [false, false, false, false]
      }
      body[itemIndex1] = obj
    })

    if (numberOfStack !== item.length) {
      //check remaining item
      for (let remitem = item.length; remitem < numberOfStack; remitem++) {
        if (typeof body[remitem] === "undefined") {
          body.push([])
        }
        var obj = {}
        obj.columns = [{}]
        if (border) {
          obj.border = [true, true, true, true]
        } else {
          obj.border = [false, false, false, false]
        }
        body[remitem] = obj
      }
    }
    allbody.push(body)
  })
  return allbody
}

export const generatecustomFieldStack = (numberOfStack, stackData) => {
  var size = Math.ceil(stackData.length / numberOfStack)
  var stack = chunk(stackData, size, numberOfStack)
  var obj = {
    stack: [],
  }
  stack.forEach((item) => {
    obj.stack.push(item)
  })
  return obj
}

export const calculateFixRow = ({ template, otherDataLength, Print_Detail_narration, DetailCustomField, pdfFontSize, Print_hsn, Print_sku, Print_uom }) => {
  var fixRow = template === "template4" ? 10 : template === "template7" ? 24 : 13
  if (template === "template7") {
    if (otherDataLength > 0) {
      fixRow = fixRow - otherDataLength - 1
    }
    if (pdfFontSize === 9) {
      fixRow = fixRow - 5
    } else if (pdfFontSize === 8.5) {
      fixRow = fixRow - 2
    } else if (pdfFontSize === 7) {
      fixRow = fixRow + 2
    }

    if (
      Print_Detail_narration === 2 ||
      Print_hsn === 2 ||
      Print_sku === 2 ||
      (DetailCustomField.length > 0 &&
        DetailCustomField.filter(function (item) {
          return item.showInPrint === "1" && item.printWithItem === "1"
        }).length > 0)
    ) {
      fixRow = 14
      if (otherDataLength > 0) {
        fixRow = fixRow - Math.ceil(otherDataLength / 2)
      }
      if (pdfFontSize === 9) {
        fixRow = fixRow - 2
      } else if (pdfFontSize === 8.5) {
        fixRow = fixRow - 1
      } else if (pdfFontSize === 7) {
        fixRow = 16
      }
    }
  }
  return fixRow
}

export const masterCustomField = ({ customField, Values, alignment, type, valueBold }) => {
  var CustomField = []
  var LabelWidth = alignment === "left" ? "auto" : "*"
  customField.forEach((item) => {
    var obj = {}
    if (item.showInPrint === "1") {
      if (parseInt(item.dataType) === cF.Photo) {
        if (!isblank(Values[item.name]) && item.printWithItem !== "4") {
          CustomField.push([
            { text: item.labelName },
            {
              image: Values[item.name],
              fit: [100, 100],
            },
          ])
        }
      } else if (parseInt(item.dataType) === cF.Dropbox) {
        if (Values[item.name] && Values[item.name].length > 0) {
          Values[item.name].forEach((i) => {
            if (item.printWithItem !== "4") {
              CustomField.push([
                { text: item.labelName },
                {
                  image: i.name,
                  fit: [100, 100],
                },
              ])
            }
          })
        }
      } else {
        if (type === "valueLabel") {
          obj = {
            label: `${item.labelName}`,
            value: convertIntif(item.dataType) === 3 ? DatetoDMY(Values[item.name]) : Values[item.name],
          }
          if (isblank(obj.value)) {
            obj.value = ""
          }
          CustomField.push(obj)
        } else if (!isblank(Values[item.name])) {
          obj = {
            columns: [
              {
                width: LabelWidth,
                text: `${item.labelName}:`,
              },
              {
                margin: [2, 0, 0, 0],
                text: convertIntif(item.dataType) === 3 ? DatetoDMY(Values[item.name]) : Values[item.name],
                bold: valueBold,
              },
            ],
          }
          CustomField.push(obj)
        } else if (type === "blankValueAccept") {
          obj = {
            columns: [
              {
                width: LabelWidth,
                text: `${item.labelName}:`,
              },
              {
                margin: [2, 0, 0, 0],
                text: "",
                bold: valueBold,
              },
            ],
          }
          CustomField.push(obj)
        }
      }
    }
  })
  return CustomField
}

export const masterCustomFieldV1 = ({ customFieldStructure, MasterCustomField, Values }) => {
  var newCustomField = []
  MasterCustomField.forEach((item) => {
    var obj = {}
    if (item.showInPrint === "1") {
      obj = {
        label: `${item.labelName}`,
        value: convertIntif(item.dataType) === 3 ? DatetoDMY(Values[item.name]) : Values[item.name],
      }
      if (isblank(obj.value)) {
        obj.value = ""
      }
      newCustomField.push(obj)
    }
  })
  var size = Math.ceil(newCustomField.length / customFieldStructure.column)
  var stack = chunk(newCustomField, customFieldStructure.column, size)
  var returnCustomField = {}
  if (stack.length > 0) {
    var LabelWidth = customFieldStructure.labelwidth ? customFieldStructure.labelwidth : "auto"
    let sepratorWith = customFieldStructure.sepratorWith ? customFieldStructure.sepratorWith : "value"
    if (customFieldStructure.mode === "stack") {
      returnCustomField = []
      stack.forEach((item) => {
        const singleItem = []
        item.forEach((e) => {
          if (sepratorWith === "label") {
            singleItem.push({
              columns: [
                { width: LabelWidth, text: `${e.label}: `, style: customFieldStructure.labelStyle, bold: customFieldStructure.labelBold },
                { text: `${e.value}`, style: customFieldStructure.valueStyle, bold: customFieldStructure.valueBold },
              ],
            })
          } else {
            singleItem.push({
              columns: [
                { width: LabelWidth, text: e.label, style: customFieldStructure.labelStyle, bold: customFieldStructure.labelBold },
                { text: `: ${e.value}`, style: customFieldStructure.valueStyle, bold: customFieldStructure.valueBold },
              ],
            })
          }
        })
        returnCustomField.push({ columns: singleItem })
      })
    }
  } else {
    if (customFieldStructure.mode === "stack") {
      returnCustomField = []
    }
  }
  return returnCustomField
}

export const masterCustomFieldV2 = (MasterCustomField, Values) => {
  var newCustomField = []
  MasterCustomField.forEach((item) => {
    var obj = {}
    if (item.showInPrint === "1") {
      if (parseInt(item.dataType) === cF.Photo) {
        if (!isblank(Values[item.name]) && item.printWithItem !== "4") {
          newCustomField.push({ label: item.labelName, image: Values[item.name] })
        }
      } else if (parseInt(item.dataType) === cF.Dropbox) {
        if (Values[item.name] && Values[item.name].length > 0) {
          Values[item.name].forEach((i) => {
            if (item.printWithItem !== "4") {
              newCustomField.push({ label: item.labelName, image: i.name })
            }
          })
        }
      } else {
        obj = {
          label: `${item.labelName}`,
          value: convertIntif(item.dataType) === 3 ? DatetoDMY(Values[item.name]) : Values[item.name],
        }
        if (isblank(obj.value)) {
          obj.value = ""
        }
        newCustomField.push(obj)
      }
    }
  })

  return newCustomField
}

export const chunk = (array, size, numberOfStack) => {
  const chunkedArray = []
  for (var i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size))
  }
  var chunkedArray1 = cloneObj(chunkedArray)
  if (size === 1) {
    for (var j = 0; j < numberOfStack - chunkedArray.length; j++) {
      chunkedArray1.push([])
    }
  }
  return chunkedArray1
}

export const customFirstStackLength = (numberOfStack, stackData) => {
  var stack = []
  for (let index = 1; index <= numberOfStack; index++) {
    stack[index - 1] = []
  }
  stackData.forEach((item, itemIndex) => {
    for (let index = 1; index <= numberOfStack; index++) {
      if ((itemIndex + 1) % numberOfStack === index) {
        stack[index - 1].push(item)
      } else if ((itemIndex + 1) % numberOfStack === 0) {
        stack[numberOfStack - 1].push(item)
      }
    }
  })
  return stack[0].length
}

export const getTotalOfData = (props) => {
  const { getAccountSitesetting, data, columns } = props
  const inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
  const siteSettingForformatField = {
    inventory_decimalpoint: inventory_decimalpoint,
    rate_decimalpoint: getAccountSitesetting("rate_decimalpoint"),
    timezone: getAccountSitesetting("timezone"),
  }
  const returnValue = {}
  columns.forEach((e) => {
    if (e.total === "label") {
      returnValue[e.id] = "Total"
    } else if (e.total) {
      if (e.format === "qty_decimal") {
        returnValue[e.id] = amount_formatDecimal(lodashSum(lodashMap(data, (d) => parseFloat(getNumberonlyDecimal(d[e.id], inventory_decimalpoint)))), inventory_decimalpoint)
      } else if (!isblank(e.decimalPoint)) {
        returnValue[e.id] = amount_formatDecimal(lodashSum(lodashMap(data, (d) => parseFloat(getNumberonlyDecimal(d[e.id], e.decimalPoint)))), e.decimalPoint)
      } else {
        if (e.format) {
          returnValue[e.id] = format_report_fieldV1({ value: lodashSum(lodashMap(data, (d) => parseFloat(getNumberonly(d[e.id])))), format: e.format, localSetting: siteSettingForformatField })
        } else {
          returnValue[e.id] = amount_format(lodashSum(lodashMap(data, (d) => parseFloat(getNumberonly(d[e.id])))))
        }
      }
    }
  })
  return returnValue
}

export const generateTableData = ({ data, mycol, user_data, requireTotal, totalDifference, functions }) => {
  const { getAccountSitesetting } = functions
  const siteSettingForformatField = {
    inventory_decimalpoint: getAccountSitesetting("inventory_decimalpoint"),
    rate_decimalpoint: getAccountSitesetting("rate_decimalpoint"),
    timezone: getAccountSitesetting("timezone"),
  }

  var PDF_DATA = data.map((n, index) => {
    var row = mycol.map((column) => {
      column.isbold = false
      if (column.bold) {
        if (column.bold === true) {
          column.isbold = true
        } else {
          var checkcol = column["bold"]["valueblank"]
          if (checkcol.length > 0) {
            for (var i = 0; i < checkcol.length; i++) {
              var colname = column["bold"]["valueblank"][i]
              if (n[colname] === "" || n[colname] === "0.00") {
                column.isbold = true
                break
              }
            }
          }
        }
        if (column.bold === true) {
          column.isbold = true
        }
      }
      var align = column.numeric ? "right" : "left"
      var isbold = column.isbold
      var txt = n[column.id]

      if (isblank(txt)) {
        txt = ""
      }

      if (column.printcharcount && txt.length > column.printcharcount) {
        txt = breakString(txt, column.printcharcount)
      }
      if (column.customField && column.customFieldType === "2" && !isblank(txt)) {
        txt = amount_formatDecimal(txt, column.decimalPoint)
      }
      if (column.format === "indian_rupee" || column.format === "Dr_Cr_indian_rupee") {
        txt = format_report_fieldV1({ value: txt, format: column.format })
      } else if (!isblank(column.format)) {
        txt = format_report_fieldV1({ value: txt, format: column.format, localSetting: siteSettingForformatField })
      }
      return { text: txt, alignment: align, bold: isbold }
    })
    return row
  })
  var fianlArray = []
  if (requireTotal) {
    mycol.forEach((colItem) => {
      var obj = {}
      if (colItem.total === "label") {
        obj.text = "Total"
        obj.alignment = "right"
        // obj.fontSize = 8
        obj.bold = true
      } else if (colItem.total === "label_count") {
        obj.text = `Total ${data.length}`
        obj.alignment = "right"
        // obj.fontSize = 8
        obj.bold = true
      } else if (colItem.total === "lastRow") {
        obj.text = ""
        if (data.length > 0) {
          if (colItem.format === "qty_decimal") {
            obj.text = amount_formatDecimal(data[data.length - 1][colItem.id], siteSettingForformatField.inventory_decimalpoint)
          } else if (!isblank(colItem.decimalPoint)) {
            obj.text = amount_formatDecimal(data[data.length - 1][colItem.id], colItem.decimalPoint)
          } else {
            if (colItem.format) {
              obj.text = format_report_fieldV1({ value: data[data.length - 1][colItem.id], format: colItem.format, localSetting: siteSettingForformatField })
            } else {
              obj.text = amount_format(data[data.length - 1][colItem.id])
            }
          }
        }
        obj.alignment = "right"
        // obj.fontSize = 8
        obj.bold = true
      } else if (colItem.total) {
        if (colItem.format === "qty_decimal") {
          obj.text = amount_formatDecimal(
            lodashSum(lodashMap(data, (d) => parseFloat(getNumberonlyDecimal(d[colItem.id], siteSettingForformatField.inventory_decimalpoint)))),
            siteSettingForformatField.inventory_decimalpoint
          )
        } else if (!isblank(colItem.decimalPoint)) {
          obj.text = amount_formatDecimal(lodashSum(lodashMap(data, (d) => parseFloat(getNumberonlyDecimal(d[colItem.id], colItem.decimalPoint)))), colItem.decimalPoint)
        } else {
          if (colItem.format) {
            obj.text = format_report_fieldV1({ value: lodashSum(lodashMap(data, (d) => parseFloat(getNumberonly(d[colItem.id])))), format: colItem.format, localSetting: siteSettingForformatField })
          } else {
            obj.text = amount_format(lodashSum(lodashMap(data, (d) => parseFloat(getNumberonly(d[colItem.id])))))
          }
        }
        obj.alignment = "right"
        // obj.fontSize = 8
        obj.bold = true
      }
      fianlArray.push(obj)
    })
    PDF_DATA.push(fianlArray)
  }
  if (totalDifference) {
    fianlArray = []
    mycol.forEach((colItem) => {
      var obj = {}
      if (!isblank(colItem.totalDifferenceLabel)) {
        obj.text = colItem.totalDifferenceLabel
        obj.alignment = "right"
        // obj.fontSize = 8
        obj.bold = true
      } else if (!isblank(colItem.totalDifference)) {
        var differenceFromData = mycol.find((e) => e.id === colItem.totalDifference.diferenceFrom)
        var differenceOfData = mycol.find((e) => e.id === colItem.totalDifference.diferenceOf)
        differenceFromData.total = 0
        differenceOfData.total = 0
        if (differenceFromData.format === "qty_decimal") {
          differenceFromData.total = lodashSum(lodashMap(data, (d) => parseFloat(getNumberonlyDecimal(d[differenceFromData.id], siteSettingForformatField.inventory_decimalpoint))))
        } else {
          differenceFromData.total = lodashSum(lodashMap(data, (d) => parseFloat(getNumberonly(d[differenceFromData.id]))))
        }
        if (differenceOfData.format === "qty_decimal") {
          differenceOfData.total = lodashSum(lodashMap(data, (d) => parseFloat(getNumberonlyDecimal(d[differenceOfData.id], siteSettingForformatField.inventory_decimalpoint))))
        } else {
          differenceOfData.total = lodashSum(lodashMap(data, (d) => parseFloat(getNumberonly(d[differenceOfData.id]))))
        }
        var differenceFrom =
          differenceFromData.format === "qty_decimal" ? getNumberonlyDecimal(differenceFromData.total, siteSettingForformatField.inventory_decimalpoint) : getNumberonly(differenceFromData.total)
        var differenceOf =
          differenceOfData.format === "qty_decimal" ? getNumberonlyDecimal(differenceOfData.total, siteSettingForformatField.inventory_decimalpoint) : getNumberonly(differenceOfData.total)
        colItem.Difference = parseFloat(differenceFrom) - parseFloat(differenceOf)
        obj.text = colItem.format === "qty_decimal" ? amount_formatDecimal(colItem.Difference, siteSettingForformatField.inventory_decimalpoint) : amount_format(colItem.Difference)
        obj.alignment = "right"
        // obj.fontSize = 8
        obj.bold = true
      }
      fianlArray.push(obj)
    })
    PDF_DATA.push(fianlArray)
  }

  return PDF_DATA
}

export const customFieldPrint = ({ value, key, custom_fields_data }) => {
  var label = ""
  if (custom_fields_data.filter((e) => e.name === key).length > 0) {
    label = custom_fields_data.filter((e) => e.name === key)[0].labelName
    if (isblank(value)) {
      return `${label}: N/A`
    } else {
      return `${label}: ${value}`
    }
  }
  return ""
}

export const generateCompanyBankDetail = (banker) => {
  var returnValue = JSON.stringify({ text: "", width: 0 })
  if (!isblank(banker)) {
    try {
      const banker1 = JSON.parse(banker)
      if (!isblank(banker1)) {
        const stackData = []
        if (!isblank(banker1.bankAccountName)) {
          stackData.push({
            columns: [
              { text: "Account Name", width: 60, bold: true },
              { text: `: ${banker1.bankAccountName}`, width: "*" },
            ],
          })
        }
        if (!isblank(banker1.bankAccountNo)) {
          stackData.push({
            columns: [
              { text: "Account No", width: 60, bold: true },
              { text: `: ${banker1.bankAccountNo}`, width: "*" },
            ],
          })
        }
        if (!isblank(banker1.bankNameAndBranch)) {
          stackData.push({
            columns: [
              { text: "Bank & Branch", width: 60, bold: true },
              { text: `: ${banker1.bankNameAndBranch}`, width: "*" },
            ],
          })
        }
        if (!isblank(banker1.bankIFSC)) {
          stackData.push({
            columns: [
              { text: "IFSC Code", width: 60, bold: true },
              { text: `: ${banker1.bankIFSC}`, width: "*" },
            ],
          })
        }
        if (!isblank(banker1.upiId)) {
          stackData.push({
            columns: [
              { text: "UPI ID", width: 60, bold: true },
              { text: `: ${banker1.upiId}`, width: "*" },
            ],
          })
        }
        returnValue = { stack: stackData, width: "*" }
        returnValue = JSON.stringify(returnValue)
      }
    } catch (error) {
      // returnValue = replacefunction(JSON.stringify(banker))
    }
  }
  return returnValue
}

export const generateCompanyBankDetailV2 = (banker, no = 1) => {
  var returnValue = JSON.stringify({ text: "", width: 0 })
  if (!isblank(banker)) {
    try {
      const banker1 = JSON.parse(banker)
      if (!isblank(banker1)) {
        const stackData = []
        if (!isblank(banker1.bankAccountName)) {
          stackData.push({
            columns: [
              { text: "A/c Name: ", width: 50, bold: true },
              { text: `${banker1.bankAccountName}`, width: "*" },
            ],
          })
        }
        if (!isblank(banker1.bankNameAndBranch)) {
          stackData.push({
            columns: [
              { text: "Bank Name: ", width: 50, bold: true },
              { text: `${banker1.bankNameAndBranch}`, width: "*" },
            ],
          })
        }
        if (!isblank(banker1.bankAccountNo)) {
          stackData.push({
            columns: [
              { text: "A/c No: ", width: 50, bold: true },
              { text: `${banker1.bankAccountNo}`, width: "*" },
            ],
          })
        }
        if (!isblank(banker1.bankIFSC)) {
          stackData.push({
            columns: [
              { text: "IFSC Code: ", width: 50, bold: true },
              { text: `${banker1.bankIFSC}`, width: "*" },
            ],
          })
        }
        if (!isblank(banker1.upiId)) {
          stackData.push({
            columns: [
              { text: "UPI ID: ", width: 50, bold: true },
              { text: `${banker1.upiId}`, width: "*" },
            ],
          })
        }
        returnValue = { stack: stackData, width: "*" }
        returnValue = JSON.stringify(returnValue)
      }
    } catch (error) {
      returnValue = replacefunction(JSON.stringify(banker))
    }
  }
  return returnValue
}

export const generateCompanyBankDetailWithQR = (banker, no = 1) => {
  var returnValue = JSON.stringify({ text: "", width: 0 })
  if (!isblank(banker)) {
    try {
      const banker1 = JSON.parse(banker)
      const enBank = encodeURI(banker1.bankAccountName)
      if (!isblank(banker1)) {
        const stackData = []
        if (no == 2) {
          //stackData.push({ canvas: [{ type: "line", x1: 0, y1: 0, x2: 150, y2: 0, lineWidth: 1 }], margin: [0, 5, 0, 5] })
        }
        if (!isblank(banker1.bankAccountName)) {
          stackData.push({
            columns: [
              { text: "Account Name", width: 60, bold: true },
              { text: `: ${banker1.bankAccountName}`, width: "*" },
            ],
          })
        }
        if (!isblank(banker1.bankAccountNo)) {
          stackData.push({
            columns: [
              { text: "Account No", width: 60, bold: true },
              { text: `: ${banker1.bankAccountNo}`, width: "*" },
            ],
          })
        }
        if (!isblank(banker1.bankNameAndBranch)) {
          stackData.push({
            columns: [
              { text: "Bank & Branch", width: 60, bold: true },
              { text: `: ${banker1.bankNameAndBranch}`, width: "*" },
            ],
          })
        }
        if (!isblank(banker1.bankIFSC)) {
          stackData.push({
            columns: [
              { text: "IFSC Code", width: 60, bold: true },
              { text: `: ${banker1.bankIFSC}`, width: "*" },
            ],
          })
        }
        if (!isblank(banker1.upiId)) {
          stackData.push({
            columns: [
              { text: "UPI ID", width: 60, bold: true },
              { text: `: ${banker1.upiId}`, width: "*" },
            ],
          })
        }
        if (!isblank(banker1.upiId)) {
          returnValue = {
            columns: [
              { stack: stackData, width: "*" },
              { qr: `upi://pay?pa=${banker1.upiId}&pn=${enBank}&cu=INR`, fit: "70", width: 70 },
            ],
            columnGap: 5,
          }
        } else {
          returnValue = {
            columns: [{ stack: stackData, width: "*" }],
          }
        }

        returnValue = JSON.stringify(returnValue)
      }
    } catch (error) {
      returnValue = replacefunction(JSON.stringify(banker))
    }
  }
  return returnValue
}

export const isblankBankDetail = (banker1, banker2) => {
  if (isblank(banker1) && isblank(banker2)) {
    return true
  }
  var returnValue = true
  if (!isblank(banker1)) {
    try {
      const banker1Detail = JSON.parse(banker1)
      if (!isblank(banker1Detail)) {
        returnValue = false
      }
    } catch (error) {
      returnValue = false
    }
  }
  if (returnValue && !isblank(banker2)) {
    try {
      const banker2Detail = JSON.parse(banker2)
      if (!isblank(banker2Detail)) {
        returnValue = false
      }
    } catch (error) {
      returnValue = false
    }
  }
  return returnValue
}

export const delIt = (obj, id) => {
  return (obj.children = obj.children.filter(function (child) {
    if (child.children) delIt(child, id)
    return child.field_id != id
  }))
}

export const findNode = (currentNode, key, value) => {
  var currentChild, result
  if (!isblank(currentNode) && value == currentNode[key]) {
    return currentNode
  } else {
    for (let i in currentNode) {
      currentChild = currentNode[i]
      if (typeof currentChild === "object") {
        // Search in the current child
        result = findNode(currentChild, key, value)
        // Return the result if the node has been found
        if (result !== false) {
          return result
        }
      }
    }
    return false
  }
}

export const jnestedReplace = ({ input, search_key, searchValue, newValue, replacePath }) => {
  // replacePath === from searchobject key to replace
  // Validate for input, searchValue and newValue
  // throws error if any value is undefined/null
  if (!input || !searchValue || !newValue) {
    throw new Error("JSON, searchValue, newValue cannot be zero")
  }

  // If input is not json
  if (!isObject(input) && !isArray(input)) {
    throw new Error("Invalid JSON")
  }

  // Iterate over the object and find and replace values
  for (let key in input) {
    // If type is object, call the same function recursively
    if (isObject(input[key])) {
      input[key] = jnestedReplace({ input: input[key], search_key, searchValue, newValue, replacePath })
      continue
    }

    // If type is array, call the same function recursively
    // for every element of array
    if (isArray(input[key])) {
      for (let i = 0; i < input[key].length; i++) {
        input[key][i] = jnestedReplace({ input, search_key, searchValue, newValue, replacePath })
      }
      continue
    }
    // If the key needs to be skipped.
    // Do not process and continue to next element
    if (key === search_key && input[key] === searchValue) {
      if (replacePath) {
        setObjectByString(input, replacePath, newValue)
      } else {
        input = newValue
      }
    }
  }
  return input
}

// Checks if data is an object
const isObject = (data) => {
  return data instanceof Object
}

// checks if data is an array
const isArray = (data) => {
  return data instanceof Array
}

export const removeEmptyElements = (o) => {
  for (var k in o) {
    if (!o[k] || typeof o[k] !== "object") {
      continue // If null or not an object, skip to the next iteration
    }
    // The property is an object
    removeEmptyElements(o[k]) // <-- Make a recursive call on the nested object
    if (Object.keys(o[k]).length === 0) {
      delete o[k] // The object had no properties, so delete that property
    }
  }
}

export const removeNullElements = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => (v && typeof v === "object" ? removeNullElements(v) : v)).filter((v) => !(v == null))
  } else {
    return Object.entries(obj)
      .map(([k, v]) => [k, v && typeof v === "object" ? removeNullElements(v) : v])
      .reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {})
  }
}

export function divideArrayInColumn(array, split_size) {
  var chunks = []
  var index = 0
  for (var i = 0; i < array.length; i += split_size) {
    var temp = array.slice(i, i + split_size)
    chunks[index] = temp
    index++
  }
  return chunks
}

export const addBlank = (dataArray, minLength) => {
  let returnArray = [...dataArray]
  if (dataArray.length < minLength) {
    for (var i = 0; i < minLength - dataArray.length; i++) {
      returnArray.push({})
    }
  }
  return returnArray
}

export const generateMasterCustomFieldsTable = (MasterCustomField, printdata) => {
  let masterCustmFields = masterCustomField({ customField: MasterCustomField, Values: printdata.master_table_data[0], alignment: "left" })
  let customFieldRow = divideArrayInColumn(masterCustmFields, 4)
  let customFieldTable = []

  if (customFieldRow.length > 0) {
    customFieldRow[customFieldRow.length - 1] = addBlank(customFieldRow[customFieldRow.length - 1], 4)
    customFieldTable = [
      {
        table: {
          widths: ["*", "*", "*", "*"],
          body: customFieldRow,
        },
        layout: "noBorders",
        width: "*",
        margin: [0, 0, 0, 15],
      },
    ]
  }
  return customFieldTable
}

export const generateMasterCustomFieldsTableV2 = (MasterCustomField, printdata) => {
  let masterCustmFields = masterCustomField({ customField: MasterCustomField, Values: printdata.master_table_data[0], alignment: "left" })
  let customFieldRow = divideArrayInColumn(masterCustmFields, 2)
  let customFieldTable = []

  if (customFieldRow.length > 0) {
    customFieldRow[customFieldRow.length - 1] = addBlank(customFieldRow[customFieldRow.length - 1], 2)
    customFieldTable = [
      {
        table: {
          widths: ["*", "*"],
          body: customFieldRow,
        },
        layout: "noBorders",
        width: "*",
      },
    ]
  }
  return customFieldTable
}

export const generateMasterCustomFieldsTableV3 = (MasterCustomField, printdata) => {
  let masterCustmFields = masterCustomField({ customField: MasterCustomField, Values: printdata.master_table_data[0], alignment: "left" })
  let customFieldRow = divideArrayInColumn(masterCustmFields, 4)
  let customFieldTable = []

  if (customFieldRow.length > 0) {
    customFieldRow[customFieldRow.length - 1] = addBlank(customFieldRow[customFieldRow.length - 1], 4)
    customFieldTable = [
      {
        table: {
          widths: ["*", "*", "*", "*"],
          body: customFieldRow,
        },
        //layout: "noBorders",
        //width: "*",
      },
    ]
  }
  return customFieldTable
}

export const getPrintValue = (printdata, key) => {
  if (isblank(printdata) || isblank(key)) {
    return ""
  }

  if (key === "company_data") {
    if (!isblank(printdata.cmpy_data) && printdata.cmpy_data.length > 0) {
      return {
        company_name: !isblank(printdata.cmpy_data[0].name) ? printdata.cmpy_data[0].name : "",
        company_address1: !isblank(printdata.cmpy_data[0].address) ? replacefunction(replaceBRtoNewLine(printdata.cmpy_data[0].address)) : "",
        company_address2: "",
        company_state: !isblank(printdata.cmpy_data[0].state) ? printdata.cmpy_data[0].state : "",
        company_city: !isblank(printdata.cmpy_data[0].city) ? printdata.cmpy_data[0].city : "",
        company_zipcode: !isblank(printdata.cmpy_data[0].zipcode) ? printdata.cmpy_data[0].zipcode : "",
        company_street: !isblank(printdata.cmpy_data[0].street) ? replacefunction(replaceBRtoNewLine(printdata.cmpy_data[0].street)) : "",
        company_gstin: !isblank(printdata.cmpy_data[0].gstin) ? printdata.cmpy_data[0].gstin : "",
        company_mobile: !isblank(printdata.cmpy_data[0].mobile) ? printdata.cmpy_data[0].mobile : "",
        company_phone: !isblank(printdata.cmpy_data[0].phoneO) ? printdata.cmpy_data[0].phoneO : "",
        company_email: !isblank(printdata.cmpy_data[0].email) ? printdata.cmpy_data[0].email : "",
        company_cin: !isblank(printdata.cmpy_data[0].cin) ? printdata.cmpy_data[0].cin : "",
        company_tinno: !isblank(printdata.cmpy_data[0].tinno) ? printdata.cmpy_data[0].tinno : "",
        company_iec: !isblank(printdata.cmpy_data[0].iec) ? printdata.cmpy_data[0].iec : "",
        company_pan: !isblank(printdata.cmpy_data[0].pan) ? printdata.cmpy_data[0].pan : "",
        company_aadhaar_no: !isblank(printdata.cmpy_data[0].aadhaar_no) ? printdata.cmpy_data[0].aadhaar_no : "",
      }
    } else {
      return {
        company_name: "",
        company_address1: "",
        company_address2: "",
        company_street: "",
        company_gstin: "",
        company_mobile: "",
        company_phone: "",
        company_email: "",
        company_cin: "",
        company_tinno: "",
        company_iec: "",
        company_pan: "",
        company_aadhaar_no: "",
        company_state: "",
        company_city: "",
        company_zipcode: "",
      }
    }
  } else if (key === "company_address2") {
    if (!isblank(printdata.cmpy_data) && printdata.cmpy_data.length > 0) {
      var company_address2 = ""
      const company_street = !isblank(printdata.cmpy_data[0].street) ? replacefunction(replaceBRtoNewLine(printdata.cmpy_data[0].street)) : ""
      const company_state = !isblank(printdata.cmpy_data[0].state) ? printdata.cmpy_data[0].state : ""
      const company_city = !isblank(printdata.cmpy_data[0].city) ? printdata.cmpy_data[0].city : ""
      const company_zipcode = !isblank(printdata.cmpy_data[0].zipcode) ? printdata.cmpy_data[0].zipcode : ""

      if (!isblank(company_street)) {
        company_address2 = `${company_street}`
      }
      if (!isblank(company_city)) {
        if (!isblank(company_address2)) {
          company_address2 = `${company_address2}, ${company_city}`
        } else {
          company_address2 = `${company_city}`
        }
      }
      if (!isblank(company_state)) {
        if (!isblank(company_address2)) {
          company_address2 = `${company_address2}, ${company_state}`
        } else {
          company_address2 = `${company_state}`
        }
      }
      if (!isblank(company_zipcode)) {
        if (!isblank(company_address2)) {
          company_address2 = `${company_address2}, ${company_zipcode}`
        } else {
          company_address2 = `${company_zipcode}`
        }
      }
      return company_address2
    } else {
      return ""
    }
  } else if (key === "company_contact") {
    if (!isblank(printdata.cmpy_data) && printdata.cmpy_data.length > 0) {
      var companyContact = ""
      const company_mobile = !isblank(printdata.cmpy_data[0].mobile) ? printdata.cmpy_data[0].mobile : ""
      const company_phone = !isblank(printdata.cmpy_data[0].phoneO) ? printdata.cmpy_data[0].phoneO : ""

      if (!isblank(company_phone)) {
        companyContact = `${company_phone}`
      }
      if (!isblank(company_mobile)) {
        if (!isblank(companyContact)) {
          companyContact = `${companyContact}, ${company_mobile}`
        } else {
          companyContact = `${company_mobile}`
        }
      }

      return companyContact
    } else {
      return ""
    }
  } else if (key === "company_other_no") {
    if (!isblank(printdata.cmpy_data) && printdata.cmpy_data.length > 0) {
      var companyOtherNumbers = ""
      const company_cin = !isblank(printdata.cmpy_data[0].cin) ? printdata.cmpy_data[0].cin : ""
      const company_tinno = !isblank(printdata.cmpy_data[0].tinno) ? printdata.cmpy_data[0].tinno : ""
      const company_iec = !isblank(printdata.cmpy_data[0].iec) ? printdata.cmpy_data[0].iec : ""

      if (!isblank(company_cin)) {
        companyOtherNumbers = company_cin
      }
      if (!isblank(company_tinno)) {
        if (!isblank(companyOtherNumbers)) {
          companyOtherNumbers = `${companyOtherNumbers}, ${company_tinno}`
        } else {
          companyOtherNumbers = company_tinno
        }
      }
      if (!isblank(company_iec)) {
        if (!isblank(companyOtherNumbers)) {
          companyOtherNumbers = `${companyOtherNumbers}, ${company_iec}`
        } else {
          companyOtherNumbers = company_iec
        }
      }
      return companyOtherNumbers
    } else {
      return ""
    }
  } else if (key === "company_logo") {
    if (!isblank(printdata.base64_logo)) {
      return `data:image/png;base64,${printdata.base64_logo}`
    } else {
      return ""
    }
  } else if (key === "company_signature") {
    if (!isblank(printdata.base64_signature)) {
      return `data:image/png;base64,${printdata.base64_signature}`
    } else {
      return ""
    }
  } else if (key === "party_data") {
    if (!isblank(printdata.user_details) && printdata.user_details.length > 0) {
      return {
        party_name: !isblank(printdata.user_details[0].name) ? printdata.user_details[0].name : "",
        party_address1: !isblank(printdata.user_details[0].address) ? replacefunction(replaceBRtoNewLine(printdata.user_details[0].address)) : "",
        party_address2: "",
        party_state: !isblank(printdata.user_details[0].state) ? printdata.user_details[0].state : "",
        party_city: !isblank(printdata.user_details[0].city) ? printdata.user_details[0].city : "",
        party_zipcode: !isblank(printdata.user_details[0].zipcode) ? printdata.user_details[0].zipcode : "",
        party_street: !isblank(printdata.user_details[0].street) ? replacefunction(replaceBRtoNewLine(printdata.user_details[0].street)) : "",
        party_gstin: !isblank(printdata.user_details[0].gstin) ? printdata.user_details[0].gstin : "",
        party_mobile: !isblank(printdata.user_details[0].mobile) ? printdata.user_details[0].mobile : "",
        party_phone: !isblank(printdata.user_details[0].phoneO) ? printdata.user_details[0].phoneO : "",
        party_email: !isblank(printdata.user_details[0].email) ? printdata.user_details[0].email : "",
        party_pan: !isblank(printdata.user_details[0].pan) ? printdata.user_details[0].pan : "",
        party_aadhaar_no: !isblank(printdata.user_details[0].aadhaar_no) ? printdata.user_details[0].aadhaar_no : "",
      }
    } else {
      return {
        party_name: "",
        party_address1: "",
        party_address2: "",
        party_street: "",
        party_gstin: "",
        party_mobile: "",
        party_phone: "",
        party_email: "",
        party_pan: "",
        party_aadhaar_no: "",
        party_state: "",
        party_city: "",
        party_zipcode: "",
      }
    }
  } else if (key === "party_address2") {
    if (!isblank(printdata.user_details) && printdata.user_details.length > 0) {
      var party_address2 = ""
      const party_street = !isblank(printdata.user_details[0].street) ? replacefunction(replaceBRtoNewLine(printdata.user_details[0].street)) : ""
      const party_state = !isblank(printdata.user_details[0].state) ? printdata.user_details[0].state : ""
      const party_city = !isblank(printdata.user_details[0].city) ? printdata.user_details[0].city : ""
      const party_zipcode = !isblank(printdata.user_details[0].zipcode) ? printdata.cmpy_data[0].zipcode : ""

      if (!isblank(party_street)) {
        party_address2 = `${party_street}`
      }
      if (!isblank(party_city)) {
        if (!isblank(party_address2)) {
          party_address2 = `${party_address2}, ${party_city}`
        } else {
          party_address2 = `${party_city}`
        }
      }
      if (!isblank(party_state)) {
        if (!isblank(party_address2)) {
          party_address2 = `${party_address2}, ${party_state}`
        } else {
          party_address2 = `${party_state}`
        }
      }
      if (!isblank(party_zipcode)) {
        if (!isblank(party_address2)) {
          party_address2 = `${party_address2}, ${party_zipcode}`
        } else {
          party_address2 = `${party_zipcode}`
        }
      }
      return party_address2
    } else {
      return ""
    }
  } else if (key === "party_contact") {
    if (!isblank(printdata.user_details) && printdata.user_details.length > 0) {
      var partyContact = ""
      const party_mobile = !isblank(printdata.user_details[0].mobile) ? printdata.user_details[0].mobile : ""
      const party_phone = !isblank(printdata.user_details[0].phoneO) ? printdata.user_details[0].phoneO : ""

      if (!isblank(party_phone)) {
        partyContact = `${party_phone}`
      }
      if (!isblank(party_mobile)) {
        if (!isblank(partyContact)) {
          partyContact = `${partyContact}, ${party_mobile}`
        } else {
          partyContact = `${party_mobile}`
        }
      }

      return partyContact
    } else {
      return ""
    }
  } else if (key === "new_party_address") {
    if (!isblank(printdata.user_details) && printdata.user_details.length > 0) {
      var party_address2 = ""
      const party_street = !isblank(printdata.user_details[0].areacode) ? replacefunction(replaceBRtoNewLine(printdata.user_details[0].areacode)) : ""
      const party_state = !isblank(printdata.user_details[0].statename) ? printdata.user_details[0].statename : ""
      const party_city = !isblank(printdata.user_details[0].cityname) ? printdata.user_details[0].cityname : ""
      const party_zipcode = !isblank(printdata.user_details[0].pincode) ? printdata.user_details[0].pincode : ""

      if (!isblank(party_street)) {
        party_address2 = `${party_street}`
      }
      if (!isblank(party_city)) {
        if (!isblank(party_address2)) {
          party_address2 = `${party_address2}, ${party_city}`
        } else {
          party_address2 = `${party_city}`
        }
      }
      if (!isblank(party_state)) {
        if (!isblank(party_address2)) {
          party_address2 = `${party_address2}, ${party_state}`
        } else {
          party_address2 = `${party_state}`
        }
      }
      if (!isblank(party_zipcode)) {
        if (!isblank(party_address2)) {
          party_address2 = `${party_address2}, ${party_zipcode}`
        } else {
          party_address2 = `${party_zipcode}`
        }
      }
      return party_address2
    } else {
      return ""
    }
  }
}

export const removeTempField = (headerString, array_key_value) => {
  if (isblank(headerString) || isblank(array_key_value)) {
    return headerString
  }
  headerString = JSON.parse(headerString)
  array_key_value.forEach((e, index) => {
    if (isblank(e.value)) {
      if (e.hasOwnProperty("width")) {
        headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: e.field_id, newValue: { width: e.width, text: "" } })
      } else {
        headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: e.field_id, newValue: {} })
      }
    }
  })
  headerString = JSON.stringify(headerString)
  return headerString
}

export const generateDetailValuesMutiColumn = ({ functions, SiteSettings, printdata, meta, noColumnPrint, pageSize, PageOrientation }) => {
  const { getAccountSitesetting, getAccountSessionUserData, hideRateAmount } = functions

  let inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")
  var fixSingleRow = 0
  var barcode = false
  if (SiteSettings.length > 0) {
    fixSingleRow = parseInt(SiteSettings[0].fixSingleRow)
    barcode = SiteSettings[0].barcode
  }
  let mainBody = []
  let mainBody2 = []

  let columnData = []
  if (barcode === true) {
    columnData = [
      { id: "sr_no", label: "No", width: 15 },
      { id: "barcode", label: "Barcode", width: 33 },
      { id: "__cust_avg_weight", numeric: true, label: "Wt Rng", width: 27, total: "label", decimal: inventory_decimalpoint },
      { id: "__cust_pcs", numeric: true, label: "Qty", width: "*", total: true, decimal: 0 },
      { id: "qty", numeric: true, label: "Gross Wt", width: 38, total: true, decimal: inventory_decimalpoint },
    ]
  } else {
    columnData = [
      { id: "sr_no", label: "No", width: 15 },
      { id: "__cust_avg_weight", numeric: true, label: "Wt Rng", width: 44, total: "label", decimal: inventory_decimalpoint },
      { id: "__cust_pcs", numeric: true, label: "Qty", width: "*", total: true, decimal: 0 },
      { id: "qty", numeric: true, label: "Gross Wt", width: 44, total: true, decimal: inventory_decimalpoint },
    ]
  }

  let requireTotalRow = columnData.filter((e) => e.total === true).length > 0
  let tableWidth = columnData.map((e) => e.width)
  var tableValues = []
  let DetailGrid = []

  printdata.details_table_data.forEach((e) => {
    if (DetailGrid.filter((i) => i.itemname === e.itemname).length > 0) {
      DetailGrid = DetailGrid.map((i) => {
        const updated = { ...i }
        if (updated.itemname === e.itemname) {
          updated.detail = [...updated.detail, e]
        }
        return updated
      })
    } else {
      DetailGrid.push({
        itemname: e.itemname,
        detail: [e],
      })
    }
  })

  DetailGrid.forEach((it, index) => {
    let srNo = 0
    let itemNameRow = []
    columnData.forEach((e, cIndex) => {
      if (cIndex > 0) {
        itemNameRow.push({ text: "" })
      }
    })
    tableValues.push([{ text: it.itemname, bold: true, colSpan: columnData.length, alignment: "center" }, ...itemNameRow])
    let headerRow = []
    columnData.forEach((k) => {
      if (k.id === "sr_no") {
        headerRow.push({ text: k.label, bold: true })
      } else {
        headerRow.push({ text: k.label, bold: true, alignment: k.numeric ? "right" : "left" })
      }
    })
    tableValues.push(headerRow)
    let rowTotal = {}
    it.detail.forEach((j) => {
      let rowValue = []
      columnData.forEach((k) => {
        if (k.id === "sr_no") {
          srNo = srNo + 1
          rowValue.push({ text: srNo })
        } else {
          if (!isblank(k.decimal)) {
            rowValue.push({ text: amount_formatDecimal(j[k.id], k.decimal), alignment: k.numeric ? "right" : "left" })
          } else {
            rowValue.push({ text: j[k.id], alignment: k.numeric ? "right" : "left" })
          }
        }
        if (k.total === true) {
          rowTotal[k.id] = amount_formatDecimal(numForCalc(rowTotal[k.id], k.decimal) + numForCalc(j[k.id], k.decimal), k.decimal)
        }
      })
      tableValues.push(rowValue)
    })
    if (requireTotalRow) {
      let totalRow = []
      columnData.forEach((k) => {
        if (k.total === "label") {
          totalRow.push({ text: "Total", bold: true })
        } else if (k.total === true) {
          totalRow.push({ text: rowTotal[k.id], bold: true, alignment: k.numeric ? "right" : "left" })
        } else {
          totalRow.push({ text: "", bold: true, alignment: k.numeric ? "right" : "left" })
        }
      })
      tableValues.push(totalRow)
    }

    tableValues.push([{ text: "  ", colSpan: barcode === true ? 5 : 4, border: [false, false, false, false] }, ...itemNameRow])
  })

  // FIXME: need new setting for dynamic this no of lines
  let n = fixSingleRow //tweak this to add more items per line
  const col = noColumnPrint //NO OF ROW

  const first_page = n * col
  let table_values = tableValues
  let table_values2 = []
  if (tableValues.length > first_page) {
    table_values = tableValues.slice(0, first_page)
    table_values2 = tableValues.slice(first_page, tableValues.length)
  }

  let pageWidth_point = 0

  if (pageSize === "A4" && PageOrientation === "portrait") {
    pageWidth_point = 595
  } else if (pageSize === "A4" && PageOrientation === "landscape") {
    pageWidth_point = 841
  } else if (pageSize === "A5" && PageOrientation === "portrait") {
    pageWidth_point = 420
  } else if (pageSize === "A5" && PageOrientation === "landscape") {
    pageWidth_point = 595
  }

  const result = new Array(Math.ceil(table_values.length / n)).fill().map((_) => table_values.splice(0, n))

  let detailTable = chunk(result, col, 1)

  detailTable.forEach((e, dIndex) => {
    let columns = []
    e.forEach((i) => {
      columns.push({
        table: {
          widths: tableWidth,
          body: [...i],
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0.5
          },
          vLineWidth: function (i, node) {
            return 0.5
          },
          hLineColor: function (i, node) {
            return "black"
          },
          vLineColor: function (i, node) {
            return "black"
          },
        },
        width: (pageWidth_point - 90) / col,
      })
    })
    if (columns.length < col) {
      for (let index = 0; index <= col - columns.length; index++) {
        columns.push({ text: " ", width: "*", border: [false, false, false, false] })
      }
    }

    if (dIndex < detailTable.length - 1) {
      mainBody.push({ columns: columns, pageBreak: "after", columnGap: 5 })
    } else {
      mainBody.push({ columns: columns, columnGap: 5 })
    }
  })

  if (table_values2.length > 0) {
    mainBody2.push({ text: "", pageBreak: "after" })
    n = fixSingleRow + 10
    const result2 = new Array(Math.ceil(table_values2.length / n)).fill().map((_) => table_values2.splice(0, n))

    let detailTable2 = chunk(result2, col, 1)

    detailTable2.forEach((e, dIndex) => {
      let columns = []
      e.forEach((i) => {
        columns.push({
          table: {
            widths: tableWidth,
            body: [...i],
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0.5
            },
            vLineWidth: function (i, node) {
              return 0.5
            },
            hLineColor: function (i, node) {
              return "black"
            },
            vLineColor: function (i, node) {
              return "black"
            },
          },
          width: (pageWidth_point - 90) / col,
        })
      })
      if (columns.length < col) {
        for (let index = 0; index <= col - columns.length; index++) {
          columns.push({ text: " ", width: "*", border: [false, false, false, false] })
        }
      }

      if (dIndex < detailTable2.length - 1) {
        mainBody2.push({ columns: columns, pageBreak: "after", columnGap: 5 })
      } else {
        mainBody2.push({ columns: columns, columnGap: 5 })
      }
    })
  }

  return [...mainBody, ...mainBody2]
}

export const generateSignatureBoxes = (data) => {
  let tablewidth = parseInt(data.tablewidth)
  let columnGap = parseInt(data.columngap)
  let margin = JSON.parse(data.margin)

  let signData = data.boxes.map((e, i) => {
    return {
      width: tablewidth ? tablewidth : "*",
      table: {
        widths: JSON.parse(e.width),
        heights: JSON.parse(e.height),
        body: [
          [{ text: e.toptitle, alignment: e.topalignment ? e.topalignment : "center" }],
          [
            {
              border: JSON.parse(e.border),
              text: "",
            },
          ],
          [{ text: e.bottomtitle, alignment: e.bottomalignment ? e.bottomalignment : "center" }],
        ],
      },
      layout: { defaultBorder: false },
    }
  })

  return {
    field_id: "bodyFooter",
    columns: signData,
    columnGap: columnGap ? columnGap : 5,
    margin: margin ? margin : [0, 10, 0, 0],
  }
}

export const removeFieldIdRecursively = (obj, fieldIdToRemove) => {
  if (Array.isArray(obj)) {
    // If it's an array, check each element
    for (let i = obj.length - 1; i >= 0; i--) {
      if (obj[i].field_id === fieldIdToRemove) {
        // Remove the entire object containing the field_id
        obj.splice(i, 1)
      } else {
        // Recursively check nested objects/arrays
        removeFieldIdRecursively(obj[i], fieldIdToRemove)
      }
    }
  } else if (typeof obj === "object" && obj !== null) {
    // If it's an object, check each property
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (Array.isArray(obj[key])) {
          removeFieldIdRecursively(obj[key], fieldIdToRemove)
          // If the array becomes empty after removal, delete the property
          if (obj[key].length === 0) {
            delete obj[key]
          }
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          removeFieldIdRecursively(obj[key], fieldIdToRemove)
        }
      }
    }
  }
}

// Usage example:
// let data = ... your JSON data ...
// let fieldIdToRemove = 'field_id_to_remove';
// removeFieldIdRecursively(data, fieldIdToRemove);
