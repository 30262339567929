import { convertIntif } from "@project/sharedcomponents/utilityFunctions"
import { setColumnDataFilterWise, setOrderFilterWise } from "@project/components/utilityFunctions"
import {
  CATALOGUE_ITEM_DELETED,
  CATALOGUE_ITEM_INSERTED,
  CATALOGUE_LVEUSERS_UPDATED,
  CATALOGUE_SEARCH_TEXT_UPDATE,
  CATALOGUE_SERVICE_CLEAR,
  CATALOGUE_SERVICE_DELETED,
  CATALOGUE_SERVICE_FAIL,
  CATALOGUE_SERVICE_INSERTED,
  CATALOGUE_SERVICE_START,
  CATALOGUE_SERVICE_SUCCESS,
  CATALOGUE_SERVICE_UPDATED,
} from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  totalcount: 0,
  rowsPerPage: 0,
  page: 0,
  norecord: false,
  order: "",
  orderBy: "",
  search_text: "",
  deleted: false,
  columnData: [
    { id: "name", numeric: false, visible: true, label: "Name" },
    { id: "analytics", numeric: false, visible: true, label: "Live Users", width: 150, badge: true, showlink: true },
    { id: "created_on", numeric: false, visible: true, label: "Updated On", width: 175 /*format: 'utc'*/ },
    { id: "company_name", numeric: false, visible: true, label: "Resellers", width: 175 },
    { id: "product_cnt", numeric: true, visible: true, label: "Product Count", width: 150 },
    { id: "is_published", numeric: false, visible: true, label: "Publish on Website", width: 175 },
    { id: "is_featured", numeric: false, visible: true, label: "Featured", width: 150 },
    { id: "is_private", numeric: false, visible: true, label: "Private", width: 150 },
    { id: "show_oos", numeric: false, visible: true, label: "Show outofstock", width: 175 },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CATALOGUE_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case CATALOGUE_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case CATALOGUE_SERVICE_SUCCESS:
      action.payload.data.result = action.payload.data.result.map((item, index) => {
        const updateditem = { ...item }
        updateditem.is_published = updateditem.is_published === "1" ? "Yes" : "No"
        updateditem.is_featured = updateditem.is_featured === "1" ? "Yes" : "No"
        updateditem.is_private = updateditem.is_private === "1" ? "Yes" : "No"
        updateditem.show_oos = updateditem.show_oos === "1" ? "Yes" : "No"

        return updateditem
      })
      if (action.page === 0) {
        var updatedcoloumn = setColumnDataFilterWise({ data: action.payload.data, columnData: state.columnData })
        action = setOrderFilterWise(action)
        return {
          ...state,
          data: action.payload.data.result,
          totalcount: action.payload.data.total_count,
          columnData: updatedcoloumn,
          rowsPerPage: action.rowsPerPage,
          page: action.page,
          order: action.order,
          orderBy: action.orderBy,
          islogin: true,
          norecord: action.payload.data.result.length === 0,
        }
      } else {
        return {
          ...state,
          data: action.payload.data.result,
          totalcount: action.payload.data.total_count,
          rowsPerPage: action.rowsPerPage,
          page: action.page,
          order: action.order,
          orderBy: action.orderBy,
          islogin: true,
          norecord: action.payload.data.result.length === 0,
        }
      }
    case CATALOGUE_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        data: [],
        loading: false,
        totalcount: 0,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
      }
    case CATALOGUE_SEARCH_TEXT_UPDATE:
      return { ...state, search_text: action.search_text, norecord: false }

    case CATALOGUE_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_code.toString()
      inserteditem.name = action.payload.name
      inserteditem.description = action.payload.description
      inserteditem.company_url = action.payload.company_url
      inserteditem.company_name = action.payload.company_name
      inserteditem.showMenu = true
      inserteditem.is_published = action.payload.is_published === "1" ? "Yes" : "No"
      inserteditem.is_featured = action.payload.is_featured === "1" ? "Yes" : "No"
      inserteditem.is_private = action.payload.is_private === "1" ? "Yes" : "No"
      inserteditem.show_oos = action.payload.show_oos === "1" ? "Yes" : "No"
      inserteditem.product_cnt = "0"
      inserteditem.created_on = action.created_on
      return { ...state, data: [inserteditem, ...state.data], totalcount: convertIntif(state.totalcount) + 1 }

    case CATALOGUE_SERVICE_DELETED:
      if (action.otherDetail === "multi_delete") {
        var finalData = []
        var applyToDelete = action.payload.code.codes.map((a) => a.toString())
        if (action.response.data.meta.code === 403) {
          //Error
          var NotDelete = []
          action.response.data.meta.message.forEach((element) => {
            var codes = element.code.toString().split(",")
            NotDelete = [...NotDelete, ...codes]
          })
          NotDelete = NotDelete.map((e) => e.toString())
          finalData = state.data.filter((item) => {
            item.code = item.code.toString()
            if (!(applyToDelete.includes(item.code) && !NotDelete.includes(item.code))) {
              return item
            }
            return false
          })
        } //All delete
        else {
          finalData = state.data.filter((item) => {
            item.code = item.code.toString()
            if (!applyToDelete.includes(item.code)) {
              return item
            }
            return false
          })
        }
        return { ...state, data: finalData, deleted: !state.deleted }
      } else {
        return { ...state, data: state.data.filter((item) => item.code.toString() !== action.payload.code.toString()), deleted: !state.deleted }
      }

    case CATALOGUE_ITEM_DELETED:
      let updatedData = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.catalogue_id)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.product_cnt = parseInt(updateditem.product_cnt) - action.payload.code.codes.length
        return updateditem
      })
      return { ...state, data: updatedData }

    case CATALOGUE_ITEM_INSERTED:
      let updated_Data = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.catalogue_id)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.product_cnt = parseInt(updateditem.product_cnt) + action.payload.product_id.length
        return updateditem
      })
      return { ...state, data: updated_Data }

    case CATALOGUE_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.update_code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.name = action.payload.name
        updateditem.created_on = action.created_on
        updateditem.description = action.payload.description
        updateditem.is_published = action.payload.is_published === "1" ? "Yes" : "No"
        updateditem.is_featured = action.payload.is_featured === "1" ? "Yes" : "No"
        updateditem.is_private = action.payload.is_private === "1" ? "Yes" : "No"
        updateditem.show_oos = action.payload.show_oos === "1" ? "Yes" : "No"
        return updateditem
      })
      return { ...state, data: updateddata }
    case CATALOGUE_LVEUSERS_UPDATED:
      return { ...state, data: action.payload }
    default:
      return state
  }
}
