const setting = {
  pageMarginsA4: [40, 40, 40, 40],
  pageMarginsA5: [15, 15, 15, 15],
  logo_height_a4: 80,
  logo_width_a4: 80,
  logo_height_a5: 64,
  logo_width_a5: 64,
  header_right_section_width_a4_P: 200,
  header_right_section_width_a5_P: 125,
  header_right_section_width_a4_L: 250,
  header_right_section_width_a5_L: 200,
  footer_column_gap_a4_L: 450,
  footer_column_gap_a4_P: 90,
  footer_column_gap_a5_L: 225,
  footer_column_gap_a5_P: 45,
  customFieldBorder: false,
}

const header = [
  {
    field_id: "header",
    style: "tableExample",
    table: {
      widths: [100, "*", 100],
      body: [
        [
          { stack: [{ text: "{left_text}", style: "head_left_text" }] },
          { stack: [{ text: "{center_text}", bold: true, style: "head_center_text" }] },
          { stack: [{ text: "{right_text}", style: "head_right_text" }] },
        ],
      ],
    },
    layout: { defaultBorder: false },
    margin: [15, 15, 15, 5],
  },
]

const bodyHeader = [
  {
    style: "companyDetails",
    table: {
      body: [
        [
          {
            field_id: "company_logo",
            width: "{logo_width}",
            image: "{logo}",
            fit: ["{logo_width}", "{logo_height}"],
            margin: [5, 5, 5, 5],
          },
          {
            width: "*",
            stack: [
              { field_id: "company_name", text: "{companyName}", style: "valueField", bold: true, fontSize: 10.5 },
              {
                field_id: "company_address",
                stack: [
                  { text: "{company_address1}", style: "valueField" },
                  { text: "{company_address2}", style: "valueField" },
                  { field_id: "company_contact", text: "Contact: {company_contact}", style: "valueField" },
                  { field_id: "company_gstin", text: "GSTIN: {company_gstin}", style: "valueField" },
                  { field_id: "company_pan", text: "PAN: {companyPAN}", style: "valueField" },
                  { field_id: "company_aadhaar", text: "Aadhaar: {company_aadhaar}", style: "valueField" },
                  { field_id: "company_OtherNumbers", text: "{companyOtherNumbers}", style: "valueField" },
                ],
              },
            ],
          },
        ],
      ],
    },
    margin: [0, 0, 0, 10],
    layout: "noPB",
  },
  {
    style: "partyDetails",
    table: {
      widths: ["*", "*"],
      body: [
        [
          {
            field_id: "billing_address_block",
            stack: [
              { field_id: "party_title", text: "To,", style: "valueField", margin: [0, 0, 0, 0] },
              { field_id: "party_name", text: "{partyName}", style: "valueField", bold: true, fontSize: 10.5 },
              {
                field_id: "party_address",
                stack: [
                  { text: "{party_address1}", style: "valueField" },
                  { text: "{party_address2}", style: "valueField" },
                  { field_id: "party_gstin", text: "GSTIN: {party_gstin}", style: "valueField" },
                  { field_id: "party_pan", text: "PAN: {companyPAN}", style: "valueField" },
                  { field_id: "party_aadhaar", text: "Aadhaar: {party_aadhaar}", style: "valueField" },
                ],
              },
              { field_id: "party_contact", text: "Contact: {party_contact}", style: "valueField" },
            ],
          },
          {
            field_id: "narration_details",
            stack: [{ text: "Note/Remark" }, { text: "{narration}", style: "valueField" }],
          },
        ],
      ],
    },
    margin: [0, 0, 0, 10],
    layout: "noBorders",
  },
  {
    style: "docDetails",
    table: {
      widths: ["*", "*"],
      body: [
        [
          {
            stack: [
              {
                field_id: "invno_details",
                stack: [
                  {
                    field_id: "invoice_no",
                    columns: [
                      { width: "*", style: "valueField", margin: [0, 0, 5, 0], text: "{title}" },
                      { width: "*", text: "{billno}", bold: true, style: "valueField" },
                    ],
                    margin: [0, 0, 0, 5],
                  },
                  {
                    field_id: "invoice_date",
                    columns: [
                      { width: "*", style: "valueField", margin: [0, 0, 5, 0], text: "Date" },
                      { width: "*", text: "{inwardDate}", bold: true, style: "valueField" },
                    ],
                    margin: [0, 0, 0, 5],
                  },
                ],
              },
              {
                field_id: "process_info",
                stack: [
                  {
                    field_id: "process",
                    columns: [
                      { width: "*", style: "valueField", margin: [0, 0, 5, 0], text: "From Process" },
                      { width: "*", text: "{processName}", bold: true, style: "valueField" },
                    ],
                    margin: [0, 0, 0, 5],
                  },
                ],
              },
              {
                field_id: "trans_details",
                stack: [
                  {
                    field_id: "transport",
                    columns: [
                      { width: "*", style: "valueField", margin: [0, 0, 5, 0], text: "Transport" },
                      { width: "*", text: "{transport}", bold: true, style: "valueField" },
                    ],
                    margin: [0, 0, 0, 5],
                  },
                ],
              },
              {
                field_id: "eway_details",
                stack: [
                  {
                    field_id: "eway_bill_no",
                    columns: [
                      { width: "*", style: "valueField", margin: [0, 0, 5, 0], text: "E-Way Bill No" },
                      { width: "*", text: "{ewaybill_no}", bold: true, style: "valueField" },
                    ],
                    margin: [0, 0, 0, 5],
                  },
                  {
                    field_id: "vehicle_no",
                    columns: [
                      { width: "*", style: "valueField", margin: [0, 0, 5, 0], text: "Vehicle No" },
                      { width: "*", text: "{vehicle_no}", bold: true, style: "valueField" },
                    ],
                    margin: [0, 0, 0, 5],
                  },
                ],
              },
              {
                field_id: "lr_details",
                stack: [
                  {
                    field_id: "lr_no",
                    columns: [
                      { width: "*", style: "valueField", margin: [0, 0, 5, 0], text: "LR No" },
                      { width: "*", text: "{lr_no}", bold: true, style: "valueField" },
                    ],
                    margin: [0, 0, 0, 5],
                  },
                  {
                    field_id: "lr_date",
                    columns: [
                      { width: "*", style: "valueField", margin: [0, 0, 5, 0], text: "LR Date" },
                      { width: "*", text: "{lr_date}", bold: true, style: "valueField" },
                    ],
                    margin: [0, 0, 0, 5],
                  },
                ],
              },
              {
                field_id: "agent_details",
                stack: [
                  {
                    field_id: "agent",
                    columns: [
                      { width: "*", style: "valueField", margin: [0, 0, 5, 0], text: "Agent" },
                      { width: "*", text: "{agent}", bold: true, style: "valueField" },
                    ],
                    margin: [0, 0, 0, 5],
                  },
                ],
              },
              {
                field_id: "godown_details",
                stack: [
                  {
                    field_id: "godown",
                    columns: [
                      { width: "*", style: "valueField", margin: [0, 0, 5, 0], text: "Godown" },
                      { width: "*", text: "{godown}", bold: true, style: "valueField" },
                    ],
                    margin: [0, 0, 0, 5],
                  },
                ],
              },
            ],
            margin: [0, 0, 0, 0],
          },
          {
            field_id: "customFieldNew",
            replacePath: "table.body",
            table: {
              widths: ["*"],
              //heights: 20,
              body: [
                [
                  { stack: [{ text: "Custom Field 1" }, { text: "Custom Field Value", style: "valueField" }], border: [false, false, true, true] },
                  { stack: [{ text: "Custom Field 2" }, { text: "Custom Field Value", style: "valueField" }], border: [false, false, false, true] },
                ],
              ],
            },
            layout: "noBorders",
            margin: [0, 0, 0, 10],
          },
        ],
      ],
    },
    margin: [0, 0, 0, 10],
    layout: "noBorders",
  },
]

const bodyFooter = [
  {
    field_id: "terms",
    table: {
      widths: ["*"],
      body: [
        [
          {
            stack: [
              { text: "Terms & Conditions", bold: true },
              { text: "{termsAndconditions}", style: "termsandCondition" },
            ],
            margin: [0, 0, 0, 0],
          },
        ],
      ],
    },
    margin: [0, 5, 0, 5],
    unbreakable: true,
    layout: "noPB",
  },
]
const footer = [{}]
const body = [{}]

const style = {
  valueField: {
    fontSize: 9,
  },
  head_left_text: {
    alignment: "left",
  },
  head_center_text: {
    fontSize: 12,
    alignment: "center",
  },
  head_right_text: {
    alignment: "right",
  },
  left: {
    alignment: "left",
  },
  right: {
    alignment: "right",
  },
}

export default {
  header: JSON.stringify(header),
  bodyHeader: JSON.stringify(bodyHeader),
  bodyFooter: JSON.stringify(bodyFooter),
  footer: JSON.stringify(footer),
  body: JSON.stringify(body),
  setting,
  style,
}
