import { deleteInReducer } from "@project/components/utilityFunctions"
import { convertIntif } from "@project/sharedcomponents/utilityFunctions"
import {
  CUSTOM_PAGE_LOCAL_SEARCH,
  CUSTOM_PAGE_SERVICE_CLEAR,
  CUSTOM_PAGE_SERVICE_DELETED,
  CUSTOM_PAGE_SERVICE_FAIL,
  CUSTOM_PAGE_SERVICE_INSERTED,
  CUSTOM_PAGE_SERVICE_START,
  CUSTOM_PAGE_SERVICE_SUCCESS,
  CUSTOM_PAGE_SERVICE_UPDATED,
} from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  totalcount: 0,
  rowsPerPage: 0,
  page: 0,
  order: "asc",
  orderBy: "name",
  norecord: false,
  search_text: "",
  deleted: false,
  columnData: [{ id: "name", numeric: false, visible: true, label: "Page Name" }],
  metadata: "",
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CUSTOM_PAGE_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case CUSTOM_PAGE_SERVICE_SUCCESS:
      let updatedcoloumn = action.payload.data.result.map((item, index) => {
        const updateditem = { ...item }
        var filter_find = action.getdatatype.filter((itemRow) => updateditem.dataType === itemRow.id)
        if (filter_find.length > 0) {
          updateditem.dataType = filter_find[0].name
        } else {
          updateditem.dataType = ""
        }
        return updateditem
      })
      return {
        ...state,
        data: updatedcoloumn,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        islogin: true,
        norecord: action.payload.data.result.length === 0,
      }
    case CUSTOM_PAGE_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false, totalcount: 0, search_text: action.search_text }
    case CUSTOM_PAGE_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case CUSTOM_PAGE_LOCAL_SEARCH:
      return { ...state, search_text: action.search_text }
    case CUSTOM_PAGE_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_code
      inserteditem.name = action.payload.name
      return { ...state, data: [inserteditem, ...state.data] }

    case CUSTOM_PAGE_SERVICE_DELETED:
      return deleteInReducer({ action, key: "data", state })
    case CUSTOM_PAGE_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.update_code)) {
          return item
        }
        const updateditem = { ...item }
        updateditem.name = action.payload.name
        return updateditem
      })

      return { ...state, data: updateddata }
    default:
      return state
  }
}
