export default {
  Text: 1,
  Decimal: 2,
  Date: 3,
  Checkbox: 4,
  Dropdown: 5,
  Creatable_Dropdown: 6,
  Photo: 7,
  YesNo: 8,
  HTML_Editor: 9,
  Dropbox: 10,
  BankAcc: 101,
  ExpenseAcc: 102,
  AllAcc: 103,
  SalesAcc: 104,
  PurchaseAcc: 105,
  AccType: 106,
  AccGroup: 107,
  Transport: 108,
  Agent: 109,
  Customers: 110,
  Suppliers: 111,
  CusSuppBoth: 112,
  State: 113,
  City: 114,
  Unit: 115,
  OtherAcc: 116,
  Product: 117,
  ProductGroup: 118,
  User: 119,
  TaxScheme: 120,
  Godown: 122,
  Country: 123,
}
