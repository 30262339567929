import { list_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import {
  LAST_PAGE_UPDATE,
  LOADING_STATE,
  ORGANIZATION_SERVICE_CLEAR,
  ORGANIZATION_SERVICE_FAIL,
  ORGANIZATION_SERVICE_INSERTED,
  ORGANIZATION_SERVICE_START,
  ORGANIZATION_SERVICE_SUCCESS,
  SNACKBAR_STATE,
} from "./types"

export const organization_data_clear = () => {
  return (dispatch) => {
    dispatch({
      type: ORGANIZATION_SERVICE_CLEAR,
    })
  }
}

export const post_organization_data = (formdata, functions) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("uname", formdata.uname)
    data.append("pass", formdata.pass)
    data.append("com_url", formdata.com_url)

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "post_organization" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
          dispatch({ type: LOADING_STATE, state: false })

          dispatch({
            type: ORGANIZATION_SERVICE_INSERTED,
            payload: response,
          })

          var lastdata = {}
          lastdata = formdata
          lastdata.response = response.data
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "connect_company",
            lastaction: "insert",
            lasttime: new Date().getTime(),
            lastdata: formdata,
          })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const fetch_organization_data = (functions) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: ORGANIZATION_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", "organization")

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "organization" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          organizationServiceFail({ dispatch, response: response.data.meta.message })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          organizationServiceSuccess({ dispatch, user: response })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          organizationServiceFail({ dispatch, response: getCatchErrorMessage(error) })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const organizationServiceSuccess = ({ dispatch, user }) => {
  dispatch({
    type: ORGANIZATION_SERVICE_SUCCESS,
    payload: user,
  })
}

const organizationServiceFail = ({ dispatch, response }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })

  dispatch({
    type: ORGANIZATION_SERVICE_FAIL,
    payload: response,
  })
}
