import { list_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, isblank, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getAccountSessionUserData, getAccountSitesetting, getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import { LOADING_STATE, QUICK_LINK_SERVICE_FAIL, QUICK_LINK_SERVICE_START, QUICK_LINK_SERVICE_SUCCESS, SNACKBAR_STATE } from "./types"

export const fetch_quick_link_data = ({ companycode, rowsPerPage, page, order, orderBy, functions }) => {
  if (isblank(rowsPerPage)) {
    rowsPerPage = getAccountSitesetting("pagination_size")
  }
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: QUICK_LINK_SERVICE_START })
    dispatch({ type: LOADING_STATE, sidebarstate: true, state: false })

    let data = new FormData()
    data.append("offset", 0)
    data.append("type", "quicklink")
    data.append("limit", "All")
    data.append("sort_order", "")
    data.append("sort_by", "")
    data.append("code", getAccountSessionUserData("user_id"))

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "quicklink" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          quicklinkServiceFail({ dispatch, response: response.data.meta.message, rowsPerPage, page, order, orderBy })
        } else {
          dispatch({ type: LOADING_STATE, sidebarstate: false, state: false })
          quicklinkServiceSuccess({ dispatch, user: response, rowsPerPage, page, order, orderBy })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, sidebarstate: false, state: false })
        } else {
          postErrorOnException(error)
          quicklinkServiceFail({ dispatch, response: getCatchErrorMessage(error), rowsPerPage, page, order, orderBy })
        }
      })
  }
}

const quicklinkServiceSuccess = ({ dispatch, user, rowsPerPage, page, order, orderBy }) => {
  dispatch({
    type: QUICK_LINK_SERVICE_SUCCESS,
    payload: user,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
  })
}

const quicklinkServiceFail = ({ dispatch, response, rowsPerPage, page, order, orderBy }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })
  dispatch({
    type: QUICK_LINK_SERVICE_FAIL,
    payload: response,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
  })
  dispatch({ type: LOADING_STATE, sidebarstate: false, state: false })
}
