import { list_url } from "@project/sharedcomponents/apiURL"
import { getAxiosCancelToken, getCatchErrorMessage, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import { LOADING_STATE, SNACKBAR_STATE, TALLY_DATA_SERVICE_CLEAR, TALLY_DATA_SERVICE_FAIL, TALLY_DATA_SERVICE_START, TALLY_DATA_SERVICE_SUCCESS } from "./types"

export const tally_data_clear = () => {
  return (dispatch) => {
    dispatch({
      type: TALLY_DATA_SERVICE_CLEAR,
    })
  }
}
export const fetch_tally_data = ({ other_type, to_date, from_date, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: TALLY_DATA_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", "tallyExport")
    data.append("other_type", other_type)
    data.append("to_date", to_date)
    data.append("from_date", from_date)
    data.append("flag", "1")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "tallyExport" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          tallyServiceFail({ dispatch, response: response.data.meta.message })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          tallyServiceSuccess({ dispatch, user: response })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          tallyServiceFail({ dispatch, response: getCatchErrorMessage(error) })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const tallyServiceSuccess = ({ dispatch, user, rowsPerPage, page, order, orderBy, search_text }) => {
  dispatch({
    type: TALLY_DATA_SERVICE_SUCCESS,
    payload: user,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
  })
}

const tallyServiceFail = ({ dispatch, response, rowsPerPage, page, order, orderBy, search_text }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })

  dispatch({
    type: TALLY_DATA_SERVICE_FAIL,
    payload: response,
    rowsPerPage: rowsPerPage,
    page: page,
    order: order,
    orderBy: orderBy,
    search_text: search_text,
  })
}
