import { Chip, useTheme } from "@material-ui/core"
import React from "react"

function CustomChip({ colorObj, ...props }) {
  const theme = useTheme()
  return (
    <Chip
      {...props}
      {...(colorObj &&
        colorObj.color && {
          style: {
            backgroundColor: theme.palette.chip[colorObj.color].background,
            color: theme.palette.chip[colorObj.color].message,
            ...props.style,
          },
        })}
    ></Chip>
  )
}

export default CustomChip
