import { IconButton, InputAdornment, Link, Popover, TextField, makeStyles } from "@material-ui/core"
import { DateRange } from "@material-ui/icons"
import HelpBtnComponent from "@project/components/common/HelpBtnComponent"
import { startEvaluation } from "@project/components/utilityFunctions"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import {
  DatetoStr,
  amount_format,
  amount_formatDecimal,
  cleanStringForPDFMake,
  currencyFormat,
  getCapitalize,
  getNumberonly,
  isNaNValue,
  isblank,
  numberDecimal,
  validateEmail,
  validateMobile,
} from "@project/sharedcomponents/utilityFunctions"
import React from "react"
import { isMobileOnly } from "react-device-detect"
import { useSelector } from "react-redux"
import StaticDatePicker from "../common/StaticDatePicker"
import LabelComponent from "../common/labelComponent"

const useStyles = makeStyles((theme) => ({
  textFieldInputNumber: {
    textAlign: "right",
  },
  inputAdornedEnd: {
    paddingRight: 0,
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "&$cssError $notchedOutline": {
      borderColor: theme.palette.error.main,
    },
    "&$cssFocused $notchedDateOutline": {
      borderColor: theme.palette.primary.main,
    },
    "&$cssError $notchedDateOutline": {
      borderColor: theme.palette.error.main,
    },
  },
  cssFocused: {},
  cssError: {},
  notchedOutline: {
    borderWidth: 0,
  },
  notchedDateOutline: {},
  notchedMobileDateOutline: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.87)",
  },
  condenseInputClass: {
    padding: "6.5px 8px",
  },
}))

const StandardTextField = ({
  name,
  noLableMargin,
  label,
  placeholder,
  labelStyle,
  labelbuttonclick,
  tabIndex,
  setUnderLine,
  labelbuttontext,
  helpStyle,
  disableAutoCaps,
  multiline,
  isRequired,
  margin,
  addComma,
  fullWidth,
  currencyId,
  decimal,
  value,
  onBlur,
  number,
  startAdornment,
  onValueChange,
  helpText,
  toolTipStyle,
  fieldType,
  endAdornment,
  error,
  onChange,
  ...props
}) => {
  const classes = useStyles()
  const [textvalue, setTextvalue] = React.useState(false)
  const [inputError, setInputError] = React.useState("")
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const accountSiteSettingKeyValue = useSelector((state) => state.AuthReducer.accountSiteSettingKeyValue)
  let StartAdornment = null
  if (startAdornment) {
    StartAdornment = <InputAdornment position="start">{startAdornment}</InputAdornment>
  }

  let EndAdornment = null
  if (endAdornment) {
    EndAdornment = (
      <InputAdornment position="end" style={{ paddingRight: 0 }}>
        {endAdornment}
      </InputAdornment>
    )
  }

  function getValue(val) {
    if (!isblank(accountSiteSettingKeyValue) && accountSiteSettingKeyValue.textTransformation === "u" && !disableAutoCaps && fieldType !== "email" && props.type !== "password") {
      return val.toUpperCase()
    } else if (!isblank(accountSiteSettingKeyValue) && accountSiteSettingKeyValue.textTransformation === "c" && !disableAutoCaps && fieldType !== "email" && props.type !== "password") {
      return getCapitalize(val)
    } else {
      return val
    }
  }

  const on_Change = (val) => {
    if (onChange) {
      onChange(val)
    }
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handledatechange(date) {
    setAnchorEl(null)
    on_Change(DatetoStr(date))
  }

  var variantType = "standard"
  var ingrid = false
  if (!isblank(setUnderLine)) {
    if (!setUnderLine) {
      ingrid = true
      variantType = "outlined"
    }
  }

  if (props.type === "date") {
    variantType = "outlined"
  }

  const id = open ? "simple-popover" : undefined

  let inputClass = classes.textFieldInput
  if (number) {
    inputClass = classes.textFieldInputNumber
  }
  inputClass = `${inputClass} ${classes.condenseInputClass}`

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <LabelComponent error={error} label={label} isRequired={isRequired} noLableMargin={noLableMargin} labelStyle={labelStyle} />
          {helpText && <HelpBtnComponent help={helpText} style={helpStyle ? helpStyle : { marginLeft: 4 }} toolTipStyle={toolTipStyle} />}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {labelbuttonclick && (
            <Link component="button" data-testid={`${name}_link_5`} tabIndex="-1" variant="body2" underline="always" style={{ cursor: "pointer" }} onClick={labelbuttonclick}>
              {labelbuttontext}
            </Link>
          )}
        </div>
      </div>
      {props.type === "date" && (
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", right: 5, top: 5, zIndex: 1 }}>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <StaticDatePicker value={textvalue === false ? value : textvalue} onchange={handledatechange} />
            </Popover>
            {props.calIcon !== false && !isMobileOnly && (
              <IconButton aria-label={id} disabled={props.disabled} size="small" onClick={handleClick} tabIndex="-1">
                <DateRange fontSize="small" />
              </IconButton>
            )}
          </div>
        </div>
      )}

      <TextField
        id={name}
        fullWidth={fullWidth}
        value={textvalue === false ? value : textvalue}
        size="small"
        placeholder={placeholder}
        {...(multiline && {
          multiline: true,
          minRows: multiline,
          maxRows: multiline,
          //rows: multiline
        })}
        {...(((!isblank(error) && error) || !isblank(inputError)) && {
          error: true,
        })}
        {...(((!isblank(error) && error) || !isblank(inputError)) &&
          setUnderLine !== false && {
            helperText: !isblank(error) ? error : inputError,
          })}
        variant="outlined"
        InputProps={{
          startAdornment: StartAdornment,
          endAdornment: EndAdornment,
          inputProps: { "data-testid": name, tabIndex },
          // disableUnderline: underlineType,
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            error: classes.cssError,
            input: inputClass,
            notchedOutline:
              variantType === "outlined"
                ? ingrid === false && variantType === "outlined" && isMobileOnly
                  ? classes.notchedMobileDateOutline
                  : ingrid === false && variantType === "outlined"
                    ? classes.notchedDateOutline
                    : classes.notchedOutline
                : undefined,
          },
        }}
        autoComplete="off"
        onFocus={(e) => {
          var target = e.target
          if (number && value) {
            target.setSelectionRange(0, value.length)
          }
        }}
        onBlur={(e) => {
          var target = getValue(e.target.value)

          target = cleanStringForPDFMake(target)

          if (number) {
            if (target.includes("+") || target.includes("-") || target.includes("*") || target.includes("/")) {
              target = target.replace(/,/g, "")
              target = target.replace(/ /g, "")
              target = startEvaluation(target)
            }
          }
          on_Change(target)
          if (number) {
            if (target !== "") {
              if (isNaNValue(target)) {
                if (decimal || decimal === 0) {
                  on_Change("")
                } else {
                  on_Change("")
                }
                setInputError(ErrorMessages["E00066"])
              } else {
                if (!isblank(addComma) && !addComma) {
                  if (decimal || decimal === 0) {
                    on_Change(numberDecimal(target, decimal))
                  }
                } else {
                  if (decimal || decimal === 0) {
                    on_Change(amount_formatDecimal(target, decimal))
                  } else {
                    on_Change(amount_format(target))
                  }
                }
              }
            }
          } else if (fieldType === "mobile") {
            if (!isblank(target)) {
              if (isNaN(target)) {
                on_Change("")
                setInputError("Enter only number")
              } else if (!validateMobile(target)) {
                setInputError("Enter only 10 digit mobile number")
              }
            }
          } else if (fieldType === "email") {
            if (!isblank(target)) {
              if (!validateEmail(target)) {
                setInputError("Enter valid email address")
              }
            }
          } else if (fieldType === "currency") {
            if (!isblank(target)) {
              on_Change(currencyFormat(getNumberonly(target), currencyId))
            }
          }
          setTextvalue(false)
        }}
        onChange={(e) => {
          if (!isblank(inputError)) {
            setInputError("")
          }
          if (onValueChange) {
            onValueChange(getValue(e.target.value))
          }
          setTextvalue(getValue(e.target.value))
        }}
        {...props}
      />
    </div>
  )
}

export default StandardTextField
