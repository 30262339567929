import React from "react"
import { DialogActions, DialogContent, DialogContentText, Link, Typography } from "@material-ui/core"
import AppStore from "../Images/appstore.svg"
import PlayStore from "../Images/googleplay.svg"

const DownloadMobileApp = (props) => {
  const { message, appStoreUrl, playStoreUrl } = props
  return (
    <div>
      <React.Fragment>
        <DialogContent>
          <DialogContentText>
            <Typography component="p">{message}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link size="small" target="_blank" href={appStoreUrl}>
            <img style={{ width: "100%" }} src={AppStore} alt="logo" />
          </Link>
          <Link size="small" target="_blank" href={playStoreUrl}>
            {" "}
            <img style={{ width: "100%" }} src={PlayStore} alt="logo" />
          </Link>
        </DialogActions>
      </React.Fragment>
    </div>
  )
}

export default DownloadMobileApp
