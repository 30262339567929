export default {
  primary: {
    main: "#2979ff",
    light: "#ECF2FF",
    dark: "rgb(28, 84, 178)",
  },
  secondary: {
    main: "#28a745",
    light: "rgb(83, 184, 106, 0.12)",
    dark: "rgb(28, 116, 48)",
  },
  error: {
    main: "rgb(255, 76, 81)",
    light: "rgba(255, 76, 81, 0.12)",
    dark: "rgb(224, 67, 71)",
    lighter: "#FFE9EA",
  },
  success: {
    main: "rgb(86, 202, 0)",
    light: "rgba(86, 202, 0, 0.12)",
    dark: "rgb(76, 178, 0)",
    lighter: "#EAF9E0",
  },
  warning: {
    main: "rgb(255, 180, 0)",
    light: "rgba(255, 180, 0, 0.12)",
    dark: "rgb(224, 158, 0)",
    lighter: "#FFF6E0",
  },
  info: {
    main: "rgb(22, 177, 255)",
    light: "rgba(22, 177, 255, 0.12)",
    dark: "rgb(19, 156, 224)",
    lighter: "#E2F6FF",
  },
  purple: {
    main: "rgb(145, 85, 253)",
    light: "rgba(145, 85, 253, 0.12)",
    dark: "rgb(128, 75, 223)",
    lighter: "#F2EAFF",
  },
  grey: {
    main: "rgb(138, 141, 147)",
    light: "rgba(138, 141, 147, 0.12)",
    dark: "rgb(119, 123, 130)",
    lighter: "#F1F1F2",
    lightest: "#F9FAFB",
  },
  black: {
    main: "#000000",
    light: "#212121",
    dark: "#333333",
    lighter: "#3c3c3c",
    lightest: "#666666",
  },
}
