import { fbprintpdf, print_file } from "@project/components/printJSFunctions"
import { getStorage, setStorage } from "@project/components/sessionStoreFunctions"
import { list_url } from "@project/sharedcomponents/apiURL"
import { generateListExcel, generateListPrint } from "@project/sharedcomponents/generateListPrintExcel"
import { amount_format, dataSetInList, getAxiosCancelToken, getCatchErrorMessage, isblank, setAxiosCancelToken } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { change } from "redux-form"
import { getAccountSessionUserData, getAccountSitesetting, getheaders, getWindowYear, goToNavigate, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import { pushDrawer, removeDrawer } from "./CommonActionsV2"
import {
  CHANGE_PASSWORD_SERVICE_UPDATED,
  CONFIRM_DIALOG,
  DROPDOWN_ITEM_DELETED,
  DROPDOWN_ITEM_INSERTED,
  DROPDOWN_ITEM_UPDATED,
  EMAIL_TEMPLATE_SERVICE_START,
  EMAIL_TEMPLATE_SERVICE_SUCCESS,
  EWB_BALANCE,
  E_INVOICE_UPDATE,
  FORM_DEP_SERVICE_CLEAR,
  GROUP_SMS_CONTACT_SERVICE_DELETED,
  GROUP_SMS_CONTACT_SERVICE_INSERTED,
  GROUP_SMS_CONTACT_SERVICE_UPDATED,
  GROUP_SMS_SEND_SERVICE_DELETED,
  GROUP_SMS_SEND_SERVICE_INSERTED,
  GROUP_SMS_SEND_SERVICE_UPDATED,
  GROUP_SMS_SERVICE_DELETED,
  GROUP_SMS_SERVICE_INSERTED,
  GROUP_SMS_SERVICE_UPDATED,
  LAST_PAGE_UPDATE,
  LOADING_SNACKBAR_STATE,
  LOADING_STATE,
  SINGLE_SMS_ALL_CONTACT_SERVICE_SUCCESS,
  SINGLE_SMS_CUSTOM_SERVICE_SUCCESS,
  SINGLE_SMS_SERVICE_SUCCESS,
  SINGLE_SMS_TEMPLATE_SERVICE_SUCCESS,
  SMS_BALANCE,
  SMS_TEMPLATE_SERVICE_START,
  SMS_TEMPLATE_SERVICE_SUCCESS,
  SNACKBAR_STATE,
  UPDATE_USER_DATA,
  UPLOAD_DOCUMENT_SUCCESS,
  USER_HISTORY_REPORT_SERVICE,
  UTILITY_FILE_UPLOAD_PROGRESS,
  UTILITY_POST_SERVICE_START,
  UTILITY_POST_SERVICE_SUCCESS,
  WHATSAPP_TEMPLATE_SERVICE_START,
  WHATSAPP_TEMPLATE_SERVICE_SUCCESS,
} from "./types"

export const post_utility_data = ({ dispatch, formtype, formaction, formdata, onSuccess, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    if (formaction !== "") {
      const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)

      dispatch({
        type: UTILITY_POST_SERVICE_START,
        formaction: formaction,
        formtype: formtype,
      })
      let data = new FormData()
      data.append("type", formtype)
      data.append("action", formaction)
      data.append("form_data", formdata2)
      data.append("offset", 0)
      data.append("limit", "All")
      axios.defaults.headers.common = getheaders()
      axios({
        method: "post",
        url: list_url({ type: "post_utility_data" }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
        cancelToken: getAxiosCancelToken().token,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          if (response.data.meta.code === 401) {
            postUtilityServiceFail({ dispatch, response: response.data.meta.message, formdata })
            dispatch({ type: LOADING_STATE, state: false })
          } else {
            postUtilityServiceSuccess({ dispatch, user: response, formtype, formaction, formdata })
            if (onSuccess) {
              onSuccess()
            }
            dispatch({ type: LOADING_STATE, state: false })
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            dispatch({ type: LOADING_STATE, state: false })
          } else {
            postErrorOnException(error)
            postUtilityServiceFail({ dispatch, response: getCatchErrorMessage(error), formdata })
            dispatch({ type: LOADING_STATE, state: false })
          }
        })
    }
  }
}

export const getSMSBalance = (functions) => {
  return (dispatch) => {
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", "sms_balance")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "sms_balance" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: SMS_BALANCE,
            payload: response.data.result,
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
        dispatch({ type: LOADING_STATE, state: false })
      })
  }
}

export const getEWBBalance = (functions) => {
  return (dispatch) => {
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("type", "ewaybill_balance")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "ewaybill_balance" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: EWB_BALANCE,
            payload: response.data.result,
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
        dispatch({ type: LOADING_STATE, state: false })
      })
  }
}

export const fetch_sms_template_data = (functions) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    dispatch({ type: SMS_TEMPLATE_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("type", "sms_template")
    data.append("sort_by", "code")
    data.append("search_text", "")
    data.append("limit", "All")
    data.append("offset", 0)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "sms_template" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: SMS_TEMPLATE_SERVICE_SUCCESS,
            payload: response,
          })

          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const fetch_whatsapp_template_data = (functions) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    dispatch({ type: WHATSAPP_TEMPLATE_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("type", "whatsapp_template")
    data.append("sort_by", "code")
    data.append("search_text", "")
    data.append("limit", "All")
    data.append("offset", 0)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "whatsapp_template" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: WHATSAPP_TEMPLATE_SERVICE_SUCCESS,
            payload: response,
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const fetch_email_template_data = (functions) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    dispatch({ type: EMAIL_TEMPLATE_SERVICE_START })
    dispatch({ type: LOADING_STATE, state: true })

    let data = new FormData()
    data.append("type", "email_template")
    data.append("sort_by", "id")
    data.append("search_text", "")
    data.append("limit", "All")
    data.append("offset", 0)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "email_template" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })

          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: EMAIL_TEMPLATE_SERVICE_SUCCESS,
            payload: response,
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

const postUtilityServiceSuccess = ({ dispatch, user, formtype, formaction, formdata }) => {
  const fyear = getWindowYear()
  dispatch({
    type: UTILITY_POST_SERVICE_SUCCESS,
    payload: user,
    formtype: formtype,
    formaction: formaction,
  })
  var ModalMode = true
  if (formaction === "edit") {
    var Modaltype = ""
    if (formtype === "groupsms") {
      Modaltype = "Add New Group"
    } else if (formtype === "groupsms_contact") {
      Modaltype = "Add New Contact"
    } else if (formtype === "groupsms_send") {
      Modaltype = "Add New Group SMS"
    } else {
      alert("Modal not added in common actions")
    }

    if (ModalMode) {
      pushDrawer({ title: Modaltype, position: "right", width: 450, dispatch })
    }
    if (formtype === "user_history") {
      dispatch({
        type: USER_HISTORY_REPORT_SERVICE,
        payload: formdata,
      })
    }
  } else if (formaction === "merge") {
    if (formtype === "merge_account") {
      dispatch({
        type: SNACKBAR_STATE,
        payload: true,
        message: user.data.meta.message,
        snackbarType: "success",
      })

      dispatch(change("MergeAccountForm", "mergeAccountSelectedValue", null))

      dispatch({
        type: DROPDOWN_ITEM_DELETED,
        formtype: "allaccount",
        payload: { code: formdata.mergeAccountSelectedValue.code },
      })
    }
    if (formtype === "merge_city") {
      dispatch({
        type: SNACKBAR_STATE,
        payload: true,
        message: user.data.meta.message,
        snackbarType: "success",
      })

      dispatch(change("MergeCityForm", "mergeCitySelectedValue", null))

      dispatch({
        type: DROPDOWN_ITEM_DELETED,
        formtype: "city",
        payload: { code: formdata.mergeCitySelectedValue.code },
      })
    }
    if (formtype === "merge_item") {
      dispatch({
        type: SNACKBAR_STATE,
        payload: true,
        message: user.data.meta.message,
        snackbarType: "success",
      })

      dispatch(change("MergeItemForm", "mergeItemSelectedValue", null))

      dispatch({
        type: DROPDOWN_ITEM_DELETED,
        formtype: "item",
        payload: { code: formdata.mergeItemSelectedValue.code },
      })
    }
    if (formtype === "merge_godown") {
      dispatch({
        type: SNACKBAR_STATE,
        payload: true,
        message: user.data.meta.message,
        snackbarType: "success",
      })
      dispatch({
        type: DROPDOWN_ITEM_DELETED,
        formtype: "godown",
        payload: { code: formdata.mergeGodownSelectedValue.code },
      })
    }
  } else if (formaction === "insert") {
    if (formtype === "groupsms") {
      dispatch({
        type: GROUP_SMS_SERVICE_INSERTED,
        payload: formdata,
        insert_code: user.data.insert_code,
      })

      dispatch({
        type: DROPDOWN_ITEM_INSERTED,
        formtype: formtype,
        code: user.data.insert_code,
        payload: formdata,
        name: formdata.name,
      })
    } else if (formtype === "groupsms_contact") {
      dispatch({
        type: GROUP_SMS_CONTACT_SERVICE_INSERTED,
        payload: formdata,
        insert_code: user.data.insert_code,
      })
    } else if (formtype === "groupsms_send") {
      dispatch({
        type: GROUP_SMS_SEND_SERVICE_INSERTED,
        payload: formdata,
        insert_code: user.data.insert_code,
      })
    } else if (formtype === "sendsms_single") {
      dispatch({
        type: SINGLE_SMS_SERVICE_SUCCESS,
        payload: formdata,
      })
    } else if (formtype === "sendsms_all") {
      dispatch({
        type: SINGLE_SMS_ALL_CONTACT_SERVICE_SUCCESS,
        payload: formdata,
      })
    } else if (formtype === "sendsms_custom") {
      dispatch({
        type: SINGLE_SMS_CUSTOM_SERVICE_SUCCESS,
        payload: formdata,
      })
    }
    removeDrawer(dispatch)
  } else if (formaction === "delete") {
    if (formtype === "groupsms") {
      dispatch({
        type: GROUP_SMS_SERVICE_DELETED,
        payload: formdata,
      })
      dispatch({
        type: DROPDOWN_ITEM_DELETED,
        formtype: formtype,
        payload: { code: formdata.code },
      })
    } else if (formtype === "groupsms_contact") {
      dispatch({
        type: GROUP_SMS_CONTACT_SERVICE_DELETED,
        payload: formdata,
      })
    } else if (formtype === "groupsms_send") {
      dispatch({
        type: GROUP_SMS_SEND_SERVICE_DELETED,
        payload: formdata,
      })
    }
  } else if (formaction === "update") {
    if (formtype === "groupsms") {
      dispatch({
        type: GROUP_SMS_SERVICE_UPDATED,
        payload: formdata,
      })

      dispatch({
        type: DROPDOWN_ITEM_UPDATED,
        formtype: formtype,
        code: formdata.update_code,
        name: formdata.name,
      })
    } else if (formtype === "groupsms_contact") {
      dispatch({
        type: GROUP_SMS_CONTACT_SERVICE_UPDATED,
        payload: formdata,
      })
    } else if (formtype === "groupsms_send") {
      dispatch({
        type: GROUP_SMS_SEND_SERVICE_UPDATED,
        payload: formdata,
      })
    } else if (formtype === "change_password") {
      dispatch({
        type: CHANGE_PASSWORD_SERVICE_UPDATED,
        payload: formdata,
      })

      dispatch({
        type: LAST_PAGE_UPDATE,
        lasttype: "change_password",
        lastaction: "update",
        lasttime: new Date().getTime(),
      })
    } else if (formtype === "sendsms_template") {
      dispatch({
        type: SINGLE_SMS_TEMPLATE_SERVICE_SUCCESS,
        payload: formdata,
      })
    } else if (formtype === "skip_password") {
      goToNavigate(`/${fyear}/welcome`)
    }
    removeDrawer(dispatch)
  }
}

const postUtilityServiceFail = ({ dispatch, response, formdata }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })
}

export function uploadSuccess(dispatch, response) {
  dispatch({
    type: UPLOAD_DOCUMENT_SUCCESS,
    payload: response.data.result,
  })
}

function uploadFail(dispatch, response) {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })

  /*return {
    type: 'UPLOAD_DOCUMENT_FAIL',
    error,
  };*/
}

export function utilityuploadRequest({ file, type, other_type, formValues, import_type, _functions }) {
  const formdata2 = Array.isArray(formValues) ? formValues.map(JSON.stringify) : JSON.stringify(formValues)
  if (typeof getAxiosCancelToken() != typeof undefined) {
    getAxiosCancelToken().cancel("Operation canceled due to new request.")
  }

  //fileName for progress bar
  let fileName = `${new Date().getTime()}_${other_type}`

  // save the new request for cancellation
  setAxiosCancelToken(axios.CancelToken.source())
  let data = new FormData()
  if (other_type === "ewaybill_to_invoice") {
    Object.keys(file).forEach((item) => {
      data.append(item, file[item])
    })
  } else {
    data.append("file", file)
  }
  data.append("type", "import")
  data.append("other_type", other_type)
  data.append("import_type", import_type)
  data.append("form_data", formdata2)
  data.append("fileName", fileName)

  return (dispatch) => {
    dispatch({ type: LOADING_STATE, state: true })
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "utility_upload" }),
      headers: { "Content-Type": "multipart/form-data" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
      onUploadProgress: function (progressEvent) {
        var uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        dispatch({ type: UTILITY_FILE_UPLOAD_PROGRESS, progress: uploadPercentage })
      },
    })
      // .then(response => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({ type: UTILITY_FILE_UPLOAD_PROGRESS, progress: 0 })
          uploadFail(dispatch, response.data.meta.message)
          dispatch({ type: LOADING_STATE, state: false })
        } else if (response.data.meta.code === 210) {
          // PROGRESS BAR
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: other_type,
            lastaction: "import_checkprogress",
            lasttime: new Date().getTime(),
            lastdata: fileName,
          })

          dispatch({ type: LOADING_STATE, state: false })
          dispatch({ type: UTILITY_FILE_UPLOAD_PROGRESS, progress: 0 })
        } else {
          // uploadSuccess(dispatch,response);
          if (type === "bank_statement") {
            var DetailGrid = Object.keys(response.data.result).map((i) => response.data.result[i])
            DetailGrid = DetailGrid.map((item, index) => {
              const detailGrid = { ...item }
              detailGrid.srNo = index + 1
              detailGrid.invoiceDate = item.date
              var invoiceArray = getAccountSitesetting("invoice_type_detail").filter(function (item) {
                return item.id !== "sales_order" && item.id !== "estimate" && item.id !== "getmystore"
              })
              if (detailGrid.type === "Receipt" && invoiceArray.length === 1) {
                detailGrid.saleInvoiceSelectedValue = invoiceArray[0].id
              }
              detailGrid.netamount = amount_format(detailGrid.amount)
              detailGrid.yesnoSelected = false
              detailGrid.visible = true
              return detailGrid
            })
            dispatch(change("ImportBankStatementForm", "DetailGrid", DetailGrid))
          } else {
            if (other_type === "import_summary") {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: import_type,
                lastaction: "import_summary",
                lasttime: new Date().getTime(),
                lastdata: response.data.result,
              })
            } else if (other_type === "rapnetupload ") {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: "lastaction",
                lastaction: "rapnetupload ",
                lasttime: new Date().getTime(),
                lastdata: response.data.result,
              })
            } else if (other_type === "einvoice_response") {
              // if (formValues && formValues.other_type === "salesreturn") {
              //   dispatch({
              //     type: SALESRETURN_E_INVOICE_UPDATE,
              //     formtype: "generate_einvoice",
              //     response: response.data.irn,
              //     ewayBillstatus: response.data.ewaybillStatus.toString(),
              //     payload: formValues,
              //   })
              // } else
              // if (formValues && formValues.other_type === "purchasereturn") {
              //   dispatch({
              //     type: PURCHASERETURN_E_INVOICE_UPDATE,
              //     formtype: "generate_einvoice",
              //     response: response.data.irn,
              //     ewayBillstatus: response.data.ewaybillStatus.toString(),
              //     payload: formValues,
              //   })
              // } else
              // {
              dispatch({
                type: E_INVOICE_UPDATE,
                formtype: "generate_einvoice",
                response: response.data.irn,
                ewayBillstatus: response.data.ewaybillStatus.toString(),
                payload: formValues,
              })
              // dispatch({
              //   type: SALESINVOICE_E_INVOICE_UPDATE,
              //   formtype: "generate_einvoice",
              //   response: response.data.irn,
              //   ewayBillstatus: response.data.ewaybillStatus.toString(),
              //   payload: formValues,
              // })
              // }
              dispatch({
                type: CONFIRM_DIALOG,
                payload: false,
                confirm: false,
                message: "",
                title: "",
                form: "",
                meta: "",
              })
            } else {
              dispatch({
                type: LAST_PAGE_UPDATE,
                lasttype: other_type,
                lastaction: "import",
                lasttime: new Date().getTime(),
                lastdata: response.data.result,
              })
            }
          }
          /* dispatch({
              type: UPLOAD_DOCUMENT_SUCCESS,
              payload:Object.keys(response.data.result).map(i => response.data.result[i])
            });*/

          if (type !== "bank_statement") {
            dispatch({ type: FORM_DEP_SERVICE_CLEAR })
          }
          dispatch({ type: LOADING_STATE, state: false })
          dispatch({ type: UTILITY_FILE_UPLOAD_PROGRESS, progress: 0 })
        }
      })
      .catch((error) => {
        dispatch({ type: UTILITY_FILE_UPLOAD_PROGRESS, progress: 0 })
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          uploadFail(dispatch, getCatchErrorMessage(error))
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const wireTranferPrint = ({ code, functions }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    dispatch({ type: LOADING_STATE, state: true })
    var form_data = { code: code }
    form_data = Array.isArray(form_data) ? form_data.map(JSON.stringify) : JSON.stringify(form_data)
    let data = new FormData()
    data.append("type", "wireTransferPrint")
    data.append("form_data", form_data)
    data.append("flag", 1)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "wireTransferPrint" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        dispatch({ type: LOADING_STATE, state: false })
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          var metadata = {}
          metadata.filename = `${getAccountSessionUserData("company_url")}_wireTransfer_${code}_${getAccountSessionUserData("financial_year")}`
          print_file(response.data.result, metadata.filename)
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const changeYearFix = ({ formdata, company_url, financial_year, functions }) => {
  const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
  return (dispatch) => {
    dispatch({ type: LOADING_STATE, state: true })
    let data = new FormData()
    data.append("form_data", formdata2)
    data.append("company_url", company_url)
    data.append("financial_year", financial_year)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "changeyear_fix" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "changeYearFix",
            lastaction: "success",
            lasttime: new Date().getTime(),
          })
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
        dispatch({ type: LOADING_STATE, state: false })
      })
  }
}

export const getForwardGSTPendingInvoice = ({ onSuccess, onError, onComplete, functions }) => {
  return (dispatch) => {
    let data = new FormData()
    data.append("form_data", "change_year")
    data.append("offset", 0)
    data.append("limit", "All")
    data.append("dependency", true)
    data.append("form_type", "change_year")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "dependency" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          onError()
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          onComplete(response.data.change_year.change_year_first_time)
          onSuccess(response.data.change_year.forward_bill_flag === 1)
        }
      })
      .catch((error) => {
        onError()
        postErrorOnException(error)
        dispatch({
          type: SNACKBAR_STATE,
          payload: true,
          message: getCatchErrorMessage(error),
          snackbarType: "error",
        })
      })
  }
}

export const common_post_utility_data = ({ formtype, formaction, formdata }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({ type: LOADING_STATE, state: true })
    if (formaction !== "") {
      const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)

      dispatch({
        type: UTILITY_POST_SERVICE_START,
        formaction: formaction,
        formtype: formtype,
      })
      let data = new FormData()
      data.append("type", formtype)
      data.append("action", formaction)
      data.append("form_data", formdata2)
      data.append("offset", 0)
      data.append("limit", "All")
      axios.defaults.headers.common = getheaders()
      axios({
        method: "post",
        url: list_url({ type: "post_utility_data" }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
        cancelToken: getAxiosCancelToken().token,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          if (response.data.meta.code === 401) {
            postUtilityServiceFail({ dispatch, response: response.data.meta.message, formdata })
            dispatch({ type: LOADING_STATE, state: false })
          } else {
            postUtilityServiceSuccess({ dispatch, user: response, formtype, formaction, formdata })
            dispatch({ type: LOADING_STATE, state: false })
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            dispatch({ type: LOADING_STATE, state: false })
          } else {
            postErrorOnException(error)
            postUtilityServiceFail({ dispatch, response: getCatchErrorMessage(error), formdata })
            dispatch({ type: LOADING_STATE, state: false })
          }
        })
    }
  }
}

export const getWhatsappToken = ({ onSuccess, onError }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    let data = new FormData()
    data.append("test", "test")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "get",
      url: list_url({ type: "getWhatsappToken" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          onError(response.data.meta.message)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          onSuccess(response.data.result)
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
        } else {
          postErrorOnException(error)
          onError(getCatchErrorMessage(error))
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        }
      })
  }
}

export const paymentClick = ({ company_url, callback_url }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    dispatch({
      type: LAST_PAGE_UPDATE,
      lasttype: "payment_link",
      lastaction: "start",
      lasttime: new Date().getTime(),
    })
    let data = new FormData()
    data.append("company_url", company_url)
    data.append("callback_url", callback_url)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "payment_link" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "payment_link",
          lastaction: "complete",
          lasttime: new Date().getTime(),
        })
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          var url = response.data.result.short_url
          const win = window.open(url)
          win.focus()

          // window.location.href = response.data.result.short_url
        }
      })
      .catch((error) => {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "payment_link",
          lastaction: "complete",
          lasttime: new Date().getTime(),
        })
        if (axios.isCancel(error)) {
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        }
      })
  }
}

export const paymentComplete = ({ functions, company_url, paymentKeys }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    dispatch({
      type: LAST_PAGE_UPDATE,
      lasttype: "payment_Complete",
      lastaction: "start",
      lasttime: new Date().getTime(),
    })
    let data = new FormData()
    data.append("company_url", company_url)
    data.append("paymentKeys", paymentKeys)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "payment_complete" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "payment_Complete",
          lastaction: "complete",
          lasttime: new Date().getTime(),
        })
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "payment_Complete",
          lastaction: "complete",
          lasttime: new Date().getTime(),
        })
        if (axios.isCancel(error)) {
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        }
      })
  }
}

export const getAllData = ({ listtype, type, columnData, order, orderBy, search_text, functions, filename, title }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    let data = new FormData()
    dispatch({
      type: LOADING_STATE,
      state: true,
    })
    data.append("offset", 0)
    data.append("type", listtype)
    data.append("limit", "All")
    data.append("sort_order", order)
    data.append("sort_by", orderBy)
    data.append("search_text", search_text)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: listtype }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        dispatch({
          type: LOADING_STATE,
          state: false,
        })
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          if (isblank(response.data.result) || response.data.result.length === 0) {
            dispatch({
              type: SNACKBAR_STATE,
              payload: true,
              message: "No data found",
              snackbarType: "error",
            })
          } else {
            if (listtype === "item") {
              response.data.result = dataSetInList(response.data.result)
            }
            const newcolumnData = columnData.filter((e) => !e.isImage)
            if (type === "print") {
              const printData = generateListPrint({ data: response.data.result, columnData: newcolumnData, functions, title })
              fbprintpdf({ docDefinition: printData, metadata: { filename }, action: "print" })
            } else {
              generateListExcel({ data: response.data.result, columnData: newcolumnData, functions, filename })
            }
          }
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
        } else {
          postErrorOnException(error)
          dispatch({
            type: LOADING_STATE,
            state: false,
          })
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
        }
      })
  }
}

export const generate_authKey = ({ auth_key_enc, functions }) => {
  return (dispatch) => {
    const { year } = functions
    const type = "otp"
    const action = "update"
    const other_type = "update_key"
    const form_data = {}
    form_data.auth_key = auth_key_enc
    dispatch({ type: LOADING_STATE, state: true })

    const formdata2 = Array.isArray(form_data) ? form_data.map(JSON.stringify) : JSON.stringify(form_data)
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())

    let formdata = new FormData()
    formdata.append("form_data", formdata2)
    formdata.append("type", type)
    formdata.append("action", action)
    formdata.append("other_type", other_type)
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "generate_authKey" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formdata,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          dispatch({ type: LOADING_STATE, state: false })

          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
          })
        } else {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
          })
          var user_data = ""
          if (year) {
            user_data = getStorage(`user_data_${year}`)
            user_data.auth_key = form_data.auth_key
            setStorage(`user_data_${year}`, user_data)
          } else {
            user_data = getStorage("user_data")
            user_data.auth_key = form_data.auth_key
            setStorage("user_data", user_data)
          }
          dispatch({
            type: UPDATE_USER_DATA,
            user_data: user_data,
          })

          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "generate_authKey",
            lastaction: "success",
            lasttime: new Date().getTime(),
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch({ type: LOADING_STATE, state: false })
        } else {
          postErrorOnException(error)
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
          })
          dispatch({ type: LOADING_STATE, state: false })
        }
      })
  }
}

export const sendNewNotifications = ({
  type,
  caption,
  message,
  number,
  email,
  defaultMobile,
  defaultEmail,
  partyCode,
  filename,
  filebase64,
  filemimetype,
  file,
  send_type,
  doc_type,
  functions,
  docDetail,
  hideLoading,
  onComplete,
}) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_SNACKBAR_STATE,
      payload: true,
      message: "Sending Notifications",
    })
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    let data = new FormData()
    if (!hideLoading) {
      dispatch({
        type: LOADING_STATE,
        state: true,
      })
    }
    if (caption) {
      data.append("caption", caption)
    }
    if (number) {
      data.append("number", number)
    }
    if (partyCode) {
      data.append("partyCode", partyCode)
    }
    if (defaultMobile) {
      data.append("defaultMobile", defaultMobile)
    }
    if (defaultEmail) {
      data.append("defaultEmail", defaultEmail)
    }
    if (email) {
      data.append("email", email)
    }
    if (message) {
      data.append("message", message)
    }
    if (filename) {
      data.append("filename", filename)
    }
    if (filebase64) {
      data.append("filebase64", filebase64)
    }
    if (filemimetype) {
      data.append("filemimetype", filemimetype)
    }
    if (file) {
      data.append("file", file)
    }
    if (type) {
      data.append("type", type)
    }
    if (send_type) {
      data.append("send_type", send_type)
    }
    if (doc_type) {
      data.append("doc_type", doc_type)
    }
    if (docDetail) {
      const doc_detail = Array.isArray(docDetail) ? docDetail.map(JSON.stringify) : JSON.stringify(docDetail)
      data.append("doc_detail", doc_detail)
    }
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "send_notifications" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        dispatch({
          type: LOADING_STATE,
          state: false,
        })
        dispatch({
          type: LOADING_SNACKBAR_STATE,
          payload: false,
          message: "Sending Notifications",
        })
        if (response.data.meta.code === 401) {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "error",
            SnackbarOriginVertical: "top",
            SnackbarOriginHorizontal: "center",
          })

          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "sendNotification",
            lastaction: "error",
            lasttime: new Date().getTime(),
          })
        } else if (response.data.meta.code === 403) {
          dispatch({
            type: CONFIRM_DIALOG,
            payload: true,
            confirm: false,
            message: "",
            title: "Message",
            form: "Error List",
            meta: response.data.meta.message,
          })

          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "sendNotification",
            lastaction: "error",
            lasttime: new Date().getTime(),
          })
        } else {
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: response.data.meta.message,
            snackbarType: "success",
            SnackbarOriginVertical: "top",
            SnackbarOriginHorizontal: "center",
          })

          dispatch({
            type: LAST_PAGE_UPDATE,
            lasttype: "sendNotification",
            lastaction: "success",
            lasttime: new Date().getTime(),
          })

          if (onComplete) {
            onComplete()
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: LAST_PAGE_UPDATE,
          lasttype: "sendNotification",
          lastaction: "error",
          lasttime: new Date().getTime(),
        })

        if (axios.isCancel(error)) {
          dispatch({
            type: LOADING_SNACKBAR_STATE,
            payload: false,
            message: "Sending Notifications",
          })
        } else {
          postErrorOnException(error)
          dispatch({
            type: LOADING_STATE,
            state: false,
          })
          dispatch({
            type: LOADING_SNACKBAR_STATE,
            payload: false,
            message: "Sending Notifications",
          })
          dispatch({
            type: SNACKBAR_STATE,
            payload: true,
            message: getCatchErrorMessage(error),
            snackbarType: "error",
            SnackbarOriginVertical: "top",
            SnackbarOriginHorizontal: "center",
          })
        }
      })
  }
}

export const getBalances = ({ functions, onSuccess, onError }) => {
  return (dispatch) => {
    if (typeof getAxiosCancelToken() != typeof undefined) {
      getAxiosCancelToken().cancel("Operation canceled due to new request.")
    }
    // save the new request for cancellation
    setAxiosCancelToken(axios.CancelToken.source())
    let data = new FormData()
    data.append("type", "get_balance")
    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "get_balance" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
      cancelToken: getAxiosCancelToken().token,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          onError(response.data.meta.message)
        } else {
          onSuccess(response.data.result)
        }
      })
      .catch((error) => {
        onError(getCatchErrorMessage(error))
        if (axios.isCancel(error)) {
        } else {
          postErrorOnException(error)
        }
      })
  }
}
