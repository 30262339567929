import { Grid } from "@material-ui/core"
import { post_form_url } from "@project/sharedcomponents/apiURL"
import ErrorMessages from "@project/sharedcomponents/constantData/ErrorMessages"
import DD_Districts from "@project/sharedcomponents/constantData/district_data"
import {
  cloneObj,
  convertIntif,
  createDropDown,
  getAxiosCancelToken,
  getCatchErrorMessage,
  isBlankCode,
  isEqual,
  isblank,
  setAxiosCancelToken,
  validateAddress,
  validateZipcode,
} from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetch_dependency, fetch_form_dep, getAllStateOfCountry, loadingstate, rightdrawerstate, snackbarstate } from "../../Actions"
import { DROPDOWN_ITEM_INSERTED } from "../../Actions/types"
import { checkdropdowndependancy, getAccountSessionUserData, getAccountSitesetting, getheaders, handleResponse, postErrorOnException } from "../../Lib/commonfunctions"
import Field from "./reduxFormComponents"

const AddressComponentV2 = (props) => {
  const { control, watch, setValue, formValues, formdata, reset, checkDependency = false } = props
  const [dropDownState, setDropDownStates] = useState([])
  const [dropDownDistrict, setDropDownDistrict] = useState([])

  const [dropDownCity, setDropDownCity] = useState([])
  const [lastImportField, setLastImportField] = useState("")
  const [isCityLoading, setIsCityLoading] = useState(false)

  const _formValues = watch()
  const { importCountryButton, address, street, zipcode, country, state, city, cityType, loading, sidebarloading, mode, require, district = "", boarding, disabled = false } = props
  const { dd_country_state, ddcity, dd_allcountryForOnboarding, countryallstateForOnboarding } = useSelector((state) => state.CommonReducer)
  const dispatch = useDispatch()

  const countryDD = boarding ? dd_allcountryForOnboarding : dd_country_state

  const addressValidation = (value) => {
    if (!isblank(value)) {
      const isValid = validateAddress(value)
      if (!isValid) {
        return ErrorMessages["E00528"]
      }
      return isValid
    }
  }

  const zipValidation = (value) => {
    if (!isblank(value)) {
      const countryCode = formValues[country]
      if (convertIntif(countryCode) === 103) {
        const isValid = validateZipcode(value)
        if (!isValid) {
          return ErrorMessages["E00487"]
        }
        return isValid
      }
    }
  }

  const countryValue = country
  const oncountrychange = (value) => {
    setValue(state, "")
    setValue(city, "")
    setDropDownCity([])

    if (!isblank(district)) {
      setValue(district, "")
      setDropDownDistrict([])
    }

    if (isblank(value)) {
      setDropDownStates([])
    } else {
      setDropDownStates(value.states)
    }
  }

  const importUnit = () => {
    setLastImportField(country)
    dispatch(rightdrawerstate({ open: true, title: "Import Country", data: { from: "dropDownImport" }, position: "right", width: 450 }))
  }

  const onstatechange = (value) => {
    let stateName = value.name
    if (stateName === "Andhra Pradesh (Old)") {
      stateName = "Andhra Pradesh"
    }
    setValue(city, "")
    if (!isblank(district)) {
      setValue(district, "")
    }

    if (isblank(value)) {
      setDropDownCity([])
      if (!isblank(district)) {
        setDropDownDistrict([])
      }
    } else {
      if (!isblank(stateName) && !isblank(district)) {
        setDistrict(stateName)
      }
      setDropDownCity(
        ddcity.filter(function (item) {
          return parseInt(item.sid) === parseInt(value.code)
        })
      )
    }
  }

  const handleCityCreate = (inputValue) => {
    if (!isBlankCode(formValues[state])) {
      setIsCityLoading(true)
      const formtype = "city"
      var formaction = "insert"
      var formdata = {}
      formdata.city = inputValue
      formdata.stateSelectedValue = createDropDown(dropDownState, formValues[state], "code")
      formdata.stateSelectedValue = createDropDown(dropDownState, formValues[state], "code")
      formdata.countrySelectedValue = createDropDown(cloneObj(countryDD), formValues[country], "id")
      delete formdata.countrySelectedValue.states
      const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
      if (typeof getAxiosCancelToken() != typeof undefined) {
        getAxiosCancelToken().cancel("Operation canceled due to new request.")
      }
      // save the new request for cancellation
      setAxiosCancelToken(axios.CancelToken.source())
      dispatch(loadingstate(true))
      let data = new FormData()
      data.append("type", formtype)
      data.append("action", formaction)
      data.append("form_data", formdata2)
      data.append("offset", 0)
      data.append("limit", "All")
      axios.defaults.headers.common = getheaders()
      axios({
        method: "post",
        url: post_form_url({ formtype, props: { getAccountSitesetting } }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
        cancelToken: getAxiosCancelToken().token,
      })
        .then((response) => handleResponse(response, dispatch))
        .then((response) => {
          if (response.data.meta.code === 401) {
            dispatch(snackbarstate({ open: true, message: response.data.meta.message, snackbarType: "error" }))
            dispatch(loadingstate(false))
            setIsCityLoading(false)
          } else {
            dispatch({
              type: DROPDOWN_ITEM_INSERTED,
              formtype: formtype,
              code: `${response.data.insert_code}`,
              name: formdata.city,
              payload: formdata,
            })

            setTimeout(() => {
              setValue(city, response.data.insert_code)
              setIsCityLoading(false)
            }, 1000)
            dispatch(loadingstate(false))
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            dispatch(loadingstate(false))
          } else {
            postErrorOnException(error)
            dispatch(snackbarstate({ open: true, message: getCatchErrorMessage(error), snackbarType: "error" }))
            dispatch(loadingstate(false))
          }
        })
    } else {
      dispatch(snackbarstate({ open: true, message: ErrorMessages["E00475"], snackbarType: "error" }))
    }
  }

  useEffect(() => {
    /* setStateDropDown()

    if (!isblank(district)) {
      setDistrictDropDown()
    } */

    if (cityType === "dropDown") {
      var dropdown_string = ""
      if (checkDependency === true) {
        dropdown_string = "city"
      }
      dropdown_string = checkdropdowndependancy(dropdown_string)
      if (dropdown_string !== "") {
        dispatch(
          fetch_dependency({
            dropdown_string,
          })
        )
      }
    }
    getOnBoardingState()

    setAllDropdown({ countryVal: formValues[country], stateVal: formValues[state], district: formValues[district] })
  }, [])

  const getOnBoardingState = () => {
    if (boarding) {
      var dropdown_string = "dd_allcountry"
      dropdown_string = checkdropdowndependancy(dropdown_string)

      if (dropdown_string !== "") {
        dispatch(
          fetch_form_dep({
            dropdown_string: "dd_allcountry",
            form_type: "onboarding",
          })
        )
      }

      if (!isblank(formValues[country])) {
        const functions = {}
        dispatch(getAllStateOfCountry({ country: formValues[country], functions }))
      }
    }
  }

  useEffect(() => {
    getOnBoardingState()
  }, [formValues[country]])

  useEffect(() => {
    let countryVal = formValues[country]
    let stateVal = formValues[state]
    if (!isblank(formdata?.data?.form_data) && isblank(countryVal)) {
      /*reset((formValues) => {
        return {
          ...formValues,
          ...formdata?.data?.form_data,
        }
      })*/

      countryVal = formdata?.data?.form_data[country]
      stateVal = formdata?.data?.form_data[state]

      // setValue(`${country}`, countryVal)
      // setValue(`${state}`, stateVal)
    }

    setAllDropdown({ countryVal, stateVal, district })
    /*  if (!isblank(countryVal)) {
      setStateDropDown(countryVal)
    }
    if (!isblank(stateVal) && !isblank(ddcity) && ddcity.length > 0) {
      setDropDownCity(
        ddcity.filter(function(item) {
          return parseInt(item.sid) === parseInt(stateVal)
        })
      )
      if (!isblank(district)) {
        setDistrictDropDown()
      }
    } */
  }, [formdata, ddcity, countryDD])

  const setAllDropdown = ({ countryVal, stateVal, district }) => {
    if (!isblank(countryVal)) {
      setStateDropDown(countryVal)
    }
    if (!isblank(stateVal) && !isblank(ddcity) && ddcity.length > 0) {
      setDropDownCity(
        ddcity.filter(function (item) {
          return parseInt(item.sid) === parseInt(stateVal)
        })
      )
      if (!isblank(district)) {
        setDistrictDropDown()
      }
    }
  }

  useEffect(() => {
    if (boarding && !isblank(countryallstateForOnboarding) && countryallstateForOnboarding.length > 0) {
      setDropDownStates(countryallstateForOnboarding)
    }
  }, [countryallstateForOnboarding])

  useEffect(() => {
    if (!isblank(district) && dropDownState.length > 0) {
      setDistrictDropDown()
    }
  }, [dropDownState])

  useEffect(() => {
    if (!isblank(country) && !isblank(state) && !isblank(_formValues[country]) && !isblank(_formValues[state])) {
      setStateDropDown(_formValues[country])
    }

    if (!isblank(city) && !isblank(_formValues[state])) {
      setDropDownCity(
        ddcity.filter(function (item) {
          return parseInt(item.sid) === parseInt(_formValues[state])
        })
      )
    }

    if (!isblank(district) && !isblank(_formValues[state])) {
      let selectedState = ""
      if (!isBlankCode(_formValues[state]) && dropDownState.length > 0) {
        selectedState = createDropDown(dropDownState, _formValues[state], "code")
      }
      if (!isblank(selectedState)) {
        setDistrict(selectedState.name)
      }
    }
  }, [_formValues[state], _formValues[district], _formValues[country]])

  const setStateDropDown = (country) => {
    let dropDownStates = []
    if (!boarding) {
      if (!isblank(country)) {
        dropDownStates = countryDD.find((item) => item.id === country)
      } else if (!isblank(formValues) && !isblank(formValues.countrySelectedValue)) {
        dropDownStates = countryDD.find((item) => item.id === formValues.countrySelectedValue)
      } else if (!isblank(formValues) && !isblank(formValues[countryValue])) {
        dropDownStates = countryDD.find((item) => item.id === formValues[countryValue])
      } else if (!isblank(formdata?.data?.form_data[country])) {
        dropDownStates = countryDD.find((item) => item.id === formdata?.data?.form_data[country])
      } else {
        setDropDownStates([])
      }
      if (!isblank(dropDownStates) && dropDownState.length === 0) {
        setDropDownStates(dropDownStates.states)
      } else if (!isblank(dropDownStates) && !isEqual(dropDownState, dropDownStates.states)) {
        setDropDownStates(dropDownStates.states)
      }
    }
  }

  const setDistrictDropDown = () => {
    let selectedState = ""
    if (!isBlankCode(formValues[state]) && dropDownState.length > 0) {
      selectedState = createDropDown(dropDownState, formValues[state], "code")
    }
    if (!isblank(selectedState) && !isblank(district)) {
      setDistrict(selectedState.name)
    }
  }

  const setDistrict = (name) => {
    if (!isblank(name)) {
      const districtList = DD_Districts.find((e) => e.state.toLowerCase() === name.toLowerCase())
      if (!isblank(districtList) && !isblank(districtList.districts) && districtList.districts.length > 0) {
        setDropDownDistrict(districtList.districts)
      } else {
        setDropDownDistrict([])
      }
    } else {
      setDropDownDistrict([])
    }
  }

  const renderCountry = country ? (
    <Field
      name={country}
      inputType={"RFReactSelect"}
      label="COUNTRY"
      control={control}
      placeholder="Select country"
      disabled={loading || sidebarloading || disabled}
      isClearable={false}
      rules={{ required: require && require[country] }}
      isRequired={require && require[country]}
      options={countryDD.map(function (value) {
        return { value: value.id, label: value.name, row: value }
      })}
      onvaluechange={oncountrychange}
      {...(importCountryButton && {
        onImportClick: importUnit,
      })}
    />
  ) : null

  const renderState = state ? (
    <Field
      name={state}
      inputType={"RFReactSelect"}
      label="STATE"
      control={control}
      rules={{ required: require && require[state] }}
      placeholder="Select state"
      isRequired={require && require[state]}
      disabled={loading || sidebarloading || disabled}
      options={dropDownState?.map(function (value) {
        return { value: value.code, label: value.name, row: value }
      })}
      onvaluechange={onstatechange}
    />
  ) : null

  const renderDistrict = district ? (
    <Field
      name={district}
      inputType={"RFReactSelect"}
      control={control}
      label="District"
      helpText="Only used for Analytics Module"
      placeholder="Select district"
      disabled={loading || sidebarloading || disabled}
      options={dropDownDistrict.map(function (value) {
        return { value: value, label: value, row: value }
      })}
    />
  ) : null

  let renderCity = null
  if (city) {
    renderCity =
      cityType === "dropDown" ? (
        <Field
          name={city}
          inputType={"RFCreatableSelect"}
          label="CITY"
          placeholder="Select city"
          control={control}
          disabled={isCityLoading || isblank(formValues) || isBlankCode(formValues[state]) || disabled}
          isLoading={isCityLoading}
          isRequired={require && require[city]}
          onAddNewClick={handleCityCreate}
          options={dropDownCity?.map(function (value) {
            return { value: value.code, label: value.name, row: value }
          })}
        />
      ) : (
        <Field
          name={city}
          disabled={disabled}
          control={control}
          inputType={"RenderTextField"}
          isRequired={require && require[city]}
          rules={{ required: require && require[city] }}
          label="CITY"
          placeholder="Enter city name"
        />
      )
  }

  const renderZipcode = zipcode ? (
    <Field
      name={zipcode}
      control={control}
      inputType={"RenderTextField"}
      rules={{ required: require && require[zipcode], validate: zipValidation }}
      isRequired={require && require[zipcode]}
      label="ZIP CODE"
      disabled={disabled}
      placeholder="Enter Zip Code"
    />
  ) : null
  return (
    <React.Fragment>
      {address && (
        <Field
          name={address}
          inputType={"RenderTextField"}
          multiline={true}
          isRequired={require && require[address]}
          rules={{ required: require && require[address], validate: addressValidation }}
          rows="3"
          disabled={disabled}
          label="ADDRESS LINE 1"
          placeholder="Enter address line 1"
          fieldType="address"
          control={control}
        />
      )}
      {street && (
        <Field
          name={street}
          inputType={"RenderTextField"}
          multiline={true}
          isRequired={require && require[street]}
          label="ADDRESS LINE 2"
          rules={{ validate: addressValidation }}
          rows="3"
          disabled={disabled}
          placeholder="Enter address line 2"
          fieldType="address"
          control={control}
        />
      )}
      {mode === "compact" ? (
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {renderCountry}
              {renderState}
              {renderDistrict}
            </Grid>
            <Grid item xs={12} md={6}>
              {renderCity}
              {renderZipcode}
            </Grid>
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {renderCountry}
          {renderState}
          {renderDistrict}
          {renderCity}
          {renderZipcode}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default AddressComponentV2
