import { CircularProgress, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@material-ui/core"
import Chip from "@material-ui/core/Chip"
import { Add, Cancel, Close, ExpandMore } from "@material-ui/icons"
import { getheaders } from "@project/components/sessionStoreFunctions"
import { list_url } from "@project/sharedcomponents/apiURL"
import { postErrorOnException } from "@project/sharedcomponents/axiosFunctions"
import { capitalizeFirstLetter, getCatchErrorMessage, isblank } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import React, { useEffect, useRef } from "react"
import { isMobileOnly } from "react-device-detect"
import { useSelector } from "react-redux"
import { components } from "react-select"
import AsyncSelect from "react-select/async"
import { VariableSizeList as RWList } from "react-window"
import { RenderHelpComponent, colourStyles, renderLabel } from "./reactSelectComponent"

const RFAsyncSelect = ({
  input,
  options,
  multi,
  isClearable,
  labelStyle,
  helpText,
  placeholder,
  helpStyle,
  HelpComponent,
  toolTipStyle,
  isSearchable,
  getLocalSiteSetting,
  getconfigsettingdata,
  formprops,
  modetype,
  setUnderLine,
  label,
  disabled,
  onbtnClick,
  btnText,
  secondbtnText,
  onSecondbtnClick,
  filterList,
  menuIsOpen,
  dataindex,
  onAddNewClick,
  onvaluechange,
  className,
  meta,
  inputonly,
  isRequired,
  getAccountSessionUserData,
  extraDisplayFields,
  ...props
}) => {
  const { name, onBlur, onChange, onFocus } = input
  const { error, touched } = meta
  var height = 40
  var listheight = 300
  var timeout = 0
  const formattedOptions = {}
  const theme = useTheme()
  const accountSiteSettingKeyValue = useSelector((state) => state.AuthReducer.accountSiteSettingKeyValue)
  const __cust_hideContactInfo = getconfigsettingdata ? parseInt(getconfigsettingdata("__cust_hideContactInfo")) || 0 : 0

  const getSelectTheme = (theme, touched, error) => {
    return {
      /*
       * multiValue(remove)/color:hover
       */
      danger: theme.palette.text.primary,

      /*
       * multiValue(remove)/backgroundColor(focused)
       * multiValue(remove)/backgroundColor:hover
       */
      //dangerLight: theme.palette.grey[200],
      dangerLight: theme.palette.text.primary,

      /*
       * control/backgroundColor
       * menu/backgroundColor
       * option/color(selected)
       */
      // neutral0: theme.palette.background.default,
      neutral0: theme.palette.background.paper,

      /*
       * control/backgroundColor(disabled)
       */
      neutral5: "rgba(239, 239, 239, 0.3)",

      /*
       * control/borderColor(disabled)
       * multiValue/backgroundColor
       * indicators(separator)/backgroundColor(disabled)
       */
      neutral10: "rgba(0, 0, 0, 0.1)",

      /*
       * control/borderColor
       * option/color(disabled)
       * indicators/color
       * indicators(separator)/backgroundColor
       * indicators(loading)/color
       */
      neutral20: Boolean(touched && error) ? theme.palette.error.main : theme.palette.grey["A200"],

      /*
       * control/borderColor(focused)
       * control/borderColor:hover
       */
      // this should be the white, that's normally selected
      neutral30: theme.palette.text.primary,

      /*
       * menu(notice)/color
       * singleValue/color(disabled)
       * indicators/color:hover
       */
      neutral40: "rgba(0, 0, 0, 0.3)",

      /*
       * placeholder/color
       */
      // seen in placeholder text
      neutral50: theme.palette.grey["A200"],

      /*
       * indicators/color(focused)
       * indicators(loading)/color(focused)
       */
      neutral60: theme.palette.text.primary,
      neutral70: theme.palette.text.primary,

      /*
       * input/color
       * multiValue(label)/color
       * singleValue/color
       * indicators/color(focused)
       * indicators/color:hover(focused)
       */
      neutral80: theme.palette.text.primary,

      // no idea
      neutral90: "pink",

      /*
       * control/boxShadow(focused)
       * control/borderColor(focused)
       * control/borderColor:hover(focused)
       * option/backgroundColor(selected)
       * option/backgroundColor:active(selected)
       */
      //primary: theme.palette.text.primary,
      primary: Boolean(touched && error) ? theme.palette.error.main : theme.palette.primary.main,

      /*
       * option/backgroundColor(focused)
       */
      primary25: theme.palette.type == "dark" ? theme.palette.background.default : "#DEEBFF",

      /*
       * option/backgroundColor:active
       */
      primary50: theme.palette.type == "dark" ? theme.palette.background.default : "#B2D4FF",
      primary75: theme.palette.background.paper,
    }
  }

  if (!isblank(options)) {
    options.forEach((obj) => {
      formattedOptions[obj.value] = obj
    })
  }

  function multiChangeHandler(func, onvaluechange, dataindex) {
    return function handleMultiHandler(values) {
      if (values != null) {
        func(values.map((value) => value.value))
        if (onvaluechange) {
          onvaluechange(
            dataindex,
            values.map((value) => value.value)
          )
        }
      } else {
        func([])
        if (onvaluechange) {
          onvaluechange([])
        }
      }
    }
  }

  function singleChangeHandler(func, onvaluechange, dataindex) {
    return function handleSingleChange(value) {
      func(value ? value.value : "")
      if (onvaluechange) {
        var objvalue = value ? value.row : ""
        var objdataindex = dataindex >= 0 ? dataindex : ""
        onvaluechange(objvalue, objdataindex)
      }
    }
  }

  function MultiValue(prop) {
    return <Chip size="small" style={{ margin: "4px 2px" }} tabIndex={-1} label={prop.children} onDelete={prop.removeProps.onClick} deleteIcon={<Cancel {...prop.removeProps} />} />
  }

  const Option = ({ children, ...props }) => {
    const { mode } = props.selectProps
    if (mode === "CUSTOMER") {
      var city = ""
      var gstin = ""
      if (isblank(props.data.row.city) && isblank(props.data.row.state) && isblank(props.data.row.mobile)) {
        city = "N/A"
      } else {
        city = props.data.row.city
        if (!isblank(props.data.row.state)) {
          if (!isblank(props.data.row.city)) {
            city = `${city} - ${props.data.row.state}`
          } else {
            city = props.data.row.state
          }
        }
      }

      if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.tax === 1 && parseInt(accountSiteSettingKeyValue.show_gstinCutomerList) === 1) {
        if (!isblank(props.data.row.gstin)) {
          gstin = props.data.row.gstin
        } else {
          gstin = "N/A"
        }
      }
      return (
        <components.Option {...props}>
          <Typography variant="subtitle2" noWrap={true}>
            {props.data.label}
          </Typography>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Typography variant="caption">{city}</Typography>
            {__cust_hideContactInfo !== 1 && <Typography variant="caption">{props.data.row.mobile}</Typography>}
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Typography variant="caption">{gstin}</Typography>
          </div>
        </components.Option>
      )
    } else if (mode === "WITHDESCRIPTION") {
      var description = ""
      if (isblank(props.data.row.description)) {
        description = "N/A"
      } else {
        description = props.data.row.description
      }
      return (
        <components.Option {...props}>
          <Typography variant="subtitle2" noWrap>
            {props.data.label}
          </Typography>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Typography variant="caption">{description}</Typography>
          </div>
        </components.Option>
      )
    } else if (mode === "ITEM") {
      var group = ""
      var subgroup = ""
      var subgroupLevel2 = ""
      var barcode = ""
      var hsncode = ""
      if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.item_group === 1) {
        group = props.data.row.itemgroup
        if (isblank(group)) {
          group = "N/A"
        }
        group = `Group: ${group}`

        if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.item_category === 1) {
          subgroup = props.data.row.itemcate_name
          if (isblank(subgroup)) {
            subgroup = "N/A"
          }
          subgroup = `Subgroup: ${subgroup}`
          if (accountSiteSettingKeyValue.item_subgroupLevel2 === 1) {
            subgroupLevel2 = props.data.row.itemsubgrouplevel2_name
            if (isblank(subgroupLevel2)) {
              subgroupLevel2 = "N/A"
            }
            subgroupLevel2 = `Subgroup: ${subgroupLevel2}`
          }
        }
      }
      if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.sku_number === 1) {
        if (accountSiteSettingKeyValue.barcode_mng === 1 && !isblank(props.data.row.stockku)) {
          barcode = `SKU: ${props.data.row.stockku}`
        }
        if (isblank(barcode)) {
          barcode = "N/A"
        }
        barcode = `SKU: ${barcode}`
      }
      if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.tax === 1) {
        if (!isblank(props.data.row.hsn_code)) {
          hsncode = props.data.row.hsn_code
        }
        if (isblank(hsncode)) {
          hsncode = "N/A"
        }
        hsncode = `HSN: ${hsncode}`
      }

      return (
        <components.Option {...props}>
          <Typography variant="subtitle2" noWrap>
            {props.data.label}
          </Typography>

          <Grid container spacing={0}>
            <Grid item xs>
              {accountSiteSettingKeyValue && accountSiteSettingKeyValue.item_group === 1 && (
                <React.Fragment>
                  <Typography variant="caption" display="block">
                    {group}
                  </Typography>
                  {accountSiteSettingKeyValue.item_category === 1 && (
                    <React.Fragment>
                      <Typography variant="caption" display="block">
                        {subgroup}
                      </Typography>
                      {accountSiteSettingKeyValue.item_subgroupLevel2 === 1 && (
                        <Typography variant="caption" display="block">
                          {subgroupLevel2}
                        </Typography>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </Grid>
            <Grid item xs>
              {accountSiteSettingKeyValue && (accountSiteSettingKeyValue.sku_number === 1 || accountSiteSettingKeyValue.tax === 1) && (
                <React.Fragment>
                  {!isblank(hsncode) && <Typography variant="caption">{hsncode}</Typography>}
                  {!isblank(barcode) && <Typography variant="caption">{barcode}</Typography>}
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </components.Option>
      )
    } else if (mode === "MEMBER") {
      return (
        <components.Option {...props}>
          <Typography variant="subtitle2" noWrap>
            {props.data.label}
          </Typography>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Typography variant="caption">MemberId: {props.data.row.memberid}</Typography>
            <Typography variant="caption">Mobile: {props.data.row.mobile}</Typography>
          </div>
        </components.Option>
      )
    } else if (mode === "ALLACCOUNT") {
      return (
        <components.Option {...props}>
          <Typography variant="subtitle2" noWrap>
            {props.data.label}
          </Typography>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Typography noWrap variant="caption" display="block">
              {props.data.row.groupName}
            </Typography>
            {!isblank(props.data.row.city) && (
              <Typography noWrap variant="caption" display="block">
                {props.data.row.city}
              </Typography>
            )}
          </div>
        </components.Option>
      )
    } else {
      return (
        <components.Option {...props}>
          <Typography variant="subtitle2" noWrap>
            {props.data.label}
          </Typography>
        </components.Option>
      )
    }
  }

  const MenuList = (props) => {
    const { options, children, getValue } = props
    const [value] = getValue()
    const { mode } = props.selectProps
    height = 37
    if (mode === "CUSTOMER") {
      height = height + 19
      if (accountSiteSettingKeyValue && parseInt(accountSiteSettingKeyValue.show_gstinCutomerList) === 1) {
        height = height + 19
      }
    } else if (mode === "WITHDESCRIPTION" || mode === "MEMBER" || mode === "ALLACCOUNT") {
      height = height + 19
    } else if (mode === "ITEM") {
      if (accountSiteSettingKeyValue && accountSiteSettingKeyValue.item_group === 1) {
        height = height + 19
        if (accountSiteSettingKeyValue.sku_number === 1 || accountSiteSettingKeyValue.tax === 1 || accountSiteSettingKeyValue.item_category === 1) {
          height = height + 19
        }
        if (accountSiteSettingKeyValue.item_category === 1 && accountSiteSettingKeyValue.item_subgroupLevel2 === 1) {
          height = height + 19
        }
      } else if (accountSiteSettingKeyValue && (accountSiteSettingKeyValue.sku_number === 1 || accountSiteSettingKeyValue.tax === 1)) {
        height = height + 19
      }
    }

    const getItemSize = (index) => height
    const initialOffset = options.indexOf(value) * height

    // Scroll to item API of `react-window`
    // See https://react-window.now.sh/#/api/FixedSizeList
    const scrollToIndex = children.length ? children.findIndex((child) => child.props.isFocused) : 0

    // Use `useRef` hook to maintain the access to the menu list
    const listRef = useRef(null)

    // effect that happens after rendering to properly adjust the selection
    useEffect(() => {
      if (listRef.current) {
        if (options.length > 0) {
          listRef.current.scrollToItem(scrollToIndex)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollToIndex])

    if (children.length > 0) {
      return (
        <RWList
          ref={listRef}
          height={children.length * height < listheight ? children.length * height : listheight}
          itemCount={children.length}
          itemSize={getItemSize}
          initialScrollOffset={initialOffset}
        >
          {({ index, style }) => {
            return (
              <div
                style={{
                  ...style,
                  ...{
                    //backgroundColor: theme.palette.background.paper,
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                    borderRadius: 0,
                  },
                }}
              >
                {children[index]}
              </div>
            )
          }}
        </RWList>
      )
    } else {
      if (onAddNewClick) {
        return (
          <ListItem
            button
            disableRipple
            disbletouchripple="true"
            component="div"
            dense
            onClick={(e) => {
              onAddNewClick(props.selectProps.inputValue)
            }}
          >
            <ListItemIcon style={{ marginRight: 0 }}>
              <Add />
            </ListItemIcon>
            <ListItemText style={{ paddingLeft: 5 }} primary="Add New" />
          </ListItem>
        )
      } else {
        return (
          <ListItem component="div" dense style={{ height: 36 }}>
            <components.NoOptionsMessage {...props} />
          </ListItem>
        )
      }
    }
  }

  const noBorder = {}
  if (!(isblank(setUnderLine) || setUnderLine == true)) {
    noBorder.borderWidth = 0
  }

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => {
        const filter_List = Array.isArray(filterList) ? filterList.map(JSON.stringify) : JSON.stringify(filterList)
        let data = new FormData()
        data.append("form_data", modetype)
        data.append("limit", 20)
        data.append("offset", 0)
        data.append("search_text", inputValue)
        data.append("filterList", filter_List)
        axios.defaults.headers.common = getheaders()
        axios({
          method: "post",
          url: list_url({ type: "dependency" }),
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: data,
        })
          .then((response) => {
            if (response.data.meta.code === 401) {
              formprops.snackbarstate({ open: true, message: response.data.meta.message })
            } else {
              var options = response.data[modetype].map(function (value) {
                return { value: value.code, label: value.name, row: value }
              })
              resolve(options)
            }
          })
          .catch((error) => {
            postErrorOnException(error, "", "", getAccountSessionUserData("company_url"), getAccountSessionUserData("name"))
            formprops.snackbarstate({ open: true, message: getCatchErrorMessage(error) })
          })
      }, 1000)
    })

  const handleBlur = (value) => {
    setTimeout(() => {
      onBlur(value)
    }, 1)
  }

  const ClearIndicator = (props) => {
    const {
      innerProps: { ref, ...restInnerProps },
    } = props
    if ((!isblank(isClearable) && isClearable !== false) || isblank(isClearable)) {
      if (!isblank(input.value)) {
        return (
          <IconButton ref={ref} {...restInnerProps} size="small" tabIndex="-1">
            {" "}
            <Close fontSize="small" />
          </IconButton>
        )
      }
      return null
    } else {
      return (
        <IconButton ref={ref} {...restInnerProps} size="small" tabIndex="-1">
          {" "}
          <Close fontSize="small" />
        </IconButton>
      )
    }
  }

  const DropdownIndicator = (prop) => {
    const {
      innerProps: { ref, ...restInnerProps },
    } = prop

    if (isblank(input.value) && !prop.selectProps.isLoading) {
      return (
        <IconButton ref={ref} {...restInnerProps} size="small" tabIndex="-1">
          {" "}
          <ExpandMore fontSize="medium" />
        </IconButton>
      )
    } else {
      if ((!isblank(isClearable) && isClearable !== false) || prop.selectProps.isLoading || isblank(isClearable)) {
        return null
      } else {
        return (
          <IconButton ref={ref} {...restInnerProps} size="small" tabIndex="-1">
            {" "}
            <ExpandMore fontSize="medium" />
          </IconButton>
        )
      }
    }
  }

  const indicatorSeparatorStyle = {
    alignSelf: "stretch",
    backgroundColor: theme.palette.divider,
    marginBottom: 8,
    marginTop: 8,
    width: 1,
  }

  const IndicatorSeparator = (prop) => {
    const { innerProps } = prop
    if (isblank(input.value) && !prop.selectProps.isLoading) {
      return <span style={indicatorSeparatorStyle} {...innerProps} />
    } else {
      if ((!isblank(isClearable) && isClearable !== false) || prop.selectProps.isLoading || isblank(isClearable)) {
        return null
      } else {
        return <span style={indicatorSeparatorStyle} {...innerProps} />
      }
    }
  }

  const LoadingIndicator = (prop) => {
    return <CircularProgress {...prop} color="primary" size={18} style={{ marginRight: 4 }} />
  }

  const formThemeColors = getSelectTheme(theme, touched, error)

  return (
    <React.Fragment>
      {renderLabel({
        labelStyle,
        label,
        isRequired,
        theme,
        touched,
        error,
        helpText,
        helpStyle,
        btnText,
        toolTipStyle,
        onSecondbtnClick,
        secondbtnText,
        onbtnClick,
        input,
      })}
      <div style={{ position: "relative" }}>
        <RenderHelpComponent HelpComponent={HelpComponent} />
        <AsyncSelect
          {...props}
          {...(placeholder && {
            placeholder: capitalizeFirstLetter(placeholder),
          })}
          type="text"
          isMulti={multi}
          cacheOptions
          menuPortalTarget={document.body}
          menuShouldBlockScroll={true}
          menuShouldScrollIntoView={true}
          loadOptions={promiseOptions}
          styles={colourStyles(setUnderLine, props.mode, Boolean(touched && error), theme, HelpComponent)}
          menuPlacement="auto"
          isClearable={isClearable !== undefined ? isClearable : true}
          isSearchable={isSearchable !== undefined ? isSearchable : true}
          value={input.value ? formattedOptions[input.value] : null}
          isDisabled={disabled}
          onChange={multi ? multiChangeHandler(onChange, onvaluechange, dataindex) : singleChangeHandler(onChange, onvaluechange, dataindex)}
          {...(isMobileOnly && {
            blurInputOnSelect: false,
          })}
          onBlur={() => (multi ? handleBlur([...input.value]) : handleBlur(input.value))}
          components={{
            Option,
            MenuList,
            MultiValue,
            ClearIndicator,
            DropdownIndicator,
            IndicatorSeparator,
            LoadingIndicator,
            // Control: (ControlProps) => Control({ ...ControlProps, HelpComponent }),
          }}
          onFocuses={() => onFocus(input.value)}
          options={options}
          defaultValue={input.value ? input.value : null}
          inputId={name}
          label={label}
          error={Boolean(touched && error)}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...formThemeColors,
            },
          })}
          name={name}
        />
      </div>
    </React.Fragment>
  )
}

export default RFAsyncSelect
