import HelpBtnComponent from "@project/components/common/HelpBtnComponent"
import LabelComponent from "@project/components/common/labelComponent"
import Field from "@project/components/reactHookformComponent"
import { isblank } from "@project/sharedcomponents/utilityFunctions"
import React from "react"

const NotificationButtonsV2 = ({ sendSMSButton, sendWhatsAppButton, sendEmailButton, sendAdminButton, notifyAdmin, control }) => {
  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <LabelComponent label="NOTIFICATIONS" />
        <HelpBtnComponent help="Update Customer settings to enable notifications" style={{ marginLeft: 4 }} />
      </div>
      <div>
        <Field name="sendSMS" inputType={"renderToggle"} control={control} label="Send via SMS" size="small" />
      </div>
      <div>
        <Field name="sendWhatsApp" inputType={"renderToggle"} control={control} label="Send via WhatsApp" size="small" />
      </div>
      <div>
        <Field name="sendEmail" inputType={"renderToggle"} control={control} label="Send via Email" size="small" />
      </div>
      <div>
        <Field name="sendAdmin" inputType={"renderToggle"} control={control} disabled={isblank(notifyAdmin) || (!isblank(notifyAdmin) && notifyAdmin === 0)} label="Notify Admin" size="small" />
      </div>
    </React.Fragment>
  )
}

export default NotificationButtonsV2
