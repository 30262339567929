import { RECENT_ACTIVITIES_DRAWER_LOCAL_SEARCH, RECENT_ACTIVITIES_DRAWER_SERVICE_FAIL, RECENT_ACTIVITIES_DRAWER_SERVICE_START, RECENT_ACTIVITIES_DRAWER_SERVICE_SUCCESS } from "../Actions/types"

const INITIAL_STATE = {
  error: "",
  data: [],
  totalcount: 0,
  rowsPerPage: 0,
  page: 0,
  order: "asc",
  orderBy: "",
  search_text: "",
  columnData: [
    { id: "username", numeric: false, visible: true, label: "User" },
    { id: "action", numeric: false, visible: true, label: "Action" },
    { id: "type", numeric: false, visible: true, label: "Type" },
    { id: "entry_date", numeric: false, visible: true, label: "Date/Time", date: true },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECENT_ACTIVITIES_DRAWER_SERVICE_START:
      return { ...state, error: "" }
    case RECENT_ACTIVITIES_DRAWER_LOCAL_SEARCH:
      return { ...state, search_text: action.search_text }
    case RECENT_ACTIVITIES_DRAWER_SERVICE_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        data: action.payload.data.result,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
        islogin: true,
      }
    case RECENT_ACTIVITIES_DRAWER_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        data: [],
        totalcount: 0,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        search_text: action.search_text,
      }

    default:
      return state
  }
}
