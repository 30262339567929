import { generateDetailHeaders, generateDetailValues } from "./common"
import { DatetoDMY, isblank, replaceBRtoNewLine, replacefunction } from "./utilityFunctions"

export default ({ printdata, meta, pageSize, user_data, functions }) => {
  var invoice_template = {}
  var title = ""
  var billno = printdata.master_table_data[0].code
  var outwardDate = DatetoDMY(printdata.master_table_data[0].b_date)
  var PartyName = printdata.user_details[0].name
  var processName = printdata.master_table_data[0].processName
  var MasterCustomField = [] //printdata.custom_fields_data_master;
  var DetailCustomField = [] //printdata.custom_fields_data;

  var fromGodown = printdata.godown_detail.from[0].name
  var toGodown = printdata.godown_detail.to[0].name

  var city = ""
  var cityData = ""
  cityData = printdata.city_data.find((item) => parseInt(item.city_id) === parseInt(printdata.user_details[0].city_id))

  if (!isblank(cityData)) {
    city = cityData.city
  }

  var state = ""
  var stateData = ""
  stateData = printdata.state_data.find((item) => parseInt(item.sid) === parseInt(printdata.user_details[0].state_id))
  if (!isblank(stateData)) {
    state = stateData.state
  }

  var Address = replacefunction(replaceBRtoNewLine(printdata.user_details[0].address))
  if (!isblank(printdata.user_details[0].areacode)) {
    Address = Address + ", " + printdata.user_details[0].areacode
  }
  if (!isblank(city)) {
    if (isblank(Address)) {
      Address = Address + city
    } else {
      Address = Address + ", " + city
    }
  }
  if (!isblank(state)) {
    if (isblank(Address)) {
      Address = Address + state
    } else {
      Address = Address + ", " + state
    }
  }
  if (!isblank(printdata.user_details[0].pincode) && !isblank(Address)) {
    Address = Address + " - " + printdata.user_details[0].pincode
  }

  var Contact = !isblank(printdata.user_details[0].mobile) ? printdata.user_details[0].mobile : printdata.user_details[0].phone

  if (!isblank(Contact)) {
    Contact = `Contact: ${Contact}`
  } else {
    Contact = ""
  }

  title = "Godown Stock Transfer"
  var parameterized = printdata.master_table_data[0].prizedQty_flag
  if (isblank(parameterized)) {
    parameterized = false
  }

  var HeaderObject = generateDetailHeaders({ functions, DetailCustomField, type: "stock_transferGodown", pageSize })
  var Headers = HeaderObject.Headers
  var widthArray = HeaderObject.widthArray
  var Values = generateDetailValues({ functions, DetailCustomField, DetailGrid: printdata.details_table_data, MasterData: printdata.master_table_data[0], parameterized, type: "stock_transferGodown", user_data })

  invoice_template.topheaderheight = 20
  invoice_template.bottomheaderheight = 20
  invoice_template.headerheight = 75 //invoice_template.topheaderheight + invoice_template.bottomheaderheight + 48;
  invoice_template.topheader = {}
  invoice_template.header = {}
  invoice_template.body = {}
  invoice_template.footer = {}

  var dd = {
    pageSize: pageSize,
    pageOrientation: "portrait",
    pageMargins: [10, invoice_template.headerheight, 10, 20],
    header: [
      {
        columns: [
          { text: "", style: "documentHeaderLeft" },
          { text: title, style: "documentHeaderCenter", bold: true },
          { text: "", style: "documentHeaderRight" },
        ],
      },
      {
        columns: [
          { text: "", style: "" },
          { text: "", style: "" },
          { text: `${title}: #${billno}`, style: "outwardBillDate", bold: true },
        ],
      },
      {
        columns: [
          { text: "", style: "" },
          { text: "", style: "" },
          { text: `Date: ${outwardDate}`, style: "outwardBillDate", bold: true },
        ],
      },
    ],
    footer: [],
    content: [
      {
        stack: [
          {
            text: PartyName,
            style: "",
            bold: true,
          },
          {
            text: Address,
            style: "",
            bold: true,
          },
          {
            text: Contact,
            style: "",
            bold: true,
          },
        ],
      },
      {
        columns: [
          { text: `From Godown: ${fromGodown}`, bold: true },
          { text: `To Godown: ${toGodown}`, alignment: "right", bold: true },
        ],
        margin: [0, 5, 0, 5],
      },
      {
        table: {
          headerRows: 1,
          widths: widthArray,
          body: [Headers, ...Values],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === 1 || i === node.table.body.length || i === node.table.body.length - 1 ? 1 : 0
          },
          vLineWidth: function (i, node) {
            return 1
          },
        },
      },
    ],
    defaultStyle: {
      fontSize: 8,
    },
    styles: {
      documentHeaderLeft: {
        margin: [10, 10, 10, 10],
        alignment: "left",
      },
      documentHeaderCenter: {
        fontSize: 14,
        margin: [10, 10, 10, 10],
        alignment: "center",
      },
      outwardBillDate: {
        margin: [0, 0, 10, 5],
        alignment: "right",
      },
      documentHeaderRight: {
        margin: [10, 10, 10, 10],
        alignment: "right",
      },
    },
  }

  return dd
}
