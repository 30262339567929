import cF from "@project/sharedcomponents/constantData/customFieldsEnum"
import {
  findNode,
  generateDetailHeaders,
  generateDetailValues,
  generatePhotoFieldBody,
  generateSignatureBoxes,
  generatecustomFieldBodyV3,
  generatecustomFieldBodyV5,
  getPrintValue,
  jnestedReplace,
  masterCustomFieldV2,
  removeTempField,
} from "./common"
import processTemplateData from "./processDataTemplates/processTemplate1"
import processTemplate2Data from "./processDataTemplates/processTemplate2"
import { DatetoDMY, checkRenderConditionV1, cloneObj, isblank, numForCalc, replaceAllFn, replaceBRtoNewLine, replaceDropBoxUrl, replacefunction } from "./utilityFunctions"

export default ({ printdata, meta, pageSize, user_data, functions }) => {
  const { getAccountSessionUserData, getAccountSitesetting, getconfigsettingdata } = functions

  const dS = {
    Business_Type: getconfigsettingdata("company_status")["business_type"],
  }
  let lot_party_in_process = parseInt(getAccountSitesetting("lot_party_in_process"))
  let inventory_decimalpoint = getAccountSitesetting("inventory_decimalpoint")

  var MasterCustomField = printdata.custom_fields_data_master ? cloneObj(printdata.custom_fields_data_master) : []
  var invoice_type = meta.invoice_type
  var DetailCustomField = printdata.custom_fields_data ? cloneObj(printdata.custom_fields_data) : []

  /* CUSTOM FIELDS FOR Demo wood */

  if (dS.Business_Type === "Wood") {
    if (!isblank(printdata.master_table_data[0].__cust_product_type_code) && printdata.master_table_data[0].__cust_product_type_code === "16") {
      functions.PrintHWT = true
    }
  }

  var rowValue = {}
  if (dS.Business_Type === "Wood" && !isblank(printdata) && !isblank(printdata.details_table_data) && printdata.details_table_data.length > 0) {
    rowValue = printdata.details_table_data[0]
  }

  /* SET MASTER CUSTOM FIELD RENDER CONDITION */
  MasterCustomField = MasterCustomField.filter(function (item) {
    // return checkRenderConditionPrint(item, printdata.master_table_data[0])
    return checkRenderConditionV1({ f: item, data: printdata.master_table_data[0], other_type: invoice_type, rowValue, dS, print_flag: true })
  })

  /* SET DETAIL CUSTOM FIELD RENDER CONDITION */
  DetailCustomField = DetailCustomField.filter(function (item) {
    // return checkRenderConditionPrint(item, printdata.master_table_data[0])
    return checkRenderConditionV1({ f: item, data: printdata.master_table_data[0], other_type: invoice_type, rowValue, dS, print_flag: true })
  })

  var parameterized = printdata.master_table_data[0].prizedQty_flag
  if (isblank(parameterized)) {
    parameterized = false
  }

  let DetailGrid = printdata.details_table_data

  if (dS.Business_Type === "Wood") {
    functions.PrintLOT = false
  }

  if (lot_party_in_process === 1) {
    MasterCustomField.push({ showInPrint: "1", labelName: "Lot", name: "lot_billno" })
    MasterCustomField.push({ showInPrint: "1", labelName: "Party", name: "lot_party" })
  }

  if (dS.Business_Type === "Wood" && (printdata.master_table_data[0].processName === "Packing" || printdata.master_table_data[0].processName === "Dispatched")) {
    DetailGrid = DetailGrid.sort((a, b) => {
      if (a.__cust_flat_no === b.__cust_flat_no) {
        return a.__cust_area < b.__cust_area ? -1 : 1
      } else {
        return a.__cust_flat_no < b.__cust_flat_no ? -1 : 1
      }
    })
  }

  let metaData = {
    pageSize,
    MasterCustomField,
    printdata,
    getAccountSessionUserData,
    getconfigsettingdata,
  }

  if (dS.Business_Type === "Wood" && printdata.master_table_data[0].processName === "Door Laminate Pressing & Cutting") {
    MasterCustomField.push({ showInPrint: "1", labelName: "Total", name: "totqty", decimalPoint: 2, isNumeric: "1" })

    printdata.master_table_data[0].totqty = parseFloat(printdata.master_table_data[0].totqty).toFixed(2)

    var objHeight = {}
    var objWidth = {}
    var objHIndex = 0
    var objWIndex = 0
    var lippingNO = 0
    var lippingNOH = 0
    var lippingNOW = 0
    if (printdata.master_table_data[0].__cust_lipping === "Yes" || printdata.master_table_data[0].__cust_wpc_patti === "Yes") {
      if (printdata.master_table_data[0].__cust_lipping === "Yes") {
        lippingNO = parseFloat(printdata.master_table_data[0].__cust_lipping_size_mm)
      }
      if (printdata.master_table_data[0].__cust_wpc_patti === "Yes" && printdata.master_table_data[0].__cust_wpc_patti_type === "BOTH") {
        //BOTH
        lippingNO = lippingNO + parseFloat(printdata.master_table_data[0]["__cust_wpc_patti_size(mm)"])
      }

      if (printdata.master_table_data[0].__cust_wpc_patti === "Yes" && printdata.master_table_data[0].__cust_wpc_patti_type === "WIDTH") {
        //WIDTH
        lippingNOW = parseFloat(printdata.master_table_data[0]["__cust_wpc_patti_size(mm)"])
      }

      if (printdata.master_table_data[0].__cust_wpc_patti === "Yes" && printdata.master_table_data[0].__cust_wpc_patti_type === "HEIGHT") {
        //HEIGHT
        lippingNOH = parseFloat(printdata.master_table_data[0]["__cust_wpc_patti_size(mm)"])
      }

      DetailCustomField = DetailCustomField.map((e, index) => {
        let obj = { ...e }
        if (obj.name === "__cust_height") {
          let _obj = cloneObj(obj)
          _obj.name = "__cust_LippingHeight"
          _obj.labelName = "Before Lipping Height"
          objHeight = _obj
          objHIndex = index
        }
        if (obj.name === "__cust_width") {
          let _obj = cloneObj(obj)
          _obj.name = "__cust_LippingWidth"
          _obj.labelName = "Before Lipping Width"
          objWidth = _obj
          objWIndex = index
        }
        return obj
      })
    }

    DetailCustomField.splice(objHIndex + 1, 0, objHeight)
    DetailCustomField.splice(objWIndex + 1, 0, objWidth)

    //ADD DetailCustomField __cust_LippingHeight, __cust_LippingWidth

    var HeaderObject = generateDetailHeaders({
      functions,
      DetailCustomField: DetailCustomField.filter((e) => e.name !== "__cust_height" && e.name !== "__cust_width"),
      type: "lot_transfer",
      pageSize,
      printdata,
    })
    var Headers = HeaderObject.Headers
    var widthArray = HeaderObject.widthArray

    DetailGrid = []

    printdata.details_table_data.forEach((e) => {
      let uniqueKey = `${e.itemname}`
      let heading = ""
      DetailCustomField.filter((e) => e.name === "__cust_height" || e.name === "__cust_width")
        .filter((e) => e.showInPrint !== "0")
        .forEach((i) => {
          if (!isblank(e[i.name])) {
            uniqueKey = `${uniqueKey}_${e[i.name]}`
            if (isblank(heading)) {
              heading = `${i.labelName}: ${e[i.name]}`
            } else {
              heading = `${heading}, ${i.labelName}: ${e[i.name]}`
            }
          }
        })
      if (DetailGrid.filter((i) => i.uniqueKey === uniqueKey).length > 0) {
        DetailGrid = DetailGrid.map((i) => {
          const updated = { ...i }
          if (updated.uniqueKey === uniqueKey) {
            updated.qty = numForCalc(updated.qty, inventory_decimalpoint) + numForCalc(e.qty, inventory_decimalpoint)

            if (printdata.master_table_data[0].__cust_lipping === "Yes" || printdata.master_table_data[0].__cust_wpc_patti === "Yes") {
              e["__cust_LippingWidth"] = parseFloat(e.__cust_actual_width) - (lippingNO + lippingNOW) * 2
              e["__cust_LippingHeight"] = parseFloat(e.__cust_actual_height) - (lippingNO + lippingNOH) * 2
            }

            updated.detail.push(e)
          }
          return updated
        })
      } else {
        if (printdata.master_table_data[0].__cust_lipping === "Yes" || printdata.master_table_data[0].__cust_wpc_patti === "Yes") {
          e["__cust_LippingWidth"] = parseFloat(e.__cust_actual_width) - (lippingNO + lippingNOW) * 2
          e["__cust_LippingHeight"] = parseFloat(e.__cust_actual_height) - (lippingNO + lippingNOH) * 2
        }

        DetailGrid.push({ detail: [e], uniqueKey, qty: e.qty, heading: heading })
      }
    })
    let detailTable = []
    DetailGrid.forEach((e, iindex) => {
      detailTable.push({ text: e.heading, margin: [0, 10, 0, 4], fontSize: 9, bold: true, alignment: "center" })

      let MasterTableData = cloneObj(printdata.master_table_data[0])
      if (dS.Business_Type === "Wood" && printdata.master_table_data[0].processName === "Door Laminate Pressing & Cutting") {
        MasterTableData["totqty"] = e.qty
      }

      let Values = generateDetailValues({
        functions,
        DetailCustomField: DetailCustomField.filter((e) => e.name !== "__cust_height" && e.name !== "__cust_width"),
        DetailGrid: e.detail,
        MasterData: MasterTableData,
        parameterized,
        type: "lot_transfer",
        user_data,
      })
      if (iindex === 0) {
        detailTable.push({
          table: {
            headerRows: 1,
            widths: widthArray,
            body: [Headers, ...Values],
          },
          layout: {
            hLineWidth: function (i, node) {
              // return i === 0 || i === 1 || i === node.table.body.length || i === node.table.body.length - 1 ? 1 : 0
              return 1
            },
            vLineWidth: function (i, node) {
              return 1
            },
          },
        })
      } else {
        detailTable.push({
          table: {
            widths: widthArray,
            body: [...Values],
          },
          layout: {
            hLineWidth: function (i, node) {
              // return i === 0 || i === node.table.body.length || i === node.table.body.length - 1 ? 1 : 0
              return 1
            },
            vLineWidth: function (i, node) {
              return 1
            },
          },
        })
      }
    })
    return processPrintFn({ meta: metaData, detailTable })
  } else if (dS.Business_Type === "Wood" && (printdata.master_table_data[0].processName === "Board Section" || printdata.master_table_data[0].processName === "Press Section")) {
    DetailGrid = []

    printdata.details_table_data.forEach((e) => {
      let uniqueKey = `${e.itemname}`
      DetailCustomField.filter((e) => e.name === "__cust_width" || e.name === "__cust_height").forEach((i) => {
        if (!isblank(e[i.name])) {
          uniqueKey = `${uniqueKey}_${e[i.name]}`
        }
      })
      if (DetailGrid.filter((i) => i.uniqueKey === uniqueKey).length > 0) {
        DetailGrid = DetailGrid.map((i) => {
          const updated = { ...i }
          if (updated.uniqueKey === uniqueKey) {
            updated.qty = numForCalc(updated.qty, inventory_decimalpoint) + numForCalc(e.qty, inventory_decimalpoint)
          }
          return updated
        })
      } else {
        DetailGrid.push({ ...e, uniqueKey })
      }
    })

    var HeaderObject = generateDetailHeaders({ functions, DetailCustomField, type: "lot_transfer", pageSize })
    var Headers = HeaderObject.Headers
    var widthArray = HeaderObject.widthArray

    var Values = generateDetailValues({ functions, DetailCustomField, DetailGrid, MasterData: printdata.master_table_data[0], parameterized, type: "lot_transfer", user_data })
    let detailTable = [
      {
        table: {
          headerRows: 1,
          widths: widthArray,
          body: [Headers, ...Values],
        },
        layout: {
          hLineWidth: function (i, node) {
            // return i === 0 || i === 1 || i === node.table.body.length || i === node.table.body.length - 1 ? 1 : 0
            return 1
          },
          vLineWidth: function (i, node) {
            return 1
          },
        },
      },
    ]
    return processPrintFn({ meta: metaData, detailTable })
  } else if (
    dS.Business_Type === "Wood" &&
    (printdata.master_table_data[0].processName === "Cutting / Fixing & Sanding" ||
      printdata.master_table_data[0].processName === "Postform & Laminate Press" ||
      printdata.master_table_data[0].processName === "Finish Stock" ||
      printdata.master_table_data[0].processName === "Packing")
  ) {
    DetailGrid = []

    var rowValue = {}
    if (dS.Business_Type === "Wood" && !isblank(printdata) && !isblank(printdata.details_table_data) && printdata.details_table_data.length > 0) {
      rowValue = printdata.details_table_data[0]
    }
    DetailCustomField = DetailCustomField.filter((e) => {
      return checkRenderConditionV1({ f: e, data: printdata.master_table_data[0], other_type: "lot_transfer", rowValue, dS, print_flag: true })
    })
    if (!isblank(rowValue) && rowValue.__cust_ply_height && parseInt(rowValue.__cust_ply_height) > 0) {
      DetailCustomField = DetailCustomField.filter((e) => !(e.name === "__cust_width" || e.name === "__cust_height" || e.name === "__cust_actual_height" || e.name === "__cust_actual_width"))
    } else {
      DetailCustomField = DetailCustomField.filter((e) => !(e.name === "__cust_ply_height" || e.name === "__cust_ply_thk" || e.name === "__cust_ply_width"))
    }
    printdata.details_table_data.forEach((e, index) => {
      /*let uniqueKey = `${e.itemname}`
      DetailCustomField.filter((e) => e.name === "__cust_width" || e.name === "__cust_height").forEach((i) => {
        if (!isblank(e[i.name])) {
          uniqueKey = `${uniqueKey}_${e[i.name]}`
        }
      })
       if (DetailGrid.filter((i) => i.uniqueKey === uniqueKey).length > 0) {
        DetailGrid = DetailGrid.map((i) => {
          const updated = { ...i }
          if (updated.uniqueKey === uniqueKey) {
            updated.qty = numForCalc(updated.qty, inventory_decimalpoint) + numForCalc(e.qty, inventory_decimalpoint)
          }
          return updated
        })
      } else {
        DetailGrid.push({ ...e, uniqueKey })
      } */

      let uniqueKey = `${index}`
      DetailGrid.push({ ...e, uniqueKey })
    })

    var HeaderObject = generateDetailHeaders({ functions, DetailCustomField, type: "lot_transfer", pageSize })
    var Headers = HeaderObject.Headers
    var widthArray = HeaderObject.widthArray

    var Values = generateDetailValues({ functions, DetailCustomField, DetailGrid, MasterData: printdata.master_table_data[0], parameterized, type: "lot_transfer", user_data })
    let detailTable = [
      {
        table: {
          headerRows: 1,
          widths: widthArray,
          body: [Headers, ...Values],
        },
        layout: {
          hLineWidth: function (i, node) {
            // return i === 0 || i === 1 || i === node.table.body.length || i === node.table.body.length - 1 ? 1 : 0
            return 1
          },
          vLineWidth: function (i, node) {
            return 1
          },
        },
      },
    ]
    return processPrintFn({ meta: metaData, detailTable })
  } else {
    var HeaderObject = generateDetailHeaders({ functions, DetailCustomField, type: "lot_transfer", pageSize })
    var Headers = HeaderObject.Headers
    var widthArray = HeaderObject.widthArray

    var Values = generateDetailValues({ functions, DetailCustomField, DetailGrid, MasterData: printdata.master_table_data[0], parameterized, type: "lot_transfer", user_data })
    let detailTable = [
      {
        table: {
          headerRows: 1,
          widths: widthArray,
          body: [Headers, ...Values],
        },
        layout: {
          hLineWidth: function (i, node) {
            // return i === 0 || i === 1 || i === node.table.body.length || i === node.table.body.length - 1 ? 1 : 0
            return 1
          },
          vLineWidth: function (i, node) {
            return 1
          },
        },
      },
    ]
    return processPrintFn({ meta: metaData, detailTable })
  }
}

export const processPrintFn = ({ meta, detailTable }) => {
  const { pageSize, MasterCustomField, printdata, getAccountSessionUserData, getconfigsettingdata } = meta

  const dS = {
    Business_Type: getconfigsettingdata("company_status")["business_type"],
  }
  let photo_base_url = getAccountSessionUserData("photo_base_url")

  var title = ""
  var invoice_title = ""
  var mainHeadingText = ""
  var billno = printdata.master_table_data[0].billno
  var outwardDate = DatetoDMY(printdata.master_table_data[0].date)
  var narration = !isblank(printdata.master_table_data[0].narration) ? replacefunction(replaceBRtoNewLine(printdata.master_table_data[0].narration)) : ""
  var processName = printdata.master_table_data[0].processName
  var Print_company_logo = 0
  var Print_company_name = 0
  var Print_company_address = 0
  var Print_narration = 0
  var Print_contactDetail = 0
  var PageOrientation = "portrait"
  var pdfFontSizeSelectedValue = 8
  var Print_Page_Size = "A4"
  var Print_Template = 1
  var signBoxes = ""

  /* All Company Details */
  const company_data = getPrintValue(printdata, "company_data")
  var companyName = company_data.company_name
  var company_address1 = company_data.company_address1
  var gstin = company_data.company_gstin
  var company_email = company_data.company_email
  var pan = company_data.company_pan
  var aadhaar = company_data.company_aadhaar_no
  var company_address2 = getPrintValue(printdata, "company_address2")
  var companyContact = getPrintValue(printdata, "company_contact")
  var companyOtherNumbers = getPrintValue(printdata, "company_other_no")
  var companyLogo = getPrintValue(printdata, "company_logo")

  /* All Party Details */
  const party_data = getPrintValue(printdata, "party_data")
  var party_name = replacefunction(party_data.party_name)
  var party_address1 = party_data.party_address1
  var party_gstin = party_data.party_gstin
  var party_email = party_data.party_email
  var party_pan = party_data.party_pan
  var party_aadhaar = party_data.party_aadhaar_no
  var party_address2 = getPrintValue(printdata, "new_party_address")
  var partyContact = getPrintValue(printdata, "party_contact")

  /* Read values from settings */
  Print_company_logo = !isblank(printdata.processSetting[0].__cust_Print_company_logo) ? (printdata.processSetting[0].__cust_Print_company_logo === true ? 1 : 0) : Print_company_logo
  Print_company_name = !isblank(printdata.processSetting[0].__cust_Print_company_name) ? (printdata.processSetting[0].__cust_Print_company_name === true ? 1 : 0) : Print_company_name
  Print_company_address = !isblank(printdata.processSetting[0].__cust_Print_company_address) ? (printdata.processSetting[0].__cust_Print_company_address === true ? 1 : 0) : Print_company_address
  pdfFontSizeSelectedValue = !isblank(printdata.processSetting[0].__cust_pdfFontSizeSelectedValue) ? printdata.processSetting[0].__cust_pdfFontSizeSelectedValue : pdfFontSizeSelectedValue
  title = !isblank(printdata.processSetting[0].name) ? printdata.processSetting[0].name : "Process Transfer"
  mainHeadingText = !isblank(printdata.processSetting[0].__cust_Main_Heading) ? printdata.processSetting[0].__cust_Main_Heading : ""
  invoice_title = !isblank(printdata.processSetting[0].__cust_Heading_Text) ? printdata.processSetting[0].__cust_Heading_Text : ""
  Print_narration = !isblank(printdata.processSetting[0].__cust_Print_narration) ? (printdata.processSetting[0].__cust_Print_narration === true ? 1 : 0) : 0
  PageOrientation = !isblank(printdata.processSetting[0].__cust_Page_Orientation) ? printdata.processSetting[0].__cust_Page_Orientation : "portrait"
  Print_contactDetail = !isblank(printdata.processSetting[0].__cust_Print_party_contact) ? (printdata.processSetting[0].__cust_Print_party_contact === true ? 1 : 0) : 0
  Print_Page_Size = !isblank(printdata.processSetting[0].__cust_Page_Size) ? printdata.processSetting[0].__cust_Page_Size : "A4"
  Print_Template = !isblank(printdata.processSetting[0].__cust_Print_Template) ? parseInt(printdata.processSetting[0].__cust_Print_Template) : 1
  signBoxes = !isblank(printdata.processSetting[0].__cust_hidden_boxes) ? printdata.processSetting[0].__cust_hidden_boxes : ""

  if (isblank(mainHeadingText)) {
    mainHeadingText = title
  }
  if (isblank(invoice_title)) {
    invoice_title = ""
  }

  if (Print_Template === 1) {
    printdata.template_setting.setting = processTemplateData.setting
    printdata.template_setting.header = processTemplateData.header
    printdata.template_setting.footer = processTemplateData.footer
    printdata.template_setting.body = processTemplateData.body
    printdata.template_setting.bodyHeader = processTemplateData.bodyHeader
    printdata.template_setting.bodyFooter = processTemplateData.bodyFooter
    printdata.template_setting.style = processTemplateData.style
  } else {
    printdata.template_setting.setting = processTemplate2Data.setting
    printdata.template_setting.header = processTemplate2Data.header
    printdata.template_setting.footer = processTemplate2Data.footer
    printdata.template_setting.body = processTemplate2Data.body
    printdata.template_setting.bodyHeader = processTemplate2Data.bodyHeader
    printdata.template_setting.bodyFooter = processTemplate2Data.bodyFooter
    printdata.template_setting.style = processTemplate2Data.style
  }

  /* template setting according to page size and orientation */

  const pageMargins = Print_Page_Size === "A4" ? printdata.template_setting.setting["pageMarginsA4"] : printdata.template_setting.setting["pageMarginsA5"]
  const logo_height = Print_Page_Size === "A4" ? printdata.template_setting.setting["logo_height_a4"] : printdata.template_setting.setting["logo_height_a5"]
  const logo_width = Print_Page_Size === "A4" ? printdata.template_setting.setting["logo_width_a4"] : printdata.template_setting.setting["logo_width_a5"]
  const header_right_section_width =
    Print_Page_Size === "A4" && PageOrientation === "portrait"
      ? printdata.template_setting.setting["header_right_section_width_a4_P"]
      : Print_Page_Size === "A5" && PageOrientation === "portrait"
        ? printdata.template_setting.setting["header_right_section_width_a5_P"]
        : Print_Page_Size === "A4" && PageOrientation === "landscape"
          ? printdata.template_setting.setting["header_right_section_width_a4_L"]
          : printdata.template_setting.setting["header_right_section_width_a5_L"]
  const footer_column_gap =
    Print_Page_Size === "A4" && PageOrientation === "portrait"
      ? printdata.template_setting.setting["footer_column_gap_a4_P"]
      : Print_Page_Size === "A5" && PageOrientation === "portrait"
        ? printdata.template_setting.setting["footer_column_gap_a5_P"]
        : Print_Page_Size === "A4" && PageOrientation === "landscape"
          ? printdata.template_setting.setting["footer_column_gap_a4_L"]
          : printdata.template_setting.setting["footer_column_gap_a5_L"]

  let headerString = printdata.template_setting.bodyHeader
  headerString = replaceAllFn(headerString, "{logo}", companyLogo)
  headerString = replaceAllFn(headerString, "{companyName}", companyName)
  headerString = replaceAllFn(headerString, "{company_address1}", replacefunction(company_address1))
  headerString = replaceAllFn(headerString, "{company_address2}", replacefunction(company_address2))
  headerString = replaceAllFn(headerString, "{company_contact}", companyContact)
  headerString = replaceAllFn(headerString, "{company_gstin}", gstin)
  headerString = replaceAllFn(headerString, "{company_email}", company_email)
  headerString = replaceAllFn(headerString, "{companyOtherNumbers}", companyOtherNumbers)
  headerString = replaceAllFn(headerString, "{companyPAN}", pan)
  headerString = replaceAllFn(headerString, "{company_aadhaar}", aadhaar)

  headerString = replaceAllFn(headerString, "{partyName}", party_name)
  headerString = replaceAllFn(headerString, "{party_address1}", replacefunction(party_address1))
  headerString = replaceAllFn(headerString, "{party_address2}", replacefunction(party_address2))
  headerString = replaceAllFn(headerString, "{party_gstin}", party_gstin)
  headerString = replaceAllFn(headerString, "{party_pan}", party_pan)
  headerString = replaceAllFn(headerString, "{party_aadhaar}", party_aadhaar)
  headerString = replaceAllFn(headerString, "{party_contact}", partyContact)
  headerString = replaceAllFn(headerString, "{party_email}", party_email)

  headerString = replaceAllFn(headerString, "{title}", `${title} No`)
  headerString = replaceAllFn(headerString, "{billno}", billno)
  headerString = replaceAllFn(headerString, "{outwardDate}", outwardDate)
  headerString = replaceAllFn(headerString, "{processName}", processName)
  headerString = replaceAllFn(headerString, "{narration}", narration)

  /* LOGO HEIGHT WIDTH */
  headerString = replaceAllFn(headerString, '"{logo_width}"', logo_width)
  headerString = replaceAllFn(headerString, '"{logo_height}"', logo_height)
  headerString = replaceAllFn(headerString, '"{header_right_section_width}"', header_right_section_width)

  let fieldsArray = [
    {
      field_id: "company_aadhaar",
      value: aadhaar,
    },
    {
      field_id: "company_gstin",
      value: gstin,
    },
    {
      field_id: "company_pan",
      value: pan,
    },
    {
      field_id: "company_contact",
      value: companyContact,
    },
    {
      field_id: "company_email",
      value: company_email,
    },
    {
      field_id: "company_OtherNumbers",
      value: companyOtherNumbers,
    },
    {
      field_id: "party_aadhaar",
      value: party_aadhaar,
    },
    {
      field_id: "party_gstin",
      value: party_gstin,
    },
    {
      field_id: "party_pan",
      value: party_pan,
    },
    {
      field_id: "party_contact",
      value: partyContact,
    },
  ]

  if (meta.invoice_type !== "job_inward") {
    fieldsArray.push({
      field_id: "process_info",
      value: "",
    })
  }

  if (Print_company_logo !== 1 || isblank(companyLogo)) {
    fieldsArray.push({
      field_id: "company_logo",
      value: "",
      width: 0,
    })
  }

  if (Print_company_name !== 1) {
    fieldsArray.push({
      field_id: "company_name",
      value: "",
    })
  }

  if (Print_company_address !== 1) {
    fieldsArray.push({
      field_id: "company_address",
      value: "",
    })
  }

  if (Print_contactDetail !== 1) {
    fieldsArray.push({
      field_id: "party_contact",
      value: "",
    })
  }

  if (Print_narration !== 1 || isblank(narration)) {
    headerString = JSON.parse(headerString)
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "narration", newValue: {} })
    headerString = JSON.stringify(headerString)
  }

  headerString = removeTempField(headerString, fieldsArray)

  let images = {}

  let manually_print_photo = []

  MasterCustomField.filter((e) => parseInt(e.dataType) === cF.Photo && e.showInPrint === "1").forEach((e, index) => {
    if (!isblank(printdata.master_table_data[0][e.name])) {
      if (e.printWithItem === "4") {
        //MANUALLY
        manually_print_photo.push({
          name: printdata.master_table_data[0][e.name],
          print_width: e.width,
          label: e.labelName,
        })
      }

      images[`${printdata.master_table_data[0][e.name]}`] = `${photo_base_url}${printdata.master_table_data[0][e.name]}`
    }
  })

  MasterCustomField.filter((e) => parseInt(e.dataType) === cF.Dropbox && e.showInPrint === "1").forEach((e, index) => {
    if (!isblank(printdata.master_table_data[0][e.name])) {
      printdata.master_table_data[0][e.name].forEach((i, iindex) => {
        if (e.printWithItem === "4") {
          //MANUALLY
          manually_print_photo.push({
            name: i.name,
            print_width: e.width,
            label: e.labelName,
          })
        }

        images[i.name] = replaceDropBoxUrl(i.link)
      })
    }
  })

  if (dS.Business_Type === "Wood") {
    const img = printdata.custom_fields_data_item.find((e) => e.name === "__cust_product_image" && parseInt(e.dataType) === cF.Dropbox && e.showInPrint === "1")

    if (!isblank(img)) {
      printdata.details_table_data.forEach((data, iindex) => {
        if (!isblank(data.__cust_product_image)) {
          const imageDetail = data.__cust_product_image[0]

          const photoFound = manually_print_photo.find((f) => f.name === data.itemcode)

          if (isblank(photoFound)) {
            const imgWidth = parseInt(img.width)

            //MANUALLY
            manually_print_photo.push({
              name: data.itemcode,
              print_width: imgWidth ? imgWidth : 200,
              label: data.itemname,
            })
            images[data.itemcode] = replaceDropBoxUrl(imageDetail.link)
          }
        }
      })
    }
  }

  headerString = JSON.parse(headerString)
  const customFieldStructure = findNode(headerString, "field_id", "customFieldNew")
  let customFieldBody = {}
  var CustomFieldArr = masterCustomFieldV2(MasterCustomField, printdata.master_table_data[0]) //get label and value

  if (Print_Template === 1) {
    customFieldBody = generatecustomFieldBodyV3(customFieldStructure, CustomFieldArr)
  } else {
    customFieldBody = generatecustomFieldBodyV5(customFieldStructure, CustomFieldArr)
  }

  if (customFieldBody.length > 0) {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "customFieldNew", newValue: customFieldBody, replacePath: customFieldStructure.replacePath })
  } else {
    headerString = jnestedReplace({ input: headerString, search_key: "field_id", searchValue: "customFieldNew", newValue: {} })
  }
  headerString = JSON.stringify(headerString)

  let head_String = printdata.template_setting.header
  head_String = replaceAllFn(head_String, "{center_text}", mainHeadingText)
  head_String = replaceAllFn(head_String, "{left_text}", invoice_title)
  head_String = replaceAllFn(head_String, "{right_text}", "")

  let footer_String = printdata.template_setting.bodyFooter
  footer_String = JSON.parse(footer_String)
  /* Print Manually Image in Bottom Print */
  if (manually_print_photo.length > 0) {
    let photoFieldBody = {}
    const photoFieldStructure = findNode(footer_String, "field_id", "imgFooter")
    photoFieldBody = generatePhotoFieldBody(photoFieldStructure, manually_print_photo)
    footer_String = jnestedReplace({ input: footer_String, search_key: "field_id", searchValue: "imgFooter", newValue: photoFieldBody, replacePath: photoFieldStructure.replacePath })
  } else {
    footer_String = jnestedReplace({ input: footer_String, search_key: "field_id", searchValue: "imgFooter", newValue: {} })
  }

  let customSignBoxes = ""
  if (!isblank(signBoxes) && !isblank(signBoxes.boxes) && signBoxes.boxes.length > 0) {
    customSignBoxes = generateSignatureBoxes(signBoxes)

    const bodyFooterFieldStructure = findNode(footer_String, "field_id", "signBox")

    footer_String = jnestedReplace({ input: footer_String, search_key: "field_id", searchValue: "signBox", newValue: customSignBoxes, replacePath: bodyFooterFieldStructure.replacePath })
  } else {
    footer_String = jnestedReplace({ input: footer_String, search_key: "field_id", searchValue: "signBox", newValue: {} })
  }

  footer_String = JSON.stringify(footer_String)
  footer_String = replaceAllFn(footer_String, '"{columnGap}"', footer_column_gap)

  var dd = {
    // a string or { width: number, height: number }
    pageSize: Print_Page_Size,
    // by default we use portrait, you can change it to landscape if you wish
    pageOrientation: PageOrientation,
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: pageMargins,
    header: JSON.parse(head_String),
    content: [JSON.parse(headerString), detailTable, JSON.parse(footer_String)],
    images,
    defaultStyle: {
      fontSize: parseFloat(pdfFontSizeSelectedValue),
    },
    styles: printdata.template_setting.style,
  }
  return dd
}
