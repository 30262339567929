const setting = {
  pageMarginsA4: [40, 40, 40, 40],
  pageMarginsA5: [15, 15, 15, 15],
  logo_height_a4: 80,
  logo_width_a4: 80,
  logo_height_a5: 64,
  logo_width_a5: 64,
  header_right_section_width_a4_P: 200,
  header_right_section_width_a5_P: 125,
  header_right_section_width_a4_L: 250,
  header_right_section_width_a5_L: 200,
  footer_column_gap_a4_L: 450,
  footer_column_gap_a4_P: 90,
  footer_column_gap_a5_L: 225,
  footer_column_gap_a5_P: 45,
}

const header = [
  {
    field_id: "header",
    style: "tableExample",
    table: {
      widths: ["*", "*", "*"],
      heights: [10, 10, 10],
      body: [
        [
          { stack: [{ text: "{left_text}", style: "head_left_text" }] },
          { stack: [{ text: "{center_text}", bold: true, style: "head_center_text" }] },
          { stack: [{ text: "{right_text}", style: "head_right_text" }] },
        ],
      ],
    },
    layout: { defaultBorder: false },
    margin: [40, 15, 40, 5],
  },
]

const bodyHeader = [
  {
    field_id: "bodyHeader",
    table: {
      widths: ["*", "{header_right_section_width}"],
      body: [
        [
          {
            style: "invoiceAddressDetails",
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    columns: [
                      {
                        field_id: "company_logo",
                        width: "{logo_width}",
                        image: "{logo}",
                        fit: ["{logo_width}", "{logo_height}"],
                        margin: [0, 0, 0, 0],
                      },
                      {
                        width: "*",
                        stack: [
                          { field_id: "company_name", text: "{companyName}", style: "valueField", bold: true, fontSize: 10.5 },
                          {
                            field_id: "company_address",
                            stack: [
                              { text: "{company_address1}", style: "valueField" },
                              { text: "{company_address2}", style: "valueField" },
                              { field_id: "company_contact", text: "Contact: {company_contact}", style: "valueField" },
                              { field_id: "company_gstin", text: "GSTIN: {company_gstin}", style: "valueField" },
                              { field_id: "company_pan", text: "PAN: {companyPAN}", style: "valueField" },
                              { field_id: "company_aadhaar", text: "Aadhaar: {company_aadhaar}", style: "valueField" },
                              { field_id: "company_OtherNumbers", text: "{companyOtherNumbers}", style: "valueField" },
                            ],
                          },
                        ],
                      },
                    ],
                    columnGap: 10,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    field_id: "billing_address_block",
                    stack: [
                      { text: "Supplier's Name & Address", style: "valueField", margin: [0, 0, 0, 0] },
                      { field_id: "party_name", text: "{partyName}", style: "valueField", bold: true, fontSize: 10.5 },
                      {
                        field_id: "party_address",
                        stack: [
                          { text: "{party_address1}", style: "valueField" },
                          { text: "{party_address2}", style: "valueField" },
                          { field_id: "party_gstin", text: "GSTIN: {party_gstin}", style: "valueField" },
                          { field_id: "party_pan", text: "PAN: {companyPAN}", style: "valueField" },
                          { field_id: "party_aadhaar", text: "Aadhaar: {party_aadhaar}", style: "valueField" },
                          { field_id: "party_contact", text: "Contact: {party_contact}", style: "valueField" },
                        ],
                      },
                    ],
                    border: [false, false, false, true],
                  },
                ],
                [
                  {
                    field_id: "narration_details",
                    stack: [{ text: "Note/Remark" }, { text: "{narration}", style: "valueField" }],
                    border: [false, false, false, false],
                  },
                ],
              ],
            },
            border: [true, true, true, true],
            margin: [-5, 0, -5, 0],
          },
          {
            stack: [
              {
                field_id: "invno_details",
                table: {
                  widths: ["*", "*"],
                  heights: [20],
                  body: [
                    [
                      { field_id: "invoice_no", stack: [{ text: "{title}" }, { text: "{billno}", style: "valueField", bold: true }], border: [false, false, true, true] },
                      { field_id: "invoice_date", stack: [{ text: "Date" }, { text: "{outwardDate}", style: "valueField", bold: true }], border: [false, false, false, true] },
                    ],
                  ],
                },
              },
              {
                field_id: "process_info",
                table: {
                  widths: ["*", "*"],
                  heights: [20],
                  body: [
                    [
                      { field_id: "process", stack: [{ text: "From Process" }, { text: "{processName}", style: "valueField", bold: true }], border: [false, false, true, true] },
                      { stack: [{ text: " " }, { text: " ", style: "valueField" }], border: [false, false, false, true] },
                    ],
                  ],
                },
              },
              {
                field_id: "customFieldNew",
                replacePath: "table.body",
                table: {
                  widths: ["*", "*"],
                  heights: 20,
                  body: [
                    [
                      { stack: [{ text: "Custom Field 1" }, { text: "Custom Field Value", style: "valueField" }], border: [false, false, true, true] },
                      { stack: [{ text: "Custom Field 2" }, { text: "Custom Field Value", style: "valueField" }], border: [false, false, false, true] },
                    ],
                  ],
                },
              },
            ],
            border: [true, true, true, true],
            margin: [-5, 0, -5, 0],
          },
        ],
      ],
    },
    margin: [0, 0, 0, 10],
  },
]

const bodyFooter = [
  {
    field_id: "imgFooter",
    alignment: "center",
    replacePath: "table.body",
    margin: [0, 15, 0, 15],
    layout: "noBorders",
    table: {
      widths: ["*", "*"],
      body: [],
    },
  },
  {
    field_id: "bodyFooter",
    columns: [
      {
        width: "*",
        table: {
          dontBreakRows: true,
          widths: ["*"],
          heights: ["auto", 40, "auto"],
          body: [
            [{ text: "Store/Prepared By", alignment: "center" }],
            [
              {
                border: [true, true, true, true],
                text: "",
              },
            ],
            [{ text: "Signature", alignment: "center" }],
          ],
        },
        layout: { defaultBorder: false },
      },
      {
        width: "*",
        table: {
          dontBreakRows: true,
          widths: ["*"],
          heights: ["auto", 40, "auto"],
          body: [
            [{ text: "Approved By", alignment: "center" }],
            [
              {
                border: [true, true, true, true],
                text: "",
              },
            ],
            [{ text: "Signature", alignment: "center" }],
          ],
        },
        layout: { defaultBorder: false },
      },
    ],
    columnGap: "{columnGap}",
    margin: [0, 10, 0, 0],
  },
]

const body = [{}]
const footer = [{}]

const style = {
  valueField: {
    fontSize: 9,
  },
  head_left_text: {
    alignment: "left",
  },
  head_center_text: {
    fontSize: 12,
    alignment: "center",
  },
  head_right_text: {
    alignment: "right",
  },
  stky: {
    alignment: "center",
  },
  stval: {
    fontSize: 14,
    bold: true,
    alignment: "center",
    margin: [0, 0, 0, 5],
  },
  left: {
    alignment: "left",
  },
  right: {
    alignment: "right",
  },
}

// console.log(JSON.stringify(body))
// console.log(JSON.stringify(header))
// console.log(JSON.stringify(footer))
// console.log(JSON.stringify(setting))

export default {
  header: JSON.stringify(header),
  bodyHeader: JSON.stringify(bodyHeader),
  bodyFooter: JSON.stringify(bodyFooter),
  footer: JSON.stringify(footer),
  body: JSON.stringify(body),
  setting,
  style,
}
