import { alpha, createTheme } from "@material-ui/core/styles"
import fbcolor from "./fbcolors"

export default createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    type: "dark",
    kanbanBackground: {
      default: fbcolor.black.main,
    },
    primary: {
      main: "#90caf9",
      light: "#e3f2fd",
      dark: "#42a5f5",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    secondary: {
      main: "#ce93d8",
      light: "#f3e5f5",
      dark: "#ab47bc",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    error: {
      main: "#f44336",
      light: "#e57373",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    success: {
      main: "#66bb6a",
      light: "#81c784",
      dark: "#388e3c",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    warning: {
      main: "#ffa726",
      light: "#ffb74d",
      dark: "#f57c00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      main: "#29b6f6",
      light: "#4fc3f7",
      dark: "#0288d1",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    input: {
      border: alpha("#fff", 0.23),
      hover: alpha("#fff", 1),
    },
    alert: {
      warning: fbcolor.warning.lighter,
      success: fbcolor.success.lighter,
      error: fbcolor.error.lighter,
      info: fbcolor.info.lighter,
      warningText: fbcolor.warning.dark,
      successText: fbcolor.success.dark,
      infoText: fbcolor.info.dark,
      errorText: fbcolor.error.dark,
    },
    chip: {
      red: {
        message: fbcolor.error.dark,
        background: fbcolor.error.light,
      },
      green: {
        message: fbcolor.success.dark,
        background: fbcolor.success.light,
      },
      yellow: {
        message: fbcolor.warning.dark,
        background: fbcolor.warning.light,
      },
      blue: {
        message: fbcolor.info.dark,
        background: fbcolor.info.light,
      },
      purple: {
        message: fbcolor.secondary.dark,
        background: fbcolor.secondary.light,
      },
      black: {
        message: "#fff",
        background: fbcolor.grey.light,
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "10px",
          height: "10px",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(255, 255, 255, 0.3)",
          borderRadius: "10px",
        },
        "*::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        },
      },
    },
    MuiLink: {
      root: {
        textAlign: "left",
      },
    },
    MuiAvatar: {
      img: {
        objectFit: "contain",
        height: "100%",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.9em",
        color: "#fff",
        backgroundColor: fbcolor.black.dark,
      },
      arrow: {
        color: fbcolor.black.dark,
      },
    },
    MuiInputBase: {
      input: {
        padding: "9.5px 0",
        "&:disabled": {
          backgroundColor: fbcolor.black.lightest,
        },
      },
    },
    MuiButtonGroup: {
      contained: {
        boxShadow: "none",
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "9.5px 10px",
        backgroundColor: "#444444",
      },
      multiline: {
        padding: "0 !important",
      },
      inputMultiline: {
        padding: "9.5px 10px",
      },
      notchedOutline: {
        borderRadius: 0,
      },
    },

    MuiAppBar: {
      root: {
        boxShadow: "none",
      },
    },
    MuiTableCell: {
      root: {
        borderRight: "1px solid rgba(81, 81, 81, 1)",
        padding: "8px 10px 8px 10px",
        "&:last-child": {
          paddingRight: 10,
        },
        "&:last-child": {
          borderRight: 0,
        },
        "&:first-child": {
          borderLeft: 0,
        },
        "&:focus": {
          outlineColor: "#fff",
        },
      },
      head: {
        backgroundColor: fbcolor.black.dark,
        color: "#fff",
      },
      body: {
        wordBreak: "break-all",
      },
      sizeSmall: {
        padding: "4px 5px 4px 5px",
        "&:last-child": {
          paddingRight: 5,
        },
      },
      stickyHeader: {
        zIndex: 100,
        backgroundColor: fbcolor.black.dark,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "0.75rem",
        fontWeight: "bold",
        marginBottom: 8,
        marginTop: 16,
        display: "block",
      },
    },
    MuiListItemText: {
      dense: {},
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        borderRadius: 4,
      },
    },
  },
  fbcolor,
})
