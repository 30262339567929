import { getCatchErrorMessage } from "@project/sharedcomponents/utilityFunctions"
import axios from "axios"
import { getheaders, handleResponse, postErrorOnException } from "../Lib/commonfunctions"
import { list_url } from "@project/sharedcomponents/apiURL"
import { IMPORT_COUNTRY_SERVICE_FAIL, IMPORT_COUNTRY_SERVICE_START, IMPORT_COUNTRY_SERVICE_SUCCESS, SNACKBAR_STATE } from "./types"

export const fetch_import_country_data = (props) => {
  return (dispatch) => {
    dispatch({ type: IMPORT_COUNTRY_SERVICE_START })
    let data = new FormData()
    data.append("offset", 0)
    data.append("type", "importcountry")
    data.append("limit", "All")

    axios.defaults.headers.common = getheaders()
    axios({
      method: "post",
      url: list_url({ type: "importcountry" }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: data,
    })
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.data.meta.code === 401) {
          countryImportServiceFail({ dispatch, response: response.data.meta.message })
        } else {
          response.data.result = response.data.result.map((e) => {
            const updated = { ...e }
            updated.code = e.id
            return updated
          })
          if (props.onComplete) {
            props.onComplete(response)
          }
          countryImportServiceSuccess({ dispatch, user: response })
        }
      })
      .catch((error) => {
        postErrorOnException(error)
        countryImportServiceFail({ dispatch, response: getCatchErrorMessage(error) })
      })
  }
}

const countryImportServiceSuccess = ({ dispatch, user }) => {
  dispatch({
    type: IMPORT_COUNTRY_SERVICE_SUCCESS,
    payload: user,
  })
}

const countryImportServiceFail = ({ dispatch, response }) => {
  dispatch({
    type: SNACKBAR_STATE,
    payload: true,
    message: response,
    snackbarType: "error",
  })
  dispatch({
    type: IMPORT_COUNTRY_SERVICE_FAIL,
    payload: response,
  })
}
