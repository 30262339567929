import { AppBar, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography, makeStyles, withStyles } from "@material-ui/core"
import { Delete, Drafts as DraftsIcon, Edit, Fullscreen, FullscreenExit, MoreVert, Print, Send as SendIcon, Share } from "@material-ui/icons"
import CustomSvgIcon from "@project/components/common/CustomSvgIcon"
import { commonSiteSetting, getCookie } from "@project/components/utilityFunctions"
import { isblank } from "@project/sharedcomponents/utilityFunctions"
import React, { useState } from "react"
import { isMobileOnly } from "react-device-detect"
import { useDispatch, useSelector } from "react-redux"
import { setExpandValue } from "../../Actions"

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

function PageHeader(props) {

  const [anchorEl, setanchorEl] = useState(null)
  const [anchorEl1, setanchorEl1] = useState(null)
  const accountSiteSettingKeyValue = useSelector((state) => state.AuthReducer.accountSiteSettingKeyValue)
  const { windowWidth } = useSelector((state) => state.CommonReducer)
  const dispatch = useDispatch()

  const handleClick = (event) => {
    setanchorEl(event.currentTarget)
  }

  const handleClick1 = (event) => {
    setanchorEl1(event.currentTarget)
  }

  const handleClose = () => {
    setanchorEl(null)
  }

  const handleClose1 = () => {
    setanchorEl1(null)
  }

  const {
    drawerIndex,
    title,
    buttonOne,
    componentId,
    buttonOneName,
    expand,
    actionButton,
    buttonTwo,
    buttonOneIcon,
    buttonTwoName,
    whatsappShareButton,
    whatsAppData,
    sendSMSAndEmail,
    sendSMS,
    sendEmail,
    onPrintClick,
    loading,
    sidebarloading,
    submitting,
    width,
    drawerType,
    productbalanceDetail,
    balanceDetail,
    expandDrawer
  } = props

  const _setExpandValue = (value) => {
    dispatch(setExpandValue(value, drawerIndex))
  }

  const classes = useStyles()

  let formtitle = null
  if (title) {
    formtitle = (
      <Typography variant="h6" style={{ flex: 1 }} color="inherit" noWrap={true}>
        {title}
      </Typography>
    )
  }
  let ClosingBalance = null
  if (!isblank(balanceDetail)) {
    ClosingBalance = (
      <div style={{ marginRight: 150 }}>
        <Typography noWrap style={{ lineHeight: "inherit", cursor: "pointer" }} color="inherit" variant="subtitle1">
          {balanceDetail.name}: {accountSiteSettingKeyValue ? commonSiteSetting({ key: "currency", settingData: accountSiteSettingKeyValue }) : ""}
          {balanceDetail.balance}
        </Typography>
        <Typography noWrap style={{ lineHeight: "inherit" }} color="inherit" variant="subtitle2">
          Balance as on {balanceDetail.balance_date}
        </Typography>
      </div>
    )
  }
  let ProductClosingBalance = null
  if (!isblank(productbalanceDetail)) {
    ProductClosingBalance = (
      <div style={{ marginRight: 150 }}>
        <Typography noWrap style={{ lineHeight: "inherit", cursor: "pointer" }} color="inherit" variant="subtitle1">
          {productbalanceDetail.name}: {productbalanceDetail.balance}
        </Typography>
        <Typography noWrap style={{ lineHeight: "inherit" }} color="inherit" variant="subtitle2">
          Balance as on {productbalanceDetail.balance_date}
        </Typography>
      </div>
    )
  }

  let PrintButton = null
  if (onPrintClick) {
    if (isMobileOnly) {
      PrintButton = (
        <IconButton
          data-testid={componentId ? `${componentId}_drawer_button_1` : "drawer_button_1"}
          disabled={submitting | sidebarloading | loading ? true : false}
          color="inherit"
          style={{ marginLeft: 10 }}
          size="small"
          onClick={onPrintClick}
        >
          <Print />
        </IconButton>
      )
    } else {
      PrintButton = (
        <Button
          data-testid={componentId ? `${componentId}_drawer_button_2` : "drawer_button_2"}
          disabled={submitting | sidebarloading | loading ? true : false}
          style={{ marginLeft: 10 }}
          color="secondary"
          variant="contained"
          size="small"
          startIcon={<Print />}
          onClick={onPrintClick}
        >
          Print
        </Button>
      )
    }
  }
  let actionButon = null
  if (drawerType !== "full" && expand === true && !isMobileOnly) {
    if (expandDrawer) {
      actionButon = (
        <IconButton
          data-testid={componentId ? `${componentId}_drawer_button_3` : "drawer_button_3"}
          tabIndex="-1"
          style={{ textAlign: "center", marginRight: 10 }}
          color="inherit"
          size="small"
          aria-label="trash"
          onClick={() => _setExpandValue(false)}
        >
          <FullscreenExit />
        </IconButton>
      )
    } else {
      actionButon = (
        <IconButton
          data-testid={componentId ? `${componentId}_drawer_button_4` : "drawer_button_4"}
          tabIndex="-1"
          style={{ textAlign: "center", marginRight: 10 }}
          color="inherit"
          size="small"
          aria-label="trash"
          onClick={() => _setExpandValue(true)}
        >
          <Fullscreen />
        </IconButton>
      )
    }
  }

  let ButtonOne = null
  if (buttonOne) {
    if (isMobileOnly && !isblank(buttonOneIcon)) {
      ButtonOne = (
        <IconButton
          data-testid={componentId ? `${componentId}_drawer_button_5` : "drawer_button_5"}
          disabled={submitting | sidebarloading | loading ? true : false}
          color="inherit"
          size="small"
          onClick={buttonOne}
        >
          {buttonOneIcon}
        </IconButton>
      )
    } else {
      ButtonOne = (
        <Button
          data-testid={componentId ? `${componentId}_drawer_button_6` : "drawer_button_6"}
          disabled={submitting | sidebarloading | loading ? true : false}
          color="secondary"
          variant="contained"
          size="small"
          startIcon={buttonOneIcon}
          onClick={buttonOne}
        >
          {buttonOneName}
        </Button>
      )
    }
  }
  let ButtonTwo = null
  if (buttonTwo) {
    ButtonTwo = (
      <Button
        data-testid={componentId ? `${componentId}_drawer_button_7` : "drawer_button_7"}
        style={{ marginLeft: 10 }}
        disabled={submitting | sidebarloading | loading ? true : false}
        color="secondary"
        variant="contained"
        size="small"
        onClick={buttonTwo}
      >
        {buttonTwoName}
      </Button>
    )
  }
  let WhatsAppButton = null
  if (whatsappShareButton) {
    if (sendSMSAndEmail) {
      WhatsAppButton = (
        <React.Fragment>
          {isMobileOnly ? (
            <IconButton
              aria-controls="customized-menu"
              aria-haspopup="true"
              data-testid={componentId ? `${componentId}_drawer_button_9` : "drawer_button_9"}
              size="small"
              color="inherit"
              style={{ marginRight: 10 }}
              disabled={submitting | sidebarloading | loading ? true : false}
              onClick={handleClick}
            >
              <Share />
            </IconButton>
          ) : (
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              data-testid={componentId ? `${componentId}_drawer_button_8` : "drawer_button_8"}
              size="small"
              style={{ marginRight: 10 }}
              disabled={submitting | sidebarloading | loading ? true : false}
              variant="contained"
              color="secondary"
              startIcon={<Share />}
              onClick={handleClick}
            >
              Share
            </Button>
          )}

          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            data-testid={componentId ? `${componentId}_drawer_button_10` : "drawer_button_10"}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <li>
              <StyledMenuItem target="_blank" dense component="a" href={whatsAppData}>
                <ListItemIcon>
                  <CustomSvgIcon name="whatsApp" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Send WhatsApp" />
              </StyledMenuItem>
            </li>
            <StyledMenuItem
              button
              dense
              onClick={() => {
                sendEmail()
                handleClose()
              }}
            >
              <ListItemIcon>
                <DraftsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Send Email" />
            </StyledMenuItem>
            <StyledMenuItem
              button
              dense
              onClick={() => {
                sendSMS()
                handleClose()
              }}
            >
              <ListItemIcon>
                <SendIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Send SMS" />
            </StyledMenuItem>
          </StyledMenu>
        </React.Fragment>
      )
    } else {
      if (isMobileOnly) {
        WhatsAppButton = (
          <IconButton
            data-testid={componentId ? `${componentId}_drawer_button_11` : "drawer_button_11"}
            style={{ marginRight: 10 }}
            target="_blank"
            rel="noreferrer"
            color="inherit"
            disabled={submitting | sidebarloading | loading ? true : false}
            href={whatsAppData}
            size="small"
          >
            <CustomSvgIcon name="whatsApp" />
          </IconButton>
        )
      } else {
        WhatsAppButton = (
          <Button
            data-testid={componentId ? `${componentId}_drawer_button_12` : "drawer_button_12"}
            style={{ marginRight: 10 }}
            target="_blank"
            rel="noreferrer"
            disabled={submitting | sidebarloading | loading ? true : false}
            startIcon={<CustomSvgIcon name="whatsApp" />}
            color="secondary"
            href={whatsAppData}
            variant="contained"
            size="small"
          >
            {" "}
            Share on WhatsApp
          </Button>
        )
      }
    }
  }

  let menuButton = null
  if (actionButton && actionButton.length > 0) {
    menuButton = (
      <React.Fragment>
        {isMobileOnly ? (
          <IconButton
            aria-controls="customized-menu"
            aria-haspopup="true"
            size="small"
            data-testid={componentId ? `${componentId}_drawer_button_13` : "drawer_button_13"}
            color="inherit"
            style={{ marginRight: 10 }}
            disabled={submitting | sidebarloading | loading ? true : false}
            onClick={handleClick1}
          >
            <MoreVert />
          </IconButton>
        ) : (
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            data-testid={componentId ? `${componentId}_drawer_button_14` : "drawer_button_14"}
            size="small"
            style={{ marginRight: 10 }}
            disabled={submitting | sidebarloading | loading ? true : false}
            variant="contained"
            color="secondary"
            onClick={handleClick1}
          >
            Action
          </Button>
        )}

        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl1}
          keepMounted
          data-testid={componentId ? `${componentId}_drawer_button_15` : "drawer_button_15"}
          open={Boolean(anchorEl1)}
          onClose={handleClose1}
        >
          {actionButton.map((item) => {
            if (item.type === "edit") {
              return (
                <StyledMenuItem
                  button
                  dense
                  onClick={() => {
                    item.onClick()
                    handleClose1()
                  }}
                >
                  <ListItemIcon>
                    <Edit fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </StyledMenuItem>
              )
            } else if (item.type === "delete") {
              return (
                <StyledMenuItem
                  button
                  dense
                  onClick={() => {
                    item.onClick()
                    handleClose1()
                  }}
                >
                  <ListItemIcon>
                    <Delete fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </StyledMenuItem>
              )
            } else {
              return (
                <StyledMenuItem
                  button
                  dense
                  onClick={() => {
                    item.onClick()
                    handleClose1()
                  }}
                >
                  <ListItemText primary={item.label} />
                </StyledMenuItem>
              )
            }
          })}
        </StyledMenu>
      </React.Fragment>
    )
  }
  return (
    <AppBar
      className={classes.appBar}
      position="fixed"
      color={getCookie("darkmode") === "dark" ? "inherit" : "primary"}
      style={{ width: drawerType === "full" || expandDrawer || isMobileOnly ? windowWidth : width }}
    >
      <Toolbar variant="dense">
        {actionButon}
        {formtitle}
        <div style={{ marginRight: 100 }}>
          {menuButton}
          {WhatsAppButton}
          {ButtonOne}
          {ButtonTwo}
          {PrintButton}
        </div>
        {ProductClosingBalance}
        {ClosingBalance}
      </Toolbar>
    </AppBar>
  )
}

export default PageHeader
