export default {
  Company: "1",
  User: "2",
  CustomerGroup: "3",
  State: "4",
  City: "5",
  Customer: "7",
  CustomerSupplier: "8", // Supplier
  PartyPriceList: "9",
  Account: "11",
  OpeningBalance: "12",
  ProductGroup: "13",
  Units: "14",
  Product: "15",
  SalesInvoice: "16",
  PurchaseInvoice: "18",
  Receipt: "22",
  Payment: "23",
  JournalEntries: "24",
  ClosingStock: "43",
  TaxSchemes: "61",
  Inward: "62",
  Outward: "63",
  Export: "66",
  Import: "67",
  MergeAccount: "68",
  BackupDb: "69",
  WireTransfer: "70",
  ExpensePayment: "71",
  Billing: "75",
  ChangePassword: "76",
  ChangeYear: "77",
  Agent: "78",
  StockCapital: "80",
  Transport: "79",
  SendSms: "89",
  ImportDataFb: "109",
  UserHistories: "111",
  ImportBankStatement: "112",
  ClearCache: "120",
  TwoFactorAuthentication: "121",
  GroupSms: "124",
  LockEntries: "126",
  MergeProducts: "131",
  CreditNote: "134",
  DebitNote: "135",
  Countries: "140",
  UserRights: "141",
  SiteSetting: "142",
  ProductSubGroup: "147",
  PartyGroupPrice: "148",
  RecordExpenses: "149",
  EmailTemplates: "150",
  OpeningStock: "152",
  CostCenter: "161",
  ConfigurableProduct: "186",
  SmsTemplate: "187",
  CustomField: "188",
  EwayBill: "189",
  Godown: "190",
  Barcode: "191",
  GodownStockTransfer: "192",
  Lot: "193",
  ProcessTransfer: "194",
  Process: "195",
  Bot: "208",
  CustomPage: "209",
  AdjustBill: "210",
  ProductSubGroupLevel2: "211",
  BundleProducts: "212",
  Shipping: "213",
  PaymentUtility: "214",
  GstOldPendingInvoice: "215",
  TallyExport: "216",
  StockTransfer: "217",
  ConvertSalesOrderToInvoice: "218",
  ProcessMergePackingSlip: "219",
  ProcessDashboard: "220",
  BankReconciliation: "224",
  ConvertEstimateToInvoice: "225",
  ConvertEstimateToOrder: "226",
  Catalogue: "227",
  EnvelopePrinting: "228",
  ChequePrinting: "229",
  WhatsappAPI: "235",
  NotificationTemplate: "237",
  MergeCity: "238",
  SubGroup: "240",
  ProductSearch: "252",
  RapnetRateImportExport: "253",
  Notification: "254",
  DiscountCoupons: "258",
  MergeGodown: "259",
  Salesman: "260",
  EInvoice: "261",
  Webhook: "267",
  Dashboard: "1000",
  Utilities: "1001",
  Reports: "1002",
  GetMyStoreOrder: "5002",
  SalesOrder: "5003",
  GetMyCatalogueOrder: "5004",
}
