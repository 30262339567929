import { applyMiddleware, compose, createStore } from "redux"
import thunk from "redux-thunk"
import rootReducer from "./Reducers"
import { history } from "./Lib/history"

const initialState = {}
const enhancers = []

const middleware = [thunk]
const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)
export default createStore(rootReducer(history), initialState, composedEnhancers)
