import { getAllStatus, getdropdownvalue, setOrderFilterWise } from "@project/components/utilityFunctions"
import { getdropdownfieldvalue } from "@project/sharedcomponents/utilityFunctions"
import {
  GSTMYSTORE_USER_SEARCH_TEXT_UPDATE,
  GSTMYSTORE_USER_SERVICE_CLEAR,
  GSTMYSTORE_USER_SERVICE_FAIL,
  GSTMYSTORE_USER_SERVICE_INSERTED,
  GSTMYSTORE_USER_SERVICE_START,
  GSTMYSTORE_USER_SERVICE_SUCCESS,
} from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  totalcount: 0,
  norecord: false,
  rowsPerPage: 0,
  page: 0,
  order: "",
  orderBy: "",
  search_text: "",
  deleted: false,
  columnData: [
    { id: "abd_date", numeric: false, visible: true, label: "Date", width: 200, format: "date_time" },
    { id: "name", numeric: false, visible: true, label: "Business Name" },
    { id: "fb_name", numeric: false, visible: true, label: "Fb Account Name" },
    { id: "email", numeric: false, visible: true, label: "Email", width: 200 },
    { id: "mobile", numeric: false, visible: true, label: "Mobile", width: 150 },
    { id: "lastLoginDate", numeric: false, visible: true, label: "Last Login", format: "date_time" },
    { id: "totalTime", numeric: false, visible: true, label: "Time Remaining", isCountDown: true, width: 150 },
    {
      id: "status",
      numeric: false,
      visible: true,
      label: "Status",
      width: 150,
      badge: {
        Allow: { color: "green" },
        "Not allow": { color: "red" },
      },
      showlink: true,
    },
  ],
  metadata: "",
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GSTMYSTORE_USER_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case GSTMYSTORE_USER_SERVICE_SUCCESS:
      if (action.page === 0) {
        action = setOrderFilterWise(action)
      }
      var finalData = action.payload.data.result.map((item) => {
        const updatedItem = { ...item }
        if (item.status === "10") {
          updatedItem.status = "Waiting"
        } else {
          updatedItem.status = getdropdownfieldvalue({ dropDownData: getAllStatus(), field: "id", value: item.status, displayvalue: "name" })
        }
        updatedItem.statusValue = item.status
        return updatedItem
      })
      return {
        ...state,
        data: finalData,
        totalcount: action.payload.data.total_count,
        rowsPerPage: action.rowsPerPage,
        page: action.page,
        order: action.order,
        orderBy: action.orderBy,
        islogin: true,
        norecord: action.payload.data.result.length === 0,
      }
    case GSTMYSTORE_USER_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false, totalcount: 0, search_text: action.search_text }
    case GSTMYSTORE_USER_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case GSTMYSTORE_USER_SEARCH_TEXT_UPDATE:
      return { ...state, search_text: action.search_text, norecord: false }
    case GSTMYSTORE_USER_SERVICE_INSERTED:
      const inserteditem = {}
      inserteditem.code = action.insert_code
      inserteditem.name = action.payload.city
      inserteditem.sid = getdropdownvalue({ formValues: action.payload, field: "stateSelectedValue", prop: "code" })
      inserteditem.state = getdropdownvalue({ formValues: action.payload, field: "stateSelectedValue", prop: "name" })
      inserteditem.statecode = getdropdownvalue({ formValues: action.payload, field: "stateSelectedValue", prop: "statecode" })
      inserteditem.status = getdropdownfieldvalue({ dropDownData: getAllStatus(), field: "id", value: action.payload.status, displayvalue: "name" })
      return { ...state, data: [inserteditem, ...state.data] }
    default:
      return state
  }
}
