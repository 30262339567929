import { convertIntif, isblank } from "@project/sharedcomponents/utilityFunctions"
import {
  TDS_CATEGORY_MASTER_LOCAL_SEARCH,
  TDS_CATEGORY_MASTER_SERVICE_CLEAR,
  TDS_CATEGORY_MASTER_SERVICE_FAIL,
  TDS_CATEGORY_MASTER_SERVICE_START,
  TDS_CATEGORY_MASTER_SERVICE_SUCCESS,
  TDS_CATEGORY_MASTER_SERVICE_UPDATED,
} from "../Actions/types"
const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
  other_type: "",
  search_text: "",
  columnData: [
    { id: "name", numeric: false, visible: true, label: "Name" },
    {
      id: "thresholdLimit",
      numeric: true,
      visible: true,
      currencySign: true,
      toggle: true,
      removetoggleOnly: { valueblank: ["code"] },
      bold: { valueblank: ["code"] },
      label: "Threshold Limit",
      width: 150,
      popover: { key: "updateTDS", placeholder: "Enter Amount", label: "Amount", name: "thresholdLimit", title: "Update Threshold Limit Amount", number: true },
    },
    { id: "tdsWithPan", numeric: true, visible: true, label: `TDS(With PAN) %`, toggle: true, removetoggleOnly: { valueblank: ["code"] }, bold: { valueblank: ["code"] }, width: 200 },
    { id: "tdsWithoutPan", numeric: true, visible: true, label: `TDS(Without PAN) %`, toggle: true, removetoggleOnly: { valueblank: ["code"] }, bold: { valueblank: ["code"] }, width: 200 },
    { id: "surcharge", numeric: true, visible: true, label: `Surcharge %`, toggle: true, removetoggleOnly: { valueblank: ["code"] }, bold: { valueblank: ["code"] }, width: 200 },
  ],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TDS_CATEGORY_MASTER_SERVICE_START:
      return { ...state, loading: true, error: "" }
    case TDS_CATEGORY_MASTER_SERVICE_CLEAR:
      return { ...INITIAL_STATE }
    case TDS_CATEGORY_MASTER_SERVICE_SUCCESS:
      var data = action.payload.map((item) => {
        const updateditem = { ...item }
        if (!isblank(action.dd_tdspayee.find((it) => convertIntif(it.code) === convertIntif(item.code)))) {
          updateditem.name = action.dd_tdspayee.find((it) => convertIntif(it.code) === convertIntif(item.code)).name
        } else {
          updateditem.name = ""
        }
        return updateditem
      })
      return { ...state, ...INITIAL_STATE, data: data, loading: false, other_type: action.other_type, search_text: action.search_text }
    case TDS_CATEGORY_MASTER_SERVICE_FAIL:
      return { ...state, error: action.payload, data: [], loading: false, other_type: action.other_type, search_text: action.search_text }
    case TDS_CATEGORY_MASTER_LOCAL_SEARCH:
      return { ...state, search_text: action.search_text }
    case TDS_CATEGORY_MASTER_SERVICE_UPDATED:
      let updateddata = state.data.map((item, index) => {
        if (convertIntif(item.code) !== convertIntif(action.payload.accountcode)) {
          return item
        }
        const updateditem = { ...item }
        if (action.payload.credit > 0) {
          updateditem.credit_bal = action.payload.credit
          updateditem.debit_bal = "0.00"
        } else {
          updateditem.debit_bal = action.payload.debit
          updateditem.credit_bal = "0.00"
        }
        return updateditem
      })
      return { ...state, data: updateddata }
    default:
      return state
  }
}
